import {
  faDownload,
  faFileInvoiceDollar,
  faEye,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ReactTooltip from 'react-tooltip';
import { isEqual } from 'underscore';
import moment from 'moment';
import { debounce } from 'lodash';

import { setFilter, downloadPulsePurchasesCsv } from '^/actions/actions';
import { FETCH_ALL, getPurchasedPulses } from '^/actions/collections';
import { administerOrganisations } from '^/capabilities';
import { FilterData } from '^/components/Filter';
import { selectCollection, selectOrderingObject } from '^/selectors';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';
import { fromQueryString, toQueryString, FORMAT_DATE } from '^/utils';
import ListPage, { ListHeader, Ordering } from '../../ListPage';
import {
  selectPurchasedPulsesFilters,
  selectPurchasedPulsesHeaders,
} from './selectors';
import { openPurchasedPulseBreakdownModal } from '^/actions/modals';
import TokenStore from '^/TokenStore';

interface DispatchProps {
  getPurchasedPulses: typeof getPurchasedPulses;
  setFilter: typeof setFilter;
  push: typeof push;
  openPurchasedPulseBreakdownModal: typeof openPurchasedPulseBreakdownModal;
}

interface StateProps {
  response: Map<string, any>;
  purchasedPulses: List<Map<string, any>>;
  ordering: Ordering | undefined;
  collection: Map<string, any>;
  shouldShowOrganisations: boolean;
  filters: ReadonlyArray<FilterData>;
  headers: ReadonlyArray<ListHeader>;
  filterSpec: Record<string, string>;
  token: string;
}

interface OwnProps {
  queryParams: {
    filter: string;
  };
}

type Props = DispatchProps & StateProps & OwnProps;

export class PurchasedPulses extends React.PureComponent<Props> {
  private limitedSearch: (searchString: string) => void;

  constructor(props: Props) {
    super(props);
    this.limitedSearch = debounce(
      (searchString: string) =>
        this.props.getPurchasedPulses(
          searchString,
          1,
          this.props.filterSpec,
          FETCH_ALL as any,
          this.props.collection.get('ordering')
        ),
      500
    );
  }

  public componentDidMount() {
    const { collection, filterSpec } = this.props;

    this.props.getPurchasedPulses(
      collection.get('searchString'),
      1,
      filterSpec,
      FETCH_ALL as any,
      collection.get('ordering')
    );
  }

  public componentDidUpdate(prevProps: Props) {
    const { collection, filterSpec } = this.props;

    if (!isEqual(prevProps.filterSpec, filterSpec)) {
      this.props.getPurchasedPulses(
        collection.get('searchString'),
        1,
        filterSpec,
        FETCH_ALL as any,
        collection.get('ordering')
      );
    }
  }

  private onSearchChange = (_field: any, event: any) => {
    this.limitedSearch(event.target.value);
  };

  getInnerBoxHeaders() {
    const { token, filterSpec, collection } = this.props;
    return (
      <>
        <div className="table-inner-box-header right">
          <ReactTooltip
            id="download-shop-payments-csv-button"
            backgroundColor="white"
            border
            borderColor="#C9CCD6"
            clickable
            insecure
            resizeHide
            textColor="black"
            wrapper="div"
          >
            {i18next.t<string>('Download Pulsing payments as CSV')}
          </ReactTooltip>
          <button
            className="btn btn-small btn-icon-square btn-icon-square-secondary"
            onClick={() =>
              downloadPulsePurchasesCsv(
                token,
                filterSpec,
                collection.get('ordering'),
                collection.get('searchString')
              )
            }
            data-for="download-shop-payments-csv-button"
            data-tip
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </>
    );
  }
  public render() {
    const {
      response,
      purchasedPulses,
      ordering,
      filters,
      headers,
    } = this.props;

    return (
      <ListPage<Map<string, any>>
        headers={headers}
        tableClassName={'table-default'}
        filters={filters}
        innerBoxHeaders={this.getInnerBoxHeaders()}
        renderer={this.renderer}
        onFilterChange={this.onSetFilter}
        onOrderingChange={this.onSetOrdering}
        ordering={ordering}
        response={response}
        items={purchasedPulses}
        typeNamePlural={i18next.t<string>('Purchases')}
        searchHeaders={['pulse_name']}
        onSearchChange={this.onSearchChange}
      />
    );
  }

  private openPulseBreakdownModal = (pulseId: string) => () => {
    this.props.openPurchasedPulseBreakdownModal(pulseId);
  };

  private renderer = (item: Map<string, any>) => {
    return (
      <tr>
        {this.props.shouldShowOrganisations && (
          <td>{item.getIn(['pulse', 'organisation_name'])}</td>
        )}
        <td>{moment(item.get('created') || '').format(FORMAT_DATE)}</td>
        <td>{item.get('pulse_name')}</td>
        <td>{item.getIn(['pulse', 'product_name'])}</td>
        <td>{item.get('quantity')}</td>
        <td>N/A</td>
        <td className="text-large">
          <a
            className="margin-right"
            onClick={this.openPulseBreakdownModal(item.get('id'))}
            title="View details"
          >
            <FontAwesomeIcon icon={faEye} />
          </a>
          <a
            href={item.getIn(['invoice', 'invoice_file'])}
            target="_blank"
            download
            title="Download invoice"
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
          </a>
        </td>
      </tr>
    );
  };

  private onSetFilter = (key: string, value: string | null) => {
    const newFilterSpec = Object.assign({}, this.props.filterSpec, {
      [key]: value,
    });
    this.props.push('/page/purchases/' + toQueryString(newFilterSpec));
  };

  private onSetOrdering = (ordering: string | undefined) => {
    const { collection } = this.props;

    const reverseOrdering = ordering === collection.get('ordering', '');
    this.props.getPurchasedPulses(
      collection.get('searchString'),
      1,
      collection.get('filters'),
      FETCH_ALL as any,
      reverseOrdering ? `-${ordering}` : ordering
    );
  };
}

function mapStateToProps(state: StoreState, props: OwnProps): StateProps {
  const filterSpec = fromQueryString(props.queryParams.filter);
  const token = TokenStore.get();
  return {
    purchasedPulses: state.collections.getIn(
      ['purchasedPulses', 'items'],
      List()
    ),
    response: state.responses.get('getCollection'),
    ordering: selectOrderingObject(state, 'purchasedPulses'),
    collection: selectCollection(state, 'purchasedPulses'),
    shouldShowOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
    filters: selectPurchasedPulsesFilters(state, filterSpec),
    headers: selectPurchasedPulsesHeaders(state),
    filterSpec,
    token,
  };
}

export default connect(mapStateToProps, {
  push,
  setFilter,
  getPurchasedPulses,
  openPurchasedPulseBreakdownModal,
})(PurchasedPulses);
