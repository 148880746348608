import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';
import WithPopover from '^/components/WithPopover';

export default class Popovers extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Popovers</PageHeader>

        <PageContent>
          <h3>Popover above (default)</h3>
          <p>
            This is the standard <strong>WithPopover</strong> react component -
            currently goes 100% width. This should prob be reworked so it just
            wraps everything nicely, or replaced with React Tooltip library.
          </p>
          <WithPopover
            popover={
              <div>
                <p className="margin-none">I'm some HTML.</p>
                <p className="margin-none">
                  I'm some <strong>text</strong>.
                </p>
              </div>
            }
          >
            <div
              className="mb-md"
              style={{
                border: '1px solid red',
                padding: '1em',
                backgroundColor: 'papayawhip',
              }}
            >
              Hover over me
            </div>
          </WithPopover>

          <CodeSample code={`<WithPopover popover={..content here..}>`} />

          <h3>Popover below</h3>
          <p>
            This is the standard <strong>WithPopover</strong> react component
            with a below param.
          </p>
          <WithPopover
            popover={
              <div>
                <p className="margin-none">I'm some HTML.</p>
                <p className="margin-none">
                  I'm some <strong>text</strong>.
                </p>
              </div>
            }
            below
          >
            <div
              className="mb-md"
              style={{
                border: '1px solid red',
                padding: '1em',
                backgroundColor: 'papayawhip',
              }}
            >
              Hover over me to see things popover below
            </div>
          </WithPopover>

          <CodeSample code={`<WithPopover popover={..content here..} below>`} />

          <h3>Popover with inline wrapper</h3>
          <p>
            This is the standard <strong>WithPopover</strong> react component
            with an inline param - this makes the wrapper inline-block around
            the element it surrounds.
          </p>

          <WithPopover
            inline
            popover={
              <div>
                <p className="margin-none">I'm some HTML.</p>
                <p className="margin-none">
                  I'm some <strong>text</strong>.
                </p>
              </div>
            }
          >
            <button className="btn btn-primary mb-md">Hover over me</button>
          </WithPopover>

          <CodeSample
            code={`<WithPopover inline popover={..content here..}>`}
          />
        </PageContent>
      </div>
    );
  }
}
