import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { sum } from '^/utils';
import PureComponent from '^/components/PureComponent';
import { getReportCost } from '^/models/product';
import AutoGenerateCreditWarning from '^/components/activities/AutoGenerateCreditWarning';

export default class ReportCost extends PureComponent {
  render() {
    const {
      numberOfRespondents,
      organisation,
      reportTemplatesByProductOrganisation,
      remainingCost,
      hidePurchaseLink,
    } = this.props;
    const reportCosts = reportTemplatesByProductOrganisation.map(
      ([productOrganisation, reportTemplates]) =>
        sum(
          reportTemplates.map(report =>
            parseInt(getReportCost(report, productOrganisation), 10)
          )
        )
    );
    const totalCreditCost = numberOfRespondents * sum(reportCosts);
    const costToGenerateRemaining =
      remainingCost !== undefined ? remainingCost : totalCreditCost;

    return (
      <div>
        <h5 className="heading-underlined">{i18next.t('Cost')}</h5>

        <div className="form-block">
          {!organisation.get('has_unlimited_credits') &&
            organisation.get('credit') < costToGenerateRemaining && (
              <AutoGenerateCreditWarning
                organisation={organisation}
                hidePurchaseLink={hidePurchaseLink}
              />
            )}
          <table className="table-default table-no-border margin-none">
            <tbody>
              <tr>
                <td>{i18next.t('Total credit cost')}:</td>
                <td className="align-right">{totalCreditCost}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
