import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import _ from 'underscore';
import { debounce } from 'lodash';
import i18next from 'i18next';

import {
  can,
  administerOrganisations,
  administerActivities,
} from '^/capabilities';
import { USER_STATUS } from '^/models/user';
import {
  getUsers,
  getUserImports,
  getAllOrganisationOptions,
  getUserCategories,
  getGroups,
  clearCollection,
  FETCH_ALL,
} from '^/actions/collections';
import { setPageSize, PAGE_SIZE_OPTIONS } from '^/actions/actions';
import PureComponent from '^/components/PureComponent';
import ControlBar from '^/components/ControlBar';
import UserRow from './UserRow';
import ListPage from '^/components/ListPage';
import ListPagePagination from '^/components/ListPagePagination';
import Checkbox from '../Checkbox';

const HEADERS = [
  {
    title: i18next.t('Name'),
    field: 'full_name',
    className: 'user-name',
    sortable: true,
  },
  {
    title: i18next.t('Account'),
    field: 'organisation',
    className: 'user-org',
  },
  {
    title: i18next.t('Groups'),
    field: 'groups',
    className: 'user-org',
  },
  {
    title: i18next.t('Email'),
    field: 'email',
    className: '',
    sortable: true,
  },
  {
    title: i18next.t('Status'),
    field: 'status',
    className: 'hidden-when-selectable',
  },
  {
    title: i18next.t('Status date'),
    field: 'status_date',
  },
  {
    title: i18next.t('Action'),
    className: '',
  },
];

export class UsersListPage extends PureComponent {
  constructor(props) {
    super(props);
    this.limitedSearch = debounce(
      searchString => this.getUsers(searchString),
      500
    );
  }

  componentDidMount() {
    const {
      noLoad,
      filterSpec,
      shouldShowOrganisations,
      pageSize,
      fetchAll,
    } = this.props;
    if (!noLoad) {
      this.props.clearCollection('users');
      this.props.getUsers('', 1, filterSpec, fetchAll ? FETCH_ALL : pageSize);
      this.props.getUserCategories();
      this.props.getUserImports();
    }
    if (shouldShowOrganisations) {
      this.props.getAllOrganisationOptions();
    }
  }

  getHeaders() {
    const { simple, shouldShowOrganisations, shouldShowGroups } = this.props;
    let headers;

    if (!simple) {
      headers = HEADERS;
    } else {
      headers = [
        {
          title: '',
          className: 'add-header',
          field: 'add-user',
        },
        ...HEADERS.slice(0, 4),
      ];
    }

    if (!shouldShowGroups) {
      headers = headers.filter(each => each.field !== 'groups');
    }

    if (shouldShowOrganisations) {
      return headers;
    }

    return headers.filter(each => each.field !== 'organisation');
  }

  getUsers(searchString) {
    const { filterSpec, pageSize, fetchAll } = this.props;
    return this.props.getUsers(
      searchString,
      1,
      filterSpec,
      fetchAll ? FETCH_ALL : pageSize
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.filterSpec, this.props.filterSpec) ||
      nextProps.haveFiltersChanged !== this.props.haveFiltersChanged ||
      !_.isEqual(nextProps.pageSize, this.props.pageSize)
    ) {
      this.props.getUsers(
        this.props.users.get('searchString', ''),
        1,
        nextProps.filterSpec,
        nextProps.fetchAll ? FETCH_ALL : nextProps.pageSize
      );
    }
  }

  loadPage(page) {
    const { users, pageSize, fetchAll } = this.props;
    this.props.getUsers(
      users.get('searchString', ''),
      page,
      users.get('filters'),
      fetchAll ? FETCH_ALL : pageSize,
      users.get('ordering', '')
    );
  }

  getOrdering() {
    const ordering = this.props.users.get('ordering', '');
    return {
      field: ordering.replace(/^-/, ''),
      reversed: ordering[0] === '-',
    };
  }

  setOrdering(newOrdering) {
    const { users, pageSize, fetchAll } = this.props;

    const reverseOrdering = newOrdering === users.get('ordering', '');
    this.props.getUsers(
      users.get('searchString'),
      1,
      users.get('filters'),
      fetchAll ? FETCH_ALL : pageSize,
      reverseOrdering ? `-${newOrdering}` : newOrdering
    );
  }

  getFilters() {
    const {
      filterSpec,
      shouldShowOrganisations,
      shouldShowGroups,
      groups,
    } = this.props;

    let filters = [
      {
        name: i18next.t('Import'),
        key: 'imported',
        currentValue: filterSpec['imported'],
        values: { async: true, collectionKey: 'userImports' },
      },
      {
        name: i18next.t('Status'),
        key: 'status',
        currentValue: filterSpec['status'],
        values: {
          values: List(
            _.map(USER_STATUS, each => Map({ id: each, name: each }))
          ),
        },
      },
    ];

    const GROUP_KEY_NAME = 'groupitems';

    filters = filters.concat(
      (this.props.userCategories || List())
        .map(group => ({
          name: group.get('name'),
          key: GROUP_KEY_NAME,
          multi_key: true,
          currentValue: filterSpec[GROUP_KEY_NAME],
          values: { values: group.get(GROUP_KEY_NAME) },
        }))
        .toJS()
    );

    if (shouldShowOrganisations) {
      filters.push({
        name: 'Organisation',
        key: 'organisation',
        currentValue: filterSpec['organisation'],
        values: {
          async: true,
          collectionKey: 'organisationOptions',
        },
      });
    }

    if (shouldShowGroups) {
      filters.push({
        name: i18next.t('Group'),
        key: 'groups',
        currentValue: filterSpec['groups'],
        values: groups
          ? {
              values: groups,
            }
          : {
              async: true,
              fetchAction: this.props.getGroups.bind(null),
              collectionKey: 'groups',
            },
      });
    }

    return filters;
  }

  renderUser(user) {
    const {
      isSelectModal,
      shouldShowOrganisations,
      shouldShowGroups,
      hideProfilePicture,
      simple,
    } = this.props;
    return (
      <UserRow
        key={user.get('id')}
        user={user}
        isSelectModal={isSelectModal}
        simple={simple}
        showOrganisations={shouldShowOrganisations}
        shouldShowGroups={shouldShowGroups}
        hideProfilePicture={hideProfilePicture}
        {...this.props.getUserRowProps(user)}
      />
    );
  }

  onSearchChange(_field, event) {
    this.limitedSearch(event.target.value);
  }

  renderHeader(field) {
    if (field !== 'add-user') {
      return null;
    }
    const { users, userIsSelected, maxNumMoreUsers } = this.props;
    const items = users.get('items');
    const allUsersSelected = !items.isEmpty() && items.every(userIsSelected);
    return (
      <Checkbox
        id={field}
        checked={allUsersSelected}
        disabled={
          maxNumMoreUsers !== undefined && items.count() > maxNumMoreUsers
        }
        onChange={
          allUsersSelected ? this.props.onClearAll : this.props.onSelectAll
        }
      />
    );
  }

  render() {
    const {
      users,
      selectOne,
      pageSize,
      allowChangePageSize,
      scrollable,
      className,
    } = this.props;
    const items = users.get('items');
    const isFiltered =
      (users.get('searchString') && users.get('searchString') !== '') ||
      !_.isEmpty(this.props.filterSpec);
    const filters = this.getFilters().filter(
      each => (this.props.hideFilters || []).indexOf(each.key) === -1
    );
    const count = items ? items.size : 0;
    const pluralisedNoun = selectOne ? i18next.t('user') : i18next.t('users');

    return (
      <div>
        <ListPage
          headers={this.getHeaders()}
          items={items}
          typeNamePlural={i18next.t('users')}
          isFiltered={isFiltered}
          scrollable={scrollable}
          className={className}
          listClassName="user-grid"
          searchHeaders={'full_name'}
          onSearchChange={this.onSearchChange.bind(this)}
          tableClassName={'table-default'}
          response={this.props.response}
          renderer={this.renderUser.bind(this)}
          filters={filters}
          onFilterChange={this.props.onFilterChange}
          ordering={this.getOrdering()}
          onOrderingChange={ordering => this.setOrdering(ordering)}
          hideBorder
          renderHeader={this.renderHeader.bind(this)}
        >
          {!this.props.hideControlBar && (
            <ControlBar
              hideAdd={this.props.isSelectModal}
              createButtonText={i18next.t('user')}
              title={this.props.title}
              hideSearch
              {...this.props.controlBarProps}
            >
              {this.props.controlBarChildren}
            </ControlBar>
          )}
          {this.props.children}
          {this.props.fetchAll ? (
            <div>
              {i18next.t('Showing {{count}} {{noun}}.', {
                count,
                noun: pluralisedNoun,
              })}
            </div>
          ) : (
            <ListPagePagination
              pageSize={pageSize}
              pageSizeOptions={allowChangePageSize && PAGE_SIZE_OPTIONS}
              collection={users}
              onSelectPage={page => this.loadPage(page)}
              onChangePageSize={this.props.setPageSize}
            />
          )}
        </ListPage>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.userProfile,
    userCategories: state.collections.getIn(
      ['userCategories', 'items'],
      List()
    ),
    response: state.responses.get('getCollection'),
    shouldShowOrganisations:
      !ownProps.hideOrganisations &&
      can(state.userProfile, administerOrganisations()),
    shouldShowGroups:
      !ownProps.hideGroups && can(state.userProfile, administerActivities()),
    pageSize: ownProps.pageSize || state.ui.get('pageSize'),
    allowChangePageSize: !ownProps.pageSize,
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps, {
    getUsers: ownProps.getUsers || dispatchProps.getUsers,
  });

const dispatchProps = {
  getUsers,
  getUserImports,
  getAllOrganisationOptions,
  getGroups,
  getUserCategories,
  setPageSize,
  clearCollection,
};

export default connect(
  mapStateToProps,
  dispatchProps,
  mergeProps
)(UsersListPage);
