import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons/faQuestionSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { viewQuickSupportGuide } from '^/actions/actionSequences';
import ControlBar from '^/components/ControlBar';
import DashItem from '^/components/dashboard/admin/DashItem';
import { Organisation } from '^/reducers/api/types/organisation';
import { selectUserOrganisationJS } from '^/selectors/user';
import {
  ENABLE_ORDERS_EMAIL,
  PROJECT_OFFICE_EMAIL,
  SUPPORT_EMAIL,
} from '^/settings';
import { StoreState } from '^/store';
import { mailToLink } from '../MailTo';

interface StateProps {
  organisation: Organisation | undefined;
}

interface DispatchProps {
  viewQuickSupportGuide: typeof viewQuickSupportGuide;
}

type Props = StateProps & DispatchProps;

export class SupportHubPage extends PureComponent<Props> {
  public render() {
    return (
      <div>
        <ControlBar
          title={i18next.t<string>('Support hub')}
          hideAdd
          hideSearch
        />
        <h2>{i18next.t<string>('How can we help?')}</h2>
        <div className="dash-item-container">
          <DashItem
            title={i18next.t<string>('Download our quick guide')}
            icon={
              <FontAwesomeIcon
                icon={faFileDownload}
                size="5x"
                className="dash-icon"
              />
            }
            strapline={i18next.t<string>(
              'Learn how to use all the Enable features'
            )}
            onClick={this.props.viewQuickSupportGuide}
          />
          <DashItem
            title={i18next.t<string>('Submit a support request')}
            icon={
              <FontAwesomeIcon
                icon={faQuestionSquare}
                size="5x"
                className="dash-icon"
              />
            }
            strapline={i18next.t<string>(
              'Log an issue or suggest a new feature'
            )}
            onClick={this.onSubmitRequest}
          />
          <DashItem
            title={i18next.t<string>('Contact peoplewise')}
            icon={
              <FontAwesomeIcon
                icon={faEnvelopeOpenText}
                size="5x"
                className="dash-icon"
              />
            }
            strapline={i18next.t<string>(
              'Get in touch with PeopleWise to discuss our offerings or request information'
            )}
            onClick={this.onContact}
          />
        </div>
      </div>
    );
  }

  private onSubmitRequest = () => {
    window.location.href = mailToLink({
      email: SUPPORT_EMAIL,
      subject: `Support request ${this.props.organisation?.name}`,
      body:
        'Dear enable support, Please help with the following issue (Please add any screenshots, user email addresses or any further details to help process the request quickly):',
    });
  };

  private onContact = () => {
    window.location.href = mailToLink({
      email: [PROJECT_OFFICE_EMAIL, ENABLE_ORDERS_EMAIL],
      subject: `Information request from ${this.props.organisation?.name}`,
      body: 'Dear PeopleWise,',
    });
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    organisation: selectUserOrganisationJS(state),
  };
}

export default connect(mapStateToProps, { viewQuickSupportGuide })(
  SupportHubPage
);
