import * as Cookies from 'js-cookie';

const TOKEN_COOKIE_NAME = 'jwt_token';

const TokenStore = {
  get: () => Cookies.get(TOKEN_COOKIE_NAME),
  set: value => Cookies.set(TOKEN_COOKIE_NAME, value, { expires: 7 }), // 7 day cookie length
  clear: () => Cookies.remove(TOKEN_COOKIE_NAME),
};

export default TokenStore;
