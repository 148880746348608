/* eslint-disable react/no-string-refs */
import React from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';

import PureComponent from '../PureComponent';
import Img from '../Img';
import { USER_STATUS } from '^/models/user';

const DEFAULT_IMAGE = '/static/img/icons/user.png';

export default class ProfilePicture extends PureComponent {
  getProfilePictureUrl(user) {
    if (!user) {
      return DEFAULT_IMAGE;
    }

    const { full_name, status, profile_picture } = user.toObject();

    if (profile_picture) {
      return profile_picture;
    }

    if (this.props.hideProfilePicture) {
      return null;
    }

    if (
      !status ||
      status === USER_STATUS.UNINVITED ||
      status === USER_STATUS.INVITED
    ) {
      return DEFAULT_IMAGE;
    }

    if (!full_name) {
      return DEFAULT_IMAGE;
    }
    return null;
  }

  getInitials(fullName) {
    if (fullName) {
      return fullName
        .replace(/^[\s'"-]+/, '')
        .replace(/[\s'"-]+$/, '')
        .split(/[\s'"-]+/)
        .map(text => text.charAt(0))
        .join('');
    }
    return null;
  }

  drawInitialsMaybe() {
    const canvas = this.refs.canvas;

    if (canvas) {
      const fullName = this.props.user.get('full_name');
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#373737';
        ctx.font = Math.min(canvas.width, canvas.height) * 0.5 + 'px Open Sans';
        ctx.fillText(
          this.getInitials(fullName),
          canvas.width * 0.5,
          canvas.height * 0.53
        );
      }
    }
  }

  componentDidMount() {
    this.drawInitialsMaybe();
  }

  componentDidUpdate() {
    this.drawInitialsMaybe();
  }

  render() {
    const { src, user, className } = this.props;
    const profilePictureUrl = src || this.getProfilePictureUrl(user);
    const picStyle = {
      width: this.props.width || '100%',
      height: this.props.height || '100%',
    };
    return profilePictureUrl ? (
      <div
        style={picStyle}
        className={classNames('profile-picture-wrapper', className)}
      >
        <Img
          className="profile-picture"
          alt="Profile Picture"
          circular
          cover
          src={profilePictureUrl}
        />
      </div>
    ) : (
      <canvas
        className={classNames(
          'profile-initials profile-picture-wrapper',
          className
        )}
        width={this.props.width || '100%'}
        height={this.props.height || '100%'}
        ref="canvas"
      />
    );
  }
}
