import React from 'react';
import i18next from 'i18next';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';

import ControlBar from '^/components/ControlBar';
import { getAllOrgs, getOrgFlexiPulseNames } from '^/actions/actions';
import { createPulseAndRedirect } from '^/actions/actionSequences';
import { getOrgPulseBehaviourHierarchyOptions } from '^/actions/collections';
import { StoreState } from '^/store';
import {
  AllOrgs,
  OrgFlexiPulseNames,
  PulseBehaviourHierarchyOptions,
} from '^/reducers/api/types';
import CreatePulseForm from './CreatePulseForm';
import { isPending } from '^/responseStates';
import { ContainerRectangleTile } from '../ContainerRectangleTile';
import TaskCompleteTimed from '../TaskCompleteTimed';
import Alert, { AlertType } from '../Alert';
import { PULSE_NOTIFICATION_TIMEOUT_IN_SECONDS } from './constants';
import { CreatePulseFormValues } from './types';

interface DispatchProps {
  getAllOrgs: typeof getAllOrgs;
  push: typeof push;
  createPulseAndRedirect: typeof createPulseAndRedirect;
  getOrgPulseBehaviourHierarchyOptions: typeof getOrgPulseBehaviourHierarchyOptions;
  getOrgFlexiPulseNames: typeof getOrgFlexiPulseNames;
}

interface StateProps {
  response?: Map<string, any>;
  orgFlexiPulseNamesResponse?: Map<string, any>;
  getCollectionPending: boolean;
  orgFlexiPulseNames: OrgFlexiPulseNames | null;
  organisations: AllOrgs;
  pulseBehaviourHierarchyOptions: PulseBehaviourHierarchyOptions | null;
}

interface State {
  showTaskCompleted: boolean;
  createPulseError: string | undefined;
}

export type Props = StateProps & DispatchProps;

export class CreatePulsePage extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showTaskCompleted: false,
      createPulseError: undefined,
    };
    props.getAllOrgs({
      pulse: true,
    });
  }

  showFlash = (pulseId: string | undefined) => {
    if (pulseId) {
      this.setState({ showTaskCompleted: true }, () =>
        setTimeout(
          () => this.props.push(`/page/pulses/${pulseId}/`),
          1000 * PULSE_NOTIFICATION_TIMEOUT_IN_SECONDS
        )
      );
    } else {
      this.setState({
        createPulseError: i18next.t<string>(
          'There was an error creating your pulse'
        ),
      });
    }
  };

  submitData = (data: any) => {
    return this.props.createPulseAndRedirect(
      {
        ...data,
        users: data.users?.join(),
      },
      this.showFlash
    );
  };

  public render() {
    const {
      response,
      orgFlexiPulseNamesResponse,
      organisations,
      pulseBehaviourHierarchyOptions,
      orgFlexiPulseNames,
      getCollectionPending,
    } = this.props;

    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/pulses/hub/">{i18next.t<string>('Pulse hub')}</Link>
        <span>{i18next.t<string>('Create Pulse Check')}</span>
      </span>
    );

    return (
      <div>
        {this.state.showTaskCompleted && (
          <TaskCompleteTimed
            text="Pulse Check created!"
            iconClass="pulse-status active"
            textClass="font-size-xxxl semi-bold-text margin-top"
            time={null}
          />
        )}
        <ControlBar
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
          title={i18next.t<string>('Create Pulse Check')}
        />
        <div className="row">
          <div className="col-xs-12 center">
            {i18next.t<string>(
              'Want to create a Pulse associated with an assessment?'
            )}{' '}
            <Link to="/page/activities/create/">
              {i18next.t<string>('click here.')}
            </Link>
          </div>
          {this.state.createPulseError && (
            <div className="col-xs-12 center">
              <Alert type={AlertType.Error}>
                {this.state.createPulseError}
              </Alert>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="container-tile-container">
              <ContainerRectangleTile noBorder>
                <h1 className="title">
                  {i18next.t<string>('Create Pulse Check')}
                </h1>
                <CreatePulseForm
                  response={response}
                  orgFlexiPulseNamesResponse={orgFlexiPulseNamesResponse}
                  organisations={organisations}
                  orgFlexiPulseNames={orgFlexiPulseNames}
                  pulseBehaviourHierarchyOptions={
                    pulseBehaviourHierarchyOptions
                  }
                  getOrgPulseBehaviourHierarchyOptions={
                    this.props.getOrgPulseBehaviourHierarchyOptions
                  }
                  getCollectionPending={getCollectionPending}
                  onSubmit={this.onSubmit}
                />
              </ContainerRectangleTile>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSubmit = (data: CreatePulseFormValues) => {
    this.submitData(data);
  };
}

export function mapStateToProps(state: StoreState): StateProps {
  const hierarchyOptions = state.collections.getIn([
    'pulseBehaviourHierarchyOptions',
    'items',
  ]);
  return {
    response: state.responses.get('createPulse'),
    orgFlexiPulseNamesResponse: state.responses.get('getOrgFlexiPulseNames'),
    getCollectionPending: isPending(state.responses.get('getCollection')),
    orgFlexiPulseNames: state.orgFlexiPulseNames,
    organisations: state.allOrgs,
    pulseBehaviourHierarchyOptions: (hierarchyOptions?.toJS() ||
      null) as PulseBehaviourHierarchyOptions | null,
  };
}

export default connect(mapStateToProps, {
  getAllOrgs,
  push,
  createPulseAndRedirect,
  getOrgPulseBehaviourHierarchyOptions,
  getOrgFlexiPulseNames,
})(CreatePulsePage);
