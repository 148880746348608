import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import { closeTopModal } from '^/actions/modals';

export class ProfileThankYouModal extends PureComponent {
  render() {
    return (
      <div>
        <button
          className="btn btn-primary pull-right margin-none"
          onClick={() => this.props.closeTopModal()}
        >
          {i18next.t('Continue')}
        </button>
      </div>
    );
  }
}

export default connect(null, { closeTopModal })(ProfileThankYouModal);
