import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import FormError from '^/components/FormError';
import LiveButton from '^/components/LiveButton';
import { formatStripeCreditCostToDisplay, CURRENCIES } from '^/utils';
import Alert from '^/components/Alert';

export const EditCreditCostForm = props => {
  const { onSubmit, response, currentCreditCost } = props;

  const { currency } = currentCreditCost.toObject();
  const creditCostDisplay = formatStripeCreditCostToDisplay(currentCreditCost);
  return (
    <Formik
      initialValues={{ cost: '' }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values }) => (
        <Form className="form-block">
          <Alert className="mb-none">
            {i18next.t(
              'The new global credit cost will be applied to all credit purchases when confirmed below.'
            )}
          </Alert>

          <div>
            <label htmlFor="current-credit-cost">
              {i18next.t('Current credit cost ({{currency}})', {
                currency: CURRENCIES[currency]['symbol'],
              })}
            </label>
            <div className="form-item">
              <Field
                type="text"
                name="cost"
                id="current-credit-cost"
                placeholder={creditCostDisplay}
              />
              <FormError response={response} formKey="cost" />
            </div>
          </div>

          <div className="modal-footer clearfix">
            <div className="pull-right">
              <button
                className="btn btn-default"
                type="button"
                onClick={() => this.props.onClose()}
              >
                {i18next.t('Cancel')}
              </button>
              <LiveButton
                disabled={!values.cost}
                buttonText={i18next.t('Save credit cost')}
                pendingMessage={i18next.t('Saving credit cost...')}
                response={response}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditCreditCostForm;
