import classNames from 'classnames';
import React, { ReactNode } from 'react';

import Icon from '^/components/Icon';
import SearchBar from '^/components/Searchbar';

export type Column<T> = Readonly<{
  header?: ReactNode;
  value: (row: T) => ReactNode;
  sortKey?: string;
  searchKey?: string;
}>;

export interface ListingTableState {
  ordering?: {
    field: string;
    reversed: boolean;
  };
  search?: string;
}

export interface OwnProps<T extends {}> {
  columns: ReadonlyArray<Column<any>>;
  rows: ReadonlyArray<T> | null | undefined;
  onLoad: (params?: {}) => {};
  onClickRow?: (row: T) => void;
  rowClassName?: (row: T) => string;
  state: ListingTableState;
  onStateChange: (newState: Partial<ListingTableState>) => void;
}

type Props<T extends {}> = OwnProps<T>;

export default class ListingTable<T extends {}> extends React.PureComponent<
  Props<T>
> {
  public componentDidMount() {
    this.props.onLoad();
  }

  public render() {
    const { columns, rows, onClickRow, rowClassName } = this.props;
    const { ordering } = this.props.state;
    return (
      <table className="user-select-table">
        <thead>
          <tr>
            {columns.map(({ header, sortKey, searchKey }, idx) => (
              <th key={idx}>
                <div
                  className={classNames(
                    'table-header',
                    sortKey && 'table-header-control-on-right'
                  )}
                >
                  {header}
                  {sortKey && (
                    <Icon
                      type={'up-down'}
                      className={classNames(
                        'sort-control',
                        ordering?.field === sortKey
                          ? ordering?.reversed
                            ? 'down'
                            : 'up'
                          : ''
                      )}
                      onClick={() =>
                        this.onSortChange(
                          sortKey,
                          ordering?.field === sortKey && !ordering?.reversed
                        )
                      }
                    />
                  )}
                </div>
                {searchKey && (
                  <SearchBar
                    className="small"
                    onChange={event => this.onSearchChange(event.target.value)}
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows &&
            rows.map((row, rowIdx) => (
              <tr
                key={rowIdx}
                className={rowClassName ? rowClassName(row) : ''}
                onClick={onClickRow ? () => onClickRow(row) : undefined}
              >
                {columns.map(({ value }, colIdx) => (
                  <td key={colIdx}>{value(row)}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  }

  private onSearchChange = (value: string) =>
    this.props.onStateChange({ search: value });

  private onSortChange = (field: string, reversed: boolean) =>
    this.props.onStateChange({
      ordering: { field, reversed },
    });
}
