import React from 'react'; // eslint-disable-line no-unused-vars
import { fromJS } from 'immutable';
import classNames from 'classnames';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import MAPPracticeQuestionIcon from './MAPPracticeQuestionIcon';

function Option({ text, onClick, selected, readOnly, highlighted }) {
  const className = classNames('map-question-part-button', {
    'map-question-part-button-selected': selected && !highlighted,
    'map-question-part-button-highlighted': highlighted,
  });

  return (
    <button
      disabled={readOnly}
      className={className}
      onClick={!readOnly && onClick}
    >
      {text}
    </button>
  );
}

export const OPTIONS = fromJS([
  { value: 'A', label: 'Set A' },
  { value: 'B', label: 'Set B' },
  { value: 'NEITHER', label: 'Neither' },
]);

export default class MAPQuestionOption extends PureComponent {
  render() {
    const { questionOption, answer, readOnly, disabled, imgSrc } = this.props;
    const { id } = questionOption.toObject();
    const showTickOrCrossIcon =
      readOnly && answer && questionOption.get('correct_choice');

    return (
      <div className="map-question-part">
        {showTickOrCrossIcon && (
          <MAPPracticeQuestionIcon
            correct={questionOption.get('correct_choice') === answer}
          />
        )}
        <img src={imgSrc} className="map-question-part-image" />
        {OPTIONS.map(option => (
          <Option
            key={option.get('value')}
            text={i18next.t(option.get('label'))}
            selected={answer === option.get('value')}
            onClick={() => this.props.answerQuestion(option.get('value'), id)}
            highlighted={
              readOnly &&
              questionOption.get('correct_choice') === option.get('value')
            }
            readOnly={readOnly || disabled}
          />
        ))}
        {this.props.isLoading ? <Loading className="button-loading" /> : ''}
      </div>
    );
  }
}
