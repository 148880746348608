import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import PureComponent from '^/components/PureComponent';

export default class Tag extends PureComponent {
  render() {
    const { onRemove, name, className, disabled } = this.props;

    return (
      <div className={classNames('tag', className)}>
        <div className="tag-name">{name}</div>
        {onRemove && (
          <button
            type="button"
            className="btn btn-small tag-button"
            onClick={onRemove}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </button>
        )}
      </div>
    );
  }
}

Tag.propTypes = {
  name: PropTypes.string,
  onRemove: PropTypes.func,
};
