/* eslint-disable no-undef */
/* eslint-disable no-console */
/*
 * This is a modified copy of Redux's built in combineReducers
 * https://github.com/rackt/redux/blob/master/src/utils/combineReducers.js
 */
import _ from 'underscore';
import { Map } from 'immutable';

import { NODE_ENV } from './settings';

function getUndefinedStateErrorMessage(key, action) {
  const actionType = action && action.type;
  const actionName =
    (actionType && `"${actionType.toString()}"`) || 'an action';

  return (
    `Reducer "${key}" returned undefined handling ${actionName}. ` +
    `To ignore an action, you must explicitly return the previous state.`
  );
}

function getUnexpectedStateKeyWarningMessage(inputState, outputState, action) {
  const reducerKeys = outputState.keySeq();
  const argumentName =
    action && action.type === '@@redux/INIT'
      ? 'initialState argument passed to createStore'
      : 'previous state received by the reducer';

  if (outputState.size === 0) {
    return (
      'Store does not have a valid reducer. Make sure the argument passed ' +
      'to combineReducers is an object whose values are reducers.'
    );
  }

  if (!Map.isMap(inputState)) {
    return (
      `The ${argumentName} has unexpected type of "` +
      {}.toString.call(inputState).match(/\s([a-z|A-Z]+)/)[1] +
      `". Expected argument to be an object with the following ` +
      `keys: "${reducerKeys.join('", "')}"`
    );
  }

  const unexpectedKeys = inputState
    .keySeq()
    .filter(key => reducerKeys.includes(key) === false);

  if (unexpectedKeys.count() > 0) {
    return (
      `Unexpected ${unexpectedKeys.count() > 1 ? 'keys' : 'key'} ` +
      `"${unexpectedKeys.join('", "')}" found in ${argumentName}. ` +
      `Expected to find one of the known reducer keys instead: ` +
      `"${reducerKeys.join('", "')}". Unexpected keys will be ignored.`
    );
  }
}

function assertReducerSanity(reducers) {
  _.each(reducers, (reducer, key) => {
    const initialState = reducer(undefined, { type: '@@redux/INIT' });

    if (typeof initialState === 'undefined') {
      throw new Error(
        `Reducer "${key}" returned undefined during initialization. ` +
          `If the state passed to the reducer is undefined, you must ` +
          `explicitly return the initial state. The initial state may ` +
          `not be undefined.`
      );
    }

    const type =
      '@@redux/PROBE_UNKNOWN_ACTION_' +
      Math.random()
        .toString(36)
        .substring(7)
        .split('')
        .join('.');
    if (typeof reducer(undefined, { type }) === 'undefined') {
      throw new Error(
        `Reducer "${key}" returned undefined when probed with a random type. ` +
          `Don't try to handle @@redux/INIT or other actions in "redux/*" ` +
          `namespace. They are considered private. Instead, you must return the ` +
          `current state for any unknown actions, unless it is undefined, ` +
          `in which case you must return the initial state, regardless of the ` +
          `action type. The initial state may not be undefined.`
      );
    }
  });
}

export default function combineImmutableReducers(reducers) {
  const finalReducers = _.chain(reducers)
    .pairs()
    .filter(([, /* key */ val]) => _.isFunction(val))
    .object()
    .value();

  let sanityError;

  try {
    assertReducerSanity(finalReducers);
  } catch (e) {
    sanityError = e;
  }

  const defaultState = Map(_.mapObject(finalReducers, () => undefined));

  return function combination(state = defaultState, action) {
    if (sanityError) {
      throw sanityError;
    }

    let hasChanged = false;
    const finalState = Map(
      _.mapObject(finalReducers, (reducer, key) => {
        if (!Map.isMap(state)) {
          throw new Error(
            `combineImmutableReducers expected immutable map ` +
              `but got ${typeof state}: ${JSON.stringify(state)}`
          );
        }

        const previousStateForKey = state.get(key);
        const nextStateForKey = reducer(previousStateForKey, action);

        if (typeof nextStateForKey === 'undefined') {
          const errorMessage = getUndefinedStateErrorMessage(key, action);
          throw new Error(errorMessage);
        }
        hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
        return nextStateForKey;
      })
    );

    if (NODE_ENV !== 'production') {
      const warningMessage = getUnexpectedStateKeyWarningMessage(
        state,
        finalState,
        action
      );
      if (warningMessage) {
        console.error(warningMessage);
      }
    }

    return hasChanged ? finalState : state;
  };
}
