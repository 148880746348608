import * as actions from '../actions/actions';
import TokenStore from '../TokenStore';

export default store => next => action => {
  switch (action.type) {
    case actions.CLEAR_TOKEN:
      TokenStore.clear();
      break;

    case actions.LOGIN.SUCCESS:
    case actions.EXTERNAL_LOGIN.SUCCESS:
    case actions.SUDO_LOGIN.SUCCESS:
    case actions.REDEEM_INVITE.SUCCESS:
      TokenStore.set(action.payload.token);
      break;

    case actions.SUDO_LOGOUT:
      TokenStore.set(store.getState().stash.user.get('token'));
      break;

    case actions.LOGOUT:
      TokenStore.clear();
      break;
  }

  return next(action);
};
