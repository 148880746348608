import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  label?: string;
  icon?: JSX.Element;
  valueClassName?: string;
  children?: React.ReactNode;
}

export default (props: Props) => (
  <div className={props.className}>
    {props.icon}
    <label>{props.label}</label>
    <div className={classNames('form-item wrapping', props.valueClassName)}>
      {props.children}
    </div>
  </div>
);
