import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import {
  setReportFilterSelection,
  toggleReportFilterSelection,
  toggleReportFilterSelections,
} from '^/actions/ui';
import Dropdown from '^/components/dropdown/Dropdown';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import {
  selectActivitiesMultiItems,
  selectActivitiesSharedWithMeIds,
  selectIsAllActivitiesSelected,
  selectIsAllActivitiesSharedWithMeSelected,
  selectIsAllMyActivitiesSelected,
  selectIsAllOtherActivitiesSelected,
  selectMappedSelectedActivities,
  selectMyActivityIds,
  selectOtherActivityIds,
} from '^/components/reports/admin/AdminReportsPage/ActivitiesDropdown/selectors';
import {
  ACTIVITIES_SHARED_WITH_ME_ID,
  MY_ACTIVITIES_ID,
  OTHER_ID,
  SELECT_ALL_ID,
} from '^/components/reports/admin/AdminReportsPage/constants';
import { Uuid } from '^/reducers/api/types';
import { StoreState } from '^/store';
import {
  selectAllOrgFilterBoolMap,
  selectReportFilterSelectedCount,
  selectReportTableIsLoading,
} from '../selectors';
import { BoolMap } from '../types';

interface StateProps {
  activitiesItems: ReadonlyArray<DropdownOption> | null;
  activitiesMultiItems: ReadonlyArray<DropdownOption>;
  activitiesAmountSelected: number;
  everyActivityIsSelected: boolean;
  everyOtherActivitySelected: boolean;
  everyMyActivitySelected: boolean;
  everyActivitySharedWithMeSelected: boolean;
  allActivities: BoolMap;
  allActivitiesSharedWithMe: ReadonlyArray<Uuid>;
  allMyActivities: ReadonlyArray<Uuid>;
  allOtherActivities: ReadonlyArray<Uuid>;
  isLoadingTable: boolean;
}

interface DispatchProps {
  toggleReportFilterSelection: typeof toggleReportFilterSelection;
  setReportFilterSelection: typeof setReportFilterSelection;
  toggleReportFilterSelections: typeof toggleReportFilterSelections;
}

type Props = StateProps & DispatchProps;

export class ActivitiesDropdown extends React.PureComponent<Props> {
  public render() {
    const {
      activitiesItems,
      activitiesMultiItems,
      activitiesAmountSelected,
      isLoadingTable,
    } = this.props;
    return (
      <Dropdown
        title={i18next.t<string>('Activities')}
        selectedTitle={this.getTitle()}
        isNoneSelected={activitiesAmountSelected === 0}
        emptyTitle={i18next.t<string>('No activities available')}
        selectable
        items={activitiesItems}
        multiSelects={activitiesMultiItems}
        onClick={this.onPress}
        loading={isLoadingTable}
        failed={false}
      />
    );
  }

  private getTitle = () => {
    const { activitiesAmountSelected, activitiesItems } = this.props;
    if (
      activitiesAmountSelected === 0 ||
      (activitiesItems && activitiesAmountSelected === activitiesItems.length)
    ) {
      return i18next.t<string>('All activities');
    }
    return i18next.t<string>('{{count}} activities selected', {
      count: activitiesAmountSelected,
    });
  };

  private onPress = (id: DropdownOption['id']) => {
    const {
      everyActivityIsSelected,
      allActivities,
      allMyActivities,
      allActivitiesSharedWithMe,
      allOtherActivities,
      everyOtherActivitySelected,
      everyMyActivitySelected,
      everyActivitySharedWithMeSelected,
    } = this.props;
    switch (id) {
      case SELECT_ALL_ID:
        if (everyActivityIsSelected) {
          this.props.setReportFilterSelection({
            activities: {},
          });
        } else {
          this.props.setReportFilterSelection({
            activities: allActivities,
          });
        }
        break;
      case OTHER_ID:
        this.props.toggleReportFilterSelections(
          { activities: allOtherActivities },
          !everyOtherActivitySelected
        );
        break;
      case MY_ACTIVITIES_ID:
        this.props.toggleReportFilterSelections(
          { activities: allMyActivities },
          !everyMyActivitySelected
        );
        break;
      case ACTIVITIES_SHARED_WITH_ME_ID:
        this.props.toggleReportFilterSelections(
          { activities: allActivitiesSharedWithMe },
          !everyActivitySharedWithMeSelected
        );
        break;
      default:
        this.props.toggleReportFilterSelection({
          activities: typeof id !== 'string' && id !== null ? `${id}` : id,
        });
        break;
    }
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  activitiesItems: selectMappedSelectedActivities(state),
  activitiesMultiItems: selectActivitiesMultiItems(state),
  activitiesAmountSelected: selectReportFilterSelectedCount(
    state,
    'activities'
  ),
  everyActivityIsSelected: selectIsAllActivitiesSelected(state),
  everyOtherActivitySelected: selectIsAllOtherActivitiesSelected(state),
  everyMyActivitySelected: selectIsAllMyActivitiesSelected(state),
  everyActivitySharedWithMeSelected: selectIsAllActivitiesSharedWithMeSelected(
    state
  ),
  allActivities: selectAllOrgFilterBoolMap(state, 'activities'),
  allMyActivities: selectMyActivityIds(state),
  allActivitiesSharedWithMe: selectActivitiesSharedWithMeIds(state),
  allOtherActivities: selectOtherActivityIds(state),
  isLoadingTable: selectReportTableIsLoading(state),
});

export default connect(mapStateToProps, {
  toggleReportFilterSelection,
  toggleReportFilterSelections,
  setReportFilterSelection,
})(ActivitiesDropdown);
