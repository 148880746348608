import classNames from 'classnames';
import i18next from 'i18next';
import React, {
  ChangeEvent,
  ComponentClass,
  KeyboardEvent,
  PureComponent,
} from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

import { editField, stopEditingField } from '^/actions/actions';
import { saveField } from '^/actions/actionSequences';
import { handleKeys, Key, matchesKey } from '^/keys';
import { StoreState } from '^/store';
import { createResponseComponent } from '../response-hoc';
import { ResponseComponentProps } from '../response-hoc/types';
import Controls from './Controls';
import Input from './Input';
import Placeholder from './Placeholder';
import {
  DispatchProps,
  EditableProps,
  EditableValue,
  OwnProps,
  StateProps,
} from './types';
import {
  isDisplayingPlaceholder,
  isMultiline,
  normalizeValue,
  selectEditing,
} from './utils';

interface State {
  focused: boolean;
  value: string | undefined;
}

export type Props = EditableProps & ResponseComponentProps;

const DEFAULT_FORMAT_VALUE = (value: EditableValue) => value;

export class Editable extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      focused: false,
      value: normalizeValue(props.value),
    };

    if (props.startInEdit) {
      props.onEdit();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: normalizeValue(this.props.value),
      });
    }
  }

  public render() {
    const {
      readOnly,
      editing,
      loading,
      block,
      failed,
      errorMessage,
      hideErrorMessage,
    } = this.props;

    const { focused } = this.state;

    return (
      <div
        className={classNames('editable', this.props.className, {
          editing,
          block,
          focused,
          'read-only': readOnly,
          multiline: isMultiline(this.props),
          'displaying-placeholder': isDisplayingPlaceholder(this.state.value),
          'displaying-error': failed,
        })}
        onClick={!editing && !readOnly ? this.props.onEdit : undefined}
      >
        <div className="editable-wrapper">
          {editing && !readOnly ? (
            <Input
              {...this.props}
              value={this.state.value}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
            />
          ) : (
            <Placeholder
              value={this.state.value}
              placeholder={this.props.placeholder}
              formatDisplay={this.props.formatDisplay}
            />
          )}
          {!readOnly && (
            <Controls
              editing={editing}
              loading={loading}
              onAccept={this.onAccept}
              onCancel={this.onCancel}
              onKeyDownEdit={this.onKeyDownEdit}
              onKeyDownCancel={this.onKeyDownCancel}
              onKeyDownAccept={this.onKeyDownAccept}
            />
          )}
        </div>
        {failed && !hideErrorMessage && (
          <div className="editable-error">
            {errorMessage || i18next.t<string>('Unable to save')}
          </div>
        )}
      </div>
    );
  }

  private onFocus = () => {
    this.setState({ focused: true });
  };

  private onBlur = () => {
    this.setState({ focused: false });
  };

  private onChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLSelectElement>
  ) => {
    const maybeNormalizedValue = this.props.normalizeValue
      ? this.props.normalizeValue(event.currentTarget.value)
      : event.currentTarget.value;

    this.setState({
      value: maybeNormalizedValue,
    });

    if (typeof this.props.onChange === 'function') {
      // This is guaranteed to be the correct type due to the union type of the props,
      // but at this point it appears as an intersection and would require individual
      // type guards that are not necessary
      this.props.onChange(event as any);
    }
  };

  private setValue = (value: EditableValue) => {
    const { formatValue = DEFAULT_FORMAT_VALUE } = this.props;
    return this.setState({
      value: formatValue(normalizeValue(value)),
    });
  };

  private onAccept = () => {
    const { formatValue = DEFAULT_FORMAT_VALUE } = this.props;
    this.setValue(this.state.value);
    this.props.onAccept(formatValue(this.state.value));
  };

  private onCancel = () => {
    this.setValue(this.state.value);
    this.props.onCancel();
  };

  private onKeyDownEdit = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (matchesKey(event, Key.ENTER)) {
      this.props.onEdit();
    }
  };

  private onKeyDownCancel = (event: KeyboardEvent<HTMLAnchorElement>) => {
    handleKeys(event, {
      [Key.ESCAPE]: this.onCancel,
      [Key.ENTER]: this.onCancel,
    });
  };

  private onKeyDownAccept = (event: KeyboardEvent<HTMLAnchorElement>) => {
    handleKeys(event, {
      [Key.ESCAPE]: this.onCancel,
      [Key.ENTER]: this.onAccept,
    });
  };

  private onKeyDown = (
    event:
      | KeyboardEvent<HTMLInputElement>
      | KeyboardEvent<HTMLTextAreaElement>
      | KeyboardEvent<HTMLSelectElement>
  ) => {
    const { formatValue = DEFAULT_FORMAT_VALUE } = this.props;

    handleKeys(event, {
      [Key.ESCAPE]: this.onCancel,
      [Key.ENTER]: () => {
        if (!event.shiftKey) {
          event.preventDefault();
          this.setValue(this.state.value);
          this.props.onAccept(formatValue(this.state.value));
        }
      },
    });
  };
}

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  editing: selectEditing(state, props.fieldName),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<StoreState, undefined, AnyAction>,
  props: OwnProps
): DispatchProps => {
  const onEdit = () => dispatch(editField(props.fieldName));
  const onAccept = (value: string | undefined) =>
    dispatch(saveField(props.onSave, props.fieldName, value));
  const onCancel = () => dispatch(stopEditingField(props.fieldName));

  return {
    onEdit,
    onCancel,
    onAccept,
  };
};

export const ConnectedEditable = connect(
  mapStateToProps,
  mapDispatchToProps
  // Cast necessary due to React 15 optional props type
)(Editable as ComponentClass<Props>);

export default createResponseComponent(ConnectedEditable);
