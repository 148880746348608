/* eslint-disable no-fallthrough */
import _ from 'underscore';
import { Map, List, fromJS } from 'immutable';

import { getIn } from '../utils';
import * as actions from '../actions/actions';
import * as collections from '../actions/collections';
import composeReducers from './composeReducers';

function collectionResult(collectionMap, action) {
  let collectionItems = null;
  if (action.type === collections.GET_COLLECTION.SUCCESS) {
    let results = null;
    if (action.meta.simple) {
      results = fromJS(action.payload);
    } else {
      results = fromJS(getIn(action, ['payload', 'results'], []));
    }
    if (action.meta.shouldAppend) {
      collectionItems = collectionMap.get('items', List()).concat(results);
    } else {
      collectionItems = results;
    }
  } else {
    // GET_COLLECTION.REQUEST
    const metaUnchanged =
      action.meta.search === collectionMap.get('searchString') &&
      action.meta.ordering === collectionMap.get('ordering') &&
      _.isEqual(action.meta.filters, collectionMap.get('filters')) &&
      action.meta.page === collectionMap.get('page');

    if (action.meta.shouldAppend || metaUnchanged) {
      collectionItems = collectionMap.get('items');
    }
  }

  const count = getIn(action, ['payload', 'count']);
  const newState = collectionMap
    .set('searchString', action.meta.search)
    .set('ordering', action.meta.ordering)
    .set('filters', action.meta.filters)
    .set('page', action.meta.page)
    .set(
      'hasMore',
      Boolean(
        getIn(action, ['payload', 'next']) || collectionMap.get('hasMore')
      )
    )
    .set(
      'count',
      typeof count === 'number' ? count : collectionMap.get('count') || 0
    );

  if (collectionItems) {
    return newState.set('items', collectionItems);
  }
  return newState;
}

function userCollectionsReducer(state = Map(), action) {
  switch (action.type) {
    case actions.INVITE_USER.SUCCESS:
      if (action.payload) {
        return state.updateIn(
          ['users', 'items'],
          users =>
            users &&
            users.map(user =>
              user.get('id') === action.payload.user.id
                ? fromJS(action.payload.user)
                : user
            )
        );
      }
      return state;

    case collections.UPDATE_ITEM.SUCCESS:
      if (
        action.payload &&
        action.payload.organisation &&
        action.meta.collectionName === 'users'
      ) {
        return state.updateIn(
          ['users', 'items'],
          users =>
            users &&
            users.map(user =>
              user.getIn(['organisation', 'id']) ===
              action.payload.organisation.id
                ? user.set('organisation', fromJS(action.payload.organisation))
                : user
            )
        );
      }
      return state;
    default:
      return state;
  }
}

function activityCollectionsReducer(state = Map(), action) {
  switch (action.type) {
    case actions.ADD_USERS_TO_ACTIVITY.SUCCESS:
    case actions.REMOVE_USER_FROM_ACTIVITY.SUCCESS:
      if (action.payload) {
        const activities = state.getIn(['activities', 'items']);
        if (activities) {
          return state.setIn(
            ['activities', 'items'],
            activities.map(each =>
              each.get('id') === action.payload.id
                ? each.set('users', fromJS(action.payload.users))
                : each
            )
          );
        }
      }
    case actions.ADD_REPORT_RECIPIENT.SUCCESS:
    case actions.REMOVE_REPORT_RECIPIENT.SUCCESS:
      const { id, report_recipients } = action.payload;
      return state.updateIn(
        ['activities', 'items'],
        activities =>
          activities &&
          activities.map(activity =>
            activity.update(
              'users',
              users =>
                users &&
                users.map(user =>
                  user.get('id') === id
                    ? user.set('report_recipients', fromJS(report_recipients))
                    : user
                )
            )
          )
      );

    default:
      return state;
  }
}

function myActivityCollectionsReducer(state = Map(), action) {
  const activities = state.getIn(['myActivities', 'items']);

  switch (action.type) {
    case actions.START_ACTIVITY_PRODUCT_VERSION_SESSION.SUCCESS:
      if (action.payload) {
        if (activities) {
          return state.setIn(
            ['myActivities', 'items'],
            activities.map(activity => {
              if (activity.get('id') === action.payload.activity) {
                return activity.update(
                  'activity_product_version_items',
                  activityProductVersions =>
                    activityProductVersions.map(activityProductVersion =>
                      activityProductVersion.get('product_version') ===
                      action.payload.product_version
                        ? activityProductVersion.update(
                            'activity_product_version_sessions',
                            sessions => sessions.push(Map(action.payload))
                          )
                        : activityProductVersion
                    )
                );
              }
              return activity;
            })
          );
        }
      }
      return state;

    case actions.ADD_RATER_TO_ACTIVITY.SUCCESS:
    case actions.REMOVE_RATER_FROM_ACTIVITY.SUCCESS:
    case actions.REMOVE_LINE_MANAGER_FROM_ACTIVITY.SUCCESS:
      if (action.payload && activities) {
        return state.setIn(
          ['myActivities', 'items'],
          activities.map(activity =>
            activity.get('id') === action.payload.activity
              ? activity.setIn(
                  ['self_users', 0, 'raters'],
                  fromJS(action.payload.raters)
                )
              : activity
          )
        );
      }
      return state;

    case actions.SUBMIT_RATERS.SUCCESS:
      if (action.payload && activities) {
        return state.setIn(
          ['myActivities', 'items'],
          activities.map(activity =>
            activity.get('id') === action.payload.activity
              ? activity.set('raters_submitted', true)
              : activity
          )
        );
      }
      return state;

    case actions.UPDATE_RATER.SUCCESS:
      const setRater = (raters, raterId, approved_by_line_manager) =>
        raters &&
        raters.map(rater =>
          rater.getIn(['rater', 'id']) === raterId
            ? rater.set('approved_by_line_manager', approved_by_line_manager)
            : rater
        );

      const updateActivityRaters = (activity, userId, raterId, newStatus) => {
        const updateRatersInUsers = users =>
          users &&
          users.map(user =>
            user.getIn(['user', 'id']) === userId
              ? user.update('raters', raters =>
                  setRater(raters, raterId, newStatus)
                )
              : user
          );
        return activity
          .update('direct_reports', updateRatersInUsers)
          .update('users', updateRatersInUsers);
      };

      if (action.payload && activities) {
        const { activityId, userId, raterId } = action.meta;
        const newStatus = action.payload.approved_by_line_manager;
        return state.updateIn(['myActivities', 'items'], _activities =>
          _activities.map(activity =>
            activity.get('id') === activityId
              ? updateActivityRaters(activity, userId, raterId, newStatus)
              : activity
          )
        );
      }
    default:
      return state;
  }
}

function collectionsReducer(state = Map(), action) {
  function updateItems(updater) {
    return state.updateIn(
      [action.meta.collectionName, 'items'],
      List(),
      updater
    );
  }

  switch (action.type) {
    case collections.GET_COLLECTION.REQUEST:
    case collections.GET_COLLECTION.SUCCESS:
      const existing = state.get(action.meta.collectionName, Map());
      return state.set(
        action.meta.collectionName,
        collectionResult(existing, action)
      );

    case collections.ADD_TO_COLLECTION.SUCCESS:
      return updateItems(items =>
        items.unshift(fromJS(action.payload))
      ).updateIn(
        [action.meta.collectionName, 'count'],
        value => (value || 0) + 1
      );

    case collections.UPDATE_ITEM.SUCCESS: {
      const id = action.payload.id;
      return updateItems(items =>
        items.map(item =>
          item.get('id') === id ? item.merge(fromJS(action.payload)) : item
        )
      );
    }

    case collections.DELETE_ITEM.REQUEST: {
      const id = action.meta.id;
      return updateItems(items => items.filter(item => item.get('id') !== id));
    }
    case collections.CLEAR_COLLECTION: {
      return state.delete(action.payload);
    }

    case actions.SORT_SELECTION: {
      return updateItems(items =>
        items.sortBy(item => item.get(action.payload.value))
      );
    }
    default:
      return state;
  }
}

function discountBandsCollectionsReducer(state = Map(), action) {
  switch (action.type) {
    case collections.ADD_TO_COLLECTION.SUCCESS:
      if (
        action.payload &&
        action.meta.collectionName === 'bulkDiscountBands'
      ) {
        const id = action.payload.bulk_discount_collection;
        return state.updateIn(['bulkDiscountBandCollections', 'items'], items =>
          items.map(collection =>
            collection.get('id') === id
              ? collection.update(
                  'bulkdiscountband_set',
                  bulkdiscountband_set =>
                    bulkdiscountband_set.push(fromJS(action.payload))
                )
              : collection
          )
        );
      }

    case collections.UPDATE_ITEM.SUCCESS:
      if (
        action.payload &&
        action.meta.collectionName === 'bulkDiscountBands'
      ) {
        const id = action.payload.id;
        return state.updateIn(['bulkDiscountBandCollections', 'items'], items =>
          items.map(collection =>
            collection.update('bulkdiscountband_set', bands =>
              bands
                .map(band =>
                  band.get('id') === id ? fromJS(action.payload) : band
                )
                .sortBy(set => set.get('discount_from'))
            )
          )
        );
      }

    case collections.DELETE_ITEM.SUCCESS:
      if (action.meta.collectionName === 'bulkDiscountBands') {
        const id = action.meta.id;
        return state.updateIn(['bulkDiscountBandCollections', 'items'], items =>
          items.map(collection =>
            collection.update('bulkdiscountband_set', bands =>
              bands.filter(band => band.get('id') !== id)
            )
          )
        );
      }

    default:
      return state;
  }
}

function creditCollectionsReducer(state = Map(), action) {
  switch (action.type) {
    case collections.UPDATE_ITEM.SUCCESS:
      if (action.meta.collectionName === 'creditPots' && action.payload) {
        const id = action.payload.id;
        return state.updateIn(
          ['orgCreditHistory', 'items'],
          items =>
            items &&
            items.map(log =>
              log.update('credit_pot', pot =>
                pot && pot.get('id') === id ? fromJS(action.payload) : pot
              )
            )
        );
      }

    default:
      return state;
  }
}

function activityUsersCollectionsReducer(state = Map(), action) {
  switch (action.type) {
    case actions.GET_ORGANISATION_ALL_REPORTS.SUCCESS:
    case actions.CREATE_REPORTS.SUCCESS:
      return action.payload.reduce(
        (_state, report) =>
          _state.updateIn(
            [
              collections.getActivityUsersCollectionName(report.activity.id),
              'items',
            ],
            items =>
              items &&
              items.map(activityUser =>
                activityUser.get('session_id') === report.session
                  ? activityUser.set('has_comparison_group', true)
                  : activityUser
              )
          ),
        state
      );

    default:
      return state;
  }
}

export default composeReducers(
  [
    discountBandsCollectionsReducer,
    collectionsReducer,
    userCollectionsReducer,
    activityCollectionsReducer,
    myActivityCollectionsReducer,
    creditCollectionsReducer,
    activityUsersCollectionsReducer,
  ],
  Map()
);
