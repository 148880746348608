import i18next from 'i18next';
import { createSelector } from 'reselect';

import { shareAccess } from '^/capabilities';
import {
  ACTIVITIES_SHARED_WITH_ME_ID,
  MY_ACTIVITIES_ID,
  OTHER_ID,
  SELECT_ALL_ID,
} from '^/components/reports/admin/AdminReportsPage/constants';
import { Activity } from '^/reducers/api/types';
import { selectOrgFilters } from '^/selectors';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';
import {
  createIsAllSelectedSelector,
  selectMappedSelectedOrgFilter,
} from '../selectors';
import { getIdArray } from '../utils';

export const selectOrgFiltersActivities = createSelector(
  selectOrgFilters,
  orgFilters => orgFilters && orgFilters.activities
);

export const selectMappedSelectedActivities = (state: StoreState) =>
  selectMappedSelectedOrgFilter(state, 'activities') as ReadonlyArray<
    Activity & Readonly<{ selected: boolean }>
  >;

const selectAllMyActivities = createSelector(
  selectMappedSelectedActivities,
  activities => (activities ? activities.filter(activity => activity.mine) : [])
);

const selectAllActivitiesSharedWithMe = createSelector(
  selectMappedSelectedActivities,
  (state: StoreState) => selectUserCapability(state, shareAccess()),
  (activities, userHasShareAccess) =>
    userHasShareAccess && activities
      ? activities.filter(activity => activity.shared_with_me)
      : []
);

const selectAllOtherActivities = createSelector(
  selectMappedSelectedActivities,
  (state: StoreState) => selectUserCapability(state, shareAccess()),
  (activities, userHasShareAccess) =>
    activities
      ? activities.filter(
          activity =>
            !((userHasShareAccess && activity.shared_with_me) || activity.mine)
        )
      : []
);

export const selectIsAllActivitiesSelected = createIsAllSelectedSelector(
  selectMappedSelectedActivities
);

export const selectIsAllMyActivitiesSelected = createIsAllSelectedSelector(
  selectAllMyActivities
);

export const selectIsAllActivitiesSharedWithMeSelected = createIsAllSelectedSelector(
  selectAllActivitiesSharedWithMe
);

export const selectIsAllOtherActivitiesSelected = createIsAllSelectedSelector(
  selectAllOtherActivities
);

export const selectActivitiesSharedWithMeIds = createSelector(
  selectAllActivitiesSharedWithMe,
  getIdArray
);

export const selectMyActivityIds = createSelector(
  selectAllMyActivities,
  getIdArray
);

export const selectOtherActivityIds = createSelector(
  selectAllOtherActivities,
  getIdArray
);

export const selectActivitiesMultiItems = createSelector(
  selectIsAllActivitiesSelected,
  selectIsAllMyActivitiesSelected,
  selectIsAllActivitiesSharedWithMeSelected,
  selectIsAllOtherActivitiesSelected,
  selectAllMyActivities,
  selectAllActivitiesSharedWithMe,
  selectAllOtherActivities,
  selectMappedSelectedActivities,

  (
    allActivitiesSelected,
    allMyActivitiesSelected,
    allSharedWithMeSelected,
    allOtherSelected,
    allMyActivities,
    allSharedWithMe,
    allOtherActivities,
    allActivities
  ) => {
    const itemArray = [];
    if (allActivities.length) {
      itemArray.push({
        id: SELECT_ALL_ID,
        name: i18next.t<string>('Select all'),
        selected: allActivitiesSelected,
      });
    }
    if (allMyActivities.length) {
      itemArray.push({
        id: MY_ACTIVITIES_ID,
        name: i18next.t<string>('My activities'),
        selected: allMyActivitiesSelected,
      });
    }
    if (allSharedWithMe.length) {
      itemArray.push({
        id: ACTIVITIES_SHARED_WITH_ME_ID,
        name: i18next.t<string>('Activities shared with me'),
        selected: allSharedWithMeSelected,
      });
    }
    if (
      allOtherActivities.length &&
      allOtherActivities.length !== allActivities.length
    ) {
      itemArray.push({
        id: OTHER_ID,
        name: i18next.t<string>('Other'),
        selected: allOtherSelected,
      });
    }
    return itemArray;
  }
);
