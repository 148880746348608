import React, { PureComponent, ReactElement } from 'react';

import * as DragAndDropGroupContextInstance from './DragAndDropGroupContextInstance';
import * as DragAndDropContextInstance from './DragAndDropHandlerContextInstance';

interface OwnProps<Item> {
  group: string;
  tag?: 'div' | 'span' | 'ul';
  className?: string;
  children: (item: Item, index: number) => ReactElement;
}

type Props<Item> = OwnProps<Item>;

class DragAndDropGroup<Item> extends PureComponent<Props<Item>> {
  public render() {
    const { group, tag: Tag = 'div', className, children } = this.props;

    return (
      <DragAndDropContextInstance.Consumer>
        {context => {
          if (!context.groups) {
            throw new Error(
              'DragAndDropGroup rendered outside the context of a DragAndDropHandler'
            );
          }

          return (
            <DragAndDropGroupContextInstance.Provider
              context={{ ...context, group: this.props.group }}
            >
              <Tag className={className}>
                {context.groups[group].map(children)}
              </Tag>
            </DragAndDropGroupContextInstance.Provider>
          );
        }}
      </DragAndDropContextInstance.Consumer>
    );
  }
}

export default DragAndDropGroup;
