/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
// required for IE11
import 'core-js/es/symbol';
import 'core-js/es/promise';

import React from 'react'; // eslint-disable-line no-unused-vars
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { faCoffee } from '@fortawesome/pro-light-svg-icons/faCoffee';
import { faStoreAlt } from '@fortawesome/pro-light-svg-icons/faStoreAlt';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUsersClass } from '@fortawesome/pro-light-svg-icons/faUsersClass';
import { faArrowToTop } from '@fortawesome/pro-light-svg-icons/faArrowToTop';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faUserChart } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faCoins } from '@fortawesome/pro-light-svg-icons/faCoins';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faFileEdit } from '@fortawesome/pro-light-svg-icons/faFileEdit';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faFolders } from '@fortawesome/pro-light-svg-icons/faFolders';
import { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faQuestionSquare } from '@fortawesome/pro-light-svg-icons/faQuestionSquare';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons/faCreditCardFront';
import { Router, Route } from 'react-router';
import { Provider } from 'react-redux';

import { PulseIconCircleOutline } from '^/components/PulseIconCircleOutline';
import i18next from '^/locale';
import { resetResponse, logoutSkippingRedirect } from '^/actions/actions';
import { setUiLanguage } from './actions/ui';
import { openSudoModal } from '^/actions/modals';
import {
  getAndCheckUserInfo,
  checkUserInfoAndLogin,
  loadAndSetActiveLanguage,
  logoutAndResetInteractions,
} from '^/actions/actionSequences';
import '^/stubConsoleIfUnavailable';
import App from '^/containers/App';
import {
  administerActivities,
  administerAnyOrganisations,
  administerProductVersions,
  administerOrganisations,
  administerOwnOrganisation,
  administerPulses,
  administerUsers,
  createAndListPulses,
  viewDataExports,
  viewDataAnalytics,
  accessProductAdmin,
  seeShop,
  seeCredits,
  sudoAsUser,
  purchaseReports,
  interactWithShop,
  seeDashboardNav,
  seeHomeNav,
  switchLanguage,
  can,
} from '^/capabilities';
import store, { history } from '^/store';
import TokenStore from '^/TokenStore';
import { selectAvailableProductPlusItems } from '^/components/shop/basket/selectors';
import { selectUserOrgCredit } from '^/selectors/user';
import {
  PAGE_PATH,
  PAGE_PATH_FULL,
  LOGOUT_PATH,
  DEFAULT_LANGUAGE_CODE,
  TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS,
} from '^/constants/routes';
import Page from '^/components/Page';
import Login from '^/components/Login';
import ForgotPassword from '^/components/ForgotPassword';
import ResetPassword from '^/components/ResetPassword';
import RedeemInvite from '^/components/RedeemInvite';
import RedeemPrepay from '^/components/RedeemPrepay';
import RedeemAnonymous from '^/components/RedeemAnonymous';
import NotFoundPage from '^/components/NotFoundPage';
import ProfilePage from '^/components/profile/ProfilePage';
import SupportHubPage from '^/components/support-hub/SupportHubPage';
import UsersPage from '^/components/users/UsersPage';
import GroupsPage from '^/components/groups/GroupsPage';
import CreditHistoryPage from '^/components/credits/CreditHistoryPage';
import CreditAccountHistory from '^/components/credits/CreditAccountHistory';
import BasketPage from '^/components/shop/basket/BasketPage';
import UserImportsPage from '^/components/users/UserImportsPage';
import OrganisationsPage from '^/components/organisations/OrganisationsPage';
import OrganisationEditPage from '^/components/organisations/OrganisationEditPage';
import OrganisationViewPage from '^/components/organisations/OrganisationViewPage';
import ActivitiesPage from '^/components/activities/ActivitiesPage';
import ProductVersionsPage from '^/components/productVersions/ProductVersionsPage';
import ActivityPage from '^/components/activities/ActivityPage';
import CreateActivityPage from '^/components/activities/CreateActivityPage';
import ActivityHub from '^/components/activities/ActivityHub';
import ActivityRatersPage from '^/components/activities/ActivityRatersPage';
import AccountSignUp from '^/components/organisations/AccountSignUp';
import SignUpChoice from '^/components/organisations/SignUpChoice';
import VerifyValidationCode from '^/components/VerifyValidationCode';
import StyleGuide from '^/components/styleguide/StyleGuide';
import ShopPage from '^/components/shop/ShopPage';
import Purchases from '^/components/shop/Purchases';
import ReportsPage from '^/components/reports/admin/ReportsPage';
import RefreshPage from '^/components/RefreshPage';
import HomePage from '^/components/HomePage';
import AnswerProductVersion from '^/components/dashboard/enduser/answer/AnswerProductVersion';
import ManageRatersPage from '^/components/dashboard/enduser/manage_raters/ManageRatersPage';
import ApproveRatersPage from '^/components/dashboard/enduser/ApproveRatersPage';
import ApproveUserRatersPage from '^/components/dashboard/enduser/ApproveUserRatersPage';
import EndUserDashboard from '^/components/dashboard/enduser/Dashboard';
import DataExportsPage from '^/components/analytics/DataExportsPage';
import DataAnalyticsPage from '^/components/analytics/DataAnalyticsPage';
import DataAnalyticsChartsPage from '^/components/analytics/DataAnalyticsChartsPage';
import Contact from '^/components/legal/Contact';
import SingleUserPage from '^/components/users/SingleUserPage';
import ExternalDashboard from '^/components/dashboard/external/Dashboard';
import ExternalAuthenticate from '^/components/dashboard/external/Authenticate';
import SettingsPage from '^/components/settings/SettingsPage';
import PulsePage from '^/components/pulses/PulsePage';
import PulsesPage from '^/components/pulses/PulsesPage';
import { PulsesUsersListPage } from '^/components/pulses/PulsesUsersListPage';
import PulseHub from '^/components/pulses/PulseHub';
import CreatePulsePage from '^/components/pulses/CreatePulsePage';
import PulseRatePage from '^/components/pulses/PulseRatePage';
import UserPulsePage from '^/components/pulses/UserPulsePage';
import RemoveRaterPage from '^/components/pulses/RemoveRaterPage';
import { PulseTermsAndConditions } from './components/pulses/PulseTermsAndConditions';
import { DataPrivacyPolicy } from './components/legal/DataPrivacyPolicy';
import { TermsAndConditions } from './components/legal/TermsAndConditions';
import ForgotPasswordPulseWrapper from '^/components/ForgotPasswordPulseWrapper';
import ConsentPage from './components/consent/ConsentPage';
import { constructUrl, getLangCodeWithFallback } from './utils-ts';

const initialGetAndSetUiLanguage = () => {
  const browserLang =
    window.navigator.language || window.navigator.languageCode;
  const languageCode = getLangCodeWithFallback(browserLang);
  store.dispatch(setUiLanguage(languageCode));
};

const switchLanguageForPaths = (nextState, replace) => {
  return new Promise(function(resolve, reject) {
    const uiLanguage = store.getState().ui.get('uiLanguage');
    const url = constructUrl(nextState.location);
    if (
      Object.keys(TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS).includes(
        nextState.location.pathname
      )
    ) {
      const userProfile = store.getState().userProfile;
      if (
        TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS[nextState.location.pathname]
      ) {
        if (userProfile && can(userProfile, switchLanguage())) {
          store.dispatch(loadAndSetActiveLanguage(uiLanguage, url));
          resolve();
          return;
        }
        resolve();
        return;
      }
      store.dispatch(loadAndSetActiveLanguage(uiLanguage, url));
      resolve();
      return;
    }
    store.dispatch(loadAndSetActiveLanguage(DEFAULT_LANGUAGE_CODE, url));
    resolve();
    return;
  });
};

const switchLanguageForUrl = (nextState, replace) => {
  store.dispatch(loadAndSetActiveLanguage(nextState.params.langCode));
};

const triggerSwitchLanguage = () => {
  const uiLanguage = store.getState().ui.get('uiLanguage');
  store.dispatch(loadAndSetActiveLanguage(uiLanguage));
};

const resetLanguage = () => {
  store.dispatch(loadAndSetActiveLanguage(DEFAULT_LANGUAGE_CODE));
};

function loadUserInfo(updateTranslation, nextPath) {
  const state = store.getState();
  if (state.user.get('token')) {
    store.dispatch(
      getAndCheckUserInfo(
        updateTranslation,
        nextPath,
        state.ui.get('uiLanguage')
      )
    );
  }
}

function logUserOut() {
  store.dispatch(logoutAndResetInteractions());
}

function trySkipLogin(nextState, replace) {
  const token = TokenStore.get();
  if (token) {
    const userPulseId = nextState.location.query['userpulse'];
    const redirectPath = userPulseId
      ? `/page/user-pulses/${userPulseId}`
      : '/page/home';
    store.dispatch(checkUserInfoAndLogin(redirectPath));
  }
}

function ensureLoggedOut() {
  const token = TokenStore.get();
  if (token) {
    store.dispatch(logoutSkippingRedirect());
  }
}

function resetResponseFor(key) {
  store.dispatch(resetResponse(key));
}

function onClickSudoNav() {
  store.dispatch(openSudoModal());
}

const root = createRoot(document.getElementById('app'));

root.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={App} onEnter={initialGetAndSetUiLanguage}>
          <Route onEnter={triggerSwitchLanguage} onLeave={resetLanguage}>
            <Route path="login" component={Login} onEnter={trySkipLogin} />
            <Route path={LOGOUT_PATH} onEnter={logUserOut} />
            <Route
              path="forgotPassword"
              component={ForgotPassword}
              onEnter={resetResponseFor.bind(null, 'forgotPassword')}
            />
            <Route
              path="resetPassword/:uuid/:token"
              component={ResetPassword}
              onEnter={resetResponseFor.bind(null, 'resetPassword')}
            />
            <Route
              path="invite/:id/redeem/"
              component={RedeemInvite}
              onEnter={resetResponseFor.bind(null, 'redeemInvite')}
            />
            <Route
              path="prepay/:activity"
              component={RedeemPrepay}
              onEnter={ensureLoggedOut}
            />
            <Route
              path="verify/:validationCode"
              component={VerifyValidationCode}
              onEnter={resetResponseFor.bind(null, 'verifyValidationCode')}
            />
            <Route
              path="prepay/login/:activity"
              component={Login}
              onEnter={trySkipLogin}
            />
            <Route path="consent" component={ConsentPage} />
          </Route>
          <Route
            path="forgotPassword/pulse"
            component={ForgotPasswordPulseWrapper}
            onEnter={resetResponseFor.bind(null, 'forgotPassword')}
          />
          <Route
            path="signup"
            component={SignUpChoice}
            onEnter={ensureLoggedOut}
          />
          <Route
            path="accountSignup"
            component={AccountSignUp}
            onEnter={ensureLoggedOut}
          />
          <Route
            path="anonymous/:activity"
            component={RedeemAnonymous}
            onEnter={ensureLoggedOut}
          />
          <Route
            path="external/:user/:activity/:raterFor"
            component={ExternalAuthenticate}
            onEnter={ensureLoggedOut}
          />
          <Route
            path="pulse/rate/:userPulseRaterId"
            component={PulseRatePage}
          />
          <Route
            path="pulse/rate/:userPulseRaterId/remove"
            component={RemoveRaterPage}
          />

          <Route
            path={PAGE_PATH}
            component={Page}
            onEnter={(nextState, replace) => {
              loadUserInfo(
                () => switchLanguageForPaths(nextState, replace),
                constructUrl(nextState.location)
              );
            }}
            fullpath={PAGE_PATH_FULL}
            onLeave={resetLanguage}
            onChange={(nextState, replace) =>
              nextState.location.action === 'PUSH' &&
              switchLanguageForPaths(nextState, replace)
            }
          >
            <Route
              path="external/:activity/:raterFor"
              component={ExternalDashboard}
            />
            <Route
              path="dashboard/raters/approve/:activity"
              component={ApproveRatersPage}
            />
            <Route
              path="dashboard/raters/approve/:activity/:user"
              component={ApproveUserRatersPage}
            />
            <Route
              path="dashboard/raters/:activity"
              component={ManageRatersPage}
            />
            <Route
              path="dashboard/:activity/:survey"
              component={AnswerProductVersion}
            />

            <Route path="home" component={HomePage} />

            {/* Nav items */}
            <Route
              path="dashboard"
              component={EndUserDashboard}
              displayNav
              title={i18next.t('Dashboard')}
              image={faHome}
              displayNavCapability={seeDashboardNav}
            />
            <Route displayNav title={i18next.t('Pulse Check')} pulseIcon>
              <Route
                path="pulses/create"
                component={CreatePulsePage}
                displayNav
                image={faPlus}
                title={i18next.t('Create Pulse Check')}
                capability={createAndListPulses}
              />
              <Route
                path="pulses/view"
                component={PulsesUsersListPage}
                displayNav
                pulseIcon
                title={i18next.t('Manage Pulse Checks')}
                capability={createAndListPulses}
              />
            </Route>
            <Route
              displayNav
              title={i18next.t('Activities')}
              image={faClipboardList}
            >
              <Route
                path="activities/create"
                component={CreateActivityPage}
                displayNav
                title={i18next.t('Create activity')}
                image={faPlus}
                capability={administerActivities}
              />
              <Route
                path="activities/view"
                component={ActivitiesPage}
                displayNav
                title={i18next.t('Manage activities')}
                image={faClipboardList}
                capability={administerActivities}
              />
            </Route>
            <Route displayNav title={i18next.t('Users')} image={faUsers}>
              <Route
                path="individuals/view"
                component={UsersPage}
                displayNav
                title={i18next.t('Individuals')}
                image={faUser}
                capability={administerUsers}
              />

              <Route
                path="groups/view"
                component={GroupsPage}
                displayNav
                title={i18next.t('Groups')}
                image={faUsersClass}
                capability={administerUsers}
              />

              <Route
                path="users/imports"
                component={UserImportsPage}
                displayNav
                title={i18next.t('User imports')}
                image={faArrowToTop}
                capability={administerUsers}
              />
            </Route>

            <Route
              path="reports"
              component={ReportsPage}
              displayNav
              title={i18next.t('Reports')}
              image={faFileInvoice}
              capability={purchaseReports}
            />

            <Route
              displayNav
              title={i18next.t('Analytics')}
              image={faAnalytics}
            >
              <Route
                path="data-analytics"
                component={DataAnalyticsPage}
                displayNav
                title={i18next.t('Data analytics')}
                image={faUserChart}
                capability={viewDataAnalytics}
              />

              <Route
                path="data-downloads"
                component={DataExportsPage}
                displayNav
                title={i18next.t('Data downloads')}
                image={faArrowToBottom}
                capability={viewDataExports}
              />

              <Route
                path="data-analytics-charts"
                component={DataAnalyticsChartsPage}
                title={i18next.t('Data analytics chart')}
                image={faUserChart}
                capability={viewDataAnalytics}
              />
            </Route>

            <Route
              displayNav
              title={i18next.t('Shop')}
              image={faStoreAlt}
              getBadge={selectAvailableProductPlusItems}
            >
              <Route
                path="shop"
                component={ShopPage}
                displayNav
                capability={seeShop}
                title={i18next.t('Shop')}
                image={faStoreAlt}
              />
              <Route
                path="purchases"
                component={Purchases}
                displayNav
                capability={seeShop}
                title={i18next.t('Purchases')}
                image={faCreditCardFront}
              />
            </Route>

            <Route
              path="basket"
              capability={interactWithShop}
              component={BasketPage}
            />

            <Route
              path="credits/"
              component={CreditHistoryPage}
              displayNav
              title={i18next.t('Credits')}
              image={faCoins}
              capability={seeCredits}
              getBadge={selectUserOrgCredit}
            />

            <Route displayNav title={i18next.t('Admin')} image={faFileEdit}>
              <Route
                path="accounts"
                component={OrganisationsPage}
                displayNav
                title={i18next.t('Accounts')}
                image={faBuilding}
                capability={administerOrganisations}
              />

              <Route
                path="settings/"
                component={SettingsPage}
                displayNav
                title={i18next.t('Settings')}
                image={faCogs}
                capability={administerOrganisations}
              />
            </Route>

            <Route displayNav title={i18next.t('Profile')}>
              <Route
                displayNav
                showLoggedInUser
                path="support-hub"
                title={i18next.t('Help & Support')}
                component={SupportHubPage}
                image={faQuestionSquare}
                capability={administerAnyOrganisations}
              />

              <Route
                displayNav
                path="profile"
                title={i18next.t('My profile')}
                component={ProfilePage}
                onEnter={() => resetResponseFor.bind(null, 'updateProfile')}
                image={faUserCircle}
              />

              <Route
                displayNav
                path="my-account"
                title={i18next.t('Account details')}
                image={faBuilding}
                component={OrganisationViewPage}
                capability={administerOwnOrganisation}
              />

              <Route
                displayNav
                title={i18next.t('View as another')}
                image={faEye}
                onNavClick={onClickSudoNav}
                capability={sudoAsUser}
              />

              {/* This route exists only to add the logout link in the navigation */}
              <Route
                displayNav
                title={i18next.t('Logout')}
                image={faSignOut}
                onNavClick={logUserOut}
              />
            </Route>

            <Route
              path="activities/create"
              component={CreateActivityPage}
              title={i18next.t('Create activity')}
              image={faCoffee}
              capability={administerActivities}
            />
            <Route
              path="activities/hub"
              component={ActivityHub}
              title={i18next.t('Create activity')}
              image={faCoffee}
              capability={administerActivities}
            />
            <Route
              path="psychometrics"
              component={ProductVersionsPage}
              title={i18next.t('Psychometrics')}
              image={faCoffee}
              capability={administerProductVersions}
            />
            <Route
              path="accounts/:account/"
              component={OrganisationEditPage}
              capability={administerOrganisations}
            />
            <Route
              path="purchases/:filter"
              component={Purchases}
              capability={seeShop}
            />

            <Route
              path="credits/:account/"
              component={CreditAccountHistory}
              capability={seeCredits}
            />

            <Route path="refresh" component={RefreshPage} />

            {/* Require capabilities */}
            <Route
              path="users/imports/:filter"
              component={UserImportsPage}
              capability={administerUsers}
            />
            <Route
              path="individuals/view/:filter"
              component={UsersPage}
              capability={administerUsers}
            />
            <Route
              path="individuals/:id"
              component={SingleUserPage}
              capability={administerUsers}
            />
            <Route
              path="accounts/:filter"
              component={OrganisationsPage}
              capability={administerOrganisations}
            />
            <Route
              path="activities/view/:filter"
              component={ActivitiesPage}
              capability={administerActivities}
            />
            <Route
              path="activities/:id"
              component={ActivityPage}
              capability={administerActivities}
            />
            <Route
              path="activities/:id/raters"
              component={ActivityRatersPage}
              capability={administerActivities}
            />
            <Route
              path="pulses/hub"
              component={PulseHub}
              title={i18next.t('Pulsing Hub')}
              capability={createAndListPulses}
            />
            <Route
              path="pulses/view"
              component={PulsesUsersListPage}
              capability={createAndListPulses}
            />
            <Route
              path="pulses/view/:filter"
              component={PulsesPage}
              capability={createAndListPulses}
            />
            <Route
              path="pulses/create"
              component={CreatePulsePage}
              title={i18next.t('Create pulse')}
              capability={createAndListPulses}
            />
            <Route
              path="pulses/:id"
              component={PulsePage}
              capability={administerPulses}
            />
            <Route path="user-pulses/:id" component={UserPulsePage} />

            {/* Legal */}
            <Route path="contact" component={Contact} />
            <Route
              path="pulse-terms-and-conditions"
              component={PulseTermsAndConditions}
            />
            <Route path="styleguide" component={StyleGuide} />
            <Route path="styleguide/:page" component={StyleGuide} />
          </Route>
          <Route
            path=":langCode/"
            onEnter={switchLanguageForUrl}
            onLeave={resetLanguage}
          >
            <Route path="data-privacy-policy" component={DataPrivacyPolicy} />
            <Route path="terms-and-conditions" component={TermsAndConditions} />
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Route>
      </Router>
    </Provider>
  </I18nextProvider>
);
