import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';

export default class BulkDiscountsTable extends PureComponent {
  render() {
    const { discountBands } = this.props;
    return (
      <div>
        <b>{i18next.t('Bulk purchase credits to get a discount.')}</b>
        <table className="inline-table">
          <thead>
            <tr>
              <th>{i18next.t('Credits')}</th>
              <th>{i18next.t('Saving')}</th>
            </tr>
          </thead>
          <tbody>
            {discountBands
              .zip(discountBands.slice(1).push(null))
              .map(([band, nextBand], idx) => (
                <tr key={idx}>
                  <td>
                    {band.get('discount_from')}
                    {nextBand ? `–${nextBand.get('discount_from') - 1}` : '+'}
                  </td>
                  <td>{band.get('percentage')}%</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
