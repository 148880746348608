import {
  faChevronDown,
  faChevronUp,
  faSpinnerThird,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { PureComponent } from 'react';
import uuid from 'uuid';

import DropdownItem, {
  DropdownOption,
} from '^/components/dropdown/DropdownItem';
import { handleKeys, Key } from '^/keys';
import { ResponseComponentProps } from '../response-hoc/types';
import SearchBar from '../Searchbar';

interface State {
  active: boolean;
  selected: DropdownOption | null;
  defaultValue?: DropdownOption['id'];
}

export interface DropdownProps {
  multiSelects?: ReadonlyArray<DropdownOption> | null;
  items: ReadonlyArray<DropdownOption> | null;
  selectedItems?: ReadonlyArray<string | number | null>;
  title?: string;
  // Key that is shown if the dropdown is a selectable type
  // should be something akin to "{{count}} item(s) selected"
  selectedTitle?: string;
  isNoneSelected?: boolean;
  emptyTitle?: string;
  selectable?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  name?: string;
  // id of the default value of a singular select dropdown
  defaultValue?: DropdownOption['id'];
  onClick: (id: DropdownOption['id']) => void;
  formatDisplay?: (title: string | JSX.Element) => string | JSX.Element;
  disableOnSingle?: boolean;
}

type Props = DropdownProps & Partial<ResponseComponentProps>;

export default class Dropdown extends PureComponent<Props, State> {
  public dropDown: HTMLDivElement | null = null;
  private uuid: string | null = uuid.v4();

  constructor(props: Props) {
    super(props);

    this.state = {
      active: false,
      selected:
        props.items?.find(item => item.id === props.defaultValue) ||
        props.items?.find(item => item.selected) ||
        null,
      defaultValue: props.defaultValue,
    };
  }

  public componentDidUpdate() {
    if (this.props.disabled && this.state.active) {
      this.setState({ active: false });
    }
    if (this.props.defaultValue !== undefined) {
      this.setState({
        selected:
          this.props.items?.find(item => item.id === this.props.defaultValue) ||
          null,
      });
    }
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  public render() {
    const {
      selectable,
      items,
      searchable,
      selectedItems,
      multiSelects,
      formatDisplay,
      loading,
      failed,
      name,
    } = this.props;
    const { active } = this.state;

    return (
      <div
        className={classnames('dropdown', {
          active,
        })}
        ref={this.setDropdownRef}
      >
        <div
          className={classnames('dropdown-select', {
            'dropdown-disabled': this.isDisabled(),
            'is-default': this.isDefault(),
            'displaying-error': failed,
          })}
          onClick={this.toggleDropdown}
          role="button"
          aria-expanded={active}
          aria-labelledby={`label:${this.uuid}`}
          tabIndex={0}
          id={`select:${this.uuid}`}
        >
          <div
            className={
              this.getTitle() === this.props.title
                ? 'dropdown-value placeholder'
                : 'dropdown-value'
            }
            id={`label:${this.uuid}`}
          >
            {formatDisplay ? formatDisplay(this.getTitle()) : this.getTitle()}
          </div>

          {!this.props.disabled && (
            <div className="dropdown-icon">
              <FontAwesomeIcon
                className="dropdown-chevron"
                icon={
                  loading
                    ? faSpinnerThird
                    : this.state.active
                    ? faChevronUp
                    : faChevronDown
                }
                size="sm"
                spin={loading}
              />
            </div>
          )}
        </div>
        {active && (
          <div role="list" className="dropdown-container">
            {searchable && (
              <SearchBar formKey={name || ''} className="dropdown" />
            )}
            {multiSelects && (
              <ul className="dropdown-list multiselect" role="group">
                {multiSelects.map((item, index) => (
                  <DropdownItem
                    selectable={selectable}
                    onClick={this.onClick}
                    key={index}
                    item={item}
                    id={`option:${this.uuid}:${index}`}
                  />
                ))}
              </ul>
            )}

            {items && (
              <ul className="dropdown-list options" role="group">
                {items.map((item, index) => (
                  <DropdownItem
                    selectable={selectable}
                    onClick={this.onClick}
                    key={index}
                    item={{
                      selected: (selectedItems || []).includes(item.id),
                      ...item,
                    }}
                    id={`option:${this.uuid}:${index +
                      (multiSelects || []).length}`}
                  />
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    );
  }

  private getTitle = () => {
    const {
      items,
      emptyTitle,
      selectable,
      selectedTitle,
      disableOnSingle,
    } = this.props;
    if (disableOnSingle && items?.length === 1) {
      return items[0].name;
    }
    if ((items ? items.length : 0) === 0 && emptyTitle) {
      return emptyTitle;
    }
    if (selectable && selectedTitle) {
      return selectedTitle;
    }
    return this.state.selected?.name || this.props.title || '';
  };

  private getItemsLength = () =>
    (this.props.multiSelects || []).length + (this.props.items || []).length;

  private getItemFromindex = (index: number) => {
    const multiSelectsLength = (this.props.multiSelects || []).length;
    if (index > multiSelectsLength - 1) {
      return (this.props.items || [])[index - multiSelectsLength];
    }
    return (this.props.multiSelects || [])[index];
  };

  private handleKeyDown = (event: KeyboardEvent) => {
    const activeElementId = document.activeElement && document.activeElement.id;
    if (!activeElementId || !this.props.items) {
      return;
    }
    const [typeName, itemUuid, meta] = activeElementId.split(':');
    if (itemUuid !== this.uuid) {
      return;
    }

    const handleOptionIsActiveElement = () => {
      if (typeName === 'option') {
        const currentIndex = parseInt(meta, 10);
        const cursor = event.key === 'ArrowDown' ? 1 : -1;
        if (
          !isNaN(currentIndex) &&
          currentIndex + cursor < this.getItemsLength() &&
          currentIndex + cursor >= 0
        ) {
          const nextElement: HTMLElement | null = document.querySelector(
            `#option\\:${this.uuid}\\:${currentIndex + cursor}`
          );
          if (nextElement) {
            nextElement.focus();
          }
        }
      }
      event.preventDefault();
    };

    handleKeys(event, {
      [Key.DOWN_ARROW]: () => {
        if (typeName === 'select') {
          if (this.state.active) {
            const firstElement: HTMLElement | null = document.querySelector(
              `#option\\:${this.uuid}\\:0`
            );
            if (firstElement) {
              firstElement.focus();
            }
          } else {
            this.toggleDropdown();
          }
        }

        handleOptionIsActiveElement();
      },
      [Key.UP_ARROW]: handleOptionIsActiveElement,
      [Key.ENTER]: () => {
        if (typeName === 'select') {
          this.toggleDropdown();
        } else if (typeName === 'option') {
          this.props.onClick(this.getItemFromindex(parseInt(meta, 10)).id);
          this.toggleDropdown();
        }
      },
      [Key.SPACE]: () => {
        if (typeName === 'option' && this.props.selectable) {
          this.props.onClick(this.getItemFromindex(parseInt(meta, 10)).id);
          event.preventDefault();
        }
      },
      [Key.ESCAPE]: () => {
        if (this.state.active) {
          this.setState({ active: false });
        }
      },
    });
  };

  private onClick = (option: DropdownOption) => {
    if (!this.props.selectable) {
      this.setState({
        selected: option,
        defaultValue: undefined,
      });
      this.toggleDropdown();
    }
    this.props.onClick(option.id);
  };

  private handleClick = (event: MouseEvent) => {
    if (
      this.dropDown &&
      event.target &&
      this.dropDown.contains(event.target as HTMLDivElement)
    ) {
      return;
    }
    if (this.state.active) {
      this.setState({ active: false });
    }
  };

  private isDefault = () => {
    return this.props.selectable
      ? this.props.isNoneSelected
      : !this.state.selected;
  };

  private isDisabled = () =>
    this.props.disabled ||
    this.props.loading ||
    this.props.items?.length === 0 ||
    (this.props.disableOnSingle && this.props.items?.length === 1);

  private setDropdownRef = (node: HTMLDivElement) => (this.dropDown = node);

  private toggleDropdown = () => {
    if (!this.isDisabled()) {
      this.setState({ active: !this.state.active });
    }
  };
}
