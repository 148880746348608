import classNames from 'classnames';
import { fromJS, List, Map } from 'immutable';
import React, { ReactNode } from 'react';
import { debounce } from 'underscore';
import { connect } from 'react-redux';

import { setSimpleComponentState } from '^/actions/actions';
import { StoreState } from '^/store';
import Filter from '^/components/Filter';
import Icon from './Icon';
import SearchBar from './Searchbar';

const SEARCH_DEBOUNCE_MS = 500;

type ColumnFilter<T> = {
  key: string;
  values: ReadonlyArray<{ id: string; name: string }>;
  getValue: (row: T) => ReactNode;
};

export type Column<T> = Readonly<{
  header?: ReactNode;
  value: (row: T, index: number) => ReactNode;
  className?: string;
  searchable?: boolean;
  searchPlaceholder?: string;
  largeSearchBox?: boolean;
  headerClassName?: string;
  fieldName?: string;
  sortable?: boolean;
  sort?: (row: T, index: number) => ReactNode;
  filter?: ColumnFilter<T>;
}>;

export type Columns<T> = ReadonlyArray<Column<T>>;

export interface OwnProps<T extends {}> {
  defaultSort?: Column<any>;
  className?: string;
  id?: string;
  columns: Columns<any>;
  rows?: List<T>;
  emptyMessage?: string;
  prefixRows?: ReactNode;
  suffixRows?: ReactNode;
  noHeaders?: boolean;
  rowClassName?: (row?: T) => string;
  headerClassName?: string;
  onSearchChange?: (value?: string, fieldName?: string) => void;
  collapse?: 'md';
}

interface StateProps {
  tableSorting: Map<string, any>;
  tableFilters: Map<string, any>;
}

interface DispatchProps {
  setSimpleComponentState: typeof setSimpleComponentState;
}

type Props<T extends {}> = OwnProps<T> & StateProps & DispatchProps;

const sortReducerKey = (activityId?: string) => `${activityId}_sorting`;
const filtersReducerKey = (activityId?: string) => `${activityId}_filters`;

export class Table<T extends {}> extends React.PureComponent<Props<T>> {
  private limitedSearch: (
    fieldName?: string,
    searchString?: string
  ) => void | null;

  constructor(props: Props<T>) {
    super(props);
    this.limitedSearch = debounce(
      (fieldName?: string, searchString?: string) =>
        this.props.onSearchChange &&
        this.props.onSearchChange(searchString, fieldName),
      SEARCH_DEBOUNCE_MS
    );
  }

  public componentDidMount() {
    if (this.props.defaultSort && this.props.tableSorting.size === 0) {
      this.toggleSorting(this.props.defaultSort);
    }
  }

  public renderTableHeaders(columns: Columns<any>) {
    const hasExtraFields = columns.some(column => column.searchable);
    return (
      <thead>
        <tr>
          {columns.map((column, idx) => (
            <th
              key={idx}
              className={classNames(column.headerClassName, {
                'has-extra-fields': hasExtraFields,
              })}
            >
              <div
                className={classNames(
                  'table-header',
                  column.sortable && 'table-header-control-on-right',
                  column.filter && 'filter-control'
                )}
              >
                {column.header}
                {column.sortable && this.renderSortButton(column)}
                {column.filter && this.renderFilterDropdown(column.filter)}
              </div>
              {column.searchable && (
                <SearchBar
                  // formKey={column.fieldName}
                  className={classNames('block', {
                    small: !column.largeSearchBox,
                  })}
                  onChange={this.onSearchChange.bind(this, column.fieldName)}
                  placeholder={column.searchPlaceholder}
                />
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  public render() {
    const {
      className,
      columns,
      emptyMessage,
      prefixRows,
      suffixRows,
      tableSorting,
      tableFilters,
      noHeaders,
      rowClassName,
      collapse,
    } = this.props;
    let rows = this.props.rows;

    if (rows) {
      if (!tableSorting.isEmpty()) {
        rows = rows
          .sortBy(this.getSortFunction(tableSorting.get('key')) as any)
          .toList();
        if (tableSorting.get('sort') === -1) {
          rows = rows.reverse().toList();
        }
      }

      tableFilters.forEach((value: any, key?: string) => {
        const filterFunc = this.getFilterFunction(key);
        if (filterFunc) {
          rows = rows!.filter(row => filterFunc(row) === value).toList();
        }
      });
    }

    return (
      <table
        className={classNames(
          { [`collapse collapse-${collapse}`]: collapse },
          className
        )}
      >
        {!noHeaders && this.renderTableHeaders(columns)}
        <tbody>
          {prefixRows}
          {rows &&
            rows
              .map((row, rowIdx) => (
                <tr key={rowIdx} className={rowClassName && rowClassName(row)}>
                  {columns.map((column, idx) => (
                    <td
                      key={idx}
                      className={column.className}
                      data-header={column.header}
                    >
                      {column.value(row!, rowIdx!)}
                    </td>
                  ))}
                </tr>
              ))
              .toArray()}
          {rows && rows.isEmpty() && emptyMessage && (
            <tr>
              <td colSpan={columns.length}>{emptyMessage}</td>
            </tr>
          )}
          {suffixRows}
        </tbody>
      </table>
    );
  }

  private toggleSorting(column: Column<any>) {
    let currentSort = this.props.tableSorting.get('sort', null);
    // reverse sorting if already in place, or use default
    currentSort = currentSort ? currentSort * -1 : 1;

    this.props.setSimpleComponentState(
      sortReducerKey(this.props.id),
      Map({ key: column.header, sort: currentSort })
    );
  }

  private getSortFunction(headerName: string) {
    const column = this.props.columns.find(each => each.header === headerName);
    return column?.sort || column?.value;
  }

  private renderSortButton(column: Column<any>) {
    const sortHeader = this.props.tableSorting.get('key', null);
    const sortOrder = this.props.tableSorting.get('sort', 1);
    const upOrDown = sortOrder === 1 ? 'down' : 'up';

    return (
      <Icon
        onClick={this.toggleSorting.bind(this, column)}
        type="up-down"
        className={classNames(
          'sort-control',
          sortHeader === column.header ? upOrDown : ''
        )}
      />
    );
  }

  private setFilter(filterKey: string, value: string | null) {
    const { tableFilters } = this.props;
    this.props.setSimpleComponentState(
      filtersReducerKey(this.props.id),
      value
        ? tableFilters.merge({ [filterKey]: value })
        : tableFilters.delete(filterKey)
    );
  }

  private getFilterFunction(key?: string) {
    const column = this.props.columns.find(each => each.filter?.key === key);
    return column?.filter?.getValue;
  }

  private renderFilterDropdown(filter: ColumnFilter<any>) {
    const { tableFilters } = this.props;
    const { key, values } = filter;
    return (
      <div className="filters">
        <Filter
          canBeChanged
          showIfUnset
          data={{
            values: {
              values: fromJS(values),
            },
            currentValue: tableFilters.get(key),
            key,
            name: key,
          }}
          key={key}
          onChange={(value: string | null) => this.setFilter(key, value)}
        />
      </div>
    );
  }

  private onSearchChange = (
    fieldName?: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.limitedSearch(fieldName, event!.target.value);
  };
}

export function mapStateToProps<T extends {}>(
  state: StoreState,
  props: OwnProps<T>
) {
  return {
    tableSorting: state.ui.getIn(
      ['simpleComponentState', sortReducerKey(props.id)],
      Map()
    ),
    tableFilters: state.ui.getIn(
      ['simpleComponentState', filtersReducerKey(props.id)],
      Map()
    ),
  };
}

export default connect(mapStateToProps, { setSimpleComponentState })(Table);
