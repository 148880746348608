import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import { setFilter } from '^/actions/actions';
import {
  getAllPulseProductOptions,
  getPurchasedPulseOrgs,
  getBasketItemOrgs,
} from '^/actions/collections';
import { administerOrganisations } from '^/capabilities';
import ContentTab from '^/components/ContentTab';
import ContentTabset from '^/components/ContentTabset';
import ControlBar from '^/components/ControlBar';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';
import { RouterParamProps, withRouter } from '^/withRouter';
import PurchasedBasketItems from './BasketItems';
import PurchasedPulses from './Pulses';

interface DispatchProps {
  setFilter: typeof setFilter;
  push: typeof push;
  getAllPulseProductOptions: typeof getAllPulseProductOptions;
  getPurchasedPulseOrgs: typeof getPurchasedPulseOrgs;
  getBasketItemOrgs: typeof getBasketItemOrgs;
}

interface StateProps {
  response: Map<string, any>;
  shouldShowOrganisations: boolean;
}

type Props = DispatchProps &
  StateProps &
  RouterParamProps<
    {
      filter: string;
    },
    {}
  >;

export class Purchases extends React.PureComponent<Props> {
  public componentDidMount() {
    if (this.props.shouldShowOrganisations) {
      this.props.getPurchasedPulseOrgs();
      this.props.getBasketItemOrgs();
    }
    this.props.getAllPulseProductOptions();
  }

  private clearQueryString = () => {
    this.props.push('/page/purchases/');
  };

  public render() {
    return (
      <div className="purchases-table">
        <ControlBar title={i18next.t<string>('Purchases')} hideSearch hideAdd />
        <ContentTabset name="purchase-tabs" defaultActiveTab={0}>
          <ContentTab
            tabId={0}
            tabsetName="purchase-tabs"
            tabName={i18next.t<string>('Shop')}
            onSelect={this.clearQueryString}
          >
            <PurchasedBasketItems queryParams={this.props.router.params} />
          </ContentTab>
          <ContentTab
            tabId={1}
            tabsetName="purchase-tabs"
            tabName={i18next.t<string>('Pulsing')}
            onSelect={this.clearQueryString}
          >
            <PurchasedPulses queryParams={this.props.router.params} />
          </ContentTab>
        </ContentTabset>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  return {
    response: state.responses.get('getCollection'),
    shouldShowOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setFilter,
    push,
    getAllPulseProductOptions,
    getPurchasedPulseOrgs,
    getBasketItemOrgs,
  })(Purchases)
);
