import i18next from 'i18next';

import { getAnalyticsSessionsCount } from '^/actions/analytics';
import {
  AnalyticsAppliedFilters,
  AnalyticsAppliedFiltersWithResultsRangesFlattened,
  AnalyticsProduct,
} from '^/reducers/api/types';
import {
  FACTORS_HIERARCHY,
  FACTORS_HIERARCHY_EXTRA_RESULTS,
  THREE_SIXTY_LEVELS,
} from '../analytics/DataAnalyticsTableStructure';
import { ADVANCED_ANALYTICS_ATTRIBUTE } from './types';

export const convertFilterToSegments = (filter: string) => {
  const segments = filter.split(':');
  return {
    attribute: segments[0],
    factor: segments[1],
    from: segments[2],
    to: segments[3],
  };
};

export const convertFilterToDisplay = (filter: string) => {
  const { attribute, factor, from, to } = convertFilterToSegments(filter);
  const updatedAttribute = attribute === 'score' ? 'percentile' : attribute;
  const factorWithoutLeading =
    factor.indexOf('_') === -1 ? factor : factor.split('_')[1];
  const capitalisedAttribute =
    updatedAttribute.charAt(0).toUpperCase() + updatedAttribute.slice(1);

  return `${factorWithoutLeading} ${capitalisedAttribute}: ${from} - ${to}`;
};

export const get360LevelText = (threshold: string) =>
  THREE_SIXTY_LEVELS.find(level => level.threshold === Number(threshold))
    ?.level || '';

export const getFilterValueDisplay = (
  product: AnalyticsProduct,
  from: string | undefined,
  to: string | undefined,
  attribute: string | undefined
) => {
  if (from && to) {
    return product.activity_type === 'THREE_SIXTY' &&
      attribute === ADVANCED_ANALYTICS_ATTRIBUTE.LEVEL
      ? `${get360LevelText(from)} - ${get360LevelText(to)}`
      : `${from} - ${to}`;
  }
  return '';
};

export const filterOutInvalidFilters = (filters: string[]) => {
  return filters.filter(value => value.split(':').length === 4);
};

export const flattenResultsRanges = (
  filters: AnalyticsAppliedFilters
): AnalyticsAppliedFiltersWithResultsRangesFlattened => {
  return {
    ...filters,
    results_ranges: filters.results_ranges ? filters.results_ranges.flat() : [],
  };
};

export const concatResultsRanges = (
  analyticsFilters: AnalyticsAppliedFilters,
  newResultsRanges: string[]
): string[][] => {
  let resultsRanges: string[][] = [];
  if (analyticsFilters.results_ranges) {
    resultsRanges = analyticsFilters.results_ranges as string[][];
  }

  if (newResultsRanges.length === 0) {
    return resultsRanges;
  }
  return [...resultsRanges, newResultsRanges];
};

export const getAdvancedAnalyticsSessionCount = (
  filters: string[],
  analyticsFilters: AnalyticsAppliedFilters,
  connectedGetAnalyticsSessionsCount: typeof getAnalyticsSessionsCount,
  setCount: (count: number) => void
) => {
  const filterData: AnalyticsAppliedFilters = {
    ...(analyticsFilters as any),
    results_ranges: concatResultsRanges(
      analyticsFilters,
      filterOutInvalidFilters(filters)
    ),
  };

  const request: any = connectedGetAnalyticsSessionsCount(filterData);

  request.then((response: any) => {
    response?.meta?.success && setCount(response?.payload?.count);
  });
};

export const getFactors = (product: AnalyticsProduct) => {
  const get360Factors = () => {
    return product.primary_competencies
      ? [
          { id: 'ALL', name: i18next.t<string>('All capabilities') },
          ...product.primary_competencies.flatMap(
            ({ code, name, secondary_competencies }) => [
              {
                id: code,
                name,
              },
              ...secondary_competencies.map(subcompetency => ({
                id: subcompetency.code,
                name: `${name} - ${subcompetency.name}`,
              })),
            ]
          ),
        ]
      : [];
  };

  const getPsychometricFactors = () => {
    if (product.type === 'DISC') {
      const selfImage = FACTORS_HIERARCHY.DISC[0];
      const {
        factor: [name, , code],
        subfactors,
      } = selfImage;
      return subfactors
        .filter(([, subfactorId]) => 'DISC'.includes(subfactorId))
        .map(([subfactorName, subfactorId]) => ({
          id: `${subfactorId}|${code}`,
          name: `${name} - ${subfactorName}`,
        }));
    }
    return FACTORS_HIERARCHY[product.type]
      .flatMap(({ factor: [name, id], subfactors }) => [
        {
          id,
          name,
        },
        ...subfactors.map(([subfactorName, subfactorId]) => ({
          id: subfactorId,
          name: `${name} - ${subfactorName}`,
        })),
      ])
      .concat(
        FACTORS_HIERARCHY_EXTRA_RESULTS[product.type].flatMap(
          ({ factor: [name, id], subfactors }) => [
            {
              id,
              name,
            },
            ...subfactors.map(([subfactorName, subfactorId]) => ({
              id: subfactorId,
              name: `${name} - ${subfactorName}`,
            })),
          ]
        )
      );
  };

  if (product.activity_type === 'THREE_SIXTY') {
    return get360Factors();
  }
  return getPsychometricFactors();
};
