import React from 'react';

import Alert, { AlertType } from './Alert';
import NewButton from './NewButton';

interface Action {
  label: string;
  onClick: () => void;
  isDisabled: boolean;
}

interface Props {
  infoText: string;
  icon?: React.JSX.Element;
  errorText: string | null;
  actions: ReadonlyArray<Action>;
}

export const BottomActionBar: React.FC<Props> = ({
  actions,
  infoText,
  errorText,
  icon,
}) => {
  return (
    <div className="bottom-action-bar visible">
      <div className="bottom-action-bar-wrapper">
        <div className="bottom-action-bar-status">
          {icon && icon}
          <h2 className="margin-none">{infoText}</h2>
        </div>
        {errorText && (
          <div className="bottom-action-bar-error">
            <Alert type={AlertType.Error} className="margin-none">
              {errorText}
            </Alert>
          </div>
        )}
        <div className="bottom-action-bar-buttons">
          {actions.map(
            action =>
              action && (
                <NewButton
                  key={action.label}
                  disabled={action.isDisabled}
                  onClick={action.onClick}
                  buttonType="white-stroke"
                >
                  {action.label}
                </NewButton>
              )
          )}
        </div>
      </div>
    </div>
  );
};
