import classNames from 'classnames';
import React, { ReactNode, FunctionComponent } from 'react';

interface OwnProps {
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
}

type Props = OwnProps;

const Well: FunctionComponent<Props> = ({ children, disabled, className }) => (
  <div className={classNames('well', className, { disabled })}>{children}</div>
);

export default Well;
