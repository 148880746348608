import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { debounce } from 'underscore';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { getAllOrganisations, getGroups } from '^/actions/collections';
import { clearItem } from '^/actions/items';
import { openCreateGroupModal } from '^/actions/modals';
import { can, administerOrganisations } from '^/capabilities';
import ControlBar from '^/components/ControlBar';
import Loading from '^/components/Loading';
import PureComponent from '^/components/PureComponent';
import GroupsDetail from './GroupsDetail';
import GroupsPageOrganisations, {
  SELECTED_ORGANISATION,
} from '^/components/groups/GroupsPageOrganisations';
import TabSet from '^/components/ContentTabset';
import SearchBar from '^/components/Searchbar';
import GroupsListItem from '^/components/groups/GroupsListItem';
import Alert from '^/components/Alert';

const getIdOrNull = object => object && object.get('id');

export class GroupsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.limitedSearch = debounce(
      searchString => this.getGroups(this.props.organisation, searchString),
      500
    );
  }

  onSearchChange(event) {
    this.limitedSearch(event.target.value);
  }

  UNSAFE_componentWillMount() {
    const {
      shouldShowOrganisations,
      administeredOrganisation,
      selectedOrganisation,
    } = this.props;
    if (shouldShowOrganisations) {
      this.props.getAllOrganisations({ simple: true });
      this.getGroups(selectedOrganisation);
    } else if (administeredOrganisation) {
      this.getGroups(administeredOrganisation);
    }
  }

  getGroups(organisation, searchString) {
    this.props.getGroups(getIdOrNull(organisation), searchString);
  }

  loadGroupsOnOrgChange(currentOrg, nextOrg) {
    if (getIdOrNull(nextOrg) !== getIdOrNull(currentOrg)) {
      this.getGroups(nextOrg);
      this.props.clearItem('groups');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadGroupsOnOrgChange(
      this.props.selectedOrganisation,
      nextProps.selectedOrganisation
    );
  }

  renderGroupsList() {
    const {
      shouldShowOrganisations,
      selectedOrganisation,
      administeredOrganisation,
      groups,
    } = this.props;
    const organisation = shouldShowOrganisations
      ? selectedOrganisation
      : administeredOrganisation;
    if (!this.props.groups) {
      return <Loading />;
    }

    if (this.props.groups.isEmpty()) {
      return (
        <Alert className="mt-md">
          {i18next.t('There are currently no groups to display')}
        </Alert>
      );
    }
    return groups.map(group => (
      <GroupsListItem
        key={group.get('id')}
        group={group}
        organisation={organisation}
      />
    ));
  }

  render() {
    const {
      selectedOrganisation,
      administeredOrganisation,
      shouldShowOrganisations,
      groupsLoading,
      organisations,
    } = this.props;
    const organisation = shouldShowOrganisations
      ? selectedOrganisation
      : administeredOrganisation;
    return (
      <div>
        <ControlBar
          createButtonText="group"
          title={i18next.t('Groups')}
          onAddClick={() =>
            this.props.openCreateGroupModal(organisation, organisations)
          }
          disableAdd={!organisation && !organisations}
          hideSearch
        />
        <TabSet
          className="full-width vertical text-transform-initial"
          defaultActiveTab={0}
          name="groups"
          content={groupsLoading ? <Loading /> : <GroupsDetail />}
        >
          {shouldShowOrganisations && (
            <GroupsPageOrganisations organisations={organisations} />
          )}

          <SearchBar
            onChange={this.onSearchChange.bind(this)}
            className="full-width"
            formKey="GroupsSearchForm"
            placeholder={i18next.t('Search by group or user')}
          />

          {this.renderGroupsList()}
        </TabSet>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedOrganisation: state.ui
      .get('simpleComponentState')
      .getIn(SELECTED_ORGANISATION),
    administeredOrganisation: state.userProfile.get('organisation'),
    organisations: state.collections.getIn(['organisations', 'items']),
    groups: state.collections.getIn(['groups', 'items']),
    groupsLoading: isPending(
      state.multiResponses.get('getCollection').get('groups')
    ),
    shouldShowOrganisations: can(state.userProfile, administerOrganisations()),
  };
}

export default connect(mapStateToProps, {
  openCreateGroupModal,
  getGroups,
  getAllOrganisations,
  clearItem,
})(GroupsPage);
