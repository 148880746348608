import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';
import { List } from 'immutable';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Formik, Form } from 'formik';

import PureComponent from './PureComponent';
import LiveButton from './LiveButton';
import FormError from './FormError';
import { renderPasswordError } from './RedeemInviteForm';
import RevealablePasswordField from './forms/RevealablePasswordField';

export class ResetPasswordForm extends PureComponent {
  render() {
    const { onSubmit, response, loginLink } = this.props;

    return (
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ touched, errors }) => (
          <Form className="form-inline">
            <p>{i18next.t('Enter your new password below')}:</p>

            <div>
              <label>{i18next.t('Password')}</label>
              <div className="form-item">
                <RevealablePasswordField
                  id="reset-password"
                  name="password"
                  placeholder={i18next.t('Password')}
                />
                <FormError
                  response={response}
                  formKey="password"
                  renderError={renderPasswordError}
                />
              </div>
            </div>

            <div>
              <label>{i18next.t('Confirm password')}</label>
              <div className="form-item">
                <RevealablePasswordField
                  id="reset-confirm-password"
                  name="confirmPassword"
                  placeholder={i18next.t('Confirm password')}
                />
                <FormError
                  response={response}
                  formKey="password2"
                  renderError={renderPasswordError}
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div className="text-error">{errors.confirmPassword}</div>
                )}
              </div>
            </div>

            <div>
              <LiveButton
                response={response}
                buttonText={i18next.t('Reset password')}
                pendingMessage={i18next.t('Resetting password…')}
                additionalButtonClasses="col-xs-12"
                successContent={
                  <Trans i18nKey="TRANS reset password login">
                    Password has been reset. Please
                    <Link to={loginLink}>log in here</Link> with your new
                    password.
                  </Trans>
                }
                getAdditionalErrors={responseData =>
                  responseData.getIn(['errors', 'uid'], List())
                }
                failedClassName="full-width"
              />
            </div>

            <p>{i18next.t('PARAGRAPH password information')}</p>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ResetPasswordForm;
