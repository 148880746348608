import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import { propTypes as nominationRulesPropTypes } from '^/nominationRules';
import PureComponent from '^/components/PureComponent';
import { addRaterToActivity } from '^/actions/actions';
import BaseAddRaterRow from '^/components/dashboard/enduser/manage_raters/BaseAddRaterRow';

export class AddRaterRow extends PureComponent {
  addRater(userId, roleId) {
    this.props.addRaterToActivity(
      this.props.activityId,
      roleId,
      userId,
      this.props.id
    );
  }

  render() {
    return (
      <BaseAddRaterRow {...this.props} addRater={this.addRater.bind(this)} />
    );
  }
}

AddRaterRow.propTypes = {
  id: PropTypes.string.isRequired,
  rules: ImmutablePropTypes.listOf(nominationRulesPropTypes.NominationRule)
    .isRequired,
  activityId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  excludedUserIds: ImmutablePropTypes.list.isRequired,
  raters: ImmutablePropTypes.list.isRequired,
};

export default connect(
  () => {
    return {};
  },
  {
    addRaterToActivity,
  }
)(AddRaterRow);
