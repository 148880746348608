import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import SubHeader from '../SubHeader';
import SectionHeader from '../SectionHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';
import Well from '../Well';
import Alert, { AlertType } from '../Alert';

export default class Typography extends PureComponent {
  render() {
    return (
      <div>
        {/* Typography */}
        <PageHeader>Typography</PageHeader>

        <PageContent>
          <SectionHeader>Wells</SectionHeader>

          <div className="row">
            <div className="col-lg-6">
              <Well>
                <p>
                  <strong>Well</strong> well well...
                </p>
                <ul>
                  <li>This is a list item within a well component.</li>
                  <li>
                    This is a list item with alot of text. It should be
                    correctly aligned with the same horisontal spacing, as well
                    as having a normal line height between the lines within an
                    item.
                  </li>
                  <li>This is another list item within a well component.</li>
                </ul>
              </Well>
              <Well disabled>What do we have here</Well>
            </div>
            <div className="col-lg-6">
              <CodeSample
                code={`<Well>
  <p><strong>Well</strong> well well...</p>
  <ul>
    <li>This is a list item within a well component</li>
    <li>This is a list item with alot of text. It should be correctly
      aligned with the same horisontal spacing, as well as having a
      normal line height between the lines within an item.</li>
    <li>This is another list item within  a well component.</li>
    <li>This is another list item within  a well component.</li>
  </ul>
</Well>

<Well disabled>
  What do we have here
</Well>`}
              />
            </div>
          </div>

          <SectionHeader>Paragraphs</SectionHeader>

          <div className="row">
            <div className="col-lg-6">
              <p>
                Open Sans Regular is used for all paragraphs. 13px font size and
                1.7 line-height is the default. It can be <strong>bold</strong>,{' '}
                <em>italic</em> or <u>underlined</u>.{' '}
                <a>Links in paragraphs are always in SemiBold</a>. Page
                headings, card headings, table headings and paragraphs should
                always be Sentence case by default, in font-weight Regular.
                Headings and labels always have line-height: 1.4.
              </p>

              <p>
                <b>Open Sans Bold</b> is only used for headings in big cards,
                and can be used sparsely if needed elsewhere (like in
                paragraphs).
              </p>

              <p>
                <span className="semi-bold-text">Open Sans SemiBold</span> is
                used for headings, links, labels, buttons and other navigation
                elements. Section headings, top navigation links, buttons and
                input labels should always be uppercase and SemiBold.
              </p>

              <p>
                <span className="uppercase">Uppercase text</span> should never
                be written hard-coded in uppercase. Style it with CSS
                text-transform.
              </p>

              <p>
                Colons should only be used when there is a label/title to a
                value on the same row. For example,{' '}
                <span className="semi-bold-text">Name:</span> Laura. In that
                case the label would be SemiBold and the value would be Regular
                font-weight.
              </p>
            </div>

            <div className="col-lg-6">
              <CodeSample
                code={`<p>Open Sans Regular is used for all paragraphs. 13px font size and 1.7 line-height
 is the default.
It can be <strong>bold</strong>, <em>italic</em> or <u>underlined</u>. <a>Links in paragraphs are always
in SemiBold</a>.
Page headings, card headings, table headings and paragraphs should always be Sentence case by default, in
font-weight Regular.
Headings and labels always have line-height: 1.4.</p>

<p><b>Open Sans Bold</b> is only used for headings in big cards, and can be used sparsely if needed elsewhere (like
in paragraphs).</p>

<p><span className="semi-bold-text">Open Sans SemiBold</span> is used for headings, links, labels, buttons and other
navigation elements.
Section headings, top navigation links, buttons and input labels should always be uppercase and SemiBold.</p>

<p><span className="uppercase">Uppercase text</span> should never be written hard-coded in uppercase. Style it with
CSS text-transform.</p>

<p>Colons should only be used when there is a label/title to a value on the same row.
For example, <span className="semi-bold-text">Name:</span> Laura. In that case the label would be SemiBold and the
value would be Regular font-weight.</p>`}
              />
            </div>

            <div className="col-lg-6">
              <p className="lead">
                Lead paragraph. Info / intro text at the top of a page.
              </p>
            </div>
            <div className="col-lg-6">
              <CodeSample
                code={`<p className="lead">
  Lead paragraph. Info / intro text at the top of a page.
</p>`}
              />
            </div>

            <div className="col-lg-6">
              <ol>
                <li>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li>Aliquam tincidunt mauris eu risus.</li>
              </ol>
            </div>
            <div className="col-lg-6">
              <CodeSample
                code={`<ol>
  <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
  <li>Aliquam tincidunt mauris eu risus.</li>
</ol>`}
              />
            </div>

            <div className="col-lg-6">
              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                </li>
                <li>Aliquam tincidunt mauris eu risus.</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <CodeSample
                code={`<ul>
  <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
  <li>Aliquam tincidunt mauris eu risus.</li>
</ul>`}
              />
            </div>

            <div className="col-lg-6">
              <blockquote>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                  eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                  libero at sagittis mollis, tellus est malesuada tellus, at
                  luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                </p>
              </blockquote>
            </div>

            <div className="col-lg-6">
              <CodeSample
                code={`<blockquote>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida.
    Curabitur massa. Donec eleifend, libero at sagittis mollis,
     tellus est malesuada tellus, at luctus turpis elit sit amet quam.
    Vivamus pretium ornare est.
  </p>
</blockquote>`}
              />
            </div>
          </div>

          <SectionHeader>Headings & header components</SectionHeader>

          <div className="row">
            <div className="col-lg-6">
              <h1>Heading level 1, sentence case, regular</h1>
              <h2>Heading level 2, sentence case, regular</h2>
              <h3>Heading level 3, sentence case, regular</h3>
              <h3 className="h3-secondary">
                Heading level 3 - secondary, uppercase, bold
              </h3>
              <h4>Heading level 4, uppercase, semibold</h4>
              <h5>Heading level 5, uppercase, semibold</h5>
            </div>
            <div className="col-lg-6">
              <CodeSample
                code={`<h1>Heading level 1, sentence case, regular</h1>
<h2>Heading level 2, sentence case, regular</h2>
<h3>Heading level 3, sentence case, regular</h3>
<h3 className="h3-secondary">Heading level 3 - secondary, uppercase, bold</h3>
<h4>Heading level 4, uppercase, semibold</h4>
<h5>Heading level 5, uppercase, semibold</h5>`}
              />
            </div>
          </div>

          <Alert type={AlertType.Warning}>
            Note: Do not use h1 or h2 tags as these are used by the app itself.
          </Alert>

          <div className="row">
            <div className="col-lg-6">
              <PageHeader>
                PageHeader tag (outputs grid tags, a h1 tag and an hr tag)
              </PageHeader>

              <SubHeader>
                SubHeader tag (outputs grid tags, a h2 tag and an hr tag)
              </SubHeader>

              <SectionHeader>
                SectionHeader tag (outputs grid tags, a h4 tag and an hr tag)
              </SectionHeader>
            </div>

            <div className="col-lg-6">
              <CodeSample
                code={`<PageHeader>
  PageHeader tag (outputs grid tags, a h1 tag and an hr tag)
</PageHeader>

<SubHeader>
  SubHeader tag (outputs grid tags, a h2 tag and an hr tag)
</SubHeader>

<SectionHeader>
  SectionHeader tag (outputs grid tags, a h4 tag and an hr tag)
</SectionHeader>`}
              />
            </div>
          </div>

          <SectionHeader>Special cases</SectionHeader>

          <div className="row">
            <div className="col-lg-6">
              <h3>Call-out text</h3>
              <p>
                used at foot of pages with modals on for context (e.g. login)
              </p>
              <p className="call-out-text">
                Login to PeopleWise Enable to manage and view your Psychometric
                tests and 360s.
              </p>
            </div>
            <div className="col-lg-6">
              <CodeSample
                code={`<p className="call-out-text">
Login to PeopleWise Enable to manage and view
your Psychometric tests and 360s.</p>`}
              />
            </div>
          </div>
        </PageContent>
      </div>
    );
  }
}
