import React from 'react';
import { connect } from 'react-redux';

import {
  clearAnalyticsSessions,
  getAnalyticsSessions,
  clearAnalyticsSessionsModal,
  getAnalyticsSessionsModal,
  getAnalyticsFiltersHeatmap,
} from '^/actions/analytics';
import Loading from '^/components/Loading';
import { StoreState } from '^/store';
import {
  AnalyticsProduct,
  AnalyticsSessionsState,
  HeatmapRow,
  Uuid,
} from '^/reducers/api/types';
import DataAnalyticsHeatMapFiltersTable from './DataAnalyticsHeatMapFiltersTable';
import DataAnalyticsTable from './DataAnalyticsTable';
import { DEMOGRAPHIC_COLUMN_GROUPS } from './DataAnalyticsTableStructure';
import { getSummaryName } from './utils';

interface StateProps {
  analyticsSessions: AnalyticsSessionsState;
  analyticsSessionsModal: AnalyticsSessionsState;
  user: Immutable.Map<string, any>;
}

interface DispatchProps {
  clearAnalyticsSessions: typeof clearAnalyticsSessions;
  getAnalyticsSessions: typeof getAnalyticsSessions;
  clearAnalyticsSessionsModal: typeof clearAnalyticsSessionsModal;
  getAnalyticsSessionsModal: typeof getAnalyticsSessionsModal;
  getAnalyticsFiltersHeatmap: typeof getAnalyticsFiltersHeatmap;
}

interface OwnProps {
  aggregate: HeatmapRow | null;
  product?: Uuid;
  editable: boolean;
  index?: number;
  inModal: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  page: number;
  ordering?: string;
}

export class DataAnalyticsOverlayModal extends React.PureComponent<
  Props,
  State
> {
  private initialOrdering = DEMOGRAPHIC_COLUMN_GROUPS[0].columns[0].sortKey;

  public initialState = {
    page: 1,
    ordering: this.initialOrdering,
  };

  constructor(props: Props) {
    super(props);

    this.state = this.initialState;
    const { inModal } = this.props;
    const sessions = props.aggregate?.filtersState.sessions;
    const results = sessions?.results;
    const getAnalyticsSessionsFunc = inModal
      ? this.props.getAnalyticsSessionsModal
      : this.props.getAnalyticsSessions;
    if (results?.length === 0) {
      this.props.getAnalyticsFiltersHeatmap(props.aggregate!.appliedFilters);
      getAnalyticsSessionsFunc(
        props.aggregate!.appliedFilters,
        1,
        this.initialState.ordering,
        false
      );
    } else if (sessions && results) {
      getAnalyticsSessionsFunc(
        props.aggregate!.appliedFilters,
        1,
        this.initialState.ordering,
        false
      );
    }
  }

  public componentDidUpdate(_prevProps: Props, prevState: State) {
    const { page, ordering } = this.state;
    const { aggregate, inModal } = this.props;
    const sortChanged = ordering !== prevState.ordering;
    const pageChanged = page !== prevState.page;

    if (aggregate && (sortChanged || pageChanged)) {
      if (!inModal) {
        this.props.getAnalyticsSessions(
          aggregate.appliedFilters,
          page,
          ordering,
          sortChanged
        );
      } else {
        this.props.getAnalyticsSessionsModal(
          aggregate.appliedFilters,
          page,
          ordering,
          sortChanged
        );
      }
    }
  }

  public render() {
    const {
      aggregate,
      editable,
      user,
      analyticsSessions,
      analyticsSessionsModal,
      product,
      index,
      inModal,
    } = this.props;
    if (aggregate?.filtersState.sessions?.results.length === 0) {
      return <Loading />;
    }

    return editable ? (
      <DataAnalyticsHeatMapFiltersTable
        product={product}
        aggregate={aggregate}
        index={index}
        hideAnalyse
      />
    ) : (
      aggregate && (
        <>
          <div className="boxed mb-md">
            <div className="box">{getSummaryName(aggregate)}</div>
          </div>
          <DataAnalyticsTable
            count={aggregate.filtersState.sessions!.count}
            user={user}
            loading={false}
            loadingPage={false}
            aggregate={aggregate.filtersState.sessions!.aggregate}
            product={aggregate.filtersState.product as AnalyticsProduct}
            page={this.state.page}
            onChangePage={this.onChangePage}
            ordering={this.state.ordering}
            onSetOrdering={this.onChangeOrdering}
            resultsByPage={inModal ? analyticsSessionsModal : analyticsSessions}
            subaggregates={[]}
            filters={aggregate.filtersState.filters}
          />
        </>
      )
    );
  }

  private onChangePage = (page: number) => {
    this.setState({ page });
  };

  private onChangeOrdering = (ordering: string) => {
    this.setState({
      ordering,
      page: 1,
    });
  };
}

export function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
    analyticsSessions: state.analyticsSessions,
    analyticsSessionsModal: state.analyticsSessionsModal,
  };
}

export default connect(mapStateToProps, {
  clearAnalyticsSessions,
  getAnalyticsSessions,
  clearAnalyticsSessionsModal,
  getAnalyticsSessionsModal,
  getAnalyticsFiltersHeatmap,
})(DataAnalyticsOverlayModal);
