import { isFSA } from 'flux-standard-action';
import { isRSAA } from 'redux-api-middleware';

import { SET_POLL, CLEAR_POLL } from '^/actions/actions';

let polls = {};

function doCallback(callback, store, onComplete) {
  let result = callback();
  if (isFSA(result) || isRSAA(result)) {
    store.dispatch(result).then(onComplete);
  } else if (result && result.then) {
    result.then(onComplete);
  } else {
    onComplete();
  }
}

export default store => next => action => {
  const { name, callback, rate } = action.payload || {};
  switch (action.type) {
    case SET_POLL:
      clearTimeout(polls[name]);
      const runAndRepeat = () => {
        doCallback(callback, store, () => {
          if (polls[name]) {
            polls[name] = setTimeout(runAndRepeat, rate);
          }
        });
      };
      polls[name] = true;
      runAndRepeat();
      break;
    case CLEAR_POLL:
      clearTimeout(polls[name]);
      delete polls[name];
      break;
  }

  return next(action);
};
