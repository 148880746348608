/* eslint-disable no-console */
import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import { ACTIVITY_STATUS, ACTIVITY_FILTERS } from '../../activityStatus';

export function getText(status) {
  switch (status) {
    case ACTIVITY_STATUS.LIVE:
      return 'LIVE';
    case ACTIVITY_STATUS.SETUP:
      return 'SETUP';
    case ACTIVITY_STATUS.SUSPENDED:
      return 'SUSPENDED';
    case ACTIVITY_STATUS.ENDED:
      return 'CLOSED';
    case ACTIVITY_FILTERS.ENDING_SOON:
      return 'CLOSING SOON';
    default:
      console.warn('Unrecognized status value' + { status });
      return 'CLOSED';
  }
}

export default class ActivityStatusBadge extends PureComponent {
  render() {
    const { isComplete, status } = this.props;

    return (
      <div className="status">{isComplete ? 'COMPLETED' : getText(status)}</div>
    );
  }
}
