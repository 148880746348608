import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import { debounce } from 'underscore';

import LikertQuestion from '^/components/dashboard/enduser/answer/LikertQuestion';
import { LikertBounds } from '^/productVersions';
import { FreeTextQuestion } from './FreeTextQuestion';

type OnAnswer = (value: string | number) => void;

interface OwnProps {
  answer: string | number;
  question: Map<string, any>;
  onAnswer: OnAnswer;
  bounds: LikertBounds;
  orgName: string;
  isSelfRating?: boolean;
  error?: List<string>;
  isPending?: boolean;
}

type Props = OwnProps;

const FREETEXT_ANSWER_DEBOUNCE_MS = 251;

export default class ThreeSixtyQuestion extends PureComponent<Props> {
  private debouncedAnswer: OnAnswer;

  constructor(props: Props) {
    super(props);
    this.debouncedAnswer = debounce(
      props.onAnswer,
      FREETEXT_ANSWER_DEBOUNCE_MS
    );
  }

  public UNSAFE_componentWillMount() {
    const { question } = this.props;
    if (question) {
      const required = question.getIn(['question360', 'required'], null);
      if (required === false) {
        // Should replace with existing value if saved questions are recovered
        this.props.onAnswer('');
      }
    }
  }

  public render() {
    const {
      question,
      answer,
      onAnswer,
      isSelfRating,
      bounds,
      isPending,
      error,
    } = this.props;
    const isFreeText = question.getIn(['question360', 'is_freetext']);
    const questionText = this.getQuestionText();

    return isFreeText ? (
      <FreeTextQuestion
        question={questionText}
        onChange={this.debouncedAnswer}
        disabled={isPending}
        error={error}
        placeholder={i18next.t<string>('Write your comment')}
        defaultValue={answer ? `${answer}` : ''}
      />
    ) : (
      <LikertQuestion
        isPending={isPending}
        questionText={questionText}
        onAnswer={onAnswer}
        answer={answer}
        bounds={bounds}
        hasDontKnow={!isSelfRating}
      />
    );
  }

  private interpolateOrgName(questionText: string) {
    return questionText.replace(/\[ORG_NAME\]/g, this.props.orgName);
  }

  private getQuestionText() {
    const { question, isSelfRating } = this.props;
    const questionText = isSelfRating
      ? question.getIn(['question360', 'text_for_self'])
      : question.get('text');
    return this.interpolateOrgName(questionText);
  }
}
