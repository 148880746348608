import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import {
  setReportFilterSelection,
  toggleReportFilterSelection,
} from '^/actions/ui';
import Dropdown from '^/components/dropdown/Dropdown';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import { StoreState } from '^/store';
import { SELECT_ALL_ID } from './constants';
import {
  selectAllOrgFilterBoolMap,
  selectIsAllProductsSelected,
  selectMappedSelectedProducts,
  selectProductsMultiItems,
  selectReportFilterSelectedCount,
  selectReportTableIsLoading,
} from './selectors';
import { BoolMap } from './types';

interface DispatchProps {
  toggleReportFilterSelection: typeof toggleReportFilterSelection;
  setReportFilterSelection: typeof setReportFilterSelection;
}

interface StateProps {
  isLoadingTable?: boolean;
  everyProductIsSelected: boolean;
  allProducts: BoolMap;
  selectedProductsCount: number;
  productsItems: ReadonlyArray<DropdownOption>;
  productsMultiItems: ReadonlyArray<DropdownOption>;
}

type Props = DispatchProps & StateProps;

export class ProductsDropdown extends React.PureComponent<Props> {
  public render() {
    const {
      selectedProductsCount,
      productsItems,
      productsMultiItems,
      isLoadingTable,
    } = this.props;
    return (
      <Dropdown
        title={i18next.t<string>('Products')}
        selectedTitle={this.getTitle()}
        isNoneSelected={selectedProductsCount === 0}
        emptyTitle={i18next.t<string>('No products available')}
        selectable
        items={productsItems}
        multiSelects={productsMultiItems}
        onClick={this.onClick}
        loading={Boolean(isLoadingTable)}
        failed={false}
        disableOnSingle
      />
    );
  }

  private getTitle = () => {
    const { selectedProductsCount, productsItems } = this.props;
    if (
      selectedProductsCount === 0 ||
      (productsItems && selectedProductsCount === productsItems.length)
    ) {
      return i18next.t<string>('All products');
    }
    return i18next.t<string>('{{count}} products selected', {
      count: selectedProductsCount,
    });
  };

  private onClick = (productId: DropdownOption['id']) => {
    const { everyProductIsSelected, allProducts } = this.props;
    if (productId === SELECT_ALL_ID) {
      if (everyProductIsSelected) {
        this.props.setReportFilterSelection({
          products: {},
        });
      } else {
        this.props.setReportFilterSelection({
          products: allProducts,
        });
      }
    } else {
      this.props.toggleReportFilterSelection({
        products:
          typeof productId !== 'string' && productId !== null
            ? `${productId}`
            : productId,
      });
    }
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    isLoadingTable: selectReportTableIsLoading(state),
    allProducts: selectAllOrgFilterBoolMap(state, 'products'),
    selectedProductsCount: selectReportFilterSelectedCount(state, 'products'),
    everyProductIsSelected: selectIsAllProductsSelected(state),
    productsItems: selectMappedSelectedProducts(state),
    productsMultiItems: selectProductsMultiItems(state),
  };
}

export default connect(mapStateToProps, {
  toggleReportFilterSelection,
  setReportFilterSelection,
})(ProductsDropdown);
