import React from 'react'; // eslint-disable-line no-unused-vars

export function get(propList, key, notSetValue) {
  const item = propList.find(pair => {
    return pair.get(0) === key;
  });

  if (item) {
    return item.get(1);
  }
  return notSetValue;
}

export function toOptions(pairs, pluralise) {
  return pairs.map(pair => {
    const value = pair.get(0);
    const label = pair.get(1);

    return (
      <option key={value} value={value}>
        {label}
        {pluralise ? 's' : ''}
      </option>
    );
  });
}

export default {
  get,
  toOptions,
};
