import { createSelector } from 'reselect';
import { unique } from 'underscore';

import {
  selectShopItems,
  selectUnpurchasedBasketItems,
} from '^/components/shop/selectors';
import { TAX_LOCATION } from '^/models/organisation';
import { findProductOrganisationJS } from '^/models/product';
import { ShopItem, ShopItemOptionType } from '^/reducers/api/types';
import {
  Organisation,
  ProductOrganisation,
} from '^/reducers/api/types/organisation';
import { selectCurrentRoutePath, selectUserProfile } from '^/selectors';
import { selectUserOrganisationJS } from '^/selectors/user';
import { StoreState } from '^/store';
import { sum } from '^/utils';
import { BasketItem, BasketItemData } from './types';

export const getBasketItemsData = (
  shopItems: ReadonlyArray<ShopItem> | null,
  unpurchasedBasketItems: ReadonlyArray<BasketItem>
) =>
  shopItems &&
  unpurchasedBasketItems
    .map(basketItem => {
      const shopItem = shopItems.find(each =>
        each.options.some(option => option.id === basketItem.shop_item_option)
      );
      return {
        basketItem,
        shopItem,
        shopItemOption: shopItem?.options.find(
          option => option.id === basketItem.shop_item_option
        ),
      };
    })
    .filter((each): each is BasketItemData =>
      Boolean(each.shopItem && each.shopItemOption)
    );

export const selectBasketItemsData = createSelector(
  selectShopItems,
  selectUnpurchasedBasketItems,
  getBasketItemsData
);

export const selectAnyQuantityIsZero = createSelector(
  selectUnpurchasedBasketItems,
  basketItems =>
    basketItems.some(basketItem => parseFloat(basketItem.total_cost) === 0)
);

export const selectBasketTotalCost = createSelector(
  selectBasketItemsData,
  basketItemsData =>
    sum(
      (basketItemsData || []).map(({ basketItem: { total_cost } }) =>
        parseFloat(total_cost || '')
      )
    )
);

export const selectAccountPaysUKTax = createSelector(
  selectUserProfile,
  userProfile =>
    userProfile.getIn(['organisation', 'tax_location']) ===
    TAX_LOCATION.CHOICES.UK
);

export const selectNumberOfItemsInBasket = createSelector(
  selectUnpurchasedBasketItems,
  unpurchasedBasketItems => unpurchasedBasketItems.length
);

export const selectAvailableProductPlusItems = createSelector(
  selectUserOrganisationJS,
  organisation =>
    organisation?.productorganisation_set.reduce(
      (total, productOrganisation) =>
        total + (productOrganisation.number_available || 0),
      0
    )
);

export const selectBasketActionBarVisible = createSelector(
  selectNumberOfItemsInBasket,
  selectCurrentRoutePath,
  (numberOfItemsInBasket, currentRoutePath) =>
    numberOfItemsInBasket > 0 &&
    !['/page/basket', '/page/reports'].includes(currentRoutePath)
);

function getProductOrganisationForBasketItem(
  basketItemData: BasketItemData,
  organisation: Organisation
) {
  return findProductOrganisationJS(
    organisation,
    basketItemData.shopItem.product?.id,
    basketItemData.shopItemOption.product_variant
  );
}
export const selectProductOrganisationsToCreate = createSelector(
  (_: StoreState, basketItemsData: ReadonlyArray<BasketItemData> | null) =>
    basketItemsData,
  selectUserOrganisationJS,
  (basketItemsData, organisation) => {
    if (!organisation || !basketItemsData) {
      return [];
    }
    return unique(
      basketItemsData
        .filter(
          basketItemData =>
            basketItemData.shopItemOption.type === ShopItemOptionType.Product
        )
        .map(basketItemData =>
          getProductOrganisationForBasketItem(basketItemData, organisation)
        )
        .filter(productOrganisation => !!productOrganisation)
    ) as ProductOrganisation[];
  }
);
