/* eslint-disable no-console */
import React, { FunctionComponent } from 'react';

import CheckableButtonQuestion from '^/components/buttons/CheckableButtonQuestion';
import PageHeader from '../PageHeader';
import SectionHeader from '../SectionHeader';
import CodeSample from './CodeSample';

const AssessmentComponents: FunctionComponent = () => (
  <div>
    <PageHeader>Assessment components</PageHeader>
    <SectionHeader>Checkable Button questions</SectionHeader>
    <div className="row">
      <div className="col-xs-6">
        <p>
          Component most commonly used in likert-question styled assessment
          screens.
        </p>
        <div>
          <CheckableButtonQuestion
            question="This is the question"
            buttons={[
              { label: 'First answer', value: '1' },
              { label: 'Second answer', value: '2' },
              { label: 'Third answer', checked: true, value: '3' },
              { label: 'Fourth answer', value: '4' },
            ]}
            onChange={console.log}
          />
          <CheckableButtonQuestion
            question="This is the second question"
            buttons={[
              { label: 'First answer\nSecond line', value: '1' },
              { label: 'Second answer', disabled: true, value: '2' },
              { label: 'Third answer', disabled: true, value: '3' },
              { label: 'Fourth answer', value: '4' },
            ]}
            onChange={console.log}
          />
        </div>
      </div>

      <div className="col-xs-6">
        <CodeSample
          code={`<div>
  <CheckableButtonQuestion
    question="This is the question"
    buttons={[
      { label: 'First answer', value: '1' },
      { label: 'Second answer', value: '2' },
      { label: 'Third answer', checked: true, value: '3' },
      { label: 'Fourth answer', value: '4' },
    ]}
    onChange={console.log}
  />
  <CheckableButtonQuestion
    question="This is the second question"
    buttons={[
      { label: 'First answer', value: '1' },
      { label: 'Second answer', disabled: true, value: '2' },
      { label: 'Third answer', disabled: true, value: '3' },
      { label: 'Fourth answer', value: '4' },
    ]}
    onChange={console.log}
  />
</div>
`}
        />
      </div>
    </div>
  </div>
);

export default AssessmentComponents;
