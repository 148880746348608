/* eslint-disable max-len */
import makeRsaAction from '../middleware/makeRsaAction';
import makeAsyncActionSet from './makeAsyncActionSet';

export const GET_COLLECTION = makeAsyncActionSet('GET_COLLECTION');
export const ADD_TO_COLLECTION = makeAsyncActionSet('ADD_TO_COLLECTION');
export const DELETE_ITEM = makeAsyncActionSet('DELETE_ITEM');
export const UPDATE_ITEM = makeAsyncActionSet('UPDATE_ITEM');
export const REMOVE_ITEM = makeAsyncActionSet('REMOVE_ITEM');
export const CLEAR_COLLECTION = makeAsyncActionSet('CLEAR_COLLECTION');

export const ITEMS_PER_PAGE = 20;

// Items per page that presumably would include all the items
export const FETCH_ALL = 'FETCH_ALL';

export function getCollection(
  collectionName,
  url,
  additionalParams = {},
  shouldAppend = false,
  searchString = '',
  page = 1,
  filters = {},
  pageSize = false,
  ordering = ''
) {
  return makeRsaAction(
    GET_COLLECTION,
    url,
    'GET',
    Object.assign(
      {},
      additionalParams,
      {
        search: searchString,
        page: page,
      },
      pageSize && pageSize === FETCH_ALL
        ? { fetch_all: true }
        : { page_size: pageSize },
      !pageSize && { page_size: ITEMS_PER_PAGE },
      ordering && { ordering },
      filters
    ),
    Object.assign({}, additionalParams, {
      collectionName: collectionName,
      search: searchString,
      page: page,
      shouldAppend: shouldAppend,
      filters: filters,
      ordering: ordering,
      source: collectionName,
    })
  );
}

// For Collections that don't use pagination
export function getSimpleCollection(
  collectionName,
  url,
  additionalParams = {}
) {
  return makeRsaAction(
    GET_COLLECTION,
    url,
    'GET',
    additionalParams,
    Object.assign({}, additionalParams, {
      collectionName,
      simple: true,
      source: collectionName,
    })
  );
}

export function addToCollection(collectionName, url, params) {
  return makeRsaAction(ADD_TO_COLLECTION, url, 'POST', params, {
    collectionName,
  });
}

export function deleteItem(collectionName, id, url) {
  return makeRsaAction(DELETE_ITEM, url, 'DELETE', {}, { collectionName, id });
}

export function updateItem(collectionName, url, data) {
  return makeRsaAction(UPDATE_ITEM, url, 'PATCH', data, {
    collectionName,
    source: collectionName,
    data,
  });
}

export function putItem(collectionName, url, data) {
  return makeRsaAction(UPDATE_ITEM, url, 'PUT', data, {
    collectionName,
    source: collectionName,
    data,
  });
}

export function removeItemFromNestedCollection(collectionName, url, data) {
  return makeRsaAction(REMOVE_ITEM, url, 'POST', data, { collectionName });
}

export function clearCollection(collectionName) {
  return {
    type: CLEAR_COLLECTION,
    payload: collectionName,
  };
}

//
//  USERS
//

export const getUsers = getCollection.bind(
  null,
  'users',
  '/accounts/users/',
  {},
  false
);
export function getAllUsers(filters) {
  return getUsers(undefined, undefined, filters, FETCH_ALL);
}
export const getUserOptions = getCollection.bind(
  null,
  'userOptions',
  '/accounts/users/',
  {},
  false
);
export function getAllUserOptions(filters) {
  return getUserOptions(undefined, undefined, filters, FETCH_ALL);
}
export const showMoreUsers = getCollection.bind(
  null,
  'users',
  '/accounts/users/',
  {},
  true
);
export function addUser(organisation, fullName, role, email) {
  return addToCollection('users', '/accounts/users/', {
    organisation,
    full_name: fullName,
    email,
    role,
  });
}
export function updateUser(id, user) {
  return updateItem('users', `/accounts/users/${id}/`, user);
}
export function updateUserStatus(id, status) {
  return updateItem('users', `/accounts/users/${id}/status/`, status);
}

export const getUserImports = getCollection.bind(
  null,
  'userImports',
  '/accounts/users/imports/',
  {},
  false
);

export const getUserCategories = getCollection.bind(
  null,
  'userCategories',
  '/accounts/usercategories/',
  {},
  false
);

export const getUserGroups = getCollection.bind(
  null,
  'groups',
  '/accounts/groups/',
  {},
  false
);

export function deleteUser(id) {
  return deleteItem('users', id, `/accounts/users/${id}/`);
}

//
//  GROUPS
//

const GROUPS_URL = '/organisations/groups/';

export function getGroups(
  organisationId,
  searchString = '',
  page = 1,
  filters = {},
  pageSize = false,
  ordering = ''
) {
  return getCollection(
    'groups',
    GROUPS_URL,
    organisationId && { organisation: organisationId },
    false,
    searchString,
    page,
    filters,
    pageSize,
    ordering
  );
}

export function getAllGroups(filters) {
  return getGroups(undefined, undefined, undefined, filters, FETCH_ALL);
}

export function addGroup(organisation, name, dataAnalyticsFilters) {
  return addToCollection('groups', GROUPS_URL + 'create/', {
    organisation,
    name,
    dataAnalyticsFilters,
  });
}
export function updateGroup(id, group) {
  return updateItem('groups', GROUPS_URL + `${id}/`, group);
}
export function deleteGroup(id) {
  return deleteItem('groups', id, GROUPS_URL + `${id}/`);
}

export function removeUserFromGroup(groupId, userId) {
  return removeItemFromNestedCollection(
    'groups',
    GROUPS_URL + `${groupId}/remove-user/`,
    { group: groupId, user: userId }
  );
}

export const showMoreGroups = getCollection.bind(
  null,
  'groups',
  GROUPS_URL,
  {},
  true
);

//
//  ORGANISATIONS
//

export const getOrganisations = getCollection.bind(
  null,
  'organisations',
  '/organisations/',
  {},
  false
);
// Return a large number of organisations
export function getAllOrganisations(filters) {
  return getOrganisations(undefined, undefined, filters, FETCH_ALL);
}
export const getOrganisationsSimple = getCollection.bind(
  null,
  'organisations-simple',
  '/organisations/list-simple/',
  {},
  false
);

export const getOrganisationsListCreateUser = getCollection.bind(
  null,
  'organisations-list-create-user',
  '/organisations/list-create-user/',
  {},
  false,
  undefined,
  undefined,
  undefined,
  FETCH_ALL
);

export const getOrganisationsListIds = getCollection.bind(
  null,
  'organisations-list-ids',
  '/organisations/list-ids/',
  {},
  false,
  undefined,
  undefined,
  undefined,
  FETCH_ALL
);

export function getOrganisationDetailSimple(organisationId) {
  return getSimpleCollection(
    'organisations-detail-simple',
    `/organisations/${organisationId}/simple/`
  );
}

export const showMoreOrganisations = getCollection.bind(
  null,
  'organisations',
  '/organisations/',
  {},
  true
);

export function getAllOrganisationOptions(filters) {
  return getCollection(
    'organisationOptions',
    '/organisations/',
    {},
    false,
    undefined,
    undefined,
    Object.assign({ simple: true }, filters),
    FETCH_ALL
  );
}

export function addOrganisation(
  name,
  code,
  description,
  accountType,
  hasUnlimitedCredits,
  accountSuperuserName,
  accountSuperuserEmail
) {
  return addToCollection('organisations', '/organisations/', {
    name,
    code,
    description,
    account_type: accountType,
    has_unlimited_credits: hasUnlimitedCredits,
    account_superuser_name: accountSuperuserName,
    account_superuser_email: accountSuperuserEmail,
  });
}

export function updateOrganisation(id, organisation) {
  return updateItem('organisations', `/organisations/${id}/`, organisation);
}

export function upgradeOrganisationType(id, account_type) {
  return updateItem('organisations', `/organisations/${id}/upgrade/`, {
    account_type,
  });
}

export function getAllCategoriesForOrganisation(organisationId) {
  return getCollection(
    'categories',
    `/organisations/${organisationId}/categories/`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    1000
  );
}

export function getAllOrganisationsActivities() {
  return getSimpleCollection(
    'organisationsActivities',
    '/organisations/activities/'
  );
}

export const getCreditHistory = getCollection.bind(
  null,
  'creditHistory',
  '/organisations/credit-history/',
  {},
  false
);

export function getOrgCreditHistory(
  organisationId,
  searchString = '',
  page = 1,
  filters = {},
  pageSize = false,
  ordering = ''
) {
  return getCollection(
    'orgCreditHistory',
    `/organisations/${organisationId}/credit-history/`,
    {},
    false,
    searchString,
    page,
    filters,
    pageSize,
    ordering
  );
}

export const getOrganisationCreditDetail = (organisationId, filters) => {
  return getSimpleCollection(
    'orgCreditDetail',
    `/organisations/${organisationId}/credit-detail/`,
    filters
  );
};

export function updateCreditPot(id, creditPot) {
  return updateItem('creditPots', `/organisations/pot/${id}/`, creditPot);
}

export function updateProductOrganisationReportTemplate(id, data) {
  return updateItem(
    'productOrganisationReportTemplate',
    `/organisations/product-organisation-report-templates/${id}/`,
    data
  );
}

export function updateProductOrganisation(productOrganisationId, data) {
  return updateItem(
    'productOrganisation',
    `/organisations/product-organisations/${productOrganisationId}/`,
    data
  );
}

//
//  PRODUCT_VERSIONS
//

export const getProductVersions = (
  organisationId,
  filters,
  pageSize = false,
  showAll = false
) => {
  const params = Object.assign(
    { show_all: showAll },
    organisationId && { organisation: organisationId },
    filters || {}
  );
  return getCollection(
    'productVersions',
    '/product-versions/',
    params,
    undefined,
    undefined,
    undefined,
    undefined,
    pageSize
  );
};

export const getAllProductVersionsForActivityType = (organisationId, type) => {
  const params = Object.assign(
    type && { activity_type: type },
    organisationId && { organisation: organisationId }
  );
  return getCollection(
    'productVersions',
    '/product-versions/',
    params,
    undefined,
    undefined,
    undefined,
    undefined,
    FETCH_ALL
  );
};

// Return a large number of productVersions
export function getAllProductVersions(filter) {
  return getProductVersions(null, filter, FETCH_ALL);
}

export function cloneProductVersion(productVersionId) {
  return makeRsaAction(
    ADD_TO_COLLECTION,
    `/product-versions/${productVersionId}/clone/`,
    'POST',
    {},
    { collectionName: 'productVersions' }
  );
}

export function getAllProducts(filters = {}) {
  return getSimpleCollection('products', '/products/', filters);
}

//
//  ACTIVITES
//

export function getMyActivities(
  searchString = '',
  page = 1,
  filters = {},
  pageSize = false,
  ordering = ''
) {
  return getCollection(
    'myActivities',
    '/activities/my-activities/',
    {},
    false,
    searchString,
    page,
    filters,
    pageSize,
    ordering
  );
}
export function getAllMyActivities(filters) {
  return getMyActivities(undefined, undefined, filters, FETCH_ALL);
}
export const showMoreMyActivities = getCollection.bind(
  null,
  'myActivities',
  '/activities/my-activities/',
  { context: 'enduser', user_filter: true },
  true
);

export const getActivities = getCollection.bind(
  null,
  'activities',
  '/activities/',
  {},
  false
);
export const showMoreActivities = getCollection.bind(
  null,
  'activities',
  '/activities/',
  {},
  true
);

export const getActivitiesList = getCollection.bind(
  null,
  'activities-list',
  '/activities/list/',
  {},
  false
);

// Return a large number of activites for an organisation
export function getAllActivities(filters) {
  return getActivities('', 1, filters, FETCH_ALL);
}

export function addActivity(activity) {
  return addToCollection('activities', '/activities/', activity);
}

export function updateActivity(id, activity) {
  return putItem('activities', `/activities/${id}/`, activity);
}

export function updateJobProfile(id, jobProfile) {
  return putItem(
    'activities',
    `/activities/job-profile/${id}/update/`,
    jobProfile
  );
}

export function deleteActivity(id) {
  return deleteItem('activities', id, `/activities/${id}/`);
}

export const getActivityReportTypes = activityId => {
  return getSimpleCollection(
    'activityReportTypes',
    `/activities/${activityId}/report-types/`
  );
};

export const getActivityUsersCollectionName = activityId =>
  `activityUsers${activityId}`;

export const getActivityUsers = activityId => {
  return getSimpleCollection(
    getActivityUsersCollectionName(activityId),
    `/activities/${activityId}/user-sessions/`
  );
};

//
//  REPORTS
//

export function getReportsForProductVersion(productVersionId, organisationId) {
  let params = {};
  if (organisationId) {
    params.organisation = organisationId;
  }
  return getSimpleCollection(
    'reports',
    `/reports/product-version/${productVersionId}/`,
    params
  );
}

export function getActivityTemplates(userId) {
  let params = {};
  if (userId) {
    params.user = userId;
  }
  return getSimpleCollection(
    'activity-templates',
    `/activity-templates/`,
    params
  );
}

export function getCreditCosts() {
  return getSimpleCollection('creditCosts', '/organisations/credit-costs/', {});
}

export function getBulkDiscountBandCollections() {
  return getSimpleCollection(
    'bulkDiscountBandCollections',
    '/organisations/discount-band-collections/',
    {}
  );
}

export function getReportCosts() {
  return getSimpleCollection('reportCosts', `/reports/report-costs/`);
}

//
//  SETTINGS
//

export function updateCreditCost(id, cost) {
  return updateItem('creditCosts', `/organisations/credit-costs/${id}/`, cost);
}

export function addDiscountBandCollection(name) {
  return addToCollection(
    'bulkDiscountBandCollections',
    '/organisations/discount-band-collections/',
    name
  );
}

export function updateDiscountBandCollection(id, value) {
  return updateItem(
    'bulkDiscountBandCollections',
    `/organisations/discount-band-collections/${id}/`,
    value
  );
}

export function deleteDiscountBandCollection(id) {
  return deleteItem(
    'bulkDiscountBandCollections',
    id,
    `/organisations/discount-band-collections/${id}/`
  );
}

export function addDiscountBand(bandCollection, discountFrom, percentage) {
  return addToCollection(
    'bulkDiscountBands',
    '/organisations/discount-bands/',
    {
      bulk_discount_collection: bandCollection,
      discount_from: discountFrom,
      percentage,
    }
  );
}

export function updateDiscountBand(id, value) {
  return updateItem(
    'bulkDiscountBands',
    `/organisations/discount-bands/${id}/`,
    value
  );
}

export function deleteDiscountBand(id) {
  return deleteItem(
    'bulkDiscountBands',
    id,
    `/organisations/discount-bands/${id}/`
  );
}

///
///  SHOP
///

export const getPurchasedBasketItems = getCollection.bind(
  null,
  'purchasedBasketItems',
  '/shop/basket-items/',
  {},
  false
);

export const getPurchasedPulses = getCollection.bind(
  null,
  'purchasedPulses',
  '/shop/purchased-pulses/',
  {},
  false
);

export const getPurchasedPulseRespondents = (
  pulseId,
  searchString = '',
  page = 1,
  filters = {},
  pageSize = false,
  ordering = ''
) => {
  return getCollection(
    'purchasedPulseRespondents',
    `/shop/purchased-pulses/${pulseId}/respondents/`,
    {},
    false,
    searchString,
    page,
    filters,
    pageSize,
    ordering
  );
};

export const getAllProductOrganisations = (organisation, filters) => {
  return getCollection(
    'productOrganisations',
    '/product-versions/product-organisations/',
    Object.assign({}, filters, { organisation })
  );
};

export const getPurchasedPulseOrgs = getCollection.bind(
  null,
  'purchasedPulseOrgs',
  '/shop/purchased-pulses/org-list/',
  {},
  false
);

export const getBasketItemOrgs = getCollection.bind(
  null,
  'basketItemOrgs',
  '/shop/basket-items/org-list/',
  {},
  false
);

export const getBasketItemsStatus = basketItemIds => {
  return getSimpleCollection('basketItemStatus', '/shop/basket-items-status/', {
    basket_item: basketItemIds,
  });
};

///
/// PULSES
///

export function getAllMyUserPulses() {
  return getCollection('myUserPulses', '/pulses/my-user-pulses/');
}

export const getPulsesList = getCollection.bind(
  null,
  'pulses',
  '/pulses/',
  {},
  false
);

export function getAllPulseBehaviourHierarchyOptions(filters) {
  return getCollection(
    'pulseBehaviourHierarchyOptions',
    '/pulses/pulse-behaviour-hierarchies/',
    {},
    false,
    undefined,
    undefined,
    Object.assign({ simple: true }, filters),
    FETCH_ALL
  );
}

export function getOrgPulseBehaviourHierarchyOptions(orgId) {
  return getCollection(
    'pulseBehaviourHierarchyOptions',
    `/organisations/${orgId}/flexi-pulse-hierarchies/`,
    {},
    false,
    undefined,
    undefined,
    {},
    FETCH_ALL
  );
}

export function getAllPulseProductOptions() {
  return getCollection(
    'pulseProductOptions',
    '/pulses/pulse-product-options/',
    {},
    false,
    undefined,
    undefined,
    {},
    FETCH_ALL
  );
}
