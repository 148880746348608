import { Map } from 'immutable';

// mapping of report templates to icons
export const REPORT_ICONS = Map({
  ISP: 'static/img/icons/isp-logo.png',
  EAP: 'static/img/icons/eap-logo.png',
  THREE_SIXTY: 'static/img/icons/360-logo.png',
  POSITIVE_RESILIENCE_PROFILER: 'static/img/icons/prp-logo.png',
  RESILIENCE_INDEX_TEAM: 'static/img/icons/RI-team-logo.png',
  EXECUTIVE_SUMMARY: 'static/img/icons/executive-summary-icon.png',
  PROFILER: 'static/img/icons/psycap-potential-logo.png',
  INDIVIDUAL_DEVELOPMENT: 'static/img/icons/individual-development-logo.png',
  INTERVIEW_INSIGHTS: 'static/img/icons/interview-insights-logo.png',
  MAP: 'static/img/icons/map-logo.png',
});
