import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { updateUser } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import { ACTIVITY_TYPES } from '^/components/productVersions/choices';
import Checkbox from '^/components/Checkbox';

export class EditAdminProducts extends PureComponent {
  updateUserProducts(productId, isAdd) {
    const { user } = this.props;
    const productsSet = user.get('accredited_products').toSet();
    const newProductsSet = isAdd
      ? productsSet.add(productId)
      : productsSet.delete(productId);

    this.props.updateUser(user.get('id'), {
      accredited_products: newProductsSet.toList(),
    });
  }

  updateUserAllProducts(isAdd) {
    const { accountProducts, user } = this.props;
    const products = isAdd ? accountProducts.map(each => each.get('id')) : [];

    this.props.updateUser(user.get('id'), { accredited_products: products });
  }

  render() {
    const { accountProducts, user, response } = this.props;
    const userProductIds = user.get('accredited_products');

    return (
      <form className="form-inline">
        <div>
          <div className="form-label wide">
            {i18next.t('Accreditation completed')}:
          </div>
        </div>
        <div className="scrollable mt-none">
          <Table
            className="highlight-rows selectable compact"
            columns={[
              {
                header: (
                  <Checkbox
                    name={'ALL'}
                    checked={accountProducts.every(product =>
                      userProductIds.contains(product.get('id'))
                    )}
                    onChange={event =>
                      this.updateUserAllProducts(event.target.checked)
                    }
                  />
                ),
                value: product => {
                  const productId = product.get('id');
                  return (
                    <Checkbox
                      name={'ALL'}
                      checked={userProductIds.contains(productId)}
                      onChange={event =>
                        this.updateUserProducts(productId, event.target.checked)
                      }
                      disabled={isPending(response)}
                    />
                  );
                },
              },
              {
                header: i18next.t('Assessment type'),
                value: product =>
                  ACTIVITY_TYPES.DISPLAY[product.get('activity_type')],
              },
              {
                header: i18next.t('Product'),
                value: product => product.get('name'),
              },
            ]}
            rowClassName={product =>
              userProductIds.contains(product.get('id')) ? 'selected' : ''
            }
            rows={accountProducts}
          />
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.multiResponses.getIn(['updateItem', 'users']),
  };
}

export default connect(mapStateToProps, { updateUser })(EditAdminProducts);
