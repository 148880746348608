import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import QuantityDropdown from '^/components/dropdown/QuantityDropdown';
import { ShopItemOptionType, Uuid } from '^/reducers/api/types';
import Currency from './Currency';
import { BasketItemData, BasketItemReportQuantity } from './types';

interface Props {
  basketItemData: BasketItemData;
  response: Map<string, any>;
  updateReportQuantityResponse: Map<string, any>;
  onRemove: () => void;
  onUpdateQuantity: (quantity: number) => void;
  onUpdateReportQuantity: (
    basketReportQuantityId: Uuid,
    quantity: number
  ) => void;
}

function definitelyNotNull(onUpdateQuantity: (quantity: number) => void) {
  return (maybeQuantity: number | null) =>
    onUpdateQuantity(maybeQuantity as number);
}

interface RowProps {
  id: string;
  name: string | null;
  unitCost: string | number;
  quantity: number;
  minQuantity: number;
  totalCost: string | number;
  onChangeQuantity: (value: number) => void;
  response: Map<string, any>;
}

const BasketItemRow: React.FunctionComponent<RowProps> = ({
  id,
  name,
  unitCost,
  quantity,
  totalCost,
  minQuantity,
  onChangeQuantity,
  response,
}) => (
  <div className="basketitem-payment">
    <span className="basketitem-name">{name}</span>
    <span className="pr-sm">
      <Currency value={unitCost} /> + {i18next.t<string>('VAT')}
    </span>
    <QuantityDropdown
      value={quantity}
      minQuantity={minQuantity}
      maxDropdownValue={10}
      onChange={definitelyNotNull(onChangeQuantity)}
      response={response}
      fieldName={id}
    />
    <span className="semi-bold-text basketitem-total">
      <Currency value={totalCost} />
    </span>
  </div>
);

const BasketItem: React.FunctionComponent<Props> = ({
  basketItemData: { basketItem, shopItemOption, shopItem },
  response,
  onRemove,
  onUpdateQuantity,
  onUpdateReportQuantity,
  updateReportQuantityResponse,
}) => (
  <div className="basketitem">
    <div>
      <h3 className="display-inline">{shopItem.name}</h3>
      <a
        className="ml-sm"
        onClick={onRemove}
        role="button"
        aria-label="Remove from basket"
      >
        {i18next.t<string>('Remove')}
      </a>
      <ReactMarkdown
        source={shopItemOption.description || ''}
        className="mt-base"
      />
    </div>
    {shopItemOption.type !== ShopItemOptionType.Product ||
    shopItemOption.product_variant ? (
      <BasketItemRow
        id={shopItemOption.id}
        name={`${shopItemOption.label}`}
        unitCost={shopItemOption.cost || ''}
        totalCost={basketItem.total_cost}
        quantity={basketItem.quantity}
        minQuantity={1}
        onChangeQuantity={onUpdateQuantity}
        response={response}
      />
    ) : (
      basketItem.report_quantities.map(
        (reportQuantity: BasketItemReportQuantity) => (
          <BasketItemRow
            key={reportQuantity.id}
            id={reportQuantity.id}
            name={`${reportQuantity.report_template.name} Report`}
            unitCost={reportQuantity.cost_per_report}
            totalCost={reportQuantity.cost_per_report * reportQuantity.quantity}
            quantity={reportQuantity.quantity}
            minQuantity={basketItem.report_quantities.length > 1 ? 0 : 1}
            onChangeQuantity={onUpdateReportQuantity.bind(
              null,
              reportQuantity.id
            )}
            response={updateReportQuantityResponse}
          />
        )
      )
    )}
  </div>
);

export default BasketItem;
