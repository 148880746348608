import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import PureComponent from './PureComponent';
import { toggleMobileMenu } from '../actions/actions';

export class Navigation extends PureComponent {
  getClassName() {
    return classnames('navigation', {
      'navigation-mobile-expanded': this.props.mobileMenuExpanded,
    });
  }

  render() {
    return (
      <nav className={this.getClassName()}>
        <div className="navigation-wrapper">
          <ul className="navigation-items">{this.props.children}</ul>
        </div>
        <button
          className="mobile-nav-icon"
          onClick={() => this.props.toggleMobileMenu()}
          tabIndex="0"
        >
          <FontAwesomeIcon icon={faBars} size="2x" />
        </button>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    mobileMenuExpanded: state.ui.get('mobileMenuExpanded'),
  };
}

export default connect(mapStateToProps, { toggleMobileMenu })(Navigation);
