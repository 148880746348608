import classnames from 'classnames';
import i18next from 'i18next';
import React from 'react';

import { PdfStatus } from '^/reducers/api/types';
import { formatDate } from '^/utils';

type ClassNameKey = PdfStatus | 'DEFAULT';

type ClassNames = {
  readonly [T in ClassNameKey]: {
    unselected: string;
    selected: string;
  };
};

const CLASSNAMES: ClassNames = {
  DEFAULT: {
    unselected: 'btn-buy',
    selected: 'btn-buy-selected',
  },
  [PdfStatus.Unpopulated]: {
    unselected: 'btn-in-progress',
    selected: 'btn-in-progress',
  },
  [PdfStatus.NotStarted]: {
    unselected: 'btn-in-progress',
    selected: 'btn-in-progress',
  },
  [PdfStatus.Progress]: {
    unselected: 'btn-in-progress',
    selected: 'btn-in-progress',
  },
  [PdfStatus.Complete]: {
    unselected: 'btn-download',
    selected: 'btn-download-selected',
  },
  [PdfStatus.Failed]: {
    unselected: 'btn-errored',
    selected: 'btn-errored',
  },
};

function getTooltip(
  status: PdfStatus | undefined,
  disabled?: boolean,
  hasVariant?: boolean,
  downloadBlocked?: boolean,
  isUnavailable?: boolean,
  created?: string,
  completed?: string | null,
  roleName?: string,
  reportName?: string
) {
  if (status === PdfStatus.Failed) {
    return i18next.t<string>('Report failed');
  }

  if (isUnavailable) {
    return hasVariant
      ? i18next.t<string>('Contact PeopleWise for access')
      : i18next.t<string>('You do not have access to this report');
  }

  if (disabled) {
    if (!status) {
      return i18next.t<string>('PARAGRAPH cannot select ready to purchase');
    }

    if (status === PdfStatus.Complete) {
      return i18next.t<string>('PARAGRAPH cannot select already purchased');
    }
  }

  if (reportName && completed) {
    if (roleName) {
      return i18next.t<string>(
        '{{reportName}}, {{roleName}}, Assessment completed: {{completed}}',
        { reportName, roleName, completed: formatDate(completed) }
      );
    }

    return i18next.t<string>(
      '{{reportName}}, Assessment completed: {{completed}}',
      { reportName, completed: formatDate(completed) }
    );
  }

  if (status === PdfStatus.Complete) {
    if (downloadBlocked) {
      return i18next.t<string>('PARAGRAPH report previously purchased');
    }

    if (created) {
      return i18next.t<string>('Report generated: {{created}}', {
        created: formatDate(created),
      });
    }
  }

  if (completed) {
    return i18next.t<string>('Assessment completed: {{completed}}', {
      completed: formatDate(completed),
    });
  }
}

function getClassName(
  isSelected?: boolean,
  status?: PdfStatus,
  downloadBlocked?: boolean,
  isUnavailable?: boolean
) {
  const className =
    CLASSNAMES[status || 'DEFAULT'][isSelected ? 'selected' : 'unselected'];

  if ((!status || status === PdfStatus.Complete) && isUnavailable) {
    return `${className}-unavailable`;
  }
  if (status === PdfStatus.Complete && downloadBlocked) {
    return `${className}-blocked`;
  }

  return className;
}

interface Props {
  label: string;
  status?: PdfStatus;
  disabled?: boolean;
  downloadBlocked?: boolean;
  isUnavailable?: boolean;
  hasVariant?: boolean;
  isSelected?: boolean;
  created?: string;
  completed?: string | null;
  onSelect: () => void;
  extraClass?: string;
  roleName?: string;
  reportName?: string;
}

const SelectableReport: React.FunctionComponent<Props> = ({
  label,
  status,
  disabled,
  isSelected,
  downloadBlocked,
  isUnavailable,
  hasVariant,
  created,
  completed,
  onSelect,
  extraClass,
  roleName,
  reportName,
}) => {
  const tooltip = getTooltip(
    status,
    disabled,
    hasVariant,
    downloadBlocked,
    isUnavailable,
    created,
    completed,
    roleName,
    reportName
  );
  const disableCheckbox =
    disabled || (status && status !== PdfStatus.Complete) || isUnavailable;
  const className = getClassName(
    isSelected,
    status,
    downloadBlocked,
    isUnavailable
  );

  return (
    <label
      className={classnames('btn margin-none', className, extraClass, {
        disabled,
      })}
      tabIndex={0}
      title={tooltip}
    >
      <span className="inline-svg" />
      {label}
      <input
        type="checkbox"
        onChange={onSelect}
        checked={isSelected}
        disabled={disableCheckbox}
      />
    </label>
  );
};

export default SelectableReport;
