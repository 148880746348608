import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import ContentBox from '../ContentBox';
import CodeSample from './CodeSample';

export default class Layout extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Layouts</PageHeader>
        <h3>Grids</h3>
        <p>
          Peoplewise use a series of responsive grid classes to lay things out
          at different breakpoints. A grid is 12 units, so{' '}
          <strong>col-xs-12</strong> would be full width, whereas
          <strong>col-xs-6</strong> would be <strong>12 / 6 = 2</strong> so{' '}
          <strong>2 columns</strong>
        </p>

        <div className="styleguide-grid-sample">
          <div className="row">
            <div className="col-xs-12 col-md-12 col-lg-12 col-xl-12">12</div>

            <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">6</div>
            <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">6</div>

            <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>
            <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>
            <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>

            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>

            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>

            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>

            <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
            <div className="col-xs-12 col-md-11 col-lg-11 col-xl-11">11</div>

            <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
            <div className="col-xs-12 col-md-10 col-lg-10 col-xl-10">10</div>

            <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
            <div className="col-xs-12 col-md-9 col-lg-9 col-xl-9">9</div>

            <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>
            <div className="col-xs-12 col-md-8 col-lg-8 col-xl-8">8</div>

            <div className="col-xs-12 col-md-5 col-lg-5 col-xl-5">5</div>
            <div className="col-xs-12 col-md-7 col-lg-7 col-xl-7">7</div>

            <div className="col-xs-12 col-md-5 col-lg-5 col-xl-5">5</div>
            <div className="col-xs-12 col-md-5 col-lg-5 col-xl-5 col-md-offset-2 col-lg-offset-2 col-xl-offset-2">
              5 offset 2
            </div>

            <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">6</div>
            <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6 col-md-pull-2 col-lg-pull-2 col-xl-pull-2">
              6 pull 2
            </div>
          </div>
        </div>

        <h4>example grid code</h4>
        <CodeSample
          code={`<div className="row">
  <div className="col-xs-12 col-md-12 col-lg-12 col-xl-12">12</div>

  <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">6</div>
  <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">6</div>

  <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>
  <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>
  <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>

  <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
  <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
  <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
  <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>

  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>

  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>

  <div className="col-xs-12 col-md-1 col-lg-1 col-xl-1">1</div>
  <div className="col-xs-12 col-md-11 col-lg-11 col-xl-11">11</div>

  <div className="col-xs-12 col-md-2 col-lg-2 col-xl-2">2</div>
  <div className="col-xs-12 col-md-10 col-lg-10 col-xl-10">10</div>

  <div className="col-xs-12 col-md-3 col-lg-3 col-xl-3">3</div>
  <div className="col-xs-12 col-md-9 col-lg-9 col-xl-9">9</div>

  <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4">4</div>
  <div className="col-xs-12 col-md-8 col-lg-8 col-xl-8">8</div>

  <div className="col-xs-12 col-md-5 col-lg-5 col-xl-5">5</div>
  <div className="col-xs-12 col-md-7 col-lg-7 col-xl-7">7</div>

  <div className="col-xs-12 col-md-5 col-lg-5 col-xl-5">5</div>
  <div className="col-xs-12 col-md-5 col-lg-5 col-xl-5 col-md-offset-2 col-lg-offset-2 col-xl-offset-2">
    5 offset 2
  </div>

  <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6">6</div>
  <div className="col-xs-12 col-md-6 col-lg-6 col-xl-6 col-md-pull-2 col-lg-pull-2 col-xl-pull-2">
    6 pull 2
  </div>
</div>`}
        />

        <h3>Pages</h3>
        <p>
          page header and content tags - these both output a{' '}
          <strong>row</strong>
          div with a <strong>col-xs-12</strong> full width column div inside it,
          nesting whatever content you put within them.
        </p>
        <PageHeader>My Page Title</PageHeader>
        <PageContent>
          <p>my page content</p>
        </PageContent>

        <CodeSample
          code={`<PageHeader>
  My Page Title
</PageHeader>
<PageContent>
  <p>my page content</p>
</PageContent>`}
        />

        <PageContent>
          <h3>Content Boxes</h3>
          <p>Please use ContentBox inside PageContent</p>
          <p>
            Content Boxes are a div with classes{' '}
            <strong>col-xs-12 content-box</strong>.
          </p>
          <ContentBox>Woo - This is a content box!</ContentBox>
          <CodeSample
            code={`<ContentBox>Woo - This is a content box!</ContentBox>`}
          />

          <h3>Dividing a Content Box</h3>
          <ContentBox>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <h2>Content Box</h2>

                <p>
                  <strong>Pellentesque habitant morbi tristique</strong>
                  senectus et netus et malesuada fames ac turpis egestas.
                </p>
              </div>
              <div className="col-xs-12 col-sm-6">
                <h2>Wow, columns!</h2>
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong>
                  senectus et netus et malesuada fames ac turpis egestas.
                </p>
              </div>
            </div>
          </ContentBox>

          <CodeSample
            code={`<ContentBox>
<div className="row">
  <div className="col-xs-12 col-sm-6">
    <h2>Content Box</h2>

    <p><strong>Pellentesque habitant morbi tristique</strong>
      senectus et netus et malesuada fames ac turpis egestas.</p>
  </div>
  <div className="col-xs-12 col-sm-6">
    <h2>Wow, columns!</h2>
    <p><strong>Pellentesque habitant morbi tristique</strong>
      senectus et netus et malesuada fames ac turpis egestas.</p>
  </div>
</div>
</ContentBox>`}
          />
        </PageContent>
      </div>
    );
  }
}
