import { fromJS } from 'immutable';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { openCreditAddModal, openPurchaseCreditsModal } from '^/actions/modals';
import { administerOrganisations } from '^/capabilities';
import Alert, { AlertType } from '^/components/Alert';
import PureComponent from '^/components/PureComponent';
import { Uuid } from '^/reducers/api/types';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';

interface DispatchProps {
  openCreditAddModal: typeof openCreditAddModal;
  openPurchaseCreditsModal: typeof openPurchaseCreditsModal;
}

interface StateProps {
  canUserAdministerOrganisations: boolean;
}

interface OwnProps {
  organisation: Readonly<{
    credit: number;
    id: Uuid;
    name: string;
  }>;
}

type Props = StateProps & DispatchProps & OwnProps;

export class CreditWarning extends PureComponent<Props> {
  public render() {
    const { organisation, canUserAdministerOrganisations } = this.props;
    const { name: organisationName, credit } = organisation;
    const creditBalance = credit || 0;
    return (
      <div className="action-bar-error">
        <Alert type={AlertType.Error} className="margin-none">
          {canUserAdministerOrganisations ? (
            <Trans
              i18nKey="TRANS reports actionbar topup alert pw admin"
              values={{ organisationName, creditBalance }}
              components={[
                <a
                  key="add-more-credits"
                  onClick={this.openCreditAddModal}
                  role="button"
                  aria-label="Add more credits"
                >
                  Add more credits
                </a>,
              ]}
            />
          ) : (
            <Trans
              i18nKey="TRANS reports actionbar topup alert account admin"
              values={{ creditBalance }}
              components={[
                <a
                  key="buy-more-credits"
                  onClick={this.openPurchaseCreditsModal}
                  role="button"
                  aria-label="Buy more credits"
                >
                  Buy more credits
                </a>,
              ]}
            />
          )}
        </Alert>
      </div>
    );
  }

  private openCreditAddModal = () => {
    this.props.openCreditAddModal(fromJS(this.props.organisation));
  };

  private openPurchaseCreditsModal = () => {
    this.props.openPurchaseCreditsModal(this.props.organisation.id);
  };
}

function mapStateToProps(state: StoreState) {
  return {
    canUserAdministerOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
  };
}

export default connect(mapStateToProps, {
  openCreditAddModal,
  openPurchaseCreditsModal,
})(CreditWarning);
