import { formatDate } from '^/utils';

export const PDF_STATUS = {
  UNPOPULATED: 'UNPOPULATED',
  NOTSTARTED: 'NOTSTARTED',
  PROGRESS: 'PROGRESS',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
};

export function isInProgress(report) {
  return [
    PDF_STATUS.UNPOPULATED,
    PDF_STATUS.NOTSTARTED,
    PDF_STATUS.PROGRESS,
  ].includes(report.get('pdf_status'));
}

export const REPORT_TEMPLATE_CODES = {
  JOB_MATCH: 'JM',
};

export const createdByUser = user => report =>
  report.get('manually_generated_by') === user.get('id');

export function getName(report) {
  return (
    `${report.get('template_code_display')}` +
    `_${report.getIn(['user', 'full_name']) ||
      report.getIn(['report_team', 'name'])}` +
    `_${formatDate(report.get('created'))}`
  );
}
