import React, { useState } from 'react';
import classNames from 'classnames';

interface TabConfig {
  id: string;
  label: string;
  renderer: JSX.Element;
  noPadding?: boolean;
}

interface Props {
  defaultActive: string;
  transparent?: boolean;
  config: TabConfig[];
}
export const TabbedContainer: React.FC<Props> = ({
  defaultActive,
  config,
  transparent,
}) => {
  const [activeTabId, setActiveTabId] = useState(defaultActive);

  const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
    setActiveTabId(e.currentTarget.id);
  };

  const currentSelectedTabConfig = config.find(tab => tab.id === activeTabId);

  return (
    <>
      <div className="tab-container-header">
        {config.map(header => (
          <div
            id={header.id}
            onClick={handleSelect}
            key={header.id}
            className={classNames('tab-heading', {
              active: header.id === activeTabId,
            })}
          >
            {header.label}
          </div>
        ))}
      </div>

      {currentSelectedTabConfig && (
        <div
          className={classNames(
            'tab-container-content',
            { transparent: transparent },
            { 'no-padding': currentSelectedTabConfig.noPadding }
          )}
        >
          {currentSelectedTabConfig.renderer}
        </div>
      )}
    </>
  );
};
