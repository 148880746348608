import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars

import Alert, { AlertType } from '^/components/Alert';
import PureComponent from '^/components/PureComponent';
import { joinWithAnd } from '^/utils-ts';

interface Props {
  unavailableProducts: Array<Map<string, any>>;
}

export default class LostProductAccessWarning extends PureComponent<Props> {
  public render() {
    return (
      <Alert type={AlertType.Warning}>
        {i18next.t<string>(
          `You no longer have access to {{productNames}}. Go to the shop to buy accreditation or a corresponding assessment that has feedback included`,
          {
            productNames: joinWithAnd(
              this.props.unavailableProducts.map(product => product.get('name'))
            ),
          }
        )}
      </Alert>
    );
  }
}
