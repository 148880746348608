import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  onDelete: () => void;
  descriptionText: string;
  actionText: string;
  actionButtonClass?: string;
}

export type Props = OwnProps & DispatchProps;

export class AdvancedAnalyticsConfirmModal extends React.PureComponent<Props> {
  action() {
    this.props.closeTopModal(false);
    this.props.onDelete();
  }

  render() {
    const { actionText, actionButtonClass, descriptionText } = this.props;
    return (
      <div>
        <p>{descriptionText}</p>
        <div className="pull-right">
          <button
            className="btn btn-secondary"
            onClick={() => this.props.closeTopModal()}
          >
            {i18next.t<string>('Cancel')}
          </button>
          <button
            className={classNames('btn btn-primary', actionButtonClass)}
            onClick={this.action.bind(this)}
          >
            {actionText}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(undefined, { closeTopModal })(
  AdvancedAnalyticsConfirmModal
);
