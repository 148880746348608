import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class MAPPracticeQuestionIcon extends PureComponent {
  render() {
    const { correct } = this.props;

    return (
      <span
        className={classNames(
          'icon-answer-result',
          correct ? 'correct' : 'incorrect'
        )}
      />
    );
  }
}

MAPPracticeQuestionIcon.propTypes = {
  correct: PropTypes.bool,
};
