import React, { PropsWithChildren } from 'react';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

interface ExpandableListProps {
  title: string;
  onToggleList: () => void;
  isListOpen?: boolean;
}

const ExpandableList: React.FunctionComponent<PropsWithChildren<
  ExpandableListProps
>> = ({ isListOpen, onToggleList, title, children }) => {
  return (
    <div
      className={classNames('expandable-list', isListOpen ? 'open' : 'closed')}
    >
      <div className="header" onClick={onToggleList}>
        <div className="title">
          <span>{title}</span>
        </div>
        <div className="chevron">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default ExpandableList;
