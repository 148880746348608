import React from 'react';

export default () => (
  <svg
    style={{ width: 13, height: 13 }}
    viewBox="3 1 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8c-1.714 0-5.266 4-5.266 4m0 0s3.123 4 5.266 4m-5.266-4H4"
      stroke="#1C75BC"
      strokeWidth="2"
    />
    <path
      d="m20 16-4.5 2.598v-5.196L20 16ZM20 8l-4.5 2.598V5.402L20 8Z"
      fill="#1C75BC"
    />
  </svg>
);
