import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from './PureComponent';

export default class PageContent extends PureComponent {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12">{this.props.children}</div>
      </div>
    );
  }
}
