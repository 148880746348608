import { UPDATE_PROGRESS } from '../actions/actions';
import { pollProgressAndUpdate } from '../actions/actionSequences';

export default store => next => action => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      const payload = action.payload;
      if (payload.importId && payload.progress < 100) {
        setTimeout(
          () =>
            store.dispatch(
              pollProgressAndUpdate(payload.importId, payload.key)
            ),
          1000
        );
      }
  }

  return next(action);
};
