/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'; // eslint-disable-line no-unused-vars
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { withRouter } from '^/withRouter';
import { verifyValidationCode } from '^/actions/actions';
import { setLanguageAndReload } from '^/actions/actionSequences';
import { isPending, hasFailed, hasSucceeded } from '^/responseStates';
import PureComponent from './PureComponent';
import Loading from './Loading';
import NoAuth from './NoAuth';
import { getQueryValue } from '^/utils-ts';

export class VerifyValidationCode extends PureComponent {
  componentDidMount() {
    const {
      langCode,
      router: {
        location: { pathname },
      },
    } = this.props;
    langCode && this.props.setLanguageAndReload(langCode);
    this.props.verifyValidationCode(this.props.validationCode);
  }

  handleResponse(response) {
    const { userPulseId } = this.props;
    const loginLink = userPulseId
      ? `/login/?userpulse=${userPulseId}`
      : '/login/';

    if (isPending(response)) {
      return (
        <div>
          <Loading />
          <p>{i18next.t('Checking validation code...')}</p>
        </div>
      );
    }

    if (hasSucceeded(response)) {
      const hasActivity = this.props.user.get('has_activity');
      return (
        <div>
          <p>{i18next.t('Your account has been verified successfully!')}</p>
          <p>
            {hasActivity ? (
              <Trans i18nKey="TRANS login proceed to activity">
                Now <a onClick={() => this.props.push(loginLink)}>log in</a> to
                proceed to your Activity.
              </Trans>
            ) : (
              <Trans i18nKey="TRANS now login">
                Now <a onClick={() => this.props.push(loginLink)}>log in.</a>
              </Trans>
            )}
          </p>
        </div>
      );
    }

    if (hasFailed(response)) {
      return (
        <div>
          <p className="text-error">
            {i18next.t('There was a problem accessing that page.')}
          </p>
          <p>
            <Trans i18nKey="TRANS Please log in here">
              Please{' '}
              <a onClick={() => this.props.push(loginLink)}>log in here</a>.
            </Trans>
          </p>
        </div>
      );
    }

    return <p>'...'</p>;
  }

  render() {
    return (
      <NoAuth>
        <div className="form-horizontal">
          <h2>{i18next.t('Verify Validation Code')}</h2>
          {this.handleResponse(this.props.response)}
        </div>
      </NoAuth>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    response: state.responses.get('verifyValidationCode'),
    user: state.userProfile,
    validationCode: props.router.params.validationCode,
    langCode: getQueryValue(props, 'lang_code'),
    userPulseId: getQueryValue(props, 'userpulse'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    verifyValidationCode,
    push,
    setLanguageAndReload,
  })(VerifyValidationCode)
);
