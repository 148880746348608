import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map } from 'immutable';

import ListPage from '^/components/ListPage';
import ListPagePagination from '^/components/ListPagePaginationWrapper';
import { getPurchasedPulseRespondents } from '^/actions/collections';
import { getPulseRespondentsSummary } from '^/actions/actions';
import { closeModal } from '^/actions/modals';
import { StoreState } from '^/store';
import { selectCollection } from '^/selectors';
import PurchasedPulseBreakdownSummary from './PurchasedPulseBreakdownSummary';
import { MODALS } from '^/components/modals/types';
import { isPending } from '^/responseStates';

const HEADERS = [
  {
    title: i18next.t<string>('Respondent'),
    field: 'user__full_name',
    sortable: true,
  },
  {
    title: i18next.t<string>('Type'),
    field: 'pulse__product_name',
    sortable: false,
  },
  {
    title: i18next.t<string>('Price'),
    field: 'name',
    sortable: false,
  },
];

interface DispatchProps {
  getPurchasedPulseRespondents: typeof getPurchasedPulseRespondents;
  getPulseRespondentsSummary: typeof getPulseRespondentsSummary;
  closeModal: typeof closeModal;
}

interface StateProps {
  response: any;
  respondents: any;
  collection: Map<string, any>;
  summaryData: Map<string, any>;
  loading: boolean;
}

interface OwnProps {
  pulseId: string;
  modalId: MODALS;
}

export type Props = OwnProps & DispatchProps & StateProps;

export class PurchasedPulseBreakdownModal extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getPurchasedPulseRespondents(this.props.pulseId);
    this.props.getPulseRespondentsSummary(this.props.pulseId);
  }

  private renderRespondent = (respondent: any) => {
    return (
      <tr>
        <td>{respondent.getIn(['user', 'full_name'])}</td>
        <td>{respondent.getIn(['pulse', 'product_name'])}</td>
        <td>N/A</td>
      </tr>
    );
  };

  private onSetOrdering = (ordering: string | undefined) => {
    const { collection } = this.props;

    const reverseOrdering = ordering === collection.get('ordering', '');
    this.props.getPurchasedPulseRespondents(
      this.props.pulseId,
      '',
      1,
      {},
      false,
      reverseOrdering ? `-${ordering}` : ordering
    );
  };

  private loadPage(page: number) {
    const { collection } = this.props;
    const ordering = collection.get('ordering', '');
    this.props.getPurchasedPulseRespondents(
      this.props.pulseId,
      '',
      page,
      {},
      false,
      ordering
    );
  }

  public render() {
    const { summaryData } = this.props;
    return (
      <div>
        {summaryData && (
          <PurchasedPulseBreakdownSummary
            summaryData={summaryData.toJS()}
            modalId={this.props.modalId}
          />
        )}
        <ListPage
          headers={HEADERS}
          items={this.props.respondents.get('items')}
          typeNamePlural={i18next.t<string>('users')}
          scrollable
          listClassName="user-grid"
          searchHeaders={'full_name'}
          tableClassName={'table-default'}
          response={this.props.response}
          renderer={this.renderRespondent}
          onFilterChange={() => {}}
          onOrderingChange={this.onSetOrdering}
          hideBorder
        >
          <ListPagePagination
            pageSize={20}
            collection={this.props.collection}
            onSelectPage={(page: any) => this.loadPage(page)}
          />
        </ListPage>
        <div className="pull-right">
          <button
            disabled={this.props.loading}
            className="btn btn-primary"
            onClick={() => this.props.closeModal(this.props.modalId)}
          >
            {i18next.t<string>('Close')}
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    response: state.responses.get('getCollection'),
    respondents: state.collections.get('purchasedPulseRespondents', Map()),
    collection: selectCollection(state, 'purchasedPulseRespondents'),
    summaryData:
      state.responses.get('pulseRespondentsSummary').get('success') &&
      state.responses.get('pulseRespondentsSummary').get('payload'),
    loading: isPending(state.responses.get('getCollection')),
  };
}

export default connect(mapStateToProps, {
  getPurchasedPulseRespondents,
  getPulseRespondentsSummary,
  closeModal,
})(PurchasedPulseBreakdownModal);
