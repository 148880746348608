import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import Img from '../Img';
import CodeSample from './CodeSample';

export default class Images extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Images</PageHeader>
        <PageContent>
          <p className="lead">You'll mostly be using Circular - Cover</p>

          <div className="row">
            <div className="col-xs-4">
              <p>Circular</p>
              <Img circular src="http://placehold.it/250x350" />
              <CodeSample
                code={`<Img circular src="http://placehold.it/250x350" />`}
              />
            </div>

            <div className="col-xs-4">
              <p>Circular - Contain</p>
              <Img circular contain src="http://placehold.it/250x350" />
              <CodeSample
                code={`<Img circular contain src="http://placehold.it/250x350" />`}
              />
            </div>

            <div className="col-xs-4">
              <p>Circular - Cover</p>
              <Img circular cover src="http://placehold.it/250x350" />
              <CodeSample
                code={`<Img circular cover src="http://placehold.it/250x350" />`}
              />
            </div>

            <div className="col-xs-4">
              <p>Square</p>
              <Img square src="http://placehold.it/350x250" />
              <CodeSample
                code={`<Img square src="http://placehold.it/250x350" />`}
              />
            </div>

            <div className="col-xs-4">
              <p>Square - Contain</p>
              <Img square contain src="http://placehold.it/350x250" />
              <CodeSample
                code={`<Img square contain src="http://placehold.it/350x250" />`}
              />
            </div>

            <div className="col-xs-4">
              <p>Square - Cover</p>
              <Img square cover src="http://placehold.it/350x250" />
              <CodeSample
                code={`<Img square cover src="http://placehold.it/350x250" />`}
              />
            </div>

            <div className="col-xs-4">
              <p>Default (responsive)</p>
              <Img src="http://placehold.it/250x350" />
              <CodeSample code={`<Img src="http://placehold.it/250x350" />`} />
            </div>

            <div className="col-xs-4">
              <p>Default (with link & hover effect)</p>
              <a>
                <Img src="http://placehold.it/250x350" />
              </a>
              <CodeSample
                code={`<a><Img src="http://placehold.it/250x350" /></a>`}
              />
            </div>

            <div className="col-xs-4">
              <p>Square / Circular (with link & hover effect)</p>

              <a>
                <Img circular cover src="http://placehold.it/250x350" />
              </a>
              <CodeSample
                code={`<a><Img circular cover src="http://placehold.it/250x350" /></a>`}
              />
            </div>
          </div>
        </PageContent>
      </div>
    );
  }
}
