import i18next from 'i18next';
import { Moment } from 'moment';
import React from 'react';

import ToggleSwitch from '^/components/buttons/ToggleSwitch';
import DateRangePicker from '^/components/date/DateRangePicker';
import Dropdown from '^/components/dropdown/Dropdown';
import { FormItem } from '^/components/forms/FormItem';
import { AllOrgs, Uuid } from '^/reducers/api/types';
import ActivitiesDropdown from './ActivitiesDropdown';
import GroupsDropdown from './GroupsDropdown';
import ProductsDropdown from './ProductsDropdown';

interface Props {
  canUserAdministerOrganisations: boolean;
  organisations: AllOrgs;
  onOrganisationClick: (orgId: string | number | null) => void;
  isLoadingTable: boolean;
  initialOrganisation: Uuid | null;
  clearActivityGroupSelection: () => void;
  orgId: Uuid | null;
  selectedDate: Readonly<{
    from?: Date;
    to?: Date;
  }>;
  setDate: (fieldName: string, date?: Moment) => void;
}

interface State {
  activitySelected: boolean;
}

export default class ReportsFilters extends React.Component<Props, State> {
  public readonly state = {
    activitySelected: true,
  };

  public render() {
    const {
      canUserAdministerOrganisations,
      organisations,
      onOrganisationClick,
      isLoadingTable,
      initialOrganisation,
      orgId,
      selectedDate,
      setDate,
    } = this.props;
    const { activitySelected } = this.state;

    const toggleItems = [
      {
        id: 'activity',
        name: i18next.t<string>('Activity'),
        selected: activitySelected,
      },
      {
        id: 'group',
        name: i18next.t<string>('Group'),
        selected: !activitySelected,
      },
    ];

    return (
      <div className="reports-control-bar">
        {canUserAdministerOrganisations && (
          <div className="form-block">
            <FormItem label={i18next.t<string>('Account')}>
              <Dropdown
                title={i18next.t<string>('Choose an organisation')}
                items={organisations}
                onClick={onOrganisationClick}
                loading={isLoadingTable}
                failed={false}
                defaultValue={initialOrganisation || undefined}
              />
            </FormItem>
          </div>
        )}
        <div className="form-block">
          <FormItem
            label={[
              `${i18next.t<string>('Filter by')} `,
              <ToggleSwitch
                key={1}
                items={toggleItems}
                onClick={this.toggleActivityOrGroup}
                disabled={isLoadingTable || !orgId}
              />,
            ]}
          >
            {activitySelected ? <ActivitiesDropdown /> : <GroupsDropdown />}
          </FormItem>
        </div>
        <div className="form-block">
          <FormItem label={i18next.t<string>('Products')}>
            <ProductsDropdown />
          </FormItem>
        </div>

        <div className="form-block">
          <FormItem label={i18next.t<string>('Assessment completion date')}>
            <DateRangePicker
              clearable
              fitWidth
              onSave={setDate}
              first={{
                defaultValue: i18next.t<string>('From'),
                fieldName: 'from',
                value: selectedDate.from,
                disabled: isLoadingTable || !orgId,
              }}
              second={{
                defaultValue: i18next.t<string>('To'),
                fieldName: 'to',
                value: selectedDate.to,
                disabled: isLoadingTable || !orgId,
              }}
            />
          </FormItem>
        </div>
      </div>
    );
  }

  private toggleActivityOrGroup = () => {
    this.props.clearActivityGroupSelection();
    this.setState({ activitySelected: !this.state.activitySelected });
  };
}
