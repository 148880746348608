import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { SUPPORT_EMAIL } from '^/settings';
import { isPending, hasFailed } from '^/responseStates';
import PureComponent from '^/components/PureComponent';
import WithPopover from '^/components/WithPopover';
import Loading from '^/components/Loading';
import { getCreditCosts, getReportCosts } from '^/actions/collections';
import PurchaseCreditsForm from './PurchaseCreditsForm';
import AccountCreditBalance from './AccountCreditBalance';
import BulkDiscountsTable from './BulkDiscountsTable';
import ProductCostsTable from './ProductCostsTable';
import { loadOrganisation } from '^/actions/items';
import Alert, { AlertType } from '^/components/Alert';

export const CURRENCY = 'GBP';

export class PurchaseCreditsModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getCreditCosts();
    this.props.getReportCosts();
    this.props.loadOrganisation(this.props.accountId);
  }

  render() {
    const {
      user,
      account,
      getCreditCostsResponse,
      getStripeInvoiceResponse,
      creditCosts,
      reportCosts,
    } = this.props;
    if (isPending(getCreditCostsResponse) || !creditCosts || !account) {
      return <Loading />;
    }
    const creditCost = creditCosts
      .find(each => each.get('currency') === CURRENCY)
      .get('cost');
    const discountBands = account.getIn([
      'bulk_discount_band_collection',
      'bulkdiscountband_set',
    ]);

    return (
      <div>
        <AccountCreditBalance account={account} />

        {hasFailed(getStripeInvoiceResponse) && (
          <Alert type={AlertType.Error}>
            {i18next.t('Purchase failed. You have not been charged.')}
          </Alert>
        )}

        {account && reportCosts && (
          <WithPopover
            className="purchase-credits-popover-reports"
            popover={
              <ProductCostsTable account={account} reportCosts={reportCosts} />
            }
            below
          >
            <a>{i18next.t('Products')}</a>
          </WithPopover>
        )}

        {discountBands && discountBands.size && (
          <WithPopover
            className="purchase-credits-popover-discounts"
            popover={<BulkDiscountsTable discountBands={discountBands} />}
            below
          >
            <a>{i18next.t('Discounts')}</a>
          </WithPopover>
        )}
        <PurchaseCreditsForm
          creditCost={creditCost}
          currency={CURRENCY}
          email={user.get('email')}
          response={getStripeInvoiceResponse}
          discountBands={discountBands}
          account={account}
        />

        <hr />
        <p className="text-center">
          {i18next.t('Please contact PeopleWise at ')}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>{' '}
          {i18next.t('for help & support.')}
        </p>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    getCreditCostsResponse: state.multiResponses.getIn([
      'getCollection',
      'creditCosts',
    ]),
    getStripeInvoiceResponse: state.responses.get('getStripeInvoice'),
    creditCosts: state.collections.getIn(['creditCosts', 'items']),
    reportCosts: state.collections.getIn(['reportCosts', 'items'], null),
    account: state.items.get('organisations'),
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, {
  getCreditCosts,
  getReportCosts,
  loadOrganisation,
})(PurchaseCreditsModal);
