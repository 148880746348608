import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { fromJS, Map, List } from 'immutable';
import _ from 'underscore';
import i18next from 'i18next';

import {
  sortList,
  setFilter,
  clearFilters,
  setSearch,
} from '^/actions/actions';
import { isLineManager } from '^/rater';
import ApprovalStatus from './ApprovalStatus';
import SearchBar from '^/components/Searchbar';
import ListPage from '^/components/ListPage';
import UserNameAndEmail from '^/components/UserNameAndEmail';
import PureComponent from '^/components/PureComponent';

export class ActivityRatersStatusTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: List(),
    };
  }

  componentDidMount() {
    this.setState({
      items: this.getItems(),
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filterSpec !== this.props.filterSpec ||
      prevProps.ordering !== this.props.ordering ||
      prevProps.currentSearch !== this.props.currentSearch
    ) {
      this.setState({
        items: this.getItems(),
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.props.clearFilters();
    this.props.setSearch(null);
    this.props.sortList('raters', {});
  }

  getItems() {
    return this.sort(this.search(this.filter(this.getAllRaters())));
  }

  getAllRaters() {
    return this.props.activity
      .get('users')
      .map(respondent =>
        List.of({ respondent }).concat(
          respondent.get('raters', List()).map(rater => ({ respondent, rater }))
        )
      )
      .flatten(true);
  }

  applyOrderingTo(rows, field) {
    switch (field) {
      case 'respondent':
        return rows.sortBy(row => row.respondent.getIn(['user', 'full_name']));
      case 'rater':
        return rows.sortBy(row =>
          row.rater
            ? row.rater.getIn(['rater', 'email'])
            : row.respondent.getIn(['user', 'email'])
        );
      case 'role':
        return rows.sortBy(row => {
          if (!row.rater) {
            return 'Self';
          }
          if (!row.rater.getIn(['role', 'name'])) {
            return 'Line Manager';
          }
          return row.rater.getIn(['role', 'name']);
        });
      case 'approval':
        return rows.sortBy(
          row => row.rater && row.rater.get('approved_by_line_manager')
        );
      case 'complete':
        return rows.sortBy(row =>
          this.getSurveyStatus(row.rater || row.respondent)
        );
      default:
        return rows;
    }
  }

  sort(rows) {
    const { ordering } = this.props;
    const sortedRows = this.applyOrderingTo(rows, ordering.field);
    return ordering.reversed ? sortedRows.reverse() : sortedRows;
  }

  applyFilterTo(rows, field, value) {
    switch (field) {
      case 'respondent':
        return rows.filter(row => {
          return row.respondent.getIn(['user', 'full_name']) === value;
        });
      case 'rater':
        return rows.filter(row => {
          if (row.rater) {
            const rater = row.rater.get('rater');
            return rater.get('email') === value;
          }
          const rater = row.respondent.get('user');
          return rater.get('email') === value;
        });
      case 'role':
        if (value === 'SELF') {
          return rows.filter(row => !row.rater);
        } else if (!value) {
          return rows.filter(row => row.rater && isLineManager(row.rater));
        }
        return rows.filter(
          row => row.rater && row.rater.getIn(['role', 'code']) === value
        );
      case 'approval':
        return rows.filter(
          row =>
            row.rater && row.rater.get('approved_by_line_manager') === value
        );
      case 'complete':
        return rows.filter(row => {
          return this.getSurveyStatus(row.rater || row.respondent) === value;
        });
      default:
        return rows;
    }
  }

  filter(rows) {
    const { filterSpec } = this.props;
    return _.chain(filterSpec)
      .pairs()
      .reduce(
        (memo, [field, value]) => this.applyFilterTo(rows, field, value),
        rows
      )
      .value();
  }

  applySearchTo(rows, searchString) {
    return rows.filter(row => {
      const rater = row.rater && row.rater.get('rater'),
        respondent = row.respondent.get('user');

      const searchStringLower = searchString.toLowerCase();

      const matchesSearchString = user =>
        _.any([user.get('full_name'), user.get('email')], text =>
          text.toLowerCase().includes(searchStringLower)
        );

      return _.any(_.filter([rater, respondent]), matchesSearchString);
    });
  }

  search(rows) {
    const { currentSearch } = this.props;
    return currentSearch ? this.applySearchTo(rows, currentSearch) : rows;
  }

  getSurveyStatus(respondentOrRater) {
    if (respondentOrRater.get('completed')) {
      return i18next.t('COMPLETED');
    } else if (respondentOrRater.get('has_started')) {
      return i18next.t('PARTIALLY COMPLETED');
    }
    return i18next.t('NOT STARTED');
  }

  renderApprovalStatus(rater, ratersSubmitted) {
    const ratersNeedApproval = this.props.activity.getIn([
      'activity360',
      'line_manager_must_approve_raters',
    ]);

    if (!ratersNeedApproval) {
      return (
        <span className="inline-badge badge-grey">
          {i18next.t('APPROVAL NOT REQUIRED')}
        </span>
      );
    }

    const approvalStatus = rater.get('approved_by_line_manager');

    return (
      <ApprovalStatus
        status={approvalStatus}
        ratersSubmitted={ratersSubmitted}
      />
    );
  }

  renderRaterRow(row) {
    const respondentUser = row.respondent.get('user'),
      ratersSubmitted = row.respondent.get('raters_submitted'),
      rater = row.rater,
      raterUser = rater.get('rater');

    return (
      <tr key={[respondentUser, raterUser].map(each => each.get('id')).join()}>
        <td>
          <UserNameAndEmail user={respondentUser} />
        </td>
        <td>
          <UserNameAndEmail user={raterUser} />
        </td>
        <td>
          {isLineManager(rater)
            ? i18next.t('Line Manager')
            : rater.getIn(['role', 'name'])}
        </td>
        <td>
          {isLineManager(rater)
            ? ''
            : this.renderApprovalStatus(rater, ratersSubmitted)}
        </td>
        <td>{this.getSurveyStatus(rater)}</td>
      </tr>
    );
  }

  renderRespondentRow(row) {
    const { respondent } = row;
    const respondentUser = respondent.get('user');

    return (
      <tr key={[respondentUser].map(each => each.get('id')).join()}>
        <td>
          <UserNameAndEmail user={respondentUser} />
        </td>
        <td>
          <UserNameAndEmail user={respondentUser} />
        </td>
        <td>{i18next.t('Self')}</td>
        <td />
        <td>{this.getSurveyStatus(respondent)}</td>
      </tr>
    );
  }

  renderRow(row) {
    if (row.rater) {
      return this.renderRaterRow(row);
    }
    return this.renderRespondentRow(row);
  }

  setOrdering(field) {
    const { ordering } = this.props;
    const shouldReverse = field === ordering.field;

    this.props.sortList('raters', {
      field,
      reversed: shouldReverse ? !ordering.reversed : ordering.reversed,
    });
  }

  getFilters() {
    const raters = this.state.items.map(each => each.rater);

    const makeFilter = (key, values) => ({
      name: key,
      key,
      values: { values },
    });

    const filters = [
      makeFilter(
        'respondent',
        this.state.items
          .map(each => {
            const name = each.respondent.getIn(['user', 'full_name']);
            return Map({ id: name, name });
          })
          .toSet()
          .sort((a, b) => {
            if (a.get('name') < b.get('name')) return -1;
            if (a.get('name') > b.get('name')) return 1;
            return 0;
          })
      ),
      makeFilter(
        'rater',
        this.state.items
          .map(each => {
            const rater = each.rater
              ? each.rater.get('rater')
              : each.respondent.get('user');
            const name = rater.get('email');
            return Map({ id: name, name });
          })
          .toSet()
          .sort((a, b) => {
            if (a.get('name') < b.get('name')) return -1;
            if (a.get('name') > b.get('name')) return 1;
            return 0;
          })
      ),
      makeFilter(
        'role',
        raters
          .map(each =>
            each ? each.get('role') : Map({ code: 'SELF', name: 'Self' })
          )
          .toSet()
          .map(each =>
            Map({
              id: each ? each.get('code') : '',
              name: each ? each.get('name') : i18next.t('Line Manager'),
            })
          )
      ),
      makeFilter(
        'approval',
        raters
          .filter(item => item)
          .map(each => each.get('approved_by_line_manager'))
          .toSet()
          .map(each =>
            Map({
              id: each,
              name: <ApprovalStatus status={each} />,
            })
          )
      ),
      makeFilter(
        'complete',
        fromJS([
          { id: 'COMPLETED', name: i18next.t('COMPLETED') },
          { id: 'PARTIALLY COMPLETED', name: i18next.t('PARTIALLY COMPLETED') },
          { id: 'NOT STARTED', name: i18next.t('NOT STARTED') },
        ])
      ),
    ];

    const { filterSpec } = this.props;

    return filters.map(each =>
      Object.assign({}, each, { currentValue: filterSpec[each.key] })
    );
  }

  render() {
    return (
      <ListPage
        headers={[
          {
            title: i18next.t('Respondent'),
            field: 'respondent',
            sortable: true,
          },
          {
            title: i18next.t('Rater'),
            field: 'rater',
            sortable: true,
          },
          { title: i18next.t('Rater type'), field: 'role', sortable: true },
          {
            title: i18next.t('Rater approval status'),
            field: 'approval',
            sortable: true,
          },
          {
            title: i18next.t('Product status'),
            field: 'complete',
            sortable: true,
          },
        ]}
        renderer={row => this.renderRow(row)}
        items={this.state.items}
        typeNamePlural="raters"
        ordering={this.props.ordering}
        onOrderingChange={field => this.setOrdering(field)}
        filters={this.getFilters()}
        onFilterChange={this.props.setFilter}
        isFiltered={!!this.props.filterSpec}
      >
        <SearchBar onChange={evt => this.props.setSearch(evt.target.value)} />
      </ListPage>
    );
  }
}

function mapStateToProps(state) {
  return {
    ordering: state.sortOrders.get('raters', {}),
    filterSpec: state.ui.get('filterSpec'),
    currentSearch: state.ui.get('currentSearch'),
  };
}

export default connect(mapStateToProps, {
  sortList,
  setFilter,
  clearFilters,
  setSearch,
})(ActivityRatersStatusTable);
