import { CALL_API } from 'redux-api-middleware';
import _ from 'underscore';

import { groupedListsToProplist } from '^/utils';
import { API_PREFIX } from '../settings';
import TokenStore from '../TokenStore';

// Construct a Redux Standard API-calling Action (RSAA)

export default function makeRsaAction(
  actionSet,
  path,
  method,
  optionalData,
  meta
) {
  const types = _.map(
    [actionSet.REQUEST, actionSet.SUCCESS, actionSet.FAILURE],
    requestType => {
      const newMeta = Object.assign(
        {},
        meta && _.clone(meta),
        requestType === actionSet.SUCCESS && { success: true }
      );
      return Object.assign(
        { type: requestType },
        Object.keys(newMeta).length ? { meta: newMeta } : null
      );
    }
  );

  let callSpec = {
    types: types,
    endpoint: API_PREFIX + path,
    method,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Accept: 'application/json',
    },
  };

  const token = TokenStore.get();
  if (token) {
    callSpec.headers['Authorization'] = 'JWT ' + token;
  }

  if (optionalData) {
    if (method === 'GET') {
      callSpec.endpoint +=
        '?' +
        _.map(groupedListsToProplist(optionalData), keyAndValue =>
          keyAndValue.map(encodeURIComponent).join('=')
        ).join('&');
    } else if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      const hasFile = _(optionalData).some(
        value => value && value.constructor === window.File
      );
      if (hasFile) {
        const formData = new FormData();
        _.each(optionalData, (value, key) => {
          if (value !== undefined) {
            formData.append(key, value);
          }
        });
        callSpec.body = formData;
      } else {
        callSpec.headers['Content-Type'] = 'application/json';
        callSpec.body = JSON.stringify(optionalData);
      }
    }
  }

  return {
    [CALL_API]: callSpec,
  };
}
