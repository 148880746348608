import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { getStripeShopInvoiceAndRedirectToPayment } from '^/actions/actionSequences';
import {
  getShopItems,
  removeFromBasket,
  updateBasketItemQuantity,
  updateBasketItemReportQuantity,
} from '^/actions/shop';
import Alert, { AlertType } from '^/components/Alert';
import ControlBar from '^/components/ControlBar';
import LiveButton from '^/components/LiveButton';
import Loading from '^/components/Loading';
import { ShopItemOptionType } from '^/reducers/api/types';
import { selectResponse } from '^/selectors/responses';
import { StoreState } from '^/store';
import BasketCostSummary from './BasketCostSummary';
import BasketItem from './BasketItem';
import {
  selectAccountPaysUKTax,
  selectAnyQuantityIsZero,
  selectBasketItemsData,
  selectBasketTotalCost,
} from './selectors';
import { BasketItemData } from './types';

interface DispatchProps {
  getShopItems: typeof getShopItems;
  removeFromBasket: typeof removeFromBasket;
  updateBasketItemQuantity: typeof updateBasketItemQuantity;
  updateBasketItemReportQuantity: typeof updateBasketItemReportQuantity;
  getStripeShopInvoiceAndRedirectToPayment: typeof getStripeShopInvoiceAndRedirectToPayment;
}

interface StateProps {
  basketItemsData: ReadonlyArray<BasketItemData> | null;
  anyQuantityIsZero: boolean;
  accountPaysUKTax: boolean;
  totalCost: number;
  response: Map<string, any>;
  updateBasketItemResponse: Map<string, any>;
  updateBasketItemReportQuantityResponse: Map<string, any>;
}

type Props = DispatchProps & StateProps;

export class BasketPage extends React.PureComponent<Props> {
  public UNSAFE_componentWillMount() {
    if (!this.props.basketItemsData) {
      this.props.getShopItems();
    }
  }

  public render() {
    const {
      basketItemsData,
      accountPaysUKTax,
      totalCost,
      response,
      updateBasketItemResponse,
      updateBasketItemReportQuantityResponse,
      anyQuantityIsZero,
    } = this.props;
    if (!basketItemsData) {
      return <Loading />;
    }

    const hasPurchasedPureProduct = basketItemsData.some(
      basketItemData =>
        basketItemData.shopItemOption.type === ShopItemOptionType.Product &&
        !basketItemData.shopItemOption.product_variant
    );

    const basketItemIds = basketItemsData.map(
      basketItem => basketItem.basketItem.id
    );

    return (
      <div>
        <ControlBar hideSearch hideAdd title={i18next.t<string>('Basket')} />
        <div className="form-container">
          <div>
            {basketItemsData.length === 0 ? (
              <Alert>{i18next.t<string>('Your basket is empty.')}</Alert>
            ) : (
              <div>
                <table className="table-transparent no-lines">
                  {basketItemsData.map(basketItemData => (
                    <BasketItem
                      key={basketItemData.basketItem.id}
                      basketItemData={basketItemData}
                      response={updateBasketItemResponse}
                      updateReportQuantityResponse={
                        updateBasketItemReportQuantityResponse
                      }
                      onRemove={() =>
                        this.props.removeFromBasket(
                          basketItemData.basketItem.id
                        )
                      }
                      onUpdateQuantity={this.props.updateBasketItemQuantity.bind(
                        this,
                        basketItemData.basketItem.id
                      )}
                      onUpdateReportQuantity={
                        this.props.updateBasketItemReportQuantity
                      }
                    />
                  ))}
                </table>

                {hasPurchasedPureProduct && (
                  <div className="clearfix">
                    <Alert type={AlertType.Info}>
                      {i18next.t<string>(
                        'Please note - bulk purchase discounts are only available through purchasing credits.'
                      )}
                    </Alert>
                  </div>
                )}

                <div className="clearfix">
                  <BasketCostSummary
                    totalCost={totalCost}
                    applyVat={accountPaysUKTax}
                  />
                </div>
              </div>
            )}

            <div className="text-right mt-md">
              {anyQuantityIsZero && (
                <Alert type={AlertType.Warning}>
                  {i18next.t<string>(
                    'Select a quantity for each item to checkout.'
                  )}
                </Alert>
              )}
              <Link className="btn btn-default" to="/page/shop/">
                {i18next.t<string>('Continue shopping')}
              </Link>
              {basketItemsData.length > 0 && (
                <LiveButton
                  onClick={() =>
                    this.props.getStripeShopInvoiceAndRedirectToPayment(
                      basketItemIds
                    )
                  }
                  buttonText={i18next.t<string>('Checkout')}
                  response={response}
                  pendingMessage={i18next.t<string>('Opening payment tab...')}
                  disabled={anyQuantityIsZero}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    basketItemsData: selectBasketItemsData(state),
    accountPaysUKTax: selectAccountPaysUKTax(state),
    totalCost: selectBasketTotalCost(state),
    response: selectResponse(state, 'getStripeShopInvoice'),
    updateBasketItemResponse: selectResponse(state, 'updateBasketItemQuantity'),
    updateBasketItemReportQuantityResponse: selectResponse(
      state,
      'updateBasketItemReportQuantity'
    ),
    anyQuantityIsZero: selectAnyQuantityIsZero(state),
  };
}

export default connect(mapStateToProps, {
  getShopItems,
  removeFromBasket,
  updateBasketItemQuantity,
  updateBasketItemReportQuantity,
  getStripeShopInvoiceAndRedirectToPayment,
})(BasketPage);
