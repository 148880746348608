import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsAndClose,
  answerMultipleQuestionsAndSubmit,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import {
  getLikertBoundsInformation,
  PaginationInformationResilience,
  QUESTIONS_PER_PAGE,
} from '^/productVersions';
import { Uuid } from '^/reducers/api/types';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import LikertQuestion from './LikertQuestion';
import MultipleChoice from './MultipleChoice';
import { multiProductVersionQuestionProgress } from './QuestionProgress';
import ScrollTopOnPageChange from './ScrollTopOnPageChange';

enum NextAction {
  Submit,
  Advance,
  Close,
}

interface Answer {
  id: Uuid;
  text: string;
}

interface Question {
  text: string;
  id: string;
  options: ReadonlyArray<{
    id: Uuid;
    text: string;
  }>;
}

export type ResilienceQuestions = ReadonlyArray<[Question, Answer]>;

interface OwnProps {
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  raterFor: Map<string, any>;
  questionCollectionIdx: number;
  questionsWithAnswers: List<any>;
  multipleChoiceQuestionsWithAnswers: List<List<Map<string, any>>>;
}

interface StateProps {
  progress: Map<string, any>;
  isPending: boolean;
  uiLanguage: string;
}

interface DispatchProps {
  setProductVersionPage: typeof setProductVersionPage;
  storeProductVersionAnswer: typeof storeProductVersionAnswer;
  push: typeof push;
  answerMultipleQuestionsAndAdvance: typeof answerMultipleQuestionsAndAdvance;
  answerMultipleQuestionsAndSubmit: typeof answerMultipleQuestionsAndSubmit;
  answerMultipleQuestionsAndClose: typeof answerMultipleQuestionsAndClose;
}

type Props = OwnProps & StateProps & DispatchProps;

// FIXME: Move to composition-based architecture
function isMultipleChoiceQuestion(
  questionId: number,
  productVersion: Map<string, any>
) {
  return productVersion
    .get('questioncollection_set')
    .some((questionCollection: Map<string, any>) =>
      questionCollection
        .get('multiple_choice_questions')
        .some((question: Map<string, any>) => question.get('id') === questionId)
    );
}

export class ResilienceProductVersion extends PureComponent<Props> {
  public render() {
    const {
      productVersion,
      questionCollectionIdx,
      questionsWithAnswers,
      multipleChoiceQuestionsWithAnswers,
      raterFor,
      progress,
    } = this.props;
    const paginationInformation = this.getPaginationInformation();
    const currentPageIndex = paginationInformation.currentPageIndex;
    const currentPage = paginationInformation.currentPage;
    const isViewingLastPage = paginationInformation.isViewingLastPage;
    const isMultipleChoicePage =
      currentPage.getIn([0, 0, 'isMultipleChoice']) || false;
    const isPending = this.props.isPending;

    const transformed: ResilienceQuestions = currentPage.toJS();
    const answer = progress.getIn([
      'unsavedResponses',
      currentPage.getIn([0, 'id']),
    ]);

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={paginationInformation.pageCount}
          currentStep={currentPageIndex}
        />
        <AssessmentBody
          className="mb-none"
          header={multiProductVersionQuestionProgress(
            currentPageIndex,
            {
              listSize: questionsWithAnswers.count(),
              pageSize: QUESTIONS_PER_PAGE,
            },
            {
              listSize: multipleChoiceQuestionsWithAnswers.count(),
              pageSize: multipleChoiceQuestionsWithAnswers.count(),
            }
          )}
          questionIndex={currentPageIndex}
        >
          <ScrollTopOnPageChange page={currentPageIndex} />
          {isMultipleChoicePage ? (
            <MultipleChoice
              isPending={isPending}
              onChange={this.onAnswer}
              questions={transformed}
              selectedValue={answer}
            />
          ) : (
            this.renderEAPPage(transformed)
          )}
        </AssessmentBody>
        <AssessmentFooter
          isNotComplete={!this.isPageComplete(currentPage)}
          isSaving={isPending}
          showContinue={progress.get('canResume')}
          onContinueLater={this.confirmAndClose}
          onContinue={this.onNext.bind(
            this,
            isViewingLastPage ? NextAction.Submit : NextAction.Advance
          )}
          isEnd={isViewingLastPage}
        />
      </AssessmentContainer>
    );
  }

  private getPaginationInformation() {
    const {
      questionsWithAnswers,
      multipleChoiceQuestionsWithAnswers,
      progress,
    } = this.props;
    return new PaginationInformationResilience(
      questionsWithAnswers,
      multipleChoiceQuestionsWithAnswers,
      progress
    );
  }

  private onAnswer = (questionId: string, value: string | number) => {
    this.props.storeProductVersionAnswer(questionId, value);
  };

  private onNext(nextAction: NextAction) {
    const { progress, activity, productVersion, raterFor } = this.props;

    const answers = progress
      .get('unsavedResponses')
      .toKeyedSeq()
      .map((score: string | number, question: number) => {
        if (isMultipleChoiceQuestion(question, productVersion)) {
          return Map({
            multiple_choice_question: question,
            question_option: score,
          });
        }
        return Map({
          question,
          score,
        });
      })
      .toList();

    switch (nextAction) {
      case NextAction.Submit:
        return this.props.answerMultipleQuestionsAndSubmit(
          activity,
          productVersion,
          raterFor,
          answers,
          this.props.uiLanguage,
          0
        );

      case NextAction.Advance:
        const nextPage = this.getPaginationInformation().currentPageIndex + 1;
        return this.props.answerMultipleQuestionsAndAdvance(
          activity,
          productVersion,
          raterFor,
          answers,
          nextPage
        );

      case NextAction.Close:
        return this.props.answerMultipleQuestionsAndClose(
          activity,
          productVersion,
          raterFor,
          answers,
          this.props.uiLanguage
        );
    }
  }

  private confirmAndClose = () => {
    if (
      window.confirm(
        i18next.t<string>(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      this.onNext(NextAction.Close);
    }
  };

  private isPageComplete(currentPage: List<List<Map<string, any>>>) {
    return currentPage.every(questionAndAnswer => {
      const question = questionAndAnswer.get(0);
      const answer = questionAndAnswer.get(1);

      return (
        (answer ? answer.get('score', null) !== null : false) ||
        this.props.progress.getIn(['unsavedResponses', question.get('id')]) !==
          undefined
      );
    });
  }

  private renderEAPPage(questions: ResilienceQuestions) {
    const bounds = getLikertBoundsInformation(this.props.productVersion);

    const introText =
      this.props.productVersion.get('on_page_guide_text') ||
      i18next.t<string>(
        'Please indicate your level of agreement for each of the statements below'
      );

    return (
      <div>
        <Well>{introText}</Well>
        <div>
          {questions.map(([answer]) => (
            <LikertQuestion
              questionText={answer.text}
              answer={this.props.progress.getIn([
                'unsavedResponses',
                answer.id,
              ])}
              key={answer.id}
              onAnswer={this.onAnswer.bind(this, answer.id)}
              bounds={bounds}
            />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('answerQuestion'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  push,
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsAndSubmit,
  answerMultipleQuestionsAndClose,
})(ResilienceProductVersion);
