import React, { PureComponent, ReactElement } from 'react';

import * as DragAndDropContextInstance from './DragAndDropHandlerContextInstance';
import { DragAndDropHandlerContext } from './types';
import { isDragging } from './utils';

export interface DragDetails<GroupsKeys> {
  draggedGroup: GroupsKeys;
  draggedIndex: number;
  droppedGroup: GroupsKeys;
  droppedIndex: number;
}

interface OwnProps<Groups extends { [i: string]: ReadonlyArray<Item> }, Item> {
  groups: Groups;
  // Not sure of a better type for tags that can have children
  tag?: 'div' | 'span';
  className?: string;
  shouldSwap?: (
    item: Item,
    targetItem: Item,
    details: DragDetails<keyof Groups>
  ) => boolean;
  isEmpty?: (item: Item) => boolean;
  children: (group: keyof Groups) => ReactElement;
  onDrop: (groups: Groups) => void;
}

export type Props<
  Groups extends { [i: string]: ReadonlyArray<Item> },
  Item
> = OwnProps<Groups, Item>;

type State<Groups extends { [i: string]: ReadonlyArray<Item> }, Item> = Pick<
  DragAndDropHandlerContext<Groups, Item>,
  | 'draggedGroup'
  | 'draggedIndex'
  | 'droppedGroup'
  | 'droppedIndex'
  | 'box'
  | 'pointer'
  | 'pointerStart'
> & {
  originalGroups: Groups;
  reorderedGroups: Groups;
};

const defaultIsEmpty = <Item extends any>(item: Item) => item;

export default class DragAndDropHandler<
  Groups extends { [i: string]: ReadonlyArray<Item> },
  Item
> extends PureComponent<Props<Groups, Item>, State<Groups, Item>> {
  public constructor(props: Props<Groups, Item>) {
    super(props);

    this.state = {
      originalGroups: props.groups,
      reorderedGroups: props.groups,
    };
  }

  public componentDidUpdate() {
    // Prevent overriding reordered or original groups while dragging
    if (!isDragging(this.state)) {
      this.setState({
        originalGroups: this.props.groups,
        reorderedGroups: this.props.groups,
      });
    }
  }

  public render() {
    const { tag: Tag = 'div', className, children } = this.props;
    const { reorderedGroups } = this.state;

    const groupsKeys = Object.keys(reorderedGroups);

    return (
      <DragAndDropContextInstance.Provider
        context={{
          ...this.state,
          setDragged: this.setDragged,
          setPosition: this.setPosition,
          setDropped: this.setDropped,
          clearDragged: this.clearDragged,
          clearDropped: this.clearDropped,
          itemDropped: this.itemDropped,
          groups: reorderedGroups,
        }}
      >
        <Tag className={className}>
          {groupsKeys.map(groupKey => children(groupKey))}
        </Tag>
      </DragAndDropContextInstance.Provider>
    );
  }

  private setDragged: DragAndDropHandlerContext<Groups, Item>['setDragged'] = (
    draggedGroup,
    draggedIndex,
    box,
    pointerStart
  ) =>
    this.setState({
      draggedGroup,
      draggedIndex,
      box,
      pointerStart,
      pointer: pointerStart,
    });

  private setPosition: DragAndDropHandlerContext<
    Groups,
    Item
  >['setPosition'] = pointer => {
    this.setState({ pointer });
  };

  private setDropped: DragAndDropHandlerContext<Groups, Item>['setDropped'] = (
    droppedGroup,
    droppedIndex
  ) => {
    if (isDragging(this.state)) {
      const newGroups = this.reorderGroups(
        this.state.draggedGroup,
        this.state.draggedIndex,
        droppedGroup,
        droppedIndex
      );

      this.setState({
        reorderedGroups: newGroups,
      });
    }

    this.setState({ droppedGroup, droppedIndex });
  };

  private clearDragged = () =>
    this.setState({ draggedGroup: undefined, draggedIndex: undefined });

  private clearDropped = () => {
    this.setState({
      reorderedGroups: this.state.originalGroups,
      droppedGroup: undefined,
      droppedIndex: undefined,
    });
  };

  private itemDropped = () => {
    // IMPORTANT: Must be called before clearing the state
    this.props.onDrop(this.state.reorderedGroups);

    this.setState({
      draggedGroup: undefined,
      draggedIndex: undefined,
      droppedGroup: undefined,
      droppedIndex: undefined,
      box: undefined,
      pointer: undefined,
      pointerStart: undefined,
    });
  };

  private reorderGroups(
    draggedGroup: string,
    draggedIndex: number,
    droppedGroup: string,
    droppedIndex: number
  ) {
    const { groups } = this.props;
    const shouldNotSwap =
      typeof this.props.shouldSwap === 'function' &&
      !this.props.shouldSwap(
        groups[draggedGroup][draggedIndex],
        groups[droppedGroup][droppedIndex],
        { draggedGroup, draggedIndex, droppedGroup, droppedIndex }
      );

    if (draggedGroup === droppedGroup && !shouldNotSwap) {
      if (draggedIndex === droppedIndex) {
        return this.state.originalGroups;
      }

      const groupCopy = [...this.state.originalGroups[droppedGroup]];
      const extractedItem = groupCopy.splice(draggedIndex, 1)[0];
      groupCopy.splice(droppedIndex, 0, extractedItem);

      return {
        ...this.state.originalGroups,
        [droppedGroup]: groupCopy,
      };
    }

    if (shouldNotSwap) {
      const { list, removedItem } = this.insertAtAndGetRemoved(
        this.state.originalGroups[droppedGroup],
        this.state.originalGroups[draggedGroup][draggedIndex],
        droppedIndex
      );

      const draggedList = [...this.state.originalGroups[draggedGroup]];
      draggedList.splice(draggedIndex, 1, removedItem);

      return {
        ...this.state.originalGroups,
        [droppedGroup]: list,
        [draggedGroup]: draggedList,
      };
    }

    const fromGroupCopy = [...this.state.originalGroups[draggedGroup]];
    const toGroupCopy = [...this.state.originalGroups[droppedGroup]];
    const fromItem = fromGroupCopy[draggedIndex];
    const toItem = toGroupCopy[droppedIndex];

    fromGroupCopy[draggedIndex] = toItem;
    toGroupCopy[droppedIndex] = fromItem;

    return {
      ...this.state.originalGroups,
      [draggedGroup]: fromGroupCopy,
      [droppedGroup]: toGroupCopy,
    };
  }

  private insertAtAndGetRemoved(
    list: ReadonlyArray<Item>,
    item: Item,
    index: number
  ): { list: ReadonlyArray<Item>; removedItem: Item } {
    const { isEmpty = defaultIsEmpty } = this.props;
    const listCopy = [...list];

    for (let i = 0; i < listCopy.length; i += 1) {
      const checkForwardIndex = index + i;
      const checkBackwardIndex = index - i;

      if (
        checkForwardIndex < listCopy.length &&
        isEmpty(listCopy[checkForwardIndex])
      ) {
        const removedItem = listCopy.splice(checkForwardIndex, 1)[0];
        listCopy.splice(index, 0, item);

        return {
          list: listCopy,
          removedItem,
        };
      }

      if (checkBackwardIndex > -1 && isEmpty(listCopy[checkBackwardIndex])) {
        const removedItem = listCopy.splice(checkBackwardIndex, 1)[0];
        listCopy.splice(index, 0, item);

        return {
          list: listCopy,
          removedItem,
        };
      }
    }

    // If we fail to find a space, put it back where it came from
    return {
      list: listCopy,
      removedItem: item,
    };
  }
}
