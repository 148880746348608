import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from './PureComponent';
import { USER_ROLES } from '^/models/user';

export function getRoleText(role) {
  switch (role) {
    case USER_ROLES.ADMIN:
      return i18next.t('Admin');
    case USER_ROLES.ORGADMIN:
      return i18next.t('Organisation Admin');
    case USER_ROLES.ACCOUNT_SUPERUSER:
      return i18next.t('Account Superuser');
    case USER_ROLES.ACCOUNT_ORG_ADMIN:
      return i18next.t('Account Org Admin');
    case USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER:
      return i18next.t('Professional Practitioner');
    case USER_ROLES.ENDUSER:
    default:
      return i18next.t('End User');
  }
}

export class UserRole extends PureComponent {
  getRole() {
    if (this.props.role !== undefined) {
      return this.props.role;
    }
    return this.props.user.get('role');
  }

  render() {
    return <span>{getRoleText(this.getRole())}</span>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps)(UserRole);
