import i18next from 'i18next';
import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { startPulses } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import { PulseSubscriptionDetails, Uuid } from '^/reducers/api/types';
import { PulseUserRow } from '^/components/pulses/PulsePage';
import Alert, { AlertType } from '^/components/Alert';
import PulseSubscriptionOrInvoice from './PulseSubscriptionOrInvoice';
import { StoreState } from '^/store';
import { HELP_EMAIL } from '^/settings';
import { administerOrganisations, can } from '^/capabilities';

interface DispatchProps {
  startPulses: typeof startPulses;
  closeTopModal: typeof closeTopModal;
}

interface StateProps {
  isAdmin: boolean;
}

interface OwnProps {
  users: ReadonlyArray<PulseUserRow>;
  pulseSubscriptionDetails: PulseSubscriptionDetails;
  pulseId: Uuid;
  reloadPulse: () => void;
}

type Props = DispatchProps & StateProps & OwnProps;

interface State {
  pulseSubscriptionName: string;
  selectedInvoice: File | null;
}

export class StartPulsingModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pulseSubscriptionName: '',
      selectedInvoice: null,
    };
  }

  startDisabled = (pulseSubscriptionNameInUse: boolean) => {
    const pulseSubscriptionName = this.state.pulseSubscriptionName;
    const subscriptionNameInvalid =
      !pulseSubscriptionName ||
      pulseSubscriptionName.length === 0 ||
      pulseSubscriptionNameInUse;
    return subscriptionNameInvalid || !this.state.selectedInvoice;
  };

  handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      selectedInvoice: event.target.files && event.target.files[0],
    }));
  };

  handlePulseNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      pulseSubscriptionName: event.target.value,
    }));
  };

  private createUserPulses = (
    users: ReadonlyArray<PulseUserRow>,
    pulseSubscriptionName: string,
    pulseId: Uuid,
    invoice: File | null
  ) => {
    this.props.startPulses(
      users.map(user => user.userId).toString(),
      pulseSubscriptionName,
      this.props.reloadPulse,
      pulseId,
      invoice
    );
    this.props.closeTopModal();
  };

  adminView = () => {
    const { users, pulseSubscriptionDetails } = this.props;
    const { pulseSubscriptionName, selectedInvoice } = this.state;
    const pulseSubscriptionNameInUse = pulseSubscriptionDetails.used_names.includes(
      pulseSubscriptionName
    );

    return (
      <>
        <h5>{i18next.t<string>('Selected respondents')}</h5>
        <div className="boxed">
          <div className="box">
            {users.map(user => user.fullName).join(', ')}
          </div>
        </div>
        <Alert type={AlertType.Info}>
          {i18next.t<string>('Please name your pulse.')}
        </Alert>
        <h5>{i18next.t<string>('Pulse Name')}</h5>
        <input
          placeholder={i18next.t<string>('Enter pulse name')}
          onChange={this.handlePulseNameChange}
        />
        {pulseSubscriptionNameInUse && (
          <p className="text-error">
            {i18next.t<string>(
              'This pulse name is already in use on your account.'
            )}
          </p>
        )}
        <PulseSubscriptionOrInvoice
          handleFileSelection={this.handleFileSelection}
          selectedInvoice={selectedInvoice}
        />
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>
            <button
              className="btn btn-primary"
              onClick={() =>
                this.createUserPulses(
                  users,
                  pulseSubscriptionName,
                  this.props.pulseId,
                  selectedInvoice
                )
              }
              disabled={this.startDisabled(pulseSubscriptionNameInUse)}
            >
              {i18next.t<string>('Start pulsing')}
            </button>
          </div>
        </div>
      </>
    );
  };

  contactUsView = () => {
    return (
      <>
        <Alert type={AlertType.Info}>
          <Trans
            i18nKey="TRANS start pulsing"
            components={[
              <a key="link" href={`mailto:${HELP_EMAIL}`}>
                email
              </a>,
            ]}
            values={{ email: HELP_EMAIL }}
          />
        </Alert>
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-primary"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Close')}
            </button>
          </div>
        </div>
      </>
    );
  };

  public render() {
    return (
      <div className="start-pulsing-modal">
        {this.props.isAdmin ? this.adminView() : this.contactUsView()}
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  isAdmin: can(state.userProfile, administerOrganisations()),
});

export default connect(mapStateToProps, {
  startPulses,
  closeTopModal,
})(StartPulsingModal);
