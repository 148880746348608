import React, { useState } from 'react';
import i18next from 'i18next';
import Calendar from 'react-calendar';

import NewButton from '../NewButton';
import ToggleSwitch from '../buttons/ToggleSwitch';

export interface Props {
  confirmButtonLabel: string;
  onConfirm: (date: Date, type: string) => void;
  onClose: () => void;
}

export const DateSelectAndTypeModal: React.FC<Props> = ({
  confirmButtonLabel,
  onConfirm,
  onClose,
}) => {
  const [date, setDate] = useState<Date | Date[]>(new Date());
  const [choiceType, setChoiceType] = useState('on');
  const SWITCHER_CHOICES = [
    { type: 'on', name: i18next.t<string>('On') },
    { type: 'before', name: i18next.t<string>('Before') },
    {
      type: 'after',
      name: i18next.t<string>('After'),
    },
  ];
  const handleConfirm = () => {
    onConfirm(date as Date, choiceType);
    onClose();
  };

  return (
    <>
      <div className="date-compare">
        <ToggleSwitch
          items={SWITCHER_CHOICES.map(choices => {
            return {
              id: choices.type,
              name: choices.name,
              selected: choiceType === choices.type,
            };
          })}
          onClick={(choice: string) => setChoiceType(choice)}
        />
      </div>
      <Calendar value={date as Date} onChange={newDate => setDate(newDate)} />
      <div className="modal-footer">
        <div className="pull-right">
          <NewButton buttonType="outline" onClick={onClose}>
            {i18next.t<string>('Cancel')}
          </NewButton>
          <NewButton onClick={handleConfirm}>{confirmButtonLabel}</NewButton>
        </div>
      </div>
    </>
  );
};
