import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';

export default class Categories extends PureComponent {
  render() {
    const { categories } = this.props;

    if (!categories || categories.isEmpty()) {
      return false;
    }

    return (
      <span className="user-category-list">
        {categories
          .map((value, key) => (
            <span
              key={key.toString() + value}
              className="user-category-wrapper"
            >
              <span className="user-category-label">
                {key}: <strong>{value}</strong>
              </span>
            </span>
          ))
          .toArray()}
      </span>
    );
  }
}
