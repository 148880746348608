import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';
import classNames from 'classnames';

import Checkbox from '^/components/Checkbox';
import PureComponent from '../PureComponent';
import Img from '../Img';
import EditOrganisationModal from './EditOrganisationModal';
import {
  openModal,
  closeTopModal,
  openConfirmationModal,
} from '^/actions/modals';
import { updateOrganisationAndCloseModal } from '../../actions/actionSequences';
import { getAccountTypeDisplay, isAccount } from '^/models/organisation';

export class OrganisationRow extends PureComponent {
  select() {
    if (this.props.isSelectable && !this.props.disabled) {
      this.props.onSelect();
    }
  }

  navigateToEditAccountPage() {
    this.props.push(`/page/accounts/${this.props.organisation.get('id')}/`);
  }

  showEditOrganisationModal() {
    this.props.openModal({
      title: i18next.t('Edit Organisation'),
      body: (
        <EditOrganisationModal
          onComplete={() => {}}
          organisation={this.props.organisation}
        />
      ),
    });
  }

  getClassName() {
    return (
      'user-row ' +
      (this.props.isSelectable && !this.props.disabled ? ' selectable' : '') +
      (this.props.isSelected ? ' selected' : '')
    );
  }

  showEditScreen() {
    if (isAccount(this.props.organisation)) {
      this.navigateToEditAccountPage();
    } else {
      this.showEditOrganisationModal();
    }
  }

  setOrganisationActiveState() {
    const { organisation } = this.props;

    this.props.updateOrganisationAndCloseModal(organisation.get('id'), {
      is_active: !organisation.get('is_active'),
    });
  }

  enableOrDisableOrganisation() {
    const { organisation } = this.props;
    const isActive = organisation.get('is_active');

    this.props.openConfirmationModal(
      isActive ? i18next.t('disable') : i18next.t('enable'),
      'organisation',
      isActive
        ? [
            i18next.t(
              'End users in this organisation will no longer be able to log in.'
            ),
          ]
        : [
            i18next.t(
              'End users in this organisation will once again be able to log in'
            ),
          ],
      this.setOrganisationActiveState.bind(this, organisation),
      this.props.closeTopModal,
      isActive ? 'btn-danger' : 'btn-success'
    );
  }

  renderEnableDisableButton() {
    const { organisation } = this.props;
    const isActive = organisation.get('is_active');

    return (
      <button
        className={classNames(
          'btn btn-small no-margin',
          isActive ? 'btn-danger-secondary' : 'btn-success-secondary'
        )}
        onClick={this.enableOrDisableOrganisation.bind(this, organisation)}
      >
        {isActive ? i18next.t('Disable') : i18next.t('Enable')}
      </button>
    );
  }

  renderAdmin(admin) {
    return (
      <p key={admin.get('email')}>
        {admin.get('full_name')} ({admin.get('email')})
      </p>
    );
  }

  render() {
    const { organisation, isSelectable } = this.props;

    return (
      <tr
        className={this.getClassName()}
        disabled={this.props.disabled}
        onClick={() => this.select()}
      >
        <td>
          <a onClick={this.showEditScreen.bind(this)}>
            <Img src={organisation.get('brand_logo')} />
          </a>
        </td>
        <td data-title="Name">
          <a onClick={this.showEditScreen.bind(this)}>
            {organisation.get('name')}
          </a>
        </td>
        <td data-title="Type">
          <a onClick={this.showEditScreen.bind(this)}>
            {getAccountTypeDisplay(organisation)}
          </a>
        </td>
        <td className="center" data-title="Credits Total">
          {organisation.get('credit')}
        </td>
        <td className="center" data-title="Users Total">
          {organisation.get('user_count')}
        </td>
        <td className="center" data-title="Registered">
          {organisation.get('num_registered_users')}
        </td>
        <td className="center" data-title="Respondents">
          {organisation.get('num_respondents')}
        </td>
        {!isSelectable && (
          <td className="center" data-title="Live Activities">
            {organisation.getIn(['stats', 'live'])}
          </td>
        )}
        {this.props.isAdmin && (
          <td data-title="Status">
            {organisation.get('is_active') ? 'ACTIVE' : 'INACTIVE'}
          </td>
        )}
        {!this.props.selectOne && this.props.isAdmin && (
          <td className="actions" data-title="Action">
            {isSelectable ? (
              <Checkbox
                checked={this.props.isSelected}
                disabled={this.props.disabled}
              />
            ) : (
              this.renderEnableDisableButton()
            )}
          </td>
        )}
      </tr>
    );
  }
}

export default connect(null, {
  openModal,
  closeTopModal,
  openConfirmationModal,
  updateOrganisationAndCloseModal,
  push,
})(OrganisationRow);
