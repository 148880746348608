import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import Alert from '^/components/Alert';

export default class AccountCreditBalance extends PureComponent {
  render() {
    const { account } = this.props;
    return (
      <Alert className="text-center bold-text">
        {i18next.t('{{accountName}} – current credit balance {{credits}}', {
          accountName: account.get('name'),
          credits: account.get('credit'),
        })}
      </Alert>
    );
  }
}
