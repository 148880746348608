/* eslint-disable @typescript-eslint/no-use-before-define */
import i18next from 'i18next';
import React from 'react';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

import {
  openDataAnalyticsOverlayModal,
  openConfirmationModal,
  openSplitRowModal,
  closeTopModal,
} from '^/actions/modals';
import {
  deleteHeatmapRow,
  updateHeatmapRow,
  deleteHeatmapRowModal,
  updateHeatmapRowModal,
} from '^/actions/analytics';
import InlineMenu, { InlineMenuOption } from '^/components/InlineMenu';
import SplitIcon from '^/components/SplitIcon';
import { AnalyticsAppliedFilters, HeatmapRow } from '^/reducers/api/types';
import { getSplitKeys, getSummaryName } from './utils';

interface DispatchProps {
  openDataAnalyticsOverlayModal: typeof openDataAnalyticsOverlayModal;
  deleteHeatmapRow: typeof deleteHeatmapRow;
  updateHeatmapRow: typeof updateHeatmapRow;
  deleteHeatmapRowModal: typeof deleteHeatmapRowModal;
  updateHeatmapRowModal: typeof updateHeatmapRowModal;
  openConfirmationModal: typeof openConfirmationModal;
  openSplitRowModal: typeof openSplitRowModal;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  row: HeatmapRow;
  indices: ReadonlyArray<number>;
  parentFilters?: AnalyticsAppliedFilters;
  user: Immutable.Map<string, any>;
  inModal: boolean;
}

type Props = OwnProps & DispatchProps;

export const HeatmapRowActionsMenu: React.FunctionComponent<Props> = props => {
  const { row, indices, parentFilters, user, inModal } = props;
  const { children } = row;
  const index = indices[indices.length - 1];
  const canSplit = getSplitKeys(row, user, parentFilters).length !== 0;
  const isSubrow = indices.length > 1;
  const unsplit = () =>
    !inModal
      ? props.updateHeatmapRow({ ...row, children: [] }, indices)
      : props.updateHeatmapRowModal({ ...row, children: [] }, indices);
  const split = () =>
    props.openSplitRowModal(row, indices, user, inModal, parentFilters);

  return isSubrow ? (
    <div className="inline-menu">
      {children.length ? (
        <div onClick={unsplit} title={i18next.t<string>('Unsplit')}>
          <FontAwesomeIcon icon={faMinus} />
        </div>
      ) : canSplit ? (
        <div onClick={split} title={i18next.t<string>('Split')}>
          <SplitIcon />
        </div>
      ) : (
        undefined
      )}
    </div>
  ) : (
    <InlineMenu>
      <InlineMenuOption
        name={i18next.t<string>('Edit')}
        faIcon={faPencil}
        disabled={!inModal}
        onSelect={() =>
          props.openDataAnalyticsOverlayModal(
            i18next.t<string>('Edit data set'),
            row,
            true,
            inModal,
            row.filtersState.product?.id || '',
            index
          )
        }
      />
      {children.length ? (
        <InlineMenuOption
          name={i18next.t<string>('Unsplit')}
          faIcon={faMinus}
          onSelect={unsplit}
        />
      ) : (
        <InlineMenuOption
          name={i18next.t<string>('Split')}
          icon={<SplitIcon />}
          disabled={!canSplit}
          onSelect={split}
        />
      )}
      <InlineMenuOption
        className={'red-text'}
        disabled={!inModal}
        name={i18next.t<string>('Remove')}
        faIcon={faTimes}
        onSelect={() => confirmDeleteRow(row)}
      />
    </InlineMenu>
  );

  function confirmDeleteRow(rowToDelete: HeatmapRow) {
    const rowToDeleteName = getSummaryName(rowToDelete);
    props.openConfirmationModal(
      i18next.t<string>('remove'),
      i18next.t<string>('summary row of data'),
      [rowToDeleteName],
      () => {
        !inModal
          ? props.deleteHeatmapRow(rowToDelete)
          : props.deleteHeatmapRowModal(rowToDelete);
        props.closeTopModal();
      },
      props.closeTopModal,
      null,
      null,
      i18next.t<string>('Confirm')
    );
  }
};

export default connect(null, {
  openDataAnalyticsOverlayModal,
  deleteHeatmapRow,
  updateHeatmapRow,
  deleteHeatmapRowModal,
  updateHeatmapRowModal,
  openConfirmationModal,
  openSplitRowModal,
  closeTopModal,
})(HeatmapRowActionsMenu);
