import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';

import PureComponent from '^/components/PureComponent';
import { PENDING, SUCCESSFUL, FAILED, isFieldUpdating } from '^/responseStates';

export default class ResponseStatusIcon extends PureComponent {
  getClassNames(className) {
    const { alignLeft, smallInline, marginLeft } = this.props;
    return classNames(
      {
        'pull-right': !alignLeft,
        'icon-smaller-inline': smallInline,
        'margin-left': marginLeft,
      },
      className
    );
  }

  render() {
    const { response, field } = this.props;
    const showResponseIcon = !field || isFieldUpdating(response, field);

    if (!response || !showResponseIcon) {
      return null;
    }

    switch (response.get('state')) {
      case PENDING:
        return (
          <FontAwesomeIcon
            className={this.getClassNames()}
            title={i18next.t('Saving...')}
            icon={faSpinnerThird}
            spin
          />
        );
      case SUCCESSFUL:
        return (
          <FontAwesomeIcon
            className={this.getClassNames('success')}
            title={i18next.t('Saved successfully')}
            icon={faCheck}
          />
        );
      case FAILED:
        return (
          <FontAwesomeIcon
            className={this.getClassNames('error')}
            title={i18next.t('Could not save')}
            icon={faExclamationTriangle}
          />
        );
      default:
        return null;
    }
  }
}

ResponseStatusIcon.propTypes = {
  response: ImmutablePropTypes.map,
};
