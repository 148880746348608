import { List } from 'immutable';

import { isLineManager } from '^/rater';
import { getAutoReportGeneration } from '^/models/product';

export const ACTIVITY_TYPE = {
  THREE_SIXTY: 'THREE_SIXTY',
  PSYCHOMETRIC: 'PSYCHOMETRIC',
  JOB_PROFILER: 'JOB_PROFILER',
};

export function mustSetLineManager(activity) {
  const isOneEighty =
    activity.getIn(['activity360', 'variant']) === 'ONE_EIGHTY';
  const raters = activity.getIn(['self_users', 0, 'raters']) || List();
  return isOneEighty && !raters.find(isLineManager);
}

export function getChosenReportTemplates(activity) {
  const {
    product_versions,
    activity_product_version_items,
    organisation,
  } = activity.toObject();

  return product_versions.map(productVersion => {
    const activityProductVersion = activity_product_version_items.find(
      item => item.getIn(['product_version', 'id']) === productVersion.get('id')
    );

    const productOrganisation = organisation
      .get('productorganisation_set')
      .find(
        each => each.get('product') === productVersion.getIn(['product', 'id'])
      );
    const productReportTemplates = productVersion.getIn([
      'product',
      'report_templates',
    ]);
    const productVariant = activityProductVersion.get('product_variant');
    const reportTemplates = productVariant
      ? productReportTemplates.filter(template =>
          productVariant.get('report_templates').includes(template.get('id'))
        )
      : productReportTemplates;
    const autoReportGeneration = getAutoReportGeneration(
      activityProductVersion
    );

    const chosenReportTemplates = autoReportGeneration
      ? reportTemplates.filter(report =>
          autoReportGeneration
            .get('report_templates')
            .contains(report.get('id'))
        )
      : List();

    return [productOrganisation, chosenReportTemplates];
  });
}

export function getNumberAvailable(activity) {
  const activityProductVersion = activity
    .get('activity_product_version_items')
    .first();
  const productOrganisations = activity.getIn([
    'organisation',
    'productorganisation_set',
  ]);
  const productVariant =
    activityProductVersion && activityProductVersion.get('product_variant');
  const productOrganisation =
    productVariant &&
    productOrganisations.find(
      each => each.get('product_variant') === productVariant.get('id')
    );
  return productOrganisation
    ? productOrganisation.get('number_available')
    : undefined;
}
