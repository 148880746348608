import React from 'react'; // eslint-disable-line no-unused-vars
import { Map } from 'immutable';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { faArrowAltFromBottom } from '@fortawesome/pro-light-svg-icons/faArrowAltFromBottom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import { fromQueryString, toQueryString } from '../../utils';
import { USER_STATUS } from '^/models/user';
import { expandUiComponent, collapseUiComponent } from '../../actions/actions';
import {
  openModal,
  openCreateUserModal,
  openImportUsersModal,
} from '../../actions/modals';
import InviteUserModal from './InviteUserModal';
import PureComponent from '../PureComponent';
import UsersListPage from './UsersListPage';
import { withRouter } from '^/withRouter';

export class UsersPage extends PureComponent {
  showImportUsersModal() {
    this.props.openImportUsersModal();
  }

  showInviteUserModal(user) {
    const isReinvite = user.get('status') === USER_STATUS.INVITED;
    this.props.openModal({
      title: isReinvite
        ? i18next.t('Resend invite to {{userEmail}}', {
            userEmail: user.get('email'),
          })
        : i18next.t('Send invite to {{userEmail}}', {
            userEmail: user.get('email'),
          }),
      body: (
        <InviteUserModal
          userId={user.get('id')}
          userName={user.get('full_name')}
          invitationEmail={user.get('email')}
          isReinvite={isReinvite}
        />
      ),
    });
  }

  onFilterChange(key, value) {
    const newFilterSpec = Object.assign({}, this.props.filterSpec, {
      [key]: value,
    });
    this.props.push('/page/individuals/view/' + toQueryString(newFilterSpec));
  }

  render() {
    const { users, filterSpec } = this.props;

    return (
      <UsersListPage
        users={users}
        title={i18next.t('Individuals')}
        filterSpec={filterSpec}
        onFilterChange={this.onFilterChange.bind(this)}
        getUserRowProps={user => ({
          invite: () => this.showInviteUserModal(user),
        })}
        controlBarProps={{
          onAddClick: this.props.openCreateUserModal,
        }}
        controlBarChildren={
          <button
            className="btn btn-default import"
            title={i18next.t('Import users')}
            onClick={() => this.showImportUsersModal()}
          >
            <FontAwesomeIcon icon={faArrowAltFromBottom} />
            {i18next.t('Import users')}
          </button>
        }
      />
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    filterSpec: fromQueryString(props.router.params['filter']),
    users: state.collections.get('users', Map()),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openModal,
    openCreateUserModal,
    openImportUsersModal,
    push,
    collapseUiComponent,
    expandUiComponent,
  })(UsersPage)
);
