import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import SJTOrderedQuestion from './SJTOrderedQuestion';
import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  saveSJTAnswersAndShowNext,
  saveSJTAnswersAndSubmit,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { anyPending } from '^/responseStates';
import { generateQuestionOrder } from '^/questionGroups';
import AssessmentContainer from './AssessmentContainer';
import AssessmentHeader from './AssessmentHeader';
import AssessmentBody from './AssessmentBody';
import AssessmentFooter from './AssessmentFooter';

export class SJTProductVersion extends PureComponent {
  UNSAFE_componentWillMount() {
    this.preloadImages();
  }

  componentDidMount() {
    // Reset our SJTProductVersion progress
    const currentQuestionIndex = this.props.orderedQuestionsWithAnswers.findIndex(
      orderedQuestion => {
        return orderedQuestion.get('options').every(([, answer]) => !answer);
      }
    );

    if (currentQuestionIndex === -1) {
      // We've already answered this productVersion, so jump to the last page
      this.props.setProductVersionPage(
        Math.floor(this.props.orderedQuestionsWithAnswers.count() - 1)
      );
    } else {
      this.props.setProductVersionPage(currentQuestionIndex);
    }
  }

  preloadImages() {
    const questions = this.props.orderedQuestionsWithAnswers;
    questions.map(question => {
      const questionImage = question.get('image');
      const mainImage = new Image();
      mainImage.src = questionImage;
    });
  }

  isViewingLastQuestion() {
    return (
      this.props.progress.get('currentPage') >=
      this.props.orderedQuestionsWithAnswers.count() - 1
    );
  }

  onNext(action) {
    const currentOrderedQuestion = this.props.orderedQuestionsWithAnswers.get(
      this.props.progress.get('currentPage', 0)
    );
    const questionId = currentOrderedQuestion.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );

    action(
      this.props.activity,
      this.props.productVersion,
      this.props.uiLanguage,
      questionId,
      unsyncedAnswers ||
        generateQuestionOrder(currentOrderedQuestion.get('options')),
      this.props.questionCollectionIdx
    );
  }

  confirmAndClose() {
    if (
      window.confirm(
        i18next.t(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      return; //do nothing, client wants the button to still be here even though it's broken
    }
  }

  render() {
    const {
      activity,
      isPending,
      productVersion,
      questionCollectionIdx,
      raterFor,
    } = this.props;

    const currentQuestionIndex = this.props.progress.get('currentPage', 0);
    const currentOrderedQuestion = this.props.orderedQuestionsWithAnswers.get(
      currentQuestionIndex
    );
    const questionId = currentOrderedQuestion.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );
    const disableSave = !unsyncedAnswers || !unsyncedAnswers.every(id => id);

    const currentPage = this.props.progress.get('currentPage', 0);
    const canResume = this.props.progress.get('canResume');
    const pageCount = this.props.orderedQuestionsWithAnswers.count();

    const action = this.isViewingLastQuestion()
      ? this.props.saveSJTAnswersAndSubmit
      : this.props.saveSJTAnswersAndShowNext;

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          organisationName={activity.getIn(['organisation', 'name'])}
        />

        <AssessmentBody questionIndex={currentQuestionIndex}>
          <img
            src={currentOrderedQuestion.get('image')}
            className="sjt-scenario-image"
          />

          <Well className="squared">
            <h2>
              {i18next.t('Scenario {{current}} of {{total}}', {
                current: currentPage + 1,
                total: pageCount,
              })}
            </h2>

            <div>{currentOrderedQuestion.get('text')}</div>

            <div className="grey mt-base">
              {i18next.t('PARAGRAPH Drag and drop to rank order')}
            </div>
          </Well>

          <SJTOrderedQuestion
            orderedQuestion={currentOrderedQuestion}
            unsyncedAnswers={unsyncedAnswers}
            reorderAnswers={this.props.storeProductVersionAnswer.bind(
              null,
              questionId
            )}
            isPending={isPending}
          />
        </AssessmentBody>

        <AssessmentFooter
          isSaving={isPending}
          showContinue={canResume}
          onContinueLater={() => this.confirmAndClose()}
          onContinue={this.onNext.bind(this, action)}
          isEnd={this.isViewingLastQuestion()}
          isSaveButtonDisabled={disableSave}
        />
      </AssessmentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('saveSJTAnswers'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  saveSJTAnswersAndShowNext,
  saveSJTAnswersAndSubmit,
  push,
})(SJTProductVersion);
