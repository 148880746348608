import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';

export default class CreditOrgInfo extends PureComponent {
  renderValueWithTitle(title, value) {
    return (
      <div className="credits-stats">
        <h5>{title}</h5>
        <div>{value}</div>
      </div>
    );
  }

  render() {
    const { orgCreditDetail } = this.props;
    const {
      credits_purchased,
      credits_spent,
      available_credit,
    } = orgCreditDetail.toObject();

    return (
      <div className="credits-org">
        {this.renderValueWithTitle(
          i18next.t('Credits purchased'),
          credits_purchased
        )}
        {this.renderValueWithTitle(i18next.t('Credits spent'), credits_spent)}
        {this.renderValueWithTitle(
          i18next.t('Available credits'),
          available_credit
        )}
      </div>
    );
  }
}
