import i18next from 'i18next';
import { fromJS, List } from 'immutable';
import React from 'react';
import { Formik, Form, Field } from 'formik';

import Alert from '^/components/Alert';
import FormError from '^/components/FormError';
import { ACTIVITY_TYPES } from '^/components/productVersions/choices';
import ProductNameAndLogo from '^/components/productVersions/ProductNameAndLogo';
import PureComponent from '^/components/PureComponent';
import { getReportCost } from '^/components/reports/utils';
import Table, { Column } from '^/components/Table';
import { JOB_LEVELS } from '^/models/product';
import { Uuid } from '^/reducers/api/types';
import { setFind } from '^/utils-ts';
import {
  ActivityReportTypes,
  ProductInfo,
  ProductOrg,
  ReportTemplate,
} from './ReportGenerationModal/types';

interface OwnProps {
  activityReportTypes: ActivityReportTypes;
  productsInfo: { [key: string]: ProductInfo };
  selectedReportTemplateSet: Set<{ reportTemplateId: Uuid; productId: Uuid }>;
  totalCost: number;
  onClose: () => void;
  response?: Immutable.Map<string, any>;
  initialValues: Record<string, string>;
  onSubmit: (data: { [key: string]: string }) => void;
  jobProfilerRole: string;
  isJobProfiler: boolean;
}

export type Props = OwnProps;

export class ReportGenerationForm extends PureComponent<Props> {
  private renderTable(productOrg: ProductOrg) {
    const {
      selectedReportTemplateSet,
      jobProfilerRole,
      isJobProfiler,
    } = this.props;
    const { report_templates } = productOrg;
    const selectedReportTemplates = report_templates.filter(template =>
      setFind(
        selectedReportTemplateSet,
        ({ reportTemplateId }) => template.id === reportTemplateId
      )
    );
    const showJobLevel = selectedReportTemplates.some(
      report => report.requires_job_level
    );
    const columns: Array<Column<ReportTemplate> & {
      isJobLevelColumn?: boolean;
    }> = [
      {
        header: i18next.t<string>('Selected report'),
        value: reportTemplate => reportTemplate.name,
        className: 'uppercase',
      },
      {
        isJobLevelColumn: true,
        value: reportTemplate =>
          reportTemplate.requires_job_level && (
            <Field as="select" name="jobLevel">
              <option disabled value="">
                Job role level
              </option>
              {JOB_LEVELS.ORDER.map((jobLevel: string) => (
                <option key={jobLevel} value={jobLevel}>
                  {(JOB_LEVELS.DISPLAY as { [key: string]: string })[jobLevel]}
                </option>
              ))}
            </Field>
          ),
        className: 'padding-small',
      },
      {
        header: i18next.t<string>('Cost'),
        value: reportTemplate => getReportCost(productOrg, reportTemplate),
        headerClassName: 'align-right',
        className: 'align-right',
      },
    ];
    isJobProfiler &&
      columns.unshift({
        header: i18next.t<string>('Job role(s)'),
        value: () => jobProfilerRole,
      });
    return (
      <Table
        className="table-smaller"
        columns={columns.filter(
          column => showJobLevel || !column.isJobLevelColumn
        )}
        rows={List(selectedReportTemplates)}
      />
    );
  }

  private validSelection(values: Record<string, string>) {
    const { activityReportTypes, productsInfo } = this.props;
    const compGroupFieldNames = activityReportTypes
      .filter(productOrg => productOrg.comparison_groups.length > 1)
      .map(productOrg => productOrg.product.id)
      .filter(productId => !productsInfo[productId].allUsersHaveCompGroup)
      .map(productId => `comparisonGroup_${productId}`);

    return (
      compGroupFieldNames.every(fieldName => values[fieldName]) &&
      (!Object.keys(values).includes('jobLevel') || values.jobLevel)
    );
  }

  public render() {
    const {
      activityReportTypes,
      initialValues,
      onSubmit,
      productsInfo,
      selectedReportTemplateSet,
      totalCost,
      response,
    } = this.props;

    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="form-horizontal">
            {activityReportTypes
              .filter(productOrg =>
                setFind(
                  selectedReportTemplateSet,
                  ({ productId }) =>
                    productOrg.product.id === productId &&
                    productOrg.product_variant === null
                )
              )
              .map(productOrg => {
                const product = productOrg.product;
                const productId = productOrg.product.id;
                const productInfo = productsInfo[productId];
                const { comparison_groups } = productOrg;
                const onlyOneCompGroup = comparison_groups.length === 1;

                if (
                  onlyOneCompGroup &&
                  values[`comparisonGroup_${productId}`] !==
                    comparison_groups[0].id
                ) {
                  setFieldValue(
                    `comparisonGroup_${productId}`,
                    comparison_groups[0].id
                  );
                }

                return (
                  <div key={productId} className="mt-lg">
                    <h5 className="heading-underlined">
                      <ProductNameAndLogo product={fromJS(product)} />
                    </h5>

                    {product.activity_type ===
                      ACTIVITY_TYPES.CHOICES.PSYCHOMETRIC &&
                      comparison_groups.length > 0 &&
                      (productInfo.allUsersHaveCompGroup ? (
                        <Alert>
                          {i18next.t<string>(
                            'PARAGRAPH already have comparison groups'
                          )}
                        </Alert>
                      ) : (
                        <div>
                          {productInfo.someUsersHaveCompGroup && (
                            <Alert>
                              {i18next.t<string>(
                                'PARAGRAPH some have comparison groups'
                              )}
                            </Alert>
                          )}
                          <div className="list-container">
                            <div className="list-item">
                              <div className="list-item-label uppercase semi-bold-text small very-wide">
                                {i18next.t<string>('Select comparison group')}
                              </div>
                              <div className="list-item-value full-width">
                                <Field
                                  as="select"
                                  name={`comparisonGroup_${productId}`}
                                  disabled={onlyOneCompGroup}
                                  className="full-width margin-none"
                                >
                                  {!onlyOneCompGroup && (
                                    <option value="" disabled>
                                      {i18next.t<string>(
                                        'Please select comparison group'
                                      )}
                                    </option>
                                  )}
                                  {comparison_groups.map(compGroup => (
                                    <option
                                      key={compGroup.id}
                                      value={compGroup.id}
                                    >
                                      {compGroup.name ||
                                        i18next.t<string>('Default')}
                                    </option>
                                  ))}
                                </Field>
                                <FormError
                                  response={response}
                                  formKey="comparison_group"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {this.renderTable(productOrg)}
                    <FormError response={response} formKey="job_level" />
                  </div>
                );
              })}

            <div className="mt-lg">
              <h5 className="mb-sm">{i18next.t<string>('Cost')}</h5>
              <div className="boxed">
                <div className="box">
                  <div className="pull-left">
                    {i18next.t<string>('Total credit cost')}:
                  </div>
                  <div className="pull-right">{totalCost}</div>
                </div>
              </div>
            </div>

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={this.props.onClose}
                >
                  {i18next.t<string>('Cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={!this.validSelection(values)}
                >
                  {i18next.t<string>('Purchase')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ReportGenerationForm;
