import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import {
  setSimpleComponentState,
  clearSimpleComponentState,
} from '^/actions/actions';

export class GroupsPageOrganisations extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.clearSelectedOrganisation();
  }

  setSelectedOrganisation(selectedOrgId) {
    const { organisations } = this.props;
    this.props.setSelectedOrganisation(
      organisations.find(
        organisation => organisation.get('id') === selectedOrgId
      )
    );
  }

  render() {
    const { organisations, selectedOrganisation } = this.props;

    return (
      <div className="group-chooser">
        <select
          value={selectedOrganisation ? selectedOrganisation.get('id') : ''}
          onChange={event => this.setSelectedOrganisation(event.target.value)}
          disabled={!organisations}
        >
          <option value={null}>{i18next.t('All accounts')}</option>
          {organisations &&
            organisations.map(organisation => (
              <option
                key={organisation.get('id')}
                value={organisation.get('id')}
              >
                {organisation.get('name')}
              </option>
            ))}
        </select>
      </div>
    );
  }
}

GroupsPageOrganisations.propTypes = {
  organisations: ImmutablePropTypes.list,
  selectedOrganisation: ImmutablePropTypes.map,
  setSelectedOrganisation: PropTypes.func.isRequired,
  clearSelectedOrganisation: PropTypes.func.isRequired,
};

export const SELECTED_ORGANISATION = [
  'GroupsPageOrganisations',
  'selectedOrganisation',
];

export function mapStateToProps(state) {
  return {
    selectedOrganisation: state.ui
      .get('simpleComponentState')
      .getIn(SELECTED_ORGANISATION),
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setSelectedOrganisation: organisation =>
      dispatch(setSimpleComponentState(SELECTED_ORGANISATION, organisation)),
    clearSelectedOrganisation: () =>
      dispatch(clearSimpleComponentState(SELECTED_ORGANISATION)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsPageOrganisations);
