/* eslint-disable no-fallthrough */
/* eslint-disable no-console */
import { Map, fromJS } from 'immutable';
import Cursor from 'immutable/contrib/cursor';

import * as actions from '^/actions/actions';
import * as collections from '^/actions/collections';
import * as items from '^/actions/items';
import composeReducers from '^/reducers/composeReducers';
import { USER_STATUS } from '^/models/user';

// Activities are loaded in chunks as a performance optimisation
const ITEM_FRAGMENTS = {
  activities: ['activitiesSummary', 'activitiesRespondents'],
};
function getCollectionNames(collectionName) {
  return [collectionName].concat(ITEM_FRAGMENTS[collectionName] || []);
}

function itemsReducer(state = Map(), action) {
  switch (action.type) {
    case items.CLEAR_ITEM:
      return state.set(action.payload, null);
    case items.LOAD_ITEM.REQUEST:
      return action.meta.id !== state.getIn([action.meta.itemType, 'id'])
        ? state.set(action.meta.itemType, null)
        : state;
    case items.LOAD_ITEM.SUCCESS:
      return state.set(action.meta.itemType, fromJS(action.payload));
    case collections.UPDATE_ITEM.SUCCESS: {
      return getCollectionNames(action.meta.collectionName).reduce(
        (_state, collectionName) =>
          _state.getIn([collectionName, 'id']) === action.payload.id
            ? _state.update(collectionName, item =>
                item.merge(fromJS(action.payload))
              )
            : _state,
        state
      );
    }
    default:
      return state;
  }
}

function organisationItemsReducer(state = Map(), action) {
  switch (action.type) {
    case actions.ADD_PRODUCT_TO_ACCOUNT.SUCCESS:
    case actions.ADD_ALL_PRODUCTS_TO_ACCOUNT.SUCCESS:
    case actions.ADD_ADMIN_TO_ORGANISATION.SUCCESS:
    case actions.REMOVE_ADMIN_FROM_ORGANISATION.SUCCESS:
    case actions.ADD_CREDITS_TO_ORGANISATION.SUCCESS:
      if (action.payload) {
        return state.update('organisations', item =>
          item && item.get('id') === action.payload.id
            ? fromJS(action.payload)
            : item
        );
      }
    case actions.ADD_REPORT_TO_PRODUCT_ORGANISATION.SUCCESS:
    case actions.REMOVE_REPORT_FROM_PRODUCT_ORGANISATION.SUCCESS:
      if (action.payload) {
        return state.updateIn(
          ['organisations', 'productorganisation_set'],
          productOrganisations =>
            productOrganisations &&
            productOrganisations.map(productOrganisation =>
              productOrganisation.get('id') === action.payload.id
                ? fromJS(action.payload)
                : productOrganisation
            )
        );
      }
      return state;
    case collections.DELETE_ITEM.SUCCESS:
      const { collectionName, id } = action.meta;
      if (collectionName === 'users') {
        return state.updateIn(
          ['organisations', 'admins'],
          admins => admins && admins.filterNot(admin => admin.get('id') === id)
        );
      }
      return state;
    case actions.INVITE_USER.SUCCESS:
      if (action.payload) {
        const updateInviteStatuses = users =>
          users &&
          users.map(user =>
            user.get('id') === action.payload.user.id
              ? user.set('status', USER_STATUS.INVITED)
              : user
          );
        return state.updateIn(
          ['organisations', 'admins'],
          updateInviteStatuses
        );
      }
    case collections.UPDATE_ITEM.SUCCESS:
      if (action.payload && action.meta.collectionName === 'users') {
        return state.updateIn(
          ['organisations', 'admins'],
          accountAdmins =>
            accountAdmins &&
            accountAdmins.map(user =>
              user.get('id') === action.payload.id
                ? user.set('products', fromJS(action.payload.products))
                : user
            )
        );
      }
      if (
        action.payload &&
        action.meta.collectionName === 'productOrganisationReportTemplate'
      ) {
        return state.updateIn(
          ['organisations', 'productorganisation_set'],
          productOrganisations =>
            productOrganisations &&
            productOrganisations.map(productOrganisation =>
              productOrganisation.update(
                'productorganisationreporttemplate_set',
                productOrgReportTemplates =>
                  productOrgReportTemplates &&
                  productOrgReportTemplates.map(productOrgReportTemplate =>
                    productOrgReportTemplate.get('id') === action.payload.id
                      ? fromJS(action.payload)
                      : productOrgReportTemplate
                  )
              )
            )
        );
      }
      if (
        action.payload &&
        action.meta.collectionName === 'productOrganisation'
      ) {
        return state.updateIn(
          ['organisations', 'productorganisation_set'],
          productOrganisations =>
            productOrganisations &&
            productOrganisations.map(productOrganisation =>
              productOrganisation.get('id') === action.payload.id
                ? fromJS(action.payload)
                : productOrganisation
            )
        );
      }
      return state;
    default:
      return state;
  }
}

function activityItemsReducer(state = Map(), action) {
  switch (action.type) {
    case actions.ADD_USERS_TO_ACTIVITY.SUCCESS:
    case actions.REMOVE_USER_FROM_ACTIVITY.SUCCESS:
      if (action.payload) {
        return getCollectionNames('activities').reduce(
          (_state, collectionName) =>
            _state.update(collectionName, item =>
              item && item.get('id') === action.payload.id
                ? item
                    .set('users', fromJS(action.payload.users))
                    .set('num_respondents', action.payload.users.length)
                    .setIn(
                      ['organisation', 'productorganisation_set'],
                      fromJS(
                        action.payload.organisation.productorganisation_set
                      )
                    )
                : item
            ),
          state
        );
      }
      return state;
    case actions.UPDATE_ACTIVITY_USER.SUCCESS:
      if (
        action.payload &&
        state.getIn(['activities', 'id']) === action.payload.id
      ) {
        let finalState = state;
        let cursor = Cursor.from(
          finalState,
          ['activities', 'users'],
          updated => {
            finalState = updated;
          }
        );
        if (cursor.deref() !== undefined) {
          const userLocation = cursor
            .deref()
            .findEntry(
              activityUser => activityUser.get('id') === action.payload.id
            );
          if (userLocation) {
            const [key] = userLocation;
            cursor = cursor.cursor(key);
            const user = cursor.get('user');
            cursor.set('user', user.merge(fromJS(action.payload)));
            return finalState;
          }
        }
        console.warn(
          'Updating an activity user that cannot be found',
          action.payload
        );
        return finalState;
      }
      return state;
    case actions.CREATE_NOTIFICATION_EMAIL.SUCCESS:
      if (action.payload) {
        if (state.getIn(['activities', 'id']) === action.meta.activityId) {
          const notificationEmails = state.getIn([
            'activities',
            'notification_emails',
          ]);
          const index = notificationEmails.findIndex(email => {
            return email.get('type') === action.payload.type;
          });

          if (index > -1) {
            return state.setIn(
              [
                'activities',
                'notification_emails',
                index,
                'translations',
                action.payload.lang_code,
              ],
              fromJS(action.payload)
            );
          }
          return state;
        }
      }
      return state;
    case actions.UPDATE_NOTIFICATION_EMAIL.SUCCESS:
      if (action.payload) {
        if (state.getIn(['activities', 'id']) === action.payload.activity) {
          const notificationEmails = state.getIn([
            'activities',
            'notification_emails',
          ]);
          const pos = notificationEmails.findIndex(email => {
            return (
              email.getIn(['translations', action.payload.lang_code, 'id']) ===
              action.payload.id
            );
          });

          if (pos > -1) {
            return state.setIn(
              [
                'activities',
                'notification_emails',
                pos,
                'translations',
                action.payload.lang_code,
              ],
              fromJS(action.payload)
            );
          }
          return state;
        }
      }
      return state;
    case actions.CREATE_REPORT_NOTIFICATION_EMAIL.SUCCESS:
      if (action.payload) {
        if (state.getIn(['activities', 'id']) === action.meta.activityId) {
          const reportNotificationEmails = state.getIn([
            'activities',
            'report_notification_emails',
          ]);
          const newReportNotificationEmails = reportNotificationEmails.push(
            fromJS(action.payload)
          );
          return state.setIn(
            ['activities', 'report_notification_emails'],
            newReportNotificationEmails
          );
        }
        return state;
      }
      return state;
    case actions.UPDATE_REPORT_NOTIFICATION_EMAIL.SUCCESS:
      if (action.payload) {
        if (state.getIn(['activities', 'id']) === action.payload.activity) {
          const reportNotificationEmails = state.getIn([
            'activities',
            'report_notification_emails',
          ]);
          const pos = reportNotificationEmails.findIndex(email => {
            return email.get('id') === action.payload.id;
          });

          if (pos > -1) {
            return state.setIn(
              ['activities', 'report_notification_emails'],
              reportNotificationEmails.set(pos, fromJS(action.payload))
            );
          }
          return state;
        }
      }
      return state;
    case actions.ADD_NOMINATION_RULE.SUCCESS:
      if (action.payload) {
        if (state.getIn(['activities', 'id']) === action.meta.activityId) {
          const activity360 = state.getIn(
            ['activities', 'activity360'],
            fromJS({
              nomination_rules: [],
            })
          );
          const rules = activity360.get('nomination_rules');
          const newRules = rules.push(fromJS(action.payload));

          return state.setIn(
            ['activities', 'activity360'],
            activity360
              .set('nomination_rules', newRules)
              .set('is_using_default_rules', false)
          );
        }
      }
      return state;
    case actions.DELETE_NOMINATION_RULE.SUCCESS:
      if (state.getIn(['activities', 'id']) === action.meta.activityId) {
        const activity360 = state.getIn(
          ['activities', 'activity360'],
          fromJS({
            nomination_rules: [],
          })
        );

        const rules = activity360.get('nomination_rules');
        const newRules = rules.filter(
          rule => rule.get('id') !== action.meta.ruleId
        );

        return state.setIn(
          ['activities', 'activity360'],
          activity360
            .set('nomination_rules', newRules)
            .set('is_using_default_rules', false)
        );
      }
      return state;
    case actions.UPDATE_ACTIVITY_REPORT_VISIBILITY.SUCCESS:
      if (state.getIn(['activities', 'id']) === action.meta.activityId) {
        return state.setIn(
          ['activities', 'report_visibility'],
          fromJS(action.payload)
        );
      }
      return state;
    case actions.ADD_REPORT_RECIPIENT.SUCCESS:
    case actions.REMOVE_REPORT_RECIPIENT.SUCCESS:
      const { id, report_recipients } = action.payload;
      return state.updateIn(
        ['activities', 'users'],
        users =>
          users &&
          users.map(user =>
            user.get('id') === id
              ? user.set('report_recipients', fromJS(report_recipients))
              : user
          )
      );
    case actions.MUTE_ACTIVITY_NOTIFICATION.SUCCESS:
      if (state.getIn(['activities', 'id']) === action.payload.id) {
        return state.setIn(
          ['activities', 'notification_emails'],
          fromJS(action.payload.notification_emails)
        );
      }
      return state;
    case actions.UPDATE_REPORT_GENERATION_BATCH.SUCCESS:
      return state
        .updateIn(
          ['activities', 'activity_product_version_items'],
          activityProductVersions =>
            activityProductVersions &&
            activityProductVersions.map(activityProductVersion =>
              activityProductVersion.update(
                'reportgenerationbatch_set',
                batches =>
                  batches &&
                  batches.map(batch =>
                    batch.get('id') === action.payload.id
                      ? fromJS(action.payload)
                      : batch
                  )
              )
            )
        )
        .setIn(
          ['activitiesSummary', 'remaining_credit_cost'],
          action.payload.activity_remaining_credit_cost
        );
    case actions.CREATE_REPORT_GENERATION_BATCH.SUCCESS:
      return state
        .updateIn(
          ['activities', 'activity_product_version_items'],
          activity_product_version_items =>
            activity_product_version_items &&
            activity_product_version_items.map(activityProductVersion =>
              activityProductVersion.get('id') ===
              action.payload.activity_product_version
                ? activityProductVersion.update(
                    'reportgenerationbatch_set',
                    reportgenerationbatch_set =>
                      reportgenerationbatch_set.push(fromJS(action.payload))
                  )
                : activityProductVersion
            )
        )
        .setIn(
          ['activitiesSummary', 'remaining_credit_cost'],
          action.payload.activity_remaining_credit_cost
        );
    case actions.SET_MANUAL_REPORT_GENERATION.SUCCESS:
      if (state.getIn(['activities', 'id']) === action.meta.activityId) {
        return state
          .update(
            'activities',
            activity =>
              activity &&
              activity
                .update(
                  'activity_product_version_items',
                  activityProductVersions =>
                    activityProductVersions &&
                    activityProductVersions.map(activityProductVersion =>
                      activityProductVersion.update(
                        'reportgenerationbatch_set',
                        batches => batches.clear()
                      )
                    )
                )
                .delete('report_visibility')
                .merge(
                  fromJS({
                    send_zipped_reports_to_activity_creator: false,
                    additional_report_recipients_send_date: null,
                    additional_report_recipients: [],
                  })
                )
          )
          .setIn(
            ['activitiesSummary', 'remaining_credit_cost'],
            action.payload.remaining_credit_cost
          );
      }

      return state;
    case actions.ADD_CREDITS_TO_ORGANISATION.SUCCESS:
      return getCollectionNames('activities').reduce(
        (_state, collectionName) =>
          _state.updateIn([collectionName, 'organisation'], organisation =>
            organisation && organisation.get('id') === action.payload.id
              ? organisation.set('credit', action.payload.credit)
              : organisation
          ),
        state
      );
    case actions.GET_USER_INFO.SUCCESS:
      return getCollectionNames('activities').reduce(
        (_state, collectionName) =>
          _state.hasIn([collectionName, 'organisation'])
            ? _state.updateIn([collectionName, 'organisation'], organisation =>
                organisation &&
                action.payload.organisation &&
                organisation.get('id') === action.payload.organisation.id
                  ? organisation.set(
                      'credit',
                      action.payload.organisation.credit
                    )
                  : organisation
              )
            : _state,
        state
      );
    case actions.ADD_PRODUCT_ORGANISATIONS.SUCCESS:
      return getCollectionNames('activities').reduce(
        (_state, collectionName) =>
          _state.getIn([collectionName, 'id']) === action.payload.id
            ? _state
                .setIn(
                  [collectionName, 'product_versions'],
                  fromJS(action.payload.product_versions)
                )
                .setIn(
                  [collectionName, 'activity_product_version_items'],
                  fromJS(action.payload.activity_product_version_items)
                )
            : _state,
        state
      );

    case actions.DELETE_ACTIVITY_PRODUCT_VERSION.SUCCESS:
      return getCollectionNames('activities').reduce(
        (_state, collectionName) =>
          _state.updateIn(
            [collectionName, 'activity_product_version_items'],
            apvs =>
              apvs && apvs.filterNot(apv => apv.get('id') === action.meta.id)
          ),
        state
      );

    case actions.SET_NOTIFICATION_EMAIL_LANGUAGE_PREFERENCE.SUCCESS:
      if (state.getIn(['activities', 'id']) === action.payload.id) {
        const { notifications, lang_code } = action.payload;
        notifications.forEach(notificationType => {
          const index = state
            .getIn(['activities', 'notification_emails'])
            .findIndex(email => email.get('type') === notificationType);
          if (index >= 0) {
            state = state.setIn(
              [
                'activities',
                'notification_emails',
                index,
                'preferred_lang_code',
              ],
              lang_code
            );
          }
        });
      }

    case actions.ADD_PULSING_TO_ACTIVITY.SUCCESS:
      if (
        state.getIn(['activitiesSummary', 'id']) === action.payload.activity_id
      ) {
        return state
          .setIn(['activitiesSummary', 'with_pulse'], true)
          .updateIn(
            ['activitiesSummary', 'activity_product_version_items'],
            apvItems =>
              apvItems.map(apv =>
                apv.get('id') === action.payload.activity_product_version_id
                  ? apv.set('pulse', Map({ id: action.payload.pulse_id }))
                  : apv
              )
          );
      }

    default:
      return state;
  }
}

function groupsItemReducer(state = Map(), action) {
  switch (action.type) {
    case collections.REMOVE_ITEM.SUCCESS:
      if (
        action.meta.collectionName === 'groups' &&
        action.payload.id === state.getIn(['groups', 'id'])
      ) {
        return state.setIn(['groups', 'users'], fromJS(action.payload.users));
      }
      return state;
    default:
      return state;
  }
}

function accountAdminItemReducer(state = Map(), action) {
  switch (action.type) {
    case collections.UPDATE_ITEM.SUCCESS:
      if (action.meta.collectionName === 'users' && action.payload) {
        const id = action.payload.id;
        return state.update(
          'organisations',
          organisation =>
            organisation &&
            organisation.update(
              'admins',
              admins =>
                admins &&
                admins.map(admin =>
                  admin.get('id') === id ? fromJS(action.payload) : admin
                )
            )
        );
      }
    default:
      return state;
  }
}

function pulsesReducer(state = Map(), action) {
  switch (action.type) {
    case actions.STOP_PULSES.SUCCESS:
      let newState = state;
      action.payload.map(stoppedPulse => {
        if (state.getIn(['pulses', 'id']) === stoppedPulse.pulse) {
          const userIndex = state
            .getIn(['pulses', 'userpulse_set'])
            .findIndex(userPulse => userPulse.get('id') === stoppedPulse.id);
          if (userIndex !== -1) {
            newState = newState
              .setIn(
                ['pulses', 'userpulse_set', userIndex, 'status'],
                stoppedPulse.status
              )
              .setIn(
                ['pulses', 'userpulse_set', userIndex, 'end_date'],
                stoppedPulse.end_date
              );
          }
        }
      });
      return newState;
    case actions.UPDATE_PULSE.SUCCESS:
      if (state.getIn(['pulses', 'id']) === action.payload.id) {
        return state.set('pulses', fromJS(action.payload));
      }
      return state;
    default:
      return state;
  }
}

export default composeReducers(
  [
    itemsReducer,
    activityItemsReducer,
    organisationItemsReducer,
    groupsItemReducer,
    accountAdminItemReducer,
    pulsesReducer,
  ],
  Map()
);
