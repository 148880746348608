// Stop IE exploding
if (!window.console) {
  const noop = function noop() {};

  window.console = {
    log: noop,
    warn: noop,
    info: noop,
    error: noop,
    debug: noop,
  };
}
