import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '^/components/PureComponent';
import ItemAndLogo from '^/components/ItemAndLogo';

export default class ProductVersionNameAndLogo extends PureComponent {
  render() {
    const {
      productVersion,
      message,
      small,
      boxed,
      inline,
      name,
      icon,
    } = this.props;
    return (
      <ItemAndLogo
        label={
          name || (productVersion && productVersion.getIn(['product', 'name']))
        }
        icon={
          icon ||
          (productVersion && productVersion.getIn(['product', 'icon'], null))
        }
        message={message}
        small={small}
        boxed={boxed}
        inline={inline}
      />
    );
  }
}
