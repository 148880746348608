import i18next from 'i18next';
import { Map } from 'immutable';
import React, { ChangeEvent, useCallback } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';

import FormError from '^/components/FormError';
import { FormItem } from '^/components/forms/FormItem';
import RevealablePasswordField from '^/components/forms/RevealablePasswordField';
import Img from '^/components/Img';
import LiveButton from '^/components/LiveButton';
import PictureUpload from '^/components/PictureUpload';
import { renderPasswordError } from '^/components/RedeemInviteForm';
import { TAX_LOCATION } from '^/models/organisation';

interface PreviewableFile extends File {
  preview: string;
}

export interface AccountSignUpFormProps {
  account_superuser_name: string;
  account_superuser_email: string;
  account_name: string;
  brand_logo: PreviewableFile | null;
  tax_location: string;
  tax_registration_number: string;
  password: string;
  password2: string;
}

interface OwnProps {
  response: Map<string, any>;
  onSubmit: (data: AccountSignUpFormProps) => void;
}

type Props = OwnProps;

const renderImg = (src: string) => <Img src={src} />;

export const AccountSignUpSignUpForm: React.FC<Props> = props => {
  const onChangeTaxLocation = useCallback(
    (
      event: ChangeEvent<HTMLSelectElement>,
      setFieldValue: FormikHelpers<AccountSignUpFormProps>['setFieldValue']
    ) => {
      if (event.target.value === TAX_LOCATION.CHOICES.REST_OF_WORLD) {
        setFieldValue('tax_registration_number', '');
      }
      setFieldValue('tax_location', event.target.value);
    },
    []
  );

  const { onSubmit, response } = props;

  return (
    <Formik<AccountSignUpFormProps>
      initialValues={{
        account_superuser_email: '',
        account_superuser_name: '',
        account_name: '',
        brand_logo: null,
        tax_location: TAX_LOCATION.CHOICES.UK,
        tax_registration_number: '',
        password: '',
        password2: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, dirty, isSubmitting, setFieldValue }) => (
        <Form className="form-inline mb-none">
          <FormItem
            label={i18next.t<string>('Full name (First Last)')}
            htmlFor="account_superuser_name"
            isRequired
          >
            <Field
              type="text"
              placeholder={i18next.t<string>('Full Name')}
              disabled={isSubmitting}
              name="account_superuser_name"
              id="account_superuser_name"
            />
            <FormError
              response={response}
              formKeys={['superuser', 'full_name']}
            />
          </FormItem>

          <FormItem
            label={i18next.t<string>('Email Address')}
            htmlFor="account_superuser_email"
            isRequired
          >
            <Field
              type="email"
              placeholder={i18next.t<string>('Email address')}
              disabled={isSubmitting}
              name="account_superuser_email"
              id="account_superuser_email"
            />
            <FormError response={response} formKeys={['email', 'email']} />
            <FormError response={response} formKeys={['superuser', 'email']} />
          </FormItem>

          <FormItem
            label={i18next.t<string>('Business Name')}
            htmlFor="account_name"
            isRequired
          >
            <Field
              type="text"
              placeholder={i18next.t<string>('Business name')}
              disabled={isSubmitting}
              name="account_name"
              id="account_name"
            />
            <FormError
              response={response}
              formKeys={['organisation', 'name']}
            />
          </FormItem>

          <FormItem
            label={i18next.t<string>('Tax location')}
            htmlFor="tax_location"
            isRequired
          >
            <select
              id="tax_location"
              name="tax_location"
              disabled={isSubmitting}
              onChange={event => onChangeTaxLocation(event, setFieldValue)}
            >
              <option value="" disabled>
                {i18next.t<string>('Please select…')}
              </option>
              {TAX_LOCATION.ORDER.map(each => (
                <option key={each} value={each}>
                  {TAX_LOCATION.DISPLAY[each]}
                </option>
              ))}
            </select>
            <FormError
              response={response}
              formKeys={['organisation', 'tax_location']}
            />
          </FormItem>

          <FormItem
            label={i18next.t<string>('Tax registration number')}
            htmlFor="tax_registration_number"
          >
            <Field
              type="text"
              placeholder={i18next.t<string>('Tax registration number')}
              disabled={
                !values.tax_location ||
                values.tax_location === TAX_LOCATION.CHOICES.REST_OF_WORLD ||
                isSubmitting
              }
              name="tax_registration_number"
              id="tax_registration_number"
            />
            <FormError
              response={response}
              formKeys={['organisation', 'tax_registration_number']}
            />
          </FormItem>

          <FormItem label={i18next.t<string>('Account logo')}>
            <PictureUpload
              src={values.brand_logo && values.brand_logo.preview}
              className="btn-small"
              noPadding
              selectPictureMessage={i18next.t<string>('Add logo')}
              changePictureMessage={i18next.t<string>('Change logo')}
              setPendingUpload={(file: File) =>
                setFieldValue('brand_logo', file)
              }
              display={renderImg}
              onSubmit={onSubmit}
            />
            <FormError response={response} formKey="brand_logo" />
          </FormItem>

          <FormItem
            label={i18next.t<string>('Password')}
            htmlFor="prepay-password"
            isRequired
          >
            <RevealablePasswordField
              id="prepay-password"
              name="password"
              placeholder={i18next.t<string>('Password')}
              disabled={isSubmitting}
            />
            <FormError
              response={response}
              formKeys={['superuser', 'password']}
              renderError={renderPasswordError}
            />
          </FormItem>

          <FormItem
            label={i18next.t<string>('Confirm password')}
            htmlFor="prepay-confirm-password"
            isRequired
          >
            <RevealablePasswordField
              id="prepay-confirm-password"
              name="password2"
              placeholder={i18next.t<string>('Confirm password')}
              renderError={renderPasswordError}
              disabled={isSubmitting}
            />
            <FormError
              response={response}
              formKeys={['superuser', 'password2']}
              renderError={renderPasswordError}
            />
          </FormItem>

          <LiveButton
            additionalButtonClasses="btn-full-width"
            response={response}
            buttonText={i18next.t<string>('Sign up')}
            pendingMessage={i18next.t<string>('Signing up…')}
            disabled={!dirty || isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AccountSignUpSignUpForm;
