import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '^/components/PureComponent';
import ItemAndLogo from '^/components/ItemAndLogo';

export default class ProductNameAndLogo extends PureComponent {
  render() {
    const {
      product,
      message,
      small,
      boxed,
      inline,
      activityName,
      icon,
    } = this.props;
    return (
      <ItemAndLogo
        label={activityName || product.get('name')}
        icon={icon || product.get('icon', null)}
        message={message}
        small={small}
        boxed={boxed}
        inline={inline}
      />
    );
  }
}
