import { sum } from '^/utils';
import {
  ActivityReportTypes,
  ExistingValues,
  ProductOrg,
  ReportTemplate,
} from './admin/ReportGenerationModal/types';

export function getReportCost(
  productOrg: ProductOrg,
  reportTemplate: ReportTemplate
): number {
  const override = productOrg.productorganisationreporttemplate_set.find(
    each => each.report_template === reportTemplate.id
  );
  return override && override.credit_cost !== null
    ? override.credit_cost
    : reportTemplate.cost;
}

export function getEstimatedCreditCost(
  existingValues: ExistingValues,
  activityReportTypes: ActivityReportTypes
) {
  const costs: {
    [k: string]: {
      [k: string]: number;
    };
  } = activityReportTypes.reduce(
    (acc, productOrg) => ({
      ...acc,
      [`${productOrg.product.id}:${productOrg.product_variant ||
        ''}`]: productOrg.report_templates.reduce(
        (acc0, reportTemplate) => ({
          ...acc0,
          [reportTemplate.id]: getReportCost(productOrg, reportTemplate),
        }),
        {}
      ),
    }),
    {}
  );

  const selectedReportCosts = existingValues.map(
    each =>
      costs[`${each.product}:${each.productVariant || ''}`][
        each.reportTemplateId
      ]
  );

  return sum(selectedReportCosts);
}
