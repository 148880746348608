import React from 'react';
import i18next from 'i18next';

import { OrgSessions } from '^/reducers/api/types';
import CreditWarning from '../../reports/admin/CreditWarning';
import { ReportToGenerate } from './types';
import { sum } from '^/utils';

interface Props {
  notEnoughCredits: boolean | undefined;
  totalCost: number;
  orgSessions: OrgSessions | null;
}

export function getTotalCost(reports: ReportToGenerate[]) {
  return sum(
    reports
      .filter(report => report.selected)
      .map(each => each.cost * each.quantity)
  );
}

export const TotalCostSection: React.FC<Props> = props => {
  const { notEnoughCredits, totalCost, orgSessions } = props;
  return (
    <div className="mt-lg">
      <h5 className="mb-sm">{i18next.t<string>('Cost')}</h5>
      <div className="boxed">
        <div className="box">
          <div className="pull-left">
            {i18next.t<string>('Total credit cost')}:
          </div>
          <div className="pull-right">{totalCost}</div>
        </div>
      </div>
      {notEnoughCredits && orgSessions && (
        <CreditWarning organisation={orgSessions} />
      )}
    </div>
  );
};

export default TotalCostSection;
