import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { LanguageCode } from '^/constants/routes';
import Alert from '^/components/Alert';
import FormError from '^/components/FormError';
import { isPending } from '^/responseStates';
import { ReportToGenerate } from './types';
import { OrgSessions, Uuid, TeamSessions } from '^/reducers/api/types';
import SelectReportsTable from './SelectReportsTable';
import TotalCostSection, { getTotalCost } from './TotalCostSection';
import MultiLanguageSection, { isMultiLanguage } from './MultiLanguageSection';
import { enoughReportsSelected } from './utils';

interface State {
  teamName: string;
}

interface OwnProps {
  productId: Uuid | null;
  reportsToGenerate: ReportToGenerate[];
  orgSessions: OrgSessions | null;
  selectedSessionIds: Set<Uuid>;
  languageCode: LanguageCode;
  getSelectedSessions: () => TeamSessions;
  updateReportsToGenerate: (reports: ReportToGenerate[]) => void;
  updateLanguageCode: (languageCode: LanguageCode) => void;
  response: Immutable.Map<string, any> | undefined;
  generate: (teamName?: string) => void;
  isTeamReports: boolean;
  isJobMatch: boolean;
  roleName?: string;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

export type Props = OwnProps & DispatchProps;

export class ConfirmPurchasePage extends React.Component<Props> {
  public readonly state = {
    teamName: '',
  } as State;

  public render() {
    const {
      orgSessions,
      productId,
      response,
      selectedSessionIds,
      reportsToGenerate,
      updateReportsToGenerate,
      languageCode,
      updateLanguageCode,
      isTeamReports,
      isJobMatch,
      generate,
      roleName,
    } = this.props;
    const { teamName } = this.state;
    const totalCost = getTotalCost(reportsToGenerate);
    const notEnoughCredits =
      orgSessions?.is_using_credit && totalCost > orgSessions.credit;

    return (
      <div>
        {isTeamReports && (
          <div>
            <h5>{i18next.t<string>('Team report name')}</h5>
            <input
              type="text"
              value={teamName}
              onChange={evt => this.setState({ teamName: evt.target.value })}
            />
            <Alert>
              {i18next.t<string>(
                'Enter a name for your team report. This cannot be changed once the report has been generated.'
              )}
            </Alert>
            <FormError response={response} formKey="name" />
          </div>
        )}

        <div>
          <h5>{i18next.t<string>('Selected respondents')}</h5>
          <div className="boxed">
            <div className="box">
              {this.props
                .getSelectedSessions()
                .map(session => session.user.full_name)
                .join(', ')}
            </div>
          </div>
        </div>

        {isMultiLanguage(orgSessions, productId) && (
          <MultiLanguageSection
            languageCode={languageCode}
            updateLanguageCode={updateLanguageCode}
          />
        )}

        <SelectReportsTable
          reportsToGenerate={reportsToGenerate}
          updateReportsToGenerate={updateReportsToGenerate}
          isTeamReports={isTeamReports}
          isJobMatch={isJobMatch}
          roleName={roleName}
        />

        <TotalCostSection
          notEnoughCredits={notEnoughCredits}
          totalCost={totalCost}
          orgSessions={orgSessions}
        />

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>

            <button
              className="btn btn-primary"
              onClick={
                isTeamReports ? () => generate(teamName) : () => generate()
              }
              disabled={
                isPending(response) ||
                !selectedSessionIds.size ||
                (isTeamReports && !teamName) ||
                notEnoughCredits ||
                (!isJobMatch && !enoughReportsSelected(reportsToGenerate))
              }
            >
              {i18next.t<string>('Purchase')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  closeTopModal,
})(ConfirmPurchasePage);
