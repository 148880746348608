export const APPROVAL_STATUS = {
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export function isAwaitingApproval(rater) {
  return (
    rater.get('approved_by_line_manager') === APPROVAL_STATUS.AWAITING_APPROVAL
  );
}
