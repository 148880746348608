import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Set, Map } from 'immutable';
import { connect } from 'react-redux';
import i18next from 'i18next';

import {
  toggleSelection,
  setSelection,
  clearSelection,
  initialiseSelection,
  loadOrgFilters,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import SelectUsersPage from '^/components/users/SelectUsersPage';
import CreateUserInlineForm from '^/components/users/CreateUserInlineForm';
import { addUserAndReset, addUserAndSelect } from '^/actions/actionSequences';
import { USER_ROLES } from '^/models/user';
import Alert from '^/components/Alert';
import ListingTableFilters from '../ListingTableFilters';

export class AddUsersModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userFilters: { filters: {} },
    };
  }
  UNSAFE_componentWillMount() {
    this.props.initialiseSelection('users', this.props.currentIds);
    this.props.loadOrgFilters(this.props.organisationId);
  }

  cancel() {
    this.props.closeTopModal();
  }

  submit() {
    this.props.closeTopModal();
    this.props.onComplete(this.props.selectedUsers);
    if (
      this.props.users &&
      this.props.isJobProfile &&
      this.props.setProfileCreatorEmail
    ) {
      const email = this.props.users
        .get('items')
        .find(user => user.get('id') === this.props.selectedUsers.first())
        .get('email');
      this.props.setProfileCreatorEmail(email);
    }
  }

  getUserOrUsers(numUsers) {
    const { asRespondents } = this.props;
    if (numUsers === 1) {
      return asRespondents ? i18next.t('respondent') : i18next.t('user');
    }
    return asRespondents ? i18next.t('respondents') : i18next.t('users');
  }

  renderSubmitButton() {
    const {
      selectedUsers: selectedUserIds,
      currentIds,
      allowRemove,
      isJobProfile,
    } = this.props;
    const numUsers = allowRemove
      ? selectedUserIds.count()
      : selectedUserIds.subtract(currentIds).count();
    const userOrUsers = this.getUserOrUsers(numUsers);
    let buttonText = i18next.t('Add selected {{userOrUsers}}', { userOrUsers });
    if (numUsers && !isJobProfile) {
      buttonText = i18next.t('Add {{numUsers}} selected {{userOrUsers}}', {
        numUsers,
        userOrUsers,
      });
    }
    if (isJobProfile) {
      buttonText = i18next.t('Add profile creator');
    }
    return (
      <button
        disabled={(!allowRemove || isJobProfile) && numUsers === 0}
        className="btn btn-primary"
        onClick={() => this.submit()}
      >
        {buttonText}
      </button>
    );
  }

  getCurrentIds() {
    const { current, currentIds } = this.props;
    return currentIds || (current || List()).map(each => each.get('id'));
  }

  addEndUser({ full_name, email }) {
    const { organisationId } = this.props;

    if (this.isMaxNumUsersAlreadySelected()) {
      this.props.addUserAndReset(
        organisationId,
        full_name,
        USER_ROLES.ENDUSER,
        email
      );
    } else {
      this.props.addUserAndSelect(
        organisationId,
        full_name,
        USER_ROLES.ENDUSER,
        email
      );
    }
  }

  calculateMaxNumMoreUsers() {
    const { maxNumUsers, selectedUsers } = this.props;
    const numCurrentSelected = selectedUsers
      .intersect(this.getCurrentIds())
      .count();
    return maxNumUsers ? maxNumUsers - numCurrentSelected : undefined;
  }

  getMaxNumMoreUsers() {
    return this.props.maxNumMoreUsers || this.calculateMaxNumMoreUsers();
  }

  isMaxNumUsersAlreadySelected() {
    const maxNumMoreUsers = this.getMaxNumMoreUsers();
    const numNewlySelectedUsers = this.props.selectedUsers
      .subtract(this.getCurrentIds())
      .count();
    return (
      maxNumMoreUsers !== undefined && numNewlySelectedUsers >= maxNumMoreUsers
    );
  }

  render() {
    const {
      orgFilters,
      maxNumMoreUsers,
      filter,
      noLoad,
      groups,
      allowRemove,
      collectionName,
      getUsers,
      fetchAll,
      hideOrganisations,
      hideGroups,
      hideProfilePicture,
      pageSize,
      response,
      maxNumUsers,
      showInlineForm,
      showFilters,
      isJobProfile,
    } = this.props;
    const { userFilters } = this.state;

    return (
      <div>
        {!!maxNumMoreUsers && (
          <Alert>
            {i18next.t('You can add up to {{count}} more respondents', {
              count: maxNumMoreUsers,
            })}
          </Alert>
        )}
        {!!maxNumUsers && !isJobProfile && (
          <Alert>
            {i18next.t('You can add up to {{count}} respondents', {
              count: maxNumUsers,
            })}
          </Alert>
        )}
        {isJobProfile && (
          <div className="mb-md">
            {i18next.t('This is the person creating the job profile.')}
          </div>
        )}

        {showInlineForm && (
          <div className="mb-sm">
            <CreateUserInlineForm
              response={response}
              onSubmit={this.addEndUser.bind(this)}
            />
            <Alert className="mt-sm">
              {i18next.t(
                'Please enter the full name and email address of any new users you wish to add.'
              )}
            </Alert>
          </div>
        )}

        {showFilters && (
          <ListingTableFilters
            filters={[
              {
                key: 'activities',
                name: 'Activity',
                values: orgFilters && orgFilters.activities,
              },
              {
                key: 'groups',
                name: 'Group',
                values: orgFilters && orgFilters.groups,
              },
            ]}
            state={userFilters}
            onStateChange={filters => this.setState({ userFilters: filters })}
          />
        )}

        <SelectUsersPage
          current={this.getCurrentIds()}
          filter={Object.assign({}, filter, userFilters.filters)}
          noLoad={noLoad}
          pageSize={pageSize || 250}
          fetchAll={fetchAll}
          showInlineForm={showInlineForm}
          onSelect={user => this.props.toggleSelection('users', user.get('id'))}
          onSetSelected={(user, selected) =>
            this.props.setSelection('users', user.get('id'), selected)
          }
          onClearAll={() => this.props.clearSelection('users')}
          hideOrganisations={hideOrganisations}
          hideGroups={hideGroups}
          hideProfilePicture={hideProfilePicture}
          groups={groups}
          allowRemove={allowRemove}
          collectionName={collectionName}
          getUsers={getUsers}
          maxNumMoreUsers={this.getMaxNumMoreUsers()}
        />
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button className="btn btn-default" onClick={() => this.cancel()}>
              {i18next.t('Cancel')}
            </button>
            {this.renderSubmitButton()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedUsers: state.ui.get('selectedUiComponents').get('users', Set()),
    response: state.responses.get('addToCollection'),
    orgFilters: state.orgFilters,
    users: state.collections.get(ownProps.collectionName || 'users', Map()),
  };
}

export default connect(mapStateToProps, {
  toggleSelection,
  setSelection,
  clearSelection,
  initialiseSelection,
  closeTopModal,
  addUserAndSelect,
  addUserAndReset,
  loadOrgFilters,
})(AddUsersModal);
