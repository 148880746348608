/* eslint-disable react/no-string-refs */
import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';

export default class Tables extends PureComponent {
  render() {
    const activities = [
      {
        id: 'activity1',
        name: 'A psycap7 activity',
        start: '2070-11-01 9:00',
        end: '2070-12-24 11:30',
        numUsers: 2,
      },
      {
        id: 'activity2',
        name: 'A EAP activity',
        start: '2070-11-01 9:00',
        end: '2070-12-24 11:30',
        numUsers: 2,
      },
    ];

    return (
      <div>
        {/* Tables */}
        <PageHeader>Tables</PageHeader>
        <PageContent>
          <h5 className="heading-underlined">
            New table style 2019 - with checkboxes
          </h5>

          <table className="table-smaller">
            <thead>
              <tr>
                <th>
                  <div className="table-header">
                    <input type="checkbox" name="ALL" />
                  </div>
                </th>
                <th>
                  <div className="table-header table-header-control-on-right">
                    Table header sortable
                    <a className="icon icon-up-down sort-control">
                      <span className="icon-inner" />
                    </a>
                  </div>
                </th>
                <th>
                  <div className="table-header table-header-control-on-right">
                    Table header sortable
                    <a className="icon icon-up-down sort-control">
                      <span className="icon-inner" />
                    </a>
                  </div>
                </th>
                <th>
                  <div className="table-header table-header-control-on-right">
                    Table header sortable
                    <a className="icon icon-up-down sort-control">
                      <span className="icon-inner" />
                    </a>
                  </div>
                </th>
                <th className="align-right">
                  <div className="table-header">Table header .align-right</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="selectable selected">
                <td className="">
                  <input type="checkbox" name="ALL" checked />
                </td>
                <td className="">
                  <a>Value text</a>
                </td>
                <td>Value text</td>
                <td>Value text</td>
                <td className="align-right">1000</td>
              </tr>
              <tr className="selectable">
                <td className="">
                  <input type="checkbox" name="ALL" />
                </td>
                <td className="">
                  <a>Value text</a>
                </td>
                <td>Value text</td>
                <td>Value text</td>
                <td className="align-right">1000</td>
              </tr>
              <tr className="selectable">
                <td className="">
                  <input type="checkbox" name="ALL" />
                </td>
                <td className="">
                  <a>Value text</a>
                </td>
                <td>Value text</td>
                <td>Value text</td>
                <td className="align-right">1000</td>
              </tr>
            </tbody>
          </table>

          <h5>Table with standard white header</h5>
          <p>
            Standard table markup - use a <strong>theader and tbody</strong>{' '}
            tag.
          </p>
          <table className="table">
            <thead>
              <tr>
                {['Title', 'Participating', 'Starts:', 'Complete by:', ''].map(
                  (header, idx) => (
                    <th key={idx}>{header}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {activities.map(activity => (
                <tr key={activity.id}>
                  <td>
                    <p className="lead">{activity.name}</p>
                  </td>
                  <td>
                    {activity.numUsers || 'No users'}
                    <span className="hidden-lg"> participating</span>
                  </td>
                  <td>
                    <span className={activity.start ? 'text-warning' : ''}>
                      <span className="hidden-lg">Starts </span>
                      {activity.start}
                    </span>
                  </td>
                  <td>
                    <span className="hidden-lg">Ends </span>
                    {activity.end}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary pull-right"
                      onClick={() => this.navigateTo(activity.id)}
                    >
                      Manage
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <CodeSample
            code={`<table className="table">
            <thead>
              <tr>{['Title', 'Participating', 'Starts:', 'Complete by:', ''].map(
                  (header, idx) => <th key={idx}>{header}</th>
              )}</tr>
            </thead>
            <tbody>
              {activities.map(activity => (
                <tr key={activity.id}>
                  <td>
                    <p className="lead">
                      {activity.name}
                    </p>
                  </td>
                  <td>
                    {activity.numUsers || 'No users'}
                    <span className="hidden-lg"> participating</span>
                  </td>
                  <td>
                    <span className={activity.start ? 'text-warning' : ''}>
                      <span className="hidden-lg">Starts </span>
                      {activity.start}
                    </span>
                  </td>
                  <td>
                    <span className="hidden-lg">Ends </span>
                    {activity.end}
                  </td>
                  <td>
                    <button className="btn btn-primary pull-right" onClick={() => this.navigateTo(activity.id)}>
                      Manage
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>`}
          />

          <h5>User List table</h5>
          <p>
            This is created by providing a <strong>headers</strong>
            prop to the <strong>ListPage</strong> React component, but the CSS
            output is below.
          </p>
          <div className="row">
            <div className="col-xs-12">
              <table className="user-table">
                <thead>
                  <tr>
                    <th className="user-picture" />
                    <th className="user-name-org">Name /Organisation</th>
                    <th>Email</th>
                    <th className="hidden-when-selectable">Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="user-picture">
                      <div className="image circular">
                        <div className="image-wrapper">
                          <canvas
                            className="fill-parent"
                            width={200}
                            height={200}
                            ref="canvas"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="user-name">No Name Provided</span>
                      <span className="user-organisation">No Organisation</span>
                    </td>
                    <td className="user-email">No Email Provided</td>
                    <td className="hidden-when-selectable">
                      <div className="badge-small badge-warning">
                        Status Unknown
                      </div>
                    </td>
                    <td>
                      <button className="btn btn-default btn-small no-margin">
                        Send Invite
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <CodeSample
            code={`<table className="user-table">
          <thead>
            <tr>
              <th className="user-picture"></th>
              <th className="user-name-org">Name /Organisation</th>
              <th>Email</th>
              <th className="hidden-when-selectable">Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="user-picture">
                <div className="image circular">
                  <div className="image-wrapper">
                    <canvas className="fill-parent" width={200} height={200} ref="canvas" />
                  </div>
                </div>
              </td>
              <td>
                <span className="user-name">No Name Provided</span>
                <span className="user-organisation">
                  No Organisation
                </span>
              </td>
              <td className="user-email">
                No Email Provided
              </td>
              <td className="hidden-when-selectable">
                <div className="badge-small badge-warning">
                  Status Unknown
                </div>
              </td>
              <td>
                <button className="btn btn-default btn-small no-margin">
                  Send Invite
                </button>
              </td>
            </tr>
          </tbody>
        </table>`}
          />

          <h5>User List table - Selectable</h5>
          <p>
            Used when adding users to activities. This is created using the
            <strong>UserTable</strong> React component with some param flags,
            but the simplified CSS is below.
          </p>
          <p>
            'Selected' state has been forced in the CSS below for demonstrative
            purposes.
          </p>
          <div className="row">
            <div className="col-xs-12 scrollable-modal">
              <table className="user-select-table">
                <thead>
                  <tr>
                    <th className="user-picture" />
                    <th className="user-name-org">Name /Organisation</th>
                    <th>Email</th>
                    <th className="centered hidden-when-selectable">Status</th>
                    <th className="centered">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="user-row selectable selected">
                    <td className="user-picture">
                      <div className="image circular">
                        <div className="image-wrapper">
                          <canvas
                            className="fill-parent"
                            width={200}
                            height={200}
                            ref="canvas"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="user-name">No Name Provided</span>
                      <span className="user-organisation">No Organisation</span>
                    </td>
                    <td className="user-email">No Email Provided</td>
                    <td className="centered hidden-when-selectable">
                      <div className="badge-small badge-warning">
                        Status Unknown
                      </div>
                    </td>
                    <td className="centered">
                      <input type="checkbox" checked="true" />
                    </td>
                  </tr>
                  <tr className="user-row selectable">
                    <td className="user-picture">
                      <div className="image circular">
                        <div className="image-wrapper">
                          <canvas
                            className="fill-parent"
                            width={200}
                            height={200}
                            ref="canvas"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="user-name">No Name Provided</span>
                      <span className="user-organisation">No Organisation</span>
                    </td>
                    <td className="user-email">No Email Provided</td>
                    <td className="centered hidden-when-selectable">
                      <div className="badge-small badge-warning">
                        Status Unknown
                      </div>
                    </td>
                    <td className="centered">
                      <input type="checkbox" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <CodeSample
            code={`<div className="col-xs-12 scrollable-modal">
          <table className="user-select-table">
            <thead>
              <tr>
                <th className="user-picture"></th>
                <th className="user-name-org">Name /Organisation</th>
                <th>Email</th>
                <th className="centered hidden-when-select-modal">Status</th>
                <th className="centered">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="user-row selectable selected">
                <td className="user-picture">
                  <div className="image circular">
                    <div className="image-wrapper">
                      <canvas className="fill-parent" width={200} height={200} ref="canvas" />
                    </div>
                  </div>
                </td>
                <td>
                  <span className="user-name">No Name Provided</span>
                  <span className="user-organisation">
                    No Organisation
                  </span>
                </td>
                <td className="user-email">
                  No Email Provided
                </td>
                <td className="centered hidden-when-selectable">
                  <div className="badge-small badge-warning">
                    Status Unknown
                  </div>
                </td>
                <td className="centered">

                  <input type="checkbox" checked="true"/>
                </td>
              </tr>
              <tr className="user-row selectable">
                <td className="user-picture">
                  <div className="image circular">
                    <div className="image-wrapper">
                      <canvas className="fill-parent" width={200} height={200} ref="canvas" />
                    </div>
                  </div>
                </td>
                <td>
                  <span className="user-name">No Name Provided</span>
                  <span className="user-organisation">
                    No Organisation
                  </span>
                </td>
                <td className="user-email">
                  No Email Provided
                </td>
                <td className="centered hidden-when-selectable">
                  <div className="badge-small badge-warning">
                    Status Unknown
                  </div>
                </td>
                <td className="centered">

                  <input type="checkbox"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>`}
          />
        </PageContent>
      </div>
    );
  }
}
