import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'underscore';
import { List, Set } from 'immutable';
import moment from 'moment';
import classNames from 'classnames';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faAngleUp } from '@fortawesome/pro-regular-svg-icons/faAngleUp';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';

import { expandFilter } from '^/actions/actions';
import { withStopPropagation } from '^/utils';
import EditableDatetime from './EditableDatetime';

export function getCurrentValueWithComparator(filter) {
  const valueOf = comparator => filter.currentValue[filter.key + comparator];

  return _(filter.comparators)
    .map((comparator, name) => ({
      name,
      comparator,
      value: valueOf(comparator),
    }))
    .find(({ value }) => value);
}

export class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateTime: moment().format('YYYY-MM-DD'),
    };
  }

  getId() {
    return 'filter.' + this.props.data.name;
  }

  onChooseFilter(value, comparator) {
    const filter = this.props.data;

    if (filter.multi_key) {
      if (!value && !filter.values.values) {
        this.props.onChange(null);
      } else {
        const { currentValue } = filter;
        const currentValues = currentValue ? currentValue.split(',') : [];
        const existingValues = filter.values.values || [];

        const values = Set(currentValues).subtract(
          existingValues.map(each => each.get('id'))
        );

        this.props.onChange((value ? values.add(value) : values).join(','));
      }
    } else {
      this.props.onChange(value, comparator);
    }
  }

  getCurrentValue() {
    const { data } = this.props;

    if (data.values.dateTime) {
      if (data.comparators) {
        return getCurrentValueWithComparator(data);
      }
      return data.currentValue;
    }

    const nameFor = id =>
      (this.getValues() || List())
        .filter(value => value.get('id') === id)
        .map(value => value.get('name'))
        .first();

    if (data.multi_key) {
      return (data.currentValue || '')
        .split(',')
        .map(nameFor)
        .filter(each => each)[0];
    }

    return nameFor(data.currentValue);
  }

  makeEditableDatetime(value, defaultValue) {
    const fieldName = 'filter.dateTime';

    const onAccept = newValue => {
      if (this.props.data.comparators) {
        const currentValue = this.getCurrentValue();
        if (currentValue) {
          this.onChooseFilter(
            moment(newValue).format('YYYY-MM-DD'),
            currentValue.comparator
          );
        }
      } else {
        this.onChooseFilter(moment(newValue).format('YYYY-MM-DD'));
      }
      return { type: '' };
    };

    return (
      <EditableDatetime
        defaultValue={defaultValue}
        value={value}
        fieldName={fieldName}
        onAccept={onAccept}
        showDateOnly
        hideIcon
      />
    );
  }

  isExpanded() {
    return this.props.expandedFilter === this.getId();
  }

  toggleDropdown() {
    if (this.isExpanded()) {
      this.props.expandFilter(null);
    } else {
      this.props.expandFilter(this.getId());
    }
  }

  componentWillUnmount() {
    this.props.expandFilter(null);
  }

  renderFilter(value, enumeratedValues, notSet) {
    return (
      <div
        className={classNames('filter', notSet && ' not-set')}
        onClick={() => this.toggleDropdown()}
      >
        <div className="filter-value">
          {notSet ? this.renderDropdownIcon() : value}
          {notSet || this.renderCloseIcon()}
        </div>
        {this.isExpanded() && this.props.canBeChanged && enumeratedValues && (
          <div className="filter-dropdown">
            <ul>
              {enumeratedValues.map(({ key, name, onClick }) => (
                <li key={key}>
                  <a
                    onClick={evt => {
                      this.toggleDropdown();
                      onClick(evt);
                    }}
                  >
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  renderCloseIcon() {
    return !this.props.hideCancelOption ? (
      <FontAwesomeIcon
        icon={faTimes}
        onClick={withStopPropagation(() => this.onChooseFilter(null))}
      />
    ) : null;
  }

  renderDropdownIcon() {
    return this.props.canBeChanged ? (
      <FontAwesomeIcon
        className="table-control"
        icon={this.isExpanded() ? faAngleUp : faAngleDown}
      />
    ) : null;
  }

  renderDateTimeRange(currentValue) {
    return this.renderFilter(
      <span>
        <span className="filter-prefix">
          {currentValue && currentValue.name}
          {this.renderDropdownIcon()}
        </span>
        {currentValue && (
          <span>{this.makeEditableDatetime(currentValue.value)}</span>
        )}
      </span>,
      _.map(this.props.data.comparators, (comparator, name) => ({
        key: comparator,
        name,
        onClick: () =>
          this.onChooseFilter(
            currentValue ? currentValue.value : this.state.dateTime,
            comparator
          ),
      })),
      !currentValue
    );
  }

  renderDateTime(currentValue) {
    return this.renderFilter(
      <span>
        {this.makeEditableDatetime(currentValue, i18next.t('Choose...'))}
      </span>
    );
  }

  renderEnumeratedValues(values, currentValue) {
    if (values.count() === 0) {
      return null;
    }

    return this.renderFilter(
      <span className="not-date-value">
        {currentValue}
        {this.renderDropdownIcon()}
      </span>,
      values.map(value => ({
        key: value.get('id'),
        name: value.get('name'),
        onClick: () => this.onChooseFilter(value.get('id')),
      })),
      !currentValue
    );
  }

  isPending() {
    return !this.getValues();
  }

  getValues() {
    return this.props.data.values.values;
  }

  render() {
    const currentValue = this.getCurrentValue();

    if (!currentValue && !this.props.showIfUnset) {
      return null;
    }

    if (this.props.data.values.dateTime) {
      if (this.props.data.comparators) {
        return this.renderDateTimeRange(currentValue);
      }
      return this.renderDateTime(currentValue);
    }

    if (this.isPending()) {
      return this.renderFilter(i18next.t('Loading...'));
    }

    return this.renderEnumeratedValues(this.getValues(), currentValue);
  }
}

function mapStateToProps(state) {
  return {
    expandedFilter: state.ui.get('expandedFilter'),
  };
}

export default connect(mapStateToProps, { expandFilter })(Filter);
