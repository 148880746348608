import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from './PureComponent';
import LiveButton from './LiveButton';
import FormError from './FormError';
import { renderPasswordError } from './RedeemInviteForm';
import RevealablePasswordField from './forms/RevealablePasswordField';

export class RedeemPrepayForm extends PureComponent {
  renderError(errors) {
    if (
      errors.size &&
      errors.first() === 'User with this email address already exists.'
    ) {
      return [
        <Trans key="trans" i18nKey="TRANS account already exists">
          An account already exists with this email address.
          <a onClick={this.props.navigateToLogin}>Log in</a> instead.
        </Trans>,
      ];
    }
    return errors;
  }

  render() {
    const { onSubmit, response } = this.props;
    return (
      <Formik
        initialValues={{
          email: '',
          fullName: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form className="form-inline mb-none">
          <p>
            {i18next.t(
              'Please enter a few details below to access Enable and continue to your Activity'
            )}
            :
          </p>

          <div>
            <label htmlFor="email">{i18next.t('Email')}</label>
            <div className="form-item">
              <Field
                type="email"
                name="email"
                id="email"
                placeholder={i18next.t('Email address')}
              />
              <FormError
                response={response}
                formKey="email"
                renderError={this.renderError.bind(this)}
              />
            </div>
          </div>

          <div>
            <label htmlFor="fullName">{i18next.t('Full name')}</label>
            <div className="form-item">
              <Field
                type="text"
                name="fullName"
                id="fullName"
                placeholder={i18next.t('Full name')}
              />
              <FormError response={response} formKey="full_name" />
            </div>
          </div>

          <div>
            <label>{i18next.t('Password')}</label>
            <div className="form-item">
              <RevealablePasswordField
                id="prepay-password"
                name="password"
                placeholder={i18next.t('Password')}
              />
              <FormError
                response={response}
                formKey="password"
                renderError={renderPasswordError}
              />
            </div>
          </div>

          <div>
            <label>{i18next.t('Confirm password')}</label>
            <div className="form-item">
              <RevealablePasswordField
                id="prepay-confirm-password"
                name="confirmPassword"
                placeholder={i18next.t('Confirm password')}
                renderError={renderPasswordError}
              />
              <FormError
                response={response}
                formKey="password1"
                renderError={renderPasswordError}
              />
            </div>
          </div>

          <LiveButton
            additionalButtonClasses="btn-full-width"
            response={response}
            buttonText={i18next.t('Submit details')}
            pendingMessage={i18next.t('Submitting details...')}
            successContent={
              <p>
                {i18next.t(
                  'Account created successfully. Logging you in to Peoplewise...'
                )}
              </p>
            }
            getAdditionalErrors={responseData => {
              return List([
                i18next.t('Unable to redeem prepay. ') +
                  responseData.getIn(['errors', 'msg'], ''),
              ]);
            }}
          />

          <p className="font-size-md">
            {i18next.t('Already registered? ')}
            <a
              className="font-weight-body"
              onClick={this.props.navigateToLogin}
            >
              {i18next.t('Login here.')}
            </a>
          </p>
        </Form>
      </Formik>
    );
  }
}

export default RedeemPrepayForm;
