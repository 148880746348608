import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  text: string;
  iconClass: string;
  textClass?: string;
  time: number | null;
}

export const TaskCompleteTimed: React.FunctionComponent<Props> = ({
  text,
  iconClass,
  textClass,
  time,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    if (time) {
      const timer = setTimeout(() => setIsVisible(false), time);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isVisible ? (
    <div className="row timed-status">
      <div className="col-xs-12">
        <div className="center">
          <FontAwesomeIcon
            className={iconClass}
            icon={faCheckCircle}
            size="5x"
          />
          <h1 className={textClass}>{text}</h1>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TaskCompleteTimed;
