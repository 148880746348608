import React from 'react'; // eslint-disable-line no-unused-vars
import ReactMarkdown from 'react-markdown';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import MAPQuestion from './MAPQuestion';
import Well from '^/components/Well';

export default class MAPQuestionFeedback extends PureComponent {
  render() {
    const { questionWithAnswer } = this.props;
    const [question, answer] = questionWithAnswer.toArray();
    const { is_practice, options, post_question_text } = question.toObject();

    const score = options.count(option => {
      const { correct_choice, id } = option.toObject();
      return answer.getIn([id, 'choice']) === correct_choice;
    });

    return (
      <span>
        {is_practice && <MAPQuestion {...this.props} readOnly />}

        <Well>
          <p>
            {i18next.t(
              'Out of the 5 items, you answered {{score}} correctly.',
              { score }
            )}
          </p>

          {post_question_text && <ReactMarkdown source={post_question_text} />}
        </Well>
      </span>
    );
  }
}
