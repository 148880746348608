import * as actions from '../actions/actions';

export default store => next => action => {
  switch (action.type) {
    case actions.REMOVE_USER_FROM_ACTIVITY.REQUEST:
      store.dispatch(actions.resetResponse('addUsersToActivity'));
      store.dispatch(actions.resetResponse('addGroupsToActivity'));
  }

  return next(action);
};
