/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  faPlusCircle,
  faDownload,
  faKey,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { push } from 'react-router-redux';
import _ from 'underscore';
import { connect } from 'react-redux';

import { getToken } from '^/actions/actions';
import { tryViewExport } from '^/actions/actionSequences';
import {
  clearAnalyticsAggregates,
  clearAnalyticsAggregatesHeatmap,
  clearAnalyticsSessions,
  clearAnalyticsFilters,
  getAnalyticsFilters,
  getAnalyticsAggregates,
  getAnalyticsAggregatesHeatmap,
  getAnalyticsSessions,
  addHeatmapRow,
  updateHeatmapRow,
  clearHeatmapRows,
  clearHeatmapRowsModal,
  addHeatmapRowModal,
} from '^/actions/analytics';
import {
  openAdvancedAnalyticsModal,
  closeTopModal,
  openDataAnalyticsHeatMapOverlayModal,
  openCreateGroupModal,
} from '^/actions/modals';
import { administerOrganisations, can } from '^/capabilities';
import Alert from '^/components/Alert';
import DataAnalyticsTable from '^/components/analytics/DataAnalyticsTable';
import MenuButton, { MenuButtonOption } from '^/components/MenuButton';
import OnOffToggle from '^/components/OnOffToggle';
import { RESULTS } from '^/middleware/reports';
import {
  AnalyticsAppliedFilters,
  AnalyticsFilters,
  AnalyticsFiltersState,
  AnalyticsSessionsState,
  HeatmapRow,
  SelectedFilterProfileData,
  SubAggregate,
  Uuid,
} from '^/reducers/api/types';
import { isPending } from '^/responseStates';
import { StoreState, SearchBarForm } from '^/store';
import DataAnalyticsFilters from './DataAnalyticsFilters';
import { DEMOGRAPHIC_COLUMN_GROUPS } from './DataAnalyticsTableStructure';
import HeatmapLegend from './HeatmapLegend';
import { cantShowHeatmap } from './utils';
import DISCCharts from './DISCCharts';
import ToggleSwitch from '../buttons/ToggleSwitch';
import { downloadElementImage, removeItemWithIndexFromArray } from '^/utils-ts';
import DataAnalyticsChartsPage from './DataAnalyticsChartsPage';
import Loading from '../Loading';
import { DropdownOption } from '../dropdown/DropdownItem';

interface StateProps {
  user: Immutable.Map<string, any>;
  analyticsFilters: AnalyticsFiltersState;
  analyticsSessions: AnalyticsSessionsState;
  filtersLoading: boolean;
  sessionsLoading: boolean;
  searchForm: SearchBarForm | null;
  shortlivedToken: string;
  subaggregates: ReadonlyArray<SubAggregate>;
  subaggregatesHeatmap: ReadonlyArray<SubAggregate>;
  analyticsSessionsOrgList: string[];
}

const SWITCHER_CHOICES = [
  { type: 'ALL', name: i18next.t<string>('All') },
  { type: 'ABOVE_MIDLINE', name: i18next.t<string>('Above midline') },
];

const DEFAULT_ORDER = 'user__full_name';
const PAGE_SIZE = 20;

interface DispatchProps {
  push: typeof push;
  openDataAnalyticsHeatMapOverlayModal: typeof openDataAnalyticsHeatMapOverlayModal;
  addHeatmapRow: typeof addHeatmapRow;
  updateHeatmapRow: typeof updateHeatmapRow;
  clearHeatmapRows: typeof clearHeatmapRows;
  clearHeatmapRowsModal: typeof clearHeatmapRowsModal;
  addHeatmapRowModal: typeof addHeatmapRowModal;
  getAnalyticsFilters: typeof getAnalyticsFilters;
  getAnalyticsAggregates: typeof getAnalyticsAggregates;
  getAnalyticsAggregatesHeatmap: typeof getAnalyticsAggregatesHeatmap;
  getAnalyticsSessions: typeof getAnalyticsSessions;
  openAdvancedAnalyticsModal: typeof openAdvancedAnalyticsModal;
  clearAnalyticsAggregates: typeof clearAnalyticsAggregates;
  clearAnalyticsAggregatesHeatmap: typeof clearAnalyticsAggregatesHeatmap;
  clearAnalyticsSessions: typeof clearAnalyticsSessions;
  clearAnalyticsFilters: typeof clearAnalyticsFilters;
  closeTopModal: typeof closeTopModal;
  getToken: typeof getToken;
  tryViewExport: typeof tryViewExport;
  openCreateGroupModal: typeof openCreateGroupModal;
}

interface OwnProps {
  product?: Uuid;
  aggregate?: HeatmapRow | null;
  index?: number;
  hideAnalyse?: boolean;
}
type TabChoiceType =
  | 'RESULTS_TABLE'
  | 'PROFILE_SUMMARY'
  | 'INDIVIDUAL_GRAPHS'
  | 'TEAM_GRAPHS'
  | 'SUMMARY'
  | 'FILTERED';

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  filters: AnalyticsAppliedFilters;
  page: number;
  ordering?: string;
  showHeatmap: boolean;
  collapsed: boolean;
  showHeatmapLegend: boolean;
  tabSelected: TabChoiceType;
  showTableThree: boolean;
  downloadingImages: boolean;
  imageDownloadBlockNumber: number;
  imageDownloadTotalBlocks: number;
  selectedProfiles: Record<Uuid, SelectedFilterProfileData>;
  previewFilterRanges: Array<Array<string>>;
}

export class DataAnalytics extends React.PureComponent<Props, State> {
  private initialOrdering = DEMOGRAPHIC_COLUMN_GROUPS[0].columns[0].sortKey;
  private subaggregateKeys: ReadonlyArray<keyof AnalyticsAppliedFilters> = [
    'organisation',
    'activity',
    'group',
  ];
  public initialState = {
    page: 1,
    filters: {},
    ordering: this.initialOrdering,
    collapsed: false,
    showHeatmap: false,
    showHeatmapLegend: false,
    tabSelected: 'RESULTS_TABLE' as TabChoiceType,
    showTableThree: false,
    downloadingImages: false,
    imageDownloadBlockNumber: 0,
    imageDownloadTotalBlocks: 0,
    selectedProfiles: {},
    previewFilterRanges: [],
  };

  constructor(props: Props) {
    super(props);
    const { product, aggregate } = props;
    const initialFilters = aggregate
      ? { ...aggregate.appliedFilters, results_ranges: [] }
      : product
      ? { product, results_ranges: [] }
      : {};

    this.state = {
      ...this.initialState,
      filters: initialFilters,
      selectedProfiles: {},
      previewFilterRanges: [],
    };
    this.props.clearAnalyticsSessions();
    this.props.clearAnalyticsFilters();
    this.props.clearHeatmapRows();
    this.props.getAnalyticsFilters(initialFilters);
    this.props.getToken();
  }

  public componentDidUpdate(_prevProps: Props, prevState: State) {
    const {
      filters,
      page,
      ordering,
      tabSelected,
      imageDownloadTotalBlocks,
      imageDownloadBlockNumber,
      downloadingImages,
    } = this.state;
    const filtersChanged =
      !_.isEqual(filters, prevState.filters) ||
      prevState.selectedProfiles !== this.state.selectedProfiles ||
      prevState.previewFilterRanges !== this.state.previewFilterRanges;
    const sortChanged = ordering !== prevState.ordering;
    const pageChanged = page !== prevState.page;
    const tabSelectedChanged = tabSelected !== prevState.tabSelected;
    const productChanged =
      prevState.filters.product !== this.state.filters.product;
    if (filtersChanged) {
      if (productChanged) {
        this.setState({
          previewFilterRanges: [],
        });
      }
      Promise.resolve(
        this.props.getAnalyticsFilters(
          {
            ...filters,
            results_ranges: productChanged
              ? []
              : this.consolidateFilters(
                  this.state.selectedProfiles,
                  this.state.previewFilterRanges
                ),
          },
          DEFAULT_ORDER
        )
      ).then(() => {
        if (this.state.tabSelected === 'SUMMARY') {
          this.analyse();
        }
        if (this.state.tabSelected === 'FILTERED') {
          this.analyseAll();
        }
      });

      this.subaggregateKeys.forEach(key => {
        if (filters[key]) {
          if (filters[key]!.length > 1) {
            this.props.getAnalyticsAggregates(
              filters,
              key,
              filters[key] as string[]
            );

            this.props.getAnalyticsAggregatesHeatmap(
              filters,
              key,
              filters[key] as string[]
            );
          } else {
            this.props.clearAnalyticsAggregates(key);
            this.props.clearAnalyticsAggregatesHeatmap(key);
          }
        }
      });
    } else if (sortChanged) {
      this.props.getAnalyticsSessions(
        {
          ...filters,
          product: this.state.filters.product,
          results_ranges: productChanged
            ? []
            : this.consolidateFilters(
                this.state.selectedProfiles,
                this.state.previewFilterRanges
              ),
        },
        page,
        productChanged ? DEFAULT_ORDER : ordering,
        true
      );
    } else if (pageChanged || tabSelectedChanged) {
      this.props.getAnalyticsSessions(
        {
          ...filters,
          product: this.state.filters.product,
          results_ranges: productChanged
            ? []
            : this.consolidateFilters(
                this.state.selectedProfiles,
                this.state.previewFilterRanges
              ),
        },
        page,
        productChanged ? DEFAULT_ORDER : ordering,
        false
      );
    }
    if (downloadingImages) {
      downloadElementImage(
        `individual-disc-charts-element-${imageDownloadBlockNumber}`,
        `DISC-Individual-Charts-${imageDownloadBlockNumber +
          1}-of-${imageDownloadTotalBlocks}`,
        this.customizeImageDownload
      );
      if (imageDownloadBlockNumber < imageDownloadTotalBlocks - 1) {
        this.setState({
          imageDownloadBlockNumber: imageDownloadBlockNumber + 1,
        });
      } else if (imageDownloadBlockNumber === imageDownloadTotalBlocks - 1) {
        this.setState({
          imageDownloadBlockNumber: 0,
          imageDownloadTotalBlocks: 0,
          downloadingImages: false,
        });
      }
    }
  }

  public downloadTeamImages = (isAgg: boolean) => {
    downloadElementImage(
      isAgg ? 'summary-charts' : 'team-disc-charts-element',
      'DISC-team-charts-1-of-1',
      this.customizeImageDownloadTeamImages
    );
  };

  public downloadIndividualImages = async (totalBlocks: number) => {
    const { analyticsSessions, analyticsFilters } = this.props;
    if (
      analyticsSessions.some(items => items === null) &&
      analyticsSessions.length ===
        Math.ceil((analyticsFilters?.sessions?.count || 1) / PAGE_SIZE)
    ) {
      setTimeout(this.downloadIndividualImages, 1500, totalBlocks);
    } else {
      this.setState({
        downloadingImages: true,
        imageDownloadTotalBlocks: totalBlocks,
      });
    }
  };

  public customizeImageDownloadTeamImages = (
    cloneDocument: Document,
    _cloneElement: HTMLElement
  ) => {
    const staticClass = 'static-page';
    const downloadImageClass = 'download-image';
    const classToSwapBox = cloneDocument?.getElementsByClassName(staticClass);
    while (classToSwapBox && classToSwapBox.length > 0) {
      classToSwapBox[0].classList.add(downloadImageClass);
      classToSwapBox[0].classList.remove(staticClass);
    }
  };

  public customizeImageDownload = (
    cloneDocument: Document,
    _cloneElement: HTMLElement
  ) => {
    const elementToRemoveClass = 'expand-icon';
    const elementToRemove = cloneDocument?.getElementsByClassName(
      elementToRemoveClass
    );
    while (elementToRemove && elementToRemove.length > 0) {
      elementToRemove[0].remove();
    }
  };

  public renderDownloadButton = (offerMultiDownload: boolean) => {
    const product = this.props.analyticsFilters?.product?.type;
    const { tabSelected } = this.state;
    const enableImageButton =
      tabSelected === 'TEAM_GRAPHS' || tabSelected === 'INDIVIDUAL_GRAPHS';
    return offerMultiDownload ? (
      <MenuButton
        position="right"
        label={<FontAwesomeIcon icon={faDownload} />}
      >
        <MenuButtonOption
          onSelect={() => this.onOpenExport('CATEGORICAL')}
          name={i18next.t<string>('Export categorical')}
        />
        <MenuButtonOption
          onSelect={() => this.onOpenExport('NUMERICAL')}
          name={i18next.t<string>('Export numerical')}
        />

        <MenuButtonOption
          disabled={!enableImageButton}
          onSelect={() =>
            this.state.tabSelected === 'TEAM_GRAPHS'
              ? this.downloadTeamImages(this.props.subaggregates?.length > 0)
              : this.downloadIndividualImages(
                  Math.ceil(
                    (this.props.analyticsFilters?.sessions?.count || 1) /
                      PAGE_SIZE
                  )
                )
          }
          name={i18next.t<string>('Export Image')}
        />
      </MenuButton>
    ) : (
      <button
        className="btn btn-small btn-icon-square btn-icon-square-secondary"
        onClick={() => this.onOpenExport()}
        disabled={!product}
        title={i18next.t<string>('Download as CSV')}
      >
        <FontAwesomeIcon icon={faDownload} />
      </button>
    );
  };

  public renderGroupsButton = () => {
    const disabled = this.props.analyticsSessionsOrgList.length !== 1;
    const hoverMessage = disabled
      ? 'Unable to create groups of people across accounts'
      : 'Create group from table';
    return (
      <button
        className="btn btn-small btn-icon-square btn-icon-square-secondary"
        onClick={() => this.onOpenGroupModal()}
        disabled={disabled}
        title={i18next.t<string>(hoverMessage)}
      >
        <FontAwesomeIcon
          icon={faUsers}
          style={{ width: '18px', height: 'auto' }}
        />
      </button>
    );
  };

  public render() {
    const {
      user,
      analyticsFilters,
      analyticsSessions,
      filtersLoading,
      sessionsLoading,
      searchForm,
      hideAnalyse,
      aggregate,
      subaggregates,
    } = this.props;
    const { product, sessions } = analyticsFilters;
    const {
      showHeatmap,
      collapsed,
      showHeatmapLegend,
      tabSelected,
      showTableThree,
      downloadingImages,
      imageDownloadBlockNumber,
      imageDownloadTotalBlocks,
    } = this.state;
    const sessionsAvailable = Boolean(
      analyticsSessions.length &&
        analyticsSessions[0] &&
        analyticsSessions[0].length
    );
    const canShowHeatmap = !cantShowHeatmap(product);
    const canUserAdministerOrganisations = can(user, administerOrganisations());
    const offerMultiDownload =
      canUserAdministerOrganisations && product?.type === 'DISC';

    const filters = {
      ...analyticsFilters.filters,
      product: this.props.product
        ? analyticsFilters.filters.product.filter(
            each => each.id === this.props.product
          )
        : analyticsFilters.filters.product,
    };
    const showAlternativeTable = tabSelected === 'PROFILE_SUMMARY';
    const isTeamChart = tabSelected === 'TEAM_GRAPHS';
    const showCharts =
      tabSelected === 'INDIVIDUAL_GRAPHS' || tabSelected === 'TEAM_GRAPHS';
    const showHeatMapCharts =
      tabSelected === 'SUMMARY' || tabSelected === 'FILTERED';
    return (
      <div>
        <div className="data-analytics-container">
          <HeatmapLegend
            visible={canShowHeatmap && showHeatmap && showHeatmapLegend}
            legendType={product}
            isFooterBar
          />
          <div
            className={classNames(
              'data-analytics-filters-wrapper',
              !collapsed || 'collapsed-filters'
            )}
          >
            <DataAnalyticsFilters
              canUserAdministerOrganisations={canUserAdministerOrganisations}
              appliedFilters={this.state.filters}
              filters={filters}
              toggleCollapsed={this.toggleCollapsed}
              collapsed={collapsed}
              filtersLoading={filtersLoading}
              onChange={this.onFiltersChange}
              searchForm={searchForm}
              product={product}
              handleRemoveResultsRange={this.handleRemoveResultsRange}
              handleClickAdvancedAnalytics={this.handleClickAdvancedAnalytics}
              clearAllFiltersAndOrdering={this.clearAllFiltersAndOrdering}
              inModal={false}
              selectedProfiles={this.state.selectedProfiles}
              previewFilterRanges={this.state.previewFilterRanges}
            />
          </div>
          <div
            className={classNames(
              'data-analytics-table-and-control-wrapper',
              product?.type === 'DISC' && showCharts && 'disc-charts'
            )}
          >
            <div className="data-control-bar">
              <div className={classNames(sessionsAvailable && 'tabset')}>
                {sessionsAvailable && product?.type !== 'DISC' && (
                  <div className="data-control-bar">
                    {product && (
                      <div className="tabset">
                        <div className="tabs">
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'RESULTS_TABLE' && 'selected'
                            )}
                            onClick={() => this.switchTab('RESULTS_TABLE')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Results table')}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'SUMMARY' && 'selected'
                            )}
                            onClick={() => this.switchTab('SUMMARY')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Summary')}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'FILTERED' && 'selected'
                            )}
                            onClick={() => this.switchTab('FILTERED')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Filtered')}
                            </span>
                          </div>
                          {canShowHeatmap && !hideAnalyse && (
                            <div className="text-right">
                              <button
                                className="btn no-border"
                                onClick={this.openHeatmapModalAddDataset}
                                disabled={!product}
                                title={i18next.t<string>('Add data set')}
                              >
                                <FontAwesomeIcon icon={faPlusCircle} />
                                {i18next.t<string>('Add data set')}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {tabSelected !== 'SUMMARY' && tabSelected !== 'FILTERED' && (
                      <div className="data-control-bar">
                        <div className="data-control-bar-inner">
                          {this.renderGroupsButton()}
                          {this.renderDownloadButton(offerMultiDownload)}
                          <div
                            className={classNames(
                              'heatmap-toggle',
                              'display-flex',
                              product || 'disabled'
                            )}
                          >
                            <span>{i18next.t<string>('Heatmap')}</span>
                            <OnOffToggle
                              label={{
                                on: i18next.t<string>('On'),
                                off: i18next.t<string>('Off'),
                              }}
                              value={showHeatmap}
                              secondary
                              onChange={() => this.toggleHeatmap()}
                              disabled={!product || !canShowHeatmap}
                            />
                          </div>
                          <button
                            className={classNames(
                              'btn btn-small btn-icon-square',
                              !showHeatmapLegend && 'btn-icon-square-secondary'
                            )}
                            onClick={this.toggleHeatmapLegend}
                            disabled={
                              !product || !canShowHeatmap || !showHeatmap
                            }
                            title={i18next.t<string>('Show heatmap legend')}
                          >
                            <FontAwesomeIcon icon={faKey} />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {sessionsAvailable && product?.type === 'DISC' && (
                  <div className="data-control-bar">
                    {product && (
                      <div className="tabset">
                        <div className="tabs">
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'RESULTS_TABLE' && 'selected'
                            )}
                            onClick={() => this.switchTab('RESULTS_TABLE')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Results table')}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'PROFILE_SUMMARY' && 'selected'
                            )}
                            onClick={() => this.switchTab('PROFILE_SUMMARY')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Summary')}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'INDIVIDUAL_GRAPHS' && 'selected'
                            )}
                            onClick={() => this.switchTab('INDIVIDUAL_GRAPHS')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Individual graphs')}
                            </span>
                          </div>
                          <div
                            className={classNames(
                              'tab tab-heading',
                              tabSelected === 'TEAM_GRAPHS' && 'selected'
                            )}
                            onClick={() => this.switchTab('TEAM_GRAPHS')}
                          >
                            <span className="tab-link docs-creator">
                              {i18next.t<string>('Team graphs')}
                            </span>
                          </div>

                          {showCharts && (
                            <div className="download-button text-right">
                              {this.renderGroupsButton()}
                              {this.renderDownloadButton(offerMultiDownload)}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="data-control-bar-inner">
                      <>
                        {showAlternativeTable && (
                          <div className="table-switcher">
                            <ToggleSwitch
                              items={SWITCHER_CHOICES.map(choices => {
                                return {
                                  id: choices.type,
                                  name: choices.name,
                                  selected:
                                    (!showTableThree &&
                                      choices.type === 'ALL') ||
                                    (showTableThree && choices.type !== 'ALL'),
                                };
                              })}
                              onClick={choice => this.switchTable(choice)}
                            />
                          </div>
                        )}
                        {!showCharts && this.renderGroupsButton()}
                        {!showCharts &&
                          this.renderDownloadButton(offerMultiDownload)}
                        {!showCharts && (
                          <>
                            <div
                              className={classNames(
                                'heatmap-toggle',
                                'display-flex',
                                !showCharts || 'disabled',
                                product || 'disabled'
                              )}
                            >
                              <span>{i18next.t<string>('Heatmap')}</span>
                              <OnOffToggle
                                label={{
                                  on: i18next.t<string>('On'),
                                  off: i18next.t<string>('Off'),
                                }}
                                value={showHeatmap}
                                secondary
                                onChange={() => this.toggleHeatmap()}
                                disabled={
                                  !product || !canShowHeatmap || showCharts
                                }
                              />
                            </div>
                            <button
                              className={classNames(
                                'btn btn-small btn-icon-square',
                                !showHeatmapLegend &&
                                  'btn-icon-square-secondary'
                              )}
                              onClick={this.toggleHeatmapLegend}
                              disabled={
                                !product ||
                                !canShowHeatmap ||
                                !showHeatmap ||
                                showCharts
                              }
                              title={i18next.t<string>('Show heatmap legend')}
                            >
                              <FontAwesomeIcon icon={faKey} />
                            </button>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {sessionsAvailable ? (
              sessions &&
              product &&
              (!showCharts ? (
                showHeatMapCharts ? (
                  <DataAnalyticsChartsPage
                    product={product}
                    aggregate={aggregate as HeatmapRow}
                    loading={filtersLoading}
                    inModal={false}
                    reloadHeatmapChart={this.reloadHeatmapChart}
                    switchTabToTable={() => this.switchTab('RESULTS_TABLE')}
                  />
                ) : (
                  <DataAnalyticsTable
                    key={product.id}
                    count={sessions.count}
                    user={user}
                    page={this.state.page}
                    loading={filtersLoading}
                    loadingPage={sessionsLoading}
                    aggregate={sessions.aggregate}
                    aggregateExtra={sessions.aggregate_extra}
                    subaggregates={subaggregates}
                    filters={filters}
                    product={product}
                    onChangePage={this.onChangePage}
                    ordering={this.state.ordering}
                    onSetOrdering={this.onChangeOrdering}
                    resultsByPage={analyticsSessions}
                    showHeatmap={canShowHeatmap && showHeatmap}
                    showAlternativeTable={showAlternativeTable}
                    showTableThree={showTableThree}
                  />
                )
              ) : (
                <DISCCharts
                  key={product.id}
                  count={sessions.count}
                  user={user}
                  page={1}
                  loading={filtersLoading}
                  loadingPage={sessionsLoading}
                  aggregate={sessions.aggregate}
                  aggregateExtra={sessions.aggregate_extra}
                  subaggregates={subaggregates}
                  filters={filters}
                  onChangePage={this.onChangePage}
                  ordering={this.state.ordering}
                  filtersCollapsed={collapsed}
                  resultsByPage={analyticsSessions}
                  isTeamChart={isTeamChart}
                  downloadingImages={downloadingImages}
                  imageDownloadBlockNumber={imageDownloadBlockNumber}
                  imageDownloadTotalBlocks={imageDownloadTotalBlocks}
                />
              ))
            ) : showHeatMapCharts ? (
              <div>
                <Loading />
              </div>
            ) : (
              <>
                <div className="sort-text">
                  {i18next.t<string>('0 responses')}
                </div>
                <div
                  className={classNames(
                    showCharts
                      ? 'data-analytics-chart-wrapper'
                      : 'data-analytics-table-wrapper',
                    'full-height'
                  )}
                >
                  <Alert className="centred">
                    {i18next.t<string>(
                      'Use the filters to create a table of results and add to charts'
                    )}
                  </Alert>
                </div>
              </>
            )}
          </div>
        </div>
        {hideAnalyse && (
          <div className="modal-footer clearfix">
            <div className="pull-right">
              <button
                className="btn btn-default"
                onClick={() => this.props.closeTopModal()}
              >
                {i18next.t<string>('Cancel')}
              </button>
              <button className="btn btn-primary" onClick={this.analyse}>
                {aggregate
                  ? i18next.t<string>('Done')
                  : i18next.t<string>('Add to chart')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  private handleRemoveResultsRange = (removeIndex: number) => {
    this.setState({
      previewFilterRanges: removeItemWithIndexFromArray(
        this.state.previewFilterRanges,
        removeIndex
      ),
    });
  };

  private handleClickAdvancedAnalytics = () => {
    const {
      analyticsFilters: { product, sessions },
    } = this.props;
    if (product && sessions) {
      this.props.openAdvancedAnalyticsModal(
        {
          ...this.state.filters,
          product: this.state.filters.product,
          results_ranges: this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
        },
        product,
        sessions.count,
        (resultsRanges: string[][]) => {
          this.setState({
            previewFilterRanges: [
              ...this.state.previewFilterRanges,
              ...resultsRanges,
            ],
          });
        }
      );
    }
  };

  private toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  private toggleHeatmap = () => {
    this.setState({ showHeatmap: !this.state.showHeatmap });
  };

  private toggleHeatmapLegend = () => {
    this.setState({ showHeatmapLegend: !this.state.showHeatmapLegend });
  };

  private getFilterProfileFromFilters = (
    filterProfile: DropdownOption['id'],
    filters: AnalyticsFilters
  ) => {
    return filters.filter_profile.find(filter => filter.id === filterProfile);
  };

  private getSelectedFilterProfiles(
    selectedProfileIds?: string[] | null
  ): Record<Uuid, SelectedFilterProfileData> {
    if (typeof selectedProfileIds === 'undefined') {
      return this.state.selectedProfiles;
    }

    if (!selectedProfileIds) {
      return {};
    }

    const selectedIdsAndProfiles = selectedProfileIds.map(selectedProfileId => {
      const selectedProfile = this.getFilterProfileFromFilters(
        selectedProfileId,
        this.props.analyticsFilters.filters
      );

      if (selectedProfile) {
        return [selectedProfile.id, selectedProfile];
      }
    }) as ReadonlyArray<[string, SelectedFilterProfileData]>;

    return Object.fromEntries(selectedIdsAndProfiles);
  }

  private onFiltersChange = (newFilters: Partial<AnalyticsAppliedFilters>) => {
    if (
      newFilters.product &&
      newFilters.product !== this.state.filters.product
    ) {
      if (this.props.analyticsFilters.product?.type === 'DISC') {
        this.setState({
          ordering: this.initialOrdering,
          tabSelected: 'RESULTS_TABLE' as TabChoiceType,
          showHeatmapLegend: false,
        });
      }

      if (this.state.tabSelected === 'FILTERED') {
        this.analyseAll();
      }
      if (this.state.tabSelected === 'SUMMARY') {
        this.analyse();
      }
    }

    const filters = { ...this.state.filters, ...newFilters };
    let key: keyof AnalyticsAppliedFilters;

    for (key in filters) {
      if (!filters[key] || key === 'filter_profile') {
        delete filters[key];
      }
    }

    const selectedFilterProfiles = this.getSelectedFilterProfiles(
      newFilters.filter_profile
    );

    const hasPreviouslySelectedProduct = filters.product;

    this.setState({
      page: 1,
      selectedProfiles: selectedFilterProfiles,
      filters: {
        ...filters,
        ...(!hasPreviouslySelectedProduct &&
          newFilters.filter_profile &&
          newFilters.filter_profile.length && {
            product:
              selectedFilterProfiles[newFilters.filter_profile[0]].product,
          }),
      },
    });
  };

  private consolidateFilters(
    selectedProfiles: Record<Uuid, SelectedFilterProfileData>,
    previewFilterRanges: Array<Array<string>>
  ): Array<string> {
    const selectedProfileFilters = Object.keys(selectedProfiles).map(
      key => selectedProfiles[key].filters
    );
    return _.flatten([selectedProfileFilters, previewFilterRanges], true);
  }

  private clearAllFiltersAndOrdering = () => {
    this.props.clearAnalyticsAggregates();
    this.setState(this.initialState);
  };

  private onChangePage = (page: number) => {
    this.setState({ page });
  };

  private onChangeOrdering = (ordering: string) => {
    this.setState({
      ordering,
      page: 1,
    });
  };

  private onOpenExport = (type?: string) => {
    const { filters } = this.state;
    const { shortlivedToken, analyticsFilters, user } = this.props;

    this.props.getToken();
    this.props.tryViewExport(shortlivedToken, RESULTS, {
      organisation: user.getIn(['organisation', 'id']),
      ...Object.assign(filters, {
        results_ranges: _.flatten(
          this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
          true
        ),
      }),
      type,
      product: analyticsFilters.product!.id,
    });
  };

  private onOpenGroupModal = () => {
    if (this.props.analyticsSessionsOrgList.length === 1) {
      const org = this.props.analyticsSessionsOrgList[0];
      const selectedFilters = {
        ...this.state.filters,
        results_ranges: _.flatten(
          this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          )
        ),
      };
      this.props.openCreateGroupModal(org, undefined, selectedFilters);
    }
  };

  private openHeatmapModalAddDataset = () => {
    this.addDataset();
    this.props.openDataAnalyticsHeatMapOverlayModal(
      i18next.t<string>('Add data set'),
      null,
      true,
      true,
      this.props.product || ''
    );
  };

  private analyse = () => {
    const { filters } = this.state;
    const { analyticsFilters } = this.props;
    const appliedFilters = _.isEmpty(filters)
      ? { product: analyticsFilters.product?.id }
      : {
          ...filters,
          results_ranges: this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
        };
    const row = {
      appliedFilters,
      filtersState: analyticsFilters,
      children: [],
      selectedProfiles: this.state.selectedProfiles,
      previewFilterRanges: this.state.previewFilterRanges,
    };
    this.props.clearHeatmapRows();
    this.props.addHeatmapRow(row, undefined);
  };

  private switchTab = (tabChoice: TabChoiceType) => {
    if (tabChoice === 'SUMMARY') {
      this.analyse();
    }
    if (tabChoice === 'FILTERED') {
      this.analyseAll();
    }
    this.setState({
      showHeatmapLegend: false,
      tabSelected: tabChoice,
    });
  };

  public switchTable = (choice: string) => {
    return this.setState({ showTableThree: choice !== 'ALL' });
  };

  private addDataset = () => {
    const { filters } = this.state;
    const { analyticsFilters, subaggregates } = this.props;
    this.props.clearHeatmapRowsModal();
    this.props.addHeatmapRowModal(
      {
        appliedFilters: {
          ...filters,
          results_ranges: this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
        },
        filtersState: analyticsFilters,
        children:
          subaggregates.map(({ key, value, filtersState }) => ({
            appliedFilters: {
              [key as keyof AnalyticsAppliedFilters]: [value],
            },
            filtersState: {
              product: analyticsFilters.product,
              filters: analyticsFilters.filters,
              sessions: filtersState?.sessions || null,
            },
            children: [],
            selectedProfiles: this.state.selectedProfiles,
            previewFilterRanges: this.state.previewFilterRanges,
          })) || [],
        selectedProfiles: this.state.selectedProfiles,
        previewFilterRanges: this.state.previewFilterRanges,
      },
      undefined
    );
  };

  private reloadHeatmapChart = () => {
    this.setState({
      ...this.initialState,
      filters: this.state.filters,
      tabSelected: this.state.tabSelected as TabChoiceType,
    });
    this.props.clearAnalyticsSessions();
    this.props.getAnalyticsFilters(this.state.filters);

    if (this.state.tabSelected === 'SUMMARY') {
      this.analyse();
    }
    if (this.state.tabSelected === 'FILTERED') {
      this.analyseAll();
    }
  };

  private analyseAll = () => {
    const { filters } = this.state;
    const { analyticsFilters, subaggregates } = this.props;
    this.props.clearHeatmapRows();
    this.props.addHeatmapRow(
      {
        appliedFilters: {
          ...filters,
          results_ranges: this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
        },
        filtersState: analyticsFilters,
        children:
          subaggregates.map(({ key, value, filtersState }) => ({
            appliedFilters: {
              [key as keyof AnalyticsAppliedFilters]: [value],
            },
            filtersState: {
              product: analyticsFilters.product,
              filters: analyticsFilters.filters,
              sessions: filtersState?.sessions || null,
            },
            children: [],
            selectedProfiles: this.state.selectedProfiles,
            previewFilterRanges: this.state.previewFilterRanges,
          })) || [],
        previewFilterRanges: this.state.previewFilterRanges,
        selectedProfiles: this.state.selectedProfiles,
      },
      undefined
    );
  };
}
export function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
    analyticsFilters: state.analyticsFilters,
    analyticsSessions: state.analyticsSessions,
    subaggregates: state.subaggregates,
    subaggregatesHeatmap: state.subaggregatesHeatmap,
    searchForm: state.form.searchBarForm,
    filtersLoading: isPending(state.responses.get('getAnalyticsFilters')),
    sessionsLoading: isPending(state.responses.get('getAnalyticsSessions')),
    shortlivedToken: state.shortlivedToken,
    analyticsSessionsOrgList: state.analyticsSessionsOrgList,
  };
}

export default connect(mapStateToProps, {
  openDataAnalyticsHeatMapOverlayModal,
  addHeatmapRow,
  updateHeatmapRow,
  clearHeatmapRows,
  clearHeatmapRowsModal,
  addHeatmapRowModal,
  getAnalyticsFilters,
  getAnalyticsAggregates,
  getAnalyticsAggregatesHeatmap,
  getAnalyticsSessions,
  openAdvancedAnalyticsModal,
  clearAnalyticsAggregates,
  clearAnalyticsAggregatesHeatmap,
  clearAnalyticsFilters,
  clearAnalyticsSessions,
  closeTopModal,
  getToken,
  tryViewExport,
  push,
  openCreateGroupModal,
})(DataAnalytics);
