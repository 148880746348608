/* eslint-disable @typescript-eslint/no-unused-vars */
import $ from 'jquery';

import * as actions from '../actions/actions';

let lastOnBeforeUnload = null;

export default store => next => action => {
  switch (action.type) {
    case actions.SCROLL_TOP:
      const element =
        (action.payload && $(action.payload)) || (window && $(window));
      if (element) {
        element.scrollTop(0);
      }
      break;
    case actions.ON_BEFORE_UNLOAD:
      if (window) {
        if (lastOnBeforeUnload) {
          window.removeEventListener('beforeunload', lastOnBeforeUnload);
        }

        lastOnBeforeUnload = e => {
          e.returnValue = action.payload;
          return action.payload;
        };

        window.addEventListener('beforeunload', lastOnBeforeUnload);
      }
      break;
    case actions.OFF_BEFORE_UNLOAD:
      if (window && lastOnBeforeUnload) {
        window.removeEventListener('beforeunload', lastOnBeforeUnload);
        lastOnBeforeUnload = null;
      }
  }

  return next(action);
};
