import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { isPending, hasFailed } from '../responseStates';
import Loading from './Loading';
import PureComponent from './PureComponent';
import Alert, { AlertType } from '^/components/Alert';

class LoadingAndErrors extends PureComponent {
  render() {
    const { responses, loadingClassName } = this.props;

    if (responses.some(isPending)) {
      return <Loading className={loadingClassName} />;
    }

    if (responses.some(hasFailed)) {
      const errors = responses
        .filter(hasFailed)
        .map(response => response.get('errors') || List())
        .flatten(true);

      if (!errors.isEmpty()) {
        return (
          <div>
            {errors.map(error => (
              <Alert type={AlertType.Error} key={error}>
                {error}
              </Alert>
            ))}
          </div>
        );
      }
    }

    return null;
  }
}

LoadingAndErrors.propTypes = {
  responses: ImmutablePropTypes.list.isRequired,
  loadingClassName: PropTypes.string,
};

export default LoadingAndErrors;
