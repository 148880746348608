import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';

import {
  createAdvancedAnalyticsFilterProfile,
  updateAdvancedAnalyticsFilterProfile,
  getAnalyticsSessionsCount,
} from '^/actions/analytics';
import { setModalTitle } from '^/actions/actions';
import { makeRequestAndShowStatus } from '^/actions/actionSequences';
import CreateProfileDetails from './CreateProfileDetails';
import { CreateEditProfileDetailsFormProps } from './CreateEditProfileDetailsForm';
import { MODAL_COMPONENTS } from '../types';
import PercentileWheel from './PercentileWheel';
import { ProfileFilters } from './ProfileFilters';
import {
  AnalyticsAppliedFilters,
  AnalyticsProduct,
  FilterProfileDetail,
} from '^/reducers/api/types';
import { closeTopModal } from '^/actions/modals';
import {
  filterOutInvalidFilters,
  getAdvancedAnalyticsSessionCount,
} from '../utils';
import EditProfileDetails from './EditProfileDetails';

interface DispatchProps {
  setModalTitle: typeof setModalTitle;
  makeRequestAndShowStatus: typeof makeRequestAndShowStatus;
  closeTopModal: typeof closeTopModal;
  getAnalyticsSessionsCount: typeof getAnalyticsSessionsCount;
}

interface OwnProps {
  renderComponent: (componentId: MODAL_COMPONENTS) => void;
  initialCount: number;
  product: AnalyticsProduct | undefined;
  analyticsFilters: AnalyticsAppliedFilters;
  profile?: FilterProfileDetail;
  onApply: (resultsRanges: string[][]) => void;
}

interface State {
  profile: FilterProfileDetail | undefined;
  filters: string[];
  count: number;
}

export type Props = OwnProps & DispatchProps;

export class CreateEditProfilePage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filters: props.profile?.filters || [''],
      count: props.initialCount,
      profile: props.profile,
    };
    this.updateDial = this.updateDial.bind(this);
    this.setModalTitle = this.setModalTitle.bind(this);
  }

  componentDidMount(): void {
    this.state.filters && this.updateDial();
    this.setModalTitle();
  }

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevState.filters !== this.state.filters) {
      this.updateDial();
    }
  }

  private setModalTitle() {
    const { profile } = this.props;
    if (profile) {
      this.props.setModalTitle(
        i18next.t<string>('Advanced analytics - edit profile')
      );
    } else {
      this.props.setModalTitle(
        i18next.t<string>('Advanced analytics - create profile')
      );
    }
  }

  public saveProfile(data: CreateEditProfileDetailsFormProps) {
    this.props.makeRequestAndShowStatus(
      createAdvancedAnalyticsFilterProfile,
      (response: any) => {
        response?.meta?.success && this.setState({ profile: response.payload });
      },
      'createAdvancedAnalyticsFilterProfile',
      i18next.t<string>('Profile saved'),
      {
        ...data,
        filters: filterOutInvalidFilters(this.state.filters),
      }
    );
  }

  public updateProfile(data: CreateEditProfileDetailsFormProps) {
    this.props.makeRequestAndShowStatus(
      updateAdvancedAnalyticsFilterProfile,
      (response: any) => {
        if (response?.meta?.success) {
          this.setState({ profile: response.payload });
        }
      },
      'updateAdvancedAnalyticsFilterProfile',
      i18next.t<string>('Profile updated'),
      {
        formData: data,
        filters: filterOutInvalidFilters(this.state.filters),
        profile_id: this.state.profile?.id,
      }
    );
  }

  public saveACopy(data: CreateEditProfileDetailsFormProps) {
    this.props.makeRequestAndShowStatus(
      createAdvancedAnalyticsFilterProfile,
      (response: any) => {
        if (response?.meta?.success) {
          this.props.closeTopModal(false);
          this.setState({ profile: response.payload });
        }
      },
      'createAdvancedAnalyticsFilterProfile',
      i18next.t<string>('Profile copied'),
      {
        ...data,
        filters: filterOutInvalidFilters(this.state.filters),
      }
    );
  }

  public updateDial() {
    getAdvancedAnalyticsSessionCount(
      this.state.filters,
      this.props.analyticsFilters,
      this.props.getAnalyticsSessionsCount,
      count => this.setState({ count })
    );
  }

  private onApply = () => {
    this.props.onApply([[...this.state.filters]]);
    this.props.closeTopModal();
  };

  render() {
    const { product, initialCount } = this.props;
    const { profile, count } = this.state;
    const hasNoCompletedFilters =
      filterOutInvalidFilters(this.state.filters).length === 0;

    return (
      <div className="profile-detail-page">
        <div className="top">
          {profile ? (
            <EditProfileDetails
              profile={profile}
              onSubmit={this.updateProfile.bind(this)}
              saveACopy={this.saveACopy.bind(this)}
            />
          ) : (
            <CreateProfileDetails
              onSubmit={this.saveProfile.bind(this)}
              disabled={hasNoCompletedFilters}
            />
          )}
          <PercentileWheel count={this.state.count} total={initialCount} />
        </div>
        {product && (
          <ProfileFilters
            product={product}
            setFilters={(filters: string[]) => this.setState({ filters })}
            initialFilters={this.state.filters}
          />
        )}
        <div className="modal-footer text-right">
          <button
            type="button"
            className="btn btn-default margin-right"
            onClick={() => this.props.closeTopModal()}
          >
            {i18next.t<string>('Cancel')}
          </button>

          <button
            disabled={count < 1 || hasNoCompletedFilters}
            type="button"
            className="btn btn-primary"
            onClick={this.onApply}
          >
            {i18next.t<string>('View')}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(undefined, {
  getAnalyticsSessionsCount,
  makeRequestAndShowStatus,
  closeTopModal,
  setModalTitle,
})(CreateEditProfilePage);
