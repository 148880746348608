import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'underscore';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { faArrowAltFromBottom } from '@fortawesome/pro-light-svg-icons/faArrowAltFromBottom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import { getUserImports, getAllOrganisations } from '^/actions/collections';
import { sortList } from '^/actions/actions';
import { openModal } from '^/actions/modals';
import { formatDateTime, sort, toQueryString, fromQueryString } from '^/utils';
import ImportUsersModal from './ImportUsersModal';
import ControlBar from '^/components/ControlBar';
import Loading from '^/components/Loading';
import ListPage from '^/components/ListPage';
import { can, administerOrganisations } from '^/capabilities';
import { withRouter } from '^/withRouter';

let HEADERS = [
  {
    title: i18next.t('Import ID'),
  },
  {
    title: i18next.t('Account'),
    field: 'organisation',
  },
  {
    title: i18next.t('Number of users'),
  },
  {
    title: i18next.t('By'),
    field: ['creator', 'full_name'],
    sortable: true,
  },
  {
    title: i18next.t('When'),
    field: ['created'],
    sortable: true,
  },
];

export class UserImportsPage extends PureComponent {
  isAdmin() {
    return can(this.props.user, administerOrganisations());
  }

  componentDidMount() {
    this.props.getUserImports();
    if (this.isAdmin()) {
      this.props.getAllOrganisations({ simple: true });
    }
  }

  showUsersMatchingImportId(importId) {
    this.props.push(
      '/page/individuals/view/' + toQueryString({ imported: importId })
    );
  }

  sortBy(field) {
    this.props.sortList('userImports', {
      field: field,
      reversed: _.isEqual(this.props.sortBy.field, field)
        ? !this.props.sortBy.reversed
        : false,
    });
  }

  onFilterChange(key, value) {
    const newFilterSpec = Object.assign({}, this.props.filterSpec, {
      [key]: value,
    });
    this.props.push('/page/users/imports/' + toQueryString(newFilterSpec));
  }

  getSortedImports(imports, sortBy) {
    if (!imports || !sortBy.field) {
      return imports;
    }

    return sort(
      imports,
      eachImport => eachImport.getIn(sortBy.field),
      sortBy.reversed
    );
  }

  getFilteredImports(imports, filterSpec) {
    if (!imports || !filterSpec) {
      return imports;
    }

    return imports.filter(function(eachImport) {
      return _.all(filterSpec, function(value, key) {
        return eachImport.get(key) === value;
      });
    });
  }

  getOrganisationName(organisationID) {
    const managedOrganisation = this.props.user.get('organisation');

    if (
      managedOrganisation &&
      managedOrganisation.get('id') === organisationID
    ) {
      return managedOrganisation.get('name');
    }

    const organisation = this.props.organisations.find(
      eachOrg => eachOrg.get('id') === organisationID
    );

    return organisation ? organisation.get('name') : 'No organisation';
  }

  showImportUsersModal() {
    this.props.openModal({
      title: i18next.t('Import users'),
      body: <ImportUsersModal />,
    });
  }

  getFilters() {
    const { filterSpec, user } = this.props;

    if (can(user, administerOrganisations())) {
      return [
        {
          name: i18next.t('Organisation'),
          key: 'organisation',
          currentValue: filterSpec['organisation'],
          values: {
            async: true,
            fetchAction: this.props.getAllOrganisations.bind(null, {
              simple: true,
            }),
            collectionKey: 'organisations',
          },
        },
      ];
    }

    return [];
  }

  renderImportRow(eachImport) {
    return (
      <tr
        key={eachImport.get('id')}
        onClick={() => this.showUsersMatchingImportId(eachImport.get('id'))}
      >
        <td>
          <a>{eachImport.get('name')}</a>
        </td>
        {can(this.props.user, administerOrganisations()) ? (
          <td>
            <a>{this.getOrganisationName(eachImport.get('organisation'))}</a>
          </td>
        ) : null}
        <td>
          <a>{eachImport.get('user_count')}</a>
        </td>
        <td>{eachImport.getIn(['creator', 'full_name'])}</td>
        <td>{formatDateTime(eachImport.get('created'))}</td>
      </tr>
    );
  }

  renderTable(sortedImports) {
    const filters = this.getFilters();
    const isFiltered = !_.isEmpty(this.props.filterSpec);
    const headers = [...HEADERS];

    if (!sortedImports || (this.isAdmin() && !this.props.organisations)) {
      return <Loading className="list-loading" />;
    }
    const { filterSpec } = this.props;
    const filteredImports = this.getFilteredImports(sortedImports, filterSpec);
    if (!can(this.props.user, administerOrganisations())) {
      headers.splice(1, 1);
    }

    return sortedImports.isEmpty() ? (
      <p>
        {i18next.t('You have no previous user imports. Would you like to ')}
        <a onClick={() => this.showImportUsersModal()}>
          {i18next.t('import some users')}
        </a>
      </p>
    ) : (
      <ListPage
        typeNamePlural="imports"
        items={filteredImports}
        headers={headers}
        tableClassName="user-imports"
        renderer={this.renderImportRow.bind(this)}
        filters={filters}
        isFiltered={isFiltered}
        onFilterChange={this.onFilterChange.bind(this)}
        ordering={this.props.sortBy}
        onOrderingChange={ordering => this.sortBy(ordering)}
      />
    );
  }

  render() {
    const { imports, sortBy } = this.props;
    const sortedImports = this.getSortedImports(imports, sortBy);

    return (
      <div>
        <ControlBar title={i18next.t('User imports')} hideAdd hideSearch>
          <button
            className="btn btn-default pull-right"
            title={i18next.t('Import users')}
            onClick={() => this.showImportUsersModal()}
          >
            <FontAwesomeIcon icon={faArrowAltFromBottom} />
            {i18next.t('Import users')}
          </button>
        </ControlBar>

        {this.renderTable(sortedImports)}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    filterSpec: fromQueryString(props.router.params['filter']),
    user: state.userProfile,
    imports: state.collections.getIn(['userImports', 'items']),
    sortBy: state.sortOrders.get('userImports', {}),
    organisations: state.collections.getIn(['organisations', 'items']),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getUserImports,
    push,
    sortList,
    openModal,
    getAllOrganisations,
  })(UserImportsPage)
);
