import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';
import * as actions from '../../actions/actions';

const UnconnectedModalButton = class SmartComponent extends PureComponent {
  getClassName() {
    return 'btn'.concat(
      this.props.className ? ' '.concat(this.props.className) : ''
    );
  }

  openModal() {
    this.props.openModal({
      // className is applied to modal-wrapper
      className: this.props.modalClass,
      title: 'A Modal Example',
      body: (
        <div>
          <p>Hello, world!</p>
          <p>This is an example of some body content for a modal.</p>
          <div className="form-horizontal">
            <label>
              Ohh, look! An Input
              <input
                type="text"
                placeholder="Something, something..."
                defaultValue=""
              />
            </label>

            <div className="modal-table-full">
              <table className="table-smaller">
                <thead>
                  <tr>
                    <th>
                      <div className="table-header">
                        <input type="checkbox" name="ALL" />
                      </div>
                    </th>
                    <th>
                      <div className="table-header table-header-control-on-right">
                        Table header sortable
                        <a className="icon icon-up-down sort-control">
                          <span className="icon-inner" />
                        </a>
                      </div>
                    </th>
                    <th>
                      <div className="table-header table-header-control-on-right">
                        Table header sortable
                        <a className="icon icon-up-down sort-control">
                          <span className="icon-inner" />
                        </a>
                      </div>
                    </th>
                    <th className="align-right">
                      <div className="table-header">
                        Table header .align-right
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="">
                      <input type="checkbox" name="ALL" />
                    </td>
                    <td className="">
                      <a>Value text</a>
                    </td>
                    <td>Value text</td>
                    <td className="align-right">1000</td>
                  </tr>
                  <tr>
                    <td className="">
                      <input type="checkbox" name="ALL" />
                    </td>
                    <td className="">
                      <a>Value text</a>
                    </td>
                    <td>Value text</td>
                    <td className="align-right">1000</td>
                  </tr>
                  <tr>
                    <td className="">
                      <input type="checkbox" name="ALL" />
                    </td>
                    <td className="">
                      <a>Value text</a>
                    </td>
                    <td>Value text</td>
                    <td className="align-right">1000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ),
      footer: (
        <div className="pull-right">
          <button
            className="btn btn-default"
            onClick={this.closeTopModal.bind(this)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={this.closeTopModal.bind(this)}
          >
            Submit
          </button>
        </div>
      ),
    });
  }

  closeTopModal() {
    this.props.closeTopModal();
  }

  render() {
    return (
      <button
        className={this.getClassName()}
        onClick={this.openModal.bind(this)}
      >
        {this.props.children}
      </button>
    );
  }
};

const ModalButton = connect(null, actions)(UnconnectedModalButton);

export default class Modals extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Modals</PageHeader>
        <h3>Modal buttons</h3>
        <PageContent>
          <p className="lead">
            These buttons are for demonstration only. click to open!
          </p>
          <ModalButton className="btn-primary">
            Open A Standard Modal
          </ModalButton>

          <CodeSample
            code={`<ModalButton className="btn-primary">
            Open A Standard Modal
          </ModalButton>`}
          />

          <ModalButton className="btn-default" modalClass="modal-small">
            Open A Small (Alert) Modal
          </ModalButton>
          <CodeSample
            code={`<ModalButton className="btn-default" modalClass="modal-small">
            Open A Small (Alert) Modal
          </ModalButton>`}
          />

          <ModalButton className="btn-danger" modalClass="error">
            Open An Error Modal
          </ModalButton>

          <CodeSample
            code={`<ModalButton className="btn-danger" modalClass="error">
            Open An Error Modal
          </ModalButton>`}
          />
        </PageContent>
      </div>
    );
  }
}
