import i18next from 'i18next';
import React from 'react';

import DataAnalytics from '^/components/analytics/DataAnalytics';
import ControlBar from '^/components/ControlBar';

export class DataAnalyticsPage extends React.PureComponent {
  public render() {
    return (
      <div className="data-analytics">
        <ControlBar
          title={i18next.t<string>('Data analytics')}
          hideSearch
          hideAdd
        />
        <DataAnalytics />
      </div>
    );
  }
}

export default DataAnalyticsPage;
