/* eslint-disable @typescript-eslint/no-unused-vars */
import { Map } from 'immutable';
import createCachedSelector from 're-reselect';

import { anyPending, hasSucceeded, isPending } from '^/responseStates';
import { StoreState } from '^/store';
import { selectPropString, selectResponses } from '.';

export const selectResponse = createCachedSelector(
  selectResponses,
  selectPropString,
  (reponses, str) => reponses.get(str, Map())
)((_state, str) => str);

export const selectResponseIsPending = createCachedSelector(
  selectResponse,
  selectPropString,
  (response, _str) => isPending(response)
)((_state, str) => str);

export const selectResponseMoreToCome = createCachedSelector(
  selectResponse,
  response => hasSucceeded(response) && response.getIn(['payload', 'next'])
)((_state, str) => str);

export const selectResponseAnyPending = createCachedSelector(
  selectResponses,
  (_state: StoreState, pendingResponses: ReadonlyArray<string>) =>
    pendingResponses,
  (responses, pendingResponses) =>
    anyPending(pendingResponses.map(response => responses.get(response)))
)((_state, pendingResponses) => pendingResponses.join());
