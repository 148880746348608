/* eslint-disable no-undef */
import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '../../PureComponent';
import { ACTIVITY_STATUS } from '^/activityStatus';
import ActivityPrepaidUsersTab from './users/ActivityPrepaidUsersTab';
import ActivityUsersTab from './users/ActivityUsersTab';
import ProfileCreatorsTab from './users/ProfileCreatorsTab';

export default class RespondentsTab extends PureComponent {
  setPrepay(value) {
    this.props.updateActivity(this.props.activityId, { is_prepay: value });
  }

  confirmRemoveUser(activityId, userId, userName) {
    userName = userName ? userName : i18next.t('this user');
    if (
      confirm(
        i18next.t(
          'Are you sure you wish to remove {{userName}}? They will be removed from the activity.',
          { userName }
        )
      )
    ) {
      this.props.removeUserFromActivityAndRefresh(activityId, userId);
    }
  }

  render() {
    const { isPrepay, canBePrepay, activityUsers, isJobProfiler } = this.props;
    const notYetSetUp =
      (!activityUsers || activityUsers.isEmpty()) && !isPrepay;
    const activityEnded =
      this.props.activity.get('status') === ACTIVITY_STATUS.ENDED;
    return this.props.isPrepay || activityEnded ? (
      <ActivityPrepaidUsersTab
        {...this.props}
        isJobProfiler={isJobProfiler}
        confirmRemoveUser={(activityId, userId, userName) =>
          this.confirmRemoveUser(activityId, userId, userName)
        }
      >
        {notYetSetUp && (
          <p>
            {i18next.t('or')}
            <a onClick={() => this.setPrepay(false)}>
              {i18next.t('add existing Enable users to this Activity')}
            </a>
            .
          </p>
        )}
      </ActivityPrepaidUsersTab>
    ) : isJobProfiler ? (
      <ProfileCreatorsTab
        {...this.props}
        isJobProfiler={isJobProfiler}
        confirmRemoveUser={(activityId, userId, userName) =>
          this.confirmRemoveUser(activityId, userId, userName)
        }
      >
        {notYetSetUp && canBePrepay && (
          <p>
            <a onClick={() => this.setPrepay(true)}>
              {i18next.t('distribute this activity using a generic link')}
            </a>
            .
          </p>
        )}
      </ProfileCreatorsTab>
    ) : (
      <ActivityUsersTab
        {...this.props}
        isJobProfiler={isJobProfiler}
        confirmRemoveUser={(activityId, userId, userName) =>
          this.confirmRemoveUser(activityId, userId, userName)
        }
      >
        {notYetSetUp && canBePrepay && (
          <p>
            {i18next.t('or')}{' '}
            <a onClick={() => this.setPrepay(true)}>
              {i18next.t('distribute this activity using a generic link')}
            </a>
            .
          </p>
        )}
      </ActivityUsersTab>
    );
  }
}
