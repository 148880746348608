import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { CURRENCIES } from '^/utils';
import { TAX_LOCATION } from '^/models/organisation';

export default class CreditCostBreakdownTable extends PureComponent {
  displayCurrency(amount) {
    const { currency } = this.props;
    const currencySymbol = CURRENCIES[currency]['symbol'];
    const currencyDisplayFactor = CURRENCIES[currency]['displayFactor'];
    return `${currencySymbol}${(amount / currencyDisplayFactor)
      .toFixed(2)
      .toLocaleString()}`;
  }

  render() {
    const {
      account,
      costOfCredits,
      discountAmount,
      discountPercentage,
      taxAmount,
      totalAfterTax,
      isValidPurchase,
    } = this.props;
    const taxLocation = account.get('tax_location');
    const accountPaysUKTax = taxLocation === TAX_LOCATION.CHOICES.UK;

    return (
      <table className="inline-table centered">
        <tbody>
          <tr>
            <td>{i18next.t('Credit cost')}:</td>
            <td>{isValidPurchase && this.displayCurrency(costOfCredits)}</td>
          </tr>
          {discountPercentage > 0 && isValidPurchase && (
            <tr>
              <td>{discountPercentage}% discount:</td>
              <td>{isValidPurchase && this.displayCurrency(discountAmount)}</td>
            </tr>
          )}
          {taxLocation && (
            <tr>
              <td>
                {accountPaysUKTax ? i18next.t('VAT (+ 20%)') : i18next.t('VAT')}
                :
              </td>
              <td>
                {accountPaysUKTax
                  ? isValidPurchase && this.displayCurrency(taxAmount)
                  : i18next.t('EXEMPT')}
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td>{i18next.t('Total')}:</td>
            <td>{isValidPurchase && this.displayCurrency(totalAfterTax)}</td>
          </tr>
        </tfoot>
      </table>
    );
  }
}
