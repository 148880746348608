import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import {
  productNeedsComparisonGroup,
  findProductOrganisation,
} from '^/models/product';
import {
  addProductToAccount,
  addAllProductsToAccount,
  addReportToProductOrganisation,
  removeReportFromProductOrganisation,
} from '^/actions/actions';
import { openModal } from '^/actions/modals';
import { getAllProducts } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import { ACTIVITY_TYPES } from '^/components/productVersions/choices';
import Loading from '^/components/Loading';
import Table from '^/components/Table';
import EditReportTemplates from './EditReportTemplates';
import EditAccountProductComparisonGroups from './EditAccountProductComparisonGroups';
import Checkbox from '^/components/Checkbox';

export class EditAccountProducts extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getAllProducts();
  }

  isReportPending(reportId) {
    const { addReportResponses, removeReportResponses } = this.props;
    return (
      isPending(addReportResponses && addReportResponses.get(reportId)) ||
      isPending(removeReportResponses && removeReportResponses.get(reportId))
    );
  }

  render() {
    const {
      account,
      productResponses,
      updateReportResponse,
      isLoading,
      products,
    } = this.props;
    const {
      pure_products: accountProducts,
      id: accountId,
    } = account.toObject();
    const accountProductIds = accountProducts.map(each => each.get('id'));

    if (isLoading || !products) {
      return <Loading />;
    }
    return (
      <div>
        <div className="margin-bottom">
          <h5 className="mb-0">{i18next.t('Product and report access')}</h5>
          <p>
            (
            {accountProducts.isEmpty()
              ? i18next.t('None selected')
              : i18next.t('{{number}} selected', {
                  number: accountProducts.count(),
                })}
            )
          </p>
          <form className="form-inline scrollable">
            <Table
              className="highlight-rows selectable compact"
              columns={[
                {
                  header: (
                    <Checkbox
                      id="ALL_PRODUCTS"
                      name="ALL_PRODUCTS"
                      checked={products.every(product =>
                        accountProductIds.contains(product.get('id'))
                      )}
                      onChange={() =>
                        this.props.addAllProductsToAccount(accountId)
                      }
                    />
                  ),
                  value: product => {
                    const productId = product.get('id');
                    return (
                      <Checkbox
                        id={productId}
                        checked={accountProductIds.contains(productId)}
                        onChange={event =>
                          this.props.addProductToAccount(
                            accountId,
                            productId,
                            event.target.checked
                          )
                        }
                        disabled={isPending(
                          productResponses && productResponses.get(productId)
                        )}
                      />
                    );
                  },
                },
                {
                  header: i18next.t('Assessment type'),
                  value: product =>
                    ACTIVITY_TYPES.DISPLAY[product.get('activity_type')],
                },
                {
                  header: i18next.t('Product'),
                  value: product => product.get('name'),
                },
                {
                  header: i18next.t('Reports'),
                  value: product => {
                    const reports = product.get('report_templates');
                    const productOrganisation = findProductOrganisation(
                      account,
                      product.get('id'),
                      null
                    );

                    return reports.isEmpty() ? (
                      <span className="no-reports">
                        {i18next.t('No reports available')}
                      </span>
                    ) : (
                      <EditReportTemplates
                        reports={reports}
                        orgReports={
                          productOrganisation &&
                          productOrganisation.get('report_templates')
                        }
                        orgReportCostObjects={
                          productOrganisation &&
                          productOrganisation.get(
                            'productorganisationreporttemplate_set'
                          )
                        }
                        addReport={reportId =>
                          this.props.addReportToProductOrganisation(
                            productOrganisation.get('id'),
                            reportId
                          )
                        }
                        removeReport={reportId =>
                          this.props.removeReportFromProductOrganisation(
                            productOrganisation.get('id'),
                            reportId
                          )
                        }
                        isLoading={reportId => this.isReportPending(reportId)}
                        updateReportResponse={updateReportResponse}
                      />
                    );
                  },
                },
              ]}
              rowClassName={product =>
                accountProductIds.contains(product.get('id')) ? 'selected' : ''
              }
              rows={products}
            />
          </form>
        </div>

        <h5 className="mb-0">{i18next.t('Comparison group access')}</h5>
        {accountProductIds.map(productId => {
          const product = products.find(each => each.get('id') === productId);
          const productOrganisation = findProductOrganisation(
            account,
            productId,
            null
          );
          return productOrganisation && productNeedsComparisonGroup(product) ? (
            <EditAccountProductComparisonGroups
              key={productId}
              product={product}
              productOrganisation={productOrganisation}
            />
          ) : null;
        })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productResponses: state.multiResponses.get('addProductToAccount'),
    addReportResponses: state.multiResponses.get(
      'addReportToProductOrganisation'
    ),
    removeReportResponses: state.multiResponses.get(
      'removeReportFromProductOrganisation'
    ),
    updateReportResponse: state.multiResponses.getIn([
      'updateItem',
      'productOrganisationReportTemplate',
    ]),
    isLoading: isPending(
      state.multiResponses.getIn(['getCollection', 'products'])
    ),
    products: state.collections.getIn(['products', 'items']),
  };
}

export default connect(mapStateToProps, {
  addProductToAccount,
  addAllProductsToAccount,
  openModal,
  getAllProducts,
  addReportToProductOrganisation,
  removeReportFromProductOrganisation,
})(EditAccountProducts);
