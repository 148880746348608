import i18next from 'i18next';
import React, { ChangeEvent, PureComponent } from 'react';

import CheckableButtonQuestion from '^/components/buttons/CheckableButtonQuestion';
import Well from '^/components/Well';
import { ResilienceQuestions } from './ResilienceProductVersion';

interface OwnProps {
  questions: ResilienceQuestions;
  selectedValue: string;
  isPending: boolean;
  onChange: (questionId: string, value: string) => void;
}

type Props = OwnProps;

export class MultipleChoice extends PureComponent<Props> {
  public render() {
    const { questions, isPending, selectedValue } = this.props;

    return (
      <div>
        <Well>
          {i18next.t<string>('Pick your preferred answer to each question')}
        </Well>
        <div>
          {questions.map(questionAndAnswer => {
            const [question] = questionAndAnswer;
            const buttons = question.options.map(option => ({
              value: option.id,
              label: option.text,
              disabled: isPending,
              checked: selectedValue ? option.id === selectedValue : undefined,
            }));
            return (
              <CheckableButtonQuestion
                question={question.text}
                buttons={buttons}
                key={question.id}
                onChange={this.onChange.bind(this, question.id)}
                size="medium"
              />
            );
          })}
        </div>
      </div>
    );
  }

  private onChange(questionID: string, event: ChangeEvent<HTMLInputElement>) {
    this.props.onChange(questionID, event.target.value);
  }
}

export default MultipleChoice;
