import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { setModalTitle } from '^/actions/actions';
import { makeRequestAndShowStatus } from '^/actions/actionSequences';
import {
  getAdvancedAnalyticsFilterProfiles,
  deleteAdvancedAnalyticsFilterProfile,
} from '^/actions/analytics';
import { openAdvancedAnalyticsConfirmModal } from '^/actions/modals';
import Loading from '^/components/Loading';
import WithPopover from '^/components/WithPopover';
import { AnalyticsProduct, FilterProfile, Uuid } from '^/reducers/api/types';
import { hasFailed, isPending } from '^/responseStates';
import { StoreState } from '^/store';
import { MODAL_COMPONENTS } from '../types';
import { convertFilterToDisplay } from '../utils';

interface DispatchProps {
  getAdvancedAnalyticsFilterProfiles: typeof getAdvancedAnalyticsFilterProfiles;
  setModalTitle: typeof setModalTitle;
  openAdvancedAnalyticsConfirmModal: typeof openAdvancedAnalyticsConfirmModal;
  makeRequestAndShowStatus: typeof makeRequestAndShowStatus;
}

interface StateProps {
  filterProfiles: FilterProfile[] | null;
  getResponse: Map<string, any>;
}

interface OwnProps {
  renderComponent: (componentId: MODAL_COMPONENTS) => void;
  setProfileId: (profileId: Uuid) => void;
  product: AnalyticsProduct;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class ListProfilePage extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.getContent = this.getContent.bind(this);
    this.deleteProfile = this.deleteProfile.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onClickDetail = this.onClickDetail.bind(this);
    this.props.setModalTitle(
      i18next.t<string>('Advanced analytics - edit profile')
    );
    this.props.getAdvancedAnalyticsFilterProfiles(this.props.product.id);
  }

  public deleteProfile(id: string) {
    this.props.makeRequestAndShowStatus(
      deleteAdvancedAnalyticsFilterProfile,
      undefined,
      'deleteAdvancedAnalyticsFilterProfile',
      i18next.t<string>('Profile deleted'),
      id
    );
  }

  onDelete(profile: FilterProfile) {
    if (!profile.can_edit) {
      return;
    }

    this.props.openAdvancedAnalyticsConfirmModal(
      i18next.t<string>('Are you sure you want to delete?'),
      i18next.t<string>('Delete'),
      () => this.deleteProfile(profile.id),
      'delete-button'
    );
  }

  onClickDetail(profile: FilterProfile) {
    if (!profile.can_edit) {
      return;
    }

    this.props.setProfileId(profile.id);
    this.props.renderComponent(MODAL_COMPONENTS.FILTER_PROFILE_DETAIL);
  }

  getContent() {
    const { getResponse, filterProfiles } = this.props;
    if (hasFailed(getResponse)) {
      return (
        <>
          <p className="text-error">
            {i18next.t<string>(
              'An error has occurred, please try again later.'
            )}
          </p>
        </>
      );
    } else if (isPending(getResponse)) {
      return (
        <>
          <Loading />
        </>
      );
    } else if (filterProfiles && filterProfiles.length > 0) {
      return (
        <div className="edit-profile-list">
          {filterProfiles.map(profile => (
            <div
              key={profile.id}
              className={classNames('filter-profile', {
                disabled: !profile.can_edit,
              })}
            >
              <WithPopover
                popover={
                  <div>
                    {profile.filters
                      .map(filter => `(${convertFilterToDisplay(filter)})`)
                      .join(', ')}
                  </div>
                }
              >
                <a onClick={() => this.onClickDetail(profile)}>
                  {profile.name}
                </a>
              </WithPopover>
              <a onClick={() => this.onDelete(profile)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </a>
            </div>
          ))}
        </div>
      );
    }
    return (
      <>
        <p>{i18next.t<string>('There are no existing profiles')}</p>
      </>
    );
  }

  render() {
    return <div className="edit-profile-page">{this.getContent()}</div>;
  }
}

function mapStateToProps(state: StoreState) {
  return {
    filterProfiles: state.advancedAnalyticsFilterProfiles,
    getResponse: state.responses.get('getAdvancedAnalyticsFilterProfiles'),
  };
}

export default connect(mapStateToProps, {
  getAdvancedAnalyticsFilterProfiles,
  setModalTitle,
  openAdvancedAnalyticsConfirmModal,
  makeRequestAndShowStatus,
})(ListProfilePage);
