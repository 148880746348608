import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from './PureComponent';
import ResetPasswordForm from './ResetPasswordForm';
import { resetPassword } from '../actions/actions';
import { setLanguageAndReload } from '^/actions/actionSequences';
import NoAuth from './NoAuth';
import { withRouter } from '^/withRouter';
import {
  constructUrl,
  getClassNameForUrlPath,
  getQueryValue,
} from '^/utils-ts';

export class ResetPassword extends PureComponent {
  resetPassword(data) {
    this.props.resetPassword(
      this.props.uuid,
      this.props.token,
      data.password,
      data.confirmPassword
    );
  }

  componentDidMount() {
    this.props.lang && this.props.setLanguageAndReload(this.props.lang);
  }

  render() {
    const { nextPath } = this.props;
    return (
      <NoAuth>
        <div className={getClassNameForUrlPath(nextPath)}>
          <h2>{i18next.t('Password reset')}</h2>
          <ResetPasswordForm
            response={this.props.response}
            onSubmit={this.resetPassword.bind(this)}
            loginLink={nextPath ? nextPath : '/login'}
          />
        </div>
      </NoAuth>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    response: state.responses.get('resetPassword'),
    uuid: props.router.params.uuid,
    token: props.router.params.token,
    nextPath: getQueryValue(props, 'next'),
    lang: getQueryValue(props, 'lang'),
    url: constructUrl(props.router.location),
  };
}

export default withRouter(
  connect(mapStateToProps, { resetPassword, setLanguageAndReload })(
    ResetPassword
  )
);
