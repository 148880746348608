import i18next from 'i18next';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { resendPulseInvites } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import { Uuid } from '^/reducers/api/types';
import { PulseUserRow } from '^/components/pulses/PulsePage';

interface DispatchProps {
  resendPulseInvites: typeof resendPulseInvites;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  users: ReadonlyArray<PulseUserRow>;
  pulseId: Uuid;
}

type Props = DispatchProps & OwnProps;

export class ResendPulseInvitesModal extends PureComponent<Props> {
  private resendInvites = () => {
    ((this.props.resendPulseInvites(
      this.props.pulseId,
      this.props.users.map(user => user.userPulseId)
    ) as unknown) as Promise<any>).then(() => {
      this.props.closeTopModal();
    });
  };

  private onClickCancel = () => {
    this.props.closeTopModal();
  };

  public render() {
    const { users } = this.props;

    return (
      <div className="start-pulsing-modal">
        <h5>{i18next.t<string>('Selected respondents')}</h5>
        <div className="boxed">
          <div className="box">
            {users.map(user => user.fullName).join(', ')}
          </div>
        </div>
        <p>
          {i18next.t<string>(
            'Invites will be resent to the respondents specified above.'
          )}
        </p>
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button className="btn btn-default" onClick={this.onClickCancel}>
              {i18next.t<string>('Cancel')}
            </button>
            <button className="btn btn-primary" onClick={this.resendInvites}>
              {i18next.t<string>('Resend invites')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  closeTopModal,
  resendPulseInvites,
})(ResendPulseInvitesModal);
