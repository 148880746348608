import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import WelcomeModal from '^/components/dashboard/enduser/answer/WelcomeModal';
import Loading from '^/components/Loading';
import { loadActivity } from '^/actions/items';
import { continueProductVersion } from '^/actions/actionSequences';
import { hasFailed } from '^/responseStates';
import { SUPPORT_EMAIL } from '^/settings';
import { ACTIVITY_STATUS } from '^/activityStatus';
import { withRouter } from '^/withRouter';
import Alert from '^/components/Alert';

export class Dashboard extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.loadActivity(this.props.activityId);
  }

  renderError(message) {
    return (
      <div className="container">
        <div className="no-auth content-box">
          <div className="row">
            <div className="col-xs-12">
              <Alert>{message}</Alert>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderWarning(text, showContinueText = true) {
    return (
      <Alert>
        {text}{' '}
        {showContinueText &&
          i18next.t('Please press continue survey to complete and submit')}{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </Alert>
    );
  }

  renderNotRater() {
    return this.renderWarning(
      i18next.t('You are not a rater for any respondent of this activity.')
    );
  }

  continueProductVersion() {
    const { activity, raterFor, activeLanguage } = this.props;
    const raterForObj = activity
      .get('rater_for', List())
      .find(each => each.get('id') === raterFor);
    const productVersion = activity.getIn(['product_versions', 0]);

    this.props.continueProductVersion(
      productVersion,
      activity,
      raterForObj,
      activeLanguage
    );
  }

  renderActivityStarted(isComplete, respondentName, isThreeSixty) {
    if (isComplete) {
      return (
        <Alert>
          {i18next.t(
            'This survey has already been completed for {{respondentName}}.',
            { respondentName }
          )}{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
          {'. '}
          {i18next.t('You may now close this tab.')}
        </Alert>
      );
    }
    return (
      <div>
        {this.renderWarning(
          i18next.t(
            'You have already started feedback for {{respondentName}}.',
            { respondentName }
          )
        )}

        {isThreeSixty && (
          <div className="col-xs-12">
            <div className="row pull-right">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => this.continueProductVersion()}
              >
                {i18next.t('Continue Survey')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderContent(activity, productVersion, raterForId) {
    const raterFor = activity
      .get('rater_for', List())
      .find(each => each.get('id') === raterForId);
    const session = productVersion
      .get('activity_product_version_sessions', List())
      .find(each => each.get('rater_for') === raterForId);
    const isStarted = Boolean(session);
    const isComplete = Boolean(session && session.get('completed'));
    const isThreeSixty = activity.get('activity360');

    if (!raterFor) {
      return this.renderNotRater();
    }

    if (isStarted) {
      return this.renderActivityStarted(
        isComplete,
        raterFor.get('full_name'),
        isThreeSixty
      );
    }

    return (
      <WelcomeModal
        productVersion={productVersion}
        activity={activity}
        raterFor={raterFor}
      />
    );
  }

  render() {
    const { activity, raterFor, response } = this.props;

    if (!activity) {
      return hasFailed(response) ? (
        this.renderError(
          <div>
            {i18next.t('Sorry, no matching activity was found.')}
            <br />
            {i18next.t(
              'If you clicked a link then it may be corrupt, or you may have been removed from this activity.'
            )}
          </div>
        )
      ) : (
        <Loading />
      );
    }

    const productVersion = activity.getIn(['product_versions', 0]);

    if (activity.get('status') === ACTIVITY_STATUS.ENDED) {
      return this.renderError(
        <div>
          {i18next.t(
            'This activity has now expired. Please contact your administrator or {{SUPPORT_EMAIL}} for further assistance.',
            { SUPPORT_EMAIL }
          )}
          <br />
          <br />
          {i18next.t('Thank you')}
        </div>
      );
    } else if (activity.get('status') !== ACTIVITY_STATUS.LIVE) {
      return this.renderError(
        <div>
          {i18next.t('Sorry, this activity is not currently active.')}
          <br />
          {i18next.t(
            'It may have been suspended, or the start time may have been changed to in the future.'
          )}
        </div>
      );
    }

    return (
      <div className="modal-wrapper">
        <div className="modal-overlay">
          <div className="modal row content-box">
            <div className="modal-body col-xs-12">
              {this.renderContent(activity, productVersion, raterFor)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.userProfile,
    activityId: props.router.params.activity,
    raterFor: props.router.params.raterFor,
    activity: state.items.get('activities'),
    response: state.responses.get('loadItem'),
    activeLanguage: state.ui.get('activeLanguage'),
  };
}

export default withRouter(
  connect(mapStateToProps, { loadActivity, continueProductVersion })(Dashboard)
);
