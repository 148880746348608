import { createContext } from './createContext';
import { DragAndDropHandlerContext } from './types';

const logOutsideContextError = () => {
  throw new Error(
    'Drag or drop component was rendered outside the context of a DragAndDropHandler'
  );
};

const INITIAL_CONTEXT: DragAndDropHandlerContext<{ [i: string]: any }, any> = {
  setDragged: logOutsideContextError,
  setDropped: logOutsideContextError,
  clearDragged: logOutsideContextError,
  clearDropped: logOutsideContextError,
  itemDropped: logOutsideContextError,
  setPosition: logOutsideContextError,
};

const { Provider, Consumer } = createContext(INITIAL_CONTEXT);

export { INITIAL_CONTEXT, Provider, Consumer };
