import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'underscore';

import PureComponent from '^/components/PureComponent';
import CreditAccountHistory from '^/components/credits/CreditAccountHistory';
import CreditHistory from '^/components/credits/CreditHistory';
import { selectUserOrgId } from '^/selectors/user';
import { setFilters } from '^/actions/actions';
import { selectFilterSpec } from '^/selectors/ui';

export class CreditHistoryPage extends PureComponent {
  onSearchChange(event) {
    this.props.setFilters({
      ...this.props.filters,
      search: event.target.value.trim(),
    });
  }

  render() {
    return this.props.userOrgId ? (
      <CreditAccountHistory />
    ) : (
      <CreditHistory
        onSearchChange={_.debounce(this.onSearchChange.bind(this), 500)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    userOrgId: selectUserOrgId(state),
    filters: selectFilterSpec(state),
  };
}

export default connect(mapStateToProps, {
  setFilters,
})(CreditHistoryPage);
