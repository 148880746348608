import { TAX_LOCATION } from '^/models/organisation';
import { VAT_RATE } from './constants';

export const shouldApplyVat = (taxLocation: string): boolean => {
  return taxLocation === TAX_LOCATION.CHOICES.UK;
};

export const costAfterTax = (
  costBeforeTax: number,
  applyVat: boolean
): number => {
  return applyVat ? costBeforeTax * (1 + VAT_RATE) : costBeforeTax;
};
