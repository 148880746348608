import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import PureComponent from '../../../PureComponent';
import MAPQuestionOption from './MAPQuestionOption';
import { isPending } from '../../../../responseStates';
import Well from '^/components/Well';

export default class MAPQuestion extends PureComponent {
  render() {
    const {
      questionWithAnswer,
      responses,
      readOnly,
      disabled,
      questionIdx,
      isPsycapPotential,
    } = this.props;
    const [question, answer] = questionWithAnswer.toArray();
    const { options, is_practice } = question.toObject();
    const questionNumber = is_practice
      ? `Sample${questionIdx + 1}`
      : questionIdx - 2;
    const folder = isPsycapPotential ? 'pp_map' : 'map';
    const imgSrc = `/static/img/assets/${folder}/Map${questionNumber}`;

    return (
      <span>
        <div className="map-question-image-wrapper">
          <div className="map-question-image-set">
            <div className="label">{i18next.t('Set A')}</div>
            <img src={`${imgSrc}_SetA.svg`} className="map-question-image" />
          </div>
          <div className="map-question-image-set">
            <div className="label">{i18next.t('Set B')}</div>
            <img src={`${imgSrc}_SetB.svg`} className="map-question-image" />
          </div>
        </div>

        <Well>
          <p>
            <Trans i18nKey="TRANS For each image below">
              For each image below, select if it belongs to
              <strong>Set A</strong>, <strong>Set B</strong> or
              <strong>Neither</strong>:
            </Trans>
          </p>
        </Well>

        <div className="map-question-row">
          {options.map((questionOption, questionOptionIdx) => {
            const optionId = questionOption.get('id');
            return (
              <MAPQuestionOption
                key={optionId}
                questionOption={questionOption}
                imgSrc={`${imgSrc}_${questionOptionIdx + 1}.svg`}
                answer={answer && answer.getIn([optionId, 'choice'])}
                answerQuestion={_answer =>
                  this.props.answerQuestion(_answer, optionId)
                }
                isLoading={responses && isPending(responses.get(optionId))}
                readOnly={readOnly}
                disabled={disabled}
              />
            );
          })}
        </div>
      </span>
    );
  }
}
