import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { connect } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '^/components/Table';
import PureComponent from '^/components/PureComponent';
import Editable from '^/components/editable/Editable';
import { MAX_PURCHASE_CREDITS } from '^/components/organisations/accounts/PurchaseCreditsForm';
import {
  updateDiscountBand,
  addDiscountBand,
  deleteDiscountBand,
  updateDiscountBandCollection,
  getBulkDiscountBandCollections,
} from '^/actions/collections';
import Loading from '^/components/Loading';
import { isPending } from '^/responseStates';
import Alert from '^/components/Alert';

export class EditBulkDiscountBandModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getBulkDiscountBandCollections();
  }

  renderFromCredit(discount) {
    const { id } = discount.toObject();
    const value = discount.get('discount_from');
    return (
      <Editable
        block
        placeholder={value}
        value={value}
        fieldName={`discount_from_${id}`}
        onSave={newValue =>
          this.props.updateDiscountBand(id, { discount_from: newValue })
        }
      />
    );
  }

  renderPercentage(discount) {
    const { id } = discount.toObject();
    const value = discount.get('percentage');
    return (
      <Editable
        block
        placeholder={value}
        value={value}
        fieldName={`percentage_${id}`}
        onSave={newValue =>
          this.props.updateDiscountBand(id, { percentage: newValue })
        }
      />
    );
  }

  renderUpperLimit(discount) {
    const { bulkDiscountBandCollection } = this.props;
    const {
      bulkdiscountband_set: discountBands,
    } = bulkDiscountBandCollection.toObject();
    const nextBand =
      discountBands &&
      discountBands.find(
        band => discount.get('discount_from') < band.get('discount_from')
      );
    return nextBand ? nextBand.get('discount_from') - 1 : MAX_PURCHASE_CREDITS;
  }

  renderActions(discount) {
    const { id } = discount.toObject();
    return (
      <a
        className="display-block text-center"
        title="Delete discount band"
        onClick={() => this.props.deleteDiscountBand(id)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </a>
    );
  }

  createNewBand() {
    const { bulkDiscountBandCollection } = this.props;
    const {
      bulkdiscountband_set: discounts,
      id,
    } = bulkDiscountBandCollection.toObject();

    if (discounts.isEmpty()) {
      return this.props.addDiscountBand(id, 100, 0);
    }

    const discountFrom = discounts.last().get('discount_from') + 1;
    const percentage = discounts.last().get('percentage') + 1;

    const newPercentage = percentage >= 0 && percentage < 100 ? percentage : 0;

    return this.props.addDiscountBand(id, discountFrom, newPercentage);
  }

  render() {
    const {
      bulkDiscountBandCollection,
      bulkDiscountBandCollectionsResponse,
    } = this.props;

    if (
      isPending(bulkDiscountBandCollectionsResponse) ||
      !bulkDiscountBandCollection
    ) {
      return <Loading />;
    }
    const {
      bulkdiscountband_set: discounts,
      id,
      name,
    } = bulkDiscountBandCollection.toObject();
    const sortedDiscounts = discounts.sortBy(discount =>
      discount.get('discount_from')
    );
    return (
      <div>
        <h4 className="margin-bottom">
          <Editable
            placeholder="Name"
            value={name}
            fieldName="name"
            onSave={newValue =>
              this.props.updateDiscountBandCollection(id, { name: newValue })
            }
          />
        </h4>

        {sortedDiscounts.isEmpty() ? (
          <Alert>
            {i18next.t(
              'This collection has no bands, add a discount band with the button below.'
            )}
          </Alert>
        ) : (
          <Table
            className="table-discount"
            columns={[
              {
                header: i18next.t('Credit payment type'),
                value: () => i18next.t('Single credit purchase'),
              },
              {
                header: i18next.t('From credit'),
                value: discount => this.renderFromCredit(discount),
                className: 'discount-credit',
              },
              {
                header: i18next.t('To credit'),
                value: discount => this.renderUpperLimit(discount),
              },
              {
                header: i18next.t('Discount %'),
                value: discount => this.renderPercentage(discount),
                className: 'discount-percentage',
              },
              {
                header: i18next.t('Delete'),
                value: discount => this.renderActions(discount),
              },
            ]}
            rows={sortedDiscounts}
          />
        )}

        <button
          className="btn btn-default pull-right"
          onClick={() => this.createNewBand()}
        >
          <FontAwesomeIcon icon={faPlus} />
          {i18next.t('Add discount band')}
        </button>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const bandCollections = state.collections.getIn([
    'bulkDiscountBandCollections',
    'items',
  ]);
  return {
    bulkDiscountBandCollection: bandCollections.find(
      collection => collection.get('id') === props.id
    ),
    bulkDiscountBandCollectionsResponse: state.multiResponses.getIn([
      'getCollection',
      'bulkDiscountBandCollections',
    ]),
  };
}

export default connect(mapStateToProps, {
  getBulkDiscountBandCollections,
  addDiscountBand,
  deleteDiscountBand,
  updateDiscountBand,
  updateDiscountBandCollection,
})(EditBulkDiscountBandModal);
