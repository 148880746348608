/* eslint-disable react/no-children-prop */
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import * as _ from 'underscore';
import classnames from 'classnames';

import { setActiveTab } from '../actions/actions';
import PureComponent from './PureComponent';
import Loading from './Loading';

export class ContentTabset extends PureComponent {
  UNSAFE_componentWillMount() {
    this.setDefaultActiveTab();
  }

  setDefaultActiveTab() {
    if (this.props.name) {
      this.props.setActiveTab(this.props.name, this.props.defaultActiveTab);
    }
  }

  renderActiveTabChildren() {
    const { activeTab } = this.props;
    if (this.props.children && this.props.children.props) {
      return this.props.children.props.loading ? (
        <Loading />
      ) : (
        this.props.children.props.children
      );
    }

    const children = _.filter(this.props.children, child => !!child);
    const tab = _.find(
      children,
      child => child && child.props && child.props.tabId === activeTab
    );
    if (tab) {
      return tab.props.loading ? <Loading /> : tab.props.children;
    }
    return null;
  }

  render() {
    const {
      className,
      children,
      content,
      contentWrapper: MaybeContentWrapper,
    } = this.props;
    const renderedContent = content || this.renderActiveTabChildren();

    return (
      <div>
        <div className={classnames('tabset', className)}>
          <div className="tabs">{children}</div>
          <div className="tabcontent">
            {MaybeContentWrapper ? (
              <MaybeContentWrapper children={renderedContent} />
            ) : (
              renderedContent
            )}
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    activeTab: state.ui.get('activeTabs', Map()).get(props.name),
  };
}

export default connect(mapStateToProps, { setActiveTab })(ContentTabset);

ContentTabset.propTypes = {
  content: PropTypes.element,
};
