import classNames from 'classnames';
import React, { ReactNode, FunctionComponent } from 'react';

interface OwnProps {
  children?: ReactNode;
  className?: string;
}

type Props = OwnProps;

export const AssessmentAside: FunctionComponent<Props> = ({
  children,
  className,
}) => (
  <div className={classNames('assessment-aside', className)}>{children}</div>
);

export default AssessmentAside;
