import i18next from 'i18next';
import React from 'react';

interface OwnProps {
  questionNumber: number;
  numberOfQuestions: number;
}

type Props = OwnProps;

const MultiLikertQuestionProgress = ({
  questionNumber,
  numberOfQuestions,
}: Props) => (
  <h2>
    {i18next.t<string>('Question {{questionNumber}} of {{numberOfQuestions}}', {
      questionNumber,
      numberOfQuestions,
    })}
  </h2>
);

export { MultiLikertQuestionProgress };

export default MultiLikertQuestionProgress;
