import classNames from 'classnames';
import React from 'react';

interface OwnProps {
  remainingSeconds: number;
  totalSeconds: number;
  scale?: number;
  style?: 'primary' | 'secondary';
}

type Props = OwnProps;

// Must remain in sync with @countdown-circle-offset in less
const COUNTDOWN_CIRCLE_OFFSET = 88;

export const CountDownCircle: React.FunctionComponent<Props> = ({
  remainingSeconds,
  totalSeconds,
  scale,
  style = 'primary',
}) => (
  <div className={classNames(style, 'countdown-circle-wrapper')}>
    <div className="countdown-number">
      {Math.max(
        scale ? Math.ceil(remainingSeconds / scale) : remainingSeconds,
        0
      )}
    </div>
    <svg>
      <circle
        className={'circle-background countdown-circle'}
        r="14"
        cx="15"
        cy="15"
      />
      <circle
        className={'circle-foreground countdown-circle'}
        r="14"
        cx="15"
        cy="15"
        style={{
          animation: 'none',
          strokeDashoffset: `${(1 -
            Math.min(Math.max(remainingSeconds / totalSeconds, 0), 1)) *
            COUNTDOWN_CIRCLE_OFFSET}px`,
        }}
      />
    </svg>
  </div>
);
