import { List } from 'immutable';
import createCachedSelector from 're-reselect';

import { StoreState } from '^/store';
import { MultilineTextProps, OwnProps } from './types';

export const isMultiline = (props: OwnProps): props is MultilineTextProps =>
  Boolean((!props.type || props.type === 'text') && props['multiline']);

export const isDisplayingPlaceholder = (value: string | undefined) =>
  typeof value !== 'string';

export const selectEditing = createCachedSelector(
  [
    (state: StoreState) => state.ui.get('currentlyEditingFields'),
    (_state: StoreState, fieldName: string) => fieldName,
  ],
  (currentlyEditingFields: List<string>, fieldName) =>
    currentlyEditingFields.includes(fieldName)
)((_state, fieldName) => fieldName);

export const normalizeValue = (
  value: string | number | undefined
): string | undefined => (typeof value === 'number' ? value.toString() : value);
