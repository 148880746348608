import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'underscore';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import FormError from '../FormError';
import { isPending } from '../../responseStates';
import ProfilePicture from './ProfilePicture';
import PictureUpload from '../PictureUpload';
import {
  SEX_CHOICES,
  GEOGRAPHY_CHOICES,
  AGE_CHOICES,
  EDUCATION_CHOICES,
  JOB_LEVEL_CHOICES,
  INDUSTRY_CHOICES,
  NATIONALITY_CHOICES,
  ETHNICITY_CHOICES,
  FIRST_LANGUAGE_ENGLISH,
} from './choices';
import Checkbox from '../Checkbox';

export const UpdateProfileForm = props => {
  const disableSubmit = (response, showTCs, acceptTcs) => {
    return (showTCs && !acceptTcs) || isPending(response);
  };

  const { onSubmit, response, showTCs, initialValues } = props;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="form-inline">
          <div>
            <div className="form-item">
              <PictureUpload
                name="profile_picture"
                src={
                  values.profile_picture &&
                  (values.profile_picture.preview || values.profile_picture)
                }
                display={src => (
                  <ProfilePicture src={src} className="centered" />
                )}
                setPendingUpload={file =>
                  setFieldValue('profile_picture', file)
                }
                onSubmit={onSubmit}
              />
              <FormError response={response} formKey="picture_upload" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-name">{i18next.t('Name')}</label>
            <div className="form-item">
              <Field
                id="profile-name"
                name="full_name"
                type="text"
                placeholder={i18next.t('First Last')}
                disabled={isPending(response)}
              />
              <FormError response={response} formKey="full_name" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-email">{i18next.t('Email')}</label>
            <div className="form-item">
              <Field
                type="text"
                id="profile-email"
                name="email"
                placeholder={i18next.t('Email')}
                disabled={isPending(response)}
              />
              <FormError response={response} formKey="email" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-employee-number">
              {i18next.t('Employee number')}
            </label>
            <div className="form-item">
              <Field
                id="profile-employee-number"
                name="employee_no"
                type="text"
                placeholder={i18next.t('Employee number')}
                disabled={isPending(response)}
              />
              <FormError response={response} formKey="employee_no" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-sex">{i18next.t('Sex')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-sex"
                name="sex"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(SEX_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="sex" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-age">{i18next.t('Age')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-age"
                name="age"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(AGE_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="age" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-education">{i18next.t('Education')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-education"
                name="education"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(EDUCATION_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="education" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-job-level">{i18next.t('Job level')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-job-level"
                name="job_level"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(JOB_LEVEL_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="job_level" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-industry">{i18next.t('Industry')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-industry"
                name="industry"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(INDUSTRY_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="industry" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-geography">{i18next.t('Geography')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-geography"
                name="geography"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(GEOGRAPHY_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="geography" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-file-language-english">
              {i18next.t('First language is English')}
            </label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-first-language-english"
                name="first_language_english"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(FIRST_LANGUAGE_ENGLISH, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="first_language_english" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-ethnicity">{i18next.t('Ethnicity')}</label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-ethnicity"
                name="ethnicity"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(ETHNICITY_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="ethnicity" />
            </div>
          </div>

          <div>
            <label htmlFor="profile-nationality">
              {i18next.t('Nationality')}
            </label>
            <div className="form-item">
              <Field
                as="select"
                id="profile-nationality"
                name="nationality"
                disabled={isPending(response)}
              >
                <option value="">{i18next.t('Please select...')}</option>
                {_.map(NATIONALITY_CHOICES, (text, value) => (
                  <option key={value} value={value}>
                    {i18next.t(text)}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="nationality" />
            </div>
          </div>

          {showTCs && (
            <>
              <div>
                <div className="form-item">
                  <Trans i18nKey="TRANS understandTC">
                    Please acknowledge that you have read, understood and accept
                    the opt out{' '}
                    <a
                      href={`/#/${i18next.language}/terms-and-conditions`}
                      target="_blank"
                    >
                      Terms of Use
                    </a>
                    for Enable and the use of services offered by PeopleWise, by
                    checking the box below.
                  </Trans>
                </div>
              </div>

              <div>
                <Checkbox
                  id={'tc'}
                  name="accept_tcs"
                  checked={values.accept_tcs}
                  onChange={() =>
                    setFieldValue('accept_tcs', !values.accept_tcs)
                  }
                  flex
                  label={
                    <Trans i18nKey="TRANS agreeTCcheckbox">
                      I acknowledge that I have read and agree to the{' '}
                      <a
                        href={`/#/${i18next.language}/terms-and-conditions`}
                        target="_blank"
                      >
                        Terms of Use
                      </a>
                    </Trans>
                  }
                />
                <FormError response={response} formKey="accept_tcs" />
              </div>
            </>
          )}

          <Trans
            i18nKey="TRANS gdprOptOut"
            parent="p"
            className="agreement-info"
          >
            By agreeing to the Data Privacy Policy you consented to your data
            being processed on enable. To read our Data Privacy Policy or opt
            out <a href="/#/consent"> click here</a>.
          </Trans>

          <div className="form-button-group">
            <hr />
            <div className="form-item wrapping">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={disableSubmit(response, showTCs, values.accept_tcs)}
              >
                {i18next.t('Submit')}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProfileForm;
