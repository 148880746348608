import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from 'react-router';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import { withRouter } from '^/withRouter';
import {
  openModal,
  closeTopModal,
  removeProductVersionFromActivityModal,
  openConfirmationModal,
} from '^/actions/modals';
import { updateActivity, getActivityUsers } from '^/actions/collections';
import {
  loadActivity,
  loadActivitySummary,
  loadActivityRespondents,
} from '^/actions/items';
import {
  deleteActivityCloseModalAndNavigateTo,
  closeModalAndUpdateActivity,
} from '^/actions/actionSequences';
import Activity from './Activity';
import ControlBar from '../ControlBar';
import Editable from '^/components/editable/Editable';
import StatusPill from '^/components/StatusPill';
import {
  getStatus,
  getStatusPillProps,
  ACTIVITY_STATUS,
} from '^/activityStatus';
import LoadingAndErrors from '../LoadingAndErrors';

export class ActivityPage extends PureComponent {
  UNSAFE_componentWillMount() {
    const { activityId } = this.props;
    this.props.loadActivitySummary(activityId);
    this.props.loadActivityRespondents(activityId);
    this.props.getActivityUsers(activityId);
    this.props.loadActivity(activityId);
  }

  updateField(fieldPath, newValue) {
    const { activitySummary } = this.props;
    const { id } = activitySummary.toObject();
    const fields = [].concat(fieldPath);

    return this.props.updateActivity(id, { [fields[0]]: newValue });
  }

  getFieldName(fieldPath) {
    const { activitySummary } = this.props;
    const { id } = activitySummary.toObject();
    const fields = [].concat(fieldPath);

    return `activities.${id}.` + fields.join('.');
  }

  confirmAndDelete() {
    this.props.openConfirmationModal(
      'delete',
      'activity',
      [i18next.t('You cannot undo this operation.')],
      () =>
        this.props.deleteActivityCloseModalAndNavigateTo(
          this.props.activitySummary.get('id'),
          '/page/activities/view'
        ),
      this.props.closeTopModal,
      'btn-danger'
    );
  }

  navigateToList() {
    this.props.push('/page/activities/view');
  }

  showSuspendUnsuspendConfirmationModal(shouldSuspend) {
    const willOrWillNot = shouldSuspend
      ? i18next.t('will no longer')
      : i18next.t('will again');

    this.props.openConfirmationModal(
      shouldSuspend ? 'suspend' : 'unsuspend',
      'activity',
      [`Respondents ${willOrWillNot} be able to complete the activity.`],
      this.setSuspendedState.bind(this, shouldSuspend),
      this.props.closeTopModal,
      shouldSuspend ? 'btn-danger' : 'btn-warning'
    );
  }

  setSuspendedState(isSuspended) {
    this.props.closeModalAndUpdateActivity(this.props.activity.get('id'), {
      is_suspended: isSuspended,
    });
  }

  renderSuspendOrUnsuspend(status) {
    if (status === ACTIVITY_STATUS.LIVE) {
      return (
        <button
          className="btn btn-small btn-suspend margin-none"
          onClick={this.showSuspendUnsuspendConfirmationModal.bind(this, true)}
        >
          {i18next.t('Suspend')}
        </button>
      );
    }

    if (status === ACTIVITY_STATUS.SUSPENDED) {
      return (
        <button
          className="btn btn-small btn-success-secondary margin-none"
          onClick={this.showSuspendUnsuspendConfirmationModal.bind(this, false)}
        >
          {i18next.t('Unsuspend')}
        </button>
      );
    }
  }

  render() {
    const {
      activity,
      activitySummary,
      activityRespondents,
      loadActivitySummaryResponse,
      response,
      user,
    } = this.props;

    const activityName = activitySummary && activitySummary.get('name');
    const createdByUser =
      activitySummary && activitySummary.get('created_by_user');
    const hasSessions = activitySummary && activitySummary.get('has_sessions');

    const readOnly =
      createdByUser &&
      createdByUser.get('id') !== user.get('id') &&
      user.get('has_restricted_sharing_permissions', false);

    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/activities/view">{i18next.t('Activities')}</Link>
        <span>{activityName}</span>
      </span>
    );
    return (
      <div>
        <ControlBar
          title={
            activitySummary && (
              <Editable
                placeholder={i18next.t('Name')}
                value={activityName}
                fieldName={this.getFieldName('name')}
                responseFieldName="name"
                onSave={this.updateField.bind(this, 'name')}
                response={response}
                readOnly={readOnly}
              />
            )
          }
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
        >
          {activitySummary && (
            <div>
              <StatusPill {...getStatusPillProps(activitySummary)} />
              {this.renderSuspendOrUnsuspend(getStatus(activitySummary))}
            </div>
          )}
        </ControlBar>
        {activitySummary ? (
          <div>
            <Activity
              activity={activity}
              activitySummary={activitySummary}
              activityRespondents={activityRespondents}
              isDetail
            />
            {!readOnly && (
              <button
                className="btn btn-danger-secondary pull-right"
                disabled={hasSessions}
                onClick={() => this.confirmAndDelete()}
              >
                {i18next.t('Delete activity')}
              </button>
            )}
          </div>
        ) : (
          <LoadingAndErrors
            responses={List([loadActivitySummaryResponse])}
            loadingClassName="list-loading"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.userProfile,
    activityId: props.router.params.id,
    activity: state.items.get('activities'),
    activitySummary: state.items.get('activitiesSummary'),
    activityRespondents: state.items.get('activitiesRespondents'),
    response: state.responses.get('updateItem'),
    loadActivitySummaryResponse: state.multiResponses.getIn([
      'loadItem',
      'activitiesSummary',
    ]),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadActivity,
    loadActivitySummary,
    loadActivityRespondents,
    openModal,
    closeTopModal,
    closeModalAndUpdateActivity,
    removeProductVersionFromActivityModal,
    openConfirmationModal,
    deleteActivityCloseModalAndNavigateTo,
    getActivityUsers,
    push,
    updateActivity,
  })(ActivityPage)
);
