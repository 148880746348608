/* eslint-disable @typescript-eslint/no-use-before-define */
import classNames from 'classnames';
import i18next from 'i18next';
import { fromJS } from 'immutable';
import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';

import {
  loadTeamSessions,
  setModalTitle,
  loadDiscSessions,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import Alert, { AlertType } from '^/components/Alert';
import ListingTable from '^/components/ListingTable';
import ListingTableFilters from '^/components/ListingTableFilters';
import ProfilePicture from '^/components/profile/ProfilePicture';
import WithPopover from '^/components/WithPopover';
import {
  OrgFilters,
  OrgSessions,
  TeamSessions,
  DiscSessions,
  Uuid,
} from '^/reducers/api/types';
import { formatDate } from '^/utils';
import { compare, difference, intersection, union, without } from '^/utils-ts';
import { filterSessions } from './utils';

interface ListingTableState {
  ordering?: {
    field: string;
    reversed: boolean;
  };
  search?: string;
  filters: { [key: string]: string[] };
}

interface State {
  listingTableState: ListingTableState;
}

interface OwnProps {
  orgId: Uuid;
  productId: Uuid | null;
  profileSessionId?: Uuid;
  moveToConfirmPurchasePage: () => void;
  updateSelectedSessions: (selectedSessionIds: Set<Uuid>) => void;
  selectedSessionIds: Set<Uuid>;
  sessions: TeamSessions | DiscSessions | null;
  orgFilters: OrgFilters | null;
  orgSessions: OrgSessions | null;
  canUserAdministerOwnOrganisation: boolean;
  loadSessions: typeof loadTeamSessions | typeof loadDiscSessions;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
  setModalTitle: typeof setModalTitle;
}

export type Props = OwnProps & DispatchProps;

export class ChooseSessionsPage extends React.Component<Props, State> {
  public readonly state = {
    listingTableState: {
      filters: {},
      ordering: undefined,
      search: undefined,
    },
  } as State;

  private isMissingAccreditation() {
    const {
      orgSessions,
      canUserAdministerOwnOrganisation,
      productId,
    } = this.props;
    const product = orgSessions?.productorganisation_set
      .filter(po => po.product.id === productId)
      .find(productOrganisation =>
        productOrganisation.productorganisationreporttemplate_set.some(
          ({ report_template }) => report_template.is_team
        )
      )?.product;

    return (
      product?.requires_accreditation &&
      canUserAdministerOwnOrganisation &&
      !orgSessions?.accredited_products.includes(product.id)
    );
  }

  private onListingTableStateChange = (
    newState: Partial<ListingTableState>
  ) => {
    const listingTableState = {
      ...this.state.listingTableState,
      ...newState,
    };

    this.setState({ listingTableState });

    const { sessions, updateSelectedSessions, selectedSessionIds } = this.props;

    if (sessions) {
      const filteredSessions = filterSessions(
        [...sessions],
        listingTableState.filters
      );
      updateSelectedSessions(
        intersection(
          selectedSessionIds,
          new Set<Uuid>(filteredSessions.map(each => each.id))
        )
      );
    }
  };

  private getFilteredSessions(ignoreFilters = false): TeamSessions | null {
    const { sessions } = this.props;
    if (!sessions) {
      return null;
    }

    let filteredSessions = [...sessions];
    const { ordering, search, filters } = this.state.listingTableState;

    if (ordering) {
      const { field, reversed } = (ordering as any) as {
        field: string;
        reversed: boolean;
      };
      filteredSessions.sort((a, b) => {
        switch (field) {
          case 'user__full_name':
            return compare(a.user.full_name, b.user.full_name);
          case 'activity_product_version__activity__name':
            return compare(
              a.activity_product_version.activity.name,
              b.activity_product_version.activity.name
            );
          case 'completed':
            return compare(a.completed, b.completed);
        }
        return 0;
      });
      if (reversed) filteredSessions.reverse();
    }

    if (search) {
      filteredSessions = filteredSessions.filter(session =>
        session.user.full_name
          .toLowerCase()
          .includes(((search as any) as string).toLowerCase())
      );
    }

    if (filters && !ignoreFilters) {
      filteredSessions = filterSessions(filteredSessions, filters);
    }

    return filteredSessions;
  }

  private renderGroups = (groups: ReadonlyArray<{ name: string }>) => {
    return (
      <WithPopover
        className="asset-count"
        popover={
          groups.length ? (
            <div>{groups.map(({ name }) => name).join(', ')}</div>
          ) : (
            <p>{i18next.t<string>('No groups')}</p>
          )
        }
      >
        <a className="hover-count">{groups.length}</a>
      </WithPopover>
    );
  };

  public render() {
    const {
      orgFilters,
      orgId,
      productId,
      selectedSessionIds,
      updateSelectedSessions,
      profileSessionId,
    } = this.props;
    const filteredSessions = this.getFilteredSessions();
    const sortedSessions = this.getFilteredSessions(true);
    const numSessionsSelected = selectedSessionIds.size;
    const activityIds = new Set(
      sortedSessions?.map(
        session => session.activity_product_version.activity.id
      )
    );
    const groupIds = new Set(
      _.flatten(
        sortedSessions?.map(session =>
          session.user.usergroup_set.map(userGroup => userGroup.id)
        ) || []
      )
    );

    return (
      <div>
        <div>
          <ListingTableFilters
            filters={[
              {
                key: 'activity',
                name: 'Activity',
                values: orgFilters?.activities.filter(activity =>
                  activityIds.has(activity.id)
                ),
              },
              {
                key: 'group',
                name: 'Group',
                values: orgFilters?.groups.filter(group =>
                  groupIds.has(group.id)
                ),
              },
            ]}
            state={this.state.listingTableState}
            onStateChange={this.onListingTableStateChange}
          />

          <p>
            {i18next.t<string>('{{count}} responses', {
              count: filteredSessions?.length || 0,
            })}
          </p>
          <div className="table-scrollable-wrapper">
            <ListingTable
              onLoad={params =>
                this.props.loadSessions(orgId, {
                  ...params,
                  product_id: productId,
                  job_profile_session: profileSessionId,
                })
              }
              state={this.state.listingTableState}
              onStateChange={this.onListingTableStateChange}
              onClickRow={session =>
                updateSelectedSessions(
                  selectedSessionIds.has(session.id)
                    ? without(selectedSessionIds, session.id)
                    : selectedSessionIds.add(session.id)
                )
              }
              rowClassName={session =>
                classNames('user-row selectable', {
                  selected: selectedSessionIds.has(session.id),
                })
              }
              columns={[
                {
                  header: (
                    <input
                      type="checkbox"
                      className="ml-sm mt-none"
                      checked={filteredSessions?.every(session =>
                        selectedSessionIds.has(session.id)
                      )}
                      onChange={() =>
                        updateSelectedSessions(
                          filteredSessions?.every(session =>
                            selectedSessionIds.has(session.id)
                          )
                            ? difference(
                                selectedSessionIds,
                                new Set<Uuid>(
                                  filteredSessions!.map(session => session.id)
                                )
                              )
                            : union(
                                selectedSessionIds,
                                new Set<Uuid>(
                                  filteredSessions!.map(session => session.id)
                                )
                              )
                        )
                      }
                    />
                  ),
                  value: session => (
                    <input
                      type="checkbox"
                      checked={selectedSessionIds.has(session.id)}
                    />
                  ),
                },
                {
                  header: i18next.t<string>('Name'),
                  value: session => (
                    <div className="user-profile">
                      <ProfilePicture
                        width={24}
                        height={24}
                        user={fromJS(session.user)}
                      />
                      {session.user.full_name}
                      {!session.reports.length && (
                        <span className="no-report">NO REPORT</span>
                      )}
                    </div>
                  ),
                  sortKey: 'user__full_name',
                  searchKey: 'name',
                },
                {
                  header: i18next.t<string>('Activity'),
                  value: session =>
                    session.activity_product_version.activity.name,
                  sortKey: 'activity_product_version__activity__name',
                },
                {
                  header: i18next.t<string>('Groups'),
                  value: session =>
                    this.renderGroups(session.user.usergroup_set),
                },
                {
                  header: i18next.t<string>('Date completed'),
                  value: session => formatDate(session.completed),
                  sortKey: 'completed',
                },
              ]}
              rows={filteredSessions}
            />
          </div>
          <Alert type={AlertType.Info} className="margin-none">
            {this.isMissingAccreditation()
              ? i18next.t<string>(
                  'PARAGRAPH This list shows sessions where there is a report generated'
                )
              : i18next.t<string>(
                  'Any Individual reports not yet purchased will be purchased now.'
                )}
          </Alert>
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>

            <button
              className="btn btn-primary"
              onClick={this.props.moveToConfirmPurchasePage}
              disabled={!numSessionsSelected}
            >
              {numSessionsSelected
                ? i18next.t<string>('Select {{count}} responses', {
                    count: numSessionsSelected,
                  })
                : i18next.t<string>('Select responses')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  closeTopModal,
  setModalTitle,
})(ChooseSessionsPage);
