/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'underscore';
import i18next from 'i18next';

import {
  AnalyticsAggregateResults,
  AnalyticsAppliedFilters,
  AnalyticsFactorDISCResults,
  AnalyticsFilters,
  AnalyticsProduct,
  HeatmapRow,
  Uuid,
  GenericAnalyticsFilterItem,
} from '^/reducers/api/types';
import { ActivityType } from '^/reducers/api/types/product';
import { isTruthy } from '^/utils';
import { administerOrganisations, can } from '^/capabilities';

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export function isFivePoint(product: AnalyticsProduct | undefined): boolean {
  return Boolean(product?.likert_maximum === 5);
}

export function catchBadFactor(factor: string) {
  return factor === 'PP_CA' ? 'PP_MA' : factor;
}

export function cantShowHeatmap(
  product: AnalyticsProduct | undefined
): boolean {
  return (
    product?.activity_type === ActivityType.THREE_SIXTY && !isFivePoint(product)
  );
}

export const ALL_FILTERS: ReadonlyArray<keyof AnalyticsFilters> = [
  'organisation',
  'activity',
  'group',
  'user',
  'sex',
  'age',
  'ethnicity',
  'job_level',
  'industry',
  'geography',
];

export function handleBlank<T extends Item>(item: T): T {
  return {
    ...item,
    name: item.id === 'NOT_SET' ? i18next.t<string>('(Blanks)') : item.name,
  };
}

export function getNamesFromIDs(
  key: keyof AnalyticsFilters,
  heatmapRow: HeatmapRow
) {
  const appliedFilter = heatmapRow.appliedFilters[key];
  return (
    appliedFilter &&
    heatmapRow.filtersState.filters[key]
      .filter(item => appliedFilter.includes(item.id))
      .map(handleBlank)
      .map(item => item.name)
      .sort()
      .join(', ')
  );
}

export function getSummaryName(heatmapRow: HeatmapRow) {
  return (
    ALL_FILTERS.map(key => getNamesFromIDs(key, heatmapRow))
      .filter(isTruthy)
      .join(' > ') ||
    getNamesFromIDs('product', heatmapRow) ||
    ''
  );
}

export function existingIDs(
  key: keyof AnalyticsFilters,
  heatmapRow: HeatmapRow
) {
  const appliedFilter = heatmapRow.appliedFilters[key];

  return (
    appliedFilter &&
    heatmapRow.filtersState.filters[key]
      .filter((item: GenericAnalyticsFilterItem) =>
        appliedFilter.includes(item.id)
      )
      .map((item: GenericAnalyticsFilterItem) => item.id)
      .join()
  );
}

export function rowExists(heatmapRow: HeatmapRow, parentRowCount: number) {
  const filtersState = heatmapRow.filtersState;
  return Boolean(
    ALL_FILTERS.map(key => existingIDs(key, heatmapRow)).filter(isTruthy)
      .length > 0 &&
      filtersState.sessions &&
      filtersState.sessions !== null &&
      filtersState.sessions.count &&
      filtersState.sessions.count / parentRowCount <= 1
  );
}

export const HUMAN_READABLE_KEYS: {
  [key in keyof AnalyticsAppliedFilters]: string;
} = {
  organisation: i18next.t<string>('Account'),
  activity: i18next.t<string>('Activity'),
  product: i18next.t<string>('Product'),
  group: i18next.t<string>('Group'),
  user: i18next.t<string>('Respondent'),
  sex: i18next.t<string>('Sex'),
  age: i18next.t<string>('Age'),
  ethnicity: i18next.t<string>('Ethnicity'),
  job_level: i18next.t<string>('Job level'),
  industry: i18next.t<string>('Industry'),
  geography: i18next.t<string>('Geography'),
};

export const getSplitKeys = (
  row: HeatmapRow,
  user: Immutable.Map<string, any>,
  parentFilters?: AnalyticsAppliedFilters
) => {
  const isAdmin = can(user, administerOrganisations());
  const appliedFilters = { ...parentFilters, ...row.appliedFilters };
  const hideOrg =
    appliedFilters['activity'] || appliedFilters['group'] || !isAdmin;
  return _.pairs(row.filtersState.filters)
    .filter(([_key, values]) => values.length)
    .map(([key, _values]) => key)
    .filter(key => key !== 'user')
    .filter(key => key !== 'product')
    .filter(key => !hideOrg || key !== 'organisation')
    .filter(
      key => appliedFilters[key as keyof AnalyticsAppliedFilters]?.length !== 1
    );
};

interface Item {
  id: Uuid;
  name: string;
}

export const DISC_FACTORS = ['D', 'I', 'S', 'C'];
export const calcWidth = (leg1: number, leg2: number) => {
  return Math.sqrt(leg1 * leg1 + leg2 * leg2);
};

export const radiansToDegrees = (rads: number, isNeg: boolean) => {
  return isNeg
    ? Math.abs(rads * (180 / Math.PI) - 90)
    : rads * (180 / Math.PI) - 90;
};

export const calcAngle = (leg1: number, leg2: number) => {
  const isNeg = leg2 < 0;
  return radiansToDegrees(Math.asin(leg1 / calcWidth(leg1, leg2)), isNeg);
};

const MAX_SCORE = 80;
export const getDISCRowScores = (
  results: AnalyticsFactorDISCResults | null,
  aggResults: AnalyticsAggregateResults | null,
  code: string
) => {
  return results
    ? DISC_FACTORS.map(
        factor =>
          MAX_SCORE -
            results.find(
              items => items.factor === factor && items.code === code
            )!.score || 0
      )
    : (aggResults &&
        DISC_FACTORS.map(
          factor =>
            MAX_SCORE -
            (aggResults.find(
              items => items.factor === factor && items.code === code
            )?.mean?.score || 0)
        )) ||
        [];
};

export const isLighter = (
  product: string | undefined,
  columnGroupIndex: number,
  columnIndex: number
) => {
  return (
    product === 'THREE_SIXTY' && columnGroupIndex > 0 && columnIndex % 3 === 0
  );
};
