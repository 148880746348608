import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-regular-svg-icons';
import i18next from 'i18next';
import React from 'react';
import _ from 'underscore';
import uuid from 'uuid';

import { AnalyticsProduct } from '^/reducers/api/types';
import ProfileFilter from './ProfileFilter';
import { getFactors } from '../utils';

export interface DeconstructedProfileFilter {
  factor: string | undefined;
  attribute: string | undefined;
  from: string | undefined;
  to: string | undefined;
}

interface OwnProps {
  initialFilters: string[];
  product: AnalyticsProduct;
  setFilters: (filters: string[]) => void;
}

export type Props = OwnProps;

interface State {
  filters: DeconstructedProfileFilter[];
}

export class ProfileFilters extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.updateFilters = this.updateFilters.bind(this);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.state = {
      filters: props.initialFilters.map(filter => {
        const [attribute, factor, from, to] = filter.split(':');
        return {
          attribute,
          factor,
          from,
          to,
        };
      }),
    };
  }

  convertFiltersToStrings(filters: DeconstructedProfileFilter[]): string[] {
    return filters
      .filter(
        filter => filter.attribute && filter.factor && filter.from && filter.to
      )
      .map(
        filter =>
          `${filter.attribute}:${filter.factor}:${filter.from}:${filter.to}`
      );
  }

  componentDidUpdate(_prevProps: OwnProps, prevState: State) {
    const completedFilters = this.convertFiltersToStrings(this.state.filters);
    if (
      !_.isEqual(
        this.convertFiltersToStrings(prevState.filters),
        completedFilters
      )
    ) {
      this.props.setFilters(completedFilters);
    }
  }

  updateFilters(newFilter: DeconstructedProfileFilter, index: number) {
    const newFilters = this.state.filters.map((filter, idx) =>
      idx === index ? newFilter : filter
    );
    this.setState({ filters: newFilters });
  }

  addRow() {
    this.setState({
      filters: this.state.filters.concat([
        {
          attribute: undefined,
          factor: undefined,
          from: undefined,
          to: undefined,
        },
      ]),
    });
  }

  removeRow(rowId: number) {
    this.setState({
      filters: this.state.filters.filter((_filter, idx) => idx !== rowId),
    });
  }

  render() {
    const { product } = this.props;
    const factors = getFactors(product);

    return (
      <div>
        <div className="filters-container">
          {this.state.filters.map((_filter, index) => {
            return (
              <ProfileFilter
                factors={factors}
                product={product}
                key={uuid.v4()}
                onDeleteClickHandler={() => this.removeRow(index)}
                setFilters={(filter: DeconstructedProfileFilter) =>
                  this.updateFilters(filter, index)
                }
                filter={_filter}
              />
            );
          })}
          <a className="add-capability" onClick={this.addRow}>
            <FontAwesomeIcon icon={faPlusSquare} />
            {i18next.t<string>('Capability')}
          </a>
        </div>
      </div>
    );
  }
}
