import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';

import DatePicker from '^/components/date/DatePicker';

interface SingleDatePickerProp {
  disabled?: boolean;
  value?: Date;
  defaultValue?: string;
  fieldName?: string;
  isLoading?: boolean;
  prefix?: string;
  error?: string;
}

interface Props {
  className?: string;
  minDate?: Moment;
  maxDate?: Moment;
  onSave: (fieldName: string, value?: Moment) => void;
  showTime?: boolean;
  first: SingleDatePickerProp;
  second: SingleDatePickerProp;
  error?: string;
  fitWidth?: boolean;
  clearable?: boolean;
  isCreditDatePicker?: boolean;
}

export class DateRangePicker extends React.Component<Props, {}> {
  public render() {
    const {
      minDate,
      maxDate,
      className,
      first,
      second,
      showTime,
      fitWidth,
      clearable,
      isCreditDatePicker,
    } = this.props;

    return (
      <div
        className={classNames(
          'date-range-picker',
          { 'fit-width': fitWidth },
          className
        )}
      >
        <DatePicker
          prefix={first.prefix}
          minDate={minDate}
          maxDate={second.value ? moment(second.value) : maxDate}
          disabled={first.disabled}
          showTime={showTime}
          value={first.value}
          onSave={this.onSaveFirst}
          defaultValue={first.defaultValue}
          isLoading={first.isLoading}
          error={first.error}
          clearable={clearable}
          isCreditDatePicker={isCreditDatePicker}
          second={second.value}
        />
        <DatePicker
          prefix={second.prefix}
          minDate={first.value ? moment(first.value) : minDate}
          maxDate={maxDate}
          disabled={second.disabled}
          showTime={showTime}
          value={second.value}
          onSave={this.onSaveSecond}
          defaultValue={second.defaultValue}
          isLoading={second.isLoading}
          error={second.error}
          clearable={clearable}
          isCreditDatePicker={isCreditDatePicker}
          first={first.value}
        />
      </div>
    );
  }
  private onSaveFirst = (value?: Moment) => {
    const { onSave, first } = this.props;
    if (onSave) {
      onSave(first.fieldName || '', value);
    }
  };

  private onSaveSecond = (value?: Moment) => {
    const { onSave, second, showTime } = this.props;
    if (onSave) {
      if (!showTime && value) {
        // Sets the date as an inclusive range, alsomutates the moment object
        value.endOf('day');
      }
      onSave(second.fieldName || '', value);
    }
  };
}

export default DateRangePicker;
