import React, { ChangeEvent, PureComponent } from 'react';

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export class TextArea extends PureComponent<Props> {
  public textArea: HTMLTextAreaElement | null = null;

  public componentDidMount() {
    window.addEventListener('resize', this.autoExpand, false);
    this.autoExpand();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.autoExpand, false);
  }

  public render() {
    return (
      <textarea ref={this.storeRef} {...this.props} onChange={this.onChange} />
    );
  }

  private storeRef = (node: HTMLTextAreaElement) => (this.textArea = node);

  private onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.autoExpand();
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  // https://gomakethings.com/automatically-expand-a-textarea-as-the-user-types-using-vanilla-javascript/
  private autoExpand = () => {
    if (!this.textArea) {
      return;
    }
    this.textArea.style.height = 'inherit';
    const computed = window.getComputedStyle(this.textArea);

    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      this.textArea.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);
    this.textArea.style.height = height + 'px';
  };
}

export default TextArea;
