import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import { is, OrderedSet } from 'immutable';
import i18next from 'i18next';

import { propTypes as nominationRulesPropTypes } from '^/nominationRules';
import PureComponent from '^/components/PureComponent';
import { generateNominationRulesLabel } from '^/constants/nominationRule';
import { capitalize } from '^/utils';

export default class RaterRules extends PureComponent {
  getLabel(rule) {
    return capitalize(generateNominationRulesLabel(rule));
  }

  render() {
    const { rules } = this.props;
    const uniqueRoles = OrderedSet(rules.map(each => each.get('role')));
    const hasRole = role => rater => is(rater.get('role'), role);

    return (
      <div className="row">
        <div className="col-xs-12">
          <p>{i18next.t('You are required to nominate')}</p>
          <ul>
            <li>{i18next.t('Your Line Manager')}</li>
            {uniqueRoles.map(role => (
              <li key={role}>{this.getLabel(rules.filter(hasRole(role)))}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

RaterRules.propTypes = {
  rules: ImmutablePropTypes.listOf(nominationRulesPropTypes.NominationRule)
    .isRequired,
};
