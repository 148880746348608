import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import ControlBar from '../ControlBar';
import { Reports } from './productVersionsPage/Reports';

export default class ProductVersionsPage extends PureComponent {
  render() {
    return (
      <div>
        <ControlBar
          hideAdd
          createButtonText={i18next.t('survey')}
          title={i18next.t('Psychometrics & Reports')}
          hideSearch
          onAddClick={() => this.showAddProductVersionModal()}
        />
        <Reports />
      </div>
    );
  }
}
