import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { getStripeInvoiceAndRedirectToPayment } from '^/actions/actionSequences';
import { isPending } from '^/responseStates';
import FormError from '^/components/FormError';
import CreditCostBreakdownTable from '^/components/organisations/accounts/CreditCostBreakdownTable';
import { isInt } from '^/utils';
import { VAT_RATE } from '^/components/shop/constants';
import { shouldApplyVat } from '^/components/shop/utils';

export const MAX_PURCHASE_CREDITS = 1000000;

export const PurchaseCreditsForm = props => {
  const [credits, setCredits] = useState(0);

  const calculateDiscountPercentage = () => {
    const { discountBands } = props;
    const bandToApply =
      discountBands &&
      discountBands.findLast(band => credits >= band.get('discount_from'));
    return bandToApply ? bandToApply.get('percentage') : 0;
  };

  const { response, creditCost, currency, account } = props;

  const isValidPurchase =
    isInt(credits) && credits > 0 && credits <= MAX_PURCHASE_CREDITS;
  const costOfCredits = (credits || 0) * creditCost;
  const discountPercentage = calculateDiscountPercentage(credits);
  const discountAmount = (discountPercentage / 100) * costOfCredits;
  const totalBeforeTax = costOfCredits - discountAmount;
  const applyVat = shouldApplyVat(account.get('tax_location'));
  const taxAmount = applyVat ? totalBeforeTax * VAT_RATE : 0;
  const totalAfterTax = costOfCredits - discountAmount + taxAmount;
  const purchasing = isPending(response);

  return (
    <form className="form-horizontal">
      <div className="centered" style={{ width: 200 }}>
        <label htmlFor="credits">
          {i18next.t('Number credits to purchase')}
          <input
            id="credits"
            type="number"
            min={0}
            max={MAX_PURCHASE_CREDITS}
            placeholder="0"
            onChange={e => setCredits(parseInt(e.target.value, 10))}
            disabled={purchasing}
            className="center"
          />
          <FormError response={response} formKey="credits" />
        </label>
      </div>

      <CreditCostBreakdownTable
        account={account}
        currency={currency}
        isValidPurchase={isValidPurchase}
        costOfCredits={costOfCredits}
        discountPercentage={discountPercentage}
        discountAmount={discountAmount}
        taxAmount={taxAmount}
        totalAfterTax={totalAfterTax}
      />

      <div className="center">
        <button
          type="button"
          className="btn btn-primary purchase-credits"
          disabled={!isValidPurchase || purchasing}
          onClick={() =>
            props.getStripeInvoiceAndRedirectToPayment(
              account.get('id'),
              credits
            )
          }
        >
          {purchasing
            ? i18next.t('Opening payment tab...')
            : i18next.t('Purchase')}
        </button>

        <FormError response={response} formKey="non_field_errors" />
      </div>
    </form>
  );
};

export default connect(null, { getStripeInvoiceAndRedirectToPayment })(
  PurchaseCreditsForm
);
