import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import { updateOrganisationAndCloseModal } from '^/actions/actionSequences';
import EditAccountLogoForm from '^/components/organisations/accounts/EditAccountLogoForm';

export class EditAccountLogoModal extends PureComponent {
  updateOrganisation(data) {
    const { account } = this.props;
    this.props.updateOrganisationAndCloseModal(account.get('id'), data);
  }

  render() {
    const { account, response } = this.props;
    return (
      <EditAccountLogoForm
        originalLogo={account.get('brand_logo')}
        response={response}
        onSubmit={this.updateOrganisation.bind(this)}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  updateOrganisationAndCloseModal,
})(EditAccountLogoModal);
