import React from 'react';

import { OrgSessions, Uuid } from '^/reducers/api/types';
import { LanguageCode } from '^/constants/routes';
import { InputLanguageSelector } from '^/components/buttons/InputLanguageSelector';

interface Props {
  updateLanguageCode: (languageCode: LanguageCode) => void;
  languageCode: LanguageCode;
}

export function isMultiLanguage(
  orgSessions: OrgSessions | null,
  productId: Uuid | null
) {
  return orgSessions?.productorganisation_set
    .filter(po => po.product.id === productId)
    .some(
      productOrganisation =>
        productOrganisation.productorganisationreporttemplate_set.filter(
          ({ report_template }) => report_template.is_team
        ).length > 1
    );
}

export const MultiLanguageSection: React.FC<Props> = props => {
  const { languageCode, updateLanguageCode } = props;
  return (
    <div className="mt-lg">
      <InputLanguageSelector
        inputTextLanguage={languageCode}
        setInputTextLanguage={updateLanguageCode as any}
        disabled={false}
      />
    </div>
  );
};

export default MultiLanguageSection;
