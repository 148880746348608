import React from 'react'; // eslint-disable-line no-unused-vars
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import { can, seeAdminDashboard } from '^/capabilities';
import PureComponent from '^/components/PureComponent';
import AdminDashboard from '^/components/dashboard/admin/Dashboard';
import Loading from '^/components/Loading';

export class HomePage extends PureComponent {
  componentDidMount() {
    this.checkPermissions();
  }

  componentDidUpdate() {
    this.checkPermissions();
  }

  checkPermissions() {
    const { user } = this.props;

    if (user.count() && !can(user, seeAdminDashboard())) {
      this.props.push('/page/dashboard');
    }
  }

  render() {
    const { user } = this.props;

    if (!user.count() || !can(user, seeAdminDashboard())) {
      return <Loading className="list-loading" />;
    }

    return <AdminDashboard />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, { push })(HomePage);
