import { AnyAction } from 'redux';
import _ from 'underscore';

import * as actions from '^/actions/actions';
import * as uiActions from '^/actions/ui';
import {
  getReportTemplatesByProductAndVariantId,
  getSessionsByUser,
} from '^/components/reports/admin/selectors';
import {
  asKeys,
  available,
  isSelected,
  notHidden,
  ReportsTableSelections,
  ReportsTableSelectionsKey,
  selectionFromKey,
  SelectionState,
  setSelectionState,
  setUnselectedState,
  toggleHiddenSelectionState,
  toggleSelectionState,
} from './types';
import {
  getInitialSelectionState,
  getInitialTeamSelectionState,
} from './utils';

export function reportsTableSelections(
  state: ReportsTableSelections = {
    reports: {},
    reportTemplates: {},
  },
  action: AnyAction
): ReportsTableSelections {
  switch (action.type) {
    case actions.TOGGLE_REPORTS_TABLE_SELECTION: {
      const [key, innerKey] = asKeys(action.payload);
      return {
        ...state,
        [key]: {
          ...state[key],
          [innerKey]: toggleSelectionState(state[key][innerKey]),
        },
      };
    }
    case actions.TOGGLE_SELECT_ALL_REPORTS_TABLE_SELECTION: {
      const key = action.payload as ReportsTableSelectionsKey;
      const allSelected = Object.values(state[key])
        .filter(
          selectionState =>
            notHidden(selectionState) && available(selectionState)
        )
        .every(isSelected);
      return {
        ...state,
        [key]: _.mapObject(state[key], currentState =>
          setSelectionState(
            currentState,
            allSelected ? SelectionState.Unselected : SelectionState.Selected
          )
        ),
      };
    }
    case actions.LOAD_ORG_PRODUCTS.SUCCESS:
      return {
        reports: {},
        reportTemplates: {},
        reportTemplatesByProductAndVariantId: getReportTemplatesByProductAndVariantId(
          action.payload
        ),
        activities: [],
      };
    case actions.LOAD_ORG_SESSIONS.SUCCESS: {
      const activities = (state.activities || []).concat(
        action.payload.results
      );

      if (action.payload.next) {
        return { ...state, activities };
      }

      return getInitialSelectionState(
        action.meta.user,
        getSessionsByUser({ activities }),
        state.reportTemplatesByProductAndVariantId!
      );
    }
    case actions.LOAD_TEAM_REPORTS.SUCCESS: {
      return getInitialTeamSelectionState(action.payload, action.meta.user);
    }
    case uiActions.TOGGLE_REPORT_FILTER_SELECTION:
    case uiActions.TOGGLE_REPORT_FILTER_SELECTIONS:
    case actions.UNSELECT_ALL_REPORTS_TABLE_SELECTION:
      return {
        reports: _.mapObject(state.reports, setUnselectedState),
        reportTemplates: _.mapObject(state.reportTemplates, setUnselectedState),
      };
    case actions.FILTER_HIDDEN_REPORTS_TABLE_SELECTION:
      const visibleSessionIds = new Set(action.payload);
      return {
        reports: _.mapObject(state.reports, (currentState, key) =>
          toggleHiddenSelectionState(
            visibleSessionIds.has(selectionFromKey(key).sessionId),
            currentState
          )
        ),
        reportTemplates: _.mapObject(
          state.reportTemplates,
          (currentState, key) =>
            toggleHiddenSelectionState(
              visibleSessionIds.has(selectionFromKey(key).sessionId),
              currentState
            )
        ),
      };
    default:
      return state;
  }
}
