import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export enum AlertType {
  Info,
  Warning,
  Error,
  Success,
}

interface OwnProps {
  children: ReactNode;
  hideIcon?: boolean;
  type?: AlertType;
  className?: string;
  dangerouslySetInnerHTML?: { __html: string };
}

type Props = OwnProps;

const iconTypeMap = {
  [AlertType.Info]: faInfoCircle,
  [AlertType.Warning]: faExclamationCircle,
  [AlertType.Success]: faCheck,
  [AlertType.Error]: faExclamationTriangle,
};

const classTypeMap = {
  [AlertType.Info]: 'alert-info',
  [AlertType.Warning]: 'alert-warning',
  [AlertType.Success]: 'alert-success',
  [AlertType.Error]: 'alert-error',
};

export const Alert: React.FunctionComponent<Props> = ({
  children,
  className,
  hideIcon,
  dangerouslySetInnerHTML,
  type = AlertType.Info,
}) => (
  <div className={classNames(className, classTypeMap[type], 'alert')}>
    {!hideIcon && (
      <FontAwesomeIcon
        icon={iconTypeMap[type]}
        size="lg"
        className="alert-icon"
      />
    )}
    <span
      className="alert-content"
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </span>
  </div>
);

export default Alert;
