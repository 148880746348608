import i18next from 'i18next';
import { createSelector } from 'reselect';

import { USER_STATUS } from '^/models/user';
import { selectSelectedUser } from '^/selectors/items';

const USER_STATUS_MAP = {
  [USER_STATUS.UNINVITED]: i18next.t<string>('To be invited'),
  [USER_STATUS.INVITED]: i18next.t<string>('Invited'),
  [USER_STATUS.ACTIVE]: i18next.t<string>('Active'),
  [USER_STATUS.INACTIVE]: i18next.t<string>('Inactive'),
};

export const selectSelectedUserFullName = createSelector(
  selectSelectedUser,
  user => user && user.get('full_name')
);

export const selectSelectedUserId = createSelector(
  selectSelectedUser,
  user => user && user.get('id')
);

export const selectSelectedUserOrgName = createSelector(
  selectSelectedUser,
  user => user && user.getIn(['organisation', 'name'])
);

export const selectSelectedUserStatus = createSelector(
  selectSelectedUser,
  user => user && user.get('status')
);

export const selectTranslatedSelectedUserStatus = createSelector(
  selectSelectedUserStatus,
  userStatus => USER_STATUS_MAP[userStatus]
);
