import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import PerspectivesMotivatorsQuestionGroup from './PerspectivesMotivatorsQuestionGroup';
import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  saveISPAnswersAndShowNext,
  saveISPAnswersAndSubmit,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { anyPending } from '^/responseStates';
import { generateQuestionOrder } from '^/questionGroups';
import AssessmentContainer from './AssessmentContainer';
import AssessmentHeader from './AssessmentHeader';
import AssessmentBody from './AssessmentBody';
import AssessmentFooter from './AssessmentFooter';

export class OrderedProductVersion extends PureComponent {
  componentDidMount() {
    // Reset our OrderedProductVersion progress
    const currentQuestionIndex = this.props.questionGroupsWithAnswers.findIndex(
      questionGroup => {
        return questionGroup.get('questions').every(([, answer]) => !answer);
      }
    );
    if (currentQuestionIndex === undefined || currentQuestionIndex === -1) {
      //We've already answered this productVersion, so jump to the last page
      this.props.setProductVersionPage(
        Math.floor(this.props.questionGroupsWithAnswers.count() - 1)
      );
    } else {
      this.props.setProductVersionPage(currentQuestionIndex);
    }
  }

  isViewingLastQuestion() {
    return (
      this.props.progress.get('currentPage') >=
      this.props.questionGroupsWithAnswers.count() - 1
    );
  }

  onNext(action) {
    const currentQuestionGroup = this.props.questionGroupsWithAnswers.get(
      this.props.progress.get('currentPage', 0)
    );
    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );

    action(
      this.props.activity,
      this.props.productVersion,
      this.props.raterFor,
      this.props.uiLanguage,
      questionId,
      unsyncedAnswers ||
        generateQuestionOrder(currentQuestionGroup.get('questions')),
      this.props.questionCollectionIdx
    );
  }

  confirmAndClose() {
    if (
      window.confirm(
        i18next.t(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      return; //do nothing, client wants the button to still be here even though it's broken
    }
  }

  render() {
    const {
      isPending,
      productVersion,
      questionCollectionIdx,
      raterFor,
    } = this.props;
    const pageCount = this.props.questionGroupsWithAnswers.count();
    const currentQuestionIndex =
      this.props.progress.get('currentPage', 0) > pageCount
        ? 0
        : this.props.progress.get('currentPage', 0);
    const currentQuestionGroup = this.props.questionGroupsWithAnswers.get(
      currentQuestionIndex
    );

    const questionId = currentQuestionGroup.get('id');
    const unsyncedAnswers = this.props.progress.getIn(
      ['unsavedResponses', questionId],
      null
    );
    const disableSave = !unsyncedAnswers || !unsyncedAnswers.every(id => id);

    const currentPage = this.props.progress.get('currentPage', 0);

    const action = this.isViewingLastQuestion()
      ? this.props.saveISPAnswersAndSubmit
      : this.props.saveISPAnswersAndShowNext;

    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          noLogo
        />

        <AssessmentBody
          header={i18next.t('Question {{current}} of {{total}}', {
            current: currentPage + 1,
            total: this.props.questionGroupsWithAnswers.count(),
          })}
          questionIndex={currentQuestionIndex}
        >
          <Well>
            <div>
              <Trans i18nKey="Please rank order motivators">
                Thinking of your own personal values, needs and preferences for
                your ideal job role and workplace environment, rank order each
                of the nine statements from{' '}
                <strong>'MOST important to me at work (1)'</strong> and{' '}
                <strong>'LEAST important to me at work (9)'</strong>
              </Trans>
            </div>
            <div />
          </Well>

          <PerspectivesMotivatorsQuestionGroup
            questionGroup={currentQuestionGroup}
            unsyncedAnswers={unsyncedAnswers}
            reorderAnswers={this.props.storeProductVersionAnswer.bind(
              null,
              questionId
            )}
            isPending={isPending}
            targetText
          />
        </AssessmentBody>

        <AssessmentFooter
          isSaving={isPending}
          showContinue={false}
          onContinueLater={() => this.confirmAndClose()}
          onContinue={this.onNext.bind(this, action)}
          isEnd={this.isViewingLastQuestion()}
          isSaveButtonDisabled={disableSave}
        />
      </AssessmentContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('saveISPAnswers'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  saveISPAnswersAndShowNext,
  saveISPAnswersAndSubmit,
  push,
})(OrderedProductVersion);
