import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';

import * as DragAndDropGroupContextInstance from './DragAndDropGroupContextInstance';
import { DragAndDropGroupContext, PointerPosition } from './types';
import { isDragging, isDropping, isTouchEvent } from './utils';

interface OwnProps<Groups extends { [i: string]: ReadonlyArray<Item> }, Item> {
  index: number;
  tag?: 'div' | 'span' | 'li';
  className?: string;
  disableDrag?: boolean;
  draggedClassName?: string;
  placeholderClassName?: string;
  group: string | undefined;
  draggedGroup: string | undefined;
  draggedIndex: number | undefined;
  droppedGroup: string | undefined;
  droppedIndex: number | undefined;
  box: ClientRect | undefined;
  pointer: PointerPosition | undefined;
  pointerStart: PointerPosition | undefined;
  children: ReactNode | ReadonlyArray<ReactNode>;
  setDragged: DragAndDropGroupContext<Groups, Item>['setDragged'];
  setPosition: DragAndDropGroupContext<Groups, Item>['setPosition'];
  itemDropped: DragAndDropGroupContext<Groups, Item>['itemDropped'];
}

type Props<
  Groups extends { [i: string]: ReadonlyArray<Item> },
  Item
> = OwnProps<Groups, Item>;

class DraggableWithGroupContext<
  Groups extends { [i: string]: ReadonlyArray<Item> },
  Item
> extends PureComponent<Props<Groups, Item>> {
  private element: HTMLElement | null = null;

  public componentDidMount() {
    if (this.element) {
      this.element.addEventListener('touchstart', this.onDragStart);
    }

    window.addEventListener('touchmove', this.onDragMove);
    window.addEventListener('touchend', this.onDragEnd);
    window.addEventListener('touchcancel', this.onDragEnd);
    window.addEventListener('mousemove', this.onDragMove);
    window.addEventListener('mouseup', this.onDragEnd);
  }

  public componentWillUnmount() {
    if (this.element) {
      this.element.removeEventListener('touchstart', this.onDragStart);
    }

    window.removeEventListener('touchmove', this.onDragMove);
    window.removeEventListener('touchend', this.onDragEnd);
    window.removeEventListener('touchcancel', this.onDragEnd);
    window.removeEventListener('mousemove', this.onDragMove);
    window.removeEventListener('mouseup', this.onDragEnd);
  }

  public render() {
    const {
      tag: Tag = 'div',
      disableDrag,
      group,
      index,
      box,
      pointer,
      pointerStart,
      className,
      draggedClassName,
      placeholderClassName,
      children,
    } = this.props;

    const dragging = isDragging(this.props);

    const dropping = isDropping(this.props);

    const isBeingDraggedNotDropped =
      !dropping &&
      dragging &&
      typeof group !== 'undefined' &&
      this.props.draggedGroup === group &&
      this.props.draggedIndex === index;

    const isBeingDroppedOn =
      dragging &&
      dropping &&
      typeof group !== 'undefined' &&
      this.props.droppedGroup === group &&
      this.props.droppedIndex === index;

    const isBeingDraggedOrDroppedOn =
      isBeingDraggedNotDropped || isBeingDroppedOn;

    const draggableProps = disableDrag
      ? undefined
      : {
          ref: this.storeRef,
          onMouseDown: this.onDragStart,
        };

    const style =
      isBeingDraggedOrDroppedOn && box && pointer && pointerStart
        ? {
            position: 'fixed' as const,
            top: box.top + pointer.clientY - pointerStart.clientY,
            left: box.left + pointer.clientX - pointerStart.clientX,
            width: box.width,
            height: box.height,
            zIndex: 1,
          }
        : undefined;

    const draggableElement = (
      <Tag
        {...draggableProps}
        style={style}
        className={classNames(
          className,
          draggedClassName && {
            [draggedClassName]: Boolean(
              isBeingDraggedOrDroppedOn && box && pointer && pointerStart
            ),
          }
        )}
      >
        {children}
      </Tag>
    );

    if (isBeingDraggedOrDroppedOn && box && pointer && pointerStart) {
      return (
        <span>
          <Tag className={classNames(className, placeholderClassName)}>
            {children}
          </Tag>
          {draggableElement}
        </span>
      );
    }

    return draggableElement;
  }

  private storeRef = (element: HTMLElement | null) => {
    this.element = element;
  };

  private onDragStart = (event: React.MouseEvent<HTMLElement> | TouchEvent) => {
    event.preventDefault();

    if (typeof this.props.group === 'undefined') {
      throw new Error(
        'DragTarget was rendered outside of the context of a DragAndDropGroup'
      );
    }

    if (this.element) {
      const { clientX, clientY } = isTouchEvent(event)
        ? event.touches[0]
        : event;

      this.props.setDragged(
        this.props.group,
        this.props.index,
        this.element.getBoundingClientRect(),
        {
          clientX,
          clientY,
        }
      );
    }
  };

  private onDragMove = (event: MouseEvent | TouchEvent) => {
    const isBeingDragged =
      isDragging(this.props) &&
      this.props.draggedGroup === this.props.group &&
      this.props.draggedIndex === this.props.index;

    if (isBeingDragged) {
      const { clientX, clientY } = isTouchEvent(event)
        ? event.touches[0]
        : event;
      this.props.setPosition({ clientX, clientY });
    }
  };

  private onDragEnd = () => {
    const isBeingDragged =
      isDragging(this.props) &&
      this.props.draggedGroup === this.props.group &&
      this.props.draggedIndex === this.props.index;

    if (isBeingDragged) {
      this.props.itemDropped();
    }
  };
}

const Draggable = <Groups extends { [i: string]: ReadonlyArray<Item> }, Item>(
  props: Pick<
    Props<Groups, Item>,
    Exclude<
      keyof Props<Groups, Item>,
      | 'group'
      | 'setDragged'
      | 'itemDropped'
      | 'draggedGroup'
      | 'draggedIndex'
      | 'droppedGroup'
      | 'droppedIndex'
      | 'box'
      | 'setPosition'
      | 'pointer'
      | 'pointerStart'
    >
  >
) => (
  <DragAndDropGroupContextInstance.Consumer>
    {context => (
      <DraggableWithGroupContext
        {...props}
        setDragged={context.setDragged}
        setPosition={context.setPosition}
        itemDropped={context.itemDropped}
        group={context.group}
        draggedGroup={context.draggedGroup}
        draggedIndex={context.draggedIndex}
        droppedGroup={context.droppedGroup}
        droppedIndex={context.droppedIndex}
        box={context.box}
        pointer={context.pointer}
        pointerStart={context.pointerStart}
      />
    )}
  </DragAndDropGroupContextInstance.Consumer>
);

export default Draggable;
