import { LanguageCode } from '^/constants/routes';
import { Uuid } from '^/reducers/api/types';

export type ReportFilter = 'activities' | 'groups' | 'products';
export type ReportFilterSelections = {
  [key in ReportFilter]: { [_key in Uuid]: boolean };
} &
  Readonly<{
    searchString?: string | null;
    date: {
      from?: Date;
      to?: Date;
    };
  }>;

export type ReportFilterSelection = {
  [key in ReportFilter]: Uuid | null;
};

export const SEARCH_REPORT_FILTER_USERS = 'SEARCH_REPORT_FILTER_USERS';
export const searchReportFilterUsers = (searchString?: string | null) => ({
  type: SEARCH_REPORT_FILTER_USERS,
  payload: searchString,
});

export const TOGGLE_REPORT_FILTER_SELECTION = 'TOGGLE_REPORT_FILTER_SELECTION';
export const toggleReportFilterSelection = (
  reportFilterSelection: Partial<ReportFilterSelection>
) => ({
  type: TOGGLE_REPORT_FILTER_SELECTION,
  payload: reportFilterSelection,
});

export const TOGGLE_REPORT_FILTER_SELECTIONS =
  'TOGGLE_REPORT_FILTER_SELECTIONS';
export const toggleReportFilterSelections = (
  reportFilterSelections: Partial<
    {
      [key in ReportFilter]: ReadonlyArray<Uuid>;
    }
  >,
  toggleTo: boolean
) => ({
  type: TOGGLE_REPORT_FILTER_SELECTIONS,
  payload: reportFilterSelections,
  meta: {
    toggleTo,
  },
});

export const SET_REPORT_FILTER_DATE = 'SET_REPORT_FILTER_DATE';
export const setReportFilterDate = (payload: {
  from?: Date | null;
  to?: Date | null;
}) => ({
  type: SET_REPORT_FILTER_DATE,
  payload,
});

export const SET_REPORT_FILTER_SELECTION = 'SET_REPORT_FILTER_SELECTION';
export const setReportFilterSelection = (
  reportFilterSelection: Partial<ReportFilterSelections>
) => ({
  type: SET_REPORT_FILTER_SELECTION,
  payload: reportFilterSelection,
});

export const CLEAR_REPORT_FILTER_SELECTION = 'CLEAR_REPORT_FILTER_SELECTION';
export const clearReportFilterSelection = () => ({
  type: CLEAR_REPORT_FILTER_SELECTION,
});

export const SET_REPORTS_INITIAL_ORGANISATION =
  'SET_REPORTS_INITIAL_ORGANISATION';
export const setReportsInitialOrganisation = (organisationId: Uuid) => ({
  type: SET_REPORTS_INITIAL_ORGANISATION,
  payload: organisationId,
});

export const CLEAR_REPORTS_INITIAL_ORGANISATION =
  'CLEAR_REPORTS_INITIAL_ORGANISATION';
export const clearReportsInitialOrganisation = () => ({
  type: CLEAR_REPORTS_INITIAL_ORGANISATION,
});

export const SET_UI_LANGUAGE = 'SET_UI_LANGUAGE';
export const setUiLanguage = (languageCode: LanguageCode) => ({
  type: SET_UI_LANGUAGE,
  payload: languageCode,
});

export const SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE';
export const setActiveLanguage = (languageCode: LanguageCode) => ({
  type: SET_ACTIVE_LANGUAGE,
  payload: languageCode,
});

export const SET_INPUT_TEXT_LANGUAGE = 'SET_INPUT_TEXT_LANGUAGE';
export const setInputTextLanguage = (languageCode: LanguageCode) => ({
  type: SET_INPUT_TEXT_LANGUAGE,
  payload: languageCode,
});

export const SET_LANGUAGE_SWITCHER_INTERACTED_WITH =
  'SET_LANGUAGE_SWITCHER_INTERACTED_WITH';
export const setLanguageSwitcherInteractedWith = (hasBeenChanged: boolean) => ({
  type: SET_LANGUAGE_SWITCHER_INTERACTED_WITH,
  payload: hasBeenChanged,
});
