import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { formatDate } from '^/utils';
import { Pulse, PulseDisplayFrequency } from '^/reducers/api/types';
import PulseDetailTable from './PulseDetailTable';
import { updatePulse } from '^/actions/actions';
import { ExpandableBox } from '../ExpandableBox';
import { ContainerRectangleTile } from '../ContainerRectangleTile';

type OwnProps = {
  pulse: Pulse;
  expandable?: boolean;
  defaultExpanded?: boolean;
  closeBox?: boolean;
  onClick?: (id: string, isExpanded: boolean) => void;
};

interface DispatchProps {
  updatePulse: typeof updatePulse;
}

interface State {
  frequency: PulseDisplayFrequency;
  reminderTime: string;
}

export type Props = OwnProps & DispatchProps;

export class PulseDetail extends React.PureComponent<Props, State> {
  render() {
    const {
      pulse,
      expandable,
      defaultExpanded = false,
      onClick,
      closeBox,
    } = this.props;

    const onBoxClick = (boxId: string, isExpanded: boolean) => {
      onClick && onClick(boxId, isExpanded);
    };

    const pulseSubscriptionName =
      pulse.pulsesubscription_set && pulse.pulsesubscription_set[0]
        ? pulse.pulsesubscription_set[0].name
        : '';
    const usePulseCreatedDate =
      pulse.userpulse_set && pulse.userpulse_set[0]
        ? formatDate(pulse.userpulse_set[0].created)
        : i18next.t<string>('N/A');

    return (
      <>
        {expandable ? (
          <ExpandableBox
            closeBox={closeBox}
            onClick={onBoxClick}
            defaultExpanded={defaultExpanded}
            title={pulseSubscriptionName}
            rightText={() => (
              <p>
                {i18next.t<string>('Started')} : <b> {usePulseCreatedDate}</b>
              </p>
            )}
          >
            <div>
              <div className="mb-base">
                {i18next.t<string>('Number of respondents:')}{' '}
                <b>{pulse.userpulse_set?.length || 0}</b>
              </div>
            </div>
            <PulseDetailTable pulse={pulse} />
          </ExpandableBox>
        ) : (
          <ContainerRectangleTile noBorder>
            <div>
              <div className="expandable-box-header">
                <h1 className="table-heading-tab">{pulseSubscriptionName}</h1>
                <div className="expandable-box-header-right">
                  <div>
                    <p>
                      {i18next.t<string>('Started')}:{' '}
                      <b>{formatDate(pulse.created)}</b>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-base">
                {i18next.t<string>('Number of respondents:')}{' '}
                <b>{pulse.userpulse_set?.length || 0}</b>
              </div>
            </div>
            <PulseDetailTable pulse={pulse} />
          </ContainerRectangleTile>
        )}
      </>
    );
  }
}

export default connect(undefined, { updatePulse })(PulseDetail);
