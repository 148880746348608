import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import {
  administerOrganisations,
  administerOwnOrganisation,
  can,
} from '^/capabilities';
import ControlBar from '^/components/ControlBar';
import RadioToggle from '^/components/RadioToggle';
import { StoreState } from '^/store';
import { makeChoices } from '^/utils-ts';
import AdminReportsPage from './AdminReportsPage';
import TeamReportsPage from './TeamReportsPage';
import JobProfilesReportsPage from './JobProfilesReportsPage';
import { Uuid } from '^/reducers/api/types';
import { selectReportsInitialOrganisation } from '^/selectors/ui';
import { clearReportsInitialOrganisation } from '^/actions/ui';

type ReportType = 'INDIVIDUAL' | 'TEAM' | 'JOB_PROFILES';

const REPORT_TYPE_VALUES: Record<ReportType, ReportType> = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
  JOB_PROFILES: 'JOB_PROFILES',
};

interface State {
  selectedTab: string;
}

interface DispatchProps {
  clearReportsInitialOrganisation: typeof clearReportsInitialOrganisation;
}
type OwnProps = {
  user: Immutable.Map<string, any>;
  initialOrganisation: Uuid | null;
};

const INDIVIDUAL_REPORT_TYPE: [ReportType, string][] = [
  [REPORT_TYPE_VALUES.INDIVIDUAL, i18next.t<string>('Individual reports')],
];

const TEAM_REPORT_TYPES: [ReportType, string][] = [
  [REPORT_TYPE_VALUES.TEAM, i18next.t<string>('Team reports')],
];

const JOB_PROFILER_REPORT_TYPE: [ReportType, string][] = [
  [REPORT_TYPE_VALUES.JOB_PROFILES, i18next.t<string>('Job profiles')],
];

const ReportBreadcrumb = (
  <span className="breadcrumbs">
    <Link to="/page/home">{i18next.t<string>('Home')}</Link>
    <span>{i18next.t<string>('Reports')}</span>
  </span>
);
export type Props = DispatchProps & OwnProps;
export class ReportsPage extends React.Component<Props, State> {
  public readonly state = {
    selectedTab: REPORT_TYPE_VALUES.INDIVIDUAL,
  };

  public render() {
    const { selectedTab } = this.state;
    const { user, initialOrganisation } = this.props;
    const url = new URL(window.location.href);
    const isJobProfileInitial = Boolean(
      url.hash.split('?job-profiler=')[1] && initialOrganisation !== null
    );
    const hasTeamReports =
      can(user, administerOrganisations()) ||
      (can(user, administerOwnOrganisation()) && user.get('has_team_sessions'));

    const hasJobProfiles =
      can(user, administerOrganisations()) ||
      (can(user, administerOwnOrganisation()) &&
        user.get('organisation')?.get('can_create_job_profiles'));

    let toggleChoices = INDIVIDUAL_REPORT_TYPE;
    if (hasTeamReports) {
      toggleChoices = toggleChoices.concat(TEAM_REPORT_TYPES);
    }
    if (hasJobProfiles) {
      toggleChoices = toggleChoices.concat(JOB_PROFILER_REPORT_TYPE);
    }

    const handleOnChange = (value: string) => {
      this.props.clearReportsInitialOrganisation();
      this.setState({ selectedTab: value });
    };

    const choices = makeChoices(toggleChoices);
    const switcher = (hasTeamReports || hasJobProfiles) && (
      <RadioToggle
        choices={choices}
        selected={selectedTab}
        onChange={handleOnChange}
      />
    );

    const renderReportsPage = () => {
      if (selectedTab === choices.CHOICES.TEAM) {
        return <TeamReportsPage switcher={switcher} />;
      }
      if (selectedTab === choices.CHOICES.JOB_PROFILES) {
        return <JobProfilesReportsPage switcher={switcher} />;
      } else if (isJobProfileInitial) {
        this.setState({ selectedTab: choices.CHOICES.JOB_PROFILES });
        return <JobProfilesReportsPage switcher={switcher} />;
      }
      return <AdminReportsPage switcher={switcher} />;
    };
    return (
      <div>
        <ControlBar
          title={i18next.t<string>('Reports')}
          breadcrumb={ReportBreadcrumb}
          hideSearch
          hideAdd
        />

        {renderReportsPage()}
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
    initialOrganisation: selectReportsInitialOrganisation(state),
  };
}

export default connect(mapStateToProps, { clearReportsInitialOrganisation })(
  ReportsPage
);
