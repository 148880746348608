import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { updateProductOrganisation } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import Checkbox from '^/components/Checkbox';

export class EditAccountProductComparisonGroups extends PureComponent {
  isComparisonGroupEnabledForAccount(comparisonGroup) {
    const { productOrganisation } = this.props;
    const comparisonGroupEntityId = comparisonGroup.getIn(['entity', 'id']);
    const enabledEntities = productOrganisation.get('comparison_groups');
    return enabledEntities.contains(comparisonGroupEntityId);
  }

  isEveryComparisonGroupSelected() {
    const { product, productOrganisation } = this.props;
    const comparisonGroups = product.getIn([
      'latest_version',
      'comparison_groups',
    ]);
    const enabledEntities = productOrganisation.get('comparison_groups');
    return comparisonGroups.every(comparisonGroup =>
      enabledEntities.contains(comparisonGroup.getIn(['entity', 'id']))
    );
  }

  getEntityList(comparisonGroup, checked) {
    const { productOrganisation } = this.props;
    const enabledEntities = productOrganisation.get('comparison_groups');
    const comparisonGroupEntityId = comparisonGroup.getIn(['entity', 'id']);
    let newEntitiesList;
    checked
      ? (newEntitiesList = enabledEntities.update(newEntities =>
          newEntities.push(comparisonGroupEntityId)
        ))
      : (newEntitiesList = enabledEntities.delete(
          enabledEntities.findIndex(x => x === comparisonGroupEntityId)
        ));
    return newEntitiesList;
  }

  addAllComparisonGroupsToAccount() {
    const { product, productOrganisation } = this.props;
    const comparisonGroups = product.getIn([
      'latest_version',
      'comparison_groups',
    ]);
    const allGroupsList = comparisonGroups.map(comparisonGroup =>
      comparisonGroup.getIn(['entity', 'id'])
    );
    return this.props.updateProductOrganisation(productOrganisation.get('id'), {
      comparison_groups: allGroupsList,
    });
  }

  render() {
    const { product, productOrganisation, productResponses } = this.props;
    const comparisonGroups = product.getIn([
      'latest_version',
      'comparison_groups',
    ]);
    const productId = product.get('id');
    return (
      <div className="margin-bottom">
        <h6 className="mb-0">{product.get('name')}</h6>
        <form className="form-inline scrollable">
          <Table
            className="highlight-rows selectable"
            columns={[
              {
                header: (
                  <Checkbox
                    id={'ALL' + productId}
                    checked={this.isEveryComparisonGroupSelected()}
                    onChange={() => this.addAllComparisonGroupsToAccount()}
                    disabled={isPending(productResponses)}
                  />
                ),
                value: comparisonGroup => {
                  const comparisonGroupId = comparisonGroup.get('id');
                  return (
                    <Checkbox
                      id={productId + comparisonGroupId}
                      checked={this.isComparisonGroupEnabledForAccount(
                        comparisonGroup
                      )}
                      onChange={event =>
                        this.props.updateProductOrganisation(
                          productOrganisation.get('id'),
                          {
                            comparison_groups: this.getEntityList(
                              comparisonGroup,
                              event.target.checked
                            ),
                          }
                        )
                      }
                      disabled={isPending(productResponses)}
                    />
                  );
                },
              },
              {
                header: i18next.t('Comparison Group'),
                value: comparisonGroup =>
                  comparisonGroup.getIn(['entity', 'name']) ||
                  i18next.t('Default'),
              },
            ]}
            rowClassName={comparisonGroup =>
              this.isComparisonGroupEnabledForAccount(comparisonGroup)
                ? 'selected'
                : ''
            }
            rows={comparisonGroups}
          />
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productResponses: state.multiResponses.get('updateProductOrganisation'),
  };
}

export default connect(mapStateToProps, {
  updateProductOrganisation,
})(EditAccountProductComparisonGroups);
