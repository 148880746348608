import { List } from 'immutable';
import { createSelector } from 'reselect';

import { USER_STATUS } from '^/models/user';
import { selectCollections } from '^/selectors';
import { selectSelectedUserStatus } from '^/selectors/items/users';
import { StatusType } from '../StatusPill';

export const selectActivitiesItems = createSelector(
  selectCollections,
  collections => collections.getIn(['activities', 'items'], List())
);

const USER_STATUS_PILL_TYPE_MAP = {
  [USER_STATUS.ACTIVE]: StatusType.Success,
  [USER_STATUS.INACTIVE]: StatusType.Danger,
  [USER_STATUS.INVITED]: StatusType.Warning,
  [USER_STATUS.UNINVITED]: StatusType.Info,
};

export const selectUserStatusPillType = createSelector(
  selectSelectedUserStatus,
  status => USER_STATUS_PILL_TYPE_MAP[status]
);
