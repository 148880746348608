import { default as i18next } from 'i18next';
import { reactI18nextModule } from 'react-i18next';
i18next.use(reactI18nextModule).init({
  languages: ['en', 'ar'],
  fallbackLng: 'en',
  debug: false,
  initImmediate: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: require('../../locale/en.json'),
    },
    ar: {
      translation: require('../../locale/ar.json'),
    },
  },
  react: {
    defaultTransParent: 'span',
  },
  keySeparator: false,
  nsSeparator: false,
});

export default i18next;
