import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import PureComponent from '^/components/PureComponent';
import { can, administerOrganisations } from '^/capabilities';
import { openCreditAddModal, openPurchaseCreditsModal } from '^/actions/modals';
import Alert, { AlertType } from '^/components/Alert';

export class AutoGenerateCreditWarning extends PureComponent {
  openAddCreditsModal(organisation) {
    if (can(this.props.user, administerOrganisations())) {
      this.props.openCreditAddModal(organisation);
    } else {
      this.props.openPurchaseCreditsModal(organisation.get('id'));
    }
  }

  render() {
    const { organisation, user, hidePurchaseLink } = this.props;
    const { name: organisationName, credit } = organisation.toObject();
    const isAdmin = can(user, administerOrganisations());
    const creditBalance = credit || 0;
    return (
      <Alert type={AlertType.Warning}>
        {isAdmin && (
          <Trans i18nKey="TRANS autogen alert admin">
            {{ organisationName }}'s credit balance is {{ creditBalance }}.
            <a onClick={() => this.openAddCreditsModal(organisation)}>
              Add more credits
            </a>
            to autogenerate the reports.
          </Trans>
        )}
        {!isAdmin &&
          (!hidePurchaseLink ? (
            <Trans i18nKey="TRANS autogen alert not admin">
              Your credit balance is {{ creditBalance }}.
              <a onClick={() => this.openAddCreditsModal(organisation)}>
                Buy more credits
              </a>
              to autogenerate the reports.
            </Trans>
          ) : (
            <Trans i18nKey="TRANS autogen alert not admin and no purchase link">
              Your credit balance is {{ creditBalance }}. You will need to buy
              more credits to autogenerate the reports. You can continue to
              create the activity and purchase credits from the next screen.
            </Trans>
          ))}
      </Alert>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, {
  openCreditAddModal,
  openPurchaseCreditsModal,
})(AutoGenerateCreditWarning);
