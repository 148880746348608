/* eslint-disable no-console */
import _ from 'underscore';

import { GET_COLLECTION } from '^/actions/collections';
import {
  GET_ANALYTICS_FILTERS,
  GET_ANALYTICS_FILTERS_HEATMAP,
} from '^/actions/analytics';
import { allFieldsMatch } from '^/utils';

export default store => next => action => {
  if ([GET_COLLECTION.SUCCESS, GET_COLLECTION.FAILURE].includes(action.type)) {
    const { meta } = action;
    const response = store
      .getState()
      .multiResponses.getIn(['getCollection', meta.collectionName]);
    if (
      response &&
      !allFieldsMatch(meta, response.toJS(), [
        'search',
        'filters',
        'ordering',
        'page',
      ])
    ) {
      console.warn(
        `Discarding response to request getCollection.${meta.collectionName}` +
          ` because params do not match those of currently pending response`
      );
      return;
    }
  }

  if (
    [GET_ANALYTICS_FILTERS.SUCCESS, GET_ANALYTICS_FILTERS.FAILURE].includes(
      action.type
    )
  ) {
    const {
      meta: { params },
    } = action;
    const response = store.getState().responses.get('getAnalyticsFilters');

    if (response && !_.isEqual(params, response.toJS().params)) {
      console.warn(
        `Discarding response to request getAnalyticsFilters` +
          ` because params do not match those of currently pending response`
      );
      return;
    }
  }
  if (
    [
      GET_ANALYTICS_FILTERS_HEATMAP.SUCCESS,
      GET_ANALYTICS_FILTERS_HEATMAP.FAILURE,
    ].includes(action.type)
  ) {
    const {
      meta: { params },
    } = action;
    const response = store
      .getState()
      .responses.get('getAnalyticsFiltersHeatmap');

    if (response && !_.isEqual(params, response.toJS().params)) {
      console.warn(
        `Discarding response to request getAnalyticsFiltersHeatmap` +
          ` because params do not match those of currently pending response`
      );
      return;
    }
  }

  return next(action);
};
