import { createStyles, SliderProps, WithStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import PureComponent from '^/components/PureComponent';

const DARK_BLUE = '#1b75bc';
const GREY = '#C4C4C4';
const WHITE = '#FFFFFF';
const TRACK_RAIL_HEIGHT = 4;
const THUMB_SIZE = 28;

const styles = () =>
  createStyles({
    root: {
      width: '85%',
      margin: '0 auto',
    },
    thumb: {
      height: THUMB_SIZE,
      width: THUMB_SIZE,
      backgroundColor: WHITE,
      border: `2px solid ${DARK_BLUE}`,
      marginTop: -12,
      marginLeft: -14,
      background:
        'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIwLjUiIHgyPSIwLjUiIHkyPSIxMCIgc3Ryb2tlPSIjQzRDNEM0Ii8+CjxsaW5lIHgxPSI0LjUiIHgyPSI0LjUiIHkyPSIxMCIgc3Ryb2tlPSIjQzRDNEM0Ii8+Cjwvc3ZnPgo=)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      '&:focus, &:hover': {
        boxShadow: 'inherit',
      },
    },
    rail: {
      background: GREY,
      height: TRACK_RAIL_HEIGHT,
    },
    track: {
      background: DARK_BLUE,
      height: TRACK_RAIL_HEIGHT,
    },
    valueLabel: {
      left: -4,
      '&>*': {
        background: DARK_BLUE,
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    mark: {
      width: 2,
      height: 0,
      marginTop: -7,
      background: GREY,
    },
    markActive: {
      background: DARK_BLUE,
    },
    markLabel: {
      marginTop: 15,
      maxWidth: 70,
      width: 'inherit',
      whiteSpace: 'normal',
      textAlign: 'center',
      color: DARK_BLUE,
      fontSize: '1.1rem',
    },
  });

type Props = WithStyles<typeof styles> & SliderProps;

class RangeSlider extends PureComponent<Props> {
  public render() {
    const { classes, ...rest } = this.props;

    return (
      <div className={classes.root}>
        <Slider
          classes={{
            rail: classes.rail,
            track: classes.track,
            valueLabel: classes.valueLabel,
            thumb: classes.thumb,
            mark: classes.mark,
            markActive: classes.markActive,
            markLabel: classes.markLabel,
          }}
          {...rest}
        />
      </div>
    );
  }
}

export default withStyles(styles)(RangeSlider);
