import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { loadOrganisation } from '^/actions/items';
import ControlBar from '^/components/ControlBar';
import Loading from '^/components/Loading';
import PureComponent from '^/components/PureComponent';
import { isAccount } from '^/models/organisation';
import { isPending } from '^/responseStates';
import { StoreState } from '^/store';
import EditAccount from './accounts/EditAccount';
import InfoIcon from './accounts/InfoIcon';

interface StateProps {
  organisation: Map<string, any>;
  response: Map<string, any>;
  user: Map<string, any>;
}

interface DispatchProps {
  loadOrganisation: typeof loadOrganisation;
}

type Props = StateProps & DispatchProps;

export class OrganisationViewPage extends PureComponent<Props> {
  public UNSAFE_componentWillMount() {
    this.props.loadOrganisation(this.props.user.getIn(['organisation', 'id']));
  }

  public render() {
    const { organisation, response } = this.props;

    const breadcrumb = (
      <span className="breadcrumbs">
        <span>{i18next.t<string>('My account')}</span>
      </span>
    );

    if (!organisation) {
      return <Loading />;
    }

    if (!isAccount(organisation)) {
      return (
        <h2>
          {i18next.t<string>(
            `Sorry this is an organisation not an account and can't be edited.`
          )}
        </h2>
      );
    }

    return (
      <div>
        <ControlBar
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
          title={
            <span>
              {organisation.get('name')}
              <InfoIcon below />
            </span>
          }
        />
        {isPending(response) && <Loading />}
        <EditAccount account={organisation} readOnly />
      </div>
    );
  }
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userProfile,
    response: state.responses.get('loadItem'),
    organisation: state.items.get('organisations'),
  };
}

export default connect(mapStateToProps, { loadOrganisation })(
  OrganisationViewPage
);
