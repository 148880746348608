import { Map, List, fromJS } from 'immutable';

import * as datafilters from '../actions/datafilters';
import composeReducers from './composeReducers';

function getEmptyFilterType(filterType) {
  return Map({
    filterType,
    searchTerm: '',
    results: List(),
    selectedResult: Map(),
    isAll: false,
  });
}

export function dataFiltersReducer(state = Map(), action) {
  switch (action.type) {
    case datafilters.SET_FILTER_ID: {
      return state.set(action.filterId, Map({}));
    }
    case datafilters.LOAD_ORGANISATION_FILTERS.REQUEST:
    case datafilters.LOAD_ACTIVITY_FILTERS.REQUEST:
    case datafilters.LOAD_USER_FILTERS.REQUEST:
    case datafilters.LOAD_PRODUCT_FILTERS.REQUEST: {
      const filterId = action.meta.get('filterId');
      const filterType = action.meta.get('filterType');

      if (!state.get(filterId, false)) {
        state = state.set(filterId, Map({}));
      }

      if (!state.getIn([filterId, filterType], false)) {
        state = state.setIn(
          [filterId, filterType],
          getEmptyFilterType(filterType)
        );
      }

      return state
        .setIn([filterId, filterType, 'results'], List())
        .setIn(
          [filterId, filterType, 'searchTerm'],
          action.meta.get('searchTerm', '')
        );
    }
    case datafilters.LOAD_ORGANISATION_FILTERS.SUCCESS:
    case datafilters.LOAD_ACTIVITY_FILTERS.SUCCESS:
    case datafilters.LOAD_USER_FILTERS.SUCCESS:
    case datafilters.LOAD_TEAM_FILTERS.SUCCESS:
    case datafilters.LOAD_PRODUCT_FILTERS.SUCCESS: {
      const filterId = action.meta.get('filterId');
      const filterType = action.meta.get('filterType');
      return state.setIn(
        [filterId, filterType, 'results'],
        fromJS(action.payload.results)
      );
    }
    case datafilters.SELECT_FILTER_ITEM: {
      const filterId = action.meta.get('filterId');
      const filterType = action.meta.get('filterType');
      return state
        .setIn(
          [filterId, filterType, 'selectedResult'],
          action.meta.get('selectedOption')
        )
        .setIn([filterId, filterType, 'isAll'], action.meta.get('isAll'));
    }
    case datafilters.PRESELECT_FILTER_ITEM: {
      const filterId = action.meta.get('filterId');
      const filterType = action.meta.get('filterType');
      return state
        .setIn([filterId, filterType], getEmptyFilterType(filterType))
        .setIn(
          [filterId, filterType, 'selectedResult'],
          Map({ value: action.meta.get('selectedValue') })
        );
    }
    case datafilters.CLEAR_FILTER_TYPE: {
      return state.setIn(
        [action.meta.get('filterId'), action.meta.get('filterType')],
        Map()
      );
    }
    default:
      return state;
  }
}

export default composeReducers([dataFiltersReducer], Map());
