import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '../PureComponent';
import { addCreditsAndCloseModal } from '../../actions/actionSequences';
import AddCreditsToOrganisationForm from './AddCreditsToOrganisationForm';
import { resetResponse } from '../../actions/actions';

export class AddCreditsToOrganisationModal extends PureComponent {
  setPendingUpload() {
    this.props.resetResponse('addCredits');
  }

  addCredits(data) {
    const { account, onPurchaseComplete } = this.props;
    this.props.addCreditsAndCloseModal(
      account.get('id'),
      data.amount,
      data.invoice,
      onPurchaseComplete
    );
  }

  render() {
    const { response, account } = this.props;
    return (
      <div>
        <AddCreditsToOrganisationForm
          response={response}
          onSubmit={this.addCredits.bind(this)}
          setPendingUpload={file => this.setPendingUpload(file)}
          account={account}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('addCredits'),
  };
}

export default connect(mapStateToProps, {
  addCreditsAndCloseModal,
  resetResponse,
})(AddCreditsToOrganisationModal);
