import { UserSession } from '^/components/reports/admin/selectors';
import {
  OrgActivity,
  Report,
  ReportTemplate,
  ReportTeam,
  Uuid,
} from '^/reducers/api/types';

export interface Selection {
  report?: Report;
  reportTemplate: ReportTemplate;
  productId: Uuid;
  productVariantId: Uuid | null;
  userId: Uuid;
  sessionId: Uuid;
  mine?: boolean;
  sharedWithMe?: boolean;
  reportTeamId?: Uuid;
  completed?: string | null;
}

export interface JobProfileSelection {
  report?: Report;
  reportTemplate: ReportTemplate;
  productId: Uuid;
  productVariantId: Uuid | null;
  userId: Uuid;
  sessionId: Uuid;
  mine?: boolean;
  sharedWithMe?: boolean;
  completed?: string | null;
  roleName: string;
}

export enum SelectionState {
  Selected,
  Unselected,
  Hidden,
  Unavailable,
  PermanentlyHidden,
}

export const isSelected = (selectionState: SelectionState): boolean =>
  selectionState === SelectionState.Selected;

export const notHidden = (selectionState: SelectionState): boolean =>
  selectionState !== SelectionState.Hidden &&
  selectionState !== SelectionState.PermanentlyHidden;

export const available = (selectionState: SelectionState): boolean =>
  selectionState !== SelectionState.Unavailable;

const untoggleable = (selectionState: SelectionState): boolean =>
  [
    SelectionState.Unavailable,
    SelectionState.Hidden,
    SelectionState.PermanentlyHidden,
  ].includes(selectionState);

export const toggleSelectionState = (
  currentState?: SelectionState
): SelectionState => {
  if (currentState && untoggleable(currentState)) {
    return currentState;
  }

  return currentState === SelectionState.Selected
    ? SelectionState.Unselected
    : SelectionState.Selected;
};

export const toggleHiddenSelectionState = (
  isVisible: boolean,
  currentState: SelectionState
): SelectionState => {
  if (isVisible && currentState === SelectionState.Hidden) {
    return SelectionState.Unselected;
  }
  if (untoggleable(currentState)) {
    return currentState;
  }
  if (!isVisible) {
    return SelectionState.Hidden;
  }
  return currentState;
};

export const setUnselectedState = (currentState: SelectionState) =>
  untoggleable(currentState) ? currentState : SelectionState.Unselected;

export const setSelectionState = (
  currentState: SelectionState,
  newState: SelectionState
): SelectionState => (untoggleable(currentState) ? currentState : newState);

export type ReportTemplatesByProductAndVariantId = Record<
  string,
  ReadonlyArray<ReportTemplate>
>;

export interface ReportsTableSelections {
  reports: { [key: string]: SelectionState };
  reportTemplates: { [key: string]: SelectionState };
  reportTemplatesByProductAndVariantId?: ReportTemplatesByProductAndVariantId;
  activities?: ReadonlyArray<OrgActivity>;
}

export type ReportsTableSelectionsKey = 'reports' | 'reportTemplates';

export function asKeys(
  selection: Selection
): [ReportsTableSelectionsKey, string] {
  return [
    selection.report ? 'reports' : 'reportTemplates',
    [
      selection.reportTemplate.id,
      selection.productId,
      selection.productVariantId || '',
      selection.userId,
      selection.sessionId,
      selection.reportTeamId || '',
    ]
      .concat(selection.report ? selection.report.id : [])
      .join('.'),
  ];
}

export function selectionFromKey(key: string) {
  const [
    reportTemplateId,
    productId,
    productVariantId,
    userId,
    sessionId,
    reportTeamId,
    reportId,
  ] = key.split('.');
  return {
    reportTemplateId,
    productId,
    productVariantId,
    userId,
    sessionId,
    reportTeamId,
    reportId,
  };
}

export function buildSelection(
  session: UserSession,
  reportTemplate: ReportTemplate,
  userId: Uuid
): Selection {
  return {
    report: session.reports.find(each => each.template === reportTemplate.id),
    reportTemplate,
    productId: session.product,
    productVariantId: session.productVariant,
    userId,
    sessionId: session.id,
    mine: session.mine,
    sharedWithMe: session.sharedWithMe,
    completed: session.completed,
  };
}

export function buildTeamSelection(reportTeam: ReportTeam): Selection {
  const { report_template } = reportTeam;
  return {
    report: reportTeam.reports[0],
    reportTemplate: {
      id: report_template.id,
      code: report_template.code_display,
      name: report_template.name,
      credit_cost: report_template.cost,
      lang_code: report_template.lang_code,
      requires_complete_session: false,
      requires_job_level: false,
      is_team: true,
      is_job_match: false,
      is_job_match_related: false,
    },
    productId: report_template.product_set[0],
    productVariantId: null,
    userId: 'USER_ID',
    sessionId: '',
    reportTeamId: reportTeam.id,
  };
}
