/* eslint-disable no-console */
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Checkbox from '^/components/Checkbox';
import DatePicker from '^/components/date/DatePicker';
import DateRangePicker from '^/components/date/DateRangePicker';
import Dropdown from '^/components/dropdown/Dropdown';
import { QuantityDropdown } from '^/components/dropdown/QuantityDropdown';
import FormError from '^/components/FormError';
import { TextArea } from '^/components/forms/TextArea';
import LiveButton from '^/components/LiveButton';
import OnOffToggle from '^/components/OnOffToggle';
import PageContent from '^/components/PageContent';
import PageHeader from '^/components/PageHeader';
import PureComponent from '^/components/PureComponent';
import RadioToggle from '^/components/RadioToggle';
import RangeSlider from '^/components/RangeSlider';
import SectionHeader from '^/components/SectionHeader';
import CodeSample from '^/components/styleguide/CodeSample';
import { makeChoices } from '^/utils-ts';
import FormItem from '../forms/FormItem';

const MULTISELECTS = [
  {
    id: '1',
    name: 'Select All',
    selected: false,
  },
  {
    id: '2',
    name: 'My Activities',
    selected: true,
  },
  {
    id: '3',
    name: 'Activities shared with me',
    selected: false,
  },
];

const ITEMS = [
  {
    id: '1',
    name: 'Option 1',
    selected: false,
  },
  {
    id: '2',
    name: 'Option 2',
    selected: false,
  },
  {
    id: '3',
    name: 'Option 3',
    selected: false,
  },
  {
    id: '4',
    name: 'Option 4',
    selected: true,
  },
  {
    id: '5',
    name: 'Option 5',
    selected: false,
  },
  {
    id: '6',
    name: 'Option 6',
    selected: false,
  },
  {
    id: '7',
    name: 'Option 7',
    selected: true,
  },
  {
    id: '8',
    name: 'Option 8',
    selected: false,
  },
  {
    id: '9',
    name: 'Option 9',
    selected: false,
  },
  {
    id: '10',
    name: 'Option 10',
    selected: false,
  },
];

export default class FormComponents extends PureComponent {
  public render() {
    return (
      <div>
        <PageHeader>Forms</PageHeader>
        <SectionHeader>TextAreas</SectionHeader>
        <div className="row">
          <div className="col-xs-6">
            <p>
              TextAreas are resizable and will automatically expand as the user
              types.
            </p>
            <div>
              <TextArea placeholder="This is some placeholder text within the text area" />
              <TextArea readOnly>This is a read-only text area</TextArea>
              <TextArea disabled>This is a disabled text area</TextArea>
            </div>
          </div>

          <div className="col-xs-6">
            <CodeSample
              code={`<TextArea placeholder="This is some placeholder text within the text area" />
<TextArea readOnly>This is a read-only text area</TextArea>
<TextArea disabled>This is a disabled text area</TextArea>`}
            />
          </div>
        </div>

        <SectionHeader>Date pickers</SectionHeader>

        <div className="row">
          <div className="form-block col-xs-6">
            <DatePicker
              showTime
              hideBorder
              value={new Date()}
              onSave={console.log}
            />
            <DatePicker hideIcon value={new Date()} onSave={console.log} />
            <DatePicker value={new Date()} onSave={console.log} />
            <DatePicker
              prefix="Prefix:"
              value={new Date()}
              onSave={console.log}
            />
            <DatePicker
              showTime
              prefix="Prefix:"
              value={new Date()}
              onSave={console.log}
            />
            <DatePicker isLoading value={new Date()} onSave={console.log} />
            <DatePicker value={new Date()} disabled onSave={console.log} />
            <DateRangePicker
              first={{ defaultValue: 'First', prefix: 'From:' }}
              second={{ defaultValue: 'Second', prefix: 'To:' }}
              onSave={console.log}
            />
          </div>
          <div className="col-xs-6">
            <CodeSample
              code={`<DatePicker
  showTime
  hideBorder
  value={new Date()}
  onSave={console.log}
/>
<DatePicker
  hideIcon
  value={new Date()}
  onSave={console.log}
/>
<DatePicker
  value={new Date()}
  onSave={console.log}
/>
<DatePicker
  prefix="Prefix:"
  value={new Date()}
  onSave={console.log}
/>
<DatePicker
  showTime
  prefix="Prefix:"
  value={new Date()}
  onSave={console.log}
/>
<DatePicker
  isLoading
  value={new Date()}
  onSave={console.log}
/>
<DatePicker
  value={new Date()}
  disabled
  onSave={console.log}
/>
/>`}
            />
          </div>
        </div>

        <SectionHeader>Dropdowns</SectionHeader>

        <div className="row">
          <div className="form-block">
            <div className="form-item">
              <Dropdown
                title="Activities"
                items={ITEMS}
                onClick={console.log}
                loading={false}
                failed={false}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Dropdown
                title="Activities"
                items={ITEMS}
                onClick={console.log}
                selectable
                loading={false}
                failed={false}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Dropdown
                title="MultiActivities"
                multiSelects={MULTISELECTS}
                items={ITEMS}
                onClick={console.log}
                loading={false}
                failed={false}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Dropdown
                title="MultiActivities"
                multiSelects={MULTISELECTS}
                items={ITEMS}
                onClick={console.log}
                selectable
                loading={false}
                failed={false}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Dropdown
                title="Disabled state"
                items={ITEMS}
                onClick={console.log}
                disabled
                loading={false}
                failed={false}
              />
            </div>
            <div className="form-item">
              <Dropdown
                title="Loading state"
                items={ITEMS}
                onClick={console.log}
                loading
                failed={false}
                disabled={false}
              />
            </div>
            <div className="form-item">
              <Dropdown
                title="Error state"
                items={ITEMS}
                onClick={console.log}
                loading={false}
                failed
                errorMessage={'Something went wrong'}
                disabled={false}
              />
            </div>
          </div>
        </div>

        <SectionHeader>Quantity dropdown</SectionHeader>

        <div className="row">
          <PageContent>
            <h3>Quantity dropdown</h3>

            <QuantityDropdown
              loading={false}
              failed={false}
              disabled={false}
              maxDropdownValue={10}
              minQuantity={5}
              maxQuantity={15}
              onChange={console.log}
              fieldName="quantity"
            />

            <h3>
              Quantity dropdown with maxQuantity less than maxDropdownValue
            </h3>

            <QuantityDropdown
              loading={false}
              failed={false}
              disabled={false}
              maxDropdownValue={10}
              minQuantity={0}
              maxQuantity={8}
              onChange={console.log}
              fieldName="quantity"
            />

            <h3>Quantity dropdown - loading</h3>

            <QuantityDropdown
              loading
              failed={false}
              disabled={false}
              maxDropdownValue={10}
              onChange={console.log}
              fieldName="quantityLoading"
            />

            <h3>Quantity dropdown - disabled</h3>

            <QuantityDropdown
              loading={false}
              failed={false}
              disabled
              maxDropdownValue={10}
              onChange={console.log}
              fieldName="quantityDisabled"
            />

            <h3>Quantity dropdown with unlimited option</h3>

            <QuantityDropdown
              loading={false}
              failed={false}
              disabled={false}
              maxDropdownValue={10}
              onChange={console.log}
              hasUnlimited
              fieldName="quantityWithUnlimited"
            />
            <h3>Quantity dropdown with error</h3>

            <QuantityDropdown
              loading={false}
              disabled={false}
              maxDropdownValue={10}
              onChange={console.log}
              failed
              error="Something went wrong."
              fieldName="quantityWithError"
            />
            <h3>
              Quantity dropdown with minQuantity greater than maxDropdownValue
            </h3>

            <QuantityDropdown
              loading={false}
              failed={false}
              disabled={false}
              maxDropdownValue={10}
              minQuantity={10}
              onChange={console.log}
              fieldName="minQuantitygreaterThanMaxDrop"
            />

            <h3>
              Quantity dropdown with initial value greater than maxDropdownValue
            </h3>

            <QuantityDropdown
              loading={false}
              failed={false}
              disabled={false}
              maxDropdownValue={10}
              value={11}
              onChange={console.log}
              fieldName="initialValueGreaterThanMaxDrop"
            />
          </PageContent>
        </div>

        <SectionHeader>Checkboxes</SectionHeader>
        <PageContent>
          <form className="form-inline">
            <FormItem label="Rock stars" stacked>
              <Checkbox id="second" onChange={console.log} label="Jimmy Page" />
              <Checkbox
                id="second"
                onChange={console.log}
                label="Robert Plant"
              />
              <Checkbox
                id="second"
                onChange={console.log}
                label="Mr. Rockman"
                disabled
              />
              <Checkbox
                checked
                id="second"
                onChange={console.log}
                label="John Bonham"
                disabled
              />
              <p className="text-error">This is an error</p>
            </FormItem>
          </form>
          <CodeSample
            code={`<FormItem label="Rock stars">
  <Checkbox
    id="second"
    onChange={console.log}
    label="Jimmy Page"
  />
  <Checkbox
    id="second"
    onChange={console.log}
    label="Robert Plant"
  />
  <Checkbox
    id="second"
    onChange={console.log}
    label="John Bonham"
  />
  <p className="text-error">This is an error</p>
</FormItem>`}
          />
        </PageContent>

        <SectionHeader>Checkboxes - inline</SectionHeader>
        <p>
          ...add the <strong>inline</strong> prop to the checkbox component.
        </p>
        <PageContent>
          <form className="form-inline">
            <FormItem label="Rock stars" wrapping>
              <Checkbox
                id="second"
                onChange={console.log}
                label="Jimmy Page"
                inline
              />
              <Checkbox
                id="second"
                onChange={console.log}
                label="Robert Plant"
                inline
              />
              <Checkbox
                id="second"
                onChange={console.log}
                label="John Bonham"
                inline
              />
              <p className="text-error">This is an error</p>
            </FormItem>
          </form>
          <CodeSample
            code={`<FormItem label="Rock stars" wrapping>
  <Checkbox
    id="second"
    onChange={console.log}
    label="Jimmy Page"
    inline
  />
  <Checkbox
    id="second"
    onChange={console.log}
    label="Robert Plant"
    inline
  />
  <Checkbox
    id="second"
    onChange={console.log}
    label="John Bonham"
    inline
  />
  <p className="text-error">This is an error</p>
</FormItem>`}
          />
        </PageContent>

        <PageContent>
          <form className="form-inline">
            <FormItem label="Best friends" wrapping>
              <RadioToggle
                choices={makeChoices([
                  ['CATS', 'Cats'],
                  ['DOGS', 'Dogs'],
                  ['SQUIRRELS', 'Squirrels'],
                ])}
                popoverMessages={{
                  CATS: 'Message one',
                  DOGS: 'Message two',
                  SQUIRRELS: 'Message three',
                }}
                selected="SQUIRRELS"
                loading={false}
                readOnly={false}
                disabledMessage="Disabled message"
                onChange={console.log}
              />
            </FormItem>
          </form>

          <CodeSample
            code={`<FormItem label="Best friends" wrapping>
  <RadioToggle
    choices={makeChoices([
      ['CATS', 'Cats'],
      ['DOGS', 'Dogs'],
      ['SQUIRRELS', 'Squirrels'],
    ])}
    popoverMessages={{
      CATS: 'Message one',
      DOGS: 'Message two',
      SQUIRRELS: 'Message three',
    }}
    selected='SQUIRRELS'
    loading={false}
    readOnly={false}
    disabledMessage='Disabled message'
    onChange={console.log}
  />
</FormItem>`}
          />
        </PageContent>

        <SectionHeader>Complex form example</SectionHeader>
        <PageContent>
          <form>
            <div>
              Admin Report Distribution
              <div>
                <Checkbox
                  id="all"
                  onChange={console.log}
                  label="Send all reports to me"
                  inline
                />
                <div className="inline margin-right">
                  <a href="">Edit email text</a>
                </div>
              </div>
            </div>
            <div className="mt-md mb-md">
              Additional Report Distribution
              <div>
                <label className="inline margin-right">
                  Send all reports to:
                  <input
                    type="text"
                    className="inline ml-md"
                    placeholder="Recipient email"
                  />
                </label>
                <div className="inline margin-right align-middle">
                  <button className="btn btn-icon-square btn-small margin-none btn-icon-square-secondary">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                <div className="inline">
                  <a href="">Edit email text</a>
                </div>
              </div>
            </div>
            <div>
              Recipient Report Distribution
              <div>
                <Checkbox
                  id="all"
                  onChange={console.log}
                  label="Send respondents their report(s)"
                  inline
                />
                <div className="inline margin-right">
                  <a href="">Edit email text</a>
                </div>
              </div>
            </div>
          </form>
        </PageContent>

        <SectionHeader>On/Off Toggle</SectionHeader>
        <div className="row">
          <div className="form-block col-xs-6">
            <OnOffToggle isLoading onChange={console.log} />
            <OnOffToggle value onChange={console.log} />
            <OnOffToggle disabled onChange={console.log} />
            <OnOffToggle
              // isOn
              secondary
              onChange={console.log}
            />
          </div>
          <div className="form-block col-xs-6">
            <CodeSample
              code={`<OnOffToggle isLoading onChange={console.log} />

<OnOffToggle value onChange={console.log} />

<OnOffToggle disabled onChange={console.log} />`}
            />
          </div>
        </div>

        <SectionHeader>Horizontal form</SectionHeader>

        <PageContent>
          <form className="form-horizontal">
            <label>
              Name <span className="help-inline">required</span>
              <input type="text" placeholder="Add activity name" />
            </label>
            <FormError response={null} formKey="name" />

            <label>
              Description
              <input type="text" placeholder="Add optional description" />
            </label>
            <FormError response={null} formKey="description" />

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <LiveButton
                  buttonText="Create activity"
                  pendingMessage="Creating activity..."
                  response={null}
                />
              </div>
            </div>
          </form>
          <CodeSample
            code={`<form className="form-horizontal" onSubmit={return false}>
    <label>
      Name <span className="help-inline">required</span>
    <input type="text" placeholder="Add activity name" {...name}/>
  </label>
  <FormError response={response} formKey="name"/>

  <label>
    Description
    <input type="text" placeholder="Add optional description" {...description}/>
  </label>
  <FormError response={response} formKey="description"/>

  <div className="modal-footer clearfix">
    <div className="pull-right">
      <LiveButton
        buttonText="Create activity"
        pendingMessage="Creating activity..."
        response={response}/>
    </div>
  </div>
  </form>`}
          />
        </PageContent>

        <SectionHeader>Range Sliders</SectionHeader>

        <PageContent>
          <div className="row">
            <div className="col-xs-6">
              <form className="form-block">
                <FormItem label="Value" wrapping>
                  <RangeSlider
                    value={[20, 80]}
                    step={10}
                    aria-labelledby="discrete-slider-always"
                    marks={Array.from({ length: 11 }, (_, i) => i).map(i => ({
                      label: i * 10,
                      value: i * 10,
                    }))}
                    valueLabelDisplay="on"
                    onChange={console.log}
                  />
                </FormItem>
              </form>
            </div>
          </div>
          <CodeSample
            code={`<FormItem label="Value" wrapping>
    <RangeSlider
      value={[20, 80]}
      step={10}
      aria-labelledby="discrete-slider-always"
      marks={Array.from({length: 11}, (_, i) => i).map(i => ({
        label: i * 10,
        value: i * 10
      }))}
      valueLabelDisplay="on"
      onChange={console.log}
    />
  </FormItem>`}
          />
        </PageContent>
      </div>
    );
  }
}
