import React from 'react'; // eslint-disable-line no-unused-vars
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons/faPlusSquare';
import { faBroadcastTower } from '@fortawesome/pro-light-svg-icons/faBroadcastTower';
import { faUserChart } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faArrowAltToBottom } from '@fortawesome/pro-light-svg-icons/faArrowAltToBottom';

import {
  can,
  seeEndUserDashboard,
  viewDataExports,
  shareAccess,
  seeOnlyOwnActivities,
  administerOrganisations,
  createAndListPulses,
  seeReportLibraryTile,
  seeDataAnalyticsTile,
} from '^/capabilities';
import { isPending } from '^/responseStates';
import PureComponent from '^/components/PureComponent';
import ContentTabset from '^/components/ContentTabset';
import ContentTab from '^/components/ContentTab';
import DashItem from './DashItem';
import ActivityCards from './ActivityCards';
import SharedAccess from './SharedAccess';
import AdminEnduserDashboardSwitcher from './AdminEnduserDashboardSwitcher';
import { dismissShareNotifications, getStats } from '^/actions/actions';
import { withRouter } from '^/withRouter';
import { showUsersAndImport, showUsersAndAdd } from '^/actions/actionSequences';
import Alert from '^/components/Alert';
import PulseIconCircleOutlinePurple from '^/components/pulses/PulseIconCircleOutlinePurple';

const STATS_TABS = [
  {
    key: 'recently_created',
    tabName: i18next.t('Recently created activities'),
    emptyMessage: i18next.t('You have not recently created any activities'),
  },
  {
    key: 'closing_soon',
    tabName: i18next.t('Activities closing soon'),
    emptyMessage: i18next.t(
      'There are no activities closing within the next 7 days'
    ),
  },
];

export class Dashboard extends PureComponent {
  componentDidMount() {
    this.props.getStats();
  }

  navigateTo(activityId) {
    this.props.push('/page/activities/' + activityId);
  }

  getFirstNameIfPresent(userName) {
    return userName.split(' ')[0];
  }

  render() {
    const { user, stats, statsResponse } = this.props;
    const isLoadingStats = !stats || isPending(statsResponse);

    const {
      sharing_notification_received: sharingNotifications,
      full_name: userName,
    } = user.toObject();
    const userHasNotifications = sharingNotifications.count() > 0;

    const sharerNames = sharingNotifications
      .map(notification =>
        notification.getIn(['shared_from_user', 'full_name'], '')
      )
      .join(', ');
    const sharerNotificationIds = sharingNotifications
      .map(notification => notification.get('id'))
      .toArray();

    const canSeeOthersActivities = !can(user, seeOnlyOwnActivities());
    const canListPulses = can(user, createAndListPulses());

    const welcomeMessage = userName
      ? i18next.t('Hi {{name}}, what would you like to do today?', {
          name: this.getFirstNameIfPresent(userName),
        })
      : i18next.t('Hi, what would you like to do today?');

    return (
      <div>
        <h2 className="mt-md pull-left inline">{welcomeMessage}</h2>

        {can(user, seeEndUserDashboard()) && (
          <AdminEnduserDashboardSwitcher currentPath={this.props.path} />
        )}

        {userHasNotifications && (
          <Alert>
            {sharerNames} has shared access with you
            <a
              className="fa-link dismiss-notification"
              role="button"
              aria-label="dismiss"
              title="dismiss"
              onClick={() =>
                this.props.dismissShareNotifications(sharerNotificationIds)
              }
            >
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </Alert>
        )}

        <hr className="padded" />

        <div className="dash-item-container">
          <DashItem
            title={
              canListPulses
                ? i18next.t('Pulse Check Hub')
                : i18next.t('Pulse Check Hub — coming soon')
            }
            icon={
              <PulseIconCircleOutlinePurple
                width="52"
                height="52"
                className="dash-icon pulse"
              />
            }
            strapline={i18next.t('Create and manage pulses')}
            to="/page/pulses/hub"
            disabled={!canListPulses}
          />

          <DashItem
            title={i18next.t('Activity Hub')}
            icon={
              <FontAwesomeIcon
                icon={faPlusSquare}
                size="5x"
                className="dash-icon dash-create"
              />
            }
            strapline={i18next.t('Create and manage activities')}
            to="/page/activities/hub"
          />

          <DashItem
            title={i18next.t('Live Activities')}
            icon={
              <FontAwesomeIcon
                icon={faBroadcastTower}
                size="5x"
                className="dash-icon dash-live"
              />
            }
            strapline={i18next.t('See all your open activities')}
            to="/page/activities/view/?status=LIVE"
          />
        </div>

        <div className="dash-item-container">
          {can(user, seeReportLibraryTile()) && (
            <DashItem
              horizontal
              title={i18next.t('Report library')}
              icon={
                <FontAwesomeIcon
                  icon={faFileAlt}
                  size="5x"
                  className="dash-icon dash-report"
                />
              }
              strapline={i18next.t('See, buy, distribute and download reports')}
              to="/page/reports"
            />
          )}

          {can(user, seeDataAnalyticsTile()) && (
            <DashItem
              horizontal
              title={i18next.t('Data analytics')}
              icon={
                <FontAwesomeIcon
                  icon={faUserChart}
                  size="5x"
                  className="dash-icon dash-data-analytics"
                />
              }
              strapline={i18next.t('Compare respondent results')}
              to="/page/data-analytics"
            />
          )}

          {can(user, viewDataExports()) && (
            <DashItem
              horizontal
              title={i18next.t('Data downloads')}
              icon={
                <FontAwesomeIcon
                  icon={faArrowAltToBottom}
                  size="5x"
                  className="dash-icon dash-data-exports"
                />
              }
              strapline={i18next.t('Download respondent results')}
              to="/page/data-downloads"
            />
          )}
        </div>

        <div className="recent-activities-section clearfix">
          <ContentTabset name="dash-tabs" defaultActiveTab={0}>
            {STATS_TABS.map(({ tabName, key, emptyMessage }, tabIdx) => (
              <ContentTab
                key={tabIdx}
                tabId={tabIdx}
                tabsetName="dash-tabs"
                tabName={tabName}
                className="tab-heading"
              >
                <ActivityCards
                  activities={isLoadingStats ? List(Array(4)) : stats.get(key)}
                  showCreator={canSeeOthersActivities}
                  showAccount={can(user, administerOrganisations())}
                  emptyMessage={emptyMessage}
                />
              </ContentTab>
            ))}
          </ContentTabset>
        </div>

        {can(user, shareAccess()) && <SharedAccess user={user} />}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.userProfile,
    stats: state.stats,
    statsResponse: state.responses.get('stats'),
    path: props.router.location.pathname,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getStats,
    push,
    showUsersAndImport,
    showUsersAndAdd,
    dismissShareNotifications,
  })(Dashboard)
);
