import { List, Map } from 'immutable';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { answerQuestionOption } from '^/actions/actions';
import {
  goToMapProductVersionPage,
  markMAPCompleteAndFinish,
  setMAPProductVersionQuestionIndexWithEvent,
} from '^/actions/actionSequences';
import { closeTopModal, openProgressModal } from '^/actions/modals';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';
import { getMAPTextByKey, getQuestionIdFromIndex } from '^/productVersions';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import MAPBottomNav from './MAPBottomNav';
import MAPQuestion from './MAPQuestion';
import MAPQuestionFeedback from './MAPQuestionFeedback';
import { hasAllAnswers, isPractice } from './questionWithAnswer';

export enum MAP_PAGE {
  FEEDBACK = 'FEEDBACK',
  START_CONFIRMATION = 'START_CONFIRMATION',
}

interface StateProps {
  anyLoading: boolean;
  submitting: boolean;
  responses: Map<string, Map<string, any>>;
  questionIdx: number;
  elapsedMillis: number;
  uiLanguage: string;
}

interface DispatchProps {
  goToMapProductVersionPage: typeof goToMapProductVersionPage;
  answerQuestionOption: typeof answerQuestionOption;
  closeTopModal: typeof closeTopModal;
  openProgressModal: typeof openProgressModal;
  markMAPCompleteAndFinish: typeof markMAPCompleteAndFinish;
  setMAPProductVersionQuestionIndexWithEvent: typeof setMAPProductVersionQuestionIndexWithEvent;
}

interface OwnProps {
  page: MAP_PAGE | number;
  questionCollectionIdx: number;
  questionsWithAnswers: List<List<Map<string, any>>>;
  timerRunning: boolean;
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  initialRemainingMinutes: number;
}

type Props = StateProps & DispatchProps & OwnProps;

export class MAPBodyAndBottomNav extends PureComponent<Props> {
  public render() {
    const {
      page,
      questionIdx,
      questionsWithAnswers,
      timerRunning,
      responses,
      anyLoading,
      submitting,
      productVersion,
    } = this.props;
    const realQuestionIndex =
      questionIdx - questionsWithAnswers.filter(isPractice).size;
    const isNotFirstRealQuestion = realQuestionIndex !== 0;
    const hasPrev = questionIdx > 0;
    const hasNext = questionIdx < questionsWithAnswers.size - 1;

    const canGoBack = hasPrev && isNotFirstRealQuestion;

    const questionWithAnswer = questionsWithAnswers.get(questionIdx);
    const allPracticeDone = questionsWithAnswers
      .filter(isPractice)
      .every(hasAllAnswers);
    const showStartConfirmation = allPracticeDone && !timerRunning;

    const isPsycapPotential =
      productVersion.getIn(['product', 'type']) ===
      PRODUCT_TYPES.CHOICES.PSYCAP_POTENTIAL;

    if (page === MAP_PAGE.FEEDBACK) {
      return (
        <span>
          <MAPQuestionFeedback
            questionWithAnswer={questionWithAnswer}
            isPsycapPotential={isPsycapPotential}
            questionIdx={questionIdx}
          />

          <MAPBottomNav
            navigateToPrevQuestion={this.hideInfo}
            navigateToNextQuestion={
              hasNext &&
              (showStartConfirmation
                ? this.showStartConfirmation
                : this.navigateToNextQuestion)
            }
            completeTest={!hasNext && this.confirmAndFinish}
            loading={anyLoading}
            submitting={submitting}
            startAssessment={false}
          />
        </span>
      );
    }

    return (
      <span>
        <MAPQuestion
          questionWithAnswer={questionWithAnswer}
          questionIdx={questionIdx}
          answerQuestion={this.answerQuestion}
          responses={responses}
          disabled={submitting}
          isPsycapPotential={isPsycapPotential}
        />

        <MAPBottomNav
          navigateToPrevQuestion={
            canGoBack ? this.navigateToPrevQuestion : false
          }
          navigateToNextQuestion={
            isPractice(questionWithAnswer)
              ? hasAllAnswers(questionWithAnswer) && (() => this.showFeedback())
              : hasNext && this.navigateToNextQuestion
          }
          completeTest={!hasNext && (() => this.confirmAndFinish())}
          loading={anyLoading}
          submitting={submitting}
          startAssessment={false}
        />
      </span>
    );
  }

  private confirmAndFinish = () => {
    const {
      activity,
      productVersion,
      initialRemainingMinutes,
      elapsedMillis,
    } = this.props;
    const completionText = getMAPTextByKey(productVersion, 'completion_text');
    const initialRemainingMillis = initialRemainingMinutes * 60 * 1000;
    const isPsycapPotential =
      productVersion.getIn(['product', 'type']) ===
      PRODUCT_TYPES.CHOICES.PSYCAP_POTENTIAL;

    this.props.openProgressModal(
      isPsycapPotential,
      activity.get('is_staged'),
      moment(initialRemainingMillis - elapsedMillis).format('mm:ss'),
      completionText,
      () => this.props.closeTopModal(),
      () => {
        this.props.closeTopModal();
        this.props.markMAPCompleteAndFinish(
          activity,
          productVersion,
          this.props.questionCollectionIdx,
          this.props.uiLanguage
        );
      }
    );
  };

  private hideInfo = () => {
    this.props.goToMapProductVersionPage(null);
  };

  private showFeedback = () => {
    this.props.goToMapProductVersionPage(MAP_PAGE.FEEDBACK);
  };

  private showStartConfirmation = () => {
    this.props.goToMapProductVersionPage(MAP_PAGE.START_CONFIRMATION);
  };

  private answerQuestion = (answer: string, questionOptionId: string) => {
    const { activity, productVersion } = this.props;
    this.props.answerQuestionOption(
      activity.get('id'),
      productVersion.get('id'),
      questionOptionId,
      answer
    );
  };

  private navigateToPrevQuestion = () => {
    this.navigateToQuestionIndex(this.props.questionIdx - 1);
  };

  private navigateToNextQuestion = () => {
    this.hideInfo();
    this.navigateToQuestionIndex(this.props.questionIdx + 1);
  };

  private navigateToQuestionIndex = (index: number) => {
    const newQuestionId = getQuestionIdFromIndex(
      this.props.questionsWithAnswers,
      index
    );
    const { activity, productVersion } = this.props;
    this.props.setMAPProductVersionQuestionIndexWithEvent(
      activity.get('id'),
      productVersion.get('id'),
      newQuestionId,
      index
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  responses: state.multiResponses.get('answerQuestion'),
  anyLoading: anyPending([
    state.responses.get('answerQuestion'),
    state.responses.get('createActivityProductVersionSessionEvent'),
    state.responses.get('updateActivityProductVersionSession'),
  ]),
  submitting: anyPending([
    state.responses.get('updateActivityProductVersionSession'),
    state.responses.get('getCollection'),
  ]),
  questionIdx: state.ui.get('mapProductVersionQuestionIndex'),
  elapsedMillis: state.timer.get('elapsed'),
  uiLanguage: state.ui.get('uiLanguage'),
});

export default connect(mapStateToProps, {
  goToMapProductVersionPage,
  answerQuestionOption,
  closeTopModal,
  openProgressModal,
  setMAPProductVersionQuestionIndexWithEvent,
  markMAPCompleteAndFinish,
})(MAPBodyAndBottomNav);
