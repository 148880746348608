import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { Trans } from 'react-i18next';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import {
  clearSimpleComponentState,
  setSimpleComponentState,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import Button from '^/components/buttons/Button';
import MailTo from '^/components/MailTo';
import CloseModalButton from '^/components/modals/CloseModalButton';
import { ShopItemOptionType, Uuid } from '^/reducers/api/types';
import { ProductOrganisation } from '^/reducers/api/types/organisation';
import { selectUserProfile } from '^/selectors';
import { ENABLE_ORDERS_EMAIL } from '^/settings';
import { StoreState } from '^/store';
import { pluralize } from '^/utils';
import { joinWithAnd, sum } from '^/utils-ts';
import {
  getBasketItemsData,
  selectProductOrganisationsToCreate,
} from './basket/selectors';
import { BasketItem, BasketItemData } from './basket/types';
import { selectShopItems } from './selectors';
import ShopItemPurchaseProductOrganisationChooser from './ShopItemPurchaseProductOrganisationChooser';

interface StateProps {
  user: Map<string, any>;
  basketItemsData: ReadonlyArray<BasketItemData> | null;
  selectedProductOrganisation: Uuid | undefined;
  productOrganisationsToCreate: ProductOrganisation[];
}

interface OwnProps {
  purchasedBasketItems: ReadonlyArray<BasketItem>;
}

interface DispatchProps {
  setSimpleComponentState: typeof setSimpleComponentState;
  clearSimpleComponentState: typeof clearSimpleComponentState;
  push: typeof push;
  closeTopModal: typeof closeTopModal;
}

type Props = OwnProps & StateProps & DispatchProps;

export const ShopItemPurchaseConfirmationMessages: React.FunctionComponent<{
  basketItemsData: ReadonlyArray<BasketItemData>;
  user: Map<string, any>;
}> = ({ basketItemsData, user }) => {
  const accreditationBasketItems = basketItemsData.filter(
    each => each.shopItemOption.type === ShopItemOptionType.Accreditation
  );

  const selfVerifyBasketItems = basketItemsData.filter(
    each => each.shopItemOption.type === ShopItemOptionType.SelfVerify
  );

  const feedbackSessionBasketItems = basketItemsData.filter(
    each => each.shopItemOption.type === ShopItemOptionType.FeedbackSession
  );

  const productBasketItems = basketItemsData.filter(
    each =>
      each.shopItemOption.type === ShopItemOptionType.Product &&
      each.shopItemOption.product_variant
  );

  const totalCreditCost = sum(
    basketItemsData.map(each => each.basketItem.total_credit_cost)
  );

  const productBasketItemsText = joinWithAnd(
    productBasketItems.map(
      each => `${each.basketItem.quantity} ${each.shopItemOption.label}`
    )
  );
  const totalProductsPurchased = sum(
    productBasketItems.map(each => each.basketItem.quantity)
  );

  return (
    <div>
      {accreditationBasketItems.length > 0 && (
        <p>
          {i18next.t<string>(
            'You will receive an email from PeopleWise within 2 working days detailing the e-learning accreditation programme.'
          )}
        </p>
      )}
      {selfVerifyBasketItems.length > 0 && (
        <p>
          <Trans i18nKey="TRANS As you have purchased the self verify option">
            Please send your credentials to{' '}
            <MailTo
              email="enable@peoplewise.co.uk"
              subject="Credentials provided"
              body={`Dear PeopleWise accounts team,\n\nPlease find attached my credentials.\n\nI look forward to hearing from you,\n\n${user.get(
                'full_name'
              )}, ${user.getIn(['organisation', 'name'])}`}
            >
              enable@peoplewise.co.uk
            </MailTo>
            . You will then receive an email from PeopleWise within 2 working
            days confirming your credentials and detailing access to the
            psychometric.
          </Trans>
        </p>
      )}
      {feedbackSessionBasketItems.length > 0 && (
        <p>
          <Trans i18nKey="TRANS Please contact with details">
            Please contact{' '}
            <MailTo email={ENABLE_ORDERS_EMAIL} subject="Feedback Sessions">
              {ENABLE_ORDERS_EMAIL}
            </MailTo>{' '}
            with details of the people needing feedback.
          </Trans>
        </p>
      )}
      {productBasketItems.length > 0 && (
        <p>
          You now have access to {productBasketItemsText}{' '}
          {pluralize(totalProductsPurchased, 'product', 'products', false)}
        </p>
      )}
      {totalCreditCost > 0 && (
        <p>
          {i18next.t<string>(
            '{{count}} credits have been added to your account for your product purchase',
            { count: totalCreditCost }
          )}
        </p>
      )}
    </div>
  );
};

export const SIMPLE_COMPONENT_STATE_KEY = [
  'ShopItemPurchaseConfirmationModal',
  'selectedProductOrganisation',
];

export class ShopItemPurchaseConfirmationModal extends React.Component<Props> {
  public componentWillUnmount() {
    this.props.clearSimpleComponentState(SIMPLE_COMPONENT_STATE_KEY);
  }

  public componentDidMount() {
    this.checkProductOrganisationSelection(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.checkProductOrganisationSelection(nextProps);
  }

  public render() {
    const {
      basketItemsData,
      user,
      selectedProductOrganisation,
      productOrganisationsToCreate,
    } = this.props;
    const organisation = user.get('organisation');
    const canCreateActivity = !!productOrganisationsToCreate.length;
    return (
      <div>
        <p>{i18next.t<string>('Thank you for your purchase.')}</p>

        {basketItemsData && (
          <ShopItemPurchaseConfirmationMessages
            basketItemsData={basketItemsData}
            user={user}
          />
        )}

        {basketItemsData && organisation && canCreateActivity && (
          <ShopItemPurchaseProductOrganisationChooser
            productOrganisations={productOrganisationsToCreate}
            selectedProductOrganisation={selectedProductOrganisation}
            onChange={this.selectProductOrganisation}
          />
        )}

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <CloseModalButton>{i18next.t<string>('Close')}</CloseModalButton>
            {canCreateActivity && (
              <Button
                buttonType="primary"
                onClick={this.createActivity}
                disabled={!selectedProductOrganisation}
              >
                {i18next.t<string>('Create activity')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }

  private selectProductOrganisation = (id: Uuid) => {
    this.props.setSimpleComponentState(SIMPLE_COMPONENT_STATE_KEY, id);
  };

  private checkProductOrganisationSelection(props: Props) {
    const { productOrganisationsToCreate, selectedProductOrganisation } = props;
    if (
      !selectedProductOrganisation &&
      productOrganisationsToCreate.length === 1
    ) {
      this.selectProductOrganisation(productOrganisationsToCreate[0].id);
    }
  }

  private createActivity = () => {
    const { selectedProductOrganisation } = this.props;
    this.props.push({
      pathname: '/page/activities/create',
      query: {
        product_organisation: selectedProductOrganisation,
      },
    });
    this.props.closeTopModal();
  };
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StateProps {
  const basketItemsData = getBasketItemsData(
    selectShopItems(state),
    ownProps.purchasedBasketItems
  );
  return {
    user: selectUserProfile(state),
    basketItemsData,
    productOrganisationsToCreate: selectProductOrganisationsToCreate(
      state,
      basketItemsData
    ),
    selectedProductOrganisation: state.ui.getIn(
      ['simpleComponentState'].concat(SIMPLE_COMPONENT_STATE_KEY)
    ),
  };
}

export default connect(mapStateToProps, {
  setSimpleComponentState,
  clearSimpleComponentState,
  push,
  closeTopModal,
})(ShopItemPurchaseConfirmationModal);
