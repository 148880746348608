import classNames from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  noBorder?: boolean;
}

export const ContainerRectangleTile: React.FC<Props> = ({
  children,
  noBorder,
}) => {
  return (
    <div className={classNames('container-tile', { 'border-none': noBorder })}>
      {children}
    </div>
  );
};
