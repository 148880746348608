import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { updateProfileOnModal } from '^/actions/actionSequences';
import ProfileFormWrapper from '^/components/profile/ProfileFormWrapper';

export class ProfileModal extends PureComponent {
  onSubmit(data) {
    this.props.updateProfileOnModal(this.props.user.get('id'), data);
  }

  render() {
    return (
      <div>
        <p className="lead">
          {i18next.t(
            'Please complete your personal details (below) in order to proceed.'
          )}
        </p>
        <ProfileFormWrapper
          onSubmit={this.onSubmit.bind(this)}
          response={this.props.response}
          showTCs
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateProfile'),
  };
}

export default connect(mapStateToProps, {
  updateProfileOnModal,
})(ProfileModal);
