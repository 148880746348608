import i18next from 'i18next';

import { makeChoices } from '^/utils-ts';

export const USER_STATUS = {
  UNINVITED: 'UNINVITED',
  INVITED: 'INVITED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const USER_ROLES = {
  EXTERNAL: 'EXTERNAL',
  ENDUSER: 'ENDUSER',
  ORGADMIN: 'ORGADMIN',
  ADMIN: 'ADMIN',
  ACCOUNT_SUPERUSER: 'ACCOUNT_SUPERUSER',
  ACCOUNT_ORG_ADMIN: 'ACCOUNT_ORG_ADMIN',
  ACCOUNT_PROFESSIONAL_PRACTITIONER: 'ACCOUNT_PROFESSIONAL_PRACTITIONER',
};

export const SURVEY_STATUS = makeChoices([
  ['NOT_STARTED', i18next.t<string>('Not started')],
  ['PARTIALLY_COMPLETED', i18next.t<string>('Partially completed')],
  ['COMPLETED', i18next.t<string>('Completed')],
  ['OPT_OUT', i18next.t<string>('Opt out')],
  [
    'PARTIALLY_COMPLETED_STAGE_1',
    i18next.t<string>('Partially completed stage 1'),
  ],
  ['OPT_OUT_STAGE_1', i18next.t<string>('Opted out of stage 1')],
  ['COMPLETED_STAGE_1', i18next.t<string>('Completed stage 1')],
  ['INVITED_TO_STAGE_2', i18next.t<string>('Invited to stage 2')],
  [
    'PARTIALLY_COMPLETED_STAGE_2',
    i18next.t<string>('Partially completed stage 2'),
  ],
  ['COMPLETED_STAGE_2', i18next.t<string>('Completed stage 2')],
]);

export const COMPLETED_SURVEY_STATUSES = [
  SURVEY_STATUS.CHOICES.COMPLETED,

  SURVEY_STATUS.CHOICES.COMPLETED_STAGE_1,
  SURVEY_STATUS.CHOICES.INVITED_TO_STAGE_2,
  SURVEY_STATUS.CHOICES.PARTIALLY_COMPLETED_STAGE_2,
  SURVEY_STATUS.CHOICES.COMPLETED_STAGE_2,
];

export function forDisplay(status: string) {
  return status === USER_STATUS.UNINVITED ? 'TO BE INVITED' : status;
}
