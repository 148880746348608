import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';

export default class Introduction extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Introduction</PageHeader>
        <PageContent>
          {/* eslint-disable max-len */}
          <p>
            This is the Peoplewise front-end style guide. It's designed to be a
            living resource of reusable patterns and snippets of code.
          </p>
          <p>
            If something is missing or not clear, let{' '}
            <a href="mailto:design@dabapps.com">design@dabapps.com</a> know.
          </p>
          {/* eslint-enable max-len */}
        </PageContent>
      </div>
    );
  }
}
