import { Map } from 'immutable';
import { createSelector } from 'reselect';

import { assignNonAccreditedProducts, can } from '^/capabilities';
import { holdsAccreditation } from '^/models/product';
import {
  selectUserProfile,
  selectUuidProp,
  selectUuidsProp,
} from '^/selectors';
import {
  selectCollectionsOrganisationOptionsItems,
  selectSimpleOrganisation,
} from '^/selectors/collections';
import { selectSelectedGroup } from '^/selectors/items';
import { selectUserOrganisation } from '^/selectors/user';
import { StoreState } from '^/store';

export const selectSelectedOrganisation = createSelector(
  selectUuidProp,
  selectCollectionsOrganisationOptionsItems,
  selectSelectedGroup,
  selectUserOrganisation,
  selectSimpleOrganisation,
  (
    groupId,
    organisations,
    selectedGroup,
    userOrganisation,
    organisationDetail
  ) => {
    if (groupId && selectedGroup) {
      const foundOrganisation = organisations.find(
        (organisation: Map<string, any>) =>
          organisation.get('id') === selectedGroup.get('organisation')
      );
      if (foundOrganisation) {
        return foundOrganisation;
      }
    }
    return userOrganisation || organisationDetail || Map();
  }
);

export const selectDefaultActivityType = createSelector(
  selectUuidsProp,
  selectSelectedOrganisation,
  (defaultProductOrganisations, selectedOrganisation) => {
    if (!defaultProductOrganisations.length || selectedOrganisation.isEmpty()) {
      return null;
    }
    const productOrganisation = selectedOrganisation
      .get('productorganisation_set')
      .find((each: Map<string, any>) =>
        defaultProductOrganisations?.includes(each.get('id'))
      );
    if (!productOrganisation) {
      return null;
    }
    return productOrganisation.get('activity_type', null);
  }
);

export const selectAccreditationLostProducts = createSelector(
  (_: StoreState, activity: Map<string, any>) => activity,
  selectUserProfile,
  (activity, user) => {
    if (
      can(user, assignNonAccreditedProducts()) ||
      can(activity.get('created_by_user'), assignNonAccreditedProducts())
    ) {
      return [];
    }
    return activity
      .get('activity_product_version_items')
      .filter((apv: Map<string, any>) => apv.get('product_variant') === null)
      .map((apv: Map<string, any>) => apv.getIn(['product_version', 'product']))
      .filter((product: Map<string, any>) =>
        product.get('requires_accreditation')
      )
      .filterNot((product: Map<string, any>) =>
        holdsAccreditation(user.get('organisation'), product.get('id'))
      )
      .toArray();
  }
);
