import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ReactTooltip from 'react-tooltip';
import { isEqual } from 'underscore';

import { setFilter, downloadBasketItemsCsv } from '^/actions/actions';
import {
  FETCH_ALL,
  getAllOrganisationOptions,
  getPurchasedBasketItems,
} from '^/actions/collections';
import { administerOrganisations } from '^/capabilities';
import { FilterData } from '^/components/Filter';
import { selectCollection, selectOrderingObject } from '^/selectors';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';
import { fromQueryString, toQueryString } from '^/utils';
import ListPage, { ListHeader, Ordering } from '../../ListPage';
import PurchaseItem from './PurchaseItem';
import {
  selectBasketItemsFilters,
  selectPurchasedBasketItemsHeaders,
} from './selectors';
import TokenStore from '^/TokenStore';

interface DispatchProps {
  getPurchasedBasketItems: typeof getPurchasedBasketItems;
  getAllOrganisationOptions: typeof getAllOrganisationOptions;
  setFilter: typeof setFilter;
  push: typeof push;
}

interface StateProps {
  response: Map<string, any>;
  purchasedBasketItems: List<Map<string, any>>;
  ordering: Ordering | undefined;
  collection: Map<string, any>;
  shouldShowOrganisations: boolean;
  filters: ReadonlyArray<FilterData>;
  headers: ReadonlyArray<ListHeader>;
  filterSpec: Record<string, string>;
  token: string;
}

interface OwnProps {
  queryParams: {
    filter: string;
  };
}

type Props = DispatchProps & StateProps & OwnProps;

export class PurchasedBasketItems extends React.PureComponent<Props> {
  public componentDidMount() {
    const { collection, filterSpec } = this.props;

    this.props.getPurchasedBasketItems(
      collection.get('searchString'),
      1,
      filterSpec,
      FETCH_ALL as any,
      collection.get('ordering')
    );
  }

  public componentDidUpdate(prevProps: Props) {
    const { collection, filterSpec } = this.props;

    if (!isEqual(prevProps.filterSpec, filterSpec)) {
      this.props.getPurchasedBasketItems(
        collection.get('searchString'),
        1,
        filterSpec,
        FETCH_ALL as any,
        collection.get('ordering')
      );
    }
  }

  getInnerBoxHeaders() {
    const { token, collection, filterSpec } = this.props;
    return (
      <>
        <div className="table-inner-box-header right">
          <ReactTooltip
            id="download-shop-payments-csv-button"
            backgroundColor="white"
            border
            borderColor="#C9CCD6"
            clickable
            insecure
            resizeHide
            textColor="black"
            wrapper="div"
          >
            {i18next.t<string>('Download shop payments as CSV')}
          </ReactTooltip>
          <button
            className="btn btn-small btn-icon-square btn-icon-square-secondary"
            onClick={() =>
              downloadBasketItemsCsv(
                token,
                filterSpec,
                collection.get('ordering')
              )
            }
            data-for="download-shop-payments-csv-button"
            data-tip
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </>
    );
  }
  public render() {
    const {
      response,
      purchasedBasketItems,
      ordering,
      filters,
      headers,
    } = this.props;

    return (
      <ListPage<Map<string, any>>
        headers={headers}
        tableClassName={'table-default'}
        filters={filters}
        innerBoxHeaders={this.getInnerBoxHeaders()}
        renderer={this.renderer}
        onFilterChange={this.onSetFilter}
        onOrderingChange={this.onSetOrdering}
        ordering={ordering}
        response={response}
        items={purchasedBasketItems}
        typeNamePlural={i18next.t<string>('Purchases')}
      />
    );
  }

  private renderer = (item: Map<string, any>) => {
    return (
      <PurchaseItem
        key={item.get('id') as string}
        basketItem={item}
        showOrganisations={this.props.shouldShowOrganisations}
      />
    );
  };

  private onSetFilter = (key: string, value: string | null) => {
    const newFilterSpec = Object.assign({}, this.props.filterSpec, {
      [key]: value,
    });
    this.props.push('/page/purchases/' + toQueryString(newFilterSpec));
  };

  private onSetOrdering = (ordering: string | undefined) => {
    const { collection } = this.props;

    const reverseOrdering = ordering === collection.get('ordering', '');
    this.props.getPurchasedBasketItems(
      collection.get('searchString'),
      1,
      collection.get('filters'),
      FETCH_ALL as any,
      reverseOrdering ? `-${ordering}` : ordering
    );
  };
}

function mapStateToProps(state: StoreState, props: OwnProps): StateProps {
  const filterSpec = fromQueryString(props.queryParams.filter);
  const token = TokenStore.get();
  return {
    purchasedBasketItems: state.collections.getIn(
      ['purchasedBasketItems', 'items'],
      List()
    ),
    response: state.responses.get('getCollection'),
    ordering: selectOrderingObject(state, 'purchasedBasketItems'),
    collection: selectCollection(state, 'purchasedBasketItems'),
    shouldShowOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
    filters: selectBasketItemsFilters(state, filterSpec),
    headers: selectPurchasedBasketItemsHeaders(state),
    filterSpec,
    token,
  };
}

export default connect(mapStateToProps, {
  push,
  setFilter,
  getAllOrganisationOptions,
  getPurchasedBasketItems,
})(PurchasedBasketItems);
