import i18next from 'i18next';
import React from 'react';

import RadioButton from '^/components/RadioButton';
import { Uuid } from '^/reducers/api/types';
import { ProductOrganisation } from '^/reducers/api/types/organisation';

interface Props {
  productOrganisations: ProductOrganisation[];
  onChange: (id: Uuid) => void;
  selectedProductOrganisation: Uuid | undefined;
}

const ActivityToCreateChoice: React.FunctionComponent<{
  onChange: (id: Uuid) => void;
  selectedProductOrganisation: Uuid | undefined;
  productOrganisation: ProductOrganisation;
}> = ({ onChange, selectedProductOrganisation, productOrganisation }) => {
  return (
    <RadioButton
      name={productOrganisation.id}
      label={productOrganisation.display_name}
      id={productOrganisation.id}
      onChange={onChange.bind(null, productOrganisation.id)}
      checked={productOrganisation.id === selectedProductOrganisation}
    />
  );
};

export const ShopItemPurchaseProductOrganisationChooser: React.FunctionComponent<Props> = props => {
  if (props.productOrganisations.length === 1) {
    return (
      <p>{i18next.t<string>('Would you like to create an activity now?')}</p>
    );
  }
  return (
    <div>
      <p>
        {i18next.t<string>(
          'Would you like to create an activity with one of them now?'
        )}
      </p>
      {props.productOrganisations.map(productOrganisation => (
        <ActivityToCreateChoice
          key={productOrganisation.id}
          productOrganisation={productOrganisation}
          onChange={props.onChange}
          selectedProductOrganisation={props.selectedProductOrganisation}
        />
      ))}
    </div>
  );
};

export default ShopItemPurchaseProductOrganisationChooser;
