import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from './PureComponent';
import Alert from '^/components/Alert';

export class RefreshPage extends PureComponent {
  componentDidMount() {
    this.props.push('/page/home');
  }

  render() {
    return <Alert> {i18next.t('Reloading, please wait...')} </Alert>;
  }
}

export default connect(null, { push })(RefreshPage);
