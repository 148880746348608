import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from './PureComponent';

export default class Footer extends PureComponent {
  render() {
    const { langCode } = this.props;
    const currentYear = new Date().getFullYear();
    return (
      <div className="footer">
        <ul>
          <li>
            <a href={`/#/${langCode}/terms-and-conditions`} target="_blank">
              {i18next.t('Terms & Conditions')}
            </a>
          </li>
          <li>
            <a href={`/#/${langCode}/data-privacy-policy`} target="_blank">
              {i18next.t('Data Privacy Policy')}
            </a>
          </li>
          <li>
            <a href="#/page/contact" target="_blank">
              {i18next.t('Contact')}
            </a>
          </li>
        </ul>
        <span className="copyright">
          {i18next.t(
            'Copyright © 2016 - {{currentYear}}. PeopleWise. All Rights Reserved.',
            { currentYear }
          )}
        </span>
      </div>
    );
  }
}
