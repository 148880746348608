import React from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';
import i18next from 'i18next';
import { connect } from 'react-redux';

import PureComponent from './PureComponent';

export class PeoplewiseLogo extends PureComponent {
  getClassName() {
    return classNames(
      'enable-logo',
      this.props.onDark && 'enable-logo-white',
      this.props.className
    );
  }

  render() {
    return (
      <h1
        className={this.getClassName()}
        title={i18next.t('peoplewise - enabling change')}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    activeLanguage: state.ui.get('activeLanguage'), // triggers re-rendering when the language changes
  };
}

export default connect(mapStateToProps)(PeoplewiseLogo);
