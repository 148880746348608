import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { UserPulseDetail, Uuid } from '^/reducers/api/types';
import { SelectedRater, DeletedRater } from './types';
import { openConfirmRemoveModal } from '^/actions/modals';
import { getNonSelfRatersFromDetail } from './utils';
import { isEmail } from '^/utils';
import Alert, { AlertType } from '../Alert';

interface OwnProps {
  storeSelected: (emails: ReadonlyArray<SelectedRater> | undefined) => void;
  selectedRaters: ReadonlyArray<SelectedRater> | undefined;
  deletedRaters?: ReadonlyArray<DeletedRater> | undefined;
  addRater?: (email: string) => void | undefined;
  removeRater?: (id: Uuid) => void | undefined;
  clearRater?: (id: Uuid) => void | undefined;
  userPulseDetail: UserPulseDetail | null;
  disabled?: boolean;
}

interface DispatchProps {
  openConfirmRemoveModal: typeof openConfirmRemoveModal;
}

interface State {
  selectedRaters: ReadonlyArray<SelectedRater> | undefined;
  deletedRaters?: ReadonlyArray<DeletedRater> | undefined;
  error: string | undefined;
  emptyEmail: boolean;
  raterToRemove: SelectedRater | undefined;
  raterToClear: DeletedRater | undefined;
}

export type Props = OwnProps & DispatchProps;

export class SelectPulseRatersList extends React.PureComponent<Props, State> {
  emailAddress: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.emailAddress = React.createRef();
    this.state = {
      error: undefined,
      selectedRaters: this.props.selectedRaters,
      deletedRaters: this.props.deletedRaters,
      emptyEmail: true,
      raterToRemove: undefined,
      raterToClear: undefined,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.userPulseDetail &&
      prevProps.userPulseDetail !== this.props.userPulseDetail
    ) {
      this.setState({
        selectedRaters: getNonSelfRatersFromDetail(this.props.userPulseDetail),
      });
    }
    this.props.storeSelected(this.state.selectedRaters);
  }

  private selectRaters = (email: string) => {
    if (email === '') {
      this.setState({ error: i18next.t<string>('enter an email address') });
      return;
    }

    if (email && !isEmail(email)) {
      this.setState({ error: i18next.t<string>('incorrect format') });
      return;
    }

    if (
      this.props.userPulseDetail &&
      email === this.props.userPulseDetail.user.email
    ) {
      this.setState({
        error: i18next.t<string>('you cannot add yourself as a rater'),
      });
      return;
    }

    const emailExists = this.state.selectedRaters?.some(
      rater => rater.email === email
    );

    if (!emailExists) {
      this.setState({
        error: undefined,
        emptyEmail: true,
      });
      if (!this.props.addRater) {
        this.setState({
          selectedRaters: (this.state.selectedRaters || []).concat([
            { email: email },
          ]),
        });
      }
      this.props.addRater && this.props.addRater(email);
      this.emailAddress!.current!.value = '';
    } else {
      this.setState({
        error: i18next.t<string>('{{email}} already added', {
          email: email,
        }),
      });
    }
  };

  private onEmailChange = () => {
    this.emailAddress!.current?.value !== ''
      ? this.setState({ emptyEmail: false })
      : this.setState({ emptyEmail: true });
  };

  private removeEmail = (raterUserRemoving: SelectedRater) => {
    if (this.props.disabled) {
      return;
    }

    const emailExists = this.state.selectedRaters?.filter(
      each => each.email === raterUserRemoving.email
    ).length;

    if (emailExists && this.props.removeRater) {
      this.setState({ raterToRemove: raterUserRemoving });
      this.props.openConfirmRemoveModal(
        i18next.t<string>('Confirm you want to remove this rater'),
        i18next.t<string>('Are you sure you wish to remove {{emailAddress}}?', {
          emailAddress: raterUserRemoving.email,
        }),
        this.removeRaterConfirmed
      );
      return;
    }

    this.setState({ error: undefined });
    this.setState({
      selectedRaters: this.state.selectedRaters?.filter(
        each => each.email !== raterUserRemoving.email
      ),
    });
  };

  public removeRaterConfirmed = () => {
    const { raterToRemove } = this.state;
    if (raterToRemove && raterToRemove.id && this.props.removeRater) {
      this.props.removeRater(raterToRemove.id);
      this.setState({ error: undefined, raterToRemove: undefined });
    } else {
      this.setState({
        error: i18next.t<string>('Error removing rater {{rater}}', {
          rater: raterToRemove && raterToRemove.email,
        }),
      });
    }
  };

  private clearRater = (raterUserClearing: DeletedRater) => {
    if (this.props.disabled) {
      return;
    }

    if (this.props.clearRater) {
      this.setState({ raterToClear: raterUserClearing });
      this.props.openConfirmRemoveModal(
        i18next.t('Confirm you want to clear this rater'),
        i18next.t('Are you sure you wish to clear {{emailAddress}}?', {
          emailAddress: raterUserClearing.email,
        }),
        this.clearRaterConfirmed
      );
      return;
    }

    this.setState({ error: undefined });
  };

  public clearRaterConfirmed = () => {
    const { raterToClear } = this.state;
    const numRatersSelected = this.state.selectedRaters?.length || 0;
    if (raterToClear && this.props.clearRater && numRatersSelected > 0) {
      this.props.clearRater(raterToClear.id);
      this.setState({ error: undefined, raterToClear: undefined });
    } else {
      this.setState({
        error: i18next.t('Error clearing rater {{rater}}', {
          rater: raterToClear && raterToClear.email,
        }),
      });
    }
  };

  public render() {
    const { selectedRaters, error, emptyEmail, deletedRaters } = this.state;
    const { disabled, addRater, removeRater, clearRater } = this.props;
    const isLiveUpdate = Boolean(addRater) && Boolean(removeRater);
    const noRaters = !selectedRaters || selectedRaters?.length === 0;

    return (
      <div>
        <div className="input-and-button">
          <input
            id="emailAddress"
            className="email-input"
            ref={this.emailAddress}
            type="email"
            name="emailAddress"
            placeholder={i18next.t<string>("Rater's email address")}
            onChange={this.onEmailChange}
            disabled={disabled}
          />

          <button
            className={classNames(
              'btn btn-secondary',
              emptyEmail && 'disabled'
            )}
            onClick={() =>
              this.selectRaters(this.emailAddress?.current?.value || '')
            }
            disabled={emptyEmail || disabled}
          >
            {i18next.t<string>('add')}
          </button>
        </div>
        {error && <Alert type={AlertType.Error}>{error}</Alert>}
        <h3 className="mt-lg">
          <span>{i18next.t<string>('Raters')}</span>:
        </h3>
        <div className="pulse-raters-list">
          {selectedRaters?.map(emails => (
            <div className="email-block" key={emails.id}>
              <p className="email-address">{emails.email}</p>
              <p className="remove-link">
                <a
                  onClick={() => this.removeEmail(emails)}
                  className={disabled ? 'disabled' : ''}
                  role="button"
                >
                  {i18next.t<string>('remove')}
                </a>
              </p>
            </div>
          ))}
          {noRaters &&
            (isLiveUpdate ? (
              <Alert type={AlertType.Error}>
                {i18next.t<string>('You must add at least one rater')}
              </Alert>
            ) : (
              <p>{i18next.t<string>('No raters added')}</p>
            ))}
        </div>
        {deletedRaters && deletedRaters.length > 0 && clearRater && (
          <div>
            <h3 className="mt-lg">
              <span>{i18next.t<string>('Removed raters')}</span>:
            </h3>
            <h5>
              {i18next.t<string>(
                'Add raters again in the field above or clear from the list'
              )}
            </h5>
            <div className="pulse-raters-list">
              {deletedRaters.map(rater => (
                <div className="email-block" key={rater.id}>
                  <p className="email-address">{rater.email}</p>
                  <p className="remove-link">
                    <a
                      onClick={() => this.clearRater(rater)}
                      className={disabled ? 'disabled' : ''}
                    >
                      {i18next.t<string>('Clear')}
                    </a>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { openConfirmRemoveModal })(SelectPulseRatersList);
