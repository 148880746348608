import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

import { getAllUserOptions } from '^/actions/collections';
import { addRaterUserThen } from '^/actions/actionSequences';
import { isPending } from '^/responseStates';
import { USER_STATUS } from '^/models/user';
import { rangeBounded } from '^/utils';
import { Key, handleKeys } from '^/keys';
import PureComponent from '^/components/PureComponent';
import FormError from '^/components/FormError';
import {
  updateSelectUserQuery,
  expandUiComponent,
  collapseUiComponent,
  setHighlightedIndex,
  clearHighlightedIndex,
} from '../../actions/actions';

export class SelectUserInputInner extends PureComponent {
  UNSAFE_componentWillMount() {
    const { organisationId, additionalFilters, isTextInput } = this.props;

    if (isTextInput) {
      return;
    }
    const filters = Object.assign(
      {
        status: this.props.includeInactive ? '' : USER_STATUS.ACTIVE,
        simple: true,
      },
      organisationId && { organisations: organisationId },
      additionalFilters
    );
    this.props.getAllUserOptions(filters);
  }

  updateSelectUserQuery(value) {
    this.props.updateSelectUserQuery(this.props.id, value);
  }

  componentWillUnmount() {
    this.updateSelectUserQuery('');
    this.collapseComponentAndClearIndex();
  }

  componentDidMount() {
    if (this.props.setFocusOnLoad) {
      this._input.focus();
    }
  }

  getClassName() {
    return (
      'select-user-input' +
      (this.props.className ? ' ' + this.props.className : '')
    );
  }

  getUserClassName(highlightedIndex, index) {
    return 'option' + (highlightedIndex === index ? ' option-highlighted' : '');
  }

  onChange(event) {
    this.props.clearHighlightedIndex(this.props.id);
    this.updateSelectUserQuery(event.target.value);
  }

  onKeyDown(event) {
    const matchedUsers = this.getFiveUsers();
    const { highlightedUserIndex } = this.props;

    handleKeys(event, {
      [Key.ENTER]: () => {
        if (highlightedUserIndex !== undefined && matchedUsers.size > 0) {
          this.submitKnownUser(
            matchedUsers.get(highlightedUserIndex).get('id'),
            matchedUsers.get(highlightedUserIndex).get('email')
          );
        } else {
          this.onSubmit();
        }
      },
      [Key.ESCAPE]: this._input.blur,
      [Key.DOWN_ARROW]: this.incrementHighlightedIndex,
      [Key.UP_ARROW]: this.decrementHighlightedIndex,
    });
  }

  incrementHighlightedIndex() {
    const { highlightedUserIndex } = this.props;
    this.props.setHighlightedIndex(
      this.props.id,
      rangeBounded(
        typeof highlightedUserIndex === 'undefined'
          ? 0
          : highlightedUserIndex + 1,
        this.getFiveUsers().size
      )
    );
  }
  decrementHighlightedIndex() {
    const { highlightedUserIndex } = this.props;
    this.props.setHighlightedIndex(
      this.props.id,
      rangeBounded(
        typeof highlightedUserIndex === 'undefined'
          ? 0
          : highlightedUserIndex - 1,
        this.getFiveUsers().size
      )
    );
  }

  queryInNameOrEmail(value, user) {
    const reg = new RegExp(value || '', 'i');
    return reg.exec(user.get('full_name')) || reg.exec(user.get('email'));
  }

  submitKnownUser(userId, email) {
    this.collapseComponentAndClearIndex();
    this.props.submitKnownUser(userId, email);
  }

  get source() {
    return this.props.id;
  }

  submitUnknownUser(email) {
    const { disabled, submitUnknownUser } = this.props;
    if (!disabled && submitUnknownUser) {
      submitUnknownUser(email);
    }
  }

  onSubmit() {
    if (this.props.onSubmit) {
      return this.props.onSubmit();
    }

    const email = this.props.value;
    const userWithMatchingEmail = this.props.users.find(
      user => user.get('email') === email
    );

    if (userWithMatchingEmail) {
      this.submitKnownUser(userWithMatchingEmail.get('id'));
    } else {
      this.submitUnknownUser(email);
    }
  }

  getFiveUsers() {
    const { users, value } = this.props;
    return users.filter(this.queryInNameOrEmail.bind(this, value)).slice(0, 5);
  }

  collapseComponentAndClearIndex() {
    const { id } = this.props;
    this.props.collapseUiComponent(id);
    this.props.clearHighlightedIndex(id);
  }

  renderInput() {
    const {
      id,
      placeholder,
      defaultValue,
      value,
      highlightedUserIndex,
      hideIcon,
      isTextInput,
    } = this.props;

    const fiveUsers = this.getFiveUsers();

    if (isTextInput) {
      return (
        <div className="input-wrapper">
          <input
            type="text"
            placeholder={i18next.t('Email')}
            value={value}
            onChange={event =>
              this.props.updateSelectUserQuery(
                this.props.id,
                event.target.value
              )
            }
          />
        </div>
      );
    }

    return (
      <div className="input-wrapper">
        <input
          onFocus={this.props.expandUiComponent.bind(null, id)}
          onBlur={() => this.collapseComponentAndClearIndex()}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onKeyDown={this.onKeyDown.bind(this)}
          onChange={this.onChange.bind(this)}
          ref={c => (this._input = c)}
        />
        {!hideIcon && <FontAwesomeIcon icon={faSearch} />}
        {this.props.displaySelectUserOptions && (
          <ul className="options">
            {fiveUsers.map((user, index) => (
              <li
                key={user.get('id')}
                onMouseDown={event => event.preventDefault()}
                onClick={this.submitKnownUser.bind(
                  this,
                  user.get('id'),
                  user.get('email')
                )}
                className={this.getUserClassName(highlightedUserIndex, index)}
              >
                <div className="user-name">{user.get('full_name')}</div>
                <div className="user-email">{user.get('email')}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  render() {
    const { id, disabled, response, hideAddButton, existingOnly } = this.props;

    const showAddButton = !hideAddButton && !existingOnly;

    return (
      <div>
        <div {...this.props} className={this.getClassName()}>
          {this.renderInput()}
          <div className="button-wrapper">
            {response &&
            response.get('source') === this.source &&
            isPending(response) ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              showAddButton && (
                <button
                  disabled={disabled}
                  className="btn btn-primary"
                  onClick={this.onSubmit.bind(this)}
                >
                  {i18next.t('Add')}
                </button>
              )
            )}
          </div>
        </div>
        {!this.props.hideError ? (
          <FormError response={response} formKey="email" source={id} />
        ) : null}
      </div>
    );
  }
}

SelectUserInputInner.propTypes = {
  excludedUserIds: ImmutablePropTypes.list,
  organisationId: PropTypes.string.isRequired,
  includeInactive: PropTypes.bool,
  id: PropTypes.string.isRequired,
  setFocusOnLoad: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  submitKnownUser: PropTypes.func.isRequired,
  submitUnknownUser: PropTypes.func,
  displaySelectUserOptions: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  inviteOnAdd: PropTypes.bool,
};

export function mapStateToProps(state, props) {
  const excludedUserIds = props.excludedUserIds || List();

  return {
    value: state.selectUserQuery.get(props.id),
    users: state.collections
      .getIn(['userOptions', 'items'], List())
      .filterNot(user => excludedUserIds.contains(user.get('id'))),
    displaySelectUserOptions: state.ui
      .get('expandedUiComponents')
      .contains(props.id),
    response: state.responses.get('addRaterUser'),
    highlightedUserIndex: state.ui.get('selectHighlightedIndex').get(props.id),
  };
}

export default connect(mapStateToProps, {
  getAllUserOptions,
  updateSelectUserQuery,
  expandUiComponent,
  collapseUiComponent,
  addRaterUserThen,
  setHighlightedIndex,
  clearHighlightedIndex,
})(SelectUserInputInner);
