import React from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { connect } from 'react-redux';
import _ from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { closeTopModal } from '^/actions/modals';
import {
  Analytics360AggregateResults,
  AnalyticsAggregateResults,
  AnalyticsProduct,
} from '^/reducers/api/types';
import RangeSlider from '../RangeSlider';
import { THREE_SIXTY_LEVELS } from '../analytics/DataAnalyticsTableStructure';
import { clamp } from '^/utils-ts';
import { catchBadFactor } from '../analytics/utils';
import { ADVANCED_ANALYTICS_ATTRIBUTE } from '../advanced-analytics/types';
import { StoreState } from '^/store';

export interface DataAnalyticsRange {
  from: number;
  to: number;
}

export interface DataAnalyticsRanges {
  sten: DataAnalyticsRange;
  level: DataAnalyticsRange;
  score: DataAnalyticsRange;
  rating: DataAnalyticsRange;
}

interface OwnProps {
  product: AnalyticsProduct;
  onSliderValueUpdate: (from: string, to: string) => void;
  factor: string;
  attribute: ADVANCED_ANALYTICS_ATTRIBUTE;
  from: string | undefined;
  to: string | undefined;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface State {
  value: DataAnalyticsRange;
}

interface StateProps {
  aggregate:
    | undefined
    | AnalyticsAggregateResults
    | Analytics360AggregateResults;
}

type Props = DispatchProps & OwnProps & StateProps;

const DEFAULT_LIKERT_MAXIMUM = 7;

class AdvancedAnalyticsSetValueModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleRangeSliderOnChange = this.handleRangeSliderOnChange.bind(this);
    this.state = {
      value: {
        from: 0,
        to: 0,
      },
    };
  }

  componentDidMount(): void {
    const { from, to, factor, attribute } = this.props;
    if (from && to) {
      this.convertValuesStringToDataAnalyticsRange(from, to);
    } else {
      const values = this.getValues(factor as string);
      if (values) {
        const { sten, score, level, rating } = values;
        const defaultBounds: any = {
          rating: { from: rating.min, to: rating.max },
          sten: { from: sten.min, to: sten.max },
          score: { from: score.min, to: score.max },
          level: { from: level.min, to: level.max },
        };
        const bounds = defaultBounds[attribute as keyof DataAnalyticsRanges];
        this.updateSliderStateValues([bounds.from, bounds.to]);
      }
    }
  }

  private get360MinMaxes(factor: string) {
    const { aggregate } = this.props;
    const found =
      factor === 'ALL'
        ? (aggregate as Analytics360AggregateResults)?.summary
        : (aggregate as Analytics360AggregateResults)?.results.find(
            each => each.code === factor
          );
    const toLevel = (value: number) =>
      THREE_SIXTY_LEVELS.length -
      1 -
      THREE_SIXTY_LEVELS.findIndex(({ threshold }) => value >= threshold);
    if (found && factor) {
      const { min, max } = found;
      return {
        sten: { min: 0, max: 0 },
        score: { min: 0, max: 0 },
        level: {
          min: toLevel(Number(min.rater_mean)),
          max: toLevel(Number(max.rater_mean)),
        },
        rating: { min: Number(min.rater_mean), max: Number(max.rater_mean) },
      };
    }
  }

  private getPsychometricMinMaxes(factor: string) {
    const { aggregate } = this.props;
    const found = (aggregate as AnalyticsAggregateResults)?.find(
      each => each.factor === factor
    );
    if (found && factor) {
      const levels = this.getLevels(factor);
      const { min, max } = found;
      return {
        rating: { min: 0, max: 0 },
        sten: { min: min.sten, max: max.sten },
        score: { min: min.score, max: max.score },
        level: {
          min: levels.indexOf(min.level as string),
          max: levels.indexOf(max.level as string),
        },
      };
    }
  }

  private getMinMaxes(factor: string) {
    return this.isThreeSixty()
      ? this.get360MinMaxes(factor)
      : this.getPsychometricMinMaxes(factor);
  }

  private getValues(factor: string) {
    const correctedFactor = catchBadFactor(factor);
    return (
      this.getMinMaxes(correctedFactor) || this.getSliderRanges(correctedFactor)
    );
  }

  private convertValuesStringToDataAnalyticsRange(
    from: string | undefined,
    to: string | undefined
  ) {
    const { factor, attribute } = this.props;
    if (factor && attribute && from && to) {
      const allowableValues = this.getValues(factor as string);

      if (allowableValues) {
        const values = [];
        const bounds = [from, to];
        const marks = this.getRangeSliderMarks();

        for (const bound of bounds) {
          if (attribute !== ADVANCED_ANALYTICS_ATTRIBUTE.LEVEL) {
            values.push(Number(bound));
          } else {
            for (const mark of marks) {
              if (mark.label === bound) {
                values.push(mark.value);
              }
            }
          }
        }

        this.updateSliderStateValues(values);
      }
    }
  }

  private updateSliderStateValues = (newValues: number[]) => {
    const [from, to] = newValues;
    this.setState({
      value: {
        from: this.enforceMinMax(from),
        to: this.enforceMinMax(to),
      },
    });
  };

  private handleRangeSliderOnChange = (
    _event: React.ChangeEvent<{}>,
    newValues: number[]
  ) => this.updateSliderStateValues(newValues);

  private handleChangeCommitted = (
    _event: React.ChangeEvent,
    newValues: number[]
  ) => {
    this.updateSliderStateValues(newValues);
    const [from, to] = this.getResultsRange();
    this.props.onSliderValueUpdate(from.toString(), to.toString());
  };

  private getLikertMaximum() {
    return this.props.product.likert_maximum || DEFAULT_LIKERT_MAXIMUM;
  }

  private get360Levels() {
    return THREE_SIXTY_LEVELS.map(({ level }) => level).reverse();
  }

  private isThreeSixty() {
    return this.props.product.activity_type === 'THREE_SIXTY';
  }

  private getLevels(factor: string | number | null | undefined) {
    if (this.isThreeSixty()) {
      return this.get360Levels();
    }
    const { product } = this.props;
    const levelsByFactor = product.levels_by_factor;
    return levelsByFactor[factor as string] || levelsByFactor.default;
  }

  private getSliderRanges(factor: string) {
    const levels = this.getLevels(factor);
    return {
      rating: { min: 1, max: this.getLikertMaximum() },
      sten: { min: 1, max: 10 },
      score: { min: 1, max: 99 },
      level: { min: 0, max: levels.length - 1 },
    };
  }

  private getRangeSliderStep() {
    switch (this.props.attribute) {
      case ADVANCED_ANALYTICS_ATTRIBUTE.SCORE:
        return undefined;
      case ADVANCED_ANALYTICS_ATTRIBUTE.RATING:
        return 0.1;
      default:
        return 1;
    }
  }

  private getResultsRange() {
    const { factor, attribute } = this.props;
    const {
      value: { from, to },
    } = this.state;

    if (attribute === ADVANCED_ANALYTICS_ATTRIBUTE.LEVEL) {
      if (this.isThreeSixty()) {
        const _getThreshold = (level: number) =>
          level < 0 ? '' : THREE_SIXTY_LEVELS[level].threshold;
        const getThreshold = (levelIdx: number) =>
          _getThreshold(THREE_SIXTY_LEVELS.length - 1 - levelIdx);
        return [getThreshold(from), getThreshold(to + 1)];
      }
      const levels = this.getLevels(factor);
      return [levels[from], levels[to]];
    }
    return [from, to];
  }

  private enforceMinMax(value: number) {
    const { attribute, factor } = this.props;
    const { min, max } = this.getValues(factor as string)[
      attribute as keyof DataAnalyticsRanges
    ];
    return clamp(value, min as number, max as number);
  }

  private getRangeSliderMarks() {
    const { attribute, factor } = this.props;
    const makeNumericalMark = (value: number) => ({ label: value, value });
    switch (attribute.toLocaleLowerCase()) {
      case ADVANCED_ANALYTICS_ATTRIBUTE.STEN:
        return _.range(10)
          .map(idx => idx + 1)
          .map(makeNumericalMark);
      case ADVANCED_ANALYTICS_ATTRIBUTE.RATING:
        return _.range(this.getLikertMaximum())
          .map(idx => idx + 1)
          .map(makeNumericalMark);
      case ADVANCED_ANALYTICS_ATTRIBUTE.SCORE:
        return [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 99].map(
          makeNumericalMark
        );
      case ADVANCED_ANALYTICS_ATTRIBUTE.LEVEL:
      default:
        return this.getLevels(factor).map((label, value) => ({ label, value }));
    }
  }

  render() {
    const { from, to } = this.state.value;
    const { attribute, factor } = this.props;
    const minMaxes = factor && this.getSliderRanges(factor);

    return (
      <>
        <div className="modal-header pull-right">
          <div
            className="fa-link close-btn"
            onClick={() => this.props.closeTopModal()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>

        {minMaxes && (
          <RangeSlider
            className="slider-padding"
            value={[from, to]}
            aria-labelledby="discrete-slider-always"
            onChange={this.handleRangeSliderOnChange as any}
            onChangeCommitted={this.handleChangeCommitted as any}
            step={this.getRangeSliderStep()}
            marks={this.getRangeSliderMarks()}
            valueLabelDisplay={'on'}
            valueLabelFormat={
              attribute.toLocaleLowerCase() ===
              ADVANCED_ANALYTICS_ATTRIBUTE.LEVEL
                ? ''
                : undefined
            }
            {...minMaxes[attribute as keyof DataAnalyticsRanges]}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  aggregate: state.analyticsFilters?.sessions?.aggregate,
});

export default connect(mapStateToProps, {
  closeTopModal,
})(AdvancedAnalyticsSetValueModal);
