import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { NewButton, ButtonType } from './NewButton';

interface Props {
  to?: string;
  horizontal?: boolean;
  disabled?: boolean;
  icon?: React.JSX.Element;
  title?: string;
  strapline?: string;
  onClick?: () => void;
  square?: boolean;
  buttonClick?: (e: React.MouseEvent<HTMLElement>) => void;
  buttonText?: string;
  buttonType?: string;
}

export const ClickableTile: React.FC<Props> = ({
  to,
  horizontal,
  disabled,
  icon,
  title,
  strapline,
  square,
  onClick,
  buttonClick,
  buttonText,
  buttonType,
}) => {
  const TileContents = () => {
    return (
      <>
        {icon}
        <div className="clickable-tile-info">
          <h4 className="clickable-tile-title">{title}</h4>
          <p className="clickable-tile-strapline">{strapline}</p>
        </div>
        {buttonClick && (
          <div className="clickable-tile-button">
            <NewButton
              {...(buttonType ? { buttonType: buttonType as ButtonType } : {})}
              disabled={disabled}
              onClick={buttonClick}
            >
              {buttonText}
            </NewButton>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames(
        'clickable-tile',
        { 'clickable-tile-horizontal': horizontal },
        { 'clickable-tile-disabled': disabled },
        { 'clickable-tile-square': square }
      )}
    >
      {to ? (
        <Link to={to} className="clickable-tile-link">
          <TileContents />
        </Link>
      ) : (
        <div
          onClick={!disabled ? onClick : undefined}
          className="clickable-tile-link"
        >
          <TileContents />
        </div>
      )}
    </div>
  );
};
