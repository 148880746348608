/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { Formik, Form, Field } from 'formik';

import { closeTopModal } from '^/actions/modals';
import FormError from '^/components/FormError';
import FormItem from '^/components/forms/FormItem';
import SelectOrganisation from '^/components/SelectOrganisation';

export interface CreateEditProfileDetailsFormProps {
  name: string;
  organisation?: string;
  created_by: string;
  product: string;
}

export interface Props {
  response: Map<string, any>;
  fields?: any;
  initialValues?: any;
  userFullName: string;
  disabled?: boolean;
  canChooseOrg: boolean;
  saveACopy?: (data: CreateEditProfileDetailsFormProps) => void;
  onSubmit?: (data: CreateEditProfileDetailsFormProps) => void;
  closeTopModal?: typeof closeTopModal;
}

export class CreateEditProfileDetailsForm extends React.PureComponent<Props> {
  public closeModal() {
    this.props.closeTopModal && this.props.closeTopModal();
  }

  render() {
    const {
      userFullName,
      disabled,
      initialValues,
      response,
      canChooseOrg,
      saveACopy,
      onSubmit,
    } = this.props;

    const selectOrgDisabled = disabled || Boolean(initialValues?.organisation);

    const renderSaveButtons = (values: CreateEditProfileDetailsFormProps) => {
      const orgValuePermitted = canChooseOrg || values.organisation;
      const allFieldPopulated =
        values.name && orgValuePermitted && values.created_by && values.product;

      if (saveACopy && onSubmit) {
        return (
          <div className="pull-right">
            <button
              className="btn btn-small"
              onClick={() => {
                saveACopy(values);
              }}
              disabled={disabled || !allFieldPopulated}
              type="button"
            >
              {i18next.t<string>('Save a copy')}
            </button>
            <button
              type="submit"
              disabled={disabled || !allFieldPopulated}
              className="btn btn-small"
            >
              {i18next.t<string>('Save')}
            </button>
          </div>
        );
      } else if (saveACopy && this.props.closeTopModal) {
        return (
          <div className="modal-footer clearfix">
            <div className="pull-right">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  this.closeModal();
                }}
              >
                {i18next.t<string>('Cancel')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  saveACopy(values);
                }}
              >
                {i18next.t<string>('Save a copy')}
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="pull-right">
          <button
            type="submit"
            className="btn btn-small"
            disabled={disabled || !allFieldPopulated}
          >
            {i18next.t<string>('Save')}
          </button>
        </div>
      );
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit && onSubmit(values);
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form
            className={classNames(
              'form-container',
              'create-edit-profile-details-form',
              disabled && 'disabled'
            )}
          >
            <div className="form-inline">
              <FormItem label={i18next.t<string>('Profile Name')}>
                <Field
                  name="name"
                  id="name"
                  type="text"
                  placeholder={i18next.t<string>('Enter profile name')}
                />
                <FormError response={response} formKey="name" />
              </FormItem>
              {canChooseOrg && (
                <FormItem
                  label={i18next.t<string>('Account')}
                  className={classNames(
                    'account-form-item',
                    selectOrgDisabled && 'disabled'
                  )}
                >
                  <SelectOrganisation
                    value={values.organisation}
                    name="organisation"
                    disabled={selectOrgDisabled}
                    selectedProduct={values.product}
                    onChange={(value: string) => {
                      setFieldValue('organisation', value);
                    }}
                    hideLabel
                  />
                  <FormError response={response} formKey="organisation" />
                </FormItem>
              )}
              <FormItem label={i18next.t<string>('Created by')}>
                <p>{userFullName}</p>
              </FormItem>
              <FormError response={response} formKey="created_by" />
              <FormError response={response} formKey="product" />
              <FormError response={response} formKey="filters" />
            </div>
            {renderSaveButtons(values)}
          </Form>
        )}
      </Formik>
    );
  }
}

export default CreateEditProfileDetailsForm;
