import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { List } from 'immutable';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import Tag from '^/components/Tag';
import PureComponent from '^/components/PureComponent';
import { openUpdateUsersModal, openAddGroupsModal } from '^/actions/modals';
import LoadingAndErrors from '../LoadingAndErrors';

export class CreateActivityAddUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.openUpdateUsersModal = this.openUpdateUsersModal.bind(this);
    this.openUpdateGroupsModal = this.openUpdateGroupsModal.bind(this);
    this.setProfileCreatorEmail = this.setProfileCreatorEmail.bind(this);
    this.clearSelectedUsers = this.clearSelectedUsers.bind(this);
    this.state = {
      profileCreatorEmail: '',
    };
  }

  updateUsers(userIds) {
    this.props.onUpdate(userIds);
  }

  addGroups(groupIds, userIds) {
    this.updateUsers(userIds);
  }

  setProfileCreatorEmail(email) {
    this.setState({ profileCreatorEmail: email });
  }

  clearSelectedUsers() {
    this.props.onUpdate(List());
    this.setState({ profileCreatorEmail: '' });
  }

  openUpdateUsersModal() {
    const { userIds, organisation, maxNumUsers, isJobProfile } = this.props;
    const setProfileCreatorEmail = isJobProfile
      ? this.setProfileCreatorEmail
      : null;
    this.props.openUpdateUsersModal(
      organisation.get('id'),
      userIds,
      maxNumUsers,
      this.updateUsers.bind(this),
      {
        showFilters: false,
        isJobProfile,
        setProfileCreatorEmail,
      }
    );
  }

  openUpdateGroupsModal() {
    const { organisation, userIds, maxNumUsers } = this.props;
    this.props.openAddGroupsModal(
      organisation,
      userIds,
      this.addGroups.bind(this),
      maxNumUsers
    );
  }

  render() {
    const {
      userIds,
      addUsersResponse,
      addGroupsResponse,
      removeUserResponse,
      isJobProfile,
    } = this.props;

    const { profileCreatorEmail } = this.state;

    return (
      <div className="spaced-group">
        {!isJobProfile && (
          <span>
            {i18next.t('{{numberUsers}} respondents added', {
              numberUsers: userIds.count(),
            })}
          </span>
        )}
        {!profileCreatorEmail && (
          <button
            className="btn btn-default btn-small"
            type="button"
            onClick={() => this.openUpdateUsersModal()}
          >
            <FontAwesomeIcon icon={faPlus} />
            {isJobProfile
              ? i18next.t('Add profile creator')
              : i18next.t('Add respondent(s)')}
          </button>
        )}
        {isJobProfile && profileCreatorEmail && (
          <div className="mt-base">
            <Tag
              key={profileCreatorEmail}
              name={profileCreatorEmail}
              onRemove={() => this.clearSelectedUsers()}
            />
          </div>
        )}
        {!isJobProfile && (
          <button
            className="btn btn-default btn-small"
            type="button"
            onClick={() => this.openUpdateGroupsModal()}
          >
            <FontAwesomeIcon icon={faPlus} />
            {i18next.t('Add group(s)')}
          </button>
        )}
        <LoadingAndErrors
          responses={List([
            addUsersResponse,
            addGroupsResponse,
            removeUserResponse,
          ])}
        />
      </div>
    );
  }
}

export default connect(null, { openUpdateUsersModal, openAddGroupsModal })(
  CreateActivityAddUsers
);
