/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import i18next from 'i18next';
import React from 'react';
import { Map } from 'immutable';
import { push } from 'react-router-redux';
import moment from 'moment';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import {
  setPulseBehaviours,
  loadUserPulseDetail,
  clearUserPulseDetail,
  setPulseRaters,
  loadBehaviourOptions,
  deletePulseRater,
  clearPulseRater,
} from '^/actions/actions';
import { getAllMyUserPulses } from '^/actions/collections';
import {
  BehaviourOptions,
  Frequency,
  PulseFrequency,
  PulseStatus,
  UserPulseDetail,
  Uuid,
} from '^/reducers/api/types';
import { StoreState } from '^/store';
import { RouterParamProps, withRouter } from '^/withRouter';
import FilledCircleCheckIcon from '../FilledCircleCheckIcon';
import SelectPulseBehaviours from './SelectPulseBehaviours';
import SelectPulseRaters from './SelectPulseRaters';
import PulseIconCircle from '^/components/PulseIconCircle';
import { UserPulseSettings } from './UserPulseSettings';
import { SelectedRater } from './types';
import UserPulseCharts from './UserPulseCharts';
import { hasFailed, isPending } from '^/responseStates';
import Loading from '../Loading';
import Dropdown from '../dropdown/Dropdown';
import { getPulseName } from '../dashboard/enduser/Dashboard';
import { scrollToTop } from '^/utils-ts';
import ToggleSwitch from '../buttons/ToggleSwitch';
import { SUPPORT_EMAIL } from '^/settings';

interface DispatchProps {
  loadBehaviourOptions: typeof loadBehaviourOptions;
  setPulseBehaviours: typeof setPulseBehaviours;
  setPulseRaters: typeof setPulseRaters;
  deletePulseRater: typeof deletePulseRater;
  loadUserPulseDetail: typeof loadUserPulseDetail;
  clearUserPulseDetail: typeof clearUserPulseDetail;
  getAllMyUserPulses: typeof getAllMyUserPulses;
  push: typeof push;
  clearPulseRater: typeof clearPulseRater;
}

interface StateProps {
  behaviourOptions: BehaviourOptions | null;
  userPulseDetail: UserPulseDetail | null;
  setBehavioursResponse: Map<string, any> | any;
  setRatersResponse: Map<string, any> | any;
  loadUserPulseDetailResponse: Map<string, any> | any;
  userPulses: Map<string, any> | any;
}

type SwitcherChoiceType = 'DAILY' | 'WEEKLY' | 'MONTHLY';
const SWITCHER_CHOICES = [
  { type: 'DAILY', name: i18next.t<string>('D'), frequencyCount: 1 },
  { type: 'WEEKLY', name: i18next.t<string>('W'), frequencyCount: 1 },
  { type: 'MONTHLY', name: i18next.t<string>('M'), frequencyCount: 4 },
];

interface State {
  selectedBehaviours: ReadonlyArray<{ id: Uuid }> | undefined;
  behavioursChosen: boolean;
  selectedRaters: ReadonlyArray<SelectedRater> | undefined;
  ratersChosen: boolean;
  showThanks: boolean;
  isViewingSettingsPage: boolean;
  screenWidth: number;
  switcherChoice: SwitcherChoiceType;
  frequency: Frequency;
  firstLoad: boolean;
}

type Props = DispatchProps & StateProps & RouterParamProps<{ id: Uuid }, {}>;

export class UserPulsePage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedBehaviours: undefined,
      behavioursChosen: false,
      selectedRaters: undefined,
      ratersChosen: false,
      showThanks: false,
      isViewingSettingsPage: false,
      screenWidth: window.innerWidth,
      switcherChoice: 'DAILY',
      frequency: { type: PulseFrequency.DAILY, count: 1 },
      firstLoad: true,
    };
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.behavioursChosen !== this.state.behavioursChosen ||
      prevState.ratersChosen !== this.state.ratersChosen ||
      prevState.showThanks !== this.state.showThanks ||
      prevState.isViewingSettingsPage !== this.state.isViewingSettingsPage
    ) {
      scrollToTop();
    }
    if (prevState.firstLoad) {
      const subscription = this.props.userPulseDetail?.subscription;
      const choice =
        subscription?.frequency === 'WEEKLY' &&
        subscription?.frequency_count === 4
          ? 'MONTHLY'
          : subscription?.frequency || 'DAILY';
      this.setState(
        {
          frequency: {
            type: subscription?.frequency || PulseFrequency.DAILY,
            count: subscription?.frequency_count || 1,
          },
          switcherChoice: choice,
          firstLoad: false,
        },
        () => this.reloadUserPulseDetail()
      );
    }
    if (prevState.frequency !== this.state.frequency && !prevState.firstLoad) {
      this.reloadUserPulseDetail();
    }
    if (prevProps.userPulseDetail !== this.props.userPulseDetail) {
      this.props.userPulseDetail &&
        this.setState({
          selectedRaters: this.props.userPulseDetail.userpulserater_set.filter(
            rater => !rater.is_self
          ),
          selectedBehaviours: this.props.userPulseDetail.pulse_sub_behaviours.map(
            ({ id }) => ({ id })
          ),
        });
    }
  }

  private reloadUserPulseDetail = () =>
    this.props.loadUserPulseDetail(
      this.props.router.params.id,
      this.state.frequency
    );

  private storeSelectedRaters = (
    emails: ReadonlyArray<SelectedRater> | undefined
  ) => {
    this.setState({
      selectedRaters: emails,
      ratersChosen: true,
    });
  };

  private storeSelectedBehaviours = (
    behaviours: ReadonlyArray<{ id: Uuid }> | undefined
  ) => {
    this.setState({
      selectedBehaviours: behaviours,
      behavioursChosen: true,
    });
  };

  private backToSetBehaviours = () => {
    this.setState({ behavioursChosen: false });
  };

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    this.props.clearUserPulseDetail();
  }

  public updateWindowDimensions = () =>
    this.setState({ screenWidth: window.innerWidth });

  public componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    this.reloadUserPulseDetail();
    this.props.getAllMyUserPulses();
    this.props.loadBehaviourOptions(this.props.router.params.id);
  }

  public setSwitcher = (choice: SwitcherChoiceType) => {
    const frequency = choice === 'MONTHLY' ? 'WEEKLY' : choice;
    const frequencyCount =
      SWITCHER_CHOICES.find(choices => choices.type === choice)
        ?.frequencyCount || 1;
    return this.setState({
      switcherChoice: choice,
      frequency: { type: PulseFrequency[frequency], count: frequencyCount },
    });
  };

  private onClick = (id: string | number | null) => {
    this.props.push(`/page/user-pulses/${id}/`);
    this.reloadUserPulseDetail();
  };

  private showThanks = () => {
    this.setState({ showThanks: true });
  };

  private setBehavioursAndRaters = (
    behaviours: ReadonlyArray<{ id: Uuid }> | undefined
  ) => {
    const isSelfOnly = this.props.userPulseDetail?.pulse.is_self_only;
    const showThanksAndReload = () => {
      this.showThanks();
      this.reloadUserPulseDetail();
    };

    const selectedBehaviours = isSelfOnly
      ? behaviours
      : this.state.selectedBehaviours;

    this.props.setPulseBehaviours(
      this.props.router.params.id,
      {
        pulse_sub_behaviours: selectedBehaviours,
      },
      isSelfOnly ? showThanksAndReload : () => {}
    );
    if (!isSelfOnly) {
      this.props.setPulseRaters(
        this.props.router.params.id,
        {
          emails: this.state.selectedRaters,
        },
        showThanksAndReload
      );
    }
  };

  private addRater = (email: string) => {
    return this.props.setPulseRaters(
      this.props.router.params.id,
      {
        emails: [{ email: email }],
      },
      this.reloadUserPulseDetail
    );
  };

  private removeRater = (id: Uuid) => {
    return this.props.deletePulseRater(id, this.reloadUserPulseDetail);
  };

  private clearRater = (id: Uuid) => {
    return this.props.clearPulseRater(id, this.reloadUserPulseDetail);
  };

  public shouldComponentUpdate(nextProps: Props) {
    if (nextProps.userPulseDetail) {
      return true;
    }
    return false;
  }

  public render() {
    const {
      behavioursChosen,
      selectedBehaviours,
      selectedRaters,
      isViewingSettingsPage,
      showThanks,
      screenWidth,
    } = this.state;

    const {
      behaviourOptions,
      userPulses,
      userPulseDetail,
      setBehavioursResponse,
      setRatersResponse,
      loadUserPulseDetailResponse,
    } = this.props;
    const allUserPulses: ReadonlyArray<{
      id: string;
      name: string;
    }> =
      (userPulses?.get('items') &&
        Array.from(
          userPulses
            .get('items')
            .filter(
              (pulse: Immutable.Map<string, any>) =>
                pulse.get('num_raters') > 0 ||
                pulse.getIn(['pulse', 'is_self_only'])
            )
            .map((pulse: Immutable.Map<string, any>) => {
              return {
                id: pulse.get('id'),
                name: getPulseName(pulse),
              };
            })
        )) ||
      null;
    const isLoading = isPending(loadUserPulseDetailResponse);
    const ratersSet = userPulseDetail?.userpulserater_set;
    const behavioursSet = userPulseDetail?.pulse_sub_behaviours;
    const isPulsing =
      userPulseDetail &&
      ((ratersSet && ratersSet?.length > 0) ||
        userPulseDetail?.pulse.is_self_only) &&
      behavioursSet &&
      behavioursSet.length > 0 &&
      !showThanks;
    const selectRaters = behavioursChosen && !isPulsing && !showThanks;
    const selectBehaviours = !behavioursChosen && !isPulsing && !showThanks;
    if (hasFailed(loadUserPulseDetailResponse)) {
      return (
        <p className="text-error">
          {i18next.t<string>('Page not found, contact {{supportEmail}}', {
            supportEmail: SUPPORT_EMAIL,
          })}
        </p>
      );
    }

    if (
      !isViewingSettingsPage &&
      (hasFailed(setBehavioursResponse) || hasFailed(setRatersResponse))
    ) {
      return (
        <p className="text-error">
          {i18next.t<string>(
            'Error setting up new pulse, contact {{supportEmail}}',
            { supportEmail: SUPPORT_EMAIL }
          )}
        </p>
      );
    }
    if (isLoading || !userPulseDetail) {
      return <Loading />;
    }

    if (userPulseDetail) {
      if (
        [PulseStatus.NOT_STARTED, PulseStatus.INCOMPLETE].includes(
          userPulseDetail.status
        )
      ) {
        toast.error(i18next.t<string>('Your pulse has not started yet'));
        this.props.push('/page/home');
      } else if (userPulseDetail.status === PulseStatus.ENDED) {
        toast.error(i18next.t<string>('Your pulse has ended'));
        this.props.push('/page/home');
      }
    }

    return (
      <div>
        <div className="pulse user-pulse-page">
          {isPulsing && !isViewingSettingsPage && (
            <div>
              <div className="back-dash-link">
                <Link to="/page/dashboard">
                  &lt; {i18next.t<string>('back')}
                </Link>
              </div>
              <div className="pulse-icon user-pulse">
                <PulseIconCircle />
                <div
                  className="navigation-button settings"
                  onClick={() => this.setState({ isViewingSettingsPage: true })}
                >
                  <span className="settings-link">
                    {i18next.t<string>('Settings')}
                  </span>
                </div>
              </div>
              <div className="switcher-and-choice-block">
                <div className="switch-block">
                  {allUserPulses && allUserPulses.length > 1 && (
                    <div className="pulse-choice">
                      <h4>{i18next.t<string>('Chart selection')}</h4>
                      <Dropdown
                        defaultValue={
                          allUserPulses.filter(
                            pulse => pulse?.id === userPulseDetail?.id
                          )[0].id
                        }
                        title={i18next.t<string>('Select chart')}
                        selectable={false}
                        multiSelects={null}
                        items={allUserPulses}
                        onClick={this.onClick}
                      />
                    </div>
                  )}
                </div>
                <div className="switch-block centred">
                  <div
                    className={classNames('form-block', {
                      'no-dropdown': allUserPulses && allUserPulses.length <= 1,
                    })}
                  >
                    <ToggleSwitch
                      items={SWITCHER_CHOICES.map(choices => {
                        return {
                          id: choices.type,
                          name: choices.name,
                          selected: this.state.switcherChoice === choices.type,
                        };
                      })}
                      onClick={(choice: SwitcherChoiceType) =>
                        this.setSwitcher(choice)
                      }
                    />
                  </div>
                </div>
                <div className="switch-block" />
              </div>
              <UserPulseCharts
                userPulseDetail={userPulseDetail}
                screenWidth={screenWidth}
                frequency={this.state.frequency}
                created={moment(userPulseDetail.pulse.created).format(
                  'YYYY-MM-DD'
                )}
                firstLoad={this.state.firstLoad}
              />
            </div>
          )}
          {isPulsing && userPulseDetail && isViewingSettingsPage && (
            <UserPulseSettings
              backToPulseDock={() =>
                this.setState({ isViewingSettingsPage: false })
              }
              userPulseDetail={userPulseDetail}
              storeSelected={this.storeSelectedRaters}
              addRater={this.addRater}
              removeRater={this.removeRater}
              clearRater={this.clearRater}
              screenWidth={screenWidth}
            />
          )}
          {!userPulseDetail.pulse.is_self_only && selectRaters && (
            <SelectPulseRaters
              backToSetBehaviours={this.backToSetBehaviours}
              storeSelected={this.storeSelectedRaters}
              setBehavioursAndRaters={this.setBehavioursAndRaters}
              selectedRaters={selectedRaters}
              userPulseDetail={userPulseDetail}
            />
          )}
          {selectBehaviours && behaviourOptions && (
            <SelectPulseBehaviours
              behaviourOptions={behaviourOptions}
              storeSelected={this.storeSelectedBehaviours}
              selectedBehaviours={selectedBehaviours}
              isSelfOnly={userPulseDetail.pulse.is_self_only}
              setBehaviours={this.setBehavioursAndRaters}
            />
          )}
          {showThanks && (
            <div className="thanks">
              <p>{i18next.t<string>('Thanks!')}</p>
              <p>{i18next.t<string>(`You're pulsing!`)}</p>
              <FilledCircleCheckIcon />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: StoreState) {
  return {
    behaviourOptions: state.behaviourOptions,
    userPulseDetail: state.userPulseDetail,
    setBehavioursResponse: state.responses.get('setPulseBehaviours'),
    setRatersResponse: state.responses.get('setPulseRaters'),
    loadUserPulseDetailResponse: state.responses.get('loadUserPulseDetail'),
    userPulses: state.collections.get('myUserPulses', Map()),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    push,
    getAllMyUserPulses,
    loadBehaviourOptions,
    setPulseBehaviours,
    setPulseRaters,
    deletePulseRater,
    loadUserPulseDetail,
    clearUserPulseDetail,
    clearPulseRater,
  })(UserPulsePage)
);
