/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import moment from 'moment';
import i18next from 'i18next';
import _ from 'underscore';

import {
  ChartDataStructure,
  Frequency,
  PulseFrequency,
  RatingsData,
  UserPulseDetail,
} from '^/reducers/api/types';
import BehaviourChart from './BehaviourChart';
import {
  MOBILE_SCREEN_WIDTH,
  FOUR_WEEK_BLOCK,
  QUARTER_BLOCK,
  WEEK_BLOCK,
} from './constants';
import { findBlockLength } from './utils';

interface OwnProps {
  screenWidth: number;
  userPulseDetail: UserPulseDetail | null;
  frequency: Frequency;
  created: string;
  firstLoad: boolean;
}

type Props = OwnProps;

export class UserPulseCharts extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  private formatDate = (date: string | null) =>
    moment(date || '').format('YYYY-MM-DD');

  private userPulseScores = (index: number): ChartDataStructure => {
    const { userPulseDetail, screenWidth, frequency } = this.props;
    const dataSet = userPulseDetail!.pulse_sub_behaviours[index]
      .userpulsescore_set;
    const selfId = userPulseDetail!.userpulserater_set.filter(
      rater => rater.is_self === true
    )[0].id;

    const raterIds = [
      ...new Set(
        dataSet
          ?.filter(rating => rating.rater_id !== selfId)
          .map(rating => rating.rater_id)
      ),
    ];

    const groupedDataSet = _.groupBy(dataSet, score =>
      this.formatDate(score.created)
    );

    const ratedOnDates = Array.from(
      new Set(dataSet?.map(score => this.formatDate(score.created)))
    )
      .map(created => ({
        created: created,
        raterScoreId: groupedDataSet[created].map(rating => ({
          rater_id: rating.rater_id,
          score: rating.average_score,
        })),
      }))
      .sort((a, b) => (a.created > b.created ? 1 : -1));

    const padAmount =
      findBlockLength(screenWidth < MOBILE_SCREEN_WIDTH, frequency) || 14;

    const createdToLastRateLength =
      moment(ratedOnDates[ratedOnDates.length - 1].created).diff(
        moment(ratedOnDates[0].created),
        'days',
        true
      ) + 1;

    const padDateMultiplier = Math.ceil(createdToLastRateLength / padAmount);
    const padDate = moment(ratedOnDates[0].created)
      .add(
        padAmount * (padDateMultiplier === 0 ? 1 : padDateMultiplier),
        'days'
      )
      .format('YYYY-MM-DD');

    if (
      ratedOnDates &&
      moment(ratedOnDates[ratedOnDates.length - 1]?.created).isBefore(padDate)
    ) {
      ratedOnDates.push({
        created: padDate,
        raterScoreId: [{ rater_id: '', score: 0 }],
      });
    }

    const paddedDatesInRatedPeriod =
      ratedOnDates?.reduce(
        (newArray: RatingsData[], currentDate, idx, originalArray) => {
          const nextDate = originalArray[idx + 1]?.created;
          if (nextDate) {
            const daysBetween = moment(nextDate).diff(
              moment(currentDate.created || ''),
              'days'
            );
            const emptyDates = Array.from(
              { length: daysBetween - 1 },
              _value => ({ created: null, raterScoreId: null })
            );
            newArray.push(currentDate, ...emptyDates);
          } else {
            newArray.push(currentDate);
          }

          return newArray;
        },
        []
      ) || [];

    const xAxisLabels = Array.from(paddedDatesInRatedPeriod, (_x, idx) => {
      const dayString = `${i18next.t<string>('day')} ${idx + 1}`;
      const weekString = `${i18next.t<string>('week')} ${Math.floor(
        (idx + 1) / WEEK_BLOCK
      ) + 1}`;
      const monthNum = Math.floor((idx + 1) / FOUR_WEEK_BLOCK) + 1;
      const yearNum = Math.floor((idx + 1) / 365) + 1;
      const monthString = `${i18next.t<string>(
        'month'
      )} ${monthNum} \n\n(${i18next.t<string>('YR')} ${yearNum})   `;

      return frequency.type === PulseFrequency.DAILY
        ? dayString
        : frequency.count === 1
        ? weekString
        : monthString;
    });

    const selfData = paddedDatesInRatedPeriod.map((ratingsData: RatingsData) =>
      ratingsData.raterScoreId?.some(rating => rating?.rater_id === selfId)
        ? ratingsData.raterScoreId
            .filter(rating => rating?.rater_id === selfId)
            .map(rating => rating.score.toFixed(2))
            .toString()
        : null
    );

    const dailyRangeArrs = paddedDatesInRatedPeriod.map((date: RatingsData) =>
      date.raterScoreId?.some(rating => rating?.rater_id !== selfId)
        ? date.raterScoreId
            ?.filter(rating => rating?.rater_id !== selfId)
            .map(rating => rating.score)
        : null
    );

    const dailyRanges = dailyRangeArrs.map(arr => {
      const max = Math.max(...(arr || [0]));
      const min = Math.min(...(arr || [0]));
      return max - min;
    });

    const ratersData = raterIds.map(rater =>
      paddedDatesInRatedPeriod.map((ratingsData: RatingsData) => {
        return ratingsData.raterScoreId?.some(
          rating => rating?.rater_id === rater
        )
          ? ratingsData.raterScoreId
              .filter(rating => rating?.rater_id === rater)
              .map(rating => rating.score)
              .toString()
          : null;
      })
    );

    const dailySum = paddedDatesInRatedPeriod.map(
      (rating: RatingsData) =>
        rating.raterScoreId
          ?.map(item => (item.rater_id !== selfId ? item.score : 0))
          .reduce((a, b) => a + b) || null
    );

    const dailyRaterCount = paddedDatesInRatedPeriod.map(
      rating =>
        rating.raterScoreId?.filter(rater => rater.rater_id !== selfId)
          ?.length || null
    );

    const dailyAverages = Array.from(
      dailySum?.map((item, idx) => {
        return item ? item / (dailyRaterCount[idx] || 1) : null;
      })
    );

    const averagesLength =
      frequency.type === PulseFrequency.DAILY
        ? WEEK_BLOCK
        : frequency.count === 1
        ? FOUR_WEEK_BLOCK
        : QUARTER_BLOCK;

    const frequencyAverages = [];
    for (let i = 0; i < dailyAverages.length / averagesLength; i++) {
      const pos = i * averagesLength + averagesLength;
      frequencyAverages[pos] =
        (
          (dailyAverages
            .slice(i * averagesLength, (i + 1) * averagesLength)
            .reduce((a, b) => (a || 0) + (b || 0)) || 0) /
          dailyAverages
            .slice(i * averagesLength, (i + 1) * averagesLength)
            .filter((each: number | null) => each !== null).length
        ).toFixed(2) || null;
    }
    return {
      xAxisLabels: xAxisLabels,
      dailyAverages: dailyAverages,
      dailyRanges: dailyRanges,
      dailyRaterCount: dailyRaterCount,
      startDate: paddedDatesInRatedPeriod[0].created || '',
      frequencyAverages: frequencyAverages,
      selfData: selfData,
      ratersData: ratersData,
      dataBlockCount: Math.ceil(
        (xAxisLabels.length - 1) /
          (findBlockLength(screenWidth < MOBILE_SCREEN_WIDTH, frequency) || 0)
      ),
    };
  };

  public render() {
    const { screenWidth, userPulseDetail, firstLoad, frequency } = this.props;
    const ratingsExist = (index: number) =>
      userPulseDetail!.pulse_sub_behaviours[index].userpulsescore_set!.length >
      0;
    return (
      <div>
        <div>
          {userPulseDetail?.pulse_sub_behaviours.map((subBehaviour, index) => (
            <div className="chart-block" key={index}>
              <div>
                {!firstLoad && ratingsExist(index) ? (
                  <BehaviourChart
                    data={this.userPulseScores(index)}
                    chartTitle={subBehaviour.pulse_behaviour.name}
                    chartSubTitle={subBehaviour.name}
                    isMobile={screenWidth < MOBILE_SCREEN_WIDTH}
                    descriptionText={subBehaviour.description}
                    frequency={frequency}
                    isSelfOnly={userPulseDetail?.pulse.is_self_only}
                  />
                ) : (
                  <p className="text-error">
                    {i18next.t<string>('No rating data exists.')}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default UserPulseCharts;
