import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import LiveButton from '../../../LiveButton';

export default function MAPBottomNav({
  navigateToPrevQuestion,
  navigateToNextQuestion,
  completeTest,
  startAssessment,
  loading,
  submitting,
}) {
  const props = {
    pendingMessage: 'loading...',
    additionalButtonClasses: 'bordered',
  };
  const nextButtonMessage = startAssessment
    ? i18next.t('Start assessment')
    : i18next.t('Next');

  return (
    <div className="row">
      <div className="col-xs-12">
        {navigateToPrevQuestion && (
          <div className="pull-left map-nav-button map-nav-button-left">
            <LiveButton
              disabled={!navigateToPrevQuestion || submitting}
              onClick={navigateToPrevQuestion}
              buttonText={i18next.t('Back')}
              {...props}
            />
          </div>
        )}

        <div className="pull-right map-nav-button map-nav-button-right">
          {completeTest ? (
            <LiveButton
              disabled={loading || submitting}
              onClick={completeTest}
              buttonText={i18next.t('Submit')}
              {...props}
            />
          ) : (
            <LiveButton
              disabled={!navigateToNextQuestion || submitting}
              onClick={navigateToNextQuestion}
              buttonText={nextButtonMessage}
              {...props}
            />
          )}
        </div>
      </div>
    </div>
  );
}
