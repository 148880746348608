import i18next from 'i18next';
import React from 'react';

import Currency from './Currency';
import { VAT_RATE } from '../constants';
import { costAfterTax } from '../utils';

interface Props {
  totalCost: number;
  applyVat: boolean;
}

const SEMI_BOLD = 'semi-bold-text uppercase';
const BOLD = 'bold-text uppercase';

const BasketCostSummary: React.FunctionComponent<Props> = ({
  totalCost,
  applyVat,
}) => {
  const data: ReadonlyArray<[string, number | string, string]> = [
    [i18next.t<string>('Subtotal'), totalCost, SEMI_BOLD],
    applyVat
      ? [i18next.t<string>('VAT (+20%)'), totalCost * VAT_RATE, SEMI_BOLD]
      : [
          i18next.t<string>('VAT'),
          i18next.t<string>('Exempt') as string,
          SEMI_BOLD,
        ],
    [i18next.t<string>('Total'), costAfterTax(totalCost, applyVat), BOLD],
  ];

  return (
    <table className="inline-table table-transparent margin-none pull-right">
      <tbody>
        {data.map(([title, value, className], idx) => (
          <tr key={idx}>
            <td className={className}>{title}</td>
            <td className={className}>
              {typeof value === 'number' ? <Currency value={value} /> : value}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BasketCostSummary;
