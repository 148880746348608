import classNames from 'classnames';
import React from 'react';

import { ButtonType, getButtonClassName } from './utils';

interface OwnProps {
  buttonType?: ButtonType;
  secondary?: boolean;
  small?: boolean;
}

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  OwnProps;

export const Button: React.FunctionComponent<Props> = ({
  buttonType = 'primary',
  secondary,
  small,
  className,
  children,
  ...restProps
}) => (
  <button
    className={classNames(
      getButtonClassName(buttonType, secondary, small),
      className
    )}
    {...restProps}
  >
    {children}
  </button>
);

export default Button;
