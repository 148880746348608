import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { closeTopModal, openConfirmationModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';

export class GenericModal extends PureComponent {
  closeAndSubmit() {
    this.props.closeTopModal();
    this.props.submitAction();
  }

  render() {
    return (
      <div>
        {this.props.body}
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              type="button"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Cancel')}
            </button>
            <button
              className="btn btn-default"
              type="button"
              onClick={() => this.closeAndSubmit()}
            >
              {this.props.submitButtonText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    responses: state.responses,
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  openConfirmationModal,
})(GenericModal);
