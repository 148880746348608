import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import {
  setModalClosable,
  setModalTitle,
  setFormStage,
  resetFormStage,
  resetGetReportsFromDate,
  loadOrgSessionsRecent,
  setPoll,
  resetGeneratingReports,
  setModalOnCloseEvent,
  unselectAllReportsTableSelection,
  loadJobProfileSessions,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import {
  closeModalAndSetFormStage,
  pollOrgSessions,
  pollJobProfileSessions,
} from '^/actions/actionSequences';
import ReportGenerationModal from '^/components/reports/admin/ReportGenerationModal';
import ReportActions, {
  STAGES,
} from '^/components/reports/admin/ReportActions';
import Loading from '^/components/Loading';
import { POLLING_RATE } from './AdminReportsPage';

export const REPORT_GENERATION_FLOW = 'reportGenerationFlow';

export class ReportGeneration extends PureComponent {
  UNSAFE_componentWillMount() {
    const { organisationId } = this.props;
    if (organisationId) {
      this.props.setFormStage(REPORT_GENERATION_FLOW, STAGES.SET_UP);
    }
    this.props.setModalOnCloseEvent(() => this.onClose());
  }

  onClose() {
    const { organisationId } = this.props;
    this.props.resetFormStage(REPORT_GENERATION_FLOW);
    this.props.resetGeneratingReports();
    this.props.resetGetReportsFromDate();
    if (organisationId) {
      if (this.props.isJobProfiler) {
        this.props.pollJobProfileSessions(
          organisationId,
          'JobProfilesReportsPage',
          POLLING_RATE
        );
      } else {
        this.props.pollOrgSessions(
          organisationId,
          'AdminReportsPage',
          POLLING_RATE
        );
      }
    }
    this.props.unselectAllReportsTableSelection();
    this.props.closeTopModal();
  }

  render() {
    const { formStage, organisationId, selectedReports } = this.props;
    switch (formStage) {
      case STAGES.SET_UP:
        return (
          <ReportGenerationModal
            onPurchase={() =>
              this.props.setFormStage(REPORT_GENERATION_FLOW, STAGES.GENERATING)
            }
            organisationId={organisationId}
            onClose={() => this.onClose()}
            isJobProfiler={this.props.isJobProfiler}
          />
        );
      case STAGES.GENERATING:
      case STAGES.REPORTS_PURCHASED:
      case STAGES.REPORTS_DOWNLOADING:
      case STAGES.REPORTS_DOWNLOADED:
      case STAGES.REPORTS_SENT:
        return (
          <ReportActions
            reportsSent={() =>
              this.props.closeModalAndSetFormStage(
                REPORT_GENERATION_FLOW,
                STAGES.REPORTS_SENT
              )
            }
            proceedToNextStage={() =>
              this.props.setFormStage(
                REPORT_GENERATION_FLOW,
                STAGES.REPORTS_PURCHASED
              )
            }
            formName={REPORT_GENERATION_FLOW}
            formStage={formStage}
            organisationId={organisationId}
            onClose={() => this.onClose()}
            selectedReports={selectedReports}
            isJobProfiler={this.props.isJobProfiler}
          />
        );
      case STAGES.SEND_REPORTS:
      case STAGES.EDIT_EMAIL:
      default:
        return <Loading />;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    response: state.responses.get('createReports'),
    formStage: state.ui.getIn(['formStages', REPORT_GENERATION_FLOW], null),
    createReportsResponse: state.responses.get('createReports'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  setModalClosable,
  setModalTitle,
  setFormStage,
  resetFormStage,
  resetGetReportsFromDate,
  loadOrgSessionsRecent,
  setPoll,
  resetGeneratingReports,
  setModalOnCloseEvent,
  closeModalAndSetFormStage,
  unselectAllReportsTableSelection,
  loadJobProfileSessions,
  pollOrgSessions,
  pollJobProfileSessions,
})(ReportGeneration);
