import classNames from 'classnames';
import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { ChangeEvent, PureComponent, PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { clearFilters } from '^/actions/actions';
import Alert from '^/components/Alert';
import Filter, { FilterData } from '^/components/Filter';
import Icon from '^/components/Icon';
import Loading from '^/components/Loading';
import SearchBar from '^/components/Searchbar';
import { isPending } from '^/responseStates';
import { StoreState } from '^/store';

export interface ListHeader {
  className?: string;
  field: string;
  title: string;
  children?: ReadonlyArray<{ title: string; className: string }>;
  sortable?: boolean;
}

export interface Ordering {
  reversed: boolean;
  field: string;
}

interface OwnProps<ListItem> {
  items: List<ListItem>;
  response: Map<string, any>;
  hasMore?: boolean;
  filters?: ReadonlyArray<FilterData>;
  typeNamePlural: string;
  noItemsMessage?: string;
  isFiltered?: boolean;
  ordering?: Ordering;
  headers: ReadonlyArray<ListHeader>;
  searchHeaders?: ReadonlyArray<string> | string;
  isSelectModal?: boolean;
  tableClassName?: string;
  listClassName?: string;
  renderer: (item: ListItem) => JSX.Element;
  renderHeader?: (field: string) => JSX.Element;
  showMore?: () => void;
  onFilterChange: (filterKey: string, value: string | null) => void;
  onOrderingChange: (field: string) => void;
  onSearchChange?: (
    field: string,
    search: ChangeEvent<HTMLInputElement>
  ) => void;
  hideBorder?: boolean;
  scrollable?: boolean;
  isLoading?: boolean;
  className?: string;
  innerBoxHeaders?: JSX.Element;
  /**
   * @description Applies newer "collapse" class name for table responsiveness
   */
  collapse?: 'md';
  children?: React.ReactNode;
}

interface StateProps {
  collections: Map<string, Map<string, any>>;
}

interface DispatchProps {
  clearFilters: typeof clearFilters;
}

type Props<ListItem> = PropsWithChildren<
  OwnProps<ListItem> & StateProps & DispatchProps
>;

export class ListPage<ListItem> extends PureComponent<Props<ListItem>> {
  public componentDidMount() {
    if (this.props.filters?.length) {
      this.props.filters
        .filter(filter => filter.values.async && filter.values.fetchAction)
        .forEach(filter => filter.values.fetchAction!());
    }
  }

  public componentWillUnmount() {
    if (this.props.filters?.length) {
      this.props.clearFilters();
    }
  }

  public makeSynchronous(filter: FilterData) {
    if (!filter.values.async) {
      return filter;
    }

    const items = this.props.collections.getIn([
      filter.values.collectionKey,
      'items',
    ]);

    return Object.assign({}, filter, { values: { values: items } });
  }

  public renderNoItems() {
    const {
      typeNamePlural,
      noItemsMessage,
      isFiltered,
      hideBorder,
    } = this.props;

    const noItemsAlert = (
      <Alert>
        {noItemsMessage ||
          (isFiltered
            ? i18next.t<string>('No matching {{typeNamePlural}}', {
                typeNamePlural,
              })
            : i18next.t<string>('No {{typeNamePlural}} found.', {
                typeNamePlural,
              }))}
      </Alert>
    );

    return hideBorder ? (
      noItemsAlert
    ) : (
      <div className="row">
        <div className="col-xs-12">{noItemsAlert}</div>
      </div>
    );
  }

  public renderHeaderFilter(field: string) {
    const { filters = [], onFilterChange } = this.props;
    const filter = filters
      .filter(each => each.key === field)
      .map(this.makeSynchronous.bind(this))[0];

    if (!filter) {
      return null;
    }

    return (
      <div className="filters">
        <Filter
          canBeChanged
          showIfUnset
          data={filter as FilterData}
          key={filter['name']}
          onChange={onFilterChange.bind(null, filter['key'])}
        />
      </div>
    );
  }

  public renderHeaderSearch(field: string) {
    const { searchHeaders, onSearchChange } = this.props;
    if (onSearchChange && searchHeaders && searchHeaders.includes(field)) {
      return (
        <SearchBar
          className="small"
          onChange={onSearchChange.bind(null, field)}
        />
      );
    }
  }

  public renderSortButton(field: string) {
    const { ordering } = this.props;

    return (
      <Icon
        onClick={() => this.props.onOrderingChange(field)}
        type={'up-down'}
        className={classNames(
          'sort-control',
          ordering?.field === field ? (ordering.reversed ? 'down' : 'up') : ''
        )}
      />
    );
  }

  public renderTableHeaders() {
    const { headers, filters, searchHeaders } = this.props;
    const hasExtraFields = headers.some(
      ({ field }) =>
        (searchHeaders && searchHeaders.includes(field)) ||
        (filters && filters.some(({ key }) => key === field))
    );

    return (
      <thead>
        <tr>
          {headers.map(({ className, field, title, children, sortable }) => (
            <th
              key={title}
              rowSpan={children ? 1 : 2}
              colSpan={children ? children.length : 1}
              className={classNames(className, {
                'has-extra-fields': hasExtraFields,
              })}
            >
              <div
                className={classNames(
                  'table-header',
                  sortable && 'table-header-control-on-right'
                )}
              >
                {this.props.renderHeader && this.props.renderHeader(field)}
                {title}
                {sortable && this.renderSortButton(field)}
              </div>
              {this.renderHeaderFilter(field)}
              {this.renderHeaderSearch(field)}
            </th>
          ))}
        </tr>
        {headers.some(header => header.children) && (
          <tr>
            {headers.map(
              ({ children }) =>
                children &&
                children.map(({ title, className }) => (
                  <th key={title} className={className}>
                    {title}
                  </th>
                ))
            )}
          </tr>
        )}
      </thead>
    );
  }

  public renderTable(items: List<ListItem>) {
    const {
      isSelectModal,
      scrollable,
      className,
      tableClassName,
      hideBorder,
      innerBoxHeaders,
      collapse,
    } = this.props;

    const table = (
      <table
        className={classNames(
          {
            'user-select-table': isSelectModal,
            responsive: !isSelectModal && !collapse,
            [`collapse collapse-${collapse}`]: !isSelectModal && collapse,
          },
          tableClassName
        )}
      >
        {this.renderTableHeaders()}
        <tbody>{items.map(this.props.renderer).toArray()}</tbody>
      </table>
    );

    if (hideBorder) {
      return (
        <div
          className={classNames(
            { 'table-scrollable-wrapper': scrollable },
            className
          )}
        >
          {table}
          {items.isEmpty() && this.renderNoItems()}
        </div>
      );
    }

    return (
      <div>
        <div
          className={classNames('row', {
            'scrollable-modal': scrollable || isSelectModal,
          })}
        >
          <div className="col-xs-12 content-box">
            {innerBoxHeaders}
            {table}
            {items.isEmpty() && this.renderNoItems()}
          </div>
        </div>
      </div>
    );
  }

  public renderGrid(items: List<ListItem>) {
    return (
      <div className="row">
        <div className={this.props.listClassName + ' col-xs-12'}>
          {items.isEmpty()
            ? this.renderNoItems()
            : items.map(this.props.renderer).toArray()}
        </div>
      </div>
    );
  }

  public renderItems(items: List<ListItem>) {
    return this.props.headers
      ? this.renderTable(items)
      : this.renderGrid(items);
  }

  public renderLoadMore() {
    return (
      <button className="btn show-more" onClick={this.props.showMore}>
        Show More
      </button>
    );
  }

  public render() {
    const { response, items, children, hasMore, isLoading } = this.props;
    const loading = isPending(response) || !items || isLoading;
    return (
      <div>
        {children}
        {loading && <Loading className="list-loading" />}
        {items && this.renderItems(items)}
        {items && !loading && hasMore && this.renderLoadMore()}
      </div>
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    collections: state.collections,
  };
}

// Needed as redux cannot type connected generic components properly
type GenericListPage = <ListItem>(props: OwnProps<ListItem>) => JSX.Element;
const ConnectedListPage: GenericListPage = connect(mapStateToProps, {
  clearFilters,
})(ListPage) as any;

export default ConnectedListPage;
