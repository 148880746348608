import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import ContentTab from '^/components/ContentTab';
import ContentTabset from '^/components/ContentTabset';
import ContentBox from '^/components/ContentBox';
import ControlBar from '../ControlBar';
import DiscountSettings from './DiscountSettings';
import SystemSettings from './SystemSettings';

export class SettingsPage extends PureComponent {
  render() {
    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/home">{i18next.t('Home')}</Link>
        <span>{i18next.t('Settings')}</span>
      </span>
    );

    return (
      <div>
        <ControlBar
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
          title={i18next.t('Settings')}
        />
        <div className="row">
          <ContentBox>
            <div>
              <ContentTabset name="settings-tabs" defaultActiveTab={0}>
                <ContentTab
                  tabId={0}
                  tabsetName="settings-tabs"
                  tabName={i18next.t('System settings')}
                >
                  <SystemSettings />
                </ContentTab>
                <ContentTab
                  tabId={1}
                  tabsetName="settings-tabs"
                  tabName={i18next.t('Discount settings')}
                >
                  <DiscountSettings />
                </ContentTab>
              </ContentTabset>
            </div>
          </ContentBox>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('loadItem'),
  };
}

export default connect(mapStateToProps, {})(SettingsPage);
