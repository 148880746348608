import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Map } from 'immutable';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as responseStates from '../responseStates';
import PureComponent from './PureComponent';
import Alert, { AlertType } from '^/components/Alert';

export default class LiveButton extends PureComponent {
  getClassName() {
    return classNames('btn', this.props.additionalButtonClasses, {
      'btn-primary': !this.props.notPrimary,
    });
  }

  renderDefault() {
    const { onClick, disabled, buttonText, icon } = this.props;

    let props = {};

    if (onClick) {
      props.onClick = onClick;
    } else {
      props.type = 'submit';
    }

    if (disabled) {
      props.disabled = disabled;
    }

    return (
      <button {...props} className={this.getClassName()}>
        {icon && <FontAwesomeIcon icon={icon} />}
        {buttonText}
      </button>
    );
  }

  getErrors(response) {
    const errors = response.get('errors', Map());
    if (Map.isMap(errors)) {
      const NonFieldErrors = errors.get('non_field_errors', List());
      if (this.props.getAdditionalErrors) {
        return NonFieldErrors.concat(this.props.getAdditionalErrors(response));
      }
      return NonFieldErrors;
    }
    return errors;
  }

  render() {
    const {
      response,
      pendingMessage,
      successContent,
      hideErrors,
      failedClassName,
    } = this.props;

    switch (response && response.get('state')) {
      case responseStates.PENDING:
        return (
          <button className={this.getClassName()} disabled>
            {pendingMessage}
          </button>
        );
      case responseStates.FAILED:
        if (!hideErrors) {
          const errors = this.getErrors(response);
          if (errors.count()) {
            return (
              <span className={failedClassName}>
                {this.renderDefault()}
                {errors.map(error => (
                  <Alert
                    key={error}
                    className="mt-md mb-none"
                    type={AlertType.Error}
                    dangerouslySetInnerHTML={{ __html: error }}
                  />
                ))}
              </span>
            );
          }
        }
        return this.renderDefault();
      case responseStates.SUCCESSFUL:
        return successContent || this.renderDefault();
      default:
        return this.renderDefault();
    }
  }
}
