import classNames from 'classnames';
import React from 'react';
import { ReactNode } from 'react-redux';

interface OwnProps {
  label?: ReactNode;
  children: ReactNode;
  error?: ReactNode;
  htmlFor?: string;
  stacked?: boolean;
  wrapping?: boolean;
  className?: string;
  formItemClassName?: string;
  /** Renders an asterisk to the right of the label if it exists. */
  isRequired?: boolean;
}

type Props = OwnProps;

export const FormItem: React.FunctionComponent<Props> = ({
  label,
  children,
  stacked,
  wrapping,
  htmlFor,
  error,
  className,
  formItemClassName,
  isRequired,
}) => (
  <div className={className}>
    {label && (
      <label htmlFor={htmlFor}>
        {label}
        {isRequired ? '*' : ''}
      </label>
    )}
    <div
      className={classNames(
        'form-item',
        { stacked, wrapping },
        formItemClassName
      )}
    >
      {children}
      {error && <p className="text-error">{error}</p>}
    </div>
  </div>
);

export default FormItem;
