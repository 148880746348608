import i18next from 'i18next';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { ReportFilterSelections } from '^/actions/ui';
import { Activity, IdAndName, OrgFilters } from '^/reducers/api/types';
import { selectOrgFilters } from '^/selectors';
import {
  selectResponseAnyPending,
  selectResponseMoreToCome,
} from '^/selectors/responses';
import { selectReportFilterSelection } from '^/selectors/ui';
import { StoreState } from '^/store';
import { DeepReadonly } from '^/types';
import { isTruthy } from '^/utils';
import { SELECT_ALL_ID } from './constants';
import { BoolMap } from './types';
import { getIdArray } from './utils';

export const selectReportTableIsLoading = (state: StoreState) =>
  selectResponseAnyPending(state, [
    'loadOrgusers',
    'loadOrgSessions',
    'loadOrgProducts',
    'loadOrgFilters',
  ]) || selectResponseMoreToCome(state, 'loadOrgSessions');

const selectOrgFilterProp = (_state: any, orgFilter: keyof OrgFilters) =>
  orgFilter;

const selectOrgFilter = createCachedSelector(
  selectOrgFilters,
  selectOrgFilterProp,
  (orgFilters, orgFilter) => orgFilters && orgFilters[orgFilter]
)((_state, orgFilter) => orgFilter);

export const selectMappedSelectedOrgFilter = createCachedSelector(
  selectOrgFilter,
  selectReportFilterSelection,
  selectOrgFilterProp,
  (
    orgFilter: null | ReadonlyArray<IdAndName | Activity>,
    reportFilterSelection: ReportFilterSelections,
    orgFilterKey
  ) =>
    orgFilter
      ? orgFilter.map(item => ({
          ...item,
          selected: reportFilterSelection[orgFilterKey][item.id],
        }))
      : []
)((_state, orgFilter) => orgFilter);

export const selectAllOrgFilterBoolMap = createCachedSelector(
  selectOrgFilter,
  orgFilter =>
    getIdArray(orgFilter).reduce(
      (memo: BoolMap, item) => ({
        ...memo,
        [item]: true,
      }),
      {}
    )
)((_state, orgFilter) => orgFilter);

export const selectReportFilterSelectedCount = createCachedSelector(
  selectReportFilterSelection,
  selectOrgFilterProp,
  (reportFilterSelection: ReportFilterSelections, orgFilter) =>
    Object.values(reportFilterSelection[orgFilter] || {}).filter(isTruthy)
      .length
)((_state, orgFilter) => orgFilter);

export const createIsAllSelectedSelector = (
  selector: (state: StoreState) => DeepReadonly<Array<{ selected: boolean }>>
) =>
  createSelector(selector, selectedArray =>
    Boolean(selectedArray && selectedArray.every(item => item.selected))
  );

export const selectMappedSelectedGroups = (state: StoreState) =>
  selectMappedSelectedOrgFilter(state, 'groups') as ReadonlyArray<
    IdAndName & Readonly<{ selected: boolean }>
  >;

export const selectIsAllGroupsSelected = createIsAllSelectedSelector(
  selectMappedSelectedGroups
);

export const selectGroupsMultiItems = createSelector(
  selectIsAllGroupsSelected,
  allGroupsSelected => [
    {
      id: SELECT_ALL_ID,
      name: i18next.t<string>('Select all'),
      selected: allGroupsSelected,
    },
  ]
);

export const selectReportFilterDate = createSelector(
  selectReportFilterSelection,
  reportFilter => reportFilter.date
);

export const selectMappedSelectedProducts = (state: StoreState) =>
  selectMappedSelectedOrgFilter(state, 'products') as ReadonlyArray<
    IdAndName & Readonly<{ selected: boolean }>
  >;

export const selectIsAllProductsSelected = createIsAllSelectedSelector(
  selectMappedSelectedProducts
);

export const selectProductsMultiItems = createSelector(
  selectIsAllProductsSelected,
  allProductsSelected => [
    {
      id: SELECT_ALL_ID,
      name: i18next.t<string>('Select all'),
      selected: allProductsSelected,
    },
  ]
);
