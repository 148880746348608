import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import LiveButton from '^/components/LiveButton';
import { upgradeAccountTypeAndCloseModal } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import { ACCOUNT_TYPE_DISPLAY } from '^/models/organisation';
import Alert, { AlertType } from '^/components/Alert';

export class ConfirmUpgradeAccountTypeModal extends PureComponent {
  upgrade() {
    const { account, nextAccountType } = this.props;
    this.props.upgradeAccountTypeAndCloseModal(
      account.get('id'),
      nextAccountType
    );
  }

  render() {
    const { response, account, nextAccountType } = this.props;
    return (
      <div>
        <div>
          <Alert type={AlertType.Warning}>
            {i18next.t('This action cannot be reversed.')}
          </Alert>

          <p>
            {i18next.t('You have chosen to upgrade', {
              accountName: account.get('name'),
              currentAccountType:
                ACCOUNT_TYPE_DISPLAY[account.get('account_type')],
              nextAccountType: ACCOUNT_TYPE_DISPLAY[nextAccountType],
            })}
          </p>
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Cancel')}
            </button>
            <LiveButton
              buttonText={i18next.t('Confirm')}
              pendingMessage={i18next.t('Upgrading...')}
              response={response}
              onClick={() => this.upgrade()}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  upgradeAccountTypeAndCloseModal,
})(ConfirmUpgradeAccountTypeModal);
