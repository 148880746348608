import { Dispatch, Store } from 'react-redux';
import { AnyAction } from 'redux';

import { GET_USER_INFO } from '^/actions/actions';
import { openShopItemPurchaseConfirmationModal } from '^/actions/modals';
import { BasketItem } from '^/components/shop/basket/types';
import { selectBasketItems } from '^/components/shop/selectors';
import { StoreState } from '^/store';

const purchaseConfirmationMiddleware = (store: Store<StoreState>) => (
  next: Dispatch<StoreState>
) => (action: AnyAction) => {
  switch (action.type) {
    case GET_USER_INFO.SUCCESS:
      const basketItems = selectBasketItems(store.getState());
      const newBasketItems = action.payload.basketitem_set as ReadonlyArray<
        BasketItem
      >;
      const unpurchasedBasketItemIds = basketItems
        .filter(each => !each.purchased)
        .map(each => each.id);
      const newlyPurchasedBasketItems = newBasketItems.filter(
        each => each.purchased && unpurchasedBasketItemIds.includes(each.id)
      );

      if (newlyPurchasedBasketItems.length > 0) {
        store.dispatch(
          openShopItemPurchaseConfirmationModal(newlyPurchasedBasketItems)
        );
      }
      break;
    default:
      break;
  }
  return next(action);
};

export default purchaseConfirmationMiddleware;
