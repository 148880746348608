import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '../PureComponent';

export default class ExportSelect extends PureComponent {
  componentDidUpdate(prevProps) {
    const { disabled, items, onChange } = this.props;

    if (
      !disabled &&
      (items && items.size) !== (prevProps.items && prevProps.items.size)
    ) {
      if (items.size === 1) {
        onChange(items.get(0));
      }
    }
  }

  render() {
    const {
      selectName,
      disabled,
      onChange,
      items,
      blankOptionMessage,
      nameFunction,
    } = this.props;
    return (
      <div className="col-xs-12 col-md-6">
        <div className="form-group-block">
          <label>{selectName}</label>
          <div className="form-item">
            <select
              disabled={disabled || !items}
              onChange={evt =>
                onChange(
                  items.find(item => item.get('id') === evt.target.value)
                )
              }
              value={
                items && items.size === 1 ? items.getIn([0, 'id']) : undefined
              }
            >
              <option value="">{blankOptionMessage}</option>
              {items &&
                items.map(item => (
                  <option value={item.get('id')} key={item.get('id')}>
                    {nameFunction ? nameFunction(item) : item.get('name')}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

ExportSelect.propTypes = {
  selectName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  blankOptionMessage: PropTypes.string.isRequired,
  items: PropTypes.oneOfType([
    ImmutablePropTypes.list,
    ImmutablePropTypes.seq,
    PropTypes.bool,
  ]),
  nameFunction: PropTypes.func,
};
