import classNames from 'classnames';
import React from 'react';

export enum StatusType {
  Success,
  Info,
  Warning,
  Danger,
  Disabled,
}

export interface StatusPillProps {
  type: StatusType;
  className?: string;
  status: string;
}

const TYPE_CLASS_MAP = {
  [StatusType.Success]: 'pill-success',
  [StatusType.Warning]: 'pill-warning',
  [StatusType.Danger]: 'pill-danger',
  [StatusType.Disabled]: 'pill-disabled',
  [StatusType.Info]: 'pill-info',
};

export class StatusPill extends React.Component<StatusPillProps, {}> {
  public render() {
    return (
      <div
        className={classNames(
          'status-pill',
          this.getTypeClass(),
          this.props.className
        )}
      >
        {this.props.status}
      </div>
    );
  }

  private getTypeClass() {
    return TYPE_CLASS_MAP[this.props.type];
  }
}

export default StatusPill;
