import React from 'react'; // eslint-disable-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/pro-light-svg-icons/faCoffee';
import { faStroopwafel as falFaStroopwafel } from '@fortawesome/pro-light-svg-icons/faStroopwafel';
import { faStroopwafel as fasFaStroopwafel } from '@fortawesome/pro-solid-svg-icons/faStroopwafel';
import { faStroopwafel as farFaStroopwafel } from '@fortawesome/pro-regular-svg-icons/faStroopwafel';

import CodeSample from './CodeSample';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import PureComponent from '../PureComponent';

export default class Icons extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Font-Awesome Icons</PageHeader>
        <PageContent>
          <p>
            <FontAwesomeIcon icon={faCoffee} />
          </p>
          <h3>Different weights of same icon </h3>
          <p>
            <FontAwesomeIcon icon={fasFaStroopwafel} />
          </p>
          <p>
            <FontAwesomeIcon icon={farFaStroopwafel} />
          </p>
          <p>
            <FontAwesomeIcon icon={falFaStroopwafel} />
          </p>

          <p>
            Please see the{' '}
            <a href="https://github.com/FortAwesome/react-fontawesome#features">
              Font-Awesome readme
            </a>{' '}
            for usage instructions/styling etc.
          </p>
          {/* eslint-disable max-len */}
          <p>
            <strong>Please note:</strong> we are using the deep import syntax to
            stop importing the whole library - basically putting the specific
            icon name on the end of the import statement.
          </p>
          {/* eslint-enable max-len */}
          <CodeSample
            code={`
import { faCoffee } from '@fortawesome/pro-light-svg-icons/faCoffee';
import { faStroopwafel as falFaStroopwafel } from '@fortawesome/pro-light-svg-icons/faStroopwafel';
import { faStroopwafel as fasFaStroopwafel } from '@fortawesome/pro-solid-svg-icons/faStroopwafel';
import { faStroopwafel as farFaStroopwafel } from '@fortawesome/pro-regular-svg-icons/faStroopwafel';
....
<p><FontAwesomeIcon icon={faCoffee} /></p>
<h3>Different weights of same icon </h3>
<p><FontAwesomeIcon icon={fasFaStroopwafel} /></p>
<p><FontAwesomeIcon icon={farFaStroopwafel} /></p>
<p><FontAwesomeIcon icon={falFaStroopwafel} /></p>
          `}
          />
        </PageContent>
      </div>
    );
  }
}
