import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import classNames from 'classnames';
import { Range } from 'immutable';

import PureComponent from './PureComponent';
import { withPreventDefault } from '^/utils';
import { resetPageSize } from '^/actions/actions';

const PaginationLink = ({ onClick, label, disabled, active, isPageNumber }) => (
  <li key={label}>
    {!disabled ? (
      <a
        className={classNames(
          'pagination-control-link semi-bold-text',
          { active, disabled },
          isPageNumber && 'pagenumber'
        )}
        href="#"
        onClick={withPreventDefault(onClick)}
      >
        {label}
      </a>
    ) : (
      <span className="pagination-control-link semi-bold-text disabled">
        {label}
      </span>
    )}
  </li>
);

const PageLink = props => (
  <PaginationLink onClick={() => props.onSelectPage(props.page)} {...props} />
);

const NUMBER_OF_PAGES = 5;

class PageList extends PureComponent {
  getPageListStartPageNumber(activePage, numberOfPages, numberOfPagesToRender) {
    const idealStartPage = Math.max(
      activePage - Math.floor(numberOfPagesToRender / 2),
      1
    );
    return idealStartPage + numberOfPagesToRender - 1 <= numberOfPages
      ? idealStartPage
      : Math.max(1, numberOfPages - numberOfPagesToRender + 1);
  }

  render() {
    const { activePage, numberOfPages, onSelectPage } = this.props;
    const pageListStartPageNumber = this.getPageListStartPageNumber(
      activePage,
      numberOfPages,
      NUMBER_OF_PAGES
    );

    return (
      <ul className="pagination-controls">
        {Range(
          pageListStartPageNumber,
          pageListStartPageNumber + NUMBER_OF_PAGES
        ).map(
          pageNumber =>
            pageNumber <= numberOfPages && (
              <PageLink
                key={pageNumber}
                onSelectPage={onSelectPage}
                page={pageNumber}
                label={pageNumber}
                active={pageNumber === activePage}
                isPageNumber
              />
            )
        )}
      </ul>
    );
  }
}

export class ListPagePagination extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.resetPageSize();
  }

  renderPaginationControls(page, numberOfPages, isFirstPage, isLastPage) {
    const { onSelectPage } = this.props;
    return (
      <ul className="pagination-controls">
        <PageLink
          onSelectPage={onSelectPage}
          page={1}
          label={i18next.t('<< First')}
          disabled={isFirstPage}
        />
        <PageLink
          onSelectPage={onSelectPage}
          page={page - 1}
          label={i18next.t('< Prev')}
          disabled={isFirstPage}
        />
        <PageList
          onSelectPage={onSelectPage}
          activePage={page}
          numberOfPages={numberOfPages}
        />
        <PageLink
          onSelectPage={onSelectPage}
          page={page + 1}
          label={i18next.t('Next >')}
          disabled={isLastPage}
        />
        <PageLink
          onSelectPage={onSelectPage}
          page={numberOfPages}
          label={i18next.t('Last >>')}
          disabled={isLastPage}
        />
      </ul>
    );
  }

  renderShowing(firstItemNumber, lastItemNumber, count, numberOfPages) {
    const displayExtendedPaginationMessage = numberOfPages > 1;
    return (
      <span>
        {displayExtendedPaginationMessage
          ? i18next.t(
              'Showing {{firstItemNumber}} to {{lastItemNumber}} of {{count}} entries.',
              { firstItemNumber, lastItemNumber, count }
            )
          : i18next.t('Showing {{count}} entries.', { count })}
      </span>
    );
  }

  renderShowAll(onShowAll, showingAll) {
    const { onSelectPage } = this.props;
    return (
      onShowAll && (
        <span>
          {' '}
          {showingAll ? (
            <a
              onClick={withPreventDefault(() => onSelectPage(1))}
              className="semi-bold-text"
              href="#"
            >
              {i18next.t('Show paged view')}
            </a>
          ) : (
            <a
              onClick={withPreventDefault(onShowAll)}
              className="semi-bold-text"
              href="#"
            >
              {i18next.t('Show all')}
            </a>
          )}
        </span>
      )
    );
  }

  renderPageSizeOptions() {
    const { pageSize, pageSizeOptions, onChangePageSize } = this.props;
    return (
      onChangePageSize &&
      pageSizeOptions &&
      pageSizeOptions.length && (
        <span className="margin-right">
          <span>View </span>
          <ul className="pagination-controls tight-right">
            {pageSizeOptions.map(option => (
              <PaginationLink
                key={option}
                onClick={() => onChangePageSize(option)}
                label={option}
                active={option === pageSize}
                isPageNumber
              />
            ))}
          </ul>
          <span>per page</span>
        </span>
      )
    );
  }

  render() {
    const { collection, pageSize, onShowAll } = this.props;
    const { page, count, items } = collection.toObject();
    const showingAll = items && items.size === count && items.size > pageSize;

    if (!items) {
      return null;
    }

    const numberOfPages = Math.ceil(count / pageSize);
    const isFirstPage = page === 1;
    const isLastPage = numberOfPages === 0 || page === numberOfPages;

    const loadedItemsCount = items ? items.size : 0;
    const numberOfItems = (isLastPage && count % pageSize) || pageSize;
    const firstItemNumber =
      loadedItemsCount === 0 ? 0 : (page - 1) * pageSize + 1;
    const lastItemNumber = Math.max(firstItemNumber + numberOfItems - 1, 0);

    return (
      <div className="row">
        <div className="col-xs-12">
          {this.renderShowing(
            firstItemNumber,
            lastItemNumber,
            count,
            numberOfPages
          )}
          {onShowAll && this.renderShowAll(onShowAll, showingAll)}
          <span className="pull-right">
            {this.renderPageSizeOptions()}
            {this.renderPaginationControls(
              page,
              numberOfPages,
              isFirstPage,
              isLastPage
            )}
          </span>
        </div>
      </div>
    );
  }
}

export default connect(null, { resetPageSize })(ListPagePagination);
