import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';
import { Tabset, Tab } from '^/components/tabs';

export default class Tabs extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Heading Tabs</PageHeader>
        {/* eslint-disable max-len */}
        <p>
          These tabs are used in place of a heading, like on the dashboard, so
          are a slightly bigger size to match the Heading 3 style. Apply{' '}
          <code>className="tab-heading"</code> to a Tab component to achieve
          this style.
        </p>
        {/* eslint-enable max-len */}
        <PageContent>
          <Tabset defaultActiveTab={0}>
            <Tab tabId={0} title={'My activities'} className="tab-heading">
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab tabId={1} title={'Your activities'} className="tab-heading">
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
          </Tabset>

          <CodeSample
            code={`<Tabset defaultActiveTab={0}>
  <Tab tabId={0}
    title={'My activities'}
    className="tab-heading"
  >
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
    Maxime adipisci hic assumenda aut vero totam iusto consequatur?
    Natus sunt similique deleniti ex repellendus veniam, quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={1}
    title={'Your activities'}
    className="tab-heading"
  >
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
    Maxime adipisci hic assumenda aut vero totam iusto consequatur?
  </Tabset>
`}
          />
        </PageContent>

        <PageHeader>Tabs</PageHeader>
        <p>These tabs are used within containers like on a form or a card</p>
        <PageContent>
          <Tabset defaultActiveTab={0}>
            <Tab tabId={0} title={'My activities'}>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab tabId={1} title={'Your activities'}>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
          </Tabset>

          <CodeSample
            code={`<Tabset defaultActiveTab={0}>
  <Tab tabId={0}
    title={'My activities'}
  >
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
    Maxime adipisci hic assumenda aut vero totam iusto consequatur?
    Natus sunt similique deleniti ex repellendus veniam, quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={1}
    title={'Your activities'}
  >
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
    Maxime adipisci hic assumenda aut vero totam iusto consequatur?
  </Tabset>
`}
          />
        </PageContent>

        <PageHeader>Horizontal tabs</PageHeader>
        <p>These tabs are used within containers like on a form or a card</p>
        <PageContent>
          <Tabset
            className="vertical text-transform-initial"
            defaultActiveTab={0}
          >
            <Tab tabId={0} title={'Kims Group'} subheading={'ABC Associates'}>
              <p>
                Content goes here. Lorem, ipsum dolor sit amet consectetur
                adipisicing elit. Maxime adipisci hic assumenda aut vero totam
                iusto consequatur? Natus sunt similique deleniti ex repellendus
                veniam, quibusdam totam enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab
              tabId={1}
              title={'Big group containing everyone and has a long name'}
              subheading={'DabApps Ltd'}
            >
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab
              tabId={2}
              title={'Group name customized'}
              subheading={'DabApps Ltd'}
            >
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab
              tabId={3}
              title={'SP22 Sprint Group'}
              subheading={'Anderson, Johnson and Henderson'}
            >
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab tabId={4} title={'Top 5 users'} subheading={'ABC Associates'}>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
            <Tab
              tabId={5}
              title={'Happy people group'}
              subheading={'Anderson, Johnson and Henderson'}
            >
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime
                adipisci hic assumenda aut vero totam iusto consequatur? Natus
                sunt similique deleniti ex repellendus veniam, quibusdam totam
                enim fuga perferendis nam.
              </p>
            </Tab>
          </Tabset>

          <CodeSample
            code={`<Tabset className="vertical text-transform-initial" defaultActiveTab={0}>
  <Tab tabId={0} title={'Kims Group'} subheading={'ABC Associates'}>
    <p>Content goes here. Lorem, ipsum dolor sit amet consectetur adipisicing elit.
      Maxime adipisci hic assumenda aut vero totam iusto consequatur?
      Natus sunt similique deleniti ex repellendus veniam,
      quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={1} title={'Big group containing everyone and has a long name'} subheading={'DabApps Ltd'}>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
      Maxime adipisci hic assumenda aut vero totam iusto consequatur?
      Natus sunt similique deleniti ex repellendus veniam,
      quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={2} title={'Group name customized'} subheading={'DabApps Ltd'}>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
      Maxime adipisci hic assumenda aut vero totam iusto consequatur?
      Natus sunt similique deleniti ex repellendus veniam,
      quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={3} title={'SP22 Sprint Group'} subheading={'Anderson, Johnson and Henderson'}>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
      Maxime adipisci hic assumenda aut vero totam iusto consequatur?
      Natus sunt similique deleniti ex repellendus veniam,
      quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={4} title={'Top 5 users'} subheading={'ABC Associates'}>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
      Maxime adipisci hic assumenda aut vero totam iusto consequatur?
      Natus sunt similique deleniti ex repellendus veniam,
      quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
  <Tab tabId={5} title={'Happy people group'} subheading={'Anderson, Johnson and Henderson'}>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
      Maxime adipisci hic assumenda aut vero totam iusto consequatur?
      Natus sunt similique deleniti ex repellendus veniam,
      quibusdam totam enim fuga perferendis nam.</p>
  </Tab>
</Tabset>
`}
          />
        </PageContent>
      </div>
    );
  }
}
