import classNames from 'classnames';
import i18next from 'i18next';
import React, { PureComponent } from 'react';
import { FormikProps } from 'formik';

import OnOffToggle from '^/components/OnOffToggle';

interface OwnProps {
  disabled?: boolean;
  formik: FormikProps<Record<string, boolean>>;
}

type Props = OwnProps;

export class WelcomePageChecklistForm extends PureComponent<Props> {
  public render() {
    const { formik, disabled } = this.props;
    return (
      <div>
        {Object.entries(formik.values).map(([key, value]) => (
          <div className="form-item mb-base" key={key}>
            <label className={classNames({ 'bold-text': value }, 'clearfix')}>
              {key}
              <OnOffToggle
                className="pull-right"
                label={{
                  on: i18next.t<string>('Yes'),
                  off: i18next.t<string>('No'),
                }}
                secondary
                disabled={disabled}
                value={value}
                onChange={() => formik.setFieldValue(key, !value)}
              />
            </label>
          </div>
        ))}
      </div>
    );
  }
}

export default WelcomePageChecklistForm;
