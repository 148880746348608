import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table from '^/components/Table';
import PureComponent from '^/components/PureComponent';
import {
  getBulkDiscountBandCollections,
  addDiscountBandCollection,
} from '^/actions/collections';
import { isPending } from '^/responseStates';
import Loading from '^/components/Loading';
import {
  openEditBulkDiscountBandModal,
  openDeleteBulkDiscountBandModal,
  openAddBulkDiscountBandModal,
} from '^/actions/modals';
import { addDiscountBandCollectionAndOpenEditModal } from '^/actions/actionSequences';

export class DiscountSettings extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getBulkDiscountBandCollections();
  }

  renderActions(band) {
    return (
      <div>
        <a
          className="fa-link"
          title="edit"
          onClick={() => this.props.openEditBulkDiscountBandModal(band)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </a>
        <a
          className="fa-link"
          title="remove"
          onClick={() => this.props.openDeleteBulkDiscountBandModal(band)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
      </div>
    );
  }

  render() {
    const {
      bulkDiscountBandCollections,
      bulkDiscountBandCollectionsResponse,
    } = this.props;
    if (
      isPending(bulkDiscountBandCollectionsResponse) ||
      !bulkDiscountBandCollections
    ) {
      return <Loading />;
    }
    return (
      <div>
        <h3>{i18next.t('Discount settings')}</h3>
        <Table
          className="data-table"
          columns={[
            {
              header: i18next.t('Discount name'),
              value: band => band.get('name'),
            },
            {
              header: i18next.t('Action'),
              value: band => this.renderActions(band),
              className: 'nowrap',
            },
          ]}
          rows={bulkDiscountBandCollections}
        />

        <button
          className="btn btn-default pull-right"
          onClick={() => this.props.openAddBulkDiscountBandModal()}
        >
          <FontAwesomeIcon icon={faPlus} />
          {i18next.t('Create discount')}
        </button>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    bulkDiscountBandCollections: state.collections.getIn([
      'bulkDiscountBandCollections',
      'items',
    ]),
    bulkDiscountBandCollectionsResponse: state.multiResponses.getIn([
      'getCollection',
      'bulkDiscountBandCollections',
    ]),
  };
}

export default connect(mapStateToProps, {
  addDiscountBandCollection,
  getBulkDiscountBandCollections,
  openEditBulkDiscountBandModal,
  openDeleteBulkDiscountBandModal,
  openAddBulkDiscountBandModal,
  addDiscountBandCollectionAndOpenEditModal,
})(DiscountSettings);
