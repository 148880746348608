import classNames from 'classnames';
import React from 'react';

export interface Props {
  className?: string;
}

export const ProgressBarFake: React.FunctionComponent<Props> = ({
  className,
}) => (
  <div className={classNames('progress-bar fake', className)}>
    <div className="progress" />
  </div>
);

export default ProgressBarFake;
