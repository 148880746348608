import * as actions from '../actions/actions';

export function clearAllOnLogout(state = {}, action) {
  switch (action.type) {
    case actions.LOGIN:
    case actions.LOGOUT:
      return { logoutResponse: state.logoutResponse };
    default:
      return state;
  }
}

export function stashOnSudo(state = {}, action) {
  switch (action.type) {
    case actions.SUDO_LOGIN.SUCCESS:
      return {
        stash: Object.assign({}, state, {
          // Remove any currently open modals
          ui: state.ui.set('modalsConfig', []),
        }),
      };
    case actions.SUDO_LOGOUT:
      return state.stash;
    default:
      return state;
  }
}
