import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { isPending, hasFailed } from '^/responseStates';
import { ACTIVITY_STATUS } from '^/activityStatus';
import Table from '^/components/Table';
import Loading from '^/components/Loading';
import { formatDateTime } from '^/utils';
import { getActivityUsersCollectionName } from '^/actions/collections';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';
import { SURVEY_STATUS } from '^/models/user';
import { ACTIVITY_TYPE } from '^/models/activity';
import Alert from '^/components/Alert';
import { makeChoices } from '^/utils-ts';

export const PULSING_STATUSES = makeChoices([
  ['ENDING', i18next.t('Ending')],
  ['ENDED', i18next.t('Ended')],
  ['PULSING', i18next.t('Pulsing')],
  ['INVITED', i18next.t('Invited')],
  ['NOT_INVITED', i18next.t('Not invited')],
]);

export function getPulsingStatus(activityUser) {
  const firstUserPulse = activityUser.getIn(['userpulse_set', 0]);

  if (firstUserPulse) {
    const endDate = moment(firstUserPulse.get('end_date'), 'YYYY-MM-DD');
    if (moment().isAfter(endDate, 'day')) {
      return PULSING_STATUSES.CHOICES.ENDED;
    } else if (moment().isSameOrBefore(endDate, 'day')) {
      return PULSING_STATUSES.CHOICES.ENDING;
    }

    const hasBehaviours = firstUserPulse.get('has_userpulseselectedbehaviours');
    const hasRaters = firstUserPulse.get('has_userpulseraters');
    if (hasBehaviours && hasRaters) {
      return PULSING_STATUSES.CHOICES.PULSING;
    }
    return PULSING_STATUSES.CHOICES.INVITED;
  }
  return PULSING_STATUSES.CHOICES.NOT_INVITED;
}

export function getAllPulsingStatuses(activityUsers) {
  const usedStatuses = new Set(activityUsers.map(getPulsingStatus));
  return PULSING_STATUSES.ORDER.filter(status => usedStatuses.has(status));
}

export class UsersTable extends PureComponent {
  action(activityUser) {
    const isEnded = this.props.activity.get('status') === ACTIVITY_STATUS.ENDED;
    if (!activityUser.get('has_started')) {
      const userId = activityUser.get('user_id');
      const userName = activityUser.get('respondent');

      return this.props.isJobProfiler ? (
        !isEnded && (
          <a
            className="underlined"
            onClick={() => this.props.editUser(userId, userName)}
          >
            {i18next.t('Edit')}
          </a>
        )
      ) : (
        <a
          className="underlined"
          onClick={() => this.props.removeUser(userId, userName)}
        >
          {i18next.t('Remove')}
        </a>
      );
    }
  }

  render() {
    const {
      response,
      activity,
      activityId,
      activityUsers,
      readOnly,
      withPulse,
      isJobProfiler,
    } = this.props;
    const { is_anonymous: isAnonymous } = activity.toObject();

    if (!activityUsers) {
      return (
        <div className="loading-wrapper">
          <Loading />
        </div>
      );
    }

    const numberOfCompleted = activityUsers.filter(
      activityUser => activityUser.get('survey_status') === 'COMPLETED'
    ).size;

    if (hasFailed(response)) {
      return (
        <p className="text-error">
          {response.getIn(['errors', 'msg']) ||
            i18next.t('An error has occurred, please try again later.')}
        </p>
      );
    }

    if (activityUsers.isEmpty()) {
      return (
        <Alert>
          {i18next.t('No respondents have been added to the activity.')}
        </Alert>
      );
    }

    const anonymousCols = [
      {
        header: i18next.t('Identifier'),
        value: activityUser => activityUser.get('date_of_birth'),
        sortable: true,
      },
      {
        header: i18next.t('Respondent status date'),
        value: activityUser =>
          formatDateTime(activityUser.get('respondent_status_date'), '-'),
        sortable: true,
        sort: activityUser =>
          moment(
            activityUser.get('respondent_status_date') || moment.unix(1)
          ).unix(),
      },
      {
        header: i18next.t('Product type'),
        value: activityUser => activityUser.get('survey_type'),
        showIfFunc: () => activity.get('product_versions', []).size > 1,
        sortable: true,
      },
      {
        header: i18next.t('Product status'),
        value: activityUser =>
          activityUser.get('survey_status').replace('_', ' '),
        sortable: true,
      },
      {
        header: i18next.t('Product status date'),
        value: activityUser =>
          formatDateTime(activityUser.get('survey_status_date', false), '-'),
        sortable: true,
        sort: activityUser =>
          moment(
            activityUser.get('survey_status_date') || moment.unix(1)
          ).unix(),
      },
      {
        header: i18next.t('Action'),
        value: activityUser => this.action(activityUser),
      },
    ];

    const allCols = [
      {
        header: isJobProfiler
          ? i18next.t('Profile creator')
          : i18next.t('Respondent'),
        value: activityUser => (
          <Link to={`/page/individuals/${activityUser.get('user_id')}`}>
            {activityUser.get('respondent')}
          </Link>
        ),
        sortable: true,
        sort: activityUser => activityUser.get('respondent'),
      },
      {
        header: i18next.t('Email address'),
        value: activityUser => activityUser.get('email'),
      },
      {
        header: i18next.t('Respondent status'),
        value: activityUser => activityUser.get('respondent_status'),
        showIfFunc: () => activity.get('type') === ACTIVITY_TYPE.THREE_SIXTY,
        sortable: true,
      },
      {
        header: i18next.t('Respondent status date'),
        value: activityUser =>
          formatDateTime(activityUser.get('respondent_status_date'), '-'),
        sortable: true,
        showIfFunc: () => activity.get('type') === ACTIVITY_TYPE.THREE_SIXTY,
        sort: activityUser =>
          moment(
            activityUser.get('respondent_status_date') || moment.unix(1)
          ).unix(),
      },
      {
        header: i18next.t('Product type'),
        value: activityUser =>
          PRODUCT_TYPES.DISPLAY[activityUser.get('survey_type')],
        showIfFunc: () => activity.get('product_versions', []).size > 1,
        sortable: true,
      },
      {
        header: i18next.t('Product status'),
        value: activityUser =>
          SURVEY_STATUS.DISPLAY[activityUser.get('survey_status')],
        sortable: true,
      },
      {
        header: i18next.t('Product status date'),
        value: activityUser =>
          formatDateTime(activityUser.get('survey_status_date', false), '-'),
        sortable: true,
        sort: activityUser =>
          moment(
            activityUser.get('survey_status_date') || moment.unix(1)
          ).unix(),
      },
      {
        header: i18next.t('Pulsing'),
        showIfFunc: () => withPulse,
        className: 'pulsing-column',
        value: activityUser =>
          PULSING_STATUSES.DISPLAY[getPulsingStatus(activityUser)],
        sortable: true,
        sort: getPulsingStatus,
        filter: {
          key: 'pulse',
          values: getAllPulsingStatuses(activityUsers).map(status => ({
            id: status,
            name: PULSING_STATUSES.DISPLAY[status],
          })),
          getValue: getPulsingStatus,
        },
      },
      {
        header: i18next.t('Action'),
        value: activityUser => !readOnly && this.action(activityUser),
      },
    ];

    const columns = isAnonymous ? anonymousCols : allCols;
    const columnsToShow = columns.filter(
      column => !column.showIfFunc || column.showIfFunc()
    );

    return (
      <div>
        <p>Total number completed: {numberOfCompleted}</p>
        {isPending(response) && <Loading />}
        <Table
          id={activityId}
          columns={columnsToShow}
          rows={activityUsers}
          defaultSort={columns[0]}
        />
      </div>
    );
  }
}

UsersTable.propTypes = {
  activity: ImmutablePropTypes.map.isRequired,
  activityId: PropTypes.string.isRequired,
  removeUser: PropTypes.func,
  editUser: PropTypes.func,
  activityUsers: ImmutablePropTypes.list.isRequired,
};

function mapStateToProps(state, ownProps) {
  const collectionName = getActivityUsersCollectionName(ownProps.activityId);
  return {
    activityUsers: state.collections.getIn([collectionName, 'items']),
    response: state.multiResponses.get('getCollection').get(collectionName),
  };
}

export default connect(mapStateToProps)(UsersTable);
