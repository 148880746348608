import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import PureComponent from './PureComponent';

interface Props {
  className?: string;
}

export default class ContentBox extends PureComponent<
  PropsWithChildren<Props>
> {
  public render() {
    const { className, ...props } = this.props;

    return (
      <div
        {...props}
        className={classNames('col-xs-12 content-box', className)}
      >
        {this.props.children}
      </div>
    );
  }
}
