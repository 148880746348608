import i18next from 'i18next';
import * as _ from 'underscore';
import html2canvas from 'html2canvas';

import { RouterParamProps } from './withRouter';
import {
  LanguageCode,
  RTL_LANGUAGES,
  TRANSLATED_LANGUAGES,
} from './constants/routes';

export { capitalize } from './capitalize';

export const stateSetterFor = <C extends React.PureComponent>(
  reactClass: C
) => <K extends keyof C['state']>(key: K) => <V extends C['state'][K]>(
  value: V
) => reactClass.setState({ [key]: value });

export function getIn(
  item: { [i: string]: any } | undefined | null,
  path: string[],
  defaultItem?: any
): any {
  if (typeof item === 'undefined' || item === null) {
    return defaultItem;
  }

  if (path.length === 0) {
    return item;
  }

  const first = _.first(path);

  if (typeof first === 'undefined') {
    return defaultItem;
  }

  return getIn(item[first], _.rest(path), defaultItem);
}

export const extractTargetValue = <T>(event: { target: { value: T } }): T =>
  event.target.value;

export function compose<T, U, V>(f: (x: U) => V, g: (y: T) => U): (x: T) => V {
  return x => f(g(x));
}
export function pipe<T, U, V>(f: (x: T) => U, g: (y: U) => V): (x: T) => V {
  return x => g(f(x));
}

export interface Choices {
  CHOICES: { [key: string]: string };
  DISPLAY: { [key: string]: string };
  ORDER: string[];
}

export function makeChoices(choices: Array<[string, string]>): Choices {
  return {
    CHOICES: _.object(choices.map(([key]) => [key, key])),
    DISPLAY: _.object(choices),
    ORDER: choices.map(([key]) => key),
  };
}

export function setFind<T>(
  set: Set<T>,
  pred: (t: T) => boolean
): T | undefined {
  return Array.prototype.find.apply([...set], [pred]);
}

type CallbackFn<T> = (
  currentValue: T,
  currentIndex?: number,
  array?: readonly T[]
) => number;

export function sum(items: ReadonlyArray<number>): number;

export function sum<T>(
  items: ReadonlyArray<T>,
  callbackFn: CallbackFn<T>
): number;

export function sum<T>(items: ReadonlyArray<T>, callbackFn?: CallbackFn<T>) {
  if (callbackFn) {
    return items.reduce(
      (acc, item, index, array) => acc + callbackFn(item, index, array),
      0
    );
  }
  // function overload should have made sure that the item is a number by this point
  // and there's no nice way of telling typescript that the array is of string type
  return items.reduce((acc, item) => acc + ((item as unknown) as number), 0);
}

export function joinWithAnd(items: string[]) {
  if (items.length > 1) {
    return i18next.t<string>('{{things}} and {{finalThing}}', {
      things: items.slice(0, items.length - 1).join(', '),
      finalThing: items[items.length - 1],
    });
  }
  return items[0];
}

export function getQueryValue(props: RouterParamProps, key: string) {
  const queryValue = props.router.location.query[key];
  if (Array.isArray(queryValue)) {
    return queryValue[0];
  }
  return queryValue;
}

export function getMultipleQueryValue(props: RouterParamProps, key: string) {
  const queryValue = props.router.location.query[key];
  if (typeof queryValue === 'string') {
    return [queryValue];
  }
  return queryValue || [];
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function without<T>(set: Set<T>, value: T) {
  set.delete(value);
  return set;
}

export function intersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  return new Set<T>([...setA].filter(x => setB.has(x)));
}

export function union<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  return new Set<T>([...setA, ...setB]);
}

export function difference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  return new Set<T>([...setA].filter(item => !setB.has(item)));
}

export function removeItemWithIndexFromArray<T>(
  array: Array<T>,
  removeIndex: number
): Array<T> {
  return array.filter((_item: T, index: number) => index !== removeIndex);
}

export function toggle<T>(set: Set<T>, value: T) {
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
  return set;
}

export function compare<T>(a: T, b: T) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export const downloadElementImage = (
  elementID: string,
  fileName: string,
  customizeImageDownload: (document: Document, element: HTMLElement) => void,
  extraWidth?: number
) => {
  const data = document.getElementById(elementID);
  data &&
    html2canvas(data, {
      allowTaint: true,
      width: data.scrollWidth + (extraWidth || 0),
      scale: window.devicePixelRatio * 2,
      onclone: function(document) {
        return customizeImageDownload(document, data);
      },
    }).then(canvas => {
      const link = document.createElement('a');
      if (typeof link.download !== 'string') {
        window.open(canvas.toDataURL());
      } else {
        link.href = canvas.toDataURL() as string;
        link.download = fileName;
        link.click();
      }
    });
};

export const getClassNameForUrlPath = (path: string | null) => {
  if (path) {
    return path.includes('pulse') ? 'pulse' : null;
  }
  return null;
};

export const getConsentUrl = (userPulseId: string | undefined = undefined) => {
  const baseConsentUrl = 'consent/';
  if (userPulseId) {
    return baseConsentUrl + `?userpulse=${userPulseId}`;
  }
  return baseConsentUrl;
};

export const getLangCodeWithFallback = (
  langCode: LanguageCode | string,
  translatedLanguagesOverride?: string[] | undefined
) => {
  const translatedLanguages = translatedLanguagesOverride
    ? translatedLanguagesOverride
    : TRANSLATED_LANGUAGES;
  if (!langCode) {
    return translatedLanguages[0];
  }
  const directMatch = translatedLanguages.find(
    ownLangCode => ownLangCode.toLowerCase() === langCode.toLowerCase()
  );
  if (directMatch) {
    return directMatch;
  }
  const closeMatch = translatedLanguages.find(
    ownLangCode =>
      ownLangCode.split('-')[0].toLowerCase() ===
      langCode.split('-')[0].toLowerCase()
  );
  if (closeMatch) {
    return closeMatch;
  }
  return translatedLanguages[0];
};

export const getTextDirection = (langCode: LanguageCode) => {
  if (RTL_LANGUAGES.includes(langCode)) {
    return {
      dir: 'rtl',
    };
  }
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const constructUrl = (routerLocation: Location) =>
  `${routerLocation.pathname}${routerLocation.search}`;

export const getBrowserLanguage = () =>
  getLangCodeWithFallback(window.navigator.language);
