import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from './PureComponent';
import LiveButton from './LiveButton';
import FormError from './FormError';
import { matchesIsoFormat, matchesUkDateFormat, UK_DATE_FORMAT } from '^/utils';

function validateData(data) {
  const { date_of_birth } = data;
  if (!date_of_birth) {
    return { date_of_birth: 'You must enter your date of birth.' };
  } else if (
    !matchesIsoFormat(date_of_birth) &&
    !matchesUkDateFormat(date_of_birth)
  ) {
    return {
      date_of_birth: `Date of birth must be in format ${UK_DATE_FORMAT}.`,
    };
  }
  return {};
}

export class RedeemAnonymousForm extends PureComponent {
  render() {
    const { onSubmit, response } = this.props;

    return (
      <Formik
        initialValues={{ date_of_birth: '' }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
        validate={validateData}
      >
        {({ values, touched, errors, isSubmitting }) => (
          <Form className="form-inline">
            <p>
              Thank you for agreeing to participate in this research. To ensure
              that we can identify your data while maintaining your anonymity,
              please enter your date of birth.
            </p>

            <label>
              Date of birth
              <Field type="date" name="date_of_birth" id="date_of_birth" />
              <FormError response={response} formKey="date_of_birth" />
              {errors.date_of_birth && touched.date_of_birth && (
                <p className="text-error">{errors.date_of_birth}</p>
              )}
            </label>

            <LiveButton
              additionalButtonClasses="col-xs-12"
              response={response}
              disabled={!values.date_of_birth || isSubmitting}
              buttonText={i18next.t('Continue')}
              pendingMessage={i18next.t('Submitting details...')}
              successContent={<p>Loading activity...</p>}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default RedeemAnonymousForm;
