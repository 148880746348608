import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { administerOrganisations, can } from '^/capabilities';
import { FilterProfileDetail } from '^/reducers/api/types';
import { StoreState } from '^/store';
import CreateEditProfileDetailsForm, {
  CreateEditProfileDetailsFormProps,
} from './CreateEditProfileDetailsForm';

interface OwnProps {
  initialData: CreateEditProfileDetailsFormProps;
  existingProfile: FilterProfileDetail;
  onSubmit: (data: CreateEditProfileDetailsFormProps) => void;
}

interface StateProps {
  user: Map<string, any>;
  saveACopyResponse: Map<string, any>;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class AdvancedAnalyticsSaveACopyModal extends React.PureComponent<
  Props
> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      saveACopyResponse,
      initialData,
      existingProfile,
      user,
      onSubmit,
    } = this.props;

    return (
      <div>
        <CreateEditProfileDetailsForm
          response={saveACopyResponse}
          userFullName={existingProfile.created_by.full_name}
          initialValues={{
            name: `${initialData.name} COPY`,
            created_by: initialData.created_by,
            product: initialData.product,
            organisation: initialData.organisation,
          }}
          canChooseOrg={can(user, administerOrganisations())}
          saveACopy={onSubmit}
          closeTopModal={this.props.closeTopModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  user: state.userProfile,
  saveACopyResponse: state.responses.get(
    'createAdvancedAnalyticsFilterProfile'
  ),
});

export default connect(mapStateToProps, { closeTopModal })(
  AdvancedAnalyticsSaveACopyModal
);
