import { push } from 'react-router-redux';

import { setLanguageAndReload } from '^/actions/actionSequences';
import { getBrowserLanguage } from '^/utils-ts';
import * as actions from '../actions/actions';

export default store => next => action => {
  switch (action.type) {
    case actions.LOGIN.SUCCESS:
      store.dispatch(push('/page/home'));
      break;

    case actions.LOGOUT:
      if (!action.skipRedirect) {
        store.dispatch(setLanguageAndReload(getBrowserLanguage(), '/#/login/'));
      }
      break;
  }

  return next(action);
};
