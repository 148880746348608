import React from 'react';

import CloseModalButton from '^/components/modals/CloseModalButton';

const CloseButtonRight = () => (
  <div className="pull-right">
    <CloseModalButton />
  </div>
);

export default CloseButtonRight;
