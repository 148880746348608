import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from '../PureComponent';
import LiveButton from '../LiveButton';
import Img from '../Img';
import FormError from '../FormError';
import PictureUpload from '../PictureUpload';

export class EditOrganisationForm extends PureComponent {
  render() {
    const { onSubmit, response, originalLogo, initialValues } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-horizontal">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <label>
                  Brand logo
                  <PictureUpload
                    src={
                      values.brand_logo &&
                      (values.brand_logo.preview || values.brand_logo)
                    }
                    display={src => (
                      <Img
                        src={
                          src ||
                          originalLogo ||
                          '/static/img/icons/accounts.png'
                        }
                      />
                    )}
                    setPendingUpload={file => {
                      setFieldValue('brand_logo', file);
                    }}
                  />
                </label>
              </div>

              <div className="col-xs-12 col-md-6">
                <label htmlFor="edit-org-name">
                  {i18next.t('Name')}
                  <Field
                    id="edit-org-name"
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                </label>
                <FormError response={response} formKey="name" />

                <label htmlFor="edit-org-description">
                  {i18next.t('Description')}
                  <Field
                    id="edit-org-description"
                    type="text"
                    placeholder="Description"
                    name="description"
                  />
                </label>
                <FormError response={response} formKey="description" />
              </div>
            </div>

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <LiveButton
                  pendingMessage={i18next.t('Saving organisation...')}
                  buttonText={i18next.t('Save')}
                  response={response}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default EditOrganisationForm;
