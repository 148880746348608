import React from 'react';

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 7.5C2.5 6.8125 3.03125 6.25 3.75 6.25H12.25C12.9375 6.25 13.5 6.8125 13.5 7.5V8.5C13.5 9.21875 12.9375 9.75 12.25 9.75H3.75C3.03125 9.75 2.5 9.21875 2.5 8.5V7.5ZM3.5 8.5C3.5 8.65625 3.59375 8.75 3.75 8.75H12.25C12.375 8.75 12.5 8.65625 12.5 8.5V7.5C12.5 7.375 12.375 7.25 12.25 7.25H3.75C3.59375 7.25 3.5 7.375 3.5 7.5V8.5ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1C4.125 1 1 4.15625 1 8C1 11.875 4.125 15 8 15C11.8438 15 15 11.875 15 8C15 4.15625 11.8438 1 8 1Z"
      fill="#662D91"
    />
  </svg>
);
