import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';

import { sudoLogoutAndGoHome } from '../actions/actionSequences';
import PureComponent from './PureComponent';

export class Topbar extends PureComponent {
  render() {
    return this.props.superuser ? (
      <div className="topbar">
        <span>
          <b>{this.props.superuser.get('email')}</b>{' '}
          {i18next.t('temporarily logged in as')}{' '}
          <b>{this.props.user.get('email')}</b>
        </span>
        <a className="pull-right" onClick={this.props.sudoLogoutAndGoHome}>
          Stop <FontAwesomeIcon icon={faTimesCircle} size="lg" />
        </a>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    superuser: state.stash && state.stash.userProfile,
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, { sudoLogoutAndGoHome })(Topbar);
