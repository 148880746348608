import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from './PureComponent';

export default class Email extends PureComponent {
  render() {
    const { email } = this.props;
    return email ? (
      <a href={`mailto:${email}`}>{email}</a>
    ) : (
      <span>{i18next.t('No Email Provided')}</span>
    );
  }
}
