import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import i18next from 'i18next';

import { updateActivityUser, updateRater } from '^/actions/actions';
import { getAllMyActivities } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import RadioButton from '^/components/RadioButton';
import ControlBar from '^/components/ControlBar';
import { APPROVAL_STATUS } from '^/approvalStatus';
import { isLineManager } from '^/rater';
import { isPending } from '^/responseStates';
import { withRouter } from '^/withRouter';

class ApproveUserRatersPage extends PureComponent {
  componentDidMount() {
    if (!this.getActivity()) {
      this.props.getAllMyActivities();
    }
  }

  getActivity() {
    const activities = this.props.activities.get('items', List());

    return activities.find(
      activity => activity.get('id') === this.props.activityId
    );
  }

  navigateToRaters() {
    this.props.push(`/page/dashboard/raters/approve/${this.props.activityId}`);
  }

  submitApprovals(activityId, anyRejected) {
    if (anyRejected) {
      this.props.updateActivityUser(activityId, this.props.userId, {
        raters_submitted: false,
      });
    }
    this.navigateToRaters();
  }

  updateRater(rater, data) {
    this.props.updateRater(
      this.props.activityId,
      this.props.userId,
      rater.getIn(['rater', 'id']),
      data
    );
  }

  approve(rater) {
    this.updateRater(rater, {
      approved_by_line_manager: APPROVAL_STATUS.APPROVED,
    });
  }

  reject(rater) {
    this.updateRater(rater, {
      approved_by_line_manager: APPROVAL_STATUS.REJECTED,
    });
  }

  renderApprovalStatus(rater) {
    const approvalStatus = rater.get('approved_by_line_manager');
    const approved = approvalStatus === APPROVAL_STATUS.APPROVED;
    const rejected = approvalStatus === APPROVAL_STATUS.REJECTED;

    const isSaving =
      isPending(this.props.updateNomResponse) ||
      isPending(this.props.activitiesResponse);

    return (
      <div className="rater-approval-controls">
        <RadioButton
          checked={approved}
          disabled={isSaving}
          onChange={() => this.approve(rater)}
          labelClassname="text-success"
          id="approve"
          label={i18next.t('Approve')}
        />
        <RadioButton
          checked={rejected}
          disabled={isSaving}
          onChange={() => this.reject(rater)}
          labelClassname="text-danger"
          id="reject"
          label={i18next.t('Reject')}
        />
      </div>
    );
  }

  renderRaters(activity) {
    const userId = this.props.userId;
    const user = activity
      .get('direct_reports')
      .filter(each => each.getIn(['user', 'id']) === userId)
      .first();
    const ratersNotLineManagers = user.get('raters').filterNot(isLineManager);

    const anyRaterIs = status =>
      ratersNotLineManagers.some(
        rater => rater.get('approved_by_line_manager') === status
      );

    const isSaving =
      isPending(this.props.updateNomResponse) ||
      isPending(this.props.activitiesResponse);

    if (!user) {
      return null;
    }

    return (
      <div className="col-xs-12">
        <div className="row">
          <table className="rater-approval">
            <tbody>
              {ratersNotLineManagers
                .sortBy(rater =>
                  (
                    rater.getIn(['rater', 'full_name'], '') +
                    rater.getIn(['rater', 'email'], '')
                  ).toLowerCase()
                )
                .map(rater => (
                  <tr key={rater.getIn(['rater', 'email'])}>
                    <td>{rater.getIn(['role', 'name'])}</td>
                    <td>
                      {rater.getIn(['rater', 'full_name'])} (
                      <a href={`mailto:${rater.getIn(['rater', 'email'])}`}>
                        {rater.getIn(['rater', 'email'])}
                      </a>
                      )
                    </td>
                    <td>{this.renderApprovalStatus(rater)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <button
              className="btn btn-primary"
              onClick={() => this.navigateToRaters()}
            >
              {i18next.t('Back')}
            </button>
          </div>
          <div className="col-xs-6">
            <button
              className="btn btn-primary pull-right"
              onClick={() =>
                this.submitApprovals(
                  activity.get('id'),
                  anyRaterIs(APPROVAL_STATUS.REJECTED)
                )
              }
              disabled={
                anyRaterIs(APPROVAL_STATUS.AWAITING_APPROVAL) || isSaving
              }
            >
              {i18next.t('Submit approvals')}
            </button>
            {isSaving && (
              <FontAwesomeIcon
                title="Loading"
                className="pull-right"
                icon={faSpinnerThird}
                spin
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const activity = this.getActivity();
    let userName = 'user';

    if (activity) {
      const user = activity
        .get('rater_for', List())
        .find(userf => userf.get('id') === this.props.userId, this, Map());
      userName = user.get('full_name', null) || user.get('email', 'user');
    }
    return (
      <div>
        <ControlBar
          title={i18next.t('Approve raters for {{userName}}', { userName })}
          hideAdd
          hideSearch
        />

        {activity ? this.renderRaters(activity) : <Loading />}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activityId: props.router.params.activity,
    userId: props.router.params.user,
    activities: state.collections.get('myActivities', Map()),
    updateNomResponse: state.responses.get('updateRater'),
    activitiesResponse: state.responses.get('getCollection'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateRater,
    getAllMyActivities,
    push,
    updateActivityUser,
  })(ApproveUserRatersPage)
);
