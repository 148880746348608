import React from 'react'; // eslint-disable-line no-unused-vars
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import Loading from '../Loading';
import ActivityWithReports from './ActivityWithReports';
import {
  can,
  administerOrganisations,
  downloadExistingReports,
} from '../../capabilities';

export default class UserReports extends PureComponent {
  render() {
    const { activities, user, activeUser } = this.props;

    if (!activities) {
      return <Loading className="list-loading" />;
    }

    if (activities.isEmpty()) {
      return (
        <div className="table-info">
          {i18next.t('User does not belong to any activities.')}
        </div>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th>{i18next.t('Activity')}</th>
            {can(activeUser, administerOrganisations()) ? (
              <th>Organisation</th>
            ) : null}
            {can(activeUser, downloadExistingReports()) && (
              <th>{i18next.t('Reports')}</th>
            )}
            <th>{i18next.t('Start')}</th>
            <th>{i18next.t('Close')}</th>
            <th>{i18next.t('Activity completion status')}</th>
          </tr>
        </thead>
        <tbody>
          {activities.map(activity => (
            <ActivityWithReports
              key={activity.get('id')}
              activity={activity}
              activeUser={activeUser}
              user={user}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

UserReports.propTypes = {
  activities: ImmutablePropTypes.list.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  activeUser: ImmutablePropTypes.map.isRequired,
};
