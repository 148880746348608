import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from '^/components/PureComponent';
import FormError from '^/components/FormError';
import { TAX_LOCATION } from '^/models/organisation';

export class EditTaxDetailsForm extends PureComponent {
  render() {
    const { onSubmit, response, onCancel, initialValues } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-inline">
            <div>
              <label>{i18next.t('Tax location')}</label>
              <div className="form-item">
                <Field
                  as="select"
                  id="tax_location"
                  name="tax_location"
                  onChange={event => {
                    const value = event.target.value;
                    if (value === TAX_LOCATION.CHOICES.REST_OF_WORLD) {
                      setFieldValue('tax_registration_number', '');
                    }
                    setFieldValue('tax_location', value);
                  }}
                >
                  <option value="" disabled>
                    {i18next.t('Please select...')}
                  </option>
                  {TAX_LOCATION.ORDER.map(each => (
                    <option key={each} value={each}>
                      {TAX_LOCATION.DISPLAY[each]}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div>
              <label>{i18next.t('Tax registration number')}</label>
              <div className="form-item">
                <Field
                  id="tax_registration_number"
                  name="tax_registration_number"
                  type="text"
                  placeholder={i18next.t('Tax registration number')}
                  disabled={
                    !values.tax_location ||
                    values.tax_location === TAX_LOCATION.CHOICES.REST_OF_WORLD
                  }
                />
                <FormError
                  response={response}
                  formKey="tax_registration_number"
                />
              </div>
            </div>
            <div className="modal-footer clearfix">
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={onCancel}
                >
                  {i18next.t('Cancel')}
                </button>
                <button className="btn btn-primary" type="submit">
                  {i18next.t('Save')}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default EditTaxDetailsForm;
