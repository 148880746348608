import i18next from 'i18next';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

import Alert from '^/components/Alert';
import CostAndAddToBasket from '^/components/shop/CostAndAddToBasket';
import { ShopItem, ShopItemOptionType } from '^/reducers/api/types';
import { StoreState } from '^/store';
import { selectIsAccreditationMissingForShopItemProduct } from './selectors';

interface OwnProps {
  shopItem: ShopItem;
}

interface StateProps {
  isAccreditationMissingForShopItemProduct: boolean;
}

type Props = OwnProps & StateProps;

export const ChooseShopItemVerificationTypeModal: React.FunctionComponent<Props> = props => {
  const { shopItem, isAccreditationMissingForShopItemProduct } = props;
  return (
    <div>
      <h2>
        {i18next.t<string>('Choose from the options below', {
          shopItemName: shopItem.name,
        })}
      </h2>

      <div>
        {shopItem.options.map(option => (
          <div key={option.id} className="shop-modal-item">
            <span>
              <h3 className="mt-md mb-sm">
                <ReactMarkdown
                  className="children-mb-0"
                  source={option.label || ''}
                />
              </h3>
              <ReactMarkdown
                className="children-mb-0"
                source={option.description || ''}
              />
              {isAccreditationMissingForShopItemProduct &&
              option.type === ShopItemOptionType.Product &&
              option.product_variant === null ? (
                <Alert className="mt-sm">
                  {i18next.t<string>(
                    'PARAGRAPH Your account must hold accreditation'
                  )}
                </Alert>
              ) : (
                <CostAndAddToBasket option={option} />
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: StoreState,
  ownProps: OwnProps
): StateProps => ({
  isAccreditationMissingForShopItemProduct: selectIsAccreditationMissingForShopItemProduct(
    state,
    ownProps.shopItem
  ),
});

export default connect(mapStateToProps)(ChooseShopItemVerificationTypeModal);
