import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map, List, Set } from 'immutable';
import i18next from 'i18next';

import { setFilter } from '../../actions/actions';
import PureComponent from '../PureComponent';
import UsersListPage from './UsersListPage';

export class SelectUsersPage extends PureComponent {
  selectAll() {
    this.props.users
      .get('items', List())
      .forEach(user => this.props.onSetSelected(user, true));
  }

  getUserRowProps(user) {
    const {
      current,
      onSelect,
      selectOne,
      allowRemove,
      selectedUsers,
      maxNumMoreUsers,
    } = this.props;
    const userId = user.get('id');

    const cannotRemoveAndAlreadyChosen =
      !allowRemove && current && current.contains(userId);
    const newlySelectedUsers = selectedUsers.subtract(current);
    const maxNumUsersAlreadySelected =
      maxNumMoreUsers !== undefined &&
      newlySelectedUsers.count() >= maxNumMoreUsers;
    const isSelected = selectedUsers.contains(userId);

    return {
      disabled:
        cannotRemoveAndAlreadyChosen ||
        (maxNumUsersAlreadySelected && !isSelected),
      isSelectable: true,
      isSelected,
      onSelect: () => onSelect(user),
      selectOne,
    };
  }

  render() {
    const {
      users,
      filter,
      filterSpec,
      selectOne,
      noLoad,
      groups,
      selectedUsers,
      hideGroups,
      hideOrganisations,
      hideProfilePicture,
      getUsers,
      showInlineForm,
      maxNumMoreUsers,
      pageSize,
      fetchAll,
    } = this.props;

    return (
      <UsersListPage
        users={users}
        title={selectOne ? i18next.t('Select user') : i18next.t('Select users')}
        isSelectModal
        fetchAll={fetchAll}
        hideControlBar
        pageSize={pageSize}
        simple
        scrollable
        className={showInlineForm && 'table-scrollable-wrapper-add-users'}
        selectOne={selectOne}
        noLoad={noLoad}
        onSelectAll={this.selectAll.bind(this)}
        onClearAll={this.props.onClearAll}
        hideFilters={filter && Object.keys(filter)}
        filterSpec={Object.assign({}, filterSpec, filter)}
        haveFiltersChanged={JSON.stringify(filter)}
        onFilterChange={this.props.setFilter}
        getUserRowProps={user => this.getUserRowProps(user)}
        userIsSelected={user => selectedUsers.contains(user.get('id'))}
        groups={groups}
        hideGroups={hideGroups}
        hideOrganisations={hideOrganisations}
        hideProfilePicture={hideProfilePicture}
        getUsers={getUsers}
        maxNumMoreUsers={maxNumMoreUsers}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    filterSpec: state.ui.get('filterSpec'),
    users:
      ownProps.users ||
      state.collections.get(ownProps.collectionName || 'users', Map()),
    selectedUsers: state.ui.get('selectedUiComponents').get('users', Set()),
  };
}

export default connect(mapStateToProps, { setFilter })(SelectUsersPage);
