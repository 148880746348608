import { List } from 'immutable';
import { createSelector } from 'reselect';

import { selectCollections, simpleOrganisation } from '^/selectors';

export const selectCollectionsOrganisationsItems = createSelector(
  selectCollections,
  collections => collections.getIn(['organisations', 'items'], List())
);

export const selectCollectionsOrganisationOptionsItems = createSelector(
  selectCollections,
  collections => collections.getIn(['organisationOptions', 'items'], List())
);

export const selectSimpleOrganisation = createSelector(
  simpleOrganisation,
  org => org
);
