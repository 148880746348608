import i18next from 'i18next';
import React from 'react';

import Dropdown from '^/components/dropdown/Dropdown';
import { IdAndName } from '^/reducers/api/types';

export interface ListingTableFiltersState {
  filters: { [key: string]: string[] };
}

export interface OwnProps {
  filters: ReadonlyArray<{
    key: string;
    name: string;
    values: ReadonlyArray<IdAndName> | undefined;
  }>;
  state: ListingTableFiltersState;
  onStateChange: (newState: Partial<ListingTableFiltersState>) => void;
}

type Props = OwnProps;

export default class ListingTableFilters extends React.PureComponent<Props> {
  public render() {
    return (
      <div className="row top-filters-pane">
        {this.props.filters.map(({ key, name, values }) => (
          <div className="form-block col-xs-6" key={key}>
            <div className="filters">
              <label>{name}</label>
              <Dropdown
                title={name}
                selectedTitle={this.getTitle(key)}
                isNoneSelected={false}
                emptyTitle={'None available'}
                selectable
                items={values || null}
                selectedItems={this.props.state.filters[key]}
                onClick={this.onFilterChange.bind(this, key)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  private getTitle(key: string) {
    const selected = this.props.state.filters[key];
    const count = selected?.length;
    if (count) {
      if (count === 1) {
        return this.props.filters
          .find(filter => filter.key === key)
          ?.values?.find(value => value.id === selected[0])?.name;
      }
      return i18next.t<string>('{{count}} selected', { count });
    }
    return i18next.t<string>('All');
  }

  private onFilterChange = (key: string, value: string | number | null) => {
    const {
      state: { filters },
    } = this.props;
    const existingFilters = filters[key] || [];
    const newValue = (value as string) || '';
    let newFilters;
    if (existingFilters.includes(newValue)) {
      newFilters = existingFilters;
      newFilters.splice(existingFilters.indexOf(newValue), 1);
    } else {
      newFilters = existingFilters.concat([newValue]);
    }
    this.props.onStateChange({
      filters: { ...filters, [key]: newFilters },
    });
  };
}
