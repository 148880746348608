import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import { deleteDiscountBandAndCloseModal } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import Alert from '^/components/Alert';

export class DeleteBulkDiscountBandModal extends PureComponent {
  render() {
    const { bulkDiscountBandCollection } = this.props;
    const { id } = bulkDiscountBandCollection.toObject();
    return (
      <div>
        <Alert>{i18next.t('PARAGRAPH confirmBandDeletion')}</Alert>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn"
              type="button"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Cancel')}
            </button>
            <button
              className="btn btn-primary pull-right"
              type="submit"
              onClick={() => this.props.deleteDiscountBandAndCloseModal(id)}
            >
              {i18next.t('Confirm')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  closeTopModal,
  deleteDiscountBandAndCloseModal,
})(DeleteBulkDiscountBandModal);
