import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import DashIcon from './DashIcon';

export type StatusType = 'active' | 'disabled' | 'hyphen';

interface Props {
  status: StatusType;
}

export const PulseBrandStatusMark: React.FunctionComponent<Props> = ({
  status,
}) => {
  return (
    <>
      {status === 'hyphen' ? (
        <span className={classNames('pulse-status', status)}>
          <DashIcon />
        </span>
      ) : (
        <FontAwesomeIcon
          className={classNames('pulse-status', status)}
          icon={faCheckCircle}
        />
      )}
    </>
  );
};

export default PulseBrandStatusMark;
