import React from 'react'; // eslint-disable-line no-unused-vars
import moment from 'moment';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import classnames from 'classnames';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatDateTime, formatDate } from '^/utils';
import { isPending } from '^/responseStates';
import {
  USER_ROLES,
  EMAIL_TYPES,
  NOTIFICATION_LOG_TYPES,
  EMAIL_TRIGGERS,
  canOnlySendNotificationOnce,
} from '^/models/notification';
import {
  closeTopModal,
  openConfirmationModal,
  openNotificationEmailEditModal,
} from '^/actions/modals';
import { muteActivityNotification } from '^/actions/actions';
import { updateActivity } from '^/actions/collections';
import { resendEmailAndShowModal } from '^/actions/actionSequences';
import OnOffToggle from '^/components/OnOffToggle';
import PureComponent from '^/components/PureComponent';
import Editable from '^/components/editable/Editable';

function getAlreadySentDate(notificationLogType, activity) {
  const sentDate = activity
    .get('notifications', List())
    .find(
      notification => notification.get('action') === notificationLogType,
      null,
      Map()
    )
    .get('created', false);
  return sentDate ? formatDateTime(sentDate) : sentDate;
}

function forBlurb(userRole) {
  switch (userRole) {
    case USER_ROLES.CHOICES.REPORT_RECIPIENTS: {
      return i18next.t('any additional nominated recipients (if relevant),');
    }
    default:
      return null;
  }
}

export function fieldsMustBeAscending(object, keys, key) {
  const index = keys.indexOf(key);
  let minAndMax = { min: 0 };
  if (index > 0) {
    minAndMax.min = object.get(keys[index - 1]);
  }
  if (index < keys.length - 1) {
    minAndMax.max = object.get(keys[index + 1]);
  }
  return minAndMax;
}

export class Notification extends PureComponent {
  getDescription(roleOfRecipient, notification, trigger, activity, sentDate) {
    const { response, readOnly } = this.props;
    const activityDateTime = key => formatDate(activity.get(key));
    const triggerForTriggerDisplay = EMAIL_TRIGGERS.DISPLAY[trigger];
    const activity_end = activity.get('end_datetime', null);

    const renderEditableNotification = fieldName => {
      if (roleOfRecipient === 'Raters') {
        roleOfRecipient = i18next.t('all Raters yet to complete feedback');
      }

      const minAndMax = fieldsMustBeAscending(
        activity,
        ['chaser_days_before', 'pre_chaser_days_before', 'midway_days_before'],
        fieldName
      );

      const value = activity.get(fieldName);
      const willSendDate =
        moment(activity.get('end_datetime')) - moment.duration(value, 'days');
      const notificationLogType =
        NOTIFICATION_LOG_TYPES[notification.get('type')];
      const sentNotification = activity
        .get('notifications')
        .find(each => each.get('action') === notificationLogType);

      const sendDate = sentNotification
        ? i18next.t('sent on {{date}}', {
            date: formatDate(sentNotification.get('created')),
          })
        : i18next.t('sends on {{date}}', { date: formatDate(willSendDate) });

      const editableValue = (
        <Editable
          type="number"
          value={value}
          {...minAndMax}
          fieldName={fieldName}
          onSave={newValue =>
            this.props.updateActivity(activity.get('id'), {
              [fieldName]: newValue,
            })
          }
          response={
            response &&
            response.getIn(['data', fieldName]) !== undefined &&
            response
          }
          readOnly={sentNotification || readOnly}
        />
      );

      return (
        <Trans i18nKey="TRANS notificationSendsWhenOnDate">
          Sends to {{ roleOfRecipient }} {editableValue} days before close date
          ({{ sendDate }}).
        </Trans>
      );
    };

    if (
      notification.get('type') ===
      EMAIL_TYPES.CHOICES.ACTIVITY_NOMINATIONS_CHASER
    ) {
      return i18next.t(
        'Sends to {{roleOfRecipient}} who has not yet submitted Raters, every 48hrs.',
        { roleOfRecipient }
      );
    }

    if (
      notification.get('type') === EMAIL_TYPES.CHOICES.SURVEY_STARTED_PROFILER
    ) {
      return i18next.t('Sends to {{roleOfRecipient}} when profile started.', {
        roleOfRecipient,
      });
    }
    if (
      notification.get('type') === EMAIL_TYPES.CHOICES.SURVEY_COMPLETED_PROFILER
    ) {
      return i18next.t('Sends to {{roleOfRecipient}} when profile created.', {
        roleOfRecipient,
      });
    }

    switch (trigger) {
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_START: {
        if (roleOfRecipient === 'Raters') {
          return i18next.t(
            'Sends to all Respondents, Line Managers and Raters once submitted (and approved)'
          );
        }

        const startDate = activityDateTime('start_datetime');
        return i18next.t(
          'Sends to {{roleOfRecipient}} when the {{trigger}} on {{date}}.',
          {
            trigger: triggerForTriggerDisplay,
            date: sentDate || startDate,
            roleOfRecipient,
          }
        );
      }
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_MIDWAY:
        return renderEditableNotification('midway_days_before');
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_7D_BEFORE_END:
        return renderEditableNotification('pre_chaser_days_before');
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_24H_BEFORE_END:
        return renderEditableNotification('chaser_days_before');
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_END: {
        const when = formatDate(moment(activity_end).subtract(48, 'hours'));
        return i18next.t(
          'Sends to {{roleOfRecipient}} 48hrs before the {{trigger}} on {{date}}.',
          {
            trigger: triggerForTriggerDisplay,
            date: sentDate || when,
            roleOfRecipient,
          }
        );
      }
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_REPORTS_READY: {
        return i18next.t(
          'Sends to {{roleOfRecipient}} when {{trigger}} as notification that the report/s are now available.',
          { trigger: triggerForTriggerDisplay, roleOfRecipient }
        );
      }
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_RATERS_REJECTED:
      case EMAIL_TRIGGERS.CHOICES.ACTIVITY_RATERS_SUBMITTED:
      case EMAIL_TRIGGERS.CHOICES.INVITED_TO_STAGE_2:
        return i18next.t('Sends to {{roleOfRecipient}} when {{trigger}}.', {
          trigger: triggerForTriggerDisplay,
          roleOfRecipient,
        });
      case EMAIL_TRIGGERS.CHOICES.SURVEY_STARTED:
        return i18next.t(
          'Sends to {{roleOfRecipient}} each time individual assessment begins.',
          { roleOfRecipient }
        );
      case EMAIL_TRIGGERS.CHOICES.SURVEY_COMPLETED:
        return i18next.t(
          'Sends to {{roleOfRecipient}} each time individual assessment is completed.',
          { roleOfRecipient }
        );
      default:
        return '';
    }
  }

  emailHasBeenSent(type, activity) {
    const { notifications } = activity.toObject();
    if (
      notifications.some(
        each => each.get('action') === NOTIFICATION_LOG_TYPES[type]
      )
    ) {
      return true;
    }

    switch (type) {
      case EMAIL_TYPES.CHOICES.ACTIVITY_START_END_USER:
      case EMAIL_TYPES.CHOICES.ACTIVITY_START_ORG_ADMIN:
        return activity.get('start_email_sent');
      case EMAIL_TYPES.CHOICES.ACTIVITY_CHASER:
        return activity.get('chaser_email_sent');
      case EMAIL_TYPES.CHOICES.ACTIVITY_PRE_CHASER:
        return activity.get('pre_chaser_email_sent');
      case EMAIL_TYPES.CHOICES.ACTIVITY_MIDWAY_REMINDER:
        return activity.get('midway_email_sent');
      case EMAIL_TYPES.CHOICES.ACTIVITY_END_ORG_ADMIN:
        return activity.get('end_email_sent');
      case EMAIL_TYPES.CHOICES.ACTIVITY_START_RATER:
      case EMAIL_TYPES.CHOICES.ACTIVITY_START_EXTERNAL_RATER:
        return activity.getIn(['activity360', 'nomination_start_email_sent']);
      case EMAIL_TYPES.CHOICES.ACTIVITY_NOMINATIONS_CHASER:
        return activity.getIn(['activity360', 'nomination_chaser_email_sent']);
      default:
        return false;
    }
  }

  confirmResendEmailAndShowModal(sent_to, activity, type) {
    const role = USER_ROLES.DISPLAY[sent_to];
    const isRaters = sent_to === USER_ROLES.CHOICES.RATERS;
    const ratersText = i18next.t(
      'Are you sure you wish to resend this email to Respondents, Line managers and Raters?'
    );

    this.props.openConfirmationModal(
      'resend',
      isRaters ? i18next.t('email') : i18next.t('email to {{role}}', { role }),
      [],
      () => this.props.resendEmailAndShowModal(activity.get('id'), type),
      this.props.closeTopModal,
      undefined,
      isRaters && [ratersText]
    );
  }

  muteNotification(activity, type, mute) {
    this.props.muteActivityNotification(activity.get('id'), type, mute);
  }

  getTitle() {
    const { notification, activity } = this.props;
    const { type } = notification.toObject();
    const emailTypeDisplay = EMAIL_TYPES.DISPLAY[type];

    if (
      activity.get('type') === 'THREE_SIXTY' &&
      type === EMAIL_TYPES.CHOICES.ACTIVITY_START_END_USER
    ) {
      return i18next.t('Respondent invite to nominate Raters');
    }
    return emailTypeDisplay;
  }

  canResendNotification() {
    const { notification, hasStartAndEnd, activity, readOnly } = this.props;
    const { type } = notification.toObject();
    const activityIsEditable = hasStartAndEnd && !readOnly;
    const isSingleUseNotification = canOnlySendNotificationOnce(notification);
    return (
      activityIsEditable &&
      this.emailHasBeenSent(type, activity) &&
      !isSingleUseNotification
    );
  }

  render() {
    const {
      notification,
      activity,
      index,
      total,
      muteResponse,
      readOnly,
    } = this.props;

    const { trigger, sent_to, type, muted } = notification.toObject();

    const title = this.getTitle();
    const recipientsRole = USER_ROLES.DISPLAY[sent_to];
    const sentDate = getAlreadySentDate(NOTIFICATION_LOG_TYPES[type], activity);
    const isLoading =
      isPending(muteResponse) && muteResponse.get('source') === type;
    const allMuted = activity.get('notifications_muted');
    const roleOfRecipient = forBlurb(sent_to) || recipientsRole;

    return (
      <div
        key={this.props.key}
        className={classnames('row', 'notification', {
          last: index === total - 1,
        })}
      >
        <div className="col-xs-12 col-md-8 notification-content-column">
          <h6 className="title">{title}</h6>

          <p className="sub-title">
            {this.getDescription(
              roleOfRecipient,
              notification,
              trigger,
              activity,
              sentDate
            )}
          </p>
        </div>

        <div className="col-xs-12 col-md-4 notification-buttons-column">
          {this.canResendNotification() && (
            <button
              className="btn btn-icon-square btn-small email-button btn-icon-square-secondary"
              title={i18next.t('Resend email')}
              onClick={() =>
                this.confirmResendEmailAndShowModal(sent_to, activity, type)
              }
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </button>
          )}

          {!readOnly && (
            <button
              className="btn btn-icon-square btn-small edit-button btn-icon-square-secondary"
              title={i18next.t('Edit email')}
              onClick={() =>
                this.props.openNotificationEmailEditModal(
                  notification,
                  i18next.t('Editing {{title}} Notification for {{user}}', {
                    title,
                    user: recipientsRole,
                  }),
                  activity
                )
              }
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}

          {!allMuted && (
            <OnOffToggle
              isLoading={isLoading}
              value={!muted}
              onChange={() => this.muteNotification(activity, type, !muted)}
              disabled={readOnly}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(null, {
  openNotificationEmailEditModal,
  closeTopModal,
  openConfirmationModal,
  resendEmailAndShowModal,
  muteActivityNotification,
  updateActivity,
})(Notification);
