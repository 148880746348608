import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

export default ({ className, width, height }: Props) => (
  <svg
    width={width || '41'}
    height={height || '40'}
    className={className}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 19.8834 40.499 19.767 40.497 19.6508H30.0019L25.2108 32.7732C24.7818 33.9484 23.0835 33.8343 22.8156 32.6121L17.5411 8.54716L13.3713 18.869C13.1804 19.3415 12.7219 19.6508 12.2123 19.6508H0.502987C0.500998 19.767 0.5 19.8834 0.5 20C0.5 31.0457 9.45431 40 20.5 40Z"
      fill="#662D91"
    />
    <path
      d="M0.622279 17.7758C1.72859 7.77688 10.2061 0 20.5 0C30.7939 0 39.2714 7.77688 40.3777 17.7758H29.5647C29.0397 17.7758 28.5706 18.1039 28.3906 18.5971L24.1869 30.1107L18.9399 6.17108C18.6767 4.97036 17.0203 4.83076 16.5598 5.97049L11.7907 17.7758H0.622279Z"
      fill="#662D91"
    />
  </svg>
);
