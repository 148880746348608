import { List } from 'immutable';
import React, { ChangeEvent, PureComponent } from 'react';

import TextArea from '^/components/forms/TextArea';

interface OwnProps {
  question: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  error?: List<string>;
  disabled?: boolean;
  placeholder?: string;
}

type Props = OwnProps;

export class FreeTextQuestion extends PureComponent<Props> {
  public UNSAFE_componentWillMount() {
    this.props.onChange(this.props.defaultValue || '');
  }

  public render() {
    const { defaultValue, question, error, placeholder, disabled } = this.props;

    return (
      <div className="question freetext-question">
        <h5>{question}</h5>
        {error && <p className="text-error">{error.toArray()}</p>}
        <div>
          <TextArea
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={this.onChange}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  private onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(event.target.value);
  };
}
