/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import _ from 'underscore';
import i18next from 'i18next';

import { findFirstMatch } from '^/utils';
import { logout } from '../actions/actions';
import { can, onlySeeSurvey, switchLanguage } from '../capabilities';
import Footer from './Footer';
import Loading from './Loading';
import Main from './Main';
import PureComponent from './PureComponent';
import Navigation from './Navigation';
import PeoplewiseLogo from './PeoplewiseLogo';
import NavItem from './NavItem';
import Topbar from './Topbar';
import { withRouter } from '^/withRouter';
import Alert from '^/components/Alert';
import LanguageSelector from './buttons/LanguageSelector';
import { TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS } from '^/constants/routes';

export class Page extends PureComponent {
  constructor(props) {
    super();

    this.createRouteRegex = _.memoize(function(routePath) {
      const routeString = props.route.fullpath + routePath;
      const regexString = routeString.replace(/:[^/]+/gi, '[^/]+');
      return new RegExp(regexString, 'i');
    });
  }

  routeMatchesPath(route) {
    return (
      route.path && this.createRouteRegex(route.path).exec(this.props.path)
    );
  }

  isAllowedRoute(route) {
    const { user } = this.props;
    if (
      can(user, onlySeeSurvey()) &&
      route.path.lastIndexOf('external/') !== 0
    ) {
      return false;
    }
    return !route.capability || can(user, route.capability());
  }

  shouldDisplayNav(route) {
    const { user } = this.props;

    return (
      route.displayNav &&
      (!route.displayNavCapability || can(user, route.displayNavCapability()))
    );
  }

  currentChildRouteHasNav() {
    const { route, path } = this.props;
    return !route.childRoutes.find(
      childRoute =>
        childRoute.noNav && route.fullpath + childRoute.path === path
    );
  }

  getClassName(hasNav) {
    return (
      (hasNav ? 'wrapper ' : 'no-nav') +
      (this.props.hasTopBar ? ' has-top-bar' : '')
    );
  }

  renderNavLinks(routes, hasParent) {
    const shouldShowRoute = route =>
      this.isAllowedRoute(route) &&
      this.shouldDisplayNav(route) &&
      isLinkOrHasChildren(route);

    const isLinkOrHasChildren = route =>
      route.path ||
      !route.childRoutes ||
      route.childRoutes.find(shouldShowRoute);

    return routes.filter(shouldShowRoute).map(route => (
      <NavItem
        key={route.title}
        to={route.path ? this.props.route.fullpath + route.path : null}
        sudoAsUser={route.sudoAsUser}
        title={route.title}
        pulseIcon={Boolean(route.pulseIcon)}
        icon={route.image}
        hasParent={hasParent}
        hasActiveChildRoute={this.hasActiveChildRoute(route.childRoutes)}
        getBadge={route.getBadge}
        showLoggedInUser={route.showLoggedInUser}
        onClick={route.onNavClick}
      >
        {this.renderNavLinks(route.childRoutes || [], true)}
      </NavItem>
    ));
  }

  hasActiveChildRoute(childRoutes) {
    return (
      childRoutes && childRoutes.find(route => this.routeMatchesPath(route))
    );
  }

  currentRouteAllowed() {
    const findMatchingChildRoute = route => {
      return (
        route.childRoutes &&
        (route.childRoutes.find(childRoute =>
          this.routeMatchesPath(childRoute)
        ) ||
          findFirstMatch(route.childRoutes, childRoute =>
            findMatchingChildRoute(childRoute)
          ))
      );
    };

    const currentRoute = findMatchingChildRoute(this.props.route);

    return currentRoute && this.isAllowedRoute(currentRoute);
  }

  renderAccessDenied() {
    return (
      <div>
        <h1>{i18next.t('Access Denied?')}</h1>
        <Alert>
          {i18next.t('Sorry, you do not have permission to view this page.')}
          <br />(
          {i18next.t('Logged in as {{userEmail}}', {
            userEmail: this.props.user.get('email'),
          })}
        </Alert>
        <p>
          <a onClick={() => this.props.logout()}>{i18next.t('Log in')}</a>
          {i18next.t('as another user')}
        </p>
      </div>
    );
  }

  renderMain() {
    return (
      <Main>
        {this.currentRouteAllowed()
          ? this.props.children
          : this.renderAccessDenied()}
      </Main>
    );
  }

  render() {
    const { user, route, activeLanguage, path } = this.props;
    const hasNav = this.currentChildRouteHasNav();
    if (!user || user.isEmpty()) {
      return <Loading />;
    }

    if (can(user, onlySeeSurvey())) {
      return this.renderMain();
    }

    return (
      <div className="page-wrapper">
        {hasNav && <Topbar />}
        {hasNav && (
          <Navigation>
            <li className="nav-logo-language">
              <div className="logo-language-container">
                <Link to="/page/home">
                  <PeoplewiseLogo onDark />
                </Link>
                {can(user, switchLanguage()) &&
                  Object.keys(
                    TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS
                  ).includes(path) && (
                    <>
                      <div className="vertical-rule" />
                      <LanguageSelector nav />
                    </>
                  )}
              </div>
            </li>
            {this.renderNavLinks(route.childRoutes)}
          </Navigation>
        )}
        <div className={this.getClassName(hasNav)}>{this.renderMain()}</div>
        <Footer langCode={activeLanguage} />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    path: props.location.pathname,
    user: state.userProfile,
    hasTopBar: state.stash,
    activeLanguage: state.ui.get('activeLanguage'),
  };
}

export default withRouter(connect(mapStateToProps, { logout })(Page));
