import classNames from 'classnames';
import React from 'react';

import { InputProps } from './types';
import { isMultiline } from './utils';

const focusInput = (element: HTMLElement | null) => element && element.focus();

const Input = (props: InputProps) => {
  const inputClassName = classNames('input', props.inputClassName);

  if (props.type === 'choice') {
    return (
      <select
        className={inputClassName}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        ref={focusInput}
      >
        {typeof props.placeholder === 'string' && (
          <option value="" hidden>
            {props.placeholder}
          </option>
        )}
        {props.allowBlank && <option value="" hidden={!props.allowBlank} />}
        {props.options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  }

  if (isMultiline(props)) {
    return (
      <textarea
        className={inputClassName}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
        ref={focusInput}
      />
    );
  }

  const numberProps =
    props.type === 'number' ? { min: props.min, max: props.max } : {};

  return (
    <input
      {...numberProps}
      className={inputClassName}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      type={props.type}
      onKeyDown={props.onKeyDown}
      ref={focusInput}
    />
  );
};

export { Input };

export default Input;
