import React from 'react';

export const isTouchEvent = (
  event:
    | React.MouseEvent<HTMLElement>
    | React.TouchEvent<HTMLElement>
    | MouseEvent
    | TouchEvent
): event is React.TouchEvent<HTMLElement> | TouchEvent =>
  Boolean('touches' in event && event.touches.length);

export const isDragging = (state: {
  draggedGroup?: string | undefined;
  draggedIndex?: number | undefined;
}): state is { draggedGroup: string; draggedIndex: number } =>
  typeof state.draggedGroup !== 'undefined' &&
  typeof state.draggedIndex !== 'undefined';

export const isDropping = (state: {
  droppedGroup?: string | undefined;
  droppedIndex?: number | undefined;
}): state is { droppedGroup: string; droppedIndex: number } =>
  typeof state.droppedGroup !== 'undefined' &&
  typeof state.droppedIndex !== 'undefined';
