import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import ContentBox from '^/components/ContentBox';
import DataFilterControl from '^/components/datafilters/DataFilterControl';
import {
  clearSimpleComponentState,
  setSimpleComponentState,
  getToken,
} from '^/actions/actions';
import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import { THREE_SIXTY_RATER } from '^/middleware/reports';
import {
  can,
  administerOrganisations,
  administerOwnOrganisation,
} from '^/capabilities';
import {
  setFilterId,
  clearFilterType,
  preselectFilterItem,
  FILTER_TYPE_ORGANISATION,
} from '^/actions/datafilters';

const FILTERID = 'THREE_SIXTY_EXPORT';

export class ThreeSixtyExport extends PureComponent {
  componentDidMount() {
    this.props.getToken();
    this.props.setFilterId(FILTERID);

    if (can(this.props.user, administerOwnOrganisation())) {
      const userOrganisation = this.props.user.get('organisation');
      this.props.preselectFilterItem(
        FILTERID,
        FILTER_TYPE_ORGANISATION,
        userOrganisation.get('id')
      );
    }
  }
  componentWillUnmount() {
    this.props.clearSimpleComponentState([FILTERID, 'objectType']);
  }

  onChangeObjectType(value) {
    this.props.setSimpleComponentState([FILTERID, 'objectType'], value);
  }

  valueIfNotAll(selectedObject) {
    const value = selectedObject.get('value', null);
    return value !== 'ALL' ? value : null;
  }

  onOpenExport() {
    const { shortlivedToken, selectedOrganisation } = this.props;
    this.props.getToken();
    this.props.closeModalAndTryViewExport(shortlivedToken, THREE_SIXTY_RATER, {
      organisation: this.valueIfNotAll(selectedOrganisation),
    });
  }

  renderExportButton() {
    return (
      <button
        className="btn btn-primary pull-right"
        type="submit"
        onClick={() => this.onOpenExport()}
      >
        {i18next.t('Export CSV')}
      </button>
    );
  }

  render() {
    const { user, selectedOrganisation } = this.props;

    return (
      <ContentBox>
        <h3>{i18next.t('360 rater summary data')}</h3>
        {can(user, administerOrganisations()) && (
          <div className="row mb-none">
            <div className="col-xs-12 col-md-6">
              <div className="form-group-block">
                <label>{i18next.t('Account')}</label>
                <div className="form-item">
                  <DataFilterControl
                    filterId={FILTERID}
                    placeholder={i18next.t('Begin typing organisation name...')}
                    includeAll
                    filterType={FILTER_TYPE_ORGANISATION}
                    changeCallback={() => this.onChangeObjectType(null)} // clear object on change
                    responseName="loadOrganisationFilters"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {!selectedOrganisation.isEmpty() && this.renderExportButton()}
      </ContentBox>
    );
  }
}

ThreeSixtyExport.propTypes = {
  setFilterId: PropTypes.func.isRequired,
  clearFilterType: PropTypes.func.isRequired,
  selectedOrganisation: ImmutablePropTypes.map.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  getToken: PropTypes.func.isRequired,
  closeModalAndTryViewExport: PropTypes.func.isRequired,
  shortlivedToken: ImmutablePropTypes.map,
};

export function mapStateToProps(state) {
  return {
    selectedOrganisation: state.datafilters.getIn(
      [FILTERID, FILTER_TYPE_ORGANISATION, 'selectedResult'],
      Map()
    ),
    user: state.userProfile,
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  setFilterId,
  setSimpleComponentState,
  clearSimpleComponentState,
  clearFilterType,
  preselectFilterItem,
  getToken,
  closeModalAndTryViewExport,
})(ThreeSixtyExport);
