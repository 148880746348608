import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import { noop } from 'underscore';

import PageHeader from '^/components/PageHeader';
import IndeterminateProgressBar from '^/components/IndeterminateProgressBar';
import ProgressBar from '^/components/ProgressBar';
import { Countdown } from '../Countdown';

export class ProgressBarsAndTimers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      startTime: Date.now(),
      elapsedMillis: 0,
    };

    this.tick = () => {
      this.setState(state => ({
        elapsedMillis: Date.now() - state.startTime,
      }));
    };

    this.restartCountdown = () => {
      this.setState({
        startTime: Date.now(),
        ellapsedMillis: 0,
      });
    };
  }

  render() {
    return (
      <div>
        <PageHeader>Progress Bars & Timers</PageHeader>

        <h3>Progress Bar</h3>

        <div className="row">
          <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4 margin-top margin-bottom">
            <ProgressBar percent={25} />
          </div>
        </div>

        <h3>Indeterminate Progress Bar</h3>

        <div className="row">
          <div className="col-xs-12 col-md-4 col-lg-4 col-xl-4 margin-top margin-bottom">
            <IndeterminateProgressBar />
          </div>
        </div>

        <h3>Countdown</h3>

        <div className="row">
          <div className="col-xs-12 margin-top margin-bottom">
            <Countdown
              timerStart={noop}
              timerTick={this.tick}
              totalMinutes={2}
              elapsedMillis={this.state.elapsedMillis}
            />
            <button className="btn btn-small" onClick={this.restartCountdown}>
              Restart countdown
            </button>
            <p>
              The button above and this text are not part of the Countdown
              component.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBarsAndTimers;
