import { Map } from 'immutable';
import moment from 'moment';
import React, { PureComponent } from 'react';

import { SHOP_ITEM_OPTION_TYPE_CHOICE } from '^/reducers/api/types';
import { FORMAT_DATE } from '^/utils';
import { PurchasedBasketItem } from './types';

interface OwnProps {
  basketItem: Map<string, any>;
  showOrganisations: boolean;
}

type Props = OwnProps;

export class PurchaseItem extends PureComponent<Props> {
  public render() {
    const { basketItem, showOrganisations } = this.props;

    const {
      purchased,
      shop_item_option,
      user,
      quantity,
      report_quantities,
      total_cost,
    } = basketItem.toJS() as PurchasedBasketItem;

    return (
      <tr>
        {showOrganisations && <td>{user.organisation.name}</td>}
        <td>{moment(purchased || '').format(FORMAT_DATE)}</td>
        <td>{shop_item_option.shop_item.name}</td>
        <td>{SHOP_ITEM_OPTION_TYPE_CHOICE[shop_item_option.type]}</td>
        <td>
          {!report_quantities.length
            ? quantity
            : report_quantities.reduce(
                (sum, report) => sum + report.quantity,
                0
              )}
        </td>
        <td>£{total_cost}</td>
      </tr>
    );
  }
}

export default PurchaseItem;
