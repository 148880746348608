import React from 'react';

import Alert, { AlertType } from '^/components/Alert';
import InstructionsToggleButton from '^/components/dashboard/enduser/answer/InstructionsToggleButton';
import StatusPill, { StatusType } from '^/components/StatusPill';
import toast from '^/components/Toast';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import PureComponent from '../PureComponent';
import SectionHeader from '../SectionHeader';
import CodeSample from './CodeSample';

export default class BadgesAndAlerts extends PureComponent {
  public render() {
    const fakeAssessmentStyle = {
      backgroundColor: '#eeeeee',
      minHeight: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
    };

    return (
      <div>
        <PageHeader>Badges and Alerts</PageHeader>

        <SectionHeader>Collapsible Instructions</SectionHeader>

        <PageContent>
          <p>Render some instructions inside a collapsible component.</p>
          <h5>closed by default:</h5>

          <div className="row margin-bottom">
            <div className="col-xs-9">
              <div style={fakeAssessmentStyle as any}>
                I'm an assessment - woo!
              </div>
            </div>
            <div className="col-xs-3">
              <InstructionsToggleButton>
                {/* eslint-disable max-len */}
                <p>
                  All the patterns in Set A are in some way similar to each
                  other, and all those in Set B are similar to each other.
                  However, the two sets are different and you should be able to
                  identify the basis for this difference by studying them. When
                  you have worked out why the patterns in Set A and Set B are
                  grouped as they are you should then decide to which set each
                  of the 5 items belongs. Go ahead and record your responses for
                  the 5 items.
                </p>
                {/* eslint-enable max-len */}
              </InstructionsToggleButton>
            </div>
          </div>

          <CodeSample
            code={`<InstructionsToggleButton>
  <p>These are some instructions</p>
</InstructionsToggleButton>`}
          />

          <h5>Open by default (for initial question):</h5>

          <div className="row margin-bottom">
            <div className="col-xs-9">
              <div style={fakeAssessmentStyle as any}>
                I'm an assessment - woo!
              </div>
            </div>
            <div className="col-xs-3">
              <InstructionsToggleButton questionIndex={0}>
                {/* eslint-disable max-len */}
                <p>
                  All the patterns in Set A are in some way similar to each
                  other, and all those in Set B are similar to each other.
                  However, the two sets are different and you should be able to
                  identify the basis for this difference by studying them. When
                  you have worked out why the patterns in Set A and Set B are
                  grouped as they are you should then decide to which set each
                  of the 5 items belongs. Go ahead and record your responses for
                  the 5 items.
                </p>
                {/* eslint-enable max-len */}
              </InstructionsToggleButton>
            </div>
          </div>

          <CodeSample
            code={`<InstructionsToggleButton questionIndex={0}>
  <p>These are some instructions</p>
</InstructionsToggleButton>`}
          />

          <h5>Full width, alongside title:</h5>

          <div className="margin-bottom">
            <InstructionsToggleButton heading={'Question 1'}>
              {/* eslint-disable max-len */}
              <p>
                All the patterns in Set A are in some way similar to each other,
                and all those in Set B are similar to each other. However, the
                two sets are different and you should be able to identify the
                basis for this difference by studying them. When you have worked
                out why the patterns in Set A and Set B are grouped as they are
                you should then decide to which set each of the 5 items belongs.
                Go ahead and record your responses for the 5 items.
              </p>
              {/* eslint-enable max-len */}
            </InstructionsToggleButton>
          </div>
          <CodeSample
            code={`<InstructionsToggleButton heading={"Question 1"}>
  <p>These are some instructions</p>
</InstructionsToggleButton>`}
          />
        </PageContent>

        <SectionHeader>Status pills</SectionHeader>

        <PageContent>
          <p>Render a statustext inside a pill.</p>

          <div className="margin-bottom">
            <StatusPill type={StatusType.Success} status="Success" />
            <StatusPill type={StatusType.Info} status="Info" />
            <StatusPill type={StatusType.Warning} status="Warning" />
            <StatusPill type={StatusType.Danger} status="Danger" />
            <StatusPill type={StatusType.Disabled} status="Disabled" />
          </div>

          <CodeSample
            code={`<StatusPill type={StatusType.Success} status="Success" />
<StatusPill type={StatusType.Info} status="Info" />
<StatusPill type={StatusType.Warning} status="Warning" />
<StatusPill type={StatusType.Danger} status="Danger" />
<StatusPill type={StatusType.Disabled} status="Disabled" />`}
          />
        </PageContent>

        <SectionHeader>Toasts</SectionHeader>
        <PageContent>
          <div>
            <button
              className="btn btn-default"
              onClick={() =>
                toast.success(
                  'Success header.',
                  'This is the body of the success toast'
                )
              }
            >
              Success toast
            </button>
            <button
              className="btn btn-default"
              onClick={() =>
                toast.info('Info header.', 'This is the body of the info toast')
              }
            >
              Info toast
            </button>
            <button
              className="btn btn-default"
              onClick={() =>
                toast.warn('Warn header.', 'This is the body of the warn toast')
              }
            >
              Warn toast
            </button>
            <button
              className="btn btn-default"
              onClick={() =>
                toast.error(
                  'Error header.',
                  'This is the body of the error toast'
                )
              }
            >
              Error toast
            </button>
          </div>

          <CodeSample
            code={`<button className="btn btn-default" onClick={
  () => toast.success('Success header.', 'This is the body of the success toast')
  }>
  Success toast
</button>
<button className="btn btn-default" onClick={
  () => toast.info('Info header.', 'This is the body of the info toast')
  }>
  Info toast
</button>
<button className="btn btn-default" onClick={
  () => toast.warn('Warn header.', 'This is the body of the warn toast')
  }>
  Warn toast
</button>
<button className="btn btn-default" onClick={
  () => toast.error('Error header.', 'This is the body of the error toast')
  }>
  Error toast
</button>`}
          />
        </PageContent>

        <SectionHeader>Alerts</SectionHeader>

        <PageContent>
          <div className="row">
            <div className="col-xs-6">
              <p className="lead">
                Nothing to display in a list, form submit error / success
              </p>
              <Alert hideIcon>
                This is an alert component without an icon{' '}
                <a>Link looks like this</a>
              </Alert>
              <Alert>This is an alert component</Alert>
              <Alert type={AlertType.Success}>
                This is a success type alert component
              </Alert>
              <Alert type={AlertType.Warning}>
                This is a warning type alert component
              </Alert>
              <Alert type={AlertType.Error}>
                This is a error type alert component
              </Alert>
            </div>
            <div className="col-xs-6">
              <CodeSample
                code={`<Alert hideIcon>
  This is an alert component without an icon{' '}
  <a>Link looks like this</a>
</Alert>
<Alert>This is an alert component</Alert>
<Alert type={AlertType.Success}>
  This is a success type alert component
</Alert>
<Alert type={AlertType.Warning}>
  This is a warning type alert component
</Alert>
<Alert type={AlertType.Error}>
  This is a error type alert component
</Alert>`}
              />
            </div>
          </div>
        </PageContent>

        <SectionHeader>Badge States</SectionHeader>

        <PageContent>
          <p className="lead">For states e.g. organisation state</p>
          <div className="badge-success">Success text!</div>
          <div className="badge-info">Info text.</div>
          <div className="badge-warning">Warning text...</div>
          <div className="badge-danger">Danger text!</div>
          <div className="badge-error">Error text. ;(</div>

          <CodeSample
            code={`<div className="badge-success">
  Success text!
</div>
<div className="badge-info">
  Info text.
</div>
<div className="badge-warning">
  Warning text...
</div>
<div className="badge-danger">
  Danger text!
</div>
<div className="badge-error">
  Error text. ;(
  </div>`}
          />
        </PageContent>

        <h4>Text States</h4>

        <PageContent>
          <p className="lead">For form field validation</p>
          <p className="text-success">Success text!</p>
          <p className="text-info">Info text.</p>
          <p className="text-warning">Warning text...</p>
          <p className="text-danger">Danger text!</p>
          <p className="text-error">Error text. ;(</p>
          <CodeSample
            code={`<p className="text-success">
  Success text!
</p>
<p className="text-info">
  Info text.
</p>
<p className="text-warning">
  Warning text...
</p>
<p className="text-danger">
  Danger text!
</p>
<p className="text-error">
  Error text. ;(
  </p>`}
          />
        </PageContent>
      </div>
    );
  }
}
