import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { connect } from 'react-redux';

import { openModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import CreateGroupModal from '^/components/activities/tabs/users/CreateGroupModal';

export class CreateGroupButton extends PureComponent {
  showCreateGroupFromRespondentsModal() {
    const { organisation, activityUsers } = this.props;
    this.props.openModal({
      title: i18next.t('Create group from respondents'),
      body: (
        <CreateGroupModal
          organisation={organisation}
          activityUsers={activityUsers}
        />
      ),
    });
  }

  render() {
    const { activity, activityUsers } = this.props;
    const isAnonymous = activity.get('is_anonymous');
    const showButton =
      !isAnonymous && activityUsers && !activityUsers.isEmpty();
    return showButton ? (
      <button
        className="btn btn-default btn-small mt-none"
        onClick={() => this.showCreateGroupFromRespondentsModal()}
      >
        {i18next.t('Create group from respondent(s)')}
      </button>
    ) : null;
  }
}

export default connect(null, { openModal })(CreateGroupButton);
