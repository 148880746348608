import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import { stopPulses } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import { Uuid } from '^/reducers/api/types';

interface DispatchProps {
  stopPulses: typeof stopPulses;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  users: ReadonlyArray<{
    userPulseId: Uuid;
    fullName: string;
  }>;
  reloadPulse: () => void;
}

interface StateProps {
  response: Map<string, any>;
}

type Props = StateProps & DispatchProps & OwnProps;

export class StopPulsingModal extends PureComponent<Props> {
  public render() {
    const { users } = this.props;
    return (
      <>
        <h5>{i18next.t<string>('Selected respondents')}</h5>
        <div className="boxed">
          <div className="box">
            {users.map(user => user.fullName).join(', ')}
          </div>
        </div>
        <div className="mt-lg">
          <p>
            {i18next.t<string>(
              'Confirm to cancel these monthly pulsing subscriptions. Pulsing will continue to the end of the billing period.'
            )}
          </p>
        </div>
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Exit')}
            </button>
            <button
              className="btn btn-primary"
              onClick={() =>
                this.stopPulsing(users.map(user => ({ id: user.userPulseId })))
              }
            >
              {i18next.t<string>('Cancel pulsing')}
            </button>
          </div>
        </div>
      </>
    );
  }

  private stopPulsing = (userPulseIds: ReadonlyArray<{ id: Uuid }>) => {
    this.props.stopPulses(userPulseIds, this.props.reloadPulse);
    this.props.closeTopModal();
  };
}

export default connect(null, {
  stopPulses,
  closeTopModal,
})(StopPulsingModal);
