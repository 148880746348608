import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';

import MailTo from '^/components/MailTo';
import NoAuthSignUp from '^/components/NoAuthSignUp';
import PureComponent from '^/components/PureComponent';
import i18next from '^/locale';
import { HELP_EMAIL } from '^/settings';
import { getQueryValue } from '^/utils-ts';
import { RouterParamProps } from '^/withRouter';

type Props = RouterParamProps;

const TickList = (
  <FontAwesomeIcon size="sm" className="green-tick" icon={faCheck} />
);

export class SignUpChoice extends PureComponent<Props> {
  public render() {
    const shopItemOptionId = getQueryValue(
      this.props,
      'shop_item_option'
    ) as string;
    const appendShopItem = shopItemOptionId
      ? '&shop_item_option=' + shopItemOptionId
      : '';
    return (
      <NoAuthSignUp
        description={i18next.t<string>(
          'Find the perfect plan for your business'
        )}
      >
        <div className="choice-item-container">
          <div className="choice-item sole-practitioner">
            <h1>{i18next.t<string>('Sole Practitioner')} </h1>
            <h3>{i18next.t<string>('Pay as you go account')}</h3>
            <ul className="sign-up-box">
              <li>
                {TickList}
                {i18next.t<string>('1 Superuser')}
              </li>
              <li>
                {TickList}
                {i18next.t<string>('Create and manage your own assessments')}
              </li>
              <li>
                {TickList}
                {i18next.t<string>(
                  'Instant access to the PeopleWise Perspective Performance series of 360 assessments'
                )}
              </li>
              <li>
                {TickList}
                {i18next.t<string>(
                  'Buy accreditation, feedback sessions or upload your credentials in our online shop'
                )}
              </li>
              <li>
                {TickList}
                {i18next.t<string>('Generate, Download and Send reports')}
              </li>
              <li>
                {TickList}
                {i18next.t<string>('Buy credits to access reports')}
              </li>
            </ul>
            <Link
              to={
                `/accountSignup?account_type=SOLE_PRACTITIONER` + appendShopItem
              }
              className="btn btn-secondary sole-practitioner"
            >
              {i18next.t<string>('Select')}
            </Link>
          </div>

          <div className="choice-item enterprise-lite">
            <h1>{i18next.t<string>('Enterprise Lite')} </h1>
            <h3>{i18next.t<string>('Pay as you go account')}</h3>
            <ul className="sign-up-box">
              <li>
                {TickList}
                {i18next.t<string>('1 Superuser')}
              </li>
              <li>
                {TickList}
                {i18next.t<string>('Create and manage your own assessments')}
              </li>
              <li>
                {TickList}
                {i18next.t<string>(
                  'Instant access to the PeopleWise Perspective Performance series of 360 assessments'
                )}
              </li>
              <li>
                {TickList}
                {i18next.t<string>(
                  'Buy accreditation, feedback sessions or upload your credentials in our online shop'
                )}
              </li>
              <li>
                {TickList}
                {i18next.t<string>('Generate, Download and Send reports')}
              </li>
              <li>
                {TickList}
                {i18next.t<string>('Buy credits to access reports')}
              </li>
            </ul>
            <Link
              to={
                `/accountSignup?account_type=ENTERPRISE_LITE` + appendShopItem
              }
              className="btn btn-secondary enterprise-lite"
            >
              {i18next.t<string>('Select')}
            </Link>
          </div>

          <div className="choice-item enterprise">
            <h1>{i18next.t<string>('Enterprise Licence')} </h1>
            <h3>
              {i18next.t<string>('Annual licence fee for larger businesses')}
            </h3>
            <p className="sign-up-box">Contact us for a quote</p>
            <button className="btn btn-secondary enterprise">
              <MailTo
                email={HELP_EMAIL}
                subject={i18next.t<string>('RE: Enterprise Licence enquiry')}
                body={i18next.t<string>(
                  `PARAGRAPH enterprise licence contact email`
                )}
              >
                {i18next.t<string>('Contact us')}
              </MailTo>
            </button>
          </div>
        </div>
      </NoAuthSignUp>
    );
  }
}
export default SignUpChoice;
