import { Map } from 'immutable';
import React, { KeyboardEvent, PureComponent } from 'react';

import ContentBox from '^/components/ContentBox';
import { Editable } from '^/components/editable/Editable';
import { SelectOptions } from '^/components/editable/types';
import PageContent from '^/components/PageContent';
import PageHeader from '^/components/PageHeader';
import { Key, matchesKey } from '^/keys';
import { hasFailed } from '^/responseStates';

const OPTIONS: SelectOptions = [
  {
    label: 'Option 1',
    value: 'option1',
  },
  {
    label: 'Option 2',
    value: 'option2',
  },
];

interface State {
  value: string | number | undefined;
  editing: boolean;
  loading: boolean;
  response: Map<string, any>;
}

export default class EditableThings extends PureComponent<{}, State> {
  public state: State = {
    value: undefined,
    editing: false,
    loading: false,
    response: Map(),
  };

  public render() {
    const editableProps = {
      value: this.state.value,
      response: this.state.response,
      fieldName: 'test',
      onSave: this.onSave,
      editing: this.state.editing,
      loading: this.state.loading,
      failed: hasFailed(this.state.response),
      onEdit: this.onEdit,
      onCancel: this.onCancel,
      onAccept: this.onAccept,
      placeholder: 'Placeholder',
      onKeyDown: this.onKeyDown,
      onKeyDownEdit: this.onKeyDownEdit,
      onKeyDownCancel: this.onKeyDownCancel,
      onKeyDownAccept: this.onKeyDownAccept,
    };

    return (
      <div>
        <PageHeader>Editable things</PageHeader>

        <PageContent>
          <h2>Editable</h2>

          <h3>Inline</h3>

          <ContentBox>
            <p>
              {'Inline text '}
              <Editable {...editableProps} />
              {' within a sentence.'}
            </p>

            <p>
              {'Inline readonly text '}
              <Editable {...editableProps} readOnly />
              {' within a sentence.'}
            </p>

            <p>
              {'Inline multiline text '}
              <Editable {...editableProps} multiline />
              {' within a sentence.'}
            </p>

            <p>
              {'Inline number '}
              <Editable {...editableProps} type="number" />
              {' within a sentence.'}
            </p>

            <p>
              {'Inline choice '}
              <Editable {...editableProps} type="choice" options={OPTIONS} />
              {' within a sentence.'}
            </p>

            <p>
              {'Inline choice with allow blank '}
              <Editable
                {...editableProps}
                type="choice"
                allowBlank
                options={OPTIONS}
              />
              {' within a sentence.'}
            </p>
          </ContentBox>

          <h3>Block</h3>

          <ContentBox>
            <label>Block text</label>
            <Editable {...editableProps} block />

            <label>Block readonly text</label>
            <Editable {...editableProps} block readOnly />

            <label>Block multiline text</label>
            <Editable {...editableProps} block multiline />

            <label>Block number</label>
            <Editable {...editableProps} block type="number" />

            <label>Block choice</label>
            <Editable
              {...editableProps}
              block
              type="choice"
              options={OPTIONS}
            />

            <label>Block choice with allow blank</label>
            <Editable
              {...editableProps}
              block
              type="choice"
              allowBlank
              options={OPTIONS}
            />
          </ContentBox>

          <h3>Variants/States</h3>

          <ContentBox>
            <label>H1 inline</label>
            <h1>
              <Editable {...editableProps} />
            </h1>

            <label>H1 Block</label>
            <h1>
              <Editable {...editableProps} block />
            </h1>

            <p>
              {'Error inline '}
              <Editable
                {...editableProps}
                failed
                errorMessage="An error occured"
              />
              {' within a sentence'}
            </p>

            <p>
              {'Another error inline '}
              <Editable
                {...editableProps}
                failed
                errorMessage="An error occured"
              />
              {' within a sentence'}
            </p>

            <label>Error block</label>
            <Editable
              {...editableProps}
              failed
              errorMessage="An error occured"
              block
            />
          </ContentBox>
        </PageContent>
      </div>
    );
  }

  private onSave = async () => {
    alert('Saved!');
  };

  private onEdit = () => {
    this.setState({
      editing: true,
    });
  };

  private onCancel = () => {
    this.setState({
      editing: false,
    });
  };

  private onAccept = () => {
    this.setState({
      loading: true,
    });

    window.setTimeout(() => {
      this.setState({
        loading: false,
        editing: false,
      });

      this.onSave();
    }, 1000);
  };

  private onKeyDownEdit = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (matchesKey(event, Key.ENTER)) {
      this.onEdit();
    }
  };

  private onKeyDownCancel = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (matchesKey(event, Key.ENTER)) {
      this.onCancel();
    }
  };

  private onKeyDownAccept = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (matchesKey(event, Key.ENTER)) {
      this.onAccept();
    }
  };

  private onKeyDown = (
    event:
      | KeyboardEvent<HTMLInputElement>
      | KeyboardEvent<HTMLTextAreaElement>
      | KeyboardEvent<HTMLSelectElement>
  ) => {
    if (matchesKey(event, Key.ENTER) && !event.shiftKey) {
      event.preventDefault();

      this.onAccept();
    }
  };
}
