import React from 'react'; // eslint-disable-line no-unused-vars
import { Map } from 'immutable';

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import SidebarProfile from '../SidebarProfile';
import Navigation from '../Navigation';
import NavItem from '../NavItem';
import CodeSample from './CodeSample';
import { Topbar } from '../Topbar';

export default class Menu extends PureComponent {
  render() {
    const navLinks = [
      ['Dashboard', '/page/dashboard'],
      ['Activities', '/page/activities'],
      ['ProductVersions', '/page/productVersions'],
      ['Accounts', '/page/accounts'],
      ['Users', '/page/individuals', [['Roles', 'page/roles']]],
      ['Reports', '/page/reports'],
    ];
    const superuser = Map({ email: 'admin@foo.com' });
    const user = Map({ email: 'bob@foo.com' });

    return (
      <div>
        <h3>Topbar</h3>
        <p>
          you should see a blue bar at the top of the page. menu is not padded
          as cannot do that in the styleguide.
        </p>
        <Topbar superuser={superuser} user={user} />
        <CodeSample code={`<Topbar/>`} />

        <h3>Menu</h3>
        {/* menu */}
        <PageHeader>
          <a name="menu" />
          Menu
        </PageHeader>
        <PageContent>
          <div className="styleguide-inline-wrapper">
            <Navigation>
              <SidebarProfile />
              {navLinks.map(([text, path, subItems]) => (
                <NavItem
                  key={text}
                  title={text}
                  to={path && null}
                  icon={text.toLowerCase()}
                >
                  {subItems ? (
                    <ul>
                      {subItems.map(([subItemText]) => (
                        <li key={subItemText}>
                          <a>{subItemText}</a>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </NavItem>
              ))}
            </Navigation>
          </div>

          <CodeSample
            code={`<Sidebar>
            <SidebarProfile />
            {navLinks.map(([text, path, subItems]) =>
              <NavItem key={text} title={text} to={path} icon={text.toLowerCase()}>
                {
                  subItems ? (
                    <ul>
                      {
                        subItems.map(([subItemText]) => <li key={subItemText}><a>{subItemText}</a></li>)
                      }
                    </ul>
                  ) : null
                }
              </NavItem>
            )}
          </Sidebar>`}
          />
        </PageContent>
      </div>
    );
  }
}
