import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import {
  setProductVersionPage,
  storeProductVersionAnswer,
} from '^/actions/actions';
import {
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsAndClose,
  answerMultipleQuestionsAndSubmit,
} from '^/actions/actionSequences';
import Well from '^/components/Well';
import { getLikertBoundsInformation, LikertBounds } from '^/productVersions';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentFooter from './AssessmentFooter';
import AssessmentHeader from './AssessmentHeader';
import LikertQuestion from './LikertQuestion';
import { productVersionQuestionProgress } from './QuestionProgress';
import ScrollTopOnPageChange from './ScrollTopOnPageChange';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

interface OwnProps {
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  raterFor: Map<string, any>;
  questionCollectionIdx: number;
  questionsWithAnswers: List<List<Map<string, any> | undefined>>;
}

interface DispatchProps {
  setProductVersionPage: typeof setProductVersionPage;
  storeProductVersionAnswer: typeof storeProductVersionAnswer;
  push: typeof push;
  answerMultipleQuestionsAndAdvance: typeof answerMultipleQuestionsAndAdvance;
  answerMultipleQuestionsAndSubmit: typeof answerMultipleQuestionsAndSubmit;
  answerMultipleQuestionsAndClose: typeof answerMultipleQuestionsAndClose;
}

interface StateProps {
  progress: Map<string, any>;
  isPending: boolean;
  response: Map<string, any>;
  uiLanguage: string;
}

type Props = DispatchProps & StateProps & OwnProps;

const QUESTIONS_PER_PAGE = 10;

export class LikertProductVersion extends PureComponent<Props> {
  public componentDidMount() {
    const currentQuestionIndex = this.props.questionsWithAnswers.findIndex(
      questionAndAnswer => !questionAndAnswer.get(1)
    );

    if (currentQuestionIndex === -1) {
      // We've already answered this productVersion, so jump to the last page
      this.props.setProductVersionPage(
        Math.floor(
          this.props.questionsWithAnswers.count() / QUESTIONS_PER_PAGE
        ) - 1
      );
    } else {
      this.props.setProductVersionPage(
        Math.floor(currentQuestionIndex / QUESTIONS_PER_PAGE)
      );
    }
  }

  public render() {
    const {
      productVersion,
      questionCollectionIdx,
      raterFor,
      isPending,
      progress,
      activity,
    } = this.props;
    const currentQuestions = this.currentQuestions();
    const currentPage = progress.get('currentPage', 0);
    const pageCount = this.pageCount();
    const bounds = getLikertBoundsInformation(
      productVersion,
      questionCollectionIdx
    );

    const actionCreator = this.isViewingLastPage()
      ? this.props.answerMultipleQuestionsAndSubmit
      : this.props.answerMultipleQuestionsAndAdvance;

    const introText =
      this.getQuestionCollection().get('on_page_guide_text') ||
      i18next.t<string>(
        'Please indicate your level of agreement for each of the statements below'
      );
    return (
      <AssessmentContainer large>
        <AssessmentHeader
          productVersion={productVersion}
          questionCollectionIdx={questionCollectionIdx}
          raterFor={raterFor}
          steps={pageCount}
          currentStep={currentPage}
          hideSectionName={activity.get('is_staged')}
          noLogo={
            productVersion.getIn(['product', 'type']) ===
            PRODUCT_TYPES.CHOICES.PERSPECTIVES
          }
        />

        <AssessmentBody
          className="mb-none"
          header={productVersionQuestionProgress(
            currentPage,
            QUESTIONS_PER_PAGE,
            this.props.questionsWithAnswers.count()
          )}
          questionIndex={currentPage}
        >
          <ScrollTopOnPageChange page={currentPage} />
          <Well>{introText}</Well>
          {currentQuestions
            .map(questionAndAnswer => {
              const question = questionAndAnswer.get(0)!;
              const answer = this.getSelectedAnswer(questionAndAnswer, bounds);
              return (
                <LikertQuestion
                  questionText={question.get('text')}
                  answer={answer}
                  key={question.get('id')}
                  onAnswer={this.onAnswer.bind(this, question)}
                  bounds={bounds}
                  isPending={isPending}
                  size="medium"
                />
              );
            })
            .toArray()}
        </AssessmentBody>
        <AssessmentFooter
          isNotComplete={!this.isPageComplete()}
          isSaving={isPending}
          showContinue={
            progress.get('canResume') &&
            productVersion.getIn(['product', 'type']) !==
              PRODUCT_TYPES.CHOICES.PERSPECTIVES
          }
          onContinueLater={this.confirmAndClose}
          onContinue={this.onNext.bind(this, actionCreator)}
          isEnd={this.isViewingLastPage()}
        />
      </AssessmentContainer>
    );
  }

  private getSelectedAnswer(
    questionAndAnswer: List<Map<string, any> | undefined>,
    bounds: LikertBounds
  ) {
    const question = questionAndAnswer.get(0)!;
    const answer = this.props.progress.getIn(
      ['unsavedResponses', question.get('id')],
      null
    );

    // We may need to reverse the answer if it's a reversed question
    if (answer === null) {
      const existingAnswer = questionAndAnswer.getIn([1, 'score'], null);
      if (existingAnswer !== null) {
        if (question.get('reversed')) {
          return bounds.likert_maximum - existingAnswer + 1;
        }
        return existingAnswer;
      }
    }
    return answer;
  }

  private pageCount() {
    return Math.ceil(
      this.props.questionsWithAnswers.count() / QUESTIONS_PER_PAGE
    );
  }

  private isViewingLastPage() {
    return this.props.progress.get('currentPage', 0) === this.pageCount() - 1;
  }

  private onAnswer(question: Map<string, any>, value: string | number) {
    this.props.storeProductVersionAnswer(question.get('id'), value);
  }

  private currentQuestions() {
    return this.props.questionsWithAnswers.slice(
      this.props.progress.get('currentPage', 0) * QUESTIONS_PER_PAGE,
      (this.props.progress.get('currentPage', 0) + 1) * QUESTIONS_PER_PAGE
    );
  }

  private onNext(
    actionCreator:
      | typeof answerMultipleQuestionsAndClose
      | typeof answerMultipleQuestionsAndAdvance
      | typeof answerMultipleQuestionsAndSubmit
  ) {
    const nextPage = this.props.progress.get('currentPage', 0) + 1;
    const answers = this.props.progress
      .get('unsavedResponses')
      .toKeyedSeq()
      .map((score: string | number, question: Map<string, any>) => {
        return Map({
          question,
          score,
        });
      })
      .toList();

    actionCreator(
      this.props.activity,
      this.props.productVersion,
      this.props.raterFor,
      answers,
      nextPage,
      this.props.questionCollectionIdx,
      this.props.uiLanguage
    );
  }

  private confirmAndClose = () => {
    if (
      window.confirm(
        i18next.t<string>(
          'If you need to come back to finish the questionnaire, you will be able to return to where you left off.'
        )
      )
    ) {
      this.onNext(this.props.answerMultipleQuestionsAndClose);
    }
  };

  private isPageComplete() {
    return this.currentQuestions().every(questionAndAnswer => {
      const answer = questionAndAnswer.get(1);
      const question = questionAndAnswer.get(0)!;
      return (
        (answer ? answer.get('score', null) !== null : false) ||
        this.props.progress.getIn(['unsavedResponses', question.get('id')])
      );
    });
  }

  private getQuestionCollection() {
    const { productVersion, questionCollectionIdx } = this.props;
    return productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx,
    ]);
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    progress: state.productVersionProgress,
    isPending: anyPending([
      state.responses.get('getCollection'),
      state.responses.get('answerQuestion'),
      state.responses.get('updateActivityProductVersionSession'),
    ]),
    response: state.responses.get('answerQuestion'),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setProductVersionPage,
  storeProductVersionAnswer,
  push,
  answerMultipleQuestionsAndAdvance,
  answerMultipleQuestionsAndSubmit,
  answerMultipleQuestionsAndClose,
})(LikertProductVersion);
