import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';

import { DEFAULT_LANGUAGE_CODE } from '^/constants/routes';
import { StoreState } from '^/store';
import PureComponent from '../PureComponent';
import InviteUserFormSegment, {
  InviteUserFormProps,
} from './InviteUserFormSegment';

interface OwnProps {
  response: Map<string, any>;
  onSubmit: (data: InviteUserFormProps) => void;
  onClose: () => void;
  isReinvite?: boolean;
  userName: string;
}

interface StateProps {
  initialValues: {
    opening: string;
    closing: string;
  };
}

type Props = OwnProps & StateProps;

export class InviteUserForm extends PureComponent<Props> {
  public render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        onSubmit={(values, { setSubmitting }) => {
          this.props.onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <InviteUserFormSegment
              {...this.props}
              buttonText={
                this.props.isReinvite
                  ? i18next.t<string>('Reinvite User')
                  : i18next.t<string>('Invite User')
              }
              opening={values.opening}
              closing={values.closing}
              change={setFieldValue}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  initialValues: {
    opening:
      state.inviteDefaults.opening_message?.[DEFAULT_LANGUAGE_CODE] || '',
    closing: '',
  },
});

export default connect(mapStateToProps)(InviteUserForm);
