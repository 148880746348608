import React from 'react';
import i18next from 'i18next';
import { Map } from 'immutable';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { useFormikContext, Field } from 'formik';

import { hasFailed } from '^/responseStates';
import FormError from '^/components/FormError';
import CreatePulseAddUsers from './CreatePulseAddUsers';
import {
  AllOrgs,
  Organisation,
  PulseBehaviourHierarchyOptions,
} from '^/reducers/api/types';
import { PulseFrequencyDetails, CreatePulseFormValues } from './types';
import DetailRow from '../detail-list/DetailRow';
import NewButton from '../NewButton';
import PulseTimeFrequencySelector from './PulseTimeFrequencySelector';
import { ProductSelector, ProductSelectorProduct } from '../ProductSelector';
import { openModal, closeTopModal } from '^/actions/modals';
import Tag from '../Tag';
import { StoreState } from '^/store';

export interface OwnProps {
  selectedOrganisation?: Organisation;
  response?: Map<string, any>;
  organisations: AllOrgs | null;
  pulseBehaviourHierarchyOptions: PulseBehaviourHierarchyOptions | null;
  getCollectionPending: boolean;
  getOrgPulseBehaviourHierarchyOptions: (orgId: string) => void;
  onChangeOrg: (orgId: string) => void;
  pulseName: string;
  orgFlexiPulseNamesResponse?: Map<string, any>;
  nameAlreadyInUse?: boolean;
  createDisabled: boolean;
  customSubmittedButtonText?: string;
  submitting?: boolean;
}

interface DispatchProps {
  openModal: typeof openModal;
  closeTopModal: typeof closeTopModal;
}

interface StateProps {
  isModalOpen: boolean;
}

interface ButtonConfig {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

type selectableButtonIds = 'capabilityBank' | 'buildYourOwn';

export type Props = OwnProps & DispatchProps & StateProps;

export const CreatePulseSubForm1: React.FC<Props> = props => {
  const [
    pulseBehaviourHierarchy,
    setPulseBehaviourHierarchy,
  ] = React.useState<ProductSelectorProduct | null>(null);
  const [groupSelected, setGroupSelected] = React.useState<boolean>(false);
  const [selectedButtonId, setSelectedButtonId] = React.useState<
    selectableButtonIds | undefined
  >(undefined);

  const { values, setFieldValue } = useFormikContext<CreatePulseFormValues>();

  const {
    response,
    organisations,
    pulseBehaviourHierarchyOptions,
    selectedOrganisation,
    onChangeOrg,
    getOrgPulseBehaviourHierarchyOptions,
    orgFlexiPulseNamesResponse,
    nameAlreadyInUse,
    createDisabled,
    customSubmittedButtonText,
    submitting,
  } = props;

  const updateFrequencyFields = ({
    frequency,
    frequency_count,
    reminder_time_utc,
  }: PulseFrequencyDetails) => {
    setFieldValue('frequency', frequency);
    setFieldValue('frequency_count', frequency_count);
    setFieldValue('reminder_time_utc', reminder_time_utc);
  };

  const updateCapability = (selected: ProductSelectorProduct) => {
    setFieldValue('pulse_behaviour_hierarchy', selected.id);
    setPulseBehaviourHierarchy({
      id: selected.id,
      icon: selected.icon,
      name: selected.name,
      price: selected.price,
      selected: true,
    });
  };

  const showAddCapabilitiesModal = (refreshOptions?: boolean) => {
    const statePulseBehaviourHierarchyOptions =
      pulseBehaviourHierarchy && pulseBehaviourHierarchyOptions
        ? pulseBehaviourHierarchyOptions.map(item => ({
            ...item,
            selected: pulseBehaviourHierarchy?.id === item.id ? true : false,
          }))
        : pulseBehaviourHierarchyOptions;

    props.openModal({
      className:
        'product-selector product-selector-modal white-background-small',
      title: i18next.t<string>('Capability Bank'),
      body: (
        <ProductSelector
          title={i18next.t<string>('Capability Bank')}
          products={
            refreshOptions
              ? pulseBehaviourHierarchyOptions
              : statePulseBehaviourHierarchyOptions
          }
          singleSelection
          confirmButtonLabel={'Add'}
          onConfirm={selectedList => updateCapability(selectedList[0])}
          onClose={props.closeTopModal}
        />
      ),
    });
  };

  const removeCapability = () => {
    setPulseBehaviourHierarchy(null);
    showAddCapabilitiesModal(true);
  };

  const selectableButtonsConfig: Record<selectableButtonIds, ButtonConfig> = {
    capabilityBank: {
      label: 'Capability Bank',
      onClick: () => showAddCapabilitiesModal(),
    },
    buildYourOwn: {
      label: 'Build Your Own',
      onClick: () => {},
      disabled: true,
    },
  };

  const renderSelectableCapabilities = () => {
    return Object.entries(selectableButtonsConfig).map(
      ([key, buttonConfig], index) => {
        return (
          <NewButton
            key={index}
            type="button"
            buttonType={
              selectedButtonId === key ? 'select-pill-selected' : 'select-pill'
            }
            onClick={() => {
              buttonConfig.onClick();
              setSelectedButtonId(key as selectableButtonIds);
            }}
            disabled={
              !selectedOrganisation ||
              !pulseBehaviourHierarchyOptions ||
              buttonConfig.disabled
            }
          >
            {i18next.t<string>(buttonConfig.label)}
          </NewButton>
        );
      }
    );
  };

  const isSubmittingAndHasCustomSubmitButtonText = Boolean(
    submitting && customSubmittedButtonText
  );

  return (
    <>
      <div className="form-inline">
        <div>
          <label htmlFor="account">{i18next.t<string>('Account')}</label>

          <div className="form-item">
            <select
              id="account"
              name="account"
              defaultValue=""
              disabled={!organisations}
              onChange={e => {
                const value = e.target.value;
                onChangeOrg(value);
                setFieldValue('organisation', value);
                getOrgPulseBehaviourHierarchyOptions(value);
                setPulseBehaviourHierarchy(null);
                setGroupSelected(false);
              }}
            >
              <option value="">
                {i18next.t<string>(
                  organisations ? 'Please select...' : 'Loading...'
                )}
              </option>

              {organisations?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <FormError response={response} formKey="organisation" />
        <div>
          <label>{i18next.t<string>('Who')}</label>
          <div className="form-item">
            <CreatePulseAddUsers
              userIds={values.users}
              onUpdate={users => setFieldValue('users', users)}
              onGroupSelected={selected => setGroupSelected(selected)}
              groupSelected={groupSelected}
              organisationId={values.organisation}
              isModalOpen={props.isModalOpen}
            />
          </div>
        </div>
        <div>
          <label htmlFor="pulse">{i18next.t<string>('What')}</label>

          <div className="capabilities">{renderSelectableCapabilities()}</div>

          <Field
            type="hidden"
            name="pulse_behaviour_hierarchy"
            value={pulseBehaviourHierarchy ? pulseBehaviourHierarchy.id : ''}
          />
        </div>
        {pulseBehaviourHierarchy && (
          <div className="form-tag-wrapper">
            <label />
            <div className="form-tag">
              <Tag
                name={pulseBehaviourHierarchy.name}
                onRemove={() => removeCapability()}
              />
            </div>
          </div>
        )}
        <FormError response={response} formKey="pulse_behaviour_hierarchy" />

        <DetailRow label={i18next.t<string>('Frequency')}>
          <PulseTimeFrequencySelector
            setValue={updateFrequencyFields}
            disabled={!selectedOrganisation}
            organisationId={values.organisation}
            customEditIcon={<FontAwesomeIcon icon={faEdit} />}
          />
        </DetailRow>
        <FormError response={response} formKey="frequency" />
        <FormError response={response} formKey="frequency_count" />
        <FormError response={response} formKey="reminder_time_utc" />
        <div>
          <label>{i18next.t<string>('Pulse name')}</label>
          <div className="form-item">
            <Field
              type="text"
              placeholder={i18next.t<string>('Enter pulse check name')}
              name="name"
            />
            {nameAlreadyInUse && (
              <p className="text-error">
                {i18next.t<string>('Pulse check name must be unique')}
              </p>
            )}
            {hasFailed(orgFlexiPulseNamesResponse) && (
              <p className="text-error">
                {i18next.t<string>(
                  'There was an error fetching the names of pulses on this organisation'
                )}
              </p>
            )}
            <FormError response={response} formKey="name" />
            <FormError response={response} formKey="non_field_errors" />
          </div>
        </div>
        <hr />
        <div className="form-button-group">
          <div className="form-item wrapping">
            <Link to={'/page/pulses/hub/'} className="btn btn-outline">
              {i18next.t<string>('Cancel')}
            </Link>

            <NewButton
              type="submit"
              disabled={
                isSubmittingAndHasCustomSubmitButtonText || createDisabled
              }
            >
              {isSubmittingAndHasCustomSubmitButtonText
                ? customSubmittedButtonText
                : i18next.t<string>('Create')}
            </NewButton>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state: StoreState): StateProps {
  return {
    isModalOpen: Boolean(state.ui.get('modalsConfig').length),
  };
}

export default connect(mapStateToProps, {
  openModal,
  closeTopModal,
})(CreatePulseSubForm1);
