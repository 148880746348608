/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from 'i18next';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowToLeft,
  faArrowToRight,
  faDownload,
  faPlus,
  faKey,
} from '@fortawesome/pro-light-svg-icons';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import _ from 'underscore';
import { connect } from 'react-redux';

import { can, administerOrganisations } from '^/capabilities';
import { clearHeatmapRows } from '^/actions/analytics';
import { openDataAnalyticsOverlayModal } from '^/actions/modals';
import { StoreState } from '^/store';
import {
  AnalyticsAppliedFilters,
  AnalyticsFilters,
  AnalyticsProduct,
  HeatmapRow,
} from '^/reducers/api/types';
import { downloadElementImage } from '^/utils-ts';
import {
  Column,
  DATA_ANALYTICS_TABLE_STRUCTURE,
  makeThreeSixtyColumnGroups,
} from './DataAnalyticsTableStructure';
import { ColumnGroup } from './DataAnalyticsTable';
import HeatmapLegend from './HeatmapLegend';
import DataAnalyticsHeatmapRow from './DataAnalyticsHeatmapRow';
import DataAnalyticsTd from './DataAnalyticsTd';
import {
  ALL_FILTERS,
  HUMAN_READABLE_KEYS,
  getNamesFromIDs,
  getSummaryName,
  isLighter,
  isSafari,
} from './utils';
import MenuButton, { MenuButtonOption } from '../MenuButton';
import Loading from '../Loading';

const CHART_IMAGE_EXTRA_WIDTH = 250;
interface OwnProps {
  user: Immutable.Map<string, any>;
  aggregate: HeatmapRow | null;
  loading: boolean;
  inModal: boolean;
  product: AnalyticsProduct;
  reloadHeatmapChart: () => void;
  switchTabToTable: () => void;
}

interface State {
  columnGroups: ReadonlyArray<ColumnGroup> | null;
  product: AnalyticsProduct | undefined;
  showHeatmapLegend: boolean;
}

interface StateProps {
  heatmapRows: HeatmapRow[];
  heatmapRowsModal: HeatmapRow[];
}

interface DispatchProps {
  openDataAnalyticsOverlayModal: typeof openDataAnalyticsOverlayModal;
  clearHeatmapRows: typeof clearHeatmapRows;
}

type Props = OwnProps & DispatchProps & StateProps;

export class DataAnalyticsChartsPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      heatmapRows,
      heatmapRowsModal,
      inModal,
      switchTabToTable,
    } = this.props;

    const product = inModal
      ? heatmapRowsModal[0] && heatmapRowsModal[0].filtersState.product
      : heatmapRows[0] && heatmapRows[0].filtersState.product;

    if (product?.type === 'DISC') {
      switchTabToTable();
    }
    const allColumnGroups =
      product && product.activity_type === 'THREE_SIXTY'
        ? product.primary_competencies &&
          makeThreeSixtyColumnGroups(product.primary_competencies)
        : product && DATA_ANALYTICS_TABLE_STRUCTURE[product.type];
    const columnGroups =
      allColumnGroups && allColumnGroups.slice(2, allColumnGroups.length);
    this.state = {
      showHeatmapLegend: false,
      product: product,
      columnGroups: columnGroups
        ? columnGroups.map(columnGroup => ({
            ...columnGroup,
            isExpanded: true,
          }))
        : [],
    };
  }
  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.product?.type !== prevProps.product?.type ||
      this.props.heatmapRows[0]?.appliedFilters.filter_profile !==
        prevProps.heatmapRows[0]?.appliedFilters.filter_profile
    ) {
      this.props.reloadHeatmapChart();
    }
  }

  public expandCollapseColumnGroup = (index: number) => {
    this.setState({
      columnGroups:
        this.state.columnGroups &&
        this.state.columnGroups.map((columnGroup, idx) => ({
          ...columnGroup,
          isExpanded:
            idx === index ? !columnGroup.isExpanded : columnGroup.isExpanded,
        })),
    });
  };

  public customizeImageDownload = (
    cloneDocument: Document,
    _cloneElement: HTMLElement
  ) => {
    const boxShadowClass = 'box-shadow-borders';
    const borderClass = 'image-download-borders';
    const elementToRemoveClass = 'expand-icon';
    const classToRemoveName = 'hidden-legend';
    const classSwapTo = 'heatmap-row-name-long';
    const classSwapFrom = 'heatmap-row-name';
    const classSwapToHeaders = 'vertical-headers-download';
    const classSwapFromHeaders = 'vertical-headers';

    const elementToRemove = cloneDocument?.getElementsByClassName(
      elementToRemoveClass
    );

    while (elementToRemove && elementToRemove.length > 0) {
      elementToRemove[0].remove();
    }

    if (this.state.showHeatmapLegend) {
      const classToRemove = cloneDocument?.getElementsByClassName(
        classToRemoveName
      );
      while (classToRemove && classToRemove.length > 0) {
        classToRemove[0].classList.remove(classToRemoveName);
      }
    }

    const classToSwap = cloneDocument?.getElementsByClassName(classSwapFrom);
    while (classToSwap && classToSwap.length > 0) {
      classToSwap[0].classList.add(classSwapTo);
      classToSwap[0].classList.remove(classSwapFrom);
    }

    const classToSwapHeaders = cloneDocument?.getElementsByClassName(
      classSwapFromHeaders
    );
    while (classToSwapHeaders && classToSwapHeaders.length > 0) {
      classToSwapHeaders[0].classList.add(classSwapToHeaders);
      classToSwapHeaders[0].classList.remove(classSwapFromHeaders);
    }
    const classToSwapBox = cloneDocument?.getElementsByClassName(
      boxShadowClass
    );
    while (classToSwapBox && classToSwapBox.length > 0) {
      classToSwapBox[0].classList.add(borderClass);
      classToSwapBox[0].classList.remove(boxShadowClass);
    }
  };

  public renderRows = () => {
    const { inModal, heatmapRowsModal, heatmapRows, user } = this.props;
    const { columnGroups, product } = this.state;
    const rows = inModal
      ? heatmapRowsModal
      : heatmapRows.length > 1
      ? heatmapRows.splice(0, 1)
      : heatmapRows;
    return rows.map((row, idx) => (
      <DataAnalyticsHeatmapRow
        key={idx}
        row={row}
        columnGroups={columnGroups}
        user={user}
        indices={[idx]}
        parentRowCount={row.filtersState.sessions?.count || 0}
        product={product}
        inModal={inModal}
      />
    ));
  };

  public render() {
    const {
      user,
      heatmapRows,
      heatmapRowsModal,
      loading,
      inModal,
    } = this.props;
    const { product, columnGroups, showHeatmapLegend } = this.state;
    const columnGroupNameInHover = (
      columnGroupIndex: number,
      columnIndex: number
    ) => {
      return (
        columnGroupIndex > 0 &&
        columnIndex < 3 &&
        product &&
        product.activity_type === 'THREE_SIXTY'
      );
    };
    const hasRows =
      (!inModal && heatmapRows && heatmapRows.length > 0) ||
      (inModal && heatmapRowsModal && heatmapRowsModal.length > 0);
    return loading ? (
      <div>
        <Loading />
      </div>
    ) : (
      <div className="data-analytics">
        <HeatmapLegend
          visible={showHeatmapLegend}
          legendType={product}
          isFooterBar
        />

        <div className="data-control-bar">
          <div className="data-control-bar-inner">
            <MenuButton
              label={<FontAwesomeIcon icon={faDownload} />}
              disabled={!product || (inModal && !heatmapRowsModal[0])}
              position="right"
            >
              <MenuButtonOption
                onSelect={() =>
                  downloadElementImage(
                    'heatmap-chart-element',
                    'heatmap-chart-image',
                    this.customizeImageDownload,
                    CHART_IMAGE_EXTRA_WIDTH
                  )
                }
                name={i18next.t<string>('Export Image')}
              />
              <MenuButtonOption
                onSelect={this.downloadCSV}
                name={i18next.t<string>('Export CSV')}
              />
            </MenuButton>
            <button
              className={classNames(
                'btn btn-small btn-icon-square',
                !showHeatmapLegend && 'btn-icon-square-secondary'
              )}
              disabled={!product || (inModal && !heatmapRowsModal[0])}
              onClick={this.toggleHeatmapLegend}
              title={i18next.t<string>('Show heatmap legend')}
            >
              <FontAwesomeIcon icon={faKey} />
            </button>
          </div>
        </div>
        <div className="heatmap-chart">
          {hasRows && (
            <>
              <div id="heatmap-chart-element">
                <table
                  id="chart-table"
                  className="heatmap-chart-table heatmap-show"
                >
                  <thead>
                    <tr>
                      <td className="heatmap-table-name-column">
                        {product?.type !== 'POSITIVE_RESILIENCE_PROFILER' ? (
                          <h5 className="h5-secondary">{product?.name}</h5>
                        ) : (
                          <span> </span>
                        )}
                      </td>
                      {columnGroups &&
                        columnGroups.map((columnGroup, index) => (
                          <>
                            <td
                              className={classNames(
                                'first-row-headers',
                                isSafari
                                  ? 'image-download-borders'
                                  : 'box-shadow-borders',
                                product &&
                                  product.type ===
                                    'POSITIVE_RESILIENCE_PROFILER' &&
                                  'prp-top-row'
                              )}
                              data-tip={columnGroup.name}
                              colSpan={this.getColSpan(columnGroup)}
                            >
                              <div
                                className="clickable"
                                onClick={() =>
                                  this.expandCollapseColumnGroup(index)
                                }
                              >
                                {columnGroup.isExpandable &&
                                columnGroup.isExpanded
                                  ? columnGroup.name
                                  : (product &&
                                      product.type !==
                                        'POSITIVE_RESILIENCE_PROFILER' &&
                                      columnGroup.chartCode) ||
                                    ''}
                                {columnGroup.isExpandable && (
                                  <FontAwesomeIcon
                                    size="lg"
                                    icon={
                                      columnGroup.isExpanded
                                        ? faArrowToLeft
                                        : faArrowToRight
                                    }
                                    className="expand-icon"
                                  />
                                )}
                              </div>
                            </td>
                          </>
                        ))}
                    </tr>
                    <tr>
                      <td className="heatmap-table-name-column align-top">
                        {product?.type === 'POSITIVE_RESILIENCE_PROFILER' ? (
                          <h5 className="h5-secondary">{product?.name}</h5>
                        ) : (
                          <span> </span>
                        )}
                      </td>
                      {columnGroups &&
                        columnGroups.map((columnGroup, columnGroupIndex) =>
                          columnGroup.columns.map(
                            ({ ...column }, columnIndex) => (
                              <td
                                key={columnIndex}
                                className={classNames(
                                  isSafari
                                    ? 'vertical-headers-download'
                                    : 'vertical-headers',
                                  !columnGroup.isExpanded &&
                                    column.isHideable(user)
                                    ? 'hide'
                                    : 'show'
                                )}
                                data-tip={
                                  product &&
                                  product.activity_type === 'THREE_SIXTY'
                                    ? (!column.isHideable(user) &&
                                        columnGroupIndex !== 0 &&
                                        columnIndex !== 0) ||
                                      columnGroupNameInHover(
                                        columnGroupIndex,
                                        columnIndex
                                      )
                                      ? columnGroup.name
                                      : column.columnName
                                    : (!column.isHideable(user) ||
                                      columnGroupNameInHover(
                                        columnGroupIndex,
                                        columnIndex
                                      )
                                        ? columnGroup.name + ' '
                                        : '') + column.altHoverText ||
                                      column.name
                                }
                              >
                                {isLighter(
                                  product?.activity_type,
                                  columnGroupIndex,
                                  columnIndex
                                ) ||
                                (product?.type === 'PSYCAP_POTENTIAL' &&
                                  columnIndex === 2) ||
                                (columnGroupIndex > 0 && columnIndex === 0) ? (
                                  <hr
                                    className={
                                      (isLighter(
                                        product?.activity_type,
                                        columnGroupIndex,
                                        columnIndex
                                      ) &&
                                        columnIndex !== 0) ||
                                      (product?.type === 'PSYCAP_POTENTIAL' &&
                                        columnIndex === 2)
                                        ? 'lighter hr'
                                        : 'hr'
                                    }
                                  />
                                ) : (
                                  isSafari && <hr className="divider hr" />
                                )}

                                <div
                                  className={classNames(
                                    'truncated-headers',
                                    column.isHideable(user) || 'primary-header'
                                  )}
                                >
                                  {product?.activity_type === 'THREE_SIXTY'
                                    ? (!column.isHideable(user) &&
                                        columnGroupIndex !== 0 &&
                                        columnIndex !== 0) ||
                                      columnGroupNameInHover(
                                        columnGroupIndex,
                                        columnIndex
                                      )
                                      ? columnGroup.name
                                      : column.columnName
                                    : !column.isHideable(user) ||
                                      columnGroupNameInHover(
                                        columnGroupIndex,
                                        columnIndex
                                      )
                                    ? columnGroup.name
                                    : column.name}
                                </div>
                              </td>
                            )
                          )
                        )}
                    </tr>
                    <tr>
                      <th className="small-row heatmap-table-name-column" />
                      {columnGroups &&
                        columnGroups.map((columnGroup, columnGroupIndex) =>
                          columnGroup.columns.map((column, columnIndex) => (
                            <th
                              key={columnIndex}
                              className={classNames(
                                'small-row',
                                this.getClassName(
                                  columnGroup,
                                  columnGroupIndex,
                                  column,
                                  columnIndex
                                )
                              )}
                            >
                              {product?.activity_type === 'THREE_SIXTY' ||
                              product?.type === 'POSITIVE_RESILIENCE_PROFILER'
                                ? column.name
                                : column.chartName}
                            </th>
                          ))
                        )}
                    </tr>
                  </thead>
                  <tbody id="tbody">{this.renderRows()}</tbody>
                </table>
                <HeatmapLegend
                  visible={showHeatmapLegend}
                  legendType={product}
                  isFooterBar={false}
                />
              </div>
            </>
          )}
          {inModal && (
            <button
              className="btn btn-small"
              onClick={() =>
                this.props.openDataAnalyticsOverlayModal(
                  i18next.t<string>('Add data set'),
                  null,
                  true,
                  true,
                  product?.id || ''
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} />
              {i18next.t<string>('Add data set')}
            </button>
          )}
        </div>
        <ReactTooltip
          delayShow={200}
          textColor="black"
          backgroundColor="white"
          border
          borderColor="#C9CCD6"
        />
      </div>
    );
  }

  private iterateColumns(callback: (column: Column) => any) {
    const { user } = this.props;

    return this.state.columnGroups!.flatMap(columnGroup =>
      columnGroup.columns
        .filter(column => columnGroup.isExpanded || !column.isHideable(user))
        .map(callback)
    );
  }

  private downloadCSV = () => {
    const { heatmapRows, heatmapRowsModal, user, inModal } = this.props;
    const csv_filters = ALL_FILTERS.filter(col => col !== 'user').filter(
      col => can(user, administerOrganisations()) || col !== 'organisation'
    ) as ReadonlyArray<keyof AnalyticsFilters>;

    const headers = ['Product', 'Data set']
      .concat(csv_filters.map(key => HUMAN_READABLE_KEYS[key] || ''))
      .concat(this.iterateColumns(column => column.chartName || column.name));

    type ParentFilters = { [key in keyof AnalyticsAppliedFilters]: string };

    const outputRow = (
      row: HeatmapRow,
      rootRow: HeatmapRow | null,
      parentFilters: ParentFilters
    ) =>
      [
        (rootRow || row).filtersState.product?.name || '',
        getSummaryName(rootRow || row),
      ]
        .concat(
          csv_filters.map(
            key =>
              (rootRow && (parentFilters[key] || getNamesFromIDs(key, row))) ||
              i18next.t<string>('ALL')
          )
        )
        .concat(
          this.iterateColumns(column =>
            row.filtersState.product
              ? new DataAnalyticsTd({
                  product: row.filtersState.product,
                  count: 1,
                  aggregate: row.filtersState.sessions?.aggregate,
                  column: column,
                  className: '',
                }).renderAsString() || ''
              : ''
          )
        );

    const outputRows = (
      rows: HeatmapRow[],
      rootRow: HeatmapRow | null,
      parentFilters: ParentFilters
    ): string[][] =>
      rows.flatMap(row =>
        [outputRow(row, rootRow, parentFilters)].concat(
          outputRows(
            row.children,
            rootRow || row,
            rootRow
              ? _.object(
                  csv_filters.map(key => [
                    key,
                    parentFilters[key] || getNamesFromIDs(key, row),
                  ])
                )
              : {}
          )
        )
      );

    const rows = [headers].concat(
      outputRows(!inModal ? heatmapRows : heatmapRowsModal, null, {})
    );
    this.doDownloadCSV(rows);
  };

  private doDownloadCSV = (rows: ReadonlyArray<ReadonlyArray<string>>) => {
    const csvData = rows
      .map(row => row.map(field => `"${field}"`).join(','))
      .join('\n');
    const fileName = `${this.state.product?.name
      .split(' ')
      .join('_')}-summary-results.csv`;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  private isVisible = (columnGroup: ColumnGroup, column: Column) => {
    const { user } = this.props;
    return columnGroup.isExpanded || !column.isHideable(user);
  };

  private getClassName = (
    columnGroup: ColumnGroup,
    columnGroupIndex: number,
    column: Column,
    columnIndex: number
  ) => {
    const { user } = this.props;
    const { product } = this.state;
    const visible = this.isVisible(columnGroup, column);
    return classNames(visible ? 'show' : 'hide', {
      'primary-header': !column.isHideable(user),
      lighter:
        (isLighter(product?.activity_type, columnGroupIndex, columnIndex) &&
          columnIndex !== 0) ||
        (product?.type === 'PSYCAP_POTENTIAL' && columnIndex === 2),
      'left-line':
        isLighter(product?.activity_type, columnGroupIndex, columnIndex) ||
        (product?.type === 'PSYCAP_POTENTIAL' && columnIndex === 2) ||
        (columnGroupIndex > 0 && columnIndex === 0),
    });
  };

  private toggleHeatmapLegend = () => {
    this.setState({ showHeatmapLegend: !this.state.showHeatmapLegend });
  };

  private getColSpan = (columnGroup: ColumnGroup) =>
    columnGroup.columns.filter(
      column => columnGroup.isExpanded || !column.isHideable(this.props.user)
    ).length;
}

export function mapStateToProps(state: StoreState) {
  return {
    heatmapRowsModal: state.heatmapRowsModal,
    heatmapRows: state.heatmapRows,
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, {
  openDataAnalyticsOverlayModal,
  clearHeatmapRows,
})(DataAnalyticsChartsPage);
