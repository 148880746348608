import React from 'react';

export default () => (
  <svg
    width="8"
    height="3"
    viewBox="0 0 8 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.18486 1.92245H1.08997"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
