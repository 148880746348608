import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { authenticateInvite } from '^/actions/actions';
import {
  redeemInviteAndCheckUserInfo,
  setLanguageAndReload,
} from '^/actions/actionSequences';
import * as responseStates from '^/responseStates';
import { withRouter } from '^/withRouter';
import PureComponent from './PureComponent';
import RedeemInviteForm from './RedeemInviteForm';
import NoAuth from './NoAuth';
import { getQueryValue } from '^/utils-ts';

export class RedeemInvite extends PureComponent {
  componentDidMount() {
    this.props.authenticateInvite(this.props.inviteId);
  }

  componentDidUpdate(prevProps) {
    const { tokenResponse } = this.props;
    if (prevProps.tokenResponse !== tokenResponse) {
      this.changeLanguage(tokenResponse);
    }
  }

  handleGetResponse(response) {
    switch (response && response.get('state')) {
      case responseStates.PENDING:
        return <p>{i18next.t('Checking token...')}</p>;
      case responseStates.SUCCESSFUL:
        return (
          <p>
            {i18next.t(
              'Please complete the following information to create your account'
            )}
            :
          </p>
        );
      case responseStates.FAILED:
        const errorMsg = response.getIn(['errors', 'msg']);
        if (errorMsg === 'This invite has already been redeemed') {
          return (
            <div>
              <p className="text-error">{errorMsg}</p>
              <p>
                <Trans i18nKey="TRANS Please log in here">
                  Please{' '}
                  <a onClick={() => this.props.push('/login/')}>log in here</a>.
                </Trans>
              </p>
            </div>
          );
        }
        return (
          <p className="text-error">
            {i18next.t('There is a problem with this invite')}: {errorMsg}
          </p>
        );
      default:
        return <p>...</p>;
    }
  }

  redeemInvite(data) {
    this.props
      .redeemInviteAndCheckUserInfo(
        this.props.inviteId,
        this.props.tokenResponse.getIn(['payload', 'email']),
        data.fullName,
        data.password || '',
        data.confirmPassword || '',
        this.props.uiLanguage,
        this.props.userPulseId
      )
      .catch(e => {
        // We expect 400 errors from the backend
        if (!e || e.payload.status !== 400) {
          throw e;
        }
      });
  }

  changeLanguage(tokenResponse) {
    if (
      tokenResponse &&
      tokenResponse.get('state') === responseStates.SUCCESSFUL
    ) {
      const tokenLangCode = this.props.tokenResponse.getIn([
        'payload',
        'lang_code',
      ]);
      this.props.uiLanguage !== tokenLangCode &&
        this.props.setLanguageAndReload(tokenLangCode);
    }
  }

  render() {
    return (
      <NoAuth>
        <div className="form-horizontal">
          <h2>{i18next.t('Redeem Invite')}</h2>
          {this.handleGetResponse(this.props.tokenResponse)}

          {this.props.tokenResponse &&
          this.props.tokenResponse.get('state') ===
            responseStates.SUCCESSFUL ? (
            <RedeemInviteForm
              response={this.props.response}
              email={this.props.tokenResponse.getIn(['payload', 'email'])}
              fullName={this.props.tokenResponse.getIn([
                'payload',
                'full_name',
              ])}
              onSubmit={this.redeemInvite.bind(this)}
            />
          ) : null}
        </div>
      </NoAuth>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    tokenResponse: state.responses.get('checkToken'),
    response: state.responses.get('redeem'),
    inviteId: props.router.params.id,
    url: `${props.router.location.pathname}${props.router.location.search}`,
    uiLanguage: state.ui.get('uiLanguage'),
    userPulseId: getQueryValue(props, 'userpulse'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    authenticateInvite,
    redeemInviteAndCheckUserInfo,
    push,
    setLanguageAndReload,
  })(RedeemInvite)
);
