import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';
import i18next from 'i18next';
import moment from 'moment';
import { Trans } from 'react-i18next';

import { can, administerOrganisations } from '^/capabilities';
import { formatDate } from '^/utils';
import PureComponent from '^/components/PureComponent';
import Checkbox from '^/components/Checkbox';
import {
  getDayFromNumber,
  getPluralDayFromNumber,
  getPulseDisplayFrequency,
} from './utils';
import { PulseDisplayFrequency } from '^/reducers/api/types';
import { getHoursMinutesFromTime } from '../time/utils';

export default class PulseRow extends PureComponent {
  select() {
    if (this.props.isSelectable && !this.props.disabled) {
      this.props.onSelect();
    }
  }

  getClassName(status) {
    return (
      'user-row ' +
      status.toLowerCase() +
      (this.props.isSelectable && !this.props.disabled ? ' selectable' : '') +
      (this.props.isSelected ? ' selected' : '')
    );
  }

  getDisplayFrequencyDetail() {
    const { frequency, frequency_count, created } = this.props.pulse.toObject();
    const time = getHoursMinutesFromTime(
      this.props.pulse.toObject().reminder_time_utc
    );

    const day = moment(created).day();
    switch (getPulseDisplayFrequency({ frequency, frequency_count })) {
      case PulseDisplayFrequency.DAILY:
        return i18next.t('Daily at {{time}}', { time });
      case PulseDisplayFrequency.WEEKLY:
        return i18next.t('{{dayPlural}} at {{time}}', {
          dayPlural: i18next.t(getPluralDayFromNumber(day)),
          time,
        });
      case PulseDisplayFrequency.MONTHLY:
        const translatedDay = i18next.t(getDayFromNumber(day));
        return (
          <Trans i18nKey="TRANS pulse monthly frequency">
            Every 4<sup>th</sup>
            {{ translatedDay }} at {{ time }}
          </Trans>
        );
    }
  }

  render() {
    const { pulse, isSelectable, user } = this.props;
    const {
      id,
      created,
      name,
      organisation,
      pulse_behaviour_hierarchy,
    } = pulse.toObject();

    return (
      <tr disabled={this.props.disabled} onClick={() => this.select()}>
        {can(user, administerOrganisations()) && (
          <td>{organisation.get('name')}</td>
        )}
        <td>
          {this.props.isSelectable ? (
            name
          ) : (
            <Link to={`/page/pulses/${id}`} className="highlighted">
              {name}
            </Link>
          )}
        </td>
        <td>{pulse_behaviour_hierarchy.get('name')}</td>
        <td className="invited-completed center">{formatDate(created)}</td>
        <td>{this.getDisplayFrequencyDetail.bind(this)()}</td>
        {!this.props.selectOne && isSelectable && (
          <td className="actions">
            <Checkbox
              checked={this.props.isSelected}
              disabled={this.props.disabled}
            />
          </td>
        )}
      </tr>
    );
  }
}
