import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import PureComponent from '^/components/PureComponent';
import SelectUserInputInner from '^/components/forms/SelectUserInputInner';
import { updateSelectUserQuery } from '^/actions/actions';
import { addRaterUserThen } from '^/actions/actionSequences';

export class SelectUserInput extends PureComponent {
  submitKnownUser(userId, email) {
    this.updateSelectUserQuery('');

    this.props.doAdd(userId, email);
  }

  updateSelectUserQuery(value) {
    this.props.updateSelectUserQuery(this.props.id, value);
  }

  submitUnknownUser(email) {
    if (this.props.disabled) {
      return;
    }
    this.updateSelectUserQuery('');

    this.props.addRaterUserThen(
      user => this.props.doAdd(user.id, user.email),
      email,
      '',
      !this.props.inviteOnAdd,
      this.source
    );
  }

  render() {
    return (
      <div>
        <SelectUserInputInner
          submitKnownUser={this.submitKnownUser.bind(this)}
          submitUnknownUser={
            !this.props.existingOnly && this.submitUnknownUser.bind(this)
          }
          {...this.props}
        />
      </div>
    );
  }
}

SelectUserInput.propTypes = {
  excludedUserIds: ImmutablePropTypes.list,
  organisationId: PropTypes.string.isRequired,
  includeInactive: PropTypes.bool,
  id: PropTypes.string.isRequired,
  setFocusOnLoad: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  doAdd: PropTypes.func.isRequired,
  inviteOnAdd: PropTypes.bool,
};

export default connect(null, {
  updateSelectUserQuery,
  addRaterUserThen,
})(SelectUserInput);
