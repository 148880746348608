export enum MODAL_COMPONENTS {
  CREATE_OR_EDIT_FILTER_PROFILE,
  CREATE_EDIT_FILTER_PROFILE,
  LIST_FILTER_PROFILES,
  FILTER_PROFILE_DETAIL,
}

export enum ADVANCED_ANALYTICS_ATTRIBUTE {
  LEVEL = 'level',
  STEN = 'sten',
  PERCENTILE = 'percentile',
  SCORE = 'score',
  RATING = 'rating',
}
