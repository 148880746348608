import React from 'react';
import classnames from 'classnames';

import { AnalyticsProduct } from '^/reducers/api/types';
import { DATA_ANALYTICS_HEATMAP_LEGEND } from './DataAnalyticsTableStructure';

interface Props {
  visible: boolean;
  legendType: AnalyticsProduct | undefined;
  isFooterBar: boolean;
}

export class HeatmapLegend extends React.PureComponent<Props> {
  render() {
    const { visible, legendType, isFooterBar } = this.props;

    const heatmapColours =
      legendType?.type && DATA_ANALYTICS_HEATMAP_LEGEND[legendType.type];

    return (
      <div
        className={classnames(
          isFooterBar
            ? 'action-bar justify-content-center'
            : 'hidden-legend justify-content-flex-start',
          'display-flex',
          { visible }
        )}
      >
        <div
          className={classnames(
            !isFooterBar || 'action-bar-wrapper',
            'heatmap-legend-wrapper',
            'heatmap-show'
          )}
        >
          {heatmapColours?.levels.map((level, idx) => (
            <div
              key={idx}
              className="heatmap-legend display-flex justify-content-flex-start"
            >
              {level.colours.map((colour, index) => (
                <div key={index} className={classnames(colour, 'colour-box')} />
              ))}
              <div className="level-name">{level.name}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default HeatmapLegend;
