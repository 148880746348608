/* eslint-disable no-console */
import { Action, AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { StoreState } from '^/store';

type WrappedThunkAction = (
  previousResultPayload: unknown,
  previousResult: unknown
) => AnyAction | ThunkAction<Promise<unknown>, StoreState, never, AnyAction>;

type ActionSequence = ReadonlyArray<WrappedThunkAction>;

type OurDispatch = ThunkDispatch<StoreState, never, AnyAction>;

interface ActionWithPayload extends Action {
  payload: unknown;
}

interface ActionWithError extends Action {
  error: boolean;
}

export type ActionChain = (
  dispatch: OurDispatch
) => Promise<[unknown, ActionSequence] | undefined>;

const hasPayload = (input: unknown): input is ActionWithPayload =>
  Boolean(typeof input === 'object' && input && 'payload' in input);

const hasError = (input: unknown): input is ActionWithError =>
  Boolean(typeof input === 'object' && input && 'error' in input);

const makeActionChain = (sequence: ActionSequence) => (
  dispatch: OurDispatch
) => {
  const createAction = (
    [actionCreator, ...rest]: ActionSequence,
    previousResult?: unknown
  ): Promise<[unknown, ActionSequence] | undefined> =>
    hasError(previousResult) && previousResult.error
      ? Promise.reject(
          (hasPayload(previousResult) && previousResult.payload) ||
            new Error('Unknown error')
        )
      : // The below cast is required to avoid passing a union of AnyAction and ThunkAction.
        // Which are not accepted by the ThunkDispatch type. It only accepts one or the other.
        // This would otherwise require a ternary/if statement to separate the types.
        Promise.resolve(
          dispatch(
            actionCreator(
              hasPayload(previousResult) ? previousResult.payload : undefined,
              previousResult
            ) as AnyAction
          )
        ).then(result =>
          rest.length ? createAction(rest, result) : undefined
        );

  return createAction(sequence).catch(error => console.error(error));
};

export default makeActionChain;
