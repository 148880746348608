import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import { range } from 'underscore';

import Alert from '^/components/Alert';
import { momentRangeBounded, padTens } from '../utils';
import { CREDIT_EXPORTER_DATE_RANGE_MAX } from '^/components/credits/constants';

const HOURS = range(0, 24).map(padTens);
const MINS = range(0, 60, 5).map(padTens);

interface OwnProps {
  value?: string | Moment | Date;
  minDatetime?: Moment;
  maxDatetime?: Moment;
  onCancel?: () => void;
  onComplete: (date: string) => void;
  disableTime?: boolean;
  notice?: string;
  isCreditDatePicker?: boolean;
  creditDateFirst?: Date;
  creditDateSecond?: Date;
}

interface State {
  date: Moment;
}

type Props = OwnProps;

export class DateTimePickerModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { value, minDatetime, maxDatetime } = this.props;

    this.state = {
      date: momentRangeBounded(
        { start: minDatetime, end: maxDatetime },
        value ? moment(value) : moment().startOf('day')
      ),
    };
  }

  public render() {
    const {
      minDatetime,
      maxDatetime,
      notice,
      disableTime,
      isCreditDatePicker,
      creditDateFirst,
      creditDateSecond,
    } = this.props;

    const date = disableTime ? this.state.date : this.roundDateToMinutes();

    const duration = Math.abs(
      moment
        .duration(
          moment(this.state.date).diff(
            creditDateFirst ? creditDateFirst : creditDateSecond
          )
        )
        .asDays()
    );

    const isCreditDateRangeInvalid =
      isCreditDatePicker && duration > CREDIT_EXPORTER_DATE_RANGE_MAX;

    return (
      <div>
        {notice && <Alert>{notice}</Alert>}
        <Calendar
          value={date && date.toDate()}
          onChange={this.setDay}
          minDate={
            minDatetime &&
            moment(minDatetime)
              .startOf('day')
              .toDate()
          }
          maxDate={maxDatetime && maxDatetime.toDate()}
          next2Label={null}
          prev2Label={null}
        />

        {!disableTime && (
          <div className="row form-horizontal time-selects">
            <div className="col-xs-6">
              <select value={date.get('hour')} onChange={this.setHours}>
                {HOURS.map(hour => (
                  <option key={hour} value={+hour}>
                    {hour}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-xs-6">
              <select
                // round to nearest 5 minute interval
                value={date.get('minutes')}
                onChange={this.setMinutes}
              >
                {MINS.map(minute => (
                  <option key={minute} value={+minute}>
                    {minute}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {isCreditDateRangeInvalid && (
          <p className="text-error margin-top">
            The limit for each download is 1 year, please reduce the {''}
            selected timeframe.
          </p>
        )}

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button className="btn btn-default" onClick={this.props.onCancel}>
              {i18next.t<string>('Cancel')}
            </button>
            <button
              className="btn btn-primary"
              onClick={this.onComplete}
              disabled={isCreditDateRangeInvalid}
            >
              {i18next.t<string>('Select')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  private roundDateToMinutes = (roundMinutes = 5) => {
    const { date } = this.state;
    return date.clone().set({
      minutes: roundMinutes * Math.round(date.get('minutes') / roundMinutes),
      seconds: 0,
      milliseconds: 0,
    });
  };

  private setDay = (dateOrDates: Date | Date[]) => {
    const date = Array.isArray(dateOrDates) ? dateOrDates[0] : dateOrDates;
    const momentDate = moment(date);
    // set everything except for time
    this.setState({
      date: this.state.date.clone().set({
        year: momentDate.get('year'),
        month: momentDate.get('month'),
        date: momentDate.get('date'),
      }),
    });
  };

  private setHours = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      date: this.state.date.clone().hours(+event.target.value),
    });
  };

  private setMinutes = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      date: this.state.date.clone().minutes(+event.target.value),
    });
  };

  private onComplete = () => {
    const date = this.props.disableTime
      ? this.state.date.clone()
      : this.roundDateToMinutes();
    this.props.onComplete(date.toISOString());
  };
}

export default DateTimePickerModal;
