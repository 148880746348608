/* eslint-disable import/no-named-as-default-member */
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from '../../../PureComponent';
import { isPending } from '../../../../responseStates';
import { LIMIT_TEXT } from '../../../../constants/nominationRule';
import { LIMIT } from '../../../../nominationRules';
import propLists from '../../../../propLists';

const countOptions = fromJS([
  [1, i18next.t('one')],
  [2, i18next.t('two')],
  [3, i18next.t('three')],
  [4, i18next.t('four')],
  [5, i18next.t('five')],
  [6, i18next.t('six')],
  [7, i18next.t('seven')],
  [8, i18next.t('eight')],
  [9, i18next.t('nine')],
  [10, i18next.t('ten')],
  [11, i18next.t('eleven')],
  [12, i18next.t('twelve')],
  [13, i18next.t('thirteen')],
  [14, i18next.t('fourteen')],
  [15, i18next.t('fifteen')],
]);

export class ActivityNominationRulesForm extends PureComponent {
  render() {
    const { onSubmit, allRoles, response } = this.props;

    return (
      <Formik
        initialValues={{
          limit: fromJS(LIMIT).first(),
          count: countOptions.getIn([0, 0]),
          role: allRoles.getIn([0, 'id']),
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values }) => (
          <Form className="form-inline margin-bottom">
            <div>
              <label>User must nominate</label>

              <div className="form-item multiple">
                <Field as="select" name="limit" value={values.limit || ''}>
                  {propLists.toOptions(LIMIT_TEXT.entrySeq().map(List))}
                </Field>

                {values.limit !== 'UNRESTRICTED' && (
                  <Field as="select" name="count" value={values.count || ''}>
                    {propLists.toOptions(countOptions)}
                  </Field>
                )}

                <Field as="select" name="role" value={values.role || ''}>
                  {propLists.toOptions(
                    allRoles.map(each =>
                      List([each.get('id'), each.get('name')])
                    ),
                    +values.count !== 1
                  )}
                </Field>

                <input
                  type="submit"
                  value={i18next.t('Add rule')}
                  disabled={isPending(response)}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ActivityNominationRulesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  response: ImmutablePropTypes.map,
  allRoles: ImmutablePropTypes.list.isRequired,
};

export default ActivityNominationRulesForm;
