import { createSelector } from 'reselect';

import { selectItems } from '^/selectors';
import { selectUserOrganisation } from '^/selectors/user';

export const selectItemsOrganisation = createSelector(
  selectItems,
  items => items && items.get('organisations')
);

export const selectSelectedGroup = createSelector(
  selectItems,
  items => items && items.get('groups')
);

export const selectSelectedUser = createSelector(
  selectItems,
  items => items && items.get('users')
);

export const selectItemsOrUserOrganisation = createSelector(
  selectItemsOrganisation,
  selectUserOrganisation,
  (itemOrganisation, userOrganisation) => itemOrganisation || userOrganisation
);
