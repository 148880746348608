import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';
import classNames from 'classnames';

import PureComponent from '../../PureComponent';

export default class DashItem extends PureComponent {
  render() {
    const {
      to,
      horizontal,
      disabled,
      icon,
      title,
      strapline,
      onClick,
    } = this.props;
    const DivLink = to ? Link : 'div';
    return (
      <div
        className={classNames(
          'dash-item',
          { 'dash-item-horizontal': horizontal },
          { 'dash-item-disabled': disabled }
        )}
      >
        <DivLink
          to={!disabled ? to : undefined}
          onClick={!disabled ? onClick : undefined}
          className="dash-item-link"
        >
          {icon}
          <div className="dash-item-info">
            <h4 className="dash-title">{title}</h4>
            <p className="dash-strapline">{strapline}</p>
          </div>
        </DivLink>
      </div>
    );
  }
}
