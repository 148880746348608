import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import FormError from './FormError';
import LiveButton from './LiveButton';

export const ForgotPasswordForm = props => {
  const { onSubmit, response } = props;

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      <Form className="form-inline">
        <p>
          {i18next.t('Enter your email address below.')}
          <br />
          {i18next.t(
            'We will send you an email containing a link to reset your password.'
          )}
        </p>

        <div>
          <label htmlFor="email">{i18next.t('Email')}</label>
          <div className="form-item">
            <Field
              type="text"
              name="email"
              id="email"
              placeholder={i18next.t('Email')}
            />
            <FormError response={response} formKey="email" />
          </div>
        </div>

        <LiveButton
          response={response}
          buttonText={i18next.t('Send reset link')}
          pendingMessage={i18next.t('Sending email...')}
          additionalButtonClasses="col-xs-12"
          successContent={
            <p>
              {i18next.t(
                'Email has been sent. Please check your email for the password reset link.'
              )}
            </p>
          }
        />
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
