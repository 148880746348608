import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';
import i18next from 'i18next';

import PureComponent from '../../../PureComponent';
import { generateNominationRuleLabel } from '../../../../constants/nominationRule';
import { isPending } from '../../../../responseStates';

export default class ActivityNominationRule extends PureComponent {
  render() {
    const { rule, deleteRule, response, readOnly } = this.props;

    const isLoading = isPending(response);

    return (
      <li>
        {i18next.t('User must nominate {{raterRuleLable}}', {
          raterRuleLable: generateNominationRuleLabel(rule),
        })}
        {!readOnly &&
          (isLoading ? (
            <FontAwesomeIcon
              className="pull-right"
              icon={faSpinnerThird}
              spin
            />
          ) : (
            <a
              className="pull-right underlined"
              onClick={deleteRule.bind(null, rule.get('id'))}
            >
              {i18next.t('Remove')}
            </a>
          ))}
      </li>
    );
  }
}

ActivityNominationRule.propTypes = {
  rule: ImmutablePropTypes.map.isRequired,
  deleteRule: PropTypes.func.isRequired,
  response: ImmutablePropTypes.map,
};
