import { fromJS } from 'immutable';

export const RULES = {
  HIDE: 'HIDE',
  SHOW_ON_COMPLETION: 'SHOW_ON_COMPLETION',
  SHOW_AFTER_DATE: 'SHOW_AFTER_DATE',
  SHOW_AT_END: 'SHOW_AT_END',
};

function getDefaultReportVisibility() {
  return fromJS({
    rule: RULES.HIDE,
    date: null,
    hidden_from: [],
    hr_partners: [],
  });
}

export function getReportVisibility(activity) {
  return activity.get('report_visibility') || getDefaultReportVisibility();
}

export function setDisplayRule(activity, value, endDate) {
  const initial = getReportVisibility(activity);
  if (initial.get('rule') === value) {
    return initial;
  }
  const initialDate = initial.get('date');
  // If we don't have a date yet, generate it
  return initial
    .set('rule', value)
    .set(
      'date',
      value === RULES.SHOW_AFTER_DATE && !initialDate ? endDate : initialDate
    );
}
