import { Map } from 'immutable';

import makeRsaAction from '../middleware/makeRsaAction';
import makeAsyncActionSet from './makeAsyncActionSet';

export const LOAD_ORGANISATION_FILTERS = makeAsyncActionSet(
  'LOAD_ORGANISATION_FILTERS'
);
export const LOAD_ACTIVITY_FILTERS = makeAsyncActionSet(
  'LOAD_ACTIVITY_FILTERS'
);
export const LOAD_USER_FILTERS = makeAsyncActionSet('LOAD_USER_FILTERS');
export const LOAD_TEAM_FILTERS = makeAsyncActionSet('LOAD_TEAM_FILTERS');
export const LOAD_PRODUCT_FILTERS = makeAsyncActionSet('LOAD_PRODUCT_FILTERS');

function request(actionSet, url, requestData, meta) {
  const action = makeRsaAction(actionSet, url, 'GET', requestData, meta);
  return action;
}

export const FILTER_TYPE_ORGANISATION = 'ORGANISATION';
export const FILTER_TYPE_ACTIVITY = 'ACTIVITY';
export const FILTER_TYPE_USER = 'USER';
export const FILTER_TYPE_TEAM = 'TEAM';
export const FILTER_TYPE_PRODUCT = 'PRODUCT';

const FILTER_TYPE_URLS = Map({
  [FILTER_TYPE_ORGANISATION]: `/organisations/`,
  [FILTER_TYPE_ACTIVITY]: `/activities/list/`,
  [FILTER_TYPE_USER]: `/accounts/users/`,
  [FILTER_TYPE_TEAM]: `/reports/teams-simple/`,
  [FILTER_TYPE_PRODUCT]: `/analytics/products/filters/`,
});

const FILTER_TYPE_ACTIONS = Map({
  [FILTER_TYPE_ORGANISATION]: LOAD_ORGANISATION_FILTERS,
  [FILTER_TYPE_ACTIVITY]: LOAD_ACTIVITY_FILTERS,
  [FILTER_TYPE_USER]: LOAD_USER_FILTERS,
  [FILTER_TYPE_TEAM]: LOAD_TEAM_FILTERS,
  [FILTER_TYPE_PRODUCT]: LOAD_PRODUCT_FILTERS,
});

function buildRequestData(searchTerm, filters = Map()) {
  return filters
    .merge(searchTerm ? { contains: searchTerm } : {})
    .set('simple', true)
    .toJS();
}

export function loadFilterType(
  filterId,
  filterType,
  searchTerm = '',
  filters = Map()
) {
  return request(
    FILTER_TYPE_ACTIONS.get(filterType),
    FILTER_TYPE_URLS.get(filterType),
    buildRequestData(searchTerm, filters),
    Map({
      filterId,
      filterType,
      searchTerm,
      source: filterId,
    })
  );
}

export const SELECT_FILTER_ITEM = 'SELECT_FILTER_ITEM';

export function selectFilterItem(
  filterId,
  filterType,
  selectedOption,
  isAll = false
) {
  return {
    type: SELECT_FILTER_ITEM,
    meta: Map({
      filterId,
      filterType,
      selectedOption,
      isAll,
    }),
  };
}

export const PRESELECT_FILTER_ITEM = 'PRESELECT_FILTER_ITEM';

export function preselectFilterItem(filterId, filterType, selectedValue) {
  return {
    type: PRESELECT_FILTER_ITEM,
    meta: Map({
      filterId,
      filterType,
      selectedValue,
    }),
  };
}

export const CLEAR_FILTER_TYPE = 'CLEAR_FILTER_TYPE';

export function clearFilterType(filterId, filterType) {
  return {
    type: CLEAR_FILTER_TYPE,
    meta: Map({
      filterId,
      filterType,
    }),
  };
}

export const SET_FILTER_ID = 'SET_FILTER_ID';

export function setFilterId(name, filterId) {
  return {
    type: SET_FILTER_ID,
    name,
    filterId,
  };
}
