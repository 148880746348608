import React, { FunctionComponent } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

interface OwnProps {
  code: string;
  language?: string;
}

type Props = OwnProps;

const CodeSample: FunctionComponent<Props> = ({ code, language }) => (
  <SyntaxHighlighter
    style={darcula}
    language={language || 'xml'}
    showLineNumbers
  >
    {code}
  </SyntaxHighlighter>
);

export default CodeSample;
