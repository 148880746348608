import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { connect } from 'react-redux';

import { isPending } from '^/responseStates';
import { closeTopModal } from '^/actions/modals';
import { updateUser } from '^/actions/collections';
import {
  ACCOUNT_ADMIN_ROLE_DISPLAY,
  ACCOUNT_TYPE,
} from '^/models/organisation';
import Editable from '^/components/editable/Editable';
import FormError from '^/components/FormError';
import PureComponent from '^/components/PureComponent';

export class EditAccountAdminDetails extends PureComponent {
  render() {
    const {
      response,
      user,
      availableRoles,
      account,
      licenceHolders,
    } = this.props;

    const isEnterpriseAccount =
      account.get('account_type') === ACCOUNT_TYPE.ENTERPRISE;

    const userIsOnlyLicenceHolder =
      licenceHolders.size === 1 &&
      licenceHolders.get(0).get('id') === user.get('id');

    return (
      <div className="form-inline">
        <h6>
          {i18next.t('{{role}} details', {
            role: ACCOUNT_ADMIN_ROLE_DISPLAY[user.get('role')],
          })}
        </h6>
        <div>
          <div className="form-label">
            <label htmlFor="edit-account-admin-name">
              {i18next.t('Name')}:
            </label>
          </div>
          <div className="form-item">
            <Editable
              id="edit-account-admin-name"
              block
              placeholder="Name"
              value={user.get('full_name')}
              fieldName="full_name"
              responseFieldName="full_name"
              onSave={newName =>
                this.props.updateUser(user.get('id'), { full_name: newName })
              }
              response={response}
            />
          </div>
        </div>
        <div>
          <div className="form-label">
            <label htmlFor="edit-account-admin-email">
              {i18next.t('Email')}:
            </label>
          </div>
          <div className="form-item">
            <Editable
              id="edit-account-admin-email"
              block
              placeholder="Email"
              value={user.get('email')}
              fieldName="email"
              responseFieldName="email"
              onSave={newName =>
                this.props.updateUser(user.get('id'), { email: newName })
              }
              response={response}
            />
          </div>
        </div>
        <div>
          <div className="form-label">
            <label htmlFor="edit-account-admin-type">
              {i18next.t('Admin type')}:
            </label>
          </div>
          <div className="form-item">
            <Editable
              id="edit-account-admin-type"
              block
              type="choice"
              fieldName="role"
              responseFieldName="role"
              options={availableRoles.map(eachRole => ({
                value: eachRole,
                label: ACCOUNT_ADMIN_ROLE_DISPLAY[eachRole],
              }))}
              value={user.get('role')}
              formatDisplay={value => ACCOUNT_ADMIN_ROLE_DISPLAY[value]}
              onSave={newRole =>
                this.props.updateUser(user.get('id'), { role: newRole })
              }
              response={response}
            />
            <FormError formKey="role" response={response} />
          </div>
        </div>
        {isEnterpriseAccount && (
          <div>
            <div className="form-label">
              <label>{i18next.t('Licence holder')}:</label>
            </div>
            <div className="form-item licence-holder-checkbox">
              <input
                type="checkbox"
                checked={user.get('licence_holder')}
                onChange={e =>
                  this.props.updateUser(user.get('id'), {
                    licence_holder: e.target.checked,
                  })
                }
                disabled={userIsOnlyLicenceHolder || isPending(response)}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  updateUser,
  closeTopModal,
})(EditAccountAdminDetails);
