import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';
import classNames from 'classnames';

import PureComponent from './PureComponent';

export default class Icon extends PureComponent {
  getClassName() {
    const { type, inContent, hidden, disabled, className } = this.props;

    return classNames(
      ['icon', `icon-${type}`],
      {
        'in-content': inContent,
        hidden,
        disabled,
      },
      className
    );
  }

  render() {
    const { to, title } = this.props;

    return to ? (
      <Link
        {...this.props}
        title={title}
        className={this.getClassName()}
        to={to}
        type={null}
      >
        <span className="icon-inner" />
      </Link>
    ) : (
      <a
        {...this.props}
        title={title}
        className={this.getClassName()}
        type={null}
        data-testid="icon"
      >
        <span className="icon-inner" />
      </a>
    );
  }
}
