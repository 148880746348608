import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import {
  ACCOUNT_TYPE_DISPLAY,
  ACCOUNT_TYPE_UPGRADE_HIERARCHY,
  ACCOUNT_CREDIT_OPTIONS,
  accountTypeNeedsSuperUser,
  accountTypeAllowsUnlimitedCredits,
} from '^/models/organisation';
import FormError from '^/components/FormError';
import LiveButton from '^/components/LiveButton';
import RadioButton from '../RadioButton';

const AccountTypeOption = ({
  name,
  type,
  accountType,
  creditOptionType,
  setFieldValue,
}) => {
  const value = `${accountType}:${creditOptionType || ''}`;
  const extraText = creditOptionType
    ? ` - ${ACCOUNT_CREDIT_OPTIONS.DISPLAY[creditOptionType]}`
    : '';
  return (
    <RadioButton
      name={name}
      label={`${ACCOUNT_TYPE_DISPLAY[accountType]}${extraText}`}
      checked={type === value}
      id={`type-${accountType}-${creditOptionType || ''}`}
      onChange={() => setFieldValue(name, value)}
      value={value}
    />
  );
};

export const AddAccountForm = props => {
  const { response, onCancel, values, setFieldValue } = props;

  const accountType = values.type && values.type.split(':')[0];
  const needsSuperUser = accountTypeNeedsSuperUser(accountType);

  const isReadyToSubmit =
    values.type &&
    values.name &&
    (!needsSuperUser ||
      (values.accountSuperuserName && values.accountSuperuserEmail));

  return (
    <>
      <Form className="form-block">
        <div>
          <label>{i18next.t('Account type')}</label>
          <div className="form-item stacked">
            {ACCOUNT_TYPE_UPGRADE_HIERARCHY.map(eachAccountType =>
              accountTypeAllowsUnlimitedCredits(eachAccountType) ? (
                ACCOUNT_CREDIT_OPTIONS.ORDER.map((creditOptionType, idx) => (
                  <AccountTypeOption
                    name="type"
                    key={`${creditOptionType}-${idx}`}
                    type={values.type}
                    accountType={eachAccountType}
                    creditOptionType={creditOptionType}
                    setFieldValue={setFieldValue}
                  />
                ))
              ) : (
                <AccountTypeOption
                  key={eachAccountType}
                  name="type"
                  type={values.type}
                  accountType={eachAccountType}
                  setFieldValue={setFieldValue}
                />
              )
            )}
            <FormError response={response} formKey="type" />
          </div>
        </div>

        <div>
          <label htmlFor="account-name">{i18next.t('Account name')}</label>
          <div className="form-item">
            <Field
              name="name"
              type="text"
              id="account-name"
              placeholder={i18next.t('Add account name')}
            />
            <FormError response={response} formKey="name" />
          </div>
        </div>

        {needsSuperUser && (
          <div>
            <label htmlFor="primary-superuser-name">
              {i18next.t('Primary Superuser name')}
            </label>
            <div className="form-item">
              <Field
                name="accountSuperuserName"
                type="text"
                id="primary-superuser-name"
                placeholder={i18next.t('Name')}
              />
              <FormError response={response} formKey="full_name" />
            </div>
          </div>
        )}

        {needsSuperUser && (
          <div>
            <label htmlFor="primary-superuser-email">
              {i18next.t('Primary Superuser email')}
            </label>
            <div className="form-item">
              <Field
                name="accountSuperuserEmail"
                type="text"
                id="primary-superuser-email"
                placeholder={i18next.t('Email')}
              />
              <FormError response={response} formKey="email" />
            </div>
          </div>
        )}

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              type="button"
              onClick={onCancel}
            >
              {i18next.t('Cancel')}
            </button>

            <LiveButton
              pendingMessage={i18next.t('Creating account...')}
              buttonText={i18next.t('Create account')}
              disabled={!isReadyToSubmit}
              response={response}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

const AddAccountFormWrapped = props => {
  const { initialValues, onSubmit } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <AddAccountForm
          {...props}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
};

export default AddAccountFormWrapped;
