import i18next from 'i18next';
import React from 'react';

interface Props {
  count?: number;
  total?: number;
}

const PercentileWheel: React.FC<Props> = ({ count = 0, total = 0 }) => {
  const percentage = total === 0 ? 0 : Math.round((count / total) * 100);
  return (
    <div
      className="percentage-wheel"
      title={i18next.t<string>(
        '{{percentage}}%, {{count}}/{{total}} responses matched',
        {
          percentage,
          count,
          total,
        }
      )}
    >
      <svg className="circle" viewBox="0 0 120 120">
        <circle className="circle-background" cx="60" cy="60" r="54" />
        <g className="circle-text">
          <text dy="0.25em" x="50%" y="50%">
            {i18next.t<string>('{{percentage}}%', { percentage })}
          </text>
        </g>
        <circle
          className="circle-percentage"
          cx="60"
          cy="60"
          r="54"
          pathLength="100"
          style={
            {
              '--percentageLeft': 100 - percentage,
            } as React.CSSProperties
          }
        />
      </svg>
      <p className="matched-responses-text">
        {i18next.t<string>('{{count}}/{{total}} responses matched', {
          count,
          total,
        })}
      </p>
    </div>
  );
};

export default PercentileWheel;
