import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React from 'react';
import { range } from 'underscore';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { formatDateFilter, formatTime, padTens } from '^/utils';

const HOURS: string[] = range(0, 24).map(padTens);
const MINS: string[] = range(0, 60, 5).map(padTens);

interface OwnProps {
  value: string;
  onComplete: (date: string) => void;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface State {
  time: Moment;
}

export type Props = OwnProps & DispatchProps;

export class SetTimeModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { value } = this.props;

    this.state = {
      time: moment(`${formatDateFilter(moment())} ${value}`),
    };
  }

  public render() {
    const { time } = this.state;
    return (
      <div>
        <div className="row form-horizontal time-selects">
          <div className="col-xs-6">
            <select value={time.get('hour')} onChange={this.setHours}>
              {HOURS.map(hour => (
                <option key={hour} value={+hour}>
                  {hour}
                </option>
              ))}
            </select>
          </div>
          <div className="col-xs-6">
            <select
              // round to nearest 5 minute interval
              value={time.get('minutes')}
              onChange={this.setMinutes}
            >
              {MINS.map(minute => (
                <option key={minute} value={+minute}>
                  {minute}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>
            <button className="btn btn-primary" onClick={this.onComplete}>
              {i18next.t<string>('Select')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  private setHours = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      time: this.state.time.clone().hours(+event.target.value),
    });
  };

  private setMinutes = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      time: this.state.time.clone().minutes(+event.target.value),
    });
  };

  private onComplete = () => {
    this.props.closeTopModal();
    return this.props.onComplete(formatTime(this.state.time));
  };
}

export default connect(null, { closeTopModal })(SetTimeModal);
