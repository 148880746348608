import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import classNames from 'classnames';
import { Formik, Form, Field } from 'formik';

import PureComponent from './PureComponent';
import FormError from './FormError';
import LiveButton from './LiveButton';
import Alert, { AlertType } from '^/components/Alert';

export class LoginForm extends PureComponent {
  render() {
    const {
      userPulse,
      onSubmit,
      forgotPassword,
      logoutResponse,
      response,
    } = this.props;

    return (
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form
          className={classNames('form-inline mb-none', userPulse && 'pulse')}
        >
          {logoutResponse && logoutResponse.detail && (
            <Alert type={AlertType.Error} className="mb-none">
              {logoutResponse.detail}
            </Alert>
          )}

          <div>
            <label htmlFor="email">{i18next.t('Email')}</label>
            <div className="form-item">
              <Field
                type="text"
                name="email"
                id="email"
                placeholder={i18next.t('Email')}
              />
              <FormError response={response} formKey="email" />
            </div>
          </div>

          <div>
            <label htmlFor="password">{i18next.t('Password')}</label>
            <div className="form-item">
              <Field
                type="password"
                name="password"
                id="password"
                placeholder={i18next.t('Password')}
              />
              <FormError response={response} formKey="password" />
            </div>
          </div>

          <div className="form-item">
            <LiveButton
              buttonText={i18next.t('Log in')}
              pendingMessage={i18next.t('Logging in...')}
              response={response}
              additionalButtonClasses="col-xs-12 mr"
              getAdditionalErrors={_response =>
                _response.getIn(['errors', 'msg'], [])
              }
              failedClassName="full-width"
            />
          </div>

          <div className="mt-none">
            <button
              className="btn btn-full-width btn-default"
              onClick={forgotPassword}
            >
              {i18next.t('Forgotten your password?')}
            </button>
          </div>
        </Form>
      </Formik>
    );
  }
}

export default LoginForm;
