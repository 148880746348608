import React from 'react';
import { Trans } from 'react-i18next';

import i18next from '^/locale';
import { RouterParamProps } from '^/withRouter';

export const TermsAndConditions = (
  props: RouterParamProps<{ langCode: string }>
) => (
  <div className="main">
    <div className="container">
      <div className="row small">
        <div className="col-xs-12 content-box">
          <h1>{i18next.t<string>('TERMS OF USE')}</h1>
          <p>{i18next.t<string>('PARAGRAPH TERMS INTRO 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH TERMS INTRO 2')}</p>
          <p>
            <Trans i18nKey="TRANS TERMS INTRO 3">
              Your use of the Service is subject to all terms and policies
              posted on this site or the legal information section of the
              application (including the Privacy Statement; collectively
              referred to as the “Terms of Use”). If you have any questions
              concerning the Terms of Use, please contact
              <a href="mailto:letushelp@peoplewise.co.uk">
                letushelp@peoplewise.co.uk
              </a>
            </Trans>
          </p>
          <p>{i18next.t<string>('PARAGRAPH TERMS INTRO 4')}</p>
          <p>{i18next.t<string>('PARAGRAPH TERMS INTRO 5')}</p>

          <ol className="multi-level-ol">
            <li className="multi-level-li">
              <h2>{i18next.t<string>('Changes in Terms of Use')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 1-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 1-2')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>
                {i18next.t<string>(
                  'Reliance on Information Posted and Disclaimer'
                )}
              </h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 2-1')}
                </li>
                <li className="multi-level-li">
                  <Trans i18nKey="TRANS TERMS 2-2">
                    The information given in this site is for general
                    information only. If you need advice on a specific matter,
                    please contact
                    <a href="mailto:letushelp@peoplewise.co.uk">
                      letushelp@peoplewise.co.uk
                    </a>
                  </Trans>
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Copyright and Trademark Notices')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 3-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 3-2')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 3-3')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Our Liability')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 4-1')}
                  <ul>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-1')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-2')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-3')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-4')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-5')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-6')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-7')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-8')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-9')}</li>
                    <li>{i18next.t<string>('PARAGRAPH TERMS 4-1-10')}</li>
                  </ul>
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 4-2')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Warranties')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 5-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 5-2')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 5-3')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Indemnification')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 6-1')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Links from our Site')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 7-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 7-2')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 7-3')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>
                {i18next.t<string>(
                  'Information about You and Your Visits to Our Site'
                )}
              </h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  <Trans i18nKey="TRANS TERMS 8-1">
                    We process information about you in accordance with our
                    <a
                      href={`/#/${props.router.params.langCode}/data-privacy-policy`}
                    >
                      Data Privacy Policy.
                    </a>
                    By using our site, you consent to such processing and you
                    warrant that all data provided by you is accurate.
                  </Trans>
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>
                {i18next.t<string>('Viruses, Hacking and Other Offences')}
              </h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 9-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 9-2')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 9-3')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 9-4')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 9-5')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Jurisdiction and Applicable Law')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 10-1')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>
                {i18next.t<string>(
                  'Booking Public Training and Accreditation Courses'
                )}
              </h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  <h3 className="h3-tertiary">
                    {i18next.t<string>(
                      'Public Training and Accreditation Courses and Prices:'
                    )}
                  </h3>
                  <ol className="multi-level-ol">
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-1-1')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-1-2')}
                    </li>
                  </ol>
                </li>
                <li className="multi-level-li">
                  <h3 className="h3-tertiary">
                    {i18next.t<string>('Cancellation Policy:')}
                  </h3>
                  <ol className="multi-level-ol">
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-2-1')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-2-2')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-2-3')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-2-4')}
                    </li>
                  </ol>
                </li>
                <li className="multi-level-li">
                  <h3 className="h3-tertiary">
                    {i18next.t<string>(
                      'Access to learning portal and resources:'
                    )}
                  </h3>
                  <ol className="multi-level-ol">
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-3-1')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-3-2')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-3-3')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-3-4')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-3-5')}
                    </li>
                  </ol>
                </li>
                <li className="multi-level-li">
                  <h3 className="h3-tertiary">
                    {i18next.t<string>('Qualification of Participants:')}
                  </h3>
                  <ol className="multi-level-ol">
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-4-1')}
                    </li>
                    <li className="multi-level-li">
                      {i18next.t<string>('PARAGRAPH TERMS 11-4-2')}
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>{i18next.t<string>('Enable platform credits policy')}</h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 12-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 12-2')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 12-3')}
                </li>
              </ol>
            </li>

            <li className="multi-level-li">
              <h2>
                {i18next.t<string>(
                  'Enable platform product + feedback purchase policy'
                )}
              </h2>
              <ol className="multi-level-ol">
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-1')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-2')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-3')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-4')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-5')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-6')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-7')}
                </li>
                <li className="multi-level-li">
                  {i18next.t<string>('PARAGRAPH TERMS 13-8')}
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
);
