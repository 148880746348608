import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import { List } from 'immutable';
import React from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { connect } from 'react-redux';

import {
  filterHiddenReportsTableSelection,
  toggleReportsTableSelection,
  toggleSelectAllReportsTableSelection,
  loadTeamSessionsPageThen,
} from '^/actions/actions';
import { can, downloadExistingReports } from '^/capabilities';
import { loadOrganisation } from '^/actions/items';
import { openCreateTeamReportModal } from '^/actions/modals';
import Table from '^/components/Table';
import {
  OrgSessions,
  OrgUser,
  TeamReportsState,
  Uuid,
} from '^/reducers/api/types';
import {
  asKeys,
  Selection,
  available,
  buildTeamSelection,
  isSelected,
  notHidden,
  ReportsTableSelections,
} from '^/reducers/reports/types';
import { selectUserProfile } from '^/selectors';
import { StoreState } from '^/store';
import SelectableReport from './SelectableReport';
import {
  selectAccountHasUnlimitedCredits,
  selectAllSelectedReportsTable,
  selectAnySelectedReportsTable,
  selectFilteredOrgUsers,
  selectHasAnyReportsTable,
  selectReportsTableSelections,
} from './selectors';
import { selectMappedSelectedProducts } from './AdminReportsPage/selectors';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import MenuButton, { MenuButtonOption } from '^/components/MenuButton';
import { LanguageCode } from '^/constants/routes';
import { ProductOrganisation } from '^/reducers/api/types/organisation';

type OwnProps = {
  teamReports: TeamReportsState;
  orgSessions: OrgSessions | null;
  canUserAdministerOwnOrganisation: boolean;
  orgId: Uuid | null;
};

interface DispatchProps {
  toggleReportsTableSelection: typeof toggleReportsTableSelection;
  toggleSelectAllReportsTableSelection: typeof toggleSelectAllReportsTableSelection;
  filterHiddenReportsTableSelection: typeof filterHiddenReportsTableSelection;
  openCreateTeamReportModal: typeof openCreateTeamReportModal;
  loadTeamSessionsPageThen: typeof loadTeamSessionsPageThen;
  loadOrganisation: typeof loadOrganisation;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  users: ReadonlyArray<OrgUser>;
  accountHasUnlimitedCredits: boolean;
  reportsTableSelections: ReportsTableSelections;
  allSelected: { [key in keyof ReportsTableSelections]: boolean };
  anySelected: { [key in keyof ReportsTableSelections]: boolean };
  hasAny: { [key in keyof ReportsTableSelections]: boolean };
  productsItems: ReadonlyArray<DropdownOption>;
  organisation: Immutable.Map<string, any>;
}

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  search: string;
  noAvailableSessions: boolean;
  reportTemplateOptions: Array<DropdownOption>;
}

export function getReportTemplateNameforProductItems(
  productItems: ReadonlyArray<DropdownOption>,
  productOrganisationSet: Array<ProductOrganisation>
): Array<DropdownOption> {
  return productItems.map(item => {
    const productOrg = productOrganisationSet.find(
      (prodOrg: ProductOrganisation) => prodOrg.product === item.id
    );

    if (!productOrg) return item;

    const productOrganisationReportTemplateSet = productOrg.productorganisationreporttemplate_set.filter(
      reportTemplateSet => reportTemplateSet.report_template.is_team
    );

    const englishName = productOrganisationReportTemplateSet.find(
      productOrgReportTemplate => {
        return (
          productOrgReportTemplate.report_template.lang_code ===
          LanguageCode.EN_GB
        );
      }
    )?.report_template.name;

    const fallbackName =
      productOrganisationReportTemplateSet[0]?.report_template.name;

    return {
      ...item,
      name: englishName || fallbackName || item.name,
    };
  });
}

export class TeamReportsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const state = {
      search: '',
      noAvailableSessions: false,
      reportTemplateOptions: [],
    };

    if (props.canUserAdministerOwnOrganisation) {
      state.noAvailableSessions = true;
      this.props.loadTeamSessionsPageThen(props.orgId, (action: any) => {
        if (action?.payload?.results?.length) {
          this.setState({ noAvailableSessions: false });
        }
      });
    }

    this.state = state;
  }

  fetchDataForCurrentlySelectedOrganisation() {
    this.props.loadOrganisation(this.props.orgId);
  }

  setReportTemplateOptions() {
    const productOrganisationSet = this.props.organisation
      ?.get('productorganisation_set')
      ?.toJS() as Array<ProductOrganisation>;

    if (productOrganisationSet) {
      this.setState({
        reportTemplateOptions: getReportTemplateNameforProductItems(
          this.props.productsItems,
          productOrganisationSet
        ),
      });
    }
  }

  UNSAFE_componentWillMount(): void {
    if (this.props.organisation) {
      this.setReportTemplateOptions();
    }
    this.fetchDataForCurrentlySelectedOrganisation();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.orgId !== this.props.orgId) {
      this.fetchDataForCurrentlySelectedOrganisation();
    } else if (prevProps.organisation !== this.props.organisation) {
      this.setReportTemplateOptions();
    }
  }

  renderCreateReportButton() {
    const { productsItems, orgId } = this.props;
    const disabled = !orgId || this.state.noAvailableSessions || !productsItems;
    const btn = (
      <>
        <FontAwesomeIcon icon={faPlus} />
        {i18next.t<string>('Create report')}
      </>
    );

    if (productsItems.length === 1) {
      return (
        <button
          className="btn btn-secondary"
          onClick={() =>
            this.openCreateTeamReportModal(
              productsItems[0].id?.toString() || ''
            )
          }
        >
          {btn}
        </button>
      );
    }
    return (
      <MenuButton secondary label={btn} disabled={disabled} position="right">
        {this.state.reportTemplateOptions.map((item, idx) => {
          return (
            <MenuButtonOption
              key={idx}
              onSelect={() =>
                this.openCreateTeamReportModal(item.id?.toString() || '')
              }
              name={item.name}
            />
          );
        })}
      </MenuButton>
    );
  }

  public render() {
    const { search } = this.state;
    const teamReports = this.props.teamReports.filter(
      each => !search || each.name.toLowerCase().includes(search.toLowerCase())
    );
    return (
      <div className="team-reports-table">
        <p className="mb-md mt-sm">
          {this.getReportCountMessage(teamReports.length)}
        </p>
        <div className="reports-box-wrapper">
          <div className="reports-box-list">
            <div className="reports-box-respondents-list">
              <div
                className="reports-box-reports-list"
                style={{ width: '100%' }}
              >
                <Table
                  className="respondents-list-table"
                  onSearchChange={this.onSearchChange}
                  columns={[
                    {
                      header: i18next.t<string>('Team'),
                      searchable: true,
                      largeSearchBox: true,
                      searchPlaceholder: i18next.t<string>(
                        'Search for team...'
                      ),
                      value: reportTeam => reportTeam.name,
                    },
                    {
                      header: (
                        <div className="reports-box-controls">
                          <div className="reports-header">
                            {i18next.t<string>('Reports')}
                          </div>
                          <div className="table-controls-group">
                            <div className="table-controls" />
                            {this.renderCreateReportButton()}
                            {this.renderSelectAllButton()}
                          </div>
                        </div>
                      ),
                      value: reportTeam =>
                        this.renderSelectableReport(
                          buildTeamSelection(reportTeam)
                        ),
                    },
                  ]}
                  rows={List(teamReports)}
                  emptyMessage={i18next.t<string>(
                    'There are no Team Reports created yet for this account.'
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderSelectAllButton() {
    const { user, hasAny } = this.props;
    const allSelected = this.props.allSelected.reports;

    return (
      can(user, downloadExistingReports()) && (
        <div className="table-controls">
          <span>{`${i18next.t<string>('Select all')}:`}</span>
          <button
            className={classNames(
              'btn btn-icon-square',
              allSelected
                ? 'btn-icon-square-success'
                : 'btn-icon-square-success-secondary'
            )}
            title={i18next.t<string>(
              'REPORT DISTRIBUTION. These are reports you can download and/or send.'
            )}
            type="button"
            disabled={!hasAny.reports}
            onClick={this.toggleSelectAll}
            data-testid="select-all-team-reports"
          >
            <FontAwesomeIcon icon={faFileDownload} />
          </button>
        </div>
      )
    );
  }

  private toggleSelectAll = () =>
    this.props.toggleSelectAllReportsTableSelection('reports');

  private onSearchChange = (value?: string) => {
    this.setState({ search: value || '' });
  };

  private getReportCountMessage(teamReportsCount: number) {
    if (teamReportsCount === 0) {
      return i18next.t<string>('No reports');
    }
    return i18next.t<string>('Showing {{count}} report', {
      count: teamReportsCount,
    });
  }

  private getSelectionState(selection: Selection) {
    const [key, innerKey] = asKeys(selection);
    return this.props.reportsTableSelections[key][innerKey];
  }

  private renderSelectableReport(selection: Selection) {
    const { user } = this.props;
    const userCanDownloadExistingReports = can(user, downloadExistingReports());
    const selectionState = this.getSelectionState(selection);
    const hasVariant = selection.productVariantId !== null;

    if (notHidden(selectionState)) {
      return (
        <SelectableReport
          created={selection.report?.created}
          key={selection.reportTemplate.id}
          label={selection.reportTemplate.code}
          status={selection.report && selection.report.pdf_status}
          disabled={
            userCanDownloadExistingReports &&
            this.props.anySelected[
              selection.report ? 'reportTemplates' : 'reports'
            ]
          }
          isUnavailable={
            !available(selectionState) ||
            (!userCanDownloadExistingReports && hasVariant)
          }
          hasVariant={hasVariant}
          downloadBlocked={!userCanDownloadExistingReports}
          isSelected={isSelected(selectionState)}
          onSelect={this.props.toggleReportsTableSelection.bind(
            null,
            selection
          )}
          reportName={selection.reportTemplate.name}
        />
      );
    }
  }

  private openCreateTeamReportModal = (reportProductId: string) => {
    const { orgId, orgSessions } = this.props;
    if (orgSessions) {
      this.props.openCreateTeamReportModal(reportProductId, orgId || '');
    }
  };
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    users: selectFilteredOrgUsers(state),
    accountHasUnlimitedCredits: selectAccountHasUnlimitedCredits(state),
    reportsTableSelections: selectReportsTableSelections(state),
    allSelected: selectAllSelectedReportsTable(state),
    anySelected: selectAnySelectedReportsTable(state),
    hasAny: selectHasAnyReportsTable(state),
    productsItems: selectMappedSelectedProducts(state),
    organisation: state.items.get('organisations'),
  };
}

export default connect(mapStateToProps, {
  toggleReportsTableSelection,
  toggleSelectAllReportsTableSelection,
  filterHiddenReportsTableSelection,
  openCreateTeamReportModal,
  loadTeamSessionsPageThen,
  loadOrganisation,
})(TeamReportsTable);
