import { Map } from 'immutable';
import _ from 'underscore';

export const PENDING = 'PENDING';
export const SUCCESSFUL = 'SUCCESSFUL';
export const FAILED = 'FAILED';

export function isPending(
  response?: Map<string, any>
): response is Map<string, any> {
  return Boolean(response && response.get('state') === PENDING);
}

export function hasFailed(
  response?: Map<string, any>
): response is Map<string, any> {
  return Boolean(response && response.get('state') === FAILED);
}

export function hasSucceeded(
  response?: Map<string, any>
): response is Map<string, any> {
  return Boolean(response && response.get('state') === SUCCESSFUL);
}

export function anyPending(responses: ReadonlyArray<Map<string, any>>) {
  return _.any(responses, isPending);
}

export function getFormError(response: Map<string, any>, field: string) {
  return response && response.getIn(['errors', field]);
}

export function isFieldUpdating(response: Map<string, any>, field: string) {
  return Boolean(response && (response.get('data') || Map()).has(field));
}

export function isFieldPending(response: Map<string, any>, field: string) {
  return isFieldUpdating(response, field) && isPending(response);
}

export function hasFieldFailed(response: Map<string, any>, field: string) {
  return isFieldUpdating(response, field) && hasFailed(response);
}
