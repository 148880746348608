import React from 'react';

import { PlaceholderProps } from './types';
import { isDisplayingPlaceholder } from './utils';

const Placeholder = (props: PlaceholderProps) => {
  const value = props.formatDisplay
    ? props.formatDisplay(props.value)
    : props.value;

  return (
    <div className="editable-content">
      {isDisplayingPlaceholder(value) ? props.placeholder : value}
    </div>
  );
};

export { Placeholder };

export default Placeholder;
