/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  name: string | null;
  disabled?: boolean;
  faIcon?: any;
  icon?: any;
  onSelect?: () => void;
  onClick?: () => void;
  className?: string;
}

export class InlineMenuOption extends React.Component<Props> {
  public render() {
    const {
      name,
      icon,
      faIcon,
      onClick,
      onSelect,
      disabled,
      className,
    } = this.props;
    return (
      <div
        className={classNames('inline-menu-option', { disabled })}
        onClick={
          disabled
            ? undefined
            : () => {
                onClick && onClick();
                onSelect && onSelect();
              }
        }
      >
        <span className={classNames('inline-menu-option-name', className)}>
          {name}
        </span>

        {faIcon && (
          <span className={classNames('inline-menu-option-icon', className)}>
            <FontAwesomeIcon icon={faIcon} fixedWidth />
          </span>
        )}
        {icon && <span className="inline-menu-option-icon">{icon}</span>}
      </div>
    );
  }
}

interface State {
  isOpen: boolean;
}

export default class InlineMenu extends React.Component<
  PropsWithChildren,
  State
> {
  public menu: HTMLDivElement | null = null;
  public readonly state = {
    isOpen: false,
  };
  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  private setInlineMenuRef = (node: HTMLDivElement) => (this.menu = node);

  private handleClick = (_event: MouseEvent) => {
    if (event?.target && this.menu?.contains(event.target as HTMLDivElement)) {
      return;
    }
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  public render() {
    const { isOpen } = this.state;
    const { children } = this.props;
    return (
      <div
        ref={this.setInlineMenuRef}
        className={classNames('inline-menu', { open: isOpen })}
      >
        {isOpen ? (
          <>
            <div onClick={this.close} role="button">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <div className="inline-menu-options">
              {React.Children.map(children, child =>
                React.cloneElement(child as React.ReactElement, {
                  onClick: this.close,
                })
              )}
            </div>
          </>
        ) : (
          <div onClick={this.open} role="button">
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        )}
      </div>
    );
  }

  private open = () => {
    this.setState({ isOpen: true });
  };

  private close = () => {
    this.setState({ isOpen: false });
  };
}
