import React from 'react';
import { connect } from 'react-redux';

import {
  LanguageCode,
  TRANSLATED_LANGUAGES,
  TRANSLATED_LANGUAGE_ADMIN_MAP,
} from '^/constants/routes';
import { setInputTextLanguage } from '^/actions/ui';
import { StoreState } from '^/store';
import ToggleSwitch from './ToggleSwitch';
import { isPending } from '^/responseStates';

interface OwnProps {
  languages?: ReadonlyArray<LanguageCode>;
  onChange?: (lang: LanguageCode) => void;
}

interface StateProps {
  inputTextLanguage: LanguageCode;
  disabled: boolean;
}

interface DispatchProps {
  setInputTextLanguage: typeof setInputTextLanguage;
}

type Props = OwnProps & StateProps & DispatchProps;

export class InputLanguageSelector extends React.PureComponent<Props> {
  render() {
    const { languages, inputTextLanguage, onChange, disabled } = this.props;
    const shownLanguages = languages
      ? TRANSLATED_LANGUAGES.filter(lang => languages.includes(lang))
      : TRANSLATED_LANGUAGES;

    const items = shownLanguages.map(lang => ({
      id: lang,
      name: TRANSLATED_LANGUAGE_ADMIN_MAP[lang],
      selected: inputTextLanguage === lang,
    }));

    !shownLanguages.includes(inputTextLanguage) &&
      this.props.setInputTextLanguage(shownLanguages[0]);

    if (items.length > 1) {
      return (
        <ToggleSwitch
          items={items}
          onClick={(lang: LanguageCode) => {
            this.props.setInputTextLanguage(lang);
            onChange && onChange(lang);
          }}
          disabled={disabled}
        />
      );
    }
    return <></>;
  }
}

function mapStateToProps(state: StoreState) {
  return {
    inputTextLanguage: state.ui.get('inputTextLanguage'),
    disabled: isPending(
      state.responses.get('setNotificationEmailLanguagePreference')
    ),
  };
}

export default connect(mapStateToProps, { setInputTextLanguage })(
  InputLanguageSelector
);
