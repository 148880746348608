import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import {
  createNotificationEmail,
  updateNotificationEmail,
  setNotificationEmailLanguagePreference,
} from '^/actions/actions';
import NotificationEmailEditForm from '^/components/activities/notifications/NotificationEmailEditForm';

export class NotificationEmailEditModal extends PureComponent {
  updateEmail(data, notificationId) {
    this.props.updateNotificationEmail(
      this.props.activityId,
      notificationId,
      data
    );
  }

  createEmail(data) {
    this.props.createNotificationEmail(this.props.activityId, data);
  }

  setPreferredLang(lang) {
    const { activityId, notification } = this.props;
    this.props.setNotificationEmailLanguagePreference(
      activityId,
      notification.get('type'),
      lang
    );
  }

  render() {
    return (
      <NotificationEmailEditForm
        notification={this.props.notification.toJS()}
        updateEmail={this.updateEmail.bind(this)}
        createEmail={this.createEmail.bind(this)}
        setPreferredLang={this.setPreferredLang.bind(this)}
      />
    );
  }
}

export default connect(null, {
  updateNotificationEmail,
  createNotificationEmail,
  setNotificationEmailLanguagePreference,
})(NotificationEmailEditModal);
