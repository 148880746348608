import {
  faCalendarAlt,
  faSpinnerThird,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, FunctionComponent } from 'react';

import { Complete } from '^/types';
import { Props as DatePickerProps } from './types';

interface Props
  extends Complete<
    Pick<
      DatePickerProps,
      'isLoading' | 'hideIcon' | 'disabled' | 'clearable' | 'value'
    >
  > {
  onClearClick: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const DatePickerIcon: FunctionComponent<Props> = ({
  isLoading,
  hideIcon,
  disabled,
  clearable,
  value,
  onClearClick,
}) => {
  if (hideIcon || disabled) {
    return null;
  }

  if (isLoading) {
    return (
      <a className="editable-icon-link">
        <FontAwesomeIcon icon={faSpinnerThird} spin={isLoading} />
      </a>
    );
  }

  if (clearable && value) {
    return (
      <a className="editable-icon-link" onClick={onClearClick}>
        <FontAwesomeIcon icon={faTimes} />
      </a>
    );
  }

  return (
    <a className="editable-icon-link">
      <FontAwesomeIcon icon={faCalendarAlt} />
    </a>
  );
};

export default DatePickerIcon;
