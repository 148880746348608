import classNames from 'classnames';

export type ButtonType = 'primary' | 'success' | 'warning' | 'danger';

export function getButtonClassName(
  type: ButtonType = 'primary',
  secondary?: boolean,
  small?: boolean
) {
  return classNames('btn', {
    'btn-primary': type === 'primary' && !secondary,
    'btn-default': type === 'primary' && secondary,
    'btn-success': type === 'success' && !secondary,
    'btn-success-secondary': type === 'success' && secondary,
    'btn-warning': type === 'warning' && !secondary,
    'btn-warning-secondary': type === 'warning' && secondary,
    'btn-danger': type === 'danger' && !secondary,
    'btn-danger-secondary': type === 'danger' && secondary,
    'btn-small': small,
  });
}
