import React from 'react'; // eslint-disable-line no-unused-vars

export const IndeterminateProgressBar = () => (
  <div className="progress-indeterminate-bar">
    <div className="bar" />
    <div className="sub-bar inc" />
    <div className="sub-bar dec" />
  </div>
);

export default IndeterminateProgressBar;
