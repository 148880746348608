export function capitalize(text) {
  let textToCapitalize;

  if (typeof text === 'object' || typeof text === 'undefined') {
    return '';
  }

  if (typeof text !== 'string') {
    textToCapitalize = text.toString();
  }

  textToCapitalize = text.toString() || '';

  return textToCapitalize
    .charAt(0)
    .toUpperCase()
    .concat(textToCapitalize.substring(1).toLowerCase());
}
