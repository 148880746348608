import classNames from 'classnames';
import i18next from 'i18next';
import { fromJS } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { loadOrgUsers, loadOrgFilters } from '^/actions/actions';
import { addRemoveUsersGroupAndRefresh } from '^/actions/actionSequences';
import { can, administerOwnOrganisation } from '^/capabilities';
import { OrgFilters, OrgUser, OrgUsers, Uuid } from '^/reducers/api/types';
import { isPending } from '^/responseStates';
import { StoreState } from '^/store';
import ListingTable from '^/components/ListingTable';
import ListingTableFilters from '^/components/ListingTableFilters';
import ProfilePicture from '^/components/profile/ProfilePicture';
import { without, difference, union, compare } from '^/utils-ts';
import Loading from '../Loading';

interface ListingTableState {
  ordering?: {
    field: string;
    reversed: boolean;
  };
  search?: string;
  filters: { [key: string]: string[] };
}

interface State {
  selectedUserIds: Set<Uuid>;
  listingTableState: ListingTableState;
  filteredUsers: OrgUser[];
}

interface OwnProps {
  orgId: Uuid;
  groupId: Uuid;
  selectedUserIds: Set<Uuid>;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  orgUsers: OrgUsers | null;
  orgFilters: OrgFilters | null;
  response: Immutable.Map<string, any> | undefined;
  groupLoading: boolean;
  usersAdding: boolean;
  canUserAdministerOwnOrganisation: boolean;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
  loadOrgUsers: typeof loadOrgUsers;
  loadOrgFilters: typeof loadOrgFilters;
  addRemoveUsersGroupAndRefresh: typeof addRemoveUsersGroupAndRefresh;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class AddRemoveUserModal extends React.Component<Props, State> {
  public readonly state = {
    selectedUserIds: new Set<Uuid>(),
    listingTableState: {
      filters: {},
      ordering: undefined,
      search: undefined,
    },
    filteredUsers: [] as OrgUser[],
  } as State;

  public componentDidMount() {
    const { orgId } = this.props;
    this.props.loadOrgFilters(orgId);
    this.setState({ selectedUserIds: this.props.selectedUserIds });
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.orgUsers !== this.props.orgUsers) {
      const { orgUsers } = this.props;
      if (orgUsers) {
        this.setState({
          filteredUsers: this.filterUsers(
            orgUsers.users,
            this.state.listingTableState.filters
          ),
        });
      }
    }
  }

  private filterUsers = (
    users: OrgUsers['users'],
    filters: { [key: string]: string[] }
  ) => {
    return users.filter(user => {
      const activityIds = user.activity_users.map(
        activity => activity.activity
      );
      const groupIds = user.usergroup_set.map(group => group);
      return (
        (!filters.activity ||
          filters.activity.length === 0 ||
          activityIds.includes(filters.activity[0])) &&
        (!filters.group ||
          filters.group.length === 0 ||
          groupIds.includes(filters.group[0]))
      );
    });
  };

  private onListingTableStateChange = (
    newState: Partial<ListingTableState>
  ) => {
    const listingTableState = {
      ...this.state.listingTableState,
      ...newState,
    };

    this.setState({ listingTableState });

    const { orgUsers } = this.props;
    if (orgUsers) {
      const { ordering, search, filters } = listingTableState;

      let filteredUsers = this.filterUsers(orgUsers.users, filters);

      if (ordering) {
        const { field, reversed } = (ordering as any) as {
          field: string;
          reversed: boolean;
        };
        filteredUsers.sort((a, b) => {
          switch (field) {
            case 'full_name':
              return compare(a.full_name, b.full_name);
          }
          return 0;
        });
        if (reversed) filteredUsers.reverse();
      }

      if (search) {
        filteredUsers = filteredUsers.filter(user =>
          user.full_name
            .toLowerCase()
            .includes(((search as any) as string).toLowerCase())
        );
      }

      this.setState({
        // TODO: Check if this is needed  - do we want to remove users that are no longer in the filtered list?
        // selectedUserIds: intersection(
        //   this.state.selectedUserIds,
        //   new Set<Uuid>(filteredUsers.map(each => each.id))
        // ),
        filteredUsers,
      });
    }
  };

  addRemoveUsers = () => {
    const { groupId } = this.props;
    const { selectedUserIds } = this.state;
    this.props.addRemoveUsersGroupAndRefresh(groupId, selectedUserIds);
  };

  public render() {
    const { orgFilters, orgId, groupLoading } = this.props;

    const { selectedUserIds, filteredUsers } = this.state;
    const numSessionsSelected = selectedUserIds.size;

    if (groupLoading) return <Loading />;

    return (
      <div>
        <div>
          <ListingTableFilters
            filters={[
              {
                key: 'activity',
                name: 'Activity',
                values: orgFilters?.activities,
              },
              {
                key: 'group',
                name: 'Group',
                values: orgFilters?.groups,
              },
            ]}
            state={this.state.listingTableState}
            onStateChange={this.onListingTableStateChange}
          />

          <p>
            {i18next.t<string>('{{count}} users', {
              count: filteredUsers?.length || 0,
            })}
          </p>

          <ListingTable
            onLoad={() => this.props.loadOrgUsers(orgId)}
            state={this.state.listingTableState}
            onStateChange={this.onListingTableStateChange}
            onClickRow={user =>
              this.setState({
                selectedUserIds: selectedUserIds.has(user.id)
                  ? without(selectedUserIds, user.id)
                  : selectedUserIds.add(user.id),
              })
            }
            rowClassName={user =>
              classNames('user-row selectable', {
                selected: selectedUserIds.has(user.id),
              })
            }
            columns={[
              {
                header: (
                  <input
                    type="checkbox"
                    className="ml-sm mt-none"
                    checked={filteredUsers?.every(user =>
                      selectedUserIds.has(user.id)
                    )}
                    onChange={() =>
                      this.setState({
                        selectedUserIds: filteredUsers?.every(user =>
                          selectedUserIds.has(user.id)
                        )
                          ? difference(
                              selectedUserIds,
                              new Set<Uuid>(filteredUsers.map(user => user.id))
                            )
                          : union(
                              selectedUserIds,
                              new Set<Uuid>(filteredUsers.map(user => user.id))
                            ),
                      })
                    }
                  />
                ),
                value: user => (
                  <input
                    type="checkbox"
                    checked={selectedUserIds.has(user.id)}
                  />
                ),
              },
              {
                header: i18next.t<string>('Name'),
                value: user => (
                  <div className="user-profile">
                    <ProfilePicture
                      width={24}
                      height={24}
                      user={fromJS(user)}
                    />
                    {user.full_name}
                  </div>
                ),
                sortKey: 'full_name',
                searchKey: 'full_name',
              },
              {
                header: i18next.t<string>('Email'),
                value: user => user.email,
              },
            ]}
            rows={filteredUsers}
          />
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>

            <button
              className="btn btn-primary"
              onClick={this.addRemoveUsers}
              disabled={
                !numSessionsSelected ||
                this.props.usersAdding ||
                this.props.groupLoading
              }
            >
              {i18next.t<string>('Save')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userProfile,
    orgUsers: state.orgUsers,
    orgFilters: state.orgFilters,
    response: state.responses.get('addRemoveUsersGroup'),
    groupLoading: isPending(state.responses.get('loadItem')),
    usersAdding: isPending(state.responses.get('addRemoveUsersGroup')),
    canUserAdministerOwnOrganisation: can(
      state.userProfile,
      administerOwnOrganisation()
    ),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  loadOrgUsers,
  loadOrgFilters,
  addRemoveUsersGroupAndRefresh,
})(AddRemoveUserModal);
