import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import PulseIconCircleWhite from '^/components/PulseIconCircleWhite';
import PulseIconCircle from '^/components/PulseIconCircle';
import { UserPulseDetail, Uuid } from '^/reducers/api/types';
import { isPending } from '^/responseStates';
import { StoreState } from '^/store';
import SelectPulseRatersList from './SelectPulseRatersList';
import { SelectedRater } from './types';

interface OwnProps {
  backToSetBehaviours: () => void;
  storeSelected: (emails: ReadonlyArray<SelectedRater> | undefined) => void;
  setBehavioursAndRaters: (
    behaviours: ReadonlyArray<{ id: Uuid }> | undefined
  ) => void;
  selectedRaters: ReadonlyArray<SelectedRater> | undefined;
  userPulseDetail: UserPulseDetail | null;
}

interface StateProps {
  responsesPending: boolean;
}

interface State {
  selectedRaters: ReadonlyArray<SelectedRater> | undefined;
}

export type Props = OwnProps & StateProps;

export class SelectPulseRaters extends React.PureComponent<Props, State> {
  emailAddress: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.emailAddress = React.createRef();
    this.state = {
      selectedRaters: this.props.selectedRaters,
    };
  }

  public componentDidUpdate() {
    this.storeRaters();
  }

  private storeRaters = () => {
    this.props.storeSelected(this.state.selectedRaters);
  };

  private storeSelectedAndUpdateState = (
    selectedRaters?: ReadonlyArray<SelectedRater>
  ) => {
    this.props.storeSelected(selectedRaters);
    if (selectedRaters) {
      this.setState({ selectedRaters: selectedRaters });
    }
  };

  public render() {
    const {
      backToSetBehaviours,
      setBehavioursAndRaters,
      userPulseDetail,
      responsesPending,
    } = this.props;
    const { selectedRaters } = this.state;
    return (
      <div>
        <div className="navigation-button">
          <a onClick={() => backToSetBehaviours()}>
            &lt; {i18next.t<string>('back')}
          </a>
        </div>
        <div className="pulse-central-column">
          <div className="pulse-icon">
            <PulseIconCircle />
          </div>
          <h1>{i18next.t<string>('Nominate your raters')}</h1>
          <SelectPulseRatersList
            storeSelected={this.storeSelectedAndUpdateState}
            selectedRaters={selectedRaters}
            userPulseDetail={userPulseDetail}
            disabled={responsesPending}
          />
          <button
            className="btn btn-primary"
            disabled={
              !selectedRaters || selectedRaters.length === 0 || responsesPending
            }
            onClick={() => setBehavioursAndRaters(undefined)}
          >
            <PulseIconCircleWhite
              width={20}
              height={20}
              className="svg-inline--fa"
            />
            {i18next.t<string>('Start pulsing')}
          </button>
          {responsesPending && <p>{i18next.t<string>('Saving...')}</p>}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: StoreState) {
  const { responses } = state;
  return {
    responsesPending:
      isPending(responses.get('setPulseBehaviours')) ||
      isPending(responses.get('setPulseRaters')),
  };
}

export default connect(mapStateToProps, {})(SelectPulseRaters);
