import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { List } from 'immutable';
import i18next from 'i18next';

import {
  getAllOrganisationOptions,
  getOrganisationsListCreateUser,
  getOrganisationsListIds,
} from '../actions/collections';
import { can, administerOrganisations } from '../capabilities';
import { setSimpleComponentState } from '^/actions/actions';
import { isPending } from '../responseStates';
import BlankOption from './BlankOption';
import PureComponent from './PureComponent';

export class SelectOrganisation extends PureComponent {
  componentDidMount() {
    const { user, filters, collectionName } = this.props;

    const organisation_filter_options = Object.assign(
      {},
      filters,
      this.props.selectedProduct && {
        products__id: this.props.selectedProduct,
      }
    );

    if (can(user, administerOrganisations(filters))) {
      if (collectionName === 'organisations-list-create-user') {
        this.props.getOrganisationsListCreateUser();
      } else if (collectionName === 'organisations-list-ids') {
        this.props.getOrganisationsListIds();
      } else {
        this.props.getAllOrganisationOptions(organisation_filter_options);
      }
    }
  }

  onChange(event) {
    const { onChange } = this.props;
    onChange(event.target.value);
  }

  render() {
    const {
      user,
      name,
      value,
      meta,
      label,
      helpText,
      showDisabled,
      response,
      organisations,
      selectedOrganisation,
      children,
      disabled,
      showAll,
      hideLabel,
    } = this.props;

    if (!can(user, administerOrganisations())) {
      return null;
    }

    return (
      <div>
        {!hideLabel && (
          <label htmlFor="account">
            {(label || i18next.t('Account')) + ' '}
            {helpText && <span className="help-inline">{helpText}</span>}
          </label>
        )}

        <div className="form-item">
          <select
            id="account"
            name={name}
            value={
              selectedOrganisation ? selectedOrganisation.get('id') : value
            }
            defaultValue=""
            onChange={this.onChange.bind(this)}
            disabled={isPending(response) || disabled}
          >
            <BlankOption response={response} disabled={value} />

            {showAll && <option value="ALL">{i18next.t('All')}</option>}

            {organisations.map(organisation => (
              <option
                key={organisation.get('id')}
                value={organisation.get('id')}
                disabled={showDisabled && showDisabled(organisation)}
              >
                {organisation.get('name')}
              </option>
            ))}
          </select>
          {children}
          {meta && meta.touched && meta.error && (
            <p className="text-error">{meta.error}</p>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let organisations, response;
  if (ownProps.collectionName) {
    organisations = state.collections.getIn(
      [ownProps.collectionName, 'items'],
      List()
    );
    response = state.multiResponses.getIn([
      'getCollection',
      ownProps.collectionName,
    ]);
  } else {
    organisations = state.collections.getIn(
      ['organisationOptions', 'items'],
      List()
    );
    response = state.multiResponses.getIn([
      'getCollection',
      'organisationOptions',
    ]);
  }
  return {
    user: state.userProfile,
    organisations,
    response: ownProps.response || response,
  };
}

export default connect(mapStateToProps, {
  getAllOrganisationOptions,
  getOrganisationsListCreateUser,
  getOrganisationsListIds,
  setSimpleComponentState,
})(SelectOrganisation);
