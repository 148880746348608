import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import {
  selectBasketActionBarVisible,
  selectNumberOfItemsInBasket,
} from '^/components/shop/basket/selectors';
import { StoreState } from '^/store';
import { RouterParamProps, withRouter } from '^/withRouter';

interface StateProps {
  numberOfItemsInBasket: number;
  visible: boolean;
}

type Props = StateProps & RouterParamProps;

export const BasketActionBar: React.FunctionComponent<Props> = ({
  numberOfItemsInBasket,
  visible,
}) => (
  <div className={classnames('action-bar', { visible })}>
    <div className="action-bar-wrapper">
      <div className="action-bar-status">
        <h2 className="margin-none">
          {i18next.t<string>('{{count}} items in basket', {
            count: numberOfItemsInBasket,
          })}
        </h2>
      </div>

      <div className="action-bar-buttons">
        <Link className="btn btn-action-bar-primary" to="/page/basket">
          <FontAwesomeIcon icon={faShoppingCart} />
          {i18next.t<string>('Basket')}
        </Link>
      </div>
    </div>
  </div>
);

function mapStateToProps(state: StoreState, ownProps: Props): StateProps {
  return {
    visible: selectBasketActionBarVisible(state, ownProps),
    numberOfItemsInBasket: selectNumberOfItemsInBasket(state),
  };
}

export default withRouter(connect(mapStateToProps)(BasketActionBar));
