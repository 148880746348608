import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';

export default class ProgressBar extends PureComponent {
  getPercent() {
    const { percent } = this.props;
    return Math.min(Math.max(percent || 0, 0), 100);
  }

  render() {
    const { className, success } = this.props;
    return (
      <div className={classNames('progress-bar', { success }, className)}>
        <div className="progress" style={{ width: `${this.getPercent()}%` }} />
      </div>
    );
  }
}
