import { faArrowsAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import i18next from 'i18next';

import DragAndDropGroup from '^/components/drag-and-drop/DragAndDropGroup';
import DragAndDropHandler, {
  DragDetails,
} from '^/components/drag-and-drop/DragAndDropHandler';
import Draggable from '^/components/drag-and-drop/Draggable';
import DragTarget from '^/components/drag-and-drop/DragTarget';
import { generateQuestionOrder } from '^/questionGroups';

type Item = List<Map<string, any>>;

type QuestionGroup = Map<string, any>;

interface OwnProps {
  isPending: boolean;
  questionGroup: QuestionGroup;
  reorderAnswers: (answers: List<string | null>) => void;
  targetText?: boolean;
}

type Props = OwnProps;

interface State {
  from: ReadonlyArray<Item>;
  to: ReadonlyArray<Item>;
}

const createItemKeyGetter = <T extends any>(key: string) => (item: Item) =>
  item.getIn([0, key]) as T;
const getItemId = createItemKeyGetter<string>('id');
const getItemText = createItemKeyGetter<string>('text');
const isEmpty = createItemKeyGetter<boolean | undefined>('empty');

const isEmptyBoolean = (item: Item) => Boolean(isEmpty(item));

const shouldSwap = (
  _item: Item,
  targetItem: Item,
  details: DragDetails<keyof State>
) => isEmpty(targetItem) || details.draggedGroup === 'to';

export class MotivatorQuestionGroup extends PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = this.prepareGroups(props);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.questionGroup !== this.props.questionGroup) {
      this.setState(this.prepareGroups(this.props));
    }
  }

  public render() {
    return (
      <DragAndDropHandler
        groups={this.state}
        onDrop={this.onDrop}
        shouldSwap={shouldSwap}
        isEmpty={isEmptyBoolean}
        className="isp-drag-and-drop-container"
      >
        {group => (
          <DragAndDropGroup<Item>
            key={group}
            group={group}
            tag="ul"
            className={classNames('isp-drag-and-drop-group', group)}
          >
            {(item, index) => {
              const draggable = (
                <Draggable
                  key={getItemId(item)}
                  index={index}
                  disableDrag={isEmpty(item)}
                  className={classNames('draggable', {
                    empty: isEmpty(item),
                  })}
                  draggedClassName="dragged"
                  placeholderClassName="placeholder"
                >
                  <span className="draggable-text">{getItemText(item)}</span>
                  {!isEmpty(item) && <FontAwesomeIcon icon={faArrowsAlt} />}
                </Draggable>
              );
              const draggableTarget = (
                <Draggable
                  key={getItemId(item)}
                  index={index}
                  disableDrag={isEmpty(item)}
                  className={classNames('draggable motivator', {
                    empty: isEmpty(item),
                  })}
                  draggedClassName="dragged"
                  placeholderClassName="placeholder"
                >
                  <span className="draggable-text">
                    <div className="drag-text-wrapper">
                      <div>{!isEmpty(item) && getItemText(item)}</div>
                    </div>
                    {isEmpty(item) && index === 0 && this.props.targetText && (
                      <div className="drag-target-text">
                        {i18next.t<string>('Most important to me at work')}
                      </div>
                    )}
                    {isEmpty(item) &&
                      index === this.state.from.length - 1 &&
                      this.props.targetText && (
                        <div className="drag-target-text">
                          {i18next.t<string>('Least important to me at work')}
                        </div>
                      )}
                  </span>
                </Draggable>
              );

              return group === 'from' ? (
                <li className="drag-container">{draggable}</li>
              ) : (
                <DragTarget
                  key={getItemId(item)}
                  index={index}
                  tag="li"
                  className="drag-container"
                >
                  {draggableTarget}
                </DragTarget>
              );
            }}
          </DragAndDropGroup>
        )}
      </DragAndDropHandler>
    );
  }

  private prepareGroups(props: Props): State {
    const questions = props.questionGroup.get('questions');
    const currentOrder = generateQuestionOrder(questions);
    const orderedQuestions = this.orderQuestions(
      questions,
      currentOrder
    ).toArray();

    return {
      from: orderedQuestions,
      to: orderedQuestions.map(item =>
        List.of(
          Map({
            id: `${getItemId(item)}-empty`,
            text: '',
            empty: true,
          })
        )
      ),
    };
  }

  private onDrop = (state: State) => {
    this.setState(state);
    const newOrder = List(
      state.to.map(item => (isEmpty(item) ? null : getItemId(item)))
    );
    this.props.reorderAnswers(newOrder);
  };

  private orderQuestions(questions: List<Item>, currentOrder: List<Item>) {
    return currentOrder.map(id => {
      return questions.find(question => question.getIn([0, 'id']) === id);
    });
  }
}

export default MotivatorQuestionGroup;
