import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import FormError from '^/components/FormError';
import LiveButton from '^/components/LiveButton';
import PureComponent from '^/components/PureComponent';
import { hasSucceeded } from '^/responseStates';
import FormItem from '^/components/forms/FormItem';

export class CreateGroupForm extends PureComponent {
  componentDidUpdate() {
    if (hasSucceeded(this.props.response)) {
      this.props.onClose();
    }
  }

  isReadyToSubmit(name, organisation) {
    const { showOrganisationSelector } = this.props;
    return name && (!showOrganisationSelector || organisation);
  }

  render() {
    const {
      onClose,
      summaryText,
      onSubmit,
      response,
      showOrganisationSelector,
      organisations,
    } = this.props;

    return (
      <Formik
        initialValues={{ name: '', organisation: '' }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="form-block">
            {showOrganisationSelector && (
              <FormItem htmlFor="select-account" label={i18next.t('Account')}>
                <Field as="select" id="select-account" name="organisation">
                  <option disabled selected value="">
                    {i18next.t('Please select...')}
                  </option>
                  {organisations.map(org => (
                    <option key={org.get('id')} value={org.get('id')}>
                      {org.get('name')}
                    </option>
                  ))}
                </Field>
                <FormError response={response} formKey="organisation" />
              </FormItem>
            )}

            {summaryText && <p>{summaryText}</p>}

            <FormItem htmlFor="group-name" label={i18next.t('Group name')}>
              <Field
                id="group-name"
                type="text"
                name="name"
                placeholder={i18next.t('Group name')}
              />
              <FormError response={response} formKey="name" />
              <FormError response={response} formKey="non_field_errors" />
            </FormItem>

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={onClose}
                >
                  {i18next.t('Cancel')}
                </button>
                <LiveButton
                  disabled={
                    !this.isReadyToSubmit(values.name, values.organisation) ||
                    isSubmitting
                  }
                  buttonText={i18next.t('Create group')}
                  pendingMessage={i18next.t('Creating group...')}
                  response={response}
                  hideErrors
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default CreateGroupForm;
