import i18next from 'i18next';
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';

import { administerOrganisations } from '^/capabilities';
import { FilterData } from '^/components/Filter';
import { ShopItemOptionType } from '^/reducers/api/types';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';

const selectPurchasedBasketItems = (
  state: StoreState
): List<Map<string, any>> | undefined =>
  state.collections.getIn(['purchasedBasketItems', 'items']);

export const selectPurchasedBasketItemsHeaders = createSelector(
  (state: StoreState) => selectUserCapability(state, administerOrganisations()),
  shouldShowOrganisations => {
    const headers = [
      {
        title: i18next.t<string>('Purchase date'),
        field: 'purchased',
        sortable: true,
      },
      {
        title: i18next.t<string>('Item'),
        field: 'shop_item_option__shop_item__name',
        sortable: true,
      },
      {
        title: i18next.t<string>('Type'),
        field: 'shop_item_option__type',
        sortable: true,
        className: 'shop-item-type-header',
      },
      {
        title: i18next.t<string>('Qty'),
        field: 'quantity',
      },
      {
        title: i18next.t<string>('Total cost (+VAT)'),
        field: 'shop_item_option__cost',
      },
    ];
    if (shouldShowOrganisations) {
      headers.unshift({
        title: i18next.t<string>('Account'),
        field: 'user__organisation',
        sortable: true,
      });
    }
    return headers;
  }
);

export const selectBasketItemsFilters = createSelector(
  (_state: StoreState, filterSpec: Record<string, string>) => filterSpec,
  selectPurchasedBasketItems,
  (state: StoreState) => selectUserCapability(state, administerOrganisations()),
  (filterSpec, purchasedBasketItems, shouldShowOrganisations) => {
    const filters: FilterData[] = [
      {
        name: i18next.t<string>('Item'),
        key: 'shop_item_option__shop_item__name',
        currentValue: filterSpec.shop_item_option__shop_item__name,
        values: {
          values:
            purchasedBasketItems &&
            purchasedBasketItems
              .map((basketItem: Map<string, any>) =>
                basketItem.getIn(['shop_item_option', 'shop_item', 'name'])
              )
              .toOrderedSet()
              .toList()
              .map((name: string) => Map({ id: name, name })),
        },
      },
      {
        name: i18next.t<string>('Type'),
        key: 'shop_item_option__type',
        currentValue: filterSpec.shop_item_option__type,
        values: {
          values: fromJS([
            { id: ShopItemOptionType.Accreditation, name: 'Accreditation' },
            { id: ShopItemOptionType.SelfVerify, name: 'Self Verify' },
            { id: ShopItemOptionType.Product, name: 'Product' },
            {
              id: ShopItemOptionType.FeedbackSession,
              name: 'Feedback Session',
            },
          ]),
        },
      },
    ];
    if (shouldShowOrganisations) {
      filters.unshift({
        name: i18next.t<string>('Organisation'),
        key: 'user__organisation',
        currentValue: filterSpec.user__organisation,
        values: {
          async: true,
          collectionKey: 'basketItemOrgs',
        },
      });
    }
    return filters;
  }
);

export const selectPurchasedPulsesHeaders = createSelector(
  (state: StoreState) => selectUserCapability(state, administerOrganisations()),
  shouldShowOrganisations => {
    const headers = [
      {
        title: i18next.t<string>('Purchase date'),
        field: 'created',
        sortable: true,
        className: 'width-12-rem',
      },
      {
        title: i18next.t<string>('Pulse name'),
        field: 'pulse_name',
        sortable: true,
      },
      {
        title: i18next.t<string>('Product'),
        field: 'pulse__activity_product_version',
        sortable: true,
      },
      {
        title: i18next.t<string>('Qty'),
        field: 'pulse__total_pulses_count',
      },
      {
        title: i18next.t<string>('Total cost (+VAT)'),
        field: 'pulse__total_price_ex_vat',
      },
      {
        title: i18next.t<string>('Action'),
        field: '_',
      },
    ];
    if (shouldShowOrganisations) {
      headers.unshift({
        title: i18next.t<string>('Account'),
        field: 'pulse__organisation',
        sortable: true,
      });
    }
    return headers;
  }
);

export const selectPurchasedPulsesFilters = createSelector(
  (_state: StoreState, filterSpec: Record<string, string>) => filterSpec,
  selectPurchasedBasketItems,
  (state: StoreState) => selectUserCapability(state, administerOrganisations()),
  (filterSpec, _, shouldShowOrganisations) => {
    const filters: FilterData[] = [];

    if (shouldShowOrganisations) {
      filters.unshift({
        name: i18next.t<string>('Organisation'),
        key: 'pulse__organisation',
        currentValue: filterSpec.pulse__organisation,
        values: {
          async: true,
          collectionKey: 'purchasedPulseOrgs',
        },
      });
    }
    filters.unshift({
      name: i18next.t<string>('Product'),
      key: 'pulse__activity_product_version',
      currentValue: filterSpec.pulse__activity_product_version,
      values: {
        async: true,
        collectionKey: 'pulseProductOptions',
      },
    });
    return filters;
  }
);
