import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { updateUserLanguage } from '^/actions/actions';
import { setLanguageSwitcherInteractedWith } from '^/actions/ui';
import { setUiLanguageAndMaybeActivate } from '^/actions/actionSequences';
import {
  LanguageCode,
  TRANSLATED_LANGUAGES,
  TRANSLATED_LANGUAGE_READABLE_MAP,
} from '^/constants/routes';
import { StoreState } from '^/store';
import { withRouter, RouterParamProps } from '^/withRouter';
import { getLangCodeWithFallback } from '^/utils-ts';

interface OwnProps {
  nav?: boolean;
}

interface DispatchProps {
  updateUserLanguage: typeof updateUserLanguage;
  setUiLanguageAndMaybeActivate: typeof setUiLanguageAndMaybeActivate;
  setLanguageSwitcherInteractedWith: typeof setLanguageSwitcherInteractedWith;
}

interface StateProps {
  uiLanguage: LanguageCode;
  location: Location;
  user: Map<string, any>;
}

export type Props = OwnProps & StateProps & DispatchProps & RouterParamProps;

export class LanguageSelector extends React.PureComponent<Props> {
  render() {
    const {
      uiLanguage,
      nav,
      user,
      location: { pathname, search },
    } = this.props;

    const changeLanguage = (langCode: any) => {
      const safeLangCode = getLangCodeWithFallback(langCode);
      const userLang = user.get('lang_code');
      if (user.get('id') && userLang !== safeLangCode) {
        this.props.updateUserLanguage(safeLangCode);
      }
      this.props.setUiLanguageAndMaybeActivate(
        safeLangCode,
        `${pathname}${search}`
      );
      this.props.setLanguageSwitcherInteractedWith(true);
    };

    const renderLanguageOption = (langCode: LanguageCode) => (
      <a
        key={langCode}
        className={classNames('language-option', {
          selected: langCode === uiLanguage,
        })}
        onClick={() => changeLanguage(langCode)}
        role="button"
      >
        {i18next.t<string>(TRANSLATED_LANGUAGE_READABLE_MAP[langCode])}
      </a>
    );

    return (
      <div
        className={classNames(
          'language-selector dropdown',
          nav ? 'nav' : 'external'
        )}
        tabIndex={0}
      >
        <a className="label">
          <FontAwesomeIcon icon={faGlobe} />{' '}
          {i18next.t<string>(TRANSLATED_LANGUAGE_READABLE_MAP[uiLanguage])}
        </a>
        <div className="content">
          {renderLanguageOption(uiLanguage)}
          {TRANSLATED_LANGUAGES.filter(
            langCode => langCode !== uiLanguage
          ).map(langCode => renderLanguageOption(langCode))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StoreState, props: any) => {
  return {
    uiLanguage: state.ui.get('uiLanguage'),
    location: props.router.location,
    user: state.userProfile,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    updateUserLanguage,
    setUiLanguageAndMaybeActivate,
    setLanguageSwitcherInteractedWith,
  })(LanguageSelector)
);
