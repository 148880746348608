import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Link } from 'react-router';

const AdminEnduserDashboardSwitcher = props => (
  <div className="pull-right">
    {props.currentPath === '/page/home' ? (
      <Link to="/page/dashboard" className="btn btn-default mt-sm">
        {i18next.t('My tasks')}
      </Link>
    ) : (
      <Link to="/page/home" className="btn btn-default mt-sm">
        {i18next.t('Home')}
      </Link>
    )}
  </div>
);

export default AdminEnduserDashboardSwitcher;
