import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { accountSignUp } from '^/actions/actions';
import NoAuth from '^/components/NoAuth';
import AccountSignUpForm, {
  AccountSignUpFormProps,
} from '^/components/organisations/AccountSignUp/form';
import PeopleWiseText from '^/components/PeopleWiseText';
import PureComponent from '^/components/PureComponent';
import { hasSucceeded } from '^/responseStates';
import { StoreState } from '^/store';
import { getQueryValue } from '^/utils-ts';
import { RouterParamProps, withRouter } from '^/withRouter';

const defaultAccountType = 'SOLE_PRACTITIONER';

interface DispatchProps {
  accountSignUp: typeof accountSignUp;
}

interface StateProps {
  response: Map<string, any>;
  shopItemOptionId: string;
  accountType: string;
}

type Props = DispatchProps & StateProps & RouterParamProps;

export class AccountSignUp extends PureComponent<Props> {
  public render() {
    const { response } = this.props;

    return (
      <NoAuth
        description={
          !hasSucceeded(response) && (
            <div>
              <h2>
                <Trans i18nKey="TRANS Welcome to the peoplewise platform">
                  Welcome to the <PeopleWiseText /> Talent Platform
                </Trans>
              </h2>
              <p>
                {i18next.t<string>(
                  'Complete the form below to setup your account'
                )}
              </p>
              <Trans i18nKey="TRANS sign up redirect">
                If you already have an account or have been invited to complete
                an assessment please
                <a href="#/login">login here</a>
              </Trans>
            </div>
          )
        }
        noLang
      >
        <h2>{i18next.t<string>('Sign Up')}</h2>

        {hasSucceeded(response) ? (
          <p>
            {i18next.t<string>(
              'Please click the link in the email which has been sent to you to validate your email address.'
            )}
          </p>
        ) : (
          <AccountSignUpForm response={response} onSubmit={this.onSubmit} />
        )}
      </NoAuth>
    );
  }

  private onSubmit = (data: AccountSignUpFormProps) => {
    const { shopItemOptionId, accountType } = this.props;
    const setAccountType = !accountType ? defaultAccountType : accountType;
    return this.props.accountSignUp({
      'organisation.name': data.account_name,
      'organisation.tax_location': data.tax_location,
      'organisation.tax_registration_number': data.tax_registration_number,
      'organisation.brand_logo': data.brand_logo,
      'organisation.account_type': setAccountType,
      'superuser.full_name': data.account_superuser_name,
      'superuser.email': data.account_superuser_email,
      'superuser.password': data.password,
      'superuser.password2': data.password2,
      shop_item_option_id: shopItemOptionId,
    });
  };
}

export function mapStateToProps(state: StoreState, props: Props): StateProps {
  return {
    response: state.responses.get('accountSignUp'),
    shopItemOptionId: getQueryValue(props, 'shop_item_option') as string,
    accountType: getQueryValue(props, 'account_type') as string,
  };
}

export default withRouter(
  connect(mapStateToProps, { accountSignUp })(AccountSignUp)
);
