/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faDownload,
} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import {
  ChartDataStructure,
  Frequency,
  PulseFrequency,
} from '^/reducers/api/types';
import {
  CHART_COLORS,
  CHART_COLORS_TRANS,
  FOUR_WEEK_BLOCK,
  QUARTER_BLOCK,
  WEEK_BLOCK,
} from './constants';
import { findBlockLength, renderTruncatedTag } from './utils';
import { formatDate } from '^/utils';

interface OwnProps {
  data: ChartDataStructure;
  isMobile: boolean;
  chartTitle: string;
  chartSubTitle: string;
  descriptionText: string;
  frequency: Frequency;
  isSelfOnly: boolean;
}

interface State {
  currentDateBlock: number;
  blockLength: number;
  showDescription: boolean;
}

type Props = OwnProps;

export class BehaviourChart extends React.PureComponent<Props, State> {
  lineChart:
    | Chart<'line', (string | number | null)[], string | null>
    | null
    | undefined;
  lineChartDownload:
    | Chart<'line', (string | number | null)[], string | null>
    | null
    | undefined;
  chartRef: React.RefObject<HTMLCanvasElement>;
  downloadChartRef: React.RefObject<HTMLCanvasElement>;
  chartLegend: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.chartRef = React.createRef();
    this.downloadChartRef = React.createRef();
    this.chartLegend = React.createRef();
    this.state = {
      showDescription: false,
      currentDateBlock: this.props.data.dataBlockCount,
      blockLength:
        findBlockLength(this.props.isMobile, this.props.frequency) || 0,
    };
  }

  private labelText = (type: string) => {
    const { frequency, isSelfOnly } = this.props;
    switch (type) {
      case 'average':
        return frequency.type === PulseFrequency.DAILY
          ? i18next.t<string>('Weekly pulse')
          : frequency.count === 1
          ? i18next.t<string>('Monthly pulse')
          : i18next.t<string>('Quarterly pulse');
      case 'dataPointAvg':
        if (isSelfOnly) {
          return i18next.t<string>('Me = ');
        }

        return frequency.type === PulseFrequency.DAILY
          ? i18next.t<string>('Weekly pulse avg = ')
          : frequency.count === 1
          ? i18next.t<string>('Monthly pulse avg = ')
          : i18next.t<string>('Quarterly pulse avg = ');

      case 'dataPoint':
        return frequency.type === PulseFrequency.DAILY
          ? i18next.t<string>('Daily pulse')
          : frequency.count === 1
          ? i18next.t<string>('Weekly pulse')
          : i18next.t<string>('Monthly pulse');
      case 'frequencyAverage':
        return frequency.type === PulseFrequency.DAILY
          ? i18next.t<string>('Daily pulse avg')
          : frequency.count === 1
          ? i18next.t<string>('Weekly pulse avg')
          : i18next.t<string>('Monthly pulse avg');
      case 'frequencyRange':
        return frequency.type === PulseFrequency.DAILY
          ? i18next.t<string>('Daily pulse range = ')
          : frequency.count === 1
          ? i18next.t<string>('Weekly pulse range = ')
          : i18next.t<string>('Monthly pulse range = ');
    }
  };

  private showHideDescription = () => {
    this.setState({ showDescription: !this.state.showDescription });
  };

  closeTooltip = (
    chart: Chart<'line', (string | number | null)[], string | null>
  ) => {
    chart?.tooltip?.setActiveElements([], { x: 1000, y: 1000 });
    chart?.canvas?.parentNode?.querySelector('div')?.remove();
  };

  getOrCreateLegendList = () => {
    const legendDiv = document.createElement('div');
    this.chartLegend?.current?.append(legendDiv);
    return this.chartLegend;
  };

  getOrCreateTooltip = (
    chart: Chart<'line', (string | number | null)[], string | null>
  ) => {
    let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.borderWidth = '1';
      tooltipEl.style.backgroundColor = 'white';
      tooltipEl.style.opacity = '1';
      tooltipEl.style.pointerEvents = 'auto';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const tooltipDiv = document.createElement('div');
      tooltipDiv.style.margin = '0px';
      tooltipEl.appendChild(tooltipDiv);
      chart?.canvas?.parentNode?.appendChild(tooltipEl);
    }
    return tooltipEl;
  };

  externalTooltipHandler = (context: {
    chart: Chart<'line', (string | number | null)[], string | null>;
    tooltip: any;
  }) => {
    const { chart, tooltip } = context;
    const tooltipEl = this.getOrCreateTooltip(chart);
    if (tooltip.body) {
      const titleDiv = document.createElement('div');
      titleDiv.style.fontWeight = 'bold';
      const titleText = document.createTextNode(tooltip.title);
      titleDiv.appendChild(titleText);

      const bodyLines = tooltip.body.map((body: { lines: any }) => body.lines);
      const bodyDiv = document.createElement('div');
      bodyLines.forEach((body: string[]) => {
        if (body[0]) {
          const bodyInnerDivs = document.createElement('div');
          const text = document.createTextNode(body[0]);
          text?.textContent?.includes(i18next.t<string>('Me'))
            ? (bodyInnerDivs.style.fontWeight = 'bold')
            : (bodyInnerDivs.style.fontWeight = 'normal');
          bodyInnerDivs.appendChild(text);
          bodyDiv.appendChild(bodyInnerDivs);
        }
      });
      bodyDiv.onclick = () => {
        if (chart?.tooltip && chart.tooltip.getActiveElements().length > 0) {
          this.closeTooltip(chart);
        }
      };

      const afterBodyLines = tooltip.afterBody.map((lines: string) => lines);
      afterBodyLines.forEach((body: string) => {
        const afterBodyInnerDivs = document.createElement('div');
        const text = document.createTextNode(body);
        afterBodyInnerDivs.appendChild(text);
        bodyDiv.appendChild(afterBodyInnerDivs);
      });

      const divRoot = tooltipEl.querySelector('div');

      while (divRoot?.firstChild) {
        divRoot?.firstChild.remove();
      }

      divRoot?.appendChild(titleDiv);
      divRoot?.appendChild(bodyDiv);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    let adjustXpos = tooltip.caretX;
    if (tooltip.caretX > 227) {
      adjustXpos = tooltip.caretX - 62;
    }

    if (tooltip.caretX < 32) {
      adjustXpos = tooltip.caretX + 32;
    }

    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.left = positionX + adjustXpos + 'px';
    tooltipEl.style.top = positionY + tooltip._eventPosition.y + 5 + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.fontSize = '10px';
    tooltipEl.style.maxWidth = '150px';
    tooltipEl.style.border = 'solid 1px #2c2c2e';
    tooltipEl.style.padding = '2px';
  };

  public downloadChartImage(image: string) {
    const fileName = `${this.props.chartTitle.replace(
      /[\W_]/g,
      ''
    )}-${this.props.chartSubTitle.replace(/[\W_]/g, '')}`;
    const fileNameTrimmed =
      fileName.length > 30 ? fileName.substring(0, 30) : fileName;
    const link = document.createElement('a');
    if (typeof link.download !== 'string') {
      window.open(image);
    } else {
      link.href = image;
      link.download = fileNameTrimmed;
      link.click();
    }
  }

  public getGradient(
    ctx: {
      createLinearGradient: (
        arg0: number,
        arg1: any,
        arg2: number,
        arg3: any
      ) => any;
    },
    chartArea: { right: number; left: number; bottom: number; top: number }
  ) {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.bottom,
      0,
      chartArea.top
    );
    gradient.addColorStop(1, CHART_COLORS.green);
    gradient.addColorStop(0.6, CHART_COLORS_TRANS.light_green);
    gradient.addColorStop(0, CHART_COLORS_TRANS.transparent);
    return gradient;
  }

  public changeDataBlock(isBack: boolean) {
    const { blockLength, currentDateBlock } = this.state;
    if (this.lineChart?.canvas?.parentNode?.querySelector('div')) {
      this.closeTooltip(this.lineChart);
    }
    const blockToShow = isBack ? currentDateBlock - 1 : currentDateBlock + 1;
    const splitFrom =
      blockToShow === 1
        ? 0
        : blockLength * (blockToShow - 1) - (blockToShow - 1);
    const splitTo =
      blockToShow === 1
        ? blockLength
        : blockLength * blockToShow - (blockToShow - 1);
    this.setState({ currentDateBlock: blockToShow });
    if (this.lineChart) {
      const chartData = this.lineChart.data;
      chartData.labels = this.props.data.xAxisLabels.slice(splitFrom, splitTo);
      chartData.datasets[1].data = this.props.data.selfData.slice(
        splitFrom,
        splitTo
      );

      chartData.datasets[0].data = this.props.data.frequencyAverages.slice(
        splitFrom,
        splitTo
      );
      if (
        !chartData.datasets[1].data[chartData.datasets[1].data.length - 1] &&
        blockToShow < this.props.data.dataBlockCount
      ) {
        const lastDataPoint = this.props.data.selfData
          .slice(splitTo, splitTo + blockLength)
          .filter(x => x !== null || undefined)
          .slice(0, 1)[0];
        chartData.datasets[1].data[
          chartData.datasets[1].data.length - 1
        ] = lastDataPoint;
      }
      if (!chartData.datasets[1].data[0] && blockToShow !== 1) {
        const lastDataPoint = this.props.data.selfData
          .slice(0, splitFrom)
          .filter(x => x !== null || undefined);
        chartData.datasets[1].data[0] = lastDataPoint[lastDataPoint.length - 1];
      }
      for (let i = 0; i < this.props.data.ratersData.length; i++) {
        chartData.datasets[i + 2].data = this.props.data.ratersData[i].slice(
          splitFrom,
          splitTo
        );
      }
      this.lineChart.update();
    }
  }

  public getTrimmedLength() {
    return (
      this.props.data.frequencyAverages
        .map(item => item && item.toString() !== 'NaN')
        .lastIndexOf(true) + 1
    );
  }

  public buildChartData(download: boolean) {
    const { data, isSelfOnly } = this.props;
    const { blockLength, currentDateBlock } = this.state;
    const totalBlocks = data.dataBlockCount;
    const splitFrom =
      download || currentDateBlock === 1
        ? 0
        : blockLength * (totalBlocks - 1) -
          (currentDateBlock === 1 ? 1 : totalBlocks - 1);
    const splitTo = download
      ? this.getTrimmedLength()
      : blockLength * totalBlocks -
        (currentDateBlock === 1 ? 0 : totalBlocks - 1);
    const raterObjArr = [];
    for (let i = 0; i < data.ratersData.length; i++) {
      raterObjArr[i] = {
        label: [this.labelText('dataPoint'), i18next.t<string>('AVG')] as any,
        data: data.ratersData[i].slice(splitFrom, splitTo),
        borderColor: CHART_COLORS.blue,
        backgroundColor: CHART_COLORS.blue,
        pointHoverRadius: 3,
        clip: 3,
        radius: 3,
        borderWidth: 0,
        showLine: false,
      };
    }

    const chartData = {
      labels: data.xAxisLabels.slice(splitFrom, splitTo),

      datasets: [
        {
          label: [this.labelText('average'), i18next.t<string>('AVG')] as any,
          data: data.frequencyAverages.slice(splitFrom, splitTo),
          borderColor: CHART_COLORS.dark_blue,
          spanGaps: true,
          radius: 1,
          borderDash: [7, 5],
          pointHoverRadius: 5,
          borderWidth: 2,
          hidden: download ? false : true,
        },
        {
          label: i18next.t<string>('Me'),
          data: data.selfData.slice(splitFrom, splitTo),
          borderColor: CHART_COLORS.green,
          spanGaps: true,
          radius: 0.5,
          pointHoverRadius: 3,
          pointHitRadius: 5,
          borderWidth: 1.5,
          fill: {
            value: 2,
            above: (context: { chart: any }) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return;
              }
              return this.getGradient(ctx, chartArea);
            },
            below: CHART_COLORS_TRANS.transparent,
          },
          backgroundColor: CHART_COLORS_TRANS.light_green,
        },
        ...raterObjArr,
      ],
    };
    if (!chartData.datasets[1].data[0] && totalBlocks !== 1) {
      const lastDataPoint = this.props.data.selfData
        .slice(0, splitFrom)
        .filter(x => x !== null || undefined);
      chartData.datasets[1].data[0] = lastDataPoint[lastDataPoint.length - 1];
    }
    if (isSelfOnly) {
      chartData.datasets.splice(0, 1);
    }
    return chartData;
  }

  public buildChart(download: boolean) {
    const ctx = download
      ? this.downloadChartRef?.current?.getContext('2d')
      : this.chartRef?.current?.getContext('2d');
    const { isMobile, frequency, isSelfOnly } = this.props;
    const { blockLength } = this.state;
    if (this.lineChartDownload) this.lineChartDownload.destroy();
    if (this.lineChart && !download) this.lineChart.destroy();
    const builtData = this.buildChartData(download);
    const lineChartConfig:
      | Chart<'line', (string | number | null)[], string | null>
      | null
      | undefined =
      ctx &&
      new Chart(ctx, {
        plugins: [
          {
            id: 'htmlLegend',
            afterUpdate: chart => {
              if (!download) {
                const ul = this.getOrCreateLegendList().current;

                while (ul?.firstChild) {
                  ul.firstChild.remove();
                }
                const generateLabels =
                  chart?.options?.plugins?.legend?.labels?.generateLabels;
                const items = generateLabels && generateLabels(chart);
                const selectTextEl = document.createElement('li');
                const selectText = document.createTextNode(
                  `${i18next.t<string>('Select')} :`
                );
                selectTextEl.style.paddingRight = '13px';
                selectTextEl.style.listStyle = 'none';
                selectTextEl.appendChild(selectText);
                ul?.appendChild(selectTextEl);

                items && items.push(items.splice(0, 1)[0]);
                items?.forEach(item => {
                  if (item.datasetIndex < 3) {
                    const li = document.createElement('li');
                    const liWidth = (index: number) => {
                      switch (index) {
                        case 0:
                          return isMobile ? '90px' : '95px';
                        case 1:
                          return isMobile ? '49px' : '54px';
                        default:
                          return isMobile ? '80px' : '90px';
                      }
                    };
                    li.style.cursor = 'pointer';
                    li.style.width = liWidth(item.datasetIndex);
                    li.style.listStyle = 'none';
                    li.style.display = 'flex';
                    li.style.textAlign = 'center';
                    li.onmouseover = () => {
                      li.className = 'hover';
                    };
                    li.onmouseout = () => {
                      li.className = '';
                    };
                    li.onclick = () => {
                      const index = item.datasetIndex;
                      const isHidden = chart.data.datasets[index].hidden;
                      if (index === 2) {
                        for (let i = 2; i < chart.data.datasets.length; i++) {
                          chart.data.datasets[i].hidden = !isHidden;
                        }
                      } else {
                        chart.data.datasets[index].hidden = !isHidden;
                      }
                      chart.update();
                    };
                    const itemIndex = item.datasetIndex;
                    const itemMarginTop = (index: number) => {
                      if (index === 2) {
                        return '3px';
                      }
                      return isMobile ? '5px' : '7px';
                    };
                    const lineColor =
                      itemIndex === 2
                        ? CHART_COLORS_TRANS.transparent
                        : (chart.data.datasets[itemIndex]
                            .borderColor as string);
                    const boxSpan = document.createElement('span');
                    boxSpan.style.borderBottom = `3px ${
                      itemIndex === 1 || isSelfOnly ? 'solid' : 'dashed'
                    } ${lineColor}`;
                    boxSpan.style.display = 'inline-block';
                    boxSpan.style.height = itemIndex === 2 ? '9px' : '3px';
                    boxSpan.style.marginRight = '4px';
                    boxSpan.style.marginTop = itemMarginTop(itemIndex);
                    boxSpan.style.width = itemIndex === 2 ? '9px' : '15px';
                    boxSpan.style.borderRadius = itemIndex === 2 ? '50px' : '0';
                    boxSpan.style.opacity =
                      !chart.data.datasets[itemIndex].hidden || itemIndex === 2
                        ? '1'
                        : '0.4';
                    boxSpan.style.backgroundColor =
                      itemIndex === 2
                        ? (item.fillStyle as string) || ''
                        : CHART_COLORS_TRANS.transparent;
                    const textContainer = document.createElement('div');
                    const lineBreak = document.createElement('br');
                    textContainer.style.margin = '0';
                    textContainer.style.padding = '0';
                    textContainer.className = chart.data.datasets[itemIndex]
                      .hidden
                      ? 'hidden-data'
                      : 'visible-data';

                    const meLabelIndex = this.props.isSelfOnly ? 0 : 1;
                    const text = document.createTextNode(
                      itemIndex !== meLabelIndex ? item.text[0] : item.text
                    );
                    const text2 = document.createTextNode(item.text[1] || '');
                    textContainer.appendChild(text);

                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    if (itemIndex !== meLabelIndex) {
                      textContainer.appendChild(lineBreak);
                      textContainer.appendChild(text2);
                    }
                    ul?.appendChild(li);
                  }
                });
              }
            },
          },
          {
            id: 'backgroundColor',
            beforeDraw: chart => {
              const ictx = chart.canvas.getContext('2d');
              if (ictx) {
                ictx.save();
                ictx.globalCompositeOperation = 'destination-over';
                ictx.fillStyle = CHART_COLORS.white;
                ictx.fillRect(0, 0, chart.width, chart.height);
                ictx.restore();
              }
            },
          },
          {
            id: 'verticalLine',
            afterDraw: (chart: any) => {
              if (chart.tooltip?._active?.length) {
                const { x } = chart.tooltip._active[0].element;
                const yAxis = chart.scales.y;
                const ictx = chart.ctx;
                if (ictx) {
                  ictx.save();
                  ictx.beginPath();
                  ictx.moveTo(x, yAxis.top);
                  ictx.lineTo(x, yAxis.bottom);
                  ictx.lineWidth = 1;
                  ictx.strokeStyle = CHART_COLORS.dark_gray;
                  ictx.setLineDash([5, 5]);
                  ictx.stroke();
                  ictx.restore();
                }
              } else if (chart?.canvas?.parentNode?.querySelector('div')) {
                chart?.canvas?.parentNode?.querySelector('div')?.remove();
              }
            },
          },
        ],
        type: 'line',
        data: builtData,
        options: {
          animation: {
            onComplete: () => {
              download
                ? this.downloadChartImage(
                    lineChartConfig?.toBase64Image() || ''
                  )
                : '';
            },
          },
          layout: {
            padding: download
              ? 20
              : {
                  bottom: 20,
                },
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
          },
          responsive: download ? true : true,
          maintainAspectRatio: download ? false : false,
          plugins: {
            title: {
              display: download ? true : false,
              text: this.props.chartTitle,
              align: 'start',
              color: CHART_COLORS.purple,
              font: { family: 'RobotoBold' },
            },
            subtitle: {
              display: download ? true : false,
              text: this.props.chartSubTitle,
              align: 'start',
              color: CHART_COLORS.purple,
              font: { family: 'RobotoLight' },
              padding: { bottom: 10 },
            },
            tooltip: {
              mode: 'index',
              enabled: isMobile ? false : true,
              external: isMobile ? (this.externalTooltipHandler as any) : false,
              callbacks: {
                title: toolTipItem => {
                  const dayNumber =
                    (this.state.currentDateBlock - 1) * blockLength +
                    1 +
                    (toolTipItem[0].dataIndex -
                      (this.state.currentDateBlock === 1
                        ? 0
                        : this.state.currentDateBlock - 1));
                  const dayToDate = moment(this.props.data.startDate).add(
                    dayNumber - 1,
                    'days'
                  );
                  const date = formatDate(dayToDate);
                  return frequency.type === PulseFrequency.DAILY
                    ? i18next.t<string>('Day {{dayNumber}} - {{date}}', {
                        dayNumber: dayNumber,
                        date: date,
                      })
                    : frequency.count === 1
                    ? i18next.t<string>(
                        'Week {{weekNumber}} - {{fromDate}} - {{toDate}}',
                        {
                          weekNumber:
                            Math.round((dayNumber - 1) / WEEK_BLOCK) + 1,
                          fromDate: date,
                          toDate: formatDate(dayToDate.add(6, 'days')),
                        }
                      )
                    : i18next.t<string>(
                        'Month {{monthNumber}} - {{fromDate}} - {{toDate}}',
                        {
                          monthNumber:
                            Math.round((dayNumber - 1) / FOUR_WEEK_BLOCK) + 1,
                          fromDate: date,
                          toDate: formatDate(dayToDate.add(27, 'days')),
                        }
                      );
                },
                afterBody: tooltipItems => {
                  const { data } = this.props;
                  const dayIndex =
                    (this.state.currentDateBlock - 1) * blockLength +
                    (tooltipItems[0].dataIndex -
                      (this.state.currentDateBlock - 1));
                  const containsRater = tooltipItems
                    .map(item => item.datasetIndex)
                    .some(datasetIndex => datasetIndex > 1);
                  const containsAvg = tooltipItems
                    .map(item => item.datasetIndex)
                    .some(datasetIndex => datasetIndex === 0);
                  const frequencyPulse = `${this.labelText(
                    'frequencyAverage'
                  )}${i18next.t<string>(' (N={{count}}) = ', {
                    count: data.dailyRaterCount[dayIndex] || 0,
                  })}${data.dailyAverages[dayIndex]?.toFixed(2)}`;
                  const frequencyRange = `${this.labelText(
                    'frequencyRange'
                  )}${data.dailyRanges[dayIndex].toFixed(2)}`;
                  const frequencyAvg = `${this.labelText('dataPointAvg')}${
                    this.props.isSelfOnly
                      ? data.selfData[dayIndex]
                      : data.frequencyAverages[dayIndex]
                  }`;
                  if (containsRater && !containsAvg) {
                    return [frequencyPulse, frequencyRange];
                  }
                  if (containsRater && containsAvg) {
                    return [frequencyPulse, frequencyRange, frequencyAvg];
                  }
                  if (!containsRater && containsAvg) {
                    return [frequencyAvg];
                  }
                  return '';
                },
                label: labelItem => {
                  return labelItem.datasetIndex === 1
                    ? labelItem.dataset.label + ' = ' + labelItem.raw
                    : '';
                },
              },
              intersect: false,
              backgroundColor: CHART_COLORS.white,
              bodyColor: CHART_COLORS.darkest_grey,
              titleColor: CHART_COLORS.darkest_grey,
              borderColor: CHART_COLORS.darkest_grey,
              borderWidth: 1,
              cornerRadius: 0,
              displayColors: false,
            },
            legend: {
              title: {
                padding: isMobile ? 0 : 5,
                position: 'center',
                text: ' ',
                display: true,
              },
              fullSize: true,
              labels: {
                font: {
                  size: isMobile ? 9 : 10,
                },
                textAlign: 'center',
                usePointStyle: true,
                padding: 10,
                boxPadding: 5,
                boxHeight: isMobile ? 6 : 5,
                boxWidth: 8,
                generateLabels: chart => {
                  return chart.data.datasets.map((data, index) => {
                    const isHidden = chart.data.datasets[index].hidden;
                    const iconGreen = isHidden
                      ? CHART_COLORS_TRANS.light_green
                      : CHART_COLORS.green;
                    const iconDarkBlue = isHidden
                      ? CHART_COLORS_TRANS.dark_blue
                      : CHART_COLORS.dark_blue;
                    const iconLightBlue = isHidden
                      ? CHART_COLORS_TRANS.light_blue
                      : CHART_COLORS.blue;
                    const fontColor = isHidden
                      ? CHART_COLORS.dark_gray
                      : CHART_COLORS.darkest_grey;
                    return {
                      lineDashOffset: 0,
                      pointStyle: index === 2 ? 'circle' : 'line',
                      lineDash: index === 0 && !isSelfOnly ? [3, 1.5] : [0, 0],
                      backgroundColor:
                        index === 1
                          ? CHART_COLORS_TRANS.transparent
                          : CHART_COLORS.darkest_grey,
                      lineWidth: index === 2 ? 0 : 3,
                      strokeStyle:
                        index === 1 || isSelfOnly ? iconGreen : iconDarkBlue,
                      text: data.label || '',
                      fontColor: fontColor,
                      fillStyle: iconLightBlue,
                      datasetIndex: index,
                    };
                  });
                },
                sort: (a, b) => {
                  return a.datasetIndex === 2 && b.datasetIndex === 1 ? 1 : -1;
                },
                filter: item => item.datasetIndex < 3,
                color: CHART_COLORS.darkest_grey,
              },
              display: download ? true : false,
              position: 'bottom',
              onClick: function(_e, legendItem) {
                const index = legendItem.datasetIndex;
                const ci = this.chart;
                const isHidden = ci.data.datasets[index].hidden;
                if (index === 2) {
                  for (let i = 2; i < ci.data.datasets.length; i++) {
                    ci.data.datasets[i].hidden = isHidden ? false : true;
                  }
                } else {
                  ci.data.datasets[index].hidden = isHidden ? false : true;
                }
                ci.update();
              },
            },
          },
          scales: {
            y: {
              grid: {
                color: CHART_COLORS.dark_gray,
              },
              display: true,
              min: 1,
              max: 5,
              ticks: {
                display: true,
                stepSize: 1,
              },
            },
            x: {
              grid: {
                color: ({ index, scale: { ticks } }) => {
                  return isTickPosition(index, frequency) ||
                    index === 0 ||
                    index === ticks.length - 1
                    ? CHART_COLORS.light_gray
                    : CHART_COLORS_TRANS.transparent;
                },
                drawBorder: false,
                drawTicks: true,
                drawOnChartArea: true,
              },
              ticks: {
                font: {
                  size: 9,
                },
                callback: function(tick, index, array) {
                  return isTickPosition(index, frequency) ||
                    index === 0 ||
                    index === array.length - 1
                    ? this.getLabelForValue(tick as number).indexOf('\n\n') !==
                      -1
                      ? [
                          this.getLabelForValue(tick as number).split(
                            '\n\n'
                          )[0],
                          this.getLabelForValue(tick as number).split(
                            '\n\n'
                          )[1],
                        ]
                      : this.getLabelForValue(tick as number)
                    : '';
                },

                labelOffset: -6,
                display: true,
                maxRotation: 90,
                minRotation: 90,
                stepSize: 7,
                autoSkip: false,
                color: CHART_COLORS.darkest_grey,
              },
              display: true,
              min: 0,
            },
          },
        },
      });
    if (download) {
      this.lineChartDownload = lineChartConfig;
    } else {
      this.lineChart = lineChartConfig;
    }
  }

  public componentDidMount() {
    this.buildChart(false);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      this.props.data.dataBlockCount !== prevProps.data.dataBlockCount ||
      this.props.isMobile !== prevProps.isMobile ||
      this.props.frequency !== prevProps.frequency
    ) {
      this.setState(
        {
          currentDateBlock: this.props.data.dataBlockCount,
          blockLength:
            findBlockLength(this.props.isMobile, this.props.frequency) || 0,
        },
        () => this.buildChart(false)
      );
    }
  }

  public render() {
    const {
      data,
      chartTitle,
      chartSubTitle,
      descriptionText,
      isMobile,
      frequency,
    } = this.props;
    const { showDescription } = this.state;
    const blockCount = data.dataBlockCount;
    const downloadChartWidth =
      this.getTrimmedLength() / tickWidth(frequency) > 7
        ? this.getTrimmedLength() * 30
        : 340;
    const truncateTextLength = isMobile ? 30 : 75;
    return (
      <div>
        <div className="title-and-download-button">
          <div className="titles-div">
            {renderTruncatedTag('h3', chartTitle, truncateTextLength)}
            <h3 className="light">
              {renderTruncatedTag('span', chartSubTitle, truncateTextLength)}
            </h3>
          </div>
          <div className="button-div">
            <button
              className="btn btn-small btn-primary download-image-btn"
              title={i18next.t<string>('Download chart image')}
              onClick={() => {
                this.buildChart(true);
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
            <div
              id="download-chart"
              className="download-chart-wrapper"
              style={{ width: downloadChartWidth + 'px' }}
            >
              <canvas
                id="subBehaviourDownloadChart"
                ref={this.downloadChartRef}
                className="downloadChart"
              ></canvas>
            </div>
          </div>
        </div>

        <div className="chart-wrapper">
          <canvas
            id="subBehaviourChart"
            ref={this.chartRef}
            className="chart"
          ></canvas>
        </div>
        <div
          id="chart-legend"
          className="chart-legend"
          ref={this.chartLegend}
        ></div>
        {blockCount > 1 && (
          <div className="chart-navigation">
            <div className="nav-wrapper">
              <button
                className="btn btn-small btn-nav btn-primary btn-icon-round back"
                onClick={() => this.changeDataBlock(true)}
                disabled={this.state.currentDateBlock < 2}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button
                className="btn btn-small btn-nav btn-primary btn-icon-round pull-right next"
                onClick={() => this.changeDataBlock(false)}
                disabled={this.state.currentDateBlock >= blockCount}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        )}
        <div className="description-wrapper">
          <div
            className="clickable description-link"
            onClick={() => this.showHideDescription()}
          >
            {i18next.t<string>('Description')}
            <FontAwesomeIcon
              icon={showDescription ? faChevronUp : faChevronDown}
              size="xs"
              className="navigation-chevron"
            />
          </div>
          <div
            className={classNames(
              !showDescription ? 'hide' : 'show',
              'description-text-box'
            )}
          >
            <p>{descriptionText}</p>
          </div>
        </div>
      </div>
    );
  }
}

const tickWidth = (frequency: Frequency) => {
  return frequency.type === PulseFrequency.DAILY
    ? WEEK_BLOCK
    : frequency.count === 1
    ? FOUR_WEEK_BLOCK
    : QUARTER_BLOCK;
};

const isTickPosition = (index: number, frequency: Frequency) => {
  return index % tickWidth(frequency) === 0;
};

export default BehaviourChart;
