import {
  faCheck,
  faEdit,
  faSpinnerThird,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { KeyboardEvent } from 'react';

import { ResponseComponentProps } from '../response-hoc/types';
import { EditableProps } from './types';

const CLASS_NAME_CONTROLS = 'editable-controls';
const CLASS_NAME_CONTROL = 'editable-control';

interface OwnProps {
  onCancel: () => void;
  onAccept: () => void;
}

export type Props = Pick<
  EditableProps & ResponseComponentProps,
  'loading' | 'editing'
> &
  OwnProps & {
    onKeyDownEdit?: (event: KeyboardEvent<HTMLAnchorElement>) => void;
    onKeyDownCancel: (event: KeyboardEvent<HTMLAnchorElement>) => void;
    onKeyDownAccept: (event: KeyboardEvent<HTMLAnchorElement>) => void;
  };

const Controls = (props: Props) => {
  if (props.loading) {
    return (
      <div className={CLASS_NAME_CONTROLS}>
        <a tabIndex={0} className={CLASS_NAME_CONTROL}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </a>
      </div>
    );
  }

  if (props.editing) {
    return (
      <div className={CLASS_NAME_CONTROLS}>
        <a
          tabIndex={0}
          className={CLASS_NAME_CONTROL}
          onClick={props.onAccept}
          onKeyDown={props.onKeyDownAccept}
        >
          <FontAwesomeIcon icon={faCheck} />
        </a>
        <a
          tabIndex={0}
          className={CLASS_NAME_CONTROL}
          onClick={props.onCancel}
          onKeyDown={props.onKeyDownCancel}
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
      </div>
    );
  }

  return (
    <div className={CLASS_NAME_CONTROLS}>
      <a
        tabIndex={0}
        className={CLASS_NAME_CONTROL}
        onKeyDown={props.onKeyDownEdit}
      >
        <FontAwesomeIcon icon={faEdit} />
      </a>
    </div>
  );
};

export { Controls };

export default Controls;
