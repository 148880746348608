import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { administerOrganisations, can } from '^/capabilities';
import { AnalyticsProduct } from '^/reducers/api/types';
import { StoreState } from '^/store';
import CreateEditProfileDetailsForm, {
  CreateEditProfileDetailsFormProps,
} from './CreateEditProfileDetailsForm';

interface OwnProps {
  disabled?: boolean;
  onSubmit: (data: CreateEditProfileDetailsFormProps) => void;
}

interface StateProps {
  user: Map<string, any>;
  product: AnalyticsProduct | undefined;
  createResponse: Map<string, any>;
}

export type Props = OwnProps & StateProps;

export class CreateProfileDetails extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { user, product, disabled, createResponse, onSubmit } = this.props;

    return (
      <div className="details-form">
        <CreateEditProfileDetailsForm
          response={createResponse}
          userFullName={user.get('full_name')}
          initialValues={{
            created_by: user.get('id'),
            product: product?.id,
            organisation: user.getIn(['organisation', 'id']),
          }}
          disabled={disabled}
          onSubmit={onSubmit}
          canChooseOrg={can(user, administerOrganisations())}
        />
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
    product: state.analyticsFilters.product,
    createResponse: state.responses.get('createAdvancedAnalyticsFilterProfile'),
  };
}

export default connect(mapStateToProps, {})(CreateProfileDetails);
