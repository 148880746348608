export const MOBILE_SCREEN_WIDTH = 900;
export const MOBILE_DATA_BLOCK_LENGTH = 15; // 15 days of data visible on mobile/daily
export const WEB_DATA_BLOCK_LENGTH = 85; // 85 days of data visible on web/daily
export const MOBILE_DATA_BLOCK_LENGTH_MONTH = 57; // 57 days of data shown for mobile/monthly
export const WEB_DATA_BLOCK_LENGTH_MONTH = 337; // 337 days of data shown for web/monthly
export const MOBILE_DATA_BLOCK_LENGTH_4_MONTH = 169; // 169 days of data shown for mobile/4 monthly
export const WEB_DATA_BLOCK_LENGTH_4_MONTH = 1009; // 1009 days of data shown for web/4 monthly
export const WEEK_BLOCK = 7;
export const FOUR_WEEK_BLOCK = 28;
export const QUARTER_BLOCK = 84;

export const SUB_BEHAVIOUR_LIMIT = 3;

export const CHART_COLORS = {
  green: 'rgb(112, 173, 71)',
  blue: 'rgba(127, 215, 247, 0.70)',
  dark_blue: 'rgb(0, 148, 200)',
  darkest_grey: '#2c2c2e',
  dark_gray: '#818389',
  light_gray: '#c9ccd6',
  white: '#ffffff',
  purple: '#672786',
};

export const CHART_COLORS_TRANS = {
  light_blue: 'rgba(218, 245, 255, 0.75)',
  dark_blue: 'rgba(0, 148, 200, 0.75)',
  light_green: 'rgba(112, 173, 71, 0.25)',
  transparent: 'rgba(255, 255, 255, 0.1)',
};

export const DEFAULT_PULSE_TIME = '07:00';

export const STRIPE_DISABLED = true;

export enum PulseFrequencyChoices {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const PULSE_FREQUENCY_CHOICES_DISPLAY = {
  [PulseFrequencyChoices.DAILY]: 'Daily',
  [PulseFrequencyChoices.WEEKLY]: 'Weekly',
  [PulseFrequencyChoices.MONTHLY]: 'Monthly (4 weeks)',
};

export const PULSE_NOTIFICATION_TIMEOUT_IN_SECONDS = 4;
