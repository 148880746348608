import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';

import { LanguageCode } from '^/constants/routes';
import { LanguageKeyedStrings } from '^/reducers/api/types';
import { StoreState } from '^/store';
import { getTextDirection } from '^/utils-ts';
import InputLanguageSelector from '../buttons/InputLanguageSelector';
import FormError from '../FormError';
import LiveButton from '../LiveButton';
import PureComponent from '../PureComponent';

interface OwnProps {
  response: Map<string, any>;
  onSubmit: (data: InviteUserFormProps) => void;
  onClose: () => void;
  isReinvite?: boolean;
  userName: string;
  buttonText: string;
  change: (field: string, value: string) => void;
}

export interface InviteUserFormProps {
  opening: string;
  closing: string;
}

interface StateProps {
  inputTextLanguage: LanguageCode;
  defaultOpenings: LanguageKeyedStrings | undefined;
}

type Props = OwnProps & InviteUserFormProps & StateProps;

export class InviteUserFormSegment extends PureComponent<Props> {
  componentDidMount() {
    this.updateOpening();
  }

  componentDidUpdate(prevProps: Props) {
    const { inputTextLanguage, defaultOpenings, opening } = this.props;
    if (
      prevProps.inputTextLanguage !== inputTextLanguage &&
      defaultOpenings &&
      opening === defaultOpenings[prevProps.inputTextLanguage]
    ) {
      this.updateOpening();
    }
  }

  private updateOpening() {
    const { defaultOpenings, inputTextLanguage } = this.props;
    if (defaultOpenings) {
      this.props.change('opening', defaultOpenings[inputTextLanguage]);
    }
  }

  public render() {
    const {
      response,
      userName,
      inputTextLanguage,
      defaultOpenings,
    } = this.props;

    return (
      <div className="form-block">
        <InputLanguageSelector
          languages={
            defaultOpenings && (Object.keys(defaultOpenings) as LanguageCode[])
          }
        />
        <div>
          <label htmlFor="create-user-dear-field">
            {i18next.t<string>('Opening message')}
          </label>
          <div className="form-item stacked">
            <input
              className="create-user-dear-field"
              id="create-user-dear-field"
              type="text"
              placeholder={i18next.t<string>('Dear {{name}},', {
                name: userName || '',
                lng: inputTextLanguage,
              })}
              disabled
              {...getTextDirection(inputTextLanguage)}
            />
            <Field
              as="textarea"
              id="opening-message-textarea"
              name="opening"
              {...getTextDirection(inputTextLanguage)}
            />
            <FormError response={response} formKey="opening" />
          </div>
        </div>

        <div>
          <label>{i18next.t<string>('Automatically generated message')}</label>
          <div className="form-item" {...getTextDirection(inputTextLanguage)}>
            <blockquote>
              <p>
                {i18next.t<string>(
                  'Please click the link to redeem your invite.',
                  { lng: inputTextLanguage }
                )}
              </p>
              <p>
                <a>
                  {i18next.t<string>('Redeem link', { lng: inputTextLanguage })}
                </a>
              </p>
            </blockquote>
          </div>
        </div>

        <div>
          <label htmlFor="closing-message-textarea">
            {i18next.t<string>('Closing message')}
          </label>
          <div className="form-item">
            <Field
              as="textarea"
              id="closing-message-textarea"
              name="closing"
              {...getTextDirection(inputTextLanguage)}
            />
            <FormError response={response} formKey="closing" />
          </div>
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              type="button"
              onClick={this.props.onClose}
            >
              {i18next.t<string>('Cancel')}
            </button>
            <LiveButton
              buttonText={this.props.buttonText}
              response={response}
              pendingMessage={i18next.t<string>('Sending data...')}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    inputTextLanguage: state.ui.get('inputTextLanguage'),
    defaultOpenings: state.inviteDefaults.opening_message,
  };
}

export default connect(mapStateToProps)(InviteUserFormSegment);
