import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import _ from 'underscore';
import i18next from 'i18next';

import {
  sortList,
  setFilter,
  clearFilters,
  setSearch,
  getToken,
} from '^/actions/actions';
import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import { THREE_SIXTY_RATER } from '^/middleware/reports';
import SearchBar from '^/components/Searchbar';
import ListPage from '^/components/ListPage';
import UserNameAndEmail from '^/components/UserNameAndEmail';
import PureComponent from '^/components/PureComponent';

export class CompletionSummaryTable extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getToken();
    this.props.clearFilters();
    this.props.setSearch(null);
    this.props.sortList('raters', {});
  }

  getAllRaterTypes() {
    const { activity, user } = this.props;
    const respondents = user
      ? activity
          .get('users')
          .filter(
            respondent => respondent.getIn(['user', 'id']) === user.get('id')
          )
      : activity.get('users');

    const respondentRaterTypes = respondents.map(respondent =>
      List.of({
        respondent,
        role: Map({ code: 'SELF', name: i18next.t('Self') }),
      }).concat(
        respondent
          .get('raters', List())
          .groupBy(rater => rater.getIn(['role', 'code']))
          .map(raters => ({
            respondent,
            role: raters.getIn([0, 'role']),
            raters,
          }))
      )
    );
    const raterTypes = respondentRaterTypes.flatten(true);
    return raterTypes;
  }

  applyOrderingTo(rows, field) {
    switch (field) {
      case 'respondent':
        return rows.sortBy(row => row.respondent.getIn(['user', 'full_name']));
      case 'role':
        return rows.sortBy(row => (row.role ? row.role.get('code') : ''));
      default:
        return rows;
    }
  }

  sort(rows) {
    const { ordering } = this.props;
    const sortedRows = this.applyOrderingTo(rows, ordering.field);
    return ordering.reversed ? sortedRows.reverse() : sortedRows;
  }

  applyFilterTo(rows, field, value) {
    switch (field) {
      case 'respondent':
        return rows.filter(
          row => row.respondent.getIn(['user', 'full_name']) === value
        );
      case 'role':
        if (value === 'LINE_MANAGER') {
          return rows.filter(row => !row.role);
        }
        return rows.filter(row => row.role && row.role.get('code') === value);
      default:
        return rows;
    }
  }

  filter(rows) {
    const { filterSpec } = this.props;
    return _.chain(filterSpec)
      .pairs()
      .reduce(
        (memo, [field, value]) => this.applyFilterTo(rows, field, value),
        rows
      )
      .value();
  }

  applySearchTo(rows, searchString) {
    return rows.filter(row => {
      const rater = row.rater && row.rater.get('rater'),
        respondent = row.respondent.get('user');

      const searchStringLower = searchString.toLowerCase();

      const matchesSearchString = user =>
        _.any([user.get('full_name'), user.get('email')], text =>
          text.toLowerCase().includes(searchStringLower)
        );

      return _.any(_.filter([rater, respondent]), matchesSearchString);
    });
  }

  search(rows) {
    const { currentSearch } = this.props;
    return currentSearch ? this.applySearchTo(rows, currentSearch) : rows;
  }

  getRatersCompleted(raters) {
    return `${raters
      .filter(each => each.get('completed'))
      .count()}/${raters.count()}`;
  }

  renderRaterRow(row, headers) {
    const { user } = this.props;
    const { respondent, role, raters } = row;
    const respondentUser = respondent.get('user');

    return (
      <tr key={`${respondentUser.get('id')}.${role && role.get('code')}`}>
        {!user && (
          <td data-header={headers[0].title}>
            <UserNameAndEmail user={respondentUser} />
          </td>
        )}
        <td data-header={headers[0 + (headers.length % 2)].title}>
          {role ? role.get('name') : i18next.t('Line Manager')}
        </td>
        <td data-header={headers[1 + (headers.length % 2)].title}>
          {this.getRatersCompleted(raters)}
        </td>
      </tr>
    );
  }

  renderRespondentRow(row, headers) {
    const { user } = this.props;
    const { respondent } = row;
    const respondentUser = respondent.get('user');

    return (
      <tr key={respondentUser.get('id')}>
        {!user && (
          <td data-header={headers[0].title}>
            <UserNameAndEmail user={respondentUser} />
          </td>
        )}
        <td data-header={headers[0 + (headers.length % 2)].title}>
          {i18next.t('Self')}
        </td>
        <td data-header={headers[1 + (headers.length % 2)].title}>
          {respondent.get('completed') ? '1' : '0'}/1
        </td>
      </tr>
    );
  }

  renderRow(row, headers) {
    return row.raters
      ? this.renderRaterRow(row, headers)
      : this.renderRespondentRow(row, headers);
  }

  setOrdering(field) {
    const { ordering } = this.props;
    const shouldReverse = field === ordering.field;

    this.props.sortList('raters', {
      field,
      reversed: shouldReverse ? !ordering.reversed : ordering.reversed,
    });
  }

  getFilters() {
    const raterRoles = this.getAllRaterTypes().map(({ role }) => role);
    const uniqueRaterRoles = raterRoles
      .groupBy(role => role && role.get('code'))
      .map(group => group.first())
      .toList();

    const makeFilter = (key, values) => ({
      name: key,
      key,
      values: { values },
    });
    const filters = [
      makeFilter(
        'role',
        uniqueRaterRoles.map(each =>
          Map({
            id: each ? each.get('code') : 'LINE_MANAGER',
            name: each ? each.get('name') : i18next.t('Line Manager'),
          })
        )
      ),
    ];

    const { filterSpec, user } = this.props;

    if (!user) {
      const respondents = this.getAllRaterTypes().map(
        ({ respondent }) => respondent
      );
      filters.unshift(
        makeFilter(
          'respondent',
          respondents
            .map(each =>
              Map({
                id: each.getIn(['user', 'full_name']),
                name: each.getIn(['user', 'full_name']),
              })
            )
            .toSet()
            .sort((a, b) => a.get('name').localeCompare(b.get('name')))
        )
      );
    }

    return filters.map(each =>
      Object.assign({}, each, { currentValue: filterSpec[each.key] })
    );
  }

  onOpenExport() {
    const { shortlivedToken, activity, filterSpec } = this.props;
    this.props.getToken();
    this.props.closeModalAndTryViewExport(shortlivedToken, THREE_SIXTY_RATER, {
      activity: activity.get('id'),
      respondent: filterSpec.respondent,
      role: filterSpec.role,
    });
  }

  getHeaders() {
    const { user } = this.props;

    let baseHeaders = [
      { title: i18next.t('Rater category'), field: 'role', sortable: true },
      { title: i18next.t('No. Raters completed') },
    ];
    if (!user) {
      baseHeaders.unshift({
        title: i18next.t('Respondent'),
        field: 'respondent',
        sortable: true,
      });
    }
    return baseHeaders;
  }

  render() {
    const { user } = this.props;
    const headers = this.getHeaders();

    return (
      <div>
        {!user && (
          <a className="pull-right" onClick={() => this.onOpenExport()}>
            {i18next.t('Download CSV')}
          </a>
        )}
        <ListPage
          collapse="md"
          headers={headers}
          renderer={row => this.renderRow(row, headers)}
          items={this.sort(this.search(this.filter(this.getAllRaterTypes())))}
          typeNamePlural="raters"
          ordering={this.props.ordering}
          onOrderingChange={field => this.setOrdering(field)}
          filters={this.getFilters()}
          onFilterChange={this.props.setFilter}
          isFiltered={!!this.props.filterSpec}
        >
          {!user && (
            <SearchBar
              onChange={evt => this.props.setSearch(evt.target.value)}
            />
          )}
        </ListPage>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    ordering: state.sortOrders.get('raters', {}),
    filterSpec: state.ui.get('filterSpec'),
    currentSearch: state.ui.get('currentSearch'),
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  sortList,
  setFilter,
  clearFilters,
  setSearch,
  getToken,
  closeModalAndTryViewExport,
})(CompletionSummaryTable);
