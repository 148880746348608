import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, List } from 'immutable';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'underscore';
import i18next from 'i18next';

import { fromQueryString, toQueryString, COMPARATOR_SUFFIXES } from '^/utils';
import { can, administerOrganisations } from '^/capabilities';
import { setFilters, setPageSize, PAGE_SIZE_OPTIONS } from '^/actions/actions';
import {
  getPulsesList,
  getAllOrganisationOptions,
  getAllPulseBehaviourHierarchyOptions,
  clearCollection,
} from '^/actions/collections';
import PulseIconCircle from '^/components/PulseIconCircle';
import PureComponent from '^/components/PureComponent';
import ControlBar from '^/components/ControlBar';
import ListPage from '^/components/ListPage';
import ListPagePagination from '^/components/ListPagePagination';
import PulseRow from './PulseRow';
import { withRouter } from '^/withRouter';

const COMPARATORS = {
  start_datetime: COMPARATOR_SUFFIXES,
  end_datetime: COMPARATOR_SUFFIXES,
};

const HEADERS = [
  {
    title: i18next.t('Account'),
    field: 'organisation',
    sortable: true,
    capability: administerOrganisations,
  },
  {
    title: i18next.t('Pulse name'),
    field: 'name',
    sortable: true,
  },
  {
    title: i18next.t('Pulse'),
    field: 'pulse_behaviour_hierarchy',
    sortable: true,
  },
  {
    title: i18next.t('Started'),
    field: 'created',
    sortable: true,
  },
  {
    title: i18next.t('Frequency'),
    field: 'frequency',
    sortable: false,
  },
];

export class PulseListPage extends PureComponent {
  constructor(props) {
    super(props);
    const { noLoad, user, collectionName } = props;
    const { filterSpec } = props;
    if (!noLoad) {
      props.clearCollection(collectionName);
      props.getPulsesList('', 1, filterSpec);
      if (can(user, administerOrganisations())) {
        props.getAllOrganisationOptions({ pulse__isnull: false });
      }
      props.getAllPulseBehaviourHierarchyOptions();
    }
    this.limitedSearch = debounce(
      searchString => this.getPulses(searchString),
      500
    );
  }

  getPulses(searchString) {
    const { pageSize } = this.props;
    this.props.getPulsesList(searchString, 1, this.props.filterSpec, pageSize);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.filterSpec, this.props.filterSpec) ||
      !_.isEqual(prevProps.pageSize, this.props.pageSize)
    ) {
      this.props.getPulsesList(
        prevProps.pulses.get('searchString', ''),
        1,
        this.props.filterSpec,
        this.props.pageSize
      );
    }
  }

  loadPage(page, pageSize) {
    this.props.getPulsesList(
      this.props.pulses.get('searchString', ''),
      page,
      this.props.filterSpec,
      pageSize || this.props.pageSize,
      this.props.pulses.get('ordering', '')
    );
  }

  onFilterChange(key, value, comparator = '') {
    const { filterSpec, filtersInState } = this.props;
    let newFilterSpec = Object.assign({}, filterSpec);
    if (COMPARATORS[key]) {
      _.each(
        COMPARATORS[key],
        eachComparator => delete newFilterSpec[key + eachComparator]
      );
    }
    if (value === null) {
      delete newFilterSpec[key + comparator];
    } else {
      newFilterSpec = Object.assign(newFilterSpec, {
        [key + comparator]: value,
      });
    }

    if (filtersInState) {
      this.props.setFilters(newFilterSpec);
    } else {
      this.props.push(`/page/pulses/view/${toQueryString(newFilterSpec)}`);
    }
  }

  onSearchChange(_field, event) {
    this.limitedSearch(event.target.value);
  }

  getFilters() {
    const {
      filterSpec,
      user,
      organisationOptions,
      pulseBehaviourHierarchyOptions,
    } = this.props;

    let filters = [
      {
        name: i18next.t('Pulse'),
        key: 'pulse_behaviour_hierarchy',
        currentValue: filterSpec['pulse_behaviour_hierarchy'],
        values: { values: pulseBehaviourHierarchyOptions },
      },
    ];

    if (can(user, administerOrganisations())) {
      filters.push({
        name: i18next.t('Account'),
        key: 'organisation',
        currentValue: filterSpec['organisation'],
        values: { values: organisationOptions },
      });
    }

    return filters;
  }

  getOrdering() {
    const ordering = this.props.pulses.get('ordering', '');
    return {
      field: ordering.replace(/^-/, ''),
      reversed: ordering[0] === '-',
    };
  }

  setOrdering(newOrdering) {
    const { pulses, pageSize } = this.props;
    const reverseOrdering = newOrdering === pulses.get('ordering', '');

    this.props.getPulsesList(
      pulses.get('searchString'),
      1,
      pulses.get('filters'),
      pageSize,
      reverseOrdering ? `-${newOrdering}` : newOrdering
    );
  }

  renderRow(pulse) {
    const { getRowProps, user } = this.props;
    return (
      <PulseRow
        key={pulse.get('id')}
        pulse={pulse}
        user={user}
        {...(getRowProps ? getRowProps(pulse) : null)}
      />
    );
  }

  getHeaders() {
    const { isSelectModal, selectOne, user } = this.props;

    const headers = HEADERS.filter(
      each => !each.capability || can(user, each.capability())
    ).map(each => (each.title ? each : { title: each }));

    if (!isSelectModal) {
      return headers;
    }
    return headers
      .filter(each => each.title !== 'Action')
      .concat(selectOne ? [] : [{ title: 'Add', className: '' }]);
  }

  render() {
    const {
      pulses,
      hideAdd,
      response,
      isSelectModal,
      filterSpec,
      pageSize,
    } = this.props;
    const items = pulses.get('items');
    const isFiltered = pulses.get('searchString') || !_.isEmpty(filterSpec);
    const filters = this.getFilters();

    return (
      <div>
        <ControlBar
          createButtonText={i18next.t('pulse')}
          title={i18next.t('Behavioural pulsing™')}
          getItems={this.getPulses.bind(this)}
          onAddClick={() => this.props.push(`/page/pulses/create`)}
          hideAdd={hideAdd}
          hideSearch
        >
          <PulseIconCircle />
        </ControlBar>
        <ListPage
          items={items}
          headers={this.getHeaders()}
          tableClassName="activity-list-table highlight-rows"
          typeNamePlural="pulses"
          isSelectModal={isSelectModal}
          response={response}
          renderer={this.renderRow.bind(this)}
          filters={filters}
          onFilterChange={this.onFilterChange.bind(this)}
          searchHeaders="name"
          onSearchChange={this.onSearchChange.bind(this)}
          isFiltered={isFiltered}
          ordering={this.getOrdering()}
          onOrderingChange={ordering => this.setOrdering(ordering)}
        >
          <ListPagePagination
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            collection={pulses}
            onSelectPage={page => this.loadPage(page)}
            onChangePageSize={this.props.setPageSize}
          />
        </ListPage>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  let filterSpec;
  if (props.filtersInState) {
    const uiSpec = state.ui.get('filterSpec');
    const pulsesCollection = state.collections.get(
      props.collectionName || 'pulses'
    );
    filterSpec = _.isEmpty(uiSpec) ? pulsesCollection.get('filters') : uiSpec;
  } else {
    filterSpec = fromQueryString(props.router.params['filter']);
  }
  const collectionName = props.collectionName || 'pulses';

  return {
    user: state.userProfile,
    filterSpec,
    collectionName,
    pulses: state.collections.get(collectionName, Map()),
    organisationOptions: state.collections.getIn(
      ['organisationOptions', 'items'],
      List()
    ),
    pulseBehaviourHierarchyOptions: state.collections.getIn(
      ['pulseBehaviourHierarchyOptions', 'items'],
      List()
    ),
    response: state.multiResponses.getIn(['getCollection', collectionName]),
    pageSize: props.pageSize || state.ui.get('pageSize'),
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps, {
    getPulsesList: ownProps.getPulsesList || dispatchProps.getPulsesList,
  });

const dispatchProps = {
  getPulsesList,
  getAllOrganisationOptions,
  getAllPulseBehaviourHierarchyOptions,
  push,
  setFilters,
  setPageSize,
  clearCollection,
};

export default withRouter(
  connect(mapStateToProps, dispatchProps, mergeProps)(PulseListPage)
);
