import i18next from 'i18next';
import { Map } from 'immutable';
import { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import {
  clearOrgFilters,
  clearPoll,
  filterHiddenReportsTableSelection,
  getAllOrgs,
  getMyGeneratingReports,
  resetGeneratingReports,
} from '^/actions/actions';
import {
  loadOrgData,
  setFormStageAndShowGenerationProgress,
} from '^/actions/actionSequences';
import {
  clearReportFilterSelection,
  setReportFilterDate,
  setReportFilterSelection,
  clearReportsInitialOrganisation,
} from '^/actions/ui';
import {
  administerOrganisations,
  administerOwnOrganisation,
} from '^/capabilities';
import CollapsibleSidebar from '^/components/CollapsibleSidebar';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import ProgressBarFake from '^/components/ProgressBarFake';
import {
  selectReportFilterDate,
  selectReportTableIsLoading,
} from '^/components/reports/admin/AdminReportsPage/selectors';
import ReportsActionBar from '^/components/reports/admin/ReportsActionBar';
import ReportsTable from '^/components/reports/admin/ReportsTable';
import {
  selectAnySelectionReportsTable,
  selectFilteredOrgUsers,
  selectSessionsByUser,
  UserSessions,
} from '^/components/reports/admin/selectors';
import { createdByUser } from '^/models/report';
import { AllOrgs, OrgSessions, OrgUser, Uuid } from '^/reducers/api/types';
import { selectAllOrgs, selectUserProfile } from '^/selectors';
import { selectReportsInitialOrganisation } from '^/selectors/ui';
import { selectUserCapability, selectUserOrgId } from '^/selectors/user';
import { StoreState } from '^/store';
import { isTruthy } from '^/utils';
import ReportsFilters from './ReportsFilters';

interface OwnProps {
  switcher?: React.ReactNode;
}

interface DispatchProps {
  getAllOrgs: typeof getAllOrgs;
  getMyGeneratingReports: typeof getMyGeneratingReports;
  loadOrgData: (
    orgId: string,
    user: Map<string, any>,
    name: string,
    rate: number
  ) => any;
  clearReportFilterSelection: typeof clearReportFilterSelection;
  setReportFilterSelection: typeof setReportFilterSelection;
  setReportFilterDate: typeof setReportFilterDate;
  clearPoll: typeof clearPoll;
  setFormStageAndShowGenerationProgress: typeof setFormStageAndShowGenerationProgress;
  clearOrgFilters: typeof clearOrgFilters;
  clearReportsInitialOrganisation: typeof clearReportsInitialOrganisation;
  filterHiddenReportsTableSelection: typeof filterHiddenReportsTableSelection;
  resetGeneratingReports: typeof resetGeneratingReports;
}

interface StateProps {
  isLoadingTable: boolean;
  user: Immutable.Map<string, any>;
  users: ReadonlyArray<OrgUser>;
  sessionsByUser: UserSessions;
  organisations: AllOrgs;
  orgSessions: OrgSessions | null;
  hasAnySelection: boolean;
  selectedDate: Readonly<{
    from?: Date;
    to?: Date;
  }>;
  reportsInitiallyGenerating: Immutable.List<any>;
  formStage: string | null;
  userOrgId?: Uuid;
  initialOrganisation: Uuid | null;
  canUserAdministerOwnOrganisation: boolean;
  canUserAdministerOrganisations: boolean;
}

export type Props = StateProps & DispatchProps & OwnProps;

interface State {
  orgId: Uuid | null;
}

export const POLLING_RATE = 10000;

export class AdminReportsPage extends React.Component<Props, State> {
  public readonly state = {
    orgId: null,
  };

  public componentDidMount() {
    const { userOrgId, initialOrganisation } = this.props;
    this.props.resetGeneratingReports();
    this.props.getMyGeneratingReports(new Date().toISOString());
    if (this.props.canUserAdministerOwnOrganisation && userOrgId) {
      if (initialOrganisation) {
        this.loadOrganisation(initialOrganisation).then(() => {
          // Hack fix: the reducer for this.props.sessionsByUser is not updated quick enough
          // calling this.props.filterHiddenReportsTableSelection in componentDidUpdate breaks select all buttons
          setTimeout(() => {
            this.props.filterHiddenReportsTableSelection(
              this.props.users
                .flatMap(user => this.props.sessionsByUser[user.id])
                .filter(isTruthy)
                .map(each => each.id)
            );
          }, 100);
        });
      } else {
        this.loadOrganisation(userOrgId);
      }
    } else {
      this.props.getAllOrgs();
      if (initialOrganisation) {
        this.loadOrganisation(initialOrganisation).then(() => {
          // Hack fix: the reducer for this.props.sessionsByUser is not updated quick enough
          // calling this.props.filterHiddenReportsTableSelection in componentDidUpdate breaks select all buttons
          setTimeout(() => {
            this.props.filterHiddenReportsTableSelection(
              this.props.users
                .flatMap(user => this.props.sessionsByUser[user.id])
                .filter(isTruthy)
                .map(each => each.id)
            );
          }, 100);
        });
      }
    }
  }

  public componentWillUnmount() {
    this.props.clearPoll('AdminReportsPage');
    this.props.clearPoll('GenerationProgress');
    this.props.clearReportsInitialOrganisation();
    this.props.clearOrgFilters();
    this.props.clearReportFilterSelection();
  }

  public componentDidUpdate() {
    const { reportsInitiallyGenerating, formStage } = this.props;
    const myReportsInitiallyGenerating = reportsInitiallyGenerating.filter(
      createdByUser(this.props.user)
    );
    const showOverlay = !myReportsInitiallyGenerating.isEmpty();
    if (!formStage && showOverlay) {
      this.props.setFormStageAndShowGenerationProgress();
    }
  }

  public render() {
    const {
      organisations,
      selectedDate,
      orgSessions,
      hasAnySelection,
      canUserAdministerOrganisations,
      isLoadingTable,
      initialOrganisation,
      switcher,
    } = this.props;
    const { orgId } = this.state;

    return (
      <div className="report-table-and-filters-wrapper">
        <CollapsibleSidebar>
          <ReportsFilters
            canUserAdministerOrganisations={canUserAdministerOrganisations}
            organisations={organisations}
            onOrganisationClick={this.onOrganisationClick}
            isLoadingTable={isLoadingTable}
            initialOrganisation={initialOrganisation}
            clearActivityGroupSelection={this.clearActivityGroupSelection}
            orgId={orgId}
            selectedDate={selectedDate}
            setDate={this.setDate}
          />
        </CollapsibleSidebar>

        <div className="report-type-switcher">{switcher}</div>

        <div className="report-table-wrapper">
          {isLoadingTable && (
            <div className="row col-md-offset-4 col-md-4 loading-container">
              <span className="col-md-offset-3">
                {i18next.t<string>('Loading reports...')}
              </span>
              <ProgressBarFake className="tall small-top-margin" />
            </div>
          )}

          {!isLoadingTable && orgId && orgSessions && (
            <div>
              <ReportsTable />

              <ReportsActionBar
                visible={hasAnySelection}
                organisation={orgSessions}
                orgId={orgId!}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  private setDate = (fieldName: string, date?: Moment) => {
    this.props.setReportFilterDate({
      [fieldName]: date ? date.toDate() : null,
    });
  };

  private clearActivityGroupSelection = () => {
    this.props.setReportFilterSelection({ activities: {}, groups: {} });
  };

  private onOrganisationClick = (orgId: DropdownOption['id']) => {
    this.props.clearReportFilterSelection();
    this.loadOrganisation(
      typeof orgId !== 'string' && orgId !== null ? `${orgId}` : orgId
    );
  };

  private loadOrganisation = (orgId: Uuid | null) => {
    const { user } = this.props;
    if (orgId) {
      this.setState({ orgId });

      return this.props.loadOrgData(
        orgId,
        user,
        'AdminReportsPage',
        POLLING_RATE
      );
    }
    return Promise.reject();
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    isLoadingTable: selectReportTableIsLoading(state),
    orgSessions: state.orgSessions,
    user: selectUserProfile(state),
    users: selectFilteredOrgUsers(state),
    sessionsByUser: selectSessionsByUser(state),
    userOrgId: selectUserOrgId(state),
    canUserAdministerOwnOrganisation: selectUserCapability(
      state,
      administerOwnOrganisation()
    ),
    canUserAdministerOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
    hasAnySelection: selectAnySelectionReportsTable(state),
    organisations: selectAllOrgs(state),
    selectedDate: selectReportFilterDate(state),
    initialOrganisation: selectReportsInitialOrganisation(state),
    reportsInitiallyGenerating: state.ui.get('reportsInitiallyGenerating'),
    formStage: state.ui.getIn(['formStages', 'reportGenerationFlow'], null),
  };
}

export default connect(mapStateToProps, {
  getAllOrgs,
  getMyGeneratingReports,
  loadOrgData,
  clearReportFilterSelection,
  clearOrgFilters,
  setReportFilterSelection,
  setReportFilterDate,
  clearPoll,
  setFormStageAndShowGenerationProgress,
  clearReportsInitialOrganisation,
  filterHiddenReportsTableSelection,
  resetGeneratingReports,
})(AdminReportsPage);
