/* eslint-disable @typescript-eslint/no-use-before-define */
import i18next from 'i18next';
import React from 'react';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';

import { openDataAnalyticsOverlayModal } from '^/actions/modals';
import {
  AnalyticsAppliedFilters,
  AnalyticsProduct,
  HeatmapRow,
} from '^/reducers/api/types';
import { sum } from '^/utils';
import { Column } from './DataAnalyticsTableStructure';
import { ColumnGroup } from './DataAnalyticsTable';
import DataAnalyticsTd from './DataAnalyticsTd';
import HeatmapRowActionsMenu from './HeatmapRowActionsMenu';
import { isLighter, getSummaryName, rowExists } from './utils';

const INDENT_PX = 20;

interface DispatchProps {
  openDataAnalyticsOverlayModal: typeof openDataAnalyticsOverlayModal;
}

interface OwnProps {
  row: HeatmapRow;
  indices: ReadonlyArray<number>;
  parentFilters?: AnalyticsAppliedFilters;
  columnGroups: ReadonlyArray<ColumnGroup> | null;
  user: Immutable.Map<string, any>;
  parentRowCount: number;
  product: AnalyticsProduct | undefined;
  inModal: boolean;
}

type Props = OwnProps & DispatchProps;

export const DataAnalyticsHeatmapRow: React.FunctionComponent<Props> = props => {
  const {
    row,
    columnGroups,
    user,
    indices,
    parentFilters,
    parentRowCount,
    product,
    inModal,
  } = props;
  const { filtersState, children, loading } = row;
  const summaryName = getSummaryName(row);
  const isSubrow = indices.length > 1;
  const displayRow = !isSubrow || (isSubrow && rowExists(row, parentRowCount));
  return (
    <>
      {!loading ? (
        displayRow && (
          <>
            <tr>
              <td
                className={classNames('heatmap-table-name-column', {
                  'sub-row': isSubrow,
                })}
                style={{ paddingLeft: (indices.length - 1) * INDENT_PX }}
              >
                <ReactTooltip
                  id={summaryName}
                  className="tooltip"
                  multiline
                  textColor="black"
                  backgroundColor="white"
                  border
                  borderColor="#C9CCD6"
                >
                  {summaryName}
                  <br />
                  {i18next.t<string>('click to view data set')}
                </ReactTooltip>
                <div className="heatmap-row display-flex justify-content-space-between">
                  {isSubrow && <span className="elbow" />}
                  <div
                    data-tip="custom"
                    data-for={summaryName}
                    className="heatmap-row-name"
                  >
                    <a
                      onClick={() =>
                        props.openDataAnalyticsOverlayModal(
                          i18next.t<string>('View data set'),
                          {
                            ...row,
                            appliedFilters: {
                              ...parentFilters,
                              ...row.appliedFilters,
                            },
                          },
                          false,
                          inModal
                        )
                      }
                    >
                      {summaryName}
                    </a>
                  </div>
                  <div className="heatmap-row-total">
                    <span>
                      N={filtersState.sessions?.count}
                      {isSubrow &&
                      filtersState.sessions?.count &&
                      parentRowCount
                        ? ` (${Math.round(
                            (filtersState.sessions?.count / parentRowCount) *
                              100
                          )}%)`
                        : ''}
                    </span>
                  </div>
                  <HeatmapRowActionsMenu
                    row={row}
                    indices={indices}
                    parentFilters={parentFilters}
                    user={user}
                    inModal={inModal}
                  />
                </div>
              </td>
              {columnGroups?.map((columnGroup, columnGroupIndex) =>
                columnGroup.columns.map(
                  (column, columnIndex) =>
                    filtersState.product && (
                      <DataAnalyticsTd
                        product={filtersState.product}
                        count={1}
                        aggregate={filtersState.sessions?.aggregate}
                        column={column}
                        className={getClassName(
                          columnGroup,
                          columnGroupIndex,
                          column,
                          columnIndex
                        )}
                      />
                    )
                )
              )}
            </tr>
            {children.map((childRow, idx) => (
              <DataAnalyticsHeatmapRow
                key={idx}
                {...props}
                indices={[...indices, idx]}
                row={childRow}
                parentFilters={{ ...parentFilters, ...row.appliedFilters }}
                parentRowCount={row.filtersState.sessions?.count || 0}
              />
            ))}
          </>
        )
      ) : (
        <tr className={classNames({ loading })}>
          <td
            className={classNames('heatmap-table-name-column', {
              'sub-row': isSubrow,
            })}
            style={{ paddingLeft: (indices.length - 1) * INDENT_PX }}
          >
            <div className="display-flex justify-content-space-between">
              <span className="elbow" />
              <div
                data-tip="custom"
                data-for={summaryName}
                className="heatmap-row-name"
              >
                <span>{summaryName}</span>
              </div>
              <div className="heatmap-row-total" />
              <div className="inline-menu" />
            </div>
          </td>
          <td colSpan={sum(columnGroups?.map(({ columns }) => columns.length))}>
            <Skeleton />
          </td>
        </tr>
      )}
    </>
  );

  function getClassName(
    columnGroup: ColumnGroup,
    columnGroupIndex: number,
    column: Column,
    columnIndex: number
  ) {
    const visible = isVisible(columnGroup, column);
    return classNames(
      visible ? 'show' : 'hide',
      'heatmap-data-td box-shadow-borders',
      {
        'left-border box-shadow-borders':
          (columnGroupIndex > 0 && visible && columnIndex === 0) ||
          isLighter(product?.activity_type, columnGroupIndex, columnIndex) ||
          (product?.type === 'PSYCAP_POTENTIAL' && columnIndex === 2) ||
          (columnGroupIndex > 0 && columnIndex === 0),
        lighter:
          (isLighter(product?.activity_type, columnGroupIndex, columnIndex) &&
            columnIndex !== 0) ||
          (product?.type === 'PSYCAP_POTENTIAL' && columnIndex === 2),
      }
    );
  }

  function isVisible(columnGroup: ColumnGroup, column: Column) {
    return columnGroup.isExpanded || !column.isHideable(user);
  }
};

export default connect(null, {
  openDataAnalyticsOverlayModal,
})(DataAnalyticsHeatmapRow);
