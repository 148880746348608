import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import * as responseStates from '../../responseStates';
import Categories from './Categories';
import PureComponent from '../PureComponent';
import ProfileFormWrapper from './ProfileFormWrapper';
import { HELP_EMAIL } from '^/settings';
import ControlBar from '^/components/ControlBar';
import { updateProfile } from '../../actions/actions';
import Alert, { AlertType } from '^/components/Alert';

export class ProfilePage extends PureComponent {
  onSubmit(data) {
    delete data['accept_tcs'];
    this.props.updateProfile(this.props.user.get('id'), data);
  }

  successMessage() {
    return (
      <Alert type={AlertType.success}>
        {i18next.t('Thank you for updating your information')}
      </Alert>
    );
  }

  render() {
    const categories = this.props.user.get('categories');

    return (
      <div>
        <ControlBar title={i18next.t('My profile')} hideAdd hideSearch />

        <div className="form-container">
          {this.props.response.get('state') === responseStates.SUCCESSFUL &&
            this.successMessage()}
          <ProfileFormWrapper
            onSubmit={this.onSubmit.bind(this)}
            response={this.props.response}
          />

          {categories && !categories.isEmpty() && (
            <p>{i18next.t('Categories')}</p>
          )}

          <Categories categories={categories} />

          {categories && !categories.isEmpty() && (
            <p>
              <span>
                {i18next.t('If this information is incorrect, please contact ')}
              </span>
              <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>.
            </p>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    response: state.responses.get('updateProfile'),
  };
}

export default connect(mapStateToProps, {
  updateProfile,
})(ProfilePage);
