import { AnyAction } from 'redux';
import _ from 'underscore';

import {
  SET_MODAL_CLOSABLE,
  SET_MODAL_ON_CLOSE_EVENT,
  SET_MODAL_TITLE,
} from '^/actions/actions';
import {
  GET_ANALYTICS_FILTERS,
  GET_ANALYTICS_FILTERS_HEATMAP,
} from '^/actions/analytics';
import {
  CLOSE_ALL_MODALS,
  CLOSE_MODAL,
  CLOSE_TOP_MODAL,
  OPEN_ANOTHER_MODAL,
  OPEN_MODAL,
  OPEN_MODAL_AND_CLOSE_ALL_OTHERS,
} from '^/actions/modals';
import {
  CLEAR_REPORT_FILTER_SELECTION,
  CLEAR_REPORTS_INITIAL_ORGANISATION,
  ReportFilter,
  ReportFilterSelections,
  SEARCH_REPORT_FILTER_USERS,
  SET_REPORT_FILTER_DATE,
  SET_REPORT_FILTER_SELECTION,
  SET_REPORTS_INITIAL_ORGANISATION,
  TOGGLE_REPORT_FILTER_SELECTION,
  TOGGLE_REPORT_FILTER_SELECTIONS,
  SET_UI_LANGUAGE,
  SET_ACTIVE_LANGUAGE,
  SET_INPUT_TEXT_LANGUAGE,
  SET_LANGUAGE_SWITCHER_INTERACTED_WITH,
} from '^/actions/ui';
import { ModalsConfig } from '^/components/modals/types';
import { DEFAULT_LANGUAGE_CODE } from '^/constants/routes';
import { Uuid } from '^/reducers/api/types';

export function reportsInitialOrganisation(
  state: Uuid | null = null,
  action: AnyAction
): Uuid | null {
  switch (action.type) {
    case SET_REPORTS_INITIAL_ORGANISATION: {
      return action.payload;
    }
    case CLEAR_REPORTS_INITIAL_ORGANISATION: {
      return null;
    }
    default: {
      return state;
    }
  }
}

export const defaultReportFilterSelections = {
  activities: {},
  groups: {},
  products: {},
  searchString: null,
  date: {},
};

export function reportFilterSelection(
  state: ReportFilterSelections = defaultReportFilterSelections,
  action: AnyAction
): ReportFilterSelections {
  switch (action.type) {
    case TOGGLE_REPORT_FILTER_SELECTION: {
      const keys = Object.keys(action.payload) as ReadonlyArray<ReportFilter>;
      return {
        ...state,
        ...keys.reduce((memo, key) => {
          const newState = state[key][action.payload[key]] ? false : true;
          return {
            ...memo,
            [key]: {
              ...state[key],
              [action.payload[key]]: newState,
            },
          };
        }, {}),
      };
    }
    case TOGGLE_REPORT_FILTER_SELECTIONS: {
      const keys = Object.keys(action.payload) as ReadonlyArray<ReportFilter>;
      return {
        ...state,
        ...keys.reduce(
          (memo, key) => ({
            ...memo,
            [key]: (action.payload[key] as ReadonlyArray<Uuid>).reduce(
              (stateMemo, reportsKey) => ({
                ...stateMemo,
                [reportsKey]: action.meta.toggleTo,
              }),
              state[key]
            ),
          }),
          {}
        ),
      };
    }
    case SET_REPORT_FILTER_DATE: {
      return {
        ...state,
        date: {
          ...state.date,
          ...action.payload,
        },
      };
    }
    case SET_REPORT_FILTER_SELECTION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLEAR_REPORT_FILTER_SELECTION: {
      return defaultReportFilterSelections;
    }
    case SEARCH_REPORT_FILTER_USERS: {
      return {
        ...state,
        searchString: action.payload,
      };
    }
    default:
      return state;
  }
}

const INITIAL_MODALS: ModalsConfig = [];
export function modalsConfig(state = INITIAL_MODALS, action: AnyAction) {
  switch (action.type) {
    case OPEN_MODAL:
      return [..._.initial(state), action.payload];
    case OPEN_ANOTHER_MODAL:
      return [...state, action.payload];
    case OPEN_MODAL_AND_CLOSE_ALL_OTHERS:
      return [action.payload];
    case CLOSE_MODAL:
      if (typeof action.payload === 'number') {
        return [
          ..._.without(state, _.findWhere(state, { id: action.payload })),
        ];
      }
      return state;
    case CLOSE_TOP_MODAL:
      return _.initial(state);
    case CLOSE_ALL_MODALS:
      return INITIAL_MODALS;
    case SET_MODAL_CLOSABLE:
      return [
        ..._.initial(state),
        { ..._.last(state), closable: action.payload },
      ];
    case SET_MODAL_TITLE:
      return [..._.initial(state), { ..._.last(state), title: action.payload }];
    case SET_MODAL_ON_CLOSE_EVENT:
      return [
        ..._.initial(state),
        { ..._.last(state), onClose: action.payload },
      ];
    case GET_ANALYTICS_FILTERS.SUCCESS:
    case GET_ANALYTICS_FILTERS_HEATMAP.SUCCESS:
      return state.map(each =>
        _.isEqual(
          (each.props as any)?.aggregate?.appliedFilters,
          action.meta.params
        )
          ? {
              ...each,
              props: {
                ...each.props,
                aggregate: {
                  ...(each.props as any).aggregate,
                  filtersState: action.payload,
                },
              },
            }
          : _.isEqual(
              {
                ...(each.props as any)?.parentFilters,
                ...(each.props as any)?.row?.appliedFilters,
              },
              action.meta.params
            )
          ? {
              ...each,
              props: {
                ...each.props,
                row: {
                  ...(each.props as any).row,
                  filtersState: action.payload,
                },
              },
            }
          : each
      );
    default:
      return state;
  }
}

export const uiLanguage = (
  state = DEFAULT_LANGUAGE_CODE,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_UI_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export const activeLanguage = (
  state = DEFAULT_LANGUAGE_CODE,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_ACTIVE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export const inputTextLanguage = (
  state = DEFAULT_LANGUAGE_CODE,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_INPUT_TEXT_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export const languageSwitcherInteractedWith = (
  state = false,
  action: AnyAction
) => {
  switch (action.type) {
    case SET_LANGUAGE_SWITCHER_INTERACTED_WITH:
      return action.payload;
    default:
      return state;
  }
};
