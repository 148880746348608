/* eslint-disable react/no-string-refs */
import React from 'react'; // eslint-disable-line no-unused-vars
import Dropzone from 'react-dropzone';
import i18next from 'i18next';

import PureComponent from './PureComponent';

export default class FileUpload extends PureComponent {
  onOpenClick() {
    this.refs.dropzone.open();
  }

  onDrop(files) {
    if (files.length) {
      this.props.setPendingUpload(files[0]);
    }
  }

  render() {
    const { value, accept } = this.props;
    const src = value && (value.name || value);

    return (
      <div className="form-item wrapping">
        <div className="flex-list added-list">
          <Dropzone
            style={{ width: 'auto', height: 'auto' }}
            accept={accept}
            ref="dropzone"
            multiple={false}
            disableClick
            onDrop={this.onDrop.bind(this)}
          >
            {src && (
              <div className="tag">
                <div className="tag-name">{src}</div>
              </div>
            )}
          </Dropzone>
        </div>
        <button
          className="btn btn-default btn-small"
          type="button"
          onClick={() => this.onOpenClick()}
        >
          {src ? i18next.t('Select a new file') : i18next.t('Select a file')}
        </button>
      </div>
    );
  }
}
