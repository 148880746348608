import { List } from 'immutable';
import { createSelector } from 'reselect';

import { selectGetCollection } from '^/selectors';
import { StoreState } from '^/store';

export const OLDEST_DATE = '2017-01-01T00:00:00';

const selectCreditHistoryCollection = (state: StoreState) =>
  selectGetCollection(state, 'creditHistory');

export const selectFirstTransactionDate = createSelector(
  selectCreditHistoryCollection,
  creditHistory => {
    if (creditHistory && !creditHistory.get('items', List()).isEmpty()) {
      return creditHistory
        .get('items')
        .sortBy((log: Immutable.Map<string, any>) =>
          log.get('first_log_created')
        )
        .first()
        .get('first_log_created');
    }
    return new Date(OLDEST_DATE);
  }
);
