import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import i18next from 'i18next';

import { propTypes as nominationRulesPropTypes } from '^/nominationRules';
import { removeLineManagerFromActivity } from '^/actions/actions';
import { setLineManagerAndGetMyActivities } from '^/actions/actionSequences';
import {
  openModal,
  closeTopModal,
  openConfirmationModal,
} from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import UserNameAndEmail from '^/components/UserNameAndEmail';
import BaseAddRaterRow from '^/components/dashboard/enduser/manage_raters/BaseAddRaterRow';

export class AddLineManager extends PureComponent {
  confirmSetLineManager(userId, roleId, email) {
    const activity = this.props.activity;
    const approvalRequired = activity.getIn([
      'activity360',
      'line_manager_must_approve_raters',
    ]);
    let friendlyMessage = '';

    if (approvalRequired) {
      friendlyMessage = i18next.t('PARAGRAPH thank you line manager');
    } else {
      friendlyMessage = i18next.t(
        'Please check the email address and click confirm.'
      );
    }

    this.props.openConfirmationModal(
      i18next.t('confirm line manager'),
      null,
      [friendlyMessage, email || ''],
      () => {
        this.props.setLineManagerAndGetMyActivities(
          this.props.activity.get('id'),
          userId
        );
        this.props.closeTopModal();
      },
      () => this.props.closeTopModal(),
      'btn-primary'
    );
  }

  removeLineManager(rater, activity) {
    this.props.removeLineManagerFromActivity(
      activity.get('id'),
      rater.get('id'),
      this.props.id
    );
  }

  renderLineManagerError() {
    if (
      !this.props.lineManagerResponse ||
      !this.props.lineManagerResponse.getIn(['errors', 'email']) ||
      !this.props.lineManagerResponse.get('state') ||
      'line_manager' !== this.props.lineManagerResponse.get('source')
    ) {
      return null;
    }
    return (
      <p className="text-error">
        {i18next.t('This user already exists with another organisation.')}
      </p>
    );
  }

  renderWithoutLineManager() {
    return (
      <BaseAddRaterRow
        {...this.props}
        activityId={this.props.activity.get('id')}
        addRater={this.confirmSetLineManager.bind(this)}
        overrideRoleWith="Line Manager"
        userError={this.renderLineManagerError()}
        isLineManager
      />
    );
  }

  renderAction(lineManager) {
    const ratersFirstSubmitted = this.props.activity.get('raters_submitted');

    if (ratersFirstSubmitted) {
      return null;
    }
    return (
      <a
        className="underlined"
        onClick={() => this.removeLineManager(lineManager, this.props.activity)}
      >
        {i18next.t('Remove')}
      </a>
    );
  }

  renderWithLineManager(lineManager) {
    const { columns } = this.props;

    return (
      <tr>
        <td data-header={columns[0].header}>{i18next.t('Line Manager')}</td>
        <td data-header={columns[1].header}>
          <UserNameAndEmail
            user={lineManager.get('rater')}
            action={this.renderAction(lineManager)}
            inline
          />
        </td>
        <td data-header={columns[2].header} />
      </tr>
    );
  }

  render() {
    const lineManagers = this.props.raters.filter(
      rater => rater.get('role') === null
    );

    if (lineManagers.count()) {
      return this.renderWithLineManager(lineManagers.first());
    }
    return this.renderWithoutLineManager();
  }
}

AddLineManager.propTypes = {
  id: PropTypes.string.isRequired,
  rules: ImmutablePropTypes.listOf(nominationRulesPropTypes.NominationRule)
    .isRequired,
  activity: ImmutablePropTypes.map.isRequired,
  organisationId: PropTypes.string.isRequired,
  excludedUserIds: ImmutablePropTypes.list.isRequired,
  raters: ImmutablePropTypes.list.isRequired,
};

export function mapStateToProps(state) {
  return {
    lineManagerResponse: state.responses.get('addRaterUser'),
  };
}

export default connect(
  () => {
    return {};
  },
  {
    openModal,
    closeTopModal,
    removeLineManagerFromActivity,
    setLineManagerAndGetMyActivities,
    openConfirmationModal,
  }
)(AddLineManager);
