import React from 'react';
import { connect } from 'react-redux';
import {
  faUserCircle,
  faQuestionCircle,
  faStar,
  faRocket,
  faThunderstorm,
} from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loadActivity } from '^/actions/items';
import { closeTopModal, openWelcomeModal } from '^/actions/modals';
import { resetLanguageAndCloseTopModal } from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import WelcomePage from './WelcomePage';

const SECTIONS = [
  {
    icon: <FontAwesomeIcon icon={faQuestionCircle} />,
    header: (
      <Trans i18nKey="Section 1a POTENTIAL">
        {'Section 1a: '}
        <strong>POTENTIAL (timed: up to 30 minutes)</strong>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Section 1a POTENTIAL text">
        This section is designed to assess your ability to understand
        relationships between abstract shapes and patterns. It is timed and you
        will have up to 30 minutes to complete this section.
      </Trans>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    header: (
      <Trans i18nKey="Section 1b POTENTIAL">
        {'Section 1b: '}
        <strong>POTENTIAL (untimed, approximately 20 minutes)</strong>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Section 1b POTENTIAL text">
        You are asked questions about yourself and how you relate to different
        areas of your work life.
      </Trans>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={faStar} />,
    header: (
      <Trans i18nKey="Section 2 COMPETENCIES">
        {'Section 2: '}
        <strong>COMPETENCIES (untimed, approximately 20 minutes)</strong>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Section 2 COMPETENCIES text">
        This section is focussed on assessing Competencies, by understanding
        your knowledge, skills and experience against a number of different
        areas.
      </Trans>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={faRocket} />,
    header: (
      <Trans i18nKey="Section 3 MOTIVATORS">
        {'Section 3: '}
        <strong>MOTIVATORS (untimed, approximately 10 minutes)</strong>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Section 3 MOTIVATORS text">
        Values and preferences are assessed to determine the key factors that
        drive your commitment and performance in the workplace.
      </Trans>
    ),
  },
  {
    icon: <FontAwesomeIcon icon={faThunderstorm} />,
    header: (
      <Trans i18nKey="Section 4 DERAILERS">
        {'Section 4: '}
        <strong>DERAILERS (untimed, approximately 10 minutes)</strong>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Section 4 DERAILERS text">
        This section assesses your self beliefs against key areas researched to
        impact on the way we lead and operate in the workplace.
      </Trans>
    ),
  },
];

export class PerspectivesWelcomeModal extends PureComponent {
  render() {
    const {
      productVersionDetails,
      productVersion,
      activity,
      raterFor,
      user,
      uiLanguage,
    } = this.props;
    const welcomeMessageTitle = productVersion.get('welcome_message_title');

    if (!productVersionDetails) {
      return <Loading />;
    }

    return (
      <WelcomePage
        welcomeMessageTitle={welcomeMessageTitle}
        sections={SECTIONS}
        sectionAlertText={i18next.t('Perspectives section alert text')}
        onCancel={() => this.props.resetLanguageAndCloseTopModal(uiLanguage)}
        onContinue={this.props.openWelcomeModal.bind(
          null,
          productVersion,
          activity,
          raterFor,
          productVersionDetails.getIn(['questioncollection_set', 0])
        )}
        productVersion={productVersion}
        user={user}
        respondent={user}
        isSelfRating={!raterFor}
        continueLabel={i18next.t('Next')}
        hideSectionName
        useProductVersionCompletionTime
      />
    );
  }
}

export function mapStateToProps(state, props) {
  const activityDetails = state.items.get('activities');
  return {
    user: state.userProfile,
    productVersionDetails:
      activityDetails &&
      activityDetails
        .get('product_versions', List())
        .find(
          productVersion =>
            productVersion.get('id') === props.productVersion.get('id')
        ),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  loadActivity,
  closeTopModal,
  openWelcomeModal,
  resetLanguageAndCloseTopModal,
})(PerspectivesWelcomeModal);
