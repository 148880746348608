/* eslint-disable no-undef */
const settingsElement = document.getElementById('djangoSettings');
const settings =
  settingsElement && settingsElement.textContent
    ? JSON.parse(settingsElement.textContent)
    : {};

export const API_PREFIX = '/api';
export const ENABLE_ACTIVITY_TYPES = true;
export const HELP_EMAIL = 'letushelp@peoplewise.co.uk';
export const SUPPORT_EMAIL = 'enablesupport@peoplewise.co.uk';
export const ACCOUNTS_EMAIL = 'accounts@peoplewise.co.uk';
export const ENABLE_ORDERS_EMAIL = 'enableorders@peoplewise.co.uk';
export const PROJECT_OFFICE_EMAIL = 'projectoffice@peoplewise.co.uk';
export const ENABLE_EMAIL = 'enable@peoplewise.co.uk';

export const DEFAULT_IMAGE_SIZE_LIMIT_IN_MEGABYTES =
  settings.DEFAULT_IMAGE_SIZE_LIMIT_IN_MEGABYTES;
export const CONSENT_SERVICE_HOSTNAME = settings.CONSENT_SERVICE_HOSTNAME;
export const CONSENT_SERVICE_SLUG = settings.CONSENT_SERVICE_SLUG;
export const DISABLE_CONSENT_SERVICE = settings.DISABLE_CONSENT_SERVICE;
export const FRONTEND_URL = settings.FRONTEND_URL;
export const DISABLE_AUTO_LOGOUT = settings.DISABLE_AUTO_LOGOUT;
export const ENABLE_STRIPE = settings.ENABLE_STRIPE;
export const NODE_ENV = settings.NODE_ENV;
export const SENTRY_PUBLIC_KEY = settings.SENTRY_PUBLIC_KEY;
