import React, { PureComponent, ReactChild } from 'react';

import * as DragAndDropGroupContextInstance from './DragAndDropGroupContextInstance';
import { DragAndDropGroupContext } from './types';
import { isDragging, isDropping, isTouchEvent } from './utils';

interface OwnProps<Groups extends { [i: string]: ReadonlyArray<Item> }, Item> {
  index: number;
  tag?: 'div' | 'span' | 'li';
  className?: string;
  clearTargetOnLeave?: boolean;
  group: string | undefined;
  draggedGroup: string | undefined;
  draggedIndex: number | undefined;
  droppedGroup: string | undefined;
  droppedIndex: number | undefined;
  children: ReactChild;
  setDropped: DragAndDropGroupContext<Groups, Item>['setDropped'];
  clearDropped: DragAndDropGroupContext<Groups, Item>['clearDropped'];
}

type Props<
  Groups extends { [i: string]: ReadonlyArray<Item> },
  Item
> = OwnProps<Groups, Item>;

class DragTargetWithGroupContext<
  Groups extends { [i: string]: ReadonlyArray<Item> },
  Item
> extends PureComponent<Props<Groups, Item>> {
  private element: HTMLElement | null = null;

  public componentDidMount() {
    window.addEventListener('mousemove', this.onMove);
    window.addEventListener('touchmove', this.onMove);
  }

  public componentWillUnmount() {
    window.removeEventListener('mousemove', this.onMove);
    window.removeEventListener('touchmove', this.onMove);
  }

  public render() {
    const { tag: Tag = 'div', className, children } = this.props;

    return (
      <Tag ref={this.getRef} className={className}>
        {children}
      </Tag>
    );
  }

  private getRef = (element: HTMLElement | null) => {
    this.element = element;
  };

  private onMove = (event: MouseEvent | TouchEvent) => {
    if (typeof this.props.group === 'undefined') {
      throw new Error(
        'DragTarget was rendered outside of the context of a DragAndDropGroup'
      );
    }

    const { clientX, clientY } = isTouchEvent(event) ? event.touches[0] : event;

    const dragging = isDragging(this.props);

    if (this.element && dragging) {
      const { group, index, clearTargetOnLeave } = this.props;

      const box = this.element.getBoundingClientRect();
      const isInside =
        clientX > box.left &&
        clientX < box.left + box.width &&
        clientY > box.top &&
        clientY < box.top + box.height;

      const dropping = isDropping(this.props);

      const isCurrentTarget =
        dragging &&
        dropping &&
        this.props.droppedGroup === group &&
        this.props.droppedIndex === index;

      if (dragging && (!isCurrentTarget || !dropping) && isInside) {
        this.props.setDropped(group, index);
      } else if (isCurrentTarget && !isInside) {
        if (clearTargetOnLeave) {
          this.props.clearDropped();
        }
      }
    }
  };
}

const DragTarget = <Groups extends { [i: string]: ReadonlyArray<Item> }, Item>(
  props: Pick<
    Props<Groups, Item>,
    Exclude<
      keyof Props<Groups, Item>,
      | 'group'
      | 'setDropped'
      | 'clearDropped'
      | 'droppedGroup'
      | 'droppedIndex'
      | 'draggedGroup'
      | 'draggedIndex'
    >
  >
) => (
  <DragAndDropGroupContextInstance.Consumer>
    {context => (
      <DragTargetWithGroupContext
        {...props}
        draggedGroup={context.draggedGroup}
        draggedIndex={context.draggedIndex}
        droppedGroup={context.droppedGroup}
        droppedIndex={context.droppedIndex}
        setDropped={context.setDropped}
        clearDropped={context.clearDropped}
        group={context.group}
      />
    )}
  </DragAndDropGroupContextInstance.Consumer>
);

export default DragTarget;
