import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { loadOrganisation } from '^/actions/items';
import EditableCreditCost from './EditableCreditCost';

export class SystemSettings extends PureComponent {
  render() {
    return (
      <div>
        <h3>{i18next.t('Credit settings')}</h3>

        <EditableCreditCost />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, { loadOrganisation })(SystemSettings);
