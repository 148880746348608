import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '^/components/PureComponent';
import ContentTab from '^/components/ContentTab';
import ContentTabset from '^/components/ContentTabset';

export class Tabset extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { name: Math.random() };
  }

  render() {
    const { children, defaultActiveTab, className } = this.props;
    const { name } = this.state;

    return (
      <ContentTabset
        className={className}
        name={name}
        defaultActiveTab={defaultActiveTab || 0}
      >
        {React.Children.map(children, (child, idx) => (
          <ContentTab
            tabId={idx}
            tabsetName={name}
            tabName={child.props.title}
            tabSubheading={child.props.subheading}
            {...child.props}
          >
            {child.props.children}
          </ContentTab>
        ))}
      </ContentTabset>
    );
  }
}

export const Tab = () => <div />;
