import classnames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  popover: ReactNode;
  children: ReactNode;
  className?: string;
  below?: boolean;
  wide?: boolean;
  inline?: boolean;
}

const WithPopover: React.FunctionComponent<Props> = ({
  popover,
  children,
  className,
  below,
  wide,
  inline,
}) => (
  <div
    className={classnames('popover-wrapper', className, {
      'is-inline': inline,
    })}
  >
    {children}
    {popover && (
      <div className={classnames('popover', { below, wide })}>{popover}</div>
    )}
  </div>
);

export default WithPopover;
