import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import { getAllMyActivities } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import ControlBar from '^/components/ControlBar';
import { formatDateTime } from '^/utils';
import { closeTopModal, openConfirmationModal } from '^/actions/modals';
import { removeRaterFromActivity } from '^/actions/actions';
import { submitRatersThenNavigateTo } from '^/actions/actionSequences';
import { isLineManager } from '^/rater';
import { NominationRulesFulfillment } from '^/nominationRules';
import { APPROVAL_STATUS } from '^/approvalStatus';
import RaterRules from '^/components/dashboard/enduser/manage_raters/RaterRules';
import ManageRatersTable from '^/components/dashboard/enduser/manage_raters/ManageRatersTable';
import { withRouter } from '^/withRouter';
import Alert from '^/components/Alert';

const EMAIL = /([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})/i;
const hasStatus = status => rater =>
  rater.get('approved_by_line_manager') === status;

const StaticText = ({ text }) => (
  <div>
    {text.split('\n').map(line => (
      <span key={line}>
        {line.split(EMAIL).map(part => (
          <span key={part}>
            {EMAIL.test(part) ? <a href={`mailto:${part}`}>{part}</a> : part}
          </span>
        ))}
        <br />
      </span>
    ))}
  </div>
);

export class ManageRatersPage extends PureComponent {
  componentDidMount() {
    if (!this.getActivity()) {
      this.props.getAllMyActivities();
    }
  }

  getActivity() {
    const activities = this.props.activities.get('items', List());

    return activities.find(
      activity => activity.get('id') === this.props.activityId
    );
  }

  renderRaterStatusText(
    line_manager_must_approve_raters,
    overall_nomination_state
  ) {
    if (!line_manager_must_approve_raters) {
      return i18next.t('Raters submitted. They can no longer be edited.');
    }

    return overall_nomination_state === APPROVAL_STATUS.APPROVED
      ? i18next.t('Raters approved. They can no longer be edited.')
      : i18next.t('Raters currently awaiting Line Manager approval.');
  }

  getSubmitRatersConfirmationMessage(activity) {
    const approvalRequired = activity.getIn([
      'activity360',
      'line_manager_must_approve_raters',
    ]);
    const isResubmission = (
      activity.getIn(['self_users', 0, 'raters']) || List()
    ).size;
    const formatEndDateTime = formatDateTime(activity.get('end_datetime'));

    if (approvalRequired) {
      const approvalMessage = i18next.t(
        'PARAGRAPH Your Line Manager will be contacted to approve',
        { formatEndDateTime }
      );

      if (isResubmission) {
        return i18next.t(
          'Thank you for updating your nominations. {{approvalMessage}}',
          { approvalMessage }
        );
      }
      return approvalMessage;
    }
    return i18next.t(
      'Your Line Manager and raters will be asked to complete this review before {{formatEndDateTime}}',
      { formatEndDateTime }
    );
  }

  submitRaters(activity) {
    this.props.openConfirmationModal(
      i18next.t('Submit raters'),
      null,
      [this.getSubmitRatersConfirmationMessage(activity)],
      () => {
        this.props.submitRatersThenNavigateTo(
          activity.get('id'),
          '/page/dashboard'
        );
        this.props.closeTopModal();
      },
      () => this.props.closeTopModal(),
      'btn-primary'
    );
  }

  getTitle(activity) {
    const isOneEighty =
      activity.getIn(['activity360', 'variant']) === 'ONE_EIGHTY';
    const mustSetLineManager =
      isOneEighty &&
      !activity.getIn(['self_users', 0, 'raters'], List()).find(isLineManager);

    return mustSetLineManager
      ? i18next.t('Set line manager')
      : i18next.t('Submit raters');
  }

  getExcludedUserIds(activity) {
    const selfId = this.props.user.get('id');
    return ((activity && activity.getIn(['self_users', 0, 'raters'])) || List())
      .filterNot(rater => rater.get('is_line_manager'))
      .map(rater => rater.getIn(['rater', 'id']))
      .unshift(selfId);
  }

  getNominationText(activity, key) {
    return activity.getIn(['activity360', key]);
  }

  renderRatersTable() {
    const activity = this.getActivity();
    const {
      nomination_rules,
      line_manager_must_approve_raters,
      overall_nomination_state,
    } = (activity.get('activity360') || Map()).toObject();
    const nominationTextBefore = this.getNominationText(
      activity,
      'nomination_text_before'
    );
    const nominationTextAfter = this.getNominationText(
      activity,
      'nomination_text_after'
    );
    const { raters_submitted } = activity.toObject();
    const raters = activity.getIn(['self_users', 0, 'raters'], List());
    const isRevisingRaters =
      raters && !raters.every(hasStatus(APPROVAL_STATUS.AWAITING_APPROVAL));
    const nominationRules =
      (activity && activity.getIn(['activity360', 'nomination_rules'])) ||
      List();

    return (
      <div className="row">
        <div className="col-xs-12">
          {raters_submitted && (
            <div>
              <Alert>
                {this.renderRaterStatusText(
                  line_manager_must_approve_raters,
                  overall_nomination_state
                )}
              </Alert>
              <hr />
            </div>
          )}

          {nominationTextBefore && (
            <div>
              <StaticText text={nominationTextBefore} />
              <hr />
            </div>
          )}

          {activity && <RaterRules rules={nominationRules} />}

          <ManageRatersTable
            raters={raters}
            ratersSubmitted={activity.get('raters_submitted')}
            lineManagerMustApproveRaters={line_manager_must_approve_raters}
            removeRaterFromActivity={this.props.removeRaterFromActivity.bind(
              this,
              activity && activity.get('id')
            )}
            removeRaterResponse={this.props.removeRaterResponse}
            activity={activity}
            organisationId={activity.getIn(['organisation', 'id'])}
            rules={nomination_rules}
            excludedUserIds={this.getExcludedUserIds(activity)}
          />
          {nominationTextAfter && (
            <div>
              <StaticText text={nominationTextAfter} />
              <hr />
            </div>
          )}

          <div>
            {raters_submitted ? (
              <div className="pull-right">
                <button
                  className="btn btn-primary"
                  onClick={() => this.props.push('/page/dashboard')}
                >
                  {i18next.t('Done')}
                </button>
              </div>
            ) : (
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  onClick={() => this.props.push('/page/dashboard')}
                >
                  {i18next.t('Save and return later')}
                </button>

                <button
                  className="btn btn-primary"
                  onClick={() => this.submitRaters(activity)}
                  disabled={new NominationRulesFulfillment(
                    nomination_rules,
                    raters
                  ).needsMore()}
                >
                  {(isRevisingRaters
                    ? i18next.t('Resubmit')
                    : i18next.t('Submit raters')) +
                    (line_manager_must_approve_raters
                      ? ' ' + i18next.t('for approval')
                      : '')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const activity = this.getActivity();

    return (
      <div>
        <ControlBar
          title={
            activity && activity.get('name') + ' > ' + this.getTitle(activity)
          }
          hideAdd
          hideSearch
        />

        {activity ? this.renderRatersTable() : <Loading />}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activityId: props.router.params.activity,
    activities: state.collections.get('myActivities', Map()),
    user: state.userProfile,
    removeRaterResponse: state.responses.get('removeRaterFromActivity'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllMyActivities,
    push,
    closeTopModal,
    removeRaterFromActivity,
    submitRatersThenNavigateTo,
    openConfirmationModal,
  })(ManageRatersPage)
);
