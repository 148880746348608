import React from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';
import i18next from 'i18next';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

import { hasAllAnswers, isPractice } from './questionWithAnswer';
import Countdown from '^/components/Countdown';
import StepProgressBar from '^/components/StepProgressBar';
import { isTruthy } from '^/utils';

function NavItem({ text, isDone, isSelected, onClick, disabled }) {
  const icon = isDone ? <FontAwesomeIcon icon={faCheck} /> : null;
  return (
    <li className={classNames({ 'map-nav-selected': isSelected })}>
      {disabled ? (
        <span className="disabled">
          {text}
          {icon}
        </span>
      ) : (
        <a onClick={onClick}>
          {text}
          {icon}
        </a>
      )}
    </li>
  );
}

export const MAPNav = ({
  questionsWithAnswers,
  currentQuestionId,
  showTimer,
  navigateToQuestion,
  timerMinutes,
  onCountdownComplete,
}) => {
  const practiceQuestions = questionsWithAnswers.filter(isPractice);
  const realQuestions = questionsWithAnswers.filterNot(isPractice);

  const inPractice = practiceQuestions.some(
    qAndA => qAndA.getIn([0, 'id']) === currentQuestionId
  );

  const [label, numberKey, questions, enabled] = inPractice
    ? ['Practice Question', 'questionNumber', practiceQuestions, false]
    : ['Question', 'questionCodeDisplay', realQuestions, showTimer];

  const answers = realQuestions.map(questionAndAnswer =>
    questionAndAnswer.get(1, List())
  );
  const questionProgress = answers.reduce(
    (memo, answer) => ({
      total: memo.total + answer.count(),
      answered: memo.answered + answer.filter(isTruthy).count(),
    }),
    { total: 0, answered: 0 }
  );

  const questionPercentage = Math.floor(
    (questionProgress.answered / questionProgress.total) * 100
  );

  return (
    <div>
      {showTimer && (
        <Countdown
          totalMinutes={timerMinutes}
          onCountdownComplete={onCountdownComplete}
          className="mt-md mb-md"
        />
      )}
      {showTimer && <hr />}

      {!inPractice && (
        <div>
          <div className="percentage mt-sm mb-sm">
            {i18next.t('{{percentCompleted}}% complete', {
              percentCompleted: questionPercentage,
            })}
          </div>
          <StepProgressBar
            steps={questionProgress.total}
            currentStep={questionProgress.answered}
            renderHorizontalRule
          />
        </div>
      )}

      <ol key={label} className="map-nav">
        {questions.map((qAndA, idx) => {
          const question = qAndA.get(0);
          const questionId = question.get('id');

          return (
            <NavItem
              key={idx}
              onClick={() => navigateToQuestion(questionId)}
              questionCodeDisplay
              text={i18next.t(`${label} {{${numberKey}}}`, {
                [numberKey]: idx + 1,
              })}
              isDone={hasAllAnswers(qAndA)}
              isSelected={currentQuestionId === questionId}
              disabled={!enabled}
            />
          );
        })}
      </ol>
    </div>
  );
};

export default MAPNav;
