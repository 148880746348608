import classNames from 'classnames';
import React, { ReactNode, FunctionComponent } from 'react';

interface OwnProps {
  children?: ReactNode;
  large?: boolean;
  hasAsides?: boolean;
}

type Props = OwnProps;

export const AssessmentContainer: FunctionComponent<Props> = ({
  large,
  hasAsides,
  children,
}) => (
  <div
    className={classNames('assessment-container', {
      large,
      'has-asides': hasAsides,
    })}
  >
    {children}
  </div>
);

export default AssessmentContainer;
