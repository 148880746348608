import React, { Key, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Link } from 'react-router';
import moment from 'moment';
import _ from 'underscore';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Column,
  FilterOption,
  FilterOptions,
  FiltersAndOrdering,
  InteractiveTable,
  PaginatedResultsResponse,
} from '../InteractiveTable';
import { ContainerRectangleTile } from '../ContainerRectangleTile';
import PillSearch from '../PillSearch';
import { FORMAT_DATE, deepCopy } from '^/utils';
import { renderTruncatedTag } from '^/components/pulses/utils';
import Alert, { AlertType } from '../Alert';
import PulseBrandStatusMark from '../PulseBrandStatusMark';
import { openModal, closeTopModal } from '^/actions/modals';
import ItemAndLogo from '../ItemAndLogo';
import WithPopover from '../WithPopover';
import { DateSelectAndTypeModal } from '../modals/DateSelectAndTypeModal';
import { fetchWrapper } from '^/api-utils';
import NewButton from '../NewButton';
import { getText } from './ActivityStatusBadge';

interface Props {
  shareFilterValues?: boolean;
  canAdminister: boolean;
  openModal: typeof openModal;
  closeTopModal: typeof closeTopModal;
  showPulse: boolean;
}

const PAGE_SIZE = 20;
const DEBOUNCE_TIME = 600;
const API_DATA_URL_PREFIX = `/api/activities/list/`;
const API_FILTER_URL_PREFIX = `/api/components/tables/activity-list-filter-options/`;

export const ActivitiesPageTable: React.FC<Props> = props => {
  const [rowData, setRowData] = useState<PaginatedResultsResponse<any> | null>(
    null
  );
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const initialFilters = {
    filters: {
      shared: [
        {
          key: Boolean(props.shareFilterValues).toString(),
          label: Boolean(props.shareFilterValues).toString(),
        },
      ],
    } as FilterOptions,
    sorting: { key: 'name', reverse: false },
    page: 1,
  };
  const queryString = window.location.hash.split('?')[1];
  const searchParams = new URLSearchParams(queryString);
  const statusFilter = searchParams.get('status');
  if (statusFilter) {
    initialFilters.filters = {
      ...initialFilters.filters,
      status: [
        {
          key: statusFilter,
          label: statusFilter,
        },
      ],
    };
  }
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    FiltersAndOrdering
  >(initialFilters);

  const [page, setPage] = useState(1);
  const [serverError, setServerError] = useState<string | null>(null);
  const [dataPending, setDataPending] = useState(true);
  const [filtersPending, setFiltersPending] = useState(true);
  const [nextPagePending, setNextPagePending] = useState(false);
  const translateFilterKeys = (
    base: URL,
    keyArray: [string, FilterOption[]] | { key: string }[][]
  ) => {
    const [key, value] = keyArray;
    let translatedKey = key;
    switch (key) {
      case 'accounts':
        translatedKey = 'organisation';
        break;
    }
    return base.searchParams.set(translatedKey.toString(), value[0].key);
  };

  const setDateAndType = (date: Date, type: string, filterKey: string) => {
    const dateSelected = date.toISOString().split('T')[0];
    const keyName = `${filterKey}_${type}`;
    const newFilter = {
      type: keyName,
      date: dateSelected,
      baseDateFilter: filterKey,
    };
    let updateFilter = selectedFilterOptions?.dateFilters;
    if (updateFilter) {
      const filterTypeExists = Boolean(
        updateFilter.filter(item => item.baseDateFilter === filterKey).join()
      );
      if (filterTypeExists) {
        const existingIndex = updateFilter
          .map(item => item.baseDateFilter)
          .indexOf(filterKey);
        updateFilter[existingIndex] = newFilter;
      } else {
        updateFilter.push(newFilter);
      }
    } else {
      updateFilter = new Array(newFilter);
    }

    setPage(1);
    setDataPending(true);
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      dateFilters: updateFilter,
      page: 1,
    });
  };

  const openDateModal = (key: string) => {
    props.openModal({
      className: 'compare-date',
      title:
        key === 'start_datetime'
          ? i18next.t<string>('Start date')
          : i18next.t<string>('Close date'),
      body: (
        <DateSelectAndTypeModal
          confirmButtonLabel={i18next.t<string>('Select')}
          onClose={props.closeTopModal}
          onConfirm={(date: Date, type: string) =>
            setDateAndType(date, type, key)
          }
        />
      ),
    });
  };

  const removeDate = (type: string) => {
    setPage(1);
    setDataPending(true);
    const existingIndex =
      (selectedFilterOptions?.dateFilters &&
        selectedFilterOptions.dateFilters
          .map(item => item.baseDateFilter)
          .indexOf(type)) ||
      0;

    const updateSelectedFilterOptions = selectedFilterOptions;
    if (
      updateSelectedFilterOptions?.dateFilters &&
      updateSelectedFilterOptions.dateFilters[existingIndex]
    ) {
      updateSelectedFilterOptions.dateFilters.splice(existingIndex, 1);
    }
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      dateFilters: updateSelectedFilterOptions?.dateFilters,
      page: 1,
    });
  };

  const buildUrl = (pageNumber: number) => {
    const base = new URL(`${window.location.origin}${API_DATA_URL_PREFIX}`);
    selectedFilterOptions &&
      selectedFilterOptions.filters &&
      Object.entries(selectedFilterOptions.filters).map(item => {
        return (
          item[1][0].key &&
          item[1][0].key !== '' &&
          item[1][0].key !== 'shared' &&
          translateFilterKeys(base, item)
        );
      });
    base.searchParams.set(
      'shared',
      props.shareFilterValues?.toString() || 'false'
    );
    const isReverse = selectedFilterOptions?.sorting.reverse ? '-' : '';
    let translateKey = selectedFilterOptions?.sorting.key;
    switch (selectedFilterOptions?.sorting.key) {
      case 'account':
        translateKey = 'organisation';
        break;
      default:
        translateKey = selectedFilterOptions?.sorting.key;
        break;
    }

    base.searchParams.set('page', pageNumber.toString());
    base.searchParams.set('page_size', PAGE_SIZE.toString());
    base.searchParams.set(
      'ordering',
      selectedFilterOptions?.sorting.key
        ? `${isReverse}${translateKey}`
        : `${isReverse}name`
    );
    selectedFilterOptions?.search &&
      selectedFilterOptions?.search !== '' &&
      selectedFilterOptions?.search !== undefined &&
      base.searchParams.set(
        'search',
        selectedFilterOptions?.search?.toString()
      );
    for (
      let i = 0;
      i <= (selectedFilterOptions?.dateFilters?.length || 0);
      i++
    ) {
      if (
        selectedFilterOptions?.dateFilters &&
        selectedFilterOptions.dateFilters[i]
      ) {
        base.searchParams.set(
          selectedFilterOptions.dateFilters[i].type,
          selectedFilterOptions.dateFilters[i].date
        );
      }
    }
    return `${base.searchParams.toString()}`;
  };

  const fetchFilters = () => {
    const filterUrl = buildUrl(page);
    fetchWrapper
      .get(`${API_FILTER_URL_PREFIX}?${filterUrl}`)
      .then(userData => {
        setFiltersPending(false);
        setFilters(userData);
      })
      .catch(error => {
        setServerError(error.toString());
      });
  };

  const getRows = (
    data: PaginatedResultsResponse<{ [key: string]: any }>,
    append: boolean
  ) => {
    if (!append) {
      setRowData({
        results: data.results?.map(item => {
          return {
            name: item.name,
            account: item.organisation.name,
            end_datetime: moment(item.end_datetime).format(FORMAT_DATE),
            id: item.id,
            number_completed: item.number_completed,
            number_invited: item.number_invited,
            number_products: item.number_products,
            pulse_status: item.pulse_status,
            start_datetime: moment(item.start_datetime).format(FORMAT_DATE),
            status: item.status,
            created_by_user: item.created_by_user.full_name,
            product_versions: item.product_versions,
          };
        }),
        count: data.count,
        page: 1,
      });
    } else {
      const rows = deepCopy(rowData);
      setNextPagePending(false);
      const nextPageData = {
        results: data.results?.map(item => {
          return {
            name: item.name,
            account: item.organisation.name,
            end_datetime: moment(item.end_datetime).format(FORMAT_DATE),
            id: item.id,
            number_completed: item.number_completed,
            number_invited: item.number_invited,
            number_products: item.number_products,
            pulse_status: item.pulse_status,
            start_datetime: moment(item.start_datetime).format(FORMAT_DATE),
            status: item.status,
            created_by_user: item.created_by_user.full_name,
            product_versions: item.product_versions,
          };
        }),
        count: data.count,
        page: data.page,
      };
      setRowData({
        results: rows.results.concat(nextPageData.results),
        count: data.count,
        page: page,
      });
    }
    fetchFilters();
  };

  const fetchData = (append: boolean, pageNumber: number) => {
    const fetchUrl = `${API_DATA_URL_PREFIX}`;
    const filterUrl = buildUrl(pageNumber);

    fetchWrapper
      .get(`${fetchUrl}?${filterUrl}`)
      .then(userData => {
        setDataPending(false);
        getRows(userData, append);
      })
      .catch(error => {
        setServerError(error.toString());
      });
  };

  const loadPage = () => {
    setPage(page + 1);
    setNextPagePending(true);
    fetchData(true, page + 1);
  };

  const doFiltersSearchSort = (
    type: string,
    key: string | number,
    selected: string | number,
    reverse?: boolean
  ) => {
    if (type !== 'compare_date') {
      setPage(1);
      setDataPending(true);
    }
    if (type === 'compare_date') {
      openDateModal(key.toString());
    }
    if (type === 'sort') {
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        sorting: {
          key: key.toString(),
          reverse:
            reverse !== undefined
              ? reverse
              : Boolean(selectedFilterOptions?.sorting.reverse),
        },
        page: 1,
      });
    }
    if (type === 'filter' && filters) {
      const selectedFilters = filters[key as keyof Key].filter(
        item => item.key === selected
      );
      let newObj = selectedFilterOptions.filters
        ? deepCopy(selectedFilterOptions.filters)
        : {};

      newObj = {
        ...newObj,
        [key as keyof Key]: selectedFilters,
      };
      if (
        selectedFilterOptions &&
        selectedFilterOptions.filters &&
        selectedFilterOptions.filters[key as keyof Key] &&
        selectedFilterOptions.filters[key as keyof Key][0].key === selected
      ) {
        delete newObj[key as keyof Key];
      }
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        filters: newObj ? deepCopy(newObj) : undefined,
        page: 1,
      });
    }
  };

  const searchTable = (searchTerm: string) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      search: searchTerm,
      page: 1,
    });
    setDataPending(true);
    setFiltersPending(true);
  };

  const handleOnSearchChange = _.debounce((change: string) => {
    searchTable(change);
  }, DEBOUNCE_TIME);

  const renderProductNameAndLogo = (productVersion: any) => {
    return (
      <div>
        <ItemAndLogo
          label={productVersion['product']['name']}
          icon={productVersion['product']['icon']}
          small={'small'}
        />
      </div>
    );
  };

  const cellRender = (
    key: string,
    value: string,
    row: Array<{ key: string; value: string }>
  ) => {
    let cell = <>{value}</>;
    if (key === 'name' && row[3].value) {
      cell = <Link to={`/page/activities/${row[3].value}`}>{value}</Link>;
    }
    if (key === 'number_completed' || key === 'number_invited') {
      cell = <span className="center display-block">{value}</span>;
    }
    if (key === 'account' || key === 'created_by_user') {
      cell = (
        <span className="no-wrap">{renderTruncatedTag('span', value, 20)}</span>
      );
    }
    if (key === 'start_datetime' || key === 'end_datetime') {
      cell = <span className="no-wrap">{value}</span>;
    }
    if (key === 'frequency') {
      cell = <span className="capitalize">{value}</span>;
    }
    if (key === 'pulse_status') {
      cell = (
        <PulseBrandStatusMark
          status={
            value === 'UNAVAILABLE'
              ? 'hyphen'
              : value === 'ADDED'
              ? 'active'
              : 'disabled'
          }
        />
      );
    }
    if (key === 'number_products' && row[11]) {
      const productVersions = Array.from(row[11]['value']);
      cell = (
        <WithPopover
          className="asset-count"
          popover={productVersions.map(productVersion =>
            renderProductNameAndLogo(productVersion)
          )}
        >
          <a className="hover-count">{value}</a>
        </WithPopover>
      );
    }
    if (key === 'status') {
      cell = <>{getText(value)}</>;
    }
    return cell;
  };

  useEffect(() => {
    setSelectedFilterOptions(initialFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shareFilterValues]);

  useEffect(() => {
    fetchData(false, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOptions]);

  const columnHeaders = [
    {
      header: {
        label: 'Name',
        key: 'name' as keyof {
          key: Key;
          value: string;
        },
      },
      sortable: true,
    },
    {
      header: {
        label: 'Account',
        key: 'account' as keyof { key: Key; value: string },
      },
      filter: {
        key: 'accounts' as keyof { key: Key; value: string },
        type: 'dropdown-single',
      },
      sortable: true,
    },
    {
      header: {
        label: 'Pulse',
        key: 'pulse_status' as keyof { key: Key; value: string },
      },
      filter: {
        key: 'pulse_status' as keyof { key: Key; value: string },
        type: 'dropdown-single',
      },
      sortable: false,
    },
    {
      header: {
        label: 'Products',
        key: 'number_products' as keyof {
          key: Key;
          value: string;
        },
      },
      sortable: false,
    },
    {
      header: {
        label: 'Invited',
        key: 'number_invited' as keyof { key: Key; value: string },
      },
      sortable: false,
    },
    {
      header: {
        label: 'Completed',
        key: 'number_completed' as keyof {
          key: Key;
          value: string;
        },
      },
      sortable: false,
    },
    {
      header: {
        label: 'Start date',
        key: 'start_datetime' as keyof { key: Key; value: string },
      },
      filter: {
        key: 'start_datetime' as keyof { key: Key; value: string },
        type: 'compare_date',
      },
      sortable: true,
    },
    {
      header: {
        label: 'Close date',
        key: 'end_datetime' as keyof { key: Key; value: string },
      },
      filter: {
        key: 'end_datetime' as keyof { key: Key; value: string },
        type: 'compare_date',
      },
      sortable: true,
    },
    {
      header: {
        label: 'Status',
        key: 'status' as keyof { key: Key; value: string },
      },
      filter: {
        key: 'status' as keyof { key: Key; value: string },
        type: 'dropdown-single',
      },
      sortable: false,
    },
  ];
  if (props.shareFilterValues === true) {
    columnHeaders.splice(2, 0, {
      header: {
        label: 'Created by user',
        key: 'created_by_user' as keyof {
          key: Key;
          value: string;
        },
      },
      sortable: false,
    });
  }
  if (!props.canAdminister) {
    columnHeaders.splice(1, 1);
  }
  if (!props.showPulse) {
    const idx = columnHeaders.findIndex(
      (item: any) => item.header.label === 'Pulse'
    );
    columnHeaders.splice(idx, 1);
  }

  return (
    <>
      <div>
        <ContainerRectangleTile noBorder>
          <>
            <div className="row justify-content-space-between display-flex">
              <div className="ml-md mr-auto">
                <h1 className="table-heading">Activities</h1>
              </div>
              <div className="activity-list-table-create-search">
                <a href="/#/page/activities/create">
                  <NewButton className="create-activity-button">
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                    Create activity
                  </NewButton>
                </a>
                <div className="mr-md">
                  <PillSearch
                    className="pill"
                    onChange={e =>
                      handleOnSearchChange(e?.currentTarget.value || '')
                    }
                    formKey="form"
                    disabled={dataPending || filtersPending || nextPagePending}
                    placeholder="Search"
                  />
                </div>
              </div>
            </div>
            {serverError && <Alert type={AlertType.Error}>{serverError}</Alert>}
            <InteractiveTable
              className="manage-activities"
              pageSize={PAGE_SIZE}
              loadPage={loadPage}
              page={page}
              cellRenderer={cellRender}
              isDataPending={dataPending}
              isFilterDataPending={filtersPending}
              isNextPageDataPending={nextPagePending}
              setSelectedFilter={doFiltersSearchSort}
              enableCheckBoxes={false}
              onSelection={undefined}
              rows={rowData}
              filters={filters}
              initialSelections={undefined}
              removeCompareDate={removeDate}
              selectedFilterOptions={selectedFilterOptions}
              columns={
                columnHeaders as ReadonlyArray<
                  Column<{ key: Key; value: string }>
                >
              }
            />
          </>
        </ContainerRectangleTile>
      </div>
    </>
  );
};
