import React from 'react'; // eslint-disable-line no-unused-vars

import Well from '^/components/Well';
import PureComponent from '^/components/PureComponent';

export default class AboutQuestionCollectionSection extends PureComponent {
  render() {
    const { sections } = this.props;
    return (
      <div>
        {sections.map(({ icon, header, text }, index) => (
          <Well key={index} className="pp-section">
            <div className="pp-icon">{icon}</div>
            <div className="pp-text">
              <p className="lead mb-sm">{header}</p>
              <p>{text}</p>
            </div>
          </Well>
        ))}
      </div>
    );
  }
}
