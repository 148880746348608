import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '^/components/PureComponent';
import CompletionSummaryTable from '^/components/activities/CompletionSummaryTable';

export default class CompletionSummaryModal extends PureComponent {
  render() {
    const { activity, user } = this.props;
    return (
      <div>
        <CompletionSummaryTable activity={activity} user={user} />
      </div>
    );
  }
}
