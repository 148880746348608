import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { APPROVAL_STATUS } from '^/approvalStatus';
import PureComponent from '^/components/PureComponent';

export default class ApprovalStatus extends PureComponent {
  notSubmitted() {
    const { status, ratersSubmitted } = this.props;
    const requiresAttention =
      status && status !== APPROVAL_STATUS.AWAITING_APPROVAL;
    return !ratersSubmitted && !requiresAttention;
  }

  render() {
    const { status } = this.props;

    if (this.notSubmitted()) {
      return (
        <span className="inline-badge badge-grey">
          {i18next.t('NOT SUBMITTED')}
        </span>
      );
    }

    switch (status) {
      case APPROVAL_STATUS.APPROVED:
        return (
          <span className="inline-badge badge-success">
            {i18next.t('APPROVED')}
          </span>
        );
      case APPROVAL_STATUS.REJECTED:
        return (
          <span className="inline-badge badge-error">
            {i18next.t('REJECTED')}
          </span>
        );
      case APPROVAL_STATUS.AWAITING_APPROVAL:
        return (
          <span className="inline-badge badge-warning">
            {i18next.t('AWAITING APPROVAL')}
          </span>
        );
      case '':
      default:
        return null;
    }
  }
}

ApprovalStatus.propTypes = {
  status: PropTypes.string.isRequired,
  raterSubmitted: PropTypes.bool,
};
