import React from 'react'; // eslint-disable-line no-unused-vars
import { faArrowToLeft } from '@fortawesome/pro-solid-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons/faArrowToRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import i18next from 'i18next';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';

import Dropdown from '^/components/dropdown/Dropdown';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import { FormItem } from '^/components/forms/FormItem';
import {
  AnalyticsAppliedFilters,
  AnalyticsFilterItem,
  AnalyticsFilters,
  AnalyticsProduct,
  SelectedFilterProfileData,
  Uuid,
} from '^/reducers/api/types';
import { SearchBarForm } from '^/store';
import { handleBlank } from './utils';
import ExpandableList from '../ExpandableList';
import { convertFilterToDisplay } from '../advanced-analytics/utils';

const SELECT_ALL_ID = 'select-all';

interface Props {
  canUserAdministerOrganisations: boolean;
  appliedFilters: AnalyticsAppliedFilters;
  filters: AnalyticsFilters;
  product?: AnalyticsProduct;
  filtersLoading: boolean;
  searchForm: SearchBarForm | null;
  onChange: (filters: Partial<AnalyticsAppliedFilters>) => void;
  selectedProfiles: Record<Uuid, SelectedFilterProfileData>;
  previewFilterRanges: Array<Array<string>>;
  handleClickAdvancedAnalytics: () => void;
  handleRemoveResultsRange: (index: number) => void;
  toggleCollapsed: () => void;
  clearAllFiltersAndOrdering: () => void;
  collapsed: boolean;
  inModal?: boolean;
}

interface State {
  isExpandableListOpen: boolean;
}

const DEMOGRAPHIC_FILTERS: ReadonlyArray<{
  label: string;
  key: keyof Pick<
    AnalyticsFilters,
    'sex' | 'age' | 'ethnicity' | 'job_level' | 'industry' | 'geography'
  >;
}> = [
  { label: i18next.t<string>('Sex'), key: 'sex' },
  { label: i18next.t<string>('Age range'), key: 'age' },
  { label: i18next.t<string>('Ethnicity'), key: 'ethnicity' },
  { label: i18next.t<string>('Job level'), key: 'job_level' },
  { label: i18next.t<string>('Industry'), key: 'industry' },
  { label: i18next.t<string>('Geography'), key: 'geography' },
];

class DataAnalyticsFilters extends React.PureComponent<Props, State> {
  static defaultProps: Partial<Props> = {
    selectedProfiles: {},
    previewFilterRanges: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isExpandableListOpen: false,
    };
    this.updateExpandableListTitle = this.updateExpandableListTitle.bind(this);
    this.getExpandableListTitle = this.getExpandableListTitle.bind(this);
    this.renderResultsRanges = this.renderResultsRanges.bind(this);
  }

  updateExpandableListTitle() {
    this.setState({
      isExpandableListOpen: !this.state.isExpandableListOpen,
    });
  }

  getExpandableListTitle() {
    return this.state.isExpandableListOpen
      ? i18next.t<string>('Less Filters')
      : i18next.t<string>('More Filters');
  }

  renderSelectedProfiles() {
    const selectedProfiles = Object.values(this.props.selectedProfiles);
    const shouldRenderResult = Boolean(
      selectedProfiles.length && selectedProfiles.length > 0
    );
    return (
      shouldRenderResult &&
      selectedProfiles.map((profile, index) => {
        return this.renderResultsRanges(profile.filters, index, true);
      })
    );
  }

  renderPreviewFilterRanges() {
    const shouldRenderFilterRanges = Boolean(
      this.props.previewFilterRanges.length &&
        this.props.previewFilterRanges.length > 0
    );
    return (
      shouldRenderFilterRanges &&
      this.props.previewFilterRanges.map((previewFilterResultsRange, index) => {
        return this.renderResultsRanges(previewFilterResultsRange, index);
      })
    );
  }

  public render() {
    const {
      canUserAdministerOrganisations,
      product,
      appliedFilters,
      filters,
      filtersLoading,
      onChange,
      handleClickAdvancedAnalytics,
      toggleCollapsed,
      clearAllFiltersAndOrdering,
      collapsed,
      inModal,
    } = this.props;

    return (
      <div>
        <div
          className="filter-expand-icon data-analytics-expand-icon"
          onClick={toggleCollapsed}
        >
          <FontAwesomeIcon
            className="margin-none filter-expand-icon-inner"
            icon={collapsed ? faArrowToRight : faArrowToLeft}
          />
        </div>
        <div className="display-flex filter-option">
          <div
            className={classnames('filter-title-text', {
              vertical: collapsed,
            })}
          >
            {i18next.t<string>('Filters')}
          </div>
          {!collapsed && (
            <div className="button-container">
              <button
                className="btn btn-small margin-none col-xs-12"
                onClick={clearAllFiltersAndOrdering}
                disabled={_.isEmpty(appliedFilters) || inModal}
              >
                {i18next.t<string>('Clear all')}
              </button>
            </div>
          )}
        </div>
        {!collapsed && (
          <div>
            <div className="reports-control-bar">
              {canUserAdministerOrganisations && (
                <div className="form-block">
                  <FormItem label={i18next.t<string>('Account')}>
                    <Dropdown
                      searchable
                      name="organisation"
                      title={i18next.t<string>('Choose an organisation')}
                      items={this.getSearchFiltered('organisation')}
                      selectedItems={appliedFilters.organisation}
                      selectedTitle={this.getTitle('organisation')}
                      onClick={organisation =>
                        onChange({
                          organisation: this.toggleFilter(
                            'organisation',
                            organisation as Uuid
                          ),
                          activity: undefined,
                          group: undefined,
                        })
                      }
                      loading={filtersLoading}
                      defaultValue={null}
                      disableOnSingle
                      selectable
                      multiSelects={this.multiSelectsFor('organisation')}
                    />
                  </FormItem>
                </div>
              )}
              <div className="form-block">
                <FormItem label={i18next.t<string>('Product')}>
                  <Dropdown
                    searchable
                    name="product"
                    title={i18next.t<string>('Select')}
                    items={this.getSearchFiltered('product')}
                    onClick={product_ =>
                      onChange({
                        product: product_ as Uuid,
                        filter_profile: undefined,
                      })
                    }
                    loading={filtersLoading}
                    defaultValue={appliedFilters.product || null}
                    disableOnSingle
                  />
                </FormItem>
              </div>

              <div className="form-block">
                <FormItem label={i18next.t<string>('Activity')}>
                  <Dropdown
                    searchable
                    name="activity"
                    title={i18next.t<string>('Choose an activity')}
                    items={this.getSearchFiltered('activity')}
                    selectedItems={appliedFilters.activity}
                    selectedTitle={this.getTitle('activity')}
                    onClick={activity =>
                      onChange({
                        activity: this.toggleFilter(
                          'activity',
                          activity as Uuid
                        ),
                      })
                    }
                    loading={filtersLoading}
                    defaultValue={null}
                    disableOnSingle
                    selectable
                    multiSelects={this.multiSelectsFor('activity')}
                  />
                </FormItem>
              </div>

              <div className="form-block">
                <FormItem label={i18next.t<string>('Respondent')}>
                  <Dropdown
                    searchable
                    name="user"
                    title={i18next.t<string>('Choose a respondent')}
                    items={this.getSearchFiltered('user')}
                    selectedItems={appliedFilters.user}
                    selectedTitle={this.getTitle('user')}
                    onClick={user =>
                      onChange({
                        user: this.toggleFilter('user', user as Uuid),
                      })
                    }
                    loading={filtersLoading}
                    defaultValue={null}
                    disableOnSingle
                    selectable
                    multiSelects={this.multiSelectsFor('user')}
                  />
                </FormItem>
              </div>

              <div className="form-block">
                <FormItem label={i18next.t<string>('Group')}>
                  <Dropdown
                    searchable
                    name="group"
                    title={i18next.t<string>('Choose a group')}
                    items={this.getSearchFiltered('group')}
                    selectedItems={appliedFilters.group}
                    selectedTitle={this.getTitle('group')}
                    onClick={group =>
                      onChange({
                        group: this.toggleFilter('group', group as Uuid),
                      })
                    }
                    loading={filtersLoading}
                    defaultValue={null}
                    disableOnSingle
                    selectable
                    multiSelects={this.multiSelectsFor('group')}
                  />
                </FormItem>
              </div>

              <div className="form-block">
                <FormItem label={i18next.t<string>('Saved profiles')}>
                  <Dropdown
                    name="filter_profile"
                    selectedItems={Object.keys(this.props.selectedProfiles)}
                    selectedTitle={this.getTitle('filter_profile')}
                    items={this.getSearchFiltered('filter_profile')}
                    defaultValue={null}
                    emptyTitle={i18next.t<string>('None')}
                    title={i18next.t<string>('None')}
                    onClick={selectedProfileId =>
                      onChange({
                        filter_profile: this.toggleFilter(
                          'filter_profile',
                          selectedProfileId as string
                        ),
                      })
                    }
                    loading={filtersLoading}
                    multiSelects={this.multiSelectsFor('filter_profile')}
                    searchable
                    selectable
                  />
                </FormItem>
              </div>
            </div>
            <div className="reports-control-bar demographics">
              <ExpandableList
                isListOpen={this.state.isExpandableListOpen}
                onToggleList={this.updateExpandableListTitle}
                title={this.getExpandableListTitle()}
              >
                {DEMOGRAPHIC_FILTERS.map(({ label, key }) => (
                  <div key={key} className="form-block">
                    <FormItem label={label}>
                      <Dropdown
                        title={label}
                        items={filters[key].map(handleBlank)}
                        selectedItems={appliedFilters[key] as Uuid[]}
                        selectedTitle={this.getTitle(key)}
                        onClick={value =>
                          onChange({
                            [key]: this.toggleFilter(key, value as string),
                          })
                        }
                        loading={filtersLoading}
                        defaultValue={null}
                        disableOnSingle
                        selectable
                      />
                    </FormItem>
                  </div>
                ))}
              </ExpandableList>
              <div className="form-block display-flex justify-content-flex-end align-items-flex-end mt-base">
                <button
                  type="button"
                  className="btn btn-primary margin-none padding-horizontal-medium col-xs-12"
                  disabled={filtersLoading || !product}
                  onClick={handleClickAdvancedAnalytics}
                >
                  {i18next.t<string>('Advanced analytics')}
                </button>
              </div>

              {this.renderSelectedProfiles()}
              {this.renderPreviewFilterRanges()}
            </div>
          </div>
        )}
      </div>
    );
  }

  private renderResultsRanges = (
    results_ranges: string[],
    index: number,
    disableRemoveButton?: boolean
  ) => {
    return (
      <div className="results-ranges">
        <div className="item">
          {results_ranges
            .map(filter => `${convertFilterToDisplay(filter)}`)
            .join('; ')}{' '}
        </div>
        {!disableRemoveButton && (
          <>
            <span
              data-tip
              data-for="remove-results-ranges"
              className="remove-button"
              onClick={() => this.props.handleRemoveResultsRange(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
            <ReactTooltip
              id="remove-results-ranges"
              textColor="black"
              backgroundColor="white"
            >
              {i18next.t<string>('Remove')}
            </ReactTooltip>
          </>
        )}
      </div>
    );
  };

  private displayNumberOfSelectedItemsText(number: number) {
    return i18next.t<string>('{{number}} selected', {
      number,
    });
  }

  private getTitle(key: keyof AnalyticsFilters) {
    const { appliedFilters, filters } = this.props;

    const selected =
      key === 'filter_profile'
        ? Object.keys(this.props.selectedProfiles)
        : appliedFilters[key] || [];

    const selectedAvailable = filters[key].filter(item =>
      selected.includes(item.id)
    );

    switch (selectedAvailable.length) {
      case 0:
        return i18next.t<string>('Select');
      case 1:
        return handleBlank(selectedAvailable[0])!.name;
      default:
        return this.displayNumberOfSelectedItemsText(selectedAvailable.length);
    }
  }

  private toggleFilter(key: keyof AnalyticsAppliedFilters, value: string) {
    const { appliedFilters, filters } = this.props;

    const selectedValues =
      key === 'filter_profile'
        ? Object.keys(this.props.selectedProfiles)
        : appliedFilters[key] || [];

    const allValues = _.map(
      filters[key as keyof AnalyticsFilters],
      each => (each as AnalyticsFilterItem).id
    );

    if (value === SELECT_ALL_ID) {
      return selectedValues.length === allValues.length ? [] : allValues;
    }

    const flattenedSelectedValues = (selectedValues as string[][]).flat();
    const newFilterValues = flattenedSelectedValues.includes(value)
      ? _.without(flattenedSelectedValues, value)
      : [value, ...selectedValues];

    return allValues.filter((each: string) => newFilterValues.includes(each));
  }

  private getSearchFiltered = (
    filterType: keyof SearchBarForm
  ): ReadonlyArray<DropdownOption> => {
    const { filters, searchForm, canUserAdministerOrganisations } = this.props;
    const searchFilter = searchForm && searchForm[filterType];
    const searchTermLowerCase = searchFilter?.search.value?.toLowerCase() || '';

    return filters[filterType]
      .filter(each => each.name.toLowerCase().includes(searchTermLowerCase))
      .map(({ id, name, organisation__name }) => ({
        id,
        name,
        extra: canUserAdministerOrganisations ? organisation__name : undefined,
      }));
  };

  private multiSelectsFor(key: keyof AnalyticsFilters) {
    const { appliedFilters } = this.props;

    const appliedFilterKey = key === 'filter_profile' ? 'results_ranges' : key;

    return [
      {
        id: SELECT_ALL_ID,
        name: i18next.t<string>('Select all'),
        selected:
          appliedFilters[appliedFilterKey]?.length ===
          this.getSearchFiltered(key as keyof SearchBarForm).length,
      },
    ];
  }
}

export default DataAnalyticsFilters;
