import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Set, Map } from 'immutable';
import { connect } from 'react-redux';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { closeTopModal } from '^/actions/modals';
import {
  addUserAndReset,
  addUserAndSelect,
  inviteToStageTwoAndRefresh,
} from '^/actions/actionSequences';
import {
  toggleSelection,
  setSelection,
  clearSelection,
  initialiseSelection,
} from '^/actions/actions';
import PureComponent from '^/components/PureComponent';
import SelectUsersPage from '^/components/users/SelectUsersPage';

export class InviteToStageTwoModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.initialiseSelection('users', Set());
  }

  cancel() {
    this.props.closeTopModal();
  }

  submit() {
    const { activityId, activityUsers, selectedUsers } = this.props;
    const selectedSessions = activityUsers
      .filter(activityUser =>
        selectedUsers.includes(activityUser.get('user_id'))
      )
      .map(activityUser => activityUser.get('session_id'));
    this.props
      .inviteToStageTwoAndRefresh(activityId, selectedSessions)
      .then(() => this.props.closeTopModal());
  }

  render() {
    const { response, activityUsers, selectedUsers } = this.props;

    return (
      <div>
        <SelectUsersPage
          current={List()}
          noLoad
          users={Map({
            items: activityUsers.map(each =>
              Map({
                session_id: each.get('session_id'),
                id: each.get('user_id'),
                full_name: each.get('respondent'),
                email: each.get('email'),
                survey_status: each.get('survey_status'),
                status: '',
              })
            ),
          })}
          fetchAll
          onSelect={user => this.props.toggleSelection('users', user.get('id'))}
          onSetSelected={(user, selected) =>
            this.props.setSelection('users', user.get('id'), selected)
          }
          onClearAll={() => this.props.clearSelection('users')}
          hideOrganisations
          hideGroups
          hideProfilePicture
        />
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button className="btn btn-default" onClick={() => this.cancel()}>
              {i18next.t('Cancel')}
            </button>
            <button
              className="btn btn-primary"
              disabled={selectedUsers.isEmpty() || isPending(response)}
              onClick={() => this.submit()}
            >
              {i18next.t('Invite to stage 2')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    selectedUsers: state.ui.getIn(['selectedUiComponents', 'users'], Set()),
    response: state.responses.get('inviteToStageTwo'),
  };
}

export default connect(mapStateToProps, {
  toggleSelection,
  setSelection,
  clearSelection,
  initialiseSelection,
  closeTopModal,
  addUserAndSelect,
  addUserAndReset,
  inviteToStageTwoAndRefresh,
})(InviteToStageTwoModal);
