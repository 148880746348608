import React from 'react';
import { Moment } from 'moment';
import { connect } from 'react-redux';

import { getAllOrgs, clearOrgFilters } from '^/actions/actions';
import { loadOrgProductsThenTeamReports } from '^/actions/actionSequences';
import {
  clearReportFilterSelection,
  ReportFilterSelections,
} from '^/actions/ui';
import {
  administerOrganisations,
  administerOwnOrganisation,
} from '^/capabilities';
import CollapsibleSidebar from '^/components/CollapsibleSidebar';
import { selectReportTableIsLoading } from '^/components/reports/admin/AdminReportsPage/selectors';
import ReportsActionBar from '^/components/reports/admin/ReportsActionBar';
import { selectAnySelectionReportsTable } from '^/components/reports/admin/selectors';
import {
  TeamReportsState,
  AllOrgs,
  Uuid,
  OrgSessions,
} from '^/reducers/api/types';
import {
  selectAllOrgs,
  selectOrgSessions,
  selectUserProfile,
} from '^/selectors';
import { selectUserCapability, selectUserOrgId } from '^/selectors/user';
import { StoreState } from '^/store';
import TeamReportsTable from './TeamReportsTable';
import TeamReportsFilters from './TeamReportsFilters';
import { selectReportFilterSelection } from '^/selectors/ui';

interface OwnProps {
  switcher?: React.ReactNode;
}

interface StateProps {
  teamReports: TeamReportsState;
  user: Immutable.Map<string, any>;
  hasAnySelection: boolean;
  isLoadingTable: boolean;
  organisations: AllOrgs;
  orgSessions: OrgSessions | null;
  userOrgId?: Uuid;
  canUserAdministerOwnOrganisation: boolean;
  canUserAdministerOrganisations: boolean;
  reportFilterSelection: ReportFilterSelections;
}

interface DispatchProps {
  getAllOrgs: typeof getAllOrgs;
  clearOrgFilters: typeof clearOrgFilters;
  clearReportFilterSelection: typeof clearReportFilterSelection;
  loadOrgProductsThenTeamReports: typeof loadOrgProductsThenTeamReports;
}

export type Props = OwnProps & StateProps & DispatchProps;

interface State {
  orgId: Uuid | null;
  selectedDate: Readonly<{
    from?: Date;
    to?: Date;
  }>;
}

export class TeamReportsPage extends React.Component<Props, State> {
  public readonly state = {
    orgId: null,
    selectedDate: {
      from: undefined,
      to: undefined,
    },
  };

  public componentDidMount() {
    const { userOrgId } = this.props;
    if (this.props.canUserAdministerOwnOrganisation && userOrgId) {
      this.loadOrganisation(userOrgId);
    } else {
      this.props.getAllOrgs({ team: true });
    }
  }

  public componentWillUnmount() {
    this.props.clearOrgFilters();
  }

  public render() {
    const {
      organisations,
      orgSessions,
      canUserAdministerOrganisations,
      canUserAdministerOwnOrganisation,
      switcher,
      hasAnySelection,
      isLoadingTable,
    } = this.props;
    const { orgId, selectedDate } = this.state;

    const teamReports = this.getFilteredTeamReports();

    return (
      <div className="report-table-and-filters-wrapper">
        <CollapsibleSidebar>
          <TeamReportsFilters
            canUserAdministerOrganisations={canUserAdministerOrganisations}
            organisations={organisations}
            onOrganisationClick={this.onOrganisationClick}
            isLoadingTable={isLoadingTable}
            hasOrgSelected={orgId !== null}
            selectedDate={selectedDate}
            setDate={this.setSelectedDate}
          />
        </CollapsibleSidebar>

        <div className="report-type-switcher">{switcher}</div>

        <div className="report-table-wrapper">
          {teamReports && orgId && (
            <div>
              <TeamReportsTable
                teamReports={teamReports}
                orgId={orgId}
                orgSessions={orgSessions}
                canUserAdministerOwnOrganisation={
                  canUserAdministerOwnOrganisation
                }
              />

              {orgSessions && (
                <ReportsActionBar
                  isTeamReport
                  visible={hasAnySelection}
                  organisation={orgSessions}
                  orgId={orgId || ''}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  private getFilteredTeamReports() {
    const {
      selectedDate: { from, to },
    } = this.state;
    const { products } = this.props.reportFilterSelection;

    const selectedProducts = Object.keys(products).filter(
      productId => products[productId]
    );

    return this.props.teamReports?.filter(
      team =>
        team.reports.some(report => {
          const reportCreatedDate = new Date(report.created);
          return (
            (!from || reportCreatedDate > ((from as unknown) as Date)) &&
            (!to || reportCreatedDate < ((to as unknown) as Date))
          );
        }) &&
        team.report_template.product_set.some(
          productId =>
            selectedProducts.length === 0 ||
            selectedProducts.includes(productId)
        )
    );
  }

  private setSelectedDate = (fieldName: string, date?: Moment) => {
    this.setState({
      selectedDate: {
        ...this.state.selectedDate,
        [fieldName]: date ? date.toDate() : null,
      },
    });
  };

  private onOrganisationClick = (orgId: string | number | null) => {
    this.props.clearReportFilterSelection();
    this.loadOrganisation(
      typeof orgId !== 'string' && orgId !== null ? `${orgId}` : orgId
    );
  };

  private loadOrganisation = (orgId: Uuid | null) => {
    const { user } = this.props;
    if (orgId) {
      this.setState({ orgId });
      return this.props.loadOrgProductsThenTeamReports(orgId, user);
    }
    return Promise.reject();
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    teamReports: state.teamReports,
    hasAnySelection: selectAnySelectionReportsTable(state),
    isLoadingTable: selectReportTableIsLoading(state),
    userOrgId: selectUserOrgId(state),
    canUserAdministerOwnOrganisation: selectUserCapability(
      state,
      administerOwnOrganisation()
    ),
    canUserAdministerOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
    organisations: selectAllOrgs(state),
    orgSessions: selectOrgSessions(state),
    reportFilterSelection: selectReportFilterSelection(state),
  };
}

export default connect(mapStateToProps, {
  getAllOrgs,
  clearOrgFilters,
  clearReportFilterSelection,
  loadOrgProductsThenTeamReports,
})(TeamReportsPage);
