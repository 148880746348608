import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Field } from 'formik';

import { toggleRevealPassword } from '../../actions/actions';

export class RevealablePasswordField extends React.Component {
  getFieldType() {
    return this.props.shouldReveal ? 'text' : 'password';
  }

  toggle() {
    this.props.toggleRevealPassword(this.props.id, !this.props.shouldReveal);
  }

  render() {
    return (
      <div className="revealable-input">
        <Field
          type={this.getFieldType()}
          name={this.props.name}
          id={this.props.name}
          placeholder={this.props.placeholder}
        />
        <span
          className="reveal-button btn btn-small"
          onClick={this.toggle.bind(this)}
          role="button"
        >
          {this.props.shouldReveal ? i18next.t('Hide') : i18next.t('Reveal')}
        </span>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    shouldReveal: state.ui.getIn(['toggleRevealPassword', ownProps.id], false),
  };
}

export default connect(mapStateToProps, {
  toggleRevealPassword,
})(RevealablePasswordField);
