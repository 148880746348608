import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '../PureComponent';
import { closeTopModal } from '^/actions/modals';
import { addAccountCloseModalAndNavigateToEdit } from '^/actions/actionSequences';
import { ACCOUNT_TYPE, ACCOUNT_CREDIT_OPTIONS } from '^/models/organisation';
import AddAccountForm from './AddAccountForm';

export class AddAccountModal extends PureComponent {
  addAccount(data) {
    const [type, creditTypeOption] = data.type.split(':');
    const hasUnlimitedCredits =
      creditTypeOption === ACCOUNT_CREDIT_OPTIONS.CHOICES.UNLIMITED;
    this.props.addAccountCloseModalAndNavigateToEdit(
      data.name,
      data.description,
      type,
      hasUnlimitedCredits,
      data.accountSuperuserName,
      data.accountSuperuserEmail
    );
  }

  render() {
    return (
      <AddAccountForm
        initialValues={{
          name: '',
          type: `${ACCOUNT_TYPE.PW_MANAGED}:`,
          accountSuperuserName: '',
          accountSuperuserEmail: '',
        }}
        response={this.props.response}
        onSubmit={this.addAccount.bind(this)}
        onCancel={this.props.closeTopModal}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.get('addToCollection'),
  };
}

export default connect(mapStateToProps, {
  addAccountCloseModalAndNavigateToEdit,
  closeTopModal,
})(AddAccountModal);
