import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from './PureComponent';
import { resetResponse } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { sudoLoginCloseModalAndGoHome } from '^/actions/actionSequences';
import SudoForm from './SudoForm';

export class SudoModal extends PureComponent {
  componentDidMount() {
    // Should we fetch the users list here to do a typeahead?
    // Should we show the normal users selection dialog?
    this.props.resetResponse('sudoLogin');
  }

  sudoLogin(data) {
    this.props.sudoLoginCloseModalAndGoHome(data.email, data.password);
  }

  render() {
    return (
      <div>
        <SudoForm
          response={this.props.response}
          onSubmit={data => this.sudoLogin(data)}
          onClose={() => this.props.closeTopModal()}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('sudoLogin'),
  };
}

export default connect(mapStateToProps, {
  sudoLoginCloseModalAndGoHome,
  closeTopModal,
  resetResponse,
})(SudoModal);
