import { Map, List, fromJS } from 'immutable';
import _ from 'underscore';
import moment from 'moment';

import { getIn } from '^/utils';
import * as actions from '^/actions/actions';
import * as shop from '^/actions/shop';
import * as items from '^/actions/items';
import LocalStorage from '^/LocalStorage';
import TokenStore from '^/TokenStore';

export function user(state = Map({ token: TokenStore.get() }), action) {
  switch (action.type) {
    case actions.EXTERNAL_LOGIN.SUCCESS:
    case actions.LOGIN.SUCCESS:
    case actions.REDEEM_INVITE.SUCCESS:
      return Map({ token: action.payload.token });
    case actions.LOGOUT:
      return Map({});
    default:
      return state;
  }
}

export function logoutResponse(state = null, action) {
  switch (action.type) {
    case actions.LOGIN.REQUEST:
      return null;
    case actions.LOGOUT:
      return action.response || null;
    default:
      return state;
  }
}

export function userProfile(state = Map(), action) {
  switch (action.type) {
    case actions.GET_USER_INFO.SUCCESS:
    case actions.UPDATE_PROFILE.SUCCESS:
    case actions.SET_LINE_MANAGER.SUCCESS:
    case actions.REDEEM_INVITE.SUCCESS:
    case actions.UPDATE_USER_LANGUAGE.SUCCESS:
      return fromJS(action.payload);
    case actions.GET_USER_INFO.FAILURE:
      return Map();
    case actions.SHARE_ACCESS_WITH_USER.SUCCESS:
    case actions.REVOKE_ACCESS_FROM_USER.SUCCESS: {
      return state.get('id') === action.payload.id
        ? state.set('shared_with', fromJS(action.payload.shared_with))
        : state;
    }
    case actions.DISMISS_SHARE_NOTIFICATIONS.SUCCESS: {
      return state.get('id') === action.payload.id
        ? state.set(
            'sharing_notification_received',
            fromJS(action.payload.sharing_notification_received)
          )
        : state;
    }
    case actions.LOAD_ORG_PRODUCTS.SUCCESS:
      return state.getIn(['organisation', 'id']) === action.payload.id
        ? state.setIn(['organisation', 'credit'], action.payload.credit)
        : state;
    case shop.ADD_TO_BASKET.SUCCESS:
      return state.set('basketitem_set', fromJS(action.payload));
    case shop.REMOVE_FROM_BASKET.SUCCESS:
      return state.update('basketitem_set', basketItems =>
        basketItems.filter(each => each.get('id') !== action.meta.id)
      );
    case shop.UPDATE_BASKET_ITEM_QUANTITY.SUCCESS:
    case shop.UPDATE_BASKET_ITEM_REPORT_QUANTITY.SUCCESS:
      return state.update('basketitem_set', basketItems =>
        basketItems.map(each =>
          each.get('id') === action.payload.id ? fromJS(action.payload) : each
        )
      );
    case actions.VERIFY_VALIDATION_CODE.SUCCESS:
      return state.set('has_activity', action.payload.has_activity);
    default:
      return state;
  }
}

export function productVersion(state = Map(), action) {
  switch (action.type) {
    case actions.GET_PRODUCT_VERSION.SUCCESS:
      return fromJS(action.payload);
    case actions.GET_PRODUCT_VERSION.FAILURE:
      return Map();
    default:
      return state;
  }
}

export function myAnswers(state = List(), action) {
  switch (action.type) {
    case actions.CLEAR_ANSWERS:
      return List();
    case actions.GET_MY_ANSWERS.SUCCESS:
    case actions.GET_MY_IMAGE_MATCH_ANSWERS.SUCCESS:
    case actions.GET_MY_MULTI_LIKERT_ANSWERS.SUCCESS:
      return state.concat(fromJS(action.payload));
    case actions.ANSWER_QUESTION.SUCCESS:
      return state.push(fromJS(action.payload));
    case actions.ANSWER_MULTIPLE_QUESTIONS.SUCCESS:
      const new_answers = fromJS(action.payload);
      return state.concat(new_answers.flatten(true));
    default:
      return state;
  }
}

export function getReportsFromDate(state = null, action) {
  switch (action.type) {
    case actions.CREATE_REPORTS.SUCCESS:
    case actions.GET_MY_GENERATING_REPORTS.SUCCESS:
      return state || new Date().toISOString();
    case actions.RESET_GET_REPORTS_FROM_DATE:
      return null;
    default:
      return state;
  }
}

export function userPulses(state = Map(), action) {
  switch (action.type) {
    case actions.GET_USER_PULSES.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function activityReports(state = Map(), action) {
  switch (action.type) {
    case actions.CREATE_REPORTS.SUCCESS:
    case actions.GENERATE_TEAM_REPORT.SUCCESS:
    case actions.GET_MY_GENERATING_REPORTS.SUCCESS:
      return state.set(null, fromJS(action.payload));
    case actions.GET_ORGANISATION_ALL_REPORTS.SUCCESS:
      const activityId = getIn(action, ['meta', 'activityId']);
      return activityId ? state.set(activityId, fromJS(action.payload)) : state;
    case actions.GET_USER_REPORTS.SUCCESS:
      return action.payload
        ? state.merge(
            fromJS(
              _.mapObject(
                action.payload,
                value =>
                  value.reports &&
                  value.reports.map(report =>
                    Object.assign({}, report, { activity: value.activity })
                  )
              )
            )
          )
        : state;
    default:
      return state;
  }
}

export function productVersionProgress(
  state = Map({ currentPage: 0, unsavedResponses: Map(), canResume: false }),
  action
) {
  switch (action.type) {
    case actions.SET_PRODUCT_VERSION_PAGE:
      return Map({
        currentPage: action.payload.page || 0,
        unsavedResponses: Map(),
        canResume: state.get('canResume'),
      });
    case actions.SHOW_NEXT_PRODUCT_VERSION_PAGE:
      return state.set('currentPage', state.get('currentPage') + 1);
    case actions.STORE_PRODUCT_VERSION_ANSWER:
      return state.setIn(
        ['unsavedResponses', action.payload.questionId],
        action.payload.answer
      );
    case actions.RESET_PRODUCT_VERSION_ANSWERS:
      return state.set('unsavedResponses', Map());
    case actions.START_ACTIVITY_PRODUCT_VERSION_SESSION.SUCCESS:
      return Map({
        currentPage: 0,
        unsavedResponses: Map(),
        canResume: action.payload.can_resume,
      });
    case actions.SET_ACTIVITY_PRODUCT_VERSION_SESSION_CAN_RESUME:
      return state.set('canResume', action.payload.canResume);
    default:
      return state;
  }
}

export function progress(state = Map(), action) {
  switch (action.type) {
    case actions.UPDATE_PROGRESS:
      return state.set(action.payload.key, action.payload.progress);
    default:
      return state;
  }
}

export function sortOrders(state = Map(), action) {
  switch (action.type) {
    case actions.SORT_LIST:
      return state.set(action.payload.list, action.payload.sortBy);
    default:
      return state;
  }
}

// The stash is saved out to storage by the sudoStash middleware
function loadStashFromStorage() {
  if (!LocalStorage.get('user')) {
    return null;
  }

  return {
    userProfile: fromJS(LocalStorage.get('userProfile')),
    user: fromJS(LocalStorage.get('user')),
  };
}

export function stash(state = loadStashFromStorage() /*, action*/) {
  return state;
}

export function averageValues(state = null, action) {
  switch (action.type) {
    case actions.VIEW_AVERAGES_REPORT.REQUEST:
    case actions.VIEW_AVERAGES_REPORT.FAILURE:
      return null;
    case actions.VIEW_AVERAGES_REPORT.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function rankQuestionOrder(state = null, action) {
  switch (action.type) {
    case actions.SAVE_PERSPECTIVES_LIKERT.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function averageValuesConfig(state = Map(), action) {
  switch (action.type) {
    case actions.VIEW_AVERAGES_SET_ORGANISATION:
      return state.set('organisation', action.payload);
    default:
      return state;
  }
}

export function userCSVExportFilter(state = Map(), action) {
  switch (action.type) {
    case actions.RESET_EXPORT_SELECTIONS:
      return state
        .set('selectedProduct', false)
        .set('selectedActivity', false)
        .set('selectedOrganisation', false)
        .set('selectedUser', false)
        .set('selectedTeam', false);

    case actions.SET_EXPORT_ORGANISATION:
      return state
        .set('selectedOrganisation', action.payload)
        .set('selectedActivity', false)
        .set('selectedProduct', false)
        .set('selectedTeam', false);

    case actions.SET_EXPORT_ACTIVITY:
      return state
        .set('selectedActivity', action.payload)
        .set('selectedUser', false)
        .set('selectedTeam', false);

    case actions.SET_EXPORT_PRODUCT:
      return state
        .set('selectedProduct', action.payload)
        .set('selectedUser', false);

    case actions.SET_EXPORT_USER:
      return state
        .set('selectedUser', action.payload)
        .set('selectedTeam', false);

    case actions.SET_EXPORT_TEAM:
      return state
        .set('selectedTeam', action.payload)
        .set('selectedUser', false)
        .set('selectedActivity', false)
        .set('selectedOrganisation', false);

    default:
      return state;
  }
}

export function stats(state = null, action) {
  switch (action.type) {
    case actions.GET_STATS.SUCCESS:
      return fromJS(action.payload);
    default:
      return state;
  }
}

export function userReportsTableSpec(
  state = fromJS({ sort: { field: '', reversed: false }, filter: {} }),
  action
) {
  switch (action.type) {
    case actions.USER_REPORTS_ADD_FILTER:
      return state.set(
        'filter',
        state.get('filter').merge(fromJS(action.payload))
      );
    case actions.USER_REPORTS_CLEAR_FILTER:
      return state.set('filter', Map());
    case actions.USER_REPORTS_REVERSE_SORT:
      return state.setIn(
        ['sort', 'reversed'],
        !state.getIn(['sort', 'reversed'])
      );
    case actions.USER_REPORTS_SORT_BY:
      return state.set('sort', Map({ field: action.payload, reversed: false }));
    default:
      return state;
  }
}

export function userGroupsTableSpec(
  state = fromJS({ sort: { field: '', reversed: false } }),
  action
) {
  switch (action.type) {
    case actions.USER_GROUPS_REVERSE_SORT:
      return state.setIn(
        ['sort', 'reversed'],
        !state.getIn(['sort', 'reversed'])
      );
    case actions.USER_GROUPS_SORT_BY:
      return state.set('sort', Map({ field: action.payload, reversed: false }));
    default:
      return state;
  }
}

export function shortlivedToken(state = Map(), action) {
  switch (action.type) {
    case actions.GET_TOKEN.REQUEST:
      return Map();
    case actions.GET_TOKEN.SUCCESS:
      return Map({
        token: action.payload.token,
        expires: moment().add(4, 'minutes'),
      });
    case actions.GET_TOKEN.FAILURE:
    default:
      return state;
  }
}

export function selectUserQuery(state = Map(), action) {
  switch (action.type) {
    case actions.UPDATE_SELECT_USER_QUERY:
      const { key, value } = action.payload;
      return state.set(key, value);
    default:
      return state;
  }
}

const DEFAULT_MAP_PRODUCT_VERSION_STATE = Map({
  timerRunning: false,
  timedOut: false,
  page: null,
});

export function mapProductVersionState(
  state = DEFAULT_MAP_PRODUCT_VERSION_STATE,
  action
) {
  switch (action.type) {
    case items.LOAD_ITEM.REQUEST:
      return DEFAULT_MAP_PRODUCT_VERSION_STATE;

    case actions.ANSWER_QUESTION.FAILURE:
      if (action.payload.response.msg === 'This session is complete') {
        return state.set('timedOut', true);
      }
      return state;

    case actions.SET_MAP_PRODUCT_VERSION_PAGE:
      return state.set('page', action.payload);

    case actions.START_MAP_PRODUCT_VERSION_TIMER:
      return state.set('timerRunning', true);

    default:
      return state;
  }
}

export function timer(state = Map(), action) {
  switch (action.type) {
    case actions.TIMER_START:
      return Map({
        started: moment(),
        elapsed: 0,
      });

    case actions.TIMER_TICK:
      return state.set('elapsed', moment().diff(state.get('started')));

    default:
      return state;
  }
}

export function organisationDetailSimple(state = Map(), action) {
  switch (action.type) {
    case actions.GET_SIMPLE_ORGANISATION.SUCCESS:
      return fromJS(action.payload);
    case actions.RESET_SIMPLE_ORGANISATION:
      return Map();
    default:
      return state;
  }
}
