import React, { useCallback } from 'react'; // eslint-disable-line no-unused-vars
import _ from 'underscore';
import { List } from 'immutable';
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import BlankOption from '../BlankOption';
import SelectOrganisation from '../SelectOrganisation';

export const AverageValuesForm = props => {
  const {
    activities,
    productVersions,
    categories,
    shortlivedToken,
    requestPending,
    response,
    viewAveragesReport,
    closeModalAndTryViewExport,
    getToken,
    onOrganisationChange,
  } = props;

  const getRequestData = useCallback(values => {
    const isActivityData = values.activityOrProductVersion === 'activity';

    const data = {
      organisation: values.organisation,
      category: values.category,
      activity: isActivityData && values.activityOrProductVersionId,
      productVersion: !isActivityData && values.activityOrProductVersionId,
    };

    return _.pick(data, _.identity);
  }, []);

  const viewReport = useCallback(
    values => {
      viewAveragesReport(getRequestData(values));
    },
    [viewAveragesReport, getRequestData]
  );

  const exportCSV = useCallback(
    values => {
      closeModalAndTryViewExport(
        shortlivedToken,
        'ORG_AVERAGES',
        getRequestData(values)
      );
    },
    [shortlivedToken, closeModalAndTryViewExport, getRequestData]
  );

  const organisationChanged = useCallback(
    (value, setFieldValue) => {
      getToken();
      setFieldValue('category', '');
      setFieldValue('activityOrProductVersionId', '');
      setFieldValue('organisation', value);
      onOrganisationChange(value);
    },
    [getToken, onOrganisationChange]
  );

  const categoryChanged = useCallback(
    (value, setFieldValue) => {
      getToken();
      setFieldValue('activityOrProductVersionId', '');
      setFieldValue('category', value);
    },
    [getToken]
  );

  const activityOrProductVersionChanged = useCallback(
    (value, setFieldValue) => {
      getToken();
      setFieldValue('activityOrProductVersion', value);
      setFieldValue('activityOrProductVersionId', '');
    },
    [getToken]
  );

  const mapCollectionToOptions = useCallback(collection => {
    return collection
      .map(item => (
        <option key={item.get('id')} value={item.get('id')}>
          {item.get('name')}
        </option>
      ))
      .toArray();
  }, []);

  const flattenCategoriesAndMapToOptions = useCallback(() => {
    return categories
      .map(category =>
        category
          .get('categoryitems', List())
          .map(categoryItem =>
            categoryItem.set('categoryName', category.get('name'))
          )
      )
      .flatten(1)
      .map(option => (
        <option key={option.get('id')} value={option.get('id')}>
          {option.get('categoryName')}: {option.get('name')}
        </option>
      ));
  }, [categories]);

  const formatActivityOrProductVersion = useCallback(
    activityOrProductVersion => {
      if (activityOrProductVersion === 'activity') {
        return i18next.t('Activity (optional)');
      }
      return i18next.t('Product (optional)');
    },
    []
  );

  return (
    <div>
      <h3>{i18next.t('View / export average values')}</h3>
      <Formik
        initialValues={{
          organisation: '',
          category: '',
          activityOrProductVersion: 'activity',
          activityOrProductVersionId: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="row mb-none">
              <div className="col-xs-12 col-md-6">
                <div className="form-block">
                  <SelectOrganisation
                    name="organisation"
                    value={values.organisation}
                    response={response}
                    onChange={value =>
                      organisationChanged(value, setFieldValue)
                    }
                  />
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div className="form-group-block">
                  <label htmlFor="category">
                    {i18next.t('Category (optional)')}
                  </label>
                  <div className="form-item">
                    <Field
                      as="select"
                      name="category"
                      id="category"
                      disabled={requestPending || !values.organisation}
                      onChange={() =>
                        categoryChanged(values.category, setFieldValue)
                      }
                    >
                      <BlankOption
                        response={response}
                        disabled={values.category}
                      />
                      {flattenCategoriesAndMapToOptions()}
                    </Field>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-none">
              <div className="col-xs-12 col-md-6">
                <div className="form-group-block">
                  <label htmlFor="activityOrProductVersion">
                    {i18next.t('View for Activity / Survey (optional)')}
                  </label>
                  <div className="form-item">
                    <Field
                      as="select"
                      name="activityOrProductVersion"
                      id="activityOrProductVersion"
                      disabled={requestPending || !values.organisation}
                      onChange={() =>
                        activityOrProductVersionChanged(
                          values.activityOrProductVersion,
                          setFieldValue
                        )
                      }
                    >
                      <option value="activity">{i18next.t('Activity')}</option>
                      <option value="productVersion">
                        {i18next.t('Product')}
                      </option>
                    </Field>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div className="form-group-block">
                  <label htmlFor="activityOrProductVersionId">
                    {formatActivityOrProductVersion(
                      values.activityOrProductVersion
                    )}
                  </label>
                  <div className="form-item">
                    <Field
                      as="select"
                      name="activityOrProductVersionId"
                      id="activityOrProductVersionId"
                      disabled={requestPending || !values.organisation}
                    >
                      <BlankOption
                        response={response}
                        disabled={values.activityOrProductVersionId}
                      />
                      {values.activityOrProductVersion === 'activity'
                        ? mapCollectionToOptions(activities)
                        : mapCollectionToOptions(productVersions)}
                    </Field>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-none">
              <div className="col-xs-12">
                <span className="pull-right">
                  <button
                    className="btn btn-default"
                    onClick={() => viewReport(values)}
                    disabled={requestPending || !values.organisation}
                  >
                    {i18next.t('View Report')}
                  </button>

                  <button
                    className="btn btn-primary"
                    onClick={() => exportCSV(values)}
                    disabled={
                      requestPending || !values.organisation || !shortlivedToken
                    }
                  >
                    {i18next.t('Export CSV')}
                  </button>
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AverageValuesForm;
