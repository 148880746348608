import classnames from 'classnames';
import React, { ReactNode, FunctionComponent } from 'react';

interface OwnProps {
  id: string;
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  inline?: boolean;
  value?: string;
  labelClassName?: string;
  children?: ReactNode;
}

type Props = OwnProps;

const RadioButton: FunctionComponent<Props> = ({
  id,
  name,
  value,
  checked,
  disabled,
  onChange,
  label,
  inline,
  labelClassName,
  children,
}) => (
  <div
    className={classnames('form-radio-wrapper', {
      inline,
      'semi-bold-text': checked,
    })}
  >
    <span className="radio">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={!disabled ? onChange : undefined}
      />
      <span className="content" />
    </span>
    {label && (
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    )}
    {children}
  </div>
);

export default RadioButton;
