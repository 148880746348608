import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import PureComponent from './PureComponent';
import LoginForm from './LoginForm';
import {
  clearTokenAndLogin,
  setLanguageAndReload,
} from '../actions/actionSequences';
import NoAuth from './NoAuth';
import { withRouter } from '^/withRouter';
import { getQueryValue } from '^/utils-ts';

export class Login extends PureComponent {
  componentDidMount() {
    const {
      urlLangCode,
      router: { location: pathname },
    } = this.props;
    urlLangCode && this.props.setLanguageAndReload(urlLangCode, pathname);
  }

  login(data) {
    const {
      activity,
      userPulseId,
      currentLanguage,
      switcherInteractedWith,
    } = this.props;
    this.props
      .clearTokenAndLogin(
        data.email,
        data.password,
        activity,
        userPulseId,
        currentLanguage,
        switcherInteractedWith
      )
      .catch(() => null);
  }

  forgotPassword(evt) {
    evt.preventDefault();
    if (this.props.userPulseId) {
      this.props.push(
        `/forgotPassword?next=/login?userpulse=${this.props.userPulseId}`
      );
    } else {
      this.props.push('/forgotPassword');
    }
  }

  render() {
    const { response, logoutResponse, userPulseId } = this.props;
    return (
      <NoAuth>
        <div>
          <h2>{i18next.t('Log in')}</h2>
          <LoginForm
            response={response}
            userPulse={userPulseId}
            onSubmit={this.login.bind(this)}
            forgotPassword={evt => this.forgotPassword(evt)}
            logoutResponse={logoutResponse}
          />
        </div>
      </NoAuth>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    logoutResponse: state.logoutResponse,
    response: state.responses.get('login'),
    activity: props.router.params.activity,
    userPulseId: getQueryValue(props, 'userpulse'),
    currentLanguage: state.ui.get('uiLanguage'),
    switcherInteractedWith: state.ui.get('languageSwitcherInteractedWith'),
    urlLangCode: getQueryValue(props, 'lang_code'),
  };
}

export default withRouter(
  connect(mapStateToProps, { push, clearTokenAndLogin, setLanguageAndReload })(
    Login
  )
);
