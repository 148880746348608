import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '^/components/PureComponent';
import WithPopover from '^/components/WithPopover';
import { Choices } from '^/utils-ts';

interface Props {
  choices: Choices;
  className?: string;
  popoverClassName?: string;
  disabledMessage?: string;
  popoverMessages?: Record<string, string>;
  selected: string;
  readOnly?: boolean;
  loading?: boolean;
  onChange: (value: string) => void;
}

export default class RadioToggle extends PureComponent<Props> {
  public render() {
    const {
      choices,
      readOnly,
      disabledMessage,
      popoverMessages,
      selected,
      loading,
    } = this.props;

    return (
      <>
        {choices.ORDER.map(choice => (
          <div
            key={choice}
            className={classNames('form-toggle-button', this.props.className)}
          >
            <WithPopover
              className={classNames('inline', this.props.popoverClassName)}
              popover={
                (readOnly && disabledMessage) ||
                (popoverMessages && popoverMessages[choice])
              }
              below
              wide
            >
              <input
                className="form-toggle-input"
                type="radio"
                id={choice}
                value={choice}
                checked={choice === selected}
                disabled={loading || readOnly}
                onChange={this.handleOnChange}
              />

              <label className="btn btn-small" htmlFor={choice} tabIndex={0}>
                {choices.DISPLAY[choice]}
              </label>
            </WithPopover>
          </div>
        ))}
      </>
    );
  }

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, selected } = this.props;

    if (event.target.value !== selected) {
      onChange(event.target.value);
    }
  };
}
