import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';

import { forDisplay, USER_STATUS } from '^/models/user';
import {
  ACCOUNT_ADMIN_ROLE_DISPLAY,
  ACCOUNT_TYPE,
  getAvailableAdminRoles,
  accountCanAddAdmins,
  accountCanHaveAdmins,
  allowsMore,
} from '^/models/organisation';
import {
  expandUiComponent,
  collapseUiComponent,
  removeAdminFromOrganisation,
} from '^/actions/actions';
import { openModal, openEditProductAccessModal } from '^/actions/modals';
import { addAdminToOrganisationAndResetForm } from '^/actions/actionSequences';
import {
  can,
  changeUsersState,
  createUsersWithRole,
  administerOrganisations,
} from '^/capabilities';
import PureComponent from '^/components/PureComponent';
import InviteUserModal from '^/components/users/InviteUserModal';
import AddAccountAdminForm from './AddAccountAdminForm';
import ProductAccessDisplay from './ProductAccessDisplay';
import AdminLimits from './AdminLimits';
import WithPopover from '^/components/WithPopover';

const ADD_ACCOUNT_ADMIN_FORM = 'ADD_ACCOUNT_ADMIN_FORM';

export class EditAccountAdmins extends PureComponent {
  expandAddForm() {
    this.props.expandUiComponent(ADD_ACCOUNT_ADMIN_FORM);
  }

  UNSAFE_componentWillMount() {
    this.props.collapseUiComponent(ADD_ACCOUNT_ADMIN_FORM);
  }

  addAccountAdminAndCollapseAddForm(accountId, data) {
    this.props.addAdminToOrganisationAndResetForm(
      accountId,
      data.email,
      data.full_name,
      data.role || undefined,
      ADD_ACCOUNT_ADMIN_FORM
    );
  }

  confirmAndDeleteAdmin(roleDisplay, organisationId, userId) {
    if (
      window.confirm(
        i18next.t(
          'Are you sure you wish to remove {{roleDisplay}} from this account?',
          { roleDisplay }
        )
      )
    ) {
      this.props.removeAdminFromOrganisation(organisationId, userId);
    }
  }

  showInviteUserModal(user) {
    const { account } = this.props;
    const isReinvite = user.get('status') === USER_STATUS.INVITED;
    this.props.openModal({
      title: isReinvite
        ? i18next.t('Resend invite to {{userEmail}}', {
            userEmail: user.get('email'),
          })
        : i18next.t('Send invite to {{userEmail}}', {
            userEmail: user.get('email'),
          }),
      body: (
        <InviteUserModal
          userId={user.get('id')}
          organisationId={account.get('id')}
          userName={user.get('full_name')}
          invitationEmail={user.get('email')}
          isReinvite={isReinvite}
        />
      ),
    });
  }

  renderStatus(user) {
    const { status } = user.toObject();
    return <div>{forDisplay(status)}</div>;
  }

  renderInviteButton(user) {
    const { status } = user.toObject();
    switch (status) {
      case USER_STATUS.UNINVITED:
        return (
          <WithPopover
            className="asset-count inline"
            popover={i18next.t('Send invite')}
          >
            <a
              className="fa-link"
              onClick={() => this.showInviteUserModal(user)}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </WithPopover>
        );
      case USER_STATUS.INVITED:
        return (
          <WithPopover
            className="asset-count inline"
            popover={i18next.t('Resend invite')}
          >
            <a
              className="fa-link"
              onClick={() => this.showInviteUserModal(user)}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </WithPopover>
        );
      default:
        return null;
    }
  }

  render() {
    const { account, userProfile, addAdminResponse, readOnly } = this.props;
    const {
      id: accountId,
      admins,
      primary_superuser: primarySuperuser,
    } = account.toObject();
    const accountCanAddAccountAdmins = accountCanAddAdmins(account);
    const accountCanHaveAccountAdmins = accountCanHaveAdmins(account);
    const availableRoles = getAvailableAdminRoles(account).filter(eachRole =>
      can(userProfile, createUsersWithRole(eachRole))
    );
    const accountNeedsSuperUser =
      accountCanHaveAccountAdmins && !primarySuperuser;
    const isEnterpriseAccount =
      account.get('account_type') === ACCOUNT_TYPE.ENTERPRISE;
    const isAdmin = can(userProfile, administerOrganisations());
    const licenceHolders = admins.filter(admin => admin.get('licence_holder'));

    return (
      <div>
        <AdminLimits
          account={account}
          onUpdate={data => this.updateAccount(data)}
          readOnly={readOnly}
        />
        {!!availableRoles.length &&
          (accountCanAddAccountAdmins || accountNeedsSuperUser) && (
            <AddAccountAdminForm
              availableRoles={availableRoles}
              initialValues={{
                role: availableRoles.length === 1 ? availableRoles[0] : '',
              }}
              response={addAdminResponse}
              onSubmit={data =>
                this.addAccountAdminAndCollapseAddForm(accountId, data)
              }
              disabledRoles={availableRoles.filter(
                role => !allowsMore(account, role)
              )}
            />
          )}

        <table>
          <thead>
            <tr>
              <th>{i18next.t('Name')}</th>
              <th>{i18next.t('Email')}</th>
              <th>{i18next.t('Admin type')}</th>
              {isEnterpriseAccount && <th>{i18next.t('Licence holder')}</th>}
              <th>{i18next.t('Accreditation completed')}</th>
              <th>{i18next.t('Status')}</th>
              <th>{i18next.t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {admins.map(user => {
              const {
                id,
                full_name,
                email,
                role,
                licence_holder,
              } = user.toObject();
              const roleDisplay = ACCOUNT_ADMIN_ROLE_DISPLAY[role];
              return (
                <tr key={id}>
                  <td>
                    <Link to={`/page/individuals/${id}`}>{full_name}</Link>
                  </td>
                  <td>{email}</td>
                  <td>{roleDisplay}</td>
                  {isEnterpriseAccount && (
                    <td
                      title={
                        !isAdmin &&
                        'Please contact enablesupport@peoplewise.co.uk to update the licence holder'
                      }
                    >
                      {licence_holder && (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color="#628a2b"
                          size="lg"
                        />
                      )}
                    </td>
                  )}
                  <td>
                    <ProductAccessDisplay user={user} account={account} />
                  </td>
                  <td>{this.renderStatus(user)}</td>
                  <td>
                    <span>
                      {can(userProfile, changeUsersState(user)) && (
                        <a
                          className="fa-link"
                          onClick={() =>
                            this.props.openEditProductAccessModal(
                              user.get('id'),
                              account,
                              licenceHolders
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </a>
                      )}
                      {this.renderInviteButton(user)}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    userProfile: state.userProfile,
    removeAdminResponses: state.multiResponses.get(
      'removeAdminFromOrganisation'
    ),
    addAdminResponse: state.responses.get('addAdminToOrganisation'),
  };
}

export default connect(mapStateToProps, {
  removeAdminFromOrganisation,
  openEditProductAccessModal,
  expandUiComponent,
  collapseUiComponent,
  addAdminToOrganisationAndResetForm,
  openModal,
})(EditAccountAdmins);
