import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Link } from 'react-router';
import Skeleton from 'react-loading-skeleton';

import PureComponent from '^/components/PureComponent';
import { formatDateTime } from '^/utils';
import { ACTIVITY_TYPES } from '^/components/productVersions/choices';
import { getUserDisplayName } from '^/selectors/user';

const ActivityCardSkeleton = ({ showCreator }) => (
  <div className="activity-card placeholder">
    <h4 className="activity-card-title">
      <Skeleton />
    </h4>
    <ul className="data-list">
      <li>
        <Skeleton />
      </li>
      <li>
        <Skeleton />
      </li>
    </ul>

    <div className="activity-card-section">
      <h5 className="activity-card-section-title">
        <Skeleton />
      </h5>
      <ul className="data-list">
        <li>
          <Skeleton />
        </li>
        <li>
          <Skeleton />
        </li>
      </ul>
    </div>

    {showCreator && (
      <div className="activity-card-section bottom">
        <ul className="data-list">
          <li>
            <Skeleton />
          </li>
        </ul>
      </div>
    )}
  </div>
);

export class ActivityCard extends PureComponent {
  render() {
    const { user, activity, showCreator, showAccount } = this.props;
    if (!activity) {
      return <ActivityCardSkeleton showCreator={showCreator} />;
    }

    const {
      id,
      type,
      name,
      start_datetime,
      end_datetime,
      user_count,
      rater_count,
      is_prepay,
      activity_product_version_items,
      created_by_user,
      organisation,
    } = activity.toObject();

    const isThreeSixty = type === ACTIVITY_TYPES.CHOICES.THREE_SIXTY;

    return (
      <div className="activity-card">
        <h4 className="activity-card-title">
          <div className="activity-card-title-link-wrapper">
            <Link to={`/page/activities/${id}`}>{name}</Link>
          </div>
        </h4>
        <ul className="data-list">
          <li>
            <span className="data-label">{i18next.t('Start')}</span>
            <span className="data-value">{formatDateTime(start_datetime)}</span>
          </li>
          <li>
            <span className="data-label">{i18next.t('Close')}</span>
            <span className="data-value">{formatDateTime(end_datetime)}</span>
          </li>
        </ul>

        <div className="activity-card-section middle">
          <h5 className="activity-card-section-title">Completion Summary</h5>
          {isThreeSixty ? (
            <ul className="data-list three-sixty">
              <li>
                <span className="data-label">
                  {i18next.t('360 respondents')}
                </span>
                <span className="data-value">
                  {activity_product_version_items.getIn([
                    0,
                    'user_sessions_count',
                  ])}
                  /{user_count}
                </span>
              </li>
              <li>
                <span className="data-label">{i18next.t('360 raters')}</span>
                <span className="data-value">
                  {activity_product_version_items.getIn([
                    0,
                    'rater_sessions_count',
                  ])}
                  /{rater_count}
                </span>
              </li>
            </ul>
          ) : (
            <ul className="data-list two-up">
              {activity_product_version_items.map(
                (activityProductVersion, index) => (
                  <li key={index}>
                    <span className="data-label">
                      {activityProductVersion.get('product_code')}
                    </span>
                    <span className="data-value">
                      {activityProductVersion.get('user_sessions_count')}
                      {!is_prepay ? `/${user_count}` : ''}
                    </span>
                  </li>
                )
              )}
            </ul>
          )}
        </div>

        {showCreator && (
          <div className="activity-card-section bottom">
            <ul className="data-list">
              <li>
                <span className="data-label">{i18next.t('Admin')}</span>
                <span className="data-value">
                  {getUserDisplayName(user, created_by_user)}
                </span>
              </li>
              {showAccount && (
                <li>
                  <span className="data-label">{i18next.t('Account')}</span>
                  <span className="data-value">{organisation.get('name')}</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps)(ActivityCard);
