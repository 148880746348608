import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';

import PureComponent from './PureComponent';
import WithPopover from './WithPopover';

export default class TaskStatus extends PureComponent {
  getClassName() {
    const { className } = this.props;
    return 'task-status-wrapper' + (className ? ` ${className}` : '');
  }

  getLabelClassName() {
    const { inactive } = this.props;
    return 'task-label' + (inactive ? ' task-label-inactive' : '');
  }

  getIconType() {
    const { raters, survey, report } = this.props;

    if (raters) {
      return 'raters';
    } else if (survey) {
      return 'survey';
    } else if (report) {
      return 'report';
    }
    return null;
  }

  getIconPath(iconType, inactive) {
    return `/static/img/icons/task-${iconType}-icon${
      inactive ? '-disabled' : ''
    }.png`;
  }

  renderType() {
    const { inactive } = this.props;
    const iconType = this.getIconType();

    return (
      iconType && (
        <div className="task-type">
          <img
            src={this.getIconPath(iconType, inactive)}
            className="icon-task-type"
          />
        </div>
      )
    );
  }

  renderLabel() {
    const { onClick, label, inactive } = this.props;
    const props = { className: this.getLabelClassName() };

    return onClick && !inactive ? (
      <a onClick={onClick} {...props}>
        {label}
      </a>
    ) : (
      <span {...props}>{label}</span>
    );
  }

  renderStatusIcon() {
    const { progress, warning, done, loading } = this.props;

    if (warning) {
      return (
        <FontAwesomeIcon
          className="text-warning"
          icon={faExclamationTriangle}
        />
      );
    } else if (done) {
      return <FontAwesomeIcon className="text-success" icon={faCheck} />;
    } else if (progress) {
      return <FontAwesomeIcon className="text-info" icon={faCircleNotch} />;
    } else if (loading) {
      return (
        <FontAwesomeIcon className="text-info" icon={faSpinnerThird} spin />
      );
    }
  }

  renderStatus() {
    const statusIcon = this.renderStatusIcon();

    return statusIcon && <div className="task-status">{statusIcon}</div>;
  }

  render() {
    const { popover } = this.props;

    return (
      <div className={this.getClassName()}>
        {this.renderType()}
        <WithPopover className="task-label" popover={popover}>
          {this.renderLabel()}
        </WithPopover>
        {this.renderStatus()}
      </div>
    );
  }
}

TaskStatus.propTypes = {
  label: PropTypes.string.isRequired,
  popover: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  inactive: PropTypes.bool,
  warning: PropTypes.bool,
  loading: PropTypes.bool,
  progress: PropTypes.bool,
  done: PropTypes.bool,
  raters: PropTypes.bool,
  survey: PropTypes.bool,
  report: PropTypes.bool,
};
