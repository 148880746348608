import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from './PureComponent';
import BasketActionBar from '^/components/shop/BasketActionBar';

export default class Main extends PureComponent {
  render() {
    return (
      <div className="main">
        <div className="container">
          {this.props.children}
          <BasketActionBar />
        </div>
      </div>
    );
  }
}
