import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

export default ({ className, width, height }: Props) => (
  <svg
    width={width || '14'}
    height={height || '14'}
    className={className}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41 20.5C41 31.8218 31.8218 41 20.5 41C9.17816 41 0 31.8218 0 20.5C0 9.17816 9.17816 0 20.5 0C31.8218 0 41 9.17816 41 20.5ZM37.9991 20.6813C37.9018 30.2628 30.1045 38 20.5 38C10.8955 38 3.09818 30.2628 3.00092 20.6813H11.8839C12.6992 20.6813 13.4329 20.1864 13.7383 19.4305L17.3139 10.5795L22.2146 32.9392C22.6432 34.8946 25.3604 35.0774 26.047 33.1969L30.6165 20.6813H37.9991ZM37.7742 17.6813H29.917C29.077 17.6813 28.3264 18.2063 28.0383 18.9954L24.4086 28.9369L19.5518 6.77781C19.1308 4.85666 16.4805 4.6333 15.7438 6.45686L11.2093 17.6813H3.22581C4.57379 9.3565 11.7946 3 20.5 3C29.2054 3 36.4262 9.3565 37.7742 17.6813Z"
      fill="currentColor"
    />
  </svg>
);
