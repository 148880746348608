import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { updateUser } from '^/actions/collections';
import { closeTopModal } from '^/actions/modals';
import { getAvailableAdminRoles } from '^/models/organisation';
import PureComponent from '^/components/PureComponent';
import EditAccountAdminDetails from './EditAccountAdminDetails';
import EditAdminProducts from './EditAdminProducts';

export class EditAccountAdminProductsModal extends PureComponent {
  render() {
    const { user, account, response, licenceHolders } = this.props;

    if (!user) {
      return null;
    }

    const accountProducts = account
      .get('pure_products')
      .filter(product => product.get('requires_accreditation'));

    return (
      <div>
        <EditAccountAdminDetails
          response={response}
          availableRoles={getAvailableAdminRoles(account)}
          user={user}
          account={account}
          licenceHolders={licenceHolders}
        />

        <EditAdminProducts user={user} accountProducts={accountProducts} />

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Done')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  const admins = state.items.getIn(['organisations', 'admins']);
  return {
    response: state.responses.get('addToCollection'),
    user: admins && admins.find(admin => admin.get('id') === ownProps.userId),
  };
}

export default connect(mapStateToProps, { updateUser, closeTopModal })(
  EditAccountAdminProductsModal
);
