import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { resetResponse } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { addGroupToOrganisationOpenGroup } from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import CreateGroupForm from '^/components/groups/CreateGroupForm';

export class CreateGroupModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.resetResponse('addToCollection');
  }

  addGroupAndCloseModal(data) {
    const { organisation, filters } = this.props;
    let orgId;
    if (organisation) {
      orgId =
        typeof organisation === 'string'
          ? organisation
          : organisation.get('id');
    }
    this.props.addGroupToOrganisationOpenGroup(
      data.organisation || orgId,
      data.name,
      filters
    );
  }

  render() {
    const { response, organisation, organisations, filters } = this.props;
    return (
      <div>
        {filters && (
          <p>
            {i18next.t('Enter a name for your group and select create group.')}
          </p>
        )}
        <CreateGroupForm
          onSubmit={this.addGroupAndCloseModal.bind(this)}
          onClose={this.props.closeTopModal}
          response={response}
          showOrganisationSelector={!organisation}
          organisations={organisations}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    user: state.userProfile,
    response: state.responses.get('addToCollection'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  addGroupToOrganisationOpenGroup,
  resetResponse,
})(CreateGroupModal);
