import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { administerOrganisations, can } from '^/capabilities';
import { Organisation } from '^/reducers/api/types/organisation';
import { selectUserProfile } from '^/selectors';
import { StoreState } from '^/store';

export const selectUserOrganisation = createSelector(selectUserProfile, user =>
  user.get('organisation')
);

export const selectUserOrganisationJS = createSelector(
  selectUserOrganisation,
  organisation =>
    (organisation && organisation.toJS()) as Organisation | undefined
);

export const selectUserOrgId = createSelector(
  selectUserOrganisation,
  organisation => organisation && organisation.get('id')
);

export const selectUserOrgCredit = createSelector(
  selectUserOrganisation,
  organisation => organisation && organisation.get('credit')
);

const selectCapabilityProp = (_state: StoreState, capability: any) =>
  capability;

export const selectUserCapability = createCachedSelector(
  selectUserProfile,
  selectCapabilityProp,
  (user, capability) => can(user, capability)
)((_state, capability) => capability.type);

export const getUserDisplayName = (
  selfUser: Immutable.Map<string, any>,
  targetUser: Immutable.Map<string, any>
) =>
  selfUser &&
  targetUser &&
  (!can(selfUser, administerOrganisations()) &&
  can(targetUser, administerOrganisations())
    ? 'PeopleWise'
    : targetUser.get('full_name'));
