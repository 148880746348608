import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { List } from 'immutable';

import PureComponent from '../PureComponent';
import { updateProgress } from '../../actions/actions';
import { closeTopModal } from '^/actions/modals';
import { importUsersAndPollProgress } from '../../actions/actionSequences';
import {
  getOrganisations,
  getUsers,
  getUserImports,
} from '../../actions/collections';
import { loadGroup } from '^/actions/items';
import ImportUsersForm from './ImportUsersForm';

export class ImportUsersModal extends PureComponent {
  componentDidMount() {
    this.props.updateProgress('importUsers', 0);
  }

  closeAndRefresh() {
    if (this.props.groupId) {
      this.props.loadGroup(this.props.groupId);
    }
    this.props.getUsers();
    this.props.getUserImports();
    this.props.closeTopModal();
  }

  importUsers(data) {
    this.props.importUsersAndPollProgress(
      data.organisationId || this.props.userOrganisationId,
      data.users,
      'importUsers',
      this.props.groupId
    );
  }

  render() {
    return (
      <div>
        <ImportUsersForm
          response={this.props.response}
          onSubmit={this.importUsers.bind(this)}
          progress={this.props.progress}
          onClose={() => this.closeAndRefresh()}
          groupId={this.props.groupId}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userOrganisationId: state.userProfile.getIn(['organisation', 'id']),
    organisations: state.collections.getIn(['organisations', 'items'], List()),
    response: state.responses.get('importUsers'),
    progress: state.progress.get('importUsers'),
  };
}

export default connect(mapStateToProps, {
  importUsersAndPollProgress,
  updateProgress,
  closeTopModal,
  getOrganisations,
  getUsers,
  getUserImports,
  loadGroup,
})(ImportUsersModal);
