import classnames from 'classnames';
import React from 'react';

interface OwnProps {
  id?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inline?: boolean;
  readOnly?: boolean;
  flex?: boolean;
}

type Props = OwnProps;

const Checkbox: React.FunctionComponent<Props> = ({
  id,
  name,
  value,
  checked,
  disabled,
  onChange,
  label,
  inline,
  readOnly,
  flex,
}) => (
  <div
    className={classnames('form-checkbox-wrapper', {
      inline,
      flex,
      'semi-bold-text': checked,
    })}
  >
    <span className="checkbox">
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={!disabled ? onChange : undefined}
        readOnly={readOnly}
      />
      <span className="content" />
    </span>
    <label htmlFor={id}>{label}</label>
  </div>
);

export default Checkbox;
