import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { faDownload, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { isPending } from '^/responseStates';
import PureComponent from '^/components/PureComponent';
import LiveButton from '^/components/LiveButton';
import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import { getToken } from '^/actions/actions';
import { loadOrganisation } from '^/actions/items';
import { openPurchaseCreditsModal, openCreditAddModal } from '^/actions/modals';
import { CREDIT_HISTORY, ORG_CREDIT_HISTORY } from '^/middleware/reports';
import { selectUserCapability } from '^/selectors/user';
import { selectFilterSpec } from '^/selectors/ui';
import { administerOrganisations } from '^/capabilities';
import { CREDIT_EXPORTER_DATE_RANGE_MAX } from '^/components/credits/constants';

export class CreditTopLinks extends PureComponent {
  UNSAFE_componentWillMount() {
    const { organisationId, canUserAdministerOrganisations } = this.props;
    if (organisationId && canUserAdministerOrganisations) {
      this.props.loadOrganisation(organisationId);
    }
  }

  componentDidMount() {
    this.props.getToken();
  }

  createCsv() {
    this.props.getToken();
    const { filters, shortlivedToken, organisationId } = this.props;

    if (organisationId) {
      this.props.closeModalAndTryViewExport(
        shortlivedToken,
        ORG_CREDIT_HISTORY,
        {
          ...filters,
          organisation: organisationId,
        }
      );
    } else {
      this.props.closeModalAndTryViewExport(
        shortlivedToken,
        CREDIT_HISTORY,
        filters
      );
    }
  }

  render() {
    const {
      organisationId,
      orgHasUnlimitedCredits,
      onPurchaseComplete,
      canUserAdministerOrganisations,
      organisation,
      response,
      filters: { from_date, to_date },
    } = this.props;
    const creditsCanBeAddedToAccount =
      organisationId && !orgHasUnlimitedCredits;

    const duration = moment
      .duration(moment(to_date).diff(moment(from_date)))
      .asDays();

    return (
      <div className="mr-10">
        {creditsCanBeAddedToAccount &&
          (canUserAdministerOrganisations ? (
            <button
              className="btn btn-default button-right-margin"
              onClick={() =>
                this.props.openCreditAddModal(organisation, onPurchaseComplete)
              }
              disabled={isPending(response)}
            >
              <FontAwesomeIcon icon={faPlus} />
              {i18next.t('Add credits')}
            </button>
          ) : (
            <button
              className="btn btn-default button-right-margin"
              onClick={() =>
                this.props.openPurchaseCreditsModal(
                  organisationId,
                  onPurchaseComplete
                )
              }
            >
              <FontAwesomeIcon icon={faPlus} />
              {i18next.t('Purchase credits')}
            </button>
          ))}
        <LiveButton
          additionalButtonClasses="margin-none btn-default"
          icon={faDownload}
          buttonText={i18next.t('Download credit history')}
          pendingMessage={i18next.t('Downloading...')}
          notPrimary
          onClick={() => this.createCsv()}
          disabled={duration > CREDIT_EXPORTER_DATE_RANGE_MAX}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    organisation: state.items.get('organisations'),
    shortlivedToken: state.shortlivedToken,
    filters: selectFilterSpec(state),
    response: state.responses.get('loadItem'),
    canUserAdministerOrganisations: selectUserCapability(
      state,
      administerOrganisations()
    ),
  };
}

export default connect(mapStateToProps, {
  getToken,
  closeModalAndTryViewExport,
  openPurchaseCreditsModal,
  openCreditAddModal,
  loadOrganisation,
})(CreditTopLinks);
