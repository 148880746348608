import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import i18next from 'i18next';
import moment from 'moment';

import { formatDate, isTruthy, ISO_DATE_FORMAT } from '^/utils';
import PureComponent from '^/components/PureComponent';
import Img from '^/components/Img';
import Tag from '^/components/Tag';
import {
  getAccountTypeDisplay,
  ACCOUNT_TYPE_DISPLAY,
  ACCOUNT_TYPE_UPGRADE_HIERARCHY,
  TAX_LOCATION,
  ACCOUNT_TYPE,
} from '^/models/organisation';
import LiveButton from '^/components/LiveButton';
import {
  openUpgradeAccountTypeModal,
  openCreditAddModal,
  openAccountLogoModal,
  openEditTaxDetailsModal,
  openPurchaseCreditsModal,
} from '^/actions/modals';
import { updateOrganisation } from '^/actions/collections';
import DetailRow from '^/components/detail-list/DetailRow';
import InfoIcon from './InfoIcon';
import DatePicker from '^/components/date/DatePicker';

export class EditAccountDetail extends PureComponent {
  getUpgradableType() {
    const { account } = this.props;
    const { account_type: accountType } = account.toObject();
    const index = ACCOUNT_TYPE_UPGRADE_HIERARCHY.indexOf(accountType);
    const nextIndex = index + 1;
    return nextIndex === ACCOUNT_TYPE_UPGRADE_HIERARCHY.length
      ? accountType
      : ACCOUNT_TYPE_UPGRADE_HIERARCHY[nextIndex];
  }

  renderTaxDetails(account) {
    const { readOnly } = this.props;
    const { tax_location, tax_registration_number } = account.toObject();
    const onClick = () => this.props.openEditTaxDetailsModal(account);

    return tax_location || tax_registration_number ? (
      <div>
        {[TAX_LOCATION.DISPLAY[tax_location], tax_registration_number]
          .filter(isTruthy)
          .join(' – ')}
        {!readOnly && (
          <button className="btn btn-small margin-left" onClick={onClick}>
            {i18next.t('Change')}
          </button>
        )}
      </div>
    ) : (
      !readOnly && (
        <button className="btn btn-small" onClick={onClick}>
          {i18next.t('Set tax location and number')}
        </button>
      )
    );
  }

  onLicenseDateChanged(value) {
    this.props.updateOrganisation(this.props.account.get('id'), {
      license_date: moment(value).format(ISO_DATE_FORMAT),
    });
  }

  render() {
    const { account, response, readOnly } = this.props;
    const {
      id,
      brand_logo: logoUrl,
      created,
      license_date: licenseDate,
      credit,
      brand_logo_filename: logoFilename,
      is_using_credit: isUsingCredit,
    } = account.toObject();

    const nextAccountType = this.getUpgradableType();
    const nextAccountTypeDisplay = ACCOUNT_TYPE_DISPLAY[nextAccountType];
    const logoText = logoUrl ? logoFilename : i18next.t('No logo');
    const isEnterpriseAccount =
      account.get('account_type') === ACCOUNT_TYPE.ENTERPRISE;

    return (
      <div className="row account-details">
        <div className="col-md-8 form-inline form-detail">
          <DetailRow label={i18next.t('Account type')}>
            {getAccountTypeDisplay(account)}
            {nextAccountType !== account.get('account_type') && !readOnly && (
              <LiveButton
                additionalButtonClasses="margin-left btn-small"
                buttonText={i18next.t('Upgrade to {{nextAccountTypeDisplay}}', {
                  nextAccountTypeDisplay,
                })}
                pendingMessage={i18next.t('Upgrading...')}
                response={response}
                onClick={() =>
                  this.props.openUpgradeAccountTypeModal(
                    account,
                    nextAccountType
                  )
                }
              />
            )}
            {readOnly && <InfoIcon />}
          </DetailRow>

          {(!readOnly || !isEnterpriseAccount) && (
            <DetailRow label={i18next.t('Start date')}>
              {' '}
              {formatDate(created)}{' '}
            </DetailRow>
          )}

          {isEnterpriseAccount && (
            <DetailRow label={i18next.t('Licence date')}>
              {readOnly ? (
                formatDate(licenseDate)
              ) : (
                <DatePicker
                  value={licenseDate || moment()}
                  onSave={value => this.onLicenseDateChanged(value)}
                  hideBorder
                  noLeftPadding
                  noMinWidth
                />
              )}
            </DetailRow>
          )}

          <DetailRow label={i18next.t('Tax details')}>
            {this.renderTaxDetails(account)}
            {readOnly && <InfoIcon />}
          </DetailRow>
          <DetailRow label={i18next.t('Credit balance')}>
            {`${credit} ${i18next.t('credits')}`}
            {readOnly ? (
              isUsingCredit && (
                <button
                  className="btn btn-default btn-small margin-left"
                  onClick={() =>
                    this.props.openPurchaseCreditsModal(account.get('id'))
                  }
                >
                  {i18next.t('Purchase credits')}
                </button>
              )
            ) : (
              <button
                className="btn btn-default btn-small margin-left"
                onClick={() => this.props.openCreditAddModal(account)}
              >
                {i18next.t('Add credits')}
              </button>
            )}
            {isUsingCredit && (
              <Link
                className="btn btn-default btn-small margin-left"
                to={readOnly ? `/page/credits/` : `/page/credits/${id}/`}
              >
                {i18next.t('View credit history')}
              </Link>
            )}
          </DetailRow>
          {!readOnly && (
            <DetailRow label={i18next.t('Account logo')}>
              {logoUrl && <Tag name={logoText} />}
              <button
                className="btn btn-default btn-small"
                onClick={() => this.props.openAccountLogoModal(account)}
              >
                {logoUrl ? i18next.t('Change logo') : i18next.t('Add logo')}
              </button>
            </DetailRow>
          )}
        </div>
        <div className="col-md-4">
          <Img
            className="account-logo"
            src={logoUrl || '/static/img/icons/accounts.png'}
            cover
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  openUpgradeAccountTypeModal,
  openCreditAddModal,
  openAccountLogoModal,
  openEditTaxDetailsModal,
  updateOrganisation,
  openPurchaseCreditsModal,
})(EditAccountDetail);
