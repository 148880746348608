import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import Checkbox from '^/components/Checkbox';
import EditReportCost from './EditReportCost';

export default class EditReportTemplates extends PureComponent {
  render() {
    const {
      orgReports,
      orgReportCostObjects,
      reports,
      isLoading,
      updateReportResponse,
    } = this.props;
    const multipleReports = reports.count() > 1;

    return (
      <Table
        className="compact reports-table"
        noHeaders
        columns={[
          {
            value: report => {
              const reportId = report.get('id');
              return (
                <Checkbox
                  id={reportId}
                  checked={orgReports && orgReports.contains(reportId)}
                  onChange={
                    orgReports &&
                    multipleReports &&
                    (event =>
                      event.target.checked
                        ? this.props.addReport(reportId)
                        : this.props.removeReport(reportId))
                  }
                  disabled={!orgReports || isLoading(reportId)}
                />
              );
            },
          },
          { value: report => report.get('name') },
          {
            value: report => {
              const orgReportCostObject =
                orgReportCostObjects &&
                orgReportCostObjects.find(
                  each =>
                    each.getIn(['report_template', 'id']) === report.get('id')
                );

              return orgReportCostObject ? (
                <EditReportCost
                  report={report}
                  orgReportCostObject={orgReportCostObject}
                  updateReportResponse={updateReportResponse}
                />
              ) : (
                <span>{report.get('cost')}</span>
              );
            },
            className: 'report-cost',
          },
        ]}
        rows={reports}
      />
    );
  }
}
