import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map } from 'immutable';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import {
  setExportProduct,
  setExportActivity,
  setExportOrganisation,
  setExportUser,
  resetExportSelections,
  getToken,
} from '^/actions/actions';
import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import { isPending } from '^/responseStates';
import ContentBox from '^/components/ContentBox';
import ExportSelect from './ExportSelect';
import { ALL_ID } from '^/middleware/reports';

export class ExportRawScores extends PureComponent {
  componentDidMount() {
    this.props.resetExportSelections();
  }

  onChangeOrganisation(organisation) {
    this.props.getToken();
    this.props.setExportOrganisation(organisation);
  }

  onChangeActivity(activity) {
    this.props.getToken();
    this.props.setExportActivity(activity);
  }

  onChangeProduct(product) {
    this.props.getToken();
    this.props.setExportProduct(product);
  }

  onChangeUser(user) {
    this.props.getToken();
    this.props.setExportUser(user);
  }

  onSubmit() {
    this.props.closeModalAndTryViewExport(
      this.props.shortlivedToken,
      'RAW_SCORES'
    );
  }

  render() {
    const {
      response,
      organisationsActivities,
      selectedOrganisation,
      selectedActivity,
      selectedProduct,
      shortlivedToken,
    } = this.props;

    const isLoading = isPending(response);
    const blankOptionMessage = isLoading ? 'Loading...' : 'Please select...';

    const isReady =
      selectedProduct && shortlivedToken.get('token') && !isLoading;

    const hasSelectedOrganisation =
      selectedOrganisation && selectedOrganisation.get('id') !== ALL_ID;

    const activities =
      hasSelectedOrganisation &&
      (selectedProduct
        ? selectedOrganisation
            .get('activities')
            .filter(activity =>
              activity
                .get('product_versions')
                .find(
                  pv =>
                    pv.getIn(['product', 'id']) === selectedProduct.get('id')
                )
            )
        : selectedOrganisation.get('activities'));

    const users =
      hasSelectedOrganisation &&
      selectedProduct &&
      (selectedActivity
        ? selectedActivity.get('users').map(each => each.get('user'))
        : activities
            .map(activity =>
              activity.get('users').map(user => user.get('user'))
            )
            .flatten(true)
            .reduce((acc, item) => acc.set(item.get('id'), item), Map())
            .valueSeq());

    const productVersions =
      selectedOrganisation &&
      (selectedActivity
        ? selectedActivity.get('product_versions')
        : (selectedOrganisation.get('id') === ALL_ID
            ? organisationsActivities.flatMap(organisation =>
                organisation.get('activities')
              )
            : activities
          )
            .flatMap(activity => activity.get('product_versions'))
            .reduce((acc, item) => acc.set(item.get('id'), item), Map())
            .valueSeq());
    const products =
      productVersions &&
      productVersions
        .map(each => each.get('product'))
        .toOrderedSet()
        .toList()
        .map(product =>
          product.set(
            'showRespondent',
            !!productVersions
              .find(productVersion => productVersion.get('product') === product)
              .get('question_collections')
              .find(each => each.get('question_type') === 'MAP')
          )
        );
    return (
      <ContentBox>
        <h3>{i18next.t('Raw scores')}</h3>
        <form onSubmit={this.onSubmit.bind(this)}>
          <div className="row mb-none">
            <ExportSelect
              selectName={i18next.t('Account')}
              disabled={isLoading}
              onChange={this.onChangeOrganisation.bind(this)}
              blankOptionMessage={blankOptionMessage}
              items={
                organisationsActivities &&
                organisationsActivities.unshift(
                  Map({ id: ALL_ID, name: i18next.t('All') })
                )
              }
            />
            <ExportSelect
              selectName={i18next.t('Activity')}
              disabled={isLoading}
              onChange={this.onChangeActivity.bind(this)}
              blankOptionMessage={blankOptionMessage}
              items={activities}
            />
          </div>
          <div className="row mb-none">
            <ExportSelect
              selectName={i18next.t('Product')}
              disabled={isLoading}
              onChange={this.onChangeProduct.bind(this)}
              blankOptionMessage={blankOptionMessage}
              items={products}
            />
            {hasSelectedOrganisation &&
              selectedProduct &&
              selectedProduct.get('showRespondent') && (
                <ExportSelect
                  selectName={i18next.t('Respondent')}
                  disabled={isLoading}
                  onChange={this.onChangeUser.bind(this)}
                  blankOptionMessage={
                    isLoading
                      ? i18next.t('Loading...')
                      : i18next.t('Optionally Select...')
                  }
                  items={users.sortBy(user => user.get('full_name'))}
                  nameFunction={user =>
                    user.get('full_name') || user.get('email')
                  }
                />
              )}
          </div>

          <div className="row mb-none">
            <div className="col-xs-12 col-md-6 pull-right">
              <button
                className="btn btn-primary pull-right"
                type="submit"
                disabled={!isReady}
              >
                {i18next.t('Export CSV')}
              </button>
            </div>
          </div>
        </form>
      </ContentBox>
    );
  }
}

export function mapStateToProps(state) {
  return {
    organisationsActivities: state.collections.getIn([
      'organisationsActivities',
      'items',
    ]),
    selectedOrganisation: state.userCSVExportFilter.get(
      'selectedOrganisation',
      false
    ),
    selectedActivity: state.userCSVExportFilter.get('selectedActivity', false),
    selectedProduct: state.userCSVExportFilter.get('selectedProduct', false),
    response: state.multiResponses.getIn([
      'getCollection',
      'organisationsActivities',
    ]),
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  setExportProduct,
  setExportActivity,
  setExportOrganisation,
  setExportUser,
  resetExportSelections,
  closeModalAndTryViewExport,
  getToken,
})(ExportRawScores);
