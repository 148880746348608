import classNames from 'classnames';
import i18next from 'i18next';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { timerStart, timerTick } from '^/actions/actions';
import { StoreState } from '^/store';
import { CountDownCircle } from './CountDownCircle';

interface StateProps {
  elapsedMillis: number;
}

interface DispatchProps {
  timerStart: typeof timerStart;
  timerTick: typeof timerTick;
}

interface OwnProps {
  totalMinutes: number;
  onCountdownComplete?: () => void;
  className?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

const ONE_SECOND = 1000;

export class Countdown extends PureComponent<Props> {
  private timer: number | undefined;

  public componentDidMount() {
    this.props.timerStart();
    this.timer = window.setInterval(this.tick, ONE_SECOND);
  }

  public componentWillUnmount() {
    this.stopTimer();
  }

  public render() {
    const { elapsedMillis, totalMinutes, className } = this.props;
    const totalSeconds = totalMinutes * 60;

    const remainingMinutes = Math.ceil(
      totalMinutes - elapsedMillis / 1000 / 60
    );
    const remainingSeconds = Math.ceil(totalSeconds - elapsedMillis / 1000);

    return (
      <div className={classNames('countdown', className)}>
        <CountDownCircle
          remainingSeconds={remainingSeconds}
          totalSeconds={totalSeconds}
          scale={60}
          style="secondary"
        />
        <p className="countdown-text">
          {i18next.t<string>('Minute left', { count: remainingMinutes })}
        </p>
      </div>
    );
  }

  private tick = () => {
    this.props.timerTick();
    const totalMillis = this.props.totalMinutes * 60 * 1000;

    if (this.props.elapsedMillis >= totalMillis) {
      this.stopTimer();

      if (this.props.onCountdownComplete) {
        this.props.onCountdownComplete();
      }
    }
  };

  private stopTimer() {
    window.clearInterval(this.timer);
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    elapsedMillis: state.timer.get('elapsed'),
  };
}

export default connect(mapStateToProps, { timerStart, timerTick })(Countdown);
