export enum LanguageCode {
  EN_GB = 'en-gb',
  AR = 'ar',
}

export const PAGE_PATH = 'page';
export const PAGE_PATH_FULL = `/${PAGE_PATH}/`;
export const LOGOUT_PATH = 'logout';
export const LOGOUT_PATH_FULL = `/${LOGOUT_PATH}`;
export const TRANSLATED_LANGUAGES = [LanguageCode.EN_GB, LanguageCode.AR];
export const DEFAULT_LANGUAGE_CODE = LanguageCode.EN_GB;
export const RTL_LANGUAGES = [LanguageCode.AR];
export const TRANSLATED_PATHS_ONLY_FOR_SWITCHABLE_USERS = {
  '/page/dashboard': false,
  '/page/profile': true,
  '/page/contact': true,
};
export const TRANSLATED_LANGUAGE_READABLE_MAP = {
  [LanguageCode.EN_GB]: 'English [language option]',
  [LanguageCode.AR]: 'Arabic [language option]',
};
export const TRANSLATED_LANGUAGE_ADMIN_MAP = {
  [LanguageCode.EN_GB]: 'English',
  [LanguageCode.AR]: 'Arabic',
};
