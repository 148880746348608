import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { formatDateTime } from '../../utils';
import PureComponent from '../PureComponent';
import ItemAndLogo from '^/components/ItemAndLogo';
import {
  can,
  administerOrganisations,
  downloadExistingReports,
} from '../../capabilities';
import { SURVEY_STATUS } from '^/models/user';

const ReportWithIcon = ({ report }) => {
  const icon = report.get('icon');
  const label = report.get('template_type_display');

  return (
    <div className="small">
      <a href={report.get('pdf_url')} target="_blank">
        <ItemAndLogo label={label} icon={icon} />
      </a>
    </div>
  );
};

export class ActivityWithReports extends PureComponent {
  renderReportsWithIcons(reports) {
    return (
      <div>
        {reports ? (
          reports.map(report => (
            <span key={report.get('id')}>
              <ReportWithIcon report={report} />
            </span>
          ))
        ) : (
          <span>
            <br />
          </span>
        )}
      </div>
    );
  }

  renderReports(reports) {
    const { product_versions } = this.props.activity.toObject();

    return (
      <div>
        {product_versions.map(productVersion => (
          <span key={productVersion.get('id')}>
            {this.renderReportsWithIcons(
              reports &&
                reports.filter(
                  report =>
                    report.get('product_version') === productVersion.get('id')
                )
            )}
            <br />
          </span>
        ))}
      </div>
    );
  }

  completionState(userId) {
    const activityUser = this.props.activity
      .get('users')
      .find(each => each.get('user') === userId);

    if (activityUser) {
      return SURVEY_STATUS.DISPLAY[activityUser.get('survey_status')];
    }

    return SURVEY_STATUS.DISPLAY.NOT_STARTED;
  }

  render() {
    const { activity, activityReports, user, activeUser } = this.props;
    const reports = activityReports.get(activity.get('id'), List());
    const formatDate = date => (date ? formatDateTime(date) : null);

    return (
      <tr>
        <td>
          <Link to={`/page/activities/${activity.get('id')}/`}>
            {activity.get('name')}
          </Link>
        </td>
        {can(activeUser, administerOrganisations()) && (
          <td>{activity.getIn(['organisation', 'name'])}</td>
        )}
        {can(activeUser, downloadExistingReports()) && (
          <td>{this.renderReports(reports)}</td>
        )}
        <td>{formatDate(activity.get('start_datetime'))}</td>
        <td>{formatDate(activity.get('end_datetime'))}</td>
        <td>{this.completionState(user.get('id'))}</td>
      </tr>
    );
  }
}
ActivityWithReports.propTypes = {
  activity: ImmutablePropTypes.map.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  activeUser: ImmutablePropTypes.map.isRequired,
};

function mapStateToProps(state) {
  return {
    activityReports: state.activityReports,
  };
}

export default connect(mapStateToProps, {})(ActivityWithReports);
