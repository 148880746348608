import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { closeTopModal, openSliderModal } from '^/actions/modals';
import { isFivePoint } from '^/components/analytics/utils';
import Dropdown from '^/components/dropdown/Dropdown';
import { AnalyticsProduct } from '^/reducers/api/types';
import { ProductType } from '^/reducers/api/types/product';
import { ADVANCED_ANALYTICS_ATTRIBUTE } from '../types';
import { get360LevelText, getFilterValueDisplay } from '../utils';
import { DeconstructedProfileFilter } from './ProfileFilters';

export interface OwnProps {
  setFilters: (filter: DeconstructedProfileFilter) => void;
  factors: Array<{ id: string; name: string }>;
  product: AnalyticsProduct;
  onDeleteClickHandler: () => void;
  filter: DeconstructedProfileFilter;
}

interface DispatchProps {
  openSliderModal: typeof openSliderModal;
  closeTopModal: typeof closeTopModal;
}

export type Props = OwnProps & DispatchProps;

export class ProfileFilter extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.getPsychometricTypeChoices = this.getPsychometricTypeChoices.bind(
      this
    );
    this.get360TypeChoices = this.get360TypeChoices.bind(this);
    this.getTypeChoices = this.getTypeChoices.bind(this);
    this.getFilterValue = this.getFilterValue.bind(this);
    this.openSetValueModal = this.openSetValueModal.bind(this);
    this.onSliderUpdate = this.onSliderUpdate.bind(this);
  }

  makeDropDownOption(items: Array<[string, string]>) {
    return items.map(item => ({ id: item[0], name: item[1] }));
  }

  getPsychometricTypeChoices() {
    switch (this.props.product.type) {
      case 'DISC':
        return this.makeDropDownOption([['LEVEL', i18next.t<string>('level')]]);
      case 'POSITIVE_RESILIENCE_PROFILER':
        return this.makeDropDownOption([
          ['LEVEL', i18next.t<string>('level')],
          ['SCORE', i18next.t<string>('percentile')],
        ]);
      default:
        return this.makeDropDownOption([
          ['STEN', i18next.t<string>('sten')],
          ['LEVEL', i18next.t<string>('level')],
          ...(this.props.filter.factor !== 'PP_SD'
            ? ([['SCORE', i18next.t<string>('percentile')]] as any)
            : []),
        ]);
    }
  }

  get360TypeChoices() {
    const { product } = this.props;
    const items: Array<[string, string]> = [['RATING', 'rating']];

    if (isFivePoint(product)) {
      items.unshift(['LEVEL', 'level']);
    }
    return this.makeDropDownOption(items);
  }

  getTypeChoices() {
    if (this.props.product.activity_type === 'THREE_SIXTY') {
      return this.get360TypeChoices();
    }
    return this.getPsychometricTypeChoices();
  }

  getFilterValue() {
    const {
      product,
      filter: { from, to, attribute },
    } = this.props;

    if (from && to) {
      return product.activity_type === 'THREE_SIXTY' &&
        attribute === ADVANCED_ANALYTICS_ATTRIBUTE.LEVEL
        ? `${get360LevelText(from)} - ${get360LevelText(to)}`
        : `${from} - ${to}`;
    }
    return i18next.t<string>('Set values');
  }

  onSliderUpdate(from: string, to: string) {
    const { setFilters, filter } = this.props;
    setFilters({ ...filter, from, to });
  }

  openSetValueModal() {
    const {
      product,
      filter: { factor, attribute, from, to },
    } = this.props;

    if (factor && attribute) {
      this.props.openSliderModal(
        this.onSliderUpdate,
        factor,
        attribute.toLowerCase(),
        from,
        to,
        product
      );
    }
  }

  render() {
    const {
      product,
      factors,
      onDeleteClickHandler,
      filter,
      setFilters,
      filter: { from, to, attribute, factor },
    } = this.props;

    const typeChoices = this.getTypeChoices();

    return (
      <div className="filter">
        <Dropdown
          items={factors}
          title={
            product.type === ProductType.DISC
              ? i18next.t<string>('Select dimension')
              : i18next.t<string>('Select capability')
          }
          onClick={(newFactor: string | number | null) =>
            setFilters({ ...filter, factor: newFactor?.toString() })
          }
          defaultValue={factor}
        />
        <Dropdown
          items={typeChoices}
          title={i18next.t<string>('Select attribute')}
          onClick={(newAttribute: string | number | null) => {
            const valuesReset = newAttribute !== attribute;
            setFilters({
              ...filter,
              attribute: newAttribute?.toString()?.toLowerCase(),
              from: valuesReset ? undefined : from,
              to: valuesReset ? undefined : to,
            });
          }}
          defaultValue={filter.attribute?.toUpperCase()}
        />

        <div className="filter-options">
          <a onClick={this.openSetValueModal}>
            {getFilterValueDisplay(product, from, to, attribute) ||
              i18next.t<string>('Set values')}
          </a>
          <a className="delete-row-button" onClick={onDeleteClickHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  openSliderModal,
  closeTopModal,
})(ProfileFilter);
