import { Dispatch } from 'react-redux';
import { AnyAction } from 'redux';

import { CREATE_PULSE, START_PULSES } from '^/actions/actions';
import { StoreState } from '^/store';

const stripeRedirectMiddleware = () => (next: Dispatch<StoreState>) => (
  action: AnyAction
) => {
  switch (action.type) {
    case CREATE_PULSE.SUCCESS:
    case START_PULSES.SUCCESS:
      if (action.payload.stripe_redirect_url) {
        window.location = action.payload.stripe_redirect_url;
      }
      break;
    default:
      break;
  }
  return next(action);
};

export default stripeRedirectMiddleware;
