export function generateQuestionOrder(questionPairs) {
  const length = questionPairs.count();
  return questionPairs
    .map(([question, answer], index) => {
      if (answer) {
        return [question.get('id'), parseInt(answer.get('score'), 10)];
      }
      return [question.get('id'), length - index];
    })
    .sort(([, leftScore], [, rightScore]) => {
      return rightScore - leftScore;
    })
    .map(([id]) => id);
}
