import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import LiveButton from '^/components/LiveButton';
import { updateOrganisationAndCloseModal } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';

export class ConfirmUpdateBulkDiscountBandModal extends PureComponent {
  update() {
    const { account, bulkDiscountBandCollection } = this.props;
    this.props.updateOrganisationAndCloseModal(account.get('id'), {
      bulk_discount_band_collection_id:
        bulkDiscountBandCollection && bulkDiscountBandCollection.get('id'),
    });
  }

  render() {
    const { bulkDiscountBandCollection, account, response } = this.props;
    return (
      <div>
        {bulkDiscountBandCollection ? (
          <div>
            <p>
              {i18next.t(
                'You have chosen {{discountBandsName}} for {{accountName}}.',
                {
                  discountBandsName: bulkDiscountBandCollection.get('name'),
                  accountName: account.get('name'),
                }
              )}
            </p>
            <p>
              {i18next.t(
                'The account will have access to the new discount rates on confirmation of this message.'
              )}
            </p>
          </div>
        ) : (
          <div>
            <p>
              {i18next.t(
                'You have chosen to remove discounts for {{accountName}}.',
                { accountName: account.get('name') }
              )}
            </p>
            <p>
              {i18next.t(
                'The account will lose access to the discount rates on confirmation of this message.'
              )}
            </p>
          </div>
        )}

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Cancel')}
            </button>
            <LiveButton
              buttonText={i18next.t('Confirm')}
              pendingMessage={i18next.t('Updating...')}
              response={response}
              onClick={() => this.update()}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  updateOrganisationAndCloseModal,
})(ConfirmUpdateBulkDiscountBandModal);
