import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import LegalPage from './LegalPage';

export default class Contact extends PureComponent {
  render() {
    /* eslint-disable max-len */
    return (
      <LegalPage title={i18next.t('Contact')}>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="legal-info">
              <strong>{i18next.t('Talk to us')}:</strong>{' '}
              <a href="tel:+4402045039442">+44 (0)204 503 9442</a>
            </div>
            <div className="legal-info">
              <strong>{i18next.t('Technical support')}</strong>{' '}
              <a href="mailto:enablesupport@peoplewise.co.uk">
                enablesupport@peoplewise.co.uk
              </a>
            </div>
            <div className="legal-info">
              <strong>{i18next.t('Sales')}</strong>{' '}
              <a href="mailto:letushelp@peoplewise.co.uk">
                letushelp@peoplewise.co.uk
              </a>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="legal-info">
              <strong>{i18next.t('Write to us')}</strong>{' '}
              {i18next.t('PeopleWise Head Office')}, Broadway Chambers, M.2, 20
              Hammersmith Broadway, London, W6 7AF United Kingdom
            </div>
          </div>
        </div>
      </LegalPage>
    );
    /* eslint-enable max-len */
  }
}
