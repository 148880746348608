import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { isPending } from '^/responseStates';

export default class BlankOption extends PureComponent {
  render() {
    return (
      <option value="" disabled={this.props.disabled}>
        {isPending(this.props.response)
          ? i18next.t('Loading...')
          : i18next.t('Please select...')}
      </option>
    );
  }
}
