/* eslint-disable react/jsx-no-bind */
import i18next from 'i18next';
import { faArrowsH } from '@fortawesome/pro-solid-svg-icons/faArrowsH';
import { faArrowToLeft } from '@fortawesome/pro-solid-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons/faArrowToRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';

import { sum } from '^/utils';
import DataAnalyticsTd from '^/components/analytics/DataAnalyticsTd';
import Icon from '^/components/Icon';
import Loading from '^/components/Loading';
import Windowed from '^/components/Windowed';
import {
  Analytics360AggregateResults,
  AnalyticsAggregateExtraResults,
  AnalyticsAggregateResults,
  AnalyticsFilters,
  AnalyticsProduct,
  AnalyticsSessionChunk,
  AnalyticsSessionsState,
  AnalyticsFilterItem,
  SubAggregate,
} from '^/reducers/api/types';
import {
  Column,
  DATA_ANALYTICS_DISC_TABLE_TWO_STRUCTURE,
  DATA_ANALYTICS_TABLE_STRUCTURE,
  makeThreeSixtyColumnGroups,
} from './DataAnalyticsTableStructure';
import { ProductType } from '^/reducers/api/types/product';

interface OwnProps {
  count: number;
  page: number;
  loading: boolean;
  loadingPage: boolean;
  filters: AnalyticsFilters;
  aggregate: AnalyticsAggregateResults | Analytics360AggregateResults;
  aggregateExtra?: AnalyticsAggregateExtraResults;
  subaggregates: ReadonlyArray<SubAggregate>;
  product: AnalyticsProduct;
  onChangePage: (page: number) => void;
  ordering?: string;
  onSetOrdering: (ordering: string) => void;
  resultsByPage: AnalyticsSessionsState;
  showHeatmap?: boolean;
  user: Immutable.Map<string, any>;
  showAlternativeTable?: boolean;
  showTableThree?: boolean;
}

export interface Paginated<T> {
  count?: number;
  results: readonly T[];
}
export interface ColumnGroup {
  name: string;
  id: string;
  isExpanded: boolean;
  isExpandable: boolean;
  columns: ReadonlyArray<Column>;
  chartCode?: string;
}

interface State {
  columnGroups: ReadonlyArray<ColumnGroup>;
}

type Props = OwnProps;

const PAGE_SIZE = 20;
const AGGREGATE_ROW_HEIGHT = 27;

export default class DataAnalyticsTable extends React.PureComponent<
  Props,
  State
> {
  public scrollingElement: HTMLDivElement | null = null;
  public scrollContainerElement: HTMLDivElement | null = null;

  constructor(props: Props) {
    super(props);
    const {
      product: { activity_type, type, primary_competencies },
      showAlternativeTable,
    } = this.props;

    const columnGroups =
      activity_type === 'THREE_SIXTY'
        ? primary_competencies &&
          makeThreeSixtyColumnGroups(primary_competencies)
        : showAlternativeTable && type === ProductType.DISC
        ? DATA_ANALYTICS_DISC_TABLE_TWO_STRUCTURE
        : DATA_ANALYTICS_TABLE_STRUCTURE[type];
    this.state = {
      columnGroups: columnGroups!.map(columnGroup => ({
        ...columnGroup,
        isExpanded: true,
      })),
    };
  }

  public componentDidUpdate(prevProps: Props) {
    const {
      count,
      ordering,
      showAlternativeTable,
      showTableThree,
      product: { activity_type, type, primary_competencies },
    } = this.props;
    if (
      prevProps.count !== count ||
      prevProps.ordering !== ordering ||
      prevProps.showAlternativeTable !== showAlternativeTable
    ) {
      if (this.scrollContainerElement) {
        this.scrollContainerElement.scrollTop = 0;
      }
    }
    if (
      prevProps.showAlternativeTable !== showAlternativeTable ||
      prevProps.showTableThree !== showTableThree
    ) {
      const columnGroups =
        activity_type === 'THREE_SIXTY'
          ? primary_competencies &&
            makeThreeSixtyColumnGroups(primary_competencies)
          : showAlternativeTable && type === ProductType.DISC
          ? DATA_ANALYTICS_DISC_TABLE_TWO_STRUCTURE
          : DATA_ANALYTICS_TABLE_STRUCTURE[type];
      this.setState({
        columnGroups: columnGroups!.map((columnGroup, idx) => ({
          ...columnGroup,
          isExpanded:
            prevProps.showAlternativeTable === showAlternativeTable
              ? this.state.columnGroups[idx].isExpanded
              : true,
        })),
      });
    }
  }

  public expandCollapseAll = () => {
    const allExpanded = this.allColumnsExpanded();
    this.setState({
      columnGroups: this.state.columnGroups.map(columnGroup => ({
        ...columnGroup,
        isExpanded: !allExpanded,
      })),
    });
  };

  public expandCollapseColumnGroup = (index: number) => {
    this.setState({
      columnGroups: this.state.columnGroups.map((columnGroup, idx) => ({
        ...columnGroup,
        isExpanded:
          idx === index ? !columnGroup.isExpanded : columnGroup.isExpanded,
      })),
    });
  };

  public getSubAggregateColumnName(sub: SubAggregate) {
    return this.props.filters[sub.key as keyof AnalyticsFilters].find(
      each => each.id === sub.value
    )?.name;
  }

  public render() {
    const {
      count,
      loading,
      loadingPage,
      aggregate,
      aggregateExtra,
      subaggregates,
      ordering,
      resultsByPage,
      onChangePage,
      onSetOrdering,
      showHeatmap,
      product,
      showAlternativeTable,
      showTableThree,
      user,
      filters,
    } = this.props;
    const subaggregatesOffset = subaggregates.length
      ? subaggregates.length * AGGREGATE_ROW_HEIGHT
      : 0;
    const getItems = (chunk: Paginated<AnalyticsSessionChunk>) => chunk;
    const loadChunk = (chunkIndex: number) => {
      onChangePage(chunkIndex + 1);
    };
    const { columnGroups } = this.state;
    const expandCollapseAllText = this.allColumnsExpanded()
      ? i18next.t<string>('Collapse All')
      : i18next.t<string>('Expand All');
    const filterKeys = _.uniq(subaggregates.map(sub => sub.key));
    const filterIdSets = Object.fromEntries(
      filterKeys.map(key => [
        key,
        new Set(
          _.map(
            filters[key as keyof AnalyticsFilters],
            (filter: AnalyticsFilterItem) => filter.id
          )
        ),
      ])
    );

    return (
      <div>
        <div className="sort-text">
          {i18next.t<string>('{{count}} responses', { count })}
          {this.getOrderingText()}
        </div>
        <div
          className="data-analytics-table-wrapper"
          ref={this.setScrollContainerElementRef}
        >
          <table
            className={classNames(
              'data-analytics-table',
              product.activity_type === 'THREE_SIXTY'
                ? 'three-sixty'
                : 'psychometric',
              { 'pp-table': product.type === 'PSYCAP_POTENTIAL' },
              { 'heatmap-show': showHeatmap }
            )}
          >
            <thead>
              <tr>
                {columnGroups.map((columnGroup, index) => (
                  <>
                    {index === 0 ? (
                      <th
                        className={classNames(
                          'right-line',
                          product.activity_type === 'THREE_SIXTY' &&
                            'three-sixty-extra-top-row'
                        )}
                        colSpan={this.getColSpan(columnGroup)}
                      >
                        <div className="table-header">
                          <div
                            className="clickable"
                            onClick={this.expandCollapseAll}
                          >
                            {expandCollapseAllText}
                            <FontAwesomeIcon
                              size="lg"
                              icon={faArrowsH}
                              className="expand-collapse-all-icon"
                            />
                          </div>
                        </div>
                      </th>
                    ) : (
                      <th
                        className={classNames(
                          index !== 1 && 'left-line',
                          product.activity_type === 'THREE_SIXTY' &&
                            index > 2 &&
                            'text-center',
                          product.activity_type === 'THREE_SIXTY' &&
                            'three-sixty-extra-top-row'
                        )}
                        colSpan={this.getColSpan(columnGroup)}
                        data-tip={columnGroup.name}
                      >
                        <div
                          className="clickable"
                          onClick={() => this.expandCollapseColumnGroup(index)}
                        >
                          <div
                            className={classNames({
                              'single-column-header':
                                this.getColSpan(columnGroup) === 1,
                              'double-column-header':
                                this.getColSpan(columnGroup) === 2 &&
                                product.type === 'PSYCAP_POTENTIAL' &&
                                !columnGroup.isExpanded,
                              wider:
                                columnGroup.columns[0].id === 'user' ||
                                product.type === 'POSITIVE_RESILIENCE_PROFILER',
                              'collapse-small':
                                !columnGroup.isExpanded &&
                                columnGroup.columns[0].collapseSmall,
                            })}
                          >
                            {columnGroup.name}
                          </div>

                          {columnGroup.isExpandable && (
                            <FontAwesomeIcon
                              size="lg"
                              icon={
                                columnGroup.isExpanded
                                  ? faArrowToLeft
                                  : faArrowToRight
                              }
                              className={classNames('expand-icon', {
                                'collapse-small-svg':
                                  !columnGroup.isExpanded &&
                                  columnGroup.columns[0].collapseSmall,
                              })}
                            />
                          )}
                        </div>
                      </th>
                    )}
                  </>
                ))}
              </tr>
              {product.activity_type === 'THREE_SIXTY' && (
                <tr>
                  {columnGroups.map((columnGroup, index) => {
                    return index > 2
                      ? this.threeSixtySubHeaders(
                          columnGroup,
                          columnGroup.columns,
                          columnGroup.name
                        )
                      : columnGroup.columns.map((column, columnIndex) => (
                          <th
                            key={columnIndex}
                            className={classNames(
                              'three-sixty-extra-row',
                              'empty-cell',
                              'table-header',
                              column.id === 'user'
                                ? 'details-header'
                                : 'data-header',
                              column.id !== 'user' &&
                                columnIndex === 0 &&
                                'summary-cell',
                              this.getClassName(
                                columnGroup,
                                index,
                                column,
                                columnIndex
                              )
                            )}
                          />
                        ));
                  })}
                </tr>
              )}
              <tr>
                {columnGroups.map((columnGroup, columnGroupIndex) =>
                  columnGroup.columns.map(
                    ({ sortKey, ...column }, columnIndex) =>
                      this.shouldOmitColumn(column) ? (
                        ''
                      ) : (
                        <th
                          className={classNames(
                            'table-header',
                            column.id === 'user'
                              ? 'details-header'
                              : 'data-header',
                            column.id !== 'user' &&
                              columnGroupIndex > 2 &&
                              columnIndex > 2 &&
                              'light-text',
                            this.getClassName(
                              columnGroup,
                              columnGroupIndex,
                              column,
                              columnIndex
                            )
                          )}
                          data-tip={column.altHoverText || column.name}
                        >
                          <div className="display-flex">
                            <span
                              className={classNames(
                                'sub-heading-text',
                                column.id !== 'user' &&
                                  (column.isHideable(user) ||
                                    product.type === 'DISC') &&
                                  'light-text'
                              )}
                            >
                              {column.id === 'user' ||
                              product.activity_type === 'THREE_SIXTY' ||
                              (product.type === ProductType.DISC &&
                                columnGroupIndex === 2 &&
                                columnIndex === 0)
                                ? column.name
                                : column.name.slice(0, 4)}
                            </span>
                            {sortKey && (
                              <Icon
                                onClick={() =>
                                  onSetOrdering(
                                    ordering === sortKey
                                      ? `-${sortKey}`
                                      : sortKey
                                  )
                                }
                                type={'up-down'}
                                className={classNames('sort-control', {
                                  up: ordering === `-${sortKey}`,
                                  down: ordering === sortKey,
                                })}
                              />
                            )}
                          </div>
                        </th>
                      )
                  )
                )}
              </tr>
              <tr
                className={classNames('aggregate', {
                  'last-aggregate': subaggregates.length === 0,
                })}
              >
                {columnGroups.map((columnGroup, columnGroupIndex) =>
                  columnGroup.columns.map((column, columnIndex) =>
                    this.shouldOmitColumn(column) ? (
                      ''
                    ) : (
                      <DataAnalyticsTd
                        product={product}
                        count={count}
                        aggregate={aggregate}
                        aggregateExtra={aggregateExtra}
                        column={column}
                        showAlternativeTable={showAlternativeTable}
                        showTableThree={showTableThree}
                        className={this.getClassName(
                          columnGroup,
                          columnGroupIndex,
                          column,
                          columnIndex,
                          showTableThree && showAlternativeTable
                        )}
                      />
                    )
                  )
                )}
              </tr>
            </thead>
            <tbody
              ref={this.setScrollingElementRef}
              style={{ overflowAnchor: 'none' }}
            >
              {loading && <Loading className="list-loading" />}
              {subaggregates
                .filter(sub => filterIdSets[sub.key].has(sub.value))
                .map((sub, index) => (
                  <tr
                    key={index}
                    className={classNames('aggregate', 'subaggregate', {
                      'last-aggregate': index === subaggregates.length - 1,
                    })}
                  >
                    {sub.filtersState ? (
                      columnGroups.map((columnGroup, columnGroupIndex) =>
                        columnGroup.columns.map((column, columnIndex) =>
                          this.shouldOmitColumn(column) ? (
                            ''
                          ) : (
                            <DataAnalyticsTd
                              product={product}
                              count={sub.filtersState!.sessions!.count}
                              aggregate={sub.filtersState!.sessions!.aggregate}
                              aggregateExtra={
                                sub.filtersState!.sessions!.aggregate_extra
                              }
                              label={this.getSubAggregateColumnName(sub)}
                              column={column}
                              showAlternativeTable={showAlternativeTable}
                              showTableThree={showTableThree}
                              className={this.getClassName(
                                columnGroup,
                                columnGroupIndex,
                                column,
                                columnIndex,
                                showTableThree && showAlternativeTable
                              )}
                            />
                          )
                        )
                      )
                    ) : (
                      <td
                        colSpan={sum(
                          columnGroups?.map(({ columns }) => columns.length)
                        )}
                      >
                        <Skeleton />
                      </td>
                    )}
                  </tr>
                ))}
              <Windowed
                parentElement={this.scrollingElement}
                scrollClassName={'.data-analytics-table-wrapper'}
                chunkSize={PAGE_SIZE}
                total={count}
                data={resultsByPage}
                row={this.TableRow}
                spacer={this.TableSpacer}
                rowHeight={20}
                topOffset={105}
                subaggregatesOffset={subaggregatesOffset}
                chunkPadding={3}
                loading={loadingPage}
                loadChunk={loadChunk}
                getItems={getItems}
              />
            </tbody>
          </table>
          <ReactTooltip
            delayShow={200}
            textColor="black"
            backgroundColor="white"
            border
            borderColor="#C9CCD6"
          />
        </div>
      </div>
    );
  }

  public TableSpacer = (props: HTMLAttributes<HTMLElement>) => (
    <tr {...props}>
      {this.state.columnGroups.map((columnGroup, columnGroupIndex) =>
        columnGroup.columns.map((column, columnIndex) =>
          this.shouldOmitColumn(column) ? (
            ''
          ) : (
            <td
              className={this.getClassName(
                columnGroup,
                columnGroupIndex,
                column,
                columnIndex
              )}
            >
              &nbsp;
            </td>
          )
        )
      )}
    </tr>
  );

  public TableRow = (chunk: AnalyticsSessionChunk) => {
    const { columnGroups } = this.state;
    const { product, showAlternativeTable, showTableThree } = this.props;
    return (
      <tr>
        {columnGroups.map((columnGroup, columnGroupIndex) =>
          columnGroup.columns.map((column, columnIndex) =>
            this.shouldOmitColumn(column) ? (
              ''
            ) : (
              <DataAnalyticsTd
                product={product}
                session={chunk.item}
                column={column}
                showAlternativeTable={showAlternativeTable}
                showTableThree={showTableThree}
                className={this.getClassName(
                  columnGroup,
                  columnGroupIndex,
                  column,
                  columnIndex,
                  showTableThree && showAlternativeTable
                )}
              />
            )
          )
        )}
      </tr>
    );
  };

  private getClassName = (
    columnGroup: ColumnGroup,
    columnGroupIndex: number,
    column: Column,
    columnIndex: number,
    showTableThree?: boolean
  ) => {
    const visible = this.isVisible(columnGroup, column);
    const { product } = this.props;
    return classNames(visible ? 'show' : 'hide', {
      'left-line':
        (columnGroupIndex > 1 && visible && columnIndex === 0) ||
        (product.activity_type === 'THREE_SIXTY' &&
          columnGroupIndex > 2 &&
          columnIndex % 3 === 0) ||
        (product.type === 'PSYCAP_POTENTIAL' &&
          columnGroupIndex > 1 &&
          columnIndex === 2),
      'right-line': columnGroupIndex === 0 && columnIndex === 0,
      'disc-table-three': showTableThree,
      'collapse-small': column.collapseSmall && !columnGroup.isExpanded,
    });
  };
  private threeSixtySubHeaders = (
    columnGroup: ColumnGroup,
    columns: ReadonlyArray<Column>,
    CompName: string
  ) => {
    const visible = (columnIndex: number) => {
      return (
        columns[columnIndex] &&
        this.isVisible(columnGroup, columns[columnIndex])
      );
    };
    return (
      <>
        {columns.map((column, index) => {
          return (
            index % 3 === 0 && (
              <th
                className={classNames(
                  'three-sixty-extra-row left-line',
                  visible(index) ? 'show' : 'hide',
                  !visible(3) && 'hide-text',
                  index !== 0 && 'light-text'
                )}
                colSpan={3}
                data-tip={index < 3 ? CompName : column.columnName}
              >
                {index < 3 ? CompName : column.columnName}
              </th>
            )
          );
        })}
      </>
    );
  };

  private isVisible = (columnGroup: ColumnGroup, column: Column) => {
    const { user } = this.props;
    return columnGroup.isExpanded || !column.isHideable(user);
  };

  private getColSpan = (columnGroup: ColumnGroup) =>
    columnGroup.columns.filter(column => this.isVisible(columnGroup, column))
      .length -
    columnGroup.columns.filter(
      column => column.shouldOmit && column.shouldOmit(this.props.user)
    ).length;

  private shouldOmitColumn = (column: Column) =>
    column.shouldOmit && column.shouldOmit(this.props.user);

  private setScrollContainerElementRef = (element: HTMLTableSectionElement) =>
    (this.scrollContainerElement = element);

  private setScrollingElementRef = (element: HTMLTableSectionElement) =>
    (this.scrollingElement = element);

  private allColumnsExpanded = () =>
    this.state.columnGroups.every(({ isExpanded }) => isExpanded);

  private getOrderingText() {
    let orderingText = '';

    const { ordering } = this.props;
    const { columnGroups } = this.state;
    if (ordering) {
      columnGroups.forEach(columnGroup =>
        columnGroup.columns.forEach(({ name, id, sortKey }) => {
          if (ordering === sortKey || ordering === `-${sortKey}`) {
            const prependColumnGroupName =
              columnGroup.id !== 'user' && columnGroup.id === id;
            const sort = prependColumnGroupName
              ? `${columnGroup.name} ${name}`
              : name;
            orderingText = i18next.t<string>(', sorted by {{sort}}', { sort });
            if (ordering[0] === '-') {
              orderingText += i18next.t<string>(' (descending)');
            }
          }
        })
      );
    }

    return orderingText;
  }
}
