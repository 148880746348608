/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import React, { PureComponent } from 'react';

import Checkbox from '^/components/Checkbox';
import Dropdown from '^/components/dropdown/Dropdown';
import RadioButton from '^/components/RadioButton';
import Tag from '^/components/Tag';
import FormItem from '../forms/FormItem';

const ITEMS = [
  {
    id: '1',
    name: 'Option 1',
    selected: false,
  },
  {
    id: '2',
    name: 'Option 2',
    selected: false,
  },
  {
    id: '3',
    name: 'Option 3',
    selected: true,
  },
];

interface OwnProps {
  formInline?: boolean;
}

type Props = OwnProps;

export default class ExampleForm extends PureComponent<Props> {
  public render() {
    const { formInline } = this.props;
    return (
      <form className={formInline ? 'form-inline' : 'form-block'}>
        <div>
          <label>{i18next.t<string>('Input text')}</label>
          <div className="form-item stacked">
            <input type="text" name="name" placeholder="Default placeholder" />
            <input type="text" name="name" value="Default value" />
            <input
              disabled
              type="text"
              name="name"
              placeholder="Disabled placeholder"
            />
            <input disabled type="text" name="name" value="Disabled value" />
            <input
              readOnly
              type="text"
              name="name"
              placeholder="Readonly placeholder"
            />
            <input readOnly type="text" name="name" value="Readonly value" />
            <p className="text-error">
              This is an error from FormError component
            </p>
          </div>
        </div>

        <div>
          <label>{i18next.t<string>('File upload')}</label>
          <div className="form-item wrapping">
            <div className="flex-list added-list">
              <div className="tag">
                <div className="tag-name">file-name.pdf</div>
              </div>
            </div>
            <button className="btn btn-default btn-small">
              Select a new file
            </button>
            <p className="text-error">
              This is an error from FormError component
            </p>
          </div>
        </div>

        <h5 className="heading-underlined">
          <span className="asset-name-and-logo">
            <img
              className="asset-logo"
              src="https://enable-backend.bepeoplewise.com/static/images/psycap-potential-logo.ad1ffad6610d.png"
            />
            <span className="asset-name">
              <span>Dividing with h5 in forms (underlined)</span>
            </span>
          </span>
        </h5>

        <div>
          <label>{i18next.t<string>('Textarea')}</label>
          <div className="form-item stacked">
            <textarea
              name="name"
              placeholder="Textarea for longer paragraphs"
            />
            <textarea
              disabled
              name="name"
              placeholder="Textarea for longer paragraphs (disabled)"
            />
            <p className="text-error">
              laying out two inputs stacked uses the "form-item stacked" class
            </p>
          </div>
        </div>

        <h5 className="heading-underlined">
          Dividing with h5 without image/logo
          <a className="align-right text-transform-none">Optional link</a>
        </h5>

        <div>
          <label>{i18next.t<string>('Adding things through modal etc')}</label>
          <div className="form-item wrapping">
            <div className="flex-list added-list">
              <Tag name="johan@dabapps.com" onRemove={console.log} />
              <Tag name="kim@peoplewise.com" onRemove={console.log} />
              <Tag name="kim@peoplewise.com" onRemove={console.log} />
            </div>
            <button className="btn btn-default btn-small">
              <FontAwesomeIcon icon={faPlus} />
              Add button
            </button>
            <p className="text-error">
              laying out things like tags that wrap uses the "form-item
              wrapping" class
            </p>
          </div>
        </div>

        <table className="table-smaller">
          <thead>
            <tr>
              <th>
                <div className="table-header">
                  <Checkbox id="second" name="ALL" onChange={console.log} />
                </div>
              </th>
              <th>
                <div className="table-header table-header-control-on-right">
                  Table header sortable
                  <a className="icon icon-up-down sort-control">
                    <span className="icon-inner" />
                  </a>
                </div>
              </th>
              <th className="align-right">
                <div className="table-header">Table header .align-right</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="">
                <Checkbox id="second" name="ALL" onChange={console.log} />
              </td>
              <td className="uppercase">Value text</td>
              <td className="align-right">1000</td>
            </tr>
            <tr>
              <td className="">
                <Checkbox id="second" name="ALL" onChange={console.log} />
              </td>
              <td className="uppercase">Value text</td>
              <td className="align-right">1000</td>
            </tr>
            <tr>
              <td className="">
                <Checkbox id="second" name="ALL" onChange={console.log} />
              </td>
              <td className="uppercase">Value text</td>
              <td className="align-right">1000</td>
            </tr>
          </tbody>
        </table>

        <div>
          <label>{i18next.t<string>('Disabled until above actioned')}</label>
          <div className="form-item wrapping">
            <button className="btn btn-default btn-small" disabled>
              <FontAwesomeIcon icon={faPlus} />
              Disabled button
            </button>
          </div>
        </div>

        <div>
          <label>{i18next.t<string>('Select input')}</label>
          <div className="form-item wrapping">
            <Dropdown
              title="Chosen options"
              items={ITEMS}
              onClick={console.log}
              loading={false}
              failed={false}
            />
          </div>
        </div>

        <div>
          <label>{i18next.t<string>('Pre-set option')}</label>
          <div className="form-item wrapping">
            When something is set and locked.{' '}
            <FontAwesomeIcon size="lg" className="green" icon={faCheck} />
          </div>
        </div>

        <FormItem label="1-3 short radio options" wrapping>
          <RadioButton
            inline
            id="radio-short"
            name="radio-A"
            label="Short"
            onChange={console.log}
          />
          <RadioButton
            inline
            id="radio-notlong"
            name="radio-A"
            label="Not long"
            onChange={console.log}
          />
          <RadioButton
            inline
            id="radio-maxamount"
            name="radio-A"
            label="Max amount"
            onChange={console.log}
          />
          <p className="text-error">
            For left hand labels use a div with className="form-label" instead
            of a label element when radios or checkboxes have their own
            associated labels
          </p>
        </FormItem>

        <FormItem stacked label="3 or more long sentence options">
          <RadioButton
            id="radio-one"
            name="radio-B"
            label="Long example of radio option"
            onChange={console.log}
          />
          <RadioButton
            id="radio-two"
            name="radio-B"
            label="Another super long example of radio option"
            onChange={console.log}
          />
          <RadioButton
            id="radio-three"
            name="radio-B"
            label="One short"
            onChange={console.log}
          />
          <RadioButton
            disabled
            id="radio-four"
            name="radio-C"
            label="Disabled radio"
            onChange={console.log}
          />
          <RadioButton
            checked
            disabled
            id="radio-five"
            label="Disabled and checked radio"
            onChange={console.log}
          />
        </FormItem>

        <FormItem wrapping label="Checkbox options">
          <Checkbox
            checked
            inline
            id="short"
            name="short"
            value="yes"
            label="Short"
            onChange={console.log}
          />
          <Checkbox
            checked
            inline
            id="notlong"
            name="notlong"
            value="yes"
            label="Not long"
            onChange={console.log}
          />
          <Checkbox
            inline
            id="maxamount"
            name="maxamount"
            value="yes"
            label="Max amount"
            onChange={console.log}
          />
        </FormItem>

        <FormItem stacked label="3 or more long sentence options">
          <Checkbox
            checked
            id="one"
            name="one"
            value="yes"
            label="Long example of checkbox option"
            onChange={console.log}
          />
          <Checkbox
            checked
            id="two"
            name="two"
            value="yes"
            label="Another super long example of checkbox option"
            onChange={console.log}
          />
          <Checkbox
            id="three"
            name="three"
            value="yes"
            label="One short"
            onChange={console.log}
          />
          <Checkbox
            disabled
            id="four"
            name="four"
            value="yes"
            label="Disabled checkbox"
            onChange={console.log}
          />
        </FormItem>

        <FormItem label="Add email addresses" stacked>
          <div className="flex-list added-list">
            <Tag name="johan@dabapps.com" onRemove={() => null} />
            <Tag name="kim@peoplewise.com" onRemove={() => null} />
          </div>
          <div className="input-and-control">
            <input
              type="email"
              name="email"
              placeholder="name@emailaddress.com"
            />
            <button className="btn btn-default btn-small">
              <FontAwesomeIcon icon={faPlus} />
              Add
            </button>
          </div>
        </FormItem>

        <div className="form-button-group">
          <hr />
          <div className="form-item wrapping">
            <button className="btn btn-default">Cancel</button>

            <button className="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    );
  }
}
