import * as actions from '../actions/actions';
import LocalStorage from '../LocalStorage';

export default store => next => action => {
  switch (action.type) {
    case actions.SUDO_LOGIN.SUCCESS:
      LocalStorage.set('userProfile', store.getState().userProfile.toJS());
      LocalStorage.set('user', store.getState().user);
      break;

    case actions.SUDO_LOGOUT:
    case actions.LOGOUT:
      LocalStorage.clear('userProfile');
      LocalStorage.clear('user');
      break;
  }

  return next(action);
};
