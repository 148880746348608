import * as actions from '../actions/actions';
import * as collections from '../actions/collections';

function couldChangeOrgCredit(action) {
  switch (action.type) {
    case actions.ADD_USERS_TO_ACTIVITY.SUCCESS:
    case actions.REMOVE_USER_FROM_ACTIVITY.SUCCESS:
      return true;

    case collections.UPDATE_ITEM.SUCCESS:
      return action.meta.collectionName === 'activities';

    default:
      return false;
  }
}

export default store => next => action => {
  if (couldChangeOrgCredit(action)) {
    store.dispatch(actions.getUserInfo());
  }

  return next(action);
};
