import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { SEX_CHOICES, GEOGRAPHY_CHOICES } from '../profile/choices';
import PureComponent from '../PureComponent';
import ProfilePicture from '../profile/ProfilePicture';
import Categories from '../profile/Categories';
import DetailRow from '^/components/detail-list/DetailRow';

export default class UserInfo extends PureComponent {
  render() {
    const { user } = this.props;
    const categories = user.get('categories');

    return (
      <div className="row">
        <div className="col-md-8 form-inline form-detail">
          <DetailRow label={i18next.t('Email')}>
            {' '}
            {user.get('email')}{' '}
          </DetailRow>
          <DetailRow label={i18next.t('Sex')}>
            {' '}
            {SEX_CHOICES[user.get('sex')]}{' '}
          </DetailRow>
          <DetailRow label={i18next.t('Region')}>
            {' '}
            {GEOGRAPHY_CHOICES[user.get('geography')]}{' '}
          </DetailRow>
          {categories && !categories.isEmpty() && (
            <DetailRow label={'Categories'}>
              <Categories Categories={categories} />
            </DetailRow>
          )}
        </div>
        <div className="col-md-4">
          <ProfilePicture user={user} className="pull-right" />
        </div>
      </div>
    );
  }
}
