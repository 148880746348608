import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import { closeModalAndTryViewExport } from '../../actions/actionSequences';
import { closeTopModal } from '^/actions/modals';

export class TokenExpiredModal extends PureComponent {
  tryExport() {
    const { shortlivedToken, exportType, metadata } = this.props;
    this.props.closeModalAndTryViewExport(
      shortlivedToken,
      exportType,
      metadata
    );
  }

  render() {
    const { shortlivedToken } = this.props;
    const awaitingToken = !shortlivedToken.get('token');
    return (
      <div>
        {awaitingToken ? (
          <p>{i18next.t('Your download is being generated...')}</p>
        ) : (
          <p>{i18next.t('Your download is ready.')}</p>
        )}

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              type="button"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t('Cancel')}
            </button>
            <button
              className="btn btn-primary"
              type="button"
              disabled={awaitingToken}
              onClick={() => this.tryExport()}
            >
              {awaitingToken
                ? i18next.t('Generating...')
                : i18next.t('Download')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  closeModalAndTryViewExport,
})(TokenExpiredModal);
