import { AnyAction } from 'redux';
import _, { pick } from 'underscore';

import * as actions from '^/actions/actions';
import * as analyticsActions from '^/actions/analytics';
import { AsyncActionSet } from '^/actions/makeAsyncActionSet';
import { GET_SHOP_ITEMS } from '^/actions/shop';
import {
  AllOrgs,
  AnalyticsAppliedFilters,
  AnalyticsFiltersState,
  AnalyticsSessionsState,
  HeatmapRow,
  InviteDefaults,
  OrgFilters,
  OrgSessions,
  OrgUsers,
  PdfStatus,
  ReportEmailDefaults,
  ReportsState,
  ShopItemsState,
  SubAggregate,
  TeamReportsState,
  TeamSessions,
  UserPulseDetail,
  UserPulseRater,
  BehaviourOptions,
  OrgFlexiPulseNames,
  ConsentPolicy,
  FilterProfile,
  JobProfileSessions,
  DiscSessions,
} from './types';
import {
  integrateRecentSessions,
  sortedBy,
  integrateRecentJobProfileSessions,
} from './utils';

export function inviteDefaults(state: InviteDefaults = {}, action: AnyAction) {
  switch (action.type) {
    case actions.GET_INVITE_DEFAULTS.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function allOrgs(state: AllOrgs = null, action: AnyAction): AllOrgs {
  switch (action.type) {
    case actions.GET_ALL_ORGS.REQUEST:
      return null;
    case actions.GET_ALL_ORGS.SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
}

function makeApiReducer<T>(actionType: AsyncActionSet) {
  return (state: T | null = null, action: AnyAction): T | null => {
    switch (action.type) {
      case actionType.REQUEST:
        return null;
      case actionType.SUCCESS:
        return action.payload;
      default:
        return state;
    }
  };
}

export const orgUsers = makeApiReducer<OrgUsers>(actions.LOAD_ORG_USERS);

export const teamReports = (
  state: TeamReportsState | null = null,
  action: AnyAction
): TeamReportsState | null => {
  switch (action.type) {
    case actions.LOAD_TEAM_REPORTS.REQUEST:
      return null;
    case actions.LOAD_TEAM_REPORTS.SUCCESS:
      return action.payload;
    case actions.GET_REPORTS.SUCCESS:
      const statusesById: { [key: string]: PdfStatus } = _.object(
        (action.payload as ReportsState).map(({ id, pdf_status }) => [
          id,
          pdf_status,
        ])
      );
      return (
        state &&
        state.map(team => ({
          ...team,
          reports: team.reports.map(report => ({
            ...report,
            pdf_status: statusesById[report.id] || report.pdf_status,
          })),
        }))
      );
    default:
      return state;
  }
};

export const jobProfileSessions = (
  state: JobProfileSessions | null = null,
  action: AnyAction
): JobProfileSessions | null => {
  switch (action.type) {
    case actions.LOAD_JOB_PROFILE_SESSIONS.REQUEST:
      return null;
    case actions.LOAD_JOB_PROFILE_SESSIONS.SUCCESS:
      return action.payload.results;
    case actions.LOAD_JOB_PROFILE_SESSIONS_RECENT.SUCCESS:
      return integrateRecentJobProfileSessions(state, action.payload.results);
    default:
      return state;
  }
};

export const discSessions = (
  state: DiscSessions | null = null,
  action: AnyAction
): TeamSessions | null => {
  switch (action.type) {
    case actions.LOAD_DISC_SESSIONS_PAGE.REQUEST:
      return action.meta.page === 1 ? null : state;
    case actions.LOAD_DISC_SESSIONS_PAGE.SUCCESS:
      return [...(state || []), ...action.payload.results];
    default:
      return state;
  }
};

export const orgFilters = (
  state: OrgFilters | null = null,
  action: AnyAction
): OrgFilters | null => {
  switch (action.type) {
    case actions.CLEAR_ORG_FILTERS:
    case actions.LOAD_ORG_FILTERS.REQUEST:
      return null;
    case actions.LOAD_ORG_FILTERS.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const orgSessions = (
  state: OrgSessions | null = null,
  action: AnyAction
): OrgSessions | null => {
  switch (action.type) {
    case actions.LOAD_ORG_PRODUCTS.REQUEST:
      return null;
    case actions.LOAD_ORG_PRODUCTS.SUCCESS:
      return { activities: [], ...action.payload };
    case actions.LOAD_ORG_SESSIONS_RECENT.SUCCESS:
      return integrateRecentSessions(state, action.payload.results);
    case actions.LOAD_ORG_SESSIONS.SUCCESS:
      if (!state) {
        return null;
      }
      return {
        ...state,
        activities: state.activities.concat(action.payload.results),
      };
    case actions.GET_USER_INFO.SUCCESS:
      if (
        state &&
        action.payload &&
        action.payload.organisation &&
        state.id === action.payload.organisation.id
      ) {
        return {
          ...state,
          credit: action.payload.organisation.credit,
        };
      }
      return state;
    default:
      return state;
  }
};

export const teamSessions = (
  state: TeamSessions | null = null,
  action: AnyAction
): TeamSessions | null => {
  switch (action.type) {
    case actions.LOAD_TEAM_SESSIONS_PAGE.REQUEST:
      return action.meta.page === 1 ? null : state;
    case actions.LOAD_TEAM_SESSIONS_PAGE.SUCCESS:
      return [...(state || []), ...action.payload.results];
    default:
      return state;
  }
};

export function reportEmailDefaults(
  state: ReportEmailDefaults | null = null,
  action: AnyAction
): ReportEmailDefaults | null {
  switch (action.type) {
    case actions.GET_REPORT_EMAIL_DEFAULTS.SUCCESS:
      return action.payload;

    default:
      return state;
  }
}

export function shopItems(
  state: ShopItemsState = null,
  action: AnyAction
): ShopItemsState {
  switch (action.type) {
    case GET_SHOP_ITEMS.SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
}

const emptyAnalyticsFiltersState = {
  filters: {
    organisation: [],
    product: [],
    activity: [],
    group: [],
    user: [],
    age: [],
    sex: [],
    ethnicity: [],
    job_level: [],
    industry: [],
    geography: [],
    filter_profile: [],
  },
  sessions: null,
};

export const updateAdvancedAnalyticsFilterProfileInState = (
  state: AnalyticsFiltersState = emptyAnalyticsFiltersState,
  action: AnyAction
) =>
  Object.assign({}, state, {
    filters: Object.assign({}, state.filters, {
      filter_profile: state.filters.filter_profile.map(filterProfile =>
        filterProfile.id === action.payload.id ? action.payload : filterProfile
      ),
    }),
  });

export const addAdvancedAnalyticsFilterProfileToState = (
  state: AnalyticsFiltersState = emptyAnalyticsFiltersState,
  action: AnyAction
) => {
  const newFilterProfile = pick(action.payload, 'id', 'name', 'filters');

  return Object.assign({}, state, {
    filters: Object.assign({}, state.filters, {
      filter_profile: [...state.filters.filter_profile, newFilterProfile],
    }),
  });
};

export const deleteAdvancedAnalyticsFilterProfileFromState = (
  state: AnalyticsFiltersState = emptyAnalyticsFiltersState,
  action: AnyAction
) => {
  return {
    ...state,
    filters: Object.assign({}, state.filters, {
      filter_profile: state.filters.filter_profile.filter(
        filter_profile => filter_profile.id !== action.payload
      ),
    }),
  };
};

export function analyticsFilters(
  state: AnalyticsFiltersState = emptyAnalyticsFiltersState,
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.CLEAR_ANALYTICS_FILTERS:
      return emptyAnalyticsFiltersState;

    case analyticsActions.GET_ANALYTICS_FILTERS.SUCCESS:
      return action.payload;

    case analyticsActions.UPDATE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return updateAdvancedAnalyticsFilterProfileInState(state, action);

    case analyticsActions.CREATE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return addAdvancedAnalyticsFilterProfileToState(state, action);

    case analyticsActions.DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return deleteAdvancedAnalyticsFilterProfileFromState(state, action);

    default:
      return state;
  }
}

export function analyticsFiltersHeatmap(
  state: AnalyticsFiltersState = emptyAnalyticsFiltersState,
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.GET_ANALYTICS_FILTERS_HEATMAP.SUCCESS:
      return action.payload;

    case analyticsActions.UPDATE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return updateAdvancedAnalyticsFilterProfileInState(state, action);

    case analyticsActions.CREATE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return addAdvancedAnalyticsFilterProfileToState(state, action);

    case analyticsActions.DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return deleteAdvancedAnalyticsFilterProfileFromState(state, action);

    default:
      return state;
  }
}

export function analyticsSessions(
  state: AnalyticsSessionsState = [],
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.CLEAR_ANALYTICS_SESSIONS:
      if (action.payload.initialSessions) {
        return [[...action.payload.initialSessions]];
      }
      return [];

    case analyticsActions.GET_ANALYTICS_FILTERS.SUCCESS:
      if (action.payload.sessions) {
        return [action.payload.sessions.results];
      }
      return [];

    case analyticsActions.GET_ANALYTICS_SESSIONS.SUCCESS:
      const {
        meta: { page, fresh },
        payload: { results },
      } = action;
      const _state = fresh ? [] : state;
      const { length } = _state;
      const newState =
        page >= length
          ? _state.concat(new Array(page - length).fill(null))
          : [..._state];
      newState[page - 1] = results;
      return newState;

    default:
      return state;
  }
}

export function analyticsSessionsOrgList(
  state: string[] = [],
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.GET_ANALYTICS_FILTERS.SUCCESS:
      if (action.payload.sessions) {
        return action.payload.sessions.organisations_in_all_sessions;
      }
      return [];
    default:
      return state;
  }
}

export function analyticsSessionsModal(
  state: AnalyticsSessionsState = [],
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.CLEAR_ANALYTICS_SESSIONS_MODAL:
      if (action.payload.initialSessions) {
        return [[...action.payload.initialSessions]];
      }
      return [];

    case analyticsActions.GET_ANALYTICS_FILTERS_HEATMAP.SUCCESS:
      if (action.payload.sessions) {
        return [action.payload.sessions.results];
      }
      return [];

    case analyticsActions.GET_ANALYTICS_SESSIONS_MODAL.SUCCESS:
      const {
        meta: { page, fresh },
        payload: { results },
      } = action;
      const _state = fresh ? [] : state;
      const { length } = _state;
      const newState =
        page >= length
          ? _state.concat(new Array(page - length).fill(null))
          : [..._state];
      newState[page - 1] = results;
      return newState;

    default:
      return state;
  }
}

function sortedByNewState(
  state: ReadonlyArray<SubAggregate> = [],
  action: AnyAction
) {
  const newState = state
    .filter(aggregate => aggregate.key !== action.meta.key)
    .concat(
      action.meta.values.map(
        (value: string) =>
          state.find(
            aggregate =>
              aggregate.key === action.meta.key && aggregate.value === value
          ) || {
            key: action.meta.key,
            value,
          }
      )
    );
  return sortedBy(
    each => each.key,
    ['organisation', 'activity', 'group'],
    newState
  );
}

function updateAggregates(
  state: ReadonlyArray<SubAggregate> = [],
  action: AnyAction
) {
  return state.map(({ key, value, filtersState }) => {
    const found = action.payload.find(
      (each: any) => each.key === key && each.value === value
    );
    return {
      key,
      value,
      filtersState: found
        ? {
            filters: {},
            sessions: {
              aggregate: found.aggregate,
              aggregate_extra: found.aggregate_extra,
              count: found.count,
              results: [],
            },
          }
        : filtersState,
    };
  });
}

export function subaggregatesModal(
  state: ReadonlyArray<SubAggregate> = [],
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.GET_ANALYTICS_AGGREGATES_MODAL.REQUEST: {
      return sortedByNewState(state, action);
    }

    case analyticsActions.GET_ANALYTICS_AGGREGATES_MODAL.SUCCESS: {
      return updateAggregates(state, action);
    }

    case analyticsActions.CLEAR_ANALYTICS_AGGREGATES_MODAL:
      if (action.payload.key) {
        return state.filter(each => each.key !== action.payload.key);
      }
      return [];

    case analyticsActions.CLEAR_ANALYTICS_SESSIONS:
    case analyticsActions.CLEAR_ANALYTICS_FILTERS_HEATMAP:
      return [];

    default:
      return state;
  }
}

export function subaggregatesHeatmap(
  state: ReadonlyArray<SubAggregate> = [],
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.GET_ANALYTICS_AGGREGATES_HEATMAP.REQUEST: {
      return sortedByNewState(state, action);
    }

    case analyticsActions.GET_ANALYTICS_AGGREGATES_HEATMAP.SUCCESS: {
      return updateAggregates(state, action);
    }

    case analyticsActions.CLEAR_ANALYTICS_AGGREGATES_HEATMAP:
      if (action.payload.key) {
        return state.filter(each => each.key !== action.payload.key);
      }
      return [];

    case analyticsActions.CLEAR_ANALYTICS_SESSIONS:
    case analyticsActions.CLEAR_ANALYTICS_FILTERS_HEATMAP:
      return [];

    default:
      return state;
  }
}

export function subaggregates(
  state: ReadonlyArray<SubAggregate> = [],
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.GET_ANALYTICS_AGGREGATES.REQUEST: {
      return sortedByNewState(state, action);
    }

    case analyticsActions.GET_ANALYTICS_AGGREGATES.SUCCESS: {
      return updateAggregates(state, action);
    }

    case analyticsActions.CLEAR_ANALYTICS_AGGREGATES:
      if (action.payload.key) {
        return state.filter(each => each.key !== action.payload.key);
      }
      return [];

    case analyticsActions.CLEAR_ANALYTICS_SESSIONS:
    case analyticsActions.CLEAR_ANALYTICS_FILTERS:
      return [];

    default:
      return state;
  }
}

function insertRowAt(
  rows: HeatmapRow[],
  heatmapRow: HeatmapRow,
  parent: HeatmapRow
): HeatmapRow[] {
  return rows.map(row =>
    parent === row
      ? { ...row, children: [...row.children, heatmapRow] }
      : { ...row, children: insertRowAt(row.children, heatmapRow, parent) }
  );
}

function deleteRow(rows: HeatmapRow[], heatmapRow: HeatmapRow): HeatmapRow[] {
  return rows
    .filter(row => row !== heatmapRow)
    .map(row => ({ ...row, children: deleteRow(row.children, heatmapRow) }));
}

export function heatmapRows(state: HeatmapRow[] = [], action: AnyAction) {
  function update(
    rows: HeatmapRow[],
    indices: ReadonlyArray<number>,
    heatmapRow: HeatmapRow
  ): HeatmapRow[] {
    const index = indices[0];
    const remainingIndices = indices.slice(1);
    return rows
      .slice(0, index)
      .concat([
        remainingIndices.length
          ? {
              ...rows[index],
              children: update(
                rows[index].children,
                remainingIndices,
                heatmapRow
              ),
            }
          : heatmapRow,
      ])
      .concat(rows.slice(index + 1));
  }

  function deepUpdateFromFilters(
    rows: ReadonlyArray<HeatmapRow>,
    currentFilters: AnalyticsAppliedFilters
  ): HeatmapRow[] {
    return rows.map(row =>
      _.isEqual(action.meta.params, {
        ...currentFilters,
        ...row.appliedFilters,
      })
        ? {
            ...row,
            filtersState: action.payload,
          }
        : {
            ...row,
            children: deepUpdateFromFilters(row.children, {
              ...currentFilters,
              ...row.appliedFilters,
            }),
          }
    );
  }

  function deepUpdateFromAggregates(
    rows: ReadonlyArray<HeatmapRow>,
    currentFilters: AnalyticsAppliedFilters
  ): HeatmapRow[] {
    const { meta } = action;
    return rows.map(row => {
      return (
        action.payload.reduce(
          (acc: null | HeatmapRow, newRow: any) =>
            acc ||
            (_.isEqual(
              {
                ...meta.filters,
                [newRow.key]: [newRow.value],
              },
              {
                ...currentFilters,
                ...row.appliedFilters,
              }
            )
              ? {
                  ...row,
                  loading: false,
                  filtersState: {
                    ...row.filtersState,
                    sessions: {
                      aggregate: newRow.aggregate,
                      count: newRow.count,
                      results: [],
                    },
                  },
                }
              : null),
          null
        ) || {
          ...row,
          children: deepUpdateFromAggregates(row.children, {
            ...currentFilters,
            ...row.appliedFilters,
          }),
        }
      );
    });
  }

  switch (action.type) {
    case analyticsActions.CLEAR_HEATMAP_ROWS:
      return [];

    case analyticsActions.ADD_HEATMAP_ROW: {
      const { heatmapRow, parent } = action.payload;
      if (!parent) {
        return [...state, heatmapRow];
      }
      return insertRowAt(state, heatmapRow, parent);
    }

    case analyticsActions.UPDATE_HEATMAP_ROW: {
      const { heatmapRow, indices } = action.payload;
      return update(state, indices, heatmapRow);
    }

    case analyticsActions.DELETE_HEATMAP_ROW: {
      const { heatmapRow } = action.payload;
      return deleteRow(state, heatmapRow);
    }

    case analyticsActions.GET_ANALYTICS_FILTERS.SUCCESS:
      return deepUpdateFromFilters(state, {});

    case analyticsActions.GET_ANALYTICS_FILTERS_HEATMAP.SUCCESS:
      return deepUpdateFromFilters(state, {});

    case analyticsActions.GET_ANALYTICS_AGGREGATES.SUCCESS:
      return deepUpdateFromAggregates(state, {});

    case analyticsActions.GET_ANALYTICS_AGGREGATES_HEATMAP.SUCCESS:
      return deepUpdateFromAggregates(state, {});

    default:
      return state;
  }
}

export function heatmapRowsModal(state: HeatmapRow[] = [], action: AnyAction) {
  function update(
    rows: HeatmapRow[],
    indices: ReadonlyArray<number>,
    heatmapRow: HeatmapRow
  ): HeatmapRow[] {
    const index = indices[0];
    const remainingIndices = indices.slice(1);
    return rows
      .slice(0, index)
      .concat([
        remainingIndices.length
          ? {
              ...rows[index],
              children: update(
                rows[index].children,
                remainingIndices,
                heatmapRow
              ),
            }
          : heatmapRow,
      ])
      .concat(rows.slice(index + 1));
  }

  function deepUpdateFromFilters(
    rows: ReadonlyArray<HeatmapRow>,
    currentFilters: AnalyticsAppliedFilters
  ): HeatmapRow[] {
    return rows.map(row =>
      _.isEqual(action.meta.params, {
        ...currentFilters,
        ...row.appliedFilters,
      })
        ? {
            ...row,
            filtersState: action.payload,
          }
        : {
            ...row,
            children: deepUpdateFromFilters(row.children, {
              ...currentFilters,
              ...row.appliedFilters,
            }),
          }
    );
  }

  function deepUpdateFromAggregates(
    rows: ReadonlyArray<HeatmapRow>,
    currentFilters: AnalyticsAppliedFilters
  ): HeatmapRow[] {
    const { meta } = action;
    return rows.map(row => {
      return (
        action.payload.reduce(
          (acc: null | HeatmapRow, newRow: any) =>
            acc ||
            (_.isEqual(
              {
                ...meta.filters,
                [newRow.key]: [newRow.value],
              },
              {
                ...currentFilters,
                ...row.appliedFilters,
              }
            )
              ? {
                  ...row,
                  loading: false,
                  filtersState: {
                    ...row.filtersState,
                    sessions: {
                      aggregate: newRow.aggregate,
                      count: newRow.count,
                      results: [],
                    },
                  },
                }
              : null),
          null
        ) || {
          ...row,
          children: deepUpdateFromAggregates(row.children, {
            ...currentFilters,
            ...row.appliedFilters,
          }),
        }
      );
    });
  }

  switch (action.type) {
    case analyticsActions.CLEAR_HEATMAP_ROWS_MODAL:
      return [];

    case analyticsActions.ADD_HEATMAP_ROW_MODAL: {
      const { heatmapRowModal, parent } = action.payload;
      if (!parent) {
        return [...state, heatmapRowModal];
      }
      return insertRowAt(state, heatmapRowModal, parent);
    }

    case analyticsActions.UPDATE_HEATMAP_ROW_MODAL: {
      const { heatmapRowModal, indices } = action.payload;
      return update(state, indices, heatmapRowModal);
    }

    case analyticsActions.DELETE_HEATMAP_ROW_MODAL: {
      const { heatmapRowModal } = action.payload;
      return deleteRow(state, heatmapRowModal);
    }

    case analyticsActions.GET_ANALYTICS_FILTERS_HEATMAP.SUCCESS:
      return deepUpdateFromFilters(state, {});

    case analyticsActions.GET_ANALYTICS_AGGREGATES_MODAL.SUCCESS:
      return deepUpdateFromAggregates(state, {});

    default:
      return state;
  }
}

export function userPulseRater(
  state: UserPulseRater | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case actions.LOAD_USER_PULSE_RATER.SUCCESS:
      return action.payload;
    case actions.DELETE_PULSE_RATER_EXTERNAL.SUCCESS:
      return { ...state, is_deleted: true };
    default:
      return state;
  }
}

export function userPulseDetail(
  state: UserPulseDetail | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case actions.LOAD_USER_PULSE_DETAIL.SUCCESS:
      return action.payload;
    case actions.CLEAR_USER_PULSE_DETAIL:
      return null;
    default:
      return state;
  }
}

export function behaviourOptions(
  state: BehaviourOptions | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case actions.LOAD_BEHAVIOUR_OPTIONS.SUCCESS:
      return action.payload;

    default:
      return state;
  }
}

export function orgFlexiPulseNames(
  state: OrgFlexiPulseNames | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case actions.GET_ORG_FLEXI_PULSE_NAMES.SUCCESS:
      return action.payload;

    default:
      return state;
  }
}

export function consentPolicy(
  state: ConsentPolicy | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case actions.GET_CONSENT_POLICY.SUCCESS:
      return action.payload;

    default:
      return state;
  }
}

export function advancedAnalyticsFilterProfiles(
  state: FilterProfile[] | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case analyticsActions.GET_ADVANCED_ANALYTICS_FILTER_PROFILES.SUCCESS:
      return action.payload.results;
    case analyticsActions.GET_ANALYTICS_FILTERS.SUCCESS:
      return null;
    case analyticsActions.DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE.SUCCESS:
      return state?.filter(profile => profile.id !== action.payload);
    default:
      return state;
  }
}
