import React, { ChangeEvent, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import i18next from 'i18next';
import { connect } from 'react-redux';

import { updateSearchBarForm } from '^/actions/actions';

interface SearchBarFormProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  formKey?: string;
  placeholder?: string;
}

interface DispatchProps {
  updateSearchBarForm: typeof updateSearchBarForm;
}

type Props = SearchBarFormProps & DispatchProps;

class SearchBarForm extends Component<Props> {
  private _inputRef: HTMLInputElement | null = null;

  _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange, formKey } = this.props;

    // Make sure the form still gets the event
    if (this._inputRef) {
      this._inputRef.value = event.target.value;
    }

    // And also bubble it up
    onChange && onChange(event);

    if (formKey) {
      this.props.updateSearchBarForm(formKey, event.target.value);
    }
  };

  _setInputRef = (element: HTMLInputElement | null) => {
    this._inputRef = element;
  };

  _clearSearch = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (this._inputRef) {
      if (this._inputRef.value) {
        event.preventDefault();
        this._inputRef.value = '';
        this._onChange({ target: { value: '' } } as ChangeEvent<
          HTMLInputElement
        >);
      } else {
        this._inputRef.focus();
      }
    }
  };

  render() {
    const { placeholder } = this.props;

    return (
      <form onSubmit={event => event.preventDefault()}>
        <label>
          <input
            ref={this._setInputRef}
            className="input"
            type="text"
            onChange={this._onChange}
            placeholder={placeholder || i18next.t('Search')}
          />
          <a className="search-link" onClick={this._clearSearch}>
            <FontAwesomeIcon
              icon={this._inputRef && this._inputRef.value ? faTimes : faSearch}
            />
          </a>
        </label>
      </form>
    );
  }
}

export default connect(null, {
  updateSearchBarForm,
})(SearchBarForm);
