import React, { ReactElement, useEffect, useRef, useState } from 'react';
import _ from 'underscore';

export interface WindowedRowProps<T, C> {
  chunk: C;
  item: T;
  index: number;
}

interface WindowedProps<T, C> {
  parentElement: HTMLElement | HTMLTableSectionElement | null;
  scrollClassName: string;
  chunkSize: number;
  total: number;
  data: readonly C[];
  row: any;
  rowHeight: number;
  topOffset?: number;
  subaggregatesOffset?: number;
  loading: boolean;
  loadChunk: (index: number) => void;
  getItems: T | any;
  spacer: any;
  chunkPadding: number;
  forcedChunkIndex?: number;
}
const getChunk = (
  parentElement: HTMLElement | SVGElement | null,
  rowHeight: number,
  topOffset: number | undefined,
  subaggregatesOffset: number | undefined,
  chunkSize: number
) => {
  if (!parentElement) {
    return 0;
  }

  const { top } = parentElement.getBoundingClientRect();
  const realTop = top - (topOffset || 0) + (subaggregatesOffset || 0);
  const chunkHeight = rowHeight * chunkSize;
  return realTop > chunkHeight ? 0 : Math.floor(1 - realTop / chunkHeight);
};

const Windowed = <T, C>({
  parentElement,
  scrollClassName,
  data,
  row: Row,
  spacer: Spacer,
  rowHeight,
  topOffset,
  subaggregatesOffset,
  chunkSize,
  total,
  getItems,
  loading,
  loadChunk,
  chunkPadding,
  forcedChunkIndex,
}: WindowedProps<T, C>) => {
  const totalChunks = Math.ceil(total / chunkSize);
  const [chunkIndex, setChunkIndex] = useState(
    getChunk(
      parentElement,
      rowHeight,
      topOffset,
      subaggregatesOffset,
      chunkSize
    )
  );
  useEffect(() => {
    const onScroll = () => {
      const nextChunkIndex = getChunk(
        parentElement,
        rowHeight,
        topOffset,
        subaggregatesOffset,
        chunkSize
      );
      if (
        chunkIndex !== nextChunkIndex &&
        nextChunkIndex <= totalChunks - 1 &&
        typeof forcedChunkIndex === 'undefined'
      ) {
        setChunkIndex(nextChunkIndex);
      }
    };
    if (document.querySelector(scrollClassName) !== null) {
      document
        ?.querySelector(scrollClassName)
        ?.addEventListener('scroll', onScroll);
    }
    return () => {
      document
        ?.querySelector(scrollClassName)
        ?.removeEventListener('scroll', onScroll);
    };
  }, [
    forcedChunkIndex,
    scrollClassName,
    topOffset,
    subaggregatesOffset,
    chunkIndex,
    parentElement,
    rowHeight,
    chunkSize,
    totalChunks,
  ]);

  useEffect(() => {
    if (!loading) {
      if (!data[chunkIndex]) {
        loadChunk(chunkIndex);
      }

      _.range(chunkPadding).forEach(chunkOffset => {
        const chunkIdx = chunkIndex + chunkOffset;
        if (chunkIdx < totalChunks && !data[chunkIdx]) {
          loadChunk(chunkIdx);
        }
      });

      _.range(chunkPadding).forEach(chunkOffset => {
        const chunkIdx = chunkIndex - chunkOffset;
        if (chunkIdx > 0 && !data[chunkIdx]) {
          loadChunk(chunkIdx);
        }
      });
    }
  }, [loadChunk, loading, chunkIndex, data, totalChunks, chunkPadding]);

  const previousForcedChunkIndex = useRef(forcedChunkIndex);
  useEffect(() => {
    if (forcedChunkIndex !== previousForcedChunkIndex.current) {
      if (typeof forcedChunkIndex === 'undefined') {
        setChunkIndex(
          getChunk(
            parentElement,
            rowHeight,
            topOffset,
            subaggregatesOffset,
            chunkSize
          )
        );
      } else {
        setChunkIndex(forcedChunkIndex);
      }
    }

    previousForcedChunkIndex.current = forcedChunkIndex;
  }, [
    topOffset,
    subaggregatesOffset,
    forcedChunkIndex,
    parentElement,
    rowHeight,
    chunkSize,
  ]);
  return (
    <>
      {_.range(totalChunks)
        .map(
          chunkIdx =>
            Math.abs(chunkIndex - chunkIdx) <= chunkPadding && data[chunkIdx]
        )
        .map((chunk, chunkIdx) =>
          chunk ? (
            getItems(chunk).map((rowItem: T, rowIdx: number) => (
              <Row key={rowIdx} item={rowItem} />
            ))
          ) : (
            <Spacer
              key={chunkIdx}
              style={{
                height:
                  (chunkIdx === totalChunks - 1
                    ? total % chunkSize
                    : chunkSize) * rowHeight,
              }}
            />
          )
        )}
    </>
  );
};

export default Windowed as <T, C>(props: WindowedProps<T, C>) => ReactElement;
