import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import React, { PureComponent, ReactNode, PropsWithChildren } from 'react';

import Well from '^/components/Well';

interface State {
  open: boolean;
}

interface OwnProps {
  title?: string;
  heading?: ReactNode;
  questionIndex?: number;
}

type Props = PropsWithChildren<OwnProps>;

export default class InstructionsToggleButton extends PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: !props.questionIndex,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.questionIndex !== this.props.questionIndex) {
      this.setState({
        open: !this.props.questionIndex,
      });
    }
  }

  public renderButton() {
    const hasHeading = this.props.heading ? true : false;
    return (
      <button
        className={classNames('instructions-toggle-button', {
          'instructions-toggle-button-open': this.state.open,
          'instructions-toggle-button-fixed': hasHeading,
        })}
        onClick={this.toggleDropdown}
        role="button"
        aria-expanded={this.state.open}
        tabIndex={0}
      >
        {this.props.title
          ? this.props.title
          : i18next.t<string>('Instructions')}
        <div>
          <FontAwesomeIcon
            className="instructions-dropdown-chevron"
            icon={this.state.open ? faChevronUp : faChevronDown}
            size="1x"
          />
        </div>
      </button>
    );
  }

  public render() {
    return (
      <div>
        {this.props.heading ? (
          <div className="mb-none row">
            <div className="col-xs-12 col-md-8">
              <h2 className="h2-secondary">{this.props.heading}</h2>
            </div>
            <div className="col-xs-12 col-md-4">{this.renderButton()}</div>
          </div>
        ) : (
          this.renderButton()
        )}
        {this.state.open && (
          <Well className="instructions-box mt-base">
            {this.props.children}
          </Well>
        )}
      </div>
    );
  }

  private toggleDropdown = () => {
    this.setState({ open: !this.state.open });
  };
}
