import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';

import MailTo from '^/components/MailTo';
import PureComponent from '^/components/PureComponent';
import WithPopover from '^/components/WithPopover';
import { ENABLE_EMAIL } from '^/settings';

interface Props {
  below?: boolean;
}

export default class InfoIcon extends PureComponent<Props> {
  public render() {
    return (
      <WithPopover
        popover={this.renderPopoverContent()}
        inline
        below={this.props.below}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="ml-md mr-md" />
      </WithPopover>
    );
  }

  private renderPopoverContent() {
    return (
      <Trans i18nKey="TRANS To discuss your account details">
        To discuss your account details get in touch with{' '}
        <MailTo email={ENABLE_EMAIL}>{ENABLE_EMAIL}</MailTo>
      </Trans>
    );
  }
}
