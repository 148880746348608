import React from 'react'; // eslint-disable-line no-unused-vars
import { Set, List } from 'immutable';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { connect } from 'react-redux';

import { Uuid } from '^/reducers/api/types';
import PureComponent from '^/components/PureComponent';
import {
  openUpdateUsersModal,
  openPulseAddGroupsModal,
} from '^/actions/modals';
import NewButton from '../NewButton';

interface OwnProps {
  onUpdate: (userIds: Set<Uuid>) => void;
  onGroupSelected: (groupSet: boolean) => void;
  groupSelected: boolean;
  userIds: Set<Uuid>;
  organisationId?: Uuid;
  isModalOpen?: boolean;
}

interface DispatchProps {
  openUpdateUsersModal: typeof openUpdateUsersModal;
  openPulseAddGroupsModal: typeof openPulseAddGroupsModal;
}

export type Props = DispatchProps & OwnProps;

interface CreatePulseAddUsersButtonConfig {
  icon: any;
  label: string;
  onClick: () => void;
}

type CreatePulseAddUsersButtonConfigKeys = 'addRespondents' | 'addGroups';

interface State {
  selectedButtonId: CreatePulseAddUsersButtonConfigKeys | undefined;
}

export class CreatePulseAddUsers extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedButtonId: undefined,
    };
  }

  componentDidUpdate(): void {
    if (!this.props.isModalOpen) {
      this.setState({ selectedButtonId: undefined });
    }
  }

  renderAddRespondentOrAddGroupButtons() {
    const { organisationId, isModalOpen } = this.props;

    const CreatePulseAddUsersButtonConfig: Record<
      CreatePulseAddUsersButtonConfigKeys,
      CreatePulseAddUsersButtonConfig
    > = {
      addRespondents: {
        icon: faUser,
        label: 'Add respondent(s)',
        onClick: () => this.openUpdateUsersModal(),
      },
      addGroups: {
        icon: faUsers,
        label: 'Add group(s)',
        onClick: () => this.openUpdateGroupsModal(),
      },
    };

    return Object.entries(CreatePulseAddUsersButtonConfig).map(
      ([key, buttonConfig], index) => {
        return (
          <NewButton
            key={index}
            type="button"
            buttonType={
              isModalOpen && this.state.selectedButtonId === key
                ? 'select-pill-selected'
                : 'select-pill'
            }
            onClick={() => {
              buttonConfig.onClick();
              this.setState({
                selectedButtonId: key as CreatePulseAddUsersButtonConfigKeys,
              });
            }}
            disabled={!organisationId}
          >
            <FontAwesomeIcon icon={buttonConfig.icon} />
            {i18next.t<string>(buttonConfig.label)}
          </NewButton>
        );
      }
    );
  }

  public render() {
    const { userIds } = this.props;

    return (
      <div className="spaced-group">
        <span>
          {i18next.t<string>('{{numberUsers}} respondents added', {
            numberUsers: userIds.count(),
          })}
        </span>
        {this.renderAddRespondentOrAddGroupButtons()}
      </div>
    );
  }

  private onUpdate = (userIds: List<Uuid>, groupSet?: boolean) => {
    this.props.onUpdate(userIds.toSet());
    if (groupSet) {
      this.props.onGroupSelected(groupSet);
    }
    if (userIds.count() === 0) {
      this.props.onGroupSelected(false);
    }
  };
  private addGroups(_groupIds: any, userIds: List<string>, groupSet?: boolean) {
    this.onUpdate(userIds, groupSet);
  }

  openUpdateGroupsModal() {
    const { organisationId, userIds } = this.props;
    this.props.openPulseAddGroupsModal(
      organisationId,
      userIds.toList(),
      this.addGroups.bind(this)
    );
  }
  private openUpdateUsersModal = () => {
    const { userIds, organisationId } = this.props;
    organisationId &&
      this.props.openUpdateUsersModal(
        organisationId,
        userIds.toList(),
        undefined,
        this.onUpdate,
        { showInlineForm: false, showFilters: false }
      );
  };
}

export default connect(null, { openUpdateUsersModal, openPulseAddGroupsModal })(
  CreatePulseAddUsers
);
