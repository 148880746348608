import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';
import TaskStatus from '../TaskStatus';

export default class TaskStatuses extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Task Statuses</PageHeader>

        <PageContent>
          <TaskStatus
            raters
            done
            label="View raters"
            onClick={e => e.preventDefault()}
          />
          <TaskStatus
            survey
            progress
            label="Complete survey"
            onClick={e => e.preventDefault()}
          />
          <TaskStatus
            report
            warning
            label="View reports"
            onClick={e => e.preventDefault()}
          />
          <TaskStatus report warning label="View reports" />
          <TaskStatus report inactive label="View reports" />

          <CodeSample
            code={`<TaskStatus raters done label="View raters"
 onClick={e => e.preventDefault()} />
<TaskStatus survey progress label="Complete survey"
 onClick={e => e.preventDefault()} />
<TaskStatus report warning label="View reports"
 onClick={e => e.preventDefault()}/>
<TaskStatus report warning label="View reports" />
<TaskStatus report inactive label="View reports" />`}
          />
        </PageContent>
      </div>
    );
  }
}
