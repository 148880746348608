import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { loadOrganisation } from '^/actions/items';
import { isAccount, needsSuperUser, hasSuperUser } from '^/models/organisation';
import ControlBar from '../ControlBar';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import EditAccount from './accounts/EditAccount';
import { withRouter } from '^/withRouter';
import Editable from '^/components/editable/Editable';
import { updateOrganisation } from '^/actions/collections';
import Alert, { AlertType } from '^/components/Alert';

export class OrganisationEditPage extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.loadOrganisation(this.props.organisationId);
  }

  render() {
    const { organisation, response, updateResponse } = this.props;

    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/accounts/">{i18next.t('Accounts')}</Link>
        <span>{i18next.t('Edit account')}</span>
      </span>
    );
    if (!organisation) {
      return <Loading />;
    }

    if (!isAccount(organisation)) {
      return (
        <h2>
          {i18next.t(
            `Sorry this is an organisation not an account and can't be edited.`
          )}
        </h2>
      );
    }

    return (
      <div>
        <ControlBar
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
          title={
            <Editable
              placeholder={i18next.t('Name')}
              value={organisation.get('name')}
              fieldName="name"
              responseFieldName="name"
              onSave={newName =>
                this.props.updateOrganisation(organisation.get('id'), {
                  name: newName,
                })
              }
              response={updateResponse}
            />
          }
        />
        {isPending(response) && <Loading />}
        {needsSuperUser(organisation) && !hasSuperUser(organisation) && (
          <Alert type={AlertType.Warning}>
            {i18next.t('You have not yet finished setting up this account')}:
            <ul>
              <li>{i18next.t('Please add at least one Superuser.')}</li>
            </ul>
          </Alert>
        )}
        <EditAccount account={organisation} />
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    response: state.responses.get('loadItem'),
    updateResponse: state.responses.get('updateItem'),
    upgradeAccountResponse: state.responses.get('updateItem'),
    organisationId: props.router.params.account,
    organisation: state.items.get('organisations'),
  };
}

export default withRouter(
  connect(mapStateToProps, { loadOrganisation, updateOrganisation })(
    OrganisationEditPage
  )
);
