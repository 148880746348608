// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List, fromJS } from 'immutable';

export const LIMIT = {
  EXACTLY: 'EXACTLY',
  UP_TO: 'UP_TO',
  AT_LEAST: 'AT_LEAST',
  UNRESTRICTED: 'UNRESTRICTED',
};

class NominationRuleLimit {
  constructor(limit) {
    this.limit = limit;
  }

  isOneOf() {
    return List(arguments).contains(this.limit);
  }

  hasLowerBound() {
    return this.isOneOf(LIMIT.EXACTLY, LIMIT.AT_LEAST);
  }

  hasUpperBound() {
    return this.isOneOf(LIMIT.EXACTLY, LIMIT.UP_TO);
  }
}

class NominationRule {
  constructor(rule) {
    this.roleId = rule.getIn(['role', 'id'], null);
    this.limit = new NominationRuleLimit(rule.get('limit'));
    this.countRequired = rule.get('count', 0);
  }

  findMatching(raters) {
    if (this.roleId) {
      return raters.filter(
        rater => rater.getIn(['role', 'id']) === this.roleId
      );
    }
    return raters.filter(rater => rater.get('role') === null);
  }

  needsMoreThan(count) {
    return this.limit.hasLowerBound() && count < this.countRequired;
  }

  canHaveMoreThan(count) {
    return !this.limit.hasUpperBound() || count < this.countRequired;
  }
}

export class NominationRuleFulfillment {
  constructor(rule, raters) {
    this.rule = new NominationRule(rule);
    this.currentCount = this.rule.findMatching(raters).count();
  }

  needsMore() {
    return this.rule.needsMoreThan(this.currentCount);
  }

  canHaveMore() {
    return this.rule.canHaveMoreThan(this.currentCount);
  }
}

const lineManagerRule = fromJS({
  role: null,
  limit: LIMIT.EXACTLY,
  count: 1,
});

export class NominationRulesFulfillment {
  constructor(rules, raters) {
    this.ruleFulfillments = rules
      .map(rule => new NominationRuleFulfillment(rule, raters))
      .push(new NominationRuleFulfillment(lineManagerRule, raters));
  }

  needsMore() {
    return this.ruleFulfillments.some(each => each.needsMore());
  }

  canHaveMore() {
    return this.ruleFulfillments.some(each => each.canHaveMore());
  }
}

function getNominationObligation(rule) {
  const limit = rule.get('limit');
  return List([LIMIT.UNRESTRICTED, LIMIT.UP_TO]).contains(limit)
    ? 'may'
    : 'must';
}

export function getNominationRulesObligation(rules) {
  return rules.some(rule => getNominationObligation(rule) === 'must')
    ? 'must'
    : 'may';
}

export const propTypes = {
  NominationRule: ImmutablePropTypes.mapContains({
    count: PropTypes.number.isRequired,
    limit: PropTypes.string.isRequired,
    role: ImmutablePropTypes.mapContains({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
