import React, { useState } from 'react';
import classNames from 'classnames';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  defaultExpanded: boolean;
  children: React.ReactNode;
  title: string;
  rightText?: () => JSX.Element;
  onClick?: (id: string, isExpanded: boolean) => void;
  closeBox?: boolean;
}
export const ExpandableBox: React.FC<Props> = ({
  defaultExpanded,
  children,
  title,
  rightText,
  onClick,
  closeBox,
}) => {
  const [isExpanded, toggleExpanded] = useState(defaultExpanded);
  const handleClick = () => {
    toggleExpanded(!isExpanded);
    onClick && onClick(title, !isExpanded);
  };

  if (!defaultExpanded && closeBox === false && isExpanded === true) {
    toggleExpanded(false);
  }
  return (
    <>
      <div
        className={classNames('expandable-box', isExpanded ? 'open' : 'closed')}
      >
        <div className="expandable-box-header" onClick={handleClick}>
          <h1>{title}</h1>
          <div className="expandable-box-header-right">
            <div>{rightText && rightText()}</div>
            <div>
              <FontAwesomeIcon
                className="expandable-box-chevron"
                icon={isExpanded ? faChevronUp : faChevronDown}
                size="2x"
              />
            </div>
          </div>
        </div>
        {isExpanded && <div className="children">{children}</div>}
      </div>
    </>
  );
};
