import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import i18next from 'i18next';

import { ACTIVITY_STATUS } from '^/activityStatus';
import PureComponent from '^/components/PureComponent';
import LoadingAndErrors from '^/components/LoadingAndErrors';
import InviteToStageTwoButton from '^/components/activities/tabs/users/InviteToStageTwoButton';
import UsersTable from './UsersTable';
import GoToReportsLink from './GoToReportsLink';

export default class ProfileCreatorsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.setProfileCreatorEmail = this.setProfileCreatorEmail.bind(this);
    this.state = {
      profileCreatorEmail: '',
    };
  }

  confirmAndAddUsers(doAdd) {
    if (this.props.status === ACTIVITY_STATUS.LIVE) {
      this.props.openConfirmationModal(
        i18next.t('add profile creator to'),
        i18next.t('activity'),
        [i18next.t('They will be notified by email.')],
        () => {
          this.props.closeTopModal();
          doAdd();
        },
        this.props.closeTopModal,
        'btn-primary'
      );
    } else {
      doAdd();
    }
  }

  updateUsers(userIds, currentIds) {
    const doAdd = () =>
      this.props.addProfileCreatorToActivityAndRefresh(
        this.props.activityId,
        userIds.toArray(),
        currentIds
      );
    this.confirmAndAddUsers(doAdd);
  }

  setProfileCreatorEmail(email) {
    this.setState({ profileCreatorEmail: email });
  }

  editUser() {
    const { organisation, activityUsers } = this.props;
    const currentIds = activityUsers.map(each => each.getIn(['user', 'id']));
    this.props.openUpdateUsersModal(
      organisation.get('id'),
      currentIds,
      1,
      this.updateUsers.bind(this, currentIds),
      {
        showFilters: false,
        isJobProfile: true,
        setProfileCreatorEmail: this.setProfileCreatorEmail,
      }
    );
  }

  render() {
    const {
      activity,
      activityId,
      activityUsers,
      status,
      readOnly,
      organisation,
      addUsersResponse,
      addGroupsResponse,
      removeUserResponse,
      activitySummary,
    } = this.props;

    const withPulse = activitySummary.get('with_pulse');
    const isEnded = status === ACTIVITY_STATUS.ENDED;
    return (
      <div title={i18next.t('Profile creator')}>
        <div className="clearfix">
          <div className="pull-right">
            {!readOnly && !isEnded && activitySummary.get('is_staged') && (
              <InviteToStageTwoButton {...this.props} />
            )}
            <GoToReportsLink
              activityId={activityId}
              organisationId={organisation.get('id')}
              isJobProfiler
            />
          </div>
        </div>

        {this.props.children}

        <UsersTable
          activity={activity}
          activityUsers={activityUsers}
          activityId={activityId}
          editUser={() => this.editUser()}
          withPulse={withPulse}
          readOnly={readOnly}
          isJobProfiler
        />

        <LoadingAndErrors
          responses={List([
            addUsersResponse,
            removeUserResponse,
            addGroupsResponse,
          ])}
        />
      </div>
    );
  }
}
