import classNames from 'classnames';
import React, { ChangeEvent, PureComponent } from 'react';
import uuid from 'uuid';

import CheckableButton, {
  Props as CheckableButtonProps,
} from '^/components/buttons/CheckableButton';

interface OwnProps {
  question: string;
  questionHeader?: string;
  buttons: ReadonlyArray<CheckableButtonProps>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'small' | 'medium' | 'large';
  questionNumber?: number;
}

interface State {
  currentChecked?: string | number | readonly string[] | null;
}

type Props = OwnProps;

export class CheckableButtonQuestion extends PureComponent<Props, State> {
  private uuid: string = uuid.v4();

  constructor(props: Props) {
    super(props);
    const checkedButton = props.buttons.find(button => button.checked);
    this.state = {
      currentChecked: checkedButton ? checkedButton.value : undefined,
    };
  }

  public render() {
    const {
      buttons,
      question,
      questionHeader,
      size = 'large',
      questionNumber,
    } = this.props;

    return (
      <div className="checkable-button-question question">
        <div className="checkable-button-question-text">
          {!questionHeader && <h5>{question}</h5>}
          {questionHeader && (
            <h5>
              <strong>{questionHeader} - </strong>
              {question}
            </h5>
          )}
        </div>
        <div
          className={classNames('checkable-button-row', size)}
          role="radiogroup"
        >
          {buttons.map(button => (
            <CheckableButton
              name={this.uuid}
              questionNumber={questionNumber}
              key={button.label}
              onChange={this.onChange}
              checked={button.value === this.state.currentChecked}
              {...button}
            />
          ))}
        </div>
      </div>
    );
  }

  private onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentChecked: event.target.value,
    });
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };
}

export default CheckableButtonQuestion;
