import i18next from 'i18next';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import {
  Frequency,
  PulseDisplayFrequency,
  PulseFrequency,
  PulseStatus,
  UserPulseDetail,
} from '^/reducers/api/types';
import {
  MOBILE_DATA_BLOCK_LENGTH,
  MOBILE_DATA_BLOCK_LENGTH_MONTH,
  MOBILE_DATA_BLOCK_LENGTH_4_MONTH,
  WEB_DATA_BLOCK_LENGTH,
  WEB_DATA_BLOCK_LENGTH_MONTH,
  WEB_DATA_BLOCK_LENGTH_4_MONTH,
} from './constants';

export const getNonSelfRatersFromDetail = (
  userPulseDetail?: UserPulseDetail
) => {
  return userPulseDetail?.userpulserater_set.filter(
    rater => rater.is_self === false && !rater.is_deleted
  );
};

export const getDeletedRatersFromDetail = (
  userPulseDetail?: UserPulseDetail
) => {
  return userPulseDetail?.userpulserater_set.filter(
    rater => rater.is_deleted === true
  );
};

export const renderTruncatedTag = (
  htmlTagName: string,
  text: string,
  truncateTextLength: number
) => {
  if (text.length > truncateTextLength) {
    const shortenedText = `${text.substring(0, truncateTextLength)}...`;
    return (
      <div>
        {React.createElement(
          htmlTagName,
          { 'data-tip': true, 'data-for': text },
          shortenedText
        )}
        <ReactTooltip
          id={text}
          clickable
          place={'right'}
          className={'tooltip'}
          textColor="black"
          backgroundColor="white"
          border
          borderColor="#C9CCD6"
        >
          {text}
        </ReactTooltip>
      </div>
    );
  }
  return React.createElement(htmlTagName, null, text);
};

export const findBlockLength = (isMobile: boolean, frequency: Frequency) => {
  if (isMobile) {
    if (frequency.type === PulseFrequency.DAILY) {
      return MOBILE_DATA_BLOCK_LENGTH;
    }
    if (frequency.type === PulseFrequency.WEEKLY && frequency.count === 1) {
      return MOBILE_DATA_BLOCK_LENGTH_MONTH;
    }
    if (frequency.type === PulseFrequency.WEEKLY && frequency.count === 4) {
      return MOBILE_DATA_BLOCK_LENGTH_4_MONTH;
    }
  } else if (!isMobile) {
    if (frequency.type === PulseFrequency.DAILY) {
      return WEB_DATA_BLOCK_LENGTH;
    }
    if (frequency.type === PulseFrequency.WEEKLY && frequency.count === 1) {
      return WEB_DATA_BLOCK_LENGTH_MONTH;
    }
    if (frequency.type === PulseFrequency.WEEKLY && frequency.count === 4) {
      return WEB_DATA_BLOCK_LENGTH_4_MONTH;
    }
  }
};

export const getPulseDisplayFrequency = (pulse: {
  frequency: PulseFrequency;
  frequency_count: number;
}) => {
  switch (pulse.frequency) {
    case PulseFrequency.DAILY:
      if (pulse.frequency_count === 1) {
        return PulseDisplayFrequency.DAILY;
      }
      break;
    case PulseFrequency.WEEKLY:
      if (pulse.frequency_count === 1) {
        return PulseDisplayFrequency.WEEKLY;
      } else if (pulse.frequency_count === 4) {
        return PulseDisplayFrequency.MONTHLY;
      }
  }
  throw new Error(
    `Pulse frequency configuration not expected frequency=${pulse.frequency} frequency_count=${pulse.frequency_count}`
  );
};

export const convertPulseFrequencyToRaw = (
  pulseDisplayFrequency: PulseDisplayFrequency
) => {
  switch (pulseDisplayFrequency) {
    case PulseDisplayFrequency.DAILY:
      return { frequency: PulseFrequency.DAILY, frequency_count: 1 };
    case PulseDisplayFrequency.WEEKLY:
      return { frequency: PulseFrequency.WEEKLY, frequency_count: 1 };
    case PulseDisplayFrequency.MONTHLY:
      return { frequency: PulseFrequency.WEEKLY, frequency_count: 4 };
  }
};

export const getPluralDayFromNumber = (dayNumber: number) => {
  switch (dayNumber) {
    case 0:
      return 'Sundays';
    case 1:
      return 'Mondays';
    case 2:
      return 'Tuesdays';
    case 3:
      return 'Wednesdays';
    case 4:
      return 'Thursdays';
    case 5:
      return 'Fridays';
    case 6:
      return 'Saturdays';
  }
};

export const getDayFromNumber = (dayNumber: number) => {
  switch (dayNumber) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
  }
};

export const getPulsingActionStatus = (status: PulseStatus | undefined) => {
  switch (status) {
    case PulseStatus.CAN_NOT_START:
    case PulseStatus.CAN_START:
    case PulseStatus.NOT_STARTED:
      return i18next.t<string>('Not started');
    case PulseStatus.ENDED:
      return i18next.t<string>('Pulsing finished');
    case PulseStatus.INVITED:
      return i18next.t<string>('Invited');
    case PulseStatus.PULSING:
      return i18next.t<string>('Pulsing');
    case PulseStatus.ENDING:
      return i18next.t<string>('Ending');
  }
};

export const getPulsingStatusFromFilterStatus = (filterStatus: string) => {
  switch (filterStatus) {
    case i18next.t<string>('Invited'):
      return PulseStatus.INVITED;
    case i18next.t<string>('Pulsing'):
      return PulseStatus.PULSING;
    case i18next.t<string>('Not started'):
      return PulseStatus.NOT_STARTED;
    case i18next.t<string>('Ending'):
      return PulseStatus.ENDING;
    case i18next.t<string>('Pulsing finished'):
      return PulseStatus.ENDED;
    default:
      return null;
  }
};

export const selectableStatuses = [PulseStatus.INVITED, PulseStatus.PULSING];
