export const MOCK_PRODUCTS = [
  {
    id: '1',
    icon: '/static/images/eap-logo.png',
    name: 'Emotional Agility Profiler',
    optionalAddonLabel: 'pulse check',
  },
  {
    id: '2',
    icon: '/static/images/prp-logo.png',
    name: 'Positive Resilience Profiler',
  },
  {
    id: '3',
    icon: '/static/img/icons/disc-logo.png',
    name: 'DISC Personality Profiler',
    optionalAddonLabel: 'pulse check',
    optionalSelected: true,
    selected: true,
  },
];

export const MOCK_CAPABILITIES = [
  {
    id: '1',
    icon: '/static/images/eap-logo.png',
    name: 'Confidence',
    selected: true,
  },
  {
    id: '2',
    icon: '/static/images/prp-logo.png',
    name: 'Happiness',
  },
];
