import React, { useMemo } from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Formik, Form, Field, useFormikContext } from 'formik';

import { closeTopModal } from '^/actions/modals';
import { setInputTextLanguage } from '^/actions/ui';
import { hasFailed, isPending } from '../../../responseStates';
import FormError from '../../FormError';
import Loading from '../../Loading';
import FormItem from '^/components/forms/FormItem';
import InputLanguageSelector from '^/components/buttons/InputLanguageSelector';
import { getTextDirection } from '^/utils-ts';
import { DEFAULT_LANGUAGE_CODE, LanguageCode } from '^/constants/routes';

const localFields = {
  [LanguageCode.EN_GB]: {
    subject: 'subject_en_gb',
    content: 'content_en_gb',
    link_text: 'link_text_en_gb',
  },
  [LanguageCode.AR]: {
    subject: 'subject_ar',
    content: 'content_ar',
    link_text: 'link_text_ar',
  },
};

export const NotificationEmailEditForm = props => {
  const { response, inputTextLanguage, notification, preferencesError } = props;

  const { values, setFieldValue } = useFormikContext();

  const renderLocalFormFields = localFieldsObj => {
    const { subject, content, link_text } = localFieldsObj;
    return (
      <div>
        <FormItem label={i18next.t('Subject')}>
          <Field
            name={subject}
            type="text"
            {...getTextDirection(inputTextLanguage)}
          />
          <FormError response={response} formKey={subject.name} />
        </FormItem>

        <FormItem label={i18next.t('Content')}>
          <Field
            name={content}
            as="textarea"
            {...getTextDirection(inputTextLanguage)}
          />
          <FormError response={response} formKey={content.name} />
        </FormItem>

        {values[link_text] && (
          <FormItem label={i18next.t('Link text')}>
            <Field
              name={link_text}
              type="text"
              {...getTextDirection(inputTextLanguage)}
            />
            <FormError response={response} formKey={link_text.name} />
          </FormItem>
        )}
      </div>
    );
  };

  if (isPending(response)) {
    return <Loading className="list-loading" />;
  }

  return (
    <Form className="form-block">
      <InputLanguageSelector
        languages={Object.keys(notification.translations)}
        onChange={lang => {
          props.setPreferredLang(lang);
          setFieldValue('preferred_lang_code', lang);
        }}
      />
      {preferencesError && (
        <p className="text-error">
          {i18next.t('There was an error updating your language preference')}
        </p>
      )}

      {renderLocalFormFields(localFields[inputTextLanguage])}

      <div className="col-xs-12 modal-footer">
        <span className="pull-right">
          <button
            className="btn btn-default"
            type="button"
            onClick={props.closeTopModal}
          >
            {i18next.t('Cancel')}
          </button>
          <button className="btn btn-primary" type="submit">
            {i18next.t('Save changes')}
          </button>
        </span>
      </div>
    </Form>
  );
};

export const NotificationEmailEditFormWrapped = props => {
  const { notification } = props;
  const setInputLang = props.setInputTextLanguage;

  const initialValues = useMemo(() => {
    const values = {
      subject_en_gb: '',
      content_en_gb: '',
      link_text_en_gb: '',
      subject_ar: '',
      content_ar: '',
      link_text_ar: '',
      preferred_lang_code: DEFAULT_LANGUAGE_CODE,
    };

    const initialLang = notification.preferred_lang_code
      ? notification.preferred_lang_code
      : DEFAULT_LANGUAGE_CODE;
    setInputLang(initialLang);
    values['preferred_lang_code'] = initialLang;

    Object.entries(localFields).forEach(([langCode, localFieldsObj]) => {
      Object.entries(localFieldsObj).forEach(([fieldName, localField]) => {
        if (Object.keys(notification.translations).includes(langCode)) {
          const value = notification.translations[langCode][fieldName];
          values[localField] = value;
        }
      });
    });
    return values;
  }, [notification, setInputLang]);

  const saveChanges = values => {
    if (!notification) {
      throw new Error('Could not save notification');
    }

    Object.entries(localFields).forEach(([langCode, localFieldsObj]) => {
      if (Object.keys(notification.translations).includes(langCode)) {
        let data = {
          type: notification.type,
          lang_code: langCode,
          preferred_lang_code: values['preferred_lang_code'],
        };
        Object.entries(localFieldsObj).forEach(([fieldName, localField]) => {
          data[fieldName] = values[localField];
        });

        const notificationId = notification.translations[langCode].id;
        if (notificationId) {
          props.updateEmail(data, notificationId);
        } else {
          props.createEmail(data);
        }
      }
    });
    props.closeTopModal();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={saveChanges}>
      <NotificationEmailEditForm {...props} />
    </Formik>
  );
};

function mapStateToProps(state) {
  return {
    response: state.responses.get('notificationEmails'),
    inputTextLanguage: state.ui.get('inputTextLanguage'),
    preferencesError: hasFailed(
      state.responses.get('setNotificationEmailLanguagePreference')
    ),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  setInputTextLanguage,
})(NotificationEmailEditFormWrapped);
