import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from './PureComponent';

export default class SectionHeader extends PureComponent {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <h4>{this.props.children}</h4>
          <hr />
        </div>
      </div>
    );
  }
}
