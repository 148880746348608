/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Map } from 'immutable';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { RouterParamProps, withRouter } from '^/withRouter';
import { loadPulse } from '^/actions/items';
import { isPending } from '^/responseStates';
import Loading from '^/components/Loading';
import {
  PdfStatus,
  Pulse,
  PulseSession,
  PulseStatus,
  PulseSubscription,
  Uuid,
} from '^/reducers/api/types';
import { SURVEY_STATUS } from '^/models/user';
import { StoreState } from '^/store';
import PulseTable from './PulseTable';
import PulseDetail from './PulseDetail';
import ControlBar from '^/components/ControlBar';
import PulseIconCircle from '../PulseIconCircle';
import { getPulsingActionStatus } from './utils';
import { TabbedContainer } from '../TabbedContainer';
import { deepCopy } from '^/utils';
import { ContainerRectangleTile } from '../ContainerRectangleTile';
import PulseTimeFrequencySelector from './PulseTimeFrequencySelector';

interface DispatchProps {
  loadPulse: typeof loadPulse;
}

interface StateProps {
  response: Map<string, any>;
  pulse: Pulse | undefined;
  pulseId: Uuid;
  userId: Uuid | undefined;
}

interface State {
  expandedIds: Array<{ id: string; isOpen: boolean }> | undefined;
}

type Props = DispatchProps & StateProps & RouterParamProps<{ id: Uuid }, {}>;

export interface PulseUserRow {
  userPulseId: Uuid;
  userId: Uuid;
  fullName: string;
  email: string;
  product: string | undefined;
  assessmentStatus: string;
  reportStatus: string | undefined;
  pulseStatus: string | undefined;
  pulseAction: string | undefined;
}
export type PulseUserRows = ReadonlyArray<PulseUserRow>;

export class PulsePage extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.props.loadPulse(props.pulseId);
    this.state = {
      expandedIds: undefined,
    };
  }

  public reloadPulse = () => this.props.loadPulse(this.props.pulseId);
  public render() {
    const { response, pulse, pulseId } = this.props;

    if (isPending(response) || !pulse) {
      return <Loading />;
    }
    let pulses: Array<Pulse> = Array(pulse?.pulsesubscription_set?.length).fill(
      pulse
    );
    const closeExpandable = (subSet: PulseSubscription[] | undefined) => {
      const filteredId =
        this.state.expandedIds &&
        this.state.expandedIds.filter(
          expanded => expanded.id === ((subSet && subSet[0].name) || '')
        );

      return filteredId && filteredId[0] && filteredId[0].isOpen;
    };
    const onClick = (boxId: string, isExpanded: boolean) => {
      setExpanded(boxId, isExpanded);
    };
    const activity = pulse?.activity_product_version?.activity;
    const pulseIds =
      (pulse &&
        pulse.pulsesubscription_set &&
        pulse.pulsesubscription_set.map(item => item.id)) ||
      [];

    const pulsesClone: Array<Pulse> = deepCopy(pulses);
    const removeSets = pulsesClone.map((item, index) => {
      return {
        ...item,
        pulsesubscription_set:
          item.pulsesubscription_set &&
          item.pulsesubscription_set.filter(sub => sub.id === pulseIds[index]),
        userpulse_set:
          item.userpulse_set &&
          item.userpulse_set.filter(
            user => user?.subscription?.id === pulseIds[index]
          ),
      };
    });
    pulses = removeSets;
    const setExpanded = (id: string | null, isExpanded: boolean) => {
      if (!this.state.expandedIds && id === null) {
        this.setState({
          expandedIds: pulses.map(item => {
            return {
              id:
                (item.pulsesubscription_set &&
                  item.pulsesubscription_set[0].name) ||
                '',
              isOpen: false,
            };
          }),
        });
      }
      if (this.state.expandedIds && id !== null) {
        this.setState({
          expandedIds: pulses.map(item => {
            return {
              id:
                (item.pulsesubscription_set &&
                  item.pulsesubscription_set[0].name) ||
                '',
              isOpen:
                item.pulsesubscription_set &&
                item.pulsesubscription_set[0].name === id
                  ? isExpanded
                  : false,
            };
          }),
        });
      }
    };
    if (activity && !this.state.expandedIds) {
      setExpanded(null, true);
    }
    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/pulses/view/">
          {i18next.t<string>('Manage Pulse Checks')}
        </Link>
        <span>{activity ? i18next.t<string>('Pulse detail') : pulse.name}</span>
      </span>
    );
    const subscriptionSetCount = pulse?.pulsesubscription_set?.length || 0;
    return (
      <div>
        <ControlBar
          title={activity ? i18next.t<string>('Pulse detail') : pulse.name}
          hideSearch
          hideAdd
          icon={<PulseIconCircle />}
          breadcrumb={breadcrumb}
        />

        {activity ? (
          <>
            <h1 className="margin-top-larger margin-bottom-larger">
              {i18next.t<string>('Activity name: ')}
              <Link to={`/page/activities/${activity.id}`}>
                {activity.name}
              </Link>
            </h1>
            <TabbedContainer
              defaultActive="not-invited"
              transparent
              config={[
                {
                  id: 'not-invited',
                  label: i18next.t<string>('Not invited'),
                  noPadding: true,
                  renderer: (
                    <div>
                      <PulseTable
                        pulseId={pulseId}
                        pulseSubscriptionDetails={
                          pulse.pulse_subscription_details
                        }
                        pulseRows={getTableData(pulse)}
                        reloadPulse={this.reloadPulse}
                      />
                    </div>
                  ),
                },
                {
                  id: 'active',
                  noPadding: false,
                  label: i18next.t<string>('Active Pulse Checks'),
                  renderer: (
                    <>
                      <div className="display-flex mb-md">
                        <span className="mr-sm">
                          {i18next.t<string>('Notification frequency:')}
                        </span>
                        <PulseTimeFrequencySelector pulse={pulse} />
                      </div>
                      {pulses.length > 0 ? (
                        pulses.map((item: Pulse, idx: number) => (
                          <PulseDetail
                            key={idx}
                            pulse={item}
                            expandable={Boolean(subscriptionSetCount > 1)}
                            defaultExpanded={Boolean(
                              idx === 0 && pulses.length === 1
                            )}
                            closeBox={closeExpandable(
                              item.pulsesubscription_set
                            )}
                            onClick={onClick}
                          />
                        ))
                      ) : (
                        <ContainerRectangleTile noBorder>
                          {i18next.t<string>('No Active Pulse Checks yet')}
                        </ContainerRectangleTile>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </>
        ) : (
          <PulseDetail pulse={pulse} />
        )}
      </div>
    );
  }
}

const getTableData = (data: Pulse) => {
  const activity = data?.activity_product_version?.activity;
  const userPulseSet = data?.userpulse_set || null;
  const sessions =
    data?.activity_product_version?.activity_product_version_sessions;
  const product = data?.activity_product_version?.product_version.product.name;
  const tableData = activity?.users
    .map(row => {
      const userPulse = userPulseSet?.find(
        userPulses => userPulses.user.id === row.user.id
      );

      return {
        userId: row.user.id,
        fullName: row.user.full_name,
        email: row.user.email,
        product: product,
        assessmentStatus: getAssessmentStatus(sessions, row.user.id),
        reportStatus: getReportStatus(sessions, row.user.id),
        pulseStatus:
          getPulseStatus(sessions, userPulseSet, row.user.id) || undefined,
        pulseAction: getPulsingActionStatus(
          getPulseStatus(sessions, userPulseSet, row.user.id) || undefined
        ),
        userPulseId: userPulse?.id || '',
      };
    })
    .filter(
      item =>
        item.pulseStatus !== 'PULSING' &&
        item.pulseStatus !== 'ENDED' &&
        item.pulseStatus !== 'ENDING' &&
        item.pulseStatus !== 'INVITED'
    );
  return tableData;
};

const getAssessmentStatus = (
  sessions: undefined | ReadonlyArray<PulseSession>,
  userID: Uuid
) => {
  const session = sessions?.find(each => each.user === userID);
  return session
    ? SURVEY_STATUS.DISPLAY[session.survey_status]
    : i18next.t<string>('Not started');
};

const getPulseStatus = (
  reports:
    | readonly { user: string; reports: [{ pdf_status: PdfStatus }] }[]
    | undefined,
  userPulseSet:
    | {
        user: { id: Uuid };
        status: PulseStatus;
        end_date: string | undefined;
      }[]
    | null,
  userID: string
) => {
  const reportGeneratedStatus = reports?.find(report => report.user === userID)
    ?.reports[0]?.pdf_status;
  const hasPulse =
    userPulseSet &&
    userPulseSet.find(pulse => pulse.user.id === userID)?.status;
  const userPulse =
    hasPulse &&
    userPulseSet &&
    userPulseSet.find(pulse => pulse.user.id === userID);

  if (!hasPulse && !reportGeneratedStatus) {
    return PulseStatus.CAN_NOT_START;
  }
  if (!hasPulse && reportGeneratedStatus === 'COMPLETE') {
    return PulseStatus.CAN_START;
  }
  if (hasPulse) {
    return userPulse?.status;
  }
};

const getReportStatus = (
  sessions:
    | undefined
    | readonly {
        user: Uuid;
        reports: [{ pdf_status: PdfStatus }];
      }[],
  userID: Uuid
) => {
  const reportStatus = sessions?.find(session => session.user === userID)
    ?.reports[0]?.pdf_status;
  const reportString = !reportStatus
    ? i18next.t<string>('Not generated')
    : sessions?.find(session => session.user === userID)?.reports[0]
        ?.pdf_status === 'COMPLETE'
    ? i18next.t<string>('Generated')
    : sessions?.find(session => session.user === userID)?.reports[0]
        ?.pdf_status;
  return reportString;
};

export function mapStateToProps(state: StoreState, props: Props) {
  return {
    response: state.responses.get('loadItem'),
    pulse: state.items.get('pulses')?.toJS() as Pulse | undefined,
    pulseId: props.router.params.id,
    userId: state.userProfile.get('id'),
  };
}

export default withRouter(connect(mapStateToProps, { loadPulse })(PulsePage));
