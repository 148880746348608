import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from './PureComponent';

export default class FormMessage extends PureComponent {
  _renderMessage(error) {
    const { renderMessage } = this.props;
    return renderMessage ? renderMessage(error) : error;
  }

  render() {
    if (
      !this.props.response ||
      !this.props.response.getIn(['payload', this.props.formKey])
    ) {
      return null;
    }
    return (
      <p className="text-info">
        {this._renderMessage(
          this.props.response.getIn(['payload', this.props.formKey])
        )}
      </p>
    );
  }
}
