import { createSelector } from 'reselect';

import { ReportFilterSelections } from '^/actions/ui';
import { Uuid } from '^/reducers/api/types';
import { selectUi } from '^/selectors';

export const selectReportFilterSelection = createSelector(
  selectUi,
  ui => ui.get('reportFilterSelection') as ReportFilterSelections
);

export const selectFilterSpec = createSelector(selectUi, ui =>
  ui.get('filterSpec')
);

export const selectPageSize = createSelector(selectUi, ui =>
  ui.get('pageSize')
);

export const selectReportsInitialOrganisation = createSelector(
  selectUi,
  ui => ui.get('reportsInitialOrganisation') as Uuid | null
);
