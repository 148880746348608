import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { getAllOrganisationsActivities } from '^/actions/collections';
import ControlBar from '^/components/ControlBar';
import AverageValues from './AverageValues';
import ExportRawScores from './ExportRawScores';
import ExportResults from './ExportResults';
import ThreeSixtyExport from './ThreeSixtyExport';
import {
  can,
  seeDataExportsAverageValues,
  seeExportRawScores,
  seeThreeSixtyDataExport,
} from '^/capabilities';

export class DataExportsPage extends PureComponent {
  componentDidMount() {
    this.props.getAllOrganisationsActivities();
  }

  render() {
    const { user } = this.props;
    return (
      <div>
        <ControlBar title={i18next.t('Data downloads')} hideAdd hideSearch />
        {can(user, seeExportRawScores()) && <ExportRawScores />}
        <ExportResults />
        {can(user, seeThreeSixtyDataExport()) && <ThreeSixtyExport />}
        {can(user, seeDataExportsAverageValues()) && <AverageValues />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps, {
  getAllOrganisationsActivities,
})(DataExportsPage);
