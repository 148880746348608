import i18next from 'i18next';
import { fromJS, List, Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import {
  getReportEmailDefaults,
  resetResponse,
  sendReports,
  setModalClosable,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import {
  JobProfileSessions,
  ReportEmailDefaults,
  Uuid,
} from '^/reducers/api/types';
import { hasSucceeded } from '^/responseStates';
import { selectReportEmailDefaults } from '^/selectors';
import { selectResponse } from '^/selectors/responses';
import { StoreState } from '^/store';
import ReportJobProgress from './ReportJobProgress';
import SendReportsForm from './SendReportsForm';
import {
  getUsers,
  clearCollection,
  getCollection,
} from '^/actions/collections';

interface OwnProps {
  reports: ReadonlyArray<Uuid>;
  selectedUserIds: ReadonlyArray<Uuid>;
  isTeamReport?: boolean;
  isJobProfiler?: boolean;
  isFlowTeamGeneration?: boolean;
  onClose: () => void;
}

interface DispatchProps {
  resetResponse: typeof resetResponse;
  closeTopModal: typeof closeTopModal;
  sendReports: typeof sendReports;
  getReportEmailDefaults: typeof getReportEmailDefaults;
  setModalClosable: typeof setModalClosable;
  getUsers: typeof getUsers;
  clearCollection: typeof clearCollection;
  getCollection: typeof getCollection;
}

interface StateProps {
  response?: Map<string, any>;
  reportEmailDefaults: ReportEmailDefaults | null;
  users: Array<Map<string, any>>;
  jobProfileSessions: JobProfileSessions;
}

type Props = StateProps & DispatchProps & OwnProps;

export class SendReportsModal extends PureComponent<Props> {
  public UNSAFE_componentWillMount() {
    this.props.resetResponse('sendReports');
    this.props.getReportEmailDefaults();
    this.props.setModalClosable(false);
    this.props.clearCollection('users');
    if (!this.props.isJobProfiler) {
      this.props.getUsers(undefined, 1, {
        simple: true,
        fetch_all: true,
        ids: this.props.selectedUserIds.join(','),
      });
    }
  }

  public render() {
    const {
      response,
      reportEmailDefaults,
      isJobProfiler,
      jobProfileSessions,
    } = this.props;

    const jobNames: string[] = [];
    isJobProfiler &&
      jobProfileSessions &&
      jobProfileSessions.forEach((item, idx) => {
        if (item.reports.length) {
          this.props.reports.forEach(id => {
            if (id === item.reports.map(a => a.id).toString())
              jobNames[idx] = item.activity_product_version.activity.role_name;
          });
        }
      });

    if (!reportEmailDefaults) {
      return null;
    }

    if (hasSucceeded(response)) {
      return (
        <ReportJobProgress
          jobId={response.getIn(['payload', 'job'])}
          onClose={this.props.onClose || this.props.closeTopModal}
        />
      );
    }

    const getSelectedUsers = () => {
      const usersNames = [...new Set(this.props.users)].map(user =>
        user.get('full_name')
      );
      return usersNames.join(', ');
    };

    return (
      <div>
        <div className="mb-lg">
          <h5>
            {isJobProfiler
              ? i18next.t<string>('Job role(s)')
              : i18next.t<string>('Selected respondents')}
          </h5>
          <div className="boxed">
            <div className="box">
              {isJobProfiler
                ? jobNames.filter(Boolean).join(', ')
                : getSelectedUsers()}
            </div>
          </div>
        </div>
        <SendReportsForm
          onSubmit={this.onSubmit}
          onClose={this.props.closeTopModal}
          isTeamReport={this.props.isTeamReport}
          isJobProfiler={this.props.isJobProfiler}
          jobProfileSessions={jobProfileSessions}
          isFlowTeamGeneration={this.props.isFlowTeamGeneration}
          response={response}
          defaultValues={reportEmailDefaults}
        />
      </div>
    );
  }

  private onSubmit = (data: {}) => {
    const { reports } = this.props;
    this.props.sendReports(fromJS(data).merge({ reports }));
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    response: selectResponse(state, 'sendReports'),
    reportEmailDefaults: selectReportEmailDefaults(state),
    users: state.collections.getIn(['users', 'items'], List()),
    jobProfileSessions: state.jobProfileSessions,
  };
}

export default connect(mapStateToProps, {
  resetResponse,
  closeTopModal,
  sendReports,
  getReportEmailDefaults,
  setModalClosable,
  clearCollection,
  getUsers,
  getCollection,
})(SendReportsModal);
