import { combineReducers } from 'redux';

import * as actions from '../actions/actions';

const searchBarForm = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_SEARCH_BAR_FORM:
      return {
        ...state,
        [action.field]: { search: { value: action.value } },
      };
    default:
      return state;
  }
};

export default combineReducers({
  searchBarForm,
});
