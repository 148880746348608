import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import Tab from '^/components/Tab';
import Tabset from '^/components/Tabset';
import EditAccountAdmins from './EditAccountAdmins';
import EditAccountDetail from './EditAccountDetail';
import EditAccountProducts from './EditAccountProducts';
import EditAccountDiscounts from './EditAccountDiscounts';
import {
  getAccountAdminHeader,
  accountCanHaveAdmins,
} from '^/models/organisation';
import ContentBox from '^/components/ContentBox';
import Alert, { AlertType } from '^/components/Alert';

export class EditAccount extends PureComponent {
  render() {
    const { account, readOnly } = this.props;
    const noProductsSelected = account.get('pure_products').isEmpty();
    const canManageAdmins = accountCanHaveAdmins(account);
    const hideDiscounts =
      readOnly && !account.getIn(['bulk_discount_band_collection', 'id']);

    return (
      <div>
        <EditAccountDetail account={account} readOnly={readOnly} />

        {!readOnly && noProductsSelected && (
          <Alert type={AlertType.Error}>
            {i18next.t(
              'You have not yet selected any product access for this account.'
            )}
          </Alert>
        )}

        <Tabset name="create-account-tabs" contentWrapper={ContentBox}>
          {canManageAdmins && (
            <Tab tabName={getAccountAdminHeader(account.get('account_type'))}>
              <EditAccountAdmins account={account} readOnly={readOnly} />
            </Tab>
          )}
          {!readOnly && (
            <Tab tabName={i18next.t('Products and reports')}>
              <EditAccountProducts account={account} />
            </Tab>
          )}
          {!hideDiscounts && (
            <Tab tabName={i18next.t('Discounts')}>
              <EditAccountDiscounts account={account} readOnly={readOnly} />
            </Tab>
          )}
        </Tabset>
      </div>
    );
  }
}

export default EditAccount;
