import { Map } from 'immutable';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { Uuid } from '^/reducers/api/types';
import { StoreState } from '^/store';
import { RouterParamProps } from '^/withRouter';

const selectRootState = <K extends keyof StoreState>(key: K) => (
  state: StoreState
) => state[key];

export const selectCollections = selectRootState('collections');
export const selectSortOrders = selectRootState('sortOrders');
export const selectMultiResponses = selectRootState('multiResponses');
export const selectItems = selectRootState('items');
export const selectUi = selectRootState('ui');
export const selectUserProfile = selectRootState('userProfile');
export const selectActivityReports = selectRootState('activityReports');
export const selectResponses = selectRootState('responses');
export const selectAllOrgs = selectRootState('allOrgs');
export const selectOrgSessions = selectRootState('orgSessions');
export const selectOrgUsers = selectRootState('orgUsers');
export const selectOrgFilters = selectRootState('orgFilters');
export const selectReportEmailDefaults = selectRootState('reportEmailDefaults');
export const selectShopItemCategories = selectRootState('shopItems');
export const simpleOrganisation = selectRootState('organisationDetailSimple');
export const selectReportsTableSelections = selectRootState(
  'reportsTableSelections'
);

export const selectPropString = (_state: StoreState, string: string) => string;

export function selectUuidProp(_state: StoreState, props: Uuid | null) {
  return props;
}

export function selectUuidsProp(_state: StoreState, props: Uuid[]) {
  return props;
}

export function selectCurrentRoutePath(
  _state: StoreState,
  props: RouterParamProps
) {
  return props.router && props.router.location.pathname;
}

const selectGetCollections = createSelector(
  selectMultiResponses,
  multiResponses => multiResponses.get('getCollection')
);

export const selectGetCollection = createCachedSelector(
  selectGetCollections,
  selectPropString,
  (getCollections, str) => getCollections.get(str, Map())
)((_state, str) => str);

export const selectCollection = createCachedSelector(
  selectCollections,
  selectPropString,
  (collections, str) => collections.get(str, Map())
)((_state, str) => str);

export const selectSortOrder = createCachedSelector(
  selectSortOrders,
  selectPropString,
  (sortOrders, str) => sortOrders.get(str, {})
)((_state, str) => str);

export const selectOrdering = createCachedSelector(
  selectCollection,
  collection => {
    return collection.get('ordering');
  }
)((_state, str) => str);

export const selectOrderingObject = createCachedSelector(
  selectCollection,
  collection => {
    const ordering = collection.get('ordering');
    if (ordering) {
      return {
        field: ordering.replace(/^-/, ''),
        reversed: ordering[0] === '-',
      };
    }
  }
)((_state, str) => str);
