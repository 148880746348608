import { Map, Set, List, fromJS } from 'immutable';
import _ from 'underscore';

import * as actions from '../actions/actions';
import * as collections from '../actions/collections';
import { isInProgress } from '^/models/report';

export function toggleRevealPassword(state = Map(), action) {
  switch (action.type) {
    case actions.TOGGLE_REVEAL_PASSWORD:
      const { field, shouldReveal } = action.payload;
      return state.set(field, shouldReveal);
    default:
      return state;
  }
}

export function welcomeModalPage(state = 0, action) {
  switch (action.type) {
    case actions.SET_WELCOME_MODAL_PAGE:
      return action.payload;
    default:
      return state;
  }
}

export function additionalWelcomeModalPage(state = 0, action) {
  switch (action.type) {
    case actions.SET_ADDITIONAL_WELCOME_MODAL_PAGE:
      return action.payload;
    case actions.RESET_ADDITIONAL_WELCOME_MODAL_PAGE:
      return 0;
    default:
      return state;
  }
}

export function filtersExpanded(state = false, action) {
  switch (action.type) {
    case actions.CLOSE_FILTERS:
      return false;
    case actions.TOGGLE_FILTERS:
      return !state;
    default:
      return state;
  }
}

export function expandedFilter(state = null, action) {
  switch (action.type) {
    case actions.EXPAND_FILTER:
      return action.payload.id;
    case actions.TOGGLE_FILTERS:
      return null;
    default:
      return state;
  }
}

export function imageStyles(state = Map(), action) {
  switch (action.type) {
    case actions.STORE_IMAGE_STYLE:
      return state.set(
        action.payload.get('ident'),
        action.payload.get('style')
      );
    default:
      return state;
  }
}

export function showLogoutWarningOverlay(state = false, action) {
  switch (action.type) {
    case actions.SHOW_AUTO_LOGOUT_OVERLAY:
      return true;
    case actions.HIDE_AUTO_LOGOUT_OVERLAY:
      return false;
    default:
      return state;
  }
}

export function menuExpanded(state = null, action) {
  switch (action.type) {
    case actions.OPEN_MENU:
      return action.payload;
    case actions.CLOSE_MENU:
      return null;
    default:
      return state;
  }
}

export function mobileMenuExpanded(state = null, action) {
  switch (action.type) {
    case actions.TOGGLE_MOBILE_MENU:
      return !state;
    case actions.CLOSE_MOBILE_MENU:
      return false;
    default:
      return state;
  }
}

export function currentlyEditingFields(state = Set(), action) {
  switch (action.type) {
    case actions.EDIT_FIELD:
      return state.add(action.payload.id);
    case actions.STOP_EDITING_FIELD:
      return state.remove(action.payload.id);
    case actions.TOGGLE_EDITING_FIELD: {
      const { id } = action.payload;
      return state.contains(id) ? state.remove(id) : state.add(id);
    }
    default:
      return state;
  }
}

export function expandedUiComponents(state = Set(), action) {
  switch (action.type) {
    case actions.COLLAPSE_UI_COMPONENT:
      return state.remove(action.payload.id);
    case actions.EXPAND_UI_COMPONENT:
      return state.add(action.payload.id);
    default:
      return state;
  }
}

export function expandedUiComponentGroups(state = Map(), action) {
  switch (action.type) {
    case actions.COLLAPSE_UI_COMPONENT:
      return state.get(action.payload.groupId) === action.payload.id
        ? state.delete(action.payload.groupId)
        : state;
    case actions.COLLAPSE_ALL_UI_COMPONENTS_IN_GROUP:
      return state.delete(action.payload.groupId);
    case actions.EXPAND_UI_COMPONENT:
      return state.set(action.payload.groupId, action.payload.id);
    default:
      return state;
  }
}

export function expandedPaneSets(state = Map(), action) {
  switch (action.type) {
    case actions.TOGGLE_PANE:
      if (state.get(action.payload.paneset) === action.payload.pane) {
        return state.delete(action.payload.paneset);
      }
      return state.set(action.payload.paneset, action.payload.pane);

    case actions.CLOSE_PANESET:
      return state.delete(action.payload.paneset);
    default:
      return state;
  }
}

export function selectedUiComponents(state = Map(), action) {
  const { namespace, id, ids, selected } = action.payload || {};
  const current = state.get(namespace, Set());

  switch (action.type) {
    case actions.TOGGLE_SELECTION:
      return state.set(
        namespace,
        current.contains(id) ? current.remove(id) : current.add(id)
      );

    case actions.SET_SELECTION:
      return state.set(
        namespace,
        selected ? current.add(id) : current.remove(id)
      );

    case actions.INITIALISE_SELECTION:
      return state.set(namespace, Set(ids));

    case actions.CLEAR_SELECTION:
      return state.set(namespace, Set());

    default:
      return state;
  }
}

export function fixedHeader(state = Map(), action) {
  switch (action.type) {
    case actions.FIX_HEADER:
      return state.set('fixed', true).set('fixedWidth', action.payload);
    case actions.UNFIX_HEADER:
      return state.set('fixed', false);
    default:
      return state;
  }
}

export function controlBarOpaque(state = false, action) {
  switch (action.type) {
    case actions.SET_CONTROL_BAR_OPACITY:
      return action.payload;
    default:
      return state;
  }
}

export function filterSpec(state = {}, action) {
  switch (action.type) {
    case actions.SET_FILTERS:
      return action.payload.filterSpec;
    case actions.SET_FILTER:
      const { key, value } = action.payload;
      let newState = Object.assign({}, state, { [key]: value });
      if (value === undefined || value === null) {
        delete newState[key];
      }
      return newState;
    case actions.CLEAR_FILTERS:
      return {};
    default:
      return state;
  }
}

export function isReordering(state = false, action) {
  switch (action.type) {
    case actions.SET_IS_REORDERING:
      return action.payload;
    default:
      return state;
  }
}

export function currentSearch(state = null, action) {
  switch (action.type) {
    case actions.SET_SEARCH:
      return action.payload;
    default:
      return state;
  }
}

export function selectHighlightedIndex(state = Map(), action) {
  switch (action.type) {
    case actions.SET_HIGHLIGHTED_INDEX:
      return state.set(action.payload.id, action.payload.index);
    case actions.CLEAR_HIGHLIGHTED_INDEX:
      return state.delete(action.payload.id);
    default:
      return state;
  }
}

const INITIAL_QUESTION_INDEX = 0;
export function mapProductVersionQuestionIndex(
  state = INITIAL_QUESTION_INDEX,
  action
) {
  switch (action.type) {
    case actions.SET_MAP_PRODUCT_VERSION_QUESTION_INDEX:
      return action.payload;
    default:
      return state;
  }
}

export function activeTabs(state = Map(), action) {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB:
      const { tabsetName, value } = action.payload;
      return state.set(tabsetName, value);
    default:
      return state;
  }
}

export function confirmButton(state = Map(), action) {
  switch (action.type) {
    case actions.SET_CONFIRM_BUTTON:
      const { key, text, className, isConfirming } = action.payload;
      return state
        .setIn([key, 'text'], text)
        .setIn([key, 'className'], className)
        .setIn([key, 'isConfirming'], isConfirming);

    default:
      return state;
  }
}

export function toggleButton(state = Map(), action) {
  switch (action.type) {
    case actions.TOGGLE_BUTTON:
      const { text, className, isToggled } = action.payload;
      return state
        .set('text', text)
        .set('className', className)
        .set('isToggled', isToggled);

    default:
      return state;
  }
}

export function editorMode(state = Map({ mode: 'edit' }), action) {
  switch (action.type) {
    case actions.SET_EDITOR_MODE:
      return state.set('mode', action.payload.mode);
    default:
      return state;
  }
}

export function currentPageIndex(state = 0, action) {
  switch (action.type) {
    case actions.SET_CURRENT_PAGE_INDEX:
      return action.payload;

    default:
      return state;
  }
}

export function pageSize(state = collections.ITEMS_PER_PAGE, action) {
  switch (action.type) {
    case actions.RESET_PAGE_SIZE:
      return collections.ITEMS_PER_PAGE;

    case actions.SET_PAGE_SIZE:
      return action.payload;

    default:
      return state;
  }
}

export function simpleComponentState(state = Map(), action) {
  let path;
  switch (action.type) {
    case actions.SET_SIMPLE_COMPONENT_STATE:
      path = _.isArray(action.payload.pathOrIndex)
        ? action.payload.pathOrIndex
        : [action.payload.pathOrIndex];
      return state.setIn(path, action.payload.value);
    case actions.CLEAR_SIMPLE_COMPONENT_STATE:
      path = _.isArray(action.payload.pathOrIndex)
        ? action.payload.pathOrIndex
        : [action.payload.pathOrIndex];
      return state.deleteIn(path);
    case actions.ADD_CREDITS_TO_ORGANISATION.SUCCESS:
      if (action.payload) {
        return state.updateIn(
          ['CreateActivityPage', 'selectedOrganisation'],
          organisation =>
            organisation && organisation.get('id') === action.payload.id
              ? organisation.set('credit', action.payload.credit)
              : organisation
        );
      }
      return state;
    default:
      return state;
  }
}

export function formStages(state = Map(), action) {
  switch (action.type) {
    case actions.SET_FORM_STAGE:
      return state.set(action.payload.formName, action.payload.stage);
    case actions.RESET_FORM_STAGE:
      return state.set(action.payload.formName, 0);
    default:
      return state;
  }
}

export function reportsInitiallyGenerating(state = List(), action) {
  switch (action.type) {
    case actions.GET_MY_GENERATING_REPORTS.SUCCESS:
      return state.isEmpty() ? fromJS(action.payload) : state;
    case actions.GET_ORGANISATION_ALL_REPORTS.SUCCESS:
      const reports = fromJS(action.payload);
      return state.isEmpty() ? reports.filter(isInProgress) : state;
    case actions.CREATE_REPORTS.SUCCESS:
    case actions.GENERATE_TEAM_REPORT.SUCCESS:
      return fromJS(action.payload).filter(isInProgress);
    case actions.RESET_GENERATING_REPORTS:
      return List();
    default:
      return state;
  }
}

export function reportsInitiallyGeneratingSimple(state = List(), action) {
  switch (action.type) {
    case actions.GET_MY_GENERATING_REPORTS.SUCCESS:
      return state.isEmpty() ? fromJS(action.payload) : state;
    case actions.GET_ORGANISATION_ALL_REPORTS_SIMPLE.SUCCESS:
      const reports = fromJS(action.payload);
      return state.isEmpty() ? reports.filter(isInProgress) : state;
    case actions.CREATE_REPORTS.SUCCESS:
    case actions.GENERATE_TEAM_REPORT.SUCCESS:
      return fromJS(action.payload).filter(isInProgress);
    case actions.RESET_GENERATING_REPORTS:
      return List();
    default:
      return state;
  }
}
