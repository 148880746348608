import React, { ComponentType } from 'react';
import { routerShape, RouteComponentProps } from 'react-router';

const withRouterContextTypes = {
  router: routerShape,
};

// From `history`, but not exported
export interface DefaultQuery<T = string> {
  [key: string]: T | T[] | null | undefined;
}

export interface RouterParamProps<Params = {}, RouteParams = {}> {
  router: RouteComponentProps<Params, RouteParams, any, DefaultQuery>;
}

const withRouter = <Params, RouteParams, OwnProps>(
  Component: ComponentType<
    Partial<RouterParamProps<Params, RouteParams>> & OwnProps
  >
) => {
  const WithRouter = (props: OwnProps, context: any) =>
    React.createElement(Component, { ...props, ...context });

  WithRouter.contextTypes = withRouterContextTypes;

  return WithRouter;
};

export { withRouter };
