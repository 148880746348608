import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';

import { USER_STATUS } from '^/models/user';
import PureComponent from '^/components/PureComponent';
import ProfilePicture from '^/components/profile/ProfilePicture';
import { ACCOUNT_ADMIN_ROLE_DISPLAY } from '^/models/organisation';

const MAX_COLLAPSED_USERS = 5;

export default class SharingUsersList extends PureComponent {
  paginateUsers(users) {
    return users.slice(0, MAX_COLLAPSED_USERS);
  }

  render() {
    const {
      users,
      heading,
      ifNone,
      extraRender,
      removeUser,
      hideRemove,
      showMoreSelected,
    } = this.props;

    if (!users || users.isEmpty()) {
      return ifNone ? <i>{ifNone}</i> : null;
    }
    const usersList = showMoreSelected ? users : this.paginateUsers(users);
    return (
      <div>
        {heading && <h6>{heading}</h6>}
        <ul className="added-list">
          {usersList.map(user => {
            const { id, role, status, full_name } = user.toObject();
            const inactive = status === USER_STATUS.INACTIVE;

            return (
              <li key={id}>
                <div className="user-details">
                  <ProfilePicture width={25} height={25} user={user} />
                  <div className="user-details-section">
                    <div className="user-details-name">
                      {full_name}
                      {inactive && (
                        <span className="inline-badge badge-warning">
                          {i18next.t('INACTIVE')}
                        </span>
                      )}
                    </div>
                    <div className="user-details-secondary">
                      {ACCOUNT_ADMIN_ROLE_DISPLAY[role]}
                    </div>
                  </div>
                  {extraRender && extraRender(user)}
                  {!hideRemove && removeUser && (
                    <a className="underlined" onClick={() => removeUser(user)}>
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        size="lg"
                        className="remove-icon"
                        title={i18next.t('Remove')}
                      />
                    </a>
                  )}
                </div>
              </li>
            );
          })}
        </ul>

        {users.size > MAX_COLLAPSED_USERS && (
          <button
            className="btn btn-small pull-right"
            onClick={this.props.onToggleShowMore}
          >
            {showMoreSelected ? i18next.t('Show less') : i18next.t('Show more')}
          </button>
        )}
      </div>
    );
  }
}
