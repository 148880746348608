/* eslint-disable no-undef */
import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import Checkbox from '^/components/Checkbox';

export class GroupRow extends PureComponent {
  select() {
    if (this.props.isSelectable && !this.props.disabled) {
      this.props.onSelect();
    }
  }

  getClassName(status) {
    return (
      'group-row ' +
      status.toLowerCase() +
      (this.props.isSelectable && !this.props.disabled ? ' selectable' : '') +
      (this.props.isSelected ? ' selected' : '')
    );
  }

  render() {
    const {
      group,
      isSelectable,
      selectOne,
      isSelected,
      disabled,
      hideAccounts,
    } = this.props;

    const columnWidth = hideAccounts ? '100%' : '50%';

    const { name, organisation_name: accountName } = group.toObject();

    return (
      <tr
        className={this.getClassName(status)}
        disabled={this.props.disabled}
        onClick={() => this.select()}
      >
        {!selectOne && (
          <td className="actions">
            {isSelectable && (
              <Checkbox checked={isSelected} disabled={disabled} />
            )}
          </td>
        )}
        <td style={{ width: columnWidth }}>{name}</td>
        {!hideAccounts && <td style={{ width: columnWidth }}>{accountName}</td>}
      </tr>
    );
  }
}

export default GroupRow;
