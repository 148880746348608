import classNames from 'classnames';
import React, { ReactNode, FunctionComponent } from 'react';

import InstructionsToggleButton from './InstructionsToggleButton';

interface OwnProps {
  children?: ReactNode;
  header?: ReactNode;
  headerInstructions?: ReactNode;
  className?: string;
  questionIndex?: number;
}

type Props = OwnProps;

export const AssessmentBody: FunctionComponent<Props> = ({
  children,
  header,
  headerInstructions,
  questionIndex,
  className,
}) => (
  <div className={classNames('assessment-body', className)}>
    {header &&
      (headerInstructions ? (
        <InstructionsToggleButton
          questionIndex={questionIndex}
          heading={header}
        >
          {headerInstructions}
        </InstructionsToggleButton>
      ) : (
        <h2 className="text-primary">{header}</h2>
      ))}
    {children}
  </div>
);

export default AssessmentBody;
