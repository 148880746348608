import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'underscore';

import PureComponent from '^/components/PureComponent';
import ProductVersionsReportGenerationForm, {
  getFieldsAndInitialValues,
} from './ProductVersionsReportGenerationForm';
import {
  createReportGenerationBatch,
  updateReportGenerationBatch,
  showSavedToast,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { getAutoReportGeneration } from '^/models/product';

export class ProductVersionsReportGenerationModal extends PureComponent {
  submit(data) {
    const updateOrCreateActions = this.props.activityProductVersions.map(
      activityProductVersion => {
        const apvData =
          data.activity_product_versions[activityProductVersion.get('id')];
        let dataToSubmit = {
          comparison_group: apvData.comparison_group,
          report_templates: _.keys(_.pick(apvData.report_templates, x => x)),
          job_level: _.values(apvData.job_levels).filter(x => x)[0],
        };
        const autoReportGeneration = getAutoReportGeneration(
          activityProductVersion
        );

        if (autoReportGeneration) {
          return this.props.updateReportGenerationBatch(
            autoReportGeneration.get('id'),
            dataToSubmit
          );
        }
        dataToSubmit['activity_product_version'] = activityProductVersion.get(
          'id'
        );
        return this.props.createReportGenerationBatch(dataToSubmit);
      }
    );
    return Promise.all(updateOrCreateActions).then(responses => {
      if (responses.every(response => !response?.error)) {
        this.props.showSavedToast();
        this.props.closeTopModal();
      }
    });
  }

  render() {
    const { activityProductVersions, organisation, response } = this.props;
    return (
      <ProductVersionsReportGenerationForm
        organisation={organisation}
        activityProductVersions={activityProductVersions}
        onSubmit={data => this.submit(data)}
        initialValues={getFieldsAndInitialValues(
          activityProductVersions,
          organisation
        )}
        response={response}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses
      .get('createReportGenerationBatch')
      .merge(state.responses.get('updateReportGenerationBatch')),
  };
}

export default connect(mapStateToProps, {
  createReportGenerationBatch,
  updateReportGenerationBatch,
  showSavedToast,
  closeTopModal,
})(ProductVersionsReportGenerationModal);
