import classNames from 'classnames';
import i18next from 'i18next';
import React, { ChangeEvent, KeyboardEvent, PureComponent } from 'react';

import { Key, matchesKey } from '^/keys';
import Loading from './Loading';

interface OwnProps {
  isLoading?: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  secondary?: boolean;
  disabled?: boolean;
  value?: boolean;
  label?: Readonly<{
    on: string;
    off: string;
  }>;
}

export type OnOffToggleProps = OwnProps;

export class OnOffToggle extends PureComponent<OnOffToggleProps> {
  public render() {
    const { isLoading, value, disabled, className, secondary } = this.props;
    return (
      <label className={classNames('switch', className)}>
        {isLoading && <Loading className="inline" />}
        <input
          type="checkbox"
          checked={isLoading ? !value : value}
          onChange={this.onChange}
          disabled={disabled}
        />
        <div
          className={classNames('slider round', {
            disabled,
            'slider-secondary': secondary,
          })}
          aria-checked={value}
          role="checkbox"
          tabIndex={0}
          onKeyDown={this.onKeyDown}
        >
          {!isLoading && this.getLabel()}
        </div>
      </label>
    );
  }

  private getLabel = () => {
    const { label, value } = this.props;
    if (label) {
      return <span className="text">{value ? label.on : label.off}</span>;
    }
    return (
      <span className="text uppercase">
        {value ? i18next.t<string>('on') : i18next.t<string>('off')}
      </span>
    );
  };

  private onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!this.props.disabled) {
      this.props.onChange(event.target.checked);
    }
  };

  private onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (matchesKey(event, Key.SPACE)) {
      event.preventDefault();
      this.props.onChange(!this.props.value);
    }
  };
}

export default OnOffToggle;
