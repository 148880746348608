import { CreateEditProfileDetailsFormProps } from '^/components/advanced-analytics/filter-profiles/CreateEditProfileDetailsForm';
import { flattenResultsRanges } from '^/components/advanced-analytics/utils';
import { DELETE, GET, POST, PUT } from '^/constants/httpMethods';
import makeRsaAction from '^/middleware/makeRsaAction';
import {
  AnalyticsAppliedFilters,
  AnalyticsSession,
  HeatmapRow,
} from '^/reducers/api/types';
import makeAsyncActionSet from './makeAsyncActionSet';

// These data fetch requests are done with POST as the filter queries for every org/activity etc... make the url too long if "Select all" is used

export const GET_ANALYTICS_FILTERS = makeAsyncActionSet(
  'GET_ANALYTICS_FILTERS'
);

export function getAnalyticsFilters(
  filters: AnalyticsAppliedFilters,
  ordering?: string
) {
  const params = {
    ...flattenResultsRanges(filters),
    ...(ordering ? { ordering } : {}),
  };
  return makeRsaAction(
    GET_ANALYTICS_FILTERS,
    '/analytics/filters/',
    POST,
    params,
    { params }
  );
}

export const GET_ANALYTICS_FILTERS_HEATMAP = makeAsyncActionSet(
  'GET_ANALYTICS_FILTERS_HEATMAP'
);

export function getAnalyticsFiltersHeatmap(
  filters: AnalyticsAppliedFilters,
  ordering?: string
) {
  const params = {
    ...flattenResultsRanges(filters),
    ...(ordering ? { ordering } : {}),
  };
  return makeRsaAction(
    GET_ANALYTICS_FILTERS_HEATMAP,
    '/analytics/filters/',
    POST,
    params,
    { params }
  );
}

export const CLEAR_ANALYTICS_FILTERS_HEATMAP =
  'CLEAR_ANALYTICS_FILTERS_HEATMAP';

export function clearAnalyticsFiltersHeatmap() {
  return { type: CLEAR_ANALYTICS_FILTERS_HEATMAP };
}

export const CLEAR_ANALYTICS_FILTERS = 'CLEAR_ANALYTICS_FILTERS';

export function clearAnalyticsFilters() {
  return { type: CLEAR_ANALYTICS_FILTERS };
}

export const GET_ANALYTICS_SESSIONS_COUNT = makeAsyncActionSet(
  'GET_ANALYTICS_SESSIONS_COUNT'
);

export function getAnalyticsSessionsCount(filters: AnalyticsAppliedFilters) {
  return makeRsaAction(
    GET_ANALYTICS_SESSIONS_COUNT,
    '/analytics/sessions-count/',
    POST,
    flattenResultsRanges(filters)
  );
}

export const GET_ANALYTICS_SESSIONS = makeAsyncActionSet(
  'GET_ANALYTICS_SESSIONS'
);

export function getAnalyticsSessions(
  filters: AnalyticsAppliedFilters,
  page: number,
  ordering?: string,
  fresh?: boolean
) {
  return makeRsaAction(
    GET_ANALYTICS_SESSIONS,
    '/analytics/sessions/',
    POST,
    {
      ...flattenResultsRanges(filters),
      page,
      ...(ordering ? { ordering } : {}),
    },
    {
      page,
      fresh,
    }
  );
}

export const CLEAR_ANALYTICS_SESSIONS = 'CLEAR_ANALYTICS_SESSIONS';

export function clearAnalyticsSessions(
  initialSessions?: ReadonlyArray<AnalyticsSession>
) {
  return { type: CLEAR_ANALYTICS_SESSIONS, payload: { initialSessions } };
}

export const GET_ANALYTICS_SESSIONS_MODAL = makeAsyncActionSet(
  'GET_ANALYTICS_SESSIONS_MODAL'
);

export function getAnalyticsSessionsModal(
  filters: AnalyticsAppliedFilters,
  page: number,
  ordering?: string,
  fresh?: boolean
) {
  return makeRsaAction(
    GET_ANALYTICS_SESSIONS_MODAL,
    '/analytics/sessions/',
    POST,
    {
      ...flattenResultsRanges(filters),
      page,
      ...(ordering ? { ordering } : {}),
    },
    {
      page,
      fresh,
    }
  );
}

export const CLEAR_ANALYTICS_SESSIONS_MODAL = 'CLEAR_ANALYTICS_SESSIONS_MODAL';

export function clearAnalyticsSessionsModal(
  initialSessions?: ReadonlyArray<AnalyticsSession>
) {
  return { type: CLEAR_ANALYTICS_SESSIONS_MODAL, payload: { initialSessions } };
}

export const GET_ANALYTICS_AGGREGATES = makeAsyncActionSet(
  'GET_ANALYTICS_AGGREGATES'
);

export function getAnalyticsAggregates(
  filters: AnalyticsAppliedFilters,
  key: keyof AnalyticsAppliedFilters,
  values: ReadonlyArray<string>
) {
  const filtersWithResultsRangesFlattened = flattenResultsRanges(filters);
  return makeRsaAction(
    GET_ANALYTICS_AGGREGATES,
    '/analytics/aggregates/',
    POST,
    { ...filtersWithResultsRangesFlattened, key, values },
    { key, values, filters: filtersWithResultsRangesFlattened }
  );
}

export const CLEAR_ANALYTICS_AGGREGATES = makeAsyncActionSet(
  'CLEAR_ANALYTICS_AGGREGATES'
);

export function clearAnalyticsAggregates(key?: string) {
  return { type: CLEAR_ANALYTICS_AGGREGATES, payload: { key } };
}

export const GET_ANALYTICS_AGGREGATES_MODAL = makeAsyncActionSet(
  'GET_ANALYTICS_AGGREGATES_MODAL'
);

export function getAnalyticsAggregatesModal(
  filters: AnalyticsAppliedFilters,
  key: keyof AnalyticsAppliedFilters,
  values: ReadonlyArray<string>
) {
  const filtersWithResultsRangesFlattened = flattenResultsRanges(filters);
  return makeRsaAction(
    GET_ANALYTICS_AGGREGATES_MODAL,
    '/analytics/aggregates/',
    POST,
    { ...filtersWithResultsRangesFlattened, key, values },
    { key, values, filters: filtersWithResultsRangesFlattened }
  );
}

export const CLEAR_ANALYTICS_AGGREGATES_MODAL = makeAsyncActionSet(
  'CLEAR_ANALYTICS_AGGREGATES_MODAL'
);

export function clearAnalyticsAggregatesModal(key?: string) {
  return { type: CLEAR_ANALYTICS_AGGREGATES_MODAL, payload: { key } };
}

export const GET_ANALYTICS_AGGREGATES_HEATMAP = makeAsyncActionSet(
  'GET_ANALYTICS_AGGREGATES_HEATMAP'
);

export function getAnalyticsAggregatesHeatmap(
  filters: AnalyticsAppliedFilters,
  key: keyof AnalyticsAppliedFilters,
  values: ReadonlyArray<string>
) {
  const filtersWithResultsRangesFlattened = flattenResultsRanges(filters);
  return makeRsaAction(
    GET_ANALYTICS_AGGREGATES_HEATMAP,
    '/analytics/aggregates/',
    POST,
    { ...filtersWithResultsRangesFlattened, key, values },
    { key, values, filters: filtersWithResultsRangesFlattened }
  );
}

export const CLEAR_ANALYTICS_AGGREGATES_HEATMAP = makeAsyncActionSet(
  'CLEAR_ANALYTICS_AGGREGATES_HEATMAP'
);

export function clearAnalyticsAggregatesHeatmap(key?: string) {
  return { type: CLEAR_ANALYTICS_AGGREGATES_HEATMAP, payload: { key } };
}

export const CLEAR_HEATMAP_ROWS = 'CLEAR_HEATMAP_ROWS';

export function clearHeatmapRows() {
  return { type: CLEAR_HEATMAP_ROWS };
}

export const ADD_HEATMAP_ROW = 'ADD_HEATMAP_ROW';

export function addHeatmapRow(
  heatmapRow: HeatmapRow,
  parent: HeatmapRow | undefined
) {
  const flattenedRanges = flattenResultsRanges(heatmapRow.appliedFilters);
  heatmapRow.appliedFilters = flattenedRanges;
  return { type: ADD_HEATMAP_ROW, payload: { heatmapRow, parent } };
}

export const DELETE_HEATMAP_ROW = 'DELETE_HEATMAP_ROW';

export function deleteHeatmapRow(heatmapRow: HeatmapRow) {
  return { type: DELETE_HEATMAP_ROW, payload: { heatmapRow } };
}

export const UPDATE_HEATMAP_ROW = 'UPDATE_HEATMAP_ROW';

export function updateHeatmapRow(
  heatmapRow: HeatmapRow,
  indices: ReadonlyArray<number>
) {
  const flattened = flattenResultsRanges(heatmapRow.appliedFilters);
  heatmapRow.appliedFilters = flattened;
  return { type: UPDATE_HEATMAP_ROW, payload: { heatmapRow, indices } };
}

export const CREATE_ADVANCED_ANALYTICS_FILTER_PROFILE = makeAsyncActionSet(
  'CREATE_ADVANCED_ANALYTICS_FILTER_PROFILE'
);
export const createAdvancedAnalyticsFilterProfile = (data: Object) => {
  return makeRsaAction(
    CREATE_ADVANCED_ANALYTICS_FILTER_PROFILE,
    `/analytics/create-profile/`,
    POST,
    data
  );
};

export const UPDATE_ADVANCED_ANALYTICS_FILTER_PROFILE = makeAsyncActionSet(
  'UPDATE_ADVANCED_ANALYTICS_FILTER_PROFILE'
);

interface UpdateFilterProfileData {
  profile_id: string | undefined;
  formData: CreateEditProfileDetailsFormProps;
  filters: string[];
}
export const updateAdvancedAnalyticsFilterProfile = (
  data: UpdateFilterProfileData
) => {
  return makeRsaAction(
    UPDATE_ADVANCED_ANALYTICS_FILTER_PROFILE,
    `/analytics/update-profile/${data.profile_id}/`,
    PUT,
    {
      ...data.formData,
      filters: data.filters,
    }
  );
};

export const GET_ADVANCED_ANALYTICS_FILTER_PROFILES = makeAsyncActionSet(
  'GET_ADVANCED_ANALYTICS_FILTER_PROFILES'
);
export const getAdvancedAnalyticsFilterProfiles = (product: string) => {
  return makeRsaAction(
    GET_ADVANCED_ANALYTICS_FILTER_PROFILES,
    `/analytics/list-profiles/`,
    GET,
    { product, fetch_all: true }
  );
};

export const DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE = makeAsyncActionSet(
  'DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE'
);
export const deleteAdvancedAnalyticsFilterProfile = (id: string) => {
  return makeRsaAction(
    DELETE_ADVANCED_ANALYTICS_FILTER_PROFILE,
    `/analytics/delete-profile/${id}/`,
    DELETE
  );
};

export const GET_ADVANCED_ANALYTICS_FILTER_PROFILE_DETAIL = makeAsyncActionSet(
  'GET_ADVANCED_ANALYTICS_FILTER_PROFILE_DETAIL'
);
export const getAdvancedAnalyticsFilterProfileDetail = (id: string) => {
  return makeRsaAction(
    GET_ADVANCED_ANALYTICS_FILTER_PROFILE_DETAIL,
    `/analytics/profile/${id}/`,
    GET
  );
};

export const CLEAR_HEATMAP_ROWS_MODAL = 'CLEAR_HEATMAP_ROWS_MODAL';
export function clearHeatmapRowsModal() {
  return { type: CLEAR_HEATMAP_ROWS_MODAL };
}

export const ADD_HEATMAP_ROW_MODAL = 'ADD_HEATMAP_ROW_MODAL';
export function addHeatmapRowModal(
  heatmapRowModal: HeatmapRow,
  parent: HeatmapRow | undefined
) {
  const flattened = flattenResultsRanges(heatmapRowModal.appliedFilters);
  heatmapRowModal.appliedFilters = flattened;
  return { type: ADD_HEATMAP_ROW_MODAL, payload: { heatmapRowModal, parent } };
}

export const DELETE_HEATMAP_ROW_MODAL = 'DELETE_HEATMAP_ROW_MODAL';
export function deleteHeatmapRowModal(heatmapRowModal: HeatmapRow) {
  return { type: DELETE_HEATMAP_ROW_MODAL, payload: { heatmapRowModal } };
}

export const UPDATE_HEATMAP_ROW_MODAL = 'UPDATE_HEATMAP_ROW_MODAL';
export function updateHeatmapRowModal(
  heatmapRowModal: HeatmapRow,
  indices: ReadonlyArray<number>
) {
  const flattened = flattenResultsRanges(heatmapRowModal.appliedFilters);
  heatmapRowModal.appliedFilters = flattened;
  return {
    type: UPDATE_HEATMAP_ROW_MODAL,
    payload: { heatmapRowModal, indices },
  };
}
