import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import {
  createReportNotificationEmail,
  updateReportNotificationEmail,
  setNotificationEmailLanguagePreference,
} from '^/actions/actions';
import NotificationEmailEditForm from './NotificationEmailEditForm';

export class EmailEditModal extends PureComponent {
  createEmail(data) {
    data.type = this.props.type;
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(data);
      return;
    }

    this.props.createReportNotificationEmail(this.props.activityId, data);
  }

  updateEmail(data, notificationId) {
    data.type = this.props.type;
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit(data);
      return;
    }

    this.props.updateReportNotificationEmail(
      this.props.activityId,
      notificationId,
      data
    );
  }

  setPreferredLang(lang) {
    const { onSubmit, activityId, type } = this.props;
    !onSubmit &&
      this.props.setNotificationEmailLanguagePreference(activityId, type, lang);
  }

  render() {
    const { notification, activityId, type } = this.props;

    return (
      <NotificationEmailEditForm
        notification={notification}
        activityId={activityId}
        notificationType={type}
        createEmail={this.createEmail.bind(this)}
        updateEmail={this.updateEmail.bind(this)}
        setPreferredLang={this.setPreferredLang.bind(this)}
      />
    );
  }
}

export default connect(null, {
  createReportNotificationEmail,
  updateReportNotificationEmail,
  setNotificationEmailLanguagePreference,
})(EmailEditModal);
