import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';

interface Props {
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | null) => void;
  formKey?: string;
  placeholder?: string;
  disabled?: boolean;
}

export const PillSearch: React.FunctionComponent<Props> = ({
  className,
  onChange,
  formKey,
  placeholder,
  disabled,
}) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    onChange(e);
    setValue(e ? e.target.value : '');
  };

  const clearSearch = () => {
    handleValueChange(null);
    inputRef.current?.focus();
  };

  return (
    <div className={classNames('search', className)}>
      <form
        key={formKey || 'searchForm'}
        onSubmit={event => event.preventDefault()}
      >
        <label>
          <input
            disabled={disabled}
            name="search"
            value={value}
            ref={inputRef}
            className="input"
            type="text"
            onChange={e => handleValueChange(e)}
            placeholder={placeholder || i18next.t<string>('Search')}
          />
          <a
            className={classNames('search-link', { disabled: disabled })}
            onClick={() => !disabled && clearSearch()}
          >
            <FontAwesomeIcon icon={value ? faTimes : faSearch} />
          </a>
        </label>
      </form>
    </div>
  );
};

export default PillSearch;
