import React, { PureComponent, PropsWithChildren } from 'react';

interface OwnProps {
  page: number;
}

type Props = PropsWithChildren<OwnProps>;

export class ScrollTopOnPageChange extends PureComponent<Props> {
  private element: HTMLElement | null = null;

  public componentDidMount() {
    this.scrollToTop();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.page !== this.props.page) {
      this.scrollToTop();
    }
  }

  public render() {
    return <div ref={this.storeRef}>{this.props.children}</div>;
  }

  private storeRef = (element: HTMLDivElement) => {
    this.element = element;
  };

  private scrollToTop() {
    let element = this.element;

    while (element) {
      element.scrollTop = 0;
      element = element.parentElement;
    }
  }
}

export default ScrollTopOnPageChange;
