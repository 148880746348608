import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';

import { HELP_EMAIL } from '^/settings';
import PeoplewiseLogo from './PeoplewiseLogo';
import Img from './Img';

const NoAuthSignUp = ({ description, children }) => (
  <div className="container">
    <div className="no-auth sign-up">
      <div className="row">
        <div className="col-xs-12">
          <div className="peoplewise-powered-by">
            <Img
              src={'/static/img/brand/peoplewise-logo-full.png'}
              alt="powered by peoplewise - enabling change"
            />
          </div>
        </div>
      </div>

      <h2>{description && <p className="text-center">{description}</p>}</h2>

      <div className="row">{children}</div>
      <div className="row">
        <PeoplewiseLogo />
      </div>
      <div className="call-out-text">
        <Trans i18nKey="TRANS Questions get in touch">
          Questions? get in touch
          <a href={`mailto:${HELP_EMAIL}`}>{{ HELP_EMAIL }}</a>
        </Trans>
      </div>
    </div>
  </div>
);

export default NoAuthSignUp;
