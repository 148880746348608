import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'underscore';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import i18next from 'i18next';

import { setControlBarOpaque } from '../actions/actions';
import PureComponent from './PureComponent';
import SearchBar from './Searchbar';
import { getBodyScrollTop } from '../utils';

export class ControlBar extends PureComponent {
  constructor() {
    super();

    this.limitedSearch = _.debounce(
      searchString => this.props.getItems(searchString),
      500
    );

    this.setControlBarOpaque = function() {
      const scrollTop = getBodyScrollTop();

      if (!this.props.controlBarOpaque && scrollTop) {
        this.props.setControlBarOpaque(true);
      } else if (this.props.controlBarOpaque && !scrollTop) {
        this.props.setControlBarOpaque(false);
      }
    }.bind(this);
  }

  getClassName() {
    return classnames(
      'controlbar',
      this.props.controlBarOpaque && 'opaque',
      this.props.className,
      this.props.hideTitleAndBorder && 'hide-title-and-border'
    );
  }

  getControlsClasses() {
    return classnames(
      'controls',
      this.props.editableTitle && 'editable-title',
      this.props.className
    );
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('scroll', this.setControlBarOpaque);
    window.addEventListener('resize', this.setControlBarOpaque);
    this.setControlBarOpaque();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setControlBarOpaque);
    window.removeEventListener('resize', this.setControlBarOpaque);
  }

  onSearchChange(event) {
    this.limitedSearch(event.target.value);
  }

  render() {
    const {
      breadcrumb,
      icon,
      title,
      hideSearch,
      hideAdd,
      disableAdd,
      onAddClick,
      children,
      selectedCount,
      onSelectAll,
      onClearAll,
    } = this.props;
    const createButtonText = this.props.createButtonText
      ? i18next.t('Create {{objectType}}', {
          objectType: this.props.createButtonText,
        })
      : i18next.t('{{objectType}}', {
          objectType: this.props.createButtonText,
        });
    return (
      <div className={this.getClassName()}>
        {breadcrumb && <div className="breadcrumb-wrapper">{breadcrumb}</div>}
        <div className="controlbar-inner">
          {title && (
            <div className="title">
              <h1>{title}</h1>
              {icon && <span className="with-icon">{icon}</span>}
            </div>
          )}
          <div className={this.getControlsClasses()}>
            {!hideAdd && (
              <button
                className="btn btn-default"
                onClick={onAddClick}
                title={createButtonText}
                disabled={disableAdd}
              >
                <FontAwesomeIcon icon={faPlus} />
                {createButtonText}
              </button>
            )}
            {children}
            {!hideSearch && (
              <SearchBar onChange={this.onSearchChange.bind(this)} />
            )}
          </div>
        </div>
        <div className="selection-info">
          {selectedCount !== undefined && (
            <span>({selectedCount} selected)</span>
          )}
          <span className="selection-info-links">
            {onSelectAll && (
              <a onClick={onSelectAll}>{i18next.t('Select all')}</a>
            )}
            {onSelectAll && onClearAll && <span> / </span>}
            {onClearAll && (
              <a onClick={onClearAll}>{i18next.t('Select none')}</a>
            )}
          </span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    controlBarOpaque: state.ui.get('controlBarOpaque'),
  };
}

export default connect(mapStateToProps, {
  setControlBarOpaque,
})(ControlBar);
