import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-light-svg-icons';

import { can, createAndListPulses } from '^/capabilities';
import { ClickableTile } from '^/components/ClickableTile';
import ControlBar from '^/components/ControlBar';
import PulseIconCircleOutline from '^/components/PulseIconCircleOutline';
import { StoreState } from '^/store';
import { withRouter } from '^/withRouter';

interface StateProps {
  user: Immutable.Map<string, any>;
}

interface DispatchProps {
  push: typeof push;
}

type Props = StateProps & DispatchProps;

const PulseHub: React.FC<Props> = props => {
  const title = i18next.t('Pulse Check Hub') as React.ReactNode;
  const canCreateListPulses = can(props.user, createAndListPulses());
  !canCreateListPulses && props.push('/page/home');

  return (
    <div>
      <ControlBar title={title} hideSearch hideAdd />
      <div className="row">
        <div className="display-flex justify-content-center pulse-hub">
          <div className="col-xs-12 col-sm-10 col-lg-8">
            <div className="clickable-tile-container">
              <ClickableTile
                title={i18next.t<string>('Create Pulse Check')}
                strapline={i18next.t('Create a new Pulse Check')}
                to="/page/pulses/create"
                icon={
                  <PulseIconCircleOutline
                    width={73}
                    height={72}
                    className="dash-icon pulse"
                  />
                }
                buttonClick={() => push('/page/pulses/create')}
                buttonText="Create"
              />
              <ClickableTile
                title={i18next.t<string>('Manage Pulse Checks')}
                strapline={i18next.t('Manage existing Pulse Check')}
                to="/page/pulses/view"
                icon={
                  <FontAwesomeIcon
                    icon={faCog}
                    className="dash-icon pulse-fa"
                  />
                }
                buttonClick={() => push('/page/pulses/view')}
                buttonText="Manage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: StoreState): StateProps => ({
  user: state.userProfile,
});

export default withRouter(connect(mapStateToProps, { push })(PulseHub));
