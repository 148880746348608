import React from 'react';
import { connect } from 'react-redux';

import { Uuid } from '^/reducers/api/types';
import { closeTopModal } from '^/actions/modals';
import ReportActions, {
  STAGES,
} from '^/components/reports/admin/ReportActions';

interface OwnProps {
  orgId: Uuid;
  formStage: string;
  updateFormStage: (formStage: string) => void;
  selectedReports: Array<{ reportId: Uuid }>;
  isTeamReport: boolean;
  isJobMatch?: boolean;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

type Props = OwnProps & DispatchProps;

const ReportActionsPage: React.FC<Props> = props => {
  const {
    orgId,
    formStage,
    updateFormStage,
    selectedReports,
    isTeamReport,
    isJobMatch,
  } = props;

  return (
    <ReportActions
      isTeamReport={isTeamReport}
      reportsSent={() => {
        updateFormStage(STAGES.REPORTS_SENT);
        props.closeTopModal();
      }}
      proceedToNextStage={() => updateFormStage(STAGES.REPORTS_PURCHASED)}
      formName="reportGenerationFlow"
      formStage={formStage}
      organisationId={orgId}
      onClose={props.closeTopModal}
      selectedReports={selectedReports}
      isJobMatch={isJobMatch}
    />
  );
};

export default connect(null, {
  closeTopModal,
})(ReportActionsPage);
