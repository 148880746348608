import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Map, List, Set } from 'immutable';
import i18next from 'i18next';

import { setFilter } from '../../actions/actions';
import PureComponent from '../PureComponent';
import GroupsListPage from './GroupsListPage';

export class SelectGroupsPage extends PureComponent {
  selectAll() {
    this.props.groups.get('items', List()).forEach(this.props.onSelect);
  }

  isGroupSelected(group) {
    return this.props.selectedGroupIds.contains(group.get('id'));
  }

  getGroupRowProps(group) {
    const {
      current,
      isSelectable,
      onSelect,
      selectOne,
      isGroupDisabled,
    } = this.props;
    const isInCurrent = current && current.contains(group.get('id'));
    const isDisabled = isGroupDisabled && isGroupDisabled(group);

    return {
      disabled: isInCurrent || isDisabled,
      isSelectable: true,
      isSelected: (isSelectable && this.isGroupSelected(group)) || isInCurrent,
      onSelect: () => onSelect(group),
      selectOne,
    };
  }

  render() {
    const {
      groups,
      filter,
      filterSpec,
      selectOne,
      noLoad,
      hideAdd,
      selectedOrganisations,
      getter,
      hideAccounts,
      disableSelectAll,
    } = this.props;
    const { items: groupList } = groups.toObject();
    const filteredGroups =
      selectedOrganisations &&
      !selectedOrganisations.isEmpty() &&
      !groupList.isEmpty()
        ? groups.set(
            'items',
            groupList.filter(group =>
              selectedOrganisations.contains(group.get('organisation_id'))
            )
          )
        : groups;

    return (
      <GroupsListPage
        groups={filteredGroups}
        groupIsSelected={this.isGroupSelected.bind(this)}
        pageSize={1000}
        title={
          selectOne ? i18next.t('Select group') : i18next.t('Select groups')
        }
        hideControlBar
        onClearAll={this.props.onClearAll}
        onSelectAll={this.selectAll.bind(this)}
        tableClassName="table-default"
        selectOne={selectOne}
        hideFilters={filter && Object.keys(filter)}
        filterSpec={Object.assign({}, filterSpec, filter)}
        onFilterChange={this.props.setFilter}
        getGroupRowProps={group => this.getGroupRowProps(group)}
        noLoad={noLoad}
        hideAdd={hideAdd}
        getGroups={getter}
        hideAccounts={hideAccounts}
        disableSelectAll={disableSelectAll}
      />
    );
  }
}

export function mapStateToProps(state, props) {
  return {
    filterSpec: state.ui.get('filterSpec'),
    groups: state.collections.get(props.collectionName || 'groups', Map()),
    selectedGroupIds: state.ui.getIn(['selectedUiComponents', 'groups'], Set()),
  };
}

export default connect(mapStateToProps, { setFilter })(SelectGroupsPage);
