import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'underscore';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import PureComponent from '../PureComponent';
import Introduction from './Introduction';
import Typography from './Typography';
import Images from './Images';
import Icons from './Icons';
import Layout from './Layout';
import Menu from './Menu';
import Buttons from './Buttons';
import BadgesAndAlerts from './BadgesAndAlerts';
import Modals from './Modals';
import FormComponents from './FormComponents';
import FormLayout from '^/components/styleguide/FormLayout';
import Tables from './Tables';
import EditableThings from './EditableThings';
import LoginBox from './LoginBox';
import TaskStatuses from './TaskStatuses';
import Tabs from './Tabs';
import ProgressBarsAndTimers from './ProgressBarsAndTimers';
import Popovers from './Popovers';
import ActionBars from './ActionBars';
import AssessmentComponents from './AssessmentComponents';
import { NewComponents } from './NewComponents';
import { withRouter } from '^/withRouter';

const PAGES = {
  introduction: { name: 'Introduction', element: <Introduction /> },
  typography: { name: 'Typography', element: <Typography /> },
  images: { name: 'Images', element: <Images /> },
  layout: { name: 'Layout', element: <Layout /> },
  menu: { name: 'Menu', element: <Menu /> },
  icons: { name: 'Icons', element: <Icons /> },
  buttons: { name: 'Buttons', element: <Buttons /> },
  badgesandalerts: { name: 'Badges and Alerts', element: <BadgesAndAlerts /> },
  formcomponents: { name: 'Form Components', element: <FormComponents /> },
  formlayout: { name: 'Form Layout', element: <FormLayout /> },
  tables: { name: 'Tables', element: <Tables /> },
  modals: { name: 'Modals', element: <Modals /> },
  loginbox: { name: 'Login box', element: <LoginBox /> },
  editablethings: { name: 'Editable things', element: <EditableThings /> },
  taskstatuses: { name: 'Task statuses', element: <TaskStatuses /> },
  tabs: { name: 'Tabs', element: <Tabs /> },
  progressbars: {
    name: 'Progress Bars & Timers',
    element: <ProgressBarsAndTimers />,
  },
  popovers: { name: 'Popovers', element: <Popovers /> },
  actionbars: { name: 'ActionBars', element: <ActionBars /> },
  assessmentcomponents: {
    name: 'Assessment components',
    element: <AssessmentComponents />,
  },
  newcomponents: {
    name: 'New components',
    element: <NewComponents />,
  },
};

export class Styleguide extends PureComponent {
  getActiveClass(path) {
    const { page } = this.props;
    const currentPage =
      typeof page !== 'undefined' ? page : Object.keys(PAGES)[0];
    return classNames({ 'styleguide-link-active': currentPage === path });
  }

  render() {
    const { page } = this.props;
    return (
      <div>
        <div className="margin-bottom">
          <h5 className="margin-top-large">Peoplewise Styleguide</h5>
          <ul className="list-inline">
            {_.map(PAGES, ({ name }, path) => (
              <li key={path} className={this.getActiveClass(path)}>
                <Link to={`/page/styleguide/${path}`}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
        {PAGES[page] ? PAGES[page].element : PAGES.introduction.element}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    page: props.router.params.page,
  };
}

export default withRouter(connect(mapStateToProps)(Styleguide));
