import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, List } from 'immutable';
import { connect } from 'react-redux';

import PureComponent from '../PureComponent';
import {
  viewAveragesReport,
  getToken,
  viewAveragesSetOrganisation,
} from '../../actions/actions';
import { closeModalAndTryViewExport } from '../../actions/actionSequences';
import { getAllCategoriesForOrganisation } from '../../actions/collections';
import ContentBox from '../ContentBox';
import AverageValuesForm from './AverageValuesForm';
import AverageValuesResults from './AverageValuesResults';
import Loading from '../Loading';
import { isPending, hasFailed } from '../../responseStates';

export class AverageValues extends PureComponent {
  componentDidMount() {
    this.props.viewAveragesSetOrganisation(false);
  }

  onOrganisationChange(organisationId) {
    if (organisationId) {
      this.props.getAllCategoriesForOrganisation(organisationId);
    }
    this.props.viewAveragesSetOrganisation(organisationId);
  }

  render() {
    const {
      averageValues,
      organisationsActivities,
      averageValuesConfig,
      response,
      responses,
    } = this.props;
    const requestPending =
      isPending(responses.get('organisationsActivities')) ||
      isPending(response);

    const selectedOrganisationId = averageValuesConfig.get('organisation');
    const selectedOrganisation = organisationsActivities.find(
      org => org.get('id') === selectedOrganisationId
    );

    const activities =
      selectedOrganisation && selectedOrganisation.get('activities');
    const productVersions =
      activities &&
      activities
        .map(activity => activity.get('product_versions'))
        .flatten(true)
        .reduce((acc, item) => acc.set(item.get('id'), item), Map())
        .valueSeq()
        .sortBy(asset => asset.get('name'));

    return (
      <ContentBox>
        <AverageValuesForm
          organisations={organisationsActivities}
          activities={activities || List()}
          productVersions={productVersions || List()}
          categories={this.props.categoriesCollection.get('items') || List()}
          onOrganisationChange={this.onOrganisationChange.bind(this)}
          requestPending={requestPending}
          viewAveragesReport={this.props.viewAveragesReport}
          getToken={this.props.getToken}
          shortlivedToken={this.props.shortlivedToken}
          closeModalAndTryViewExport={this.props.closeModalAndTryViewExport}
        />
        {requestPending && <Loading className="list-loading" />}
        {hasFailed(response) && response.getIn(['errors', 'msg'])}
        {!isPending(response) && averageValues && (
          <AverageValuesResults data={averageValues} />
        )}
      </ContentBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    organisationsActivities: state.collections.getIn(
      ['organisationsActivities', 'items'],
      List()
    ),
    categoriesCollection: state.collections.get('categories', Map()),
    averageValues: state.averageValues,
    averageValuesConfig: state.averageValuesConfig,
    response: state.responses.get('averageValues'),
    responses: state.multiResponses.get('getCollection'),
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  getToken,
  getAllCategoriesForOrganisation,
  viewAveragesReport,
  viewAveragesSetOrganisation,
  closeModalAndTryViewExport,
})(AverageValues);
