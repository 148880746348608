import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

import { startMAPRealQuestions } from '^/actions/actionSequences';
import Well from '^/components/Well';
import { getMAPTextByKey, getQuestionIdFromIndex } from '^/productVersions';
import { anyPending } from '^/responseStates';
import { StoreState } from '^/store';
import AssessmentBody from './AssessmentBody';
import AssessmentContainer from './AssessmentContainer';
import AssessmentHeader from './AssessmentHeader';
import MAPBottomNav from './MAPBottomNav';

interface StateProps {
  anyLoading: boolean;
  submitting: boolean;
  questionIdx: number;
}

interface DispatchProps {
  startMAPRealQuestions: typeof startMAPRealQuestions;
}

interface OwnProps {
  activity: Map<string, any>;
  productVersion: Map<string, any>;
  questionsWithAnswers: List<List<Map<string, any>>>;
}

type Props = StateProps & DispatchProps & OwnProps;

export class MAPStartConfirmation extends PureComponent<Props> {
  public render() {
    const { productVersion, anyLoading, submitting, activity } = this.props;
    const isStaged = activity.get('is_staged');

    return (
      <AssessmentContainer>
        <AssessmentHeader
          productVersion={productVersion}
          hideSectionName={isStaged}
          showCompletionTime
        />
        <AssessmentBody header={i18next.t<string>('Remember')}>
          <Well>
            <ReactMarkdown
              source={getMAPTextByKey(
                productVersion,
                isStaged
                  ? 'starting_actual_questions_intro_text_staged'
                  : 'starting_actual_questions_intro_text'
              )}
            />
          </Well>
          <MAPBottomNav
            navigateToPrevQuestion={undefined}
            navigateToNextQuestion={this.startRealQuestions}
            completeTest={undefined}
            loading={anyLoading}
            submitting={submitting}
            startAssessment
          />
        </AssessmentBody>
      </AssessmentContainer>
    );
  }

  private startRealQuestions = () => {
    const { activity, productVersion } = this.props;
    const newIndex = this.props.questionIdx + 1;
    const newQuestionId = getQuestionIdFromIndex(
      this.props.questionsWithAnswers,
      newIndex
    );
    this.props.startMAPRealQuestions(
      activity.get('id'),
      productVersion.get('id'),
      newQuestionId,
      newIndex
    );
  };
}

const mapStateToProps = (state: StoreState): StateProps => ({
  anyLoading: anyPending([
    state.responses.get('answerQuestion'),
    state.responses.get('createActivityProductVersionSessionEvent'),
    state.responses.get('updateActivityProductVersionSession'),
  ]),
  submitting: anyPending([
    state.responses.get('updateActivityProductVersionSession'),
    state.responses.get('getCollection'),
  ]),
  questionIdx: state.ui.get('mapProductVersionQuestionIndex'),
});

export default connect(mapStateToProps, { startMAPRealQuestions })(
  MAPStartConfirmation
);
