import i18next from 'i18next';
import React, { ChangeEvent, PureComponent } from 'react';

import { Props as CheckableButtonProps } from '^/components/buttons/CheckableButton';
import CheckableButtonQuestion from '^/components/buttons/CheckableButtonQuestion';
import { LikertBounds } from '^/productVersions';

interface OwnProps {
  answer?: string | number | null;
  onAnswer: (value: string | number) => void;
  bounds: LikertBounds;
  questionText: string;
  factor: string;
  isPending?: boolean;
  hasDontKnow?: boolean;
  size?: 'small' | 'medium' | 'large';
  questionNumber: number;
}

type Props = OwnProps;

export default class PerspectivesLikertQuestion extends PureComponent<Props> {
  public render() {
    const {
      isPending,
      answer: currentlySelected,
      questionText,
      bounds,
      size,
      factor,
      questionNumber,
    } = this.props;

    const buttons: CheckableButtonProps[] = bounds.headers.map(
      (answer, index) => {
        return {
          label: i18next.t<string>(answer),
          value: index + 1,
          disabled: isPending,
          checked: currentlySelected === index + 1,
        };
      }
    );
    return (
      <CheckableButtonQuestion
        question={questionText}
        questionHeader={factor}
        buttons={buttons}
        onChange={this.onChange}
        size={size}
        questionNumber={questionNumber}
      />
    );
  }

  private onChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.props.onAnswer(parseFloat(event.target.value));
}
