import {
  FilterOptions,
  FiltersAndOrdering,
  PaginatedResultsResponse,
  Selections,
} from '^/components/InteractiveTable';

export const MOCK_FILTER_OPTIONS: FilterOptions = {
  hat: [
    {
      label: 'Option two',
      key: 'option-two',
    },
    {
      label: 'Option three',
      key: 'option-three',
    },
  ],
  status: [
    {
      label: 'Pulsing',
      key: 'pulsing',
    },
  ],
};

export const MOCK_FILTERS_AND_ORDERING: FiltersAndOrdering = {
  filters: MOCK_FILTER_OPTIONS,
  sorting: {
    key: 'age',
    reverse: false,
  },
  page: 1,
};

export const MOCK_FILTERS_AND_ORDERING_REVERSE: FiltersAndOrdering = {
  filters: MOCK_FILTER_OPTIONS,
  sorting: {
    key: 'age',
    reverse: true,
  },
  page: 1,
};

export const MOCK_FILTERS: FilterOptions = {
  hat: [
    {
      label: 'Option one',
      key: 'option-one',
    },
    {
      label: 'Option two',
      key: 'option-two',
    },
    {
      label: 'Option three',
      key: 'option-three',
    },
    {
      label: 'Option four',
      key: 'option-four',
    },
    {
      label: 'Option five',
      key: 'option-five',
    },
    {
      label: 'Option six',
      key: 'option-six',
    },
    {
      label: 'Option seven',
      key: 'option-seven',
    },
    {
      label: 'Option eight',
      key: 'option-eight',
    },
  ],
  status: [
    {
      label: 'Pulsing',
      key: 'pulsing',
    },
    {
      label: 'Invited',
      key: 'invited',
    },
    {
      label: 'Stopped',
      key: 'stopped',
    },
  ],
  'pulse-type': [
    {
      label: 'Activity based',
      key: 'activity-based',
    },
    {
      label: 'Standalone',
      key: 'standalone',
    },
  ],
};
export const MOCK_FILTERS_CHANGE_STATUS: FilterOptions = {
  hat: [
    {
      label: 'Option one',
      key: 'option-one',
    },
    {
      label: 'Option two',
      key: 'option-two',
    },
    {
      label: 'Option three',
      key: 'option-three',
    },
    {
      label: 'Option four',
      key: 'option-four',
    },
    {
      label: 'Option five',
      key: 'option-five',
    },
    {
      label: 'Option six',
      key: 'option-six',
    },
    {
      label: 'Option seven',
      key: 'option-seven',
    },
    {
      label: 'Option eight',
      key: 'option-eight',
    },
  ],
  status: [
    {
      label: 'Pulsing',
      key: 'pulsing',
    },
    {
      label: 'Invited',
      key: 'invited',
    },
  ],
  'pulse-type': [
    {
      label: 'Activity based',
      key: 'activity-based',
    },
    {
      label: 'Standalone',
      key: 'standalone',
    },
  ],
};
export const MOCK_FILTERS_CHANGE_HAT: FilterOptions = {
  hat: [
    {
      label: 'Option one',
      key: 'option-one',
    },
    {
      label: 'Option two',
      key: 'option-two',
    },
  ],
  status: [
    {
      label: 'Pulsing',
      key: 'pulsing',
    },
    {
      label: 'Invited',
      key: 'invited',
    },
    {
      label: 'Stopped',
      key: 'stopped',
    },
  ],
  'pulse-type': [
    {
      label: 'Activity based',
      key: 'activity-based',
    },
    {
      label: 'Standalone',
      key: 'standalone',
    },
  ],
};
export const MOCK_TABLE_ROWS = [
  {
    id: '1',
    respondent: 'jenny',
    status: 'pulsing',
    age: '12',
    hat: 'no',
    'pulse-type': 'standalone',
    button: 'Details',
  },
  {
    id: '2',
    respondent: 'sid',
    status: 'invited',
    age: '32',
    hat: 'no',
    'pulse-type': 'standalone',
    button: 'Button',
  },
  {
    id: '3',
    respondent: 'frank',
    status: 'stopped',
    age: '44',
    hat: 'yes',
    'pulse-type': 'activity',
    button: 'Text',
  },
];
export const MOCK_EXTRA_PAGE = [
  {
    id: '4',
    respondent: 'Wanda',
    status: 'pulsing',
    age: '22',
    hat: 'yes',
    'pulse-type': 'activity',
    button: 'Details',
  },
  {
    id: '5',
    respondent: 'Bert',
    status: 'pulsing',
    age: '12',
    hat: 'no',
    'pulse-type': 'activity',
    button: 'Button',
  },
  {
    id: '6',
    respondent: 'Cleo',
    status: 'stopped',
    age: '32',
    hat: 'no',
    'pulse-type': 'activity',
    button: 'Details',
  },
];
export const MOCK_TABLE_ROWS_SORTED = [
  {
    id: '3',
    respondent: 'frank',
    status: 'stopped',
    age: '44',
    hat: 'yes',
    'pulse-type': 'standalone',
    button: 'Text',
  },
  {
    id: '1',
    respondent: 'jenny',
    status: 'pulsing',
    age: '12',
    hat: 'no',
    'pulse-type': 'standalone',
    button: 'Button',
  },
  {
    id: '2',
    respondent: 'sid',
    status: 'invited',
    age: '32',
    hat: 'no',
    'pulse-type': 'activity',
    button: 'Details',
  },
];

export const MOCK_TABLE_ROWS_FILTERED = [
  {
    id: '3',
    respondent: 'frank',
    status: 'stopped',
    age: '44',
    hat: 'yes',
    'pulse-type': 'activity',
    button: 'Text',
  },
];

export const MOCK_PAGINATED_TABLE_DATA: PaginatedResultsResponse<any> = {
  count: 15,
  results: [...MOCK_TABLE_ROWS],
};

export const MOCK_PAGINATED_TABLE_DATA_FILTERED: PaginatedResultsResponse<any> = {
  count: 1,
  results: [...MOCK_TABLE_ROWS_FILTERED],
};

export const MOCK_PAGINATED_TABLE_DATA_SORTED: PaginatedResultsResponse<any> = {
  count: 15,
  results: [...MOCK_TABLE_ROWS_SORTED],
};

export const MOCK_SELECTIONS: ReadonlyArray<Selections> = [
  {
    id: '1',
    checked: false,
    selectable: true,
  },
  {
    id: '2',
    checked: false,
    selectable: true,
  },
  {
    id: '3',
    checked: false,
    selectable: true,
  },
];
