import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import ContentBox from '../ContentBox';

export default class LegalPage extends PureComponent {
  render() {
    return (
      <div className={'row' + (this.props.smallText ? ' small' : '')}>
        <ContentBox className="col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2">
          <h2>{this.props.title}</h2>
          {this.props.children}
        </ContentBox>
      </div>
    );
  }
}
