/* eslint-disable no-console */
import React, { Key, useState } from 'react';
import i18next from 'i18next';
import {
  faCheck,
  faFileDownload,
  faPlusSquare,
  faPlus,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import PageHeader from '../PageHeader';
import SectionHeader from '../SectionHeader';
import { ClickableTile } from '../ClickableTile';
import { ContainerRectangleTile } from '../ContainerRectangleTile';
import Checkbox from '../Checkbox';
import FormItem from '../forms/FormItem';
import TextArea from '../forms/TextArea';
import { TabbedContainer } from '../TabbedContainer';
import { ExpandableBox } from '../ExpandableBox';
import { InteractiveTable } from '../InteractiveTable';
import {
  MOCK_FILTERS,
  MOCK_FILTERS_AND_ORDERING,
  MOCK_FILTERS_CHANGE_HAT,
  MOCK_FILTERS_CHANGE_STATUS,
  MOCK_PAGINATED_TABLE_DATA,
  MOCK_PAGINATED_TABLE_DATA_FILTERED,
  MOCK_PAGINATED_TABLE_DATA_SORTED,
  MOCK_EXTRA_PAGE,
  MOCK_SELECTIONS,
  MOCK_FILTERS_AND_ORDERING_REVERSE,
} from '^/__helpers__/table-mocks';
import { PillSearch } from '../PillSearch';
import { BottomActionBar } from '../BottomActionBar';
import PulseIconCircleWhite from '../PulseIconCircleWhite';
import { NewButton } from '../NewButton';
import PulseIcon from '../PulseIcon';
import { PulseBrandStatusMark } from '../PulseBrandStatusMark';
import TaskCompleteTimed from '../TaskCompleteTimed';
import { ProductSelector } from '../ProductSelector';
import { MOCK_PRODUCTS, MOCK_CAPABILITIES } from '^/__helpers__/productMocks';

export const NewComponents = <T extends { key: Key; value: string }>() => {
  const [showActionBar, setShowActionBar] = useState(false);
  const [showActionBarError, setShowActionBarError] = useState(false);
  const [numOfRespondents, setNumOfRespondents] = useState(1);
  const [showTaskCompleted, setShowTaskCompleted] = useState(false);
  const toggleShowActionBar = () => {
    setShowActionBar(!showActionBar);
  };

  const [rowData, setRowData] = useState(MOCK_PAGINATED_TABLE_DATA);
  const [filters, setFilters] = useState(MOCK_FILTERS);
  const [selections, setSelections] = useState(MOCK_SELECTIONS);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(
    MOCK_FILTERS_AND_ORDERING
  );
  const [page, setPage] = useState(1);

  const mockLoadPage = () => {
    const rows = JSON.parse(JSON.stringify(rowData));
    const row = Object.assign([], [...rows.results]);
    row.push(MOCK_EXTRA_PAGE[0]);
    row.push(MOCK_EXTRA_PAGE[1]);
    row.push(MOCK_EXTRA_PAGE[2]);
    rows.results = row;
    setRowData(rows);
    setPage(page + 1);
  };

  const mockDoFiltersSearchSort = (
    type: string,
    key: string | number,
    selected: string | number,
    reverse?: boolean
  ) => {
    switch (type) {
      case 'filter':
        if (
          JSON.stringify(rowData) === JSON.stringify(MOCK_PAGINATED_TABLE_DATA)
        ) {
          setRowData(MOCK_PAGINATED_TABLE_DATA_FILTERED);
        } else {
          setRowData(MOCK_PAGINATED_TABLE_DATA);
        }
        if (key === 'hat') {
          setFilters(MOCK_FILTERS_CHANGE_STATUS);
        }
        if (key === 'status') {
          setFilters(MOCK_FILTERS_CHANGE_HAT);
        }
        break;
      case 'sort':
        if (!reverse) {
          setSelectedFilterOptions(MOCK_FILTERS_AND_ORDERING);
          setRowData(MOCK_PAGINATED_TABLE_DATA_SORTED);
        } else {
          setSelectedFilterOptions(MOCK_FILTERS_AND_ORDERING_REVERSE);
          setRowData(MOCK_PAGINATED_TABLE_DATA);
        }
        break;
      case 'search':
        if (selected) {
          setRowData(MOCK_PAGINATED_TABLE_DATA_FILTERED);
        } else {
          setRowData(MOCK_PAGINATED_TABLE_DATA);
        }
        break;
      default:
        break;
    }
    setPage(1);
  };

  const mockCellRender = (key: string, value: string, row: T[]) => {
    //whole row send to renderer so you could for example get user name to put in details link etc
    let render = <>{value}</>;
    if (key === 'button') {
      const id = row.find(item => item['key'] === 'id')?.value;
      render = (
        <NewButton buttonType="select" onClick={() => console.log(id)}>
          {value}
        </NewButton>
      );
    }
    if (key === 'status') {
      render = (
        <PulseBrandStatusMark
          status={
            value === 'stopped'
              ? 'hyphen'
              : value === 'pulsing'
              ? 'active'
              : 'disabled'
          }
        />
      );
    }
    return render;
  };

  const mockOnSelection = (
    checked: boolean,
    row: T[] | null,
    selectAll: boolean
  ) => {
    const id = row ? row.find(item => item['key'] === 'id')?.value : null;
    const selectionState = [...selections];
    const selectedIndex = selectionState.findIndex(
      (item: { id: string }) => item.id === id
    );
    if (!selectAll) {
      selectionState[selectedIndex].checked = checked;
      setSelections([...selectionState]);
      const numberChecked = selections.filter(items => items.checked).length;
      setNumOfRespondents(numberChecked);
      if (numberChecked > 0) {
        setShowActionBar(true);
      } else {
        setShowActionBar(false);
      }
    } else {
      const anyChecked = Boolean(
        selectionState.filter(item => item.checked === true).length > 0
      );
      const allChecked = Boolean(
        selectionState.filter(item => item.checked === true).length ===
          selectionState.length
      );
      if (anyChecked && !allChecked) {
        selectionState.forEach(items => (items.checked = true));
        setSelections([...selectionState]);
      } else if (allChecked) {
        selectionState.forEach(items => (items.checked = false));
        setSelections([...selectionState]);
      } else {
        selectionState.forEach(items => (items.checked = true));
        setSelections([...selectionState]);
      }
      const numberChecked = selections.filter(items => items.checked).length;
      setNumOfRespondents(numberChecked);
      if (numberChecked > 0) {
        setShowActionBar(true);
      } else {
        setShowActionBar(false);
      }
    }
  };

  const toggleShowActionBarError = () => {
    setShowActionBarError(!showActionBarError);
  };

  const clickButton = (e: any) => {
    alert('click button');
    e.stopPropagation();
  };

  const clickActionBarButtonTwo = () => {
    const selectionState = [...selections];
    selectionState.forEach(items => (items.checked = false));
    setSelections([...selectionState]);
    setShowActionBar(false);
  };

  return (
    <>
      <PageHeader>New Components</PageHeader>
      <SectionHeader>Clickable Tile</SectionHeader>
      <div className="row">
        <div className="col-xs-8">
          <div className="clickable-tile-container">
            <ClickableTile
              title={i18next.t<string>('Title text disabled box')}
              icon={
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="5x"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              strapline={i18next.t('Strapline')}
              to="/page/styleguide/newcomponents"
              disabled
              onClick={() => alert('click me')}
            />
            <ClickableTile
              title={i18next.t<string>('Title text active box')}
              icon={
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="5x"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              strapline={i18next.t('Strapline')}
              to="/page/styleguide/"
              onClick={() => alert('click me')}
            />
          </div>
          <div className="clickable-tile-container">
            <ClickableTile
              title={i18next.t<string>('Horizontal active box')}
              icon={
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="5x"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              strapline={i18next.t('Strapline')}
              horizontal
              onClick={() => alert('click me')}
            />
            <ClickableTile
              title={i18next.t<string>('Horizontal disabled')}
              icon={
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="5x"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              strapline={i18next.t('Strapline')}
              horizontal
              disabled
              onClick={() => alert('click me')}
            />
          </div>
        </div>
      </div>
      <SectionHeader>Clickable Tile - square</SectionHeader>
      <div className="row">
        <div className="col-xs-3">
          <div className="clickable-tile-container">
            <ClickableTile
              square
              disabled
              title={i18next.t<string>('Active box')}
              icon={
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="5x"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              strapline={i18next.t('Strapline')}
              buttonClick={e => clickButton(e)}
              buttonText="Button"
            />
          </div>
        </div>
        <div className="col-xs-3">
          <div className="clickable-tile-container">
            <ClickableTile
              square
              title={i18next.t<string>('Active box')}
              icon={
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  size="5x"
                  className="clickable-tile-icon clickable-tile-create"
                />
              }
              strapline={i18next.t('Strapline')}
              buttonClick={e => clickButton(e)}
              buttonText="Create"
            />
          </div>
        </div>
        <div className="col-xs-3">
          <div className="clickable-tile-container">
            <ClickableTile square title={i18next.t<string>('Title only')} />
          </div>
        </div>
      </div>
      <SectionHeader>Container Rectangle Tile</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <div className="container-tile-container">
            <ContainerRectangleTile>
              <h1>
                {i18next.t<string>(
                  'Container Rectangle Tile with random elements'
                )}
              </h1>
              <TextArea placeholder="This is some placeholder text within the text area" />
              <FormItem label="Rock stars">
                <Checkbox
                  id="second"
                  onChange={console.log}
                  label="Jimmy Page"
                />
                <Checkbox
                  id="second"
                  onChange={console.log}
                  label="Robert Plant"
                />
                <Checkbox
                  id="second"
                  onChange={console.log}
                  label="John Bonham"
                />
              </FormItem>
              <NewButton
                buttonType="use-class"
                className="btn-warning-secondary"
                onClick={console.log}
              >
                <FontAwesomeIcon icon={faFileDownload} />
                With icon
              </NewButton>
            </ContainerRectangleTile>
          </div>
        </div>
      </div>

      <SectionHeader>Tabbed Container</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <TabbedContainer
            transparent
            defaultActive="not-invited"
            config={[
              {
                id: 'not-invited',

                label: 'Not invited',
                renderer: (
                  <div key="not-invited">
                    <p>tab contents</p>
                  </div>
                ),
              },
              {
                id: 'invited',
                label: 'Invited',
                renderer: (
                  <div key="invited">
                    <FontAwesomeIcon
                      icon={faPlusSquare}
                      size="5x"
                      className="clickable-tile-icon clickable-tile-create"
                    />
                    <p>hullo</p>
                    <p>more</p>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>

      <SectionHeader>Expandable Box</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <ExpandableBox
            defaultExpanded={false}
            title="Pulse name"
            rightText={() => (
              <p>
                <b>Started: </b> date
              </p>
            )}
          >
            <FormItem label="Rock stars">
              <Checkbox id="second" onChange={console.log} label="Jimmy Page" />
              <Checkbox
                id="second"
                onChange={console.log}
                label="Robert Plant"
              />
              <Checkbox
                id="second"
                onChange={console.log}
                label="John Bonham"
              />
            </FormItem>
            <NewButton
              onClick={() =>
                toast.success(
                  <>
                    <FontAwesomeIcon
                      size="sm"
                      className="tick"
                      icon={faCheck}
                    />
                    {i18next.t<string>(' Toast test')}
                  </>,
                  {
                    className: 'toast-success',
                  }
                )
              }
            >
              <FontAwesomeIcon icon={faFileDownload} />
              Toast test
            </NewButton>
          </ExpandableBox>
        </div>
      </div>

      <SectionHeader>Pill Search</SectionHeader>
      <div className="row">
        <div className="col-xs-4" />
        <PillSearch
          className="pill"
          onChange={e => console.log(e?.currentTarget.value || '')}
          formKey="form"
          placeholder="Search me"
        />
      </div>

      <SectionHeader>Task completed timed icon display</SectionHeader>
      <div className="row">
        <div className="col-xs-4" />
        <NewButton onClick={() => setShowTaskCompleted(!showTaskCompleted)}>
          Complete task
        </NewButton>

        {showTaskCompleted && (
          <TaskCompleteTimed
            text="Pulse Check created!"
            iconClass="pulse-status active"
            time={2000}
          />
        )}
      </div>

      <SectionHeader>Action Bar</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <NewButton onClick={toggleShowActionBar}>
            {showActionBar ? 'Hide Action Bar' : 'Show Action Bar'}
          </NewButton>
        </div>
      </div>
      {showActionBar && (
        <BottomActionBar
          icon={<PulseIconCircleWhite />}
          infoText={`${numOfRespondents} respondents selected`}
          errorText={showActionBarError ? 'There was an error' : ''}
          actions={[
            {
              label: 'Toggle error',
              onClick: () => toggleShowActionBarError(),
              isDisabled: false,
            },
            {
              label: 'Button Two',
              onClick: () => clickActionBarButtonTwo(),
              isDisabled: showActionBarError ? true : false,
            },
          ]}
        />
      )}

      <SectionHeader>Pulse Brand Status Mark</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <div>
            <PulseBrandStatusMark status="active" />
          </div>
          <div>
            <PulseBrandStatusMark status="disabled" />
          </div>
          <div>
            <PulseBrandStatusMark status="hyphen" />
          </div>
        </div>
      </div>

      <SectionHeader>Capability selector modal</SectionHeader>
      <div className="row">
        <div className="col-xs-7">
          <div className="modal product-selector white-background">
            <div className="modal-title clearfix">
              <h3>The title</h3>
              <a className="fa-link" onClick={() => console.log('close')}>
                <FontAwesomeIcon icon={faTimes} />
              </a>
            </div>
            <ProductSelector
              title="The title"
              products={MOCK_CAPABILITIES}
              singleSelection
              confirmButtonLabel={'Add'}
              onConfirm={selectedList => console.log(selectedList)}
              onClose={() => console.log('Close modal')}
            />
          </div>
        </div>
      </div>
      <SectionHeader>Product selector modal</SectionHeader>
      <div className="row">
        <div className="col-xs-7">
          <div className="modal product-selector">
            <div className="modal-title clearfix">
              <h3>The title</h3>
              <a className="fa-link" onClick={() => console.log('close')}>
                <FontAwesomeIcon icon={faTimes} />
              </a>
            </div>
            <ProductSelector
              title="The title"
              products={MOCK_PRODUCTS}
              footnote={() => (
                <div>
                  footnote - look in the{' '}
                  <a href="#" target="_blank">
                    shop.
                  </a>
                </div>
              )}
              confirmButtonLabel={'Confirm'}
              onConfirm={selectedList => console.log(selectedList)}
              onClose={() => console.log('Close modal')}
            />
          </div>
        </div>
      </div>

      <SectionHeader>New Buttons</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <p>Standard button:</p>
          <NewButton> Create</NewButton>
          <NewButton disabled>Disabled</NewButton>
        </div>
        <div className="col-xs-12">
          <p>White stroke standard button:</p>
          <NewButton buttonType="white-stroke">Resend invites</NewButton>
          <NewButton buttonType="white-stroke" disabled>
            Disabled
          </NewButton>
        </div>
        <div className="col-xs-12">
          <p>Outline button:</p>
          <NewButton buttonType="outline">Cancel</NewButton>
          <NewButton buttonType="outline" disabled>
            Disabled
          </NewButton>
        </div>
        <div className="col-xs-12">
          <p>Select button selected/unselected - pill </p>
          <NewButton buttonType="select-pill-selected">Selected</NewButton>
          <NewButton buttonType="select-pill-selected" disabled>
            Disabled
          </NewButton>

          <NewButton buttonType="select-pill">Not selected</NewButton>
          <NewButton buttonType="select-pill" disabled>
            Disabled
          </NewButton>
        </div>
        <div className="col-xs-12">
          <p>Select button selected/unselected</p>
          <NewButton buttonType="select-selected">Selected</NewButton>
          <NewButton buttonType="select-selected" disabled>
            Disabled
          </NewButton>
          <NewButton buttonType="select">Not selected</NewButton>
          <NewButton buttonType="select" disabled>
            Disabled
          </NewButton>
        </div>

        <div className="col-xs-12">
          <p>Small form buttons</p>
          <NewButton buttonType="form">
            <FontAwesomeIcon icon={faPlus} />
            Form button
          </NewButton>
          <NewButton buttonType="form" disabled>
            <FontAwesomeIcon icon={faPlus} />
            Disabled
          </NewButton>
        </div>

        <div className="col-xs-12">
          <p>Small form - pulse</p>
          <NewButton buttonType="pulse">
            <PulseIcon className="svg-inline--fa" />
            View pulse check
          </NewButton>
          <NewButton buttonType="pulse" disabled>
            <PulseIcon className="svg-inline--fa" />
            Disabled
          </NewButton>
        </div>
      </div>

      <SectionHeader>Interactive Table - Pulse checks</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <InteractiveTable
            className="pulse-checks"
            pageSize={3}
            loadPage={mockLoadPage}
            page={page}
            cellRenderer={mockCellRender}
            isDataPending={false} //from api response
            isFilterDataPending={false} //from api response filters
            isNextPageDataPending={false} //from api response page < 1
            setSelectedFilter={mockDoFiltersSearchSort}
            enableCheckBoxes={false}
            onSelection={mockOnSelection}
            rows={rowData}
            filters={filters}
            initialSelections={selections}
            selectedFilterOptions={selectedFilterOptions}
            columns={[
              {
                header: {
                  label: 'Respondent name',
                  key: 'respondent' as keyof T,
                },
                filter: {
                  key: 'respondent' as keyof T,
                  type: 'search',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Status',
                  key: 'status' as keyof T,
                },
                filter: {
                  key: 'status' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Age',
                  key: 'age' as keyof T,
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Hat',
                  key: 'hat' as keyof T,
                },
                filter: {
                  key: 'hat' as keyof T,
                  type: 'dropdown-multi',
                },
                sortable: false,
              },
              {
                header: {
                  label: '',
                  key: 'button' as keyof T,
                },
                sortable: false,
              },
            ]}
          />
        </div>
      </div>
      <SectionHeader>Interactive Table - Pulse name</SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <InteractiveTable
            pageSize={3}
            loadPage={mockLoadPage}
            page={page}
            cellRenderer={mockCellRender}
            isDataPending={false} //from api response
            isFilterDataPending={false} //from api response filters
            isNextPageDataPending={false} //from api response page < 1
            setSelectedFilter={mockDoFiltersSearchSort}
            enableCheckBoxes
            onSelection={mockOnSelection}
            rows={rowData}
            filters={filters}
            initialSelections={selections}
            selectedFilterOptions={selectedFilterOptions}
            columns={[
              {
                header: {
                  label: 'Select all',
                  key: 'id' as keyof T,
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Respondent name',
                  key: 'respondent' as keyof T,
                },
                filter: {
                  key: 'respondent' as keyof T,
                  type: 'search',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Pulse type',
                  key: 'pulse-type' as keyof T,
                },
                filter: {
                  key: 'pulse-type' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Status',
                  key: 'status' as keyof T,
                },
                filter: {
                  key: 'status' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Age',
                  key: 'age' as keyof T,
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Hat',
                  key: 'hat' as keyof T,
                },
                filter: {
                  key: 'hat' as keyof T,
                  type: 'dropdown-multi',
                },
                sortable: false,
              },
            ]}
          />
        </div>
      </div>
      <SectionHeader>
        Interactive Table - loading filters and data
      </SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <InteractiveTable
            pageSize={15}
            loadPage={mockLoadPage}
            page={page}
            cellRenderer={mockCellRender}
            isDataPending
            isFilterDataPending={false}
            isNextPageDataPending={false}
            setSelectedFilter={mockDoFiltersSearchSort}
            enableCheckBoxes
            onSelection={mockOnSelection}
            rows={rowData}
            filters={filters}
            initialSelections={selections}
            selectedFilterOptions={selectedFilterOptions}
            columns={[
              {
                header: {
                  label: 'Select all',
                  key: 'id' as keyof T,
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Respondent name',
                  key: 'respondent' as keyof T,
                },
                filter: {
                  key: 'respondent' as keyof T,
                  type: 'search',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Pulse type',
                  key: 'pulse-type' as keyof T,
                },
                filter: {
                  key: 'pulse-type' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Status',
                  key: 'status' as keyof T,
                },
                filter: {
                  key: 'status' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Age',
                  key: 'age' as keyof T,
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Hat',
                  key: 'hat' as keyof T,
                },
                filter: {
                  key: 'hat' as keyof T,
                  type: 'dropdown-multi',
                },
                sortable: false,
              },
            ]}
          />
        </div>
      </div>

      <SectionHeader>
        Interactive Table - loading next page of data
      </SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <InteractiveTable
            pageSize={15}
            loadPage={mockLoadPage}
            page={page}
            cellRenderer={mockCellRender}
            isDataPending={false}
            isFilterDataPending={false}
            isNextPageDataPending
            setSelectedFilter={mockDoFiltersSearchSort}
            enableCheckBoxes
            onSelection={mockOnSelection}
            rows={rowData}
            filters={filters}
            initialSelections={selections}
            selectedFilterOptions={selectedFilterOptions}
            columns={[
              {
                header: {
                  label: 'Select all',
                  key: 'id' as keyof T,
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Respondent name',
                  key: 'respondent' as keyof T,
                },
                filter: {
                  key: 'respondent' as keyof T,
                  type: 'search',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Pulse type',
                  key: 'pulse-type' as keyof T,
                },
                filter: {
                  key: 'pulse-type' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Status',
                  key: 'status' as keyof T,
                },
                filter: {
                  key: 'status' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Age',
                  key: 'age' as keyof T,
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Hat',
                  key: 'hat' as keyof T,
                },
                filter: {
                  key: 'hat' as keyof T,
                  type: 'dropdown-multi',
                },
                sortable: false,
              },
            ]}
          />
        </div>
      </div>

      <SectionHeader>Pulse hub listings table</SectionHeader>

      <ContainerRectangleTile>
        <>
          <div className="row justify-content-space-between display-flex">
            <div className="ml-md mr-auto">
              <h1>Pulse Checks</h1>
            </div>
            <div className="mr-md">
              <PillSearch
                className="pill"
                onChange={e => console.log(e?.currentTarget.value || '')}
                formKey="form"
                placeholder="Search"
              />
            </div>
          </div>
          <InteractiveTable
            className="pulse-checks"
            pageSize={3}
            loadPage={mockLoadPage}
            page={page}
            cellRenderer={mockCellRender}
            isDataPending={false}
            isFilterDataPending={false}
            isNextPageDataPending={false}
            setSelectedFilter={mockDoFiltersSearchSort}
            enableCheckBoxes={false}
            onSelection={mockOnSelection}
            rows={rowData}
            filters={filters}
            initialSelections={selections}
            selectedFilterOptions={selectedFilterOptions}
            columns={[
              {
                header: {
                  label: 'Respondent name',
                  key: 'respondent' as keyof T,
                },
                filter: {
                  key: 'respondent' as keyof T,
                  type: 'search',
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Status',
                  key: 'status' as keyof T,
                },
                filter: {
                  key: 'status' as keyof T,
                  type: 'dropdown-single',
                },
                sortable: false,
              },
              {
                header: {
                  label: 'Age',
                  key: 'age' as keyof T,
                },
                sortable: true,
              },
              {
                header: {
                  label: 'Hat',
                  key: 'hat' as keyof T,
                },
                filter: {
                  key: 'hat' as keyof T,
                  type: 'dropdown-multi',
                },
                sortable: false,
              },
              {
                header: {
                  label: '',
                  key: 'button' as keyof T,
                },
                sortable: false,
              },
            ]}
          />
        </>
      </ContainerRectangleTile>

      <SectionHeader>
        Activity name: <strong>Example Activity</strong>
      </SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <TabbedContainer
            transparent
            defaultActive="not-invited"
            config={[
              {
                id: 'not-invited',
                label: 'Not invited',
                renderer: (
                  <div key="not-invited">
                    <ExpandableBox
                      defaultExpanded={false}
                      title="Pulse name"
                      rightText={() => (
                        <p>
                          <b>Started: </b> date
                        </p>
                      )}
                    >
                      <p className="mb-base">
                        Number of respondents: <strong>4</strong>
                      </p>
                      <p className="mb-base">
                        Notification frequency:{' '}
                        <strong>Weekly, at 07:00 UTC edit</strong>
                      </p>
                      <InteractiveTable
                        pageSize={3}
                        loadPage={mockLoadPage}
                        page={page}
                        cellRenderer={mockCellRender}
                        isDataPending={false}
                        isFilterDataPending={false}
                        isNextPageDataPending={false}
                        setSelectedFilter={mockDoFiltersSearchSort}
                        enableCheckBoxes
                        onSelection={mockOnSelection}
                        rows={rowData}
                        filters={filters}
                        initialSelections={selections}
                        selectedFilterOptions={selectedFilterOptions}
                        columns={[
                          {
                            header: {
                              label: 'Select all',
                              key: 'id' as keyof T,
                            },
                            sortable: false,
                          },
                          {
                            header: {
                              label: 'Respondent name',
                              key: 'respondent' as keyof T,
                            },
                            filter: {
                              key: 'respondent' as keyof T,
                              type: 'search',
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Pulse type',
                              key: 'pulse-type' as keyof T,
                            },
                            filter: {
                              key: 'pulse-type' as keyof T,
                              type: 'dropdown-single',
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Status',
                              key: 'status' as keyof T,
                            },
                            filter: {
                              key: 'status' as keyof T,
                              type: 'dropdown-single',
                            },
                            sortable: false,
                          },
                          {
                            header: {
                              label: 'Age',
                              key: 'age' as keyof T,
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Hat',
                              key: 'hat' as keyof T,
                            },
                            filter: {
                              key: 'hat' as keyof T,
                              type: 'dropdown-multi',
                            },
                            sortable: false,
                          },
                        ]}
                      />
                    </ExpandableBox>
                    <ExpandableBox
                      defaultExpanded={false}
                      title="Pulse name"
                      rightText={() => (
                        <p>
                          <b>Started: </b> date
                        </p>
                      )}
                    >
                      <p className="mb-base">
                        Number of respondents: <strong>4</strong>
                      </p>
                      <p className="mb-base">
                        Notification frequency:{' '}
                        <strong>Weekly, at 07:00 UTC edit</strong>
                      </p>
                      <InteractiveTable
                        pageSize={3}
                        loadPage={mockLoadPage}
                        page={page}
                        cellRenderer={mockCellRender}
                        isDataPending
                        isFilterDataPending={false}
                        isNextPageDataPending={false}
                        setSelectedFilter={mockDoFiltersSearchSort}
                        enableCheckBoxes
                        onSelection={mockOnSelection}
                        rows={rowData}
                        filters={filters}
                        initialSelections={selections}
                        selectedFilterOptions={selectedFilterOptions}
                        columns={[
                          {
                            header: {
                              label: 'Select all',
                              key: 'id' as keyof T,
                            },
                            sortable: false,
                          },
                          {
                            header: {
                              label: 'Respondent name',
                              key: 'respondent' as keyof T,
                            },
                            filter: {
                              key: 'respondent' as keyof T,
                              type: 'search',
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Pulse type',
                              key: 'pulse-type' as keyof T,
                            },
                            filter: {
                              key: 'pulse-type' as keyof T,
                              type: 'dropdown-single',
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Status',
                              key: 'status' as keyof T,
                            },
                            filter: {
                              key: 'status' as keyof T,
                              type: 'dropdown-single',
                            },
                            sortable: false,
                          },
                          {
                            header: {
                              label: 'Age',
                              key: 'age' as keyof T,
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Hat',
                              key: 'hat' as keyof T,
                            },
                            filter: {
                              key: 'hat' as keyof T,
                              type: 'dropdown-multi',
                            },
                            sortable: false,
                          },
                        ]}
                      />
                    </ExpandableBox>
                  </div>
                ),
              },
              {
                id: 'invited',
                label: 'Invited',
                renderer: (
                  <div key="invited">
                    <ExpandableBox
                      defaultExpanded
                      title="Pulse name"
                      rightText={() => (
                        <p>
                          <b>Started: </b> date
                        </p>
                      )}
                    >
                      <p className="mb-base">
                        Number of respondents: <strong>4</strong>
                      </p>
                      <p className="mb-base">
                        Notification frequency:{' '}
                        <strong>Weekly, at 07:00 UTC edit</strong>
                      </p>
                      <InteractiveTable
                        pageSize={15}
                        loadPage={mockLoadPage}
                        page={page}
                        cellRenderer={mockCellRender}
                        isDataPending={false}
                        isFilterDataPending={false}
                        isNextPageDataPending={false}
                        setSelectedFilter={mockDoFiltersSearchSort}
                        enableCheckBoxes
                        onSelection={mockOnSelection}
                        rows={rowData}
                        filters={filters}
                        initialSelections={selections}
                        selectedFilterOptions={selectedFilterOptions}
                        columns={[
                          {
                            header: {
                              label: 'Select all',
                              key: 'id' as keyof T,
                            },
                            sortable: false,
                          },
                          {
                            header: {
                              label: 'Respondent name',
                              key: 'respondent' as keyof T,
                            },
                            filter: {
                              key: 'respondent' as keyof T,
                              type: 'search',
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Pulse type',
                              key: 'pulse-type' as keyof T,
                            },
                            filter: {
                              key: 'pulse-type' as keyof T,
                              type: 'dropdown-single',
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Status',
                              key: 'status' as keyof T,
                            },
                            filter: {
                              key: 'status' as keyof T,
                              type: 'dropdown-single',
                            },
                            sortable: false,
                          },
                          {
                            header: {
                              label: 'Age',
                              key: 'age' as keyof T,
                            },
                            sortable: true,
                          },
                          {
                            header: {
                              label: 'Hat',
                              key: 'hat' as keyof T,
                            },
                            filter: {
                              key: 'hat' as keyof T,
                              type: 'dropdown-multi',
                            },
                            sortable: false,
                          },
                        ]}
                      />
                    </ExpandableBox>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>

      <SectionHeader>
        Activity name: <strong>Example Activity Single</strong>
      </SectionHeader>
      <div className="row">
        <div className="col-xs-12">
          <TabbedContainer
            transparent
            defaultActive="not-invited"
            config={[
              {
                id: 'not-invited',
                label: 'Not invited',
                renderer: (
                  <div key="not-invited">
                    <p className="mb-base">
                      Number of respondents: <strong>4</strong>
                    </p>
                    <InteractiveTable
                      pageSize={3}
                      loadPage={mockLoadPage}
                      page={page}
                      cellRenderer={mockCellRender}
                      isDataPending={false}
                      isFilterDataPending={false}
                      isNextPageDataPending={false}
                      setSelectedFilter={mockDoFiltersSearchSort}
                      enableCheckBoxes
                      onSelection={mockOnSelection}
                      rows={rowData}
                      filters={filters}
                      initialSelections={selections}
                      selectedFilterOptions={selectedFilterOptions}
                      columns={[
                        {
                          header: {
                            label: 'Select all',
                            key: 'id' as keyof T,
                          },
                          sortable: false,
                        },
                        {
                          header: {
                            label: 'Respondent name',
                            key: 'respondent' as keyof T,
                          },
                          filter: {
                            key: 'respondent' as keyof T,
                            type: 'search',
                          },
                          sortable: true,
                        },
                        {
                          header: {
                            label: 'Pulse type',
                            key: 'pulse-type' as keyof T,
                          },
                          filter: {
                            key: 'pulse-type' as keyof T,
                            type: 'dropdown-single',
                          },
                          sortable: true,
                        },
                        {
                          header: {
                            label: 'Status',
                            key: 'status' as keyof T,
                          },
                          filter: {
                            key: 'status' as keyof T,
                            type: 'dropdown-single',
                          },
                          sortable: false,
                        },
                        {
                          header: {
                            label: 'Age',
                            key: 'age' as keyof T,
                          },
                          sortable: true,
                        },
                        {
                          header: {
                            label: 'Hat',
                            key: 'hat' as keyof T,
                          },
                          filter: {
                            key: 'hat' as keyof T,
                            type: 'dropdown-multi',
                          },
                          sortable: false,
                        },
                      ]}
                    />
                  </div>
                ),
              },
              {
                id: 'invited',
                label: 'Invited',
                renderer: (
                  <div key="invited">
                    <p className="mb-base">
                      Number of respondents: <strong>0</strong>
                    </p>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
