import React from 'react'; // eslint-disable-line no-unused-vars
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import i18next from 'i18next';
import { connect } from 'react-redux';

import { hasFailed, hasSucceeded, isPending } from '^/responseStates';
import { StoreState } from '^/store';
import { closeTopModal } from '^/actions/modals';
import Loading from '../Loading';

interface OwnProps {
  responseKey: string;
  successText: string;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface StateProps {
  pending: boolean;
  failed: boolean;
  succeeded: boolean;
}

interface State {
  timeout: NodeJS.Timeout | undefined;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class RequestStatusModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { timeout: undefined };
    this.checkToTimeout = this.checkToTimeout.bind(this);
  }

  componentDidMount() {
    this.checkToTimeout();
  }

  componentDidUpdate() {
    this.checkToTimeout();
  }

  checkToTimeout() {
    const { failed, succeeded } = this.props;
    if (!this.state.timeout && (failed || succeeded)) {
      this.setState({
        timeout: setTimeout(() => this.props.closeTopModal(false), 3000),
      });
    }
  }

  render() {
    const { successText, pending, failed, succeeded } = this.props;

    const getContent = () => {
      if (pending) {
        return <Loading />;
      } else if (failed) {
        return (
          <>
            <FontAwesomeIcon icon={faTimes} />
            <h1>{i18next.t<string>('An error has occurred')}</h1>
          </>
        );
      } else if (succeeded) {
        return (
          <>
            <FontAwesomeIcon icon={faCheck} />
            <h1>{successText}</h1>
          </>
        );
      }
    };

    return (
      <div>
        <div className="content">{getContent()}</div>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps) {
  const response = state.responses.get(ownProps.responseKey);

  return {
    pending: isPending(response),
    failed: hasFailed(response),
    succeeded: hasSucceeded(response),
  };
}

export default connect(mapStateToProps, { closeTopModal })(RequestStatusModal);
