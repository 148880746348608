import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { toast } from 'react-toastify';

interface ToastBodyProps {
  icon?: IconProp;
  header: string;
  body?: string;
}

export const ToastBody = (props: ToastBodyProps) => (
  <div className="toast-body">
    {props.icon && (
      <FontAwesomeIcon icon={props.icon} size="lg" className="toast-icon" />
    )}
    <div>
      {props.header && <h3> {props.header} </h3>}
      {props.body && <p> {props.body} </p>}
    </div>
  </div>
);

export default {
  success: (header: string, body?: string) =>
    toast.success(<ToastBody header={header} body={body} icon={faCheck} />),
  error: (header: string, body?: string) =>
    toast.error(
      <ToastBody header={header} body={body} icon={faExclamationTriangle} />
    ),
  warn: (header: string, body?: string) =>
    toast.warn(
      <ToastBody header={header} body={body} icon={faExclamationCircle} />
    ),
  info: (header: string, body?: string) =>
    toast.info(<ToastBody header={header} body={body} icon={faInfoCircle} />),
};
