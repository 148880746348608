import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import { updateCreditCostAndCloseModal } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import EditCreditCostForm from './EditCreditCostForm';
import { formatDisplayCreditCostToStripe } from '^/utils';

export class EditCreditCostModal extends PureComponent {
  render() {
    const { currentCreditCost } = this.props;
    const { id } = currentCreditCost.toObject();
    return (
      <div>
        <EditCreditCostForm
          response={this.props.response}
          onSubmit={data => {
            const newValue = data && data.cost;
            const stripeCost = formatDisplayCreditCostToStripe(
              currentCreditCost.update('cost', () => newValue)
            );
            return this.props.updateCreditCostAndCloseModal(id, {
              cost: stripeCost,
            });
          }}
          onClose={this.props.closeTopModal}
          currentCreditCost={currentCreditCost}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  updateCreditCostAndCloseModal,
})(EditCreditCostModal);
