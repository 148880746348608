import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { administerOrganisations, can } from '^/capabilities';
import { FilterProfileDetail } from '^/reducers/api/types';
import { StoreState } from '^/store';
import { openAdvancedAnalyticsSaveACopyModal } from '^/actions/modals';
import CreateEditProfileDetailsForm, {
  CreateEditProfileDetailsFormProps,
} from './CreateEditProfileDetailsForm';

interface DispatchProps {
  openAdvancedAnalyticsSaveACopyModal: typeof openAdvancedAnalyticsSaveACopyModal;
}

interface OwnProps {
  disabled?: boolean;
  profile: FilterProfileDetail;
  saveACopy: (data: CreateEditProfileDetailsFormProps) => void;
  onSubmit: (data: CreateEditProfileDetailsFormProps) => void;
}

interface StateProps {
  user: Map<string, any>;
  editResponse: Map<string, any>;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class EditProfileDetails extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  public handleSaveACopy(data: CreateEditProfileDetailsFormProps) {
    this.props.openAdvancedAnalyticsSaveACopyModal(
      data,
      this.props.profile,
      this.props.saveACopy
    );
  }

  render() {
    const { user, profile, editResponse, onSubmit } = this.props;

    return (
      <div className="details-form">
        <CreateEditProfileDetailsForm
          response={editResponse}
          userFullName={profile.created_by.full_name}
          initialValues={{
            name: profile.name,
            created_by: profile.created_by.id,
            product: profile.product.id,
            organisation: profile.organisation?.id,
          }}
          onSubmit={onSubmit}
          canChooseOrg={can(user, administerOrganisations())}
          saveACopy={this.handleSaveACopy.bind(this)}
        />
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
    editResponse: state.responses.get('updateAdvancedAnalyticsFilterProfile'),
  };
}

export default connect(mapStateToProps, {
  openAdvancedAnalyticsSaveACopyModal,
})(EditProfileDetails);
