import { faKey } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';

import {
  addHeatmapRowModal,
  updateHeatmapRowModal,
  clearAnalyticsAggregatesModal,
  clearAnalyticsFiltersHeatmap,
  getAnalyticsFiltersHeatmap,
  getAnalyticsAggregatesModal,
  getAnalyticsSessionsModal,
  clearAnalyticsSessionsModal,
} from '^/actions/analytics';
import { openAdvancedAnalyticsModal, closeTopModal } from '^/actions/modals';
import { administerOrganisations, can } from '^/capabilities';
import Alert from '^/components/Alert';
import DataAnalyticsTable from '^/components/analytics/DataAnalyticsTable';
import OnOffToggle from '^/components/OnOffToggle';
import {
  AnalyticsAppliedFilters,
  AnalyticsFilters,
  AnalyticsFiltersState,
  AnalyticsSessionsState,
  HeatmapRow,
  SelectedFilterProfileData,
  SubAggregate,
  Uuid,
} from '^/reducers/api/types';
import { isPending } from '^/responseStates';
import { StoreState, SearchBarForm } from '^/store';
import { removeItemWithIndexFromArray } from '^/utils-ts';
import { DropdownOption } from '../dropdown/DropdownItem';
import DataAnalyticsFilters from './DataAnalyticsFilters';
import { DEMOGRAPHIC_COLUMN_GROUPS } from './DataAnalyticsTableStructure';
import HeatmapLegend from './HeatmapLegend';
import { cantShowHeatmap } from './utils';

interface StateProps {
  user: Immutable.Map<string, any>;
  analyticsFiltersHeatmap: AnalyticsFiltersState;
  analyticsSessionsModal: AnalyticsSessionsState;
  filtersLoading: boolean;
  sessionsLoading: boolean;
  searchForm: SearchBarForm | null;
  shortlivedToken: string;
  subaggregatesModal: ReadonlyArray<SubAggregate>;
}

interface DispatchProps {
  addHeatmapRowModal: typeof addHeatmapRowModal;
  updateHeatmapRowModal: typeof updateHeatmapRowModal;
  getAnalyticsFiltersHeatmap: typeof getAnalyticsFiltersHeatmap;
  getAnalyticsAggregatesModal: typeof getAnalyticsAggregatesModal;
  getAnalyticsSessionsModal: typeof getAnalyticsSessionsModal;
  openAdvancedAnalyticsModal: typeof openAdvancedAnalyticsModal;
  clearAnalyticsAggregatesModal: typeof clearAnalyticsAggregatesModal;
  clearAnalyticsSessionsModal: typeof clearAnalyticsSessionsModal;
  clearAnalyticsFiltersHeatmap: typeof clearAnalyticsFiltersHeatmap;
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  product?: Uuid;
  aggregate?: HeatmapRow | null;
  index?: number;
  hideAnalyse?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  filters: AnalyticsAppliedFilters;
  page: number;
  ordering?: string;
  showHeatmap: boolean;
  collapsed: boolean;
  showHeatmapLegend: boolean;
  selectedProfiles: Record<Uuid, SelectedFilterProfileData>;
  previewFilterRanges: Array<Array<string>>;
}

export class DataAnalyticsHeatMapFiltersTable extends React.PureComponent<
  Props,
  State
> {
  private initialOrdering = DEMOGRAPHIC_COLUMN_GROUPS[0].columns[0].sortKey;
  private subaggregateKeys: ReadonlyArray<keyof AnalyticsAppliedFilters> = [
    'organisation',
    'activity',
    'group',
  ];
  public initialState = {
    page: 1,
    filters: {},
    ordering: this.initialOrdering,
    collapsed: false,
    showHeatmap: false,
    showHeatmapLegend: false,
    selectedProfiles: this.props.aggregate?.selectedProfiles || {},
    previewFilterRanges: this.props.aggregate?.previewFilterRanges || [],
  };

  constructor(props: Props) {
    super(props);
    const { product, aggregate } = props;
    const initialFilters = aggregate
      ? { ...aggregate.appliedFilters }
      : product
      ? { product }
      : {};
    this.state = {
      ...this.initialState,
      filters: initialFilters,
      selectedProfiles: this.initialState.selectedProfiles,
      previewFilterRanges: this.initialState.previewFilterRanges,
    };
    if (this.props.index !== undefined) {
      this.subaggregateKeys.forEach(key => {
        if (initialFilters[key]) {
          if (initialFilters[key]!.length > 1) {
            this.props.getAnalyticsAggregatesModal(
              {
                ...initialFilters,
                results_ranges: this.consolidateFilters(
                  this.state.selectedProfiles,
                  this.state.previewFilterRanges
                ),
              },
              key,
              initialFilters[key] as string[]
            );
          }
        }
      });
    } else {
      this.props.clearAnalyticsAggregatesModal();
    }
    this.props.getAnalyticsFiltersHeatmap({
      ...initialFilters,
      results_ranges: this.consolidateFilters(
        this.state.selectedProfiles,
        this.state.previewFilterRanges
      ),
    });
  }

  private consolidateFilters(
    selectedProfiles: Record<Uuid, SelectedFilterProfileData>,
    previewFilterRanges: Array<Array<string>>
  ): Array<string> {
    const selectedProfileFilters = Object.keys(selectedProfiles).map(
      key => selectedProfiles[key].filters
    );
    return _.flatten([selectedProfileFilters, previewFilterRanges], true);
  }

  public componentDidUpdate(_prevProps: Props, prevState: State) {
    const { filters, page, ordering } = this.state;
    const filtersChanged = !_.isEqual(filters, prevState.filters);
    const sortChanged = ordering !== prevState.ordering;
    const pageChanged = page !== prevState.page;
    if (filtersChanged) {
      this.props.getAnalyticsFiltersHeatmap(
        {
          ...filters,
          results_ranges: this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
        },
        ordering
      );
      this.subaggregateKeys.forEach(key => {
        if (filters[key]) {
          if (filters[key]!.length > 1) {
            this.props.getAnalyticsAggregatesModal(
              {
                ...filters,
                results_ranges: this.consolidateFilters(
                  this.state.selectedProfiles,
                  this.state.previewFilterRanges
                ),
              },
              key,
              filters[key] as string[]
            );
          } else {
            this.props.clearAnalyticsAggregatesModal(key);
          }
        }
      });
      this.props.getAnalyticsSessionsModal(filters, page, ordering, true);
    } else if (sortChanged) {
      this.props.getAnalyticsSessionsModal(filters, page, ordering, true);
    } else if (pageChanged) {
      this.props.getAnalyticsSessionsModal(filters, page, ordering, false);
    }

    if (
      prevState.selectedProfiles !== this.state.selectedProfiles ||
      prevState.previewFilterRanges !== this.state.previewFilterRanges
    ) {
      this.setFilters({
        ...this.state.filters,
        results_ranges: this.consolidateFilters(
          this.state.selectedProfiles,
          this.state.previewFilterRanges
        ),
      });
    }
  }

  public render() {
    const {
      user,
      analyticsFiltersHeatmap,
      analyticsSessionsModal,
      filtersLoading,
      sessionsLoading,
      searchForm,
      hideAnalyse,
      aggregate,
      subaggregatesModal,
    } = this.props;
    const { product, sessions } = analyticsFiltersHeatmap;
    const { showHeatmap, collapsed, showHeatmapLegend } = this.state;
    const canShowHeatmap = !cantShowHeatmap(product);
    const canUserAdministerOrganisations = can(user, administerOrganisations());

    const filters = {
      ...analyticsFiltersHeatmap.filters,
      product: this.props.product
        ? analyticsFiltersHeatmap.filters.product.filter(
            each => each.id === this.props.product
          )
        : analyticsFiltersHeatmap.filters.product,
    };

    return (
      <div>
        <div className="data-analytics-container">
          <HeatmapLegend
            visible={canShowHeatmap && showHeatmap && showHeatmapLegend}
            legendType={product}
            isFooterBar
          />
          <div
            className={classNames(
              'data-analytics-filters-wrapper',
              !collapsed || 'collapsed-filters'
            )}
          >
            <DataAnalyticsFilters
              canUserAdministerOrganisations={canUserAdministerOrganisations}
              appliedFilters={this.state.filters}
              filters={filters}
              toggleCollapsed={this.toggleCollapsed}
              collapsed={collapsed}
              filtersLoading={filtersLoading}
              onChange={this.onFiltersChange}
              searchForm={searchForm}
              product={product}
              handleClickAdvancedAnalytics={this.openAdvancedFilterModal}
              handleRemoveResultsRange={this.handleRemoveResultsRange}
              clearAllFiltersAndOrdering={this.clearAllFiltersAndOrdering}
              inModal
              selectedProfiles={this.state.selectedProfiles}
              previewFilterRanges={this.state.previewFilterRanges}
            />
          </div>
          <div className="data-analytics-table-and-control-wrapper">
            <div className="data-control-bar">
              <div className="data-control-bar-inner">
                <div
                  className={classNames(
                    'heatmap-toggle',
                    'display-flex',
                    product || 'disabled'
                  )}
                >
                  <span>{i18next.t<string>('Heatmap')}</span>
                  <OnOffToggle
                    label={{
                      on: i18next.t<string>('On'),
                      off: i18next.t<string>('Off'),
                    }}
                    value={showHeatmap}
                    secondary
                    onChange={() => this.toggleHeatmap()}
                    disabled={!product || !canShowHeatmap}
                  />
                </div>
                <button
                  className={classNames(
                    'btn btn-small btn-icon-square',
                    !showHeatmapLegend && 'btn-icon-square-secondary'
                  )}
                  onClick={this.toggleHeatmapLegend}
                  disabled={!product || !canShowHeatmap || !showHeatmap}
                  title={i18next.t<string>('Show heatmap legend')}
                >
                  <FontAwesomeIcon icon={faKey} />
                </button>
              </div>
            </div>

            {analyticsSessionsModal.length ? (
              sessions &&
              product && (
                <DataAnalyticsTable
                  key={product.id}
                  count={sessions.count}
                  user={user}
                  page={this.state.page}
                  loading={filtersLoading}
                  loadingPage={sessionsLoading}
                  aggregate={sessions.aggregate}
                  aggregateExtra={sessions.aggregate_extra}
                  subaggregates={subaggregatesModal}
                  filters={filters}
                  product={product}
                  onChangePage={this.onChangePage}
                  ordering={this.state.ordering}
                  onSetOrdering={this.onChangeOrdering}
                  resultsByPage={analyticsSessionsModal}
                  showHeatmap={canShowHeatmap && showHeatmap}
                  showAlternativeTable={false}
                  showTableThree={false}
                />
              )
            ) : (
              <>
                <div className="sort-text">
                  {i18next.t<string>('0 responses')}
                </div>
                <div className="data-analytics-chart-wrapper full-height">
                  <Alert className="centred">
                    {i18next.t<string>(
                      'Use the filters to create a table of results and add to charts'
                    )}
                  </Alert>
                </div>
              </>
            )}
          </div>
        </div>
        {hideAnalyse && (
          <div className="modal-footer clearfix">
            <div className="pull-right">
              <button
                className="btn btn-default"
                onClick={() => this.props.closeTopModal()}
              >
                {i18next.t<string>('Cancel')}
              </button>
              <button
                className="btn btn-primary"
                onClick={aggregate ? this.editChart : this.addToChart}
              >
                {aggregate
                  ? i18next.t<string>('Done')
                  : i18next.t<string>('Add to chart')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  private openAdvancedFilterModal = () => {
    const {
      analyticsFiltersHeatmap: { product, sessions },
    } = this.props;

    if (product && sessions) {
      this.props.openAdvancedAnalyticsModal(
        this.state.filters,
        product,
        sessions.count,
        (resultsRanges: string[][]) => {
          this.setState({
            previewFilterRanges: [
              ...this.state.previewFilterRanges,
              ...resultsRanges,
            ],
          });
        }
      );
    }
  };

  private toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  private toggleHeatmap = () => {
    this.setState({ showHeatmap: !this.state.showHeatmap });
  };

  private toggleHeatmapLegend = () => {
    this.setState({ showHeatmapLegend: !this.state.showHeatmapLegend });
  };

  private getFilterProfileFromFilters = (
    filterProfile: DropdownOption['id'],
    filters: AnalyticsFilters
  ) => {
    return filters.filter_profile.find(filter => filter.id === filterProfile);
  };

  private getSelectedFilterProfiles(
    selectedProfileIds?: string[] | null
  ): Record<Uuid, SelectedFilterProfileData> {
    if (!selectedProfileIds) {
      return {};
    }

    const selectedIdsAndProfiles = selectedProfileIds.map(selectedProfileId => {
      const selectedProfile = this.getFilterProfileFromFilters(
        selectedProfileId,
        this.props.analyticsFiltersHeatmap.filters
      );

      if (selectedProfile) {
        return [selectedProfile.id, selectedProfile];
      }
    }) as ReadonlyArray<[string, SelectedFilterProfileData]>;

    return Object.fromEntries(selectedIdsAndProfiles);
  }

  private onFiltersChange = (newFilters: Partial<AnalyticsAppliedFilters>) => {
    if (
      newFilters.product &&
      newFilters.product !== this.state.filters.product
    ) {
      this.setState({
        ordering: this.initialOrdering,
        showHeatmapLegend: false,
      });
    }

    const filters = { ...this.state.filters, ...newFilters };
    let key: keyof AnalyticsAppliedFilters;

    for (key in filters) {
      if (!filters[key] || key === 'filter_profile') {
        delete filters[key];
      }
    }

    const selectedFilterProfiles = this.getSelectedFilterProfiles(
      newFilters.filter_profile
    );

    this.setState({
      selectedProfiles: selectedFilterProfiles,
      filters,
    });
  };

  private setFilters(filters: Partial<AnalyticsAppliedFilters>) {
    this.setState({
      filters,
      page: 1,
    });
  }

  private handleRemoveResultsRange = (removeIndex: number) => {
    this.setState({
      previewFilterRanges: removeItemWithIndexFromArray(
        this.state.previewFilterRanges,
        removeIndex
      ),
    });
  };

  private clearAllFiltersAndOrdering = () => {
    this.props.clearAnalyticsAggregatesModal();
    this.setState(this.initialState);
  };

  private onChangePage = (page: number) => {
    this.setState({ page });
  };

  private onChangeOrdering = (ordering: string) => {
    this.setState({
      ordering,
      page: 1,
    });
  };

  private addToChart = () => {
    const { filters } = this.state;
    const { analyticsFiltersHeatmap, subaggregatesModal } = this.props;
    this.props.addHeatmapRowModal(
      {
        appliedFilters: {
          ...filters,
          results_ranges: this.consolidateFilters(
            this.state.selectedProfiles,
            this.state.previewFilterRanges
          ),
        },
        filtersState: analyticsFiltersHeatmap,
        children:
          subaggregatesModal.map(({ key, value, filtersState }) => ({
            appliedFilters: {
              [key as keyof AnalyticsAppliedFilters]: [value],
            },
            filtersState: {
              product: analyticsFiltersHeatmap.product,
              filters: analyticsFiltersHeatmap.filters,
              sessions: filtersState?.sessions || null,
            },
            children: [],
          })) || [],
        selectedProfiles: this.state.selectedProfiles,
        previewFilterRanges: this.state.previewFilterRanges,
      },
      undefined
    );
    this.props.closeTopModal();
  };

  private editChart = () => {
    const { filters } = this.state;
    const {
      analyticsFiltersHeatmap,
      subaggregatesModal,
      aggregate,
      index,
    } = this.props;
    const appliedFilters = _.isEmpty(filters)
      ? { product: analyticsFiltersHeatmap.product?.id }
      : filters;
    const row = {
      appliedFilters,
      filtersState: analyticsFiltersHeatmap,
      children:
        subaggregatesModal.map(({ key, value, filtersState }) => ({
          appliedFilters: {
            [key as keyof AnalyticsAppliedFilters]: [value],
          },
          filtersState: {
            product: analyticsFiltersHeatmap.product,
            filters: {
              ...analyticsFiltersHeatmap.filters,
              results_ranges: this.consolidateFilters(
                this.state.selectedProfiles,
                this.state.previewFilterRanges
              ),
            },
            sessions: filtersState?.sessions || null,
          },
          children: [],
        })) || [],
      selectedProfiles: this.state.selectedProfiles,
      previewFilterRanges: this.state.previewFilterRanges,
    };

    if (aggregate && index !== undefined) {
      this.props.updateHeatmapRowModal(row, [index]);
    } else {
      this.props.addHeatmapRowModal(row, undefined);
    }

    this.props.closeTopModal();
  };
}

export function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
    analyticsFiltersHeatmap: state.analyticsFiltersHeatmap,
    analyticsSessionsModal: state.analyticsSessionsModal,
    subaggregatesModal: state.subaggregatesModal,
    searchForm: state.form.searchBarForm,
    filtersLoading: isPending(
      state.responses.get('getAnalyticsFiltersHeatmap')
    ),
    sessionsLoading: isPending(
      state.responses.get('getAnalyticsSessionsModal')
    ),
    shortlivedToken: state.shortlivedToken,
  };
}

export default connect(mapStateToProps, {
  addHeatmapRowModal,
  updateHeatmapRowModal,
  getAnalyticsFiltersHeatmap,
  getAnalyticsAggregatesModal,
  getAnalyticsSessionsModal,
  openAdvancedAnalyticsModal,
  clearAnalyticsAggregatesModal,
  clearAnalyticsFiltersHeatmap,
  clearAnalyticsSessionsModal,
  closeTopModal,
})(DataAnalyticsHeatMapFiltersTable);
