import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, fromJS } from 'immutable';
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import { can, createUsersWithRole } from '../../capabilities';
import { getRoleText } from '../UserRole';
import { USER_ROLES } from '^/models/user';
import {
  ACCOUNT_ADMIN_ROLES_FOR_ACCOUNT_TYPE,
  allowsRole,
} from '^/models/organisation';
import BlankOption from '^/components/BlankOption';
import PureComponent from '../PureComponent';
import InviteUserFormSegment from './InviteUserFormSegment';
import FormError from '../FormError';
import LiveButton from '../LiveButton';
import SelectOrganisation from '../SelectOrganisation';

function validate(values) {
  if (values.role !== USER_ROLES.ADMIN && !values.organisationId) {
    return { organisationId: i18next.t('You must select an organisation') };
  }
  return {};
}

export class CreateUserForm extends PureComponent {
  render() {
    const {
      onSubmit,
      response,
      disabledRoles,
      user,
      invalid,
      initialValues,
    } = this.props;

    const accountType = user.getIn(['organisation', 'account_type']);
    const legalRoles = accountType
      ? fromJS(ACCOUNT_ADMIN_ROLES_FOR_ACCOUNT_TYPE).get(accountType)
      : Map(USER_ROLES).toList();

    const roles = Map(USER_ROLES)
      .toList()
      .filter(eachRole => eachRole !== USER_ROLES.ENDUSER)
      .filter(eachRole => legalRoles.includes(eachRole))
      .filter(eachRole => can(user, createUsersWithRole(eachRole)))
      .push(USER_ROLES.ENDUSER);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
        validate={validate}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-block">
            {values.role !== USER_ROLES.ADMIN && (
              <SelectOrganisation
                name="organisationId"
                value={values.organisationId}
                onChange={value => setFieldValue('organisationId', value)}
                showDisabled={org =>
                  values.role && !allowsRole(org, values.role)
                }
                collectionName="organisations-list-create-user"
              >
                <FormError response={response} formKey="organisation" />
              </SelectOrganisation>
            )}

            {roles.size === 1 ? (
              <input type="hidden" name="role" value={roles[0]} />
            ) : (
              <div>
                <label htmlFor="select-user-role">
                  {i18next.t('User role')}
                </label>
                <div className="form-item">
                  <Field
                    as="select"
                    id="select-user-role"
                    name="role"
                    defaultValue=""
                  >
                    <BlankOption
                      response={this.props.response}
                      disabled={values.role}
                    />
                    {roles.map(eachRole => (
                      <option
                        key={eachRole}
                        value={eachRole}
                        disabled={disabledRoles.includes(eachRole)}
                      >
                        {getRoleText(eachRole)}
                      </option>
                    ))}
                  </Field>
                  <FormError response={response} formKey="role" />
                </div>
              </div>
            )}

            <div>
              <label htmlFor="create-user-name">{i18next.t('Full name')}</label>
              <div className="form-item">
                <Field
                  type="text"
                  id="create-user-name"
                  name="fullName"
                  placeholder={i18next.t('Full name')}
                />
                <FormError response={response} formKey="full_name" />
              </div>
            </div>

            <div>
              <label htmlFor="create-user-email">{i18next.t('Email')}</label>
              <div className="form-item">
                <Field
                  type="text"
                  id="create-user-email"
                  name="email"
                  placeholder={i18next.t('Email')}
                />
                <FormError response={response} formKey="email" />
              </div>
            </div>

            {!values.invite ? (
              <div className="modal-footer clearfix">
                <div className="pull-right">
                  <button
                    className="btn btn-default"
                    type="button"
                    onClick={() => this.props.onClose()}
                  >
                    {i18next.t('Cancel')}
                  </button>
                  <button
                    disabled={invalid}
                    className="btn btn-primary"
                    onClick={() => setFieldValue('invite', true)}
                    type="button"
                  >
                    {i18next.t('Create and invite')}
                  </button>
                  <LiveButton
                    buttonText={i18next.t('Create user')}
                    response={response}
                    disabled={invalid}
                    pendingMessage={i18next.t('Creating user...')}
                  />
                </div>
              </div>
            ) : (
              <InviteUserFormSegment
                {...this.props}
                buttonText="Create and invite"
                userName={values.fullName}
                change={setFieldValue}
                opening={values.opening}
                closing={values.closing}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

export default CreateUserForm;
