/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'underscore';

import { isEmail } from '^/utils';
import { openEmailEditModal } from '^/actions/modals';
import PureComponent from '^/components/PureComponent';
import LiveButton from '^/components/LiveButton';
import Tag from '^/components/Tag';
import Alert from '^/components/Alert';
import Checkbox from '^/components/Checkbox';
import FormItem from '^/components/forms/FormItem';

export class SendReportsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      send_to_self: false,
      send_team_to_self: false,
      send_individual_reports_to_self: false,
      send_to_respondents: false,
      send_individual_reports_to_respondents: false,
      additional_recipients: [],
      additional_individual_reports_recipients: [],
      additional_team_reports_recipients: [],
      individual_reports_recipient: '',
      recipient: '',
      emails: {
        ..._.pick(props.defaultValues, [
          'self',
          'additional_recipients',
          'respondents',
        ]),
      },
    };
  }

  editEmail(fieldName) {
    const { emails } = this.state;
    const onSubmit = data => {
      const updatedEmails = Object.assign({}, emails);
      updatedEmails[fieldName].translations[data.lang_code] = _.omit(data, [
        'lang_code',
        'preferred_lang_code',
      ]);
      updatedEmails[fieldName].preferred_lang_code = data.preferred_lang_code;
      this.setState({
        emails: updatedEmails,
      });
    };

    this.props.openEmailEditModal(
      emails[fieldName],
      undefined,
      fieldName,
      onSubmit
    );
  }

  canSubmit() {
    return (
      this.state.send_to_self ||
      this.state.send_team_to_self ||
      this.state.send_individual_reports_to_self ||
      this.state.send_to_respondents ||
      this.state.send_individual_reports_to_respondents ||
      this.state.additional_recipients.length > 0 ||
      this.state.additional_individual_reports_recipients.length > 0 ||
      this.state.additional_team_reports_recipients.length > 0
    );
  }

  render() {
    const {
      onSubmit,
      response,
      isTeamReport,
      isJobProfiler,
      jobProfileSessions,
      isFlowTeamGeneration,
    } = this.props;
    const {
      send_to_self,
      send_team_to_self,
      send_individual_reports_to_self,
      send_to_respondents,
      send_individual_reports_to_respondents,
      additional_recipients,
      additional_individual_reports_recipients,
      additional_team_reports_recipients,
      recipient,
      individual_reports_recipient,
    } = this.state;

    return (
      <form className="form-block">
        <Alert className="mb-none">
          {i18next.t(
            'Please ensure all additional email addresses are correct.'
          )}
          <br />
          {i18next.t(
            'Your reports will send immediately and cannot be recalled.'
          )}
        </Alert>
        {!isTeamReport && (
          <>
            <h5 className="heading-underlined">
              {i18next.t('Admin report distribution')}

              <a
                onClick={() => this.editEmail('self')}
                className="align-right text-transform-none"
              >
                {i18next.t('Edit notification')}
              </a>
            </h5>

            <FormItem>
              <Checkbox
                id="send-reports-checkbox-individual-send-to-me"
                value={Boolean(send_to_self)}
                onChange={e =>
                  this.setState(prevState => ({
                    send_to_self: !prevState.send_to_self,
                  }))
                }
                label={i18next.t('Send all reports to me')}
              />
            </FormItem>

            <h5 className="heading-underlined">
              {i18next.t('Additional report distribution')}
              <a
                className="align-right text-transform-none"
                onClick={() => this.editEmail('additional_recipients')}
              >
                {i18next.t('Edit notification')}
              </a>
            </h5>

            <div>
              <label>{i18next.t('Send all reports to')}</label>
              <div className="form-item">
                {additional_recipients.length > 0 && (
                  <div className="flex-list mt-md">
                    {additional_recipients.map((email, index) => (
                      <Tag
                        key={index}
                        name={email}
                        onRemove={() =>
                          this.setState(prevState => ({
                            additional_recipients: _.without(
                              prevState.additional_recipients,
                              email
                            ),
                          }))
                        }
                      />
                    ))}
                  </div>
                )}
                <div className="input-and-control">
                  <input
                    type="email"
                    id="send-reports-emails"
                    placeholder={i18next.t('Recipient email')}
                    value={recipient}
                    onChange={e => this.setState({ recipient: e.target.value })}
                  />

                  <button
                    type="button"
                    className="btn btn-default btn-small"
                    disabled={!isEmail(recipient)}
                    onClick={() => {
                      this.setState(prevState => ({
                        additional_recipients: [
                          ...prevState.additional_recipients,
                          prevState.recipient,
                        ],
                        recipient: '',
                      }));
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </button>
                </div>
              </div>
            </div>

            {!isJobProfiler && (
              <>
                <h5 className="heading-underlined">
                  {i18next.t('Respondent report distribution')}
                  <a
                    className="align-right text-transform-none"
                    onClick={() => this.editEmail('respondents')}
                  >
                    {i18next.t('Edit notification')}
                  </a>
                </h5>
                <FormItem>
                  <Checkbox
                    id="send-reports-checkbox-individual-send-to-respondents"
                    value={Boolean(send_to_respondents)}
                    onChange={() =>
                      this.setState(prevSate => ({
                        send_to_respondents: !prevSate.send_to_respondents,
                      }))
                    }
                    label={i18next.t('Send respondents their report(s)')}
                  />
                </FormItem>
              </>
            )}
          </>
        )}
        {isTeamReport && (
          <>
            <h5 className="heading-underlined">
              {i18next.t('Admin report distribution')}

              <a
                onClick={() => this.editEmail('self')}
                className="align-right text-transform-none"
              >
                {i18next.t('Edit notification')}
              </a>
            </h5>
            <FormItem>
              <Checkbox
                id="send-reports-checkbox-team-send-to-me"
                value={Boolean(send_team_to_self)}
                onChange={() =>
                  this.setState(prevState => ({
                    send_team_to_self: !prevState.send_team_to_self,
                  }))
                }
                label={
                  isFlowTeamGeneration ? (
                    <Trans i18nKey="Send team report to me">
                      Send <strong>team</strong> report to me
                    </Trans>
                  ) : (
                    <Trans i18nKey="Send team report(s) to me">
                      Send <strong>team</strong> report(s) to me
                    </Trans>
                  )
                }
              />
            </FormItem>
            {isFlowTeamGeneration && (
              <FormItem>
                <Checkbox
                  id="send-reports-checkbox-team-send-individual-to-me"
                  value={Boolean(send_individual_reports_to_self)}
                  label={
                    <Trans i18nKey="Send all individual report(s) to me">
                      Send <strong>all individual</strong> report(s) to me
                    </Trans>
                  }
                />
              </FormItem>
            )}
            <h5 className="heading-underlined">
              {i18next.t('Additional report distribution')}
              <a
                className="align-right text-transform-none"
                onClick={() => this.editEmail('additional_recipients')}
              >
                {i18next.t('Edit notification')}
              </a>
            </h5>

            <div>
              {isFlowTeamGeneration ? (
                <label>
                  {
                    <Trans i18nKey="Send the team report to">
                      Send the <strong>team</strong> report to
                    </Trans>
                  }
                </label>
              ) : (
                <label>
                  {
                    <Trans i18nKey="Send team report(s) to">
                      Send <strong>team</strong> report(s) to
                    </Trans>
                  }
                </label>
              )}
              <div className="form-item">
                {additional_team_reports_recipients.length > 0 && (
                  <div className="flex-list mt-md">
                    {additional_team_reports_recipients.map((email, index) => (
                      <Tag
                        key={index}
                        name={email}
                        onRemove={() =>
                          this.setState(prevState => ({
                            additional_team_reports_recipients: _.without(
                              prevState.additional_team_reports_recipients,
                              email
                            ),
                          }))
                        }
                      />
                    ))}
                  </div>
                )}
                <div className="input-and-control">
                  <input
                    type="email"
                    id="send-reports-emails"
                    placeholder={i18next.t(
                      'Recipient email - consider sending to the team leader'
                    )}
                    value={recipient}
                    onChange={e => this.setState({ recipient: e.target.value })}
                  />

                  <button
                    type="button"
                    className="btn btn-default btn-small"
                    disabled={!isEmail(recipient)}
                    onClick={() =>
                      this.setState(prevState => ({
                        additional_team_reports_recipients: [
                          ...prevState.additional_team_reports_recipients,
                          recipient,
                        ],
                        recipient: '',
                      }))
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </button>
                </div>
              </div>
            </div>
            {isFlowTeamGeneration && (
              <div className="form-item">
                <label>
                  {
                    <Trans i18nKey="Send all individual reports to">
                      Send <strong>all individual</strong> reports to
                    </Trans>
                  }
                </label>
                <div className="form-item">
                  {additional_individual_reports_recipients.length > 0 && (
                    <div className="flex-list mt-md">
                      {additional_individual_reports_recipients.map(
                        (email, index) => (
                          <Tag
                            key={index}
                            name={email}
                            onRemove={() =>
                              this.setState(prevState => ({
                                ...prevState,
                                additional_individual_reports_recipients: _.without(
                                  prevState.additional_individual_reports_recipients,
                                  email
                                ),
                              }))
                            }
                          />
                        )
                      )}
                    </div>
                  )}
                  <div className="input-and-control">
                    <input
                      type="email"
                      id="individual-report-recipient-input"
                      placeholder={i18next.t(
                        'Recipient email - consider sending to the team leader'
                      )}
                      value={individual_reports_recipient}
                      onChange={e =>
                        this.setState({
                          individual_reports_recipient: e.target.value,
                        })
                      }
                    />

                    <button
                      type="button"
                      className="btn btn-default btn-small"
                      disabled={!isEmail(individual_reports_recipient)}
                      onClick={() => {
                        this.setState(prevState => ({
                          additional_individual_reports_recipients: [
                            ...prevState.additional_individual_reports_recipients,
                            prevState.individual_reports_recipient,
                          ],
                          individual_reports_recipient: '',
                        }));
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add
                    </button>
                  </div>
                </div>
              </div>
            )}
            {isFlowTeamGeneration && (
              <div>
                <h5 className="heading-underlined">
                  {i18next.t('Respondent report distribution')}
                  <a
                    className="align-right text-transform-none"
                    onClick={() => this.editEmail('respondents')}
                  >
                    {i18next.t('Edit notification')}
                  </a>
                </h5>
                <FormItem>
                  <Checkbox
                    id="send-reports-checkbox-team-send-respondent-individual"
                    value={Boolean(send_individual_reports_to_respondents)}
                    label={i18next.t(
                      'Send all respondent(s) their individual report(s)'
                    )}
                  />
                </FormItem>
              </div>
            )}
          </>
        )}
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              type="button"
              onClick={() => this.props.onClose()}
            >
              {i18next.t('Cancel')}
            </button>
            <LiveButton
              response={response}
              buttonText={i18next.t('Send')}
              onClick={() => onSubmit(_.omit(this.state, ['recipient']))}
              pendingMessage={i18next.t('Sending reports...')}
              disabled={!this.canSubmit()}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default connect(null, { openEmailEditModal })(SendReportsForm);
