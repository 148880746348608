import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import {
  JOB_LEVELS,
  getAutoReportGeneration,
  getNeedsComparisonGroup,
  getReportCost,
  getName,
} from '^/models/product';
import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import ProductVersionNameAndLogo from '^/components/productVersions/ProductVersionNameAndLogo';
import FormItem from '^/components/forms/FormItem';
import Alert from '^/components/Alert';
import { getProductOrganisation } from './ProductVersionsReportGenerationForm';

export default class ProductVersionsReportGeneration extends PureComponent {
  render() {
    const {
      activityProductVersion,
      productVersion,
      organisation,
      activityChosenTemplates,
    } = this.props;
    const { product, comparison_groups } = productVersion.toObject();
    const { report_templates } = product.toObject();
    const reportGeneration = getAutoReportGeneration(activityProductVersion);
    const needsComparisonGroup = getNeedsComparisonGroup(
      activityProductVersion
    );
    const chosenReportTemplates =
      activityChosenTemplates ||
      report_templates.filter(report =>
        reportGeneration.get('report_templates').contains(report.get('id'))
      );

    const productOrganisation = getProductOrganisation(
      organisation,
      activityProductVersion
    );
    const {
      report_templates: orgReportTemplateIds,
    } = productOrganisation.toObject();
    const productVariant = activityProductVersion.get('product_variant');
    const reportTemplates = productVariant
      ? chosenReportTemplates
      : chosenReportTemplates.filter(each =>
          orgReportTemplateIds.contains(each.get('id'))
        );

    const comparisonGroup = comparison_groups.find(
      each => each.get('id') === reportGeneration.get('comparison_group')
    );
    const showJobLevel = reportTemplates.some(report =>
      report.get('requires_job_level')
    );

    return (
      <div>
        <h5 className="heading-underlined">
          <ProductVersionNameAndLogo
            icon={productVersion.getIn(['product', 'icon'])}
            name={getName(activityProductVersion)}
          />
        </h5>
        <div className="form-inline">
          {needsComparisonGroup && (
            <FormItem label={i18next.t('Comparison group')}>
              {comparisonGroup.getIn(['entity', 'name']) ||
                i18next.t('Default')}
            </FormItem>
          )}
          {reportTemplates.isEmpty() ? (
            <Alert>
              {i18next.t('There are no reports available for this product')}
            </Alert>
          ) : (
            <Table
              className="table-smaller table-header-small mt-md"
              columns={[
                {
                  header: i18next.t('Reports'),
                  value: report => report.get('name'),
                  className: 'uppercase',
                },
              ]
                .concat(
                  showJobLevel
                    ? [
                        {
                          header: i18next.t('Job level'),
                          value: report =>
                            report.get('requires_job_level') &&
                            JOB_LEVELS.DISPLAY[
                              reportGeneration.get('job_level')
                            ],
                        },
                      ]
                    : []
                )
                .concat(
                  productVariant
                    ? []
                    : [
                        {
                          header: i18next.t('Credit cost'),
                          value: report =>
                            getReportCost(report, productOrganisation),
                          headerClassName: 'align-right',
                          className: 'align-right',
                        },
                      ]
                )}
              rows={reportTemplates}
            />
          )}
        </div>
      </div>
    );
  }
}
