import { ComponentType } from 'react';
import { connect } from 'react-redux';

import {
  hasFailed,
  hasFieldFailed,
  isFieldPending,
  isPending,
} from '^/responseStates';
import { StoreState } from '^/store';
import {
  OwnProps as ResponseComponentOwnProps,
  ResponseComponentProps,
  StateProps,
} from './types';

export const createResponseComponent = <OwnProps extends {}>(
  Component: ComponentType<OwnProps & ResponseComponentProps>
) => {
  type ExcludedStateProps = Omit<OwnProps, keyof StateProps>;

  const mapStateToProps = (
    _state: StoreState,
    {
      response,
      responseFieldName,
      errorKey,
    }: ExcludedStateProps & ResponseComponentOwnProps
  ): StateProps => {
    return {
      loading: responseFieldName
        ? isFieldPending(response, responseFieldName)
        : isPending(response),
      failed: responseFieldName
        ? hasFieldFailed(response, responseFieldName)
        : hasFailed(response),
      errorMessage: errorKey && response.getIn(['errors', errorKey]),
    };
  };

  return connect<
    StateProps,
    {},
    ExcludedStateProps & ResponseComponentOwnProps,
    StoreState
  >(mapStateToProps)(Component);
};
