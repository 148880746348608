import i18next from 'i18next';
import { Map } from 'immutable';
import { Trans } from 'react-i18next';
import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';

import { StoreState } from '^/store';
import Loading from '^/components/Loading';
import FilledCircleCheckIcon from '^/components/FilledCircleCheckIcon';
import { Uuid, UserPulseRater } from '^/reducers/api/types';
import { loadUserPulseRater, submitUserPulseRatings } from '^/actions/actions';
import { isPending, hasSucceeded, hasFailed } from '^/responseStates';
import PulseItem from './PulseItem';
import BackNextable from './BackNextable';
import Alert, { AlertType } from '../Alert';
import { scrollToTop } from '^/utils-ts';
import { SUPPORT_EMAIL } from '^/settings';

interface DispatchProps {
  loadUserPulseRater: typeof loadUserPulseRater;
  submitUserPulseRatings: typeof submitUserPulseRatings;
}

interface StateProps {
  userPulseRater: UserPulseRater | null;
  userPulseRaterId: Uuid;
  userPulseRaterResponse: Map<string, any> | any;
  submitResponse: Map<string, any> | any;
}

export type Props = DispatchProps & StateProps;

interface State {
  values: { [key: string]: number | null };
}

export class PulseRatePage extends React.PureComponent<Props, State> {
  public readonly state = {
    values: {} as { [key: string]: number | null },
  };

  componentDidMount() {
    this.props.loadUserPulseRater(this.props.userPulseRaterId);
  }

  UNSAFE_componentWillUpdate(prevProps: Props) {
    const { userPulseRater } = this.props;
    if (userPulseRater && !prevProps.userPulseRater) {
      const values = _.object(
        this.getPulseItems().map(pulseItem => [pulseItem.id, null])
      );
      this.setState({ values });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.submitResponse !== this.props.submitResponse) {
      scrollToTop();
    }
  }

  public render() {
    const {
      userPulseRater,
      submitResponse,
      userPulseRaterResponse,
    } = this.props;
    const { values } = this.state;

    if (hasFailed(userPulseRaterResponse)) {
      return (
        <p className="text-error">
          {i18next.t<string>('Page not found, contact {{supportEmail}}', {
            supportEmail: SUPPORT_EMAIL,
          })}
        </p>
      );
    }

    if (!userPulseRater) {
      return <Loading />;
    }

    const pulseItems = this.getPulseItems();
    const userName = userPulseRater!.user_pulse.user.full_name;
    const orgLogo = userPulseRater!.user_pulse.user.organisation.brand_logo;
    const isDeleted = userPulseRater?.is_deleted;

    if (isDeleted) {
      return (
        <div className="pulse-bg">
          <div className="pulse pulse-rate">
            <p>
              {i18next.t<string>(
                'You have been removed from Pulsing for {{respondentName}}.',
                { respondentName: userName }
              )}
            </p>
            <div className="content">
              <div className="logos">
                <img src="/static/img/brand/enable-logo-stripe.png" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (hasSucceeded(submitResponse)) {
      return (
        <div className="pulse-bg">
          <div className="pulse-rate">
            <div className="thanks">
              <div className="thanks-inner">
                {i18next.t<string>('Thanks!')}
                <FilledCircleCheckIcon />
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (hasFailed(submitResponse)) {
      return (
        <p className="text-error">
          {i18next.t<string>(
            'There was a problem saving your response. Please refresh and try again'
          )}
        </p>
      );
    }

    return (
      <div className="pulse-bg">
        <div className="pulse pulse-rate">
          {userPulseRater!.rated_today && (
            <Alert type={AlertType.Warning}>
              {i18next.t<string>(
                'You have already submitted ratings for today'
              )}
            </Alert>
          )}
          <p>
            {userPulseRater!.is_self ? (
              <Trans i18nKey="TRANS Rate yourself on the following">
                Rate <b>yourself</b> on the following:
              </Trans>
            ) : (
              <Trans
                i18nKey="TRANS Rate username on the following"
                values={{ userName }}
                components={[<b key="username">username</b>]}
              />
            )}
          </p>
          <div className="content">
            <BackNextable
              onSubmit={this.submit}
              submitting={isPending(submitResponse)}
            >
              {pulseItems.map(({ id, text }, index) => (
                <PulseItem
                  key={id}
                  text={text}
                  numItems={pulseItems.length}
                  itemNumber={index + 1}
                  selectedValue={values[id]}
                  selectValue={value =>
                    this.setState({ values: { ...values, [id]: value } })
                  }
                />
              ))}
            </BackNextable>
            <div className="logos">
              <img src="/static/img/brand/enable-logo-stripe.png" />
              {orgLogo && <img src={orgLogo} />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private submit = () =>
    this.props.submitUserPulseRatings(
      this.props.userPulseRaterId,
      this.state.values
    );

  private getPulseItems = () =>
    this.props.userPulseRater?.user_pulse.pulse_sub_behaviours.flatMap(
      pulse_sub_behaviour => pulse_sub_behaviour.pulseitem_set
    ) || [];
}

export function mapStateToProps(state: StoreState, props: any) {
  return {
    userPulseRaterId: props.router.params.userPulseRaterId,
    userPulseRater: state.userPulseRater,
    userPulseRaterResponse: state.responses.get('loadUserPulseRater'),
    submitResponse: state.responses.get('submitUserPulseRatings'),
  };
}

export default connect(mapStateToProps, {
  loadUserPulseRater,
  submitUserPulseRatings,
})(PulseRatePage);
