import classNames from 'classnames';
import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import { clearFilters, setFilters } from '^/actions/actions';
import DateRangePicker from '^/components/date/DateRangePicker';
import { getFormError, isFieldPending } from '^/responseStates';
import { formatDateFilter } from '^/utils';

interface ExternalProps {
  response: any;
  readOnly: boolean;
  firstTransactionDate: Moment;
  filters: Readonly<{
    from_date: Date;
    to_date: Date;
  }>;
  className?: string;
}

interface DispatchProps {
  setFilters: typeof setFilters;
  clearFilters: typeof clearFilters;
}

type Props = ExternalProps & DispatchProps;

export class CreditDatePicker extends React.Component<Props, {}> {
  public UNSAFE_componentWillMount() {
    this.props.setFilters({
      from_date: formatDateFilter(moment().startOf('year')),
      to_date: formatDateFilter(moment()),
    });
  }

  public render() {
    const {
      response,
      readOnly,
      firstTransactionDate,
      className,
      filters: { from_date: fromDate, to_date: toDate },
    } = this.props;

    const currentDate = moment();
    const minDateTime = moment(firstTransactionDate).startOf('year');

    return (
      <DateRangePicker
        onSave={this.onDateSave}
        minDate={minDateTime}
        maxDate={currentDate}
        className={classNames('margin-bottom', className)}
        first={{
          fieldName: 'from_date',
          disabled: readOnly,
          value: fromDate,
          isLoading: isFieldPending(response, 'from_date'),
          defaultValue: i18next.t<string>('Start date'),
          prefix: `${i18next.t<string>('From')}: `,
          error: getFormError(response, 'from_date'),
        }}
        second={{
          fieldName: 'to_date',
          disabled: readOnly,
          value: toDate,
          isLoading: isFieldPending(response, 'to_date'),
          defaultValue: i18next.t<string>('Close date'),
          prefix: `${i18next.t<string>('To')}:`,
          error: getFormError(response, 'to_date'),
        }}
        isCreditDatePicker
      />
    );
  }

  private onDateSave = (field: string, value?: Moment) => {
    const { filters } = this.props;
    this.props.setFilters({
      ...filters,
      [field]: formatDateFilter(value),
    });
  };
}

export default connect(null, { setFilters, clearFilters })(CreditDatePicker);
