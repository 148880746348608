import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Link } from 'react-router';

import ControlBar from '^/components/ControlBar';
import { TabbedContainer } from '^/components/TabbedContainer';
import { can, administerOrganisations } from '^/capabilities';
import { openModal, closeTopModal } from '^/actions/modals';
import { ActivitiesPageTable } from './ActivitiesPageTable';

function hasSharedActivities(user) {
  const { shared_from } = user.toObject();
  return shared_from && shared_from.size;
}

export class ActivitiesPage extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { user } = this.props;
    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/activities/hub/">{i18next.t('Activities Hub')}</Link>
        <span>{i18next.t('Manage Activities')}</span>
      </span>
    );
    const canAdminister = can(user, administerOrganisations());
    const userOrg = user.get('organisation');
    const showPulse = (userOrg && userOrg.get('can_pulse')) || canAdminister;
    return (
      <div>
        <ControlBar
          title={i18next.t('Manage Activities')}
          breadcrumb={breadcrumb}
          hideAdd
          hideSearch
        />
        {hasSharedActivities(user) ? (
          <TabbedContainer
            transparent
            defaultActive="mine"
            config={[
              {
                id: 'mine',
                label: i18next.t('My activities'),
                renderer: (
                  <ActivitiesPageTable
                    openModal={this.props.openModal}
                    closeTopModal={this.props.closeTopModal}
                    shareFilterValues={false}
                    canAdminister={canAdminister}
                    showPulse={showPulse}
                  />
                ),
              },
              {
                id: 'shared',
                label: i18next.t('Activities shared with me'),
                renderer: (
                  <ActivitiesPageTable
                    openModal={this.props.openModal}
                    closeTopModal={this.props.closeTopModal}
                    shareFilterValues
                    canAdminister={canAdminister}
                    showPulse={showPulse}
                  />
                ),
              },
            ]}
          />
        ) : (
          <ActivitiesPageTable
            openModal={this.props.openModal}
            closeTopModal={this.props.closeTopModal}
            canAdminister={canAdminister}
            showPulse={showPulse}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}
export default connect(mapStateToProps, {
  openModal,
  closeTopModal,
})(ActivitiesPage);
