import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import WithPopover from '^/components/WithPopover';
import PureComponent from '^/components/PureComponent';
import { can, administerOwnOrganisation } from '^/capabilities';

export class ProfileModalTitle extends PureComponent {
  render() {
    const { user } = this.props;
    return (
      <div>
        {i18next.t('We need a little more information about you')}
        {can(user, administerOwnOrganisation()) && (
          <div className="pull-right">
            <WithPopover
              inline
              popover={i18next.t('PARAGRAPH profile modal title popover')}
              below
              wide
            >
              <a className="modal-link">{i18next.t('Find out more')}</a>
            </WithPopover>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps)(ProfileModalTitle);
