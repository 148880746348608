import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import { can, muteAdminReportDistribution } from '^/capabilities';
import { RULES, getReportVisibility, setDisplayRule } from '^/reportVisibility';
import {
  setManualReportGeneration,
  getReportEmailDefaults,
} from '^/actions/actions';
import {
  closeTopModal,
  openProductVersionsReportGenerationModal,
  openEmailEditModal,
  openNotificationEmailEditModal,
} from '^/actions/modals';
import { selectReportEmailDefaults } from '^/selectors';
import {
  createReportNotificationEmailAndClose,
  createNotificationEmailAndClose,
  updateReportNotificationEmailAndClose,
  updateNotificationEmailAndClose,
} from '^/actions/actionSequences';
import { getAutoReportGeneration } from '^/models/product';
import { getChosenReportTemplates } from '^/models/activity';
import PureComponent from '^/components/PureComponent';
import ProductVersionsReportGeneration from '^/components/activities/ProductVersionsReportGeneration';
import ReportDistribution from './ReportDistribution';
import ReportCost from './ReportCost';
import ReportGenerationToggle, {
  REPORT_GENERATION,
} from './ReportGenerationToggle';
import Alert from '^/components/Alert';
import { DEFAULT_LANGUAGE_CODE } from '^/constants/routes';

const THREE_SIXTY = 'THREE_SIXTY';

export class ActivityReportsTab extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getReportEmailDefaults();
  }

  getReportVisibility() {
    return getReportVisibility(this.props.activity);
  }

  setDisplay(value) {
    const { activity } = this.props;
    const original = this.getReportVisibility();
    const changed = setDisplayRule(
      activity,
      value,
      activity.get('end_datetime')
    );
    if (changed !== original) {
      this.props.updateReportVisibility(changed.toJS());
      // If we set report visibility to Hide, we should turn off the flags on their notifications, too
      // Otherwise, we should turn it on
      if (original.get('rule') !== RULES.HIDE && value === RULES.HIDE) {
        this.props.muteActivityNotification('ACTIVITY_REPORTS_READY', true);
      } else if (original.get('rule') === RULES.HIDE) {
        this.props.muteActivityNotification('ACTIVITY_REPORTS_READY', false);
      }
    }
  }

  setDate(value) {
    const reportVisibility = this.getReportVisibility()
      .set('date', value)
      .set('rule', RULES.SHOW_AFTER_DATE)
      .toJS();
    this.props.updateReportVisibility(reportVisibility);
  }

  getActivityProductVersions() {
    const { activity } = this.props;
    const {
      activity_product_version_items,
      product_versions,
    } = activity.toObject();
    return activity_product_version_items.map(activityProductVersion =>
      activityProductVersion.update('product_version', productVersion =>
        productVersion.merge(
          product_versions.find(
            each => each.get('id') === productVersion.get('id')
          )
        )
      )
    );
  }

  onChangeReportGenerationType(type) {
    const { activity } = this.props;
    const { organisation, id } = activity.toObject();

    if (type === REPORT_GENERATION.CHOICES.AUTO) {
      this.props.openProductVersionsReportGenerationModal(
        this.getActivityProductVersions(),
        organisation
      );
    } else {
      this.props.setManualReportGeneration(id);
    }
  }

  onChangeAutoConfiguration() {
    const { activity } = this.props;
    const { organisation } = activity.toObject();

    this.props.openProductVersionsReportGenerationModal(
      this.getActivityProductVersions(),
      organisation
    );
  }

  editReportNotification(default_template, type) {
    const { activity, reportEmailDefaults } = this.props;
    const {
      id: activityId,
      report_notification_emails: reportEmails,
      email_language_preferences,
    } = activity.toObject();
    const emailTemplate = reportEmailDefaults[default_template];
    const preferences = email_language_preferences.toJS();
    emailTemplate.preferred_lang_code =
      Object.keys(preferences).indexOf(type) >= 0
        ? preferences[type]
        : DEFAULT_LANGUAGE_CODE;
    reportEmails.forEach(email => {
      if (email.get('type') === type) {
        emailTemplate.translations[email.get('lang_code')] = email.toJS();
      }
    });
    this.props.openEmailEditModal(emailTemplate, activityId, type);
  }

  editNotification() {
    const type = 'ACTIVITY_REPORTS_READY';
    const { activity } = this.props;
    const notification = activity
      .get('notification_emails')
      .find(each => each.get('type') === type);
    this.props.openNotificationEmailEditModal(
      notification,
      i18next.t('Editing report email'),
      activity
    );
  }

  render() {
    const {
      user,
      activity,
      response,
      updateReportVisibilityResponse,
      readOnly,
      reportTypeResponse,
    } = this.props;

    const reportVisibility = this.getReportVisibility();
    const is360 = activity.get('type') === THREE_SIXTY;

    const activityProductVersionItems = activity.get(
      'activity_product_version_items'
    );
    const productVersions = activity.get('product_versions');
    const organisation = activity.get('organisation');
    const activityChosenTemplatesByProductOrganisation =
      activity && getChosenReportTemplates(activity);
    const activityChosenTemplates =
      activityChosenTemplatesByProductOrganisation &&
      activityChosenTemplatesByProductOrganisation.flatMap(
        ([, reportTemplates]) => reportTemplates
      );
    const reportGeneration =
      !activityProductVersionItems.isEmpty() &&
      activityProductVersionItems.every(getAutoReportGeneration)
        ? REPORT_GENERATION.CHOICES.AUTO
        : REPORT_GENERATION.CHOICES.MANUAL;
    const isVariant = activityProductVersionItems.some(each =>
      each.get('product_variant')
    );
    const hasSessions = activity.get('has_sessions');
    const canChange = !readOnly && !hasSessions;

    if (
      productVersions
        .flatMap(each => each.getIn(['product', 'report_templates']))
        .isEmpty()
    ) {
      return (
        <Alert>
          {i18next.t(
            'There are no reports available for your chosen products.'
          )}
        </Alert>
      );
    }

    return (
      <div className="form-container activity-reports-tab">
        <div className="form-group-inline">
          <label>{i18next.t('Report options')}</label>
          {isVariant ? (
            <ReportGenerationToggle
              readOnly
              reportGeneration={REPORT_GENERATION.CHOICES.AUTO}
              disabledMessage={i18next.t(
                'This product requires auto report generation.'
              )}
            />
          ) : (
            <ReportGenerationToggle
              is360={is360}
              reportGeneration={reportGeneration}
              reportTypeResponse={reportTypeResponse}
              readOnly={!canChange}
              onChange={this.onChangeReportGenerationType.bind(this)}
            />
          )}
        </div>

        {reportGeneration === REPORT_GENERATION.CHOICES.AUTO && (
          <div>
            <hr />

            <div className="form-group-spacer">
              {canChange && (
                <button
                  className="btn btn-default btn-small margin-none"
                  onClick={() => this.onChangeAutoConfiguration()}
                >
                  {i18next.t('Change report(s)')}
                </button>
              )}
            </div>

            {productVersions.map(productVersion => (
              <ProductVersionsReportGeneration
                key={productVersion.get('id')}
                activityChosenTemplates={activityChosenTemplates}
                organisation={organisation}
                productVersion={productVersion}
                activityProductVersion={activityProductVersionItems
                  .find(
                    item =>
                      item.getIn(['product_version', 'id']) ===
                      productVersion.get('id')
                  )
                  .update('product_version', existing =>
                    existing.merge(productVersion)
                  )}
              />
            ))}

            {organisation.get('is_using_credit') &&
              !activity.get('is_prepay') &&
              !isVariant && (
                <ReportCost
                  organisation={organisation}
                  numberOfRespondents={activity.get('users').count()}
                  reportTemplatesByProductOrganisation={
                    activityChosenTemplatesByProductOrganisation
                  }
                  remainingCost={activity.get('remaining_credit_cost')}
                />
              )}

            <ReportDistribution
              activity={activity}
              editNotification={this.editNotification.bind(this)}
              editReportNotification={this.editReportNotification.bind(this)}
              updateFields={this.props.updateFields}
              canUserMuteAdminReportDistribution={can(
                user,
                muteAdminReportDistribution()
              )}
              readOnly={readOnly}
              response={response}
              is360={is360}
              user={user}
              reportVisibility={reportVisibility}
              setDisplay={this.setDisplay.bind(this)}
              setDate={this.setDate.bind(this)}
              updateReportVisibilityResponse={updateReportVisibilityResponse}
            />
          </div>
        )}
      </div>
    );
  }
}

ActivityReportsTab.propTypes = {
  activity: ImmutablePropTypes.map.isRequired,
  response: PropTypes.object,
  reportVisibility: ImmutablePropTypes.map,
  muteActivityNotification: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    reportTypeResponse: state.responses.get('setManualReportGeneration'),
    response: state.responses.get('updateItem'),
    reportEmailDefaults: selectReportEmailDefaults(state),
  };
}

export default connect(mapStateToProps, {
  openProductVersionsReportGenerationModal,
  setManualReportGeneration,
  openEmailEditModal,
  openNotificationEmailEditModal,
  getReportEmailDefaults,
  createReportNotificationEmailAndClose,
  updateReportNotificationEmailAndClose,
  createNotificationEmailAndClose,
  updateNotificationEmailAndClose,
  closeTopModal,
})(ActivityReportsTab);
