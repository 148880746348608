/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSelector } from 'reselect';
import _ from 'underscore';

import { ReportFilterSelections } from '^/actions/ui';
import { accountHasUnlimitedCredits } from '^/models/organisation';
import {
  OrgSessions,
  Report,
  ReportTemplate,
  Uuid,
} from '^/reducers/api/types';
import {
  available,
  isSelected,
  notHidden,
  ReportsTableSelections,
  selectionFromKey,
  SelectionState,
} from '^/reducers/reports/types';
import { selectOrgSessions, selectOrgUsers } from '^/selectors';
import { selectItemsOrUserOrganisation } from '^/selectors/items';
import { selectReportFilterSelection } from '^/selectors/ui';
import { StoreState } from '^/store';
import { isTruthy } from '^/utils';
import { searchStringify } from './utils';

export const selectAccountHasUnlimitedCredits = createSelector(
  selectItemsOrUserOrganisation,
  organisation => organisation && accountHasUnlimitedCredits(organisation)
);

const selectReportFilterSearchString = createSelector(
  selectReportFilterSelection,
  (reportFilterSelection: ReportFilterSelections) =>
    searchStringify(reportFilterSelection.searchString)
);

export function makeReportCode(
  product: { code: string },
  template: { code: string },
  productVariant?: { code: string } | null
): string {
  return [product.code, template.code, productVariant && productVariant.code]
    .filter(isTruthy)
    .join(' - ');
}

export const createProductAndVariantKey = (
  productId: Uuid,
  variantId: Uuid | undefined | null
) => `${productId}${variantId || ''}`;

export const getReportTemplatesByProductAndVariantId = (
  orgSessions: OrgSessions | null
): {
  [key: string]: ReadonlyArray<ReportTemplate>;
} =>
  orgSessions
    ? orgSessions.productorganisation_set.reduce(
        (
          memo: {
            [key: string]: ReadonlyArray<ReportTemplate>;
          },
          productOrganisation
        ) => {
          return {
            ...memo,
            [createProductAndVariantKey(
              productOrganisation.product.id,
              productOrganisation.product_variant?.id
            )]: productOrganisation.product_variant
              ? productOrganisation.product_variant.report_templates.map(
                  reportTemplate => ({
                    ...reportTemplate,
                    code: makeReportCode(
                      productOrganisation.product,
                      reportTemplate,
                      productOrganisation.product_variant
                    ),
                  })
                )
              : productOrganisation.productorganisationreporttemplate_set
                  .filter(
                    orgReportTemplate =>
                      !orgReportTemplate.report_template.is_team
                  )
                  .map(orgReportTemplate => ({
                    ...orgReportTemplate.report_template,
                    code: makeReportCode(
                      productOrganisation.product,
                      orgReportTemplate.report_template
                    ),
                  })),
          };
        },
        {}
      )
    : {};

export const selectReportTemplatesByProductAndVariantId = createSelector(
  selectOrgSessions,
  getReportTemplatesByProductAndVariantId
);

export interface UserSession {
  id: Uuid;
  product: Uuid;
  productVariant: Uuid | null;
  reports: ReadonlyArray<Report>;
  sharedWithMe?: boolean;
  mine?: boolean;
  completed: string | null;
}

export interface UserSessions {
  [key: string]: ReadonlyArray<UserSession>;
}

function dateIsBetween(dateString: string, fromDate?: Date, toDate?: Date) {
  const date = new Date(dateString);
  return (!fromDate || date > fromDate) && (!toDate || date < toDate);
}

function sortByCompleted(sessionsByUser: UserSessions) {
  return _.mapObject(sessionsByUser, (sessions: ReadonlyArray<UserSession>) =>
    _.sortBy(sessions, (session: UserSession) => session.completed)
  );
}

export const getSessionsByUser = (
  orgSessions: Pick<OrgSessions, 'activities'> | null,
  reportFilterSelection?: ReportFilterSelections
): UserSessions =>
  orgSessions
    ? sortByCompleted(
        orgSessions.activities
          .filter(
            activity =>
              !reportFilterSelection ||
              !Object.values(reportFilterSelection.activities).some(isTruthy) ||
              reportFilterSelection.activities[activity.id]
          )
          .reduce((memo: UserSessions, activity) => {
            activity.activity_product_version_items
              .filter(
                apv =>
                  !reportFilterSelection ||
                  !Object.values(reportFilterSelection.products).some(
                    isTruthy
                  ) ||
                  reportFilterSelection.products[apv.product_version.product]
              )
              .forEach(apv =>
                apv.activity_product_version_sessions
                  .filter(session => !session.is_missing_line_manager)
                  .filter(session =>
                    dateIsBetween(
                      session.completed,
                      reportFilterSelection && reportFilterSelection.date.from,
                      reportFilterSelection && reportFilterSelection.date.to
                    )
                  )
                  .forEach(
                    session =>
                      (memo[session.user] = (memo[session.user] || []).concat({
                        product: apv.product_version.product,
                        productVariant: apv.product_variant,
                        reports: session.reports,
                        id: session.id,
                        mine: activity.mine,
                        sharedWithMe: activity.shared_with_me,
                        completed: session.completed,
                      }))
                  )
              );
            return memo;
          }, {})
      )
    : {};

export const selectSessionsByUser = createSelector(
  selectOrgSessions,
  selectReportFilterSelection,
  getSessionsByUser
);

export const selectFilteredOrgUsers = createSelector(
  selectOrgUsers,
  selectReportFilterSelection,
  selectReportFilterSearchString,
  (orgUsers, reportFilterSelection: ReportFilterSelections, searchString) => {
    if (!orgUsers) {
      return [];
    }
    let filteredUsers = orgUsers.users;
    // TODO: figure out which one of these operations are the most expensive and
    // put them in order from least expensive to most
    if (Object.values(reportFilterSelection.activities).some(isTruthy)) {
      filteredUsers = filteredUsers.filter(user =>
        user.activity_users.some(
          au => reportFilterSelection.activities[au.activity]
        )
      );
    }
    if (Object.values(reportFilterSelection.groups).some(isTruthy)) {
      filteredUsers = filteredUsers.filter(user =>
        user.usergroup_set.some(
          userGroup => reportFilterSelection.groups[userGroup]
        )
      );
    }
    if (searchString) {
      filteredUsers = filteredUsers.filter(user =>
        searchStringify(user.full_name).includes(searchString)
      );
    }
    return filteredUsers;
  }
);

export const selectReportsTableSelections = (state: StoreState) =>
  state.reportsTableSelections;

function makeReportsTableSelectionSelector<T>(
  mapper: (k: SelectionState[]) => T
) {
  return createSelector(
    selectReportsTableSelections,
    reportsTableSelections =>
      _.mapObject(reportsTableSelections, (each: ReportsTableSelections) =>
        mapper(
          Object.values(each).filter(
            selectionState =>
              notHidden(selectionState) && available(selectionState)
          )
        )
      ) as { [key in keyof ReportsTableSelections]: T }
  );
}

export const selectAllSelectedReportsTable = makeReportsTableSelectionSelector(
  selections => selections.length > 0 && selections.every(isSelected)
);
export const selectAnySelectedReportsTable = makeReportsTableSelectionSelector(
  selections => selections.some(isSelected)
);
export const selectHasAnyReportsTable = makeReportsTableSelectionSelector(
  selections => selections.length > 0
);
export const selectCountReportsTable = makeReportsTableSelectionSelector(
  selections => selections.filter(isSelected).length
);

export const selectSelectedReportsReportsTable = createSelector(
  selectReportsTableSelections,
  reportsTableSelections =>
    Object.entries(reportsTableSelections.reports)
      .filter(([_key, value]) => isSelected(value))
      .map(([key, _value]) => selectionFromKey(key))
);
export const selectSelectedReportTemplatesReportsTable = createSelector(
  selectReportsTableSelections,
  reportsTableSelections =>
    Object.entries(reportsTableSelections.reportTemplates)
      .filter(([_key, value]) => isSelected(value))
      .map(([key, _value]) => selectionFromKey(key))
);
export const selectAnySelectionReportsTable = createSelector(
  selectAnySelectedReportsTable,
  anySelectedReportsTable =>
    anySelectedReportsTable.reports || anySelectedReportsTable.reportTemplates
);

export const selectCountSelectionReportsTable = createSelector(
  selectCountReportsTable,
  countReportsTable =>
    countReportsTable.reports + countReportsTable.reportTemplates
);
