/* eslint-disable @typescript-eslint/no-use-before-define */
import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { loadTeamSessions, setModalTitle } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { generateTeamReportAndRefresh } from '^/actions/actionSequences';
import { can, administerOwnOrganisation } from '^/capabilities';
import { LanguageCode } from '^/constants/routes';
import { STAGES } from '^/components/reports/admin/ReportActions';
import {
  OrgFilters,
  OrgSessions,
  TeamSessions,
  Uuid,
} from '^/reducers/api/types';
import { StoreState } from '^/store';
import { getReportsToGenerate } from './utils';
import ChooseSessionsPage from './ChooseSessionsPage';
import ConfirmPurchasePage from './ConfirmPurchasePage';
import ReportActionsPage from './ReportActionsPage';
import { ReportToGenerate, TeamReportModalPage } from './types';
import { isMultiLanguage } from './MultiLanguageSection';

interface State {
  page: TeamReportModalPage;
  formStage: string;
  selectedReports: Array<{ reportId: Uuid }>;
  teamName: string;
  selectedSessionIds: Set<Uuid>;
  languageCode: LanguageCode;
  reportsToGenerate: ReportToGenerate[];
}

interface OwnProps {
  orgId: Uuid;
  productId: Uuid;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  teamSessions: TeamSessions | null;
  orgFilters: OrgFilters | null;
  orgSessions: OrgSessions | null;
  canUserAdministerOwnOrganisation: boolean;
  response: Immutable.Map<string, any> | undefined;
}

interface DispatchProps {
  loadTeamSessions: typeof loadTeamSessions;
  closeTopModal: typeof closeTopModal;
  setModalTitle: typeof setModalTitle;
  generateTeamReportAndRefresh: typeof generateTeamReportAndRefresh;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class CreateTeamReportModal extends React.Component<Props, State> {
  public readonly state = {
    page: TeamReportModalPage.ChooseSessions,
    formStage: STAGES.SET_UP,
    teamName: '',
    selectedSessionIds: new Set<Uuid>(),
    selectedReports: [],
    languageCode: LanguageCode.EN_GB,
    reportsToGenerate: [],
  } as State;

  public componentDidMount() {
    this.setState({
      reportsToGenerate: getReportsToGenerate(
        this.props.orgSessions,
        this.props.productId,
        this.state.languageCode,
        this.getSelectedSessions.bind(this)
      ),
    });
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.teamSessions !== this.props.teamSessions ||
      prevState.languageCode !== this.state.languageCode
    ) {
      this.setState({
        reportsToGenerate: getReportsToGenerate(
          this.props.orgSessions,
          this.props.productId,
          this.state.languageCode,
          this.getSelectedSessions.bind(this)
        ),
      });
    }
  }

  private getSelectedSessions() {
    const { selectedSessionIds } = this.state;
    const { teamSessions } = this.props;
    return (teamSessions || []).filter(session =>
      selectedSessionIds.has(session.id)
    );
  }

  private updateSelectedSessions = (selectedSessionIds: Set<Uuid>) => {
    this.setState({ selectedSessionIds: new Set(selectedSessionIds) });
  };

  private updateReportsToGenerate = (reportsToGenerate: ReportToGenerate[]) => {
    this.setState({ reportsToGenerate });
  };

  private updateFormStage = (formStage: string) => {
    this.setState({ formStage });
  };

  private updateSelectedReports = (reports: ReportToGenerate[]) => {
    this.setState({
      selectedReports: reports.map((each: any) => ({ reportId: each.id })),
    });
  };

  private updateLanguageCode = (languageCode: LanguageCode) => {
    this.setState({ languageCode });
  };

  private moveToConfirmPurchasePage = () => {
    this.props.setModalTitle(i18next.t<string>('Confirm purchase'));
    this.setState({
      page: TeamReportModalPage.ProvideName,
      reportsToGenerate: getReportsToGenerate(
        this.props.orgSessions,
        this.props.productId,
        this.state.languageCode,
        this.getSelectedSessions.bind(this)
      ),
    });
  };

  private generate = (teamName?: string) => {
    const { orgSessions, productId, orgId, user } = this.props;
    const { reportsToGenerate, selectedSessionIds, languageCode } = this.state;
    const selectedReports = reportsToGenerate.filter(report => report.selected);
    this.props.generateTeamReportAndRefresh(
      {
        name: teamName,
        sessions: [...selectedSessionIds],
        team_template: selectedReports.find(report => report.isTeam)?.id,
        individual_templates: selectedReports
          .filter(report => !report.isTeam)
          .map(report => report.id),
        ...(isMultiLanguage(orgSessions, productId)
          ? { lang_code: languageCode }
          : {}),
      },
      orgId,
      user,
      (reports: any) => {
        this.updateSelectedReports(reports);
        this.updateFormStage(STAGES.GENERATING);
      }
    );
  };

  public render() {
    const {
      page,
      formStage,
      selectedSessionIds,
      reportsToGenerate,
      languageCode,
      selectedReports,
    } = this.state;
    const {
      orgId,
      productId,
      orgSessions,
      orgFilters,
      teamSessions,
      canUserAdministerOwnOrganisation,
      response,
    } = this.props;
    if (formStage === STAGES.SET_UP) {
      return page === TeamReportModalPage.ChooseSessions ? (
        <ChooseSessionsPage
          orgId={orgId}
          productId={productId}
          moveToConfirmPurchasePage={this.moveToConfirmPurchasePage}
          selectedSessionIds={selectedSessionIds}
          updateSelectedSessions={this.updateSelectedSessions}
          orgSessions={orgSessions}
          sessions={teamSessions}
          orgFilters={orgFilters}
          canUserAdministerOwnOrganisation={canUserAdministerOwnOrganisation}
          loadSessions={this.props.loadTeamSessions}
        />
      ) : (
        <ConfirmPurchasePage
          productId={productId}
          reportsToGenerate={reportsToGenerate}
          orgSessions={orgSessions}
          selectedSessionIds={selectedSessionIds}
          languageCode={languageCode}
          getSelectedSessions={this.getSelectedSessions.bind(this)}
          updateReportsToGenerate={this.updateReportsToGenerate}
          updateLanguageCode={this.updateLanguageCode}
          response={response}
          generate={this.generate}
          isTeamReports
          isJobMatch={false}
        />
      );
    }
    return (
      <ReportActionsPage
        orgId={orgId}
        formStage={formStage}
        updateFormStage={this.updateFormStage}
        selectedReports={selectedReports}
        isTeamReport
      />
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userProfile,
    teamSessions: state.teamSessions,
    orgFilters: state.orgFilters,
    orgSessions: state.orgSessions,
    canUserAdministerOwnOrganisation: can(
      state.userProfile,
      administerOwnOrganisation()
    ),
    response: state.responses.get('generateTeamReport'),
  };
}

export default connect(mapStateToProps, {
  loadTeamSessions,
  closeTopModal,
  setModalTitle,
  generateTeamReportAndRefresh,
})(CreateTeamReportModal);
