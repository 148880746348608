import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import ActivityRatersStatusTable from './ActivityRatersStatusTable';
import { loadActivity } from '../../actions/items';
import ControlBar from '../ControlBar';
import ContentBox from '../ContentBox';
import { withRouter } from '^/withRouter';
import LoadingAndErrors from '../LoadingAndErrors';

export class ActivityRatersPage extends PureComponent {
  UNSAFE_componentWillMount() {
    const { activityId, activity } = this.props;
    if (!activity || activity.get('id') !== activityId) {
      this.props.loadActivity(this.props.activityId);
    }
  }

  render() {
    const { activity, loadActivityResponse } = this.props;
    const activityName = activity && activity.get('name');

    const breadcrumb = (
      <span className="breadcrumbs">
        <Link to="/page/activities/view">{i18next.t('Activities')}</Link>
        <span>{activityName || null}</span>
      </span>
    );
    return (
      <div>
        <ControlBar
          hideSearch
          hideAdd
          breadcrumb={breadcrumb}
          title={activityName || null}
        />

        {activity ? (
          <ContentBox>
            <h2>{i18next.t('Rater Status')}</h2>
            <p>{i18next.t('Check the status of all Raters for this review')}</p>

            <ActivityRatersStatusTable activity={activity} />

            <Link
              to={`/page/activities/${activity.get('id')}`}
              className="btn btn-primary"
            >
              {i18next.t('Back to activity')}
            </Link>
          </ContentBox>
        ) : (
          <LoadingAndErrors
            responses={List([loadActivityResponse])}
            loadingClassName="list-loading"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    user: state.userProfile,
    activityId: props.router.params.id,
    activity: state.items.get('activities'),
    response: state.responses.get('updateItem'),
    loadActivityResponse: state.responses.get('loadItem'),
  };
}

export default withRouter(
  connect(mapStateToProps, { loadActivity })(ActivityRatersPage)
);
