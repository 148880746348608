import React from 'react'; // eslint-disable-line no-unused-vars

import ActivityCard from './ActivityCard';

const ActivityCards = ({
  activities,
  emptyMessage,
  showCreator,
  showAccount,
}) =>
  activities.isEmpty() ? (
    <p>{emptyMessage}.</p>
  ) : (
    <div className="activity-card-wrapper">
      {activities.map((activity, idx) => (
        <ActivityCard
          key={activity ? activity.get('id') : idx}
          activity={activity}
          showCreator={showCreator}
          showAccount={showAccount}
        />
      ))}
    </div>
  );

export default ActivityCards;
