import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import LiveButton from '^/components/LiveButton';
import FileUpload from '^/components/FileUpload';
import FormError from '^/components/FormError';
import PureComponent from '^/components/PureComponent';
import { MAX_PURCHASE_CREDITS } from '^/components/organisations/accounts/PurchaseCreditsForm';

export class AddCreditsToOrganisationForm extends PureComponent {
  render() {
    const { onSubmit, response, account } = this.props;

    return (
      <Formik
        initialValues={{
          amount: '',
          invoice: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-inline">
            <div>
              <label>{i18next.t('Available credits')}</label>
              <div className="form-item">
                <span>
                  {account.get('credit') || 0} {i18next.t('credits')}
                </span>
              </div>
            </div>

            <div>
              <label htmlFor="add-org-credits">
                {i18next.t('Add credits')}
              </label>
              <div className="form-item">
                <Field
                  id="add-org-credits"
                  type="number"
                  min={1}
                  max={MAX_PURCHASE_CREDITS}
                  placeholder="0"
                  name="amount"
                />
              </div>
            </div>

            <div>
              <label>{i18next.t('Invoice')}</label>
              <div className="form-item">
                <FileUpload
                  value={values.invoice}
                  setPendingUpload={file => {
                    this.props.setPendingUpload();
                    setFieldValue('invoice', file);
                  }}
                  onSubmit={onSubmit}
                  name="invoice"
                />
                <FormError
                  className="mt-none"
                  response={response}
                  formKey="invoice"
                />
              </div>
            </div>

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <LiveButton
                  pendingMessage={i18next.t('Adding credits...')}
                  buttonText={i18next.t('Add credits')}
                  response={response}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AddCreditsToOrganisationForm;
