import React from 'react'; // eslint-disable-line no-unused-vars
import { faEdit, faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import DashItem from '../../dashboard/admin/DashItem';
import { MODAL_COMPONENTS } from '../types';

interface Props {
  renderComponent: (componentId: MODAL_COMPONENTS) => void;
}

const CreateOrEditProfilePage: React.FC<Props> = ({ renderComponent }) => {
  const renderCreateFilterProfileComponent = () => {
    renderComponent(MODAL_COMPONENTS.CREATE_EDIT_FILTER_PROFILE);
  };

  const renderListFilterProfileComponent = () => {
    renderComponent(MODAL_COMPONENTS.LIST_FILTER_PROFILES);
  };

  return (
    <div className="dash-item-container padding-large">
      <DashItem
        title={i18next.t<string>('Create a profile')}
        icon={
          <FontAwesomeIcon
            icon={faPlusSquare}
            size="5x"
            className="dash-icon dash-create"
          />
        }
        onClick={renderCreateFilterProfileComponent}
      />
      <DashItem
        title={i18next.t<string>('Edit a profile')}
        icon={
          <FontAwesomeIcon
            icon={faEdit}
            size="5x"
            className="dash-icon dash-create"
          />
        }
        onClick={renderListFilterProfileComponent}
      />
    </div>
  );
};

export default CreateOrEditProfilePage;
