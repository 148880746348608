import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Set } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import { sum } from '^/utils-ts';
import { hasFailed, isPending } from '^/responseStates';
import { getAllProductOrganisations } from '^/actions/collections';
import { toggleSelection, clearSelection } from '^/actions/actions';
import { ACTIVITY_TYPE } from '^/models/activity';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import { ProductSelector } from '^/components/ProductSelector';
import Alert, { AlertType } from '^/components/Alert';

const getName = productOrganisation =>
  productOrganisation.getIn(['product_variant', 'name']) ||
  productOrganisation.getIn(['product', 'name']);

const getNumberAvailable = productOrganisation => {
  const numberAvailable = productOrganisation.get('number_available', null);
  return numberAvailable !== null
    ? ` ${i18next.t('({{numberAvailable}} available)', { numberAvailable })}`
    : '';
};

const ONLY_SINGLE_ALLOWED = [
  productOrganisation => productOrganisation.get('product_variant') !== null,
  productOrganisation =>
    productOrganisation.getIn(['product', 'activity_type']) ===
    ACTIVITY_TYPE.THREE_SIXTY,
];

export class AddProductsModal extends PureComponent {
  componentDidMount() {
    if (!this.props.noLoad) {
      this.props.getAllProductOrganisations(this.props.organisation.get('id'), {
        activity_type: this.props.activityType,
      });
    }
  }

  toggleSelection(productOrganisationId) {
    if (this.numberSelected() > 0) {
      this.props.clearSelection('productOrganisationsAddPulse');
    }
    this.props.toggleSelection('productOrganisations', productOrganisationId);
  }

  togglePulsing(productOrganisationId) {
    return this.props.toggleSelection(
      'productOrganisationsAddPulse',
      productOrganisationId
    );
  }

  getSelectedProductOrganisations() {
    const { productOrganisations, selectedProductOrganisations } = this.props;
    return productOrganisations.filter(each =>
      selectedProductOrganisations.contains(each.get('id'))
    );
  }

  addToActivity() {
    const withPulse = this.props.selectedProductOrganisationsPulses.size > 0;

    this.props.onChooseProductOrganisations(
      this.getSelectedProductOrganisations(),
      withPulse
    );
    this.props.clearSelection('productOrganisations');
  }

  clearAndClose() {
    this.props.clearSelection('productOrganisations');
    this.props.onClose();
  }

  isCurrent(productOrganisation) {
    const { current } = this.props;
    return (
      current &&
      current.some(
        each =>
          each.getIn(['product_version', 'id']) ===
            productOrganisation.getIn(['product', 'latest_version', 'id']) &&
          each.getIn(['product_variant', 'id']) ===
            productOrganisation.getIn(['product_variant', 'id'])
      )
    );
  }
  numberSelected() {
    return (
      Array.from(
        this.props.productOrganisations
          .filter(
            productOrganisation =>
              productOrganisation.get('number_available') !== 0
          )
          .map(productOrganisation =>
            this.isSelected(productOrganisation) ||
            this.isCurrent(productOrganisation)
              ? true
              : false
          )
      ).filter(item => item === true).length || 0
    );
  }

  isSelected(productOrganisation) {
    const { selectedProductOrganisations } = this.props;
    return selectedProductOrganisations.includes(productOrganisation.get('id'));
  }

  isPulsingAvailable(productOrganisation) {
    return productOrganisation.get('pulse_behaviour_hierarchy') !== null;
  }

  isDisabled(productOrganisation) {
    const { productOrganisations } = this.props;
    const selectedOrCurrent = productOrganisations.filter(
      each => this.isCurrent(each) || this.isSelected(each)
    );
    const hasSelection = !selectedOrCurrent.isEmpty();

    const isDisabledBySelection = isLimitedToOne =>
      selectedOrCurrent.some(isLimitedToOne)
        ? !this.isSelected(productOrganisation)
        : isLimitedToOne(productOrganisation);
    return hasSelection && ONLY_SINGLE_ALLOWED.some(isDisabledBySelection);
  }

  getNumberAvailable() {
    const { productOrganisations, selectedProductOrganisations } = this.props;
    const numberAvailables = productOrganisations
      .filter(productOrganisation =>
        selectedProductOrganisations.includes(productOrganisation.get('id'))
      )
      .map(productOrganisation => productOrganisation.get('number_available'))
      .filter(numberAvailable => numberAvailable !== null);

    return numberAvailables.isEmpty() ? null : sum(numberAvailables);
  }

  render() {
    const {
      productOrganisations,
      response,
      selectedProductOrganisationsPulses,
    } = this.props;

    const selectedPulse = Array.from(selectedProductOrganisationsPulses);
    const products =
      productOrganisations &&
      Array.from(
        productOrganisations
          .filter(
            productOrganisation =>
              productOrganisation.get('number_available') !== 0
          )
          .map(productOrganisation => {
            return {
              id: productOrganisation.get('id'),
              icon: productOrganisation.getIn(['product', 'icon']),
              name:
                getName(productOrganisation) +
                getNumberAvailable(productOrganisation),
              selected:
                this.isSelected(productOrganisation) ||
                this.isCurrent(productOrganisation),
              optionalAddonLabel: this.isPulsingAvailable(productOrganisation)
                ? i18next.t('pulse check')
                : null,
              disabled:
                this.isCurrent(productOrganisation) ||
                this.isDisabled(productOrganisation) ||
                this.isDisabledBySelection ||
                this.props.selectedProductOrganisationsPulses.size > 0,
              optionalSelected: selectedPulse.includes(
                productOrganisation.get('id')
              ),
              optionalEnabled:
                this.isSelected(productOrganisation) ||
                this.isCurrent(productOrganisation),
              optionalDisabled: this.numberSelected() > 1,
            };
          })
      );

    if (!productOrganisations) {
      return <Loading />;
    }

    return (
      <div className="product-selector no-margin">
        <ProductSelector
          products={products}
          onSelection={id => this.toggleSelection(id)}
          onClickRowButton={id => this.togglePulsing(id)}
          title={i18next.t('Your products')}
          footnote={() => (
            <div>
              <Trans i18nKey="TRANS Other products are available to buy">
                Other products are available to buy{' '}
                <a href="#/page/shop" target="_blank">
                  TRANS Other products are available to buy
                </a>
                .
              </Trans>
            </div>
          )}
          confirmButtonLabel={
            isPending(response)
              ? i18next.t('Adding...')
              : i18next.t('Add to Activity')
          }
          onConfirm={() => this.addToActivity()}
          onClose={() => this.clearAndClose()}
        />

        {hasFailed(response) && (
          <div>
            {response.get('errors', List()).map(error => (
              <Alert type={AlertType.Error} key={error}>
                {error}
              </Alert>
            ))}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    response: state.responses.get('addProductOrganisations'),
    productOrganisations: state.collections.getIn([
      'productOrganisations',
      'items',
    ]),
    selectedProductOrganisationsPulses: state.ui
      .get('selectedUiComponents')
      .get('productOrganisationsAddPulse', Set()),
    selectedProductOrganisations: state.ui
      .get('selectedUiComponents')
      .get('productOrganisations', Set()),
  };
}

AddProductsModal.propTypes = {
  current: ImmutablePropTypes.list.isRequired,
  activityType: PropTypes.string.isRequired,
  onChooseProductOrganisations: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  response: ImmutablePropTypes.map.isRequired,
  productOrganisations: ImmutablePropTypes.list,
  selectedProductOrganisations: ImmutablePropTypes.set.isRequired,
  selectedProductOrganisationsPulses: ImmutablePropTypes.set.isRequired,
  getAllProductOrganisations: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
  organisation: ImmutablePropTypes.map.isRequired,
};

export default connect(mapStateToProps, {
  getAllProductOrganisations,
  toggleSelection,
  clearSelection,
})(AddProductsModal);
