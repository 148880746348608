import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { connect } from 'react-redux';

import { SURVEY_STATUS } from '^/models/user';
import { openInviteToStageTwoModal } from '^/actions/modals';
import { getActivityUsersCollectionName } from '^/actions/collections';
import PureComponent from '^/components/PureComponent';

const hasCompletedStageOne = activityUser =>
  activityUser.get('survey_status') === SURVEY_STATUS.CHOICES.COMPLETED_STAGE_1;

export class InviteToStageTwoButton extends PureComponent {
  openInviteToStageTwoModal() {
    const { activityId, activityUsers } = this.props;
    this.props.openInviteToStageTwoModal(
      activityId,
      activityUsers.filter(hasCompletedStageOne)
    );
  }

  render() {
    const { activityUsers } = this.props;
    return (
      <button
        className="btn btn-default btn-small mt-none"
        disabled={!activityUsers || !activityUsers.some(hasCompletedStageOne)}
        onClick={() => this.openInviteToStageTwoModal()}
      >
        {i18next.t('Invite to stage 2')}
      </button>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  const collectionName = getActivityUsersCollectionName(ownProps.activityId);
  return {
    activityUsers: state.collections.getIn([collectionName, 'items']),
  };
}

export default connect(mapStateToProps, { openInviteToStageTwoModal })(
  InviteToStageTwoButton
);
