import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';

import PulseIconCircleOutlinePurple from '^/components/pulses/PulseIconCircleOutlinePurple';

interface PulseCheckButtonProps {
  onClick?: () => void;
  title?: string;
  text: string;
  className?: string;
}

class PulseCheckButton extends React.PureComponent<PulseCheckButtonProps> {
  render() {
    const { onClick, title, text, className } = this.props;

    return (
      <button
        className={classNames('pulse-check-button', className)}
        onClick={onClick}
        title={title && i18next.t<string>(title)}
      >
        <div className="pulse-check-button-icon">
          <PulseIconCircleOutlinePurple />
        </div>
        <div className="pulse-check-button-text">
          <p>{i18next.t<string>(text)}</p>
        </div>
      </button>
    );
  }
}

export default PulseCheckButton;
