import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map } from 'immutable';

import { RouterParamProps, withRouter } from '^/withRouter';
import { Uuid, UserPulseRater } from '^/reducers/api/types';
import LiveButton from '^/components/LiveButton';
import {
  deletePulseRaterExternal,
  loadUserPulseRater,
} from '^/actions/actions';
import { StoreState } from '^/store';
import { isPending, hasFailed } from '^/responseStates';
import Loading from '^/components/Loading';
import { SUPPORT_EMAIL } from '^/settings';

interface DispatchProps {
  deletePulseRaterExternal: typeof deletePulseRaterExternal;
  loadUserPulseRater: typeof loadUserPulseRater;
}

interface StateProps {
  userPulseRaterId: Uuid;
  userPulseRater: UserPulseRater | null;
  deleteRaterResponse: Map<string, any>;
  userPulseRaterResponse: Map<string, any>;
}

interface State {
  cancelled: boolean;
}

type Props = DispatchProps &
  StateProps &
  RouterParamProps<{ userPulseRaterId: Uuid }, {}>;

export class RemoveRaterPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cancelled: false,
    };
  }

  public componentDidMount() {
    this.props.loadUserPulseRater(this.props.userPulseRaterId);
  }

  public render() {
    const {
      deleteRaterResponse,
      userPulseRaterResponse,
      userPulseRater,
    } = this.props;

    const userName = userPulseRater?.user_pulse.user.full_name;
    const orgLogo = userPulseRater?.user_pulse.user.organisation.brand_logo;
    const isDeleted = userPulseRater?.is_deleted;

    if (hasFailed(userPulseRaterResponse)) {
      return (
        <p className="text-error">
          {i18next.t<string>('Page not found, contact {{supportEmail}}', {
            supportEmail: SUPPORT_EMAIL,
          })}
        </p>
      );
    }

    if (!userPulseRater) {
      return <Loading />;
    }

    if (isDeleted) {
      return (
        <div className="pulse-bg">
          <div className="pulse pulse-rate">
            <div className="content">
              <p>
                {i18next.t<string>(
                  'You have been removed from Pulsing for {{respondentName}}.',
                  { respondentName: userName }
                )}
              </p>
              <div className="logos">
                <img src="/static/img/brand/enable-logo-stripe.png" />
                {orgLogo && <img src={orgLogo} />}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="pulse-bg">
        <div className="pulse pulse-rate">
          {this.state.cancelled ? (
            <div className="content">
              <p>
                {i18next.t<string>(
                  'Thank you for continuing with Pulsing for {{respondentName}}.',
                  { respondentName: userName }
                )}{' '}
                <a href={`/#/pulse/rate/${this.props.userPulseRaterId}`}>
                  {i18next.t<string>('Go here to pulse.')}
                </a>
              </p>
              <div className="logos">
                <img src="/static/img/brand/enable-logo-stripe.png" />
                {orgLogo && <img src={orgLogo} />}
              </div>
            </div>
          ) : (
            <div>
              <div className="content">
                <p>
                  {i18next.t<string>(
                    'Please confirm you are happy to remove yourself from Pulsing for {{respondentName}}.',
                    { respondentName: userName }
                  )}
                </p>
                <button
                  className="btn btn-secondary"
                  onClick={() => this.setState({ cancelled: true })}
                >
                  Cancel
                </button>
                <LiveButton
                  response={deleteRaterResponse}
                  buttonText={i18next.t('Confirm')}
                  onClick={() =>
                    this.props.deletePulseRaterExternal(
                      this.props.userPulseRaterId
                    )
                  }
                  pendingMessage={i18next.t('Removing from pulse...')}
                  disabled={isPending(deleteRaterResponse)}
                />
                <div className="logos">
                  <img src="/static/img/brand/enable-logo-stripe.png" />
                  {orgLogo && <img src={orgLogo} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: StoreState, props: any) {
  return {
    userPulseRaterId: props.router.params.userPulseRaterId,
    userPulseRater: state.userPulseRater,
    deleteRaterResponse: state.responses.get(
      'deletePulseRaterExternal',
      Map()
    ) as Map<string, any>,
    userPulseRaterResponse: state.responses.get(
      'loadUserPulseRater',
      Map()
    ) as Map<string, any>,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    deletePulseRaterExternal,
    loadUserPulseRater,
  })(RemoveRaterPage)
);
