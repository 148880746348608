import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'underscore';
import { debounce } from 'lodash';
import i18next from 'i18next';

import { getGroups, showMoreGroups } from '../../actions/collections';
import PureComponent from '../PureComponent';
import ControlBar from '../ControlBar';
import GroupRow from './GroupRow';
import ListPage from '../ListPage';
import Checkbox from '^/components/Checkbox';

export class GroupsListPage extends PureComponent {
  constructor(props) {
    super(props);
    this.limitedSearch = debounce(
      searchString => this.getGroups(searchString),
      500
    );
  }

  componentDidMount() {
    if (!this.props.noLoad) {
      this.props.getGroups(
        '',
        '',
        1,
        this.props.filterSpec,
        this.props.pageSize
      );
    }
  }

  getHeaders() {
    const { selectOne, hideAccounts } = this.props;
    const headers = [
      {
        title: i18next.t('Name'),
        field: 'name',
        className: 'group-name',
        sortable: true,
      },
    ];
    if (!selectOne) {
      headers.unshift({
        title: '',
        className: 'add-header',
        field: 'add-group',
      });
    }

    if (!hideAccounts) {
      headers.push({
        title: i18next.t('Accounts'),
        field: 'organisation_name',
        className: 'organisation-name',
        sortable: true,
      });
    }

    return headers;
  }

  getGroups(searchString) {
    return this.props.getGroups(
      '',
      searchString,
      1,
      this.props.filterSpec,
      this.props.pageSize
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.filterSpec, this.props.filterSpec)) {
      this.props.getGroups(
        '',
        this.props.groups.get('searchString', ''),
        1,
        nextProps.filterSpec,
        this.props.pageSize
      );
    }
  }

  showMore() {
    this.props.showMoreGroups(
      this.props.groups.get('searchString', ''),
      this.props.groups.get('page', 0) + 1,
      this.props.groups.get('filters'),
      this.props.pageSize,
      this.props.groups.get('ordering', '')
    );
  }

  getOrdering() {
    const ordering = this.props.groups.get('ordering', '');
    return {
      field: ordering.replace(/^-/, ''),
      reversed: ordering[0] === '-',
    };
  }

  setOrdering(newOrdering) {
    const reverseOrdering =
      newOrdering === this.props.groups.get('ordering', '');

    this.props.getGroups(
      '',
      this.props.groups.get('searchString'),
      1,
      this.props.groups.get('filters'),
      this.props.pageSize,
      reverseOrdering ? `-${newOrdering}` : newOrdering
    );
  }

  renderGroup(group) {
    return (
      <GroupRow
        key={group.get('id')}
        group={group}
        isSelectModal={this.props.isSelectModal}
        hideAccounts={this.props.hideAccounts}
        {...this.props.getGroupRowProps(group)}
      />
    );
  }

  onSearchChange(_field, event) {
    this.limitedSearch(event.target.value);
  }

  renderHeader(field) {
    if (field !== 'add-group') {
      return null;
    }
    const allGroupsSelected =
      !this.props.groups.get('items').isEmpty() &&
      this.props.groups.get('items').every(this.props.groupIsSelected);
    return (
      <Checkbox
        checked={allGroupsSelected}
        disabled={!!this.props.disableSelectAll}
        onChange={
          allGroupsSelected ? this.props.onClearAll : this.props.onSelectAll
        }
      />
    );
  }

  render() {
    const { groups, selectOne, hideControlBar } = this.props;
    const items = groups.get('items');
    const isFiltered =
      (groups.get('searchString') && groups.get('searchString') !== '') ||
      !_.isEmpty(this.props.filterSpec);

    return (
      <ListPage
        headers={this.getHeaders()}
        items={items}
        typeNamePlural={selectOne ? i18next.t('group') : i18next.t('groups')}
        isFiltered={isFiltered}
        hideBorder
        searchHeaders={'name'}
        hasMore={groups.get('hasMore')}
        showMore={this.showMore.bind(this)}
        isSelectModal={this.props.isSelectModal}
        listClassName="user-grid"
        tableClassName={this.props.tableClassName}
        response={this.props.response}
        renderer={this.renderGroup.bind(this)}
        onSearchChange={this.onSearchChange.bind(this)}
        onFilterChange={this.props.onFilterChange}
        ordering={this.getOrdering()}
        onOrderingChange={this.setOrdering.bind(this)}
        renderHeader={this.renderHeader.bind(this)}
        scrollable
      >
        {!hideControlBar && (
          <ControlBar
            hideAdd={this.props.isSelectModal}
            createButtonText="group"
            title={this.props.title}
            getItems={this.getGroups.bind(this)}
            {...this.props.controlBarProps}
          >
            {this.props.controlBarChildren}
          </ControlBar>
        )}
        {this.props.children}
      </ListPage>
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.get('getCollection'),
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps, {
    getGroups: ownProps.getGroups || dispatchProps.getGroups,
  });

const dispatchProps = {
  getGroups,
  showMoreGroups,
};

export default connect(
  mapStateToProps,
  dispatchProps,
  mergeProps
)(GroupsListPage);
