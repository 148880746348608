import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Select from 'react-select';
import i18next from 'i18next';
import _ from 'underscore';
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import { isPending } from '^/responseStates';
import {
  loadFilterType,
  selectFilterItem,
  clearFilterType,
} from '^/actions/datafilters';

export class DataFilterControl extends PureComponent {
  componentDidMount() {
    this.loadOptions('');
  }

  componentWillUnmount() {
    this.props.clearFilterType(this.props.filterId, this.props.filterType);
  }

  componentDidUpdate(prevProps) {
    const { response, filterData } = this.props;
    const sizeOf = data => data.get('results', List()).count();

    if (
      !isPending(response) &&
      !filterData.get('searchTerm') &&
      sizeOf(filterData) !== sizeOf(prevProps.filterData)
    ) {
      const options = this.formatOptions();
      if (options.length === 1) {
        this.onChange(options[0]);
      }
    }
  }

  formatExtraFilters() {
    if (this.props.prefilter) {
      return this.props.prefilterData
        .reduce((memo, filterData, filterKey) => {
          const selectedResult = filterData.get('selectedResult', Map());
          if (selectedResult.isEmpty() || selectedResult.get('isAll')) {
            return memo;
          }
          return memo.set(filterKey.toLowerCase(), selectedResult.get('value'));
        }, Map())
        .merge(this.props.extraFilters || Map());
    }
    return this.props.extraFilters || Map();
  }

  loadOptions(searchTerm) {
    this.props.loadFilterType(
      this.props.filterId,
      this.props.filterType,
      searchTerm,
      this.formatExtraFilters()
    );
  }

  onChange(value) {
    this.props.selectFilterItem(
      this.props.filterId,
      this.props.filterType,
      Map(value),
      value ? value.isAll : false
    );
    if (this.props.changeCallback) {
      this.props.changeCallback(value);
    }
  }

  formatOptions() {
    const { filterData, includeAll } = this.props;
    let options = filterData.get('results', List()).map(result => ({
      value: result.get('id'),
      key: result.get('type', null),
      label: result.get(this.props.resultValueLabel || 'name'),
      isAll: false,
    }));

    if (includeAll) {
      options = options.insert(0, {
        value: 'ALL',
        label: 'All',
        isAll: true,
      });
    }

    return options.toJS();
  }

  render() {
    const { response, filterData, placeholder } = this.props;
    const selectedResult = filterData.get('selectedResult', Map());
    return (
      <div>
        <Select
          isLoading={isPending(response)}
          searchable
          options={this.formatOptions()}
          value={selectedResult.isEmpty() ? undefined : selectedResult.toJS()}
          onInputChange={_.debounce(
            searchTerm => this.loadOptions(searchTerm),
            500
          )}
          onBlur={() => this.loadOptions('')} // clear the search filters on blur
          onChange={value => this.onChange(value)}
          placeholder={placeholder || i18next.t('Please select...')}
          loadingPlaceholder={i18next.t('Loading...')}
          noResultsText={isPending(response) ? 'Loading...' : 'No results'}
          classNamePrefix="react-select"
        />
      </div>
    );
  }
}

DataFilterControl.propTypes = {
  loadFilterType: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  filterId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  includeAll: PropTypes.bool,
  prefilter: PropTypes.bool,
  response: ImmutablePropTypes.map,
  filterData: ImmutablePropTypes.map.isRequired,
  prefilterData: ImmutablePropTypes.map.isRequired,
  extraFilters: ImmutablePropTypes.map,
  selectFilterItem: PropTypes.func.isRequired,
  resultValueLabel: PropTypes.string,
  clearFilterType: PropTypes.func.isRequired,
  changeCallback: PropTypes.func,
};

export function mapStateToProps(state, ownProps) {
  return {
    response: state.responses.get(ownProps.responseName),
    filterData: state.datafilters
      .get(ownProps.filterId, Map())
      .get(ownProps.filterType, Map()),
    prefilterData: ownProps.prefilter
      ? state.datafilters
          .get(ownProps.filterId, Map())
          .filter((value, filterType) => filterType !== ownProps.filterType)
      : Map(),
  };
}

export default connect(mapStateToProps, {
  loadFilterType,
  selectFilterItem,
  clearFilterType,
})(DataFilterControl);
