import { createSelector } from 'reselect';

import {
  makeReportCode,
  selectSelectedReportTemplatesReportsTable,
} from '^/components/reports/admin/selectors';
import { getEstimatedCreditCost } from '^/components/reports/utils';
import {
  selectOrgSessions,
  selectOrgUsers,
  selectReportsTableSelections,
} from '^/selectors';
import { isTruthy } from '^/utils';
import { selectionFromKey, SelectionState } from '^/reducers/reports/types';

export const selectSelectedSessions = createSelector(
  selectReportsTableSelections,
  reportsTableSelections =>
    Object.entries(reportsTableSelections.reportTemplates)
      .filter(entry => entry[1] === SelectionState.Selected)
      .map(entry => selectionFromKey(entry[0]).sessionId)
);

export const selectActivityReportTypes = createSelector(
  selectOrgSessions,
  orgSessions =>
    (orgSessions ? orgSessions.productorganisation_set : []).map(
      productOrganisation => ({
        report_templates: productOrganisation.productorganisationreporttemplate_set.map(
          orgReportTemplate => ({
            id: orgReportTemplate.report_template.id,
            cost:
              orgReportTemplate.credit_cost ||
              orgReportTemplate.report_template.credit_cost,
            requires_job_level:
              orgReportTemplate.report_template.requires_job_level,
            template_code_display: makeReportCode(
              productOrganisation.product,
              orgReportTemplate.report_template,
              productOrganisation.product_variant
            ),
            name: orgReportTemplate.report_template.name,
          })
        ),
        comparison_groups: productOrganisation.comparison_groups,
        product: {
          id: productOrganisation.product.id,
          name: productOrganisation.product.name,
          icon: productOrganisation.product.icon,
          activity_type: productOrganisation.product.activity_type,
        },
        product_variant: productOrganisation.product_variant
          ? productOrganisation.product_variant.id
          : null,
        productorganisationreporttemplate_set: productOrganisation.productorganisationreporttemplate_set.map(
          rt => ({
            report_template: rt.report_template.id,
            credit_cost: rt.credit_cost,
          })
        ),
      })
    )
);

const selectOrgUserNamesById = createSelector(selectOrgUsers, orgUsers =>
  (orgUsers ? orgUsers.users : []).reduce(
    (acc: { [key: string]: string }, each) => ({
      ...acc,
      [each.id]: each.full_name,
    }),
    {}
  )
);

export const selectActivityUsers = createSelector(
  selectOrgSessions,
  selectOrgUserNamesById,
  (orgSessions, orgUserNamesById) =>
    (orgSessions ? orgSessions.activities : []).flatMap(activity =>
      activity.activity_product_version_items.flatMap(apv =>
        apv.activity_product_version_sessions.map(session => ({
          user_id: session.user,
          product_id: apv.product_version.product,
          has_comparison_group: session.has_comparison_group,
          respondent: orgUserNamesById[session.user],
          session_id: session.id,
        }))
      )
    )
);

export const selectExistingValues = createSelector(
  selectSelectedReportTemplatesReportsTable,
  selectedReportTemplatesReportsTable =>
    selectedReportTemplatesReportsTable.map(each => ({
      user: each.userId,
      session: each.sessionId,
      reportTeamId: each.reportTeamId,
      product: each.productId,
      productVariant: each.productVariantId,
      reportTemplateId: each.reportTemplateId,
    }))
);

export const selectReports = createSelector(
  selectOrgSessions,
  selectOrgUserNamesById,
  (orgSessions, orgUserNamesById) =>
    (orgSessions ? orgSessions.activities : []).flatMap(activity =>
      activity.activity_product_version_items.flatMap(apv =>
        apv.activity_product_version_sessions.flatMap(session =>
          session.reports.map(report => ({
            id: report.id,
            template_id: report.template,
            product: apv.product_version.product,
            user: {
              full_name: orgUserNamesById[session.user],
            },
          }))
        )
      )
    )
);

export const selectReportGenerationTotalCost = createSelector(
  selectActivityReportTypes,
  selectExistingValues,
  (activityReportTypes, existingValues) =>
    getEstimatedCreditCost(existingValues, activityReportTypes)
);

const selectSelectedReportTemplateIdSet = createSelector(
  selectSelectedReportTemplatesReportsTable,
  selectedReportTemplatesReportsTable =>
    new Set(
      selectedReportTemplatesReportsTable.map(each => each.reportTemplateId)
    )
);

const selectSelectedProducts = createSelector(
  selectSelectedReportTemplateIdSet,
  selectActivityReportTypes,
  (selectedReportTemplateIdSet, activityReportTypes) =>
    activityReportTypes.filter(each =>
      each.report_templates.some(template =>
        selectedReportTemplateIdSet.has(template.id)
      )
    )
);

const selectSelectedReports = createSelector(
  selectSelectedReportTemplateIdSet,
  selectActivityReportTypes,
  (selectedReportTemplateIdSet, activityReportTypes) =>
    activityReportTypes
      .flatMap(each => each.report_templates)
      .filter(template => selectedReportTemplateIdSet.has(template.id))
);

const selectShouldRequireJobLevel = createSelector(
  selectSelectedReports,
  selectedReports => selectedReports.some(report => report.requires_job_level)
);

export const selectReportGenerationFields = createSelector(
  selectSelectedProducts,
  selectShouldRequireJobLevel,
  (selectedProducts, requireJobLevel) => {
    const compGroupFields = selectedProducts.map(
      each => `comparisonGroup_${each.product.id}`
    );

    return requireJobLevel ? [...compGroupFields, 'jobLevel'] : compGroupFields;
  }
);

export const selectProductsInfo = createSelector(
  selectActivityReportTypes,
  selectActivityUsers,
  selectExistingValues,
  selectSelectedSessions,
  (activityReportTypes, activityUsers, existingValues, selectedSessions) =>
    activityReportTypes
      .filter(productOrg => productOrg.product_variant === null)
      .reduce((acc, productOrg) => {
        const productReportTemplateIds = productOrg.report_templates.map(
          template => template.id
        );
        const users = activityUsers
          .filter(activityUser =>
            selectedSessions.includes(activityUser.session_id)
          )
          .filter(activityUser =>
            existingValues.some(
              item =>
                item.user === activityUser.user_id &&
                productReportTemplateIds.includes(item.reportTemplateId)
            )
          )
          .filter(
            activityUser => activityUser.product_id === productOrg.product.id
          );
        return {
          ...acc,
          [productOrg.product.id]: {
            onlyOneCompGroup: productOrg.comparison_groups.length <= 1,
            allUsersHaveCompGroup: users.every(
              user => user.has_comparison_group
            ),
            someUsersHaveCompGroup: users.some(
              user => user.has_comparison_group
            ),
          },
        };
      }, {})
);

export const selectRespondentNames = createSelector(
  selectActivityUsers,
  selectExistingValues,
  (activityUsers, existingValues) => {
    const userNames = existingValues
      .map(item =>
        activityUsers.find(activityUser => activityUser.user_id === item.user)
      )
      .filter(isTruthy)
      .map(activityUser => activityUser!.respondent);

    return [...new Set(userNames)];
  }
);

export const selectAllSelectedReportTemplateSet = createSelector(
  selectExistingValues,
  existingValues => {
    const selectedUnpurchasedReportTemplates = existingValues.map(user => ({
      reportTemplateId: user.reportTemplateId,
      productId: user.product,
    }));
    return new Set(selectedUnpurchasedReportTemplates);
  }
);
