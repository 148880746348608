import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { makeChoices } from '^/utils-ts';
import PureComponent from '^/components/PureComponent';
import RadioToggle from '^/components/RadioToggle';

export const REPORT_GENERATION = makeChoices([
  ['MANUAL', i18next.t('Manual')],
  ['AUTO', i18next.t('Auto')],
]);

export default class ReportGenerationToggle extends PureComponent {
  render() {
    const {
      is360,
      reportGeneration,
      reportTypeResponse,
      onChange,
      readOnly,
      disabledMessage,
    } = this.props;

    const popoverMessages = {
      [REPORT_GENERATION.CHOICES.MANUAL]: i18next.t(
        'Generate and pay for reports as you go.'
      ),
      [REPORT_GENERATION.CHOICES.AUTO]: is360
        ? i18next.t(
            'Generate and pay for reports automatically when the activity closes.'
          )
        : i18next.t(
            'Generate and pay for reports automatically when respondents complete assessments.'
          ),
    };

    return (
      <div className="form-item wrapping">
        <RadioToggle
          choices={REPORT_GENERATION}
          popoverMessages={popoverMessages}
          selected={reportGeneration}
          loading={isPending(reportTypeResponse)}
          readOnly={readOnly}
          disabledMessage={disabledMessage}
          onChange={onChange}
        />
      </div>
    );
  }
}
