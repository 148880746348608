import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';

interface OwnProps {
  text: string;
  onConfirmFunction: () => void;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

type Props = OwnProps & DispatchProps;

export class ConfirmRemoveModal extends React.PureComponent<Props> {
  private closeAndConfirm = () => {
    this.props.closeTopModal();
    this.props.onConfirmFunction();
  };

  public render() {
    return (
      <div>
        <div>
          <p>{this.props.text}</p>
        </div>
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn"
              id="back"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Back')}
            </button>
            <button
              className="btn btn-primary"
              id="confirm"
              onClick={this.closeAndConfirm}
            >
              {i18next.t<string>('Confirm')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { closeTopModal })(ConfirmRemoveModal);
