import _ from 'underscore';
import { List } from 'immutable';
import moment from 'moment';
import { isRSAA } from 'redux-api-middleware';
import { isFSA } from 'flux-standard-action';
export { capitalize, getIn } from './utils-ts';
import i18next from 'i18next';

export const FORMAT_DATE = 'Do MMM, YYYY';
const FORMAT_TIME = 'HH:mm';
const FORMAT_DATE_TIME = FORMAT_DATE.concat(' [at] ').concat(FORMAT_TIME);
const FORMAT_DATE_FILTER = 'YYYY-MM-DD';

export const TOAST_TIMEOUT_MS = 4000;

export function pluralize(count, singular, plural, includeCount = true) {
  count = parseFloat(count);

  if (isNaN(count)) {
    throw new Error(
      'The count provided for pluralize function was not a number'
    );
  }

  if (!singular || typeof singular === 'object') {
    throw new Error(
      'Singular provided for pluralize function was empty or incorrect type'
    );
  }

  plural = plural || singular.toString().concat('s');

  if (includeCount) {
    if (!count) {
      return ['No', plural].join(' ');
    } else if (count === 1) {
      return [count, singular].join(' ');
    }
    return [count, plural].join(' ');
  }
  if (count === 1) {
    return singular;
  }
  return plural;
}

// Convert { foo: [1, 2] } to [['foo', 1], ['foo', 2]]
export function groupedListsToProplist(data) {
  return _.chain(data)
    .pairs()
    .map(([key, valueOrValues]) =>
      _.isArray(valueOrValues)
        ? valueOrValues.map(each => [key, each])
        : [[key, valueOrValues]]
    )
    .flatten(true)
    .value();
}

export function createdByOnDate(userName, date) {
  if (!userName && !date) {
    return '';
  }

  if (!userName) {
    return i18next.t('Created on {{formatDate}}', {
      formatDate: moment(date).format(FORMAT_DATE),
    });
  }

  if (!date) {
    return i18next.t('Created by {{userName}}', { userName });
  }

  return i18next.t('Created by {{userName}} on {{formatDate}}', {
    userName,
    formatDate: moment(date).format(FORMAT_DATE),
  });
}

export function formatDateTime(date, noDate) {
  if (!date) {
    return noDate || i18next.t('unknown');
  }
  return moment(date).format(FORMAT_DATE_TIME);
}

export const CURRENCIES = {
  GBP: { symbol: '£', displayFactor: 100 },
};

export function formatStripeCreditCostToDisplay(creditCost) {
  const { cost, currency } = creditCost.toObject();
  return (cost / CURRENCIES[currency]['displayFactor']).toFixed(2);
}

export function formatDisplayCreditCostToStripe(creditCost) {
  const { cost, currency } = creditCost.toObject();
  return (cost * CURRENCIES[currency]['displayFactor']).toFixed(0);
}

export function formatDate(date) {
  return moment(date).format(FORMAT_DATE);
}

export function formatDateFilter(date) {
  return moment(date).format(FORMAT_DATE_FILTER);
}

export function formatTime(date) {
  return moment(date).format(FORMAT_TIME);
}

export function padTens(number) {
  return (number < 10 ? '0' : '') + number;
}

export function compare(a, b) {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
}

export function sort(items, transform, ascending) {
  return items.sort(
    (item1, item2) =>
      compare(transform(item1), transform(item2)) * (ascending ? 1 : -1)
  );
}

export function getProductVersionNameFromQuestionType(questionType) {
  switch (questionType) {
    case 'THREE_SIXTY':
      return '360°';
    case 'POSITIVE_RESILIENCE_PROFILER':
      return 'Positive Resilience Profiler';
    case 'PSYCAP_POTENTIAL':
      return 'Psycap Potential';
    default:
      return questionType;
  }
}

export function toQueryString(data) {
  return data
    ? groupedListsToProplist(data)
        .filter(([key, value]) => key && value)
        .map(pair => pair.map(encodeURIComponent))
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    : '';
}

export function fromQueryString(queryString) {
  return queryString
    ? _.object(
        queryString
          .split('&')
          .map(keyValue => keyValue.split('=').map(decodeURIComponent))
      )
    : {};
}

// Append these to datetime fields to add comparisons to a filter
export const COMPARATOR_SUFFIXES = {
  before: '_before',
  after: '_after',
  on: '_on',
};

export function momentRangeBounded(range, value) {
  const { start, end } = _.mapObject(range, each => each && moment(each));

  if (start && value.isBefore(start)) {
    return start;
  }
  if (end && value.isAfter(end)) {
    return end;
  }

  return value;
}

export function toProperCase(string) {
  if (!string || !string.length) {
    return '';
  }

  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function constantToProperCase(string) {
  return toProperCase(string.replace(/_/gi, ' '));
}

export function toTitleCase(string) {
  if (!string || !string.length) {
    return '';
  }
  const items = string.split(' ');
  return _.map(items, s => toProperCase(s)).join(' ');
}

export function startsWith(haystack, needle) {
  return haystack.lastIndexOf(needle, 0) === 0;
}

export function isAction(obj) {
  return isRSAA(obj) || isFSA(obj);
}

export function shuffleImmutableList(immList) {
  const indexes = _.range(immList.count());
  const shuffledIndexes = _.shuffle(indexes);
  return List(shuffledIndexes).map(i => immList.get(i));
}

export function interpolate(template, context) {
  return _.chain(context)
    .pairs()
    .reduce(
      (lastTemplate, [label, replacement]) =>
        lastTemplate.replace(new RegExp(`\\[${label}\\]`, 'g'), replacement),
      template
    )
    .value();
}

export function getBodyScrollTop() {
  return (
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop
  );
}

export function getBodyScrollHeight() {
  return (
    (document.documentElement && document.documentElement.scrollHeight) ||
    document.body.scrollHeight
  );
}

export function rangeBounded(value, max) {
  return (value + max) % max;
}

// convert from a sortBy field into an ordering string for an endpoint
export function asOrdering(sortBy) {
  const { field, reversed } = sortBy;
  const fieldString = Array.isArray(field) ? field.join('__') : field || '';
  return reversed ? `-${fieldString}` : fieldString;
}

const matchesDateFormat = (format, formatRegExp, dateString) =>
  formatRegExp.test(dateString) && moment(dateString, format).isValid();

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_FORMAT_RE = /^\d{4}-\d{2}-\d{2}$/;
export function matchesIsoFormat(dateString) {
  return matchesDateFormat(ISO_DATE_FORMAT, ISO_DATE_FORMAT_RE, dateString);
}
export const UK_DATE_FORMAT = 'DD/MM/YYYY';
export const UK_DATE_FORMAT_RE = /^\d{2}\/\d{2}\/\d{4}$/;
export function matchesUkDateFormat(dateString) {
  return matchesDateFormat(UK_DATE_FORMAT, UK_DATE_FORMAT_RE, dateString);
}

export const withPreventDefault = callback => evt => {
  evt.preventDefault();
  callback(evt);
};

export const withStopPropagation = callback => evt => {
  evt.stopPropagation();
  callback(evt);
};

export function isInt(value) {
  if (isNaN(value)) {
    return false;
  }
  const number = parseFloat(value);
  return (number | 0) === number;
}

export const sum = items => items.reduce((acc, item) => acc + item, 0);

export function findFirstMatch(items, matchFunc) {
  for (let i = 0; i < items.length; i += 1) {
    const val = matchFunc(items[i]);
    if (val) {
      return val;
    }
  }
}

// Not comprehensive: errs on the side of generosity
const EMAIL_REGEX = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/;
export const isEmail = string =>
  Boolean(string && string.toUpperCase().match(EMAIL_REGEX));

export const allFieldsMatch = (obj1, obj2, fields) =>
  fields.every(field => _.isEqual(obj1[field], obj2[field]));

export const isTruthy = value => value;
export const isFalsy = value => !value;

export function logError(msg, data) {
  window.Raven.setExtraContext(data);
  window.Raven.captureMessage(msg);
}

export function deepCopy(item) {
  return JSON.parse(JSON.stringify(item));
}
