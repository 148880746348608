import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';

import { getAllMyActivities } from '../../../actions/collections';
import PureComponent from '../../PureComponent';
import Loading from '../../Loading';
import ControlBar from '../../ControlBar';
import { isAwaitingApproval } from '../../../approvalStatus';
import { isLineManager } from '../../../rater';
import { withRouter } from '^/withRouter';

class ApproveRatersPage extends PureComponent {
  componentDidMount() {
    if (!this.getActivity()) {
      this.props.getAllMyActivities();
    }
  }

  getActivity() {
    const activities = this.props.activities.get('items', List());

    return activities.find(
      activity => activity.get('id') === this.props.activityId
    );
  }

  navigateToUserRaters(user) {
    const path = `/page/dashboard/raters/approve/${
      this.props.activityId
    }/${user.get('id')}`;
    this.props.push(path);
  }

  navigateToDashboard() {
    this.props.push(`/page/dashboard/`);
  }

  getActionLabel(ratersApprovalCount) {
    if (ratersApprovalCount >= 1) {
      return ratersApprovalCount === 1
        ? i18next.t('rater awaiting approval')
        : i18next.t('raters awaiting approval');
    }
    return i18next.t('no action required');
  }

  renderUsers(users) {
    return (
      <div className="col-xs-12">
        <div className="row">
          <table>
            <tbody>
              {users.map((activityUser, idx) => {
                const ratersAwaitingApproval = activityUser
                  .get('raters', List())
                  .filterNot(isLineManager)
                  .filter(isAwaitingApproval);
                const user = activityUser.get('user');
                const ratersApprovalCount = ratersAwaitingApproval.size;
                return (
                  <tr key={idx}>
                    <td>
                      {user.get('full_name')} (
                      <a href={`mailto:${user.get('email')}`}>
                        {user.get('email')}
                      </a>
                      )
                    </td>
                    <td>
                      <a onClick={() => this.navigateToUserRaters(user)}>
                        {this.getActionLabel(ratersApprovalCount)}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row pull-left">
          <button
            className="btn btn-primary"
            onClick={() => this.navigateToDashboard()}
          >
            {i18next.t('Back')}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const activity = this.getActivity();

    return (
      <div>
        <ControlBar title={i18next.t('Approve raters')} hideAdd hideSearch />

        {activity ? (
          this.renderUsers(activity.get('direct_reports'))
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activityId: props.router.params.activity,
    activities: state.collections.get('myActivities', Map()),
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllMyActivities, push })(ApproveRatersPage)
);
