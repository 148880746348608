import React from 'react';

export default () => (
  <svg width="59" height="59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.5 0C13.411 0 .375 13.036.375 29.125S13.411 58.25 29.5 58.25s29.125-13.036 29.125-29.125S45.589 0 29.5 0Zm14.913 21.074L25.69 39.879h-.014c-.238.238-.882.77-1.624.77-.532 0-1.134-.294-1.638-.798l-7.842-7.841a.558.558 0 0 1 0-.799l2.493-2.492a.553.553 0 0 1 .392-.168c.14 0 .28.056.392.168l6.217 6.217L41.15 17.727a.553.553 0 0 1 .392-.168c.154 0 .294.056.392.168l2.45 2.535c.253.238.253.588.029.812Z"
      fill="#8DC63F"
    />
  </svg>
);
