import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { push } from 'react-router-redux';
import { isEqual } from 'underscore';

import PureComponent from '^/components/PureComponent';
import ControlBar from '^/components/ControlBar';
import CreditDatePicker from '^/components/credits/CreditDatePicker';
import { getAccountTypeDisplay } from '^/models/organisation';
import CreditTopLinks from '^/components/credits/CreditTopLinks';
import Loading from '^/components/Loading';
import ListPage from '^/components/ListPage';
import ListPagePagination from '^/components/ListPagePagination';
import {
  sortList,
  setPageSize,
  PAGE_SIZE_OPTIONS,
  setFilters,
} from '^/actions/actions';
import { getCreditHistory } from '^/actions/collections';
import { asOrdering } from '^/utils';
import { selectFirstTransactionDate } from './selectors';
import {
  selectGetCollection,
  selectSortOrder,
  selectCollection,
} from '^/selectors';
import { selectFilterSpec, selectPageSize } from '^/selectors/ui';
import SearchBar from '^/components/Searchbar';

const HEADERS = [
  {
    title: i18next.t('Account'),
    field: ['name'],
    sortable: true,
  },
  {
    title: i18next.t('Account type'),
    field: ['account_type'],
  },
  {
    title: i18next.t('Credits purchased'),
    field: ['purchased'],
  },
  {
    title: i18next.t('Credits spent'),
    field: ['spent'],
  },
  {
    title: i18next.t('Available credits'),
    field: ['available'],
  },
];

export class CreditHistory extends PureComponent {
  UNSAFE_componentWillMount() {
    this.loadCreditHistoryPage(1);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.sortBy, this.props.sortBy) ||
      !isEqual(nextProps.filters, this.props.filters) ||
      !isEqual(nextProps.pageSize, this.props.pageSize)
    ) {
      this.loadCreditHistoryPage(1, nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.loadCreditHistoryPage(1, this.props);
    }
  }

  loadCreditHistoryPage(page, props) {
    const { filters, sortBy, pageSize } = props || this.props;

    this.props.getCreditHistory(
      '',
      page,
      filters,
      pageSize,
      asOrdering(sortBy)
    );
  }

  renderOrgRow(eachOrg) {
    return (
      <tr key={eachOrg.get('id')}>
        <td>
          <Link to={`/page/credits/${eachOrg.get('id')}/`}>
            {eachOrg.get('name')}
          </Link>
        </td>
        <td>{getAccountTypeDisplay(eachOrg)}</td>
        <td>{eachOrg.get('credits_purchased')}</td>
        <td>{eachOrg.get('credits_spent')}</td>
        <td>{eachOrg.get('available_credit')}</td>
      </tr>
    );
  }

  sortBy(field) {
    const { sortBy } = this.props;
    this.props.sortList('organisations', {
      field,
      reversed: isEqual(sortBy.field, field) ? !sortBy.reversed : false,
    });
  }

  render() {
    const {
      creditHistory,
      response,
      sortBy,
      pageSize,
      filters,
      firstTransactionDate,
    } = this.props;

    return (
      <div>
        {!creditHistory ? (
          <Loading className="list-loading" />
        ) : (
          <div>
            <ControlBar title={i18next.t('Credits')} hideSearch hideAdd>
              <CreditTopLinks />
              <SearchBar onChange={this.props.onSearchChange} />
            </ControlBar>

            <CreditDatePicker
              firstTransactionDate={firstTransactionDate}
              filters={filters}
            />

            <ListPagePagination
              pageSize={pageSize}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              collection={creditHistory}
              onSelectPage={page => this.loadCreditHistoryPage(page)}
              onChangePageSize={this.props.setPageSize}
            />
            <ListPage
              typeNamePlural="credits"
              items={creditHistory.get('items')}
              headers={HEADERS}
              tableClassName="credit-history table-default"
              hideBorder
              filters={[]}
              renderer={this.renderOrgRow.bind(this)}
              ordering={sortBy}
              onOrderingChange={this.sortBy.bind(this)}
              response={response}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    creditHistory: selectCollection(state, 'creditHistory'),
    sortBy: selectSortOrder(state, 'organisations'),
    response: selectGetCollection(state, 'creditHistory'),
    filters: selectFilterSpec(state),
    pageSize: selectPageSize(state),
    firstTransactionDate: selectFirstTransactionDate(state),
  };
}

export default connect(mapStateToProps, {
  push,
  getCreditHistory,
  sortList,
  setPageSize,
  setFilters,
})(CreditHistory);
