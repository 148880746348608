import i18next from 'i18next';
import { range } from 'underscore';

import { sum } from '^/utils-ts';

export const productVersionQuestionProgress = (
  pageIndex: number,
  pageSize: number,
  listSize: number
) =>
  i18next.t<string>('Questions {{start}} - {{end}} of {{total}}', {
    start: pageIndex * pageSize + 1,
    end: Math.min(listSize, (pageIndex + 1) * pageSize),
    total: listSize,
  });

interface Chapter {
  pageSize: number;
  listSize: number;
}

const chapterToPages = (chapter: Chapter) => {
  const pagesExceptLast = range(
    Math.floor(chapter.listSize / chapter.pageSize)
  ).fill(chapter.pageSize);

  const lastPage = chapter.listSize % chapter.pageSize;
  return pagesExceptLast.concat(lastPage ? [lastPage] : []);
};

/**
 * Calculates the current page start, page end and position for the current "chapter"
 * where each "chapter" can have a different amount of items on each page.
 * @param pageIndex the current index of the page (starting at 0)
 * @param chapters array of
 * ```typescript
 *  {
 *    pageSize: number, // amount of items on each page
 *    listSize: number  // total items in the chapter
 *  }
 * ```
 */
export const multiProductVersionQuestionProgress = (
  pageIndex: number,
  ...chapters: ReadonlyArray<Chapter>
) => {
  const pageLengths = chapters.flatMap(chapterToPages);
  const pageStart = sum(pageLengths.slice(0, pageIndex));

  return i18next.t<string>('Questions {{start}} - {{end}} of {{total}}', {
    start: pageStart + 1,
    end: pageStart + pageLengths[pageIndex],
    total: sum(chapters, chapter => chapter.listSize),
  });
};
