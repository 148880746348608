import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { LanguageCode } from '^/constants/routes';
import { InviteDefaults } from '^/reducers/api/types';
import { StoreState } from '^/store';
import { getInviteDefaults } from '../../actions/actions';
import { inviteUserAndCloseModal } from '../../actions/actionSequences';
import Loading from '../Loading';
import PureComponent from '../PureComponent';
import InviteUserForm from './InviteUserForm';
import { InviteUserFormProps } from './InviteUserFormSegment';

interface DispatchProps {
  getInviteDefaults: typeof getInviteDefaults;
  closeTopModal: typeof closeTopModal;
  inviteUserAndCloseModal: typeof inviteUserAndCloseModal;
}

interface OwnProps {
  userId: string;
  organisationId: string;
  invitationEmail: string;
  userName: string;
  isReinvite?: boolean;
}

interface StateProps {
  inviteDefaults: InviteDefaults;
  response: Map<string, any>;
  inputTextLanguage: LanguageCode;
}

type Props = DispatchProps & OwnProps & StateProps;

export class InviteUserModal extends PureComponent<Props> {
  public UNSAFE_componentWillMount() {
    this.props.getInviteDefaults(this.props.organisationId);
  }

  public inviteUser = (data: InviteUserFormProps) => {
    this.props.inviteUserAndCloseModal(
      this.props.userId,
      this.props.invitationEmail,
      data.opening,
      data.closing,
      this.props.inputTextLanguage
    );
  };

  public render() {
    if (!this.props.inviteDefaults) {
      return <Loading />;
    }
    return (
      <InviteUserForm
        response={this.props.response}
        onSubmit={this.inviteUser}
        onClose={this.props.closeTopModal}
        isReinvite={this.props.isReinvite}
        userName={this.props.userName}
      />
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    response: state.responses.get('inviteUser'),
    inviteDefaults: state.inviteDefaults,
    inputTextLanguage: state.ui.get('inputTextLanguage'),
  };
}

export default connect(mapStateToProps, {
  getInviteDefaults,
  closeTopModal,
  inviteUserAndCloseModal,
})(InviteUserModal);
