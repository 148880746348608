import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';

import Table from '^/components/Table';
import PureComponent from '^/components/PureComponent';
import { getCreditCosts } from '^/actions/collections';
import { isPending } from '^/responseStates';
import Loading from '^/components/Loading';
import { openEditCreditCostModal } from '^/actions/modals';
import {
  formatDateTime,
  formatStripeCreditCostToDisplay,
  CURRENCIES,
} from '^/utils';

export class EditableCreditCost extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.getCreditCosts();
  }

  renderEditButton(creditCost) {
    return (
      <div>
        <a
          className="fa-link"
          title="edit"
          onClick={() => this.props.openEditCreditCostModal(creditCost)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </a>
      </div>
    );
  }

  render() {
    const { creditCosts, getCreditCostsResponse } = this.props;
    if (isPending(getCreditCostsResponse) || !creditCosts) {
      return <Loading />;
    }
    return (
      <div>
        <Table
          className="data-table"
          columns={[
            {
              header: i18next.t('Global credit cost'),
              value: () => i18next.t('Global credit cost'),
              className: 'half-width',
            },
            {
              header: i18next.t('Last updated'),
              value: creditCost => formatDateTime(creditCost.get('modified')),
              className: 'quarter-width',
            },
            {
              header: i18next.t('Cost'),
              value: creditCost =>
                `${
                  CURRENCIES[creditCost.get('currency')]['symbol']
                }${formatStripeCreditCostToDisplay(creditCost)}`,
              className: 'quarter-width',
            },
            {
              header: i18next.t('Action'),
              value: creditCost => this.renderEditButton(creditCost),
              className: 'nowrap',
            },
          ]}
          rows={creditCosts}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    getCreditCostsResponse: state.multiResponses.getIn([
      'getCollection',
      'creditCosts',
    ]),
    creditCosts: state.collections.getIn(['creditCosts', 'items']),
  };
}

export default connect(mapStateToProps, {
  getCreditCosts,
  openEditCreditCostModal,
})(EditableCreditCost);
