import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import ImmutableProptypes from 'react-immutable-proptypes';

import PureComponent from '../PureComponent';
import { deleteGroup } from '^/actions/collections';
import { loadGroup, clearItem } from '^/actions/items';
import Tab from '^/components/ContentTab';

export class GroupsListItem extends PureComponent {
  selectGroup() {
    this.props.loadGroup(this.props.group.get('id'));
  }

  render() {
    const { group, active, organisation } = this.props;
    return (
      <Tab
        key={group.get('id')}
        tabName={group.get('name')}
        tabSubheading={!organisation ? group.get('organisation_name') : ''}
        onSelect={event => this.selectGroup(event)}
        active={active}
      />
    );
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    active: ownProps.group.get('id') === state.items.getIn(['groups', 'id']),
  };
}

export default connect(mapStateToProps, {
  deleteGroup,
  loadGroup,
  clearItem,
})(GroupsListItem);

GroupsListItem.propTypes = {
  group: ImmutableProptypes.map,
  organisation: ImmutableProptypes.map,
};
