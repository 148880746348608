/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import _ from 'underscore';
import { Map, List } from 'immutable';
import { Formik, Form, Field } from 'formik';

import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import {
  JOB_LEVELS,
  findProductOrganisation,
  getReportCost,
  getAutoReportGeneration,
  getNeedsComparisonGroup,
  getName,
} from '^/models/product';
import FormError from '^/components/FormError';
import CloseModalButton from '^/components/modals/CloseModalButton';
import LiveButton from '^/components/LiveButton';
import ProductVersionNameAndLogo from '^/components/productVersions/ProductVersionNameAndLogo';
import FormItem from '^/components/forms/FormItem';
import Checkbox from '^/components/Checkbox';
import Alert from '../Alert';

function _getFieldsAndInitialValues(activityProductVersion, organisation) {
  const fieldsAndInitialValues = {
    report_templates: {},
    job_levels: {},
  };

  // Create the comparison group key and set the value to empty string if the product version needs a comparison group
  const needsComparisonGroup = getNeedsComparisonGroup(activityProductVersion);
  if (needsComparisonGroup) {
    fieldsAndInitialValues.comparison_group = '';
  }

  // Get the report templates for the product version
  const productReportTemplates = activityProductVersion.getIn([
    'product_version',
    'product',
    'report_templates',
  ]);
  const productVariant = activityProductVersion.get('product_variant');
  const reportTemplates = productVariant
    ? productReportTemplates.filter(template =>
        productVariant.get('report_templates').includes(template.get('id'))
      )
    : productReportTemplates;

  const autoReportGeneration =
    getAutoReportGeneration(activityProductVersion) || Map();
  const autoReportTemplateIds = autoReportGeneration
    .get('report_templates', List())
    .toArray();

  reportTemplates.forEach(reportTemplate => {
    // Set report template value true if template found in auto report generation
    const reportId = reportTemplate.get('id');
    fieldsAndInitialValues.report_templates[
      reportId
    ] = autoReportTemplateIds.includes(reportId);

    // If the report template requires job level, add the job level key and get the value from the auto report generation
    if (reportTemplate.get('requires_job_level')) {
      fieldsAndInitialValues.job_levels[reportId] = autoReportGeneration.get(
        'job_level'
      );
    }
  });

  return fieldsAndInitialValues;
}

export function getFieldsAndInitialValues(
  activityProductVersions,
  organisation
) {
  return {
    activity_product_versions: _.object(
      activityProductVersions
        .map(activityProductVersion => [
          activityProductVersion.get('id'),
          _getFieldsAndInitialValues(activityProductVersion, organisation),
        ])
        .toArray()
    ),
  };
}

export function getProductOrganisation(organisation, activityProductVersion) {
  return findProductOrganisation(
    organisation,
    activityProductVersion.getIn(['product_version', 'product', 'id']),
    activityProductVersion.getIn(['product_variant', 'id'], null)
  );
}

export class ProductVersionReportGeneration extends PureComponent {
  render() {
    const {
      setFieldValue,
      values,
      response,
      activityProductVersion,
      organisation,
    } = this.props;
    const productVersion = activityProductVersion.get('product_version');
    const productVariant = activityProductVersion.get('product_variant');
    const productOrganisation = getProductOrganisation(
      organisation,
      activityProductVersion
    );

    const { report_templates, comparison_groups } = (
      productVariant || productOrganisation
    ).toObject();

    const reportTemplates = productVersion
      .getIn(['product', 'report_templates'])
      .filterNot(each => each.get('is_team'))
      .filter(each => report_templates.contains(each.get('id')));
    const comparisonGroups = productVersion
      .get('comparison_groups')
      .filter(
        each =>
          comparison_groups &&
          comparison_groups.contains(each.getIn(['entity', 'id']))
      );

    const showJobLevel = reportTemplates.some(report =>
      report.get('requires_job_level')
    );
    const needsComparisonGroup = getNeedsComparisonGroup(
      activityProductVersion
    );

    return (
      <div>
        <h5 className="heading-underlined">
          <ProductVersionNameAndLogo
            icon={productVersion.getIn(['product', 'icon'])}
            name={getName(activityProductVersion)}
          />
        </h5>

        <div className="form-inline">
          {needsComparisonGroup && (
            <FormItem label={i18next.t('Comparison group')}>
              <Field
                as="select"
                name={`activity_product_versions.${activityProductVersion.get(
                  'id'
                )}.comparison_group`}
                className="full-width margin-none"
              >
                {!values[
                  `activity_product_versions.${activityProductVersion.get(
                    'id'
                  )}.comparison_group`
                ] && (
                  <option disabled value="">
                    {i18next.t('Please select comparison group')}
                  </option>
                )}
                {comparisonGroups.map((comparisonGroup, idx) => (
                  <option key={idx} value={comparisonGroup.get('id')}>
                    {comparisonGroup.getIn(['entity', 'name']) ||
                      i18next.t('Default')}
                  </option>
                ))}
              </Field>
              <FormError response={response} formKey="comparison_group" />
            </FormItem>
          )}

          {reportTemplates.isEmpty() ? (
            <Alert>
              {' '}
              {i18next.t(
                'There are no reports available for this product'
              )}{' '}
            </Alert>
          ) : (
            <Table
              className="table-smaller table-header-small mt-md"
              columns={[
                {
                  header: (
                    <Checkbox
                      name="ALL"
                      checked={reportTemplates.every(
                        report => values.report_templates[report.get('id')]
                      )}
                      onChange={evt =>
                        reportTemplates.forEach(report =>
                          setFieldValue(
                            `activity_product_versions.${activityProductVersion.get(
                              'id'
                            )}.report_templates.${report.get('id')}`,
                            evt.target.checked
                          )
                        )
                      }
                    />
                  ),
                  value: report => (
                    <Checkbox
                      name="ALL"
                      checked={values.report_templates[report.get('id')]}
                      onChange={evt =>
                        setFieldValue(
                          `activity_product_versions.${activityProductVersion.get(
                            'id'
                          )}.report_templates.${report.get('id')}`,
                          evt.target.checked
                        )
                      }
                    />
                  ),
                  className: 'checkbox-column',
                },
                {
                  header: i18next.t('Reports'),
                  value: report => report.get('name'),
                  className: 'uppercase',
                },
              ]
                .concat(
                  showJobLevel
                    ? [
                        {
                          value: report =>
                            report.get('requires_job_level') && (
                              <Field
                                as="select"
                                name={`activity_product_versions.${activityProductVersion.get(
                                  'id'
                                )}.job_levels.${report.get('id')}`}
                                defaultValue=""
                                disabled={
                                  !values.report_templates[report.get('id')]
                                }
                                className="margin-none"
                              >
                                <option disabled value="">
                                  Job role level
                                </option>
                                {JOB_LEVELS.ORDER.map(jobLevel => (
                                  <option key={jobLevel} value={jobLevel}>
                                    {JOB_LEVELS.DISPLAY[jobLevel]}
                                  </option>
                                ))}
                              </Field>
                            ),
                          className: 'padding-small',
                        },
                      ]
                    : []
                )
                .concat(
                  activityProductVersion.get('product_variant')
                    ? []
                    : [
                        {
                          header: i18next.t('Credit cost'),
                          value: report =>
                            getReportCost(report, productOrganisation),
                          headerClassName: 'align-right',
                          className: 'align-right',
                        },
                      ]
                )}
              rowClassName={report =>
                values.report_templates[report.get('id')] ? 'selected' : ''
              }
              rows={reportTemplates}
            />
          )}
          <FormError response={response} formKey="report_templates" />
          <FormError response={response} formKey="job_level" />
        </div>
      </div>
    );
  }
}

export class ProductVersionsReportGenerationSubform extends PureComponent {
  render() {
    const {
      values,
      setFieldValue,
      response,
      activityProductVersions,
      organisation,
    } = this.props;
    return (
      <div>
        {activityProductVersions.map((activityProductVersion, idx) => (
          <ProductVersionReportGeneration
            key={idx}
            activityProductVersion={activityProductVersion}
            organisation={organisation}
            values={
              values.activity_product_versions[activityProductVersion.get('id')]
            }
            response={response.update(
              'errors',
              errors => errors && errors.getIn(['report_options', idx])
            )}
            setFieldValue={setFieldValue}
          />
        ))}
      </div>
    );
  }
}

export class ProductVersionsReportGenerationForm extends PureComponent {
  validateActivityProductVersion(obj, activityProductVersion) {
    const needsComparisonGroup = getNeedsComparisonGroup(
      activityProductVersion
    );

    if (!obj.comparison_group && needsComparisonGroup) {
      return false;
    }

    if (!obj.comparison_group && !needsComparisonGroup) {
      return true;
    }

    const reportTemplateKeys = Object.keys(obj.report_templates).filter(
      key => obj.report_templates[key]
    );

    if (reportTemplateKeys.length === 0) {
      return false;
    }

    if (obj.job_levels) {
      return reportTemplateKeys.every(key => {
        return (
          !Object.keys(obj.job_levels).includes(key) ||
          obj.job_levels[key] !== undefined
        );
      });
    }
    return true;
  }

  isFormValid(values) {
    const { activityProductVersions } = this.props;
    return activityProductVersions.every(activityProductVersion => {
      return this.validateActivityProductVersion(
        values.activity_product_versions[activityProductVersion.get('id')],
        activityProductVersion
      );
    });
  }

  render() {
    const {
      initialValues,
      onSubmit,
      response,
      activityProductVersions,
      organisation,
    } = this.props;
    return (
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="form-block">
            {activityProductVersions.map((activityProductVersion, idx) => (
              <ProductVersionReportGeneration
                key={idx}
                activityProductVersion={activityProductVersion}
                organisation={organisation}
                values={
                  values.activity_product_versions[
                    activityProductVersion.get('id')
                  ]
                }
                response={response}
                setFieldValue={setFieldValue}
              />
            ))}

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <CloseModalButton>{i18next.t('Back')}</CloseModalButton>

                <LiveButton
                  response={response}
                  buttonText={i18next.t('Confirm')}
                  pendingMessage={i18next.t('Saving...')}
                  disabled={!this.isFormValid(values)}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ProductVersionsReportGenerationForm;
