import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import {
  updateHeatmapRow,
  updateHeatmapRowModal,
  getAnalyticsAggregatesHeatmap,
  getAnalyticsAggregatesModal,
  getAnalyticsFiltersHeatmap,
} from '^/actions/analytics';
import { closeTopModal } from '^/actions/modals';
import Dropdown from '^/components/dropdown/Dropdown';
import Loading from '^/components/Loading';
import {
  AnalyticsAppliedFilters,
  AnalyticsFilters,
  HeatmapRow,
} from '^/reducers/api/types';
import { HUMAN_READABLE_KEYS, getSplitKeys, getSummaryName } from './utils';

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
  getAnalyticsAggregatesHeatmap: typeof getAnalyticsAggregatesHeatmap;
  getAnalyticsAggregatesModal: typeof getAnalyticsAggregatesModal;
  getAnalyticsFiltersHeatmap: typeof getAnalyticsFiltersHeatmap;
  updateHeatmapRow: typeof updateHeatmapRow;
  updateHeatmapRowModal: typeof updateHeatmapRowModal;
}

interface OwnProps {
  row: HeatmapRow;
  parentFilters?: AnalyticsAppliedFilters;
  indices: ReadonlyArray<number>;
  user: Immutable.Map<string, any>;
  inModal: boolean;
}

type Props = OwnProps & DispatchProps;

interface State {
  selectedKey: keyof AnalyticsFilters | null;
}

export class SplitRowModal extends React.Component<Props, State> {
  public readonly state = {
    selectedKey: null as null,
  };

  public constructor(props: Props) {
    super(props);
    const { row, parentFilters } = this.props;

    if (row.filtersState.sessions?.results.length === 0) {
      this.props.getAnalyticsFiltersHeatmap({
        ...parentFilters,
        ...row.appliedFilters,
      });
    }
  }

  public render() {
    const { row, parentFilters, user } = this.props;
    const { selectedKey } = this.state;
    if (row.filtersState.sessions?.results.length === 0) {
      return <Loading />;
    }

    return (
      <div>
        <div className="boxed mb-md">
          <div className="box">{getSummaryName(row)}</div>
        </div>
        <p>Split by</p>
        <Dropdown
          title={i18next.t<string>('Please select...')}
          items={getSplitKeys(row, user, parentFilters)
            .map(key => ({
              id: key,
              name:
                HUMAN_READABLE_KEYS[key as keyof AnalyticsFilters] +
                ` (${this.possibleValuesFor(key).length})`,
            }))
            .filter(item => item.id !== 'filter_profile')}
          onClick={this.selectKey}
        />
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </button>
            <button
              className="btn btn-primary"
              disabled={!selectedKey}
              onClick={this.applySplit}
            >
              {i18next.t<string>('Split')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  private possibleValuesFor = (key: string) => {
    const { row, parentFilters } = this.props;
    const appliedFilters = { ...row.appliedFilters, ...parentFilters };

    const values = row.filtersState.filters[key as keyof AnalyticsFilters];
    const applied = appliedFilters[key as keyof AnalyticsAppliedFilters];
    return applied?.length
      ? values.filter(each => (applied as string[]).includes(each.id as string))
      : values;
  };

  private selectKey = (key: string | number | null) =>
    key && this.setState({ selectedKey: key as keyof AnalyticsFilters });

  private applySplit = () => {
    const { selectedKey } = this.state;
    if (selectedKey) {
      this.doApplySplit(selectedKey as any);
    }
  };

  private doApplySplit = (key: keyof AnalyticsFilters) => {
    const { row, indices, parentFilters, inModal } = this.props;
    const values = this.possibleValuesFor(key);
    const updateHeatmapRowFunc = !inModal
      ? this.props.updateHeatmapRow
      : this.props.updateHeatmapRowModal;
    const getAnalyticsAggregatesFunc = !inModal
      ? this.props.getAnalyticsAggregatesHeatmap
      : this.props.getAnalyticsAggregatesModal;
    updateHeatmapRowFunc(
      {
        ...row,
        children:
          values?.map(({ id }) => ({
            loading: true,
            appliedFilters: {
              [key]: [id],
            },
            filtersState: {
              ...row.filtersState,
              sessions: null,
            },
            children: [],
          })) || [],
      },
      indices
    );
    getAnalyticsAggregatesFunc(
      {
        ...parentFilters,
        ...row.appliedFilters,
      },
      key,
      values.map(({ id }) => id)
    );
    this.props.closeTopModal();
  };
}

export default connect(null, {
  closeTopModal,
  getAnalyticsAggregatesHeatmap,
  getAnalyticsAggregatesModal,
  getAnalyticsFiltersHeatmap,
  updateHeatmapRow,
  updateHeatmapRowModal,
})(SplitRowModal);
