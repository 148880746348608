import React, { Key, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Link } from 'react-router';
import moment from 'moment';
import _ from 'underscore';

import {
  FilterOption,
  FilterOptions,
  FiltersAndOrdering,
  InteractiveTable,
  PaginatedResultsResponse,
} from '../InteractiveTable';
import { ContainerRectangleTile } from '../ContainerRectangleTile';
import PillSearch from '../PillSearch';
import ControlBar from '../ControlBar';
import NewButton from '../NewButton';
import { FORMAT_DATE, deepCopy } from '^/utils';
import { renderTruncatedTag } from './utils';
import Alert, { AlertType } from '../Alert';
import { fetchWrapper } from '^/api-utils';

const PAGE_SIZE = 50;
const DEBOUNCE_TIME = 600;
const API_DATA_URL_PREFIX = `/api/pulses/user-pulses/`;
const API_FILTER_URL_PREFIX = `/api/components/tables/pulse-checks-filter-options/`;

export const PulsesUsersListPage = () => {
  const [rowData, setRowData] = useState<PaginatedResultsResponse<any> | null>(
    null
  );
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<
    FiltersAndOrdering
  >({
    sorting: { key: 'start_date', reverse: true },
    page: 1,
  });
  const [page, setPage] = useState(1);
  const [filtersPending, setFiltersPending] = useState(true);
  const [dataPending, setDataPending] = useState(true);
  const [serverError, setServerError] = useState<string | null>(null);
  const [nextPagePending, setNextPagePending] = useState(false);

  const translateFilterKeys = (
    base: URL,
    keyArray: [string, FilterOption[]] | { key: string }[][]
  ) => {
    const [key, value] = keyArray;
    let translatedKey = key;
    switch (key) {
      case 'accounts':
        translatedKey = 'organisation';
        break;
      case 'users':
        translatedKey = 'user';
        break;
      case 'pulse_types':
        translatedKey = 'pulse_type';
        break;
    }
    return base.searchParams.set(
      translatedKey.toString(),
      value[0].key.toLowerCase()
    );
  };

  const buildUrl = (pageNumber: number) => {
    const base = new URL(`${window.location.origin}${API_DATA_URL_PREFIX}`);
    selectedFilterOptions &&
      selectedFilterOptions.filters &&
      Object.entries(selectedFilterOptions.filters).map(item => {
        return (
          item[1][0].key &&
          item[1][0].key !== '' &&
          translateFilterKeys(base, item)
        );
      });

    selectedFilterOptions?.searches &&
      Object.entries(selectedFilterOptions.searches).map(item => {
        return (
          item[1] &&
          item[1] !== '' &&
          base.searchParams.set(item[0], item[1].toString())
        );
      });
    const isReverse = selectedFilterOptions?.sorting.reverse ? '-' : '';
    let translateKey = selectedFilterOptions?.sorting.key;
    switch (selectedFilterOptions?.sorting.key) {
      case 'organisation':
        translateKey = 'user__organisation__name';
        break;
      case 'full_name':
        translateKey = 'user__full_name';
        break;
      case 'start_date':
        translateKey = 'created';
        break;
      case 'pulse_type':
        translateKey = 'pulse_type';
        break;
      case 'activity_name':
        translateKey = 'pulse__activity_product_version__activity__name';
        break;
      default:
        translateKey = selectedFilterOptions?.sorting.key;
    }

    base.searchParams.set('page', pageNumber.toString());
    base.searchParams.set('page_size', PAGE_SIZE.toString());
    base.searchParams.set(
      'ordering',
      selectedFilterOptions?.sorting.key
        ? `${isReverse}${translateKey}`
        : `${isReverse}name`
    );
    selectedFilterOptions?.search &&
      selectedFilterOptions?.search !== '' &&
      selectedFilterOptions?.search !== undefined &&
      base.searchParams.set(
        'search',
        selectedFilterOptions?.search?.toString()
      );
    return `${base.searchParams.toString()}`;
  };

  const fetchFilters = () => {
    const filterUrl = buildUrl(page);
    fetchWrapper
      .get(`${API_FILTER_URL_PREFIX}?${filterUrl}`)
      .then(userData => {
        setFiltersPending(false);
        setFilters(userData);
      })
      .catch(error => {
        setServerError(error.toString());
      });
  };
  const getRows = (
    data: PaginatedResultsResponse<{ [key: string]: any }>,
    append: boolean
  ) => {
    if (!append) {
      setRowData({
        results: data.results?.map(item => {
          return {
            organisation: item.user.organisation.name,
            full_name: item.user.full_name,
            user_id: item.user.id,
            pulse_name: item.pulse.name,
            pulse_type: item.pulse.type,
            activity_name: item.pulse.activity?.name || '-',
            activity_id: item.pulse.activity?.id || '',
            start_date: moment(item.created).format(FORMAT_DATE),
            pulse_id: item.pulse.id,
          };
        }),
        count: data.count,
        page: 1,
      });
    } else {
      const rows = deepCopy(rowData);
      setNextPagePending(false);
      const nextPageData = {
        results: data.results?.map(item => {
          return {
            organisation: item.user.organisation.name,
            full_name: item.user.full_name,
            user_id: item.user.id,
            pulse_name: item.pulse.name,
            pulse_type: item.pulse.type,
            activity_name: item.pulse.activity?.name || '-',
            activity_id: item.pulse.activity?.id || '',
            start_date: moment(item.created).format(FORMAT_DATE),
            pulse_id: item.pulse.id,
          };
        }),
        count: data.count,
        page: data.page,
      };

      setRowData({
        results: rows.results.concat(nextPageData.results),
        count: data.count,
        page: page,
      });
    }
    fetchFilters();
  };

  const fetchData = (append: boolean, pageNumber: number) => {
    const filterUrl = buildUrl(pageNumber);
    fetchWrapper
      .get(`${API_DATA_URL_PREFIX}?${filterUrl}`)
      .then(userData => {
        setDataPending(false);
        getRows(userData, append);
      })
      .catch(error => {
        setServerError(error.toString());
      });
  };

  const loadPage = () => {
    setPage(page + 1);
    setNextPagePending(true);
    fetchData(true, page + 1);
  };

  const doFiltersSearchSort = (
    type: string,
    key: string | number,
    selected: string | number,
    reverse?: boolean
  ) => {
    setPage(1);
    if (type === 'sort') {
      setDataPending(true);
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        sorting: {
          key: key.toString(),
          reverse:
            reverse !== undefined
              ? reverse
              : Boolean(selectedFilterOptions?.sorting.reverse),
        },
        page: 1,
      });
    }

    if (type === 'search') {
      let newObj = selectedFilterOptions.searches
        ? deepCopy(selectedFilterOptions.searches)
        : {};

      newObj = {
        ...newObj,
        [key as keyof Key]: selected,
      };
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        searches: newObj,
        page: 1,
      });
    }

    if (type === 'filter' && filters) {
      setDataPending(true);
      const selectedFilters = filters[key as keyof Key].filter(
        item => item.key === selected
      );
      let newObj = selectedFilterOptions.filters
        ? deepCopy(selectedFilterOptions.filters)
        : {};

      newObj = {
        ...newObj,
        [key as keyof Key]: selectedFilters,
      };
      if (
        selectedFilterOptions &&
        selectedFilterOptions.filters &&
        selectedFilterOptions.filters[key as keyof Key] &&
        selectedFilterOptions.filters[key as keyof Key][0].key === selected
      ) {
        delete newObj[key as keyof Key];
      }
      setSelectedFilterOptions({
        ...selectedFilterOptions,
        filters: newObj ? deepCopy(newObj) : undefined,
        page: 1,
      });
    }
  };

  const searchTable = (searchTerm: string) => {
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      page: 1,
      search: searchTerm,
    });
    setDataPending(true);
    setFiltersPending(true);
  };

  const handleOnSearchChange = _.debounce((change: string) => {
    searchTable(change);
  }, DEBOUNCE_TIME);

  const cellRender = (
    key: string,
    value: string,
    row: Array<{ key: string; value: string }>
  ) => {
    let cell = <>{value}</>;
    if (key === 'pulse_name') {
      cell = renderTruncatedTag('span', value, 20);
    }
    if (key === 'start_date') {
      cell = <span className="no-wrap">{value}</span>;
    }
    if (key === 'full_name' && row[2].value) {
      cell = <Link to={`/page/individuals/${row[2].value}/`}>{value}</Link>;
    }
    if (key === 'status') {
      cell = <span className="capitalize">{value.toLowerCase()}</span>;
    }
    if (key === 'frequency') {
      cell = <span className="capitalize">{value}</span>;
    }
    if (key === 'activity_name' && value !== '-' && row[6].value) {
      cell = <Link to={`/page/activities/${row[6].value}/`}>{value}</Link>;
    }
    if (key === 'pulse_id') {
      cell = (
        <Link to={`/page/pulses/${value}/`}>
          <NewButton buttonType="select">Details</NewButton>
        </Link>
      );
    }
    return cell;
  };
  const breadcrumb = (
    <span className="breadcrumbs">
      <Link to="/page/pulses/hub/">{i18next.t<string>('Pulse Hub')}</Link>
      <span>{i18next.t<string>('Manage Pulse Checks')}</span>
    </span>
  );

  useEffect(() => {
    fetchData(false, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOptions]);

  return (
    <>
      <ControlBar
        title={i18next.t<string>('Manage Pulse Checks')}
        breadcrumb={breadcrumb}
        hideAdd
        hideSearch
      />
      <div>
        <ContainerRectangleTile noBorder>
          <>
            <div className="row justify-content-space-between display-flex">
              <div className="ml-md mr-auto">
                <h1 className="table-heading">Pulse Checks</h1>
              </div>
              <div className="mr-md">
                <PillSearch
                  disabled={dataPending || filtersPending || nextPagePending}
                  className="pill"
                  onChange={e =>
                    handleOnSearchChange(e?.currentTarget.value || '')
                  }
                  formKey="form"
                  placeholder="Search"
                />
              </div>
            </div>
            {serverError && <Alert type={AlertType.Error}>{serverError}</Alert>}
            <InteractiveTable
              className="pulse-checks"
              pageSize={50}
              loadPage={loadPage}
              page={page}
              cellRenderer={cellRender}
              isDataPending={dataPending}
              isFilterDataPending={filtersPending}
              isNextPageDataPending={nextPagePending}
              setSelectedFilter={doFiltersSearchSort}
              enableCheckBoxes={false}
              onSelection={undefined}
              rows={rowData}
              filters={filters}
              initialSelections={undefined}
              selectedFilterOptions={selectedFilterOptions}
              columns={[
                {
                  header: {
                    label: 'Account',
                    key: 'organisation' as keyof {
                      key: Key;
                      value: string;
                    },
                  },
                  filter: {
                    key: 'accounts' as keyof { key: Key; value: string },
                    type: 'dropdown-single',
                  },
                  sortable: true,
                },
                {
                  header: {
                    label: 'Respondent name',
                    key: 'full_name' as keyof { key: Key; value: string },
                  },
                  filter: {
                    key: 'user_name' as keyof { key: Key; value: string },
                    type: 'search',
                  },
                  sortable: true,
                },
                {
                  header: {
                    label: 'Pulse Check Name',
                    key: 'pulse_name' as keyof { key: Key; value: string },
                  },
                  filter: {
                    key: 'pulse_name' as keyof { key: Key; value: string },
                    type: 'search',
                  },
                  sortable: true,
                },
                {
                  header: {
                    label: 'Pulse type',
                    key: 'pulse_type' as keyof {
                      key: Key;
                      value: string;
                    },
                  },
                  filter: {
                    key: 'pulse_types' as keyof { key: Key; value: string },
                    type: 'dropdown-single',
                  },
                  sortable: true,
                },
                {
                  header: {
                    label: 'Activity Name',
                    key: 'activity_name' as keyof { key: Key; value: string },
                  },
                  filter: {
                    key: 'activity_name' as keyof { key: Key; value: string },
                    type: 'search',
                  },
                  sortable: true,
                },
                {
                  header: {
                    label: 'Start date',
                    key: 'start_date' as keyof { key: Key; value: string },
                  },
                  sortable: true,
                },
                {
                  header: {
                    label: ' ',
                    key: 'pulse_id' as keyof { key: Key; value: string },
                  },
                  sortable: false,
                },
              ]}
            />
          </>
        </ContainerRectangleTile>
      </div>
    </>
  );
};
