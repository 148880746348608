import { Map } from 'immutable';
import React, { PureComponent } from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';

import { getUserInfo } from '^/actions/actions';
import {
  getOrganisationCreditDetail,
  getOrgCreditHistory,
  getBasketItemsStatus,
} from '^/actions/collections';
import { Uuid } from '^/reducers/api/types';
import { selectFilterSpec } from '^/selectors/ui';
import { selectUserOrgId } from '^/selectors/user';
import { StoreState } from '^/store';
import { MODALS } from './types';
import { closeModal } from '^/actions/modals';

interface OwnProps {
  paymentUrl: string;
  isPurchasingCredits: boolean;
  basketItemIds: ReadonlyArray<string>;
}

interface DispatchProps {
  getUserInfo: typeof getUserInfo;
  getOrgCreditHistory: typeof getOrgCreditHistory;
  getOrganisationCreditDetail: typeof getOrganisationCreditDetail;
  getBasketItemsStatus: typeof getBasketItemsStatus;
  closeModal: typeof closeModal;
}

interface StateProps {
  organisationId: Uuid;
  filters: Map<string, any>;
  allBasketItemsPurchased: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

const BASKET_ITEM_STATUS_POLL_INTERVAL_IN_MS = 1500;
const FIFTEEN_MIN_IN_MS = 900000;
const MAX_BASKET_ITEM_STATUS_TRIES =
  FIFTEEN_MIN_IN_MS / BASKET_ITEM_STATUS_POLL_INTERVAL_IN_MS;

export class PaymentInProgressModal extends PureComponent<Props> {
  private basketItemStatusInterval: NodeJS.Timeout | null = null;
  private basketItemStatusTries = 0;

  public componentWillMount() {
    this.basketItemStatusInterval = setInterval(() => {
      if (this.basketItemStatusTries > MAX_BASKET_ITEM_STATUS_TRIES) {
        if (this.basketItemStatusInterval) {
          clearInterval(this.basketItemStatusInterval);
        }
        return;
      }

      this.basketItemStatusTries = this.basketItemStatusTries + 1;
      this.props.getBasketItemsStatus(this.props.basketItemIds);
    }, BASKET_ITEM_STATUS_POLL_INTERVAL_IN_MS);
  }

  public componentWillUnmount() {
    const { filters, organisationId, isPurchasingCredits } = this.props;
    this.props.getUserInfo();
    if (isPurchasingCredits) {
      this.props.getOrgCreditHistory(organisationId, '', 1, filters);
      this.props.getOrganisationCreditDetail(organisationId, filters);
    }

    if (this.basketItemStatusInterval) {
      clearInterval(this.basketItemStatusInterval);
    }
  }

  public render() {
    if (this.props.allBasketItemsPurchased) {
      this.props.closeModal(MODALS.PAYMENT_IN_PROGRESS_MODAL);
    }

    return (
      <div>
        <p>
          <Trans i18nKey="TRANS Payment tab not open?">
            Payment tab not open?{' '}
            <a href={this.props.paymentUrl} target="_blank">
              Click here
            </a>
            .
          </Trans>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    organisationId: selectUserOrgId(state),
    filters: selectFilterSpec(state),
    allBasketItemsPurchased: state.collections.getIn(
      ['basketItemStatus', 'items', 'all_basket_items_purchased'],
      false
    ),
  };
}

export default connect(mapStateToProps, {
  getUserInfo,
  getOrgCreditHistory,
  getOrganisationCreditDetail,
  getBasketItemsStatus,
  closeModal,
})(PaymentInProgressModal);
