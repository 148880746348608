import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';
import { isNumber } from 'underscore';

import {
  AGE_CHOICES,
  ETHNICITY_CHOICES,
  GEOGRAPHY_CHOICES,
  INDUSTRY_CHOICES,
  JOB_LEVEL_SHORT_CHOICES,
  SEX_CHOICES,
} from '^/components/profile/choices';
import {
  Analytics360AggregateResults,
  Analytics360Result,
  Analytics360Results,
  AnalyticsAggregateExtraResults,
  AnalyticsAggregateResults,
  AnalyticsFactorResults,
  AnalyticsProduct,
  AnalyticsPsychoResult,
  AnalyticsSession,
} from '^/reducers/api/types';
import { formatDate, getIn } from '^/utils';
import {
  Column,
  DATA_ANALYTICS_HEATMAP_LEVEL_MAP,
  FACTORS_HIERARCHY,
  THREE_SIXTY_LEVELS,
} from './DataAnalyticsTableStructure';
import { catchBadFactor } from './utils';

function findResult<T extends { factor: string; code?: string }>(
  results: ReadonlyArray<T> | undefined,
  factor: string
): T | undefined {
  return results?.find(
    each =>
      each.factor === catchBadFactor(factor) &&
      (!each.code || each.code === 'CHANGE')
  );
}

type Props = OwnProps;

interface OwnProps {
  session?: AnalyticsSession;
  column: Column;
  aggregate?: AnalyticsAggregateResults | Analytics360AggregateResults;
  aggregateExtra?: AnalyticsAggregateExtraResults;
  label?: string;
  count?: number;
  product: AnalyticsProduct;
  className?: string;
  showAlternativeTable?: boolean;
  showTableThree?: boolean;
}

export default class DataAnalyticsTd extends React.PureComponent<Props> {
  public render() {
    const { column, className } = this.props;
    return column.id === 'user' ? (
      <td
        className={classNames(className, 'text-align-left')}
        title={this.renderDemographicField()}
      >
        {this.renderDemographicField()}
      </td>
    ) : (
      <td
        className={classNames(this.getLevelClass(), className)}
        title={this.getTitle(this.renderResultsField())}
      >
        {this.renderResultsField()}
      </td>
    );
  }

  public findDISCResult<T extends { factor: string; code?: string }>(
    results: ReadonlyArray<T> | undefined | any,
    factor: string,
    code?: string,
    isExtra?: boolean,
    aggregateExtra?: AnalyticsAggregateExtraResults
  ) {
    const { showAlternativeTable } = this.props;

    if (
      aggregateExtra &&
      ['self_image_style_name', 'self_image_pattern_text'].includes(factor)
    ) {
      return {
        extra: aggregateExtra[factor as keyof AnalyticsAggregateExtraResults],
      };
    }
    if (
      aggregateExtra &&
      aggregateExtra.self_image_dimensions_order &&
      factor.includes(':')
    ) {
      return { extra: getIn(aggregateExtra, factor.split(':')) };
    }
    return isExtra
      ? {
          code: code,
          factor:
            showAlternativeTable && factor.indexOf(':') > -1
              ? getIn(results, factor.split(':'))
              : getIn(results, [factor]),
        }
      : results?.find(
          (items: { factor: string; code: string }) =>
            items.factor === factor && items.code === code
        );
  }

  public renderAsString() {
    return this.props.column.id === 'user'
      ? this.renderDemographicField()
      : this.renderResultsField();
  }

  private levelToClassName(level?: string, factor?: string) {
    const { product } = this.props;
    const heatmapClassName =
      level &&
      getIn(
        DATA_ANALYTICS_HEATMAP_LEVEL_MAP[product.type],
        factor ? [factor, level] : [level]
      );
    return heatmapClassName || 'heatmap-no-level';
  }

  private isProductType(type: string) {
    return this.props.product?.type === type;
  }

  private getPsychometricLevelClass(aggregate?: AnalyticsAggregateResults) {
    const {
      session,
      column,
      showAlternativeTable,
      aggregateExtra,
    } = this.props;
    const isPRP = this.isProductType('POSITIVE_RESILIENCE_PROFILER');
    const isDISC = this.isProductType('DISC');
    const aggregateItem = isDISC
      ? this.findDISCResult(
          aggregate as AnalyticsAggregateResults,
          column.id,
          column.codeLevel,
          false
        )
      : findResult(aggregate as AnalyticsAggregateResults, column.id);

    if (aggregateItem) {
      return this.levelToClassName(
        aggregateItem.mean?.level,
        isPRP || isDISC || column.id === 'PP_SD' ? column.id : undefined
      );
    }

    if (
      column.id.indexOf(':') > -1 &&
      aggregate &&
      isDISC &&
      showAlternativeTable
    ) {
      const selfImageFactor =
        aggregateExtra?.self_image_dimensions_order &&
        aggregateExtra?.self_image_dimensions_order[
          parseInt(column.id.split(':')[1], 10)
        ];
      const selfImageLevel =
        this.findDISCResult(
          aggregate as AnalyticsAggregateResults,
          selfImageFactor || '',
          'CHANGE',
          false
        )?.mean?.level || '';

      return this.levelToClassName(selfImageLevel, selfImageFactor);
    }

    if (session) {
      const results = column.isExtra ? session.extra_results : session.results;
      const result = isDISC
        ? this.findDISCResult(
            results as AnalyticsFactorResults,
            column.id,
            column.codeLevel,
            column.isExtra
          )
        : findResult(results as AnalyticsFactorResults, column.id);

      if (isDISC && showAlternativeTable && column.id.indexOf(':') > -1) {
        const selfImageFactor = this.findDISCResult(
          results as AnalyticsFactorResults,
          column.id,
          'CHANGE',
          true
        ).factor;
        const selfImageResult = selfImageFactor
          ? this.findDISCResult(
              session.results as AnalyticsFactorResults,
              selfImageFactor,
              'CHANGE',
              false
            )
          : '';
        return this.levelToClassName(
          selfImageResult.level,
          selfImageResult.factor
        );
      }
      return this.levelToClassName(
        result?.level,
        isPRP || isDISC || column.id === 'PP_SD' ? column.id : undefined
      );
    }
  }

  private getThreeSixtyLevelClass(aggregate?: Analytics360AggregateResults) {
    const shouldColor = ['self_mean', 'rater_mean'].includes(
      this.props.column.field
    );
    const value = parseFloat(
      this.renderThreeSixtyResultsField(aggregate) as string
    );
    const level = THREE_SIXTY_LEVELS.find(({ threshold }) => value >= threshold)
      ?.level;
    return this.levelToClassName(shouldColor ? level : undefined);
  }

  private getLevelClass() {
    const { aggregate } = this.props;
    if (this.isThreeSixty(aggregate)) {
      return this.getThreeSixtyLevelClass(aggregate);
    }
    return this.getPsychometricLevelClass(aggregate);
  }

  private renderPsychometricResultsField(
    aggregate?: AnalyticsAggregateResults,
    aggregateExtra?: AnalyticsAggregateExtraResults
  ) {
    const {
      session,
      column,
      showTableThree,
      showAlternativeTable,
    } = this.props;
    const isDISC = this.isProductType('DISC');
    const fieldName = column.field as keyof AnalyticsPsychoResult;
    const aggregateItem = isDISC
      ? this.findDISCResult(
          aggregate,
          column.id,
          column.codeLevel,
          column.isExtra,
          aggregateExtra
        )
      : findResult(aggregate as AnalyticsAggregateResults, column.id);

    if (aggregateItem?.mean) {
      return aggregateItem!.mean[fieldName];
    }

    if (aggregateItem?.extra) {
      if (
        showTableThree &&
        showAlternativeTable &&
        this.isLevelBelowThreshold(this.getLevelClass())
      )
        return '-';

      return aggregateItem!.extra;
    }

    if (session) {
      const results = column.isExtra ? session.extra_results : session.results;
      const result = isDISC
        ? this.findDISCResult(
            results as AnalyticsFactorResults,
            column.id,
            column.codeLevel,
            column.isExtra,
            aggregateExtra
          )
        : findResult(results as AnalyticsFactorResults, column.id);

      const tableThreeResult =
        showTableThree &&
        showAlternativeTable &&
        this.isLevelBelowThreshold(this.getLevelClass()) &&
        '-';
      return tableThreeResult || (result && result[fieldName]);
    }
  }

  private isLevelBelowThreshold(heatmapLevel?: string) {
    return (
      heatmapLevel?.includes('pale') ||
      heatmapLevel?.includes('no-heatmap') ||
      heatmapLevel?.includes('light')
    );
  }

  private renderThreeSixtyResultsField(
    aggregate?: Analytics360AggregateResults
  ) {
    const { session, column } = this.props;

    if (aggregate) {
      if (column.id === 'total') {
        return aggregate[column.field as keyof Analytics360AggregateResults];
      }

      const aggregateResult =
        column.id === 'summary'
          ? aggregate.summary
          : aggregate.results.find(each => each.code === column.id);
      return aggregateResult?.mean[column.field as keyof Analytics360Result];
    }

    const results = session?.results as Analytics360Results | undefined;

    if (column.id === 'total') {
      return results && results[column.field as keyof Analytics360Results];
    }

    const result =
      column.id === 'summary'
        ? results?.summary
        : results?.results.find(each => each.code === column.id);
    return result ? result[column.field as keyof Analytics360Result] : '';
  }

  private isThreeSixty(
    _aggregate?: AnalyticsAggregateResults | Analytics360AggregateResults
  ): _aggregate is Analytics360AggregateResults {
    return this.props.product.activity_type === 'THREE_SIXTY';
  }

  private renderResultsField() {
    const { aggregate, aggregateExtra } = this.props;
    if (this.isThreeSixty(aggregate)) {
      const value = this.renderThreeSixtyResultsField(aggregate);
      return value !== null ? value : i18next.t<string>('N/A');
    }
    return this.renderPsychometricResultsField(aggregate, aggregateExtra);
  }

  private getTitle(result: string) {
    const { showAlternativeTable } = this.props;
    return showAlternativeTable && result?.length === 1
      ? this.DISCLetterToWord(result)
      : isNumber(result)
      ? ''
      : result;
  }

  private DISCLetterToWord(letter: string) {
    const word = FACTORS_HIERARCHY[this.props.product.type][0]?.subfactors.find(
      subFactor => subFactor[1] === letter
    );
    return letter !== '-' ? word && word[0] : '';
  }

  private renderDemographicField() {
    const { session, column, aggregate, label, count } = this.props;

    if (column.field === 'full_name' && aggregate) {
      return i18next.t<string>('{{label}} (N={{count}})', {
        label: label || i18next.t<string>('Aggregate'),
        count,
      });
    }

    if (session) {
      const { user, activity, completed, self_and_lm_completed } = session;
      switch (column.field) {
        case 'full_name':
          return user.full_name;
        case 'organisation':
          return user.organisation?.name || '-';
        case 'sex':
          return SEX_CHOICES[user.sex] || '-';
        case 'age':
          return AGE_CHOICES[user.age] || '-';
        case 'ethnicity':
          return ETHNICITY_CHOICES[user.ethnicity] || '-';
        case 'job_level':
          return JOB_LEVEL_SHORT_CHOICES[user.job_level] || '-';
        case 'industry':
          return INDUSTRY_CHOICES[user.industry] || '-';
        case 'geography':
          return GEOGRAPHY_CHOICES[user.geography] || '-';
        case 'activity':
          return activity.name || '-';
        case 'completed':
          return formatDate(completed);
        case 'self_and_lm_completed':
          return formatDate(self_and_lm_completed);
        default:
          return '';
      }
    }
  }
}
