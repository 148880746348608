import React from 'react';
import { chunk } from 'underscore';

import { ShopItemCategory } from '^/reducers/api/types';
import ShopItemComponent from './ShopItem';

interface OwnProps {
  category: ShopItemCategory;
}

type Props = OwnProps;

export const ShopCategory: React.FunctionComponent<Props> = ({ category }) => {
  const itemsGroupedByThree = (chunk(
    category.shopitem_set,
    3
  ) as unknown) as ReadonlyArray<typeof category.shopitem_set>;

  return (
    <div>
      <p className="lead">{category.description}</p>
      <div>
        {itemsGroupedByThree.map((items, index) => (
          <div className="shop-items-wrapper" key={index}>
            {items.map(item => (
              <ShopItemComponent item={item} key={item.id} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopCategory;
