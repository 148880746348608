import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { hashHistory } from 'react-router';
import { syncHistory } from 'react-router-redux';
import { apiMiddleware } from 'redux-api-middleware';

import rootReducer from './reducers/rootReducer';
import navigation from '^/middleware/navigation';
import authentication from '^/middleware/authentication';
import toasts from '^/middleware/toasts';
import sudoStash from '^/middleware/sudoStash';
import refreshProfile from '^/middleware/refreshProfile';
import discardLateCollectionResponse from '^/middleware/discardLateCollectionResponse';
import ui from '^/middleware/ui';
import pollProgress from '^/middleware/pollProgress';
import polling from '^/middleware/polling';
import reports from '^/middleware/reports';
import autoLogout from '^/middleware/autoLogout';
import purchaseConfirmation from '^/middleware/purchaseConfirmation';
import activityAddRemoveUsers from '^/middleware/activityAddRemoveUsers';
import addLanguageHeaderMiddleware from './middleware/addLanguageHeader';
import stripeRedirect from './middleware/stripeRedirect';

export const history = hashHistory;

const reduxRouterMiddleware = syncHistory(history);

const middlewares = [
  reduxRouterMiddleware,
  thunk,
  autoLogout,
  addLanguageHeaderMiddleware,
  apiMiddleware,
  authentication,
  sudoStash,
  navigation,
  stripeRedirect,
  refreshProfile,
  discardLateCollectionResponse,
  ui,
  toasts,
  purchaseConfirmation,
  activityAddRemoveUsers,
  reports,
  pollProgress,
  polling,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const finalCreateStore = composeEnhancers(applyMiddleware(...middlewares))(
  createStore
);

const store = finalCreateStore(rootReducer);

export default store;
