import i18next from 'i18next';
import React, { ChangeEvent, PureComponent } from 'react';

import { Props as CheckableButtonProps } from '^/components/buttons/CheckableButton';
import CheckableButtonQuestion from '^/components/buttons/CheckableButtonQuestion';
import { LikertBounds } from '^/productVersions';

interface OwnProps {
  answer?: string | number | null;
  onAnswer: (value: string | number) => void;
  bounds: LikertBounds;
  questionText: string;
  isPending?: boolean;
  hasDontKnow?: boolean;
  size?: 'small' | 'medium' | 'large';
}

type Props = OwnProps;

export default class LikertQuestion extends PureComponent<Props> {
  public render() {
    const {
      isPending,
      answer: currentlySelected,
      hasDontKnow,
      questionText,
      bounds,
      size,
    } = this.props;

    const buttons: CheckableButtonProps[] = bounds.headers.map(
      (answer, index) => {
        return {
          label: i18next.t<string>(answer),
          value: index + 1,
          disabled: isPending,
          checked: currentlySelected === index + 1,
        };
      }
    );

    if (hasDontKnow) {
      buttons.push({
        label: i18next.t<string>("I Don't Know"),
        value: 0,
        disabled: isPending,
        checked: currentlySelected === 0,
        secondary: true,
      });
    }

    return (
      <CheckableButtonQuestion
        question={questionText}
        buttons={buttons}
        onChange={this.onChange}
        size={size}
      />
    );
  }

  private onChange = (event: ChangeEvent<HTMLInputElement>) =>
    this.props.onAnswer(parseFloat(event.target.value));
}
