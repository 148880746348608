import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { setActiveTab } from '../actions/actions';
import PureComponent from './PureComponent';

export class ContentTab extends PureComponent {
  setActiveTab() {
    const { tabsetName, tabId, onSelect } = this.props;
    if (tabsetName) {
      this.props.setActiveTab(tabsetName, tabId);
    }
    onSelect && onSelect();
  }

  getClassNames() {
    const { className, active } = this.props;
    return 'tab ' + (className ? className : '') + (active ? ' selected' : '');
  }

  render() {
    const { tabName, tabSubheading } = this.props;

    return (
      <div className={this.getClassNames()}>
        <a className="tab-link" onClick={this.setActiveTab.bind(this)}>
          {tabName}
          {tabSubheading && (
            <span className="tab-link-subheading">{tabSubheading}</span>
          )}
        </a>
      </div>
    );
  }
}

export function mapStateToProps(state, props) {
  const activeTab = state.ui.get('activeTabs', Map()).get(props.tabsetName);
  return {
    activeTab: activeTab,
    active:
      props.active || activeTab !== undefined
        ? activeTab === props.tabId
        : false,
  };
}

export default connect(mapStateToProps, { setActiveTab })(ContentTab);

ContentTab.propTypes = {
  onSelect: PropTypes.func,
  active: PropTypes.bool,
};
