import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from './PureComponent';

export default class Loading extends PureComponent {
  render() {
    return (
      <div
        className={
          'loading' + (this.props.className ? ' ' + this.props.className : '')
        }
      >
        <svg width="36" height="36" viewBox="0 0 36 36">
          <circle cx="18" cy="18" r="17" strokeWidth="2" fill="none" />
        </svg>
      </div>
    );
  }
}
