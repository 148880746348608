import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

import PureComponent from '^/components/PureComponent';

export default class ItemAndLogo extends PureComponent {
  getClassName() {
    const { small, boxed, inline } = this.props;

    return (
      'asset-name-and-logo' +
      (small ? ' small' : '') +
      (boxed ? ' boxed' : '') +
      (inline ? ' inline' : '')
    );
  }

  render() {
    const { label, icon, message } = this.props;

    return (
      <span className={this.getClassName()}>
        {icon && <img className="asset-logo" src={icon} />}
        <span className="asset-name">
          {label}
          {message && (
            <i>
              <br />
              {message}
            </i>
          )}
        </span>
      </span>
    );
  }
}

ItemAndLogo.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  creditCost: PropTypes.number,
  message: PropTypes.string,
  small: PropTypes.bool,
  boxed: PropTypes.bool,
  inline: PropTypes.bool,
};
