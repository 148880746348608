import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import {
  ACCOUNT_ADMIN_ROLE_DISPLAY,
  ACCOUNT_ADMIN_ROLE,
  ACCOUNT_TYPE,
  countRoleActive,
} from '^/models/organisation';
import { updateOrganisation } from '^/actions/collections';
import Editable from '^/components/editable/Editable';
import FormError from '^/components/FormError';
import PureComponent from '^/components/PureComponent';
import InfoIcon from './InfoIcon';

const blankAsNull = value => (value === '' ? null : value);

export class AdminLimits extends PureComponent {
  displayNumberOfAdmins(role) {
    const roleDisplay = ACCOUNT_ADMIN_ROLE_DISPLAY[role];
    return i18next.t('Active {{roleDisplay}}s', { roleDisplay });
  }

  displayOf(amount) {
    return i18next.t('{{amount}} of ', { amount });
  }

  render() {
    const { account, response, readOnly } = this.props;
    const {
      id,
      account_type,
      max_number_org_admins,
      max_number_superusers,
    } = account.toObject();
    const numSuperusers = countRoleActive(
      account,
      ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER
    );
    const numOrgAdmins = countRoleActive(
      account,
      ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN
    );
    const numProfessionalPractitioners = countRoleActive(
      account,
      ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER
    );

    if (account_type === ACCOUNT_TYPE.SOLE_PRACTITIONER) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-sm-12">
          <div>
            <div className="list-item">
              <div className="list-item-label wide">
                {this.displayNumberOfAdmins(
                  ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER
                )}
              </div>
              <div className="list-item-value">
                {this.displayOf(numSuperusers)}
                <Editable
                  type="number"
                  value={max_number_superusers}
                  min={numSuperusers}
                  fieldName="max_number_superusers"
                  responseFieldName="max_number_superusers"
                  placeholder={i18next.t('Upper limit')}
                  onSave={value =>
                    this.props.updateOrganisation(id, {
                      max_number_superusers: blankAsNull(value),
                    })
                  }
                  response={response}
                  readOnly={readOnly}
                />
                {readOnly && <InfoIcon />}
                <FormError
                  formKey="max_number_superusers"
                  response={response}
                />
              </div>
            </div>

            <div className="list-item">
              <div className="list-item-label wide">
                {this.displayNumberOfAdmins(
                  ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN
                )}
              </div>
              <div className="list-item-value">
                {this.displayOf(numOrgAdmins)}
                <Editable
                  type="number"
                  value={max_number_org_admins}
                  min={numOrgAdmins}
                  fieldName="max_number_org_admins"
                  responseFieldName="max_number_org_admins"
                  placeholder={i18next.t('Upper limit')}
                  onSave={value =>
                    this.props.updateOrganisation(id, {
                      max_number_org_admins: blankAsNull(value),
                    })
                  }
                  response={response}
                  readOnly={readOnly}
                />
                {readOnly && <InfoIcon />}
                <FormError
                  formKey="max_number_org_admins"
                  response={response}
                />
              </div>
            </div>

            <div className="list-item">
              <div className="list-item-label wide">
                {this.displayNumberOfAdmins(
                  ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER
                )}
              </div>
              <div className="list-item-value">
                <span className="content bold-text">
                  {numProfessionalPractitioners}
                </span>
                {readOnly && <InfoIcon />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, { updateOrganisation })(AdminLimits);
