import i18next from 'i18next';
import { AnyAction } from 'redux';
import { Dispatch, Store } from 'react-redux';

import * as actions from '^/actions/actions';
import { ADD_TO_COLLECTION, UPDATE_ITEM } from '^/actions/collections';
import * as shop from '^/actions/shop';
import Toast from '^/components/Toast';
import { StoreState } from '^/store';

export const UPDATE_ITEM_SUCCESS_WHITELIST = [
  'activities',
  'organisations',
  'productOrganisation',
  'productOrganisationReportTemplate',
  'users',
];

export const SAVED_ACTION_WHITELIST = [
  ...[
    actions.ADD_ADMIN_TO_ORGANISATION,
    actions.ADD_PRODUCT_TO_ACCOUNT,
    actions.DELETE_NOMINATION_RULE,
    actions.ADD_NOMINATION_RULE,
    actions.REMOVE_USER_FROM_ACTIVITY,
    actions.ADD_USERS_TO_ACTIVITY,
    actions.ADD_GROUPS_TO_ACTIVITY,
    actions.UPDATE_NOTIFICATION_EMAIL,
    actions.CREATE_NOTIFICATION_EMAIL,
    actions.CREATE_REPORT_NOTIFICATION_EMAIL,
    actions.UPDATE_REPORT_NOTIFICATION_EMAIL,
    actions.SET_MANUAL_REPORT_GENERATION,
    actions.UPDATE_ACTIVITY_REPORT_VISIBILITY,
    actions.ADD_CREDITS_TO_ORGANISATION,
    actions.MUTE_ACTIVITY_NOTIFICATION,
    actions.ADD_REPORT_TO_PRODUCT_ORGANISATION,
    actions.REMOVE_REPORT_FROM_PRODUCT_ORGANISATION,
  ].map(each => each.SUCCESS),
  actions.SHOW_SAVED_TOAST,
];

export const toastsMiddleware = (store: Store<StoreState>) => (
  next: Dispatch<StoreState>
) => (action: AnyAction) => {
  switch (action.type) {
    case UPDATE_ITEM.SUCCESS: {
      const collectionName: string | undefined =
        action.meta && action.meta.collectionName;

      if (
        collectionName &&
        UPDATE_ITEM_SUCCESS_WHITELIST.includes(collectionName)
      ) {
        const isActivityCollection = collectionName === 'activities';
        const pulseCheckCreated =
          isActivityCollection &&
          action.payload.with_pulse &&
          action.meta.data.adding_pulse_check;
        const successMessageTranslationKey = pulseCheckCreated
          ? 'Pulse check added'
          : 'Saved';

        Toast.success(i18next.t<string>(successMessageTranslationKey));
      }
      break;
    }
    case ADD_TO_COLLECTION.SUCCESS: {
      const collectionName: string | undefined =
        action.meta && action.meta.collectionName;

      switch (collectionName) {
        case 'groups': {
          Toast.success(i18next.t<string>('New group created'));
          break;
        }
        case 'activities': {
          Toast.success(
            i18next.t<string>('Activity created'),
            i18next.t<string>('Now set activity notifications')
          );
          break;
        }
        default:
          break;
      }
      break;
    }
    case actions.GET_USER_INFO.SUCCESS:
      const userProfile = store.getState().userProfile;
      const organisation = action.payload.organisation;
      const currentCreditsPurchased = userProfile.getIn([
        'organisation',
        'credits_purchased',
      ]);
      const newCreditsPurchased =
        organisation && organisation.credits_purchased;

      if (
        userProfile &&
        organisation &&
        newCreditsPurchased > currentCreditsPurchased
      ) {
        Toast.success(i18next.t<string>('Purchase complete'));
      }
      break;
    case shop.SEND_ENQUIRY_EMAIL.SUCCESS:
      Toast.success(i18next.t<string>('Enquiry sent'));
      break;
    case actions.RESEND_PULSE_INVITES.SUCCESS:
      Toast.success(i18next.t<string>('Invites resent'));
      break;
    default: {
      if (SAVED_ACTION_WHITELIST.includes(action.type)) {
        Toast.success(i18next.t<string>('Saved'));
      }
      break;
    }
  }
  return next(action);
};

export default toastsMiddleware;
