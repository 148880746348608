/* eslint-disable import/no-named-as-default-member */
import { Map, List } from 'immutable';
import i18next from 'i18next';

import propLists from '../propLists';
import { pluralize } from '../utils';
import { LIMIT } from '../nominationRules';

export const LIMIT_TEXT = Map({
  [LIMIT.EXACTLY]: i18next.t('exactly'),
  [LIMIT.UP_TO]: i18next.t('up to'),
  [LIMIT.AT_LEAST]: i18next.t('at least'),
  [LIMIT.UNRESTRICTED]: i18next.t('any number of'),
});

function getLimit(rule) {
  const { limit, count } = rule.toObject();
  const limitText = propLists.get(LIMIT_TEXT.entrySeq().map(List), limit);

  return limit === LIMIT.UNRESTRICTED ? limitText : `${limitText} ${count}`;
}

function getLabel(rule) {
  return pluralize(
    rule.get('count') || 0,
    rule.getIn(['role', 'name']),
    undefined,
    false
  );
}

export function generateNominationRuleLabel(rule) {
  return `${getLimit(rule)} ${getLabel(rule)}`;
}

export function generateNominationRulesLabel(rules) {
  const limit = rules.map(getLimit).join(' and ');
  const label = getLabel(rules.last());
  return `${limit} ${label}`;
}
