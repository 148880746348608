import classNames from 'classnames';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { replace } from 'react-router-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer, toast, Slide } from 'react-toastify';

import { can, onlySeeSurvey } from '../capabilities';
import TokenStore from '../TokenStore';
import PureComponent from '../components/PureComponent';
import ModalRenderer from '../components/modals/ModalRenderer';
import LogoutWarningOverlay from '../components/LogoutWarningOverlay';
import Loading from '../components/Loading';
import PeoplewiseLogo from '../components/PeoplewiseLogo';
import { withRouter } from '^/withRouter';
import { TOAST_TIMEOUT_MS } from '^/utils';
import { Key, matchesKey } from '^/keys';
import { LOGOUT_PATH_FULL, RTL_LANGUAGES } from '^/constants/routes';
import { getLangCodeWithFallback, getQueryValue } from '^/utils-ts';

export class App extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hideFocusRings: true,
    };

    this.onDocumentKeyDown = event => {
      if (matchesKey(event, Key.TAB)) {
        this.setState({
          hideFocusRings: false,
        });
      }

      if (
        event.target &&
        (event.target.type === 'checkbox' || event.target.type === 'radio')
      ) {
        this.setState({
          hideFocusRings: false,
        });
      }
    };

    this.onDocumentPointerDown = () => {
      this.setState({
        hideFocusRings: true,
      });
    };
  }

  checkPathAndRedirect() {
    const { currentPath, user, userPulseId } = this.props;

    if (can(user, onlySeeSurvey())) {
      return;
    }

    const isAuthenticated = Boolean(TokenStore.get());
    const onAuthenticatedPage = (currentPath || '').indexOf('/page') === 0;
    const atBaseRoute = (currentPath || '/') === '/';
    const atLogoutRoute = currentPath === LOGOUT_PATH_FULL;

    if (atLogoutRoute) {
      // We need to handle this logic somewhere other than here,
      // as redirecting shouldn't be handled in a component like this
      return;
    }

    if (atBaseRoute || (!isAuthenticated && onAuthenticatedPage)) {
      const userPulseIdUndefined =
        userPulseId === 'undefined' || userPulseId === undefined;

      const authenticatedRoute = userPulseIdUndefined
        ? '/page/home'
        : `/page/user-pulses/${userPulseId}/`;

      const defaultPath = isAuthenticated
        ? authenticatedRoute
        : `/login${userPulseIdUndefined ? '' : `?userpulse=${userPulseId}`}`;

      this.props.replace(defaultPath);
    }
  }

  setTitle() {
    const { title } = this.props;
    document.title = `${title ? `${title} |` : ''} Peoplewise Enable`;
  }

  componentDidUpdate(newProps) {
    // reset scroll when routes change
    if (newProps.currentPath !== this.props.currentPath) {
      window.scrollTo(0, 0);
      this.setTitle();
    }
    this.checkPathAndRedirect();
  }

  componentDidMount() {
    this.checkPathAndRedirect();

    document.addEventListener('keydown', this.onDocumentKeyDown);
    document.addEventListener('mousedown', this.onDocumentPointerDown);
    document.addEventListener('touchstart', this.onDocumentPointerDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onDocumentKeyDown);
    document.removeEventListener('mousedown', this.onDocumentPointerDown);
    document.removeEventListener('touchstart', this.onDocumentPointerDown);
  }

  render() {
    const {
      router: {
        params: { langCode },
      },
      activeLanguage,
    } = this.props;
    const currentLanguage = langCode
      ? getLangCodeWithFallback(langCode)
      : activeLanguage;

    return this.props.children ? (
      <div
        className="direction-container"
        dir={RTL_LANGUAGES.includes(currentLanguage) ? 'rtl' : 'ltr'}
      >
        <SkeletonTheme color="#f2f5f7" highlightColor="#ffffff">
          <div
            className={classNames({
              'has-top-bar': this.props.hasTopBar,
              'hide-focus-rings': this.state.hideFocusRings,
            })}
          >
            <ModalRenderer />
            {this.props.children}
            {this.props.showLogoutWarningOverlay && <LogoutWarningOverlay />}
          </div>
          <ToastContainer
            autoClose={TOAST_TIMEOUT_MS}
            closeButton={false}
            position={toast.POSITION.BOTTOM_CENTER}
            transition={Slide}
            className="toast-container"
            toastClassName="toast"
            hideProgressBar
            draggable={false}
            closeOnClick={false}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
          />
        </SkeletonTheme>
      </div>
    ) : (
      <div className="container loading-app">
        <div className="no-auth">
          <div className="row">
            <div className="col-xs-12">
              <PeoplewiseLogo />
            </div>
          </div>

          <div className="row">
            <Loading />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    title: props.router.routes
      .map(each => each.title)
      .filter(each => each)
      .join(' > '),
    currentPath: props.router.location.pathname,
    user: state.userProfile,
    hasTopBar: state.stash,
    showLogoutWarningOverlay: state.ui.get('showLogoutWarningOverlay'),
    userPulseId: getQueryValue(props, 'userpulse'),
    activeLanguage: state.ui.get('activeLanguage'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    replace,
  })(App)
);
