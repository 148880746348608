import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

interface Props {
  handleFileSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedInvoice: File | null;
}

const PulseSubscriptionOrInvoice = ({
  selectedInvoice,
  handleFileSelection,
}: Props) => {
  return (
    <div className="form">
      <h5>{i18next.t<string>('Invoice')}</h5>
      <div className="form-item">
        <label htmlFor="invoice-input" className="btn btn-default btn-small">
          {selectedInvoice
            ? i18next.t<string>('Select a new file')
            : i18next.t<string>('Select a file')}
        </label>
        <input
          className="display-none"
          id="invoice-input"
          type="file"
          onChange={handleFileSelection}
        />
        {selectedInvoice && (
          <div className="flex-list added-list">
            <div className="tag">
              <div className="tag-name">{selectedInvoice.name}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PulseSubscriptionOrInvoice;
