import i18next from 'i18next';
import React, { PureComponent, PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface OwnProps {
  onClose?: () => void;
}

type Props = PropsWithChildren<DispatchProps & OwnProps>;

export class CloseModalButton extends PureComponent<Props> {
  public render() {
    return (
      <button type="button" className="btn" onClick={this.onClick}>
        {this.props.children || i18next.t<string>('Close')}
      </button>
    );
  }

  private onClick = () => {
    this.props.closeTopModal();

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };
}

export default connect(null, { closeTopModal })(CloseModalButton);
