import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '../../PureComponent';
import { REPORT_ICONS } from '^/constants/reports';

const psycapPotentialContent = [
  {
    name: 'Profiler',
    icon: REPORT_ICONS.get('PROFILER'),
    content: function() {
      return (
        <span>
          <h3>{i18next.t('PsyCap Potential report types')}</h3>
          <p>{i18next.t('PARAGRAPH psycap potential types')}</p>
          <p>
            {i18next.t(
              'Key questions to consider when selecting the report type to be generated are'
            )}
            :
          </p>
          <ul>
            <li>
              {i18next.t(
                'Will the report be used for selection or for development?'
              )}
            </li>
            <li>{i18next.t('Who will be reading the report?')}</li>
            <li>{i18next.t('What information will be required?')}</li>
          </ul>
          <p>
            {i18next.t('Get in touch with one of our consultants on')}
            <a href="mailto:letushelp@peoplewise.co.uk">
              {' '}
              letushelp@peoplewise.co.uk{' '}
            </a>
            {i18next.t('PARAGRAPH psycap potential more info')}
          </p>
        </span>
      );
    },
  },
  {
    name: 'Profiler Report',
    icon: REPORT_ICONS.get('PROFILER'),
    content: function() {
      return (
        <span>
          <h3>{i18next.t('PsyCap Potential Profiler Report')}</h3>
          <p>{i18next.t('PARAGRAPH psycap potential profiler summary')}</p>
          <p>{i18next.t('PARAGRAPH psycap potential profiler overview')}</p>
          <p>
            <strong>{i18next.t('When should you use it?')}</strong>
            <br />
            {i18next.t('PARAGRAPH psycap potential profiler')}
          </p>
        </span>
      );
    },
  },
  {
    name: 'Individual Development',
    icon: REPORT_ICONS.get('INDIVIDUAL_DEVELOPMENT'),
    content: function() {
      return (
        <span>
          <h3>PsyCap Potential Individual Development Report</h3>
          <p>
            {i18next.t('PARAGRAPH psycap potential individual development')}
          </p>
          <p>
            {i18next.t(
              'PARAGRAPH psycap potential individual development overview'
            )}
          </p>
          <p>
            <strong>{i18next.t('When should you use it?')}</strong>
            <br />
            {i18next.t(
              'PARAGRAPH psycap potential individual development info'
            )}
          </p>
        </span>
      );
    },
  },
  {
    name: 'Interview Insights',
    icon: REPORT_ICONS.get('INTERVIEW_INSIGHTS'),
    content: function() {
      return (
        <span>
          <h3>{i18next.t('PsyCap Potential Interview Insights Report')}</h3>
          <p>
            {i18next.t('PARAGRAPH psycap potential interview insights report')}
          </p>
          <p>{i18next.t('PARAGRAPH psycap potential interview')}</p>
          <p>
            <strong>{i18next.t('When should you use it?')}</strong>
            <br />
            {i18next.t('PARAGRAPH psycap potential interview information')}
          </p>
        </span>
      );
    },
  },
  {
    name: 'Executive Summary',
    icon: REPORT_ICONS.get('EXECUTIVE_SUMMARY'),
    content: function() {
      return (
        <span>
          <h3>{i18next.t('PsyCap Potential Executive Summary Report')}</h3>
          <p>{i18next.t('PARAGRAPH psycap potential executive summary')}</p>
          <p>{i18next.t('PARAGRAPH psycap potential executive report')}</p>
          <p>
            <strong>{i18next.t('When should you use it?')}</strong>
            <br />
            {i18next.t('PARAGRAPH psycap potential executive')}
          </p>
        </span>
      );
    },
  },
];

const resilienceIndexContent = [
  {
    name: 'Positive Resilience Profiler Overview',
    icon: REPORT_ICONS.get('POSITIVE_RESILIENCE_PROFILER'),
    content: function() {
      return (
        <span>
          <h3>{i18next.t('Positive Resilience Profiler report types')}</h3>
          <p>{i18next.t('PARAGRAPH Positive Resilience Profiler report')}</p>
          <p>
            {i18next.t('Questions to consider when selecting a report format')}:
          </p>
          <ul>
            <li>
              {i18next.t(
                'Am I developing individual positive resilience, team positive resilience or both?'
              )}
            </li>
            <li>{i18next.t('Who will be reading the report(s)?')}</li>
          </ul>
          <p>
            {i18next.t('Email us on ')}
            <a href="mailto:letushelp@peoplewise.co.uk">
              letushelp@peoplewise.co.uk
            </a>
            {i18next.t('PARAGRAPH Positive Resilience Profiler report info')}
          </p>
        </span>
      );
    },
  },
  {
    name: 'Positive Resilience Profiler Individual Report',
    icon: REPORT_ICONS.get('POSITIVE_RESILIENCE_PROFILER'),
    content: function() {
      return (
        <span>
          <h3>Positive Resilience Profiler Individual Report</h3>
          <p>
            {i18next.t(
              'PARAGRAPH Positive Resilience Profiler individual report'
            )}
          </p>
          <p>
            {i18next.t(
              'PARAGRAPH Positive Resilience Profiler individual report info'
            )}
          </p>
          <p>
            <strong>{i18next.t('When should you use it?')}</strong>
            <br />
            {i18next.t(
              'PARAGRAPH Positive Resilience Profiler individual report summary'
            )}
          </p>
        </span>
      );
    },
  },
  {
    name: 'Positive Resilience Profiler Team Report',
    icon: REPORT_ICONS.get('RESILIENCE_INDEX_TEAM'),
    content: function() {
      return (
        <span>
          <h3>Positive Resilience Profiler Team Report</h3>
          <p>
            {i18next.t('PARAGRAPH Positive Resilience Profiler teams support')}
          </p>
          <p>
            {i18next.t(
              'PARAGRAPH Positive Resilience Profiler teams support summary'
            )}
          </p>
          <p>
            <strong>{i18next.t('When should you use it?')}</strong>
            <br />
            {i18next.t(
              'PARAGRAPH Positive Resilience Profiler teams support info'
            )}
          </p>
        </span>
      );
    },
  },
];

const slides = [
  {
    name: 'PsyCap Potential',
    icon: 'static/img/icons/psycap-potential-logo-lg.png',
    content: function() {
      return (
        <div className="row">
          <div className="col-xs-12 col-lg-4 short-description">
            <p>
              <img
                className="image text-center"
                src={'static/img/icons/psycap-potential-logo-lg.png'}
              />
            </p>
            <h3>{i18next.t('PsyCap Potential')}</h3>
            <p>{i18next.t('PARAGRAPH psycap potential')}</p>
            <a
              className="btn"
              onClick={() => {
                this.goToSlide(0);
              }}
            >
              {i18next.t('Report Types Overview')}
            </a>
          </div>
          <div className="col-xs-12 col-lg-8 description">
            <div className="container">
              {psycapPotentialContent[this.state.contentIndex].content.call(
                this
              )}
            </div>
            <div className="row">
              <div className="col-xs-12 more-icons">
                <h4>{i18next.t('Click on the icons to learn more')}</h4>

                {psycapPotentialContent.map((content, index) => {
                  if (index === 0) {
                    return null;
                  }

                  return (
                    <div key={index} className="col-xs-12 col-lg-3 text-center">
                      <a onClick={this.goToContent.bind(this, index)}>
                        <img className="image" src={content.icon} />
                        {content.name}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    name: 'Mental Agility Profiler',
    icon: REPORT_ICONS.get('MAP'),
    content: function() {
      return (
        <div className="row">
          <div className="col-xs-12 col-lg-4 short-description">
            <p>
              <img
                className="image text-center"
                src={'static/img/icons/map-logo-lg.png'}
              />
            </p>
            <h3>{i18next.t('MAP')}</h3>
            <p>{i18next.t('PARAGRAPH map reporting intro')}</p>
          </div>
          <div className="col-xs-12 col-lg-8 description">
            <div className="container">
              <h3>{i18next.t('MAP reporting')}</h3>
              <p>{i18next.t('PARAGRAPH map reporting info')}</p>
              <p>{i18next.t('PARAGRAPH map reporting summary')}</p>
              <p>
                {i18next.t('Get in touch with one of our consultants on')}
                <a href="mailto:letushelp@peoplewise.co.uk">
                  {' '}
                  letushelp@peoplewise.co.uk
                </a>
                {i18next.t('PARAGRAPH group and activities')}
              </p>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    name: 'Positive Resilience Profiler',
    icon: REPORT_ICONS.get('POSITIVE_RESILIENCE_PROFILER'),
    content: function() {
      return (
        <div className="Resilience-index row">
          <div className="col-xs-12 col-lg-4 short-description">
            <p>
              <img
                className="image text-center"
                src={'static/img/icons/prp-logo-lg.png'}
              />
            </p>
            <h3>{i18next.t('Positive Resilience Profiler')}</h3>
            <p>
              {i18next.t(
                'The Positive Resilience Profiler measures the five factors'
              )}
              {i18next.t('PARAGRAPH patterns and behaviour')}
            </p>
            <a
              className="btn"
              onClick={() => {
                this.goToSlide(3);
              }}
            >
              {i18next.t('Report Types Overview')}
            </a>
          </div>
          <div className="col-xs-12 col-lg-8 description">
            <div className="container">
              {resilienceIndexContent[this.state.contentIndex].content.call(
                this
              )}
            </div>

            <div className="row">
              <div className="col-xs-12 more-icons">
                <h4>{i18next.t('Click on the icons to learn more')}</h4>

                {resilienceIndexContent.map((content, index) => {
                  if (index === 0) {
                    return null;
                  }

                  return (
                    <div key={index} className="col-xs-12 col-lg-3 text-center">
                      <a onClick={this.goToContent.bind(this, index)}>
                        <img className="image" src={content.icon} />
                        {content.name}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    name: '360',
    icon: REPORT_ICONS.get('THREE_SIXTY'),
    content: function() {
      return (
        <div className="row">
          <div className="col-xs-12 col-lg-4 short-description">
            <p>
              <img
                className="image text-center"
                src={'static/img/icons/360-logo-lg.png'}
              />
            </p>
            <h3>360</h3>
            <p>
              The{' '}
              <span className="text-peoplewise">
                people<span className="lower">wise</span>
              </span>{' '}
              360
              {i18next.t(
                'provides behavioural feedback from a broad range of perspectives to provide insight on an individual’s'
              )}
              {i18next.t(
                'performance and impact in the workplace. It compares self and others’ perceptions of workplace'
              )}
              {i18next.t('behaviours.')}
            </p>
          </div>
          <div className="col-xs-12 col-lg-8 description">
            <div className="container">
              <h3>{i18next.t('360 types & reporting')}</h3>
              <p>{i18next.t('PARAGRAPH three sixty intro')}</p>
              <p>
                {i18next.t('The 360 report can be based upon the')}
                <span className="text-peoplewise">
                  {' '}
                  people<span className="lower">wise </span>
                </span>
                {i18next.t('PARAGRAPH three sixty summary')}
                <span className="text-peoplewise">
                  {' '}
                  people<span className="lower">wise </span>
                </span>
                {i18next.t(
                  'capability bank to measure the most relevant workplace behaviours that underpin success in a'
                )}
                {i18next.t('particular organisation, team or job-role.')}
              </p>
              <p>
                {i18next.t('Get in touch with one of our consultants on')}
                <a href="mailto:letushelp@peoplewise.co.uk">
                  {' '}
                  letushelp@peoplewise.co.uk
                </a>
                {i18next.t('PARAGRAPH three sixty end')}
              </p>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    name: 'Emotional Agility Profiler',
    icon: REPORT_ICONS.get('EAP'),
    content: function() {
      return (
        <div className="row">
          <div className="col-xs-12 col-lg-4 short-description">
            <p>
              <img
                className="image text-center"
                src={'static/img/icons/eap-logo-lg.png'}
              />
            </p>
            <h3>EAP</h3>
            <p>{i18next.t('PARAGRAPH eap reporting')}</p>
          </div>
          <div className="col-xs-12 col-lg-8 description">
            <div className="container">
              <h3>EAP reporting</h3>
              <p>{i18next.t('PARAGRAPH eap reporting intro')}</p>
              <p>{i18next.t('PARAGRAPH eap reporting summary')}</p>
              <p>
                {i18next.t('Get in touch with one of our consultants on')}
                <a href="mailto:letushelp@peoplewise.co.uk">
                  {' '}
                  letushelp@peoplewise.co.uk
                </a>
                {i18next.t('PARAGRAPH more info on eap')}
              </p>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    name: 'Influencing Styles Profiler',
    icon: REPORT_ICONS.get('ISP'),
    content: function() {
      return (
        <div className="row">
          <div className="col-xs-12 col-lg-4 short-description">
            <p>
              <img
                className="image text-center"
                src={'static/img/icons/isp-logo-lg.png'}
              />
            </p>
            <h3>ISP</h3>
            <p>{i18next.t('PARAGRAPH isp reporting intro')}</p>
          </div>
          <div className="col-xs-12 col-lg-8 description">
            <div className="container">
              <h3>ISP reporting</h3>
              <p>{i18next.t('PARAGRAPH isp reporting intro')}</p>
              <p>{i18next.t('PARAGRAPH isp reporting summary')}</p>
              <p>
                {i18next.t('Get in touch with one of our consultants on')}
                <a href="mailto:letushelp@peoplewise.co.uk">
                  {' '}
                  letushelp@peoplewise.co.uk
                </a>
                {i18next.t('PARAGRAPH more info on isp')}
              </p>
            </div>
          </div>
        </div>
      );
    },
  },
];

export class Reports extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 0,
      contentIndex: 0,
    };
  }

  nextSlide() {
    this.setState({
      slideIndex:
        this.state.slideIndex === slides.length - 1
          ? 0
          : this.state.slideIndex + 1,
      contentIndex: 0,
    });
  }

  prevSlide() {
    this.setState({
      slideIndex:
        this.state.slideIndex === 0
          ? slides.length - 1
          : this.state.slideIndex - 1,
      contentIndex: 0,
    });
  }

  goToSlide(index) {
    this.setState({
      slideIndex: index,
      contentIndex: 0,
    });
  }

  goToContent(index) {
    this.setState({
      contentIndex: index,
    });
  }

  render() {
    return (
      <div className="row asset-grid">
        <div className="col-lg-1 text-center arrow">
          <i
            onClick={() => this.prevSlide()}
            className="icon icon-left"
            aria-hidden="true"
          />
        </div>

        <div className="col-xs-12 col-lg-10 psychometrics">
          {slides[this.state.slideIndex].content.call(this)}
        </div>

        <div className="col-xs-hide col-lg-1 text-center arrow">
          <i
            onClick={() => this.nextSlide()}
            className="icon icon-right"
            aria-hidden="true"
          />
        </div>
        <div className="col-lg-offset-1 col-lg-10 col-xl-1 col-xl-10">
          <div className="survey-nav">
            {slides.map((slide, index) => (
              <div key={index} className="text-center logo-buttons">
                <a onClick={() => this.goToSlide(index)}>
                  <img className="image" src={slide.icon} />
                  {slide.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
