import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import moment from 'moment';
import i18next from 'i18next';

import { isPending, hasFailed, hasSucceeded } from '../responseStates';
import { signUpAnonymousUserAndOpenActivity } from '../actions/actionSequences';
import { loadActivityPublic } from '../actions/items';
import { ACTIVITY_STATUS, getStatus } from '../activityStatus';
import {
  formatDateTime,
  matchesUkDateFormat,
  UK_DATE_FORMAT,
  ISO_DATE_FORMAT,
} from '../utils';
import PureComponent from './PureComponent';
import Loading from './Loading';
import RedeemAnonymousForm from './RedeemAnonymousForm';
import NoAuth from './NoAuth';
import { SUPPORT_EMAIL } from '^/settings';
import { withRouter } from '^/withRouter';
import Alert from '^/components/Alert';

export class RedeemAnonymous extends PureComponent {
  componentDidMount() {
    this.props.loadActivityPublic(this.props.activityId);
  }

  renderActivityErrors() {
    const { activity } = this.props;

    if (!activity) {
      return <span />;
    }

    if (!activity.get('is_anonymous')) {
      return (
        <Alert>
          {i18next.t('This activity is not open to public access.')}
        </Alert>
      );
    }

    switch (getStatus(activity)) {
      case ACTIVITY_STATUS.SETUP:
        const startTime = activity.get('start_datetime'),
          endTime = activity.get('end_datetime');

        return (
          <Alert>
            {endTime && startTime ? (
              <span>
                Activity does not start until{' '}
                <i>{formatDateTime(startTime)}.</i>
              </span>
            ) : (
              <span>{i18next.t('Activity has not yet started.')}</span>
            )}
          </Alert>
        );
      case ACTIVITY_STATUS.SUSPENDED:
        return <Alert>{i18next.t('Activity has been suspended.')}</Alert>;
      case ACTIVITY_STATUS.ENDED:
        return (
          <Alert>
            {i18next.t('Unfortunately, this activity has now ended.')}
          </Alert>
        );
      case ACTIVITY_STATUS.LIVE:
      default:
        return null;
    }
  }

  signupUser(data) {
    const { date_of_birth } = data;
    const dob = matchesUkDateFormat(date_of_birth)
      ? moment(date_of_birth, UK_DATE_FORMAT).format(ISO_DATE_FORMAT)
      : date_of_birth;
    this.props.signUpAnonymousUserAndOpenActivity(dob, this.props.activityId);
  }

  navigateToLogin() {
    this.props.push(`/prepay/login/${this.props.activityId}`);
  }

  renderContactLink() {
    return (
      <p>
        {i18next.t('Please contact PeopleWise at')}{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
      </p>
    );
  }

  renderVerifyingActivity() {
    const { activityResponse } = this.props;

    if (isPending(activityResponse)) {
      return (
        <div>
          <Alert>{i18next.t('Verifying activity...')}</Alert>
          <Loading />
        </div>
      );
    } else if (hasFailed(activityResponse)) {
      return (
        <div>
          <Alert>{i18next.t('Sorry, this URL is invalid.')}</Alert>
          {this.renderContactLink()}
        </div>
      );
    }
    return null;
  }

  renderFormOrMessage() {
    const { response } = this.props;
    if (hasSucceeded(response)) {
      return <p>{i18next.t('Opening activity...')}</p>;
    }

    const activityErrors = this.renderActivityErrors();

    return activityErrors ? (
      <div>
        {activityErrors}
        {this.renderContactLink()}
      </div>
    ) : (
      <RedeemAnonymousForm
        response={response}
        navigateToLogin={() => this.navigateToLogin()}
        onSubmit={this.signupUser.bind(this)}
      />
    );
  }

  render() {
    return (
      <NoAuth noLang>
        <div className="form-horizontal">
          <h2>{i18next.t('Start activity')}</h2>
          {this.renderVerifyingActivity() || this.renderFormOrMessage()}
        </div>
      </NoAuth>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    activityResponse: state.responses.get('loadItem'),
    response: state.responses.get('signUpAnonymousUser'),
    activity: state.items.get('activities'),
    activityId: props.router.params.activity,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadActivityPublic,
    signUpAnonymousUserAndOpenActivity,
    push,
  })(RedeemAnonymous)
);
