import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router';

import { formatDateTime } from '^/utils';
import { getPulsingActionStatus } from '^/components/pulses/utils';

interface PulseSubBehaviour {
  id: string;
  name: string;
}

interface UserPulseResult {
  id: string;
  name: string;
  pulse_sub_behaviours: Array<PulseSubBehaviour>;
  number_of_raters: number;
  created: string;
  status: string;
}

interface UserPulseRequestData {
  count: number;
  next?: number;
  previous?: number;
  results: Array<UserPulseResult>;
}

export interface UserPulsesTableProps {
  userPulses: UserPulseRequestData;
}

class UserPulsesTable extends React.Component<UserPulsesTableProps> {
  concatenatePulseSubBehaviours(pulseSubBehaviours: Array<PulseSubBehaviour>) {
    if (pulseSubBehaviours.length === 0) {
      return i18next.t<string>('Not yet selected');
    }
    return pulseSubBehaviours.map(behaviour => behaviour.name).join(', ');
  }

  render() {
    return (
      <table data-testid="user-pulse-table">
        <thead>
          <tr>
            <th>Pulse name</th>
            <th>Behaviours</th>
            <th>No. Raters</th>
            <th>Start date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {this.props.userPulses.results.map((userPulse: any) => (
            <tr key={userPulse.pulse.id}>
              <td>
                <Link to={`/page/pulses/${userPulse.pulse.id}`}>
                  {userPulse.name}
                </Link>
              </td>
              <td>
                {this.concatenatePulseSubBehaviours(
                  userPulse.pulse_sub_behaviours
                )}
              </td>
              <td>{userPulse.number_of_raters}</td>
              <td>{formatDateTime(userPulse.created)}</td>
              <td>{getPulsingActionStatus(userPulse.status)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default UserPulsesTable;
