import { Map } from 'immutable';
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import _ from 'underscore';

import { getInviteDefaults } from '^/actions/actions';
import {
  addAndInviteUser,
  addUserAndCloseModal,
} from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import { administerOwnOrganisation, can } from '^/capabilities';
import PureComponent from '^/components/PureComponent';
import { LanguageCode } from '^/constants/routes';
import { allowsMore, allowsRole } from '^/models/organisation';
import { USER_ROLES } from '^/models/user';
import { StoreState } from '^/store';
import CreateUserForm from './CreateUserForm';

interface DispatchProps {
  getInviteDefaults: typeof getInviteDefaults;
  closeTopModal: typeof closeTopModal;
  addUserAndCloseModal: typeof addUserAndCloseModal;
  addAndInviteUser: typeof addAndInviteUser;
}

interface StateProps {
  user: StoreState['userProfile'];
  response: Map<string, any>;
  organisation: Map<string, any>;
  initialValues: Partial<CreateUserFormData>;
  inputTextLanguage: LanguageCode;
}

type Props = StateProps & DispatchProps;

interface CreateUserFormData {
  organisationId?: string;
  fullName?: string;
  email?: string;
  role?: string;
  opening?: string;
  closing?: string;
  submit?: unknown;
  invite?: unknown;
}

interface State {
  initialValues: Partial<CreateUserFormData>;
}

export class CreateUserModal extends PureComponent<Props, State> {
  public UNSAFE_componentWillMount() {
    this.props.getInviteDefaults();
    this.setState({
      initialValues: this.props.initialValues,
    });
  }

  public render() {
    const { user, organisation, response } = this.props;
    const { initialValues } = this.state;

    const disabledRoles = _.values(USER_ROLES).filter(
      role =>
        organisation &&
        (!allowsRole(organisation, role) || !allowsMore(organisation, role))
    );

    return (
      <CreateUserForm
        user={user}
        response={response}
        onSubmit={this.addUser}
        onClose={this.props.closeTopModal}
        disabledRoles={disabledRoles}
        initialValues={initialValues}
      />
    );
  }

  private addUser = (data: CreateUserFormData) => {
    const { user } = this.props;

    if (data.role === USER_ROLES.ADMIN) {
      delete data.organisationId;
    }

    if (data.invite) {
      this.props.addAndInviteUser(
        data.organisationId,
        data.fullName,
        data.role,
        data.email,
        data.opening,
        data.closing,
        this.props.inputTextLanguage
      );
    } else {
      this.props.addUserAndCloseModal(
        user.getIn(['organisation', 'id'], data.organisationId),
        data.fullName,
        data.role,
        data.email
      );
    }
  };
}

function mapStateToProps(
  state: Pick<StoreState, 'userProfile' | 'ui' | 'responses' | 'inviteDefaults'>
): StateProps {
  const user = state.userProfile;
  const selectedOrganisation = state.ui.getIn([
    'simpleComponentState',
    'CreateUserModal',
    'selectedOrganisation',
  ]);
  const canAdministerOwnOrganisation = can(user, administerOwnOrganisation());
  const inputTextLanguage = state.ui.get('inputTextLanguage') as LanguageCode;
  return {
    user,
    response: state.responses.get('addToCollection'),
    organisation: user.get('organisation') || selectedOrganisation,
    initialValues: {
      opening: state.inviteDefaults.opening_message?.[inputTextLanguage],
      organisationId: canAdministerOwnOrganisation
        ? user.getIn(['organisation', 'id'])
        : undefined,
    },
    inputTextLanguage,
  };
}

export default connect(mapStateToProps, {
  getInviteDefaults,
  closeTopModal,
  addUserAndCloseModal,
  addAndInviteUser,
})(CreateUserModal);
