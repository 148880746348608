/* eslint-disable import/no-named-as-default-member */
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import i18next from 'i18next';

import FormError from '^/components/FormError';
import {
  propTypes as nominationRulesPropTypes,
  NominationRuleFulfillment,
} from '^/nominationRules';
import propLists from '^/propLists';
import PureComponent from '^/components/PureComponent';
import SelectUserInputInner from '^/components/forms/SelectUserInputInner';
import {
  updateSelectUserQuery,
  setSimpleComponentState,
  clearSimpleComponentState,
  addRaterToActivity,
} from '^/actions/actions';
import { addRaterUserThen } from '^/actions/actionSequences';

const EMAIL_REGEX = /.+@.+\..+/i;

export class BaseAddRaterRow extends PureComponent {
  submitKnownUser(user, email) {
    this.props.updateSelectUserQuery(this.props.id, email);
  }

  componentWillUnmount() {
    this.props.clearSimpleComponentState(this.props.id);
  }

  addRater(userId, roleId, email) {
    this.props.updateSelectUserQuery(this.props.id, '');
    this.props.addRater(userId, roleId, email);
  }

  canAddMore(rule) {
    const { raters } = this.props;
    return new NominationRuleFulfillment(rule, raters).canHaveMore();
  }

  onSubmit() {
    const email = this.props.value;
    const selectedRole = this.props.selectedRole;
    this.props.addRaterUserThen(
      user => this.addRater(user.id, selectedRole, email),
      email,
      '',
      // TODO: change this to set external for line managers with approval is not required.
      // will need to then check the email is sent correctly
      !this.props.isLineManager,
      this.props.id
    );
    this.props.setSimpleComponentState([this.props.id, 'selectedRole'], '');
  }

  render() {
    const {
      value,
      selectedRole,
      isLineManager,
      setLineManagerResponse,
      addRaterToActivityResponse,
      columns,
    } = this.props;

    const invalidEmail = !value || !EMAIL_REGEX.exec(value);
    const roles = this.props.rules
      .filter(rule => this.canAddMore(rule))
      .map(rule =>
        List.of(rule.getIn(['role', 'id']), rule.getIn(['role', 'name']))
      )
      .toSet()
      .toList()
      .unshift(List.of('', i18next.t('Select...')));

    return (
      <tr className="top-aligned">
        <td data-header={columns[0].header}>
          {this.props.overrideRoleWith ? (
            this.props.overrideRoleWith
          ) : (
            <select
              value={this.props.selectedRole}
              onChange={event =>
                this.props.setSimpleComponentState(
                  [this.props.id, 'selectedRole'],
                  event.target.value
                )
              }
              name="role"
            >
              {propLists.toOptions(roles)}
            </select>
          )}
        </td>
        <td data-header={columns[1].header}>
          <div className="top-padded">
            <FormError
              response={
                isLineManager
                  ? setLineManagerResponse
                  : addRaterToActivityResponse
              }
              formKey="users"
            />
            <div className="max-450">
              <SelectUserInputInner
                id={this.props.id}
                disabled={
                  invalidEmail ||
                  (!selectedRole && !this.props.overrideRoleWith)
                }
                includeInactive
                organisationId={this.props.organisationId}
                excludedUserIds={this.props.excludedUserIds}
                hideAddButton={false}
                hideIcon
                submitKnownUser={this.submitKnownUser.bind(this)}
                submitUnknownUser={() => {}}
                onSubmit={() => this.onSubmit()}
                isTextInput
              />
            </div>
            {value && invalidEmail ? (
              <p className="help-inline help-inline-error margin-none">
                {i18next.t('Please enter a valid email address')}
              </p>
            ) : (
              <p className="help-inline margin-none">
                {i18next.t('Enter email address')}
              </p>
            )}
            {this.props.userError}
          </div>
        </td>
        <td data-header={columns[2].header}> </td>
      </tr>
    );
  }
}

BaseAddRaterRow.propTypes = {
  id: PropTypes.string.isRequired,
  rules: ImmutablePropTypes.listOf(nominationRulesPropTypes.NominationRule)
    .isRequired,
  activityId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  excludedUserIds: ImmutablePropTypes.list.isRequired,
  raters: ImmutablePropTypes.list.isRequired,
  addRater: PropTypes.func.isRequired,
  overrideRoleWith: PropTypes.string,
  userError: PropTypes.node,
  isLineManager: PropTypes.bool,
};

export function mapStateToProps(state, props) {
  const excludedUserIds = props.excludedUserIds || List();

  return {
    value: state.selectUserQuery.get(props.id),
    users: state.collections
      .getIn(['userOptions', 'items'], List())
      .filterNot(user => excludedUserIds.contains(user.get('id'))), //Janky, but the SelectUser component provides these for us
    selectedRole: state.ui.getIn([
      'simpleComponentState',
      props.id,
      'selectedRole',
    ]),
    setLineManagerResponse: state.responses.get('setLineManager'),
    addRaterToActivityResponse: state.responses.get('addRaterToActivity'),
  };
}

export default connect(mapStateToProps, {
  updateSelectUserQuery,
  setSimpleComponentState,
  clearSimpleComponentState,
  addRaterUserThen,
  addRaterToActivity,
})(BaseAddRaterRow);
