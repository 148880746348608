import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Map, List } from 'immutable';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import EditableWithLabel from '^/components/editable/EditableWithLabel';
import PureComponent from '../../../PureComponent';
import ResponseStatusIcon from '../ResponseStatusIcon';
import ActivityNominationRulesForm from './ActivityNominationRulesForm';
import ActivityNominationRule from './ActivityNominationRule';
import Alert from '^/components/Alert';
import Checkbox from '^/components/Checkbox';

export default class ActivityNominationRulesTab extends PureComponent {
  onNominationAdded(values) {
    // Split out our magic
    let output = {
      limit: values.limit,
      role: values.role,
    };

    if (values.limit !== 'UNRESTRICTED') {
      output.count = values.count;
    }

    this.props.createNominationRule(output);
  }

  renderForm(response, allRoles) {
    return (
      <ActivityNominationRulesForm
        onSubmit={this.onNominationAdded.bind(this)}
        response={response}
        allRoles={allRoles}
      />
    );
  }

  renderNoRoles() {
    return (
      <div>
        <p>
          {i18next.t(
            'There are no available roles - have you added any surveys?'
          )}
        </p>
      </div>
    );
  }

  renderNominationTexts() {
    const { activity, response, readOnly } = this.props;
    const activityId = activity.get('id');
    const activity360 = activity.get('activity360') || Map();
    const {
      nomination_text_before,
      nomination_text_after,
    } = activity360.toObject();

    const defaultNominationTextBefore = Map(activity).getIn([
      'product_versions',
      0,
      'productversion360',
      'nomination_text_before',
    ]);
    const defaultNominationTextAfter = Map(activity).getIn([
      'product_versions',
      0,
      'productversion360',
      'nomination_text_after',
    ]);

    return (
      <div className="row">
        <div className="col-xs-12">
          <h5>{i18next.t('Help Text for Nominations')}</h5>
          <Checkbox
            id="show_extra_text"
            checked={nomination_text_after !== ''}
            onChange={event =>
              this.props.updateFields({
                nomination_text_before: event.target.checked
                  ? defaultNominationTextBefore
                  : '',
                nomination_text_after: event.target.checked
                  ? defaultNominationTextAfter
                  : '',
              })
            }
            disabled={readOnly}
            label={i18next.t('Show extra text on nominations')}
            inline
          />
          {nomination_text_after !== '' && (
            <div>
              <EditableWithLabel
                multiline
                groupClassName="form-group-block"
                label={i18next.t('Text before nominations')}
                value={nomination_text_before}
                fieldName={`activities.${activityId}.nomination_text_before`}
                onSave={newValue =>
                  this.props.updateFields({ nomination_text_before: newValue })
                }
                response={response}
                readOnly={readOnly}
              />
              <div className="margin-top-medium">
                <EditableWithLabel
                  multiline
                  groupClassName="form-group-block"
                  label={i18next.t('Text after nominations')}
                  value={nomination_text_after}
                  fieldName={`activities.${activityId}.nomination_text_after`}
                  onSave={newValue =>
                    this.props.updateFields({ nomination_text_after: newValue })
                  }
                  response={response}
                  readOnly={readOnly}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      deleteRule,
      response,
      allRoles,
      activity,
      activityResponse,
      readOnly,
    } = this.props;
    const activity360 =
      activity.get('activity360') || Map({ nomination_rules: List() });
    const {
      line_manager_must_approve_raters,
      nomination_rules,
    } = activity360.toObject();

    return (
      <div>
        <div className="margin-bottom">
          <h5>{i18next.t('Line Manager Rules')}</h5>
          <Checkbox
            id="line_manager_must_approve"
            checked={line_manager_must_approve_raters}
            onChange={
              !readOnly &&
              (event =>
                this.props.updateFields({
                  line_manager_must_approve_raters: event.target.checked,
                }))
            }
            disabled={readOnly || isPending(activityResponse)}
            label={i18next.t('Line manager must approve raters')}
            inline
          />
          <ResponseStatusIcon
            response={activityResponse}
            alignLeft
            marginLeft
          />
        </div>
        <div className="margin-bottom">
          <h5>{i18next.t('Existing Nomination Rules')}</h5>
          {activity360.get('is_using_default_rules') && (
            <Alert>
              {i18next.t('These are the recommended default nomination rules')}
            </Alert>
          )}
          <ul className="added-list">
            {nomination_rules.map(rule => (
              <ActivityNominationRule
                key={rule.get('id')}
                rule={rule}
                deleteRule={deleteRule}
                response={response}
                readOnly={readOnly}
              />
            ))}
          </ul>
        </div>
        {!readOnly && (
          <div>
            <div>
              <h5>{i18next.t('Edit Nomination Rules')}</h5>
              <Alert>
                {i18next.t(
                  'Please ensure you remove the default rules before editing.'
                )}
              </Alert>
            </div>
            {allRoles.count()
              ? this.renderForm(response, allRoles)
              : this.renderNoRoles()}
          </div>
        )}
        {!nomination_rules.isEmpty() && this.renderNominationTexts()}
      </div>
    );
  }
}

ActivityNominationRulesTab.propTypes = {
  createNominationRule: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
  response: ImmutablePropTypes.map,
  activityResponse: ImmutablePropTypes.map,
  activity: ImmutablePropTypes.map,
  allRoles: ImmutablePropTypes.list.isRequired,
};
