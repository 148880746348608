/* eslint-disable @typescript-eslint/no-use-before-define */
import i18next from 'i18next';
import React, { Component, HTMLAttributes, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import Windowed from '^/components/Windowed';
import {
  Analytics360AggregateResults,
  AnalyticsAggregateExtraResults,
  AnalyticsAggregateResults,
  AnalyticsFactorDISCResults,
  AnalyticsFilters,
  AnalyticsSessionDISC,
  AnalyticsSessionDISCChunk,
  AnalyticsSessionsState,
  SubAggregate,
} from '^/reducers/api/types';
import { DATA_ANALYTICS_TABLE_STRUCTURE } from './DataAnalyticsTableStructure';
import Loading from '^/components/Loading';
import { ColumnGroup, Paginated } from './DataAnalyticsTable';
import { calcAngle, calcWidth, DISC_FACTORS, getDISCRowScores } from './utils';
import ToggleSwitch from '../buttons/ToggleSwitch';

interface OwnProps {
  count: number;
  page: number;
  loading: boolean;
  loadingPage: boolean;
  filters: AnalyticsFilters;
  aggregate: AnalyticsAggregateResults | Analytics360AggregateResults;
  aggregateExtra?: AnalyticsAggregateExtraResults;
  subaggregates: ReadonlyArray<SubAggregate>;
  onChangePage: (page: number) => void;
  ordering?: string;
  resultsByPage: AnalyticsSessionsState;
  user: Immutable.Map<string, any>;
  filtersCollapsed: boolean;
  isTeamChart: boolean;
  downloadingImages: boolean;
  imageDownloadBlockNumber: number;
  imageDownloadTotalBlocks: number;
}

type SwitcherChoiceType = 'ALL' | 'SELF_IMAGE' | 'WORK_MASK' | 'STRESS_IMAGE';

interface State {
  columnGroups: ReadonlyArray<ColumnGroup>;
  switcherChoice: SwitcherChoiceType;
}

type Props = OwnProps;

const PAGE_SIZE = 20;
const ROW_HEIGHT = 440;

const SWITCHER_CHOICES = [
  { type: 'ALL', name: i18next.t<string>('All'), code: 'All' },
  { type: 'SELF_IMAGE', name: i18next.t<string>('Self image'), code: 'CHANGE' },
  { type: 'WORK_MASK', name: i18next.t<string>('Work mask'), code: 'MOST' },
  {
    type: 'STRESS_IMAGE',
    name: i18next.t<string>('Stress image'),
    code: 'LEAST',
  },
];

const ChartName = ({ children }: { children: ReactNode }) => {
  const id = String(Math.random());
  return (
    <div>
      <p className="individual-name" data-tip data-for={id}>
        {children}
      </p>
      <ReactTooltip
        id={id}
        className="tooltip"
        textColor="black"
        backgroundColor="white"
        border
        borderColor="#C9CCD6"
      >
        {children}
      </ReactTooltip>
    </div>
  );
};

const buildTeamName = (name: string, count: number, teamIndex: number) =>
  i18next.t<string>('Team {{number}} {{name}} (N = {{count}})', {
    number: teamIndex + 1,
    name,
    count,
  });

export default class DISCCharts extends Component<Props, State> {
  scrollingElement: React.RefObject<HTMLTableSectionElement>;
  scrollContainerElement: React.RefObject<HTMLTableSectionElement>;
  constructor(props: Props) {
    super(props);
    this.scrollingElement = React.createRef();
    this.scrollContainerElement = React.createRef();
    const columnGroups = DATA_ANALYTICS_TABLE_STRUCTURE['DISC'];
    this.state = {
      switcherChoice: 'ALL',
      columnGroups: columnGroups!.map(columnGroup => ({
        ...columnGroup,
        isExpanded: true,
      })),
    };
  }
  public renderImageDownloadBlocks = (
    totalBlocks: number,
    currentBlock: number
  ) => {
    const { resultsByPage } = this.props;
    const loaded =
      !resultsByPage.some(items => items === null) &&
      resultsByPage.length === totalBlocks;
    return (
      <>
        {loaded &&
          resultsByPage.map((block, blockIndex) =>
            blockIndex === currentBlock
              ? block?.map((row, index) =>
                  this.renderChartRow(
                    {
                      item: row as AnalyticsSessionDISC,
                      index: blockIndex + index,
                      key: blockIndex + index,
                    },
                    true
                  )
                )
              : ''
          )}
      </>
    );
  };

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      count,
      ordering,
      filtersCollapsed,
      isTeamChart,
      subaggregates,
    } = this.props;
    const { switcherChoice } = this.state;
    if (
      prevState.switcherChoice === 'WORK_MASK' &&
      prevProps.isTeamChart === isTeamChart &&
      prevProps.subaggregates !== subaggregates
    ) {
      this.setState({ switcherChoice: 'ALL' });
    }
    if (
      prevProps.count !== count ||
      prevProps.ordering !== ordering ||
      prevProps.filtersCollapsed !== filtersCollapsed ||
      prevState.switcherChoice !== switcherChoice ||
      prevProps.isTeamChart !== isTeamChart ||
      prevProps.subaggregates !== subaggregates
    ) {
      if (
        prevState.switcherChoice === 'WORK_MASK' &&
        prevProps.isTeamChart !== isTeamChart
      ) {
        this.setState({ switcherChoice: 'ALL' });
      }
      if (this.scrollContainerElement.current) {
        this.scrollContainerElement.current.scrollTop = 0;
      }
    }
  }

  public setSwitcher = (choice: SwitcherChoiceType) => {
    return this.setState({ switcherChoice: choice });
  };

  public render() {
    const {
      count,
      loading,
      filters,
      loadingPage,
      resultsByPage,
      onChangePage,
      filtersCollapsed,
      isTeamChart,
      subaggregates,
      aggregate,
      aggregateExtra,
    } = this.props;
    const { switcherChoice } = this.state;
    const downloadImageBlockCount = Math.ceil(count / PAGE_SIZE);
    const getItems = (chunk: Paginated<AnalyticsSessionDISCChunk>) => chunk;
    const loadChunk = (chunkIndex: number) => {
      onChangePage(chunkIndex + 1);
    };
    const rowHeight =
      switcherChoice === 'ALL'
        ? ROW_HEIGHT
        : Math.floor(ROW_HEIGHT / (filtersCollapsed ? 4 : 3));
    const chunkPadding = Math.abs(count / PAGE_SIZE);
    const areSubAggs = subaggregates.length > 0;
    const showIndividualCharts = !isTeamChart || (!areSubAggs && isTeamChart);
    return (
      <div
        className={classNames(
          isTeamChart
            ? 'control-charts-wrapper-team static-page'
            : 'control-charts-wrapper'
        )}
        id="summary-charts"
      >
        {isTeamChart && (
          <>
            <div>
              <div className="title-text">
                {i18next.t<string>('DISC -')}
                {i18next.t<string>('Team summary')}
              </div>
              <div
                id="team-disc-charts-element"
                className="line-chart-agg-group-wrapper"
              >
                <ChartName>
                  {areSubAggs
                    ? i18next.t<string>(
                        'Aggregate (N = {{count}}) x {{numTeams}} teams',
                        {
                          count,
                          numTeams: subaggregates.length,
                        }
                      )
                    : i18next.t<string>('Aggregate (N = {{count}})', { count })}
                </ChartName>
                <div className="style-pattern">
                  <div>
                    <span>{i18next.t<string>('Style')}: </span>
                    {aggregateExtra?.self_image_style_name}
                  </div>
                  <div>
                    <span className="pattern">
                      <span>{i18next.t<string>('Pattern')}: </span>
                      {aggregateExtra?.self_image_pattern_text}
                    </span>
                  </div>
                </div>

                {this.renderChartBlock(
                  true,
                  aggregate as AnalyticsAggregateResults,
                  'CHANGE',
                  i18next.t<string>('Self image')
                )}
                {this.renderChartBlock(
                  true,
                  aggregate as AnalyticsAggregateResults,
                  'LEAST',
                  i18next.t<string>('Stress image')
                )}
              </div>
            </div>

            {areSubAggs && (
              <div>
                <div>
                  <div className="title-text">{i18next.t<string>('Teams')}</div>
                </div>
                <div className="switcher">
                  <ToggleSwitch
                    items={SWITCHER_CHOICES.map(choices => {
                      return {
                        id: choices.type,
                        name: choices.name,
                        selected: switcherChoice === choices.type,
                      };
                    }).filter(choice => choice.id !== 'WORK_MASK')}
                    onClick={(choice: SwitcherChoiceType) =>
                      this.setSwitcher(choice)
                    }
                  />
                </div>

                <div
                  id="summary-charts"
                  className={classNames(
                    switcherChoice === 'ALL' && 'chart2columns',
                    'team-charts-wrapper',
                    !filtersCollapsed &&
                      switcherChoice !== 'ALL' &&
                      'chart3columns',
                    filtersCollapsed &&
                      switcherChoice !== 'ALL' &&
                      'chart4columns'
                  )}
                >
                  {subaggregates.map((sub, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classNames(
                          'line-chart-group-wrapper',
                          switcherChoice !== 'ALL' && 'single-width'
                        )}
                      >
                        <ChartName>
                          {buildTeamName(
                            filters[sub.key as keyof AnalyticsFilters].find(
                              each => each.id === sub.value
                            )?.name || '',
                            sub.filtersState?.sessions?.count || 0,
                            idx
                          )}
                        </ChartName>
                        <div className="style-pattern">
                          <div>
                            <span>{i18next.t<string>('Style')}: </span>
                            {
                              sub.filtersState?.sessions?.aggregate_extra
                                ?.self_image_style_name
                            }
                          </div>
                          <div>
                            <span className="pattern">
                              <span>{i18next.t<string>('Pattern')}: </span>
                              {
                                sub.filtersState?.sessions?.aggregate_extra
                                  ?.self_image_pattern_text
                              }
                            </span>
                          </div>
                        </div>
                        {(switcherChoice === 'ALL' ||
                          switcherChoice === 'SELF_IMAGE') &&
                          this.renderChartBlock(
                            true,
                            sub.filtersState?.sessions
                              ?.aggregate as AnalyticsAggregateResults,
                            'CHANGE',
                            i18next.t<string>('Self image')
                          )}
                        {(switcherChoice === 'ALL' ||
                          switcherChoice === 'STRESS_IMAGE') &&
                          this.renderChartBlock(
                            true,
                            sub.filtersState?.sessions
                              ?.aggregate as AnalyticsAggregateResults,
                            'LEAST',
                            i18next.t<string>('Stress image')
                          )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
        {showIndividualCharts && (
          <>
            {isTeamChart && (
              <div>
                <div className="title-text">
                  {i18next.t<string>('Individuals')}
                </div>
              </div>
            )}
            <div className="switcher">
              <ToggleSwitch
                items={SWITCHER_CHOICES.map(choices => {
                  return {
                    id: choices.type,
                    name: choices.name,
                    selected: switcherChoice === choices.type,
                  };
                })}
                onClick={(choice: SwitcherChoiceType) =>
                  this.setSwitcher(choice)
                }
              />
            </div>
            {!isTeamChart && (
              <div className="switcher-text">
                {i18next.t<string>('DISC -')}
                {switcherChoice === 'ALL'
                  ? i18next.t<string>('Individual')
                  : SWITCHER_CHOICES.filter(
                      choices => choices?.type === switcherChoice
                    ).map(choice => choice.name)}
              </div>
            )}

            {this.props.downloadingImages && (
              <div
                key={this.props.imageDownloadBlockNumber}
                className="download-image-div-individual"
                id={
                  'individual-disc-charts-element-' +
                  this.props.imageDownloadBlockNumber
                }
              >
                {this.renderImageDownloadBlocks(
                  downloadImageBlockCount,
                  this.props.imageDownloadBlockNumber
                )}
              </div>
            )}
            <div
              className="data-analytics-chart-wrapper"
              ref={this.scrollContainerElement}
            >
              <table className="data-analytics-chart-table">
                <tbody
                  id="chart-table"
                  className={classNames(
                    !filtersCollapsed &&
                      switcherChoice !== 'ALL' &&
                      'chart3columns',
                    filtersCollapsed &&
                      switcherChoice !== 'ALL' &&
                      'chart4columns'
                  )}
                  ref={this.scrollingElement}
                  style={{ overflowAnchor: 'none' }}
                >
                  {(!this.scrollingElement || loading) && (
                    <Loading className="list-loading" />
                  )}
                  <Windowed
                    parentElement={this.scrollingElement.current}
                    scrollClassName=".data-analytics-chart-wrapper"
                    chunkSize={PAGE_SIZE}
                    total={count}
                    data={resultsByPage}
                    row={this.renderChartRow}
                    spacer={this.renderChartSpacer}
                    rowHeight={rowHeight}
                    topOffset={0}
                    chunkPadding={chunkPadding}
                    loading={loadingPage}
                    loadChunk={loadChunk}
                    getItems={getItems}
                  />
                </tbody>
              </table>
              <ReactTooltip
                delayShow={200}
                textColor="black"
                backgroundColor="white"
                border
                borderColor="#C9CCD6"
              />
            </div>
          </>
        )}
      </div>
    );
  }

  public renderChartSpacer = (props: HTMLAttributes<HTMLElement>) => {
    const { switcherChoice } = this.state;
    return (
      <>
        <div
          {...props}
          className={classNames(
            'line-chart-group-wrapper',
            this.state.switcherChoice !== 'ALL' && 'single-width'
          )}
        >
          {(switcherChoice === 'ALL' || switcherChoice === 'SELF_IMAGE') && (
            <div className="line-chart-wrapper-outer" />
          )}
          {(switcherChoice === 'ALL' || switcherChoice === 'WORK_MASK') && (
            <div className="line-chart-wrapper-outer" />
          )}
          {(switcherChoice === 'ALL' || switcherChoice === 'STRESS_IMAGE') && (
            <div className="line-chart-wrapper-outer" />
          )}
        </div>
      </>
    );
  };

  public renderChartRow = (
    chunk: AnalyticsSessionDISCChunk,
    isDownload?: boolean
  ) => {
    const {
      item: { results, extra_results },
    } = chunk;
    const { switcherChoice } = this.state;
    return (
      <>
        <div
          className={classNames(
            'line-chart-group-wrapper',
            switcherChoice !== 'ALL' && 'single-width'
          )}
        >
          {isDownload ? (
            <p className="individual-name">
              <span>{chunk.item.user.full_name}</span>
            </p>
          ) : (
            <ChartName>{chunk.item.user.full_name}</ChartName>
          )}
          <div className="style-pattern">
            <span className="style">
              <span>{i18next.t<string>('Style')}: </span>
              <span>{extra_results?.self_image_style_name}</span>
            </span>

            <span className="pattern">
              <span>{i18next.t<string>('Pattern')}: </span>
              <span>{extra_results?.self_image_pattern_text}</span>
            </span>
          </div>
          {(switcherChoice === 'ALL' || switcherChoice === 'SELF_IMAGE') &&
            this.renderChartBlock(
              false,
              results,
              'CHANGE',
              i18next.t<string>('Self image')
            )}
          {(switcherChoice === 'ALL' || switcherChoice === 'WORK_MASK') &&
            this.renderChartBlock(
              false,
              results,
              'MOST',
              i18next.t<string>('Work mask')
            )}
          {(switcherChoice === 'ALL' || switcherChoice === 'STRESS_IMAGE') &&
            this.renderChartBlock(
              false,
              results,
              'LEAST',
              i18next.t<string>('Stress image')
            )}
        </div>
      </>
    );
  };

  public renderChartRowDownload = (chunk: AnalyticsSessionDISCChunk) => {
    const {
      item: { results, extra_results },
    } = chunk;
    const { switcherChoice } = this.state;
    return (
      <>
        <div
          className={classNames(
            'line-chart-group-wrapper',
            switcherChoice !== 'ALL' && 'single-width'
          )}
        >
          <div className="style-pattern">
            <div>
              <span>{i18next.t<string>('Style')}: </span>
              {extra_results?.self_image_style_name}
            </div>
            <div>
              <span className="pattern">
                <span>{i18next.t<string>('Pattern')}: </span>
                {extra_results?.self_image_pattern_text}
              </span>
            </div>
          </div>
          {(switcherChoice === 'ALL' || switcherChoice === 'SELF_IMAGE') &&
            this.renderChartBlock(
              false,
              results,
              'CHANGE',
              i18next.t<string>('Self image')
            )}
          {(switcherChoice === 'ALL' || switcherChoice === 'WORK_MASK') &&
            this.renderChartBlock(
              false,
              results,
              'MOST',
              i18next.t<string>('Work mask')
            )}
          {(switcherChoice === 'ALL' || switcherChoice === 'STRESS_IMAGE') &&
            this.renderChartBlock(
              false,
              results,
              'LEAST',
              i18next.t<string>('Stress image')
            )}
        </div>
      </>
    );
  };

  public renderChartBlock = (
    isAggregate: boolean,
    results: AnalyticsFactorDISCResults | AnalyticsAggregateResults,
    code: string,
    title: string
  ) => {
    return (
      <>
        <div className="line-chart-wrapper-outer">
          <div className="chart-title">{title}</div>
          {
            <DISCChart
              scores={getDISCRowScores(
                isAggregate ? null : (results as AnalyticsFactorDISCResults),
                isAggregate ? (results as AnalyticsAggregateResults) : null,
                code
              )}
            />
          }
          <div className="chartKey">
            {DISC_FACTORS.map((factor, idx) => (
              <div key={idx} className={classNames(factor.toLowerCase())}>
                {factor}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
}

export const DISCChart = (props: { scores: number[] }) => {
  const { scores } = props;
  const dTopPos = scores[0] * 3 - 1;
  const iTopPos = scores[1] * 3 - 1;
  const sTopPos = scores[2] * 3 - 1;
  const cTopPos = scores[3] * 3 - 1;
  const xWidth = 33;
  return (
    <div className="line-chart-wrapper">
      <div className="box1" />
      <div className="box2" />
      <div className="box3" />
      <div className="line1" />
      <div className="line2" />
      <div className="line3" />
      <ul>
        <li className="dot_d" style={{ left: '12px', top: `${dTopPos}px` }}>
          <div
            className="joint_di"
            style={{
              transform: `rotate(${calcAngle(xWidth, dTopPos - iTopPos)}deg)`,
              width: calcWidth(xWidth, dTopPos - iTopPos),
            }}
          />
        </li>
        <li className="dot_i" style={{ left: '45px', top: `${iTopPos}px` }}>
          <div
            className="joint_is"
            style={{
              width: calcWidth(xWidth, iTopPos - sTopPos),
              transform: `rotate(${calcAngle(xWidth, iTopPos - sTopPos)}deg)`,
            }}
          />
        </li>
        <li className="dot_s" style={{ left: '78px', top: `${sTopPos}px` }}>
          <div
            className="joint_sc"
            style={{
              width: calcWidth(xWidth, sTopPos - cTopPos),
              transform: `rotate(${calcAngle(xWidth, sTopPos - cTopPos)}deg)`,
            }}
          />
        </li>
        <li className="dot_c" style={{ left: '111px', top: `${cTopPos}px` }} />
      </ul>
    </div>
  );
};
