import {
  faArrowToRight,
  faArrowToLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import i18next from 'i18next';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  collapsed: boolean;
}

export default class CollapsibleSidebar extends React.Component<Props, State> {
  public readonly state = {
    collapsed: false,
  };

  public render() {
    const { children } = this.props;
    const { collapsed } = this.state;

    return (
      <div
        className={classnames(
          'report-filters-wrapper',
          collapsed && 'collapsed-filters'
        )}
      >
        <div
          className={classnames('filter-title-text', {
            vertical: collapsed,
          })}
        >
          {i18next.t<string>('Filters')}
        </div>
        <div className="filter-expand-icon" onClick={this.collapseFilters}>
          <FontAwesomeIcon
            className="margin-none filter-expand-icon-inner"
            icon={collapsed ? faArrowToRight : faArrowToLeft}
          />
        </div>

        <div className={classnames(collapsed && 'hide-filters')}>
          {children}
        </div>
      </div>
    );
  }

  private collapseFilters = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
}
