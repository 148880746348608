import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';

import { createResponseComponent } from '../response-hoc';
import { ResponseComponentProps } from '../response-hoc/types';
import { ConnectedEditable as Editable } from './Editable';
import { EditableWithLabelProps } from './types';

const EditableWithLabel = ({
  required,
  label,
  groupClassName,
  ...props
}: EditableWithLabelProps & ResponseComponentProps) => (
  <div className={classNames(groupClassName)}>
    <label>
      {label}{' '}
      {required && (
        <span className="help-inline">{i18next.t<string>('required')}</span>
      )}
    </label>
    <Editable {...props} block />
  </div>
);

export { EditableWithLabel };

export default createResponseComponent(EditableWithLabel);
