import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import CodeSample from './CodeSample';
import NoAuth from '../NoAuth';

export default class LoginBox extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Login box</PageHeader>

        <NoAuth>
          <div className="form-horizontal">
            <h2>Login</h2>
            <label>
              Username
              <input type="text" placeholder="Username" />
            </label>

            <label>
              Password
              <input type="text" placeholder="Password" />
            </label>
            <button className="btn btn-primary col-xs-12">Login</button>
            <button className="btn btn-default col-xs-12">Register</button>
            <button className="btn btn-default col-xs-12">
              Forgot Password?
            </button>
          </div>
        </NoAuth>

        <CodeSample
          code={`<NoAuth>
  <div className="form-horizontal">
    <h2>
      Login
    </h2>
    <label>
      Username
      <input type="text" placeholder="Username"/>
    </label>

    <label>
      Password
      <input type="text" placeholder="Password"/>
    </label>
    <button className="btn btn-primary col-xs-12">
      Login
    </button>
    <button className="btn btn-default col-xs-12">
      Register
    </button>
    <button className="btn btn-default col-xs-12">
      Forgot Password?
    </button>
  </div>
</NoAuth>`}
        />
      </div>
    );
  }
}
