import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';

import { propTypes as nominationRulesPropTypes } from '^/nominationRules';
import { APPROVAL_STATUS } from '^/approvalStatus';
import { isPending } from '^/responseStates';
import PureComponent from '^/components/PureComponent';
import Table from '^/components/Table';
import ApprovalStatus from '^/components/activities/ApprovalStatus';
import AddRaterRow from '^/components/dashboard/enduser/manage_raters/AddRaterRow';
import AddLineManager from '^/components/dashboard/enduser/manage_raters/AddLineManager';
import UserNameAndEmail from '^/components/UserNameAndEmail';

export default class ManageRatersTable extends PureComponent {
  renderAction(row) {
    return (
      !isPending(this.props.removeRaterResponse) &&
      !this.props.activity.get('raters_submitted') &&
      !this.isRaterApproved(row) && (
        <a className="underlined" onClick={() => this.removeRater(row)}>
          {i18next.t('Remove')}
        </a>
      )
    );
  }

  renderApprovalStatus(activityRater) {
    if (!this.props.lineManagerMustApproveRaters) {
      return null;
    }

    const ratersSubmitted = this.props.ratersSubmitted;

    return (
      <ApprovalStatus
        status={activityRater.get('approved_by_line_manager')}
        ratersSubmitted={ratersSubmitted}
      />
    );
  }

  removeRater(rater) {
    const roleId = rater.getIn(['role', 'id']);
    this.props.removeRaterFromActivity(roleId, rater.get('id'), roleId);
  }

  mustLineManagerApproveRaters() {
    return this.props.activity.getIn(
      ['activity360', 'line_manager_must_approve_raters'],
      false
    );
  }

  isRaterApproved(rater) {
    return (
      this.mustLineManagerApproveRaters() &&
      rater.get('approved_by_line_manager') === APPROVAL_STATUS.APPROVED
    );
  }

  isRaterRejected(rater) {
    return (
      this.mustLineManagerApproveRaters() &&
      rater.get('approved_by_line_manager') === APPROVAL_STATUS.REJECTED
    );
  }

  isRaterPending(rater) {
    return (
      this.mustLineManagerApproveRaters() &&
      rater.get('approved_by_line_manager') ===
        APPROVAL_STATUS.AWAITING_APPROVAL
    );
  }

  render() {
    const columns = [
      {
        header: i18next.t('Role'),
        value: row => row.getIn(['role', 'name']),
      },
      {
        header: i18next.t('Email'),
        value: row => (
          <UserNameAndEmail
            user={row.get('rater')}
            action={this.renderAction(row)}
            inline
          />
        ),
      },
      {
        header: i18next.t('Status'),
        value: this.renderApprovalStatus.bind(this),
      },
    ];

    return (
      <div>
        <Table
          collapse="md"
          columns={columns}
          prefixRows={[
            <AddLineManager
              key="0"
              id="add-line-manager"
              rules={this.props.rules}
              activity={this.props.activity}
              organisationId={this.props.organisationId}
              excludedUserIds={this.props.excludedUserIds}
              raters={this.props.raters}
              columns={columns}
            />,
          ]}
          rows={this.props.raters.filter(rater => rater.get('role'))}
          suffixRows={[
            !this.props.activity.get('raters_submitted') && (
              <AddRaterRow
                key="0"
                id="add-rater-row"
                rules={this.props.rules}
                activityId={this.props.activity.get('id')}
                organisationId={this.props.organisationId}
                excludedUserIds={this.props.excludedUserIds}
                raters={this.props.raters}
                columns={columns}
              />
            ),
          ]}
        />
      </div>
    );
  }
}

ManageRatersTable.propTypes = {
  ratersSubmitted: PropTypes.bool.isRequired,
  raters: ImmutablePropTypes.list.isRequired,
  lineManagerMustApproveRaters: PropTypes.bool.isRequired,
  removeRaterFromActivity: PropTypes.func.isRequired,
  removeRaterResponse: ImmutablePropTypes.map.isRequired,
  excludedUserIds: ImmutablePropTypes.list.isRequired,
  rules: ImmutablePropTypes.listOf(nominationRulesPropTypes.NominationRule)
    .isRequired,
  activity: ImmutablePropTypes.map.isRequired,
  organisationId: PropTypes.string.isRequired,
};
