/* eslint-disable no-prototype-builtins */
import _ from 'underscore';
import { List } from 'immutable';

import { USER_STATUS, USER_ROLES } from '^/models/user';
import { makeChoices } from '^/utils-ts';

const MAX_SOLE_PRACTITIONER_ADMINS = 1;

export const ACCOUNT_TYPE = {
  ORGANISATION: 'ORGANISATION',
  PW_MANAGED: 'PW_MANAGED',
  SOLE_PRACTITIONER: 'SOLE_PRACTITIONER',
  ENTERPRISE_LITE: 'ENTERPRISE_LITE',
  ENTERPRISE: 'ENTERPRISE',
};

export const ACCOUNT_TYPE_DISPLAY = {
  [ACCOUNT_TYPE.ORGANISATION]: 'Organisation',
  [ACCOUNT_TYPE.PW_MANAGED]: 'PeopleWise Managed',
  [ACCOUNT_TYPE.SOLE_PRACTITIONER]: 'Sole Practitioner',
  [ACCOUNT_TYPE.ENTERPRISE_LITE]: 'Enterprise Lite',
  [ACCOUNT_TYPE.ENTERPRISE]: 'Enterprise Licence',
};

function makeCreditOptionChoices(choices) {
  return {
    CHOICES: _.object(choices.map(([key]) => [key, key])),
    DISPLAY: _.object(choices),
    ORDER: choices.map(([key]) => key),
  };
}

export const ACCOUNT_CREDIT_OPTIONS = makeCreditOptionChoices([
  ['PAY_AS_YOU_GO', 'PAYG'],
  ['UNLIMITED', 'Unlimited'],
]);

export const ACCOUNT_ADMIN_ROLE = {
  ACCOUNT_SUPERUSER: 'ACCOUNT_SUPERUSER',
  ACCOUNT_PROFESSIONAL_PRACTITIONER: 'ACCOUNT_PROFESSIONAL_PRACTITIONER',
  ACCOUNT_ORG_ADMIN: 'ACCOUNT_ORG_ADMIN',
};

export const ACCOUNT_ADMIN_ROLE_DISPLAY = {
  [ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER]: 'Superuser',
  [ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN]: 'Account Org Admin',
  [ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER]:
    'Professional Practitioner',
};

export const ACCOUNT_TYPE_UPGRADE_HIERARCHY = [
  ACCOUNT_TYPE.PW_MANAGED,
  ACCOUNT_TYPE.SOLE_PRACTITIONER,
  ACCOUNT_TYPE.ENTERPRISE_LITE,
  ACCOUNT_TYPE.ENTERPRISE,
];

export const isAccount = organisation =>
  organisation &&
  organisation.get('account_type') !== ACCOUNT_TYPE.ORGANISATION;

export const accountTypeNeedsSuperUser = accountType =>
  [
    ACCOUNT_TYPE.SOLE_PRACTITIONER,
    ACCOUNT_TYPE.ENTERPRISE,
    ACCOUNT_TYPE.ENTERPRISE_LITE,
  ].includes(accountType);

export const accountTypeAllowsUnlimitedCredits = accountType =>
  [ACCOUNT_TYPE.ENTERPRISE].includes(accountType);

const getAccountTypeDetail = organisation =>
  organisation.get('has_unlimited_credits')
    ? ACCOUNT_CREDIT_OPTIONS.CHOICES.UNLIMITED
    : ACCOUNT_CREDIT_OPTIONS.CHOICES.PAY_AS_YOU_GO;

export const getAccountTypeDisplay = organisation => {
  const { account_type } = organisation.toObject();
  const accountTypeDisplay = ACCOUNT_TYPE_DISPLAY[account_type];
  if (
    account_type !== ACCOUNT_TYPE.PW_MANAGED &&
    accountTypeAllowsUnlimitedCredits(account_type)
  ) {
    return `${accountTypeDisplay} - ${
      ACCOUNT_CREDIT_OPTIONS.DISPLAY[getAccountTypeDetail(organisation)]
    }`;
  }
  return accountTypeDisplay;
};

export const accountHasUnlimitedCredits = organisation => {
  const { account_type, has_unlimited_credits } = organisation.toObject();
  return (
    accountTypeAllowsUnlimitedCredits(account_type) && has_unlimited_credits
  );
};

export const accountCanHaveAdmins = account =>
  [
    ACCOUNT_TYPE.SOLE_PRACTITIONER,
    ACCOUNT_TYPE.ENTERPRISE,
    ACCOUNT_TYPE.ENTERPRISE_LITE,
  ].includes(account.get('account_type'));

export const accountCanAddAdmins = account =>
  [ACCOUNT_TYPE.ENTERPRISE, ACCOUNT_TYPE.ENTERPRISE_LITE].includes(
    account.get('account_type')
  );

const isOrgOrEnterprise = account =>
  [ACCOUNT_TYPE.ORGANISATION, ACCOUNT_TYPE.ENTERPRISE].includes(
    account.get('account_type')
  );
export const accountAllowsDataExports = isOrgOrEnterprise;
export const accountSeesAdvancedDashboardTiles = isOrgOrEnterprise;
export const accountAllowsDataAnalytics = isOrgOrEnterprise;

export const accountAllowsSecureShareAccess = account =>
  account.get('account_type') === ACCOUNT_TYPE.ENTERPRISE;

export const accountIsUsingCredit = account =>
  account.get('is_using_credit') === true;

export const needsSuperUser = account =>
  accountTypeNeedsSuperUser(account.get('account_type'));

export const hasSuperUser = account =>
  account
    .get('admins', List())
    .some(user => user.get('role') === ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER);

export const ACCOUNT_ADMIN_ROLES_FOR_ACCOUNT_TYPE = {
  [ACCOUNT_TYPE.ENTERPRISE]: [
    ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER,
    ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER,
    ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN,
  ],
  [ACCOUNT_TYPE.ENTERPRISE_LITE]: [
    ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER,
    ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER,
    ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN,
  ],
  [ACCOUNT_TYPE.SOLE_PRACTITIONER]: [ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER],
  [ACCOUNT_TYPE.PW_MANAGED]: [ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER],
  [ACCOUNT_TYPE.ORGANISATION]: [],
};

export const getAvailableAdminRoles = account =>
  ACCOUNT_ADMIN_ROLES_FOR_ACCOUNT_TYPE[account.get('account_type')];

export function getAccountAdminHeader(accountType) {
  return accountType === ACCOUNT_TYPE.SOLE_PRACTITIONER
    ? 'Admin users'
    : 'Account admins';
}

export const countRoleActive = (account, role) =>
  account
    .get('admins')
    .filter(
      user =>
        user.get('role') === role && user.get('status') === USER_STATUS.ACTIVE
    )
    .count();

export function limitForRole(account, role) {
  switch (role) {
    case ACCOUNT_ADMIN_ROLE.ACCOUNT_SUPERUSER:
      return account.get('account_type') === ACCOUNT_TYPE.SOLE_PRACTITIONER
        ? MAX_SOLE_PRACTITIONER_ADMINS
        : account.get('max_number_superusers');
    case ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN:
      return account.get('max_number_org_admins');
    case ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER:
    default:
      return null;
  }
}

export function allowsMore(account, role) {
  const max = limitForRole(account, role);
  const currentCount =
    account.get('account_type') === ACCOUNT_TYPE.SOLE_PRACTITIONER
      ? account.get('admins').count()
      : countRoleActive(account, role);

  return max === null || max > currentCount;
}

export function isAccountAdmin(user) {
  return ACCOUNT_ADMIN_ROLE.hasOwnProperty(user.get('role'));
}

export const CREDIT_LOG_ACTION_TYPE = {
  PURCHASED_CREDITS: 'PURCHASED_CREDITS',
  REPORT_GENERATED: 'REPORT_GENERATED',
  CREDITS_EXPIRED: 'CREDITS_EXPIRED',
  CREDITS_REMOVED: 'CREDITS_REMOVED',
};

export const CREDIT_LOG_ACTION_TYPE_DISPLAY = {
  [CREDIT_LOG_ACTION_TYPE.PURCHASED_CREDITS]: 'Purchased credits',
  [CREDIT_LOG_ACTION_TYPE.REPORT_GENERATED]: 'Report generated',
  [CREDIT_LOG_ACTION_TYPE.CREDITS_EXPIRED]: 'Credits expired',
  [CREDIT_LOG_ACTION_TYPE.CREDITS_REMOVED]: 'Miscellaneous',
};

export function allowsRole(organisation, role) {
  const isAccountAdminRole = Object.keys(ACCOUNT_ADMIN_ROLE).includes(role);

  if (isAccountAdminRole) {
    return getAvailableAdminRoles(organisation).includes(role);
  }

  if (isAccount(organisation)) {
    return role !== USER_ROLES.ORGADMIN;
  }

  return true;
}

export const TAX_LOCATION = makeChoices([
  ['UK', 'UK'],
  ['EU', 'EU'],
  ['REST_OF_WORLD', 'Rest of world'],
]);
