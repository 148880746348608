import { List } from 'immutable';
import createCachedSelector from 're-reselect';

import { getActivityUsersCollectionName } from '^/actions/collections';
import { COMPLETED_SURVEY_STATUSES } from '^/models/user';
import { StoreState } from '^/store';

const selectActivityUsers = createCachedSelector(
  (_: StoreState, activityId: string) => activityId,
  (state: StoreState) => state.collections,
  (activityId, collections) => {
    const collectionName = getActivityUsersCollectionName(activityId);
    return collections.getIn([collectionName, 'items'], List()) as List<any>;
  }
)((_: StoreState, activityId: string) => activityId);

export const selectActivityUsersHasCompletedSession = createCachedSelector(
  selectActivityUsers,
  activityUsers =>
    activityUsers.some(activityUser =>
      COMPLETED_SURVEY_STATUSES.includes(activityUser.get('survey_status'))
    )
)((_: StoreState, activityId: string) => activityId);
