import { List } from 'immutable';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { BasketItem } from '^/components/shop/basket/types';
import { ShopItem } from '^/reducers/api/types';
import { selectShopItemCategories, selectUserProfile } from '^/selectors';
import { selectUserOrganisationJS } from '^/selectors/user';
import { StoreState } from '^/store';

export const selectBasketItems = createSelector(
  selectUserProfile,
  userProfile =>
    userProfile.get('basketitem_set', List()).toJS() as ReadonlyArray<
      BasketItem
    >
);

export const selectUnpurchasedBasketItems = createSelector(
  selectBasketItems,
  basketItems => basketItems.filter(basketItem => !basketItem.purchased)
);

export const selectPurchasedBasketItems = createSelector(
  selectBasketItems,
  basketItems => basketItems.filter(basketItem => basketItem.purchased)
);

export const selectShopItems = createSelector(
  selectShopItemCategories,
  shopItemCategories =>
    shopItemCategories &&
    shopItemCategories.flatMap(category => category.shopitem_set)
);

export const selectIsAccreditationMissingForShopItemProduct = createCachedSelector(
  (_: StoreState, shopItem: ShopItem) => shopItem,
  selectUserOrganisationJS,
  ({ product }, organisation) =>
    !!product &&
    !!organisation &&
    product.requires_accreditation &&
    !organisation.admins.some(admin =>
      admin.accredited_products.includes(product.id)
    )
)((_: StoreState, shopItem: ShopItem) => shopItem.id);
