import classNames from 'classnames';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component, MouseEvent } from 'react';
import { connect } from 'react-redux';

import { closeTopModal, openAnotherModal } from '^/actions/modals';
import { formatDate, formatDateTime } from '^/utils';
import DateTimePickerModal from '../DateTimePickerModal';
import DatePickerIcon from './DatePickerIcon';
import { Props } from './types';

export class DatePicker extends Component<Props, {}> {
  public render() {
    const {
      value,
      disabled,
      prefix,
      hideIcon,
      isLoading,
      className,
      hideBorder,
      noLeftPadding,
      noMinWidth,
      error,
      clearable,
    } = this.props;

    return (
      <div className="date-picker-wrapper">
        <div
          className={classNames('date-picker-button', className, {
            borderless: hideBorder,
            'no-left-padding': noLeftPadding,
            'no-min-width': noMinWidth,
            'default-value': !value,
            disabled,
          })}
          onClick={this.onClick}
          role="button"
          tabIndex={0}
        >
          <span className="date-picker-text">
            {prefix && value && <span className="date-prefix">{prefix}</span>}
            <span className="date-display">{this.formatDateString()}</span>
          </span>
          <DatePickerIcon
            disabled={disabled}
            value={value}
            hideIcon={hideIcon}
            isLoading={isLoading}
            clearable={clearable}
            onClearClick={this.onClearClick}
          />
        </div>
        {error && <p className="text-error">{error}</p>}
      </div>
    );
  }

  private onClearClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    this.props.onSave(undefined);
  };

  private onSave = (value: string) => {
    this.props.closeTopModal();
    if (this.props.onSave) {
      this.props.onSave(moment(value));
    }
  };

  private onClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (!this.props.disabled && !this.props.isLoading) {
      const {
        minDate,
        maxDate,
        notice,
        showTime,
        value,
        isCreditDatePicker,
        first,
        second,
      } = this.props;
      // TODO extract this to a separate action?
      this.props.openAnotherModal({
        title: showTime
          ? i18next.t<string>('Select a date and time')
          : i18next.t<string>('Select a date'),
        className: 'small',
        body: (
          <DateTimePickerModal
            value={value}
            onComplete={this.onSave}
            onCancel={this.props.closeTopModal}
            minDatetime={minDate}
            maxDatetime={maxDate}
            disableTime={!showTime}
            notice={notice}
            isCreditDatePicker={isCreditDatePicker}
            creditDateFirst={first}
            creditDateSecond={second}
          />
        ),
      });
      if (this.props.onClick) {
        this.props.onClick();
      }
    }
  };

  private formatDateString = () => {
    const { value, defaultValue, showTime } = this.props;

    if (!value) {
      return defaultValue || '';
    }

    return showTime ? formatDateTime(value) : formatDate(value);
  };
}

export default connect(null, { closeTopModal, openAnotherModal })(DatePicker);
