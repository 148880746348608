import i18next from 'i18next';
import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import makeActionChain, { ActionChain } from '^/actions/makeActionChain';
import {
  setReportsInitialOrganisation,
  toggleReportFilterSelection,
} from '^/actions/ui';
import { Uuid } from '^/reducers/api/types';
import { StoreState } from '^/store';
import { selectActivityUsersHasCompletedSession } from './selectors';

interface OwnProps {
  activityId: string;
  organisationId: string;
  readonly: boolean;
  isJobProfiler: boolean;
}

interface StateProps {
  activityUsersHasCompletedSession: boolean;
}

interface DispatchProps {
  onGoToReportsPress: (
    organisationId: Uuid,
    activityId: Uuid,
    isJobProfiler: boolean
  ) => ActionChain;
}

type Props = OwnProps & DispatchProps & StateProps;

export class GoToReportsLink extends React.PureComponent<Props> {
  public render() {
    const { readonly, activityUsersHasCompletedSession } = this.props;

    if (!activityUsersHasCompletedSession || readonly) {
      return null;
    }

    return (
      <a className="underlined" onClick={this.goToReports}>
        {i18next.t<string>('Go to reports')}
      </a>
    );
  }

  private goToReports = () => {
    const {
      onGoToReportsPress,
      organisationId,
      activityId,
      isJobProfiler,
    } = this.props;
    onGoToReportsPress(organisationId, activityId, isJobProfiler);
  };
}

function mapStateToProps(state: StoreState, ownProps: Props) {
  return {
    activityUsersHasCompletedSession: selectActivityUsersHasCompletedSession(
      state,
      ownProps.activityId
    ),
  };
}

export default connect(mapStateToProps, {
  onGoToReportsPress: (
    organisationId: Uuid,
    activityId: Uuid,
    isJobProfiler: boolean
  ) =>
    makeActionChain([
      () => setReportsInitialOrganisation(organisationId),
      () => toggleReportFilterSelection({ activities: activityId }),
      () =>
        push({
          pathname: `/page/reports/${
            isJobProfiler ? '?job-profiler=true' : ''
          }`,
        }),
    ]),
})(GoToReportsLink);
