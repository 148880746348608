import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from './PureComponent';
import ForgotPasswordForm from './ForgotPasswordForm';
import * as actions from '../actions/actions';
import NoAuth from './NoAuth';
import { getClassNameForUrlPath, getQueryValue } from '^/utils-ts';

export class ForgotPassword extends PureComponent {
  sendRecoveryLink(data) {
    this.props.sendRecoveryLink(data.email, this.props.nextPath);
  }

  render() {
    const { nextPath } = this.props;
    return (
      <NoAuth>
        <div className={getClassNameForUrlPath(nextPath)}>
          <h2>{i18next.t('Password reset')}</h2>
          <ForgotPasswordForm
            response={this.props.response}
            onSubmit={this.sendRecoveryLink.bind(this)}
          />
        </div>
      </NoAuth>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    response: state.responses.get('forgotPassword'),
    nextPath: getQueryValue(props, 'next'),
  };
}

export default connect(mapStateToProps, actions)(ForgotPassword);
