import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from './PureComponent';
import FormError from './FormError';
import LiveButton from './LiveButton';
import Alert from '^/components/Alert';

export class SudoForm extends PureComponent {
  render() {
    const { onSubmit, response } = this.props;

    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form className="form-block">
          <Alert className="mb-none">
            {i18next.t(
              'Enter a user email address here and your own password again for security.'
            )}
          </Alert>

          <div>
            <label htmlFor="email">{i18next.t('User email address')}</label>
            <div className="form-item">
              <Field
                id="email"
                name="email"
                type="email"
                placeholder={i18next.t('User email address')}
              />
              <FormError response={response} formKey="sudo_email" />
            </div>
          </div>

          <div>
            <label htmlFor="password">{i18next.t('YOUR password')}</label>
            <div className="form-item">
              <Field
                id="password"
                name="password"
                type="password"
                placeholder={i18next.t('YOUR password')}
              />
              <FormError response={response} formKey="password" />
            </div>
          </div>

          <div className="modal-footer">
            <div className="pull-right full-width text-right">
              <button
                className="btn"
                type="button"
                onClick={this.props.onClose}
              >
                {i18next.t('Cancel')}
              </button>
              <LiveButton
                buttonText={i18next.t('Login as user')}
                pendingMessage={i18next.t('Logging in...')}
                response={response}
                additionalButtonClasses=""
                getAdditionalErrors={_response =>
                  _response.getIn(['errors', 'msg'], [])
                }
              />
            </div>
          </div>
        </Form>
      </Formik>
    );
  }
}

export default SudoForm;
