import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import {
  setReportFilterSelection,
  toggleReportFilterSelection,
} from '^/actions/ui';
import Dropdown from '^/components/dropdown/Dropdown';
import { DropdownOption } from '^/components/dropdown/DropdownItem';
import { StoreState } from '^/store';
import { SELECT_ALL_ID } from './constants';
import {
  selectAllOrgFilterBoolMap,
  selectGroupsMultiItems,
  selectIsAllGroupsSelected,
  selectMappedSelectedGroups,
  selectReportFilterSelectedCount,
  selectReportTableIsLoading,
} from './selectors';
import { BoolMap } from './types';

interface DispatchProps {
  toggleReportFilterSelection: typeof toggleReportFilterSelection;
  setReportFilterSelection: typeof setReportFilterSelection;
}

interface StateProps {
  isLoadingTable?: boolean;
  everyGroupIsSelected: boolean;
  allGroups: BoolMap;
  selectedGroupsCount: number;
  groupsItems: ReadonlyArray<DropdownOption>;
  groupsMultiItems: ReadonlyArray<DropdownOption>;
}

type Props = DispatchProps & StateProps;

export class GroupsDropdown extends React.PureComponent<Props> {
  public render() {
    const {
      selectedGroupsCount,
      groupsItems,
      groupsMultiItems,
      isLoadingTable,
    } = this.props;
    return (
      <Dropdown
        title={i18next.t<string>('Groups')}
        selectedTitle={this.getTitle()}
        isNoneSelected={selectedGroupsCount === 0}
        emptyTitle={i18next.t<string>('No groups available')}
        selectable
        items={groupsItems}
        multiSelects={groupsMultiItems}
        onClick={this.onClick}
        loading={Boolean(isLoadingTable)}
        failed={false}
      />
    );
  }

  private getTitle = () => {
    const { selectedGroupsCount, groupsItems } = this.props;
    if (
      selectedGroupsCount === 0 ||
      (groupsItems && selectedGroupsCount === groupsItems.length)
    ) {
      return i18next.t<string>('All groups');
    }
    return i18next.t<string>('{{count}} groups selected ', {
      count: selectedGroupsCount,
    });
  };

  private onClick = (groupId: DropdownOption['id']) => {
    const { everyGroupIsSelected, allGroups } = this.props;
    if (groupId === SELECT_ALL_ID) {
      if (everyGroupIsSelected) {
        this.props.setReportFilterSelection({
          groups: {},
        });
      } else {
        this.props.setReportFilterSelection({
          groups: allGroups,
        });
      }
    } else {
      this.props.toggleReportFilterSelection({
        groups:
          typeof groupId !== 'string' && groupId !== null
            ? `${groupId}`
            : groupId,
      });
    }
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    isLoadingTable: selectReportTableIsLoading(state),
    allGroups: selectAllOrgFilterBoolMap(state, 'groups'),
    selectedGroupsCount: selectReportFilterSelectedCount(state, 'groups'),
    everyGroupIsSelected: selectIsAllGroupsSelected(state),
    groupsItems: selectMappedSelectedGroups(state),
    groupsMultiItems: selectGroupsMultiItems(state),
  };
}

export default connect(mapStateToProps, {
  toggleReportFilterSelection,
  setReportFilterSelection,
})(GroupsDropdown);
