import i18next from 'i18next';
import React, { ReactNode, PureComponent, Children } from 'react';

import { scrollToTop } from '^/utils-ts';

interface State {
  currentChildIndex: number;
}

interface Props {
  children: ReactNode;
  onSubmit: () => void;
  submitting?: boolean;
}

export default class BackNextable extends PureComponent<Props, State> {
  public readonly state = {
    currentChildIndex: 0,
  };

  public componentDidUpdate(_prevProps: Props, prevState: State) {
    if (prevState.currentChildIndex !== this.state.currentChildIndex) {
      scrollToTop();
    }
  }

  public render() {
    const { children, submitting, onSubmit } = this.props;
    const { currentChildIndex } = this.state;
    return (
      <div className="back-nextable">
        {Children.toArray(children)[currentChildIndex]}
        {currentChildIndex > 0 && (
          <button className="btn" onClick={this.back}>
            {i18next.t<string>('Back')}
          </button>
        )}
        {currentChildIndex < Children.count(children) - 1 ? (
          <button className="btn btn-primary" onClick={this.next}>
            {i18next.t<string>('Next')}
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={submitting}
          >
            {i18next.t<string>('Submit')}
          </button>
        )}
      </div>
    );
  }

  private back = () =>
    this.setState({ currentChildIndex: this.state.currentChildIndex - 1 });

  private next = () =>
    this.setState({ currentChildIndex: this.state.currentChildIndex + 1 });
}
