import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
};

export default ({
  className,
  width = 73,
  height = 72,
  fill = '#1C75BC',
}: Props) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="pulse logo outline"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2538 9.22848C18.2538 14.2303 14.1991 18.2851 9.19723 18.2851C4.1954 18.2851 0.140625 14.2303 0.140625 9.22848C0.140625 4.22665 4.1954 0.171875 9.19723 0.171875C14.1991 0.171875 18.2538 4.22665 18.2538 9.22848ZM16.9281 9.30858C16.8851 13.5415 13.4403 16.9597 9.19723 16.9597C4.95411 16.9597 1.50936 13.5415 1.46639 9.30858H5.39075C5.75095 9.30858 6.07508 9.08994 6.21 8.75597L7.78965 4.84577L9.95473 14.7239C10.1441 15.5878 11.3445 15.6685 11.6478 14.8378L13.6665 9.30858H16.9281ZM16.8287 7.98323H13.3575C12.9864 7.98323 12.6548 8.21515 12.5276 8.56377L10.924 12.9558L8.77835 3.16621C8.59232 2.31748 7.42147 2.2188 7.09602 3.02442L5.09275 7.98323H1.56574C2.16126 4.30544 5.3513 1.49723 9.19723 1.49723C13.0432 1.49723 16.2332 4.30544 16.8287 7.98323Z"
      fill={fill}
    />
  </svg>
);
