import React from 'react'; // eslint-disable-line no-unused-vars

import { MODAL_COMPONENTS } from './types';
import {
  AnalyticsAppliedFilters,
  AnalyticsProduct,
  FilterProfileDetail,
  Uuid,
} from '^/reducers/api/types';
import CreateOrEditProfilePage from './filter-profiles/CreateOrEditProfilePage';
import CreateEditProfilePage from './filter-profiles/CreateEditProfilePage';
import ListProfilePage from './filter-profiles/ListProfilePage';
import ProfileDetailPage from './filter-profiles/ProfileDetailPage';

interface AdvancedAnalyticsModalProps {
  filters: AnalyticsAppliedFilters;
  product: AnalyticsProduct;
  onApply: (resultsRanges: string[][]) => void;
  initialCount: number;
}

interface State {
  componentId: MODAL_COMPONENTS;
  profileId: Uuid | undefined;
  profile: FilterProfileDetail | undefined;
}

export class AdvancedAnalyticsModal extends React.PureComponent<
  AdvancedAnalyticsModalProps,
  State
> {
  constructor(props: AdvancedAnalyticsModalProps) {
    super(props);

    this.state = {
      componentId: MODAL_COMPONENTS.CREATE_OR_EDIT_FILTER_PROFILE,
      profileId: undefined,
      profile: undefined,
    };
    this.getComponent = this.getComponent.bind(this);
    this.setComponentId = this.setComponentId.bind(this);
    this.setProfileId = this.setProfileId.bind(this);
    this.setProfileAndRenderEdit = this.setProfileAndRenderEdit.bind(this);
  }

  public render() {
    return <div className="advanced-analytics">{this.getComponent()}</div>;
  }

  private getComponent = () => {
    const { product, initialCount, filters, onApply } = this.props;
    switch (this.state.componentId) {
      case MODAL_COMPONENTS.CREATE_EDIT_FILTER_PROFILE:
        return (
          <CreateEditProfilePage
            renderComponent={this.setComponentId}
            product={product}
            initialCount={initialCount}
            profile={this.state.profile}
            analyticsFilters={filters}
            onApply={onApply}
          />
        );
      case MODAL_COMPONENTS.LIST_FILTER_PROFILES:
        return (
          <ListProfilePage
            renderComponent={this.setComponentId}
            setProfileId={this.setProfileId}
            product={product}
          />
        );
      case MODAL_COMPONENTS.FILTER_PROFILE_DETAIL:
        return (
          <ProfileDetailPage
            profileId={this.state.profileId}
            product={this.props.product}
            setProfileAndRenderEdit={this.setProfileAndRenderEdit}
            initialCount={initialCount}
            analyticsFilters={filters}
            onCancel={() =>
              this.setComponentId(MODAL_COMPONENTS.LIST_FILTER_PROFILES)
            }
          />
        );
      default:
        return (
          <CreateOrEditProfilePage renderComponent={this.setComponentId} />
        );
    }
  };

  private setComponentId = (componentId: MODAL_COMPONENTS) => {
    this.setState({ componentId });
  };

  private setProfileId = (profileId: Uuid) => this.setState({ profileId });

  private setProfileAndRenderEdit = (profile: FilterProfileDetail) =>
    this.setState({
      profile,
      componentId: MODAL_COMPONENTS.CREATE_EDIT_FILTER_PROFILE,
    });
}

export default AdvancedAnalyticsModal;
