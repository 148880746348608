import React from 'react'; // eslint-disable-line no-unused-vars
import { Map, List } from 'immutable';
import i18next from 'i18next';

import PureComponent from '../PureComponent';

export default class AverageValuesResults extends PureComponent {
  constructor() {
    super();
    this.state = {
      headers: List([
        i18next.t('Factor'),
        i18next.t('Average'),
        i18next.t('Count'),
      ]),
    };
  }
  render() {
    return (
      <div>
        <h3>{i18next.t('Results')}</h3>
        <table {...this.props}>
          <thead>
            <tr>
              {this.state.headers.map(header => (
                <td key={header}>{header}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.props.data &&
              this.props.data
                .get('averages', Map())
                .map((average, key) => (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{average}</td>
                    <td>{this.props.data.get('counts', Map()).get(key)}</td>
                  </tr>
                ))
                .toArray()}
          </tbody>
        </table>
      </div>
    );
  }
}
