/* eslint-disable no-console */
import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird';

import { closeTopModal, openAnotherModal } from '../actions/modals';
import { saveField } from '../actions/actionSequences';
import PureComponent from './PureComponent';
import * as responseStates from '../responseStates';
import { formatDateTime, formatDate, withStopPropagation } from '^/utils';
import DateTimePickerModal from './DateTimePickerModal';

export class EditableDatetime extends PureComponent {
  constructor(props) {
    super(props);
    console.warn(
      'EditableDateTime is deprecated, use a component from /components/date/ instead'
    );
  }

  startEditing() {
    const {
      value,
      minDatetime,
      maxDatetime,
      showDateOnly,
      notice,
    } = this.props;

    if (!this.props.disabled) {
      this.props.openAnotherModal({
        title: showDateOnly
          ? i18next.t('Select a date')
          : i18next.t('Select a date and time'),
        className: 'small',
        body: (
          <DateTimePickerModal
            value={value}
            onComplete={this.saveEdit.bind(this)}
            onCancel={this.props.closeTopModal}
            minDatetime={minDatetime}
            maxDatetime={maxDatetime}
            disableTime={showDateOnly}
            notice={notice}
          />
        ),
      });
    }
  }

  saveEdit(value) {
    const { onAccept, fieldName, beforeDone } = this.props;
    this.props.closeTopModal();
    const save = this.props.saveFunc
      ? this.props.saveFunc
      : newValue => this.props.saveField(onAccept, fieldName, newValue);

    if (this.props.beforeDone) {
      beforeDone(value, save);
    } else {
      save(value);
    }
  }

  formattedValue() {
    const { value, defaultValue, showDateOnly } = this.props;

    if (!value) {
      return defaultValue || '';
    }

    return showDateOnly ? formatDate(value) : formatDateTime(value);
  }

  render() {
    const { response, disabled, prefix, hideIcon, className } = this.props;
    const isSaving =
      response && response.get('state') === responseStates.PENDING;

    return (
      <span
        ref={this.props.getRef}
        className={className}
        onClick={
          !disabled && withStopPropagation(event => this.startEditing(event))
        }
      >
        <span className="date-display">
          {prefix}
          {disabled ? (
            <span>{this.formattedValue()}</span>
          ) : (
            <a tabIndex="0">{this.formattedValue()}</a>
          )}
        </span>
        {!hideIcon && !disabled && (
          <a className="editable-icon-link">
            <FontAwesomeIcon
              icon={isSaving ? faSpinnerThird : faCalendarAlt}
              spin={isSaving}
            />
          </a>
        )}
      </span>
    );
  }
}

EditableDatetime.propTypes = {
  beforeDone: PropTypes.func,
  saveFunc: PropTypes.func,
  hideIcon: PropTypes.bool,
  className: PropTypes.string,
  //TODO: Populate this with our requirements
};

export default connect(null, {
  saveField,
  openAnotherModal,
  closeTopModal,
})(EditableDatetime);
