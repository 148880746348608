import React, { useState } from 'react';
import i18next from 'i18next';

import Checkbox from './Checkbox';
import NewButton from './NewButton';
import RadioButton from './RadioButton';
import { Uuid } from '^/reducers/api/types';

export interface ProductSelectorProduct {
  id: string;
  icon: string | null;
  name: string;
  price?: number;
  optionalAddonLabel?: string;
  optionalSelected?: boolean;
  selected?: boolean;
  disabled?: boolean;
  optionalEnabled?: boolean;
  optionalDisabled?: boolean;
}

export interface ProductSelectorSelectedProduct {
  id: Uuid;
  name: string;
  price?: number;
  icon: string | null;
  optionalAddonSelected: boolean;
  checked?: boolean;
  optionalEnabled?: boolean;
  optionalDisabled?: boolean;
}

export interface Props {
  title: string;
  products: ReadonlyArray<ProductSelectorProduct> | null;
  singleSelection?: boolean;
  footnote?: () => JSX.Element;
  confirmButtonLabel: string;
  onConfirm: (
    selectedList: ReadonlyArray<ProductSelectorSelectedProduct>
  ) => void;
  onClose: () => void;
  onSelection?: (id: string) => void;
  onClickRowButton?: (id: string) => void;
}

export const ProductSelector: React.FC<Props> = ({
  products,
  footnote,
  confirmButtonLabel,
  onConfirm,
  onClose,
  singleSelection,
  title,
  onSelection,
  onClickRowButton,
}) => {
  const [selected, setSelected] = useState<
    Array<ProductSelectorSelectedProduct>
  >(
    products
      ? products.map(product => ({
          id: product.id,
          name: product.name,
          icon: product.icon,
          price: product.price ? product.price : 0,
          optionalAddonSelected: product.optionalSelected ? true : false,
          checked: product.selected ? true : false,
          disabled: product.disabled,
          optionalEnabled: product.optionalEnabled,
          optionalDisabled: product.optionalDisabled,
        }))
      : []
  );
  const anyChecked = selected.filter(items => items.checked).length > 0;
  const clickRowButton = (id: string) => {
    setSelected(selections => {
      return selections.map(item => {
        return item.id === id
          ? { ...item, optionalAddonSelected: !item.optionalAddonSelected }
          : item;
      });
    });
    onClickRowButton && onClickRowButton(id);
  };
  const handleOnRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const checked = e.currentTarget.checked;
    setSelected(selections => {
      return selections.map(item => {
        return item.id === id
          ? {
              ...item,
              checked: checked,
              optionalAddonSelected:
                checked === false ? false : item.optionalAddonSelected,
            }
          : {
              ...item,
              checked: false,
              optionalAddonSelected: false,
            };
      });
    });
  };

  const handleOnCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.id;
    const checked = e.currentTarget.checked;
    onSelection && onSelection(id);
    setSelected(selections => {
      return selections.map(item => {
        return item.id === id
          ? {
              ...item,
              checked: checked,
              optionalAddonSelected:
                checked === false ? false : item.optionalAddonSelected,
            }
          : item;
      });
    });
  };

  const handleConfirm = () => {
    const confirmSelections = selected
      .filter(selection => selection.checked)
      .map(checked => ({
        id: checked.id,
        optionalAddonSelected: checked.optionalAddonSelected,
        icon: checked.icon,
        price: checked.price ? checked.price : 0,
        name: checked.name,
      }));
    onConfirm(confirmSelections);
    onClose();
  };
  return (
    <>
      <table>
        <col width={'40px'} />
        {products &&
          products.map(product => (
            <tr key={product.id}>
              <td>
                {singleSelection ? (
                  <RadioButton
                    name={title}
                    disabled={product.disabled}
                    checked={
                      (selected &&
                        selected.find(item => item.id === product.id)
                          ?.checked) ||
                      false
                    }
                    value={product.id}
                    id={product.id}
                    onChange={e => handleOnRadioChange(e)}
                  />
                ) : (
                  <Checkbox
                    value={product.id}
                    id={product.id}
                    disabled={product.disabled}
                    checked={
                      (selected &&
                        selected.find(item => item.id === product.id)
                          ?.checked) ||
                      false
                    }
                    onChange={e => handleOnCheckboxChange(e)}
                  />
                )}
              </td>
              <td>
                <span className="asset-name-and-logo">
                  {product.icon && (
                    <img className="asset-logo" src={product.icon} />
                  )}
                  <span className="asset-name">{product.name}</span>
                  {product.optionalAddonLabel && (
                    <span className="extra-button">
                      <NewButton
                        value={product.id}
                        buttonType={
                          selected.find(select => select.id === product.id)
                            ?.optionalAddonSelected === true
                            ? 'select-selected'
                            : 'select'
                        }
                        onClick={e => clickRowButton(e.currentTarget.value)}
                        disabled={
                          (product.optionalEnabled !== true &&
                            selected.find(select => select.id === product.id)
                              ?.checked === false) ||
                          product.optionalDisabled
                        }
                      >
                        {selected.find(select => select.id === product.id)
                          ?.optionalAddonSelected
                          ? i18next.t<string>('Remove ')
                          : i18next.t<string>('Add ')}
                        {product.optionalAddonLabel}
                      </NewButton>
                    </span>
                  )}
                </span>
              </td>
            </tr>
          ))}
      </table>
      {footnote && <div className="product-selector-footer"> {footnote()}</div>}
      <div className="modal-footer">
        <div className="pull-right">
          <NewButton buttonType="outline" onClick={onClose}>
            {i18next.t<string>('Cancel')}
          </NewButton>
          <NewButton disabled={!anyChecked} onClick={handleConfirm}>
            {confirmButtonLabel}
          </NewButton>
        </div>
      </div>
    </>
  );
};
