import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { isPending, isFieldUpdating } from '^/responseStates';
import { RULES } from '^/reportVisibility';
import PureComponent from '^/components/PureComponent';
import Tag from '^/components/Tag';
import EditableDatetime from '^/components/EditableDatetime';
import EmailListForm from '^/components/activities/tabs/EmailListForm';
import RadioButton from '^/components/RadioButton';
import ResponseStatusIcon from './ResponseStatusIcon';
import Checkbox from '^/components/Checkbox';

const RULE_TEXT = {
  [RULES.HIDE]: i18next.t('Do not send reports to Respondents'),
  [RULES.SHOW_ON_COMPLETION]: i18next.t('Send reports on completion'),
  [RULES.SHOW_AFTER_DATE]: i18next.t('Send all available reports on'),
  [RULES.SHOW_AT_END]: i18next.t(
    'Send all available reports when activity closes'
  ),
};

const RULE_TEXT_JOB_PROFILE = {
  [RULES.HIDE]: i18next.t('Do not send report to profile creator'),
  [RULES.SHOW_ON_COMPLETION]: i18next.t(
    'Send report to profile creator on completion'
  ),
};

export default class ReportDistribution extends PureComponent {
  renderAdminReportsOption() {
    const { user, activity, is360, isJobProfile } = this.props;
    const {
      created_by_user: { id, full_name },
    } = activity.toJS();

    if (is360) {
      return user.get('id') === id
        ? i18next.t('Send all available reports to me when activity closes')
        : i18next.t(
            'Send all available reports to {{name}} when activity closes',
            { name: full_name }
          );
    }

    if (isJobProfile) {
      return user.get('id') === id
        ? i18next.t('Send report to me when created')
        : i18next.t('Send report to {{name}} when created', {
            name: full_name,
          });
    }

    return user.get('id') === id
      ? i18next.t('Send reports to me each time a Respondent completes')
      : i18next.t('Send reports to {{name}} each time a Respondent completes', {
          name: full_name,
        });
  }

  renderAdminReportsMessage() {
    return this.props.is360
      ? i18next.t(
          'All available reports will be sent to you when activity closes'
        )
      : i18next.t(
          'Reports will be sent to you each time a Respondent completes'
        );
  }

  renderRespondentDistributionOptions() {
    const {
      activity,
      readOnly,
      is360,
      reportVisibility,
      isJobProfile,
    } = this.props;
    const { rule, date } = reportVisibility.toObject();
    const minDate = activity.get(is360 ? 'end_datetime' : 'start_datetime');

    if (is360) {
      return (
        <RadioButton
          checked={rule === RULES.SHOW_AT_END}
          onChange={
            !readOnly && this.props.setDisplay.bind(null, RULES.SHOW_AT_END)
          }
          disabled={readOnly}
          name="report-visibility"
          id="show_at_end"
          label={RULE_TEXT[RULES.SHOW_AT_END]}
        />
      );
    }

    if (isJobProfile) {
      return (
        <RadioButton
          checked={rule === RULES.SHOW_ON_COMPLETION}
          onChange={
            !readOnly &&
            this.props.setDisplay.bind(null, RULES.SHOW_ON_COMPLETION)
          }
          disabled={readOnly}
          name="report-visibility"
          id="show_on_completion"
          label={RULE_TEXT_JOB_PROFILE[RULES.SHOW_ON_COMPLETION]}
        />
      );
    }

    return (
      <div>
        <RadioButton
          checked={rule === RULES.SHOW_ON_COMPLETION}
          onChange={
            !readOnly &&
            this.props.setDisplay.bind(null, RULES.SHOW_ON_COMPLETION)
          }
          disabled={readOnly}
          name="report-visibility"
          id="show_on_completion"
          label={RULE_TEXT[RULES.SHOW_ON_COMPLETION]}
        />
        <RadioButton
          disabled={readOnly}
          checked={rule === RULES.SHOW_AFTER_DATE}
          onChange={
            !readOnly && this.props.setDisplay.bind(null, RULES.SHOW_AFTER_DATE)
          }
          name="report-visibility"
          id="show_after_date"
          label={
            <span>
              <span className="mr-sm">{RULE_TEXT[RULES.SHOW_AFTER_DATE]}</span>
              <EditableDatetime
                getRef={element => (this.datePicker = element)}
                saveFunc={this.props.setDate}
                value={date || activity.get('end_datetime')}
                minDatetime={minDate}
                disabled={rule !== RULES.SHOW_AFTER_DATE || readOnly}
                className={rule !== RULES.SHOW_AFTER_DATE && 'muted'}
              />
            </span>
          }
        />
      </div>
    );
  }

  render() {
    const {
      activity,
      readOnly,
      response,
      is360,
      isJobProfile,
      reportVisibility,
      updateReportVisibilityResponse,
    } = this.props;
    const { rule } = reportVisibility.toObject();
    const minDate = activity.get(is360 ? 'end_datetime' : 'start_datetime');
    const {
      additional_report_recipients_send_date: additionalReportRecipientsSendDate,
      additional_report_recipients,
      notifications,
    } = activity.toObject();
    const reportRecipientsSent = notifications.some(
      each => each.get('action') === 'NOTIFY_REPORT_LINK'
    );

    let adminReportTempate = 'created_by_admin';
    if (is360) {
      adminReportTempate = 'created_by_admin_360';
    } else if (isJobProfile) {
      adminReportTempate = 'created_by_admin_job_profiler';
    }

    return (
      <div className="form-block">
        <h5 className="heading-underlined">
          {i18next.t('Admin report distribution')}
          <a
            className="pull-right text-size-sm text-transform-initial"
            onClick={() =>
              this.props.editReportNotification(
                adminReportTempate,
                'NOTIFY_REPORTS_LINK_ADMIN'
              )
            }
          >
            {i18next.t('Edit notification')}
          </a>
        </h5>

        <div className="form-item">
          {this.props.canUserMuteAdminReportDistribution ? (
            <div>
              <Checkbox
                id="reports_distribution"
                checked={activity.get(
                  'send_zipped_reports_to_activity_creator'
                )}
                disabled={
                  readOnly ||
                  (isPending(response) &&
                    isFieldUpdating(
                      response,
                      'send_zipped_reports_to_activity_creator'
                    ))
                }
                inline
                label={this.renderAdminReportsOption()}
                onChange={
                  !readOnly &&
                  (event =>
                    this.props.updateFields({
                      send_zipped_reports_to_activity_creator:
                        event.target.checked,
                    }))
                }
              />
              <ResponseStatusIcon
                response={response}
                field="send_zipped_reports_to_activity_creator"
              />
            </div>
          ) : (
            this.renderAdminReportsMessage()
          )}
        </div>

        <h5 className="heading-underlined">
          {i18next.t('Additional report distribution')}
          <a
            className="pull-right text-size-sm text-transform-initial"
            onClick={() =>
              this.props.editReportNotification(
                is360 ? 'recipients_360' : 'recipients',
                'NOTIFY_REPORTS_LINK_ADDITIONAL_RECIPIENTS'
              )
            }
          >
            {i18next.t('Edit notification')}
          </a>
        </h5>

        <div className="form-item">
          <label>
            {is360 ? (
              <div>
                {i18next.t(
                  'When activity closes send all available reports to'
                )}
              </div>
            ) : (
              <span>
                <EditableDatetime
                  getRef={element => (this.datePicker = element)}
                  saveFunc={value =>
                    this.props.updateFields({
                      additional_report_recipients_send_date: value,
                    })
                  }
                  value={
                    additionalReportRecipientsSendDate ||
                    activity.get('end_datetime')
                  }
                  minDatetime={minDate}
                  disabled={reportRecipientsSent || readOnly}
                  prefix={i18next.t(
                    'Send all reports to the following Recipients on'
                  )}
                />
                <ResponseStatusIcon
                  response={response}
                  field="additional_report_recipients_send_date"
                />
              </span>
            )}
          </label>

          <div>
            <div className="flex-list">
              {additional_report_recipients.map((email, idx) => (
                <Tag
                  key={idx}
                  name={email}
                  onRemove={
                    !reportRecipientsSent &&
                    (() =>
                      this.props.updateFields({
                        additional_report_recipients: additional_report_recipients.delete(
                          idx
                        ),
                      }))
                  }
                />
              ))}
            </div>
            <EmailListForm
              onSubmit={recipient =>
                this.props.updateFields({
                  additional_report_recipients: additional_report_recipients.push(
                    recipient
                  ),
                })
              }
              disabled={
                reportRecipientsSent ||
                (isPending(response) &&
                  isFieldUpdating(response, 'additional_report_recipients'))
              }
            />
          </div>
          <ResponseStatusIcon
            response={response}
            field="additional_report_recipients"
          />
        </div>

        <h5 className="heading-underlined">
          {isJobProfile
            ? i18next.t('Profile creator report distribution')
            : i18next.t('Respondent report distribution')}
          <a
            className="pull-right text-size-sm text-transform-initial"
            onClick={() => this.props.editNotification()}
          >
            {i18next.t('Edit notification')}
          </a>
        </h5>

        <div className="report-visibility">
          <div>
            <RadioButton
              checked={rule === RULES.HIDE}
              onChange={
                !readOnly && this.props.setDisplay.bind(null, RULES.HIDE)
              }
              disabled={readOnly}
              name="report-visibility"
              id="hide"
              label={
                isJobProfile
                  ? RULE_TEXT_JOB_PROFILE[RULES.HIDE]
                  : RULE_TEXT[RULES.HIDE]
              }
            />
            {this.renderRespondentDistributionOptions()}
          </div>
          {isPending(updateReportVisibilityResponse) && (
            <ResponseStatusIcon
              response={updateReportVisibilityResponse}
              alignLeft
              smallInline
            />
          )}
        </div>
      </div>
    );
  }
}
