import React, { ReactNode, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface OptionProps {
  name: string;
  disabled?: boolean;
  danger?: boolean;
  icon?: React.ReactElement;
  onSelect: () => void;
  onClick?: () => void;
}

export class MenuButtonOption extends React.Component<OptionProps> {
  public render() {
    const { name, onClick, onSelect, disabled, danger } = this.props;
    return (
      <div
        className={classNames(
          'menu-button-option',
          disabled && 'disabled',
          danger && 'danger'
        )}
        onClick={() => {
          !disabled && onClick && onClick();
          onSelect();
        }}
      >
        {this.props.icon && (
          <div className="menu-button-option-icon">{this.props.icon}</div>
        )}
        {name}
      </div>
    );
  }
}

interface Props {
  disabled?: boolean;
  title?: string;
  label: ReactNode;
  position?: string;
  primary?: boolean;
  secondary?: boolean;
  dataFor?: string;
  isNotSquare?: boolean;
}

interface State {
  isOpen: boolean;
}

export default class MenuButton extends React.Component<
  PropsWithChildren<Props>,
  State
> {
  public readonly state = {
    isOpen: false,
  };

  public render() {
    const { isOpen } = this.state;
    const {
      children,
      disabled,
      label,
      title,
      position,
      primary,
      secondary,
      dataFor,
      isNotSquare,
    } = this.props;

    const className =
      primary || secondary
        ? `menu-button btn btn-${primary ? 'primary' : 'secondary'}`
        : classNames('menu-button btn btn-small', {
            'btn-icon-square': !isNotSquare,
            'btn-icon-square-secondary': !isOpen,
          });

    return (
      <>
        <button
          className={classNames(className, position || '')}
          onClick={isOpen ? this.close : this.open}
          disabled={disabled}
          title={title}
          data-for={dataFor}
          data-tip
        >
          {label}
          {isOpen && (
            <div
              className={classNames(
                'menu-button-options',
                primary || (secondary && 'big')
              )}
            >
              {React.Children.map(children, child =>
                React.cloneElement(child as React.ReactElement, {
                  onClick: this.close,
                })
              )}
            </div>
          )}
        </button>
      </>
    );
  }

  private open = () => {
    this.setState({ isOpen: true });
  };

  private close = () => {
    this.setState({ isOpen: false });
  };
}
