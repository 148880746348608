import classNames from 'classnames';
import i18next from 'i18next';
import React, { PureComponent } from 'react';
import { range } from 'underscore';
import { ComponentClass } from 'react-redux';

import Dropdown, { DropdownProps } from '^/components/dropdown/Dropdown';
import { ConnectedEditable as Editable } from '^/components/editable/Editable';
import { EditableValue } from '^/components/editable/types';
import { createResponseComponent } from '^/components/response-hoc';
import { ResponseComponentProps } from '^/components/response-hoc/types';
import { clamp } from '^/utils-ts';
import { DropdownOption } from './DropdownItem';

interface OwnProps {
  minQuantity?: number;
  maxQuantity?: number;
  maxDropdownValue: number;
  hasUnlimited?: boolean;
  value?: number | null;
  error?: string;
  onChange: (value: number | null) => void;
  fieldName: string;
  className?: string;
}

interface State {
  editing: boolean;
  selectedId?: DropdownOption['id'];
}

type Props = Omit<DropdownProps, 'items' | 'onClick'> &
  OwnProps &
  ResponseComponentProps;

const MORE_ID = 'more';
const UNLIMITED_ID = null;
const UNLIMITED_DISPLAY = 'UNLTD';
const MIN_QUANTITY_DEFAULT = 0;

export class QuantityDropdown extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      selectedId: props.value,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selectedId: this.props.value });
    }
  }

  public render() {
    const {
      minQuantity = MIN_QUANTITY_DEFAULT,
      hasUnlimited,
      loading,
      errorMessage,
      fieldName,
      failed,
      maxQuantity,
      className,
      ...restProps
    } = this.props;
    const { selectedId, editing } = this.state;

    const maxDropdownValue = this.getMaxDropdownValue();

    const items = range(minQuantity, maxDropdownValue).map(
      this.createDropdownOption
    );

    if (
      typeof maxQuantity === 'undefined' ||
      maxQuantity > this.props.maxDropdownValue
    ) {
      items.push({ name: `${maxDropdownValue}+`, id: MORE_ID });
    } else {
      items.push(this.createDropdownOption(maxDropdownValue));
    }

    if (hasUnlimited) {
      items.unshift({
        name: UNLIMITED_DISPLAY,
        id: UNLIMITED_ID,
      });
    }

    return (
      <div className={classNames(className, 'quantity-dropdown')}>
        {editing || this.selectedIsOverMaxDropdownValue() ? (
          <Editable
            className="flex dropdown-height"
            startInEdit={this.state.editing}
            value={selectedId ?? minQuantity}
            onSave={this.onSave}
            fieldName={fieldName}
            loading={loading}
            failed={failed}
            normalizeValue={this.normalizeEditableValue}
            formatDisplay={this.formatTitle}
            formatValue={this.formatEditableValue}
            hideErrorMessage
          />
        ) : (
          <Dropdown
            {...restProps}
            loading={loading}
            failed={failed}
            items={items}
            formatDisplay={title =>
              this.formatTitle((title && title.toString()) || '')
            }
            onClick={this.onSelect}
            defaultValue={this.getDefaultValue()}
          />
        )}
        {failed && (
          <div className="text-error">
            {errorMessage || i18next.t<string>('Unable to save')}
          </div>
        )}
      </div>
    );
  }

  private getDefaultValue() {
    const { value, hasUnlimited } = this.props;

    switch (value) {
      case undefined:
        return undefined;
      case null:
        return hasUnlimited ? UNLIMITED_ID : undefined;
      default:
        return value;
    }
  }

  private formatEditableValue = (value: EditableValue) => {
    const { minQuantity = MIN_QUANTITY_DEFAULT, maxQuantity } = this.props;
    return (
      value &&
      clamp(
        parseInt(value, 10),
        minQuantity,
        typeof maxQuantity === 'undefined'
          ? Number.MAX_SAFE_INTEGER
          : maxQuantity
      ).toString()
    );
  };

  private selectedIsOverMaxDropdownValue = () => {
    const { selectedId } = this.state;
    const { maxDropdownValue, minQuantity = 0 } = this.props;

    return (
      minQuantity >= maxDropdownValue ||
      (selectedId &&
        !isNaN(parseInt(`${selectedId}`, 10)) &&
        parseInt(`${selectedId}`, 10) >= maxDropdownValue)
    );
  };

  private normalizeEditableValue = (value: string | undefined) =>
    value?.replace(/\D/g, '');

  private onSelect = (id: DropdownOption['id']) => {
    if (id === MORE_ID) {
      this.setState({ editing: true });
    } else {
      this.setSelectedId(id === null ? null : parseInt(`${id}`, 10));
    }
  };

  private onSave = (id: string | number | undefined) => {
    this.setState({ editing: false });
    const { minQuantity = 0 } = this.props;
    const idInt = id ? parseInt(`${id}`, 10) : 0;

    this.setSelectedId(idInt < minQuantity ? minQuantity : idInt);
    return Promise.resolve();
  };

  private setSelectedId(id: number | null) {
    this.setState({ selectedId: id });
    this.props.onChange(id);
  }

  private formatTitle = (title: string | undefined) => {
    if (this.props.hasUnlimited && this.state.selectedId === null) {
      title = UNLIMITED_DISPLAY;
    }
    return `${i18next.t<string>('Qty:')} ${title}`;
  };

  private getMaxDropdownValue() {
    const {
      maxQuantity,
      minQuantity = MIN_QUANTITY_DEFAULT,
      maxDropdownValue,
    } = this.props;
    return maxQuantity === undefined
      ? maxDropdownValue
      : clamp(maxDropdownValue, minQuantity, maxQuantity);
  }

  private createDropdownOption = (value: number): DropdownOption => {
    return {
      name: value.toString(),
      id: value,
      selected: this.state.selectedId === value,
    };
  };
}

// Cast necessary due to React 15 optional props type
export default createResponseComponent(
  QuantityDropdown as ComponentClass<Props>
);
