import React from 'react';
import i18next from 'i18next';

import { UserPulseDetail, Uuid } from '^/reducers/api/types';
import PulseIconCircle from '../PulseIconCircle';
import SelectPulseRatersList from './SelectPulseRatersList';
import { SelectedRater, DeletedRater } from './types';
import {
  getNonSelfRatersFromDetail,
  renderTruncatedTag,
  getDeletedRatersFromDetail,
} from './utils';
import { MOBILE_SCREEN_WIDTH } from './constants';

interface OwnProps {
  backToPulseDock: () => void;
  userPulseDetail: UserPulseDetail;
  storeSelected: (emails: ReadonlyArray<SelectedRater> | undefined) => void;
  addRater: (email: string) => any;
  removeRater: (email: string) => void;
  screenWidth: number;
  clearRater: (id: Uuid) => void;
}

interface State {
  selectedRaters: ReadonlyArray<SelectedRater> | undefined;
  deletedRaters: ReadonlyArray<DeletedRater> | undefined;
}

export type Props = OwnProps;

export class UserPulseSettings extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRaters: getNonSelfRatersFromDetail(this.props.userPulseDetail),
      deletedRaters: getDeletedRatersFromDetail(
        this.props.userPulseDetail
      ) as ReadonlyArray<DeletedRater>,
    };
  }

  public render() {
    const {
      backToPulseDock,
      storeSelected,
      addRater,
      removeRater,
      clearRater,
      userPulseDetail,
      screenWidth,
    } = this.props;
    const { selectedRaters, deletedRaters } = this.state;
    const truncateTextLength = screenWidth < MOBILE_SCREEN_WIDTH ? 30 : 40;
    return (
      <div className="settings-page">
        <div
          className="navigation-button"
          onClick={backToPulseDock}
          role="button"
        >
          &lt; {i18next.t<string>('back')}
        </div>
        <div className="heading">
          <div className="pulse-icon">
            <PulseIconCircle />
            <h1>{i18next.t<string>('Settings')}</h1>
          </div>
        </div>
        <div className="pulse-central-column">
          <div className="selected-behaviours">
            {userPulseDetail.pulse_sub_behaviours.map(selectedBehaviour => (
              <div key={selectedBehaviour.id}>
                {renderTruncatedTag(
                  'h3',
                  selectedBehaviour.pulse_behaviour.name,
                  truncateTextLength
                )}
                {renderTruncatedTag(
                  'p',
                  selectedBehaviour.name,
                  truncateTextLength
                )}
              </div>
            ))}
          </div>
        </div>
        {!userPulseDetail?.pulse?.is_self_only && (
          <div className="raters pulse-central-column">
            <SelectPulseRatersList
              selectedRaters={selectedRaters}
              storeSelected={storeSelected}
              addRater={addRater}
              removeRater={removeRater}
              clearRater={clearRater}
              userPulseDetail={userPulseDetail}
              deletedRaters={deletedRaters}
            />
          </div>
        )}
      </div>
    );
  }
}
