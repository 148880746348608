import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';

import PureComponent from '^/components/PureComponent';

export interface DropdownOption {
  id: string | number | null;
  name: string;
  extra?: string;
  selected?: boolean;
}

type Props = Readonly<{
  item: DropdownOption;
  onClick: (option: DropdownOption) => void;
  selectable?: boolean;
  id?: string;
}>;

const CHARS_ON_SINGLE_LINE = 60;

const MATCHES_X_CHARS_ON_SINGLE_LINE = new RegExp(
  `(.{${CHARS_ON_SINGLE_LINE},}?)\\s+`,
  'gi'
);

export const wrapLongName = (name: string) =>
  name.replace(MATCHES_X_CHARS_ON_SINGLE_LINE, '$1\n');

export default class DropdownItem extends PureComponent<Props> {
  public render() {
    const { id, selectable, item } = this.props;
    return (
      <li
        className={classnames('dropdown-item', {
          selected: selectable && item.selected,
        })}
        onClick={this.handleOnClick}
        tabIndex={0}
        id={id}
        role="listitem"
      >
        {selectable && (
          <FontAwesomeIcon
            className="pw-filter-checkbox"
            icon={item.selected ? faCheckSquare : faSquare}
            size="lg"
          />
        )}

        <div className="dropdown-item-text">
          <div>{wrapLongName(item.name)}</div>
          {item.extra && (
            <div className="subtext">{wrapLongName(item.extra)}</div>
          )}
        </div>
      </li>
    );
  }

  private handleOnClick = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };
}
