export const SEX_CHOICES = {
  MALE: 'Male',
  FEMALE: 'Female',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const GEOGRAPHY_CHOICES = {
  EUROPE: 'Europe',
  NORTH_AMERICA: 'North America',
  SOUTH_AMERICA: 'South America',
  OCEANIA: 'Oceania',
  ASIA: 'Asia',
  AFRICA: 'Africa',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const AGE_CHOICES = {
  RANGE_0_TO_20: 'Up to 20',
  RANGE_21_TO_30: '21-30',
  RANGE_31_TO_40: '31-40',
  RANGE_41_TO_50: '41-50',
  RANGE_51_TO_60: '51-60',
  RANGE_61_PLUS: '61 and above',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const FIRST_LANGUAGE_ENGLISH = {
  YES: 'Yes',
  NO: 'No',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const ETHNICITY_CHOICES = {
  ASIAN: 'Asian or Asian British',
  'BLACK/AFRICAN/CARRIBEAN': 'Black, Black British, Carribean or African',
  MIXED: 'Mixed or Multiple ethnic groups',
  OTHER_ETHNIC_GROUP: 'Other ethnic group',
  WHITE: 'White',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const NATIONALITY_CHOICES = {
  AFGHANISTAN: 'Afghanistan',
  ALBANIA: 'Albania',
  ALGERIA: 'Algeria',
  AMERICAN_SAMOA: 'American Samoa',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguilla',
  ANTIGUA_AND_BARBUDA: 'Antigua and Barbuda',
  ARGENTINA: 'Argentina',
  ARMENIA: 'Armenia',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australia',
  AUSTRIA: 'Austria',
  AZERBAIJAN: 'Azerbaijan',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahrain',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbados',
  BELARUS: 'Belarus',
  BELGIUM: 'Belgium',
  BELIZE: 'Belize',
  BENIN: 'Benin',
  BERMUDA: 'Bermuda',
  BHUTAN: 'Bhutan',
  BOLIVIA: 'Bolivia',
  BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
  BOTSWANA: 'Botswana',
  BRAZIL: 'Brazil',
  BRITISH_VIRGIN_ISLANDS: 'British Virgin Islands',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgaria',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Cambodia',
  CAMEROON: 'Cameroon',
  CANADA: 'Canada',
  CAPE_VERDE: 'Cape Verde',
  CAYMAN_ISLANDS: 'Cayman Islands',
  CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
  CHAD: 'Chad',
  CHILE: 'Chile',
  CHINA: 'China',
  COLOMBIA: 'Colombia',
  COMOROS: 'Comoros',
  COOK_ISLANDS: 'Cook Islands',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Croatia',
  COTE_DIVOIRE: "Côte d'Ivoire (Ivory Coast)",
  CUBA: 'Cuba',
  CYPRUS: 'Cyprus',
  CZECH_REPUBLIC: 'Czech Republic',
  DEMOCRATIC_REPUBLIC_OF_CONGO: 'Democratic Republic of Congo',
  DENMARK: 'Denmark',
  DJIBOUTI: 'Djibouti',
  DOMINICA: 'Dominica',
  DOMINICAN_REPUBLIC: 'Dominican Republic',
  ECUADOR: 'Ecuador',
  EGYPT: 'Egypt',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Equatorial Guinea',
  ERITREA: 'Eritrea',
  ESTONIA: 'Estonia',
  ETHIOPIA: 'Ethiopia',
  FAROE_ISLANDS: 'Faroe Islands',
  FEDERATED_STATES_OF_MICRONESIA: 'Federated States of Micronesia',
  FIJI: 'Fiji',
  FINLAND: 'Finland',
  FRANCE: 'France',
  FRENCH_GUIANA: 'French Guiana',
  FRENCH_POLYNESIA: 'French Polynesia',
  GABON: 'Gabon',
  GAMBIA: 'Gambia',
  GEORGIA: 'Georgia',
  GERMANY: 'Germany',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Greece',
  GREENLAND: 'Greenland',
  GRENADA: 'Grenada',
  GUADELOUPE: 'Guadeloupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUINEA: 'Guinea',
  'GUINEA-BISSAU': 'Guinea-Bissau',
  GUYANA: 'Guyana',
  HAITI: 'Haiti',
  HONDURAS: 'Honduras',
  HUNGARY: 'Hungary',
  ICELAND: 'Iceland',
  INDIA: 'India',
  INDONESIA: 'Indonesia',
  IRAN: 'Iran',
  IRAQ: 'Iraq',
  IRELAND: 'Ireland',
  ISRAEL: 'Israel',
  ITALY: 'Italy',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japan',
  JORDAN: 'Jordan',
  KAZAKHSTAN: 'Kazakhstan',
  KENYA: 'Kenya',
  KIRIBATI: 'Kiribati',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Kyrgyzstan',
  LAOS: 'Laos',
  LATVIA: 'Latvia',
  LEBANON: 'Lebanon',
  LESOTHO: 'Lesotho',
  LIBERIA: 'Liberia',
  LIBYA: 'Libya',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Lithuania',
  LUXEMBOURG: 'Luxembourg',
  MACEDONIA: 'Macedonia',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malawi',
  MALAYSIA: 'Malaysia',
  MALDIVES: 'Maldives',
  MALI: 'Mali',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Marshall Islands',
  MARTINIQUE: 'Martinique',
  MAURITANIA: 'Mauritania',
  MAURITIUS: 'Mauritius',
  MEXICO: 'Mexico',
  MOLDOVA: 'Moldova',
  MONACO: 'Monaco',
  MONGOLIA: 'Mongolia',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Morocco',
  MOZAMBIQUE: 'Mozambique',
  MYANMAR: 'Myanmar',
  NAMIBIA: 'Namibia',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Netherlands',
  NETHERLANDS_ANTILLES: 'Netherlands Antilles',
  NEW_CALEDONIA: 'New Caledonia',
  NEW_ZEALAND: 'New Zealand',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  NIUE: 'Niue',
  NORTH_KOREA: 'North Korea',
  NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
  NORWAY: 'Norway',
  OMAN: 'Oman',
  PAKISTAN: 'Pakistan',
  PALAU: 'Palau',
  PALESTINIAN_TERRITORIES: 'Palestinian territories',
  PANAMA: 'Panama',
  PAPUA_NEW_GUINEA: 'Papua New Guinea',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  PHILIPPINES: 'Philippines',
  PITCAIRN: 'Pitcairn',
  POLAND: 'Poland',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Puerto Rico',
  QATAR: 'Qatar',
  REPUBLIC_OF_CONGO: 'Republic of Congo',
  ROMANIA: 'Romania',
  RUSSIAN_FEDERATION: 'Russian Federation',
  RWANDA: 'Rwanda',
  REUNION: 'Réunion',
  SAO_TOME_AND_PRINCIPE: 'São Tomé and Príncipe',
  SAINT_HELENA: 'Saint Helena',
  SAINT_KITTS_AND_NEVIS: 'Saint Kitts and Nevis',
  SAINT_LUCIA: 'Saint Lucia',
  SAINT_VINCENT_AND_THE_GRENADINES: 'Saint Vincent and the Grenadines',
  SAMOA: 'Samoa',
  SAN_MARINO: 'San Marino',
  SAUDI_ARABIA: 'Saudi Arabia',
  SENEGAL: 'Senegal',
  SERBIA: 'Serbia',
  SEYCHELLES: 'Seychelles',
  SIERRA_LEONE: 'Sierra Leone',
  SINGAPORE: 'Singapore',
  SLOVAKIA: 'Slovakia',
  SLOVENIA: 'Slovenia',
  SOLOMON_ISLANDS: 'Solomon Islands',
  SOMALIA: 'Somalia',
  SOUTH_AFRICA: 'South Africa',
  SOUTH_KOREA: 'South Korea',
  SOUTH_SUDAN: 'South Sudan',
  SPAIN: 'Spain',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Sudan',
  SURINAME: 'Suriname',
  SWAZILAND: 'Swaziland',
  SWEDEN: 'Sweden',
  SWITZERLAND: 'Switzerland',
  SYRIA: 'Syria',
  TAIWAN: 'Taiwan',
  TAJIKISTAN: 'Tajikistan',
  TANZANIA: 'Tanzania',
  THAILAND: 'Thailand',
  'TIMOR-LESTE': 'Timor-Leste',
  TOGO: 'Togo',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad and Tobago',
  TUNISIA: 'Tunisia',
  TURKEY: 'Turkey',
  TURKMENISTAN: 'Turkmenistan',
  TUVALU: 'Tuvalu',
  UGANDA: 'Uganda',
  UKRAINE: 'Ukraine',
  UNITED_ARAB_EMIRATES: 'United Arab Emirates',
  UNITED_KINGDOM: 'United Kingdom',
  UNITED_STATES: 'United States',
  URUGUAY: 'Uruguay',
  US_VIRGIN_ISLANDS: 'US Virgin Islands',
  UZBEKISTAN: 'Uzbekistan',
  VANUATU: 'Vanuatu',
  VATICAN_CITY_STATE: 'Vatican City State',
  VENEZUELA: 'Venezuela',
  VIETNAM: 'Vietnam',
  'WESTERN SAHARA': 'Western Sahara',
  YEMEN: 'Yemen',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabwe',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const EDUCATION_CHOICES = {
  SECONDARY_EDUCATION: 'Secondary Education (GCSE/O-Levels)',
  POST_SECONDARY_EDUCATION:
    'Post-Secondary Education (College, A-Levels, NVQ3 or below, or similar)',
  VOCATIONAL_QUALIFICATION:
    'Vocational Qualification (Diploma, Certificate, BTEC, NVQ 4 and above, or similar)',
  UNDERGRADUATE_DEGREE: 'Undergraduate Degree (BA, BSc etc.)',
  POSTGRADUATE_DEGREE: 'Postgraduate Degree (MA, MSc etc.)',
  DOCTORATE: 'Doctorate (PhD)',
  NONE: 'None of the above',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const JOB_LEVEL_CHOICES = {
  LEADING_SELF: 'Leading Self – e.g., individual contributor, team member etc.',
  LEADING_OTHERS:
    'Leading Others – first level leader roles, e.g., supervisor, team leader, manager',
  LEADING_LEADERS:
    'Leading Leaders – mid level leader roles, e.g., general manager, regional leader etc.',
  LEADING_A_BUSINESS:
    'Leading a Business – senior level leader roles, e.g., Functional or Regional Head / Director',
  LEADING_AN_ENTERPRISE:
    'Leading an Enterprise – executive level leader roles, e.g., C-Suite, Board of Directors, VP, Director etc.',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const JOB_LEVEL_SHORT_CHOICES = {
  LEADING_SELF: 'Leading Self',
  LEADING_OTHERS: 'Leading Others',
  LEADING_LEADERS: 'Leading Leaders',
  LEADING_A_BUSINESS: 'Leading a Business',
  LEADING_AN_ENTERPRISE: 'Leading an Enterprise',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const INDUSTRY_CHOICES = {
  ACCOUNTING: 'Accounting / Finance / Banking',
  ADMINISTRATION: 'Administration / Clerical / Reception',
  ADVERTISEMENT: 'Advertisement / PR / Publishing / Marketing',
  ARCHITECTURE: 'Architecture / Design',
  ARTS: 'Arts / Leisure / Entertainment',
  BEAUTY: 'Beauty / Fashion',
  BUYING: 'Buying / Purchasing',
  CONSTRUCTION: 'Construction / Manual trade',
  CONSULTING: 'Consulting',
  CUSTOMER_SERVICE: 'Customer service',
  DISTRIBUTION: 'Distribution / Transportation / Logistics',
  EDUCATION: 'Education / Academia',
  EMERGENCY_SERVICES: 'Emergency services (Police / Fire / Ambulance)',
  ENGINEERING: 'Engineering',
  ENTREPRENEUR: 'Entrepreneur',
  FARMING: 'Farming, Fishing and Forestry',
  HEALTH_CARE: 'Health care (Physical & Mental)',
  HUMAN_RESOURCES_MANAGEMENT: 'Human resources management',
  INSTALLATION_MAINTENANCE_REPAIR: 'Installation, Maintenance and Repair',
  LEGAL: 'Legal',
  NEWS: 'News / Information / Media',
  PLANNING: 'Planning (Meeting, Events, etc.)',
  PRODUCTION: 'Production / Manufacture',
  PROPERTY: 'Real estate / Property',
  RESEARCH: 'Research',
  FOOD_SERVICE: 'Restaurant / Food service',
  SALES: 'Sales',
  SCIENCE: 'Science / Technology / Programming / Computing',
  SOCIAL_SERVICE: 'Social service',
  OTHER: 'Other',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
};
