import React from 'react';

import { IdAndName } from '^/reducers/api/types';

type ToggleItem = IdAndName &
  Readonly<{
    selected?: boolean;
  }>;

interface OwnProps {
  items: ReadonlyArray<ToggleItem>;
  disabled?: boolean;
  onClick(id: string): void;
}

type Props = OwnProps;

export default class ToggleSwitch extends React.PureComponent<Props> {
  public render() {
    const { items, disabled } = this.props;
    return (
      <div className="form-toggle">
        {items.map(item => (
          <span className="form-toggle-button" key={item.id}>
            <input
              className="form-toggle-input"
              type="radio"
              value={item.id}
              checked={item.selected}
              disabled={disabled}
              onChange={this.onClick}
              id={item.id}
            />
            <label className="btn btn-small" htmlFor={item.id} tabIndex={0}>
              {item.name}
            </label>
          </span>
        ))}
      </div>
    );
  }
  private onClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    this.props.onClick(event.target.value);
  };
}
