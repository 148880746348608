import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { updateProductOrganisationReportTemplate } from '^/actions/collections';
import Editable from '^/components/editable/Editable';

export class EditReportCost extends PureComponent {
  render() {
    const { report, orgReportCostObject, updateReportResponse } = this.props;
    const orgReportCost = orgReportCostObject.get('credit_cost');
    return (
      <Editable
        block
        placeholder={i18next.t('Cost')}
        value={orgReportCost === null ? report.get('cost') : orgReportCost}
        fieldName={`report.${orgReportCostObject.get('id')}.cost`}
        onSave={newValue =>
          this.props.updateProductOrganisationReportTemplate(
            orgReportCostObject.get('id'),
            { credit_cost: newValue }
          )
        }
        response={updateReportResponse}
      />
    );
  }
}

export default connect(null, { updateProductOrganisationReportTemplate })(
  EditReportCost
);
