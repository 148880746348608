import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
};

export default ({ className, width = 73, height = 72 }: Props) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4216 36.1092C71.4216 55.4391 55.7516 71.1091 36.4217 71.1091C17.0919 71.1091 1.42188 55.4391 1.42188 36.1092C1.42188 16.7794 17.0919 1.10938 36.4217 1.10938C55.7516 1.10938 71.4216 16.7794 71.4216 36.1092ZM66.2981 36.4188C66.1321 52.7774 52.8196 65.9872 36.4217 65.9872C20.0239 65.9872 6.71143 52.7774 6.54538 36.4188H21.7114C23.1033 36.4188 24.356 35.5739 24.8774 34.2832L30.9821 19.172L39.3492 57.3468C40.0809 60.6853 44.72 60.9973 45.8921 57.7869L53.6937 36.4188H66.2981ZM65.9142 31.2969H52.4995C51.0653 31.2969 49.7839 32.1932 49.292 33.5404L43.095 50.5136L34.8029 12.6812C34.084 9.40121 29.5592 9.01986 28.3015 12.1332L20.5597 31.2969H6.92933C9.23075 17.0838 21.5589 6.23131 36.4217 6.23131C51.2846 6.23131 63.6127 17.0838 65.9142 31.2969Z"
      fill="white"
      stroke="#1C75BC"
      strokeWidth="1.20689"
    />
  </svg>
);
