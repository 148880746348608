/* eslint-disable react/no-string-refs */
import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Map, List } from 'immutable';
import i18next from 'i18next';
import ReactTooltip from 'react-tooltip';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatDate } from '^/utils';
import { getStatus } from '^/activityStatus';
import { can, administerOrganisations } from '^/capabilities';
import { isFieldPending, getFormError } from '^/responseStates';
import { addPulsingToActivity } from '^/actions/actions';
import Editable from '^/components/editable/Editable';
import { updateActivity, updateJobProfile } from '^/actions/collections';
import {
  removeProductVersionFromActivityModal,
  openConfirmationModal,
  closeTopModal,
  openModal,
} from '^/actions/modals';
import {
  closeModalAndApplyTemplate,
  closeModalAndUpdateActivity,
} from '^/actions/actionSequences';
import { getUserDisplayName } from '^/selectors/user';
import PureComponent from '^/components/PureComponent';
import Checkbox from '^/components/Checkbox';
import { selectAccreditationLostProducts } from '^/components/activities/selectors';
import { getNumberAvailable, ACTIVITY_TYPE } from '^/models/activity';
import QuantityDropdown from '^/components/dropdown/QuantityDropdown';
import DateRangePicker from '^/components/date/DateRangePicker';
import ActivityProductVersions from '^/components/activities/ActivityProductVersions';
import DetailRow from '^/components/detail-list/DetailRow';
import AutoGenerateCreditWarning from '^/components/activities/AutoGenerateCreditWarning';
import LostProductAccessWarning from '^/components/activities/LostProductAccessWarning';
import PulseCheckButton from '^/components/pulses/PulseCheckButton';
import Loading from '../Loading';

export class ActivityDetail extends PureComponent {
  getFieldName(fieldPath) {
    const { activity } = this.props;
    const { id } = activity.toObject();
    const fields = [].concat(fieldPath);
    return `activities.${id}.` + fields.join('.');
  }

  copyShareLink() {
    this.refs.shareLink.select();
    try {
      const success = document.execCommand('copy');
      if (success) {
        return;
      }
    } catch (err) {
      // Handled below
    }
    alert(
      [
        i18next.t('Your browser may not support automatic copy.'),
        i18next.t('Use Edit > Copy to copy the URL.'),
      ].join('\n\n')
    );
  }

  selectText() {
    this.refs.shareLink.select();
  }

  startNotificationMuted() {
    const { activity } = this.props;
    return activity
      .get('notification_emails', List())
      .find(
        notification => notification.get('trigger') === 'ACTIVITY_START',
        this,
        Map()
      )
      .get('muted', false);
  }

  updateField(field, newValue) {
    const activity = this.props.activity.toObject();
    const job_profile = activity.job_profile?.toObject();
    field === 'role_name'
      ? this.props.updateJobProfile(job_profile.id, {
          [field]: newValue,
        })
      : this.props.updateActivity(activity.id, {
          [field]: newValue,
        });
  }

  updateActivityToAllowPulsingCapabilities() {
    const activity = this.props.activity.toObject();

    this.props.updateActivity(activity.id, {
      ...activity,
      with_pulse: true,
      adding_pulse_check: true,
    });
  }

  openPulseDetailView(pulseId) {
    this.props.push(`/page/pulses/${pulseId}`);
  }

  render() {
    const {
      activity,
      response,
      readOnly,
      isLiveOrEnded,
      createdOn,
      prepayUrl,
      accreditationLostProducts,
      user,
      profileCreator,
    } = this.props;
    const {
      id,
      start_datetime,
      end_datetime,
      created_by_user,
      organisation,
      type,
      is_prepay,
      is_staged,
      activity_product_version_items: activityProductVersions,
      has_sessions,
      remaining_credit_cost: costToGenerateRemaining,
      invitation_link_usage_limit,
      num_respondents,
      users,
      with_pulse,
      job_profile,
    } = activity.toObject();

    const role_name = (job_profile && job_profile.get('role_name')) || '';
    const {
      name: organisationName,
      has_unlimited_credits: hasUnlimitedCredits,
      is_using_credit: isUsingCredit,
      credit,
      productorganisation_set,
    } = organisation.toObject();
    const status = getStatus(activity);
    const createdOnDisplay = formatDate(createdOn);
    const createdByUserDisplay = getUserDisplayName(user, created_by_user);
    const completedUsersCount = users
      ? users.filter(each => each.get('has_started')).count()
      : num_respondents;
    const numberAvailable = getNumberAvailable(activity);
    const isVariant = activityProductVersions.some(each =>
      each.get('product_variant')
    );
    const pulseId = activityProductVersions
      .map(each => each.getIn(['pulse', 'id']))
      .first();
    const firstProductId = activityProductVersions
      .first()
      .getIn(['product_version', 'product', 'id']);
    const orgProductOrganisation = productorganisation_set.find(
      productOrganisation =>
        productOrganisation.get('product') === firstProductId
    );
    const canPulse =
      !with_pulse &&
      orgProductOrganisation &&
      orgProductOrganisation.get('pulse_behaviour_hierarchy');

    const isPulsing = with_pulse && pulseId;
    const isJobProfiler = type === ACTIVITY_TYPE.JOB_PROFILER;
    return (
      <div className="activity-detail">
        {accreditationLostProducts.length !== 0 && (
          <LostProductAccessWarning
            unavailableProducts={accreditationLostProducts}
          />
        )}
        {isUsingCredit &&
          !hasUnlimitedCredits &&
          credit < costToGenerateRemaining &&
          !isVariant && (
            <AutoGenerateCreditWarning organisation={organisation} />
          )}
        <div className="form-inline">
          <DetailRow
            className="list-item-dates"
            valueClassName="activity-dates"
            label={i18next.t('Dates')}
          >
            <DateRangePicker
              onSave={this.updateField.bind(this)}
              showTime
              first={{
                fieldName: 'start_datetime',
                disabled: has_sessions || readOnly,
                value: start_datetime,
                isLoading: isFieldPending(response, 'start_datetime'),
                defaultValue: i18next.t('Start date'),
                prefix: `${i18next.t('Start')}:`,
                error: getFormError(response, 'start_datetime'),
              }}
              second={{
                fieldName: 'end_datetime',
                disabled: readOnly,
                value: end_datetime,
                isLoading: isFieldPending(response, 'end_datetime'),
                defaultValue: i18next.t('Close date'),
                prefix: `${i18next.t('Close')}:`,
                error: getFormError(response, 'end_datetime'),
              }}
            />
          </DetailRow>
          <DetailRow label={i18next.t('Products')} className="stacked">
            <ActivityProductVersions
              activityId={id}
              activityType={type}
              activityStatus={status}
              organisation={organisation}
              activityProductVersions={activityProductVersions}
              readOnly={readOnly}
              isLiveOrEnded={isLiveOrEnded}
              isPulsing={isPulsing}
              isJobProfiler={isJobProfiler}
            />
          </DetailRow>

          {canPulse && !pulseId && (
            <DetailRow label={i18next.t('Pulse Check')}>
              <PulseCheckButton
                text="Add pulse check"
                title="Add pulsing to your activity for respondents to track their progress"
                onClick={this.updateActivityToAllowPulsingCapabilities.bind(
                  this
                )}
              />
            </DetailRow>
          )}

          {isPulsing && (
            <DetailRow label={i18next.t('Pulse Check')}>
              <PulseCheckButton
                text="View pulse check"
                title="Open the pulsing settings to manage and invite respondents to pulse"
                onClick={this.openPulseDetailView.bind(this, pulseId)}
                className="view-pulse-check"
              />
            </DetailRow>
          )}

          {is_staged && (
            <DetailRow label={i18next.t('Staged assessment')}>
              <Checkbox
                id="is_staged"
                name="is_staged"
                label={i18next.t('Is staged')}
                checked
                disabled
              />
            </DetailRow>
          )}

          {isJobProfiler && (
            <DetailRow label={i18next.t('Job role')}>
              {role_name ? (
                <>
                  <Editable
                    className="in-page"
                    placeholder={i18next.t('Job role')}
                    value={role_name}
                    fieldName={this.getFieldName('role_name')}
                    responseFieldName="role_name"
                    onSave={this.updateField.bind(this, 'role_name')}
                    response={response}
                    readOnly={
                      readOnly ||
                      (isJobProfiler &&
                        profileCreator &&
                        profileCreator.get('has_started'))
                    }
                  />
                  <div className="tooltip">
                    <span data-tip data-for="job-role-tooltip">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="margin-left-small"
                      />
                    </span>
                    <ReactTooltip
                      id="job-role-tooltip"
                      textColor="black"
                      backgroundColor="white"
                    >
                      {i18next.t(
                        'This is the name that will appear in the reports and profile text.'
                      )}
                    </ReactTooltip>
                  </div>
                </>
              ) : (
                <div>
                  <Loading />
                </div>
              )}
            </DetailRow>
          )}

          <DetailRow label={i18next.t('Activity invitation')}>
            {is_prepay ? (
              <div className="display-flex">
                <span className="list-item margin-right">
                  {i18next.t(`'Copy and paste' link`)}
                </span>
                <input
                  className="list-item inline-flex no-margin wide"
                  type="text"
                  ref="shareLink"
                  value={prepayUrl}
                  onClick={this.selectText.bind(this)}
                />
                {!isJobProfiler && (
                  <QuantityDropdown
                    value={invitation_link_usage_limit}
                    minQuantity={
                      invitation_link_usage_limit &&
                      invitation_link_usage_limit < completedUsersCount
                        ? invitation_link_usage_limit
                        : completedUsersCount
                    }
                    maxQuantity={
                      numberAvailable === undefined
                        ? undefined
                        : numberAvailable + invitation_link_usage_limit
                    }
                    hasUnlimited={numberAvailable === undefined}
                    maxDropdownValue={10}
                    onChange={newValue =>
                      this.updateField(
                        ['invitation_link_usage_limit'],
                        newValue
                      )
                    }
                    response={response}
                    responseFieldName="invitation_link_usage_limit"
                    fieldName="invitation_link_usage_limit"
                    errorKey="invitation_link_usage_limit"
                  />
                )}
                <button
                  type="button"
                  className="btn btn-default btn-small margin-left"
                  onClick={() => this.copyShareLink()}
                >
                  {i18next.t('Copy')}
                </button>
              </div>
            ) : isJobProfiler ? (
              <span>{i18next.t('Platform invitation')} </span>
            ) : (
              <span>
                {i18next.t(
                  'Platform invitation - your respondents are added below'
                )}
              </span>
            )}
          </DetailRow>
          <DetailRow label={i18next.t('Created by')}>
            {can(user, administerOrganisations())
              ? i18next.t(
                  '{{createdByUserDisplay}} for {{organisationName}} on {{createdOnDisplay}}',
                  { createdByUserDisplay, organisationName, createdOnDisplay }
                )
              : i18next.t('{{createdByUserDisplay}} on {{createdOnDisplay}}', {
                  createdByUserDisplay,
                  createdOnDisplay,
                })}
          </DetailRow>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    user: state.userProfile,
    accreditationLostProducts: selectAccreditationLostProducts(
      state,
      ownProps.activity
    ),
  };
}

export default connect(mapStateToProps, {
  addPulsingToActivity,
  removeProductVersionFromActivityModal,
  openConfirmationModal,
  openModal,
  closeTopModal,
  closeModalAndUpdateActivity,
  closeModalAndApplyTemplate,
  updateActivity,
  updateJobProfile,
  push,
})(ActivityDetail);
