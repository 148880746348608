import React, { Key } from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

import Icon from './Icon';
import Dropdown from './dropdown/Dropdown';
import PillSearch from './PillSearch';
import Checkbox from './Checkbox';

export type FilterOption = {
  label: string;
  key: string;
};

export type Sorting = {
  key: string;
  reverse: boolean;
};

export type Selections = {
  id: string;
  checked: boolean;
  selectable: boolean;
};

export type FilterOptions = Record<string, FilterOption[]>;
export type SearchOptions = Record<string | number, string | number>;
export interface ColumnFilter<T> {
  type: 'dropdown-single' | 'dropdown-multi' | 'search' | 'compare_date';
  key: keyof T;
}

export interface Column<T> {
  header: {
    label: string;
    key: keyof T;
  };
  filter?: ColumnFilter<T>;
  sortable: boolean;
}

export interface FiltersAndOrdering {
  filters?: FilterOptions;
  dateFilters?: Array<{ type: string; date: string; baseDateFilter: string }>;
  searches?: SearchOptions;
  sorting: Sorting;
  search?: string;
  page: number;
}

export interface PaginatedResultsResponse<T> {
  results: T[];
  page?: number;
  count: number;
}

interface Props<T> {
  className?: string;
  pageSize: number;
  page: number;
  loadPage: () => void | null;
  columns: ReadonlyArray<Column<T>>;
  rows:
    | PaginatedResultsResponse<T | { [key: string]: string }>
    | null
    | undefined;
  selectedFilterOptions: FiltersAndOrdering | null;
  filters: FilterOptions | null;
  setSelectedFilter: (
    type: string,
    key: string | number,
    selectedId: string | number,
    reverse?: boolean
  ) => void;
  cellRenderer: (key: string, value: string, row: T[]) => JSX.Element;
  isDataPending: boolean;
  isFilterDataPending: boolean;
  isNextPageDataPending: boolean;
  enableCheckBoxes: boolean;
  selectAllDisabled?: boolean;
  initialSelections: ReadonlyArray<Selections> | undefined;
  removeCompareDate?: (type: string) => void;
  onSelection?: (checked: boolean, row: T[] | null, selectAll: boolean) => void;
}

export const InteractiveTable = <T extends { key: Key; value: string }>(
  props: Props<T>
) => {
  const {
    className,
    columns,
    setSelectedFilter,
    rows,
    selectedFilterOptions,
    isDataPending,
    isFilterDataPending,
    cellRenderer,
    filters,
    pageSize,
    loadPage,
    page,
    initialSelections,
    onSelection,
    enableCheckBoxes,
    isNextPageDataPending,
    selectAllDisabled,
    removeCompareDate,
  } = props;
  const handleSort = (key: string, reverse: boolean) => {
    setSelectedFilter('sort', key, '', reverse);
  };
  const setFilter = (
    itemSelected: null | string | number,
    filter: null | string | number
  ) => {
    filter &&
      setSelectedFilter(
        'filter',
        String(filter),
        itemSelected ? itemSelected : ''
      );
  };

  const openDateModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedFilter('compare_date', String(e.currentTarget.value), '');
  };

  const removeDate = (filterKey: string | undefined) => {
    filterKey && removeCompareDate && removeCompareDate(filterKey);
  };

  const clearSelection = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    key: string,
    selected: string
  ) => {
    e.stopPropagation();
    setFilter(selected, key);
  };

  const addCloseIcon = (title: string, selected: string, key: string) => {
    return title === i18next.t<string>('Select') ? (
      title
    ) : (
      <>
        <div
          className="underlined"
          onClick={e => clearSelection(e, key, selected)}
        >
          {i18next.t<string>('Clear x')}
        </div>
      </>
    );
  };

  const onSearchChange = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    setSelectedFilter('search', key, e ? e.currentTarget.value : '');
  };

  const toggleCheckbox = (checked: boolean, row: T[]) => {
    onSelection && onSelection(checked, row as T[], false);
  };

  const toggleSelectAll = (checked: boolean) => {
    onSelection && onSelection(checked, null, true);
  };

  const isSelected = (id: string) => {
    const checkbox = initialSelections?.find(item => item.id === id);
    return checkbox?.checked;
  };

  const isDisabled = (id: string) => {
    const checkbox = initialSelections?.find(item => item.id === id);
    return !checkbox?.selectable;
  };

  const data =
    rows &&
    rows.results.map(row =>
      Object.entries<string | number | []>(row).map(([key, value]) => ({
        key,
        value,
      }))
    );

  const anyPending =
    isFilterDataPending || isDataPending || isNextPageDataPending;
  const showLoadMore =
    rows &&
    pageSize < rows.count &&
    page < Math.ceil(rows.count / pageSize) &&
    !anyPending;
  return (
    <>
      <table
        className={classNames('interactive-table responsive-table', className)}
      >
        <thead>
          <tr>
            {enableCheckBoxes && (
              <th className="select">
                <div className="table-header-wrapper">
                  <Checkbox
                    value={'selectAll'}
                    disabled={
                      anyPending ||
                      initialSelections?.filter(
                        item => item.selectable === true
                      ).length === 0 ||
                      selectAllDisabled
                    }
                    id={'selectAll'}
                    onChange={e => toggleSelectAll(e.target.checked)}
                    checked={
                      initialSelections?.filter(
                        item => item.selectable === true
                      ).length !== 0 &&
                      initialSelections?.filter(item => item.checked === true)
                        .length ===
                        initialSelections?.filter(
                          item => item.selectable === true
                        ).length
                    }
                  />
                </div>
              </th>
            )}
            {columns.map(
              (column, idx) =>
                ((enableCheckBoxes && idx !== 0) || !enableCheckBoxes) &&
                column &&
                column.header && (
                  <th key={String(column.header.key)}>
                    <div className="table-header-wrapper">
                      <div>
                        {column.sortable && (
                          <Icon
                            type={'up-down'}
                            className={classNames('sort-control', {
                              up:
                                selectedFilterOptions?.sorting.reverse &&
                                selectedFilterOptions.sorting.key ===
                                  column.header.key,
                              down:
                                !selectedFilterOptions?.sorting.reverse &&
                                selectedFilterOptions?.sorting.key ===
                                  column.header.key,
                              disabled: anyPending,
                            })}
                            onClick={() =>
                              !anyPending &&
                              handleSort(
                                String(column.header.key),
                                !selectedFilterOptions?.sorting.reverse
                              )
                            }
                          />
                        )}
                        <span>{column.header.label}</span>
                      </div>
                      <div>
                        {column.filter &&
                          column.filter.type !== 'search' &&
                          column.filter.type !== 'compare_date' && (
                            <Dropdown
                              defaultValue={
                                (column.filter.type === 'dropdown-single' &&
                                  selectedFilterOptions?.filters &&
                                  selectedFilterOptions?.filters[
                                    String(column.filter.key)
                                  ] &&
                                  selectedFilterOptions?.filters[
                                    String(column.filter.key)
                                  ][0].key) ||
                                null
                              }
                              {...(column.filter.type === 'dropdown-single'
                                ? {
                                    formatDisplay: title =>
                                      addCloseIcon(
                                        title as string,
                                        (selectedFilterOptions?.filters &&
                                          selectedFilterOptions?.filters[
                                            String(column.filter?.key)
                                          ] &&
                                          selectedFilterOptions?.filters[
                                            String(column.filter?.key)
                                          ][0].key) ||
                                          '',
                                        String(column.filter?.key)
                                      ),
                                  }
                                : {})}
                              name={String(column.filter.key)}
                              title={i18next.t<string>('Select')}
                              items={
                                filters &&
                                filters[String(column.filter.key)] &&
                                filters[String(column.filter.key)].map(
                                  ({ key, label }) => ({
                                    id: key,
                                    name: label,
                                  })
                                )
                              }
                              selectedItems={
                                selectedFilterOptions?.filters &&
                                selectedFilterOptions?.filters[
                                  String(column.filter.key)
                                ] &&
                                selectedFilterOptions?.filters[
                                  String(column.filter.key)
                                ].map(items => items.key)
                              }
                              onClick={selected =>
                                setFilter(selected, String(column.filter?.key))
                              }
                              loading={anyPending}
                              selectedTitle={i18next.t<string>(
                                '{{count}} selected',
                                {
                                  count:
                                    (selectedFilterOptions?.filters &&
                                      selectedFilterOptions?.filters[
                                        String(column.filter.key)
                                      ] &&
                                      selectedFilterOptions?.filters[
                                        String(column.filter.key)
                                      ].length) ||
                                    0,
                                }
                              )}
                              selectable={
                                column.filter.type === 'dropdown-multi'
                                  ? true
                                  : false
                              }
                            />
                          )}
                        {column.filter && column.filter.type === 'search' && (
                          <PillSearch
                            disabled={anyPending}
                            className="search-table"
                            onChange={e =>
                              onSearchChange(String(column.filter?.key), e)
                            }
                            placeholder={i18next.t<string>('Search')}
                          />
                        )}
                        {column.filter &&
                          column.filter.type === 'compare_date' && (
                            <div className="compare-date">
                              <button
                                className="btn btn-small btn-icon-square-secondary"
                                disabled={anyPending}
                                value={String(column.filter?.key)}
                                onClick={e => openDateModal(e)}
                              >
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </button>
                              {selectedFilterOptions?.dateFilters &&
                                !anyPending && (
                                  <a
                                    className="date-text"
                                    onClick={() =>
                                      removeDate(
                                        column.filter &&
                                          column.filter.key.toString()
                                      )
                                    }
                                  >
                                    {selectedFilterOptions?.dateFilters &&
                                      selectedFilterOptions.dateFilters
                                        .filter(
                                          item =>
                                            item.baseDateFilter ===
                                            column.filter?.key
                                        )
                                        .map((items, ix) => (
                                          <span key={ix}>
                                            <div>
                                              {items.type.split('_')[2] || ''}:
                                            </div>
                                            <div>{items.date} x</div>
                                          </span>
                                        ))}
                                  </a>
                                )}
                            </div>
                          )}
                      </div>
                    </div>
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {!isDataPending && data && !data?.length && (
            <tr>{i18next.t<string>('No results to show')}</tr>
          )}
          {isDataPending ? (
            <tr>
              <td colSpan={columns.length}>
                <Skeleton />
              </td>
            </tr>
          ) : (
            data &&
            data.map((row, idx) => (
              <tr key={idx}>
                {columns.map(column =>
                  row.map(
                    (cell, i) =>
                      column &&
                      column.header &&
                      column.header.key === cell.key && (
                        <td key={cell.key}>
                          {i === 0 && enableCheckBoxes ? (
                            <Checkbox
                              onChange={e =>
                                toggleCheckbox(e.target.checked, row as T[])
                              }
                              value={String(cell.value)}
                              checked={isSelected(String(cell.value))}
                              disabled={
                                isDisabled(String(cell.value)) || anyPending
                              }
                              id={String(cell.value)}
                            />
                          ) : (
                            cellRenderer(
                              cell.key,
                              String(cell.value),
                              row as T[]
                            )
                          )}
                        </td>
                      )
                  )
                )}
              </tr>
            ))
          )}
          {isNextPageDataPending && (
            <tr>
              <td colSpan={columns.length}>
                <Skeleton />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showLoadMore && (
        <div className="center">
          <a onClick={loadPage}>
            {i18next.t<string>('Load page')} {page + 1}
          </a>
        </div>
      )}
    </>
  );
};
