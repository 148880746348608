import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import i18next from 'i18next';
import classNames from 'classnames';

import { formatDate } from '^/utils';
import { can, changeUsersState } from '^/capabilities';
import { allowsMore } from '^/models/organisation';
import { forDisplay, USER_STATUS } from '^/models/user';
import { closeTopModal, openConfirmationModal } from '^/actions/modals';
import { updateUserStatusAndCloseModal } from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import ProfilePicture from '^/components/profile/ProfilePicture';
import Email from '^/components/Email';
import WithPopover from '^/components/WithPopover';
import Checkbox from '^/components/Checkbox';

export class UserRow extends PureComponent {
  select() {
    if (this.props.isSelectable && !this.props.disabled) {
      this.props.onSelect();
    }
  }

  getClassName(status) {
    const { isSelectable, disabled, isSelected } = this.props;

    return classNames('user-row', status.toLowerCase(), {
      selectable: isSelectable && !disabled,
      selected: isSelected,
      disabled,
    });
  }

  setStatus(status) {
    this.props.updateUserStatusAndCloseModal(this.props.user.get('id'), {
      status,
    });
  }

  showDeactivateConfirmationModal() {
    const userEmail = this.props.user.get('email');
    this.props.openConfirmationModal(
      'deactivate',
      'user',
      [
        i18next.t(
          'User {{userEmail}} will no longer be able to access Enable.',
          { userEmail }
        ),
      ],
      () => this.setStatus(USER_STATUS.INACTIVE),
      this.props.closeTopModal,
      'btn-danger'
    );
  }

  showReactivateConfirmationModal() {
    const userEmail = this.props.user.get('email');
    this.props.openConfirmationModal(
      'reactivate',
      'user',
      [
        i18next.t(
          'User {{userEmail}} will once again be able to access Enable.',
          { userEmail }
        ),
      ],
      () => this.setStatus(USER_STATUS.ACTIVE),
      this.props.closeTopModal,
      'btn-success'
    );
  }

  renderName(id, full_name) {
    const { isSelectable } = this.props;
    if (full_name) {
      return isSelectable ? (
        full_name
      ) : (
        <Link to={`/page/individuals/${id}`}>{full_name}</Link>
      );
    }
    return i18next.t('No Name Provided');
  }

  renderOrg(org) {
    return org.get('name') + (org.get('is_active') ? '' : ' (INACTIVE)');
  }

  orgAllowsMoreAdmins(user) {
    if (user.get('organisation')) {
      return allowsMore(user.get('organisation'), user.get('role'));
    }
    return true;
  }

  renderActionButton(status, user) {
    const canChangeState = can(this.props.userProfile, changeUsersState(user));
    const noMoreAdminsAllowed = !this.orgAllowsMoreAdmins(user);
    switch (status) {
      case USER_STATUS.UNINVITED:
        return (
          <button
            className="btn btn-default btn-small no-margin"
            onClick={() => this.props.invite()}
          >
            {i18next.t('Send invite')}
          </button>
        );
      case USER_STATUS.INVITED:
        return (
          <button
            className="btn btn-default btn-small no-margin"
            onClick={() => this.props.invite()}
          >
            {i18next.t('Resend invite')}
          </button>
        );
      case USER_STATUS.ACTIVE:
        return (
          canChangeState && (
            <button
              className="btn btn-danger-secondary btn-small no-margin"
              onClick={() => this.showDeactivateConfirmationModal()}
            >
              {i18next.t('Deactivate')}
            </button>
          )
        );
      case USER_STATUS.INACTIVE:
        return (
          canChangeState && (
            <button
              className="btn btn-success-secondary btn-small no-margin"
              onClick={() => this.showReactivateConfirmationModal()}
              disabled={noMoreAdminsAllowed}
            >
              {i18next.t('Reactivate')}
            </button>
          )
        );
      default:
        return null;
    }
  }

  renderGroupsPopover(groups) {
    return !groups || (groups && groups.isEmpty()) ? (
      <p>{i18next.t('No groups')}</p>
    ) : (
      <div>{groups.map(group => group.get('name')).join(', ')}</div>
    );
  }

  render() {
    const {
      user,
      isSelectable,
      showOrganisations,
      shouldShowGroups,
      hideProfilePicture,
      isSelectModal,
      selectOne,
      isSelected,
      disabled,
      simple,
    } = this.props;

    const {
      full_name,
      email,
      organisation,
      groups,
      usergroup_set,
      status_date,
      id,
    } = user.toObject();
    const status = user.get('status', '');
    const userGroups = usergroup_set || groups;

    return (
      <tr
        className={this.getClassName(status)}
        disabled={this.props.disabled}
        onClick={() => this.select()}
      >
        {isSelectable && (
          <td>
            <Checkbox checked={isSelected} disabled={disabled} />
          </td>
        )}
        <td>
          <div className="user-profile">
            <ProfilePicture
              width={24}
              height={24}
              user={user}
              hideProfilePicture={hideProfilePicture}
            />
            {this.renderName(id, full_name)}
            {isSelectable && status !== USER_STATUS.ACTIVE && (
              <div className="user-status">{forDisplay(status)}</div>
            )}
          </div>
        </td>
        {showOrganisations && (
          <td>
            {(organisation && organisation.get('name')) ||
              i18next.t('No account')}
          </td>
        )}
        {shouldShowGroups && (
          <td>
            <WithPopover
              className="asset-count"
              popover={this.renderGroupsPopover(userGroups)}
            >
              <a className="hover-count">{userGroups && userGroups.count()}</a>
            </WithPopover>
          </td>
        )}
        <td className="user-email">
          {isSelectModal ? email : <Email email={email} />}
        </td>
        {!simple && (
          <td className="hidden-when-selectable">
            {forDisplay(status) || 'Status Unknown'}
          </td>
        )}
        {!simple && <td>{status_date && formatDate(status_date)}</td>}
        {!selectOne && !isSelectable && (
          <td className="actions">{this.renderActionButton(status, user)}</td>
        )}
      </tr>
    );
  }
}

UserRow.propTypes = {
  closeTopModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  invite: PropTypes.func,
  isSelectModal: PropTypes.bool,
  isSelectable: PropTypes.bool,
  onSelect: PropTypes.func,
  openConfirmationModal: PropTypes.func.isRequired,
  selectOne: PropTypes.bool,
  updateUserStatusAndCloseModal: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  userProfile: ImmutablePropTypes.map.isRequired,
  showOrganisations: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    userProfile: state.userProfile,
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  openConfirmationModal,
  updateUserStatusAndCloseModal,
})(UserRow);
