import React from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Map } from 'immutable';

import { navigateToPulseAndCloseModal } from '^/actions/actionSequences';
import { MODALS } from '^/components/modals/types';
import { formatDate } from '^/utils';
import { StoreState } from '^/store';
import { getUserDisplayName } from '^/selectors/user';

export interface SummaryData {
  id: string;
  created: string;
  pulse_id: string;
  name: string;
  quantity: number;
  admin_user: Immutable.Map<string, any>;
}

interface OwnProps {
  summaryData: SummaryData;
  modalId: MODALS;
}

interface StateProps {
  user: Immutable.Map<string, any>;
}

interface DispatchProps {
  closeModal: () => void;
}

type Props = OwnProps & DispatchProps & StateProps;

export const PurchasedPulseBreakdownSummary: React.FC<Props> = (
  props: Props
) => {
  return (
    <div className="shop-summary">
      <div>
        <h3>{i18next.t<string>('Date')}</h3>
        <p>{formatDate(props.summaryData.created)}</p>
      </div>
      <div>
        <h3>{i18next.t<string>('Details')}</h3>
        <a onClick={props.closeModal}>{props.summaryData.name}</a>
      </div>
      <div>
        <h3>{i18next.t<string>('Quantity')}</h3>
        <p>{props.summaryData.quantity}</p>
      </div>
      <div>
        <h3>{i18next.t<string>('Total cost (+VAT)')}</h3>
        <p>N/A</p>
      </div>
      <div>
        <h3>{i18next.t<string>('Admin user')}</h3>
        <p>
          {props.summaryData.admin_user &&
            getUserDisplayName(props.user, Map(props.summaryData.admin_user))}
        </p>
      </div>
    </div>
  );
};

export function mapStateToProps(state: StoreState) {
  return {
    user: state.userProfile,
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: OwnProps) {
  return {
    closeModal: () =>
      dispatch(
        navigateToPulseAndCloseModal(
          ownProps.summaryData.pulse_id,
          ownProps.modalId
        )
      ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasedPulseBreakdownSummary);
