import { DELETE, GET, PATCH, POST } from '^/constants/httpMethods';
import makeRsaAction from '^/middleware/makeRsaAction';
import { Uuid } from '^/reducers/api/types';
import makeAsyncActionSet from './makeAsyncActionSet';

export const GET_SHOP_ITEMS = makeAsyncActionSet('GET_SHOP_ITEM');
export function getShopItems() {
  return makeRsaAction(GET_SHOP_ITEMS, `/shop/shop-items/`, GET);
}

export const ADD_TO_BASKET = makeAsyncActionSet('ADD_TO_BASKET');
export function addToBasket(shopItemOptionId: Uuid) {
  return makeRsaAction(ADD_TO_BASKET, `/shop/add-basket-item/`, POST, {
    shop_item_option: shopItemOptionId,
  });
}

export const UPDATE_BASKET_ITEM_QUANTITY = makeAsyncActionSet(
  'UPDATE_BASKET_ITEM_QUANTITY'
);
export function updateBasketItemQuantity(basketItemId: Uuid, quantity: number) {
  return makeRsaAction(
    UPDATE_BASKET_ITEM_QUANTITY,
    `/shop/basket-items/${basketItemId}/`,
    PATCH,
    { quantity }
  );
}

export const UPDATE_BASKET_ITEM_REPORT_QUANTITY = makeAsyncActionSet(
  'UPDATE_BASKET_ITEM_REPORT_QUANTITY'
);
export function updateBasketItemReportQuantity(
  basketReportQuantityId: Uuid,
  quantity: number
) {
  return makeRsaAction(
    UPDATE_BASKET_ITEM_REPORT_QUANTITY,
    `/shop/report-quantities/${basketReportQuantityId}/`,
    PATCH,
    { quantity }
  );
}

export const REMOVE_FROM_BASKET = makeAsyncActionSet('REMOVE_FROM_BASKET');
export function removeFromBasket(basketItemId: Uuid) {
  return makeRsaAction(
    REMOVE_FROM_BASKET,
    `/shop/basket-items/${basketItemId}/`,
    DELETE,
    null,
    { id: basketItemId }
  );
}

export const SEND_ENQUIRY_EMAIL = makeAsyncActionSet('SEND_ENQUIRY_EMAIL');
export function sendEnquiryEmail(shopItemId: Uuid) {
  return makeRsaAction(
    SEND_ENQUIRY_EMAIL,
    `/shop/shop-items/${shopItemId}/enquire/`,
    POST
  );
}
