import React from 'react';

import { toQueryString } from '^/utils';

export interface MailToOptions {
  email: string | string[];
  subject?: string;
  cc?: string[];
  bcc?: string[];
  body?: string;
}

export type MailtoProps = MailToOptions &
  React.HTMLAttributes<HTMLAnchorElement>;

export function mailToLink(options: MailToOptions) {
  const { email, subject, cc, bcc, body } = options;
  const mailToEmail = Array.isArray(email) ? email.join(',') : email;
  return `mailto:${mailToEmail}?${toQueryString({
    subject,
    cc: cc && cc.join(', '),
    bcc: bcc && bcc.join(', '),
    body,
  })}`;
}

export const MailTo: React.FunctionComponent<MailtoProps> = ({
  children,
  email,
  subject,
  cc,
  bcc,
  body,
  ...props
}) => {
  return (
    <a href={mailToLink({ email, subject, cc, bcc, body })} {...props}>
      {children}
    </a>
  );
};

export default MailTo;
