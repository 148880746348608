import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { can, administerAnyOrganisations } from '../capabilities';
import { isPending } from '../responseStates';
import PureComponent from './PureComponent';
import UserRole from './UserRole';
import { withRouter } from '^/withRouter';

export const SidebarProfile = class SidebarProfile extends PureComponent {
  getClassName() {
    return 'profile-info';
  }

  getUserProperty(property, defaultValue) {
    const { user } = this.props;
    if (!user) {
      return '';
    }
    return user.get(property) || defaultValue;
  }

  getUserName() {
    return this.getUserProperty('full_name', i18next.t('No name provided'));
  }

  getUserEmail() {
    return this.getUserProperty('email', i18next.t('No email provided'));
  }

  getUserRole() {
    return this.getUserProperty('role', '');
  }

  renderLoggedInAs(user) {
    return (
      can(user, administerAnyOrganisations()) && (
        <span>
          <UserRole role={this.getUserRole()} />
        </span>
      )
    );
  }

  render() {
    const { user, response } = this.props;
    const hasLoaded = !isPending(response);

    return (
      <div className={this.getClassName()}>
        <div className="profile-info-name">
          {hasLoaded && this.getUserName()}
        </div>
        <div className="profile-info-role">
          {hasLoaded
            ? this.renderLoggedInAs(user)
            : i18next.t('Loading details...')}
        </div>
      </div>
    );
  }
};

function mapStateToProps(state, props) {
  return {
    router: props.router,
    user: state.userProfile,
    response: state.responses.get('getUserInfo'),
  };
}

export default withRouter(connect(mapStateToProps)(SidebarProfile));
