import { CALL_API } from 'redux-api-middleware';

export const addLanguageHeaderMiddleware = store => next => action => {
  if (action[CALL_API]) {
    action[CALL_API].headers['Accept-Language'] = store
      .getState()
      .ui.get('activeLanguage');
  }
  return next(action);
};

export default addLanguageHeaderMiddleware;
