import React from 'react';
type Props = {
  className?: string;
  width?: number;
  height?: number;
};
export default ({ className, width = 73, height = 72 }: Props) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 19.8834 39.999 19.767 39.997 19.6508H29.5019L24.7108 32.7732C24.2818 33.9484 22.5835 33.8343 22.3156 32.6121L17.0411 8.54716L12.8713 18.869C12.6804 19.3415 12.2219 19.6508 11.7123 19.6508H0.00298654C0.000998207 19.767 0 19.8834 0 20C0 31.0457 8.95431 40 20 40Z"
      fill="white"
    />
    <path
      d="M0.122279 17.7758C1.22859 7.77688 9.7061 0 20 0C30.2939 0 38.7714 7.77688 39.8777 17.7758H29.0647C28.5397 17.7758 28.0706 18.1039 27.8906 18.5971L23.6869 30.1107L18.4399 6.17108C18.1767 4.97036 16.5203 4.83076 16.0598 5.97049L11.2907 17.7758H0.122279Z"
      fill="white"
    />
  </svg>
);
