import React from 'react';

interface OwnProps {
  value: string | number;
}

const Currency: React.FunctionComponent<OwnProps> = ({ value }) => (
  <span>£{typeof value === 'string' ? value : value.toFixed(2)}</span>
);

export default Currency;
