import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { fromJS, List } from 'immutable';

import {
  setWelcomeModalPage,
  setAdditionalWelcomeModalPage,
  startActivityProductVersionSession,
} from '^/actions/actions';
import {
  startProductVersion,
  openProductVersion,
  clearPreviousAndLoadNewActivity,
  setCorrectWelcomeModalPageAndOpenPsycapPotentialWelcomeModal,
  setCorrectWelcomeModalPageAndOpenPerspectivesWelcomeModal,
  setCorrectWelcomeModalPageAndOpenWelcomeModalAndStartProductVersion,
  resetLanguageAndCloseTopModal,
} from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import WelcomePage from './WelcomePage';
import { getTranslatedRespondentAssessmentChecklistInfo } from '^/models/product';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

export class RespondentAssessmentChecklistModal extends PureComponent {
  constructor(props) {
    super(props);

    this.onContinue = this.onContinue.bind(this);
  }
  componentDidMount() {
    this.props.clearPreviousAndLoadNewActivity(this.props.activityId);
  }

  onContinue() {
    const {
      productVersion,
      activityId,
      activity,
      raterFor,
      questionCollectionIdx,
    } = this.props;

    if (!activity) {
      return;
    }

    let startWelcomeModalFlow = this.props
      .setCorrectWelcomeModalPageAndOpenWelcomeModalAndStartProductVersion;
    if (
      this.props.productVersion.getIn(['product', 'type']) ===
      PRODUCT_TYPES.CHOICES.PSYCAP_POTENTIAL
    ) {
      startWelcomeModalFlow = this.props
        .setCorrectWelcomeModalPageAndOpenPsycapPotentialWelcomeModal;
    }
    if (
      this.props.productVersion.getIn(['product', 'type']) ===
      PRODUCT_TYPES.CHOICES.PERSPECTIVES
    ) {
      startWelcomeModalFlow = this.props
        .setCorrectWelcomeModalPageAndOpenPerspectivesWelcomeModal;
    }

    const productVersionDetails =
      activity &&
      fromJS(activity)
        .get('product_versions', List())
        .find(
          fullProductVersion =>
            fullProductVersion.get('id') === productVersion.get('id')
        );

    this.props
      .startActivityProductVersionSession(activityId, productVersion.get('id'))
      .then(response => {
        if (!response.error) {
          startWelcomeModalFlow(
            productVersionDetails,
            activity,
            raterFor,
            questionCollectionIdx
          );
        }
      });
  }

  render() {
    const {
      productVersion,
      user,
      activity,
      getActivityResponse,
      startSessionResponse,
      questionCollectionIdx,
      uiLanguage,
    } = this.props;
    const assessmentChecklistInfo = getTranslatedRespondentAssessmentChecklistInfo();

    return (
      <WelcomePage
        {...assessmentChecklistInfo}
        onContinue={this.onContinue}
        onCancel={
          assessmentChecklistInfo['canCancel']
            ? () => this.props.resetLanguageAndCloseTopModal(uiLanguage)
            : undefined
        }
        user={user}
        productVersion={productVersion}
        getActivityResponse={getActivityResponse}
        startSessionResponse={startSessionResponse}
        hideSectionName
        useProductVersionCompletionTime={activity && !activity.get('is_staged')}
        blankCompletionTime={!activity}
        questionCollectionIdx={questionCollectionIdx}
        organisationName={activity && activity.getIn(['organisation', 'name'])}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    user: state.userProfile,
    getActivityResponse: state.responses.get('loadItem'),
    startSessionResponse: state.responses.get(
      'startActivityProductVersionSession'
    ),
    activity: state.items.get('activities'),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  setWelcomeModalPage,
  setAdditionalWelcomeModalPage,
  resetLanguageAndCloseTopModal,
  startProductVersion,
  openProductVersion,
  clearPreviousAndLoadNewActivity,
  setCorrectWelcomeModalPageAndOpenPsycapPotentialWelcomeModal,
  setCorrectWelcomeModalPageAndOpenPerspectivesWelcomeModal,
  setCorrectWelcomeModalPageAndOpenWelcomeModalAndStartProductVersion,
  startActivityProductVersionSession,
})(RespondentAssessmentChecklistModal);
