import i18next from 'i18next';

import TokenStore from './TokenStore';

function handleResponse(response: {
  text: () => Promise<any>;
  ok: any;
  statusText: any;
}) {
  if (!response.ok) {
    throw new Error(i18next.t<string>('Network response was not ok'));
  } else {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      return data;
    });
  }
}

function get(url: string) {
  const token = TokenStore.get();
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: 'JWT ' + token,
      'Www-Authenticate': 'JWT ' + token,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

export const fetchWrapper = {
  get,
};
