import React from 'react';

export default () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 11.5C1 12.3438 1.65625 13 2.5 13H15.5C15.75 13 16 13.25 16 13.5C16 13.7812 15.75 14 15.5 14H2.5C1.09375 14 0 12.9062 0 11.5V0.5C0 0.25 0.21875 0 0.5 0C0.75 0 1 0.25 1 0.5V11.5ZM5.25 4C5.25 3.59375 5.5625 3.25 6 3.25C6.40625 3.25 6.75 3.59375 6.75 4C6.75 4.4375 6.40625 4.75 6 4.75C5.5625 4.75 5.25 4.4375 5.25 4ZM9.75 7C9.75 7.4375 9.40625 7.75 9 7.75C8.5625 7.75 8.25 7.4375 8.25 7C8.25 6.59375 8.5625 6.25 9 6.25C9.40625 6.25 9.75 6.59375 9.75 7ZM11.25 9C11.25 8.59375 11.5625 8.25 12 8.25C12.4062 8.25 12.75 8.59375 12.75 9C12.75 9.4375 12.4062 9.75 12 9.75C11.5625 9.75 11.25 9.4375 11.25 9ZM13.75 3C13.75 3.4375 13.4062 3.75 13 3.75C12.5625 3.75 12.25 3.4375 12.25 3C12.25 2.59375 12.5625 2.25 13 2.25C13.4062 2.25 13.75 2.59375 13.75 3ZM4.25 9C4.25 8.59375 4.5625 8.25 5 8.25C5.40625 8.25 5.75 8.59375 5.75 9C5.75 9.4375 5.40625 9.75 5 9.75C4.5625 9.75 4.25 9.4375 4.25 9Z"
      fill="#662D91"
    />
  </svg>
);
