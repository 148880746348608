import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

export const DataPrivacyPolicy = () => (
  <div className="main">
    <div className="container">
      <div className="row small">
        <div className="col-xs-12 content-box">
          <h1>{i18next.t<string>('DATA PRIVACY POLICY')}</h1>

          <h2>{i18next.t<string>('Introduction')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy introduction 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy introduction 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy introduction 3')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy introduction 4')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy introduction 5')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy introduction 6')}</p>

          <h2>{i18next.t<string>('Information we may collect from you')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy collect 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy collect 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy collect 3')}</p>

          <h2>{i18next.t<string>('How long we keep your information')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy keep 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy keep 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy keep 3')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy keep 4')}</p>

          <h2>{i18next.t<string>('IP addresses and cookies')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy cookies 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy cookies 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy cookies 3')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy cookies 4')}</p>
          <ul>
            <li>
              <Trans i18nKey="TRANS Data Policy cookies 5">
                To opt out of being tracked by google analytics visit
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                >
                  Google Analytics Opt-Out
                </a>
              </Trans>
            </li>
          </ul>

          <h2>{i18next.t<string>('Where we store your personal data')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy store 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy store 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy store 3')}</p>
          <ul>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 4')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 5')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 6')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 7')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 8')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 9')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 10')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy store 11')}</li>
          </ul>
          <p>{i18next.t<string>('PARAGRAPH Data Policy store 12')}</p>

          <h2>{i18next.t<string>('Disclosure of your information')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy disclosure 1')}</p>
          <ul>
            <li>{i18next.t<string>('PARAGRAPH Data Policy disclosure 2')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy disclosure 3')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy disclosure 4')}</li>
            <li>{i18next.t<string>('PARAGRAPH Data Policy disclosure 5')}</li>
          </ul>
          <p>{i18next.t<string>('PARAGRAPH Data Policy disclosure 6')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy disclosure 7')}</p>

          <h2>{i18next.t<string>('Your rights')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 3')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 4')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 5')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 6')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 7')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 8')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 9')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy rights 10')}</p>

          <h2>{i18next.t<string>('Access to information')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy access 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy access 2')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy access 3')}</p>

          <h2>{i18next.t<string>('Changes to our privacy policy')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Data Policy changes 1')}</p>
          <p>{i18next.t<string>('PARAGRAPH Data Policy changes 2')}</p>

          <h2>{i18next.t<string>('Contact')}</h2>
          <p>
            <Trans i18nKey="TRANS Data Policy contact">
              The Company’s Data Protection Officer is Nicola Adcock. Questions,
              comments and requests regarding this data privacy policy are
              welcomed and should be addressed to the Company’s Data Protection
              Officer at
              <a href="mailto:letushelp@peoplewise.co.uk">
                letushelp@peoplewise.co.uk
              </a>
              .
            </Trans>
          </p>
        </div>
      </div>
    </div>
  </div>
);
