export interface AsyncActionSet {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

export default function makeAsyncActionSet(actionName: string): AsyncActionSet {
  return {
    REQUEST: actionName + '_REQUEST',
    SUCCESS: actionName + '_SUCCESS',
    FAILURE: actionName + '_FAILURE',
  };
}
