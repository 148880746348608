import React from 'react'; // eslint-disable-line no-unused-vars
import classNames from 'classnames';

import PureComponent from './PureComponent';

export default class FormError extends PureComponent {
  _renderError(error) {
    const { renderError } = this.props;
    return renderError ? renderError(error) : error;
  }

  render() {
    const { response, formKey, formKeys, source, className } = this.props;

    const errors =
      response &&
      response.getIn(['errors', ...(formKeys || (formKey ? [formKey] : []))]);

    if (
      !response ||
      !errors ||
      !response.get('state') ||
      (source && source !== response.get('source'))
    ) {
      return null;
    }

    return (
      <p className={classNames('text-error', className)}>
        {this._renderError(errors)}
      </p>
    );
  }
}
