import i18next from 'i18next';
export default [
  {
    title: '',
  },
  {
    title: i18next.t('Name'),
  },
  {
    title: i18next.t('Type'),
  },
  {
    title: i18next.t('Credits Total'),
    className: 'center',
  },
  {
    title: i18next.t('Users Total'),
    className: 'center',
  },
  {
    title: i18next.t('Registered'),
    className: 'center',
  },
  {
    title: i18next.t('Respondents'),
    className: 'center',
  },
  {
    title: i18next.t('Live Activities'),
    className: 'center',
  },
  {
    title: i18next.t('Status'),
    adminOnly: true,
  },
  {
    title: i18next.t('Action'),
    adminOnly: true,
  },
];
