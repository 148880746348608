import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import i18next from 'i18next';
import { Map } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import { getToken } from '^/actions/actions';
import { closeModalAndTryViewExport } from '^/actions/actionSequences';
import {
  closeTopModal,
  openReportGenerationModal,
  openSendReportsModal,
} from '^/actions/modals';
import {
  can,
  downloadExistingReports,
  purchaseReports,
  sendReports,
} from '^/capabilities';
import PureComponent from '^/components/PureComponent';
import CreditWarning from '^/components/reports/admin/CreditWarning';
import WithPopover from '^/components/WithPopover';
import { Uuid } from '^/reducers/api/types';
import { ReportsTableSelections } from '^/reducers/reports/types';
import { selectUserProfile } from '^/selectors';
import { StoreState } from '^/store';
import { selectReportGenerationTotalCost } from './ReportGenerationModal/selectors';
import {
  selectAnySelectedReportsTable,
  selectCountSelectionReportsTable,
  selectSelectedReportsReportsTable,
  selectSessionsByUser,
  UserSessions,
} from './selectors';

interface OwnProps {
  visible: boolean;
  isTeamReport?: boolean;
  isJobProfiler?: boolean;
  organisation: Readonly<{
    has_unlimited_credits: boolean;
    is_using_credit: boolean;
    credit: number;
    id: Uuid;
    name: string;
  }>;
  orgId: Uuid;
}

interface DispatchProps {
  openSendReportsModal: typeof openSendReportsModal;
  openReportGenerationModal: typeof openReportGenerationModal;
  closeModalAndTryViewExport: typeof closeModalAndTryViewExport;
  closeTopModal: typeof closeTopModal;
  getToken: typeof getToken;
}

interface StateProps {
  user: Map<string, any>;
  shortlivedToken: Map<string, any>;
  sessionsByUser: UserSessions;
  anySelected: { [key in keyof ReportsTableSelections]: boolean };
  countSelection: number;
  selectedReportsReportsTable: ReadonlyArray<{
    reportId: Uuid;
    productId: Uuid;
    userId: Uuid;
  }>;
  totalCost: number;
}

type Props = StateProps & DispatchProps & OwnProps;

export class ReportsActionBar extends PureComponent<Props> {
  public componentDidUpdate(prevProps: Props) {
    if (this.props.visible && !prevProps.visible) {
      this.props.getToken();
    }
  }

  public render() {
    const {
      user,
      visible,
      organisation,
      anySelected,
      countSelection,
      totalCost,
    } = this.props;
    const {
      has_unlimited_credits: accountHasUnlimitedCredits,
      is_using_credit: accountIsUsingCredits,
      credit: availableCredit,
    } = organisation;
    const enablePurchase = can(user, downloadExistingReports())
      ? anySelected.reportTemplates
      : anySelected.reportTemplates || anySelected.reports;
    const notEnoughCredits =
      accountIsUsingCredits && totalCost > availableCredit;

    return (
      <div className={classnames('action-bar', { visible })}>
        <div className="action-bar-wrapper">
          <div className="action-bar-status">
            <h2 className="margin-none">
              {i18next.t<string>('{{reportCount}} reports selected', {
                reportCount: countSelection,
              })}
            </h2>
          </div>

          {notEnoughCredits && enablePurchase && (
            <CreditWarning organisation={organisation} />
          )}

          <div className="action-bar-buttons">
            {can(user, downloadExistingReports()) && anySelected.reports && (
              <WithPopover
                popover={i18next.t<string>('Download reports to your computer')}
              >
                <button
                  type="button"
                  className="btn btn-action-bar-primary"
                  onClick={this.onDownload}
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  {i18next.t<string>('Download')}
                </button>
              </WithPopover>
            )}

            {can(user, sendReports()) && anySelected.reports && (
              <WithPopover
                popover={i18next.t<string>('Select report distribution')}
              >
                <button
                  type="button"
                  className="btn btn-action-bar-primary"
                  onClick={this.openSendReportsModal}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  {i18next.t<string>('Send')}
                </button>
              </WithPopover>
            )}

            {can(user, purchaseReports()) && enablePurchase && (
              <span className="ml-md">
                <button
                  type="button"
                  className="btn btn-action-bar-primary"
                  onClick={this.onPurchase}
                  disabled={notEnoughCredits}
                >
                  <FontAwesomeIcon icon={faShoppingCart} />
                  {accountHasUnlimitedCredits
                    ? i18next.t<string>('Generate')
                    : i18next.t<string>('Purchase ({{totalCost}} credits)', {
                        totalCost,
                      })}
                </button>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  private onPurchase = () => {
    const { selectedReportsReportsTable, orgId, isJobProfiler } = this.props;
    this.props.openReportGenerationModal(
      isJobProfiler,
      orgId,
      selectedReportsReportsTable
    );
  };

  private openSendReportsModal = () => {
    const { selectedReportsReportsTable, isJobProfiler } = this.props;
    this.props.openSendReportsModal(
      this.props.isTeamReport || false,
      isJobProfiler || false,
      selectedReportsReportsTable.map(each => each.reportId),
      this.props.closeTopModal,
      false,
      selectedReportsReportsTable.map(each => each.userId)
    );
  };

  private onDownload = () => {
    const { shortlivedToken, selectedReportsReportsTable } = this.props;
    this.props.closeModalAndTryViewExport(shortlivedToken, 'DOWNLOAD_REPORTS', {
      reports: selectedReportsReportsTable.map(each => each.reportId),
    });
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    shortlivedToken: state.shortlivedToken,
    anySelected: selectAnySelectedReportsTable(state),
    sessionsByUser: selectSessionsByUser(state),
    countSelection: selectCountSelectionReportsTable(state),
    selectedReportsReportsTable: selectSelectedReportsReportsTable(state),
    totalCost: selectReportGenerationTotalCost(state),
  };
}

export default connect(mapStateToProps, {
  openSendReportsModal,
  openReportGenerationModal,
  closeModalAndTryViewExport,
  closeTopModal,
  getToken,
})(ReportsActionBar);
