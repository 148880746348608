import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isEmail } from '^/utils';

export const EmailListForm = props => {
  const { onSubmit, disabled } = props;

  const [recipient, setRecipient] = useState('');

  const submitHandler = () => {
    if (isEmail(recipient)) {
      onSubmit(recipient);
      setRecipient('');
    }
  };

  return (
    <div>
      <div className="form-group-inline margin-none">
        <input
          className="margin-right"
          type="email"
          placeholder={i18next.t('Recipient email')}
          disabled={disabled}
          value={recipient}
          onChange={event => setRecipient(event.target.value)}
        />
        <button
          type="button"
          onClick={submitHandler}
          className="btn btn-default btn-small align-self-center"
          disabled={disabled || !isEmail(recipient)}
        >
          <FontAwesomeIcon icon={faPlus} />
          {i18next.t('Add')}
        </button>
      </div>
    </div>
  );
};

export default EmailListForm;
