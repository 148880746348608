import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import { closeTopModal } from '^/actions/modals';
import { updateOrganisationAndCloseModal } from '^/actions/actionSequences';
import EditTaxDetailsForm from './EditTaxDetailsForm';

export class EditTaxDetailsModal extends PureComponent {
  render() {
    const { account, response } = this.props;

    return (
      <EditTaxDetailsForm
        initialValues={{
          tax_location: account.get('tax_location') || '',
          tax_registration_number: account.get('tax_registration_number'),
        }}
        response={response}
        onSubmit={data =>
          this.props.updateOrganisationAndCloseModal(account.get('id'), data)
        }
        onCancel={this.props.closeTopModal}
      />
    );
  }
}

export function mapStateToProps(state) {
  return {
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  updateOrganisationAndCloseModal,
  closeTopModal,
})(EditTaxDetailsModal);
