import React from 'react'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router';

import PureComponent from '^/components/PureComponent';

export class GroupsDetailUser extends PureComponent {
  render() {
    const { userId, userName, email } = this.props;

    return (
      <tr className="user-list-item">
        <td className="padding-small">
          <Link to={`/page/individuals/${userId}`} className="user-view">
            {userName}
          </Link>
        </td>
        <td>{email}</td>
      </tr>
    );
  }
}

export default GroupsDetailUser;
