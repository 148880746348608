import React from 'react'; // eslint-disable-line no-unused-vars

import PureComponent from './PureComponent';

export default class PageHeader extends PureComponent {
  render() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <h1 className="page-heading">{this.props.children}</h1>
          <hr />
        </div>
      </div>
    );
  }
}
