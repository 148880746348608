import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import i18next from 'i18next';
import { faCog, faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ClickableTile } from '^/components/ClickableTile';
import ControlBar from '^/components/ControlBar';
import { StoreState } from '^/store';
import { withRouter } from '^/withRouter';

interface StateProps {
  user: Immutable.Map<string, any>;
}

type Props = StateProps;

const ActivityHub: React.FC<Props> = () => {
  const title = i18next.t('Activity Hub') as React.ReactNode;
  return (
    <div>
      <ControlBar title={title} hideSearch hideAdd />
      <div className="row">
        <div className="display-flex justify-content-center pulse-hub">
          <div className="col-xs-8">
            <div className="clickable-tile-container">
              <ClickableTile
                title={i18next.t<string>('Create Activity')}
                strapline={i18next.t('Create a new activity')}
                to="/page/activities/create"
                icon={
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    size="5x"
                    className="dash-icon dash-create"
                  />
                }
                buttonClick={() => push('/page/pulses/create')}
                buttonText="Create"
              />
              <ClickableTile
                title={i18next.t<string>('Manage Activities')}
                strapline={i18next.t('Manage existing activities')}
                to="/page/activities/view"
                icon={
                  <FontAwesomeIcon
                    icon={faCog}
                    className="dash-icon pulse-fa"
                  />
                }
                buttonClick={() => push('/page/pulses/view')}
                buttonText="Manage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: StoreState): StateProps => ({
  user: state.userProfile,
});

export default withRouter(connect(mapStateToProps)(ActivityHub));
