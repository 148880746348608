import classNames from 'classnames';
import React from 'react';

export type ButtonType =
  | 'standard'
  | 'form'
  | 'select-pill'
  | 'select-pill-selected'
  | 'select'
  | 'select-selected'
  | 'white-stroke'
  | 'outline'
  | 'pulse'
  | 'use-class';

export function getButtonClassName(type: ButtonType = 'standard') {
  return classNames('btn', {
    'btn-standard': type === 'standard',
    'btn-form': type === 'form',
    'btn-select-pill-selected': type === 'select-pill-selected',
    'btn-select-pill': type === 'select-pill',
    'btn-select-selected': type === 'select-selected',
    'btn-select': type === 'select',
    'btn-white-stroke': type === 'white-stroke',
    'btn-outline': type === 'outline',
    'btn-pulse': type === 'pulse',
    btn: type === 'use-class',
  });
}

interface OwnProps {
  buttonType?: ButtonType;
}

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  OwnProps;

export const NewButton: React.FunctionComponent<Props> = ({
  buttonType = 'standard',
  className,
  children,
  ...restProps
}) => (
  <button
    className={classNames(getButtonClassName(buttonType), className)}
    {...restProps}
  >
    {children}
  </button>
);

export default NewButton;
