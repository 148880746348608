import { Uuid } from '^/reducers/api/types';

export interface ReportToGenerate {
  id: Uuid;
  name: string;
  cost: number;
  isTeam: boolean;
  isJobMatch: boolean;
  isJobMatchRelated: boolean;
  quantity: number;
  selected: boolean;
}

export enum TeamReportModalPage {
  ChooseSessions,
  ProvideName,
}
