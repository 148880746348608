import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '../PureComponent';
import { updateOrganisationAndCloseModal } from '../../actions/actionSequences';
import EditOrganisationForm from './EditOrganisationForm';

export class EditOrganisationModal extends PureComponent {
  updateOrganisation(data) {
    this.props.updateOrganisationAndCloseModal(
      this.props.organisation.get('id'),
      data
    );
  }

  render() {
    return (
      <EditOrganisationForm
        originalLogo={this.props.organisation.get('brand_logo')}
        response={this.props.response}
        onSubmit={this.updateOrganisation.bind(this)}
        initialValues={{
          name: this.props.organisation.get('name'),
          description: this.props.organisation.get('description'),
          brand_logo: this.props.organisation.get('brand_logo'),
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('updateCollection'),
  };
}

export default connect(mapStateToProps, {
  updateOrganisationAndCloseModal,
})(EditOrganisationModal);
