import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import ProgressBar from '^/components/ProgressBar';
import IndeterminateProgressBar from '^/components/IndeterminateProgressBar';
import { loadJob } from '^/actions/items';
import Alert, { AlertType } from '^/components/Alert';

const JOB_STATE = {
  NEW: 'NEW',
  READY: 'READY',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  COMPLETE: 'COMPLETE',
};

const isDone = job =>
  job && [JOB_STATE.FAILED, JOB_STATE.COMPLETE].includes(job.get('state'));

export class ReportJobProgress extends PureComponent {
  componentDidMount() {
    this._timer = window.setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer() {
    window.clearInterval(this._timer);
  }

  tick() {
    const { jobId, job } = this.props;
    if (isDone(job)) {
      this.stopTimer();
    } else {
      this.props.loadJob(jobId);
    }
  }

  renderStatus() {
    switch (this.props.job.get('state')) {
      case JOB_STATE.FAILED:
        return (
          <Alert type={AlertType.Warning}>
            {i18next.t('Reports sending failed.')}
          </Alert>
        );

      case JOB_STATE.COMPLETE:
        return (
          <div>
            <Alert type={AlertType.Success}>
              {i18next.t('Reports sent successfully.')}
            </Alert>
            <ProgressBar percent={100} success />
          </div>
        );
    }
  }

  render() {
    const { job, onClose } = this.props;
    const jobDone = isDone(job);

    return (
      <div>
        <div>
          {jobDone ? (
            this.renderStatus()
          ) : (
            <div>
              <p>{i18next.t('Please wait while your reports send...')}</p>
              <IndeterminateProgressBar />
            </div>
          )}
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button
              className="btn btn-default"
              onClick={jobDone ? onClose : undefined}
              disabled={!jobDone}
            >
              {i18next.t('Close')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  const { jobId } = ownProps;
  const job = state.items.get('jobs');

  return {
    job: job && job.get('id') === jobId && job,
  };
}

export default connect(mapStateToProps, { loadJob })(ReportJobProgress);
