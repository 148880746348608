import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import { resetResponse } from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import { addGroupAndAddAllActivityUsersToGroup } from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import CreateGroupForm from '^/components/groups/CreateGroupForm';

export class CreateGroupModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.resetResponse('addToCollection');
  }

  addGroupAddUsersAndCloseModal(data) {
    const activity_ids = this.props.activityUsers.map(item =>
      item.getIn(['user', 'id'])
    );
    this.props.addGroupAndAddAllActivityUsersToGroup(
      this.props.organisation.get('id'),
      data.name,
      activity_ids
    );
  }

  render() {
    return (
      <CreateGroupForm
        response={this.props.response}
        onSubmit={this.addGroupAddUsersAndCloseModal.bind(this)}
        onClose={this.props.closeTopModal}
        summaryText={i18next.t(
          'To create a group of users from the current list of activity respondents, please add'
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
    response: state.responses.get('addToCollection'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  addGroupAndAddAllActivityUsersToGroup,
  resetResponse,
})(CreateGroupModal);
