import React from 'react';
import i18next from 'i18next';
import { push } from 'react-router-redux';
import { List } from 'immutable';
import moment from 'moment';
import { connect } from 'react-redux';

import { RouterParamProps, withRouter } from '^/withRouter';
import { StoreState } from '^/store';
import { getConsentPolicy } from '^/actions/actions';
import {
  agreeConsentAndRedirect,
  disagreeConsentAndRedirect,
} from '^/actions/actionSequences';
import { closeAllModals } from '^/actions/modals';
import {
  ConsentPolicy,
  PolicyVersionContentItem,
  Uuid,
} from '^/reducers/api/types';
import { anyPending, hasFailed } from '^/responseStates';
import Loading from '../Loading';
import { CONSENT_STATES } from './constants';
import { getQueryValue } from '^/utils-ts';
import { can, interactWithShop, seeHomeNav } from '^/capabilities';
import { BasketItem } from '../shop/basket/types';
import { SUPPORT_EMAIL } from '^/settings';

interface DispatchProps {
  push: typeof push;
  getConsentPolicy: typeof getConsentPolicy;
  agreeConsentAndRedirect: typeof agreeConsentAndRedirect;
  disagreeConsentAndRedirect: typeof disagreeConsentAndRedirect;
  closeAllModals: typeof closeAllModals;
}

interface StateProps {
  policy: ConsentPolicy | null;
  responsesPending: boolean;
  responsesFailed: boolean;
  userPulseId: string | null | undefined;
  user: StoreState['userProfile'];
  activeLanguage: string;
}

export type Props = DispatchProps & StateProps & RouterParamProps;

type State = Record<Uuid, boolean>;

export class ConsentPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getConsentPolicy(this.props.activeLanguage);
    this.props.closeAllModals();
  }

  private getConsentItemMarkup(item: PolicyVersionContentItem) {
    const text = this.getTextWithLink(item.text);
    if (item.bullet_point) {
      return (
        <ul>
          <li className={item.bold ? 'bold' : ''}>{text}</li>
        </ul>
      );
    }
    return <p className={item.bold ? 'bold' : ''}>{text}</p>;
  }

  private getTextWithLink(text: string) {
    const parensRegex = /\((.*?)\)/g;
    const linkDisplay = text.match(/\[(.*?)\]/g);
    const linkLocation = text.match(parensRegex);
    if (
      linkDisplay &&
      linkDisplay.length === 1 &&
      linkLocation &&
      linkLocation.length === 1
    ) {
      const textWithoutUrl = text.replace(parensRegex, '');
      const beforeLink = textWithoutUrl.slice(0, textWithoutUrl.indexOf('['));
      const afterLink = textWithoutUrl.slice(
        textWithoutUrl.indexOf(']') + 1,
        textWithoutUrl.length
      );
      return (
        <span>
          {beforeLink}
          <a href={linkLocation[0].slice(1, -1)} target="_blank">
            {linkDisplay[0].slice(1, -1)}
          </a>
          {afterLink}
        </span>
      );
    }
    return text;
  }

  private getRedirectLink() {
    const { userPulseId, user } = this.props;
    if (userPulseId !== undefined) {
      return `/page/user-pulses/${this.props.userPulseId}`;
    } else if (can(user, seeHomeNav())) {
      const basketItems = user
        .get('basketitem_set', List())
        .toJS() as ReadonlyArray<BasketItem>;
      const userHasUnpurchasedBasketItems = basketItems.some(
        each => !each.purchased
      );
      const userOrgHasProducts = !user
        .getIn(['organisation', 'pure_products'], List())
        .isEmpty();
      if (can(user, interactWithShop())) {
        if (userHasUnpurchasedBasketItems) {
          return '/page/basket';
        } else if (!userOrgHasProducts) {
          return '/page/shop';
        }
      }
      return '/page/home';
    }
    return '/page/dashboard';
  }

  render() {
    const { policy, responsesPending, responsesFailed } = this.props;

    if (responsesFailed) {
      return (
        <p className="error-text">
          {i18next.t<string>('Page not found, contact {{supportEmail}}', {
            supportEmail: SUPPORT_EMAIL,
          })}
        </p>
      );
    }

    if (responsesPending || !policy) {
      return <Loading />;
    }

    const submitEnabled = policy.consentable_set.every(
      consentable => this.state[consentable.id] === true
    );

    return (
      <div className="consent">
        <header className="consent-header">
          <nav className="consent-container consent-navbar">
            <img
              className="consent-navbar-logo"
              src="/img/brand/enable-logo-full.png"
              alt={i18next.t<string>('Enable Company Logo')}
            />
          </nav>
        </header>
        <div className="consent-container">
          <div className="consent-card">
            <div className="consent-card-body">
              <h1>{policy.title}</h1>
              {(policy.latest_response?.state === CONSENT_STATES.AGREED ||
                policy.latest_response?.state === CONSENT_STATES.DISAGREED) && (
                <div className="consent-row">
                  <div className="consent-col-md-12">
                    {policy.latest_response.state === CONSENT_STATES.AGREED && (
                      <div className="alert alert-agreed">
                        {i18next.t<string>(
                          'You already agreed to this policy on {{date}}.',
                          {
                            date: moment(policy.latest_response.date).format(
                              'Do MMMM YYYY'
                            ),
                          }
                        )}
                      </div>
                    )}
                    {policy.latest_response.state ===
                      CONSENT_STATES.DISAGREED && (
                      <div className="alert alert-disagreed">
                        {i18next.t<string>(
                          'You declined to consent to this policy on {{date}}.',
                          {
                            date: moment(policy.latest_response.date).format(
                              'Do MMMM YYYY'
                            ),
                          }
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="consent-row">
                <div className="consent-col-md-12">
                  {policy.policyversioncontentitem_set.map(item => (
                    <div key={item.id}>{this.getConsentItemMarkup(item)}</div>
                  ))}
                </div>
              </div>
              <div className="consent-row">
                <div className="consent-col-md-12">
                  <hr />
                </div>
              </div>
              <div className="consent-row">
                <div className="consent-card-bottom">
                  <div className="consent-form-group">
                    {policy.consentable_set.map(item => (
                      <div key={item.id}>
                        <span className="consent-checkbox-wrapper">
                          <input
                            type="checkbox"
                            id={item.id}
                            onChange={event =>
                              this.setState({
                                ...this.state,
                                [event.target.id]: event.target.checked,
                              })
                            }
                          />
                        </span>
                        <label htmlFor={item.id}>{item.text}</label>
                      </div>
                    ))}
                  </div>
                  <button
                    className="consent-btn consent-btn-primary consent-disabled"
                    disabled={!submitEnabled}
                    onClick={() =>
                      this.props.agreeConsentAndRedirect(
                        policy.id,
                        this.getRedirectLink()
                      )
                    }
                  >
                    {i18next.t<string>('Yes - Submit agreement')}
                  </button>{' '}
                  <button
                    className="consent-btn consent-cancel"
                    onClick={() =>
                      this.props.disagreeConsentAndRedirect(policy.id)
                    }
                  >
                    {i18next.t<string>('No - I disagree')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: StoreState, props: Props): StateProps {
  return {
    policy: state.consentPolicy,
    responsesPending: anyPending([
      state.responses.get('getConsentPolicy'),
      state.responses.get('submitConsentAgree'),
      state.responses.get('submitConsentDisagree'),
    ]),
    responsesFailed:
      hasFailed(state.responses.get('getConsentPolicy')) ||
      hasFailed(state.responses.get('submitConsentAgree')) ||
      hasFailed(state.responses.get('submitConsentDisagree')),
    userPulseId: getQueryValue(props, 'userpulse'),
    user: state.userProfile,
    activeLanguage: state.ui.get('activeLanguage'),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    push,
    getConsentPolicy,
    agreeConsentAndRedirect,
    disagreeConsentAndRedirect,
    closeAllModals,
  })(ConsentPage)
);
