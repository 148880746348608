import React from 'react'; // eslint-disable-line no-unused-vars
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PureComponent from '../PureComponent';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import CodeSample from './CodeSample';
import Alert, { AlertType } from '^/components/Alert';

export default class ActionBars extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader>Action Bars</PageHeader>
        {/* eslint-disable max-len */}
        <p>
          This ActionBar is used in places like Reports when the user needs all
          the screen real estate it can have (big tables eg) but also needs to
          be able to interact with the content. The ActionBar then popups when
          something on the page is selected and shows appropriate status text,
          alert if any, and actionable buttons.
        </p>
        {/* eslint-enable max-len */}
        <PageContent>
          <div className="action-bar visible">
            <div className="action-bar-wrapper">
              <div className="action-bar-status">
                <h2 className="margin-none">48 reports selected</h2>
              </div>
              <div className="action-bar-error">
                <Alert type={AlertType.Error}>
                  You do not have enough credits to purchase the chosen reports
                  - <a>please top up here</a>
                </Alert>
              </div>
              <div className="action-bar-buttons">
                <button type="button" className="btn btn-action-bar-primary">
                  <FontAwesomeIcon icon={faFileDownload} />
                  Download
                </button>
                <button type="button" className="btn btn-action-bar-primary">
                  <FontAwesomeIcon icon={faEnvelope} />
                  Send
                </button>
              </div>
            </div>
          </div>
          <CodeSample code={`<ActionBar/>`} />
        </PageContent>
      </div>
    );
  }
}
