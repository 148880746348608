import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { List } from 'immutable';
import { isEqual } from 'underscore';
import { push } from 'react-router-redux';
import {
  faUsers,
  faClipboardList,
  faTrashAlt,
  faCog,
  faUpload,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';

import { isPending } from '^/responseStates';
import { ACTIVITY_STATUS } from '^/activityStatus';
import {
  sortList,
  expandUiComponent,
  collapseUiComponent,
} from '^/actions/actions';
import { sort } from '^/utils';
import {
  closeTopModal,
  openAddRemoveUserModal,
  openConfirmationModal,
  openImportUsersModal,
} from '^/actions/modals';
import { updateGroup, deleteGroup } from '^/actions/collections';
import { addUsersToGroupAndRefresh } from '^/actions/actionSequences';
import Editable from '^/components/editable/Editable';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import GroupsDetailUser from './GroupsDetailUser';
import { clearItem } from '^/actions/items';
import ListPage from '../ListPage';
import Alert from '^/components/Alert';
import MenuButton, { MenuButtonOption } from '../MenuButton';

const HEADERS = [
  {
    title: i18next.t('Name'),
    field: 'full_name',
    sortable: true,
  },
  { title: i18next.t('email'), field: 'email' },
];

const GROUP_USERS_LIST = 'GROUP_USERS_LIST';

export class GroupsDetail extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.collapseUiComponent(GROUP_USERS_LIST);
  }

  deleteGroup() {
    this.props.openConfirmationModal(
      i18next.t('delete'),
      i18next.t('group'),
      [i18next.t('This action cannot be undone.')],
      () => {
        this.props.deleteGroup(this.props.group.get('id'));
        this.props.clearItem('groups');
        this.props.closeTopModal();
      },
      this.props.closeTopModal,
      'btn-danger'
    );
  }

  addUsers(userIds) {
    const doAdd = () =>
      this.props.addUsersToGroupAndRefresh(this.props.group.get('id'), userIds);

    if (this.props.status === ACTIVITY_STATUS.LIVE) {
      this.props.openConfirmationModal(
        i18next.t('add respondents to'),
        i18next.t('activity'),
        [i18next.t('They will be notified by email.')],
        () => {
          doAdd();
          this.props.closeTopModal();
        },
        this.props.closeTopModal,
        'btn-primary'
      );
    } else {
      doAdd();
    }
  }

  showAddUsersModal() {
    const { group } = this.props;

    this.props.openAddRemoveUserModal(
      group.get('organisation'),
      group.get('id'),
      new Set(group.get('users').map(user => user.get('id')))
    );
  }

  showImportUsersModal() {
    this.props.openImportUsersModal(this.props.group.get('id'));
  }

  getSortedUsers() {
    const { group, sortBy } = this.props;
    const users = group.get('users').sortBy(user => user.get(sortBy.field));
    return sortBy.reversed ? users.reverse() : users;
  }

  _getSortedImports(imports, sortBy) {
    if (!imports || !sortBy.field) {
      return imports;
    }

    return sort(
      imports,
      eachImport => eachImport.getIn(sortBy.field),
      sortBy.reversed
    );
  }

  _sortBy(field) {
    this.props.sortList('groupsDetails', {
      field: field,
      reversed: isEqual(this.props.sortBy.field, field)
        ? !this.props.sortBy.reversed
        : false,
    });
  }

  _renderUser(user) {
    const id = user.get('id');
    return (
      <GroupsDetailUser
        key={id}
        userName={user.get('full_name')}
        userId={id}
        email={user.get('email')}
        groupId={this.props.group.get('id')}
      />
    );
  }

  _onCreateActivityClick() {
    this.props.push({
      pathname: '/page/activities/create',
      query: {
        group: this.props.group.get('id'),
      },
    });
  }

  render() {
    const { group, groupLoading, usersAdding, response } = this.props;
    const users = group ? this.getSortedUsers() : List();
    if (!group || !users) {
      if (groupLoading) {
        return <Loading />;
      }
      return (
        <div className="group-detail">
          <div className="row">
            <div className="col-xs-12">
              <Alert>{i18next.t('Click on a group to view and edit.')}</Alert>
            </div>
          </div>
        </div>
      );
    }

    const userCount = users.count();
    const pluralizedUserCount =
      userCount === 1 ? i18next.t('user') : i18next.t('users');

    return (
      <div className="group-detail">
        <div className="row">
          <div className="col-xs-12 group-controls">
            <div className="group-editable-header">
              <h1>
                <Editable
                  placeholder="Name"
                  value={group.get('name')}
                  fieldName="name"
                  onSave={name =>
                    this.props.updateGroup(group.get('id'), { name })
                  }
                  response={response}
                />
              </h1>
              {userCount > 0 && (
                <p className="subtitle">
                  {userCount === 0
                    ? i18next.t('No users')
                    : i18next.t('{{count}} {{noun}}', {
                        count: userCount,
                        noun: pluralizedUserCount,
                      })}
                </p>
              )}
            </div>
            <div className="button-bar">
              <MenuButton
                label={
                  <>
                    <FontAwesomeIcon icon={faCog} />
                    Manage
                  </>
                }
                position="right"
                isNotSquare
              >
                <MenuButtonOption
                  onSelect={() => this.showAddUsersModal()}
                  name={i18next.t('Add/Remove users')}
                  icon={<FontAwesomeIcon icon={faUsers} />}
                />
                <MenuButtonOption
                  onSelect={() => this.showImportUsersModal()}
                  name={i18next.t('Import users')}
                  icon={<FontAwesomeIcon icon={faUpload} />}
                />
                <MenuButtonOption
                  onSelect={this._onCreateActivityClick.bind(this)}
                  name={i18next.t('Create activity for group')}
                  icon={<FontAwesomeIcon icon={faClipboardList} />}
                />
                <MenuButtonOption
                  onSelect={() => this.deleteGroup()}
                  name={i18next.t('Delete group')}
                  danger
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                />
              </MenuButton>
            </div>
          </div>
        </div>
        <ListPage
          items={users}
          headers={HEADERS}
          tableClassName="activity-list-table highlight-rows table-default"
          typeNamePlural="users"
          hideBorder
          response={response}
          noItemsMessage={i18next.t('Please add some users to the group')}
          renderer={this._renderUser.bind(this)}
          ordering={this.props.sortBy}
          onOrderingChange={this._sortBy.bind(this)}
          isLoading={usersAdding || groupLoading}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    group: state.items.get('groups'),
    groupLoading: isPending(state.responses.get('loadItem')),
    usersAdding: isPending(state.responses.get('addUsersToGroup')),
    isExpanded: state.ui.get('expandedUiComponents').contains(GROUP_USERS_LIST),
    sort: state.userGroupsTableSpec.get('sort'),
    sortBy: state.sortOrders.get('groupsDetails', {}),
    response: state.responses.get('updateItem'),
  };
}

export default connect(mapStateToProps, {
  openAddRemoveUserModal,
  addUsersToGroupAndRefresh,
  openConfirmationModal,
  closeTopModal,
  expandUiComponent,
  collapseUiComponent,
  updateGroup,
  deleteGroup,
  clearItem,
  sortList,
  push,
  openImportUsersModal,
})(GroupsDetail);
