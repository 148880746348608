import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form } from 'formik';

import LiveButton from '^/components/LiveButton';
import Img from '^/components/Img';
import FormError from '^/components/FormError';
import PictureUpload from '^/components/PictureUpload';

export const EditAccountLogoForm = props => {
  const { onSubmit, response, originalLogo } = props;

  return (
    <Formik
      initialValues={{ brand_logo: originalLogo }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="form-horizontal">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <label>
                {i18next.t('Brand logo')}
                <PictureUpload
                  src={
                    values.brand_logo &&
                    (values.brand_logo.preview || values.brand_logo)
                  }
                  display={src => (
                    <Img
                      src={
                        src || originalLogo || '/static/img/icons/accounts.png'
                      }
                    />
                  )}
                  setPendingUpload={file => setFieldValue('brand_logo', file)}
                  onSubmit={onSubmit}
                />
                <FormError response={response} formKey="brand_logo" />
              </label>
            </div>
          </div>

          <div className="modal-footer clearfix">
            <div className="pull-right">
              <LiveButton
                pendingMessage={i18next.t('Saving organisation...')}
                buttonText={i18next.t('Save')}
                response={response}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAccountLogoForm;
