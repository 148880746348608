import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';

import PureComponent from '^/components/PureComponent';
import { addDiscountBandCollectionAndOpenEditModal } from '^/actions/actionSequences';
import { closeTopModal } from '^/actions/modals';
import AddBulkDiscountBandForm from './AddBulkDiscountBandForm';

export class AddBulkDiscountBandModal extends PureComponent {
  render() {
    return (
      <div>
        <AddBulkDiscountBandForm
          response={this.props.response}
          onSubmit={this.props.addDiscountBandCollectionAndOpenEditModal}
          onClose={this.props.closeTopModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    response: state.responses.get('addToCollection'),
  };
}

export default connect(mapStateToProps, {
  closeTopModal,
  addDiscountBandCollectionAndOpenEditModal,
})(AddBulkDiscountBandModal);
