/**
 * This createContext exists as a wrapper around the legacy context API until we upgrade to React 16.
 * Allowing us to use the new context API now, and simply change the imports of createContext when we upgrade.
 */

import PropTypes from 'prop-types';
import { PureComponent, ReactElement } from 'react';

interface ProviderProps<C> {
  context: C;
  children: ReactElement;
}

interface ConsumerProps<C> {
  children: (context: C) => ReactElement;
}

const CONTEXT_TYPES = {
  all: PropTypes.any,
};

const createContext = <C>(initialValues: C) => {
  class Provider extends PureComponent<ProviderProps<C>> {
    public static childContextTypes = CONTEXT_TYPES;
    private childContext: C;

    public constructor(props: ProviderProps<C>) {
      super(props);

      this.childContext = { ...initialValues, ...props.context };
    }

    public getChildContext() {
      return {
        all: this.childContext,
      };
    }

    public shouldComponentUpdate(
      nextProps: ProviderProps<C>,
      nextState: {},
      nextContext: unknown
    ) {
      this.childContext = { ...this.childContext, ...nextProps.context };
      return super.shouldComponentUpdate
        ? super.shouldComponentUpdate(nextProps, nextState, nextContext)
        : true;
    }

    public render() {
      return this.props.children;
    }
  }

  class Consumer extends PureComponent<ConsumerProps<C>> {
    public context: any;
    public static contextTypes = CONTEXT_TYPES;

    public render() {
      return this.props.children(this.context.all);
    }
  }

  return {
    Provider,
    Consumer,
  };
};

export { createContext };
