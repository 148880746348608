import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import WithPopover from '^/components/WithPopover';

export default class ProductAccessDisplay extends PureComponent {
  renderProductsPopover(userProducts) {
    return (
      <div>
        {userProducts.isEmpty() ? (
          <p>{i18next.t('No accreditation')}</p>
        ) : (
          userProducts.map(product => (
            <p key={product.get('id')}>{product.get('name')}</p>
          ))
        )}
      </div>
    );
  }

  render() {
    const { user, account } = this.props;
    const accountProducts = account
      .get('pure_products')
      .filter(product => product.get('requires_accreditation'));
    const userProducts = accountProducts.filter(product =>
      user.get('accredited_products').contains(product.get('id'))
    );

    return (
      <div>
        <WithPopover
          className="inline"
          popover={this.renderProductsPopover(userProducts)}
        >
          {userProducts.size} / {accountProducts.size}
        </WithPopover>
      </div>
    );
  }
}
