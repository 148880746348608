import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { List } from 'immutable';
import { omit } from 'underscore';

import UpdateProfileForm from '^/components/profile/UpdateProfileForm';

const FIELDS = List([
  'full_name',
  'employee_no',
  'email',
  'sex',
  'geography',
  'profile_picture',
  'accept_tcs',
  'age',
  'job_level',
  'education',
  'industry',
  'first_language_english',
  'nationality',
  'ethnicity',
]);

export const ProfileFormWrapper = props => {
  const onSubmit = data => {
    props.onSubmit(
      data.profile_picture && data.profile_picture.preview
        ? data
        : omit(data, 'profile_picture')
    );
  };

  const initialValues = FIELDS.reduce((accumulator, key) => {
    if (key in props.user.toJS()) {
      accumulator[key] = props.user.get(key);
    }
    return accumulator;
  }, {});

  return (
    <div>
      <UpdateProfileForm
        onSubmit={onSubmit}
        response={props.response}
        showTCs={props.showTCs}
        initialValues={initialValues}
        userId={props.user.get('id')}
      />
    </div>
  );
};

ProfileFormWrapper.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  response: ImmutablePropTypes.map.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showTCs: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps)(ProfileFormWrapper);
