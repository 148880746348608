import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Map } from 'immutable';
import i18next from 'i18next';

import { APPROVAL_STATUS, isAwaitingApproval } from '^/approvalStatus';
import { isLive } from '^/activityStatus';
import { formatDate } from '^/utils';
import { mustSetLineManager, ACTIVITY_TYPE } from '^/models/activity';
import { isLineManager } from '^/rater';
import PureComponent from '^/components/PureComponent';
import TaskStatus from '^/components/TaskStatus';
import CompletionSummaryModal from '^/components/dashboard/enduser/CompletionSummaryModal';
import { getLangCodeWithFallback } from '^/utils-ts';
import { DEFAULT_LANGUAGE_CODE } from '^/constants/routes';

export const getSession = (activity, productVersion, task) =>
  productVersion &&
  activity &&
  activity
    .get('activity_product_version_items')
    .find(apv => apv.get('product_version') === productVersion.get('id'))
    .get('activity_product_version_sessions', List())
    .find(
      session =>
        session.get('rater_for', null) === task.getIn(['raterFor', 'id'], null)
    );

export const sessionIsComplete = session =>
  session && session.get('is_complete', false);

export function getCurrentProductVersion(task) {
  return task.get('product_version');
}

export function getCanCompleteSurvey(task, user) {
  const { activity } = task.toObject();
  const raterFor = task.get('raterFor');
  const activityUsers = activity.get('users');
  const activityThreeSixty = activity.get('activity360');

  if (activityThreeSixty) {
    // Three sixty assessment
    if (raterFor) {
      // Assessing another user
      const lineManagerMustApproveRaters = activityThreeSixty.get(
        'line_manager_must_approve_raters'
      );
      const raterForActivityUser = activityUsers.find(
        activityUser =>
          activityUser.getIn(['user', 'id']) === raterFor.get('id')
      );
      const allRaters = raterForActivityUser.get('raters', List());
      const raters = allRaters.filterNot(isLineManager);
      const lineManager = allRaters.find(isLineManager);
      const userIsActivityUsersLineManager =
        lineManager && lineManager.getIn(['rater', 'id']) === user.get('id');
      if (userIsActivityUsersLineManager) {
        // Cannot start assessment if there are raters awaiting approval by LM
        const anyRatersRequireApproval =
          lineManagerMustApproveRaters && raters.some(isAwaitingApproval);
        return isLive(activity) && !anyRatersRequireApproval;
      }
      // Not the line manager
      const validRater =
        !raters.isEmpty() &&
        raters.find(
          activityRater =>
            activityRater.getIn(['rater', 'id']) === user.get('id')
        );
      const raterIsApproved =
        validRater &&
        validRater.get('approved_by_line_manager') === APPROVAL_STATUS.APPROVED;
      const canCompleteSurvey =
        !lineManagerMustApproveRaters || raterIsApproved;
      return isLive(activity) && canCompleteSurvey;
    }
    // Assessing themselves
    const ratersSubmitted = activity.get('raters_submitted');
    return isLive(activity) && ratersSubmitted;
  }
  // Psychometric assessment
  return isLive(activity);
}

export default class TaskStatuses extends PureComponent {
  getTaskLabels(isThreeSixty, canResumeIncomplete, activityEndDateDisplay) {
    if (isThreeSixty) {
      if (canResumeIncomplete) {
        return [activityEndDateDisplay, i18next.t('Continue feedback')];
      }
      return [activityEndDateDisplay, i18next.t('Complete feedback')];
    } else if (canResumeIncomplete) {
      return [activityEndDateDisplay, i18next.t('Continue assessment')];
    }
    return [i18next.t('ASAP'), i18next.t('Complete assessment')];
  }

  beginAssessment(
    productVersion,
    activityId,
    raterFor,
    questionCollectionIdx = 0
  ) {
    const isPsychometric =
      productVersion &&
      productVersion.getIn(['product', 'activity_type']) ===
        ACTIVITY_TYPE.PSYCHOMETRIC;

    const language = getLangCodeWithFallback(
      this.props.activeLanguage,
      productVersion
        ? productVersion.get('available_languages').toJS()
        : [DEFAULT_LANGUAGE_CODE]
    );

    if (isPsychometric) {
      this.props.setActiveLanguageAndOpenAssessmentModal(
        language,
        productVersion,
        activityId,
        raterFor,
        questionCollectionIdx
      );
    } else {
      this.props.loadDataAndOpenWelcomeModal(
        productVersion,
        activityId,
        raterFor,
        language
      );
    }
  }

  renderSurveyTaskStatusPair() {
    const { task, user } = this.props;
    const activity = task.get('activity', Map());
    const activityEndDateDisplay = formatDate(activity.get('end_datetime'));
    const isThreeSixty = activity.get('activity360');
    const isStaged = activity.get('is_staged');
    const raterFor = task.get('raterFor');
    const canCompleteSurvey = getCanCompleteSurvey(task, user);
    const productVersion = getCurrentProductVersion(task);
    const session = getSession(activity, productVersion, task);
    const isInvitedToStage2 = session && session.get('is_invited_to_stage_2');
    const isMAPComplete =
      session &&
      session.getIn(['map_activity_product_version_session', 'is_complete']);
    const sessionHasAnswers = session && session.get('has_answers');
    const isComplete = sessionIsComplete(session);
    const canResume = session && session.get('can_resume');
    const canResumeIncomplete = canResume && !isComplete;
    const [completeByLabel, actionLabel] = this.getTaskLabels(
      isThreeSixty,
      canResumeIncomplete,
      activityEndDateDisplay
    );

    if (!session) {
      return [
        completeByLabel,
        <TaskStatus
          key={'key1'}
          survey
          label={actionLabel}
          inactive={!canCompleteSurvey}
          onClick={() =>
            this.beginAssessment(productVersion, activity.get('id'), raterFor)
          }
        />,
      ];
    } else if ((canResume || isThreeSixty) && !isComplete) {
      return [
        completeByLabel,
        <TaskStatus
          key={'key2'}
          survey
          label={actionLabel}
          progress
          inactive={!canCompleteSurvey}
          onClick={() =>
            this.props.continueProductVersion(
              productVersion,
              activity,
              raterFor,
              this.props.activeLanguage
            )
          }
        />,
      ];
    } else if (isStaged && isInvitedToStage2 && !sessionHasAnswers) {
      return [
        '-',
        <TaskStatus
          key={'key3'}
          survey
          label={i18next.t('Continue assessment')}
          onClick={() =>
            this.beginAssessment(
              productVersion,
              activity.get('id'),
              raterFor,
              1
            )
          }
        />,
      ];
    }
    return isComplete || (isStaged && isMAPComplete && !isInvitedToStage2)
      ? [
          '-',
          <TaskStatus
            key={'key4'}
            survey
            label={i18next.t('Survey completed')}
            done
          />,
        ]
      : [
          '-',
          <TaskStatus
            key={'key5'}
            survey
            label={i18next.t('Partially completed')}
            warning
            popover={i18next.t('Contact your activity administrator')}
          />,
        ];
  }

  renderCompletionSummary() {
    const { task, user } = this.props;
    const { activity } = task.toObject();
    const ratersSubmitted = activity.get('raters_submitted');
    const lineManagerMustApprove = activity.getIn([
      'activity360',
      'line_manager_must_approve_raters',
    ]);
    const allRaters = activity.getIn(['self_users', 0, 'raters'], List());
    const visibleRaters = lineManagerMustApprove
      ? allRaters.filter(rater => rater.get('approved_by_line_manager'))
      : allRaters;
    const raters = visibleRaters.filterNot(isLineManager);
    const anyRatersRequireApproval = raters.some(isAwaitingApproval);

    const showCompletionSummary = lineManagerMustApprove
      ? ratersSubmitted && !anyRatersRequireApproval
      : ratersSubmitted;
    const popover = i18next.t(
      'Completion summary available after raters have been submitted'
    );

    return showCompletionSummary ? (
      <div
        className="task-status-wrapper"
        onClick={() =>
          this.props.openModal({
            title: i18next.t('Completion summary'),
            body: <CompletionSummaryModal activity={activity} user={user} />,
          })
        }
      >
        <div className="task-type">
          <img
            src="/static/img/icons/task-report-icon.png"
            className="icon-task-type"
          />
        </div>
        <a className="task-label">{i18next.t('View completion summary')}</a>
      </div>
    ) : (
      <TaskStatus
        report
        label={i18next.t('View completion summary')}
        inactive
        popover={popover}
      />
    );
  }

  getActivity() {
    const { task } = this.props;
    return task.get('activity', Map());
  }

  renderRaterTaskStatus() {
    const activity = this.getActivity();
    const lineManagerMustApprove = activity.getIn([
      'activity360',
      'line_manager_must_approve_raters',
    ]);
    const ratersSubmitted = activity.get('raters_submitted');
    const overallNominationState = activity.getIn([
      'activity360',
      'overall_nomination_state',
    ]);
    const { manageRaters } = this.props;

    return mustSetLineManager(activity) ? (
      <TaskStatus
        raters
        label={i18next.t('Set line manager')}
        onClick={manageRaters}
      />
    ) : (
      <TaskStatus
        raters
        label={i18next.t('Submit raters')}
        warning={overallNominationState === APPROVAL_STATUS.REJECTED}
        done={
          lineManagerMustApprove
            ? overallNominationState === APPROVAL_STATUS.APPROVED
            : ratersSubmitted
        }
        progress={ratersSubmitted}
        onClick={manageRaters}
      />
    );
  }

  renderLineManagerTasks() {
    const { approveRaters } = this.props;
    return (
      <TaskStatus
        raters
        label={i18next.t('Approve raters')}
        onClick={approveRaters}
      />
    );
  }

  render() {
    const { task, isCompleteBy } = this.props;
    const { asLineManager, asRespondent } = task.toObject();
    const activity = this.getActivity();
    const isThreeSixty = activity.get('activity360');
    let actionSubRows = [];

    if (asRespondent) {
      isThreeSixty &&
        actionSubRows.push([i18next.t('ASAP'), this.renderRaterTaskStatus()]);
      actionSubRows.push(this.renderSurveyTaskStatusPair());
      isThreeSixty && actionSubRows.push(['-', this.renderCompletionSummary()]);
    } else if (asLineManager) {
      actionSubRows.push([i18next.t('ASAP'), this.renderLineManagerTasks()]);
    } else {
      // Must be asRater
      actionSubRows.push(this.renderSurveyTaskStatusPair());
    }

    return (
      <span>
        {actionSubRows.map(([completeBy, action]) =>
          isCompleteBy ? (
            <div className="complete-by">{completeBy}</div>
          ) : (
            action
          )
        )}
      </span>
    );
  }
}
