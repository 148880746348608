import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { List, Map } from 'immutable';
import _ from 'underscore';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import ListPage from '^/components/ListPage';
import ListPagePagination from '^/components/ListPagePagination';
import ControlBar from '^/components/ControlBar';
import CreditDatePicker from '^/components/credits/CreditDatePicker';
import CreditTopLinks from '^/components/credits/CreditTopLinks';
import CreditOrgInfo from '^/components/credits/CreditOrgInfo';
import EditableDatetime from '^/components/EditableDatetime';
import {
  sortList,
  setFilters,
  setPageSize,
  PAGE_SIZE_OPTIONS,
} from '^/actions/actions';
import { openModal } from '^/actions/modals';
import {
  getOrgCreditHistory,
  getOrganisationCreditDetail,
  updateCreditPot,
} from '^/actions/collections';
import CreditTransactionDetailModal from './CreditTransactionDetailModal';
import { formatDate, formatDateFilter, asOrdering } from '^/utils';
import {
  CREDIT_LOG_ACTION_TYPE,
  CREDIT_LOG_ACTION_TYPE_DISPLAY,
  accountHasUnlimitedCredits,
  getAccountTypeDisplay,
} from '^/models/organisation';
import { getUserDisplayName, selectUserOrgId } from '^/selectors/user';
import {
  selectUserProfile,
  selectSortOrder,
  selectCollection,
  selectGetCollection,
} from '^/selectors';
import { selectFilterSpec, selectPageSize } from '^/selectors/ui';
import { can, administerOrganisations } from '^/capabilities';
import { withRouter } from '^/withRouter';
import { isPending } from '^/responseStates';

const HEADERS = [
  {
    title: i18next.t('Date'),
    field: ['created'],
    sortable: true,
    className: 'no-wrap',
  },
  {
    title: i18next.t('Activity'),
    field: ['activity', 'name'],
    sortable: true,
  },
  {
    title: i18next.t('Purchase type'),
    field: ['creditlog', 'action'],
    sortable: true,
  },
  {
    title: i18next.t('Reports'),
    field: ['template_codes'],
  },
  {
    title: i18next.t('Admin user'),
    field: ['creditlog', 'user', 'full_name'],
    sortable: true,
  },
  {
    title: i18next.t('Credits'),
  },
  {
    title: i18next.t('Action'),
    className: 'no-wrap',
  },
];

const CreditAmount = ({ amount }) =>
  amount >= 0 ? <strong>{amount}</strong> : <span>{amount}</span>;

export class CreditAccountHistory extends PureComponent {
  UNSAFE_componentWillMount() {
    this.loadOrgCreditHistoryPage(1);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.sortBy, this.props.sortBy) ||
      !_.isEqual(nextProps.filters, this.props.filters) ||
      !_.isEqual(nextProps.pageSize, this.props.pageSize)
    ) {
      this.loadOrgCreditHistoryPage(1, nextProps);
    }
  }

  loadOrgCreditHistoryPage(page, props) {
    const { organisationId, filters, sortBy, pageSize } = props || this.props;
    this.props.getOrgCreditHistory(
      organisationId,
      '',
      page,
      filters,
      pageSize,
      asOrdering(sortBy)
    );
    this.props.getOrganisationCreditDetail(organisationId, filters);
  }

  getReportsText(log) {
    const { action, template_codes, num_transactions } = log.toObject();

    return action === CREDIT_LOG_ACTION_TYPE.REPORT_GENERATED
      ? `${template_codes.join(', ')} (${num_transactions} reports)`
      : '';
  }

  renderRow(log) {
    const { user } = this.props;
    const canUserAdministerOrganisations = can(user, administerOrganisations());
    const {
      id,
      created,
      user: actionMadeByUser,
      action,
      amount,
      credit_pot,
      response,
    } = log.toObject();

    return (
      <tr key={id}>
        <td className="no-wrap">{formatDate(created)}</td>
        <td>{log.getIn(['activity', 'name'], i18next.t('N/A'))}</td>
        <td>
          {CREDIT_LOG_ACTION_TYPE_DISPLAY[action]}
          {action === CREDIT_LOG_ACTION_TYPE.PURCHASED_CREDITS && credit_pot && (
            <span className="muted-span">
              <EditableDatetime
                fieldName="expiry_date"
                prefix={` ${i18next.t('expires')} `}
                value={credit_pot.get('expiry_date')}
                onAccept={value =>
                  this.props.updateCreditPot(credit_pot.get('id'), {
                    expiry_date: formatDateFilter(value),
                  })
                }
                response={response}
                minDatetime={credit_pot.get('expiry_date')}
                notice={i18next.t('Credits expire at 2359 (UTC)')}
                showDateOnly
                disabled={
                  !canUserAdministerOrganisations ||
                  credit_pot.get('balance') === 0
                }
              />
            </span>
          )}
        </td>
        <td>{this.getReportsText(log)}</td>
        <td>{getUserDisplayName(user, actionMadeByUser)}</td>
        <td>
          <CreditAmount amount={amount} />
        </td>
        <td className="no-wrap">
          {action === CREDIT_LOG_ACTION_TYPE.REPORT_GENERATED && (
            <a
              onClick={() =>
                this.props.openModal({
                  title: i18next.t('Transaction history'),
                  body: <CreditTransactionDetailModal batch={log} />,
                })
              }
            >
              {i18next.t('View details')}
            </a>
          )}
          {action === CREDIT_LOG_ACTION_TYPE.PURCHASED_CREDITS &&
            credit_pot &&
            credit_pot.get('invoice') &&
            canUserAdministerOrganisations && (
              <a href={credit_pot.get('invoice')} target="_blank">
                {i18next.t('Invoice')}
              </a>
            )}
        </td>
      </tr>
    );
  }

  sortBy(field) {
    const { sortBy } = this.props;
    this.props.sortList('creditLogs', {
      field,
      reversed: _.isEqual(sortBy.field, field) ? !sortBy.reversed : false,
    });
  }

  onFilterChange(key, value) {
    let newFilters = Object.assign({}, this.props.filters);
    if (value === null) {
      delete newFilters[key];
    } else {
      newFilters[key] = value;
    }

    this.props.setFilters(newFilters);
  }

  getTitle() {
    const { orgCreditDetail, response } = this.props;
    return isPending(response)
      ? i18next.t('Loading...')
      : `${orgCreditDetail.get('name')} - ${getAccountTypeDisplay(
          orgCreditDetail
        )}`;
  }

  getBreadcrumb() {
    const { user, orgCreditDetail } = this.props;

    return (
      can(user, administerOrganisations()) && (
        <span className="breadcrumbs">
          <Link to="/page/credits/">{i18next.t('Credits')}</Link>
          <span>{orgCreditDetail.get('name')}</span>
        </span>
      )
    );
  }

  render() {
    const {
      orgCreditDetail,
      organisationId,
      sortBy,
      orgCreditHistory,
      response,
      filters,
      pageSize,
    } = this.props;

    if (!orgCreditDetail || !orgCreditHistory) {
      return <Loading className="list-loading" />;
    }

    const { first_log_created: firstLogCreated } = orgCreditDetail.toObject();

    return (
      <div>
        <ControlBar
          title={this.getTitle()}
          breadcrumb={this.getBreadcrumb()}
          hideSearch
          hideAdd
        >
          <CreditTopLinks
            organisationId={organisationId}
            orgHasUnlimitedCredits={accountHasUnlimitedCredits(orgCreditDetail)}
            onPurchaseComplete={() => this.loadOrgCreditHistoryPage(1)}
          />
        </ControlBar>
        <div className="credits-org-info-wrapper">
          <CreditDatePicker
            firstTransactionDate={firstLogCreated}
            filters={filters}
            className="credits-date-picker"
          />
          <CreditOrgInfo orgCreditDetail={orgCreditDetail} />
        </div>
        <hr className="margin-bottom" />
        <ListPagePagination
          pageSize={pageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          collection={orgCreditHistory}
          onSelectPage={page => this.loadOrgCreditHistoryPage(page)}
          onChangePageSize={this.props.setPageSize}
        />
        <ListPage
          typeNamePlural="credits"
          items={orgCreditHistory.get('items')}
          headers={HEADERS}
          tableClassName="credit-history table-default"
          hideBorder
          filters={[
            {
              name: 'Purchase type',
              key: 'action',
              currentValue: filters['action'],
              values: {
                values: List(
                  _.map(CREDIT_LOG_ACTION_TYPE_DISPLAY, (name, id) =>
                    Map({ id, name })
                  )
                ),
              },
            },
          ]}
          onFilterChange={this.onFilterChange.bind(this)}
          renderer={this.renderRow.bind(this)}
          ordering={sortBy}
          onOrderingChange={this.sortBy.bind(this)}
          response={response}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    organisationId: selectUserOrgId(state) || props.router.params.account,
    orgCreditDetail: selectCollection(state, 'orgCreditDetail').get(
      'items',
      null
    ),
    orgCreditHistory: selectCollection(state, 'orgCreditHistory'),
    sortBy: selectSortOrder(state, 'creditLogs'),
    filters: selectFilterSpec(state),
    response: selectGetCollection(state, 'orgCreditHistory'),
    pageSize: selectPageSize(state),
    user: selectUserProfile(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getOrgCreditHistory,
    getOrganisationCreditDetail,
    openModal,
    formatDate,
    sortList,
    setFilters,
    setPageSize,
    updateCreditPot,
  })(CreditAccountHistory)
);
