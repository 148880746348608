import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';
import { faEdit } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';

import { Pulse, PulseDisplayFrequency } from '^/reducers/api/types';
import { hasFailed, isPending } from '^/responseStates';
import { StoreState } from '^/store';
import { getHoursMinutesFromTime } from '../time/utils';
import { convertPulseFrequencyToRaw, getPulseDisplayFrequency } from './utils';
import { updatePulse } from '^/actions/actions';
import { DEFAULT_PULSE_TIME } from './constants';
import { PulseFrequencyDetails } from './types';
import { openSetFrequencyTimeModal } from '^/actions/modals';

interface State {
  frequency: PulseDisplayFrequency;
  reminderTime: string;
}

interface StateProps {
  loading: boolean;
  failed: boolean;
}

interface DispatchProps {
  updatePulse: typeof updatePulse;
  openSetFrequencyTimeModal: typeof openSetFrequencyTimeModal;
}

interface OwnProps {
  pulse?: Pulse;
  setValue?: (value: PulseFrequencyDetails) => void;
  disabled?: boolean;
  organisationId?: string;
  customEditIcon?: React.ReactNode;
}

export type Props = StateProps & DispatchProps & OwnProps;

export class PulseTimeFrequencySelector extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      frequency: props.pulse
        ? getPulseDisplayFrequency(props.pulse)
        : PulseDisplayFrequency.DAILY,
      reminderTime: props.pulse
        ? getHoursMinutesFromTime(props.pulse.reminder_time_utc)
        : DEFAULT_PULSE_TIME,
    };
  }

  resetState() {
    this.setState({
      frequency: PulseDisplayFrequency.DAILY,
      reminderTime: DEFAULT_PULSE_TIME,
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { frequency, reminderTime } = this.state;
    const { organisationId, pulse, setValue } = this.props;
    if (
      prevState.frequency !== frequency ||
      prevState.reminderTime !== reminderTime
    ) {
      const data = {
        ...convertPulseFrequencyToRaw(frequency),
        reminder_time_utc: reminderTime,
      };
      pulse
        ? this.props.updatePulse(pulse.id, data)
        : setValue && setValue(data);
    }

    if (prevProps.organisationId !== organisationId) {
      this.resetState();
    }
  }

  render() {
    const { failed } = this.props;
    const { frequency, reminderTime } = this.state;

    if (failed) {
      return (
        <p className="text-error">
          {i18next.t<string>('An error has occurred, please try again later.')}
        </p>
      );
    }

    return (
      <div className="form-item stacked">
        <Trans i18nKey="TRANS time input">
          <a
            onClick={() =>
              this.props.openSetFrequencyTimeModal(
                reminderTime,
                frequency,

                (valueTime, valueFrequency) =>
                  this.setState({
                    reminderTime: valueTime,
                    frequency: valueFrequency,
                  })
              )
            }
            role="button"
            aria-label="Open set frequency/time modal"
          >
            {frequency} at {reminderTime} (UTC){' '}
            {this.props.customEditIcon || <FontAwesomeIcon icon={faEdit} />}
          </a>
        </Trans>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    loading: isPending(state.responses.get('updatePulse')),
    failed: hasFailed(state.responses.get('updatePulse')),
  };
}

export default connect(mapStateToProps, {
  updatePulse,
  openSetFrequencyTimeModal,
})(PulseTimeFrequencySelector);
