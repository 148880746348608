import i18next from 'i18next';
import moment, { Moment } from 'moment';
import React from 'react';
import { range } from 'underscore';
import { connect } from 'react-redux';

import { closeTopModal } from '^/actions/modals';
import { formatDateFilter, formatTime, padTens } from '^/utils';
import { PulseDisplayFrequency } from '^/reducers/api/types';
import ToggleSwitch from '../buttons/ToggleSwitch';
import NewButton from '../NewButton';

const HOURS: string[] = range(0, 24).map(padTens);
const MINS: string[] = range(0, 60, 5).map(padTens);

interface OwnProps {
  time: string;
  frequency: PulseDisplayFrequency;
  onComplete: (date: string, frequency: PulseDisplayFrequency) => void;
}

interface DispatchProps {
  closeTopModal: typeof closeTopModal;
}

interface State {
  time: Moment;
  frequency: PulseDisplayFrequency;
}

const SWITCHER_CHOICES = [
  { type: PulseDisplayFrequency.DAILY, name: i18next.t<string>('Daily') },
  { type: PulseDisplayFrequency.WEEKLY, name: i18next.t<string>('Weekly') },
  {
    type: PulseDisplayFrequency.MONTHLY,
    name: i18next.t<string>('Monthly'),
  },
];
export type Props = OwnProps & DispatchProps;

export class SetFrequencyTimeModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { time, frequency } = this.props;

    this.state = {
      time: moment(`${formatDateFilter(moment())} ${time}`),
      frequency: frequency,
    };
  }

  public setSwitcher = (choice: PulseDisplayFrequency) => {
    return this.setState({ frequency: choice });
  };

  public render() {
    const { time, frequency } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col semi-bold-text">
            {i18next.t<string>('Frequency')}
          </div>
          <div className="col">
            <div className="frequency-switcher">
              <ToggleSwitch
                items={SWITCHER_CHOICES.map(choices => {
                  return {
                    id: choices.type,
                    name: choices.name,
                    selected: frequency === choices.type,
                  };
                })}
                onClick={this.setSwitcher}
              />
            </div>
          </div>
        </div>
        <div className="row form-horizontal time-selects-col frequency-time">
          <div className="col semi-bold-text">{i18next.t<string>('Time')}</div>
          <div className="col">
            <select value={time.get('hour')} onChange={this.setHours}>
              {HOURS.map(hour => (
                <option key={hour} value={+hour}>
                  {hour}
                </option>
              ))}
            </select>
          </div>
          <div className="col">
            <select value={time.get('minutes')} onChange={this.setMinutes}>
              {MINS.map(minute => (
                <option key={minute} value={+minute}>
                  {minute}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="modal-footer clearfix">
          <div className="pull-right">
            <NewButton
              buttonType="outline"
              onClick={() => this.props.closeTopModal()}
            >
              {i18next.t<string>('Cancel')}
            </NewButton>
            <NewButton onClick={this.onComplete}>
              {i18next.t<string>('Select')}
            </NewButton>
          </div>
        </div>
      </div>
    );
  }

  private setHours = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      time: this.state.time.clone().hours(+event.target.value),
    });
  };

  private setMinutes = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      time: this.state.time.clone().minutes(+event.target.value),
    });
  };

  private onComplete = () => {
    this.props.closeTopModal();
    return this.props.onComplete(
      formatTime(this.state.time),
      this.state.frequency
    );
  };
}

export default connect(null, { closeTopModal })(SetFrequencyTimeModal);
