import React from 'react';
import { List, Map, Set } from 'immutable';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { Formik, Form, useFormikContext } from 'formik';

import { StoreState } from '^/store';
import { getOrgFlexiPulseNames } from '^/actions/actions';
import { getUsers, clearCollection } from '^/actions/collections';
import {
  AllOrgs,
  OrgFlexiPulseNames,
  PulseBehaviourHierarchyOptions,
  PulseFrequency,
} from '^/reducers/api/types';
import CreatePulseSubForm1 from './CreatePulseSubForm1';
import { DEFAULT_PULSE_TIME } from './constants';
import { CreatePulseFormValues } from './types';

interface OwnProps {
  response?: Map<string, any>;
  orgFlexiPulseNamesResponse?: Map<string, any>;
  organisations: AllOrgs | null;
  orgFlexiPulseNames: OrgFlexiPulseNames | null;
  pulseBehaviourHierarchyOptions: PulseBehaviourHierarchyOptions | null;
  getCollectionPending: boolean;
  onSubmit: (data: CreatePulseFormValues) => void;
  getOrgPulseBehaviourHierarchyOptions: (orgId: string) => void;
}

interface DispatchProps {
  getUsers: typeof getUsers;
  clearCollection: typeof clearCollection;
  getOrgFlexiPulseNames: typeof getOrgFlexiPulseNames;
}

interface StateProps {
  selectedUsers: List<Map<string, any>>;
}

interface ExtraProps {
  submitting: boolean;
}

export type Props = OwnProps & DispatchProps & StateProps;

export type CreatePulseFormProps = Props & ExtraProps;

export const CreatePulseForm: React.FC<CreatePulseFormProps> = props => {
  const {
    response,
    orgFlexiPulseNamesResponse,
    organisations,
    orgFlexiPulseNames,
    pulseBehaviourHierarchyOptions,
    getCollectionPending,
    getOrgPulseBehaviourHierarchyOptions,
    submitting,
  } = props;

  const { values, setFieldValue, resetForm } = useFormikContext<
    CreatePulseFormValues
  >();

  const onChangeOrg = (orgId: string) => {
    props.getOrgFlexiPulseNames(orgId);
    resetForm();
    setFieldValue('organisation', orgId);
  };

  const selectedOrganisation = organisations?.find(
    ({ id }) => id === values.organisation
  );
  const selectedHierarchy = pulseBehaviourHierarchyOptions?.find(
    ({ id }) => id === values.pulse_behaviour_hierarchy
  );
  const nameAlreadyInUse = orgFlexiPulseNames?.names.includes(values.name);

  const createDisabled = Boolean(
    !values.users.size ||
      !values.name ||
      !selectedOrganisation ||
      !selectedHierarchy ||
      nameAlreadyInUse
  );

  const customSubmittedButtonText = submitting
    ? i18next.t<string>('Creating...')
    : undefined;

  return (
    <Form className="create-pulse-form">
      <CreatePulseSubForm1
        selectedOrganisation={selectedOrganisation}
        response={response}
        organisations={organisations}
        pulseBehaviourHierarchyOptions={pulseBehaviourHierarchyOptions}
        getCollectionPending={getCollectionPending}
        getOrgPulseBehaviourHierarchyOptions={
          getOrgPulseBehaviourHierarchyOptions
        }
        onChangeOrg={onChangeOrg}
        pulseName={values.name}
        orgFlexiPulseNamesResponse={orgFlexiPulseNamesResponse}
        nameAlreadyInUse={nameAlreadyInUse}
        createDisabled={createDisabled}
        customSubmittedButtonText={customSubmittedButtonText}
        submitting={submitting}
      />
    </Form>
  );
};

export const CreatePulseFormWrapped: React.FC<Props> = props => {
  const [submitting, setSubmitting] = React.useState(false);
  const { onSubmit } = props;

  const handleSubmit = (values: CreatePulseFormValues) => {
    setSubmitting(true);
    onSubmit(values);
  };

  return (
    <Formik<CreatePulseFormValues>
      initialValues={{
        organisation: '',
        name: '',
        pulse_behaviour_hierarchy: '',
        users: Set(),
        frequency: PulseFrequency.DAILY,
        frequency_count: 1,
        reminder_time_utc: DEFAULT_PULSE_TIME,
      }}
      onSubmit={handleSubmit}
    >
      <CreatePulseForm {...props} submitting={submitting} />
    </Formik>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    selectedUsers: state.collections.getIn(['users', 'items'], List()),
  };
}

const ConnectedCreatePulseForm = connect(mapStateToProps, {
  clearCollection,
  getUsers,
  getOrgFlexiPulseNames,
})(CreatePulseFormWrapped);

export default ConnectedCreatePulseForm;
