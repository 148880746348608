import _ from 'underscore';

export default (reducers, initialState = {}) => (
  state = initialState,
  action
) =>
  _.compose.apply(
    null,
    reducers.map(reducer => _state => reducer(_state, action))
  )(state);
