import React, { Key } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import _ from 'lodash';

import {
  Pulse,
  PulseStatus,
  Uuid,
  PulseDisplayFrequency,
} from '^/reducers/api/types';
import {
  openStopPulsingModal,
  openResendPulseInvitesModal,
} from '^/actions/modals';
import { BottomActionBar } from '../BottomActionBar';
import PulseIconCircleWhite from '../PulseIconCircleWhite';
import {
  FilterOptions,
  FiltersAndOrdering,
  InteractiveTable,
  PaginatedResultsResponse,
  Selections,
} from '../InteractiveTable';
import { getHoursMinutesFromTime } from '../time/utils';
import { deepCopy } from '^/utils';

export type Props = {
  pulse: Pulse;
  openStopPulsingModal: typeof openStopPulsingModal;
  openResendPulseInvitesModal: typeof openResendPulseInvitesModal;
};

export interface statusFilters {
  status: Array<{ [key: string]: string }>;
}

interface State {
  selectedRowIds: Array<Uuid>;
  rowData:
    | PaginatedResultsResponse<{
        [key: string]: string;
      }>
    | undefined;
  filteredRowData: PaginatedResultsResponse<{
    [key: string]: string;
  }> | null;
  filters: statusFilters | undefined;
  filterOptions: FiltersAndOrdering;
  checkBoxOptions: ReadonlyArray<Selections> | undefined;
  selectAllDisabled: boolean;
  selectedStatus: string | null;
  allSelectableIds: ReadonlyArray<string> | undefined;
}

const initialFilterOptions = {
  sorting: {
    key: 'full_name',
    reverse: false,
  },
  page: 1,
};

export class PulseDetailTable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRowIds: [],
      rowData: undefined,
      filters: undefined,
      filterOptions: initialFilterOptions,
      checkBoxOptions: undefined,
      filteredRowData: null,
      selectAllDisabled: true,
      selectedStatus: null,
      allSelectableIds: undefined,
    };
  }

  private getRows() {
    const {
      pulse: { userpulse_set },
    } = this.props;
    const { filterOptions } = this.state;
    const rowData = userpulse_set?.map(item => {
      return {
        id: item.id,
        full_name: item.user.full_name,
        email: item.user.email,
        pulse_sub_behaviours: this.getBehaviourList(item.pulse_sub_behaviours),
        number_of_raters: this.getRaterNumMinusSelf(
          item.number_of_raters ? item.number_of_raters.toString() : '0'
        ),
        frequency: this.getFrequency(),
        status: item.status,
      };
    });
    const rows = { count: userpulse_set?.length || 0, results: rowData };
    const reverse = filterOptions.sorting.reverse;
    const sortKey = filterOptions.sorting.key;
    const sortedRows = rows as PaginatedResultsResponse<{
      [key: string]: string;
    }>;

    sortedRows.results?.sort(
      (a: { [x: string]: string }, b: { [x: string]: string }) =>
        reverse ? -1 : 1 * a[sortKey].localeCompare(b[sortKey])
    );

    this.setState(
      {
        rowData: deepCopy(sortedRows),
      },
      () => {
        this.getFilters();
        this.initialCheckBoxSelections();
      }
    );
  }
  private getRaterNumMinusSelf(raters: string) {
    return parseInt(raters, 10) > 1 ? (parseInt(raters, 10) - 1).toString() : 0;
  }

  private getBehaviourList(
    behaviours: readonly { name: string; id: string }[] | null
  ) {
    return behaviours && behaviours.length > 0
      ? behaviours.map(behaviour => behaviour.name).join(', ')
      : i18next.t<string>('Not yet selected');
  }

  private getFrequency() {
    const frequency = `${(this.props.pulse.frequency_count === 4
      ? PulseDisplayFrequency.MONTHLY
      : this.props.pulse.frequency
    ).toLowerCase()}`;
    const time = `at ${getHoursMinutesFromTime(
      this.props.pulse.reminder_time_utc
    )} (UTC)`;
    return `${frequency.charAt(0).toUpperCase()}${frequency.substring(
      1
    )} ${time}`;
  }

  private getFilters() {
    const filterKey = 'status';
    const filters = [
      ...new Set(
        this.props.pulse.userpulse_set?.map(item => ({
          label: item[filterKey as keyof statusFilters]
            ?.toString()
            .toLowerCase(),
          key: item[filterKey as keyof statusFilters],
        }))
      ),
    ];

    const uniqueBy = (k: string, s = new Set()) => (o: {
        [x: string]: string;
      }) => !s.has(o[k]) && s.add(o[k]),
      result = filters.filter(uniqueBy('key'));

    this.setState({
      filters: { status: result },
    });
  }

  private updateCheckBoxSelections(
    selectAll: boolean,
    checked: boolean,
    id?: string | null,
    status?: string | null
  ) {
    const {
      checkBoxOptions,
      selectedRowIds,
      rowData,
      filterOptions,
      selectedStatus,
      allSelectableIds,
    } = this.state;
    let updateSelections = checkBoxOptions;
    let updateSelectedRowIds = selectedRowIds;
    const matchingStatusIds = rowData?.results
      .filter(item =>
        status ? item['status'] === status : item['status'] === selectedStatus
      )
      .map(matches => matches['id']);
    const anyChecked = Boolean(
      updateSelections &&
        updateSelections.filter(item => item.checked === true).length > 0
    );
    if (!selectAll) {
      updateSelections = checkBoxOptions?.map(item => ({
        id: item['id' as keyof Key].toString(),
        checked:
          item['id' as keyof Key].toString() === id
            ? checked
            : Boolean(item['checked' as keyof Key]),
        selectable: anyChecked
          ? Boolean(
              item['id' as keyof Key].toString() === id ||
                matchingStatusIds?.includes(item['id' as keyof Key].toString())
            )
          : Boolean(
              item['id' as keyof Key].toString() === id ||
                allSelectableIds?.includes(item['id' as keyof Key].toString())
            ),
      }));
    } else {
      if (checked) {
        updateSelections = checkBoxOptions?.map(item => ({
          id: item['id' as keyof Key].toString(),
          checked: Boolean(
            matchingStatusIds?.includes(item['id' as keyof Key].toString())
          ),
          selectable: Boolean(
            matchingStatusIds?.includes(item['id' as keyof Key].toString())
          ),
        }));
      }
      if (!checked) {
        const filterChosen =
          filterOptions &&
          filterOptions['filters'] &&
          filterOptions['filters']['status'].map(item => item.key);
        const includedEND =
          filterChosen && filterChosen.includes('ENDED' || 'ENDING');
        const isOneFilter =
          filterChosen && filterChosen.length === 1 && !includedEND;
        updateSelections = rowData?.results.map(item => ({
          id: item['id' as keyof Key].toString(),
          checked: false,
          selectable:
            filterChosen && isOneFilter
              ? item['status' as keyof Key] === filterChosen[0]
                ? true
                : false
              : item['status' as keyof Key].toString() === PulseStatus.ENDED ||
                item['status' as keyof Key].toString() === PulseStatus.ENDING
              ? false
              : true,
        }));
        updateSelectedRowIds = [];
      }
    }
    this.setState({
      checkBoxOptions: deepCopy(updateSelections),
      selectAllDisabled: this.getSelectAllDisableState(
        updateSelections,
        filterOptions
      ),
      selectedStatus: status ? status : null,
      selectedRowIds: deepCopy(updateSelectedRowIds),
    });
  }

  private initialCheckBoxSelections() {
    const { selectedStatus, rowData } = this.state;
    const allNotEndedStatus = rowData?.results
      .filter(
        item =>
          item['status' as keyof Key].toString() !== PulseStatus.ENDED &&
          PulseStatus.ENDED
      )
      .map(item => item.id);
    const selections = rowData?.results.map(item => ({
      id: item['id' as keyof Key].toString(),
      checked: false,
      selectable:
        item['status' as keyof Key].toString() === PulseStatus.ENDED ||
        item['status' as keyof Key].toString() === PulseStatus.ENDING ||
        (selectedStatus &&
          item['status' as keyof Key].toString() !== selectedStatus)
          ? false
          : true,
    }));
    this.setState({
      checkBoxOptions: deepCopy(selections),
      selectAllDisabled: this.getSelectAllDisableState(selections, undefined),
      selectedRowIds: [],
      allSelectableIds: allNotEndedStatus,
    });
  }

  componentDidMount() {
    this.getRows();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!_.isEqual(prevProps.pulse, this.props.pulse)) {
      this.getRows();
    }
  }

  private getSelectedPulseUsers = (): ReadonlyArray<{
    userPulseId: string;
    fullName: string;
  }> => {
    const { rowData, selectedRowIds } = this.state;
    if (!rowData?.results) {
      return [];
    }

    const allIds = rowData?.results.map(function(value) {
      return {
        userPulseId: value['id' as keyof Key].toString(),
        fullName: value['full_name' as keyof Key].toString(),
      };
    });
    const selectedIds = selectedRowIds;
    const allSelectedIds = allIds.filter(function(item) {
      return selectedIds.indexOf(item.userPulseId.toString()) !== -1;
    });

    return allSelectedIds;
  };

  private onCheckBoxSelection = (
    checked: boolean,
    row: { key: string; value: string }[] | null,
    selectAll: boolean
  ) => {
    const {
      checkBoxOptions,
      rowData,
      selectedStatus,
      selectedRowIds,
    } = this.state;
    const checkBoxOptionClone = checkBoxOptions && [...checkBoxOptions];
    const checkedId = checkBoxOptions
      ?.filter(items => items.checked === true)
      .map(item => item['id' as keyof Key].toString())[0];

    const statusChecked = checkedId
      ? rowData?.results
          .filter(item => item['id'] === checkedId)
          .map(matches => matches['status'])[0]
      : null;

    const allRowIds = rowData?.results
      .filter(
        items =>
          items['status' as keyof Key] === statusChecked ||
          items['status' as keyof Key] === selectedStatus
      )
      .map(items => items['id']);
    if (selectAll) {
      this.setState(
        prevState => {
          return {
            ...prevState,

            selectedRowIds: deepCopy(allRowIds as Array<string>),
          };
        },
        () => this.updateCheckBoxSelections(true, checked, null, statusChecked)
      );
    } else {
      const rowId = row && row.filter(items => items.key === 'id')[0].value;
      const status =
        row && row.filter(items => items.key === 'status')[0].value;
      let updateState: string[] | [] = [];
      const copyState = [...selectedRowIds];
      if (rowId && checked) {
        updateState = copyState.filter(item => item !== rowId).concat(rowId);
      }
      if (rowId && !checked) {
        updateState = copyState.filter(item => item !== rowId);
      }
      const foundIndex =
        rowId && checkBoxOptionClone?.findIndex(item => item['id'] === rowId);
      if (checkBoxOptionClone) {
        checkBoxOptionClone[foundIndex || 0].checked = checked;
      }
      this.setState(
        prevState => {
          return {
            ...prevState,
            selectedRowIds: deepCopy(updateState),
          };
        },
        () => this.updateCheckBoxSelections(false, checked, rowId, status)
      );
    }
  };

  private getSelectAllDisableState = (
    selections: readonly Selections[] | undefined,
    filtersSelections: FiltersAndOrdering | undefined
  ) => {
    const { rowData } = this.state;
    const anyChecked =
      selections && selections.filter(item => item.checked).length > 0;
    const numbFilterSelected =
      filtersSelections &&
      filtersSelections['filters'] &&
      filtersSelections['filters']['status'].length;
    const statuses = rowData?.results
      .filter(
        items => items['status'] !== 'ENDING' && items['status'] !== 'ENDING'
      )
      .map(item => item['status']);
    const oneViable = [...new Set(statuses)].length === 1;
    if (oneViable) {
      this.setState({ selectedStatus: [...new Set(statuses)][0] });
    }
    const filterSelectedTypes =
      filtersSelections &&
      filtersSelections['filters'] &&
      filtersSelections['filters']['status'].map(item => item.key);
    const includedEND =
      filterSelectedTypes && filterSelectedTypes.includes('ENDED' || 'ENDING');
    return anyChecked || (numbFilterSelected === 1 && !includedEND) || oneViable
      ? false
      : true;
  };

  private getNumberSelected = (): number => {
    const { rowData, selectedRowIds } = this.state;
    const allStatus = rowData?.results.map(function(value) {
      return {
        userPulseId: value['id' as keyof Key].toString(),
        status: value['status' as keyof Key].toString(),
      };
    });

    const selectedIds = selectedRowIds;
    const allSelectedIds = allStatus?.filter(function(item) {
      return selectedIds.indexOf(item.userPulseId.toString()) !== -1;
    });
    if (!allSelectedIds) {
      return 0;
    }
    return (
      allSelectedIds?.filter(item => item.status === 'INVITED').length || 0
    );
  };

  public render() {
    const CellRender = (key: string, value: string) => {
      let cell = <>{value}</>;
      if (key === 'email') {
        cell = <span className="break-word">{value}</span>;
      }
      if (key === 'pulse_sub_behaviours') {
        <span className="break-word">{value}</span>;
      }
      if (key === 'status') {
        cell = <span className="capitalize">{value.toLowerCase()}</span>;
      }
      if (key === 'frequency') {
        cell = <span>{value}</span>;
      }
      return cell;
    };

    const doFiltersSearchSort = (
      type: string,
      key: string | number,
      selected: string | number,
      reverse?: boolean
    ) => {
      const {
        selectedStatus,
        checkBoxOptions,
        filterOptions,
        filteredRowData,
        rowData,
      } = this.state;
      switch (type) {
        case 'filter': {
          let updateSelectedStatus = selectedStatus;
          const updateCheckBoxOptions = checkBoxOptions;
          const filterNewOptions = deepCopy(filterOptions);
          const anyChecked =
            updateCheckBoxOptions &&
            updateCheckBoxOptions.filter(item => item.checked).length > 0;
          if (!filterNewOptions.filters) {
            filterNewOptions['filters'] = {
              status: [
                {
                  label: selected.toString(),
                  key: selected.toString(),
                },
              ],
            };
            updateSelectedStatus = selected.toString();
          } else {
            const existingIndex = filterNewOptions['filters'][
              'status'
            ].findIndex(
              (item: { key: string }) => item.key === selected.toString()
            );
            if (existingIndex === -1) {
              filterNewOptions['filters']['status'].push({
                label: selected.toString(),
                key: selected.toString(),
              });
              updateSelectedStatus = anyChecked ? updateSelectedStatus : null;
            } else if (filterNewOptions['filters']['status'].length === 1) {
              delete filterNewOptions['filters'];
              updateSelectedStatus = anyChecked ? updateSelectedStatus : null;
            } else {
              filterNewOptions['filters']['status'].splice(existingIndex, 1);
              updateSelectedStatus = null;
            }
          }

          const currentRowData = rowData?.results;
          const filtersArray =
            (filterNewOptions['filters'] &&
              filterNewOptions['filters']['status'].map(
                (item: { key: Key }) => item.key
              )) ||
            null;

          const filteredBy =
            currentRowData && filtersArray
              ? currentRowData.filter(function(item) {
                  return (
                    filtersArray.indexOf(item['status' as keyof Key]) !== -1
                  );
                })
              : null;

          let filteredByState: PaginatedResultsResponse<{
            [key: string]: string;
          }> | null = deepCopy(rowData);

          if (filteredByState) {
            if (filteredBy) {
              filteredByState.results = [...filteredBy];
            } else {
              filteredByState = null;
              updateSelectedStatus = null;
            }
          }
          if (
            (updateSelectedStatus && updateSelectedStatus === 'ENDED') ||
            updateSelectedStatus === 'ENDING'
          ) {
            updateSelectedStatus = null;
          }
          this.setState(prevState => {
            return {
              ...prevState,
              filterOptions: deepCopy(filterNewOptions),
              filteredRowData: deepCopy(filteredByState),
              selectAllDisabled: this.getSelectAllDisableState(
                updateCheckBoxOptions,
                filterNewOptions
              ),

              selectedStatus: deepCopy(updateSelectedStatus),
              checkBoxOptions: deepCopy(updateCheckBoxOptions),
            };
          });
          break;
        }
        case 'sort':
          const sortedRowData = deepCopy(rowData);
          const sortedFilteredData = filteredRowData
            ? deepCopy(filteredRowData)
            : null;
          sortedRowData.results.sort(
            (a: { [x: string]: string }, b: { [x: string]: string }) =>
              reverse ? -1 : 1 * a[key].localeCompare(b[key])
          );
          sortedFilteredData
            ? sortedFilteredData.results.sort(
                (a: { [x: string]: string }, b: { [x: string]: string }) =>
                  reverse ? -1 : 1 * a[key].localeCompare(b[key])
              )
            : null;
          const newSortOptions = filterOptions;
          newSortOptions.sorting.key === key
            ? (newSortOptions.sorting.reverse = !newSortOptions.sorting.reverse)
            : reverse;
          newSortOptions.sorting.key = key as string;

          this.setState(prevState => {
            return {
              ...prevState,
              rowData: deepCopy(sortedRowData),
              filteredRowData: deepCopy(sortedFilteredData),
              filterOptions: deepCopy(newSortOptions),
            };
          });

          break;
      }
    };

    const {
      selectedRowIds,
      rowData,
      filters,
      checkBoxOptions,
      filterOptions,
      filteredRowData,
      selectAllDisabled,
    } = this.state;
    const numberToResendInvites = this.getNumberSelected();
    const barActions = [
      {
        label: 'Cancel Pulsing',
        onClick: () =>
          this.props.openStopPulsingModal(this.getSelectedPulseUsers(), () =>
            window.location.reload()
          ),
        isDisabled: false,
      },
    ];

    numberToResendInvites > 0 &&
      barActions.unshift({
        label: 'Resend Invites',
        onClick: () =>
          this.props.openResendPulseInvitesModal(
            this.getSelectedPulseUsers(),
            this.props.pulse.id
          ),
        isDisabled: false,
      });
    return (
      <div className="pulse-table-and-buttons">
        <InteractiveTable
          className="pulse-checks"
          pageSize={1000}
          page={1}
          isDataPending={false}
          isFilterDataPending={false}
          isNextPageDataPending={false}
          loadPage={() => null}
          cellRenderer={CellRender}
          setSelectedFilter={doFiltersSearchSort}
          enableCheckBoxes
          selectAllDisabled={selectAllDisabled}
          onSelection={this.onCheckBoxSelection}
          rows={
            filteredRowData === null
              ? (rowData as PaginatedResultsResponse<{
                  key: string;
                  value: string;
                }>)
              : (filteredRowData as PaginatedResultsResponse<{
                  key: string;
                  value: string;
                }>)
          }
          filters={(filters as unknown) as FilterOptions}
          initialSelections={checkBoxOptions}
          selectedFilterOptions={filterOptions}
          columns={[
            {
              header: {
                label: 'Select all',
                key: 'id' as keyof { key: Key; value: string },
              },
              sortable: false,
            },
            {
              header: {
                label: 'Name',
                key: 'full_name' as keyof { key: Key; value: string },
              },
              sortable: true,
            },
            {
              header: {
                label: 'Email',
                key: 'email' as keyof { key: Key; value: string },
              },
              sortable: false,
            },
            {
              header: {
                label: 'Behaviours',
                key: 'pulse_sub_behaviours' as keyof {
                  key: Key;
                  value: string;
                },
              },
              sortable: false,
            },
            {
              header: {
                label: 'No. of raters',
                key: 'number_of_raters' as keyof { key: Key; value: string },
              },
              sortable: false,
            },
            {
              header: {
                label: 'Frequency',
                key: 'frequency' as keyof { key: Key; value: string },
              },
              sortable: false,
            },
            {
              header: {
                label: 'Status',
                key: 'status' as keyof { key: Key; value: string },
              },
              filter: {
                key: 'status' as keyof { key: Key; value: string },
                type: 'dropdown-multi',
              },
              sortable: false,
            },
          ]}
        />
        {selectedRowIds.length > 0 && rowData?.results && (
          <BottomActionBar
            icon={<PulseIconCircleWhite />}
            infoText={i18next.t<string>('{{count}} respondent selected', {
              count: selectedRowIds.length,
            })}
            errorText={null}
            actions={barActions}
          />
        )}
      </div>
    );
  }
}

export default connect(null, {
  openStopPulsingModal,
  openResendPulseInvitesModal,
})(PulseDetailTable);
