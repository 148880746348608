import React from 'react'; // eslint-disable-line no-unused-vars

import ContentTabset from '^/components/ContentTabset';

const Tabset = ({ name, children, contentWrapper }) => (
  <ContentTabset
    name={name}
    defaultActiveTab={0}
    contentWrapper={contentWrapper}
  >
    {React.Children.toArray(children)
      .filter(x => x)
      .map((child, idx) =>
        React.cloneElement(child, { tabId: idx, tabsetName: name })
      )}
  </ContentTabset>
);

export default Tabset;
