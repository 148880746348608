import i18next from '^/locale';
import { can, administerOrganisations } from '^/capabilities';
import { PrimaryCompetency, ProductType } from '^/reducers/api/types';

export interface Column {
  name: string;
  id: string;
  chartName?: string;
  isExtra?: boolean;
  field: string;
  altHoverText?: string;
  columnName?: string;
  codeLevel?: string;
  isHideable: (user: Immutable.Map<string, any>) => boolean;
  sortKey?: string;
  shouldOmit?: (user: Immutable.Map<string, any>) => boolean;
  collapseSmall?: boolean;
}

export interface ColumnGroup {
  name: string;
  id: string;
  isExpandable: boolean;
  chartCode?: string;
  columns: ReadonlyArray<Column>;
}

export interface DataAnalyticsTableStructure {
  PSYCAP_POTENTIAL: ReadonlyArray<ColumnGroup>;
  POSITIVE_RESILIENCE_PROFILER: ReadonlyArray<ColumnGroup>;
  DISC: ReadonlyArray<ColumnGroup>;
}

export const THREE_SIXTY_LEVELS = [
  { threshold: 5, level: 'Exceptional' },
  { threshold: 4, level: 'Strength' },
  { threshold: 3, level: 'Meets expectations' },
  { threshold: 2, level: 'Minor development' },
  { threshold: 0, level: 'Major development' },
];

export const DATA_ANALYTICS_HEATMAP_LEVEL_MAP = {
  PSYCAP_POTENTIAL: {
    'Very Low/Risk': 'heatmap-color-red',
    Low: 'heatmap-color-pale-red',
    Moderate: 'heatmap-color-yellow',
    High: 'heatmap-color-pale-green',
    'Very High/Risk': 'heatmap-color-green',
    'Very High / risk': 'heatmap-color-green',

    // Snapshot:
    Leverage: 'heatmap-color-pale-green',
    Refine: 'heatmap-color-yellow',
    Grow: 'heatmap-color-pale-red',
    PP_SD: {
      'Very Low/Risk': 'heatmap-color-green',
      Low: 'heatmap-color-pale-green',
      Moderate: 'heatmap-color-yellow',
      High: 'heatmap-color-pale-red',
      'Very High/Risk': 'heatmap-color-red',
      'Very High / risk': 'heatmap-color-red',
    },
  },
  POSITIVE_RESILIENCE_PROFILER: {
    PRP_OVERALL: {
      Strong: 'heatmap-color-grey-dark',
      Underdeveloped: 'heatmap-color-grey-light',
      Moderate: 'heatmap-color-grey-mid',
    },
    R_PU: {
      Strong: 'heatmap-color-blue-dark',
      Underdeveloped: 'heatmap-color-blue-light',
      Moderate: 'heatmap-color-blue-mid',
    },
    R_PE: {
      Strong: 'heatmap-color-purple-dark',
      Underdeveloped: 'heatmap-color-purple-light',
      Moderate: 'heatmap-color-purple-mid',
    },
    R_CD: {
      Strong: 'heatmap-color-orange-dark',
      Underdeveloped: 'heatmap-color-orange-light',
      Moderate: 'heatmap-color-orange-mid',
    },
    R_G: {
      Strong: 'heatmap-color-yellow-dark',
      Underdeveloped: 'heatmap-color-yellow-light',
      Moderate: 'heatmap-color-yellow-mid',
    },
    R_CP: {
      Strong: 'heatmap-color-green-dark',
      Underdeveloped: 'heatmap-color-green-light',
      Moderate: 'heatmap-color-green-mid',
    },
    R_WB: {
      Strong: 'heatmap-color-teal-dark',
      Underdeveloped: 'heatmap-color-teal-light',
      Moderate: 'heatmap-color-teal-mid',
    },
    R_CT: {
      Strong: 'heatmap-color-red-dark',
      Underdeveloped: 'heatmap-color-red-light',
      Moderate: 'heatmap-color-red-mid',
    },
  },
  THREE_SIXTY: {
    'Major development': 'heatmap-color-red',
    'Minor development': 'heatmap-color-pale-red',
    'Meets expectations': 'heatmap-color-yellow',
    Strength: 'heatmap-color-pale-green',
    Exceptional: 'heatmap-color-green',
  },

  DISC: {
    D: {
      'Very low': 'heatmap-d-palest-red',
      Low: 'heatmap-d-pale-red',
      Mid: 'heatmap-d-light-red',
      High: 'heatmap-d-medium-red',
      'Very high': 'heatmap-d-dark-red',
    },
    I: {
      'Very low': 'heatmap-i-palest-yellow',
      Low: 'heatmap-i-pale-yellow',
      Mid: 'heatmap-i-light-yellow',
      High: 'heatmap-i-medium-yellow',
      'Very high': 'heatmap-i-dark-yellow',
    },
    S: {
      'Very low': 'heatmap-s-palest-green',
      Low: 'heatmap-s-pale-green',
      Mid: 'heatmap-s-light-green',
      High: 'heatmap-s-medium-green',
      'Very high': 'heatmap-s-dark-green',
    },
    C: {
      'Very low': 'heatmap-c-palest-blue',
      Low: 'heatmap-c-pale-blue',
      Mid: 'heatmap-c-light-blue',
      High: 'heatmap-c-medium-blue',
      'Very high': 'heatmap-c-dark-blue',
    },
  },
};

const legend360 = Object.entries(
  DATA_ANALYTICS_HEATMAP_LEVEL_MAP['THREE_SIXTY']
).map(([key, value]) => {
  return { name: key, colours: [value] };
});

export const DATA_ANALYTICS_HEATMAP_LEGEND = {
  PSYCAP_POTENTIAL: {
    levels: [
      {
        name: 'Very Low/Risk',
        colours: ['heatmap-color-red'],
      },
      {
        name: 'Low',
        colours: ['heatmap-color-pale-red'],
      },
      {
        name: 'Moderate',
        colours: ['heatmap-color-yellow'],
      },
      {
        name: 'High',
        colours: ['heatmap-color-pale-green'],
      },
      {
        name: 'Very High/Risk',
        colours: ['heatmap-color-green'],
      },
    ],
  },
  DISC: {
    levels: ['Very low', 'Low', 'Mid', 'High', 'Very high'].map(name => ({
      name: i18next.t<string>(name),
      colours: Object.entries(
        DATA_ANALYTICS_HEATMAP_LEVEL_MAP['DISC']
      ).map(dimension => dimension[1][name as keyof Object].toString()),
    })),
  },
  POSITIVE_RESILIENCE_PROFILER: {
    levels: [
      {
        name: 'Underdeveloped',
        colours: [
          'heatmap-color-grey-light',
          'heatmap-color-blue-light',
          'heatmap-color-purple-light',
          'heatmap-color-orange-light',
          'heatmap-color-yellow-light',
          'heatmap-color-green-light',
          'heatmap-color-teal-light',
          'heatmap-color-red-light',
        ],
      },

      {
        name: 'Moderate',
        colours: [
          'heatmap-color-grey-mid',
          'heatmap-color-blue-mid',
          'heatmap-color-purple-mid',
          'heatmap-color-orange-mid',
          'heatmap-color-yellow-mid',
          'heatmap-color-green-mid',
          'heatmap-color-teal-mid',
          'heatmap-color-red-mid',
        ],
      },
      {
        name: 'Strong',
        colours: [
          'heatmap-color-grey-dark',
          'heatmap-color-blue-dark',
          'heatmap-color-purple-dark',
          'heatmap-color-orange-dark',
          'heatmap-color-yellow-dark',
          'heatmap-color-green-dark',
          'heatmap-color-teal-dark',
          'heatmap-color-red-dark',
        ],
      },
    ],
  },
  THREE_SIXTY: {
    levels: [...legend360],
  },
};

export const DEMOGRAPHIC_COLUMN_GROUPS: ReadonlyArray<ColumnGroup> = [
  {
    name: ' ',
    id: 'user',
    isExpandable: false,
    columns: [
      {
        name: i18next.t<string>('Name'),
        id: 'user',
        field: 'full_name',
        isHideable: () => false,
        sortKey: 'user__full_name',
      },
    ],
  },
  {
    name: i18next.t<string>('Details'),
    id: 'user',
    isExpandable: true,
    columns: [
      {
        name: i18next.t<string>('Account'),
        id: 'user',
        field: 'organisation',
        isHideable: user => !can(user, administerOrganisations()),
        sortKey: 'user__organisation__name',
        shouldOmit: user => !can(user, administerOrganisations()),
      },
      {
        name: i18next.t<string>('Sex'),
        id: 'user',
        field: 'sex',
        isHideable: () => true,
        sortKey: 'user__sex',
      },
      {
        name: i18next.t<string>('Age range'),
        id: 'user',
        field: 'age',
        isHideable: () => true,
        sortKey: 'user__age',
      },
      {
        name: i18next.t<string>('Ethnicity'),
        id: 'user',
        field: 'ethnicity',
        isHideable: () => true,
        sortKey: 'user__ethnicity',
      },
      {
        name: i18next.t<string>('Job Level'),
        id: 'user',
        field: 'job_level',
        isHideable: () => true,
        sortKey: 'user__job_level',
      },
      {
        name: i18next.t<string>('Industry'),
        id: 'user',
        field: 'industry',
        isHideable: () => true,
        sortKey: 'user__industry',
      },
      {
        name: i18next.t<string>('Geography'),
        id: 'user',
        field: 'geography',
        isHideable: () => true,
        sortKey: 'user__geography',
      },
      {
        name: i18next.t<string>('Activity'),
        id: 'user',
        field: 'activity',
        isHideable: () => true,
        sortKey: 'activity_product_version__activity__name',
      },
      {
        name: i18next.t<string>('Assessment completed'),
        id: 'user',
        field: 'completed',
        isHideable: user => can(user, administerOrganisations()),
        sortKey: 'considered_completed',
      },
    ],
  },
];

const THREE_SIXTY_DEMOGRAPHIC_COLUMN_GROUPS = [
  DEMOGRAPHIC_COLUMN_GROUPS[0],
  {
    ...DEMOGRAPHIC_COLUMN_GROUPS[1],
    columns: [
      ...DEMOGRAPHIC_COLUMN_GROUPS[1].columns.slice(0, -1),
      {
        ...DEMOGRAPHIC_COLUMN_GROUPS[1].columns.slice(-1)[0],
        field: 'self_and_lm_completed',
        sortKey: 'self_and_lm_completed',
      },
    ],
  },
];

interface FactorsHierarchyItem {
  factor: ReadonlyArray<string>;
  subfactors: ReadonlyArray<ReadonlyArray<string | any>>;
}

export type FactorsHierarchy = {
  [key in ProductType]: ReadonlyArray<FactorsHierarchyItem>;
};

export const FACTORS_HIERARCHY_EXTRA_RESULTS: FactorsHierarchy = {
  PSYCAP_POTENTIAL: [
    {
      factor: [i18next.t<string>('Snapshot'), 'SNAPSHOT', 'SN'],
      subfactors: [],
    },
  ],
  POSITIVE_RESILIENCE_PROFILER: [],
  DISC: [],
};

export const FACTORS_HIERARCHY: FactorsHierarchy = {
  PSYCAP_POTENTIAL: [
    {
      factor: [i18next.t<string>('Mental Agility'), 'PP_MA', 'MA'],
      subfactors: [
        [i18next.t<string>('Capacity'), 'PP_CA'],
        [i18next.t<string>('Clarity'), 'PP_CL'],
        [i18next.t<string>('Flexibility'), 'PP_F'],
      ],
    },
    {
      factor: [i18next.t<string>('Emotional Agility'), 'PP_EA', 'EA'],
      subfactors: [
        [i18next.t<string>('Insight'), 'PP_I'],
        [i18next.t<string>('Adaptation'), 'PP_AD'],
        [i18next.t<string>('Authenticity'), 'PP_AU'],
      ],
    },
    {
      factor: [i18next.t<string>('Confidence'), 'PP_C', 'CO'],
      subfactors: [
        [i18next.t<string>('Self Regard'), 'PP_SR'],
        [i18next.t<string>('Self Efficacy'), 'PP_SE'],
        [i18next.t<string>('Stance'), 'PP_S'],
      ],
    },
    {
      factor: [i18next.t<string>('Resilience'), 'PP_R', 'RE'],
      subfactors: [
        [i18next.t<string>('Perseverance'), 'PP_PS'],
        [i18next.t<string>('Perspective'), 'PP_PR'],
        [i18next.t<string>('Self Control'), 'PP_SC'],
      ],
    },
    {
      factor: [i18next.t<string>('Drive'), 'PP_D', 'DR'],
      subfactors: [
        [i18next.t<string>('Purpose'), 'PP_P'],
        [i18next.t<string>('Achievement Focus'), 'PP_AF'],
        [i18next.t<string>('Initiative'), 'PP_INIT'],
      ],
    },
    {
      factor: [i18next.t<string>('Social Desirability'), 'PP_SD', 'SD'],
      subfactors: [],
    },
  ],
  POSITIVE_RESILIENCE_PROFILER: [
    {
      factor: [i18next.t<string>('Overall'), 'PRP_OVERALL', 'O'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Purposefulness'), 'R_PU', 'PU'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Perspective'), 'R_PE', 'PE'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Control'), 'R_CT', 'CT'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Connectedness'), 'R_CD', 'CD'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Growth'), 'R_G', 'G'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Coping'), 'R_CP', 'CP'],
      subfactors: [],
    },
    {
      factor: [i18next.t<string>('Wellbeing'), 'R_WB', 'WB'],
      subfactors: [],
    },
  ],
  DISC: [
    {
      factor: [i18next.t<string>('Self image'), 'SI', 'CHANGE'],
      subfactors: [
        [i18next.t<string>('Style'), 'self_image_style_name'],
        [i18next.t<string>('Pattern'), 'self_image_pattern_text'],
        [i18next.t<string>('Dominance'), 'D'],
        [i18next.t<string>('Influence'), 'I'],
        [i18next.t<string>('Steadiness'), 'S'],
        [i18next.t<string>('Compliance'), 'C'],
      ],
    },
    {
      factor: [i18next.t<string>('Work mask'), 'WM', 'MOST'],
      subfactors: [
        [i18next.t<string>('Dominance'), 'D'],
        [i18next.t<string>('Influence'), 'I'],
        [i18next.t<string>('Steadiness'), 'S'],
        [i18next.t<string>('Compliance'), 'C'],
      ],
    },
    {
      factor: [i18next.t<string>('Stress image'), 'STI', 'LEAST'],
      subfactors: [
        [i18next.t<string>('Dominance'), 'D'],
        [i18next.t<string>('Influence'), 'I'],
        [i18next.t<string>('Steadiness'), 'S'],
        [i18next.t<string>('Compliance'), 'C'],
      ],
    },
  ],
};

const makeThreeSixtyTriple = (code: string, name?: string) => [
  {
    name: i18next.t<string>('Self'),
    altHoverText: i18next.t<string>('Self'),
    columnName: name ? `${name} ` : i18next.t<string>('Self'),
    id: code,
    field: 'self_mean',
    isHideable: () => Boolean(name || code === 'summary'),
    sortKey: `factor:${code}:self_mean`,
  },
  {
    name: i18next.t<string>('Rater'),
    altHoverText: i18next.t<string>('Rater'),
    columnName: name ? `${name} ` : i18next.t<string>('Rater'),
    id: code,
    field: 'rater_mean',
    isHideable: () => Boolean(name || code === 'summary'),
    sortKey: `factor:${code}:rater_mean`,
  },
  {
    name: i18next.t<string>('S/R'),
    altHoverText: i18next.t<string>('Self/Rater difference'),
    columnName: name ? `${name} ` : i18next.t<string>('Self/Rater difference'),
    id: code,
    field: 'self_rater_difference',
    isHideable: () => Boolean(name || code === 'summary'),
    sortKey: `factor:${code}:self_rater_difference`,
  },
];

export const makeThreeSixtyColumnGroups = (
  primaryCompetencies: ReadonlyArray<PrimaryCompetency>
) =>
  THREE_SIXTY_DEMOGRAPHIC_COLUMN_GROUPS.concat([
    {
      name: i18next.t<string>('Overall Summary'),
      id: 'total',
      isExpandable: true,
      columns: [
        {
          name: i18next.t<string>('No.'),
          altHoverText: i18next.t<string>('No. raters completed'),
          columnName: i18next.t<string>('No. raters completed'),
          id: 'total',
          field: 'total_raters_completed',
          isHideable: () => false,
          sortKey: 'total_raters_completed',
        },
        {
          name: i18next.t<string>('Benc'),
          altHoverText: i18next.t<string>('Benchmark'),
          columnName: i18next.t<string>('Benchmark'),
          id: 'total',
          field: 'benchmark_mean_avg',
          isHideable: () => true,
          sortKey: 'benchmark_mean_avg',
        },
      ].concat(makeThreeSixtyTriple('summary')),
    },
  ]).concat(
    primaryCompetencies.map(({ name, code, secondary_competencies }) => ({
      name,
      id: code,
      isExpandable: true,
      columns: makeThreeSixtyTriple(code).concat(
        secondary_competencies.flatMap(secondary =>
          makeThreeSixtyTriple(secondary.code, secondary.name)
        )
      ),
    }))
  );

export const DATA_ANALYTICS_TABLE_STRUCTURE: DataAnalyticsTableStructure = {
  PSYCAP_POTENTIAL: DEMOGRAPHIC_COLUMN_GROUPS.concat(
    FACTORS_HIERARCHY.PSYCAP_POTENTIAL.map(
      ({ factor: [name, id, chartCode], subfactors }) => ({
        name,
        id,
        chartCode,
        isExpandable: subfactors.length > 0,
        columns: (subfactors.length === 0
          ? []
          : [
              {
                chartName: i18next.t<string>('PERCENTILE'),
                altHoverText: i18next.t<string>('Percentile'),
                name: i18next.t<string>('PERCENTILE'),
                id,
                field: 'score',
                isHideable: () => false,
                sortKey: `factor:${id}:score`,
              },
            ]
        )
          .concat([
            {
              chartName: i18next.t<string>('Sten'),
              altHoverText: i18next.t<string>('Sten'),
              name: chartCode,
              id,
              field: 'sten',
              isHideable: () => false,
              sortKey: `factor:${id}:sten`,
            },
          ])
          .concat(
            subfactors.map(([subfactorName, subfactorId]) => ({
              chartName: i18next.t<string>('Sten'),
              altHoverText: i18next.t<string>('{{name}} sten', {
                name: subfactorName,
              }),
              name: i18next.t<string>('{{name}} sten', { name: subfactorName }),
              id: subfactorId,
              field: 'sten',
              isHideable: () => true,
              sortKey: `factor:${subfactorId}:sten`,
            }))
          ),
      })
    )
  ).concat([
    {
      name: i18next.t<string>('Snapshot'),
      id: 'SNAPSHOT',
      isExpandable: false,
      chartCode: 'SN',
      columns: [
        {
          chartName: i18next.t<string>('PERCENTILE'),
          altHoverText: i18next.t<string>('Percentile'),
          name: i18next.t<string>('PERCENTILE'),
          id: 'SNAPSHOT',
          sortKey: 'extra:SNAPSHOT:score',
          field: 'score',
          isHideable: () => false,
          isExtra: true,
        },
        {
          chartName: i18next.t<string>('Sten'),
          altHoverText: i18next.t<string>('Sten'),
          name: 'SN',
          id: 'SNAPSHOT',
          sortKey: 'extra:SNAPSHOT:sten',
          field: 'sten',
          isHideable: () => false,
          isExtra: true,
        },
      ],
    },
  ]),
  POSITIVE_RESILIENCE_PROFILER: DEMOGRAPHIC_COLUMN_GROUPS.concat(
    FACTORS_HIERARCHY.POSITIVE_RESILIENCE_PROFILER.map(
      ({ factor: [name, id, chartCode] }) => ({
        name,
        id,
        chartCode,
        isExpandable: false,
        columns: [
          {
            chartName: i18next.t<string>('{{code}} percentile', {
              code: name,
            }),
            altHoverText: i18next.t<string>('Percentile'),
            name: i18next.t<string>('PERCENTILE'),
            id,
            field: 'score',
            isHideable: () => false,
            sortKey: `factor:${id}:score`,
          },
        ],
      })
    )
  ),
  DISC: DEMOGRAPHIC_COLUMN_GROUPS.concat(
    FACTORS_HIERARCHY.DISC.map(
      ({ factor: [name, id, codeLevel], subfactors }, factorIdx) => ({
        name,
        id,
        isExpandable: true,
        columns:
          subfactors.length === 0
            ? []
            : subfactors.map(([subfactorName, subfactorId], subIdx) => ({
                chartName: subfactorName,
                altHoverText: subfactorName,
                name: subfactorName,
                id: subfactorId,
                codeLevel: codeLevel,
                field: factorIdx === 0 && subIdx < 2 ? 'factor' : 'level',
                isExtra: factorIdx === 0 && subIdx < 2 ? true : false,
                isHideable: () => Boolean(subIdx !== 0),
                collapseSmall: factorIdx > 0 && subIdx === 0,
                sortKey:
                  factorIdx === 0 && subIdx < 2
                    ? `extraKey:${subfactorId}`
                    : `factor:${subfactorId}|${codeLevel}:score`,
              })),
      })
    )
  ),
};

export const DISC_TABLE_TWO_FACTORS_HIERARCHY = [
  {
    factor: [i18next.t<string>('Self image'), 'SI', 'CHANGE'],
    subfactors: [
      [
        i18next.t<string>('Style'),
        i18next.t<string>('Style'),
        'self_image_style_name',
      ],
      [
        i18next.t<string>('Pattern'),
        i18next.t<string>('Pattern'),
        'self_image_pattern_text',
      ],
      [
        i18next.t<string>('1st'),
        i18next.t<string>('Highest dimension'),
        'self_image_dimensions_order',
        '0',
      ],
      [
        i18next.t<string>('2nd'),
        i18next.t<string>('Second highest dimension'),
        'self_image_dimensions_order',
        '1',
      ],
      [
        i18next.t<string>('3rd'),
        i18next.t<string>('Third highest dimension'),
        'self_image_dimensions_order',
        '2',
      ],
      [
        i18next.t<string>('4th'),
        i18next.t<string>('Lowest dimension'),
        'self_image_dimensions_order',
        '3',
      ],
    ],
  },
];

export const DATA_ANALYTICS_DISC_TABLE_TWO_STRUCTURE: ReadonlyArray<ColumnGroup> = DEMOGRAPHIC_COLUMN_GROUPS.concat(
  DISC_TABLE_TWO_FACTORS_HIERARCHY.map(
    ({ factor: [name, id, codeLevel], subfactors }, factorIdx) => ({
      name,
      id,
      isExpandable: false,
      columns: subfactors.map(
        ([subfactorName, chartCode, subfactorId, order]) => ({
          chartName: subfactorName,
          altHoverText: chartCode,
          name: subfactorName,
          id: !order ? subfactorId : `${subfactorId}:${order}`,
          codeLevel: codeLevel,
          field: 'factor',
          isExtra: true,
          isHideable: () => false,
          sortKey:
            factorIdx === 0 && !order
              ? `extraKey:${subfactorId}`
              : `extraKey:${subfactorId}__${order}`,
        })
      ),
    })
  )
);
