import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

export const PulseTermsAndConditions = () => {
  return (
    <div className="col-xs-12 content-box col-lg-10 col-lg-offset-1 col-xl-8 col-xl-offset-2">
      <div className="row">
        <div className="col-xs-12">
          <h1>
            {i18next.t<string>('Behavioural Pulsing™ Terms and conditions')}
          </h1>
          <p>{i18next.t<string>('PARAGRAPH Pulse terms intro')}</p>
          <p>
            {i18next.t<string>('The User must read this document carefully.')}
          </p>

          <h2>{i18next.t<string>('Terms')}</h2>
          <p>
            {i18next.t<string>(
              'Behavioural pulsing is equivalent in name to Pulse'
            )}
          </p>
          <p>{i18next.t<string>('PARAGRAPH Pulse terms company reg')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse respondent')}</p>

          <h2>{i18next.t<string>('Pulsing process setup')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Pulse setup respondent')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse setup emails')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse setup data')}</p>

          <h2>{i18next.t<string>('Pulse subscription')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Pulse subscription features')}</p>
          <p>
            {i18next.t<string>('PARAGRAPH Pulse subscription payment start')}
          </p>
          <p>{i18next.t<string>('PARAGRAPH Pulse regular payments')}</p>

          <h2>{i18next.t<string>('Cancellation of subscriptions')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Pulse cancel individuals')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse cancel groups')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse cancel time frame')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse after cancel')}</p>
          <p>{i18next.t<string>('PARAGRAPH Pulse cancel error')}</p>

          <h2>{i18next.t<string>('Acceptance of terms and conditions')}</h2>
          <p>{i18next.t<string>('PARAGRAPH Pulse terms agreement')}</p>
          <Trans i18nKey="TRANS Pulse terms contact" parent="p">
            For any additional information about Behavioural Pulsing™ please
            contact{' '}
            <a href="mailto:enablesupport@peoplewise.co.uk">
              enablesupport@peoplewise.co.uk
            </a>
          </Trans>
        </div>
      </div>
    </div>
  );
};
