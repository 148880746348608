import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { MAX_PURCHASE_CREDITS } from '^/components/organisations/accounts/PurchaseCreditsForm';

const AccountBulkDiscountsTable = ({ discountBands }) => (
  <table>
    <thead>
      <tr>
        <th>{i18next.t('Credit payment type')}</th>
        <th>{i18next.t('From credit')}</th>
        <th>{i18next.t('To credit')}</th>
        <th>{i18next.t('Discount %')}</th>
      </tr>
    </thead>
    <tbody>
      {discountBands
        .zip(discountBands.slice(1).push(null))
        .map(([band, nextBand], idx) => (
          <tr key={idx}>
            <td>Single credit purchase</td>
            <td>{band.get('discount_from')}</td>
            <td>
              {nextBand
                ? nextBand.get('discount_from') - 1
                : MAX_PURCHASE_CREDITS}
            </td>
            <td>{band.get('percentage')}%</td>
          </tr>
        ))}
    </tbody>
  </table>
);

export default AccountBulkDiscountsTable;
