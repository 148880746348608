import classNames from 'classnames';
import React from 'react';

import SearchBarForm from './SearchBarForm';

interface OwnProps {
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formKey?: string;
  placeholder?: string;
}

type Props = OwnProps;

export const SearchBar: React.FunctionComponent<Props> = ({
  className,
  onChange,
  formKey,
  placeholder,
}) => (
  <div className={classNames('search', className)}>
    <SearchBarForm
      onChange={onChange}
      formKey={formKey}
      placeholder={placeholder}
    />
  </div>
);

export default SearchBar;
