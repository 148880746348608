import { routeReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import combineImmutableReducers from '^/combineImmutableReducers';
import * as apiReducers from '^/reducers/api';
import collections from '^/reducers/collections';
import composeReducers from '^/reducers/composeReducers';
import datafilters from '^/reducers/datafilters';
import form from '^/reducers/form';
import items from '^/reducers/items';
import * as reducers from '^/reducers/reducers';
import * as reports from '^/reducers/reports';
import { multiResponses, responses } from '^/reducers/responses';
import { clearAllOnLogout, stashOnSudo } from '^/reducers/rootStateReducers';
import * as jsUiReducers from '^/reducers/ui';
import * as tsUiReducers from '^/reducers/ui-ts';

const rootReducer = combineReducers(
  Object.assign(
    {
      routing: routeReducer,
      ui: combineImmutableReducers({ ...jsUiReducers, ...tsUiReducers }),
      form,
      items,
      responses,
      multiResponses,
      collections,
      datafilters,
    },
    apiReducers,
    reducers,
    reports
  )
);

export default composeReducers([rootReducer, clearAllOnLogout, stashOnSudo]);
