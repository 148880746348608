/* eslint-disable react/no-string-refs */
import React from 'react'; // eslint-disable-line no-unused-vars
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import i18next from 'i18next';

import PureComponent from './PureComponent';

export default class PictureUpload extends PureComponent {
  onDrop(files) {
    if (files.length) {
      this.props.setPendingUpload(files[0]);
    }
  }

  render() {
    const { src, file, noPadding, className } = this.props;

    const selectPictureMessage =
      this.props.selectPictureMessage || i18next.t('Select a picture');
    const changePictureMessage =
      this.props.changePictureMessage || i18next.t('Select a new picture');

    const style = {
      borderWidth: 1,
      borderColor: '#E2E4EB',
      borderStyle: 'dashed',
      borderRadius: 2,
      marginBottom: 8,
      padding: noPadding ? 0 : 8,
    };

    const activeStyle = Object.assign({}, style, {
      borderStyle: 'dashed',
      borderColor: '#1C75BC',
      backgroundColor: 'transparent',
    });
    const rejectStyle = Object.assign({}, style, {
      borderStyle: 'solid',
      borderColor: '#BE1E2D',
      backgroundColor: '#D87881',
    });

    return (
      <div className="picture-upload">
        <Dropzone
          className="dropzone"
          ref="dropzone"
          multiple={false}
          onDrop={this.onDrop.bind(this)}
          style={style}
          activeStyle={activeStyle}
          rejectStyle={rejectStyle}
        >
          {this.props.display(file ? file.preview : src)}

          <button
            type="button"
            className={classNames('btn btn-default upload-button', className)}
          >
            {src || file ? changePictureMessage : selectPictureMessage}
          </button>
        </Dropzone>

        {file && (
          <button
            type="button"
            className="btn btn-primary col-xs-12"
            onClick={() => this.props.onSubmit()}
          >
            {i18next.t('Upload picture')}
          </button>
        )}
      </div>
    );
  }
}
