import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { List } from 'immutable';
import i18next from 'i18next';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  shareAccessWithUser,
  revokeAccessFromUser,
  expandUiComponent,
  collapseUiComponent,
} from '^/actions/actions';
import { ACCOUNT_ADMIN_ROLE } from '^/models/organisation';
import PureComponent from '^/components/PureComponent';
import LoadingAndErrors from '^/components/LoadingAndErrors';
import SharingUsersList from '^/components/dashboard/admin/SharingUsersList';
import SelectUserInput from '^/components/forms/SelectUserInput';
import { closeTopModal, openConfirmationModal } from '^/actions/modals';

const SHARING_USERS_LIST = 'sharingUsersList';
const SHARING_ROLES = [
  ACCOUNT_ADMIN_ROLE.ACCOUNT_PROFESSIONAL_PRACTITIONER,
  ACCOUNT_ADMIN_ROLE.ACCOUNT_ORG_ADMIN,
];

export class SharedAccess extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.collapseUiComponent(SHARING_USERS_LIST);
  }

  confirmRevokeAccess(user) {
    this.props.openConfirmationModal(
      i18next.t('revoke'),
      i18next.t(`admin's sharing access`),
      [
        i18next.t(
          '{{full_name}} will no longer be able to view your activities',
          { full_name: user.get('full_name') }
        ),
      ],
      () => {
        this.props.revokeAccessFromUser(user.get('id'));
        this.props.closeTopModal();
      },
      this.props.closeTopModal,
      'btn-danger',
      null,
      i18next.t('Revoke access')
    );
  }

  render() {
    const { user, addResponse, removeResponse, showMoreSelected } = this.props;
    const { shared_with, shared_from } = user.toObject();
    const onToggleShowMore = showMoreSelected
      ? () => this.props.collapseUiComponent(SHARING_USERS_LIST)
      : () => this.props.expandUiComponent(SHARING_USERS_LIST);

    return (
      <div className="sharing-section">
        <div className="row">
          <div className="col-md-6">
            <div className="sharing-header">
              <h4 className="sharing-header-title">
                {i18next.t('Admins I share with')}:
              </h4>
              <SelectUserInput
                id="id"
                includeInactive
                organisationId={user.getIn(['organisation', 'id'])}
                additionalFilters={{ roles: SHARING_ROLES.join(',') }}
                doAdd={userId => this.props.shareAccessWithUser(userId)}
                excludedUserIds={shared_with
                  .push(user)
                  .map(each => each.get('id'))}
                existingOnly
                placeholder={i18next.t('Search for admins')}
              />
            </div>
            <div className="sharing-list">
              <SharingUsersList
                users={shared_with}
                removeUser={revokeUser => this.confirmRevokeAccess(revokeUser)}
                ifNone={i18next.t(
                  'You are not yet sharing access with anyone.'
                )}
                showMoreSelected={showMoreSelected}
                onToggleShowMore={onToggleShowMore}
              />
              <LoadingAndErrors
                responses={List([addResponse, removeResponse])}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="sharing-header">
              <h4 className="sharing-header-title">
                {i18next.t('Admins who share with me')}:
              </h4>
            </div>
            <div className="sharing-list">
              <SharingUsersList
                users={shared_from}
                hideRemove
                extraRender={eachUser => (
                  <Link
                    to={`/page/activities/view/shared=true&created_by_user=${eachUser.get(
                      'id'
                    )}`}
                    className="sharing-list-see-activities"
                  >
                    {i18next.t('View activities')}
                    <FontAwesomeIcon
                      icon={faClipboardList}
                      size="lg"
                      title={i18next.t('View activities')}
                    />
                  </Link>
                )}
                ifNone={i18next.t('No-one is sharing access with you.')}
                showMoreSelected={showMoreSelected}
                onToggleShowMore={onToggleShowMore}
              />
            </div>
          </div>
        </div>

        <p>
          {i18next.t('PARAGRAPH Note sharing access will allow other admin(s)')}
        </p>
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    addResponse: state.responses.get('shareAccessWithUser'),
    removeResponse: state.responses.get('revokeAccessFromUser'),
    showMoreSelected: state.ui
      .get('expandedUiComponents')
      .contains(SHARING_USERS_LIST),
  };
}

export default connect(mapStateToProps, {
  shareAccessWithUser,
  revokeAccessFromUser,
  openConfirmationModal,
  closeTopModal,
  expandUiComponent,
  collapseUiComponent,
})(SharedAccess);
