import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import {
  faUserCircle,
  faQuestionCircle,
} from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { loadActivity } from '^/actions/items';
import { closeTopModal, openWelcomeModal } from '^/actions/modals';
import { resetLanguageAndCloseTopModal } from '^/actions/actionSequences';
import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import WelcomePage from './WelcomePage';

const SECTIONS = [
  {
    icon: <FontAwesomeIcon icon={faQuestionCircle} />,
    header: (
      <Trans i18nKey="Section 1 REASONING">
        {'Section 1: '}
        <strong>REASONING</strong>
      </Trans>
    ),
    text: (
      /* eslint-disable max-len */
      <Trans i18nKey="Reasoning is designed to assess your ability to understand">
        {
          'Reasoning is designed to assess your ability to understand the relationships between abstract shapes and patterns. '
        }
        <strong>It is timed</strong>
        {' and you will have 30 minutes to complete it.'}
      </Trans>
      /* eslint-enable max-len */
    ),
  },
  {
    icon: <FontAwesomeIcon icon={faUserCircle} />,
    header: (
      <Trans i18nKey="Section 2 PREFERENCES">
        {'Section 2: '}
        <strong>PREFERENCES</strong>
      </Trans>
    ),
    text: (
      <Trans i18nKey="Preferences asks you some questions">
        Preferences asks you some questions about your interests and
        preferences. It should take you no more than 20 minutes to complete this
        section.
      </Trans>
    ),
  },
];

export class PsycapPotentialWelcomeModal extends PureComponent {
  render() {
    const {
      productVersionDetails,
      productVersion,
      activity,
      raterFor,
      user,
      uiLanguage,
    } = this.props;
    const welcomeMessageTitle = productVersion.get('welcome_message_title');

    if (!productVersionDetails) {
      return <Loading />;
    }

    return (
      <WelcomePage
        welcomeMessageTitle={welcomeMessageTitle}
        sections={SECTIONS}
        onCancel={() => this.props.resetLanguageAndCloseTopModal(uiLanguage)}
        onContinue={this.props.openWelcomeModal.bind(
          null,
          productVersion,
          activity,
          raterFor,
          productVersionDetails.getIn(['questioncollection_set', 0])
        )}
        productVersion={productVersion}
        user={user}
        respondent={user}
        isSelfRating={!raterFor}
        continueLabel={i18next.t('Next')}
        hideSectionName
        useProductVersionCompletionTime
      />
    );
  }
}

export function mapStateToProps(state, props) {
  const activityDetails = state.items.get('activities');
  return {
    user: state.userProfile,
    productVersionDetails:
      activityDetails &&
      activityDetails
        .get('product_versions', List())
        .find(
          productVersion =>
            productVersion.get('id') === props.productVersion.get('id')
        ),
    uiLanguage: state.ui.get('uiLanguage'),
  };
}

export default connect(mapStateToProps, {
  loadActivity,
  closeTopModal,
  openWelcomeModal,
  resetLanguageAndCloseTopModal,
})(PsycapPotentialWelcomeModal);
