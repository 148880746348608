import i18next from 'i18next';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';

import { openChooseShopItemVerificationTypeModal } from '^/actions/modals';
import { sendEnquiryEmail } from '^/actions/shop';
import { interactWithShop } from '^/capabilities';
import Button from '^/components/buttons/Button';
import { ShopItem } from '^/reducers/api/types';
import { selectUserCapability } from '^/selectors/user';
import { StoreState } from '^/store';

interface OwnProps {
  item: ShopItem;
}

interface DispatchProps {
  openChooseShopItemVerificationTypeModal: typeof openChooseShopItemVerificationTypeModal;
  sendEnquiryEmail: typeof sendEnquiryEmail;
}

interface StateProps {
  userCanInteractWithShop: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

export const ShopItemComponent: React.FunctionComponent<Props> = props => {
  const { item, userCanInteractWithShop } = props;
  const ItemButton = () => {
    if (item.options.length > 0) {
      const addToBasket = () =>
        props.openChooseShopItemVerificationTypeModal(item);
      return (
        <Button onClick={addToBasket} disabled={!userCanInteractWithShop}>
          {i18next.t<string>('Buy now')}
        </Button>
      );
    }
    const enquireNow = () => props.sendEnquiryEmail(item.id);
    return (
      <Button onClick={enquireNow} disabled={!userCanInteractWithShop}>
        {i18next.t<string>('Enquire now')}
      </Button>
    );
  };

  return (
    <div className="shop-item-wrapper col-xs-4">
      <div className="shop-item">
        <div>
          <img
            className="shop-image"
            // TODO: Remove the placeholder
            src={item.image || 'https://via.placeholder.com/400x200'}
          />
          <h4>{item.name}</h4>
          <hr />
          <ReactMarkdown source={item.description} />
        </div>
        <div>
          <ItemButton />
        </div>
      </div>
    </div>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    userCanInteractWithShop: selectUserCapability(state, interactWithShop()),
  };
}

export default connect(mapStateToProps, {
  openChooseShopItemVerificationTypeModal,
  sendEnquiryEmail,
})(ShopItemComponent);
