import React from 'react'; // eslint-disable-line no-unused-vars
import { List, Set } from 'immutable';
import { connect } from 'react-redux';
import i18next from 'i18next';

import {
  toggleSelection,
  clearSelection,
  setSelection,
} from '^/actions/actions';
import { closeTopModal } from '^/actions/modals';
import Alert from '^/components/Alert';
import PureComponent from '^/components/PureComponent';
import SelectGroupsPage from '^/components/groups/SelectGroupsPage';

export class AddGroupsModal extends PureComponent {
  UNSAFE_componentWillMount() {
    this.props.clearSelection('groups');
  }

  cancel() {
    this.props.closeTopModal();
  }

  submit(selectedUserIds) {
    this.props.closeTopModal();
    this.props.onComplete(
      this.props.selectedGroupIds,
      selectedUserIds,
      Boolean(this.props.selectedGroupIds.count() > 0)
    );
  }

  renderSubmitButton(selectedUserIds) {
    const numGroups = this.props.selectedGroupIds.count();
    return (
      <button
        disabled={numGroups === 0}
        className="btn btn-primary"
        onClick={() => this.submit(selectedUserIds)}
      >
        {numGroups === 0 ? i18next.t('Add selected groups') : ''}
        {numGroups > 0
          ? `${
              numGroups > 1
                ? i18next.t('Add {{numGroups}} selected groups', { numGroups })
                : i18next.t('Add {{numGroups}} selected group', { numGroups })
            }`
          : ''}
      </button>
    );
  }

  isGroupDisabled(group, spareUsers, currentAndSelectedUserIds) {
    // Don't disable something which is selected
    if (this.props.selectedGroupIds.contains(group.get('id'))) {
      return false;
    }

    const usersToAdd = group
      .get('users')
      .toSet()
      .subtract(currentAndSelectedUserIds);

    // If the entire group is selected, there's no point selecting it again
    if (usersToAdd.isEmpty()) {
      return true;
    }

    return usersToAdd.count() > spareUsers;
  }

  render() {
    const {
      filter,
      hideAccounts,
      maxNumMoreUsers,
      selectedGroupIds,
      groups,
      currentUserIds,
    } = this.props;

    const selectedGroups = groups.filter(group =>
      selectedGroupIds.contains(group.get('id'))
    );
    const selectedUserIds = selectedGroups
      .flatMap(group => group.get('users'))
      .toSet();

    const currentAndSelectedUserIds = selectedUserIds.union(currentUserIds);

    const spareUsers =
      maxNumMoreUsers === null || maxNumMoreUsers === undefined
        ? Infinity
        : maxNumMoreUsers - currentAndSelectedUserIds.count();

    const totalUserIds = groups
      .flatMap(group => group.get('users'))
      .toSet()
      .count();

    return (
      <div>
        {spareUsers !== Infinity && (
          <Alert>
            {i18next.t(
              'You may add groups with less than {{spareUsers}} respondents in total',
              { spareUsers }
            )}
          </Alert>
        )}
        <SelectGroupsPage
          filter={filter}
          onSelect={group =>
            this.props.toggleSelection('groups', group.get('id'))
          }
          onSetSelected={(group, selected) =>
            this.props.setSelection('groups', group.get('id'), selected)
          }
          onClearAll={() => this.props.clearSelection('groups')}
          isSelectable
          hideAccounts={hideAccounts}
          isGroupDisabled={group =>
            this.isGroupDisabled(group, spareUsers, currentAndSelectedUserIds)
          }
          disableSelectAll={totalUserIds > spareUsers}
        />
        <div className="modal-footer clearfix">
          <div className="pull-right">
            <button className="btn btn-default" onClick={() => this.cancel()}>
              {i18next.t('Cancel')}
            </button>
            {this.renderSubmitButton(selectedUserIds)}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedGroupIds: state.ui.get('selectedUiComponents').get('groups', Set()),
    groups: state.collections.getIn(['groups', 'items'], List()),
  };
}

export default connect(mapStateToProps, {
  toggleSelection,
  clearSelection,
  setSelection,
  closeTopModal,
})(AddGroupsModal);
