import i18next from 'i18next';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { openModal } from '^/actions/modals';
import PageHeader from '^/components/PageHeader';
import ExampleForm from './ExampleForm';

interface DispatchProps {
  openModal: typeof openModal;
}

type Props = DispatchProps;

export class FormLayout extends PureComponent<Props> {
  public openAsModal = () => {
    this.props.openModal({
      title: 'Modal form',
      body: <ExampleForm />,
    });
  };

  public render() {
    return (
      <div>
        <PageHeader>
          Form example with all different inputs
          <button className="btn margin-none" onClick={this.openAsModal}>
            Open as modal
          </button>
        </PageHeader>

        <div className="form-container">
          <ExampleForm formInline />
        </div>

        <PageHeader>Form layout examples (mostly deprecated)</PageHeader>

        <div className="form-container">
          <h5 className="heading-underlined">
            Example form (inline at the form element level uses a
            "form-group-inline" classed div wrapping the label and form-item
            div)
          </h5>
          <form>
            <div className="form-group-inline">
              <label htmlFor="name">{i18next.t<string>('Activity name')}</label>
              <div className="form-item stacked">
                <input type="text" id="name" name="name" placeholder="foo" />
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>

            <div className="form-group-inline">
              <div className="form-label">
                {i18next.t<string>('Activity sent via')}
              </div>
              <div className="form-item stacked">
                <div className="form-old-radiobutton-wrapper">
                  <label className="inline margin-right">
                    <input type="radio" />
                    Foo
                  </label>
                  <label className="inline">
                    <input type="radio" />
                    Bar
                  </label>
                </div>
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>
          </form>
        </div>

        <div className="form-container">
          <h5 className="heading-underlined">
            Example form (label above at the form element level uses a
            "form-group-block" classed div wrapping the label and form-item div)
          </h5>
          <form>
            <div className="form-group-block">
              <label htmlFor="name1">
                {i18next.t<string>('Activity name')}
              </label>
              <div className="form-item">
                <input type="text" name="name" id="name1" placeholder="foo" />
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>

            <div className="form-group-block">
              <div className="form-label">
                {i18next.t<string>('Activity sent via')}
              </div>
              <div className="form-item">
                <div>
                  <label className="inline margin-right">
                    <input type="radio" />
                    Foo
                  </label>
                  <label className="inline">
                    <input type="radio" />
                    Bar
                  </label>
                </div>
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>
          </form>
        </div>

        <div className="form-container">
          <h5 className="heading-underlined">
            Example form (label above specified at the form level - allowing you
            to not have to use classes at the input level, uses form
            className="form-block")
            <a className="align-right text-transform-none">Optional link</a>
          </h5>
          <form className="form-block">
            <div>
              <label>{i18next.t<string>('Activity name')}</label>
              <div className="form-item">
                <input type="text" name="name" placeholder="foo" />
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>

            <div>
              <div className="form-label">
                {i18next.t<string>('Activity sent via')}
              </div>
              <div className="form-item">
                <div>
                  <label className="inline margin-right">
                    <input type="radio" />
                    Foo
                  </label>
                  <label className="inline">
                    <input type="radio" />
                    Bar
                  </label>
                </div>
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>
          </form>
        </div>

        <div className="form-container">
          <h5 className="heading-underlined">
            Example form (label inline specified at the form level - allowing
            you to not have to use classes at the form input level uses form
            className="form-inline")
          </h5>
          <form className="form-inline">
            <div>
              <label htmlFor="name">{i18next.t<string>('Activity name')}</label>
              <div className="form-item stacked">
                <input type="text" id="name" name="name" placeholder="foo" />
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>

            <div>
              <div className="form-label">
                {i18next.t<string>('Activity sent via')}
              </div>
              <div className="form-item stacked">
                <div className="form-old-radiobutton-wrapper">
                  <label className="inline margin-right">
                    <input type="radio" />
                    Foo
                  </label>
                  <label className="inline">
                    <input type="radio" />
                    Bar
                  </label>
                </div>
                <p className="text-error">
                  This is an error from FormError component
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(null, { openModal })(FormLayout);
