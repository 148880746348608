import { isRSAA, CALL_API } from 'redux-api-middleware';

import {
  showAutoLogoutOverlay,
  HIDE_AUTO_LOGOUT_OVERLAY,
  LOAD_ORG_SESSIONS_RECENT,
} from '^/actions/actions';
import { DISABLE_AUTO_LOGOUT } from '^/settings';

const LOGOUT_PROMPT_TIMEOUT_MILLISECONDS = 30 * 60 * 1000; // 30 minutes
let logoutPromptTimer;

const ACTION_WHITELIST = [LOAD_ORG_SESSIONS_RECENT.REQUEST];

function isAWhitelistedAction(action) {
  return (
    action &&
    action[CALL_API].types.some(rsaaAction =>
      ACTION_WHITELIST.includes(rsaaAction.type)
    )
  );
}

function isUserLoggedIn(store) {
  return !!store.getState().user.get('token');
}

export default store => next => action => {
  if (!DISABLE_AUTO_LOGOUT) {
    if (
      (isRSAA(action) && !isAWhitelistedAction(action)) ||
      action.type === HIDE_AUTO_LOGOUT_OVERLAY
    ) {
      clearTimeout(logoutPromptTimer);
      if (isUserLoggedIn(store)) {
        logoutPromptTimer = setTimeout(
          () => store.dispatch(showAutoLogoutOverlay()),
          LOGOUT_PROMPT_TIMEOUT_MILLISECONDS
        );
      }
    }
  }
  return next(action);
};
