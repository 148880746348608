import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { addToBasketAndNavigateToBasket } from '^/actions/actionSequences';
import Currency from '^/components/shop/basket/Currency';
import { ShopItemOption, ShopItemOptionType } from '^/reducers/api/types';

interface OwnProps {
  option: ShopItemOption;
}

interface DispatchProps {
  addToBasketAndNavigateToBasket: typeof addToBasketAndNavigateToBasket;
}

type Props = DispatchProps & OwnProps;

export const CostAndAddToBasket: React.FunctionComponent<Props> = props => {
  const { option } = props;
  const addToBasket = (shopItemOption: ShopItemOption) =>
    props.addToBasketAndNavigateToBasket(shopItemOption.id);

  const hasCost = option.cost !== null;
  const isPureProduct =
    option.type === ShopItemOptionType.Product &&
    option.product_variant === null;
  const hasLinkUrl = option.link_url;
  const showCostInModal = isPureProduct ? false : hasCost;
  const showAddToBasketLink = !hasLinkUrl && (hasCost || isPureProduct);

  return (
    <div className="mt-sm mb-md">
      {showCostInModal && option.cost !== null && (
        <span className="semi-bold-text mr-md">
          {i18next.t<string>('Cost')} <Currency value={option.cost} /> +{' '}
          {i18next.t<string>('VAT')}
        </span>
      )}
      {showAddToBasketLink && (
        <a
          onClick={addToBasket.bind(null, option)}
          role="button"
          aria-label="Add to basket"
        >
          {i18next.t<string>('Add to basket')}
        </a>
      )}
      {hasLinkUrl && (
        <a href={option.link_url!} target="_blank">
          {i18next.t<string>('Book now')}
        </a>
      )}
    </div>
  );
};

export default connect(null, {
  addToBasketAndNavigateToBasket,
})(CostAndAddToBasket);
