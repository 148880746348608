import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import Loading from '^/components/Loading';
import { isPending } from '^/responseStates';
import { getBulkDiscountBandCollections } from '^/actions/collections';
import { openConfirmAndUpdateBandCollectionModal } from '^/actions/modals';
import AccountBulkDiscountsTable from './AccountBulkDiscountsTable';
import RadioButton from '^/components/RadioButton';

const DISCOUNT_BANDS = 'DISCOUNT_BANDS';

export class EditAccountDiscounts extends PureComponent {
  componentDidMount() {
    this.props.getBulkDiscountBandCollections();
  }

  confirmAndUpdateBandCollection(bandCollection) {
    this.props.openConfirmAndUpdateBandCollectionModal(
      this.props.account,
      bandCollection
    );
  }

  render() {
    const {
      account,
      isLoading,
      isUpdating,
      bulkDiscountBandCollections,
      readOnly,
    } = this.props;
    const currentBandCollectionId = account.getIn([
      'bulk_discount_band_collection',
      'id',
    ]);

    if (isLoading || !bulkDiscountBandCollections) {
      return <Loading />;
    }

    if (readOnly) {
      const currentBandCollection = bulkDiscountBandCollections.find(
        each => each.get('id') === currentBandCollectionId
      );
      return (
        <div>
          {currentBandCollection ? (
            <AccountBulkDiscountsTable
              discountBands={currentBandCollection.get('bulkdiscountband_set')}
            />
          ) : (
            <label className="semi-bold-text">
              {i18next.t('No discount')}.
            </label>
          )}
        </div>
      );
    }

    return (
      <div>
        <RadioButton
          id={DISCOUNT_BANDS}
          name={DISCOUNT_BANDS}
          checked={!currentBandCollectionId}
          onChange={() => this.confirmAndUpdateBandCollection(null)}
          disabled={isUpdating}
          label={i18next.t('No discount')}
          inline
        />

        {bulkDiscountBandCollections.map((each, idx) => (
          <div key={idx}>
            <RadioButton
              id={each.get('id')}
              name={DISCOUNT_BANDS}
              checked={each.get('id') === currentBandCollectionId}
              onChange={() => this.confirmAndUpdateBandCollection(each)}
              disabled={isUpdating}
              label={each.get('name')}
              inline
            />
            <AccountBulkDiscountsTable
              discountBands={each.get('bulkdiscountband_set')}
            />
          </div>
        ))}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    bulkDiscountBandCollections: state.collections.getIn([
      'bulkDiscountBandCollections',
      'items',
    ]),
    isLoading: isPending(
      state.multiResponses.getIn([
        'getCollection',
        'bulkDiscountBandCollections',
      ])
    ),
    isUpdating: isPending(state.responses.get('updateItem')),
  };
}

export default connect(mapStateToProps, {
  getBulkDiscountBandCollections,
  openConfirmAndUpdateBandCollectionModal,
})(EditAccountDiscounts);
