import i18next from 'i18next';
import { Map } from 'immutable';

import { StatusPillProps, StatusType } from '^/components/StatusPill';

export const ACTIVITY_STATUS = {
  SETUP: 'SETUP',
  LIVE: 'LIVE',
  ENDED: 'ENDED',
  SUSPENDED: 'SUSPENDED',
};

const STATUS_PILL_PROP_MAP = {
  [ACTIVITY_STATUS.SETUP]: {
    type: StatusType.Info,
    status: i18next.t<string>('Setup'),
  },
  [ACTIVITY_STATUS.LIVE]: {
    type: StatusType.Success,
    status: i18next.t<string>('Live'),
  },
  [ACTIVITY_STATUS.ENDED]: {
    type: StatusType.Danger,
    status: i18next.t<string>('Closed'),
  },
  [ACTIVITY_STATUS.SUSPENDED]: {
    type: StatusType.Disabled,
    status: i18next.t<string>('Suspended'),
  },
};

export const ACTIVITY_FILTERS = Object.assign({}, ACTIVITY_STATUS, {
  ENDING_SOON: 'ENDING_SOON',
});

export function getStatus(activity: Map<string, any>) {
  return activity.get('status', ACTIVITY_STATUS.SETUP);
}

export function getStatusPillProps(
  activity: Map<string, any>
): StatusPillProps {
  return STATUS_PILL_PROP_MAP[getStatus(activity)];
}

export function hasCompletedProductVersion(productVersion: Map<string, any>) {
  return Boolean(
    productVersion.getIn(
      ['activity_product_version_session', 'completed'],
      false
    )
  );
}

function hasCompletedAllProductVersions(productVersions: any) {
  if (!productVersions || typeof productVersions.every !== 'function') {
    return false;
  }
  return (
    productVersions.count() > 0 &&
    productVersions.every(hasCompletedProductVersion)
  );
}

export function isComplete(activity: Map<string, any>) {
  return hasCompletedAllProductVersions(activity.get('product_versions'));
}

export function isLive(activity: Map<string, any>) {
  return activity.get('status') === ACTIVITY_STATUS.LIVE;
}

export function isThreeSixtyTask(task: Map<string, any>) {
  return task.getIn(['activity', 'type']) === 'THREE_SIXTY';
}
