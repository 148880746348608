import i18next from 'i18next';
import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';

import {
  getAdvancedAnalyticsFilterProfileDetail,
  getAnalyticsSessionsCount,
} from '^/actions/analytics';
import Loading from '^/components/Loading';
import {
  AnalyticsAppliedFilters,
  AnalyticsProduct,
  FilterProfileDetail,
  Uuid,
} from '^/reducers/api/types';
import PercentileWheel from './PercentileWheel';
import {
  getAdvancedAnalyticsSessionCount,
  getFactors,
  getFilterValueDisplay,
} from '../utils';
import { administerOrganisations, can } from '^/capabilities';
import { StoreState } from '^/store';

interface Factor {
  id: string;
  name: string;
}

interface OwnProps {
  profileId: Uuid | undefined;
  product: AnalyticsProduct;
  initialCount: number;
  analyticsFilters: AnalyticsAppliedFilters;
  setProfileAndRenderEdit: (profile: FilterProfileDetail) => void;
  onCancel: () => void;
}

interface DispatchProps {
  getAdvancedAnalyticsFilterProfileDetail: typeof getAdvancedAnalyticsFilterProfileDetail;
  getAnalyticsSessionsCount: typeof getAnalyticsSessionsCount;
}

interface StateProps {
  canChooseOrg: boolean;
}

interface State {
  profile: FilterProfileDetail | undefined;
  error: any;
  count: number;
}

export type Props = OwnProps & DispatchProps & StateProps;

export class ProfileDetailPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getFactorDisplay = this.getFactorDisplay.bind(this);
    this.renderFilterRow = this.renderFilterRow.bind(this);
    this.state = {
      profile: undefined,
      error: undefined,
      count: 0,
    };
  }

  componentDidMount() {
    this.props.profileId &&
      (this.props.getAdvancedAnalyticsFilterProfileDetail(
        this.props.profileId
      ) as any).then((response: any) => {
        response.meta.success
          ? this.setState({ profile: response.payload })
          : this.setState({ error: response.payload });
      });
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const { profile } = this.state;
    if (!_.isEqual(prevState.profile, profile) && profile) {
      getAdvancedAnalyticsSessionCount(
        profile.filters,
        this.props.analyticsFilters,
        this.props.getAnalyticsSessionsCount,
        count => this.setState({ count })
      );
    }
  }

  renderFilterRow(filter: string, factors: Factor[]) {
    const [attribute, factor, from, to] = filter.split(':');
    return (
      <>
        <p>{this.getFactorDisplay(factor, factors)}</p>
        <p>{attribute}</p>
        <p>{getFilterValueDisplay(this.props.product, from, to, attribute)}</p>
      </>
    );
  }

  getFactorDisplay(factorCode: string, factors: Factor[]) {
    const foundFactor = factors.find(factor => factor.id === factorCode);
    return foundFactor?.name || factorCode;
  }

  render() {
    const { profile, error, count } = this.state;
    const { product, initialCount, setProfileAndRenderEdit } = this.props;

    if (profile) {
      const factors = getFactors(product);

      return (
        <div className="profile-detail-page">
          <div className="top">
            <div className="details-form readonly">
              <h1>{profile.name}</h1>
              {this.props.canChooseOrg && <p>{profile.organisation?.name}</p>}
              <p>{profile.created_by.full_name}</p>
            </div>
            <PercentileWheel count={count} total={initialCount} />
          </div>
          <div className="filters-readonly">
            {profile.filters.map((filter, index) => (
              <div key={index} className="filter-row">
                {this.renderFilterRow(filter, factors)}
              </div>
            ))}
          </div>
          <div className="modal-footer clearfix">
            <div className="pull-right">
              <button className="btn btn-default" onClick={this.props.onCancel}>
                {i18next.t<string>('Cancel')}
              </button>
              {!profile.shared_with_me && (
                <button
                  className="btn btn-primary"
                  onClick={() => setProfileAndRenderEdit(profile)}
                >
                  {i18next.t<string>('Edit')}
                </button>
              )}
            </div>
          </div>
        </div>
      );
    } else if (error) {
      return <p className="text-error">{error}</p>;
    }
    return <Loading />;
  }
}

function mapStateToProps(state: StoreState) {
  const user = state.userProfile;

  return {
    canChooseOrg: can(user, administerOrganisations()),
  };
}

export default connect(mapStateToProps, {
  getAdvancedAnalyticsFilterProfileDetail,
  getAnalyticsSessionsCount,
})(ProfileDetailPage);
