import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form } from 'formik';

import PureComponent from '../PureComponent';
import FormError from '../FormError';
import FormMessage from '../FormMessage';
import FileUpload from '../FileUpload';
import ProgressBar from '../ProgressBar';
import SelectOrganisation from '../SelectOrganisation';
import * as responseStates from '../../responseStates';
import Alert from '^/components/Alert';

export class ImportUsersForm extends PureComponent {
  render() {
    const { onSubmit, response } = this.props;
    const responseState = response ? response.get('state') : null;

    return (
      <Formik
        initialValues={{ organisationId: '', users: '' }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="form-block import-users">
            <Alert className="mb-none">
              {i18next.t('This requires user data to be saved as a CSV file.')}
              <br />
              {i18next.t('Data must be structured as in this template')}
              <a target="_blank" href="/static/csv/user-import-template.csv">
                user-import-template.csv
              </a>
            </Alert>

            {this.props.groupId ? (
              <p>
                You may import new and existing users to the group. {''}
                Any new users will also be added to your account.
              </p>
            ) : (
              <SelectOrganisation
                value={values.organisationId}
                name="organisationId"
                collectionName="organisations-list-create-user"
                onChange={value => setFieldValue('organisationId', value)}
              >
                <FormError response={response} formKey="organisation" />
              </SelectOrganisation>
            )}

            <div>
              <label>{i18next.t('CSV File')}</label>
              <div className="form-item">
                <FileUpload
                  accept=".csv, application/csv, text/csv"
                  value={values.users}
                  setPendingUpload={file => {
                    setFieldValue('users', file);
                  }}
                  onSubmit={onSubmit}
                />

                <FormError
                  response={response}
                  formKey="users"
                  renderError={errors =>
                    typeof errors === 'string'
                      ? errors
                      : errors.map(error => (
                          <span key={error.get('line') + error.get('text')}>
                            <span>{`Error: ${error.get(
                              'text'
                            )} at line ${error.get('line')}`}</span>
                            <br />
                          </span>
                        ))
                  }
                />

                <FormMessage
                  response={response}
                  formKey="messages"
                  renderMessage={messages =>
                    typeof messages === 'string'
                      ? messages
                      : messages.map(message => (
                          <span key={message.get('line') + message.get('text')}>
                            <span>{`Info: ${message.get(
                              'text'
                            )} at line ${message.get('line')}`}</span>
                            <br />
                          </span>
                        ))
                  }
                />
              </div>
            </div>

            <div className="modal-footer clearfix">
              {responseState === responseStates.SUCCESSFUL ? (
                <div>
                  {this.props.progress < 100 && (
                    <p className="margin-none">
                      {i18next.t('Import is in progress...')}
                    </p>
                  )}
                  <ProgressBar percent={this.props.progress} />
                  {this.props.progress === 100 && (
                    <div>
                      <p className="margin-none">
                        {i18next.t('Import is complete.')}
                      </p>
                      <div className="pull-right">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.props.onClose}
                        >
                          {i18next.t('Done')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="pull-right">
                    <button
                      className="btn"
                      type="button"
                      onClick={this.props.onClose}
                    >
                      {i18next.t('Cancel')}
                    </button>
                    <button className="btn btn-primary" type="submit">
                      {responseState === responseStates.FAILED
                        ? i18next.t('Retry import')
                        : i18next.t('Start import')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ImportUsersForm;
