import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import FormError from '^/components/FormError';
import LiveButton from '^/components/LiveButton';

export const AddBulkDiscountBandForm = props => {
  const { onSubmit, response } = props;

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values }) => (
        <Form className="form-horizontal">
          <div className="centered">
            <label htmlFor="name">
              {i18next.t('Collection name')}
              <span className="help-inline">
                {i18next.t(' must be unique')}
              </span>
              <Field type="text" id="name" name="name" />
            </label>
            <FormError response={response} formKey="name" />

            <div className="modal-footer clearfix">
              <div className="pull-right">
                <button
                  className="btn btn-default"
                  type="button"
                  onClick={() => this.props.onClose()}
                >
                  {i18next.t('Cancel')}
                </button>
                <LiveButton
                  disabled={!values.name}
                  buttonText={i18next.t('Create discount')}
                  pendingMessage={i18next.t('Creating discount...')}
                  response={response}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddBulkDiscountBandForm;
