import i18next from 'i18next';
import React, { Key } from 'react';
import { connect } from 'react-redux';

import { PulseUserRow, PulseUserRows } from './PulsePage';
import { PulseSubscriptionDetails, Uuid } from '^/reducers/api/types';
import { openStartPulsingModal } from '^/actions/modals';
import { ContainerRectangleTile } from '../ContainerRectangleTile';
import { BottomActionBar } from '../BottomActionBar';
import {
  InteractiveTable,
  PaginatedResultsResponse,
  FilterOptions,
  Selections,
  FiltersAndOrdering,
} from '../InteractiveTable';
import PulseIconCircleWhite from '../PulseIconCircleWhite';
import { deepCopy } from '^/utils';

export interface assessmentFilters {
  assessmentStatus: Array<{ [key: string]: string }>;
}

interface DispatchProps {
  openStartPulsingModal: typeof openStartPulsingModal;
}

interface OwnProps {
  pulseRows: PulseUserRows | undefined;
  pulseSubscriptionDetails: PulseSubscriptionDetails;
  pulseId: Uuid;
  reloadPulse: () => void;
}

interface State {
  selectedRows: PulseUserRow[] | undefined;
  selectAllDisabled: boolean;
  filteredRowData: PaginatedResultsResponse<{
    [key: string]: string;
  }> | null;
  rowData:
    | PaginatedResultsResponse<{
        [key: string]: string;
      }>
    | undefined;
  filters: assessmentFilters | undefined;
  filterOptions: FiltersAndOrdering;
  checkBoxOptions: ReadonlyArray<Selections> | undefined;
  selectedAssessment: string | null;
  allSelectableIds: ReadonlyArray<string> | undefined;
}

const initialFilterOptions = {
  sorting: {
    key: 'fullName',
    reverse: false,
  },
  page: 1,
};

type Props = OwnProps & DispatchProps;
export class PulseTable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRows: [],
      selectAllDisabled: false,
      rowData: undefined,
      filteredRowData: null,
      filters: undefined,
      filterOptions: initialFilterOptions,
      checkBoxOptions: undefined,
      selectedAssessment: null,
      allSelectableIds: undefined,
    };
  }

  startSelected = (users: PulseUserRow[]) => {
    this.props.openStartPulsingModal(
      users,
      this.props.pulseSubscriptionDetails,
      this.props.reloadPulse,
      this.props.pulseId
    );
  };

  private getFilters() {
    const filterKey = 'assessmentStatus';
    const filters =
      this.props.pulseRows &&
      this.props.pulseRows.map(item => ({
        label: item[filterKey as keyof assessmentFilters]?.toString(),
        key: item[filterKey as keyof assessmentFilters],
      }));

    const uniqueBy = (k: string, s = new Set()) => (o: {
        [x: string]: string;
      }) => !s.has(o[k]) && s.add(o[k]),
      result = filters?.filter(uniqueBy('key'));

    result &&
      this.setState({ filters: { assessmentStatus: result } }, () =>
        this.initialCheckBoxSelections()
      );
  }

  private initialCheckBoxSelections() {
    const { rowData } = this.state;
    const allSelectable = rowData?.results
      .filter(
        item => item['reportStatus' as keyof Key].toString() === 'Generated'
      )
      .map(item => item.userId);
    const selections = rowData?.results.map(item => ({
      id: item['userId' as keyof Key].toString(),
      checked: false,
      selectable: item['reportStatus' as keyof Key].toString() === 'Generated',
    }));
    this.setState({
      checkBoxOptions: deepCopy(selections),
      selectAllDisabled: this.getSelectAllDisableState(selections),
      selectedRows: [],
      allSelectableIds: allSelectable,
    });
  }
  private getSelectAllDisableState = (
    selections: readonly Selections[] | undefined
  ) => {
    return Boolean(
      !selections ||
        selections.filter(item => item.selectable === true).length < 1
    );
  };

  private onCheckBoxSelection = (
    checked: boolean,
    row: { key: string | number; value: string }[] | null,
    selectAll: boolean
  ) => {
    if (selectAll) {
      const selectedRows = this.state.rowData?.results.filter(
        item => this.state.allSelectableIds?.indexOf(item.userId) !== -1
      );
      this.setState(
        prevState => {
          return {
            ...prevState,

            selectedRows: checked ? (selectedRows as any) : [],
          };
        },
        () => this.updateCheckBoxSelections(true, checked, null)
      );
    } else {
      const rowBlock: any = {};
      if (row) {
        for (let i = 0; i < row.length; i++)
          rowBlock[row[i].key] = row[i].value;
      }
      let addRemoveSingleRow = this.state.selectedRows;
      if (checked) {
        row && addRemoveSingleRow && addRemoveSingleRow.length
          ? (addRemoveSingleRow[addRemoveSingleRow.length] = rowBlock)
          : (addRemoveSingleRow = [rowBlock]);
      } else {
        addRemoveSingleRow = addRemoveSingleRow?.filter(
          item => item.userId.toString() !== (row && row[0].value)
        );
      }

      this.setState(
        prevState => {
          return {
            ...prevState,

            selectedRows: addRemoveSingleRow,
          };
        },
        () => this.updateCheckBoxSelections(false, checked, row && row[0].value)
      );
    }
  };

  private updateCheckBoxSelections(
    selectAll: boolean,
    checked: boolean,
    id?: string | null
  ) {
    const { checkBoxOptions, selectedRows } = this.state;
    let updateSelections = checkBoxOptions;
    let updateSelectedRows = selectedRows;

    if (selectAll) {
      if (checked) {
        updateSelections = checkBoxOptions?.map(item => ({
          id: item['id' as keyof Key].toString(),
          checked: Boolean(
            item['selectable' as keyof Key].toString() === 'true'
          ),
          selectable: Boolean(item['selectable' as keyof Key]),
        }));
      }
      if (!checked) {
        updateSelections = checkBoxOptions?.map(item => ({
          id: item['id' as keyof Key].toString(),
          checked: false,
          selectable: Boolean(item['selectable' as keyof Key]),
        }));
        updateSelectedRows = [];
      }
    } else {
      updateSelections = checkBoxOptions?.map(item => ({
        id: item['id' as keyof Key].toString(),
        checked: checked
          ? item['id' as keyof Key].toString() === id
            ? true
            : Boolean(item['checked' as keyof Key])
          : item['id' as keyof Key].toString() === id
          ? false
          : Boolean(item['checked' as keyof Key]),
        selectable: Boolean(item['selectable' as keyof Key]),
      }));
    }
    this.setState({
      checkBoxOptions: deepCopy(updateSelections),
      selectAllDisabled: this.getSelectAllDisableState(updateSelections),
      selectedRows: updateSelectedRows,
    });
  }
  public render() {
    const { pulseRows } = this.props;
    const {
      selectAllDisabled,
      filteredRowData,
      filters,
      checkBoxOptions,
      filterOptions,
      selectedRows,
      rowData,
    } = this.state;
    const rows = pulseRows ? { count: 1, page: 1, results: pulseRows } : null;

    const reverseOptions = filterOptions.sorting.reverse;
    const sortKey = filterOptions.sorting.key;
    const sortedRows = (rows as unknown) as PaginatedResultsResponse<{
      [key: string]: string;
    }>;

    sortedRows.results?.sort(
      (a: { [x: string]: string }, b: { [x: string]: string }) =>
        reverseOptions ? -1 : 1 * a[sortKey].localeCompare(b[sortKey])
    );
    if (!this.state.rowData)
      this.setState({
        rowData: deepCopy(sortedRows),
      });
    const CellRender = (key: string, value: string) => {
      return <span key={key}>{value}</span>;
    };
    if (!filters) {
      this.getFilters();
    }

    const doFiltersSearchSort = (
      type: string,
      key: string | number,
      selected: string | number,
      reverse?: boolean
    ) => {
      switch (type) {
        case 'filter':
          let updateAssessmentStatus = this.state.selectedAssessment;
          const filterNewOptions = deepCopy(filterOptions);
          if (!filterNewOptions.filters) {
            filterNewOptions['filters'] = {
              assessmentStatus: [
                {
                  label: selected.toString(),
                  key: selected.toString(),
                },
              ],
            };
            updateAssessmentStatus = selected.toString();
          } else {
            const existingIndex = filterNewOptions['filters'][
              'assessmentStatus'
            ].findIndex(
              (item: { key: string }) => item.key === selected.toString()
            );
            if (existingIndex === -1) {
              filterNewOptions['filters'] = {
                assessmentStatus: [
                  {
                    label: selected.toString(),
                    key: selected.toString(),
                  },
                ],
              };
              updateAssessmentStatus = selected.toString();
            } else {
              delete filterNewOptions['filters'];
              updateAssessmentStatus = null;
            }
          }

          const currentRowData = rowData?.results;
          const filtersArray =
            (filterNewOptions['filters'] &&
              filterNewOptions['filters']['assessmentStatus'].map(
                (item: { key: Key }) => item.key
              )) ||
            null;
          const filteredBy =
            currentRowData && filtersArray
              ? currentRowData.filter(function(item) {
                  return (
                    filtersArray.indexOf(
                      item['assessmentStatus' as keyof Key]
                    ) !== -1
                  );
                })
              : null;

          let filteredByState: PaginatedResultsResponse<{
            [key: string]: string;
          }> | null = deepCopy(rowData);

          if (filteredByState) {
            if (filteredBy) {
              filteredByState.results = [...filteredBy];
            } else {
              filteredByState = null;
            }
          }
          this.setState(prevState => {
            return {
              ...prevState,
              filterOptions: deepCopy(filterNewOptions),
              filteredRowData: deepCopy(filteredByState),
              selectedStatus: deepCopy(updateAssessmentStatus),
              selectAllDisabled: Boolean(
                updateAssessmentStatus !== null &&
                  updateAssessmentStatus !== 'Completed'
              ),
            };
          });
          break;
        case 'sort':
          const sortedRowData = deepCopy(rowData);
          const sortedFilteredData = filteredRowData
            ? deepCopy(filteredRowData)
            : null;
          sortedRowData.results.sort(
            (a: { [x: string]: string }, b: { [x: string]: string }) =>
              reverse ? -1 : 1 * a[key].localeCompare(b[key])
          );
          sortedFilteredData
            ? sortedFilteredData.results.sort(
                (a: { [x: string]: string }, b: { [x: string]: string }) =>
                  reverse ? -1 : 1 * a[key].localeCompare(b[key])
              )
            : null;
          const newSortOptions = filterOptions;
          newSortOptions.sorting.key === key
            ? (newSortOptions.sorting.reverse = !newSortOptions.sorting.reverse)
            : reverse;
          newSortOptions.sorting.key = key as string;

          this.setState(prevState => {
            return {
              ...prevState,
              rowData: deepCopy(sortedRowData),
              filteredRowData: deepCopy(sortedFilteredData),
              filterOptions: deepCopy(newSortOptions),
            };
          });
          break;
      }
    };

    const barActions = [
      {
        label: i18next.t<string>('Invite'),
        onClick: () =>
          this.props.openStartPulsingModal(
            selectedRows,
            this.props.pulseSubscriptionDetails,
            this.props.reloadPulse,
            this.props.pulseId
          ),
        isDisabled: false,
      },
    ];
    return (
      <>
        {pulseRows && pulseRows.length ? (
          <ContainerRectangleTile noBorder>
            <div className="mb-base">
              <div>
                {i18next.t<string>('Number of respondents:')}{' '}
                <b>{(pulseRows && pulseRows.length) || 0}</b>
              </div>
            </div>
            <div className="pulse-table-and-buttons">
              <InteractiveTable
                className="pulse-checks"
                pageSize={50}
                page={1}
                isDataPending={false}
                isFilterDataPending={false}
                isNextPageDataPending={false}
                loadPage={() => null}
                cellRenderer={CellRender}
                setSelectedFilter={doFiltersSearchSort}
                enableCheckBoxes
                selectAllDisabled={selectAllDisabled}
                onSelection={this.onCheckBoxSelection}
                rows={filteredRowData === null ? sortedRows : filteredRowData}
                filters={(filters as unknown) as FilterOptions}
                initialSelections={checkBoxOptions}
                selectedFilterOptions={filterOptions}
                columns={[
                  {
                    header: {
                      label: 'Select all',
                      key: 'userId' as keyof { key: Key; value: string },
                    },
                    sortable: false,
                  },
                  {
                    header: {
                      label: 'Name',
                      key: 'fullName' as keyof { key: Key; value: string },
                    },
                    sortable: true,
                  },
                  {
                    header: {
                      label: 'Email',
                      key: 'email' as keyof { key: Key; value: string },
                    },
                    sortable: false,
                  },
                  {
                    header: {
                      label: 'Assessment',
                      key: 'assessmentStatus' as keyof {
                        key: Key;
                        value: string;
                      },
                    },
                    filter: {
                      key: 'assessmentStatus' as keyof {
                        key: Key;
                        value: string;
                      },
                      type: 'dropdown-single',
                    },
                    sortable: false,
                  },
                  {
                    header: {
                      label: 'Report',
                      key: 'reportStatus' as keyof {
                        key: Key;
                        value: string;
                      },
                    },
                    sortable: true,
                  },
                ]}
              />
              {selectedRows && selectedRows.length > 0 && rowData?.results && (
                <BottomActionBar
                  icon={<PulseIconCircleWhite />}
                  infoText={i18next.t<string>('{{count}} respondent selected', {
                    count: selectedRows.length,
                  })}
                  errorText={null}
                  actions={barActions}
                />
              )}
            </div>
          </ContainerRectangleTile>
        ) : (
          <ContainerRectangleTile noBorder>
            {i18next.t<string>('No results')}
          </ContainerRectangleTile>
        )}
      </>
    );
  }
}

export default connect(null, {
  openStartPulsingModal,
})(PulseTable);
