/* eslint-disable react/no-string-refs */
import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import { isPending, hasFailed } from '^/responseStates';
import { ACTIVITY_STATUS } from '^/activityStatus';
import Loading from '^/components/Loading';
import PureComponent from '^/components/PureComponent';
import CreateGroupButton from '^/components/activities/tabs/users/CreateGroupButton';
import InviteToStageTwoButton from '^/components/activities/tabs/users/InviteToStageTwoButton';
import UsersTable from '^/components/activities/tabs/users/UsersTable';
import Alert from '^/components/Alert';
import GoToReportsLink from './GoToReportsLink';

export default class ActivityPrepaidUsersTab extends PureComponent {
  removeUser(userId, userName) {
    this.props.confirmRemoveUser(this.props.activityId, userId, userName);
  }

  selectText() {
    this.refs.shareLink.select();
  }

  updateActivity(data) {
    this.props.closeModalAndUpdateActivity(this.props.activityId, data);
  }

  render() {
    const {
      status,
      activityUsers,
      response,
      activity,
      readOnly,
      organisation,
      activityId,
      activitySummary,
      isJobProfiler,
    } = this.props;

    const withPulse = activitySummary.get('with_pulse');
    const activityUsersCount = activityUsers && activityUsers.size;
    const isEnded = status === ACTIVITY_STATUS.ENDED;
    return (
      <div
        className="respondents"
        title={
          isJobProfiler
            ? i18next.t('Profile creator')
            : i18next.t('Respondents')
        }
      >
        {isEnded ? (
          <Alert>
            {isJobProfiler
              ? i18next.t(
                  'You cannot edit the profile creator on a closed activity. To extend an activity adjust the close date.'
                )
              : i18next.t(
                  'You cannot add respondents to a closed activity. To extend an activity adjust the close date.'
                )}
          </Alert>
        ) : (
          <div>
            {isPending(response) && <Loading className="overlay" />}
            {hasFailed(response) && (
              <p className="text-error">
                {response.getIn(['errors', 'msg']) ||
                  i18next.t('An error has occurred, please try again later.')}
              </p>
            )}
            {this.props.children}
          </div>
        )}

        <div className="pull-right">
          {!readOnly && !isEnded && activitySummary.get('is_staged') && (
            <InviteToStageTwoButton {...this.props} />
          )}
          {!readOnly && !isJobProfiler && <CreateGroupButton {...this.props} />}
          <GoToReportsLink
            activityId={activityId}
            organisationId={organisation.get('id')}
            isJobProfiler={Boolean(isJobProfiler)}
          />
        </div>

        {activityUsersCount > 0 && (
          <UsersTable
            activity={activity}
            removeUser={(userId, userName) => this.removeUser(userId, userName)}
            activityId={activityId}
            readOnly={readOnly}
            withPulse={withPulse}
            isJobProfiler={isJobProfiler}
          />
        )}
      </div>
    );
  }
}
