import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Form, Field } from 'formik';

import FormError from '^/components/FormError';
import { isPending } from '^/responseStates';
import { isEmail } from '^/utils';

export const CreateUserInlineForm = props => {
  const { onSubmit, response } = props;
  return (
    <Formik
      initialValues={{ full_name: '', email: '' }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values }) => (
        <Form className="form-block">
          <div>
            <label htmlFor="full_name">
              {i18next.t('Enter users manually')}
            </label>
            <div className="input-and-control">
              <div className="form-item multiple">
                <div className="form-item">
                  <Field
                    id="full_name"
                    name="full_name"
                    type="text"
                    placeholder={i18next.t('Full name (First Last)')}
                  />
                  <FormError response={response} formKey="full_name" />
                </div>
                <div className="form-item">
                  <Field
                    name="email"
                    type="text"
                    placeholder={i18next.t('Email')}
                  />
                  <FormError response={response} formKey="email" />
                </div>
              </div>
              <button
                className="btn btn-default btn-small"
                disabled={isPending(response) || !isEmail(values.email)}
              >
                <FontAwesomeIcon icon={faPlus} />
                {i18next.t('Add')}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateUserInlineForm;
