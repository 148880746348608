import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import i18next from 'i18next';
import { List } from 'immutable';
import React from 'react';
import { connect } from 'react-redux';

import {
  filterHiddenReportsTableSelection,
  toggleReportsTableSelection,
  toggleSelectAllReportsTableSelection,
} from '^/actions/actions';
import { searchReportFilterUsers } from '^/actions/ui';
import { can, downloadExistingReports } from '^/capabilities';
import SelectableReport from '^/components/reports/admin/SelectableReport';
import Table from '^/components/Table';
import { OrgUser, ReportTemplate } from '^/reducers/api/types';
import {
  available,
  isSelected,
  notHidden,
  ReportsTableSelections,
  asKeys,
  buildSelection,
  Selection,
} from '^/reducers/reports/types';
import { selectUserProfile } from '^/selectors';
import { StoreState } from '^/store';
import { isTruthy } from '^/utils';
import {
  createProductAndVariantKey,
  selectAccountHasUnlimitedCredits,
  selectAllSelectedReportsTable,
  selectAnySelectedReportsTable,
  selectFilteredOrgUsers,
  selectHasAnyReportsTable,
  selectReportsTableSelections,
  selectReportTemplatesByProductAndVariantId,
  selectSessionsByUser,
  UserSessions,
} from './selectors';

interface DispatchProps {
  toggleReportsTableSelection: typeof toggleReportsTableSelection;
  toggleSelectAllReportsTableSelection: typeof toggleSelectAllReportsTableSelection;
  searchReportFilterUsers: typeof searchReportFilterUsers;
  filterHiddenReportsTableSelection: typeof filterHiddenReportsTableSelection;
}

interface StateProps {
  user: Immutable.Map<string, any>;
  users: ReadonlyArray<OrgUser>;
  reportTemplatesByProductAndVariantId: {
    [key: string]: ReadonlyArray<ReportTemplate>;
  };
  sessionsByUser: UserSessions;
  accountHasUnlimitedCredits: boolean;
  reportsTableSelections: ReportsTableSelections;
  allSelected: { [key in keyof ReportsTableSelections]: boolean };
  anySelected: { [key in keyof ReportsTableSelections]: boolean };
  hasAny: { [key in keyof ReportsTableSelections]: boolean };
}

export type Props = StateProps & DispatchProps;

export class ReportsTable extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Props) {
    const sessions = prevProps.users
      .flatMap(user => prevProps.sessionsByUser[user.id])
      .filter(isTruthy);
    const newSessions = this.props.users
      .flatMap(user => this.props.sessionsByUser[user.id])
      .filter(isTruthy);
    if (sessions.length !== newSessions.length) {
      this.props.filterHiddenReportsTableSelection(
        newSessions.map(each => each.id)
      );
    }
  }

  public render() {
    const {
      users,
      sessionsByUser,
      reportTemplatesByProductAndVariantId,
    } = this.props;

    const usersToShow = users.filter(user =>
      (sessionsByUser[user.id] || []).some(
        session =>
          (
            reportTemplatesByProductAndVariantId[
              createProductAndVariantKey(
                session.product,
                session.productVariant
              )
            ] || []
          ).filter(reportTemplate =>
            notHidden(
              this.getSelectionState(
                buildSelection(session, reportTemplate, user.id)
              )
            )
          ).length
      )
    );

    return (
      <div>
        <p className="mb-md mt-sm">
          {this.getRespondentCountMessage(usersToShow.length)}
        </p>
        <div className="reports-box-wrapper">
          <div className="reports-box-list">
            <div className="reports-box-respondents-list">
              <Table
                className="respondents-list-table"
                onSearchChange={this.onSearchChange}
                columns={[
                  {
                    header: i18next.t<string>('Respondent'),
                    searchable: true,
                    largeSearchBox: true,
                    searchPlaceholder: i18next.t<string>(
                      'Search for respondent..'
                    ),
                    value: (user: OrgUser) => user.full_name,
                  },
                ]}
                rows={List(usersToShow)}
              />
            </div>

            <div className="reports-box-reports-list">
              <div className="reports-box-controls reports-box-controls-padded">
                <div className="reports-header">
                  {i18next.t<string>('Reports')}
                </div>
                {this.renderSelectAllButtons()}
              </div>
              <div className="reports-list-table-wrapper">
                <Table
                  noHeaders
                  className="reports-list-table"
                  columns={[
                    {
                      value: (user: OrgUser) =>
                        sessionsByUser[user.id].map(session =>
                          (
                            reportTemplatesByProductAndVariantId[
                              createProductAndVariantKey(
                                session.product,
                                session.productVariant
                              )
                            ] || []
                          )
                            .map(reportTemplate =>
                              buildSelection(session, reportTemplate, user.id)
                            )
                            .filter(
                              selection =>
                                !(
                                  selection.reportTemplate.is_job_match &&
                                  !selection.report
                                )
                            )
                            .map(selection =>
                              this.renderSelectableReport(selection)
                            )
                        ),
                    },
                  ]}
                  rows={List(usersToShow)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private getSelectAllButtonTitle(isDownload: boolean) {
    if (isDownload) {
      return i18next.t<string>(
        'REPORT DISTRIBUTION. These are reports you can download and/or send.'
      );
    }

    return this.props.accountHasUnlimitedCredits
      ? i18next.t<string>(
          'REPORT GENERATION. These are reports you can generate and then download and/or send.'
        )
      : i18next.t<string>(
          'REPORT PURCHASE. These are reports you can purchase and then download and/or send.'
        );
  }

  private onSearchChange = (value?: string) => {
    this.props.searchReportFilterUsers(value);
  };

  private renderSelectAllButton(key: keyof ReportsTableSelections) {
    const { anySelected, hasAny } = this.props;
    const isDownload = key === 'reports';
    const allSelected = this.props.allSelected[key];
    const otherKey: keyof ReportsTableSelections = isDownload
      ? 'reportTemplates'
      : 'reports';

    return (
      <button
        className={classNames(
          'btn btn-icon-square',
          isDownload
            ? allSelected
              ? 'btn-icon-square-success'
              : 'btn-icon-square-success-secondary'
            : allSelected
            ? 'btn-icon-square'
            : 'btn-icon-square-secondary'
        )}
        title={this.getSelectAllButtonTitle(isDownload)}
        type="button"
        disabled={!hasAny[key] || anySelected[otherKey]}
        onClick={this.props.toggleSelectAllReportsTableSelection.bind(
          null,
          key
        )}
      >
        <FontAwesomeIcon icon={isDownload ? faFileDownload : faShoppingCart} />
      </button>
    );
  }

  private renderSelectAllButtons() {
    const { user } = this.props;

    return (
      <div className="table-controls">
        {`${i18next.t<string>('Select all')}:`}
        {this.renderSelectAllButton('reportTemplates')}
        {can(user, downloadExistingReports()) &&
          this.renderSelectAllButton('reports')}
      </div>
    );
  }

  private getSelectionState(selection: Selection) {
    const [key, innerKey] = asKeys(selection);
    return this.props.reportsTableSelections[key][innerKey];
  }

  private renderSelectableReport(selection: Selection) {
    const { user } = this.props;
    const userCanDownloadExistingReports = can(user, downloadExistingReports());
    const selectionState = this.getSelectionState(selection);
    const hasVariant = selection.productVariantId !== null;

    if (notHidden(selectionState)) {
      return (
        <SelectableReport
          completed={selection.completed}
          key={selection.reportTemplate.id}
          label={selection.reportTemplate.code}
          status={selection.report && selection.report.pdf_status}
          disabled={
            userCanDownloadExistingReports &&
            this.props.anySelected[
              selection.report ? 'reportTemplates' : 'reports'
            ]
          }
          isUnavailable={
            !available(selectionState) ||
            (!userCanDownloadExistingReports && hasVariant)
          }
          hasVariant={hasVariant}
          downloadBlocked={!userCanDownloadExistingReports}
          isSelected={isSelected(selectionState)}
          onSelect={this.props.toggleReportsTableSelection.bind(
            null,
            selection
          )}
          reportName={selection.reportTemplate.name}
          roleName={selection.report?.job_match_report?.role_name}
        />
      );
    }
  }

  private getRespondentCountMessage(respondentCount: number) {
    if (respondentCount === 0) {
      return i18next.t<string>('No respondents');
    }
    return i18next.t<string>('Showing {{count}} respondent', {
      count: respondentCount,
    });
  }
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    user: selectUserProfile(state),
    users: selectFilteredOrgUsers(state),
    accountHasUnlimitedCredits: selectAccountHasUnlimitedCredits(state),
    reportTemplatesByProductAndVariantId: selectReportTemplatesByProductAndVariantId(
      state
    ),
    sessionsByUser: selectSessionsByUser(state),
    reportsTableSelections: selectReportsTableSelections(state),
    allSelected: selectAllSelectedReportsTable(state),
    anySelected: selectAnySelectedReportsTable(state),
    hasAny: selectHasAnyReportsTable(state),
  };
}

export default connect(mapStateToProps, {
  toggleReportsTableSelection,
  toggleSelectAllReportsTableSelection,
  searchReportFilterUsers,
  filterHiddenReportsTableSelection,
})(ReportsTable as React.ComponentClass<Props>);
