import React from 'react';
import { Map } from 'immutable';

import ListPagePagination from './ListPagePagination';

interface ListPagePaginationProps {
  pageSize: number;
  collection: Map<string, any>;
  onSelectPage: (page: number) => void;
  pageSizeOptions?: ReadonlyArray<number>;
  onChangePageSize?: (pageSize: number) => void;
}

const ListPagePaginationWrapper: React.FC<ListPagePaginationProps> = props => {
  return <ListPagePagination {...props} />;
};

export default ListPagePaginationWrapper;
