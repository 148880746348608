import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { RTL_LANGUAGES } from '^/constants/routes';
import { StoreState } from '^/store';

interface StateProps {
  rtl: boolean;
}

interface OwnProps {
  steps: number;
  currentStep: number;
  renderHorizontalRule?: boolean;
}

type Props = StateProps & OwnProps;

export class StepProgressBar extends PureComponent<Props> {
  public render() {
    const steps = _.range(this.props.steps - 1);
    const directionalSteps = this.props.rtl ? steps.reverse() : steps;
    const stepStyle = {
      width: (1 / steps.length) * 100 + '%',
    };

    return (
      <div
        className={classNames('step-progress-bar', {
          'horizontal-rule': this.props.renderHorizontalRule,
        })}
      >
        {directionalSteps.map(index => (
          <div
            key={index}
            className={this.getClassName(index)}
            style={stepStyle}
          >
            <div className="progress-line" />
          </div>
        ))}
      </div>
    );
  }

  private getClassName(index: number) {
    let baseClassName = 'step';

    if (index < this.props.currentStep) {
      baseClassName = baseClassName + ' completed';
    }

    if (index === this.props.currentStep) {
      baseClassName = baseClassName + ' active';
    }

    return baseClassName;
  }
}

function mapStateToProps(state: StoreState) {
  return {
    rtl: RTL_LANGUAGES.includes(state.ui.get('activeLanguage')),
  };
}

export default connect(mapStateToProps)(StepProgressBar);
