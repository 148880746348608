import { Map } from 'immutable';

import {
  can,
  downloadOtherReports,
  downloadSharedReports,
} from '^/capabilities';
import {
  createProductAndVariantKey,
  UserSession,
  UserSessions,
} from '^/components/reports/admin/selectors';
import { isProductAvailable } from '^/models/product';
import { ReportTemplate, TeamReportsState, PdfStatus } from '../api/types';
import {
  asKeys,
  buildSelection,
  buildTeamSelection,
  ReportsTableSelections,
  ReportTemplatesByProductAndVariantId,
  SelectionState,
} from './types';

function sessionIsUnavailable(session: UserSession, user: Map<string, any>) {
  return (
    (session.sharedWithMe && !can(user, downloadSharedReports())) ||
    (!(session.mine || session.sharedWithMe) &&
      !can(user, downloadOtherReports()))
  );
}

function isReportAccessible(
  session: UserSession,
  reportTemplate: ReportTemplate,
  user: Map<string, any>
) {
  // Org is accredited or report is already generated
  return (
    isProductAvailable(user, session.product, session.productVariant) ||
    session.reports.some(report => report.template === reportTemplate.id)
  );
}

function isSessionReadyForReport(
  session: UserSession,
  reportTemplate: ReportTemplate
) {
  return !reportTemplate.requires_complete_session || session.completed;
}

function isNotReportJobMatchReportTemplate(
  session: UserSession,
  reportTemplate: ReportTemplate
) {
  return (
    (reportTemplate.is_job_match &&
      session.reports.some(
        report =>
          report.template === reportTemplate.id &&
          report.pdf_status === PdfStatus.Complete
      )) ||
    !reportTemplate.is_job_match
  );
}

function isReportFailed(session: UserSession, reportTemplate: ReportTemplate) {
  return session.reports.some(
    report =>
      report.template === reportTemplate.id &&
      report.pdf_status === PdfStatus.Failed
  );
}

function canSeeReport(
  session: UserSession,
  reportTemplate: ReportTemplate,
  user: Map<string, any>
) {
  return (
    isReportAccessible(session, reportTemplate, user) &&
    isSessionReadyForReport(session, reportTemplate) &&
    isNotReportJobMatchReportTemplate(session, reportTemplate)
  );
}

function getInitialState(
  session: UserSession,
  reportTemplate: ReportTemplate,
  user: Map<string, any>
) {
  if (
    sessionIsUnavailable(session, user) ||
    isReportFailed(session, reportTemplate)
  ) {
    return SelectionState.Unavailable;
  } else if (!canSeeReport(session, reportTemplate, user)) {
    return SelectionState.PermanentlyHidden;
  }
  return SelectionState.Unselected;
}

export function getInitialSelectionState(
  user: Map<string, any>,
  userSessions: UserSessions,
  reportTemplates: ReportTemplatesByProductAndVariantId
) {
  const newState: ReportsTableSelections = {
    reports: {},
    reportTemplates: {},
  };

  Object.entries(userSessions).forEach(
    ([userId, sessions]) =>
      sessions.forEach(session =>
        (
          (reportTemplates &&
            reportTemplates[
              createProductAndVariantKey(
                session.product,
                session.productVariant
              )
            ]) ??
          []
        ).forEach(reportTemplate => {
          const [key, innerKey] = asKeys(
            buildSelection(session, reportTemplate, userId)
          );
          newState[key][innerKey] = getInitialState(
            session,
            reportTemplate,
            user
          );
        })
      ),
    {}
  );

  return newState;
}

export function getInitialTeamSelectionState(
  teamReports: TeamReportsState,
  user: Immutable.Map<string, any>
) {
  const newState: ReportsTableSelections = {
    reports: {},
    reportTemplates: {},
  };

  teamReports.forEach(reportTeam => {
    const [key, innerKey] = asKeys(buildTeamSelection(reportTeam));
    newState[key][innerKey] =
      !user.get('has_restricted_sharing_permissions') ||
      reportTeam.reports.every(
        report => report.created_by_user === user.get('id')
      )
        ? SelectionState.Unselected
        : SelectionState.Unavailable;
  });

  return newState;
}
