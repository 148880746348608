import i18next from 'i18next';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getUserInfo, hideAutoLogoutOverlay, logout } from '^/actions/actions';
import { CountDownCircle } from './CountDownCircle';

const LOGOUT_TIMEOUT_SECONDS = 30;

interface DispatchProps {
  hideAutoLogoutOverlay: typeof hideAutoLogoutOverlay;
  getUserInfo: typeof getUserInfo;
  logout: typeof logout;
}

type Props = DispatchProps;

interface State {
  currentTime: number;
  timer?: number;
}

export class LogoutWarningOverlay extends PureComponent<Props, State> {
  public state: State = { currentTime: LOGOUT_TIMEOUT_SECONDS };

  public componentDidMount() {
    const newTimer = window.setInterval(() => {
      this.setState(state => ({ currentTime: state.currentTime - 1 }));
    }, 1000);
    this.setState({ timer: newTimer });
  }

  public componentDidUpdate() {
    if (this.state.currentTime <= 0) {
      this.logoutAndCloseOverlay();
    }
  }

  public render() {
    return (
      <div className="overlay-wrapper">
        <h3>
          {i18next.t<string>(
            'You are about to be logged out of Enable. Please click CONTINUE to stay logged in'
          )}
        </h3>
        <button
          className="btn btn-primary btn-countdown"
          onClick={this.closeOverlayAndGetUserInfo}
        >
          <CountDownCircle
            remainingSeconds={this.state.currentTime}
            totalSeconds={LOGOUT_TIMEOUT_SECONDS}
          />
          {i18next.t<string>('Continue')}
        </button>
      </div>
    );
  }

  private closeOverlayAndGetUserInfo = () => {
    window.clearInterval(this.state.timer);
    this.setState({ currentTime: LOGOUT_TIMEOUT_SECONDS });
    this.props.getUserInfo();
    this.props.hideAutoLogoutOverlay();
  };

  private logoutAndCloseOverlay = () => {
    this.props.logout();
    window.clearInterval(this.state.timer);
    this.setState({ currentTime: LOGOUT_TIMEOUT_SECONDS });
    this.props.hideAutoLogoutOverlay();
  };
}

export default connect(null, {
  hideAutoLogoutOverlay,
  getUserInfo,
  logout,
})(LogoutWarningOverlay);
