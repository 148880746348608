import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { Link } from 'react-router';
import i18next from 'i18next';

import PulseIconCircleWhiteOutline from './PulseIconCircleWhiteOutline';
import { openMenu, closeMenu, closeMobileMenu } from '^/actions/actions';
import SidebarProfile from './SidebarProfile';
import PureComponent from './PureComponent';
import ProfilePicture from './profile/ProfilePicture';
import { withRouter } from '^/withRouter';

export const NavItem = class NavItem extends PureComponent {
  constructor(props) {
    super(props);

    this.openedNewMenu = false;
    this.clickAnywhere = this.clickAnywhere.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.clickAnywhere);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickAnywhere);
  }

  isOpen() {
    return this.props.title === this.props.menuExpanded;
  }

  clickAnywhere() {
    // if we haven't opened a new menu and the current menu is open when we click somewhere, close the menu
    // (this prevents the non-active menus from closing others)
    if (this.isMenu() && !this.openedNewMenu && this.isOpen()) {
      this.props.closeMenu();
    }

    // Reset the "menu was just opened" state
    this.openedNewMenu = false;
  }

  isActive() {
    const {
      to,
      router: {
        location: { pathname },
      },
    } = this.props;
    return pathname.startsWith(to);
  }

  getClassName(className) {
    const { children, hasActiveChildRoute } = this.props;

    return classNames(className, {
      'has-children': children,
      'has-active-child-route': hasActiveChildRoute,
      open: this.isOpen(),
      'navigation-link-active': this.isActive(),
    });
  }

  isMenu() {
    return React.Children.count(this.props.children) !== 0;
  }

  onClick() {
    // We track if a new menu was just opened when a menu item is clicked
    this.openedNewMenu = !this.isOpen();
    this.props.openMenu(this.props.title);

    if (typeof this.props.onClick === 'function') {
      this.props.onClick();
    }
  }

  closeMobileMenu() {
    if (!this.isMenu()) {
      this.props.closeMobileMenu();
    }
  }

  getIcon(icon, hasParent, pulseIcon, user) {
    if (pulseIcon && hasParent) {
      return (
        <div className="navigation-icon">
          <PulseIconCircleWhiteOutline
            width={20}
            height={20}
            className={'svg-inline--fa'}
          />
        </div>
      );
    }

    if (pulseIcon) {
      return (
        <div className="navigation-icon">
          <PulseIconCircleWhiteOutline width={24} height={24} />
        </div>
      );
    }

    if (icon) {
      return (
        <div className="navigation-icon">
          <FontAwesomeIcon icon={icon} size={hasParent ? 'lg' : '2x'} />
        </div>
      );
    }

    return (
      <div className="profile-icon">
        <ProfilePicture width={25} height={25} user={user} />
      </div>
    );
  }

  render() {
    const {
      user,
      hasParent,
      badge,
      pulseIcon,
      showLoggedInUser,
      title,
      children,
      to,
      onClick,
      disabled,
    } = this.props;

    const icon = this.getIcon(this.props.icon, hasParent, pulseIcon, user);

    return (
      <li onClick={() => this.closeMobileMenu()}>
        {showLoggedInUser && <SidebarProfile />}
        {// for things that are top menu items or a special case like sudo
        !to ? (
          <button
            className={this.getClassName(
              'navigation-link navigation-link-menu-button'
            )}
            onClick={() => this.onClick()}
          >
            {icon}
            {badge !== undefined && (
              <div className="credits-count">{badge}</div>
            )}
            <div className="navigation-text">
              {i18next.t(title)}

              {this.isMenu() && (
                <FontAwesomeIcon
                  className="navigation-chevron"
                  icon={faChevronDown}
                  size="xs"
                />
              )}
            </div>
          </button>
        ) : (
          <Link
            to={to}
            title={i18next.t(title)}
            onClick={onClick}
            disabled={disabled}
            className={this.getClassName('navigation-link')}
          >
            {icon}
            {badge !== undefined && (
              <div className="credits-count">{badge}</div>
            )}
            <div className="navigation-text">{i18next.t(title)}</div>
          </Link>
        )}
        {this.isMenu() && <ul className="sub-navigation">{children}</ul>}
      </li>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.userProfile,
    badge: ownProps.getBadge && ownProps.getBadge(state),
    menuExpanded: state.ui.get('menuExpanded'),
  };
}

export default withRouter(
  connect(mapStateToProps, { openMenu, closeMenu, closeMobileMenu })(NavItem)
);
