import classNames from 'classnames';
import i18next from 'i18next';
import React from 'react';

import { BehaviourDetail, BehaviourOptions, Uuid } from '^/reducers/api/types';
import PulseIconCircle from '^/components/PulseIconCircle';
import { SUB_BEHAVIOUR_LIMIT } from './constants';

interface OwnProps {
  behaviourOptions: BehaviourOptions;
  storeSelected: (behaviours: ReadonlyArray<{ id: Uuid }> | undefined) => void;
  selectedBehaviours: ReadonlyArray<{ id: Uuid }> | undefined;
  isSelfOnly: boolean;
  setBehaviours: (behaviours: ReadonlyArray<{ id: Uuid }> | undefined) => void;
}

interface State {
  selectedBehaviours: ReadonlyArray<{ id: Uuid }> | undefined;
}

export type Props = OwnProps;

export class SelectPulseBehaviours extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedBehaviours: this.props.selectedBehaviours,
    };
  }

  private displayBehaviours = (behaviourOptions: BehaviourOptions) =>
    behaviourOptions?.pulsebehaviour_set?.map((behaviour, idx) => {
      return (
        <div key={idx}>
          <h2>{behaviour.name}</h2>
          <div className="behaviour-block">
            {this.displaySubBehaviours(behaviour.pulsesubbehaviour_set)}
          </div>
        </div>
      );
    });

  private displaySubBehaviours = (subBehaviourSet: BehaviourDetail[]) => {
    const { selectedBehaviours } = this.state;
    return subBehaviourSet.map(behaviour => {
      const selected = selectedBehaviours?.filter(
        each => each.id === behaviour.id
      ).length
        ? 'selected'
        : 'not-selected';
      return (
        <div className="button-wrapper" key={behaviour.id}>
          <button
            key={behaviour.id}
            disabled={
              selected !== 'selected' &&
              selectedBehaviours &&
              selectedBehaviours.length >= SUB_BEHAVIOUR_LIMIT
            }
            className={classNames('behaviour-button', selected)}
            onClick={() => this.selectSubBehaviour(behaviour.id)}
          >
            <h3>{behaviour.name}</h3>
            <p className="small">{behaviour.description}</p>
          </button>
        </div>
      );
    });
  };

  private selectSubBehaviour = (id: Uuid) => {
    const idExists = this.state.selectedBehaviours?.filter(
      each => each.id === id
    ).length;

    const addRemoveId = !idExists
      ? this.state.selectedBehaviours?.concat([{ id: id }])
      : this.state.selectedBehaviours?.filter(each => each.id !== id);

    this.setState({
      selectedBehaviours: addRemoveId ? addRemoveId : [{ id: id }],
    });
  };

  private showSetRaters = () => {
    this.props.storeSelected(this.state.selectedBehaviours);
  };

  public render() {
    const { selectedBehaviours } = this.state;
    const { behaviourOptions, isSelfOnly } = this.props;
    return (
      <div>
        <div className="pulse-icon">
          <PulseIconCircle />
        </div>
        <div className="pulse-select-central-column">
          <h1>{i18next.t<string>('Start Pulsing')}</h1>
          <p>
            {isSelfOnly
              ? i18next.t<string>(
                  'Select 1-3 behaviours to develop, to start Pulsing.'
                )
              : i18next.t<string>(
                  'Firstly, select 1 - 3 behaviours you want to develop. Then, choose people to pulse and submit their feedback.'
                )}
          </p>
          {this.displayBehaviours(behaviourOptions)}
          <button
            className="btn btn-primary"
            disabled={!selectedBehaviours || selectedBehaviours?.length < 1}
            onClick={
              isSelfOnly
                ? () => this.props.setBehaviours(selectedBehaviours)
                : () => this.showSetRaters()
            }
          >
            {isSelfOnly
              ? i18next.t<string>('Start pulsing')
              : i18next.t<string>('Select')}
          </button>
        </div>
      </div>
    );
  }
}

export default SelectPulseBehaviours;
