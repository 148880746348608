import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import { ACCOUNT_ADMIN_ROLE_DISPLAY } from '^/models/organisation';
import PureComponent from '^/components/PureComponent';
import { isPending } from '^/responseStates';
import FormError from '^/components/FormError';

class AddAccountAdminForm extends PureComponent {
  render() {
    const { onSubmit, response, availableRoles, disabledRoles } = this.props;
    const disabled = availableRoles.length === disabledRoles.length;
    return (
      <Formik
        initialValues={{
          full_name: '',
          email: '',
          role: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form>
          <div className="row">
            <div className="col-xs-1">
              <div className="label-standalone">{i18next.t('Add admin')}</div>
            </div>
            <div className="col-xs-3">
              <label className="form-group-block">
                <Field
                  disabled={disabled}
                  type="text"
                  placeholder={i18next.t('Name')}
                  name="full_name"
                  id="full_name"
                />
                <FormError response={response} formKey="full_name" />
              </label>
            </div>
            <div className="col-xs-5 col-lg-4">
              <label className="form-group-block">
                <Field
                  disabled={disabled}
                  type="text"
                  placeholder={i18next.t('Email')}
                  name="email"
                  id="email"
                />
                <FormError response={response} formKey="email" />
              </label>
            </div>
            <div className="col-xs-3 col-lg-2">
              {availableRoles.length > 1 ? (
                <label className="form-group-block">
                  <Field as="select" name="role" id="role" disabled={disabled}>
                    <option disabled value="">
                      {i18next.t('Admin type')}
                    </option>
                    {availableRoles.map(eachRole => (
                      <option
                        key={eachRole}
                        value={eachRole}
                        disabled={disabledRoles.includes(eachRole)}
                      >
                        {ACCOUNT_ADMIN_ROLE_DISPLAY[eachRole]}
                      </option>
                    ))}
                  </Field>
                  <FormError response={response} formKey="role" />
                </label>
              ) : (
                ACCOUNT_ADMIN_ROLE_DISPLAY[availableRoles[0]]
              )}
            </div>
            <div className="col-xs-12 col-lg-2 col-xl-1 text-right-xs text-left-lg">
              <button
                disabled={disabled || isPending(response)}
                className="btn btn-primary margin-none"
              >
                {i18next.t('Add admin')}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    );
  }
}

export default AddAccountAdminForm;
