import React from 'react'; // eslint-disable-line no-unused-vars
import { List } from 'immutable';
import i18next from 'i18next';
import { Formik, Form, Field } from 'formik';

import PureComponent from './PureComponent';
import LiveButton from './LiveButton';
import FormError from './FormError';
import RevealablePasswordField from './forms/RevealablePasswordField';

export const renderPasswordError = error =>
  error.join('. ').replace('. Password', ' &');

export class RedeemInviteForm extends PureComponent {
  render() {
    const { onSubmit, response } = this.props;

    return (
      <Formik
        initialValues={{
          email: this.props.email,
          fullName: this.props.fullName,
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        <Form className="form-block mb-none">
          <div>
            <label>{i18next.t('Email')}</label>
            <div className="form-item">
              <input type="text" disabled="disabled" value={this.props.email} />
            </div>
          </div>

          <div>
            <label>{i18next.t('Full name')}</label>
            <div className="form-item">
              <Field
                type="text"
                name="fullName"
                id="fullName"
                placeholder={i18next.t('Full name')}
                className="form-name-input"
              />
              <FormError response={response} formKey="full_name" />
            </div>
          </div>

          <div>
            <label>{i18next.t('Password')}</label>
            <div className="form-item">
              <RevealablePasswordField
                id="redeem-password"
                name="password"
                placeholder={i18next.t('Password')}
              />
              <FormError
                response={response}
                formKey="password"
                renderError={renderPasswordError}
              />
            </div>
          </div>

          <div>
            <label>{i18next.t('Confirm password')}</label>
            <div className="form-item">
              <RevealablePasswordField
                id="redeem-confirm-password"
                name="confirmPassword"
                placeholder={i18next.t('Confirm password')}
              />
              <FormError
                response={response}
                formKey="password1"
                renderError={renderPasswordError}
              />
            </div>
          </div>
          <p className="mb-0">{i18next.t('PARAGRAPH passwords information')}</p>
          <LiveButton
            additionalButtonClasses="btn-full-width"
            response={response}
            buttonText={i18next.t('Redeem Invite')}
            pendingMessage={i18next.t('Redeeming invite...')}
            successContent={
              <p>
                {i18next.t(
                  'Account created successfully. Logging you in to Peoplewise...'
                )}
              </p>
            }
            getAdditionalErrors={responseData => {
              return List([
                i18next.t('Unable to redeem invite. ') +
                  responseData.getIn(['errors', 'msg'], ''),
              ]);
            }}
          />
        </Form>
      </Formik>
    );
  }
}

export default RedeemInviteForm;
