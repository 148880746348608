/* eslint-disable no-console */
import React from 'react'; // eslint-disable-line no-unused-vars
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faArrowAltFromBottom } from '@fortawesome/pro-light-svg-icons/faArrowAltFromBottom';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons/faSpinnerThird';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ToggleSwitch from '^/components/buttons/ToggleSwitch';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import PureComponent from '../PureComponent';
import CodeSample from './CodeSample';
import Countdown from '../Countdown';
import { CountDownCircle } from '../CountDownCircle';

export default class Buttons extends PureComponent {
  constructor() {
    super();

    this.state = {
      toggleButtonId: '1',
    };
  }

  render() {
    return (
      <div>
        <PageHeader>Buttons</PageHeader>
        <PageContent>
          <div className="row">
            <div className="col-lg-6">
              <p>
                Buttons are by default inline. If you put two next to each other
                they will automatically have a margin between. For most cases,
                you should use a <strong>btn-primary</strong> button, with a{' '}
                <strong>secondary (btn-default)</strong> one as a cancel option.
              </p>

              <div className="text-right">
                <button className="btn btn-default">Cancel</button>
                <button className="btn btn-success-secondary">
                  <FontAwesomeIcon icon={faFileDownload} />
                  With icon
                </button>
                <button className="btn btn-primary">Do something!</button>
                <button className="btn btn-primary" disabled>
                  Disabled
                </button>
              </div>
            </div>

            <div className="col-lg-6">
              <CodeSample
                code={` <div className="text-right">
  <button className="btn btn-default">
    Cancel
  </button>
  <button className="btn btn-success-secondary">
    <FontAwesomeIcon icon={faFileDownload} />
    With icon
  </button>
  <button className="btn btn-primary">
    Do something!
  </button>
   <button className="btn btn-primary" disabled>
    Disabled
  </button>
</div>`}
              />
            </div>
          </div>

          <h5>Primary</h5>
          <button className="btn btn-primary">Primary</button>
          <CodeSample
            code={`<button className="btn btn-primary">
  Primary
</button>`}
          />

          <h5>Secondary (default)</h5>
          <button className="btn btn-default">Default</button>

          <button className="btn">Fallback (plain .btn)</button>
          <CodeSample
            code={`<button className="btn btn-default">
  Default
</button>

<button className="btn">
  Fallback (plain .btn)
</button>`}
          />

          <h5>Success</h5>

          <div className="display-flex">
            <button className="btn btn-success">Success</button>

            <button className="btn btn-success-secondary">
              Success secondary
            </button>

            <button className="btn btn-success-secondary">
              <FontAwesomeIcon icon={faFileDownload} />
              With icon
            </button>

            <button className="btn btn-success-secondary">
              <FontAwesomeIcon icon={faFileDownload} />
              With icon
            </button>
          </div>

          <CodeSample
            code={`<div className="display-flex">
  <button className="btn btn-success">
    Success
  </button>

  <button className="btn btn-success-secondary">
    Success secondary
  </button>

  <button className="btn btn-success-secondary">
    <FontAwesomeIcon icon={faFileDownload} />
    With icon
  </button>

  <button className="btn btn-success-secondary">
    <FontAwesomeIcon icon={faFileDownload} />
    With icon
  </button>
</div>`}
          />

          <h5>Warning</h5>
          <button className="btn btn-warning">Warning</button>

          <button className="btn btn-warning-secondary">
            Warning secondary
          </button>
          <CodeSample
            code={`<button className="btn btn-warning">
  Warning
</button>

<button className="btn btn-warning-secondary">
  Warning secondary
</button>`}
          />

          <h5>Danger</h5>
          <button className="btn btn-danger">Danger</button>

          <button className="btn btn-danger-secondary">Danger secondary</button>
          <CodeSample
            code={`<button className="btn btn-danger">
  Danger
</button>

<button className="btn btn-danger-secondary">
  Danger secondary
</button>`}
          />

          <h3>Variants</h3>

          <h5>With icon</h5>
          <button className="btn btn-default">
            <FontAwesomeIcon icon={faShoppingCart} />
            With icon
          </button>
          <CodeSample
            code={`<button className="btn  btn-default">
  <FontAwesomeIcon icon={faShoppingCart} />
  With icon
</button>`}
          />

          <h5>With icon and smaller</h5>
          <button className="btn btn-default btn-small">
            <FontAwesomeIcon icon={faShoppingCart} />
            <FontAwesomeIcon icon={faPlus} />
            <FontAwesomeIcon icon={faFilter} />
            <FontAwesomeIcon icon={faSearch} />
            <FontAwesomeIcon icon={faTimes} />
            <FontAwesomeIcon icon={faCheck} />
            <FontAwesomeIcon icon={faCalendarAlt} />
            <FontAwesomeIcon icon={faEnvelope} />
            <FontAwesomeIcon icon={faEdit} />
            <FontAwesomeIcon icon={faArrowAltFromBottom} />
            <FontAwesomeIcon icon={faDownload} />
            <FontAwesomeIcon icon={faFileDownload} />
            <FontAwesomeIcon icon={faSpinnerThird} spin />
            With icon smaller
          </button>
          <CodeSample
            code={`<button className="btn btn-default btn-small">
  <FontAwesomeIcon icon={faShoppingCart} />
  <FontAwesomeIcon icon={faPlus} />
  <FontAwesomeIcon icon={faFilter} />
  <FontAwesomeIcon icon={faSearch} />
  <FontAwesomeIcon icon={faTimes} />
  <FontAwesomeIcon icon={faCheck} />
  <FontAwesomeIcon icon={faCalendarAlt} />
  <FontAwesomeIcon icon={faEnvelope} />
  <FontAwesomeIcon icon={faEdit} />
  <FontAwesomeIcon icon={faArrowAltFromBottom} />
  <FontAwesomeIcon icon={faDownload} />
  <FontAwesomeIcon icon={faFileDownload} />
  <FontAwesomeIcon icon={faSpinnerThird} spin />
  With icon smaller
</button>`}
          />

          <h5>With all other icons </h5>
          <button className="btn btn-default">
            <FontAwesomeIcon icon={faShoppingCart} />
            <FontAwesomeIcon icon={faPlus} />
            <FontAwesomeIcon icon={faFilter} />
            <FontAwesomeIcon icon={faSearch} />
            <FontAwesomeIcon icon={faTimes} />
            <FontAwesomeIcon icon={faCheck} />
            <FontAwesomeIcon icon={faCalendarAlt} />
            <FontAwesomeIcon icon={faEnvelope} />
            <FontAwesomeIcon icon={faEdit} />
            <FontAwesomeIcon icon={faArrowAltFromBottom} />
            <FontAwesomeIcon icon={faDownload} />
            <FontAwesomeIcon icon={faFileDownload} />
            <FontAwesomeIcon icon={faSpinnerThird} spin />
            With icon
          </button>
          <CodeSample
            code={`<button className="btn btn-default">
  <FontAwesomeIcon icon={faShoppingCart} />
  <FontAwesomeIcon icon={faPlus} />
  <FontAwesomeIcon icon={faFilter} />
  <FontAwesomeIcon icon={faSearch} />
  <FontAwesomeIcon icon={faTimes} />
  <FontAwesomeIcon icon={faCheck} />
  <FontAwesomeIcon icon={faCalendarAlt} />
  <FontAwesomeIcon icon={faEnvelope} />
  <FontAwesomeIcon icon={faEdit} />
  <FontAwesomeIcon icon={faArrowAltFromBottom} />
  <FontAwesomeIcon icon={faDownload} />
  <FontAwesomeIcon icon={faFileDownload} />
  <FontAwesomeIcon icon={faSpinnerThird} spin />
  With icon
</button>`}
          />

          <h5>Toggle button</h5>
          <ToggleSwitch
            items={[
              {
                id: '1',
                name: 'First',
                selected: this.state.toggleButtonId === '1',
              },
              {
                id: '2',
                name: 'Second',
                selected: this.state.toggleButtonId === '2',
              },
              {
                id: '3',
                name: 'Third',
                selected: this.state.toggleButtonId === '3',
              },
            ]}
            onClick={id => this.setState({ toggleButtonId: id })}
          />

          <h5>Countdown button</h5>
          <Countdown
            totalMinutes={2}
            style="secondary"
            onCountdownComplete={() => console.log('Countdown complete!')}
          />
          <button className="btn btn-primary btn-countdown">
            <CountDownCircle remainingSeconds={30} totalSeconds={45} />
            Continue
          </button>
          <CodeSample
            code={`<Countdown
  totalMinutes={2}
  style="secondary"
  onCountdownComplete={()=> console.log('Countdown complete!')}
/>
<button className="btn btn-primary btn-countdown">
  <CountDownCircle remaining={30} total={0.75} />
  Continue
</button>`}
          />

          <h5>
            Square icon buttons with invert hover - invert to blue/white on
            hover
          </h5>
          <div className="list-item">
            <div className="list-item-label">Credit balance</div>
            <div className="list-item-value">10 credits</div>
            <button className="btn btn-icon-square btn-icon-square-secondary">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-icon-square btn-icon-square-secondary">
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn btn-icon-square btn-icon-square-success-secondary">
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn btn-icon-square btn-icon-square-success">
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>

          <CodeSample
            code={`  <div className="list-item">
<div className="list-item-label">Credit balance</div>
<div className="list-item-value">
10 credits
</div>
<button className="btn btn-icon-square btn-icon-square-secondary">
<FontAwesomeIcon icon={faPlus} />
</button>
<button className="btn btn-icon-square btn-icon-square-secondary">
<FontAwesomeIcon icon={faEdit} />
</button>
<button className="btn btn-icon-square btn-icon-square-success-secondary">
<FontAwesomeIcon icon={faEdit} />
</button>
<button className="btn btn-icon-square btn-icon-square-success">
<FontAwesomeIcon icon={faEdit} />
</button>
</div>`}
          />

          <h5>Square icon buttons with invert hover - smaller</h5>
          <div className="list-item">
            <div className="list-item-label">Credit balance</div>
            <div className="list-item-value">10 credits</div>
            <button className="btn btn-icon-square btn-small btn-icon-square-secondary">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-icon-square btn-small btn-icon-square-secondary">
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>

          <CodeSample
            code={`  <div className="list-item">
  <div className="list-item-label">Credit balance</div>
  <div className="list-item-value">
    10 credits
</div>
<button className="btn btn-icon-square btn-small btn-icon-square-secondary">
  <FontAwesomeIcon icon={faPlus} />
</button>
<button className="btn btn-icon-square btn-small btn-icon-square-secondary">
  <FontAwesomeIcon icon={faEdit} />
</button>
</div>`}
          />

          <h5>
            Round icon buttons with invert hover - invert to blue/white on hover
          </h5>
          <div className="list-item">
            <div className="list-item-label">Credit balance</div>
            <div className="list-item-value">10 credits</div>
            <button className="btn btn-icon-round">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-icon-round">
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>

          <CodeSample
            code={`  <div className="list-item">
<div className="list-item-label">Credit balance</div>
<div className="list-item-value">
10 credits
</div>
<button className="btn btn-icon-round">
<FontAwesomeIcon icon={faPlus} />
</button>
<button className="btn btn-icon-round">
<FontAwesomeIcon icon={faEdit} />
</button>
</div>`}
          />

          <h5>
            Round Red icon buttons with invert hover - invert to red/white on
            hover
          </h5>
          <div className="list-item">
            <div className="list-item-label">Credit balance</div>
            <div className="list-item-value">10 credits</div>
            <button className="btn btn-icon-round-danger">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-icon-round-danger">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <CodeSample
            code={`  <div className="list-item">
<div className="list-item-label">Credit balance</div>
<div className="list-item-value">
10 credits
</div>
<button className="btn btn-icon-round-danger">
<FontAwesomeIcon icon={faPlus} />
</button>
<button className="btn btn-icon-round-danger">
<FontAwesomeIcon icon={faTimes} />
</button>
</div>`}
          />

          <h5>
            Smaller Round Red icon buttons with invert hover - invert to
            red/white on hover
          </h5>
          <div className="list-item">
            <div className="list-item-label">Credit balance</div>
            <div className="list-item-value">10 credits</div>
            <button className="btn btn-icon-round-danger btn-small">
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button className="btn btn-icon-round-danger btn-small">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <CodeSample
            code={`  <div className="list-item">
<div className="list-item-label">Credit balance</div>
<div className="list-item-value">
10 credits
</div>
<button className="btn btn-icon-round-danger btn-small">
<FontAwesomeIcon icon={faPlus} />
</button>
<button className="btn btn-icon-round-danger btn-small">
<FontAwesomeIcon icon={faTimes} />
</button>
</div>`}
          />
        </PageContent>
      </div>
    );
  }
}
