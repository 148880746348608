import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import i18next from 'i18next';

import PureComponent from './PureComponent';
import Main from './Main';
import Alert from '^/components/Alert';

export class NotFoundPage extends PureComponent {
  render() {
    const { user } = this.props;

    return (
      <Main>
        <div>
          <h1>{i18next.t('Not Found')}</h1>

          <Alert>
            {i18next.t('This page does not exist on the system.')}
            <br />
            {user && !user.isEmpty() ? (
              <a href="/#/page/home">{i18next.t('Go to home page.')}</a>
            ) : (
              <a href="/#/login">{i18next.t('Go to login page.')}</a>
            )}
          </Alert>
        </div>
      </Main>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userProfile,
  };
}

export default connect(mapStateToProps)(NotFoundPage);
