import React from 'react'; // eslint-disable-line no-unused-vars
import i18next from 'i18next';

import PureComponent from '^/components/PureComponent';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';

export default class ProductCostsTable extends PureComponent {
  render() {
    const { reportCosts, account } = this.props;
    const { product_types, productorganisation_set } = account.toObject();
    return (
      <div className="credits-product-table-wrapper">
        <div className="credit-product-title">
          <b>{i18next.t('Products')}</b> -{' '}
          <i>{i18next.t('All prices are displayed before tax')}</i>
        </div>

        <table className="inline-table report-costs-table">
          {PRODUCT_TYPES.ORDER.map((type, idx) => {
            const productDisabledForAccount = !product_types.getIn(
              [type, 'is_enabled'],
              false
            );
            const reports = reportCosts.filter(
              report => report.get('product') === type
            );
            return [
              <thead key={idx}>
                <tr className="report-costs-margin">
                  <th />
                  <th />
                </tr>
                <tr className="report-costs-background">
                  <th className="text-left report-costs-padding">
                    <div className="credit-product-name">
                      {PRODUCT_TYPES.DISPLAY[type]}
                    </div>
                    {productDisabledForAccount && (
                      <div className="credit-product-activate">
                        {i18next.t('Not available')}
                      </div>
                    )}
                  </th>
                  <th className="report-costs-padding">{i18next.t('Cost')}</th>
                </tr>
              </thead>,
              <tbody key={idx} className="report-costs-padding">
                {reports.map(report => {
                  const {
                    report_template_type_display: typeDisplay,
                    credit_cost: baseCost,
                    report_template_id,
                  } = report.toObject();

                  const productOrgReportTemplate = productorganisation_set
                    .map(productOrg =>
                      productOrg
                        .get('productorganisationreporttemplate_set')
                        .filter(
                          each =>
                            each.getIn(['report_template', 'id']) ===
                            report_template_id
                        )
                    )
                    .flatten(true)
                    .first();

                  const cost =
                    (productOrgReportTemplate &&
                      productOrgReportTemplate.get('credit_cost')) ||
                    baseCost;

                  const costDisplay = `${cost} ${i18next.t('credits')}`;
                  const isDeactivated = productDisabledForAccount
                    ? ' deactivated'
                    : '';
                  return (
                    <tr key={typeDisplay}>
                      <td
                        className={
                          'report-costs-name report-costs-padding' +
                          isDeactivated
                        }
                      >
                        {typeDisplay}
                      </td>
                      <td
                        className={
                          'report-costs-cost report-costs-padding' +
                          isDeactivated
                        }
                      >
                        {costDisplay}
                      </td>
                    </tr>
                  );
                })}
              </tbody>,
            ];
          })}
        </table>
      </div>
    );
  }
}
