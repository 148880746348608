import React from 'react'; // eslint-disable-line no-unused-vars
import _ from 'underscore';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { List, Map, fromJS } from 'immutable';
import i18next from 'i18next';

import PureComponent from '../PureComponent';
import { openModal } from '../../actions/modals';
import { getOrganisationsSimple } from '../../actions/collections';
import { setPageSize, PAGE_SIZE_OPTIONS } from '^/actions/actions';
import ControlBar from '../ControlBar';
import OrganisationRow from './OrganisationRow';
import AddAccountModal from './AddAccountModal';
import ListPage from '../ListPage';
import ListPagePagination from '../ListPagePagination';
import HEADERS from './organisationHeaders';
import { withRouter } from '^/withRouter';
import {
  fromQueryString,
  toQueryString,
  COMPARATOR_SUFFIXES,
} from '../../utils';

const COMPARATORS = {
  created: COMPARATOR_SUFFIXES,
};

export class OrganisationsPage extends PureComponent {
  UNSAFE_componentWillMount() {
    const { noLoad, filterSpec } = this.props;
    if (!noLoad) {
      this.props.getOrganisationsSimple('', 1, filterSpec);
    }
  }

  getHeaders() {
    const { isSelectModal, selectOne } = this.props;

    if (!isSelectModal) {
      return HEADERS;
    }
    return HEADERS.filter(
      each =>
        !List.of('Action', 'Credits', 'Live Activities').contains(each.title)
    ).concat(selectOne ? [] : [{ title: 'Add', className: '' }]);
  }

  getOrganisations(searchString) {
    const { filterSpec, pageSize } = this.props;
    this.props.getOrganisationsSimple(searchString, 1, filterSpec, pageSize);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { organisations, filterSpec, pageSize } = this.props;
    if (
      !_.isEqual(nextProps.filterSpec, filterSpec) ||
      !_.isEqual(nextProps.pageSize, pageSize)
    ) {
      this.props.getOrganisationsSimple(
        organisations.get('searchString', ''),
        1,
        nextProps.filterSpec,
        nextProps.pageSize
      );
    }
  }

  loadPage(page, pageSize) {
    const { organisations } = this.props;
    this.props.getOrganisationsSimple(
      organisations.get('searchString', ''),
      page,
      organisations.get('filters'),
      pageSize || this.props.pageSize
    );
  }

  showAddAccountModal() {
    this.props.openModal({
      title: i18next.t('Create a new account'),
      body: <AddAccountModal />,
    });
  }

  onFilterChange(key, value, comparator = '') {
    let newFilterSpec = Object.assign({}, this.props.filterSpec);
    if (COMPARATORS[key]) {
      _.each(
        COMPARATORS[key],
        eachComparator => delete newFilterSpec[key + eachComparator]
      );
    }
    newFilterSpec = Object.assign(newFilterSpec, { [key + comparator]: value });
    this.props.push('/page/accounts/' + toQueryString(newFilterSpec));
  }

  getFilters() {
    const { filterSpec } = this.props;

    return [
      {
        name: i18next.t('Status'),
        key: 'is_active',
        currentValue: filterSpec['is_active'],
        values: {
          values: fromJS([
            { id: '2', name: 'Active' },
            { id: '3', name: 'Inactive' },
          ]),
        },
      },
      {
        name: i18next.t('Created date'),
        key: 'created',
        currentValue: filterSpec,
        comparators: COMPARATORS['created'],
        values: { dateTime: true },
      },
    ];
  }

  renderOrganisation(organisation) {
    const { getRowProps } = this.props;
    return (
      <OrganisationRow
        key={organisation.get('id')}
        organisation={organisation}
        isAdmin
        {...(getRowProps ? getRowProps(organisation) : null)}
      />
    );
  }

  render() {
    const { organisations, response, hideAdd, pageSize } = this.props;
    const items = organisations.get('items');
    const filters = this.getFilters();

    return (
      <div>
        <ListPage
          headers={this.getHeaders()}
          items={items}
          renderer={organisation => this.renderOrganisation(organisation)}
          isSelectModal={this.props.isSelectModal}
          tableClassName="organisations-table"
          listClassName="organisation-grid"
          typeNamePlural="accounts"
          response={response}
          filters={filters}
          onFilterChange={this.onFilterChange.bind(this)}
        >
          <ControlBar
            createButtonText="account"
            title="Accounts"
            getItems={this.getOrganisations.bind(this)}
            onAddClick={() => this.showAddAccountModal()}
            hideAdd={hideAdd}
          />
          <ListPagePagination
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            collection={organisations}
            onSelectPage={page => this.loadPage(page)}
            onChangePageSize={this.props.setPageSize}
          />
        </ListPage>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    filterSpec: fromQueryString(props.router.params['filter']),
    organisations: state.collections.get(
      props.collectionName || 'organisations-simple',
      Map()
    ),
    response: state.responses.get('getCollection'),
    pageSize: props.pageSize || state.ui.get('pageSize'),
  };
}

const dispatchProps = {
  openModal,
  push,
  setPageSize,
  getOrganisationsSimple,
};

export default withRouter(
  connect(mapStateToProps, dispatchProps)(OrganisationsPage)
);
