import i18next from 'i18next';
import React from 'react';
import { connect } from 'react-redux';

import { getShopItems } from '^/actions/shop';
import ContentTab from '^/components/ContentTab';
import ContentTabset from '^/components/ContentTabset';
import ControlBar from '^/components/ControlBar';
import Loading from '^/components/Loading';
import { ShopItemCategory } from '^/reducers/api/types';
import { isPending } from '^/responseStates';
import { selectShopItemCategories } from '^/selectors';
import { StoreState } from '^/store';
import ShopCategory from './ShopCategory';

interface DispatchProps {
  getShopItems: typeof getShopItems;
}

interface StateProps {
  shopItemCategories: ReadonlyArray<ShopItemCategory> | null;
  isLoading: boolean;
}

type Props = DispatchProps & StateProps;

export class ShopPage extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.getShopItems();
  }

  public render() {
    const { shopItemCategories, isLoading } = this.props;

    return (
      <div>
        <ControlBar
          title={i18next.t<string>('Peoplewise shop')}
          hideSearch
          hideAdd
        />
        <div>
          {isLoading || !shopItemCategories ? (
            <Loading />
          ) : (
            <ContentTabset
              name="shop-tabs"
              defaultActiveTab={shopItemCategories[0].id}
            >
              {shopItemCategories.map((category, idx) => (
                <ContentTab
                  key={idx}
                  tabId={category.id}
                  tabsetName="shop-tabs"
                  tabName={category.title}
                  className="tab-heading"
                >
                  <ShopCategory category={category} key={category.id} />
                </ContentTab>
              ))}
            </ContentTabset>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    shopItemCategories: selectShopItemCategories(state),
    isLoading: isPending(state.responses.get('shopItems')),
  };
}

export default connect(mapStateToProps, { getShopItems })(ShopPage);
