import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import Email from './Email';

export default function UserNameAndEmail({ user, inline, action }) {
  const full_name = user.get('full_name'),
    email = <Email email={user.get('email')} />;

  if (full_name) {
    return (
      <span>
        {full_name}
        {!inline && <br />}
        {inline && ' ('}
        {email}
        {inline && ') '}
        {action}
      </span>
    );
  }
  return (
    <span>
      {email} {action}
    </span>
  );
}

UserNameAndEmail.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  action: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};
