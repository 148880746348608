import i18next from 'i18next';
import React from 'react';
import classNames from 'classnames';

const PulseOption = ({
  label,
  isSelected,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <button
    className={classNames('pulse-option btn', { 'btn-primary': isSelected })}
    onClick={onClick}
  >
    {label}
  </button>
);

const PULSE_OPTIONS = [
  { value: 1, label: i18next.t<string>('Strongly disagree') },
  { value: 2, label: i18next.t<string>('Disagree') },
  { value: 3, label: i18next.t<string>('Neither agree nor disagree') },
  { value: 4, label: i18next.t<string>('Agree') },
  { value: 5, label: i18next.t<string>('Strongly agree') },
];

const PulseItem = ({
  selectValue,
  selectedValue,
  text,
  numItems,
  itemNumber,
}: {
  selectValue: (value: number) => void;
  selectedValue: number | null;
  text: string;
  numItems: number;
  itemNumber: number;
}) => (
  <div className="pulse-item">
    <p className="item-number">
      {itemNumber}/{numItems}
    </p>
    <h2>{text}</h2>
    {PULSE_OPTIONS.map(({ value, label }) => (
      <PulseOption
        key={value}
        isSelected={selectedValue === value}
        label={label}
        onClick={() => selectValue(value)}
      />
    )).reverse()}
  </div>
);

export default PulseItem;
