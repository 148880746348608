import React from 'react'; // eslint-disable-line no-unused-vars
import { Trans } from 'react-i18next';

import { SUPPORT_EMAIL } from '^/settings';
import PeoplewiseLogo from './PeoplewiseLogo';
import ContentBox from './ContentBox';
import Img from './Img';
import LanguageSelector from './buttons/LanguageSelector';

const NoAuth = ({ description, children, noLang }) => (
  <div className="container">
    <div className="no-auth wider">
      <div className="row">
        <div className="col-xs-12">
          <div className="peoplewise-powered-by">
            <Img
              src={'/static/img/brand/peoplewise-logo-full.png'}
              alt="powered by peoplewise - enabling change"
            />
          </div>
        </div>
      </div>

      {description && <p className="text-center">{description}</p>}

      <div className="row">
        <ContentBox>
          {!noLang && <LanguageSelector />}
          {children}
        </ContentBox>
      </div>
      <div className="row">
        <PeoplewiseLogo />
      </div>
      <div className="call-out-text">
        <Trans i18nKey="TRANS Any issues">
          Any issues? Contact us at
          <a href={`mailto:${SUPPORT_EMAIL}`}>{{ SUPPORT_EMAIL }}</a>
        </Trans>
      </div>
    </div>
  </div>
);

export default NoAuth;
