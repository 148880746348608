import { Map } from 'immutable';
import _ from 'underscore';

import {
  ACCOUNT_TYPE,
  accountAllowsDataAnalytics,
  accountAllowsDataExports,
  accountAllowsSecureShareAccess,
  accountIsUsingCredit,
  accountSeesAdvancedDashboardTiles,
} from '^/models/organisation';
import { USER_ROLES } from '^/models/user';

const ONLY_SEE_SURVEY = 'ONLY_SEE_SURVEY';
const SEE_ADMIN_DASHBOARD = 'SEE_ADMIN_DASHBOARD';
const SEE_ENDUSER_DASHBOARD = 'SEE_ENDUSER_DASHBOARD';
const ADMINISTER_OWN_ORGANISATION = 'ADMINISTER_OWN_ORGANISATION';
const ADMINISTER_ORGANISATIONS = 'ADMINISTER_ORGANISATIONS';
const ADMINISTER_ANY_ORGANISATIONS = 'ADMINISTER_ANY_ORGANISATIONS';
const ADMINISTER_ACTIVITIES = 'ADMINISTER_ACTIVITIES';
const ADMINISTER_PRODUCT_VERSIONS = 'ADMINISTER_PRODUCT_VERSIONS';
const ADMINISTER_USERS = 'ADMINISTER_USERS';
const VIEW_DATA_EXPORTS = 'VIEW_DATA_EXPORTS';
const VIEW_DATA_ANALYTICS = 'VIEW_DATA_ANALYTICS';
const CREATE_USERS = 'CREATE_USERS';
const SUDO_AS_USER = 'SUDO_AS_USER';
const CHANGE_USERS_STATE = 'CHANGE_USERS_STATE';
const ACCESS_PRODUCT_ADMIN = 'ACCESS_PRODUCT_ADMIN';
const CREATE_ANONYMOUS_ACTIVITIES = 'CREATE_ANONYMOUS_ACTIVITIES';
const SEE_DATA_EXPORTS_AVERAGE_VALUES = 'SEE_DATA_EXPORTS_AVERAGE_VALUES';
const SEE_EXPORT_RAW_SCORES = 'SEE_EXPORT_RAW_SCORES';
const SEE_EXPORT_THREE_SIXTY = 'SEE_EXPORT_THREE_SIXTY';
const SEE_CREDITS = 'SEE_CREDITS';
const SHARE_ACCESS = 'SHARE_ACCESS';
const SEE_ONLY_OWN_ACTIVITIES = 'SEE_ONLY_OWN_ACTIVITIES';
const SEE_REPORT_LIBRARY_TILE = 'SEE_REPORT_LIBRARY_TILE';
const SEE_DATA_ANALYTICS_TILE = 'SEE_DATA_ANALYTICS_TILE';
const DOWNLOAD_EXISTING_REPORTS = 'DOWNLOAD_EXISTING_REPORTS';
const SEND_REPORTS = 'SEND_REPORTS';
const PURCHASE_REPORTS = 'PURCHASE_REPORTS';
const MUTE_ADMIN_REPORT_DISTRIBUTION = 'MUTE_ADMIN_REPORT_DISTRIBUTION';
const DOWNLOAD_SHARED_REPORTS = 'DOWNLOAD_SHARED_REPORTS';
const DOWNLOAD_OTHER_REPORTS = 'DOWNLOAD_OTHER_REPORTS';
const SEE_SHOP = 'SEE_SHOP';
const INTERACT_WITH_SHOP = 'INTERACT_WITH_SHOP';
const ASSIGN_NON_ACCREDITED_PRODUCTS = 'ASSIGN_NON_ACCREDITED_PRODUCTS';
const SEE_HOME_NAV = 'SEE_HOME_NAV';
const SEE_DASHBOARD_NAV = 'SEE_DASHBOARD_NAV';
const ADMINISTER_PULSES = 'ADMINISTER_PULSES';
const CREATE_AND_LIST_PULSES = 'CREATE_AND_LIST_PULSES';
const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';

interface CapabilityAction {
  type: string;
  payload?: { [key: string]: any };
}

export function onlySeeSurvey(): CapabilityAction {
  return { type: ONLY_SEE_SURVEY };
}

export function seeAdminDashboard(): CapabilityAction {
  return { type: SEE_ADMIN_DASHBOARD };
}

export function seeEndUserDashboard(): CapabilityAction {
  return { type: SEE_ENDUSER_DASHBOARD };
}

export function administerOwnOrganisation(): CapabilityAction {
  return { type: ADMINISTER_OWN_ORGANISATION };
}

export function administerOrganisations(): CapabilityAction {
  return { type: ADMINISTER_ORGANISATIONS };
}

export function administerAnyOrganisations(): CapabilityAction {
  return { type: ADMINISTER_ANY_ORGANISATIONS };
}

export function administerActivities(): CapabilityAction {
  return { type: ADMINISTER_ACTIVITIES };
}

export function administerProductVersions(): CapabilityAction {
  return { type: ADMINISTER_PRODUCT_VERSIONS };
}

export function administerUsers(): CapabilityAction {
  return { type: ADMINISTER_USERS };
}

export function createAndListPulses(): CapabilityAction {
  return { type: CREATE_AND_LIST_PULSES };
}

export function viewDataExports(): CapabilityAction {
  return { type: VIEW_DATA_EXPORTS };
}

export function viewDataAnalytics(): CapabilityAction {
  return { type: VIEW_DATA_ANALYTICS };
}

export function createUsersWithRole(userRole: string): CapabilityAction {
  return { type: CREATE_USERS, payload: { userRole } };
}

export function sudoAsUser(): CapabilityAction {
  return { type: SUDO_AS_USER };
}

export function changeUsersState(user: Map<string, any>): CapabilityAction {
  return { type: CHANGE_USERS_STATE, payload: { user } };
}

export function accessProductAdmin(): CapabilityAction {
  return { type: ACCESS_PRODUCT_ADMIN };
}

export function createAnonymousActivities(): CapabilityAction {
  return { type: CREATE_ANONYMOUS_ACTIVITIES };
}

export function seeDataExportsAverageValues(): CapabilityAction {
  return { type: SEE_DATA_EXPORTS_AVERAGE_VALUES };
}

export function seeExportRawScores(): CapabilityAction {
  return { type: SEE_EXPORT_RAW_SCORES };
}

export function seeThreeSixtyDataExport(): CapabilityAction {
  return { type: SEE_EXPORT_THREE_SIXTY };
}

export function seeCredits(): CapabilityAction {
  return { type: SEE_CREDITS };
}

export function seeShop(): CapabilityAction {
  return { type: SEE_SHOP };
}

export function interactWithShop(): CapabilityAction {
  return { type: INTERACT_WITH_SHOP };
}

export function shareAccess(): CapabilityAction {
  return { type: SHARE_ACCESS };
}

export function seeOnlyOwnActivities(): CapabilityAction {
  return { type: SEE_ONLY_OWN_ACTIVITIES };
}

export function seeReportLibraryTile(): CapabilityAction {
  return { type: SEE_REPORT_LIBRARY_TILE };
}

export function downloadExistingReports(): CapabilityAction {
  return { type: DOWNLOAD_EXISTING_REPORTS };
}

export function sendReports(): CapabilityAction {
  return { type: SEND_REPORTS };
}

export function seeDataAnalyticsTile(): CapabilityAction {
  return { type: SEE_DATA_ANALYTICS_TILE };
}

export function purchaseReports(): CapabilityAction {
  return { type: PURCHASE_REPORTS };
}

export function muteAdminReportDistribution(): CapabilityAction {
  return { type: MUTE_ADMIN_REPORT_DISTRIBUTION };
}

export function downloadSharedReports(): CapabilityAction {
  return { type: DOWNLOAD_SHARED_REPORTS };
}

export function downloadOtherReports(): CapabilityAction {
  return { type: DOWNLOAD_OTHER_REPORTS };
}

export function assignNonAccreditedProducts(): CapabilityAction {
  return { type: ASSIGN_NON_ACCREDITED_PRODUCTS };
}

export function seeHomeNav(): CapabilityAction {
  return { type: SEE_HOME_NAV };
}

export function seeDashboardNav(): CapabilityAction {
  return { type: SEE_DASHBOARD_NAV };
}

export function administerPulses(): CapabilityAction {
  return { type: ADMINISTER_PULSES };
}

export function switchLanguage(): CapabilityAction {
  return { type: SWITCH_LANGUAGE };
}

export function can(
  user: Immutable.Map<string, any>,
  capability: CapabilityAction
): boolean {
  const role = user.get('role');
  const account = user.get('organisation');
  const accountType = account && account.get('account_type');

  switch (capability.type) {
    case ONLY_SEE_SURVEY:
      return role === USER_ROLES.EXTERNAL;

    case SEE_SHOP:
    case PURCHASE_REPORTS:
    case SEE_ADMIN_DASHBOARD:
    case ADMINISTER_ACTIVITIES:
    case ADMINISTER_PRODUCT_VERSIONS:
    case ADMINISTER_PULSES:
    case ADMINISTER_USERS:
      return (
        role === USER_ROLES.ADMIN || can(user, administerOwnOrganisation())
      );

    case VIEW_DATA_ANALYTICS:
      return (
        role === USER_ROLES.ADMIN ||
        (can(user, administerOwnOrganisation()) &&
          accountAllowsDataAnalytics(account))
      );

    case SEE_ENDUSER_DASHBOARD:
    case SWITCH_LANGUAGE:
      return role !== USER_ROLES.ADMIN;

    case ADMINISTER_ORGANISATIONS:
    case CREATE_AND_LIST_PULSES:
    case SEE_EXPORT_RAW_SCORES:
    case SEE_EXPORT_THREE_SIXTY:
    case SUDO_AS_USER:
    case ACCESS_PRODUCT_ADMIN:
    case CREATE_ANONYMOUS_ACTIVITIES:
    case SEE_DATA_EXPORTS_AVERAGE_VALUES:
    case ASSIGN_NON_ACCREDITED_PRODUCTS:
      return role === USER_ROLES.ADMIN;

    case INTERACT_WITH_SHOP:
    case ADMINISTER_OWN_ORGANISATION:
      return (
        Boolean(account) &&
        [
          USER_ROLES.ORGADMIN,
          USER_ROLES.ACCOUNT_SUPERUSER,
          USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER,
          USER_ROLES.ACCOUNT_ORG_ADMIN,
        ].includes(role)
      );

    case VIEW_DATA_EXPORTS:
      return (
        role === USER_ROLES.ADMIN ||
        (can(user, administerOwnOrganisation()) &&
          accountAllowsDataExports(account))
      );

    case ADMINISTER_ANY_ORGANISATIONS:
      return (
        can(user, administerOrganisations()) ||
        can(user, administerOwnOrganisation())
      );

    // Updating this? Be sure to update `project/accounts/organisations/utils.py` to match
    case CREATE_USERS:
      switch (capability.payload?.userRole) {
        case USER_ROLES.ADMIN:
        case USER_ROLES.ORGADMIN:
        case USER_ROLES.ACCOUNT_SUPERUSER:
        case USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER:
          return role === USER_ROLES.ADMIN;
        case USER_ROLES.ACCOUNT_ORG_ADMIN:
          return (
            role === USER_ROLES.ADMIN || role === USER_ROLES.ACCOUNT_SUPERUSER
          );
        case USER_ROLES.ENDUSER:
          return true;
        case USER_ROLES.EXTERNAL:
        default:
          return false;
      }

    case CHANGE_USERS_STATE:
      switch (role) {
        case USER_ROLES.ADMIN:
          return true;
        case USER_ROLES.ACCOUNT_SUPERUSER:
          return Boolean(
            capability.payload?.user &&
              _.contains(
                [
                  USER_ROLES.ACCOUNT_ORG_ADMIN,
                  USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER,
                  USER_ROLES.ENDUSER,
                ],
                capability.payload.user.get('role')
              ) &&
              capability.payload.user.getIn(['organisation', 'id']) ===
                user.getIn(['organisation', 'id'])
          );
        case USER_ROLES.ACCOUNT_ORG_ADMIN:
        case USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER:
          return Boolean(
            capability.payload?.user &&
              _.contains(
                [USER_ROLES.ENDUSER],
                capability.payload.user.get('role')
              ) &&
              capability.payload.user.getIn(['organisation', 'id']) ===
                user.getIn(['organisation', 'id'])
          );
        case USER_ROLES.ORGADMIN:
          return Boolean(
            capability.payload?.user &&
              _.contains(
                [USER_ROLES.ENDUSER],
                capability.payload.user.get('role')
              ) &&
              capability.payload.user.getIn(['organisation', 'id']) ===
                user.getIn(['organisation', 'id'])
          );
        case USER_ROLES.ENDUSER:
          return false;
        default:
          return false;
      }

    case SEE_CREDITS:
      return (
        role === USER_ROLES.ADMIN ||
        (can(user, administerOwnOrganisation()) &&
          accountIsUsingCredit(account))
      );

    case SHARE_ACCESS:
      return (
        can(user, administerOwnOrganisation()) &&
        accountAllowsSecureShareAccess(account)
      );

    case SEE_ONLY_OWN_ACTIVITIES:
      return (
        accountType === ACCOUNT_TYPE.SOLE_PRACTITIONER ||
        _.contains(
          [
            USER_ROLES.ACCOUNT_ORG_ADMIN,
            USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER,
          ],
          role
        )
      );

    case SEE_REPORT_LIBRARY_TILE:
    case SEE_DATA_ANALYTICS_TILE:
      return (
        role === USER_ROLES.ADMIN ||
        (can(user, administerOwnOrganisation()) &&
          accountSeesAdvancedDashboardTiles(account))
      );

    case SEND_REPORTS:
    case MUTE_ADMIN_REPORT_DISTRIBUTION:
    case DOWNLOAD_EXISTING_REPORTS:
      return (
        role === USER_ROLES.ADMIN || can(user, administerOwnOrganisation())
      );
    case DOWNLOAD_SHARED_REPORTS:
      return (
        ![
          USER_ROLES.ACCOUNT_ORG_ADMIN,
          USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER,
        ].includes(role) || accountType !== ACCOUNT_TYPE.ENTERPRISE_LITE
      );
    case DOWNLOAD_OTHER_REPORTS:
      return ![
        USER_ROLES.ACCOUNT_ORG_ADMIN,
        USER_ROLES.ACCOUNT_PROFESSIONAL_PRACTITIONER,
      ].includes(role);
    case SEE_HOME_NAV:
      return ![USER_ROLES.ENDUSER, USER_ROLES.EXTERNAL].includes(role);
    case SEE_DASHBOARD_NAV:
      return [USER_ROLES.ENDUSER, USER_ROLES.EXTERNAL].includes(role);

    default:
      return false;
  }
}
